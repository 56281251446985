import {
  put,
  call,
  all,
  fork,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { get } from 'lodash';

import { errorMessageHandler } from 'common/utils/helpers';
import {
  adminEnquiryById,
  userEnquiryById,
  getFundType,
  trackBridgeLoanProcess,
  trackVdProcess,
  getLoansDetails,
  getYcDocs,
  getAdminLoanTerms,
  getStartupDetailsForSignUp,
  getLoanCloserInfo,
  handleLoanCloserService,
  getAdminRepaidAmount,
  getStartupRepaidAmount,
} from 'services';
import { ISagaAction, PayloadType } from 'common/types';

import {
  ADMIN_GET_ENQUIRY_BY_ID,
  ADMIN_GET_ENQUIRY_BY_ID_LOADING,
  ADMIN_GET_ENQUIRY_BY_ID_FAILURE,
  ADMIN_GET_ENQUIRY_BY_ID_SUCCESS,
  ADMIN_GET_ENQUIRY_BY_STARTUP_ID,
  GET_FUND_TYPE,
  GET_FUND_TYPE_LOADING,
  GET_FUND_TYPE_SUCCESS,
  GET_FUND_TYPE_FAILURE,
  TRACK_BRIDGE_LOAN_PROCESS,
  TRACK_BRIDGE_LOAN_PROCESS_LOADING,
  TRACK_BRIDGE_LOAN_PROCESS_SUCCESS,
  TRACK_BRIDGE_LOAN_PROCESS_FAILURE,
  TRACK_VD_PROCESS,
  TRACK_VD_PROCESS_LOADING,
  TRACK_VD_PROCESS_SUCCESS,
  TRACK_VD_PROCESS_FAILURE,
  GET_LOANS_DETAILS_BY_STARTUP_ID,
  GET_LOANS_DETAILS_BY_STARTUP_ID_LOADING,
  GET_LOANS_DETAILS_BY_STARTUP_ID_FAILURE,
  GET_LOANS_DETAILS_BY_STARTUP_ID_SUCCESS,
  GET_YC_DOCS_BY_STARTUP_ID,
  GET_YC_DOCS_BY_STARTUP_ID_LOADING,
  GET_YC_DOCS_BY_STARTUP_ID_FAILURE,
  GET_YC_DOCS_BY_STARTUP_ID_SUCCESS,
  GET_LOAN_TERMS_BY_STARTUP_ID,
  GET_LOAN_TERMS_BY_STARTUP_ID_LOADING,
  GET_LOAN_TERMS_BY_STARTUP_ID_FAILURE,
  GET_LOAN_TERMS_BY_STARTUP_ID_SUCCESS,
  STARTUP_INFO,
  STARTUP_INFO_LOADING,
  STARTUP_INFO_SUCCESS,
  STARTUP_INFO_FAILURE,
  GET_LOAN_CLOSER_INFO_LOADING,
  GET_LOAN_CLOSER_INFO_SUCCESS,
  GET_LOAN_CLOSER_INFO_FAILURE,
  GET_LOAN_CLOSER_INFO,
  HANDLE_LOAN_CLOSER_LOADING,
  HANDLE_LOAN_CLOSER_SUCCESS,
  HANDLE_LOAN_CLOSER_FAILURE,
  HANDLE_LOAN_CLOSER,
  GET_REPAID_AMOUNT_SUCCESS,
  GET_REPAID_AMOUNT_FAILURE,
  HANDLE_GET_REPAID_AMOUNT,
} from './Actions';

type ResponseType = {
  response: any;
};

const AdminGetEnquiryById = async (payload: PayloadType) =>
  adminEnquiryById(payload)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const getEnquiryByStartupId = async (payload: string) =>
  userEnquiryById(payload)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

function* handleAdminGetEnquiryById(data: ISagaAction<PayloadType>) {
  const { payload } = data;
  try {
    yield put({ type: ADMIN_GET_ENQUIRY_BY_ID_LOADING, payload: true });
    const response: ResponseType = yield call<any>(
      AdminGetEnquiryById,
      payload,
    );
    yield put({ type: ADMIN_GET_ENQUIRY_BY_ID_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: ADMIN_GET_ENQUIRY_BY_ID_FAILURE, payload: message });
  }
}

function* handleAdminGetEnquiryByStartupId(data: ISagaAction<PayloadType>) {
  const { payload } = data;
  try {
    yield put({ type: ADMIN_GET_ENQUIRY_BY_ID_LOADING, payload: true });
    const response: ResponseType = yield call<any>(
      getEnquiryByStartupId,
      payload.id,
    );
    yield put({
      type: ADMIN_GET_ENQUIRY_BY_ID_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({
      type: ADMIN_GET_ENQUIRY_BY_ID_FAILURE,
      payload: message,
    });
  }
}

function* handleGetFundType(data: ISagaAction<string | undefined>) {
  const { payload } = data;
  try {
    yield put({ type: GET_FUND_TYPE_LOADING });
    const response: ResponseType = yield call<any>(getFundType, payload);
    yield put({
      type: GET_FUND_TYPE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: GET_FUND_TYPE_FAILURE, payload: message });
  }
}

function* handleTrackBridgeLoanProcess(data: ISagaAction<string | undefined>) {
  const { payload } = data;
  try {
    yield put({ type: TRACK_BRIDGE_LOAN_PROCESS_LOADING });
    const response: ResponseType = yield call<any>(
      trackBridgeLoanProcess,
      payload,
    );
    yield put({
      type: TRACK_BRIDGE_LOAN_PROCESS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: TRACK_BRIDGE_LOAN_PROCESS_FAILURE, payload: message });
  }
}

function* handleTrackVdProcess(data: ISagaAction<string | undefined>) {
  const { payload } = data;
  try {
    yield put({ type: TRACK_VD_PROCESS_LOADING });
    const response: ResponseType = yield call<any>(trackVdProcess, payload);
    yield put({
      type: TRACK_VD_PROCESS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: TRACK_VD_PROCESS_FAILURE, payload: message });
  }
}

function* handleGetLoansDetailsByStartupId(
  data: ISagaAction<string | undefined>,
) {
  const { payload } = data;
  try {
    yield put({ type: GET_LOANS_DETAILS_BY_STARTUP_ID_LOADING });
    const response: ResponseType = yield call<any>(getLoansDetails, payload);
    yield put({
      type: GET_LOANS_DETAILS_BY_STARTUP_ID_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({
      type: GET_LOANS_DETAILS_BY_STARTUP_ID_FAILURE,
      payload: message,
    });
  }
}

function* handleGetYcDocumentsByStartupId(
  data: ISagaAction<string | undefined>,
) {
  const { payload } = data;
  try {
    yield put({ type: GET_YC_DOCS_BY_STARTUP_ID_LOADING });
    const queryArr: any = payload?.split('##');
    const response: ResponseType = yield call<any>(
      getYcDocs,
      `${queryArr[0]}/${queryArr[1]}`,
    );
    yield put({
      type: GET_YC_DOCS_BY_STARTUP_ID_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({
      type: GET_YC_DOCS_BY_STARTUP_ID_FAILURE,
      payload: message,
    });
  }
}

function* handleGetLoanTermsByStartupId(data: ISagaAction<string | undefined>) {
  const { payload } = data;
  try {
    yield put({ type: GET_LOAN_TERMS_BY_STARTUP_ID_LOADING });
    const response: ResponseType = yield call<any>(getAdminLoanTerms, payload);
    yield put({
      type: GET_LOAN_TERMS_BY_STARTUP_ID_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({
      type: GET_LOAN_TERMS_BY_STARTUP_ID_FAILURE,
      payload: message,
    });
  }
}

function* handleGetStartupInfo() {
  try {
    yield put({ type: STARTUP_INFO_LOADING });
    const response: ResponseType = yield call<any>(getStartupDetailsForSignUp);
    yield put({
      type: STARTUP_INFO_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({
      type: STARTUP_INFO_FAILURE,
      payload: message,
    });
  }
}

function* handleGetLoanCloserInfo(data: ISagaAction<string | undefined>) {
  try {
    const { payload } = data;
    yield put({ type: GET_LOAN_CLOSER_INFO_LOADING });
    const response: ResponseType = yield call<any>(getLoanCloserInfo, payload);
    yield put({
      type: GET_LOAN_CLOSER_INFO_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_LOAN_CLOSER_INFO_FAILURE,
      payload: message,
    });
  }
}

function* handleLoanCloser(data: ISagaAction<string | undefined>) {
  try {
    const { payload } = data;
    yield put({ type: HANDLE_LOAN_CLOSER_LOADING });
    const response: ResponseType = yield call<any>(
      handleLoanCloserService,
      payload,
    );
    yield put({
      type: HANDLE_LOAN_CLOSER_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: HANDLE_LOAN_CLOSER_FAILURE,
      payload: message,
    });
  }
}

function* handleGetRepaidAmount(data: ISagaAction<string | undefined>) {
  try {
    const { payload } = data;

    const response: ResponseType = yield call<any>(
      payload?.includes('startupId')
        ? getAdminRepaidAmount
        : getStartupRepaidAmount,
      payload,
    );
    yield put({
      type: GET_REPAID_AMOUNT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_REPAID_AMOUNT_FAILURE,
      payload: message,
    });
  }
}

function* getAdminEnquiryId() {
  yield takeEvery(ADMIN_GET_ENQUIRY_BY_ID, handleAdminGetEnquiryById);
}

function* getEnquiryDataByStartupId() {
  yield takeEvery(
    ADMIN_GET_ENQUIRY_BY_STARTUP_ID,
    handleAdminGetEnquiryByStartupId,
  );
}

function* getFundTypeSaga() {
  yield takeEvery(GET_FUND_TYPE, handleGetFundType);
}

function* trackBridgeLoanProcessSaga() {
  yield takeEvery(TRACK_BRIDGE_LOAN_PROCESS, handleTrackBridgeLoanProcess);
}

function* trackVdProcessSaga() {
  yield takeEvery(TRACK_VD_PROCESS, handleTrackVdProcess);
}

function* getLoansDetailsByStartupIdSaga() {
  yield takeEvery(
    GET_LOANS_DETAILS_BY_STARTUP_ID,
    handleGetLoansDetailsByStartupId,
  );
}

function* getYcDocstartupIdSaga() {
  yield takeEvery(GET_YC_DOCS_BY_STARTUP_ID, handleGetYcDocumentsByStartupId);
}
function* getLoanTermsByStartupIdSaga() {
  yield takeEvery(GET_LOAN_TERMS_BY_STARTUP_ID, handleGetLoanTermsByStartupId);
}

function* getStartupInfo() {
  yield takeEvery(STARTUP_INFO, handleGetStartupInfo);
}

function* getLoanCloserInfoSaga() {
  yield takeLatest(GET_LOAN_CLOSER_INFO, handleGetLoanCloserInfo);
}

function* handleLoanCloserSaga() {
  yield takeLatest(HANDLE_LOAN_CLOSER, handleLoanCloser);
}

function* handleGetAdminRepaidAmountSaga() {
  yield takeLatest(HANDLE_GET_REPAID_AMOUNT, handleGetRepaidAmount);
}

export default function* EnquirySaga() {
  yield all([
    fork(getAdminEnquiryId),
    fork(getEnquiryDataByStartupId),
    fork(getFundTypeSaga),
    fork(trackBridgeLoanProcessSaga),
    fork(trackVdProcessSaga),
    fork(getLoansDetailsByStartupIdSaga),
    fork(getYcDocstartupIdSaga),
    fork(getLoanTermsByStartupIdSaga),
    fork(getStartupInfo),
    fork(getLoanCloserInfoSaga),
    fork(handleLoanCloserSaga),
    fork(handleGetAdminRepaidAmountSaga),
  ]);
}
