import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  benchMarkWelcomeContainer: {
    width: '100%',
    background: '#FFFFFF',
    fontFamily: theme.typography.fontFamily,
    paddingBottom: 50,
    paddingTop: 35,
    borderRadius: 10,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    '@media(max-width: 1024px) and (min-width: 768px)': {
      paddingBottom: 30,
      paddingTop: 10,
      // height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    '@media(max-width: 767px)': {
      paddingBottom: 30,
      paddingTop: 10,
    },
  },
  benchMarkWelcomeContainerV2: {
    width: '100%',
    background: '#FFFFFF',
    fontFamily: theme.typography.fontFamily,
    paddingBottom: 30,
    paddingTop: 20,
    borderRadius: 4,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    border: '1px solid #CDE8DD',
    '@media(max-width: 1024px) and (min-width: 768px)': {
      paddingBottom: 30,
      paddingTop: 10,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    '@media(max-width: 767px)': {
      paddingBottom: 30,
      paddingTop: 10,
    },
  },
  benchmarkKpiText: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '30px',
    color: theme.palette.grey[50],
    paddingLeft: 46,
    paddingBottom: 10,
    '@media(max-width: 1024px)': {
      paddingLeft: 25,
      paddingBottom: 0,
    },
  },
  benchmarkKpiTextV2: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '30px',
    color: theme.palette.grey[50],
    paddingLeft: 36,
    paddingBottom: 5,
    '@media(max-width: 1024px)': {
      paddingLeft: 20,
      paddingBottom: 0,
    },
  },
  banchmarkBoxContainer: {
    padding: '20px 16px 40px 46px',
    textAlign: 'left',
    '@media(max-width: 1024px)': {
      padding: '20px 16px 30px 25px',
    },
  },
  banchmarkBoxContainerV2: {
    padding: '10px 12px 20px 36px',
    textAlign: 'left',
    '@media(max-width: 1024px)': {
      padding: '10px 12px 10px 20px',
    },
  },
  benchMarkWelcomeText: {
    fontSize: 30,
    fontWeight: 600,
    lineHeight: '30px',
    color: theme.palette.grey[100],
    marginBottom: 39,
    '@media(max-width: 1024px)': {
      fontSize: 25,
      lineHeight: '25px',
      marginBottom: 20,
    },
  },
  welcomeTextContent: {
    paddingRight: 35,
    '& ul': {
      paddingLeft: 20,
      marginTop: 0,
    },
  },
  benchmarkListContent: {
    '& li': {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
    },
    '& li::marker': {
      color: '#1EA59A',
      fontSize: 20,
      paddingTop: 2,
    },
  },
  getStartedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16.38,
    width: 256,
    height: 48,
    lineHeight: '20px',
    textTransform: 'capitalize',
    marginTop: 20,
    borderRadius: 7.5,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  benchMarkWelcomeImageContent: {
    display: 'flex',
    flexDirection: 'row',
    justigyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 959px)': {
      marginTop: 40,
    },
  },
  imageOverlapBoxesContainer: {
    position: 'relative',
    width: 59,
    '@media (max-width: 767px)': {
      width: 40,
    },
  },
  imageOverlapBoxes: {
    background: theme.palette.common.white,
    padding: '14px 16px',
    width: 141,
    borderRadius: 7,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    marginTop: 10,
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 1184px)': {
      padding: '10px',
      width: 121,
    },
    '@media (max-width: 525px)': {
      padding: '7px',
      width: 101,
    },
  },
  overImg: {
    height: 49,
    width: 49,
    '@media (max-width: 1184px)': {
      height: 35,
      width: 35,
    },
    '@media (max-width: 525px)': {
      height: 30,
      width: 30,
    },
  },
  benchMarkWelcomeImage: {
    width: 'calc(100% - 59px)',
    '@media (max-width: 767px)': {
      width: 'calc(100% - 40px)',
    },
  },
  growthIconText: {
    fontWeight: 500,
    fontSize: 12,
    color: '#000000',
    textAlign: 'left',
    paddingLeft: 13,
    marginBottom: 2,
    '@media (max-width: 1184px)': {
      fontSize: 9,
      paddingLeft: 9,
    },
    '@media (max-width: 767px)': {
      fontSize: 8,
      paddingLeft: 8,
    },
  },
  precenText: {
    fontSize: 16,
    fontWeight: 600,
    display: 'block',
    lineHeight: '19.5px',
    '@media (max-width: 1184px)': {
      fontSize: 12,
      lineHeight: '14.5px',
    },
    '@media (max-width: 767px)': {
      fontSize: 10,
      lineHeight: '12.5px',
    },
  },
}));
