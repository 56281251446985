import React, { useEffect } from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { Box, WrappedTextInput, Button } from 'components';
import { InvestorOnboardStepProps } from 'common/types';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  createAdminInvestmentDetail,
  getAdminKycSSN,
  getAdminSubscriberSSN,
} from 'services';

import { SubscriptionSchema } from '../../validations';
import styles from './styles';

const SubscriptionForm = ({
  handleNext,
  handleBack,
  spv,
  investor,
  kyc,
  investmentDetail,
  investmentProcess,
  syncInvestor,
}: InvestorOnboardStepProps) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isEditModeSSN, setIsEditModeSSN] = React.useState<boolean>(true);
  const [showSSN, setShowSSN] = React.useState<boolean>(false);
  const [kycSSN, setKycSSN] = React.useState<string>('');

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SubscriptionSchema),
  });

  const getKycSSNForSubscriber = async () => {
    if (!kycSSN) {
      try {
        const SSN: any = await getAdminKycSSN(get(kyc, 'id'));
        setKycSSN(SSN);
      } catch (err: any) {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.log('ERROR WHILE FETCHING SSN: ', message);
      }
    }
  };

  const handleClickShowSSN = () => {
    if (!showSSN) {
      getAdminSubscriberSSN(get(investmentDetail, 'id'))
        .then((res: any) => {
          setValue('subscriberEinOrSSN', res);
          setShowSSN(!showSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    } else {
      setValue(
        'subscriberEinOrSSN',
        get(investmentDetail, 'subscriberEinOrSSN'),
      );
      setShowSSN(!showSSN);
    }
  };

  const handleClickEditSSN = () => {
    if (!isEmpty(get(investmentDetail, 'id'))) {
      getAdminSubscriberSSN(get(investmentDetail, 'id'))
        .then((res: any) => {
          setValue('subscriberEinOrSSN', res);
          setIsEditModeSSN(!isEditModeSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  const handleCloseEditSSN = () => {
    setIsEditModeSSN(!isEditModeSSN);
    setValue('subscriberEinOrSSN', get(investmentDetail, 'ssn'));
  };

  const handleMouseDownSSN = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (get(investmentDetail, 'id')) {
      setValue('investmentAmount', get(investmentDetail, 'investmentAmount'));
      setValue(
        'investmentDate',
        moment(get(investmentDetail, 'investmentDate')).format('YYYY-MM-DD'),
      );
      setValue('subscriberName', get(investmentDetail, 'subscriberName'));
      setValue(
        'subscriberEinOrSSN',
        get(investmentDetail, 'subscriberEinOrSSN'),
      );
      if (!isEmpty(get(investmentDetail, 'subscriberEinOrSSN')))
        setIsEditModeSSN(false);
    } else {
      getKycSSNForSubscriber();
      setValue('subscriberName', get(kyc, 'signatoryLegalName'));
      setValue('subscriberEinOrSSN', kycSSN || get(kyc, 'ssn'));
    }
  }, [investmentDetail, kycSSN]);

  const onSubmit = async (data: any): Promise<void> => {
    setIsLoading(true);
    const payload = {
      ...data,
      investmentCurrentStep: 'SUBSCRIPTION_FORM',
      esignStatus: get(investmentProcess, 'docuSignDocumentSigned')
        ? 'COMPLETED'
        : get(investmentDetail, 'esignStatus', 'PENDING'),
      spv: spv,
      investorId: get(investor, 'id'),
      kycId: get(kyc, 'id'),
    };
    if (get(investmentDetail, 'id')) payload.id = get(investmentDetail, 'id');
    if (!isEditModeSSN) delete payload.subscriberEinOrSSN;
    try {
      await createAdminInvestmentDetail(payload);
    } catch (err) {
      setErrorMessage(errorMessageHandler(err));
    }
    setIsLoading(false);
    await syncInvestor();
    handleNext(2);
  };

  return (
    <Box>
      <Typography variant="h3" className={classes.investNowFormHeader}>
        Subscription form:
      </Typography>
      {errorMessage && (
        <Typography variant="body1" className={classes.errorMessage}>
          {errorMessage}
        </Typography>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="subscriptionForm"
        className={classes.validateForm}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.labelText} variant="body1">
              Investment Amount (US $)
            </Typography>
            <Controller
              name="investmentAmount"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="number"
                    label=""
                    disabled={get(investmentProcess, 'docuSignDocumentSigned')}
                    placeholder="Enter Investment Amount"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.labelText} variant="body1">
              Investment Date
            </Typography>
            <Controller
              name="investmentDate"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <TextField
                    id="date-local"
                    type="date"
                    placeholder="DD/MM/YYYY"
                    value={value}
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={!!error?.message}
                    onChange={onChange}
                    disabled={get(investmentProcess, 'docuSignDocumentSigned')}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.labelText} variant="body1">
              Subscriber Name
            </Typography>
            <Controller
              name="subscriberName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Name"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                    disabled={get(investmentProcess, 'docuSignDocumentSigned')}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.labelText} variant="body1">
              EIN/SSN of Subscriber
            </Typography>
            <Controller
              name="subscriberEinOrSSN"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="EIN/SSN of Subscriber"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      endAdornment: (
                        <>
                          {!isEditModeSSN &&
                            get(investmentDetail, 'subscriberEinOrSSN') &&
                            !get(
                              investmentProcess,
                              'docuSignDocumentSigned',
                            ) && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="edit"
                                  onClick={handleClickEditSSN}
                                  onMouseDown={handleMouseDownSSN}
                                  edge="end"
                                >
                                  <EditIcon />
                                </IconButton>
                              </InputAdornment>
                            )}
                          {isEditModeSSN &&
                            get(investmentDetail, 'subscriberEinOrSSN') &&
                            !get(
                              investmentProcess,
                              'docuSignDocumentSigned',
                            ) && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="close"
                                  onClick={handleCloseEditSSN}
                                  onMouseDown={handleMouseDownSSN}
                                  edge="end"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            )}
                          {get(investmentDetail, 'subscriberEinOrSSN') && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle visibility"
                                onClick={handleClickShowSSN}
                                onMouseDown={handleMouseDownSSN}
                                edge="end"
                                disabled={isEditModeSSN}
                              >
                                {showSSN ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                    disabled={
                      !isEditModeSSN ||
                      get(investmentProcess, 'docuSignDocumentSigned')
                    }
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
        </Grid>
        <Box className={classes.nextPrevButtonBox}>
          <Button
            variant="outlined"
            className={classes.prevButton}
            name="Previous"
            disabled={isLoading}
            onClick={() => handleBack()}
          />
          <Button
            type="submit"
            className={classes.nextButton}
            name="Next"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </Box>
      </form>
    </Box>
  );
};
export default SubscriptionForm;
