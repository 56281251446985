import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  inputHead: {
    padding: '12px 40px 12px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 12px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px !important',
    },
  },
  textValInput: {
    marginBottom: 25,
    marginTop: 7,
    width: '100%',
    '@media (max-width: 1120px)': {
      marginBottom: 15,
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 17,
    letterSpacing: 0.35,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  inputClasses: {
    display: 'none',
  },
  acceptBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    fontSize: 15,
    cursor: 'pointer',
    height: 50,
    width: '100%',
    marginTop: 15,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 22px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    // width: 150,
    fontSize: 15,
    cursor: 'pointer',
    height: 50,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    fontSize: 16,
    height: 48,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 450px)': {
      marginBottom: 20,
    },
  },
  backContinueBox: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  pointText: {
    fontSize: 15,
    letterSpacing: '0.35px',
    marginBottom: 10,
    color: theme.palette.grey[50],
  },
  strongText: {
    display: 'block',
    marginBottom: 7,
    fontWeight: 700,
  },
  companyLabelText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
  },
  vdLabelText: {
    fontSize: 15,
    // padding: 14,
  },
  chkBox: {
    display: 'inline-block',
    marginRight: 15,
  },
  vdLabelchk: {
    fontSize: 14,
    color: theme.palette.grey[50],
  },

  // VentureDebt success styling  ------------- start

  vdSuccess: {
    textAlign: 'center',
    maxWidth: 450,
    margin: 'auto',
    padding: '30px 50px',
    '@media (max-width: 560px)': {
      padding: 0,
    },
  },
  successHead: {
    color: theme.palette.grey[500],
    fontSize: 20,
    margin: '20px 0',
  },
  successsubHead: {
    color: theme.palette.grey[50],
    fontSize: 15,
    maxWidth: 370,
    margin: 'auto',
  },
  keyTerms: {
    marginTop: 20,
    maxWidth: 500,
  },
  // VentureDebt success styling  ------------- End
}));
