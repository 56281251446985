import React, { useState, useEffect, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import history from 'common/utils/history';
import { Box, Stepper } from 'components';
import {
  trackBridgeLoanProcess,
  trackVdProcess,
  getLoansDetailsByStartupId,
  getStartupInfo,
} from 'redux-modules/enquiries/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { formatAmount, errorMessageHandler } from 'common/utils/helpers';
import { getDealRequestSent } from 'services';

import styles from './styles';
import Chart from '../../../BenchmarkLite/Chart';
import { ShareDealProfile } from '../DialogBox';

function getBridgeLoanSteps() {
  return [
    { name: 'Submit Application', key: 'basicInfoAdded' },
    { name: 'Loan Terms', key: 'loanTermAdded' },
    { name: 'Upload Documents', key: 'documentsSubmitted' },
    { name: 'Sign Agreements', key: 'documentsSigned' },
    { name: 'Debt Approval', key: 'isLoanApproved' },
    { name: 'Wire Processing', key: 'isLoanProcessed' },
  ];
}

function getVentureDebtSteps() {
  return [
    { name: 'Request Raised', key: 'vdConversionRequestRaised' },
    { name: 'Request Approved', key: 'vdConversionRequestApproved' },
    { name: 'Verification Successful', key: 'documentsVerified' },
    { name: 'Loan Approved', key: 'isLoanToVdApproved' },
  ];
}

const StartupOverview = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const bridgeLoansteps = getBridgeLoanSteps();
  const ventureDebtSteps = getVentureDebtSteps();

  const query = new URLSearchParams(window.location.search);

  const type = query.get('type');
  const inviteId = query.get('invite');

  const [bridgeLoanActiveStep, setBridgeLoanActiveStep] = useState<number>(0);
  const [vdActiveStep, setVdActiveStep] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    isLoading,
    bridgeLoanProcess,
    vdProcess,
    loansDetails,
    startupInfo,
  } = useSelector(({ Enquiries }: RootState) => Enquiries);

  const {
    user: { startUpId, role },
    currentYcBatch,
  } = useSelector(({ Auth }: RootState) => Auth);
  const isRoleAdmin = role === 'ADMIN';

  useEffect(() => {
    if (!isRoleAdmin) {
      dispatch(trackBridgeLoanProcess(startUpId));
      dispatch(getLoansDetailsByStartupId(startUpId));
      dispatch(trackVdProcess(startUpId));
      dispatch(getStartupInfo());
    }
  }, []);

  const fundType = useMemo(() => {
    if (get(vdProcess, 'vdConversionRequestApproved', false)) {
      return 'VENTURE_DEBT';
    }
    return 'BRIDGE_LOAN';
  }, [vdProcess]);

  const getVdActiveStep = () => {
    getVentureDebtSteps().forEach((item, i) => {
      if (vdProcess[item.key]) {
        setVdActiveStep(i + 1);
      }
    });
  };

  const getBridgeActiveStep = () => {
    getBridgeLoanSteps().forEach((item, i) => {
      if (bridgeLoanProcess[item.key]) {
        setBridgeLoanActiveStep(i + 1);
      }
    });
  };

  const handleBridgeLoanStepClick = (index: number) => {
    // eslint-disable-next-line no-console
    console.log('handleStepClick', index);
    history.push('/startup/enquiry-form/EDIT');
  };

  const handleVDStepClick = (index: number) => {
    // eslint-disable-next-line no-console
    console.log('handleStepClick', index);
    history.push(
      isEmpty(bridgeLoanProcess)
        ? '/startup/vd-enquiry-form/NEW'
        : '/startup/vd-enquiry-form/EDIT',
    );
  };

  const getAllDealRequestSentToStartup = (id?: any) => {
    getDealRequestSent({ inviteId: id })
      .then((res: any) => {
        if (!isEmpty(res)) {
          setRequest(res);
          setOpen(true);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  useEffect(() => {
    if (vdProcess) {
      getVdActiveStep();
    }
  }, [vdProcess]);

  useEffect(() => {
    if (bridgeLoanProcess) {
      getBridgeActiveStep();
    }
  }, [bridgeLoanProcess]);

  useEffect(() => {
    if (type === 'deal' && !isEmpty(inviteId)) {
      getAllDealRequestSentToStartup(inviteId);
    } else {
      getAllDealRequestSentToStartup();
    }
  }, [type, inviteId]);

  const handleGetProcessStatus = () => {
    return get(bridgeLoanProcess, 'isLoanProcessed')
      ? ''
      : get(bridgeLoanProcess, 'isLoanApproved')
      ? 'Your ZERO financing has been approved, your wire will be processed shortly'
      : get(bridgeLoanProcess, 'documentsSigned')
      ? 'We have received your documents for review and we will get back to you shortly.'
      : get(bridgeLoanProcess, 'docuSignRequestRaised')
      ? 'We are processing the signed agreements'
      : 'Your loan application is in process';
  };

  return !isLoading ? (
    <>
      {!isEmpty(bridgeLoanProcess) && (
        <Box className={classes.dashLoanProcess}>
          <Typography className={classes.dashLoadText} variant="h5">
            {handleGetProcessStatus()}
          </Typography>
        </Box>
      )}
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      {fundType === 'VENTURE_DEBT' && (
        <Box className={classes.dashBridgeLoadBox}>
          <Typography
            className={classes.dashBridgeSubText}
            variant="h6"
            gutterBottom
          >
            Venture Debt Loan
          </Typography>
          <Box className={classes.bridgeContent}>
            <Box className={classes.bridgeLeft}>
              {get(loansDetails, 'isConvertedToVd') ? (
                <Box className={classes.leftContent}>
                  <Box className={classes.bridgeText}>
                    <Typography className={classes.btHeadText} variant="body1">
                      {formatAmount(loansDetails.amountConvertedToVd || 0)}
                    </Typography>
                    <Typography className={classes.btSubText} variant="body1">
                      Requested Loan
                    </Typography>
                  </Box>
                  <Box className={classes.bridgeText}>
                    <Typography className={classes.btText} variant="body1">
                      {!isEmpty(loansDetails) &&
                        formatAmount(loansDetails.vdQuarterlyInstallmentAmount)}
                    </Typography>
                    <Typography className={classes.btSubText} variant="body1">
                      Quarterly Installment
                    </Typography>
                  </Box>
                  <Box className={classes.bridgeText}>
                    <Typography className={classes.btText} variant="body1">
                      18/24
                    </Typography>
                    <Typography className={classes.btSubText} variant="body1">
                      Months Pending
                    </Typography>
                  </Box>
                </Box>
              ) : null}
              {/* <Box className={classes.bridgeRight}>
                  <FormControl
                    variant="outlined"
                    className={classes.actionSelectInput}
                  >
                    <WrappedSelect
                      label=""
                      variant={'outlined'}
                      value={'Actions'}
                      onChange={handleChange}
                      placeholder="Actions"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      dropdowns={actionsDropDowns}
                    />
                  </FormControl>
                </Box> */}
            </Box>
            <Box className={classes.bridgeCenter}>
              <Typography className={classes.centerText} variant="h5">
                Venture Debt status
              </Typography>
              <Stepper
                activeStep={vdActiveStep}
                completedSteps={vdActiveStep}
                className={classes.stepperBox}
                steps={ventureDebtSteps}
                alternativeLabel
                orientation={false}
                handleStepClick={handleVDStepClick}
              />
            </Box>
          </Box>
        </Box>
      )}
      {((!isEmpty(currentYcBatch) &&
        get(startupInfo, 'ycBatch') === get(currentYcBatch, 'value')) ||
        !isEmpty(bridgeLoanProcess)) && (
        <>
          <Box className={classes.dashBridgeLoadBox}>
            {(get(startupInfo, 'ycBatch') === get(currentYcBatch, 'value') ||
              !isEmpty(bridgeLoanProcess)) && (
              <Typography
                className={classes.dashBridgeSubText}
                variant="h6"
                gutterBottom
              >
                {get(bridgeLoanProcess, 'fundType') === 'VENTURE_DEBT'
                  ? 'Venture Debt Loan'
                  : 'Bridge Loan'}
              </Typography>
            )}
            <Box className={classes.dashBridgeContent}>
              {get(loansDetails, 'finalProcessedLoanAmount') ? (
                <Box className={classes.dashBridgeLeft}>
                  <Typography
                    className={classes.dashBridgeHeadText}
                    variant="body1"
                  >
                    {formatAmount(loansDetails.finalProcessedLoanAmount || 0)}
                  </Typography>
                  <Typography
                    className={classes.dashBridgeHeadSubText}
                    variant="body1"
                  >
                    Loan Amount
                  </Typography>
                </Box>
              ) : null}
              <Box className={classes.dashBridgeCenter}>
                <Stepper
                  activeStep={bridgeLoanActiveStep}
                  completedSteps={bridgeLoanActiveStep}
                  className={classes.stepperBox}
                  steps={bridgeLoansteps}
                  alternativeLabel
                  orientation={false}
                  handleStepClick={
                    get(bridgeLoanProcess, 'fundType') === 'VENTURE_DEBT'
                      ? handleVDStepClick
                      : handleBridgeLoanStepClick
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.hintBox}>
            <Typography className={classes.hintText}>
              Please help us offer you additional capital at better terms by
              sharing your investor updates at &nbsp;
              <a
                href="mailto:zeronote@8vdx.com"
                className={classes.mailToText}
                rel="noreferrer"
              >
                zeronote@8vdx.com
              </a>
              ,&nbsp;please feel free to add this email ID to your investor
              updates mailing list.
            </Typography>
          </Box>
        </>
      )}
      <Chart
        startupDetails={startupInfo}
        role={role}
        startUpId={startUpId}
        showBenchmarkGetStarted={true}
      />
      {open && (
        <ShareDealProfile
          open={open}
          request={request}
          closeDialog={() => {
            setRequest({});
            setOpen(false);
          }}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default StartupOverview;
