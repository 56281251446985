import React, { useEffect, useState } from 'react';
import { IconButton, Grid, Typography, Switch } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import ClearIcon from '@material-ui/icons/ClearOutlined';
import SaveIcon from '@material-ui/icons/Save';

import { Box, Button, Loader } from 'components';
import { createUpdateFcrmContact, deleteFcrmContact } from 'services';
import { emailRegex, phoneRegex } from 'common/utils/constants';
import { countryCodeList } from 'common/utils/optionList';
import { capitalizeFirstLetter } from 'common/utils/helpers';

import { InputField, SelectInputField } from './FormFields';
import styles from '../styles';
import { FCRMDeleteContactDialog } from '../FCRMDialogs';

type ContactData = {
  name: string;
  phone: string;
  email: string;
  primary?: boolean;
  countryCode?: string;
  id?: string;
};

type ComponentProps = {
  control: any;
  openSidePane: boolean;
  editMode: boolean;
  editFcrmContent: any;
  register: any;
  setValue: any;
  setError: any;
  getValues: any;
  setReloadOnSidePaneCLose: any;
  editContactId: string;
  setEditContactId: any;
  allNonPrimaryContact: string;
  isRoleAdmin: boolean;
};

const ContactDetails = ({
  control,
  editMode,
  editFcrmContent,
  register,
  setValue,
  setError,
  getValues,
  setReloadOnSidePaneCLose,
  editContactId,
  setEditContactId,
  allNonPrimaryContact,
  isRoleAdmin,
}: ComponentProps) => {
  const classes = styles();
  const [contactData, setContactData] = useState<ContactData[]>([]);
  const [loaders, setLoaders] = useState<Record<string, boolean>>({});
  const [deleteContactId, setDeleteContactId] = useState<string>('');
  const [primaryContact, setPrimaryContact] = useState<any>(0);
  const [isDeleteContactLoading, setIsDeleteContactLoading] = useState<boolean>(
    false,
  );

  useEffect(() => {
    setContactData([
      {
        name: '',
        phone: '',
        email: '',
        countryCode: '+1',
      },
    ]);
    setValue(`countryCode_${0}`, '+1');
  }, []);

  useEffect(() => {
    if (editFcrmContent.contacts) {
      const sortedItems = sortContacts(editFcrmContent.contacts);
      setContactData(sortedItems);
      const primaryContactIndex = (sortedItems || []).findIndex(
        (item: any) => item.primary,
      );
      setPrimaryContact(primaryContactIndex);
    }
  }, [editFcrmContent]);

  useEffect(() => {
    contactData.map((item: any, i: number) => {
      if (item.id) setValue(`id_${i}`, item.id);
      setValue(`name_${i}`, item.name);
      setValue(`email_${i}`, item.email);
      if (get(item, 'phone')) {
        const { code, phone } = countryCodeNadPhoneDefaultValue(item);
        setValue(`countryCode_${i}`, code);
        setValue(`phone_${i}`, phone);
      } else {
        setValue(`countryCode_${i}`, '+1');
      }

      setValue(`primary_${i}`, item.primary);
    });
    const primaryContactIndex = (contactData || []).findIndex(
      (item: any) => item.primary,
    );
    setPrimaryContact(primaryContactIndex);
  }, [contactData]);

  const sortContacts = (array: Array<any>) => {
    const s = array.sort(
      (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt),
    );

    return s;
  };
  const addNewContact = () => {
    const contactDataNew: ContactData[] = contactData.map(
      (item: any, i: number) => {
        if (getValues(`id_${i}`)) item.id = getValues(`id_${i}`);
        item.name = getValues(`name_${i}`);
        item.email = getValues(`email_${i}`);
        item.phone = getValues(`phone_${i}`);
        item.countryCode = getValues(`countryCode_${i}`) || '+1';
        item.primary = getValues(`primary_${i}`);
        return item;
      },
    );
    const contactsLength = (contactDataNew || []).length;
    setContactData([
      ...contactDataNew,
      {
        name: '',
        phone: '',
        countryCode: '+1',
        email: '',
      },
    ]);

    setValue(`countryCode_${contactsLength}`, '+1');
  };

  const saveContactOnEdit = (contact: any, index: number) => {
    if (editMode) {
      let isEmailValid = true;
      let isPhoneValid = true;
      let noDuplicates = true;
      const name = getValues(`name_${index}`);
      const email = getValues(`email_${index}`);
      const phone = getValues(`phone_${index}`);
      const code = getValues(`countryCode_${index}`);
      if (!name) {
        setError(`name_${index}`, {
          type: 'custom',
          message: 'This field is required',
        });
      }
      if (!email) {
        setError(`email_${index}`, {
          type: 'custom',
          message: 'This field is required',
        });
      }

      if (email && !email.match(emailRegex)) {
        isEmailValid = false;
        setError(`email_${index}`, {
          type: 'custom',
          message: 'Enter a valid email id',
        });
      }
      if (phone && !phone.match(phoneRegex)) {
        isPhoneValid = false;
        setError(`phone_${index}`, {
          type: 'custom',
          message: 'Enter a valid Mobile No',
        });
      }
      contactData.map((item: any, i: number) => {
        if (item.id && i !== index && phone && item.phone === phone) {
          noDuplicates = false;
          setError(`phone_${index}`, {
            type: 'custom',
            message: 'Phone No. already added',
          });
        }
        if (item.id && i !== index && item.email === email) {
          noDuplicates = false;
          setError(`email_${index}`, {
            type: 'custom',
            message: 'Email already added',
          });
        }
      });

      if (email && isEmailValid && isPhoneValid && name && noDuplicates) {
        const request = {
          ...contact,
          name,
          email,
          phone: phone ? `${code}#${phone}` : '',
        };
        request.fcrmId = get(editFcrmContent, 'id');
        setLoaders({ [index]: true });
        createUpdateFcrmContact(request)
          .then((res: any) => {
            setContactData([...sortContacts(res)]);
            setReloadOnSidePaneCLose(true);
          })
          .catch((e) => {
            const err = e?.response?.data?.message;

            if (Array.isArray(err) && !isEmpty(err)) {
              if (index !== -1) {
                setError(`${err[0]}_${index}`, {
                  type: 'custom',
                  message: `${capitalizeFirstLetter(err[0])} already exist`,
                });
              }
            }
          })
          .finally(() => setLoaders({ [index]: false }));
      }
    }

    // createUpdateFcrmContact()
  };

  const deleteContact = () => {
    setIsDeleteContactLoading(true);
    deleteFcrmContact({
      id: deleteContactId,
      fcrmId: get(editFcrmContent, 'id'),
    })
      .then((res: any) => {
        setContactData([...sortContacts(res)]);
        setReloadOnSidePaneCLose(true);
      })
      .finally(() => {
        setDeleteContactId('');
        setIsDeleteContactLoading(false);
      });
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    let text = e?.clipboardData?.getData('text/plain');
    if (text) {
      text = text.replace(/\s/g, '');
    }
    document.execCommand('insertHTML', false, text);
  };

  const countryCodeNadPhoneDefaultValue = (data: Record<string, any>) => {
    let code;
    let phone;
    if (get(data, 'phone') && get(data, 'phone').includes('#')) {
      code = (get(data, 'phone') || '').split('#')[0] || '+1';
      phone = (get(data, 'phone') || '').split('#')[1];
    } else {
      code = '+1';
      phone = get(data, 'phone');
    }
    return {
      code,
      phone,
    };
  };
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Contact Details
      </Typography>

      <Box className={classes.contactDetailsForm}>
        {contactData.map((data: ContactData, index: number) => (
          <Grid xs={12} container key={`contact-${index}`}>
            {data.id && (
              <Box className={classes.hideField}>
                <InputField
                  label=""
                  control={control}
                  disabled={isRoleAdmin}
                  classes={classes}
                  placeholder="id"
                  {...register(`id_${index}`, {
                    required: 'This field is required',
                    value: data.id,
                  })}
                />
              </Box>
            )}

            <Grid item xs={3} className={classes.contactInputBox}>
              <InputField
                label=""
                control={control}
                disabled={isRoleAdmin}
                classes={classes}
                onBlurField={() => setEditContactId('')}
                inputRef={(input: any) =>
                  editContactId === data.id && input?.focus()
                }
                // defaultValue={data.name}
                placeholder="Name"
                {...register(`name_${index}`, {
                  required: 'This field is required',
                  value: data.name,
                })}
              />
            </Grid>
            <Grid item xs={4} container className={classes.contactInputBox}>
              <Grid xs={12} className={classes.vCountryMobileSection}>
                <Grid className={classes.vCountryCode}>
                  <SelectInputField
                    label=""
                    {...register(`countryCode_${index}`)}
                    control={control}
                    disabled={isRoleAdmin}
                    classes={classes}
                    dropdowns={countryCodeList}
                  />
                </Grid>
                <Grid className={classes.vMobileLeftSpacing}>
                  <InputField
                    label=""
                    control={control}
                    classes={classes}
                    disabled={isRoleAdmin}
                    {...register(`phone_${index}`, {
                      value: data.phone,
                      pattern: {
                        value: phoneRegex,
                        message: 'Enter a valid Mobile No',
                      },
                    })}
                    placeholder="Mobile No."
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.contactInputBox}>
              <InputField
                label=""
                control={control}
                inputProps={{
                  inputProps: {
                    onPaste: (e: any) => handlePaste(e),
                  },
                }}
                disabled={isRoleAdmin}
                classes={classes}
                {...register(`email_${index}`, {
                  required: 'This field is required',
                  pattern: {
                    value: emailRegex,
                    message: 'Enter a valid email id',
                  },
                  value: data.email,
                })}
                placeholder="Email"
              />
            </Grid>

            <Grid xs={3} container>
              <Grid item xs={4} className={classes.primaryBox}>
                <Typography variant="subtitle2" className={classes.tinyText}>
                  Primary
                </Typography>
                <Box className={classes.hideField}>
                  <InputField
                    label=""
                    control={control}
                    classes={classes}
                    {...register(`primary_${index}`, {
                      value: data.primary,
                    })}
                    placeholder="Email"
                  />
                </Box>
                <Switch
                  disabled={isRoleAdmin}
                  classes={{
                    root: classes.switchRoot,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                  checked={primaryContact === index}
                  onChange={(val: any) => {
                    const checked = val.target.checked;
                    if (!checked) return;
                    if (val.target.checked) setPrimaryContact(index);
                    const newContacts = contactData.map((item, i) => {
                      if (i === index) {
                        item.name = getValues(`name_${i}`);
                        item.email = getValues(`email_${i}`);
                        item.phone = getValues(`phone_${i}`);
                        item.primary = true;
                      } else {
                        item.name = getValues(`name_${i}`);
                        item.email = getValues(`email_${i}`);
                        item.phone = getValues(`phone_${i}`);
                        item.primary = false;
                      }
                      return item;
                    });
                    setContactData([...newContacts]);
                  }}
                  name={`primary_${index}`}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Grid>

              {!isRoleAdmin && editMode && (
                <Grid item xs={3}>
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    className={classes.iconSaveButton}
                  >
                    {!get(loaders, index) && (
                      <SaveIcon
                        onClick={() => saveContactOnEdit(data, index)}
                      />
                    )}
                    {get(loaders, index) && <Loader size={20} />}
                  </IconButton>
                </Grid>
              )}
              {!isRoleAdmin &&
                !data.primary &&
                contactData &&
                contactData.length > 1 && (
                  <Grid item xs={4}>
                    <IconButton>
                      <ClearIcon
                        onClick={() => {
                          if (!get(data, 'id')) {
                            contactData.splice(index, 1);
                            setContactData([...contactData]);
                          } else {
                            setDeleteContactId(get(data, 'id') || '');
                          }
                        }}
                      />
                    </IconButton>
                  </Grid>
                )}
            </Grid>
          </Grid>
        ))}
        {!isRoleAdmin && (
          <Box className={classes.backContinueBox}>
            <Button
              name="+ Additional Contact"
              type="button"
              onClick={addNewContact}
              className={classes.investorPrimaryBtn}
            />
          </Box>
        )}
        <Box className={classes.primaryContactErr}>{allNonPrimaryContact}</Box>
      </Box>
      <FCRMDeleteContactDialog
        open={!isEmpty(deleteContactId)}
        onOk={deleteContact}
        onClose={() => setDeleteContactId('')}
        onCancel={() => setDeleteContactId('')}
        isLoading={isDeleteContactLoading}
      />
    </Box>
  );
};

export default ContactDetails;
