import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { FN } from 'common/types';

interface Props {
  id?: string;
  label?: string;
  value: any;
  defaultValue?: any;
  onChange: FN;
  onTextChange?: FN;
  options: any[];
  getOptionLabel?: FN;
  getOptionSelected: FN;
  noOptionsText?: any;
  className?: string;
  placeholder?: string;
  InputLabelProps?: any;
  loading?: any;
  loadingText?: any;
  InputProps?: any;
  variant?: 'standard' | 'filled' | 'outlined';
  size?: 'small' | 'medium';
  error?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
  groupBy?: FN;
}

const WrappedAutocomplete = ({
  id,
  label,
  placeholder,
  value,
  defaultValue,
  onChange,
  onTextChange,
  options,
  getOptionLabel,
  loading,
  loadingText,
  noOptionsText,
  getOptionSelected,
  className,
  InputProps,
  InputLabelProps,
  variant,
  error,
  disabled,
  disableClearable,
  size,
  ...props
}: Props) => {
  return (
    <Autocomplete
      id={id}
      autoComplete
      disableClearable={disableClearable}
      options={options}
      value={value}
      loading={loading}
      noOptionsText={noOptionsText}
      loadingText={loadingText}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={onChange}
      disabled={disabled}
      size={size}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={label}
          margin="normal"
          onChange={onTextChange}
          placeholder={placeholder}
          variant={variant ? variant : 'standard'}
          error={error}
          className={className}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            // type: 'search', // TODO: Need to figure out why needed
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          InputLabelProps={InputLabelProps}
        />
      )}
      {...props}
    />
  );
};
export default WrappedAutocomplete;
