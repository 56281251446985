import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  uploadCont: {
    maxWidth: 30,
    height: 30,
    minHeight: 30,
    margin: '0 auto',
    display: 'flex',
    border: '1px solid #ccc',
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    borderRadius: 6,
    minHeight: 50,
    width: 155,
    padding: 0,
    '& .MuiDropzoneArea-root': {
      width: 50,
      minHeight: 0,
      padding: 0,
    },
    '& .MuiDropzoneArea-textContainer': {
      // width: '100%',
      textAlign: 'center',
      display: 'flex',
    },
    '& .MuiDropzoneArea-text': {
      margin: 0,
      fontSize: 16,
      alignSelf: 'center',
      marginRight: 8,
    },
    '& .MuiDropzoneArea-icon': {
      width: 45,
      height: 45,
    },
    '& .MuiGrid-container': {
      marginLeft: 20,
    },
    '& .MuiDropzonePreviewList-removeButton': {
      top: '35%',
      left: '35%',
    },
    '& .MuiDropzonePreviewList-image': {
      width: 30,
    },
  },
  uploadAvatar: {
    position: 'relative',
    // height: 48,
    display: 'flex',
    width: 100,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingRight: 10,
  },
  uploadImg: {
    width: 60,
    height: 55,
    borderRadius: 6,
    border: '1px solid #1EA59A',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  offIcon: {
    position: 'absolute',
    top: -8,
    left: 52,
    fill: theme.palette.error.dark,
    cursor: 'pointer',
  },
  fileNameText: {
    fontSize: 11,
    margin: 0,
    width: 'inherit',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // paddingRight: 35,
  },
  item: { padding: 10, marginTop: 10 },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
  },
  image: {
    height: '55px',
    width: 62,
    maxWidth: '100%',
    // color: palette.text.primary,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    // borderRadius: shape.borderRadius,
    zIndex: 5,
    opacity: 1,
  },
  uploadIcon: {
    height: 16,
    width: 16,
    alignSelf: 'center',
    cursor: 'pointer',
  },
  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
}));
