import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  mainComponentContainer: {
    height: 'calc(100vh - 20px)',
    padding: '30px 30px 30px 20px',
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  mainComponentContent: {},
  mainHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '45px',
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (max-width: 1024px)': {
      fontSize: 24,
      lineHeight: '35px',
      marginTop: 15,
    },
    '@media (max-width: 767px)': {
      fontSize: 20,
      lineHeight: '30px',
      marginBottom: 0,
      marginTop: 0,
    },
  },
  aiPoweredBox: {
    display: 'flex',
    alignItems: 'center',
    width: '110px',
    height: '20px',
    '& img': {
      height: 24,
      width: 22,
    },
    backgroundColor: theme.palette.primary.main,
    '-webkit-clip-path':
      'polygon(0px 0%, 0% 0%, calc(100% - 20px) 0%, 70% 50%, calc(100% - 20px) 100%, 0% 100%)',
  },
  poweredText: {
    color: theme.palette.common.white,
    fontSize: 12,
  },
  rightBox: {
    display: 'flex',
    '@media (max-width: 600px)': {
      paddingTop: 16,
    },
  },
  searchBox: {
    borderRadius: 30,
    border: '1px solid #E5E5E5',
    marginLeft: 0,
    width: '70%',
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  clearIcon: {
    padding: 5,
  },
  inputSearch: {
    width: 'calc(100% - 10px)',
    paddingLeft: 14,
  },
  // TAB
  actionTabPanel: {
    height: 'calc(100vh - 100px)',
    overflowX: 'hidden',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiBox-root': {
      height: '100%',
    },
  },
  tabContainer: {
    display: 'flex',
    marginTop: 15,
  },
  activeDealsBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 30,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 230,
    fontSize: 16,
    lineHeight: '16px',
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 13,
      lineHeight: '16px',
    },
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.warning.contrastText,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
    borderBottom: '0.5px solid' + theme.palette.grey[50],
    '&:last-child': {
      border: 'none',
    },
  },
  prospectiveMenu: {
    '& .MuiMenu-paper': {
      marginTop: 50,
      borderRadius: 20,
      boxShadow: theme.shadows[2],
      minWidth: 210,
    },
    '& .MuiMenuItem-root': {
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      padding: '8px 15px',
      color: theme.palette.common.black,
      marginTop: 0,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  tabBox: {
    maxWidth: 'calc(100% - 400px)',
  },
  addBtnBox: {
    height: 48,
    width: 48,
    backgroundColor: '#E8F6F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addIcon: {
    fontSize: 25,
    color: '#1EA59A',
  },
  notifyUserBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightBtnBox: {
    display: 'flex',
    position: 'absolute',
    right: 30,
    '@media (max-width: 600px)': {
      paddingTop: 16,
    },
  },
  notifyUserBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 138,
    fontSize: 16,
    lineHeight: '16px',
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      fontSize: 13,
      lineHeight: '16px',
    },
  },
}));
