import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const ventureDebtSchema = yup.object().shape({
  amountConversionToVdType: yup.string().required(requiredConstant),
  expectedOrPaidAmount: yup.string().required(requiredConstant),
  expectedOrRaisedAmount: yup.string().required(requiredConstant),
  postMoneyValuation: yup.string().required(requiredConstant),
  expectedFundUtilization: yup.string().required(requiredConstant),
});
