import * as yup from 'yup';
import moment from 'moment';

import {
  requiredConstant,
  amountValidConstant,
  futureDateConstant,
  dateValidConstant,
  positiveConstant,
} from 'common/utils/constants';

export const loanClosureClosedSchema = yup.object().shape({
  loanAmount: yup.string(),
  principalAmountPaid: yup
    .number()
    .min(0, positiveConstant)
    .typeError(amountValidConstant)
    .required(requiredConstant)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .moreThan(
      yup.ref('loanAmount'),
      'Amount cannot be less then the loan amount',
    ),

  startupId: yup.string().required(requiredConstant),
  status: yup.string().required(requiredConstant),
});

export const loanClosureConversionSchema = yup.object().shape({
  remainingAmount: yup.number(),
  // amountToBeConverted: yup
  //   .number()
  //   .typeError(amountValidConstant)
  //   .required(requiredConstant),

  amountToBeConverted: yup
    .number()
    .typeError(amountValidConstant)
    .required(requiredConstant)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .oneOf(
      [yup.ref('remainingAmount')],
      'Converted amount should be equals to remaining amount',
    ),

  repaidInCash: yup
    .number()
    .typeError(amountValidConstant)
    .required(requiredConstant),
  conversionValue: yup
    .number()
    .typeError(amountValidConstant)
    .required(requiredConstant),
  startupId: yup.string().required(requiredConstant),
  status: yup.string().required(requiredConstant),
});

export const loanClosureExtensionSchema = yup.object().shape({
  newDeadLine: yup
    .date()
    .min(moment(), futureDateConstant)
    .typeError(dateValidConstant)
    .required(requiredConstant),
  additionalBonusSafe: yup
    .number()
    .typeError(amountValidConstant)
    .required(requiredConstant),
  startupId: yup.string().required(requiredConstant),
  status: yup.string().required(requiredConstant),
});
