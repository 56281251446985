import React from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { CircularProgress } from '@material-ui/core';

import { Box } from 'components';
// import { getActionsList } from 'redux-modules/global/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { ADMIN_VIEW } from 'common/utils/constants';

import UploadDocuments from './UploadDocuments';
import UploadSignDocuments from './UploadSignDocuments';
import NewEnquiry from '../NewEnquiry';
import styles from '../styles';
import VcReview from '../VcReview';
import InvestorReview from '../InvestorReview';
import LoanCloser from '../LoanCloser';
type Props = {
  status: string;
};

const env = process.env.REACT_APP_ENV || 'dev';

const CompletedActions = (props: Props) => {
  const classes = styles();
  const { status } = props;
  const actionsListState = useSelector(
    ({ global }: RootState) => global.actionsList,
  );

  const { dashboardStartupView } = useSelector(
    ({ global }: RootState) => global,
  );

  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  const isRoleAdmin = role === 'ADMIN';
  const actionsList = get(actionsListState, 'data', []);
  const getActions = () => {
    const isActionsListLoading = get(actionsListState, 'isLoading', false);
    if (isActionsListLoading) {
      return (
        <Box className={classes.loader}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      );
    } else if (!isEmpty(actionsList)) {
      const filteredList =
        dashboardStartupView === ADMIN_VIEW.STARTUP
          ? actionsList.filter(
              (item: any) =>
                item.status === status && item.actionType === 'STARTUP',
            )
          : dashboardStartupView === ADMIN_VIEW.INVESTOR
          ? actionsList.filter(
              (item: any) =>
                item.status === status && item.actionType === 'INVESTOR',
            )
          : dashboardStartupView === ADMIN_VIEW.VC_ADMIN
          ? actionsList.filter(
              (item: any) =>
                item.status === status && item.actionType === 'VC_FIRM',
            )
          : '';
      if (!isEmpty(filteredList)) {
        return filteredList.map((item: any) =>
          getActionContext(item.name, item, status),
        );
      }
    }
  };

  const getActionContext = (
    actionType: string,
    actionItem: any,
    status: string,
  ) => {
    switch (true) {
      case actionType === 'UPLOAD_DOCUMENTS':
        return <UploadDocuments />;
      case actionType === 'REQUEST_BRIDGE_LOAN_SIGN_DOC':
        return <UploadSignDocuments />;
      case actionType === 'REQUEST_VD_LOAN_SIGN_DOC':
        return <UploadSignDocuments />;
      case actionType === 'INVESTOR_REVIEW':
        return <InvestorReview key={actionItem.id} data={actionItem} />;
      case actionType === 'NEW_ENQUIRY':
        if (actionsList && actionsList.length > 0) {
          return <NewEnquiry key={actionItem.id} data={actionItem} />;
        }
        break;
      case env !== 'prod' &&
        [
          'LOAN_CONVERSION',
          'LOAN_EXTENSION',
          'SAFE_CAPPED',
          'SAFE_UNCAPPED',
          'PRICE_ROUND',
        ].includes(actionType):
        return (
          <LoanCloser
            key={actionItem.id}
            data={actionItem}
            completedAction={true}
            isRoleAdmin={isRoleAdmin}
          />
        );
      case actionType === 'VC_FIRM_SIGNUP':
        return (
          <VcReview key={actionItem.id} data={actionItem} status={status} />
        );
      case actionType === 'VC_FIRM_REVIEW':
        return (
          <VcReview key={actionItem.id} data={actionItem} status={status} />
        );
      case actionType === 'VC_FIRM_FUND_REVIEW':
        return (
          <VcReview key={actionItem.id} data={actionItem} status={status} />
        );
      case actionType === 'ANGEL_INVESTOR_REVIEW':
        return (
          <VcReview key={actionItem.id} data={actionItem} status={status} />
        );
      case actionType === 'YARDSTICK_USER_REVIEW':
        return (
          <VcReview key={actionItem.id} data={actionItem} status={status} />
        );
      default:
        return null;
    }
  };

  return <Box className={classes.pendingBox}>{getActions()}</Box>;
};

export default CompletedActions;
