import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';

import { Dialog, RichTextEditor, Button, WrappedTextInput } from 'components';
import { NOTIFY_USER } from 'common/utils/constants';
import { sendVcAdminDealEmailToVcUsers } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';

const NotifyUser = ({ isOpen, setIsOpen }: any) => {
  const classes = styles();

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = (data: any) => {
    setIsLoading(true);
    sendVcAdminDealEmailToVcUsers(data)
      .then(() => {
        setIsOpen(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  React.useEffect(() => {
    setValue('emailSubject', NOTIFY_USER.initEmailSubject);
    setValue('emailContent', NOTIFY_USER.initEmail);
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth={'md'}
      subheading={''}
      title={'Email Users'}
      handleClose={() => setIsOpen(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="emailSubject"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <WrappedTextInput
                type="text"
                placeholder=""
                className={classes.textValNotifyInput}
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                value={value}
                onChange={onChange}
                error={error?.message ? true : false}
              />
              {error?.message && (
                <span className={classes.errorText}>{error?.message}</span>
              )}
            </>
          )}
        />

        <Controller
          name="emailContent"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <RichTextEditor
                value={value}
                purpose="edit"
                onChange={onChange}
              />
              {error?.message && <span>{error?.message}</span>}
            </>
          )}
        />
        {errorMessage && (
          <Typography className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        )}
        <Box className={classes.nextPrevNotifyButtonBox}>
          <Button
            type="submit"
            className={classes.sendButton}
            name="Send"
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Button
            variant="outlined"
            className={classes.prevNotifyButton}
            name="Cancel"
            disabled={isLoading}
            onClick={() => setIsOpen(false)}
          />
        </Box>
      </form>
    </Dialog>
  );
};

export default NotifyUser;
