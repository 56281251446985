import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputAdornment,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty } from 'lodash';

import {
  WrappedTextInput,
  Button,
  Loader,
  WrappedPriceInput,
} from 'components';
import { waitImg } from 'assets';
import { RootState } from 'redux-modules/store/rootState';
import { StepProps } from 'common/types';
import {
  getStartupDefaultTerms,
  agreeLoanTerms,
  getStartupTerms,
  scheduleMeeting,
  createStartupTerms,
} from 'services';
import {
  errorMessageHandler,
  getParsedPriceInputValue,
} from 'common/utils/helpers';

import { LoanTermsSchema } from '../validations';
import styles from '../styles';

const LoanTerms = ({ scheduled, setScheduled, handleNext }: StepProps) => {
  const classes = styles();
  const [showMfn, setShowMfn] = useState<boolean>(false);

  const { handleSubmit, control, getValues, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(LoanTermsSchema),
  });

  const { startupInfo } = useSelector(({ Enquiries }: RootState) => Enquiries);
  // const { startupDetails } = useSelector(
  //   ({ EnquiryForm }: RootState) => EnquiryForm,
  // );
  const { bridgeLoanProcess } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const ycBatch = get(startupInfo, 'ycBatch');

  useEffect(() => {
    const disableMfnBactch = process.env.REACT_APP_MFN_DISABLE_BATCHES;
    if (disableMfnBactch) {
      const disableMfnBactchList = disableMfnBactch.split(',');

      setShowMfn(!disableMfnBactchList.includes(ycBatch));
    }
  }, [ycBatch]);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [startupDefaultTerms, setStartupDefaultTerms] = React.useState<any>([]);
  const [startupTerms, setStartupTerms] = React.useState<any>([]);
  const [toggleOption, setToggleOption] = React.useState<string>('');
  const [helpButton, setShowHelpButton] = React.useState<boolean>(false);
  const [
    agreeLoanTermLoading,
    setAgreeLoanTermLoading,
  ] = React.useState<boolean>(false);
  const [loanTermPayload, setLoanTermPayload] = React.useState<any>({});

  const handleOptionChange = (event: React.ChangeEvent<any>) => {
    const value = (event.target as any).value;
    setToggleOption(value);
  };

  const onSubmit = () => {
    setErrorMessage('');
    setAgreeLoanTermLoading(true);
    const data = getValues();
    const obj = {
      fundType: 'BRIDGE_LOAN',
      loanType: toggleOption,
      loanAmount: getParsedPriceInputValue(get(data, 'loanAmount')),
      loanTenure: get(data, 'loanTenure'),
      percentageBonusSafe: get(data, 'percentageBonusSafe'),
      safeCover: getParsedPriceInputValue(get(data, 'safeCover')),
      cashInterestRate: get(data, 'cashInterestRate'),
      processingFees: get(data, 'processingFees'),
      valuation: getParsedPriceInputValue(get(data, 'valuation')),
    };
    agreeLoanTerms(obj)
      .then((res) => {
        if (!isEmpty(res)) {
          handleNext();
          setAgreeLoanTermLoading(false);
        }
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setAgreeLoanTermLoading(false);
      });
  };

  const getStartupDefaultLoanTerms = () => {
    setIsLoading(true);
    setErrorMessage('');
    getStartupDefaultTerms('BRIDGE_LOAN')
      .then((res: any) => {
        if (!isEmpty(res)) {
          setStartupDefaultTerms(res);
        } else {
          setErrorMessage('Default loan term not found.');
        }
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const getStartupLoanTerms = () => {
    setIsLoading(true);
    setErrorMessage('');
    getStartupTerms('BRIDGE_LOAN')
      .then(async (res: any) => {
        if (isEmpty(res)) {
          const createdTerm = await createStartupTerms('BRIDGE_LOAN');
          setStartupTerms(createdTerm);
          setIsLoading(false);
        } else {
          setStartupTerms(res);
          setIsLoading(false);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const setFormValue = (loanType: string, data: any) => {
    setValue('loanType', loanType);
    setValue('loanAmount', get(data, 'loanAmount'));
    setValue('loanTenure', get(data, 'loanTenure'));
    setValue('percentageBonusSafe', get(data, 'percentageBonusSafe'));
    setValue('safeCover', get(data, 'safeCover'));
    setValue('processingFees', get(data, 'processingFees'));
    setValue('valuation', get(data, 'valuation'));
    setValue('cashInterestRate', get(data, 'cashInterestRate'));
  };

  const isCalendlyEvent = (e: any) => {
    return (
      e.origin === 'https://calendly.com' &&
      e.data.event &&
      e.data.event.indexOf('calendly.') === 0
    );
  };

  React.useEffect(() => {
    window.addEventListener('message', (e) => {
      if (isCalendlyEvent(e) && !isEmpty(loanTermPayload)) {
        if (e.data.event === 'calendly.event_scheduled') {
          setScheduled(true);
          scheduleMeeting(loanTermPayload);
        }
        /* Example to get the name of the event */
        // eslint-disable-next-line no-console
        console.log('Event name:', e.data.event);

        /* Example to get the payload of the event */
        // eslint-disable-next-line no-console
        console.log('Event details:', e.data.payload);
      }
    });
  }, [loanTermPayload]);

  React.useEffect(() => {
    getStartupDefaultLoanTerms();
    getStartupLoanTerms();
  }, []);

  React.useEffect(() => {
    if (isEmpty(startupTerms)) {
      // if (
      //   get(startupDetails, 'ycIncorporationCountry') ===
      //   'PENDING_INCORPORATION'
      // ) {
      //   setToggleOption('BRIDGE_TO_YC_FUND');
      // } else {
      //   setToggleOption('BRIDGE_TO_DEMO_DAY');
      // }
      setToggleOption('BRIDGE_TO_DEMO_DAY');
    } else {
      setToggleOption(get(startupTerms, 'loanType'));
    }
  }, [startupTerms]);

  React.useEffect(() => {
    if (isEmpty(startupTerms)) {
      const defaultTerm = startupDefaultTerms.find(
        (term: any) => term.loanType === toggleOption,
      );
      setFormValue(toggleOption, defaultTerm);
    } else {
      setFormValue(toggleOption, startupTerms);
    }
  }, [startupDefaultTerms, startupTerms, toggleOption]);

  React.useEffect(() => {
    if (helpButton) {
      const scrollContact: any = document.getElementById(
        'contact-us-loan-term',
      );
      scrollContact?.scrollIntoView();
    }
  }, [helpButton]);

  return (
    <div>
      <Typography variant="h2" className={classes.detailsText}>
        Loan Terms
      </Typography>
      {scheduled && (
        <div className={classes.message}>
          “Thank you for contacting us! Your meeting with 8vdX team is
          scheduled. You can process your application forward after your
          discussion with the 8vdX team”
        </div>
      )}
      {scheduled && <img src={waitImg} alt={waitImg} width="100%" />}
      {isLoading && <Loader />}
      {errorMessage && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.errorMessage}
        >
          {errorMessage}
        </Typography>
      )}

      {!isLoading && !errorMessage && !scheduled && (
        <form
          data-testid="loanTermsForm"
          onSubmit={handleSubmit(() => {
            !get(bridgeLoanProcess, 'documentsSigned')
              ? onSubmit()
              : handleNext();
          })}
          className={classes.validateForm}
        >
          <FormControl>
            <RadioGroup
              row={true}
              value={toggleOption}
              onChange={handleOptionChange}
              className={classes.radioGroup}
            >
              {startupDefaultTerms.map((defaultTerm: any) => (
                <FormControlLabel
                  key={get(defaultTerm, 'id')}
                  value={get(defaultTerm, 'loanType')}
                  control={<Radio />}
                  label={get(defaultTerm, 'loanTitle')}
                  className={classes.formControlText}
                  disabled={
                    get(defaultTerm, 'loanType') === 'BRIDGE_TO_YC_FUND' ||
                    get(startupTerms, 'acknowledgedByStartup') ||
                    get(bridgeLoanProcess, 'documentsSigned')
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Loan Amount
              </Typography>
              <Controller
                name="loanAmount"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedPriceInput
                      label=""
                      currencySymbol="$"
                      placeholder="Please enter loan amount"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) => {
                        const val = getParsedPriceInputValue(
                          get(e, 'target.value'),
                        );
                        const tempSafeCover =
                          (val * getValues('percentageBonusSafe')) / 100;
                        setValue('safeCover', tempSafeCover, {
                          shouldValidate: true,
                        });
                        onChange(e);
                      }}
                      error={error?.message ? true : false}
                      disabled={
                        get(startupTerms, 'acknowledgedByStartup') ||
                        get(bridgeLoanProcess, 'documentsSigned')
                      }
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Loan Tenure (Months)
              </Typography>
              <Controller
                name="loanTenure"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="number"
                      placeholder="Please enter tenure"
                      className={classes.textValInput}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Percentage Bonus SAFE
              </Typography>
              <Controller
                name="percentageBonusSafe"
                defaultValue=""
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="number"
                      label=""
                      placeholder="Please enter percentage"
                      className={classes.textValInput}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: { root: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) => {
                        const tempSafeCover =
                          (get(e, 'target.value') * getValues('loanAmount')) /
                          100;
                        setValue('safeCover', tempSafeCover, {
                          shouldValidate: true,
                        });
                        onChange(e);
                      }}
                      error={error?.message ? true : false}
                      disabled
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                SAFE Cover
              </Typography>
              <Controller
                name="safeCover"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedPriceInput
                      label=""
                      currencySymbol="$"
                      placeholder="SAFE Cover"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled={true}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Cash Interest Rate
              </Typography>
              <Controller
                name="cashInterestRate"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="number"
                      placeholder="Please enter rate"
                      className={classes.textValInput}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: { root: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Processing Fees
              </Typography>
              <Controller
                name="processingFees"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="number"
                      placeholder="Please enter processing fees"
                      className={classes.textValInput}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: { root: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            {showMfn && toggleOption === 'BRIDGE_TO_DEMO_DAY' && (
              <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Valuation for converting into SAFE (with MFN)
                </Typography>
                <Controller
                  name="valuation"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedPriceInput
                        label=""
                        currencySymbol="$"
                        placeholder="Please enter amount"
                        className={classes.textValInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
            )}
          </Grid>
          {/* <Typography
            variant="body1"
            gutterBottom
            className={classes.pointText}
          >
            <a
              href={`/startup/deal-explanation?ycbatch=${ycBatch}&type=${toggleOption}`}
              target="_blank"
              rel="noreferrer"
              className={classes.linkHere}
            >
              Here{' '}
            </a>
            &nbsp;is a simple explanation and a detailed term sheet of the deal.
          </Typography> */}
          <Grid container style={{ justifyContent: 'left' }}>
            <Grid item md={6} sm={6}>
              <Button
                className={classes.continueBtnBox}
                isLoading={agreeLoanTermLoading}
                name="Agree and Continue"
                type="submit"
              />
            </Grid>
          </Grid>
          {!get(bridgeLoanProcess, 'documentsSigned') && (
            <Grid container style={{ justifyContent: 'left' }}>
              <Grid item md="auto">
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.pointText}
                >
                  <strong className={classes.strongText}>
                    Have a question for us?
                  </strong>
                  <Button
                    variant="text"
                    name="Contact Us"
                    size="large"
                    onClick={() => setShowHelpButton(true)}
                    className={classes.contactUsText}
                  />
                </Typography>
              </Grid>
            </Grid>
          )}

          {helpButton && (
            <Grid container justifyContent="center" id="contact-us-loan-term">
              <Button
                name="Schedule a Meeting"
                variant="outlined"
                onClick={handleSubmit(() => {
                  const loanTermsPayload: any = {
                    ...getValues(),
                    fundType: 'BRIDGE_LOAN',
                    loanType: toggleOption,
                  };
                  setLoanTermPayload(loanTermsPayload);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  window.Calendly.initPopupWidget({
                    url: process.env.REACT_APP_CALENDLY_LINK,
                  });
                })}
              />

              <div className={classes.dummyBox} />
              <Button
                name="Send us a Message"
                variant="outlined"
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  window.Intercom('show');
                }}
              />
            </Grid>
          )}
        </form>
      )}
    </div>
  );
};

export default LoanTerms;
