import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  //   DashBoard page Right styling ------- start

  dashWelRightContent: {
    padding: '90px 35px 50px',
    '@media (max-width: 767px)': {
      padding: '60px 5% 30px',
    },
  },
  dashWelRightContBox: {
    background: theme.palette.success.light,
    borderRadius: 15,
    padding: 20,
    paddingTop: 75,
    position: 'relative',
  },
  illustateImg: {
    position: 'absolute',
    width: 84.15,
    height: 72.43,
    top: -15,
  },
  iIlustraImg: {
    position: 'absolute',
    width: 52.25,
    height: 90,
    top: -33,
    right: 20,
  },
  ticketText: {
    fontSize: 14.5,
    fontWeight: 600,
    letterSpacing: 0.34,
    color: theme.palette.grey[400],
    marginBottom: 8,
  },
  ticketSubText: {
    marginBottom: 25,
    color: theme.palette.grey[50],
    fontSize: 11.5,
    letterSpacing: 0.43,
  },
  showBtn: {
    background: theme.palette.grey[100],
    height: 42,
    width: '100%',
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  //   DashBoard page Right styling ------- End

  //   DashBoard Value Box dashboard styling ------- start

  dashValuBox: {
    border: '1px solid #E5E5E5',
    borderRadius: 4,
    padding: '12px 20px',
    marginBottom: 15,
    '@media (max-width: 1120px)': {
      marginBottom: 0,
    },
  },
  valueNo: {
    marginBottom: 7,
    fontSize: 20,
    color: theme.palette.warning.contrastText,
  },
  valueText: {
    fontSize: 13,
    color: theme.palette.grey.A400,
  },
  companiestext: {
    color: theme.palette.grey.A200,
    fontSize: 13,
    paddingLeft: 15,
  },
  //   DashBoard Value Box dashboard styling ------- End

  //   Action section styling -------- start

  dashActionSection: {},
  dashActiontext: {
    color: theme.palette.grey[500],
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
  },
  gridTwo: {
    textAlign: 'right',
    padding: '0 14px',
  },
  dottedicon: {
    color: theme.palette.grey.A700,
    fontSize: 35,
  },
  dividerLine: {
    background: '#DEDEDE',
    height: 2,
    margin: '0 6px',
  },

  //   Action section styling ---------------- start

  //   Dashboard Action Profile ---------------- start

  dashActionProfile: {
    paddingTop: 32,
    paddingBottom: 30,
  },
  dashActProfileBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 28,
  },
  dashActProfileLeft: {
    display: 'flex',
  },
  dashActProfiledetails: {
    paddingLeft: 14,
  },
  profileUNameText: {
    fontSize: 16,
    color: theme.palette.grey[400],
    fontWeight: 600,
    marginBottom: 5,
  },
  profileSubText: {
    fontSize: 10,
    background: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 5px',
    borderRadius: 4,
    marginLeft: 15,
  },
  profileLocationText: {
    fontSize: 13,
    color: theme.palette.grey[600],
  },
  dashActProfileRight: {
    alignSelf: 'center',
    paddingLeft: 15,
    width: 65,
    textAlign: 'right',
  },
  openText: {
    color: theme.palette.primary.main,
    fontSize: 13,
    position: 'relative',
    top: -2,
    marginRight: 3,
    cursor: 'pointer',
  },

  //   Dashboard Action Profile ---------------- End

  //  StartUP Dashboard Loan Process and Action Section ---------------- Start

  dashLoanProcess: {},
  dashLoadText: {
    fontSize: 19,
    color: theme.palette.warning.contrastText,
    letterSpacing: 0.3,
    marginTop: 15,
    marginBottom: 20,
  },
  // dashLoadSubText: {
  //   fontSize: 15,
  //   letterSpacing: 0.35,
  //   color: theme.palette.grey[600],
  //   marginBottom: 20,
  //   maxWidth: 610,
  // },
  dashBridgeLoadBox: {
    border: '1px solid #CDE8DD',
    borderRadius: 4,
    padding: '20px 20px 15px',
    marginBottom: 25,
  },
  dashBridgeSubText: {
    background: theme.palette.error.dark,
    padding: '1px 6px',
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    borderRadius: 2,
    fontSize: 13,
    marginBottom: 15,
  },
  dashBridgeContent: {
    display: 'flex',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  dashBridgeLeft: {},
  dashBridgeHeadText: {
    fontSize: 25,
    letterSpacing: 0.3,
    lineHeight: '25px',
    color: theme.palette.warning.contrastText,
    marginBottom: 2,
  },
  dashBridgeHeadSubText: {
    fontSize: 12,
    color: theme.palette.grey[50],
    opacity: 0.5,
  },
  dashBridgeCenter: {
    '@media (max-width: 1200px)': {
      // maxWidth: 380,
      maxWidth: 420,
      margin: '20px 0',
      position: 'relative',
      left: -13,
    },
  },
  stepperBox: {
    padding: 0,
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: theme.palette.info.main,
      border: '1px solid #FFD174',
      padding: 3.5,
      borderRadius: '50%',
      marginTop: -3.5,
      position: 'relative',
      zIndex: 1,
      background: theme.palette.primary.contrastText,
    },
    '& .MuiStepConnector-alternativeLabel': {
      top: 10,
      left: 'calc(-50% + 10px)',
      right: 'calc(50% + 10px)',
    },
    '& .MuiStep-horizontal': {
      '@media (max-width: 550px)': {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      '@media (max-width: 550px)': { fontSize: 9 },
    },
  },
  dashBridgeRight: {},
  actionSelectInput: {
    width: 100,
    height: 40,
    '& .MuiSelect-selectMenu': {
      color: theme.palette.primary.main,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
      top: 'calc(50% - 10.5px)',
    },
    '& .MuiOutlinedInput-root': {
      height: 37,
    },
    '& .MuiInputLabel-formControl': {
      top: -8,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 2px) scale(0.75)',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #1EA59A',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      height: 40,
    },
  },
  bridgeContent: {
    // display: 'flex',
  },
  bridgeLeft: {
    display: 'flex',
  },
  leftContent: {
    width: 'calc(100% - 100px)',
    display: 'flex',
    paddingRight: 25,
  },
  bridgeRight: {
    width: 100,
  },
  bridgeCenter: {
    maxWidth: 390,
  },
  bridgeText: {
    marginRight: 40,
    marginBottom: 20,
    alignSelf: 'baseline',
  },
  centerText: {
    margin: '15px 0',
    fontSize: 16,
    fontWeight: 600,
  },
  btHeadText: {
    fontSize: 25,
    letterSpacing: 0.3,
    lineHeight: '25px',
    color: theme.palette.warning.contrastText,
    marginBottom: 2,
  },
  btText: {
    fontSize: 17,
    letterSpacing: 0.3,
    lineHeight: '20px',
    color: theme.palette.warning.contrastText,
    marginBottom: 2,
  },
  btSubText: {
    fontSize: 12,
    color: theme.palette.grey[50],
    opacity: 0.5,
  },
  dashactionSignNowBox: {
    marginBottom: 20,
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 18,
  },
  dashSignNowText: {
    color: theme.palette.grey[900],
    fontSize: 14,
    marginBottom: 12,
  },
  signNowBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    width: 90,
    height: 30,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  dashactionUploadBox: {
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 18,
    marginBottom: 55,
  },
  dashUploadText: {
    color: theme.palette.grey[900],
    fontSize: 14,
    marginBottom: 12,
  },
  dashUploadListText: {
    fontSize: 12,
    color: theme.palette.grey[600],
    letterSpacing: 0.35,
    marginBottom: 5,
  },
  UploadBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    width: 90,
    height: 30,
    marginTop: 10,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },

  textValInput: {
    marginBottom: 25,
    width: '100%',
    marginTop: 0,
    '@media (max-width: 1120px)': {
      marginBottom: 15,
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },

  //  StartUP Dashboard Loan Process and Action Section ---------------- End

  // VentureDebt Model styling ----------------- Start

  vdModel: {
    height: '100%',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiDialog-container': {
      alignSelf: 'center',
      height: '90%',
      maxWidth: 800,
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '100%',
      margin: 0,
    },
  },
  ventureDebt: {
    height: '100%',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiDialog-container': {
      alignSelf: 'center',
      height: '90%',
      maxWidth: 700,
    },
    '& .MuiDialog-paper': {
      margin: 0,
      padding: '40px 20px 40px 45px',
      position: 'relative',
      '@media (max-width: 960px)': {
        padding: '20px 10px 30px 30px',
        maxWidth: '90%',
      },
    },
    '& .MuiTypography-h3': {
      padding: '0px 0 15px',
      color: theme.palette.grey[500],
    },
    '& .MuiTypography-h6': {
      padding: '0 0 20px',
      maxWidth: 550,
      color: theme.palette.grey[50],
      textAlign: 'left',
    },
    '& .MuiTypography-caption': {
      top: 0,
      right: 15,
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      paddingRight: 25,
      overflowX: 'hidden',
      '@media (max-width: 960px)': {
        paddingRight: 20,
      },
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#1EA59A',
        width: '0.6em',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: '#F6FAFA',
        minHeight: 24,
        border: '1px solid #1EA59A',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#2B2B2B',
      },
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: '0 -12px 0px',
      '@media (max-width: 960px)': {
        margin: '0 -12px 0px',
      },
    },
  },
  // VentureDebt Model styling ----------------- End
  hintBox: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  hintText: {
    color: theme.palette.grey[50],
    fontSize: 16,
    lineHeight: '26px',
  },
  mailToText: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 14,
    display: 'block',
    position: 'absolute',
    bottom: 0,
  },
}));
