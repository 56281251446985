import React, { useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { Delete } from '@material-ui/icons';
import { get, isEmpty } from 'lodash';
import { Alert } from '@material-ui/lab';

import { deleteFcrmProperty } from 'services';
import { Box, Button, WrappedSelect } from 'components';
import {
  investorTypes,
  sourceTypes,
  valuationChoices,
} from 'common/utils/optionList';

import { DeletePropertyDialog, FCRMClearAllDialog } from '../FCRMDialogs';
import {
  DateField,
  InputField,
  SelectInputField,
  PriceField,
} from './FormFields';
import styles from '../styles';

type ComponentProps = {
  control: any;
  openSidePane: boolean;
  editMode: boolean;
  editFcrmContent: any;
  register: any;
  setValue: any;
  showAddProperty: boolean;
  setShowAddProperty: any;
  saveOnEditMode: any;
  currency: string;
  isEmailEdit: boolean;
  setReloadOnSidePaneCLose: any;
  clearAllInvestorDetails: any;
  getValues: any;
  selectedStatus: string;
  fcrmInvestorTypes: Array<string>;
  setError: any;
  clearErrors: any;
  isLoading: boolean;
  onClose: any;
  isRoleAdmin: boolean;
  selectedStage: string;
  setSelectedStatus: any;
};
type FormFieldsObjType = { [index: string]: any };

const formFieldsObj: FormFieldsObjType = {
  expectedAmount: {
    field: 'expectedAmount',
    label: 'Typical Check Size',
    prefix: 'currency',
    defaultValue: null,
    // require: true,
    deletable: true,
    Component: PriceField,
  },
  amountInvested: {
    field: 'amountInvested',
    label: 'Amount Invested',
    prefix: 'currency',
    defaultValue: null,
    Component: PriceField,
  },
  nextFollowup: {
    field: 'nextFollowup',
    label: 'Next Followup',
    defaultValue: null, //moment().format("YYYY-MM-DD"),
    min: moment().format('YYYY-MM-DD'),
    max: moment().add(50, 'years').format('YYYY-MM-DD'),
    deletable: true,
    Component: DateField,
  },

  closingDate: {
    field: 'closingDate',
    label: 'Closing Date',
    defaultValue: null, //moment().format("YYYY-MM-DD"),
    // min: moment().format('YYYY-MM-DD'),
    max: moment().add(50, 'years').format('YYYY-MM-DD'),
    deletable: true,
    Component: DateField,
  },

  valuationCap: {
    field: 'valuationCap',
    label: 'Valuation Cap',
    prefix: 'currency',
    defaultValue: null,
    deletable: true,
    Component: PriceField,
    child: {
      type: 'select',
      label: '',
      field: 'valuationChoice',
      dropdowns: valuationChoices,
    },
  },
  discount: {
    field: 'discount',
    label: 'Discount',
    postfix: '%',
    require: false,
    type: 'number',
    deletable: true,
    defaultValue: 0,
    Component: InputField,
  },
  remarks: {
    field: 'remarks',
    label: 'Remarks',
    placeholder: 'Enter Remarks',
    inputProps: {},
    defaultValue: '',
    deletable: true,
    require: false,
    Component: InputField,
  },
  source: {
    field: 'source',
    label: 'Source',
    inputProps: {},
    defaultValue: '',
    require: true,
    // Component: InputField,
    type: 'select',
    deletable: true,
    dropdowns: sourceTypes,
  },
  probability: {
    field: 'probability',
    postfix: '%',
    label: 'Conversion Rate (%)',
    inputProps: {},
    defaultValue: 0,
    step: 0.1,
    deletable: true,
    // require: true,
    type: 'number',
    Component: InputField,
  },
  investorType: {
    field: 'investorType',
    label: 'Investor Type',
    inputProps: {},
    defaultValue: '',
    require: true,
    deletable: true,
    type: 'select',
    dropdowns: investorTypes,
  },
  askedForIntros: {
    field: 'askedForIntros',
    label: 'Asked For Intros?',
    inputProps: {},
    defaultValue: '',
    require: true,
    type: 'select',
    dropdowns: [
      { text: 'Yes', value: 'YES' },
      { text: 'No', value: 'NO' },
    ],
  },
};

const defaultFormFields = [
  'expectedAmount',
  'amountInvested',
  'nextFollowup',
  'closingDate',
  'valuationCap',
  'discount',
  'remarks',
  'source',
  'investorType',
];

const defaultPropertyDropdowns = [
  { text: 'Probability', value: 'probability' },
  { text: 'Investor Type', value: 'investorType' },
  { text: 'Source', value: 'source' },
  { text: 'Asked for Intros?', value: 'askedForIntros' },
  { text: 'Typical Check Size', value: 'expectedAmount' },
  { text: 'Amount Invested', value: 'amountInvested' },
  { text: 'Next Followup', value: 'nextFollowup' },
  { text: 'Closing Date', value: 'closingDate' },
  { text: 'Valuation Cap', value: 'valuationCap' },
  { text: 'Discount', value: 'discount' },
  { text: 'Remarks', value: 'remarks' },
];

const InvestmentDetails = ({
  control,
  editMode,
  editFcrmContent,
  register,
  setValue,
  setShowAddProperty,
  showAddProperty,
  saveOnEditMode,
  currency,
  isLoading,
  // isEmailEdit,
  setReloadOnSidePaneCLose,
  clearAllInvestorDetails,
  getValues,
  selectedStatus,
  setSelectedStatus,
  fcrmInvestorTypes,
  setError,
  clearErrors,
  onClose,
  isRoleAdmin,
  selectedStage,
}: ComponentProps) => {
  const classes = styles();
  const [formFields, setFormFields] = useState<string[]>(defaultFormFields);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deleteProperty, setDeleteProperty] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showClearAll, setShowClearAll] = useState<boolean>(false);
  const [newProperties, setNewProperties] = useState<any>([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedMfn, setSelectedMfn] = useState<string>('');

  const [investmentFormFields, setInvestmentForFields] = useState<
    Record<string, any>
  >(formFieldsObj);

  const [propertyDropdowns, setPropertyDropdowns] = useState<any>(
    defaultPropertyDropdowns,
  );

  useEffect(() => {
    if (selectedStatus === 'WON') {
      formFieldsObj.amountInvested.require = true;
      const amountInvested = getValues('amountInvested');
      if (!amountInvested) {
        setError(`amountInvested`, {
          type: 'custom',
          message: 'Field is required',
        });
      }
    } else {
      formFieldsObj.amountInvested.require = false;
      clearErrors('amountInvested');
    }
    setInvestmentForFields({ ...formFieldsObj });
  }, [selectedStatus]);

  useEffect(() => {
    setPropertyDropdowns(
      defaultPropertyDropdowns.filter(
        (item: any) => !formFields.includes(item.value),
      ),
    );
    formFields.map((item) =>
      setValue(
        item,
        editFcrmContent[item] !== 'DELETED' ? editFcrmContent[item] : '',
      ),
    );

    setValue(
      'valuationChoice',
      editFcrmContent.valuationChoice !== 'DELETED'
        ? editFcrmContent.valuationChoice
        : '',
    );
  }, [formFields]);

  useEffect(() => {
    let currentFormFields = Object.assign([], defaultFormFields);
    currentFormFields = currentFormFields.filter(
      (item) => editFcrmContent[item] !== 'DELETED',
    );
    if (![undefined, null, 'DELETED'].includes(editFcrmContent.probability))
      currentFormFields.push('probability');
    if (![undefined, null, 'DELETED'].includes(editFcrmContent.askedForIntros))
      currentFormFields.push('askedForIntros');
    setFormFields(currentFormFields);
    setSelectedMfn(editFcrmContent.valuationChoice);
  }, [editFcrmContent]);

  const addProperty = (e: any) => {
    setFormFields([...formFields, e.target.value]);
    setNewProperties([...newProperties, e.target.value]);
  };

  const deleteFcrmPropertyFunc = () => {
    setIsDeleting(true);
    deleteFcrmProperty({
      id: editFcrmContent.id,
      properties: [deleteProperty],
    })
      .then(() => {
        setFormFields(formFields.filter((item) => item !== deleteProperty));
        setDeleteProperty('');
        setReloadOnSidePaneCLose(true);
      })
      .catch((e) => console.log(e)) //eslint-disable-line
      .finally(() => setIsDeleting(false));
  };

  const clearAllData = () => {
    const data = formFields.reduce((item1: any, item2: any) => {
      setValue(item2, '');
      return {
        ...item1,
        [`${editFcrmContent.id}#${item2}`]: '',
      };
    }, {});
    clearAllInvestorDetails(data);
    setShowClearAll(false);
  };

  // eslint-disable-next-line no-unused-vars
  const saveNewProperties = () => {
    const data = newProperties.reduce((item1: any, item2: any) => {
      return {
        ...item1,
        [`${editFcrmContent.id}#${item2}`]: getValues(item2) || '',
      };
    }, {});
    clearAllInvestorDetails(data);
    setNewProperties([]);
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-empty-function
  const onBlurSave = (value: string, field: string) => {};

  const getRequireField = (data: any) => {
    if (
      data.field === 'amountInvested' &&
      selectedStage === 'CLOSED' &&
      selectedStatus === 'WON'
    ) {
      return { label: 'This field is required', required: true };
    }
    if (
      data.field === 'valuationCap' &&
      selectedStage === 'CLOSED' &&
      selectedStatus === 'WON' &&
      selectedMfn !== 'UNCAPPED_MFN'
    ) {
      return { label: 'This field is required', required: true };
    }
    if (
      data.field === 'closingDate' &&
      selectedStage === 'CLOSED' &&
      selectedStatus === 'WON'
    ) {
      return { label: 'This field is required', required: true };
    }
    return {
      label: data.require ? 'This field is required' : '',
      require: true,
    };
  };
  return (
    <Box className={classes.investmentDetails}>
      <Typography variant="h6" className={classes.investorSubTitle}>
        Investment Details
      </Typography>

      <Box>
        <Grid xs={12} container>
          {formFields.map((item: string, i: number) => {
            const data = investmentFormFields[item];

            return (
              <Grid
                item
                xs={6}
                className={classes.investorInputBox}
                key={`inv-${i}`}
              >
                {data.type !== 'select' && (
                  <Grid container>
                    <Grid xs={get(data, 'child') ? 6 : 12}>
                      <data.Component
                        disabled={isRoleAdmin}
                        label={() => (
                          <>
                            <Typography>
                              {data.label}{' '}
                              {get(getRequireField(data), 'required') && (
                                <span className={classes.errorText}>*</span>
                              )}
                              {showDelete && data.deletable && (
                                <IconButton
                                  size="small"
                                  onClick={() => setDeleteProperty(item)}
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </Typography>
                          </>
                        )}
                        placeholder={data.placeholder || ''}
                        type={data.type}
                        onBlurField={(value: string, field: string) =>
                          onBlurSave(value, field)
                        }
                        control={control}
                        classes={classes}
                        {...register(data.field, {
                          required: get(getRequireField(data), 'label'),
                          value:
                            editFcrmContent[data.field] || data.defaultValue,
                        })}
                        inputProps={{
                          inputProps: {
                            min: data.min || 0,
                            max: data.max || undefined,
                            step: data.step,
                          },
                          startAdornment: data.prefix && (
                            <InputAdornment position="start">
                              {data.prefix === 'currency'
                                ? currency
                                : data.prefix}
                            </InputAdornment>
                          ),
                          endAdornment: data.postfix && (
                            <InputAdornment position="end">
                              {data.postfix === 'currency'
                                ? currency
                                : data.postfix}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {get(data, 'child') &&
                      get(data, 'child.type') === 'select' && (
                        <Grid
                          style={{ paddingTop: showDelete ? 27 : 21 }}
                          xs={6}
                        >
                          <div style={{ width: '97%', marginLeft: '3%' }}>
                            <SelectInputField
                              disabled={isRoleAdmin}
                              label={() => (
                                <>
                                  {get(data, 'child.label') || ' '}
                                  {data.require && (
                                    <span className={classes.errorText}>
                                      {' '}
                                      *
                                    </span>
                                  )}
                                </>
                              )}
                              onChangeField={(value: string, field: string) => {
                                if (field === 'valuationChoice') {
                                  setSelectedMfn(value);
                                }

                                saveOnEditMode(value, field);
                              }}
                              classes={classes}
                              control={control}
                              {...register(get(data, 'child.field'))}
                              dropdowns={get(data, 'child.dropdowns')}
                            />
                          </div>
                        </Grid>
                      )}
                  </Grid>
                )}

                {data.type == 'select' && (
                  <>
                    <SelectInputField
                      disabled={isRoleAdmin}
                      label={() => (
                        <>
                          {data.label}
                          {data.require && (
                            <span className={classes.errorText}> *</span>
                          )}
                          {showDelete && data.deleteable && (
                            <IconButton
                              size="small"
                              onClick={() => setDeleteProperty(item)}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </>
                      )}
                      control={control}
                      classes={classes}
                      onChangeField={(value: string, field: string) =>
                        saveOnEditMode(value, field)
                      }
                      // name={'status'}
                      {...register(data.field, {
                        required: data.require ? 'This field is required' : '',
                      })}
                      dropdowns={
                        data.field === 'investorType'
                          ? [
                              ...data.dropdowns,
                              ...(fcrmInvestorTypes || []).map((item) => {
                                return {
                                  text: item,
                                  value: item,
                                };
                              }),
                            ]
                          : data.dropdowns
                      }
                    />
                  </>
                )}
              </Grid>
            );
          })}
          {showAddProperty && propertyDropdowns.length > 0 && (
            <Grid item xs={6} className={classes.investorInputBox}>
              <WrappedSelect
                onChange={addProperty}
                label={'Add new property'}
                className={classes.selectValInput}
                SelectProps={{
                  MenuProps: {
                    // classes: { paper: classes.menuPaper },
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                dropdowns={propertyDropdowns}
                InputProps={undefined}
                value={''}
              />
            </Grid>
          )}
          {!editMode && (
            <Grid item xs={6} className={classes.investorInputBox}>
              <SelectInputField
                label={() => (
                  <>
                    Status <span className={classes.errorText}> *</span>
                  </>
                )}
                onChangeField={(val: string) => {
                  setSelectedStatus(val);
                }}
                control={control}
                classes={classes}
                // name={'status'}
                {...register('status', {
                  required: 'This is a required field',
                })}
                dropdowns={[
                  { text: 'New', value: 'NEW' },
                  { text: 'Interested', value: 'INTERESTED' },
                  { text: 'Pass', value: 'PASS' },
                  { text: 'Won', value: 'WON' },
                ]}
              />
            </Grid>
          )}
        </Grid>
        {showDelete && (
          <Box>
            <Alert variant="filled" severity="error">
              Delete Property will work across all Investor, so be conscious, as
              your data may lost.
            </Alert>
            <br />
            <br />
          </Box>
        )}
        <Box className={classes.investorFormBtns}>
          <Grid container>
            <Grid
              xs={12}
              sm={12}
              lg={4}
              xl={4}
              md={12}
              style={{ marginTop: 4 }}
            >
              <>
                {!isRoleAdmin && !showDelete && (
                  <Button
                    name="Save"
                    type="submit"
                    isLoading={isLoading}
                    className={classes.investorPrimaryBtn}
                  />
                )}
                {!isRoleAdmin && (
                  <Button
                    name="Cancel"
                    type="button"
                    className={classes.investorSecondaryBtn}
                    onClick={() => {
                      if (showDelete) {
                        setShowDelete(false);
                      } else if (showAddProperty) {
                        setShowAddProperty(false);
                      } else {
                        onClose();
                      }
                    }}
                  />
                )}
              </>
            </Grid>
            {!isRoleAdmin && editMode && !showDelete && (
              <Grid
                xs={12}
                sm={12}
                lg={7}
                xl={7}
                md={12}
                style={{
                  marginTop: 4,
                  display: showAddProperty ? 'none' : 'flex',
                }}
                className={classes.propertyBtnGrid}
              >
                <Button
                  name="Add Property"
                  type="button"
                  className={classes.investorPrimaryBtn}
                  onClick={() => setShowAddProperty(true)}
                />
                <Button
                  name="Delete Property"
                  type="button"
                  style={{ width: 140 }}
                  className={classes.investorSecondaryBtn}
                  onClick={() => setShowDelete(true)}
                />
                <Button
                  name="Clear"
                  type="button"
                  onClick={() => setShowClearAll(true)}
                  className={classes.investorSecondaryBtn}
                />
              </Grid>
            )}
            <Grid xs={12} lg={1} xl={1} />
          </Grid>
        </Box>
      </Box>

      <DeletePropertyDialog
        open={!isEmpty(deleteProperty)}
        onClose={() => setDeleteProperty('')}
        onCancel={() => setDeleteProperty('')}
        onOk={deleteFcrmPropertyFunc}
        deleteProperty={deleteProperty}
        isLoadding={isDeleting}
      />
      <FCRMClearAllDialog
        open={showClearAll}
        onClose={() => setShowClearAll(false)}
        onCancel={() => setShowClearAll(false)}
        onOk={clearAllData}
        isLoading={isDeleting}
      />
    </Box>
  );
};

export default InvestmentDetails;
