import {
  GET_STARTUP_FUNDING_ROUND_LIST_SUCCESS,
  GET_STARTUP_FUNDING_ROUND_LIST_LOADING,
  GET_STARTUP_FUNDING_ROUND_LIST_FAILURE,
  ADD_STARTUP_FUNDING_ROUND_SUCCESS,
  ADD_STARTUP_FUNDING_ROUND_LOADING,
  ADD_STARTUP_FUNDING_ROUND_FAILURE,
  APPROVE_FUNDING_ROUND_LOADING,
  APPROVE_FUNDING_ROUND_FAILURE,
} from './Actions';
import { FundingRoundState } from './Types';
export const FundingRoundInitialState: FundingRoundState = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  isApproveFundingRoundLoading: false,
  approveFundingRoundErrorMessage: '',
  fundingRoundList: [],
};
const FundingRoundReducer = (state = FundingRoundInitialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STARTUP_FUNDING_ROUND_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_STARTUP_FUNDING_ROUND_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isApproveFundingRoundLoading: false,
        successMessage: 'success',
        fundingRoundList: payload,
      };
    case GET_STARTUP_FUNDING_ROUND_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isApproveFundingRoundLoading: false,
        successMessage: '',
        errorMessage: payload,
      };
    case ADD_STARTUP_FUNDING_ROUND_LOADING:
      return {
        ...state,
        successMessage: '',
        isLoading: true,
        errorMessage: '',
      };
    case ADD_STARTUP_FUNDING_ROUND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: 'success',
        fundingRoundList: payload,
      };
    case ADD_STARTUP_FUNDING_ROUND_FAILURE:
      return {
        ...state,
        isLoading: false,
        successMessage: '',
        errorMessage: payload,
      };
    case APPROVE_FUNDING_ROUND_LOADING:
      return {
        ...state,
        isApproveFundingRoundLoading: true,
        approveFundingRoundErrorMessage: '',
      };
    case APPROVE_FUNDING_ROUND_FAILURE:
      return {
        ...state,
        isApproveFundingRoundLoading: false,
        approveFundingRoundErrorMessage: payload,
      };
    default:
      return state;
  }
};

export default FundingRoundReducer;
