import { urlConstants } from 'common/utils/constants';
import { PortFolioParams } from 'common/types';

import { secureApi } from '../api/Axios';

export const getAdminPortfolioList = (params: PortFolioParams) =>
  secureApi.get(urlConstants.adminGetPortfolioList, { params });

export const getDocuSignUrl = () =>
  secureApi.post(urlConstants.getDocuSignDocuments);

export const getVdByStartupId = (payload: string) =>
  secureApi.get(`${urlConstants.getVdByStartupId}/${payload}`);

export const getDashboardValues = () =>
  secureApi.get(`${urlConstants.getDashboardValues}`);

export const getInvestorsFollowup = () =>
  secureApi.post(`${urlConstants.investorsFollowup}`);

export const getVdLoanDetailsByStartupId = (payload: string) =>
  secureApi.get(`${urlConstants.getVdLoanDetailsByStartupId}/${payload}`);

export const checkEmailYCVerified = (payload: string) =>
  secureApi.get(`${urlConstants.checkEmailYcVerified}?email=${payload}`);

export const checkStartupsEmailReader = (payload: string) =>
  secureApi.get(`${urlConstants.checkStartupsEmailReader}/${payload}`);
