import React, { useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';

import { Sidebar, Box, Stepper } from 'components';
import { Logo } from 'assets';
import { getDealById } from 'services';
import history from 'common/utils/history';
import { errorMessageHandler } from 'common/utils/helpers';
import { DealCreationProps } from 'common/types';
import { handleGetAllDeal } from 'redux-modules/deals/Actions';
import { RootState } from 'redux-modules/store/rootState';

import DealInformation from './Steps/DealInformation/DealInformation';
import MarketingMaterial from './Steps/MarketingMaterial/MarketingMaterial';
import LegalRegulatory from './Steps/LegalRegulatory/LegalRegulatory';
import BankDetails from './Steps/BankDetails/BankDetails';
import styles from './styles';

function getSteps() {
  return [
    { name: 'Deal Information', key: 'DEAL_INFO' },
    { name: 'Marketing Material', key: 'MARKETING' },
    { name: 'Legal & Regulatory', key: 'LEGAL_REGULATORY' },
    { name: 'Bank Detail', key: 'BANK_ACC_DETAIL' },
  ];
}

// const typeSteps = [
//   'DEAL_INFO',
//   'MARKETING',
//   'LEGAL_REGULATORY',
//   'BANK_ACC_DETAIL',
// ];

function getStepContent(step: number, props: DealCreationProps) {
  switch (step) {
    case 0:
      return <DealInformation {...props} />;
    case 1:
      return <MarketingMaterial {...props} />;
    case 2:
      return <LegalRegulatory {...props} />;
    case 3:
      return <BankDetails {...props} />;
  }
}

const DealCreation = ({ match }: any) => {
  const dealId = get(match, 'params.id');

  const { dealList } = useSelector(({ Deals }: RootState) => Deals);

  const classes = styles();
  const dispatch = useDispatch();
  const steps = getSteps();
  const [loadingDeal, setLoadingDeal] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [completedSteps, setCompletedSteps] = React.useState<number>(0);
  // const [stepName, setStepName] = React.useState<string>(typeSteps[activeStep]);
  const [deal, setDeal] = React.useState<any>(null);

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  const handleClose = () => {
    history.push('/admin/deals');
  };

  const syncDeal = async () => {
    if (dealId !== 'NEW') {
      try {
        setLoadingDeal(true);
        const res = await getDealById(dealId);
        setDeal(res);
      } catch (err) {
        const message = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setLoadingDeal(false);
      }
    } else {
      setLoadingDeal(false);
    }
  };

  useEffect(() => {
    syncDeal();
  }, [dealId]);

  useEffect(() => {
    if (!isEmpty(get(deal, 'id'))) {
      if (get(deal, 'bankdetailLink')) {
        setCompletedSteps(4);
      } else if (get(deal, 'subscriptionDocLink')) {
        setCompletedSteps(3);
      } else if (get(deal, 'informationLink')) {
        setCompletedSteps(2);
      } else if (get(deal, 'id')) {
        setCompletedSteps(1);
      }
    }
  }, [deal]);

  useEffect(() => {
    if (isEmpty(dealList)) dispatch(handleGetAllDeal());
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.dashContainer}>
        <Box className={classes.dashleft}>
          <Sidebar className={classes.sidebarFixed}>
            <Box className={classes.dashLogo}>
              <img src={Logo} alt={Logo} className={classes.logoImg} />
            </Box>
            {!loadingDeal && (
              <Stepper
                activeStep={activeStep}
                completedSteps={completedSteps}
                steps={steps}
                orientation={true}
                handleStepClick={handleStepClick}
                allStepDisable={false}
              />
            )}
          </Sidebar>
        </Box>
        {loadingDeal && (
          <div className={classes.skeletonWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Skeleton
                  style={{ marginBottom: 10 }}
                  count={10}
                  enableAnimation
                  height={32}
                />
              </Grid>
              <Grid item xs={9}>
                <Skeleton
                  style={{ marginBottom: 10 }}
                  count={10}
                  enableAnimation
                  height={32}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {!loadingDeal && (
          <Box className={classes.dashRight}>
            <Box className={classes.dashHeader}>
              <Typography
                variant="caption"
                className={classes.closeIcon}
                onClick={handleClose}
              >
                <CloseIcon />
              </Typography>
            </Box>
            {errorMessage && (
              <Typography variant="body1" className={classes.errorMessage}>
                {errorMessage}
              </Typography>
            )}
            {getStepContent(activeStep, {
              handleNext: (step: number) => setActiveStep(step),
              handleBack: () => setActiveStep(activeStep - 1),
              activeStep,
              setActiveStep,
              dealId,
              deal,
              setDeal,
            })}
          </Box>
        )}
      </Box>
    </div>
  );
};
export default DealCreation;
