import React, { useEffect } from 'react';
import { Switch, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import { createKycAndSignupOnboard } from 'services';
import history from 'common/utils/history';
import { Box } from 'components';
import { InvestorOnboardStepProps } from 'common/types';
import { errorMessageHandler } from 'common/utils/helpers';
import { FUNDING_ENTITY_TYPE } from 'common/utils/constants';

import {
  EntityFundingSchema,
  IndividualFundingSchema,
} from '../../validations';
import styles from './styles';
import IndividualFundingForm from './IndividualFundingForm';
import EntityFundingForm from './EntityFundingForm';
import SignupDetail from './SignupDetail';

const KYCDetails = ({
  spv,
  investor,
  setActiveStep,
  activeStep,
  kyc,
  investmentProcess,
  syncInvestor,
}: InvestorOnboardStepProps) => {
  const classes = styles();

  const [individualEntity, setIndividualEntity] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const [loadingKyc, setLoadingKyc] = React.useState<boolean>(false);
  const [fundingEntityType, setFundingEntityType] = React.useState<string>(
    FUNDING_ENTITY_TYPE.INDIVIDUAL,
  );

  const handleChange = (event: any) => {
    setIndividualEntity(!event.target.checked);
  };

  useEffect(() => {
    if (get(kyc, 'id')) {
      if (get(kyc, 'fundingEntityType') === FUNDING_ENTITY_TYPE.INDIVIDUAL)
        setIndividualEntity(true);
      else setIndividualEntity(false);
    }
  }, [kyc]);

  useEffect(() => {
    if (individualEntity) setFundingEntityType(FUNDING_ENTITY_TYPE.INDIVIDUAL);
    else setFundingEntityType(FUNDING_ENTITY_TYPE.ENTITY);
  }, [individualEntity]);

  // const schema = IndividualFundingSchema;
  const schema =
    fundingEntityType === FUNDING_ENTITY_TYPE.INDIVIDUAL
      ? IndividualFundingSchema
      : EntityFundingSchema;
  if (get(investor, 'id')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete schema.fields.signupDetails;
  }

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    setLoadingKyc(true);
    const kycDetails = data;
    const signupDetails = kycDetails.signupDetails;
    delete kycDetails.signupDetails;
    try {
      if (kyc) {
        kycDetails.id = get(kyc, 'id');
        kycDetails.investorId = get(kyc, 'investorId');
      } else if (get(investor, 'id')) {
        kycDetails.investorId = get(investor, 'id');
      }
      kycDetails.spv = spv;
      kycDetails.phone = !isEmpty(get(data, 'phone'))
        ? `${get(data, 'countryCode')}#${get(data, 'phone')}`
        : get(data, 'phone');
      kycDetails.fundingEntityType = fundingEntityType;
      if (get(kycDetails, 'ssn').includes('*****', 0)) {
        delete kycDetails.ssn;
      }
      const res = await createKycAndSignupOnboard({
        kycDetails,
        signupDetails: get(investor, 'id') ? null : signupDetails,
      });
      await syncInvestor('kyc-added');
      if (kyc || get(investor, 'id')) {
        setActiveStep(activeStep + 1);
      } else {
        history.push(`/admin/investor-onboard/${get(res, 'id')}`);
      }
    } catch (err) {
      const message: string = errorMessageHandler(err);
      setErrorMessage(message);
    } finally {
      setLoadingKyc(false);
    }
  };
  return (
    <Box>
      <Typography variant="h3" className={classes.investNowFormHeader}>
        Funding entity details:
      </Typography>
      {errorMessage && (
        <Typography variant="body1" className={classes.errorMessage}>
          {errorMessage}
        </Typography>
      )}
      <form
        onSubmit={
          handleSubmit(onSubmit)
          // get(investmentProcess, 'docuSignDocumentSigned')
          //   ? () => handleNext(activeStep + 1)
          //   : handleSubmit(onSubmit)
        }
        data-testid="individualFundingForm"
        className={classes.validateForm}
      >
        {!get(investor, 'id') && <SignupDetail control={control} />}
        <Typography
          className={classes.investNowSubHeader}
          variant="h3"
          gutterBottom
        >
          KYC Details
        </Typography>
        <Box className={classes.switchBox}>
          <Switch
            classes={{
              root: classes.switchRoot,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            checked={!individualEntity}
            onChange={handleChange}
            name="fundingEntityType"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={get(investmentProcess, 'docuSignDocumentSigned')}
          />
        </Box>
        {fundingEntityType === FUNDING_ENTITY_TYPE.INDIVIDUAL ? (
          <IndividualFundingForm
            spv={spv}
            investor={investor}
            kyc={kyc}
            control={control}
            loadingKyc={loadingKyc}
            // investmentProcess={investmentProcess}
            setValue={setValue}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <EntityFundingForm
            spv={spv}
            investor={investor}
            kyc={kyc}
            control={control}
            loadingKyc={loadingKyc}
            // investmentProcess={investmentProcess}
            setValue={setValue}
            setErrorMessage={setErrorMessage}
          />
        )}
      </form>
    </Box>
  );
};
export default KYCDetails;
