import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import InvestorList from './InvestorList';

export const InvestorListRoute: IRoute = {
  path: '/admin/investor-list',
  component: InvestorList,
  layout: DashboardLayout,
  exact: true,
};
