import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

import {
  Button,
  Box,
  WrappedTextInput,
  WrappedSelect,
  Banner,
} from 'components';
import {
  trackBridgeLoanProcess,
  getFundType,
} from 'redux-modules/enquiries/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { processLoan } from 'redux-modules/process/Actions';
import { FN } from 'common/types';

import styles from '../../styles';
import { processLoanSchema } from './validations';

type Props = {
  handleBack: FN;
  startupId: string | undefined;
  isRoleAdmin: boolean;
};
const ProcessLoan = ({ handleBack, startupId, isRoleAdmin }: Props) => {
  const [successPop, setSuccessPop] = useState<boolean>(false);
  const { handleSubmit, control, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(processLoanSchema),
  });
  const classes = styles();
  const dispatch = useDispatch();
  const { successMessage, isLoading, errorMessage } = useSelector(
    ({ Process }: RootState) => Process,
  );
  const {
    bridgeLoanProcess,
    loansDetails,
    loanTypeObj,
    enquiryView,
  } = useSelector(({ Enquiries }: RootState) => Enquiries);

  const { enquiryRequestLoanAmount } = enquiryView;

  const loan = isRoleAdmin ? loanTypeObj : loansDetails;
  const getFieldValue = (inputField: string) => {
    if (!isEmpty(loan)) {
      if (inputField === 'loanProcessedAt') {
        return !isEmpty(loan[inputField]) ? loan[inputField].split('T')[0] : '';
      }
      return loan[inputField];
    }
  };

  const onSubmit = () => {
    const payload = {
      startupId,
      ...getValues(),
    };
    dispatch(processLoan(payload));
  };

  useEffect(() => {
    if (!isEmpty(successMessage)) {
      dispatch(trackBridgeLoanProcess(startupId));
      setSuccessPop(true);
    }
  }, [successMessage]);

  const handleDisableField = () => {
    if (!isRoleAdmin) {
      return true;
    } else if (!isEmpty(bridgeLoanProcess)) {
      return bridgeLoanProcess.isLoanProcessed;
    }
  };

  useEffect(() => {
    dispatch(getFundType(startupId));
  }, [successPop]);

  useEffect(() => {
    if (loanTypeObj) {
      reset({
        transactionType: 'DEBIT',
        loanProcessedAt: getFieldValue('loanProcessedAt'),
        transactionId: getFieldValue('transactionId'),
        finalProcessedLoanAmount: enquiryRequestLoanAmount,
      });
    }
  }, [loanTypeObj]);

  return (
    <div>
      {/* {successMessage && <Typography>{successMessage}</Typography>} */}
      {errorMessage && <Typography>{errorMessage}</Typography>}
      {successPop ? (
        <Banner title={successMessage} subTitle={''} source={'SuccessBanner'} />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} data-testid="processLoanForm">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Controller
                  name="processedDate"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        id="date-local"
                        // label="Processed Date"
                        type="date"
                        // defaultValue={getFieldValue('loanProcessedAt')}
                        value={getFieldValue('loanProcessedAt') || value}
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                        onChange={onChange}
                        disabled={handleDisableField()}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Processed Date is required' }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Controller
                  name="transactionType"
                  control={control}
                  defaultValue="DEBIT"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <>
                      <WrappedSelect
                        value="DEBIT"
                        onChange={onChange}
                        label="Transaction Type"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        dropdowns={[
                          { text: 'Debit', value: 'DEBIT' },
                          { text: 'Credit', value: 'CREDIT' },
                        ]}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Transaction Type is required' }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Controller
                  name="transactionId"
                  control={control}
                  defaultValue={getFieldValue('transactionId')}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label="Transaction Id"
                        placeholder="Transaction Id"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={getFieldValue('transactionId') || value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled={handleDisableField()}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Transaction Id is required' }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Controller
                  name="finalProcessedLoanAmount"
                  control={control}
                  defaultValue={enquiryRequestLoanAmount}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label="Final processed loan amount"
                        placeholder="Final processed loan amount"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={
                          getFieldValue('finalProcessedLoanAmount') || value
                        }
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{
                    required: 'Final processed loan amount is required',
                  }}
                />
              </Grid>
            </Grid>
            <Box className={classes.backContinueBox}>
              {!isEmpty(bridgeLoanProcess) &&
              !get(bridgeLoanProcess, 'isLoanProcessed') ? (
                <Button
                  name="Process"
                  type="submit"
                  // onClick={() => onSubmit()}
                  className={classes.continueBtn}
                  isLoading={isLoading}
                  disabled={!isRoleAdmin || handleDisableField()}
                />
              ) : (
                <Button
                  name={'Processed'}
                  className={classes.loanApprovedBtn}
                />
              )}
              <Button
                onClick={() => handleBack()}
                className={classes.backBtn}
                name="Cancel"
              />
            </Box>
          </form>
        </>
      )}
    </div>
  );
};

export default ProcessLoan;
