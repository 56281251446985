import React from 'react';

import { PageHeader, NoItemsImg } from 'components';

type Props = {
  name: string;
};

const Banner = ({ name }: Props) => {
  return (
    <>
      <PageHeader name={name} />
      <NoItemsImg />
    </>
  );
};

export default Banner;
