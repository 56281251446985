import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { getInvestorsFollowup } from 'services';

const DashboardAlerts = () => {
  const [open, setOpen] = useState(true);
  const [investorsData, setInvestorsData] = useState<any>([]);

  useEffect(() => {
    getInvestorsFollowup().then((res: any) => {
      if (!isEmpty(res)) {
        const todayFollowup: any = res.filter(
          (item: any) => item.nextFollowup === moment().format('YYYY-MM-DD'),
        );
        if (!isEmpty(todayFollowup)) {
          setInvestorsData(todayFollowup);
        }
      }
    });
  }, []);

  if (isEmpty(investorsData)) return null;

  return (
    <Box sx={{ width: '100%' }} mb={2}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          You have follow up with{' '}
          {investorsData.map((item: any) => item.investorName).join(', ')}{' '}
          today.
        </Alert>
      </Collapse>
    </Box>
  );
};

export default DashboardAlerts;
