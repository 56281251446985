import React from 'react';
import { Typography } from '@material-ui/core';

import { Box, SuccessPage } from 'components';
import { Ornament, AccessDenied } from 'assets';

import styles from './styles';

const NotFound = () => {
  const classes = styles();
  return (
    <Box>
      <Box className={classes.enquiryViewBoxLeft}>
        <Box className={classes.enquiryListhead}>
          <img src={Ornament} alt={Ornament} className={classes.imgOrnament} />
          {/* <Typography className={classes.notFound} variant="h3">
            Page not Found
          </Typography> */}
        </Box>
      </Box>
      <Box className={classes.enquirySuccessBox}>
        <Box className={classes.eBoxContent}>
          <SuccessPage img={AccessDenied} className={classes.dashSuccessImg}>
            <Typography
              gutterBottom
              variant="h4"
              className={classes.successHeaderTxt}
            >
              Access Denied
            </Typography>
          </SuccessPage>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
