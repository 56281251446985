import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  benchMarkContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
  },
  benchmarkHead: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '80px 80px 20px 67px',
    '@media (max-width: 1024px)': {
      padding: '90px 80px 20px 20px',
    },
    '@media (max-width: 767px)': {
      padding: '40px 20px 20px 20px',
    },
    '@media (max-width: 525px)': {
      flexDirection: 'column-reverse',
    },
  },
  blurContainer: {
    filter: `blur(5px)`,
  },
  textOnBlur: {
    position: 'relative',
    textAlign: 'center',
    top: 195,
    fontWeight: 600,
    fontSize: 18,
    zIndex: 1,
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 43,
    left: 40,
    '@media (max-width: 1024px)': {
      top: 75,
      left: 5,
      objectFit: 'cover',
      height: 70,
      width: 80,
    },
    '@media (max-width: 767px)': {
      top: 10,
      left: 20,
      objectFit: 'cover',
    },
  },
  headText: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    fontWeight: 400,
    letterSpacing: 0.3,
    position: 'relative',
    top: -24,
    '@media (max-width: 1024px)': {
      top: 'unset',
      fontSize: 24,
    },
    '@media (max-width: 525px)': {
      fontSize: 22,
      top: '-22px',
    },
  },
  backToMainText: {
    fontSize: 20,
    color: '#0069D5',
    fontWeight: 700,
    letterSpacing: 0.3,
    position: 'relative',
    top: -24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      top: 'unset',
    },
    '@media (max-width: 525px)': {
      fontSize: 18,
      justifyContent: 'flex-end',
      top: -24,
    },
    cursor: 'pointer',
  },
  editProfileBtn: {
    marginLeft: 10,
    top: -2,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 50,
    width: 110,
    height: 25,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 13,
    cursor: 'pointer',
    letterSpacing: 0.3,
    lineHeight: '16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  ventureDebtLoanBtn: {
    top: -20,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 30,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 230,
    fontSize: 16,
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    lineHeight: '20.08px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    padding: '0px 45px',
  },
  //////////////////////
  benchMarkKPIContent: {
    padding: '0px 45px 40px',
    height: 'calc(100vh - 230px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media (max-width: 1024px)': {
      padding: '30px 20px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 20px 30px',
      height: 'auto',
    },
    '@media (max-width: 525px)': {
      // padding: '0px 20px 25px',
      padding: '0px 15px 20px',
    },
  },
  ////// First Box
  percentileGrowthBox: {
    padding: '20px',
    margin: '0px 20px',
    background: theme.palette.common.white,
    borderRadius: 11,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    '@media (max-width: 1024px)': {
      padding: '10px',
      margin: '0px',
    },
  },
  topContainerCgmr: {
    textAlign: 'center',
    paddingTop: 10,
  },
  cgmrText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grey.A400,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
  },
  cgmrPercentage: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '63px',
    paddingTop: 10,
    '@media (max-width: 1366px)': {
      fontSize: 40,
      lineHeight: '63px',
    },
    '@media (max-width: 768px)': {
      fontSize: 35,
      lineHeight: '63px',
    },
  },
  // Table Percentile Growth
  percentileGrowthZuiTable: {
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    font: 'normal 13px Arial, sans-serif',
    width: '100%',
    height: 110,
    '& thead': {
      height: 'calc(100% - 50%)',
    },
    '& thead th': {
      border: '1px solid #E5E5E5',
      borderBottom: 0,
      color: theme.palette.common.black,
      padding: 10,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '25px',
      textAlign: 'center',
      '&:first-child': {
        color: theme.palette.grey[300],
        fontSize: 16,
        lineHeight: '20px',
        textAlign: 'left',
      },
      '@media (max-width: 525px)': {
        padding: 5,
        fontSize: 16,
      },
    },
    '& tbody td': {
      border: '1px solid #E5E5E5',
      color: theme.palette.common.black,
      padding: 10,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '25px',
      textAlign: 'center',
      '&:first-child': {
        color: theme.palette.grey[300],
        fontSize: 16,
        lineHeight: '20px',
        textAlign: 'left',
      },
      '@media (max-width: 525px)': {
        padding: 5,
        fontSize: 16,
      },
    },
  },
  benchMarkTableOne: {
    overflowX: 'auto',
    OverflowY: 'hidden',
  },
  progressPercentageView: {
    width: 'calc(100% - 120px)',
    position: 'absolute',
    // left: '16.5%',
    bottom: '-4px',
    '@media (max-width: 525px)': {
      width: 'calc(100% - 110px)',
      // left: '19.5%',
    },
  },
  progressRank: {
    '&.MuiLinearProgress-colorPrimary': {
      background: '#ECEFF5',
      borderRadius: '100px',
      height: 8,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '100px',
    },
  },
  ////// Second Box
  startupGrowthRankBox: {
    padding: '20px',
    margin: '20px',
    background: theme.palette.common.white,
    borderRadius: 11,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    '@media (max-width: 1024px)': {
      padding: '10px',
      margin: '20px 0',
    },
  },
  filterLabelBox: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  filterLabelText: {
    color: theme.palette.grey[300],
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    textAlign: 'end',
    paddingRight: 20,
    '@media (max-width: 525px)': {
      paddingRight: 10,
      fontSize: 12.5,
    },
  },
  filterInput: {
    marginTop: 0,
    width: '90%',
  },
  inputRootDashboard: {
    width: '80%',
    fontSize: 17,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  textValInputSelect: {
    marginBottom: 10,
    marginTop: 5,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRootSelect: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRootSelect: {},
  labelFocusedSelect: {},
  startupRankContainer: {
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 23,
  },
  startupRankText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: '23px',
    '@media (max-width: 768px)': {
      lineHeight: '15px',
    },
  },
  startupRankCompairedText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 400,
    lineHeight: '23px',
    '@media (max-width: 768px)': {
      lineHeight: '15px',
    },
  },
  startupRankValue: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '63px',
    '@media (max-width: 768px)': {
      fontSize: 40,
    },
  },
  ////// Third Box Title
  startupKPIDataBox: {
    margin: 20,
    padding: '10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      margin: '0px 0px 20px',
    },
  },
  kpiDataLabelText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.3,
  },
  uploadKpiButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 157,
    fontSize: 18,
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  ////// Third Box Table
  kpiTableDataBox: {
    background: theme.palette.common.white,
    borderRadius: 11,
    margin: 20,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    paddingBottom: '40px',
    '@media (max-width: 1024px)': {
      margin: '0 0 20px',
    },
  },
  addKpiButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: '20px 30px',
    borderRadius: 30,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 157,
    fontSize: 16,
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    lineHeight: '20.08px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  ///////
  benchmarkContentRight: {
    margin: 20,
    padding: '10px 0px',
    '@media (max-width: 1024px)': {
      margin: '0px 0px 20px',
    },
  },
  benchmarkContentTitle: {
    fontWeight: 600,
    paddingLeft: 0,
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.3,
    marginBottom: 25,
  },
  benchmarkOutputBoxDollar: {
    width: '100%',
    background: '#FFF',
    border: '1px solid #D8D8D8',
    borderRadius: 11,
    textAlign: 'center',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      minHeight: 324,
    },
  },
  benchmarkOutputBoxDollarContent: {
    alignSelf: 'center',
    padding: 20,
  },
  benchmarkSubGreenText: {
    fontSize: 18,
    color: theme.palette.grey[50],
    lineHeight: '23px',
    marginBottom: 10,
  },
  benchmarkOutputGreenText: {
    fontWeight: 700,
    fontSize: 48,
    lineHeight: '60px',
    color: theme.palette.primary.main,
    '@media (max-width: 525px)': {
      fontSize: 34,
      lineHeight: '41px',
    },
  },
  enterKpiDetailsContainer: {
    backgroundColor: '#FFEEEE',
    alignItems: 'center',
    padding: '6px 16px',
    height: 49,
    width: 363,
    margin: '0px 20px',
  },
  infoIconRed: {
    marginRight: 5,
    color: '#FF5151',
  },
}));
