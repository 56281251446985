import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  clientContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
    '@media (max-width: 767px)': {
      height: '100%',
    },
  },
  actionContent: {
    height: '100%',
  },
  actionHead: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '80px 80px 20px 67px',
    '@media (max-width: 767px)': {
      padding: '35px 40px',
    },
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 43,
    left: 40,
    '@media (max-width: 1024px)': {
      height: 60,
      width: 70,
      top: 75,
    },
    '@media (max-width: 767px)': {
      top: 24,
      left: 20,
    },
  },
  headText: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    fontWeight: 400,
    letterSpacing: 0.3,
    position: 'relative',
    top: -10,
    '@media (max-width: 1024px)': {
      top: 0,
      fontSize: 24,
    },
  },
  clientContent: {
    padding: '20px 40px',
    height: 'calc(100% - 192px)',
    overflow: 'hidden auto',
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 188.3px)',
    },
    '@media (max-width: 767px)': {
      padding: '20px',
      height: 'calc(100% - 148.3px)',
    },
  },
  validateForm: {
    // padding: '20px',
    // background: '#FFFFFF',
    // boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    // borderRadius: 11,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    left: 0,
    bottom: 15,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 20,
  },
  inputFormGroup: {
    width: '100%',
    padding: 0,
    margin: 0,
    '& .MuiGrid-item': {
      padding: 0,
      position: 'relative',
      width: '65%',
    },
  },
  textValInput: {
    marginBottom: 35,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    // '@media (max-width: 525px)': {
    //   width: '100%',
    // },
  },
  inputRoot: {
    width: '100%',
    fontSize: 17,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  backContinueBox: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
}));
