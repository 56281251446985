import React, { useState, useMemo, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { Box, Tabs, TabPanel } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import history from 'common/utils/history';
import {
  getAdminVcFund,
  getAdminVcFirmDocumentsByInvestor,
  reviewVcFundActionByInvestorId,
} from 'services';
import { FN } from 'common/types';

import styles from './styles';
import {
  VcFundOverview,
  VcFundBankDetails,
  VcFundMarketingMaterial,
  //VcFundPricingPlans,
  VcFundLegalRegulatory,
} from './components';

type ComponentProps = {
  match: FN;
};

const VcFunds = ({ match }: ComponentProps) => {
  const classes = styles();
  const fundId: string = get(match, 'params.id');
  const param = useLocation();
  const UrlParamValue: string | null = new URLSearchParams(param.search).get(
    'value',
  );
  const UrlParamStatus: string | null = new URLSearchParams(param.search).get(
    'status',
  );

  const [value, setValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [fundData, setFundData] = React.useState<any>({});
  const [vcFundDocuments, setVcFundDocuments] = useState<any>([]);

  const tabs = useMemo(() => {
    return [
      { name: 'Overview' },
      { name: 'Marketing Material' },
      { name: 'Legal & Regulatory' },
      { name: 'Bank Detail' },
      // { name: 'Pricing Plans' },
    ];
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const getVcFundDetail = () => {
    setIsLoading(true);
    setErrorMessage('');
    getAdminVcFund(fundId)
      .then((res: any) => {
        setFundData(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const getVcFundDocumentsByFundId = () => {
    if (!isEmpty(get(fundData, 'investorId'))) {
      setDocumentLoading(true);
      getAdminVcFirmDocumentsByInvestor(get(fundData, 'investorId'), {
        type: 'FUND',
        vcFundId: get(fundData, 'id'),
      })
        .then((res: any) => {
          setDocumentLoading(false);
          setVcFundDocuments(
            (res.data || []).filter(
              (item: any) => item.relativeId === get(fundData, 'id'),
            ),
          );
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setDocumentLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!isEmpty(fundId)) getVcFundDetail();
  }, [fundId]);

  useEffect(() => {
    getVcFundDocumentsByFundId();
  }, [fundData]);

  useEffect(() => {
    if (
      !isEmpty(get(fundData, 'investorId')) &&
      !isEmpty(UrlParamValue) &&
      !isEmpty(UrlParamStatus)
    ) {
      reviewVcFundActionByInvestorId(
        get(fundData, 'investorId'),
        UrlParamValue,
        fundId,
      ).catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.error(`ERROR WHILE REVIEW VC FUND ACTION: ${message}`);
      });
    }
  }, [fundData]);

  return (
    <Box className={classes.vcFirmContainer}>
      {isEmpty(fundData) && !isLoading ? (
        <Box className={classes.vcFundNotFoundHead}>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.notFoundHead}
          >
            Fund Detail Not Found
          </Typography>
        </Box>
      ) : (
        <>
          <Box className={classes.vcFundHead}>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.labelFundUpHead}
            >
              {!isLoading &&
                `${get(fundData, 'vcFirm.name')} / ${get(
                  fundData,
                  'fundTitle',
                )}`}
              {/* {!isLoading && get(fundData, 'launched') && (
                <Button
                  isLoading={isLoading}
                  className={
                    get(fundData, 'active')
                      ? classes.approvedButton
                      : classes.disapprovedButton
                  }
                  name={get(fundData, 'active') ? 'Approved' : 'Disapproved'}
                  onClick={() => setIsOpen(true)}
                />
              )} */}
            </Typography>
            <Box
              className={classes.avatarInfoRight}
              onClick={() => history.goBack()}
            >
              <KeyboardBackspaceIcon />
            </Box>
          </Box>
          <Box className={classes.vcFirmContentText}>
            <Tabs
              tabs={tabs}
              value={value}
              handleChange={handleChange}
              className={classes.enquiryTabs}
            />
            <TabPanel value={value} index={0} className={classes.tanPanelBox}>
              <VcFundOverview
                isLoading={isLoading}
                errorMessage={errorMessage}
                fundData={fundData}
                fundId={fundId}
              />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tanPanelBox}>
              <VcFundMarketingMaterial
                documentLoading={documentLoading}
                fundData={fundData}
                vcFundDocuments={vcFundDocuments}
              />
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tanPanelBox}>
              <VcFundLegalRegulatory
                documentLoading={documentLoading}
                fundData={fundData}
                vcFundDocuments={vcFundDocuments}
              />
            </TabPanel>
            <TabPanel value={value} index={3} className={classes.tanPanelBox}>
              <VcFundBankDetails
                documentLoading={documentLoading}
                fundData={fundData}
                vcFundDocuments={vcFundDocuments}
              />
            </TabPanel>
            {/* <TabPanel value={value} index={4} className={classes.tanPanelBox}>
              <VcFundPricingPlans fundData={fundData} />
            </TabPanel> */}
          </Box>
        </>
      )}
      {/* {isOpen && (
        <VcFundApproveDialog
          open={isOpen}
          setOpen={setIsOpen}
          fundData={fundData}
          setFundData={setFundData}
          investorId={get(fundData, 'investorId')}
        />
      )} */}
    </Box>
  );
};

export default VcFunds;
