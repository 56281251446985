import React from 'react';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';

import { Box, Button, FileUpload } from 'components';
import { InvestorOnboardStepProps } from 'common/types';
import history from 'common/utils/history';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  adminInvestorAddDocument,
  adminInvestorDocumentSignedUrl,
} from 'services';

import styles from './styles';

const TradeConfirmation = ({
  handleBack,
  investor,
  investmentDetail,
  investmentProcess,
  syncInvestor,
  documents,
  investorId,
  kyc,
}: InvestorOnboardStepProps) => {
  const classes = styles();

  const [loadingSignedUrl, setLoadingSignedUrl] = React.useState<boolean>(
    false,
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = async (fileuploadReq: any): Promise<void> => {
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsLoading(true);
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });

        await adminInvestorAddDocument({
          filename: fileuploadReq.filename,
          investmentDetailId: get(investmentDetail, 'id'),
          documentName: 'WIRE_INSTRUCTION',
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          investorId: get(investor, 'id'),
        });
        await syncInvestor();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload wire instruction.');
    }
  };

  const uploadedFiles = () => {
    if (isEmpty(documents)) return [];
    if (!isEmpty(documents)) {
      return documents.filter(
        (doc: any) => doc.documentName === 'WIRE_INSTRUCTION',
      );
    }
  };

  return (
    <Box>
      <Typography variant="h3" className={classes.investNowFormHeader}>
        Confirmation
      </Typography>
      <Typography className={classes.tCSTxt}>
        Once the funds are sighted, you’ll be notified about the allotment of
        units through your 8vdX dashboard as well as an email.
      </Typography>
      <Typography className={classes.tCSTxt}>
        Upload the wire instructions of the{' '}
        <b>{get(kyc, 'signatoryLegalName')}</b> here. These will be used to make
        distributions from your investments and can be updated later.
      </Typography>

      <div style={{ display: 'flex' }}>
        <FileUpload
          getSignedUrl={adminInvestorDocumentSignedUrl}
          uploadedFiles={uploadedFiles()}
          setLoadingSignedUrl={setLoadingSignedUrl}
          uploadOnSelect={onSubmit}
          disabled={get(investmentProcess, 'wireInstructionUploaded')}
          requestObject={{
            documentName: 'WIRE_INSTRUCTION',
            investmentDetailId: get(investmentDetail, 'id'),
            investorId: get(investor, 'id'),
          }}
          content={
            !get(investmentProcess, 'wireInstructionUploaded') && (
              <Button
                name="Upload Your Wire Instructions"
                className={classes.tCBtn}
                isLoading={loadingSignedUrl || isLoading}
              />
            )
          }
        />
        {!get(investmentProcess, 'wireInstructionUploaded') && (
          <Button
            className={classes.prevButton}
            style={{ width: 150, height: 48, marginLeft: 10 }}
            variant="outlined"
            name="Upload Later"
            onClick={() => history.push(`/admin/investor-view/${investorId}`)}
          />
        )}
      </div>
      {errorMessage && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.errorMessage}
        >
          {errorMessage}
        </Typography>
      )}
      <Box className={classes.nextPrevButtonBox}>
        <Button
          variant="outlined"
          className={classes.prevButton}
          name="Previous"
          disabled={loadingSignedUrl || isLoading}
          onClick={() => handleBack()}
        />
        {get(investmentProcess, 'wireInstructionUploaded') && (
          <Button
            className={classes.nextButton}
            name={'Investor Profile'}
            disabled={loadingSignedUrl || isLoading}
            onClick={() => history.push(`/admin/investor-view/${investorId}`)}
          />
        )}
      </Box>
    </Box>
  );
};
export default TradeConfirmation;
