import { put, takeLatest, call, fork, all } from 'redux-saga/effects';
import { get } from 'lodash';

import {
  submitDocumentsByAdmin,
  getListDoucmentsApi,
  getStartupListDocApi,
  getDocuSignTemplates,
  submitDocuSignDocs,
  // approveDocuments,
  approveRejectDocuments,
  approveLoan,
  manageLoan,
  processLoan,
  approveVDLoanApi,
  approveVDRequest,
} from 'services';
import { ISagaAction } from 'common/types';

import {
  DocumentType,
  ListDocType,
  PayloadType,
  SubmitDocuSignDocsType,
  DocuSignPayload,
  VDApproveType,
} from './Types';
import {
  SUBMIT_DOCUMENTS_FAILURE,
  SUBMIT_DOCUMENTS_SUCCESS,
  SUBMIT_DOCUMENTS,
  SUBMIT_DOCUMENTS_LOADING,
  GET_LIST_DOUCMENTS_SUCCESS,
  GET_LIST_DOUCMENTS_LOADING,
  GET_LIST_DOUCMENTS_FAILURE,
  GET_LIST_DOUCMENTS,
  GET_LIST_DOUCMENTS_STARTUP,
  APPROVE_DOCUMENTS,
  APPROVE_DOCUMENTS_LOADING,
  APPROVE_DOCUMENTS_SUCCESS,
  APPROVE_DOCUMENTS_FAILURE,
  APPROVE_YC_DOCUMENTS,
  APPROVE_YC_DOCUMENTS_LOADING,
  APPROVE_YC_DOCUMENTS_SUCCESS,
  APPROVE_YC_DOCUMENTS_FAILURE,
  APPROVE_LOAN,
  APPROVE_LOAN_LOADING,
  APPROVE_LOAN_SUCCESS,
  APPROVE_LOAN_FAILURE,
  PROCESS_LOAN,
  PROCESS_LOAN_LOADING,
  PROCESS_LOAN_SUCCESS,
  PROCESS_LOAN_FAILURE,
  GET_DOCU_SIGN_DOCUMENTS_ADMIN,
  GET_DOCU_SIGN_DOCUMENTS_ADMIN_SUCCESS,
  GET_DOCU_SIGN_DOCUMENTS_ADMIN_FAILURE,
  GET_DOCU_SIGN_DOCUMENTS_ADMIN_LOADING,
  SUBMIT_DOCU_SIGN_DOCS,
  SUBMIT_DOCU_SIGN_DOCS_LOADING,
  SUBMIT_DOCU_SIGN_DOCS_SUCCESS,
  SUBMIT_DOCU_SIGN_DOCS_FAILURE,
  VD_APPROVE_LOAN,
  VD_APPROVE_LOAN_LOADING,
  VD_APPROVE_LOAN_SUCCESS,
  VD_APPROVE_LOAN_FAILURE,
  APPROVE_VD_REQUEST_ADMIN,
  APPROVE_VD_REQUEST_ADMIN_LOADING,
  APPROVE_VD_REQUEST_ADMIN_SUCCESS,
  APPROVE_VD_REQUEST_ADMIN_FAILURE,
  MANAGE_LOAN_SUCCESS,
  MANAGE_LOAN_FAILURE,
  MANAGE_LOAN_HOLD_REJECT,
  MANAGE_LOAN,
} from './Actions';

type ResponseType = {
  response: any | [];
};

const submitDocuments = async (data: DocumentType) =>
  submitDocumentsByAdmin(data)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const approveVDLoan = async (data: VDApproveType) =>
  approveVDLoanApi(data)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const getListDoucments = async (data: ListDocType) =>
  getListDoucmentsApi(data)
    .then((res) => res)
    .catch((err) => Promise.reject(err));
const getStartupListDocuments = async (data: string) =>
  getStartupListDocApi(data)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const getDocuSignDocuments = async (data: DocuSignPayload) =>
  getDocuSignTemplates(data)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err));

const submitAdminDocuSignDocs = async (data: SubmitDocuSignDocsType) =>
  submitDocuSignDocs(data)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err));

function* handleGetStartupListDocuments(data: ISagaAction<PayloadType>) {
  const { payload } = data;
  try {
    yield put({ type: GET_LIST_DOUCMENTS_LOADING });
    const response: ResponseType = yield call<any>(
      getStartupListDocuments,
      payload,
    );
    yield put({
      type: GET_LIST_DOUCMENTS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: GET_LIST_DOUCMENTS_FAILURE, payload: message });
  }
}

function* handleGetListOfDocuments(data: ISagaAction<ListDocType>) {
  const { payload } = data;
  try {
    yield put({ type: GET_LIST_DOUCMENTS_LOADING });
    const response: ResponseType = yield call<any>(getListDoucments, payload);
    yield put({
      type: GET_LIST_DOUCMENTS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: GET_LIST_DOUCMENTS_FAILURE, payload: message });
  }
}

function* handleSubmitDocuments(data: ISagaAction<DocumentType>) {
  const { payload } = data;
  try {
    yield put({ type: SUBMIT_DOCUMENTS_LOADING });
    const response: ResponseType = yield call<any>(submitDocuments, payload);
    yield put({
      type: SUBMIT_DOCUMENTS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: SUBMIT_DOCUMENTS_FAILURE, payload: message });
  }
}

function* getDocuSignDocs(data: ISagaAction<DocuSignPayload>) {
  const { payload } = data;
  try {
    yield put({ type: GET_DOCU_SIGN_DOCUMENTS_ADMIN_LOADING });
    const response: ResponseType = yield call<any>(
      getDocuSignDocuments,
      payload,
    );
    yield put({
      type: GET_DOCU_SIGN_DOCUMENTS_ADMIN_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({
      type: GET_DOCU_SIGN_DOCUMENTS_ADMIN_FAILURE,
      payload: message,
    });
  }
}

function* handleApproveDocuments(inputData: any) {
  const { payload } = inputData;
  try {
    yield put({
      type: APPROVE_DOCUMENTS_LOADING,
    });
    const {
      documentId,
      enquiryId,
      action,
      loanType,
      startupId,
      callback,
    } = payload;
    const responseData: ResponseType = yield call<any>(approveRejectDocuments, {
      documentId,
      enquiryId,
      // approvedBy,
      loanType,
      startupId,
      action,
    });
    const data = callback(responseData);
    yield put({
      type: APPROVE_DOCUMENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({
      type: APPROVE_DOCUMENTS_FAILURE,
      payload: message,
    });
  }
}

function* handleApproveYCDocuments(inputData: any) {
  const { payload } = inputData;
  try {
    yield put({
      type: APPROVE_YC_DOCUMENTS_LOADING,
    });
    const {
      documentId,
      enquiryId,
      action,
      loanType,
      startupId,
      callback,
    } = payload;
    const responseData: ResponseType = yield call<any>(approveRejectDocuments, {
      documentId,
      enquiryId,
      // approvedBy,
      loanType,
      startupId,
      action,
    });
    callback();
    yield put({
      type: APPROVE_YC_DOCUMENTS_SUCCESS,
      payload: responseData,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({
      type: APPROVE_YC_DOCUMENTS_FAILURE,
      payload: message,
    });
  }
}

function* handleSubmitDocuSign(data: ISagaAction<SubmitDocuSignDocsType>) {
  const { payload } = data;
  try {
    yield put({ type: SUBMIT_DOCU_SIGN_DOCS_LOADING });
    const response: ResponseType = yield call<any>(
      submitAdminDocuSignDocs,
      payload,
    );
    yield put({
      type: SUBMIT_DOCU_SIGN_DOCS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({
      type: SUBMIT_DOCU_SIGN_DOCS_FAILURE,
      payload: message,
    });
  }
}

function* handleApproveLoan(data: any) {
  const { payload } = data;
  try {
    yield put({ type: APPROVE_LOAN_LOADING });
    const response: ResponseType = yield call<any>(approveLoan, payload);
    if (get(response, 'loanStatus', null) === 'APPROVED') {
      yield put({
        type: APPROVE_LOAN_SUCCESS,
        payload: 'Loan approved successfully',
      });
    } else {
      yield put({
        type: APPROVE_LOAN_FAILURE,
        payload: 'Error in approving loan',
      });
    }
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: APPROVE_LOAN_FAILURE, payload: message });
  }
}

function* handleManageLoan(data: any) {
  const { payload } = data;
  try {
    yield put({ type: APPROVE_LOAN_LOADING });
    const response: ResponseType = yield call<any>(manageLoan, payload);
    if (get(response, 'loanStatus', null) === 'APPROVED') {
      yield put({
        type: MANAGE_LOAN_SUCCESS,
        payload: 'Loan approved successfully',
      });
    } else if (
      ['REJECTED', 'ONHOLD'].includes(get(response, 'loanStatus', null))
    ) {
      yield put({
        type: MANAGE_LOAN_HOLD_REJECT,
        payload: `Loan  ${get(response, 'loanStatus')} successfully`,
      });
    } else {
      yield put({
        type: MANAGE_LOAN_FAILURE,
        payload: 'Error in approving loan',
      });
    }
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: MANAGE_LOAN_FAILURE, payload: message });
  }
}

function* handleProcessLoan(data: any) {
  const { payload } = data;
  try {
    yield put({ type: PROCESS_LOAN_LOADING });
    yield call<any>(processLoan, payload);
    yield put({
      type: PROCESS_LOAN_SUCCESS,
      payload: 'Loan processed successfully',
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: PROCESS_LOAN_FAILURE, payload: message });
  }
}

function* handleVDApproveLoan(data: ISagaAction<VDApproveType>) {
  const { payload } = data;
  try {
    yield put({ type: VD_APPROVE_LOAN_LOADING });
    yield call<any>(approveVDLoan, payload);
    yield put({
      type: VD_APPROVE_LOAN_SUCCESS,
      payload: 'Venture Debt loan approved successfully',
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: VD_APPROVE_LOAN_FAILURE, payload: message });
  }
}

function* handleApproveVdRequestAdmin(data: any) {
  const { payload } = data;
  try {
    yield put({ type: APPROVE_VD_REQUEST_ADMIN_LOADING });
    yield call<any>(approveVDRequest, payload);
    yield put({
      type: APPROVE_VD_REQUEST_ADMIN_SUCCESS,
      payload: 'Venture Debt request approved successfully',
    });
  } catch (err) {
    const message: string = get(err, 'response.message');
    yield put({ type: APPROVE_VD_REQUEST_ADMIN_FAILURE, payload: message });
  }
}

function* handleSubmitRequestDocuments() {
  yield takeLatest(SUBMIT_DOCUMENTS, handleSubmitDocuments);
}

function* handleGetListDoucments() {
  yield takeLatest(GET_LIST_DOUCMENTS, handleGetListOfDocuments);
}

function* handleStartupGetListDoucments() {
  yield takeLatest(GET_LIST_DOUCMENTS_STARTUP, handleGetStartupListDocuments);
}

function* handleApproveDocumentsSaga() {
  yield takeLatest(APPROVE_DOCUMENTS, handleApproveDocuments);
}

function* handleYCApproveDocumentsSaga() {
  yield takeLatest(APPROVE_YC_DOCUMENTS, handleApproveYCDocuments);
}

function* handleApproveLoanSaga() {
  yield takeLatest(APPROVE_LOAN, handleApproveLoan);
}

function* handleManageLoanSaga() {
  yield takeLatest(MANAGE_LOAN, handleManageLoan);
}

function* handleProcessLoanSaga() {
  yield takeLatest(PROCESS_LOAN, handleProcessLoan);
}

function* handleAdminDocuSign() {
  yield takeLatest(GET_DOCU_SIGN_DOCUMENTS_ADMIN, getDocuSignDocs);
}

function* handleSubmitDocuSignDocs() {
  yield takeLatest(SUBMIT_DOCU_SIGN_DOCS, handleSubmitDocuSign);
}

function* handleVDApproveLoanSaga() {
  yield takeLatest(VD_APPROVE_LOAN, handleVDApproveLoan);
}

function* handleApproveVdRequestAdminSaga() {
  yield takeLatest(APPROVE_VD_REQUEST_ADMIN, handleApproveVdRequestAdmin);
}

export default function* ProcessSagas() {
  yield all([
    fork(handleSubmitRequestDocuments),
    fork(handleGetListDoucments),
    fork(handleStartupGetListDoucments),
    fork(handleApproveDocumentsSaga),
    fork(handleApproveLoanSaga),
    fork(handleManageLoanSaga),
    fork(handleProcessLoanSaga),
    fork(handleAdminDocuSign),
    fork(handleSubmitDocuSignDocs),
    fork(handleVDApproveLoanSaga),
    fork(handleApproveVdRequestAdminSaga),
    fork(handleYCApproveDocumentsSaga),
  ]);
}
