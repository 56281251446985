import { urlConstants } from 'common/utils/constants';

import { secureApi } from '../api/Axios';

export const getVcUsersDetailsByInvestor = (investorId: string) =>
  secureApi.get(`${urlConstants.getVcUsersDetailsByInvestor}/${investorId}`);

export const getVcFirmDetailsByInvestor = (investorId: string) =>
  secureApi.get(`${urlConstants.getVcFirmDetailsByInvestor}/${investorId}`);

export const adminApprovesVcFirmByInvestorId = (
  investorId: string,
  actionName: string,
) =>
  secureApi.post(
    `${urlConstants.adminApprovesVcfirm}/${investorId}?actionName=${actionName}`,
  );

export const getAdminVcFirmDocumentsByInvestor = (
  investorId: string,
  payload: any,
) =>
  secureApi.post(
    `${urlConstants.getAdminVcFirmDocumentsByInvestor}/${investorId}`,
    payload,
  );

export const adminApproveRejectVcFirmDocuments = (payload: any) =>
  secureApi.post(`${urlConstants.adminApproveRejectVcFirmDocuments}`, payload);

export const adminActivateDeactivateVcFirm = (payload: any) =>
  secureApi.post(`${urlConstants.adminActivateDeactivateVcFirm}`, payload);

export const getAdminAllVcFirms = () =>
  secureApi.get(`${urlConstants.getAdminAllVcFirms}`);

export const getAdminAllVcFunds = () =>
  secureApi.get(`${urlConstants.getAdminAllVcFunds}`);

export const getAdminVcFund = (fundId: string) =>
  secureApi.get(`${urlConstants.getAdminVcFund}/${fundId}`);

export const approveVcFund = (payload: any) =>
  secureApi.post(`${urlConstants.approveVcFund}`, payload);

export const reviewVcFundActionByInvestorId = (
  investorId: string,
  actionName: any,
  id: string,
) =>
  secureApi.post(
    `${urlConstants.reviewVcFundActionByInvestorId}/${investorId}?actionName=${actionName}&vcFundId=${id}`,
  );

// test api
export const getAdminVcFirmFundsByInvestor = (investorId: string) =>
  secureApi.get(`${urlConstants.getAdminVcFirmFundsByInvestor}/${investorId}`);

export const getVcFirmAllBillingHistoryByInvestorId2 = (
  investorId: string,
  vcFundId?: string,
) =>
  vcFundId
    ? secureApi.get(
        `${urlConstants.getVcFirmAllBillingHistoryByInvestorId2}/${investorId}?vcFundId=${vcFundId}`,
      )
    : secureApi.get(
        `${urlConstants.getVcFirmAllBillingHistoryByInvestorId2}/${investorId}`,
      );

export const getAllVcFirmsFundStat = () =>
  secureApi.get(`${urlConstants.getAllVcFirmsFundStat}`);

export const getAllVcFirmsSubscriptionStat = () =>
  secureApi.get(`${urlConstants.getAllVcFirmsSubscriptionStat}`);

export const createUpdateVcYcBatch = (payload: any) =>
  secureApi.post(`${urlConstants.createUpdateVcYcBatch}`, payload);

export const getAllVcYcBatchForAdmin = () =>
  secureApi.get(`${urlConstants.getAllVcYcBatchForAdmin}`);

export const sendVcAdminDealEmailToVcUsers = (payload: any) =>
  secureApi.post(`${urlConstants.sendVcAdminDealEmailToVcUsers}`, payload);
