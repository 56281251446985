import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  adminActionempty: {
    textAlign: 'center',
    paddingTop: 60,
    paddingBottom: 60,
    width: 500,
    margin: 'auto',
  },
  adminActionimg: {
    height: 270,
    marginBottom: 25,
  },
  textHead: {
    fontSize: 20,
    letterSpacing: 0.3,
    color: theme.palette.grey[500],
    maxWidth: 400,
    margin: 'auto',
    marginBottom: 7,
    fontWeight: 400,
  },
  textSub: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.grey[600],
  },
}));
