import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';

import { Loader, Box } from 'components';
import { verifyUser } from 'redux-modules/auth/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { successImage } from 'assets/index';
const useStyles = makeStyles((theme) => ({
  verifyBoxCenter: {
    height: 'calc(100vh - 104px)',
  },
  verifyBoxContent: {
    display: 'block',
    textAlign: 'center',
    padding: '130px 5% 30px',
  },
  successImg: {
    marginBottom: 30,
    height: 250,
  },
  verifySuccessTxt: {
    color: theme.palette.error.contrastText,
    fontSize: 23,
    fontWeight: 600,
    textAlign: 'center',
  },
  verifySuccessSubTxt: {
    color: theme.palette.error.light,
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
  },
  verifyErrorTxt: {
    color: theme.palette.error.main,
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
  },
}));

type Props = {
  match: any;
};

const Verify = ({ match }: Props) => {
  const username: string = get(match, 'params.username');
  const code: string = get(match, 'params.code');
  const dispatch = useDispatch();
  const { isLoading, errorMessage, successMessage } = useSelector(
    ({ Auth }: RootState) => Auth.verifyUser,
  );
  React.useEffect(() => {
    if (username && code) {
      dispatch(verifyUser({ username, code }));
    }
  }, [username, code, dispatch]);
  const classes = useStyles();
  return (
    <Box className={classes.verifyBoxCenter}>
      <Box className={classes.verifyBoxContent}>
        {isLoading && (
          <>
            <Loader />
          </>
        )}
        {errorMessage && (
          <>
            <img
              src={successImage}
              alt={successImage}
              className={classes.successImg}
            />
            <Typography
              variant="h2"
              gutterBottom
              className={classes.verifySuccessTxt}
            >
              {errorMessage}
            </Typography>
          </>
        )}
        {successMessage && (
          <>
            <img
              src={successImage}
              alt={successImage}
              className={classes.successImg}
            />
            <Typography
              variant="h2"
              gutterBottom
              className={classes.verifySuccessTxt}
            >
              {successMessage}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.verifySuccessSubTxt}
            >
              You will be redirected to login page.
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Verify;
