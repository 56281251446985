import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  placeHolder: {
    color: theme.palette.info.light,
    fontSize: '1rem',
    fontWeight: 400,
    textTransform: 'none',
    fontStyle: 'normal',
  },
}));
