import React from 'react';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import { WrappedTextInput, Box, Button } from 'components';
import { handleSetPassword } from 'redux-modules/auth/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { ConfirmPasswordSchema } from 'common/utils/validations';
import { successImage } from 'assets/index';

import styles from './styles';
type Props = {
  match: any;
};

type ConfirmPasswordType = {
  password: string;
  confirmPassword: string;
};

const ConfirmPassword = ({ match }: Props) => {
  const username: string = get(match, 'params.username');
  const code: string = get(match, 'params.code');

  const { isLoading, errorMessage, successMessage } = useSelector(
    ({ Auth }: RootState) => Auth.setPassword,
  );
  const dispatch = useDispatch();

  const onSubmit = ({ password }: ConfirmPasswordType) => {
    dispatch(handleSetPassword({ username, code, password }));
  };

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ConfirmPasswordSchema),
  });

  const classes = styles();

  return (
    <>
      <Box className={classes.confirmPwdBox}>
        {/* {isLoading && <Loader />} */}
        <Box>
          {errorMessage && (
            <Typography variant="body1" className={classes.errorMsgText}>
              {errorMessage}
            </Typography>
          )}
        </Box>
        <Box>
          {successMessage && successMessage.length ? (
            <Box>
              <img
                src={successImage}
                alt={successImage}
                className={classes.successImg}
              />
              <Typography
                variant="h2"
                gutterBottom
                className={classes.welcome8vdx}
              >
                {successMessage}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.welcomeSub8vdx}
              >
                Page will automatically redirect to Login.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="h2"
                gutterBottom
                className={classes.welcome8vdx}
              >
                Reset Password
              </Typography>
              <Typography className={classes.welcomeSub8vdx}>
                Create new password
              </Typography>
              <form
                onSubmit={handleSubmit(onSubmit)}
                data-testid="confirmPasswordForm"
                className={classes.validateForm}
              >
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="password"
                        label="New Password"
                        placeholder="New Password"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'New Password required' }}
                />
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        label="Re type Password"
                        type="password"
                        placeholder="Re type Password"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Password required' }}
                />
                <Button
                  name="Submit"
                  type="submit"
                  className={classes.signupBtn}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </form>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ConfirmPassword;
