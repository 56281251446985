import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import Profile from './Profile';

export const ProfileRoute: IRoute = {
  path: '/startup/profile',
  component: Profile,
  layout: DashboardLayout,
  isAdmin: false,
  exact: true,
};
