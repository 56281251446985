import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  enquiryListBox: {
    padding: '0px 60px',
    height: '100%',
  },
  enquiryListhead: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 85,
    paddingBottom: 10,
  },
  enquiryListLeft: {
    position: 'relative',
    paddingLeft: 10,
  },
  enquiryHeadText: {
    fontSize: 29,
    color: theme.palette.error.contrastText,
    marginBottom: 5,
    position: 'relative',
    top: -20,
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: -44,
    left: -18,
  },
  enquiryListRight: {
    paddingRight: 25,
  },
  referralBtn: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    cursor: 'pointer',
    textTransform: 'capitalize',
    borderRadius: 20,
    fontWeight: 800,
    padding: '6px 16px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    float: 'right',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  filterBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 10,
  },
  searchBox: {
    borderRadius: 30,
    border: '1px solid #E5E5E5',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 257,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  clearIcon: {
    padding: 5,
  },
  inputSearch: {
    width: 'calc(100% - 10px)',
    paddingLeft: 14,
  },
  filterGrid: {
    display: 'flex',
    width: '100%',
    paddingTop: 15,
    paddingBottom: 10,
    borderTop: '1px solid #C4C4C4',
  },
  filterInput: {},
  inputRootFilter: {
    width: '100%',
    fontSize: 14,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  tableContainer: {
    boxShadow: theme.shadows[0],
    height: 'calc(100% - 185px - 20px)',
    overflow: 'overlay',
    paddingBottom: 20,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTable-root': {
      // height: '100%',
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
    '& .MuiTableCell-root': {
      padding: '16px 16px 10px',
    },
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
  tdBtn: {
    border: '1px solid #1EA59A',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    padding: 3,
    cursor: 'pointer',
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  // Add Referral
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    display: 'block',
  },
  successText: {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  textValInput: {
    marginBottom: 20,
    marginTop: 0,
    width: '90%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  autoTextValInput: {
    marginBottom: 20,
    marginTop: 0,
    width: '90%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectValInput: {
    marginBottom: 20,
    width: '90%',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  autoCompleteInputRoot: {
    width: '100%',
    fontSize: 14,
    height: 46,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 14,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 0.35,
    maxWidth: '100%',
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 14,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  backContinueBox: {
    paddingTop: 10,
    paddingRight: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
}));
