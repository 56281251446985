import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

import history from 'common/utils/history';
import { Box, Button, ConfirmDialog } from 'components';
import { RootState } from 'redux-modules/store/rootState';
import { deleteStartupData } from 'services';

import styles from './styles';

const CompanyInfo = ({
  Ornament,
  name,
  link,
  type,
  location,
  startupId,
  isRoleAdmin,
  ycBatch,
}: any) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentYcBatch } = useSelector(({ Auth }: RootState) => Auth);

  const deleteStartup = () => {
    setIsLoading(true);
    deleteStartupData({ startupId })
      .then(() => {
        setDialogOpen(false);
        history.push('/admin/enquiry-list');
      })
      .finally(() => setIsLoading(false));
  };
  const classes = styles();
  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  return (
    <Box className={classes.enquiryListhead}>
      <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
      <Box className={classes.enquiryListheadContent}>
        <Box>
          <AccountCircleIcon className={classes.iconStyleOne} />
        </Box>
        <Box className={classes.gridItemView}>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.labelFundUpHead}
          >
            {name}
            {isRoleAdmin && (
              <Button
                name="Delete"
                className={classes.deleteBtn}
                onClick={() => setDialogOpen(true)}
              />
            )}
          </Typography>
          <Typography
            variant="caption"
            gutterBottom
            className={classes.labelStartUpText}
          >
            {type}
          </Typography>
          <Typography
            variant="caption"
            gutterBottom
            className={classes.labelStartUpText}
          >
            {location}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.labelInfoText}
          >
            {link}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.enquiryListRight}>
        {role !== 'ADMIN' && (
          <Typography
            variant="body1"
            className={classes.rightIconStyle}
            onClick={() => {
              !isEmpty(currentYcBatch) &&
              ycBatch === get(currentYcBatch, 'value')
                ? history.push('/startup/enquiry-form/EDIT')
                : history.push('/startup/vd-enquiry-form/EDIT');
            }}
          >
            <MoreHorizIcon className={classes.moreHoriIcon} />
          </Typography>
        )}
      </Box>

      <ConfirmDialog
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
        dialogHeading="Delete Startup"
        dialogContent="Are you sure you want to delete this startup?"
        isLoading={isLoading}
        onConfirm={deleteStartup}
      />
    </Box>
  );
};

export default CompanyInfo;
