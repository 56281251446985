import React, { useEffect } from 'react';
import { isEmpty, get } from 'lodash';

import { vcProspectiveDealRequiredFields } from 'common/utils/optionList';
import { CsvDealUpload } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  getSignedUrlForVcAdminDealsImport,
  processVcAdminDealsCSVImportFromS3,
} from 'services';
import { FN } from 'common/types';

type Props = {
  openCsvUpload: boolean;
  setOpenCsvUpload: React.Dispatch<React.SetStateAction<boolean>>;
  dealStatus: string;
  columnMapping: Array<Record<string, FN>>;
  sampleFile: string;
  refreshDeals: () => void;
};

const CsvUploadDeal = ({
  openCsvUpload,
  setOpenCsvUpload,
  dealStatus,
  columnMapping,
  sampleFile,
  refreshDeals,
}: Props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [errorData, setErrorData] = React.useState<any>([]);
  const [fileUploadReq, setFileUploadReq] = React.useState<any>({});
  const [successModal, setSuccessModal] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const [selectedColumns, setSelectedColumns] = React.useState<Array<any>>([]);
  const [importUniqueProcess, setImportUniqueProcess] = React.useState<boolean>(
    false,
  );
  const [
    importUniqueFileProcess,
    setImportUniqueFileProcess,
  ] = React.useState<boolean>(false);
  const [duplicateRecord, setDuplicateRecord] = React.useState<Array<any>>([]);

  const checkForExtraColumnAlert = () => {
    let mappedColumns = true;
    selectedColumns.map((item: any) => {
      if (
        !columnMapping.map((x: any) => x.value).includes(item) &&
        item !== '' &&
        item !== '-'
      )
        mappedColumns = false;
    });
    const isOtherColumnUnique: string[] = (selectedColumns || []).filter(
      (item: string) => item === 'Other',
    );
    if (
      !mappedColumns &&
      isEmpty(isOtherColumnUnique) &&
      selectedColumns.length > columnMapping.length - 2
    ) {
      setErrorMessage(
        'Your spreadsheet contains extra columns, you can save min of 1 extra column data and map it to column header “Others” to save that on the portal or skip & continue without saving.',
      );
      return false;
    } else if (!mappedColumns) {
      // setErrorMessage('Columns are not mapped correctly');
      return true;
    }
  };

  const isValidSrNo = () => {
    const mapping: any[] = [];
    if (!isEmpty(tableData)) {
      const indexForSrNo = tableData[0].findIndex(
        (item: any) => item === 'Sr No',
      );

      if (indexForSrNo > -1) {
        tableData.slice(1).map((item: any) => {
          const srNo = item[indexForSrNo];
          const isNumeric = (str: any) => {
            if (typeof str != 'string') return false;
            return (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              !isNaN(str) && !isNaN(parseFloat(str))
            );
          };
          if (!isNumeric(srNo)) {
            mapping.push(srNo);
          }
        });
      }
    }
    return mapping;
  };

  const checkForSameEmailForDifferentCompany = () => {
    if (!isEmpty(tableData)) {
      const indexForCompanyName = selectedColumns.findIndex(
        (item: any) => item === 'Company Name',
      );
      const indexForEmail = selectedColumns.findIndex(
        (item: any) => item === 'Email',
      );
      const mapping: any[] = [];
      if (indexForCompanyName > -1 && indexForEmail > -1) {
        tableData.slice(1).map((item: any) => {
          const companyName = item[indexForCompanyName];
          const email = item[indexForEmail];
          if (companyName !== '-' && email !== '-') {
            mapping.push({ companyName: companyName, email: email });
          }
        });
        let duplicateNotFound = true;
        mapping.reduce((acc: any, curr: any) => {
          if (
            (acc || []).find(
              (item: any) =>
                item.companyName !== curr.companyName &&
                item.email === curr.email,
            )
          ) {
            duplicateNotFound = false;
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);
        return duplicateNotFound;
      }
      return true;
    }
  };

  const handleUploadDeals = async (importUnique = false) => {
    let newSelectedColumns;
    const requiredFields: any[] = [];
    vcProspectiveDealRequiredFields.map((i: any) => {
      const fieldError = (selectedColumns || []).find(
        (item: string) => item === i,
      );
      if (isEmpty(fieldError) && i !== 'Fund Name') {
        requiredFields.push(`${i} column is required`);
      } else if (isEmpty(fieldError) && i === 'Fund Name') {
        requiredFields.push(`${i} column is required`);
      }
    });
    setErrorMessage(
      requiredFields
        .map((item: any) => item)
        .toString()
        .replaceAll(',', ', '),
    );
    if (!isEmpty(requiredFields)) return;

    if (!checkForSameEmailForDifferentCompany()) {
      setErrorMessage(
        'Your file contains similar email ids for different companies',
      );
      return;
    }

    if (!isEmpty(isValidSrNo())) {
      setErrorMessage(
        `${isValidSrNo()
          .map((item: any) => item)
          .toString()
          .replaceAll(',', ', ')} is not a valid Sr No`,
      );
      return;
    }

    if (!isEmpty(selectedColumns) && !isEmpty(columnMapping)) {
      const duplicateContent = selectedColumns.filter(
        (item: any, index: any) =>
          selectedColumns.indexOf(item) != index && item !== '' && item !== '-',
      );
      const emptyColumns = duplicateContent.filter(
        (item: any) => item === 'EMPTY',
      );
      if (
        duplicateContent?.length > 0 &&
        emptyColumns?.length !== duplicateContent?.length
      ) {
        setErrorMessage(
          'Multiple columns found with identical mapping, please use unique mappings for each column',
        );
        return;
      }

      if (checkForExtraColumnAlert() && !isEmpty(requiredFields)) {
        setErrorMessage('Columns are not mapped correctly');
        return true;
      }

      const isOtherColumnUnique: string[] = (selectedColumns || []).filter(
        (item: string) => item === 'Other',
      );
      if (isOtherColumnUnique.length > 1) {
        setErrorMessage('Other column can only be one');
        return;
      }

      const allEmpty = (selectedColumns || []).every(
        (item: string) => item === 'EMPTY' || item === 'Other',
      );
      if (allEmpty) {
        setErrorMessage('All the columns cannot be empty or other');
        return;
      }

      const checkForExtraColumn = selectedColumns.filter(
        (item: any) => !columnMapping.map((x: any) => x.value).includes(item),
      );
      newSelectedColumns = JSON.parse(JSON.stringify(selectedColumns));
      if (!isEmpty(checkForExtraColumn)) {
        let tempSelectedColumns = [];
        tempSelectedColumns = selectedColumns.map((item: any) => {
          if (checkForExtraColumn.includes(item)) {
            return 'EMPTY';
          } else {
            return item;
          }
        });
        newSelectedColumns = tempSelectedColumns;
      }
    }
    const importUniqueDB = importUnique ? importUniqueProcess : false;
    const importUniqueFile = importUnique ? importUniqueFileProcess : false;
    setErrorMessage('');
    setErrorData([]);
    if (!isEmpty(fileUploadReq)) {
      setIsLoading(true);
      try {
        const signedUrlResponse = await getSignedUrlForVcAdminDealsImport({
          filename: get(fileUploadReq, 'filename'),
          dealStatus: dealStatus,
        });
        // eslint-disable-next-line no-undef
        await fetch(get(signedUrlResponse, 'data.uploadUrl'), {
          method: 'PUT',
          body: fileUploadReq.file,
        });
        const oldKeys = tableData[0];
        const fieldMapping = newSelectedColumns
          .map((item: string, index: any) => {
            // if (item.includes('Time of Investment (MM/DD/YYYY)')) {
            //   item = item.replace(
            //     'Time of Investment (MM/DD/YYYY)',
            //     'Time of Investment',
            //   );
            // }
            if (item.includes('Close Date (MM/DD/YYYY)')) {
              item = item.replace('Close Date (MM/DD/YYYY)', 'Close Date');
            }
            return {
              oldKey: oldKeys[index],
              newKey: item,
            };
          })
          .filter((item: any) => item.oldKey !== '' && item.oldKey !== '-');
        await processVcAdminDealsCSVImportFromS3({
          url: get(signedUrlResponse, 'data.accessUrl'),
          dealStatus: dealStatus,
          fieldMapping,
          importUnique: importUniqueDB,
          importUniqueFile: importUniqueFile,
        });
        setSuccessModal(true);
        setImportUniqueProcess(false);
        setImportUniqueFileProcess(false);
        refreshDeals();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        const errorResponse = get(err, 'response.data.error', []);
        if (
          message.includes(
            `Record with email ${errorResponse} from the import matches with your Active Portfolio.`,
          ) ||
          message.includes(
            `Record with email ${errorResponse} from the import matches with your Portfolio.`,
          )
        ) {
          setErrorMessage(
            `${message} You can click on upload again to proceed.`,
          );
          setImportUniqueProcess(true);
          if (!isEmpty(errorResponse)) setDuplicateRecord(errorResponse);
        } else if (
          message.includes(`Duplicate Deal data found in uploaded file.`)
        ) {
          setErrorMessage(
            `${message} You can click on upload again to proceed.`,
          );
          setImportUniqueProcess(true);
          setImportUniqueFileProcess(true);
          if (!isEmpty(errorResponse)) setDuplicateRecord(errorResponse);
        } else {
          setErrorMessage(message);
          if (!isEmpty(errorResponse)) setErrorData(errorResponse);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload file.');
    }
  };

  useEffect(() => {
    checkForExtraColumnAlert();
  }, [selectedColumns]);

  return (
    <>
      <CsvDealUpload
        openCsvUpload={openCsvUpload}
        tableData={tableData}
        setTableData={setTableData}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        successModal={successModal}
        isLoading={isLoading}
        errorMessage={errorMessage}
        errorData={errorData}
        fileUploadReq={fileUploadReq}
        setFileUploadReq={setFileUploadReq}
        title={'Upload Deals'}
        columnMapping={columnMapping}
        sampleFile={sampleFile}
        handleUpload={handleUploadDeals}
        handleClose={() => {
          setOpenCsvUpload(false);
          successModal && setSuccessModal(false);
          setErrorMessage('');
          setErrorData([]);
          importUniqueProcess && setImportUniqueProcess(false);
          importUniqueFileProcess && setImportUniqueFileProcess(false);
          setDuplicateRecord([]);
        }}
        handleRemoveFile={() => {
          setErrorMessage('');
          setErrorData([]);
          importUniqueProcess && setImportUniqueProcess(false);
          importUniqueFileProcess && setImportUniqueFileProcess(false);
          setDuplicateRecord([]);
        }}
        importUniqueProcess={importUniqueProcess || importUniqueFileProcess}
        confirmationContent={`Do you want to skip importing ${duplicateRecord.length} deals from your file?`}
        duplicateRecord={duplicateRecord}
      />
    </>
  );
};

export default CsvUploadDeal;
