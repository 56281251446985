import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';

import { Dialog, Box, FileUpload } from 'components';
import { VC_ADMIN_DOCUMENT_NAME } from 'common/utils/constants';

import styles from './styles';

type Props = {
  isOpen: any;
  setIsOpen: React.Dispatch<React.SetStateAction<any>>;
  selectedFiles: any;
  setSelectedFiles: any;
};

const AddOtherDocument = ({
  isOpen,
  setIsOpen,
  setSelectedFiles,
  selectedFiles,
}: Props) => {
  const classes = styles();
  const [fileName, setFileName] = React.useState<string>('');

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        subheading={''}
        title={'Upload document'}
        handleClose={() => setIsOpen(false)}
      >
        <Grid container spacing={3} className={classes.inputFundBox}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.labelText}
            >
              File Name
            </Typography>
            <TextField
              autoComplete="off"
              fullWidth
              size="small"
              variant="standard"
              placeholder="Enter Document Name"
              className={classes.textValInput}
              InputProps={{
                classes: { root: classes.inputRoot },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              onChange={(e) => setFileName(e.target.value)}
              name="documentName"
            />
          </Grid>
        </Grid>
        <FileUpload
          fileExtensions={[
            'pdf',
            'pptx',
            'ppt',
            'xlsx',
            'csv',
            'text/csv',
            'doc',
            'docx',
          ]}
          requestObject={{
            documentName: VC_ADMIN_DOCUMENT_NAME.OTHER_DOC,
          }}
          fileSize={20971520} // bytes in decimal
          showSelectedFilePreview={false}
          uploadOnSelect={false}
          callGetSignedUrl={false}
          onSuccess={(req: any) => {
            const fileExt = req.filename.split('.').pop();
            const obj = {
              ...req,
              filename: fileName ? fileName.concat('.', fileExt) : req.filename,
            };
            setSelectedFiles([...selectedFiles, obj]);
            setIsOpen(false);
          }}
          content={
            <Box className={classes.uploadFieldContainer}>
              <Box>
                <Typography className={classes.uploadText}>
                  Drag & Drop
                </Typography>
                <Typography className={classes.uploadInnerText}>
                  Browse to upload file here
                </Typography>
                <Typography className={classes.uploadFormatText}>
                  only pdf, ppt, csv, xlsx and doc file with max size of 10 MB
                </Typography>
              </Box>
            </Box>
          }
        />
      </Dialog>
    </>
  );
};

export default AddOtherDocument;
