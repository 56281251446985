import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';
import { get, groupBy, isEmpty, orderBy, uniqBy, find } from 'lodash';
import { useDispatch } from 'react-redux';

import { Box, Button, WrappedSelect, WrappedTextInput } from 'components';
import {
  frequencyList,
  businessModalList,
  KPIsListV2,
} from 'common/utils/optionList';
import {
  createOrUpdateStartupMultipleKpiData,
  deleteStartupBenchmarkKpi,
  addStartupBenchmarkInfo,
  getStartupBenchmarkingKpi,
  updateStartupInformation,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { getStartupInfo } from 'redux-modules/enquiries/Actions';

import styles from './styles';
import KPITable from '../KPITable';
import YardstickRequests from './YardStickRequests';

const BenchMarkKpiData = ({
  role,
  startUpId,
  errorMessage,
  setErrorMessage,
  startupDetails,
  setViewOutput,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { control, setValue } = useForm({
    mode: 'onChange',
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [kpiList, setKpiList] = React.useState<any>([]);
  const [benchmarkKpiList, setBenchmarkKpiList] = React.useState<any>([]);
  const [benchmarkKpiRows, setBenchmarkKpiRows] = React.useState<any>([]);
  const [selectedFrequency, setSelectedFrequency] = React.useState<number>(
    get(startupDetails, 'benchmarkKPIFrequency', 1),
  );
  const [benchmarkColumn, setBenchmarkColumn] = React.useState<any>([]);
  const [noKpiAdded, setNoKpiAdded] = React.useState<boolean>(false);
  const [benchmarkCmgrKpiRows, setBenchmarCmgrkKpiRows] = React.useState<any>(
    [],
  );

  const { businessModel, fullName: startupFullName } = startupDetails;

  const [openSidePane, setOpenSidePane] = useState<boolean>(false);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const access = query.get('access');
    const investor = query.get('investor');
    if (access && investor) {
      setOpenSidePane(true);
    }
  }, []);

  const getAllMonths = (start: any, end: any) => {
    const data: any = [];
    Array.from({
      length: end.diff(start, 'months'),
    }).forEach((_, index) => {
      if (index % selectedFrequency === 0) {
        const newDate = moment(start).add(index + selectedFrequency, 'months');
        if (moment(end).isSameOrAfter(newDate)) {
          data.push({
            name: `${moment(newDate).format('M_YYYY')}`,
            title: moment(newDate).format('MMM-YYYY'),
            width: 150,
            align: 'center',
          });
        }
      }
    });
    return data.reverse();
  };

  const getBenchmarkColumn = () => {
    const benchmarkColumns = [
      { name: 'kpiName', title: 'KPI', width: 150, align: 'left' },
      { name: 'info', title: '', width: 55, align: 'center' },
      { name: 'prev', title: '', width: 55, align: 'right' },
      { name: 'next', title: '', width: 55, align: 'left' },
      { name: '#', title: 'Actions', width: 100, align: 'left' },
    ];
    benchmarkColumns.splice(
      3,
      0,
      ...getAllMonths(moment('12-2020', 'MM-YYYY'), moment()),
    );
    setBenchmarkColumn(benchmarkColumns);
  };

  const getStartupBenchmarkKpiAllData = () => {
    setIsLoading(true);
    setErrorMessage('');
    Promise.all([getStartupBenchmarkingKpi(startUpId, role)])
      .then(([benchmarkData]: any) => {
        setBenchmarkKpiList(orderBy(benchmarkData, ['isPrimary'], ['desc']));
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const createRowArrayForKPITableFormat = (
    mainArrayList: any,
    keyToGroupBy: string,
  ) => {
    const groupedObject = groupBy(mainArrayList, keyToGroupBy);
    const formattedArray = Object.entries(groupedObject).map(
      ([key, value]: any) => {
        return {
          kpiName: key,
          kpiValues: value.map((kpiObj: any) => {
            return {
              ...kpiObj,
              key: `${kpiObj.month}_${kpiObj.year}`,
            };
          }),
          isEdit: false,
          isPrimary: get(value, '[0].isPrimary'),
        };
      },
    );
    return formattedArray;
  };

  const handleSaveMultipleRow = (columnKeyName: string) => {
    setErrorMessage('');
    setNoKpiAdded(false);
    const kpiRows =
      columnKeyName === 'kpiName'
        ? benchmarkKpiRows.filter((row: any) => row.isEdit)
        : benchmarkCmgrKpiRows.filter((row: any) => row.isEdit);
    if (
      columnKeyName === 'kpiName' &&
      uniqBy(benchmarkKpiRows, 'kpiName').length !== benchmarkKpiRows.length
    ) {
      setErrorMessage(
        'Please add different KPI name. Duplicate KPI will not allowed.',
      );
    } else if (isEmpty(get(kpiRows.slice(-1)[0], 'kpiValues'))) {
      setNoKpiAdded(true);
    } else {
      const kpiData = kpiRows.flatMap((row: any) => get(row, 'kpiValues', []));
      const payload = kpiData
        .filter((obj: any) => obj.id || obj.kpiValue)
        .map((data: any) => {
          return {
            ...data,
            kpiValue: parseInt(data.kpiValue?.replace(/,/g, '') || 0),
            startupId: startUpId,
            updateCmgrValue: columnKeyName === 'cmgrKpiName',
          };
        });
      onUpdateKpiDataCall(payload);
    }
  };

  const onUpdateKpiDataCall = (payload: any) => {
    setErrorMessage('');
    payload = orderBy(
      payload,
      [(obj: any) => Number(obj.year), (obj: any) => Number(obj.month)],
      ['asc', 'asc'],
    );

    if (!isEmpty(payload)) {
      createOrUpdateStartupMultipleKpiData(payload, role)
        .then(() => {
          getStartupBenchmarkKpiAllData();
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  const handleAddBenchmarkKpiRow = () => {
    let newData: any[] = [...benchmarkKpiRows];
    const primaryKPIAdded = find(benchmarkKpiRows, { isPrimary: true });
    if (!isEmpty(primaryKPIAdded)) {
      newData.push({
        kpiName: '',
        kpiValues: [],
        isEdit: true,
        isNewRow: true,
      });
    } else {
      newData = [
        {
          kpiName: '',
          kpiValues: [],
          isEdit: true,
          isNewRow: true,
        },
        ...benchmarkKpiRows,
      ];
    }
    setBenchmarkKpiRows(newData);
  };

  const handleFrequencyChange = (event: any) => {
    const frequency = event.target.value as number;
    setSelectedFrequency(frequency);
    const obj = {
      benchmarkKPIFrequency: frequency,
    };
    addStartupBenchmarkInfo(obj)
      .then((res) => {
        dispatch(getStartupInfo());
        // eslint-disable-next-line no-console
        console.log('BENCHMARK_INFO', res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const checkIsEditModeOpen = (columnKeyName?: string) => {
    let isEditMode = false;
    if (
      columnKeyName === 'kpiName' &&
      !isEmpty(benchmarkKpiRows.find((row: any) => row.isEdit))
    )
      isEditMode = true;
    else if (
      columnKeyName === 'cmgrKpiName' &&
      !isEmpty(benchmarkCmgrKpiRows.find((row: any) => row.isEdit))
    )
      isEditMode = true;
    else if (
      columnKeyName === 'both' &&
      (benchmarkKpiRows.find((row: any) => row.isEdit) ||
        benchmarkCmgrKpiRows.find((row: any) => row.isEdit))
    )
      isEditMode = true;
    return isEditMode;
  };

  const saveStartupData = async (field: string, value: string) => {
    await updateStartupInformation({
      startupFullName,
      startupId: startUpId,
      [field]: value,
    });
    dispatch(getStartupInfo());
  };

  useEffect(() => {
    if (startUpId) {
      getStartupBenchmarkKpiAllData();
    }
  }, []);

  useEffect(() => {
    getBenchmarkColumn();
    setKpiList(KPIsListV2);
  }, []);

  useEffect(() => {
    setBenchmarkKpiRows(
      createRowArrayForKPITableFormat(benchmarkKpiList, 'kpiName'),
    );
    setBenchmarCmgrkKpiRows(
      createRowArrayForKPITableFormat(benchmarkKpiList, 'cmgrKpiName'),
    );
  }, [benchmarkKpiList]);

  useEffect(() => {
    if (get(startupDetails, 'benchmarkKPIFrequency') !== selectedFrequency)
      setSelectedFrequency(get(startupDetails, 'benchmarkKPIFrequency'));
  }, [startupDetails]);

  useEffect(() => {
    getBenchmarkColumn();
  }, [selectedFrequency]);

  useEffect(() => {
    setValue('businessModel', businessModel);
    setValue('startupFullName', startupFullName);
  }, [businessModel, startupFullName]);

  return (
    <Box className={classes.benchMarkContainer}>
      {!isEmpty(startUpId) && (
        <Box className={classes.benchMarkKPIContent}>
          <Box className={classes.startupKPIDataBox}>
            <Typography className={classes.kpiDataLabelText}>
              Yardstick <span>Light</span>
            </Typography>

            <Box className={classes.selectFrequBox}>
              <WrappedSelect
                variant={'outlined'}
                value={
                  selectedFrequency ||
                  get(startupDetails, 'benchmarkKPIFrequency')
                }
                label="Select Frequency"
                onChange={(e: ChangeEvent<any>) => handleFrequencyChange(e)}
                className={classes.textValInputFreq}
                InputProps={{
                  classes: { root: classes.inputRootFreq },
                }}
                InputLabelProps={{}}
                SelectProps={{
                  MenuProps: {
                    classes: { paper: classes.menuPaper },
                  },
                }}
                dropdowns={frequencyList}
                disabled={!isEmpty(benchmarkKpiList)}
                error={false}
              />
              <Button
                className={classes.addKpiButton}
                name="Requests"
                onClick={() => setOpenSidePane(true)}
              />
            </Box>
          </Box>
          {errorMessage && (
            <Typography
              variant="body1"
              gutterBottom
              className={classes.errorMessage}
            >
              {errorMessage}
            </Typography>
          )}
          <form data-testid="StartupBasicForm">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Full legal name of your company*
                </Typography>
                <Controller
                  name="startupFullName"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Enter Company Name"
                        className={classes.textValInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={() => saveStartupData('startupFullName', value)}
                        error={error?.message ? true : false}
                        disabled={!isEmpty(startupFullName)}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Company full name is required' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Business Model*
                </Typography>
                <Controller
                  name="businessModel"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedSelect
                        value={value}
                        onChange={(e: any) => {
                          onChange(e);
                          saveStartupData('businessModel', e.target.value);
                        }}
                        placeholder="Select Business Model"
                        className={classes.selectValInput}
                        InputProps={{
                          name: 'Business Model',
                          id: 'business-model',
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                        dropdowns={businessModalList}
                        disabled={!isEmpty(businessModel)}
                      />
                      {error?.message && (
                        <span className={classes.errorSelectText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </form>
          {noKpiAdded && (
            <Grid container className={classes.enterKpiDetailsContainer}>
              <Grid item style={{ display: 'inherit' }}>
                <InfoOutlinedIcon className={classes.infoIconRed} />
                <Typography>Please enter KPI Details</Typography>
              </Grid>
            </Grid>
          )}
          {isLoading ? (
            <Box className={classes.loader}>
              <CircularProgress color="inherit" size={40} />
            </Box>
          ) : (
            <>
              {/* {isEmpty(benchmarkKpiList) && (
                <Grid container className={classes.enterKpiTextContainer}>
                  <Grid item style={{ display: 'inherit' }}>
                    <InfoOutlinedIcon className={classes.infoIcon} />
                    <Typography>
                      Enter the company's latest KPIs to view its aggregate
                      growth rate.
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container className={classes.enterKpiTextContainer}>
                <Grid item style={{ display: 'inherit' }}>
                  <InfoOutlinedIcon className={classes.infoIcon} />
                  <Typography>
                    {businessModel === 'B2B SaaS'
                      ? 'Enter your latest Monthly Recurring Revenue (MRR) to arrive at the appropriate benchmark.'
                      : 'Enter your latest Monthly Revenue to arrive at the appropriate benchmark.'}
                  </Typography>
                </Grid>
              </Grid> */}
              <Box className={classes.kpiTableDataBox}>
                <KPITable
                  tableClassName="benchmark-kpi-table-container"
                  columnKeyName="kpiName"
                  columnValueKeyName="kpiValue"
                  originalKpiRows={createRowArrayForKPITableFormat(
                    benchmarkKpiList,
                    'kpiName',
                  )}
                  kpiRows={benchmarkKpiRows}
                  setKpiRows={setBenchmarkKpiRows}
                  kpiColumns={benchmarkColumn}
                  kpiList={kpiList}
                  startupId={startUpId}
                  role={role}
                  onUpdateCall={onUpdateKpiDataCall}
                  deleteBenchmarkKpi={deleteStartupBenchmarkKpi}
                  onDeleteCall={() => {
                    getStartupBenchmarkKpiAllData();
                  }}
                  setNoKpiAdded={setNoKpiAdded}
                  selectedFrequency={get(
                    startupDetails,
                    'benchmarkKPIFrequency',
                  )}
                />
                {checkIsEditModeOpen('kpiName') && (
                  <Button
                    className={classes.addKpiButton}
                    name="Save"
                    onClick={() => handleSaveMultipleRow('kpiName')}
                  />
                )}
                <Button
                  className={classes.addKpiButton}
                  name="Add KPI"
                  startIcon={<AddIcon />}
                  onClick={handleAddBenchmarkKpiRow}
                  disabled={
                    benchmarkKpiRows.length === kpiList.length ||
                    isEmpty(startupFullName) ||
                    isEmpty(businessModel) ||
                    (isEmpty(
                      benchmarkKpiList.find((kpi: any) => kpi.isPrimary),
                    ) &&
                      !isEmpty(
                        benchmarkKpiRows.find((row: any) => row.isNewRow),
                      ))
                  }
                />
              </Box>
            </>
          )}
          {!isEmpty(benchmarkKpiList) && (
            <>
              <Box className={classes.startupKPIDataBox}>
                <Typography className={classes.kpiDataLabelText}>
                  Cumulative Monthly Growth Rates (CMGR)
                </Typography>
              </Box>
              <Grid container className={classes.enterKpiTextContainer}>
                <Grid item style={{ display: 'inherit' }}>
                  <InfoOutlinedIcon className={classes.infoIcon} />
                  <Typography>
                    Review your Compounded Monthly Growth Rate
                  </Typography>
                </Grid>
              </Grid>
              <Box className={classes.kpiTableDataBox}>
                <KPITable
                  tableClassName="benchmark-cmgr-kpi-table-container"
                  columnKeyName="cmgrKpiName"
                  columnValueKeyName="cmgrKpiValue"
                  originalKpiRows={createRowArrayForKPITableFormat(
                    benchmarkKpiList,
                    'cmgrKpiName',
                  )}
                  kpiRows={benchmarkCmgrKpiRows}
                  setKpiRows={setBenchmarCmgrkKpiRows}
                  kpiColumns={benchmarkColumn}
                  kpiList={kpiList}
                  startupId={startUpId}
                  role={role}
                  onUpdateCall={onUpdateKpiDataCall}
                  deleteBenchmarkKpi={deleteStartupBenchmarkKpi}
                  onDeleteCall={getStartupBenchmarkKpiAllData}
                  setNoKpiAdded={setNoKpiAdded}
                  selectedFrequency={get(
                    startupDetails,
                    'benchmarkKPIFrequency',
                  )}
                />
                {checkIsEditModeOpen('cmgrKpiName') && (
                  <Button
                    className={classes.addKpiButton}
                    name="Save"
                    onClick={() => handleSaveMultipleRow('cmgrKpiName')}
                  />
                )}
              </Box>
            </>
          )}
          <Box className={classes.saveBtnContainer}>
            <Button
              className={classes.continueBtn}
              name={`View Outputs`}
              isLoading={isLoading}
              disabled={
                isLoading ||
                isEmpty(benchmarkKpiList) ||
                checkIsEditModeOpen('both')
              }
              onClick={() => {
                if (isEmpty(startupFullName) || isEmpty(businessModel)) {
                  setErrorMessage(
                    'Please fill the required company name and Business Model',
                  );
                } else {
                  setErrorMessage('');
                  setViewOutput(true);
                }
              }}
            />
            {/* <Button
              variant="outlined"
              className={classes.resetBtn}
              name="Back"
              disabled={isLoading}
              onClick={() => handleBack()}
            /> */}
          </Box>
        </Box>
      )}
      <YardstickRequests
        openSidePane={openSidePane}
        onCloseSidePan={() => setOpenSidePane(false)}
      />
    </Box>
  );
};

export default BenchMarkKpiData;
