import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

type anchorOriginType = {
  vertical: 'bottom' | 'top';
  horizontal: 'center' | 'left' | 'right';
};

type SnackbarType = {
  open: boolean;
  handleClose?: any;
  title: string;
  type: 'success' | 'error' | 'warning' | 'info';
  anchorOrigin: anchorOriginType;
};

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SnackBar = ({
  open,
  handleClose,
  title,
  type,
  anchorOrigin,
}: SnackbarType) => {
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={anchorOrigin}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type}>
          {title}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackBar;
