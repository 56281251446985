import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Collapse,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';
import { isEmpty, get, orderBy } from 'lodash';

import {
  Box,
  StyledTableCell,
  StyledTableRow,
  Loader,
  Button,
} from 'components';
import { formatAmount, getVcPortalCountryObject } from 'common/utils/helpers';
import history from 'common/utils/history';

import styles from '../styles';

const headers = (array: any[]) => {
  return (
    <>
      {array.map((i: any, n: number) => {
        return (
          <StyledTableCell
            key={n}
            style={{
              textAlign: i.textAlign ? i.textAlign : 'left',
            }}
          >
            {i.name}
          </StyledTableCell>
        );
      })}
    </>
  );
};

const TABLE_HEADERS = [
  { name: 'Firm Name', textAlign: 'left' },
  { name: 'Fund Name', textAlign: 'left' },
  {
    name: 'Fund Size',
    textAlign: 'right',
  },
  { name: 'Fund Raised', textAlign: 'right' },
  { name: 'Strategy', textAlign: 'left' },
  { name: 'Investors', textAlign: 'right' },
  { name: 'Status', textAlign: 'left' },
  { name: 'Geography', textAlign: 'left' },
  { name: 'Action', textAlign: 'left' },
];

const VcFirmTable = ({ filteredSearchData, isLoading, errorMessage }: any) => {
  const classes = styles();

  const [isOpenCollapse, setIsOpenCollapse] = useState(null);

  const handleOpen = (clickedIndex: any) => {
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  return (
    <Box className={classes.firmAdminTableBox}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table
          stickyHeader
          style={{ tableLayout: 'fixed' }}
          aria-label="colylapsible table"
        >
          <TableHead>
            <TableRow>{headers(TABLE_HEADERS)}</TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={TABLE_HEADERS.length}>
                  <Box className={classes.loader}>
                    <Loader />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : isEmpty(filteredSearchData) && !errorMessage ? (
              <StyledTableRow>
                <StyledTableCell colSpan={TABLE_HEADERS.length}>
                  <Box className={classes.loader}>
                    <Typography>No data found in table</Typography>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              (filteredSearchData || []).map((row: any, index: number) => (
                <>
                  <StyledTableRow
                    style={
                      index % 2
                        ? { background: '#FFF' }
                        : { background: '#F8F8F8' }
                    }
                    key={`row_${index}`}
                  >
                    <StyledTableCell
                      colSpan={TABLE_HEADERS.length - 1}
                      // className={classes.vHeadBg}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpen(index)}
                      >
                        {isOpenCollapse === index ? (
                          <RemoveSharpIcon />
                        ) : (
                          <AddIcon />
                        )}
                      </IconButton>
                      <span
                        className={classes.vTitleSpacing}
                        onClick={() =>
                          history.push(
                            `/admin/vc-firms/${get(
                              row,
                              'investorId',
                            )}?value=VC_FIRM_REVIEW`,
                          )
                        }
                      >
                        {get(row, 'name')}{' '}
                        {`(${(get(row, 'vcFunds') || []).length})`}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell colSpan={1}>
                      <Button
                        name="View"
                        className={classes.actionVcView}
                        onClick={() =>
                          history.push(
                            `/admin/vc-firms/${get(
                              row,
                              'investorId',
                            )}?value=VC_FIRM_REVIEW`,
                          )
                        }
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={TABLE_HEADERS.length}
                      className={classes.vNoSpacing}
                    >
                      <Collapse
                        in={isOpenCollapse === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Table
                          style={{ tableLayout: 'fixed' }}
                          className={classes.vCollapseTable}
                        >
                          {isEmpty(get(row, 'vcFunds')) && (
                            <StyledTableRow>
                              <StyledTableCell colSpan={TABLE_HEADERS.length}>
                                No data found
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                          {orderBy(
                            get(row, 'vcFunds') || [],
                            ['createdAt'],
                            ['desc'],
                          ).map((item: any) => (
                            <StyledTableRow key={`row2_${index}`}>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                              ></StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={{ textAlign: 'left' }}
                              >
                                {get(item, 'fundTitle')}
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={{ textAlign: 'right' }}
                              >
                                {formatAmount(get(item, 'targetFundSize'), {
                                  currency:
                                    get(item, 'currency', 'USD') || 'USD',
                                })}
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={{ textAlign: 'right' }}
                              >
                                {formatAmount(get(item, 'raisedFundAmount'), {
                                  currency:
                                    get(item, 'currency', 'USD') || 'USD',
                                })}
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={{ textAlign: 'left' }}
                              >
                                {get(item, 'fundStrategy')}
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={{ textAlign: 'right' }}
                              >
                                {get(item, 'vcInvestors').length}
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={{ textAlign: 'left' }}
                              >
                                {get(item, 'closed')
                                  ? 'Final Closed'
                                  : get(item, 'firstClosed')
                                  ? 'First Closed'
                                  : get(item, 'launched') && get(item, 'active')
                                  ? 'Launched'
                                  : 'In Progress'}
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={{ textAlign: 'left' }}
                              >
                                {get(
                                  getVcPortalCountryObject(
                                    get(item, 'geography'),
                                  ),
                                  'country',
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                className={classes.actionButtonBox}
                                style={{ paddingLeft: 2 }}
                              >
                                <Button
                                  name="View"
                                  className={classes.viewButton}
                                  onClick={() =>
                                    history.push(
                                      `/admin/vc-funds/${get(item, 'id')}`,
                                    )
                                  }
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </Table>
                      </Collapse>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VcFirmTable;
