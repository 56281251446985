import {
  GET_VD_INSTALLMENTS_LOADING,
  GET_VD_INSTALLMENTS_SUCCESS,
  GET_VD_INSTALLMENTS_FAILURE,
  EMPTY_SUCCESS_MESSAGE,
} from './Actions';
import { VdInstallmentsState } from './Types';

export const VdInstallmentsInitialState: VdInstallmentsState = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  vdInstallmentsList: [],
};

export const VdInstallmentsReducer = (
  state = VdInstallmentsInitialState,
  action: any,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VD_INSTALLMENTS_LOADING:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_VD_INSTALLMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: '',
        errorMessage: '',
        vdInstallmentsList: payload,
      };
    case GET_VD_INSTALLMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        successMessage: '',
        errorMessage: payload,
      };
    case EMPTY_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: '',
      };
    default:
      return state;
  }
};
