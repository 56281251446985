import React from 'react';
import { Route } from 'react-router-dom';

import { IRoute } from 'common/types';

type RouteWrapper = IRoute;

const PublicRouteWrapper = ({
  layout: Layout,
  component: Component,
  ...props
}: RouteWrapper) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  });

  return (
    <>
      <Route
        {...props}
        render={(matchProps) => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    </>
  );
};

export default PublicRouteWrapper;
