import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Typography, MenuItem } from '@material-ui/core';

import { RootState } from 'redux-modules/store/rootState';
import { handleGetStartUpDetails } from 'redux-modules/enquiryForm/Actions';
import {
  trackBridgeLoanProcess,
  trackVdProcess,
  handleGetRepaidAmountAction,
  getLoanCloserInfo,
} from 'redux-modules/enquiries/Actions';
import { getDocumentlist } from 'redux-modules/process/Actions';
import ReviewDocuments from 'components/ReviewDocumentsList';
import {
  Box,
  Tabs,
  TabPanel,
  CompanyInfo,
  CompanyOverview,
  Founder,
  Loader,
} from 'components';
import { FaceBook, Twitter, LinkedIn, Ornament } from 'assets';
import history from 'common/utils/history';
import { ListDocType } from 'redux-modules/process/Types';
import {
  getIncorporationCountryObject,
  getStateObject,
} from 'common/utils/helpers';

import Payments from '../Payments';
import SafeTracking from '../SafeTracking/SafeTracking';
import styles from './styles';

const Profile = () => {
  const dispatch = useDispatch();
  const env = process.env.REACT_APP_ENV || 'dev';
  const getTabValue = () => {
    if (history.location.search) {
      const search = history.location.search;
      const tabValue: any = new URLSearchParams(search).get('value') || 0;
      return Number(tabValue);
    }

    return Number(0);
  };

  const search = history.location.search;
  const tab: any = new URLSearchParams(search).get('tab');

  const [value, setValue] = useState<number>(getTabValue());
  const [fundType, setFundType] = useState<string>('');

  const { startUpId } = useSelector(({ Auth }: RootState) => Auth.user);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getLoanCloserInfo(startUpId));
  }, [startUpId]);

  const { startupInfo } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const {
    startupDetails,
    //additionalInformation,
    // businessDetails,
    founderDetails,
    isLoading,
    // fundType,
    productDescription,
    productProgress,
    potentialMarketTarget,
    startupInvestmentInformation,
    startupLegalInformation,
  } = useSelector(({ EnquiryForm }: RootState) => EnquiryForm);

  const { bridgeLoanProcess, vdProcess } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  useEffect(() => {
    if (tab && tab === 'safe' && !isEmpty(bridgeLoanProcess)) {
      setValue(2);
    }
  }, [tab, bridgeLoanProcess]);

  const {
    // startupName,
    // startupLink,
    startupName,
    //startupFullName,
    startupWebLink,
    stateOfIncorporation,
    ycIncorporationCountry,
    industryType,
    // description,
    // businessModel,
    startupTwitterUrl,
    startupFacebookUrl,
    startupLinkedInUrl,
    ycBatch,
  } = startupDetails;

  useEffect(() => {
    dispatch(handleGetStartUpDetails(startUpId));
  }, [dispatch, handleGetStartUpDetails]);

  useEffect(() => {
    dispatch(trackBridgeLoanProcess(startUpId));
    dispatch(trackVdProcess(startUpId)); // TODO: Need to fix as per fundtype

    dispatch(handleGetRepaidAmountAction(`loanType=BRIDGE_LOAN`));
  }, []);

  useEffect(() => {
    if (value === 1 && fundType) {
      const obj: ListDocType = {
        startupId: startUpId,
        loanType: fundType,
      };
      dispatch(getDocumentlist(obj));
    }
  }, [value]);

  useEffect(() => {
    if (vdProcess || bridgeLoanProcess) {
      const isVdDocumentsSigned = get(vdProcess, 'documentsSigned', false);
      const loanType = isVdDocumentsSigned ? 'VENTURE_DEBT' : 'BRIDGE_LOAN';
      setFundType(loanType);
    }
  }, [bridgeLoanProcess, vdProcess]);

  const getTabs = () => {
    if (get(bridgeLoanProcess, 'isLoanApproved')) {
      const options = [
        { name: 'Overview' },
        { name: 'Documents' },
        { name: 'SAFEs' },
        { name: 'Payments' },
      ];
      if (env !== 'prod') {
        return options;
      } else {
        return [
          { name: 'Overview' },
          { name: 'Documents' },
          { name: 'Payments' },
        ];
      }
    }
    return [{ name: 'Overview' }, { name: 'Documents' }, { name: 'Payments' }];
  };

  const classes = styles();
  return (
    <Box className={classes.enquiryViewContainer}>
      {isLoading && <Loader />}
      <Box className={classes.enquiryViewBox}>
        <Box
          className={[
            value !== 2
              ? classes.enquiryViewBoxLeft
              : classes.enquiryViewBoxLeftFullWidth,
          ]}
        >
          <CompanyInfo
            name={startupName || get(startupInfo, 'name')}
            link={startupWebLink}
            location={`${
              getStateObject(stateOfIncorporation, ycIncorporationCountry)
                ?.name || ''
            }${stateOfIncorporation ? ', ' : ''}${
              getIncorporationCountryObject(ycIncorporationCountry)?.country ||
              ''
            }${ycIncorporationCountry ? '.' : ''}`}
            Ornament={Ornament}
            type={
              industryType
                ? industryType
                : get(startupInfo, 'industryType') || ''
            }
            ycBatch={ycBatch || get(startupInfo, 'ycBatch')}
          />
          <Box className={classes.enquiryViewTabBox}>
            <Tabs
              tabs={getTabs()}
              value={value}
              handleChange={handleChange}
              className={classes.enquiryTabs}
            />
            <TabPanel value={value} index={0} className={classes.tanPanelBox}>
              <CompanyOverview
                //additionalInformation={additionalInformation}
                // businessDetails={businessDetails}
                // description={description}
                // businessModel={businessModel}
                startupDetails={startupDetails}
                founderDetails={founderDetails}
                productDescription={productDescription}
                productProgress={productProgress}
                potentialMarketTarget={potentialMarketTarget}
                startupInvestmentInformation={startupInvestmentInformation}
                startupLegalInformation={startupLegalInformation}
              />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
              <CompanyProcess startupId={startUpId} />
            </TabPanel> */}
            <TabPanel value={value} index={1}>
              <ReviewDocuments startupId={startUpId} isRoleAdmin={false} />
            </TabPanel>
            {env !== 'prod' && get(bridgeLoanProcess, 'isLoanApproved') && (
              <TabPanel
                value={value}
                index={2}
                className={classes.safeTracking}
              >
                <SafeTracking />
              </TabPanel>
            )}

            <TabPanel
              value={value}
              index={
                env !== 'prod' && get(bridgeLoanProcess, 'isLoanApproved')
                  ? 3
                  : 2
              }
              className={classes.tabPaymentBox}
            >
              <Payments startupId={startUpId} />
            </TabPanel>
          </Box>
        </Box>
        {value !== 2 && (
          <Box className={classes.enquiryViewBoxRight}>
            <Box className={classes.contentBoxRight}>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelStartUpText}
              >
                YC Batch
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.labelStartUp}
              >
                {ycBatch}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelStartUpText}
              >
                Company Website
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.labelStartUpUrl}
              >
                {startupWebLink || '--'}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.labelFundHead}
              >
                Founders
              </Typography>
              {founderDetails && (
                <div className={classes.founderDetailsBox}>
                  {founderDetails.map((i, n) => {
                    return <Founder details={i} key={n} />;
                  })}
                </div>
              )}
              {(startupFacebookUrl ||
                startupTwitterUrl ||
                startupLinkedInUrl) && (
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.labelStartUpText}
                >
                  Social Media URL’s
                </Typography>
              )}
              {startupFacebookUrl && (
                <MenuItem
                  className={classes.menuItemText}
                  onClick={() => window.open(startupFacebookUrl, '_blank')}
                >
                  <img src={FaceBook} alt={FaceBook} />
                </MenuItem>
              )}
              {startupTwitterUrl && (
                <MenuItem
                  className={classes.menuItemText}
                  onClick={() => window.open(startupTwitterUrl, '_blank')}
                >
                  <img src={Twitter} alt={Twitter} />
                </MenuItem>
              )}
              {startupLinkedInUrl && (
                <MenuItem
                  className={classes.menuItemText}
                  onClick={() => window.open(startupLinkedInUrl, '_blank')}
                >
                  <img src={LinkedIn} alt={LinkedIn} />
                </MenuItem>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
