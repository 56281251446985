import { urlConstants } from 'common/utils/constants';

import { secureApi } from '../api/Axios';

export const createOrUpdateDeal = (payload: any) =>
  secureApi.post(`${urlConstants.createOrUpdateDeal}`, payload);

export const getDealById = (dealId: any) =>
  secureApi.get(`${urlConstants.getDealById}?id=${dealId}`);

export const getAllDeal = () => secureApi.get(`${urlConstants.getAllDeal}`);

export const getSignedUrlForDealDocument = (payload: any) =>
  secureApi.post(`${urlConstants.adminGetDealDocSignedurl}`, payload);

export const addDealDocument = (payload: any) =>
  secureApi.post(`${urlConstants.adminAddDealDoc}`, payload);

export const getAdminDealUpdates = (dealId: any) =>
  dealId
    ? secureApi.get(`${urlConstants.getAdminDealUpdates}?id=${dealId}`)
    : secureApi.get(`${urlConstants.getAdminDealUpdates}`);

export const createUpdateDealUpdate = (payload: any) =>
  secureApi.post(`${urlConstants.createUpdateDealUpdate}`, payload);

export const publishDealUpdate = (payload: any) =>
  secureApi.post(`${urlConstants.publishDealUpdate}`, payload);

export const deleteDealUpdate = (payload: any) =>
  secureApi.post(`${urlConstants.deleteDealUpdate}`, payload);

export const getDealUpdateDocSignedurl = (payload: any) =>
  secureApi.post(`${urlConstants.adminGetDealUpdateDocSignedurl}`, payload);

export const getDealRequestSent = (payload: any) =>
  secureApi.post(`${urlConstants.getDealRequestSent}`, payload);

export const updateDealStartupBasicProfile = (payload: any) =>
  secureApi.post(`${urlConstants.updateDealStartupBasicProfile}`, payload);

export const getSignedUrlForVcDealDocumentForStartup = (payload: any) =>
  secureApi.post(
    `${urlConstants.getSignedUrlForVcDealDocumentForStartup}`,
    payload,
  );

export const addOrUpdateVcDealDocumentsForStartup = (payload: any) =>
  secureApi.post(
    `${urlConstants.addOrUpdateVcDealDocumentsForStartup}`,
    payload,
  );

export const getVcDealForVcInvitedStartup = (vcDealId: any) =>
  secureApi.get(`${urlConstants.getVcDealForVcInvitedStartup}/${vcDealId}`);

export const getAllVcAdminDeals = () =>
  secureApi.get(`${urlConstants.getAllVcAdminDeals}`);

export const getVcAdminDeal = (vcDealId: any) =>
  secureApi.get(`${urlConstants.getVcAdminDeal}/${vcDealId}`);

export const createOrUpdateVcAdminDeal = (payload: any) =>
  secureApi.post(`${urlConstants.createOrUpdateVcAdminDeal}`, payload);

export const addOrUpdateVcAdminDealsMultipleDocuments = (payload: any) =>
  secureApi.post(
    `${urlConstants.addOrUpdateVcAdminDealsMultipleDocuments}`,
    payload,
  );

export const deleteVcAdminDocuments = (payload: any) =>
  secureApi.post(`${urlConstants.deleteVcAdminDocuments}`, payload);

export const getVcAdminDocuments = (payload: any) =>
  secureApi.post(`${urlConstants.getVcAdminDocuments}`, payload);

export const getSignedUrlForVcAdminDocument = (payload: any) =>
  secureApi.post(`${urlConstants.getSignedUrlForVcAdminDocument}`, payload);

export const deleteVcDeal = (vcDealId: any) =>
  secureApi.delete(`${urlConstants.deleteVcDeal}/${vcDealId}`);

export const getSignedUrlForVcAdminDealsImport = (payload: any) =>
  secureApi.post(`${urlConstants.getSignedUrlForVcAdminDealsImport}`, payload);

export const processVcAdminDealsCSVImportFromS3 = (payload: any) =>
  secureApi.post(`${urlConstants.processVcAdminDealsCSVImportFromS3}`, payload);
