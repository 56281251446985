import { put, takeLatest, call, fork, all } from 'redux-saga/effects';
import { get } from 'lodash';

import { ISagaAction, SubmitEnquiry } from 'common/types';
import { constructArray } from 'common/utils/helpers';
import {
  getAllCountries,
  getAllIndustriesApi,
  getVdByStartupId,
  getDashboardValues,
  getVdLoanDetailsByStartupId,
  getAllYcLocations,
  getAllStates,
  submitQuery,
} from 'services';

import { actionsListService } from '../../services/actionsList';
import { ActionsListData } from './Types';
import {
  GET_ALL_COUNTRIES,
  GET_ALL_COUNTRIES_SUCCESS,
  GET_ALL_INDUSTRY_CLASSIFICATION,
  GET_ALL_INDUSTRY_CLASSIFICATION_SUCCESS,
  GET_ALL_YC_LOCATIONS,
  GET_ALL_YC_LOCATIONS_SUCCESS,
  GET_ALL_STATES,
  GET_ALL_STATES_SUCCESS,
  GET_ACTIONS_LIST,
  GET_ACTIONS_LIST_FAILURE,
  GET_ACTIONS_LIST_LOADING,
  GET_ACTIONS_LIST_SUCCESS,
  GET_VD_FORM_VALUES,
  GET_VD_FORM_VALUES_LOADING,
  GET_VD_FORM_VALUES_SUCCESS,
  GET_VD_FORM_VALUES_FAILURE,
  GET_DASHBOARD_VALUES,
  GET_DASHBOARD_VALUES_LOADING,
  GET_DASHBOARD_VALUES_SUCCESS,
  GET_DASHBOARD_VALUES_FAILURE,
  GET_VD_APPROVE_LOAN_VALUES,
  GET_VD_APPROVE_LOAN_VALUES_LOADING,
  GET_VD_APPROVE_LOAN_VALUES_SUCCESS,
  GET_VD_APPROVE_LOAN_VALUES_FAILURE,
  SUBMIT_QUERY,
  SUBMIT_QUERY_LOADING,
  SUBMIT_QUERY_SUCCESS,
  SUBMIT_QUERY_FAILURE,
} from './Actions';

type ResponseType = {
  response: any;
};

const getCountries = async () =>
  getAllCountries()
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const getAllIndustries = async () =>
  getAllIndustriesApi()
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const getYcLocations = async () =>
  getAllYcLocations()
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const getStates = async () =>
  getAllStates()
    .then((res) => res)
    .catch((err) => Promise.reject(err));

function* handleGetAllCountries() {
  try {
    const response: ResponseType = yield call<any>(getCountries);
    const array = constructArray(response, 'COUNTRIES');
    yield put({ type: GET_ALL_COUNTRIES_SUCCESS, payload: array });
  } catch (err) {
    // const message: string = errorMessageHandler(err);
    // console.log(message);
  }
}

function* handleGetAllIndustriesType() {
  try {
    const response: ResponseType = yield call<any>(getAllIndustries);
    const array = constructArray(response, 'INDUSTRY');
    yield put({
      type: GET_ALL_INDUSTRY_CLASSIFICATION_SUCCESS,
      payload: array,
    });
  } catch (err) {
    // const message: string = errorMessageHandler(err);
    // console.log(message);
  }
}

function* handleGetAllYcLocations() {
  try {
    const response: ResponseType = yield call<any>(getYcLocations);
    const array = constructArray(response, 'LOCATION');
    yield put({ type: GET_ALL_YC_LOCATIONS_SUCCESS, payload: array });
  } catch (err) {
    // const message: string = errorMessageHandler(err);
    // console.log(message);
  }
}

function* handleGetAllStates() {
  try {
    const response: ResponseType = yield call<any>(getStates);
    const array = constructArray(response, 'STATES');
    yield put({ type: GET_ALL_STATES_SUCCESS, payload: array });
  } catch (err) {
    // const message: string = errorMessageHandler(err);
    // console.log(message);
  }
}

function* getAllCountriesSaga() {
  yield takeLatest(GET_ALL_COUNTRIES, handleGetAllCountries);
}

function* getAllIndustrySaga() {
  yield takeLatest(GET_ALL_INDUSTRY_CLASSIFICATION, handleGetAllIndustriesType);
}

function* getAllYcLocationsSaga() {
  yield takeLatest(GET_ALL_YC_LOCATIONS, handleGetAllYcLocations);
}

function* getAllStatesSaga() {
  yield takeLatest(GET_ALL_STATES, handleGetAllStates);
}

function* getActionsList(data: ActionsListData) {
  const { payload } = data;
  try {
    yield put({ type: GET_ACTIONS_LIST_LOADING });
    const response: ResponseType = yield call<any>(actionsListService, payload);
    yield put({
      type: GET_ACTIONS_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'message');
    yield put({ type: GET_ACTIONS_LIST_FAILURE, payload: message });
  }
}

function* handleGetVdFormValues(data: ISagaAction<string | undefined>) {
  const { payload } = data;
  try {
    yield put({ type: GET_VD_FORM_VALUES_LOADING });
    const response: ResponseType = yield call<any>(getVdByStartupId, payload);
    yield put({
      type: GET_VD_FORM_VALUES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'message');
    yield put({ type: GET_VD_FORM_VALUES_FAILURE, payload: message });
  }
}

function* handleGetVdApproveLoanValues(data: ISagaAction<string | undefined>) {
  const { payload } = data;
  try {
    yield put({ type: GET_VD_APPROVE_LOAN_VALUES_LOADING });
    const response: ResponseType = yield call<any>(
      getVdLoanDetailsByStartupId,
      payload,
    );
    yield put({
      type: GET_VD_APPROVE_LOAN_VALUES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'message');
    yield put({ type: GET_VD_APPROVE_LOAN_VALUES_FAILURE, payload: message });
  }
}

function* handleGetDashboardValues() {
  try {
    yield put({ type: GET_DASHBOARD_VALUES_LOADING });
    const response: ResponseType = yield call<any>(getDashboardValues);
    yield put({
      type: GET_DASHBOARD_VALUES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'message');
    yield put({ type: GET_DASHBOARD_VALUES_FAILURE, payload: message });
  }
}

function* handleSubmitQuery(data: ISagaAction<SubmitEnquiry>) {
  const { payload } = data;
  try {
    yield put({ type: SUBMIT_QUERY_LOADING });
    yield call<any>(submitQuery, payload);
    yield put({
      type: SUBMIT_QUERY_SUCCESS,
      payload: 'Your query submitted successfully',
    });
  } catch (err) {
    const message = 'Something went wrong. Please try again later';
    yield put({ type: SUBMIT_QUERY_FAILURE, payload: message });
  }
}

function* getActionsListSaga() {
  yield takeLatest(GET_ACTIONS_LIST, getActionsList);
}

function* getVdFormValuesSaga() {
  yield takeLatest(GET_VD_FORM_VALUES, handleGetVdFormValues);
}

function* getDashboardValuesSaga() {
  yield takeLatest(GET_DASHBOARD_VALUES, handleGetDashboardValues);
}

function* getVdApproveLoanValuesSaga() {
  yield takeLatest(GET_VD_APPROVE_LOAN_VALUES, handleGetVdApproveLoanValues);
}

function* submitQuerySaga() {
  yield takeLatest(SUBMIT_QUERY, handleSubmitQuery);
}

export default function* globalSagas() {
  yield all([
    fork(getAllCountriesSaga),
    fork(getAllIndustrySaga),
    fork(getAllYcLocationsSaga),
    fork(getAllStatesSaga),
    fork(getActionsListSaga),
    fork(getVdFormValuesSaga),
    fork(getDashboardValuesSaga),
    fork(getVdApproveLoanValuesSaga),
    fork(submitQuerySaga),
  ]);
}
