import React from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';

import { getBenchmarkStartupProcess } from 'services';
import history from 'common/utils/history';
import { errorMessageHandler } from 'common/utils/helpers';
import { getStartupInfo } from 'redux-modules/enquiries/Actions';

import styles from './styles';
import GetStartedBox from '../GetStartedBox';

const BenchmarkWelcome = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const [benchmarkKpiProcess, setBenchmarkKpiProcess] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  React.useEffect(() => {
    dispatch(getStartupInfo());
    setIsLoading(true);
    setErrorMessage('');
    getBenchmarkStartupProcess()
      .then((process) => {
        setBenchmarkKpiProcess(process);
        setIsLoading(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (get(benchmarkKpiProcess, 'benchmarkKpiDataAdded')) {
      history.push('/benchmark-kpi-v2/EDIT');
    }
  }, [benchmarkKpiProcess]);

  return (
    <Box className={classes.benchMarkWelcome}>
      {errorMessage && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.errorMessage}
        >
          {errorMessage}
        </Typography>
      )}
      {isLoading ? (
        <Box className={classes.loader}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <GetStartedBox />
      )}
    </Box>
  );
};
export default BenchmarkWelcome;
