import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteDealText: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '45px',
    letterSpacing: 0.3,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
  },
  convertBtnSection: {
    paddingTop: 30,
  },
  deleteBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 160,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  inputFundBox: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInput: {
    marginBottom: 25,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root .Mui-focused': {
      color: 'green',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    color: '#404852',
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.main,
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'red',
      paddingBottom: 10,
    },
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#404852',
      paddingBottom: 10,
    },
    '& .MuiInputLabel-root .Mui-focused': {
      marginTop: 10,
    },
  },
  uploadFieldContainer: {
    border: '2px dashed #979797',
    height: 197,
    background: '#F4F4F4',
    borderRadius: 15,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 10,
  },
  uploadText: {
    fontSize: 16,
    color: theme.palette.info.light,
    cursor: 'pointer',
    fontWeight: 800,
    marginBottom: 7,
  },
  uploadInnerText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.info.light,
    marginBottom: 5,
  },
  uploadFormatText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey.A100,
  },
  warnCancelBtn: {
    width: 160,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
    marginLeft: 20,
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 125,
      marginLeft: 15,
    },
  },
  /// Save Changes Alert
  alertDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertText: {
    fontSize: 22,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  btnSection: {
    paddingTop: 30,
  },
  alertInfoIcon: {
    height: 70,
    width: 70,
    marginBottom: 20,
  },
  cancelBtn: {
    width: 160,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
    marginLeft: 20,
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 125,
      marginLeft: 15,
    },
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    width: 160,
    height: 48,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      marginLeft: 0,
      marginBottom: 15,
      width: '100%',
    },
  },
  inputHead: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '20px 40px 12px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 12px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px !important',
    },
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 11,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 14,
    display: 'block',
    position: 'absolute',
    bottom: 0,
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  prevButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 199,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    marginRight: 18,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  nextPrevNotifyButtonBox: {
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 10,
  },
  sendButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 143,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    marginRight: 18,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  prevNotifyButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 143,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  textValNotifyInput: {
    marginBottom: 5,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root .Mui-focused': {
      color: 'green',
    },
  },
}));
