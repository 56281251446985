import * as yup from 'yup';

import {
  requiredConstant,
  urlRegex,
  urlValidConstant,
} from 'common/utils/constants';

export const fundRaisingCRM = yup.object().shape({
  accelerator: yup.string().nullable(),
  ycInvestment: yup
    .string()
    .nullable()
    .when('accelerator', (accelerator: string, schema: any) => {
      if (accelerator === 'YC')
        return schema.required('This is a required field');
      return schema;
    }),
  mfnStartingDate: yup
    .string()
    .nullable()
    .when('ycInvestment', (ycInvestment: string, schema: any) => {
      if (ycInvestment === 'YES')
        return schema.required('This is a required field');
      return schema;
    }),
  roundName: yup.string().required(requiredConstant),
  currency: yup.string().required(requiredConstant),
  targetAmount: yup.string().required(requiredConstant),
  tentativeValuation: yup.string().required(requiredConstant),
  meetingLink: yup.string().nullable().matches(urlRegex, urlValidConstant),
  // emailReader: yup.string().required('This is a required field'),
});
