import {
  USER_LOGIN_COMPLETE,
  ERROR_VERIFY,
  USER_LOGIN,
  FORGOT_PASSWORD,
  USER_SET_PASSWORD_SUCCESS,
  USER_SET_PASSWORD,
  ERROR_LOGIN,
  SIGN_UP_FAILURE,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  VERIFY_USER,
  VERIFY_SUCCESS,
  ERROR_FORGOT_PASSWORD,
  SUCCESS_FORGOT_PASSWORD,
  USER_SET_PASSWORD_FAILURE,
  ADMIN_SET_PASSWORD_SUCCESS,
  ADMIN_SET_PASSWORD,
  ADMIN_SET_PASSWORD_FAILURE,
  YC_BATCH_SUCCESS,
  YC_BATCH_FAILURE,
  EMPTY_STATE,
  SUCCESS_SIGN_OUT,
} from './Actions';
import { AuthAction, AuthState } from './Types';

export const authInitialState: AuthState = {
  user: {
    name: '',
    role: '',
    startUpId: '',
    email: '',
    accelerator: '',
    companyName: '',
  },
  signUp: {
    isLoading: false,
    message: '',
    email: '',
  },
  confirmPassword: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
  verifyUser: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
  forgotPassword: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
  setPassword: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
  isLoading: false,
  loggedIn: false,
  errorMessage: '',
  ycBatches: [],
  currentYcBatch: {},
};

const AuthReducer = (state = authInitialState, action: AuthAction) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN:
      return {
        ...state,
        isLoading: true,
        loggedIn: false,
        errorMessage: '',
      };
    case USER_LOGIN_COMPLETE:
      return {
        ...state,
        isLoading: false,
        user: { ...payload },
        errorMessage: '',
        loggedIn: true,
      };
    case SIGN_UP:
      return {
        ...state,
        signUp: {
          message: '',
          isLoading: true,
        },
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          email: payload,
          isLoading: false,
        },
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        signUp: {
          message: payload,
          isLoading: false,
        },
      };
    case ERROR_LOGIN:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    case VERIFY_USER:
      return {
        ...state,
        verifyUser: {
          ...state.verifyUser,
          isLoading: true,
        },
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        verifyUser: {
          ...state.verifyUser,
          isLoading: false,
          successMessage: payload,
        },
      };
    case ERROR_VERIFY:
      return {
        ...state,
        verifyUser: {
          isLoading: false,
          errorMessage: payload,
        },
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          isLoading: true,
          errorMessage: '',
          successMessage: '',
        },
      };
    case ERROR_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          isLoading: false,
          errorMessage: payload,
        },
      };
    case SUCCESS_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          isLoading: false,
          successMessage: payload,
        },
      };
    case USER_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setPassword: {
          isLoading: false,
          successMessage: payload,
        },
      };
    case USER_SET_PASSWORD:
      return {
        ...state,
        setPassword: {
          isLoading: true,
          errorMessage: '',
          successMessage: '',
        },
      };
    case USER_SET_PASSWORD_FAILURE:
      return {
        ...state,
        setPassword: {
          isLoading: false,
          errorMessage: payload,
        },
      };
    case ADMIN_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        confirmPassword: {
          isLoading: false,
          successMessage: payload,
          errorMessage: '',
        },
      };
    case ADMIN_SET_PASSWORD:
      return {
        ...state,
        confirmPassword: {
          isLoading: true,
          errorMessage: '',
          successMessage: '',
        },
      };
    case ADMIN_SET_PASSWORD_FAILURE:
      return {
        ...state,
        confirmPassword: {
          isLoading: false,
          errorMessage: payload,
        },
      };
    case YC_BATCH_SUCCESS: {
      const ycBatchArray: any = payload || [];
      const currentYc: any = ycBatchArray.find(
        (item: any) => item.currentYcBatch === true,
      );
      return {
        ...state,
        ycBatches: payload,
        currentYcBatch: currentYc,
      };
    }
    case YC_BATCH_FAILURE:
      return {
        ...state,
        ycBatches: [],
        currentYcBatch: {},
      };
    case EMPTY_STATE:
      return {
        ...state,
        errorMessage: '',
      };
    case SUCCESS_SIGN_OUT:
      return {
        ...authInitialState,
      };
    default:
      return state;
  }
};

export default AuthReducer;
