import { urlConstants } from 'common/utils/constants';

import { secureApi } from '../api/Axios';

export const getAllAdminInvestors = (payload: any) =>
  payload.spv
    ? secureApi.get(`${urlConstants.getAllAdminInvestors}?spv=${payload.spv}`)
    : secureApi.get(urlConstants.getAllAdminInvestors);

export const getAdminInvestorById = (id: string) =>
  secureApi.get(`${urlConstants.getAdminInvestorById}/${id}`);

export const updateInvestorByAdmin = (payload: any) =>
  secureApi.post(urlConstants.updateInvestorByAdmin, payload);

export const getInvestmentDocuments = (payload: any) =>
  secureApi.get(`${urlConstants.getInvestmentDocuments}?id=${payload.id}`);

export const createKycAndSignupOnboard = (payload: any) =>
  secureApi.post(`${urlConstants.kycSignupOnboard}`, payload);

export const createAdminInvestmentDetail = (payload: any) =>
  secureApi.post(`${urlConstants.createAdminInvestmentDetail}`, payload);

export const getSignedUrlForDocusignDocument = (payload: any) =>
  secureApi.post(`${urlConstants.adminDocusignGetSignedUrl}`, payload);

export const addInvestorDocusignDocument = (payload: any) =>
  secureApi.post(`${urlConstants.addInvestorDocusignDocument}`, payload);

export const adminInvestorAddDocument = (payload: any) =>
  secureApi.post(`${urlConstants.adminInvestorAddDocument}`, payload);

export const adminInvestorDocumentSignedUrl = (payload: any) =>
  secureApi.post(`${urlConstants.adminInvestorDocumentSignedUrl}`, payload);

export const approveInvestorDocuments = (payload: any) =>
  secureApi.post(`${urlConstants.approveInvestorDocuments}`, payload);

export const adminApproveInvestment = (payload: any) =>
  secureApi.post(`${urlConstants.adminApproveInvestment}`, payload);

export const getInvestorPayments = (payload: any) =>
  secureApi.get(
    `${urlConstants.getInvestorPayments}?spv=${payload.spv}&investorId=${payload.investorId}`,
  );
export const adminAddInvestmentNavAttr = (payload: any) =>
  secureApi.post(`${urlConstants.adminAddInvestmentNavAttr}`, payload);

export const adminGetInvestmentNavAttrList = (spv?: string) =>
  spv
    ? secureApi.get(`${urlConstants.adminGetInvestmentNavAttrList}?spv=${spv}`)
    : secureApi.get(`${urlConstants.adminGetInvestmentNavAttrList}`);

export const adminGetInvestmentCurrentNavAttr = (spv: string) =>
  secureApi.get(`${urlConstants.adminGetInvestmentCurrentNavAttr}?spv=${spv}`);

export const getAdminKycSSN = (kycId: string) =>
  secureApi.get(`${urlConstants.getAdminKycSSN}?kycId=${kycId}`);

export const getAdminSubscriberSSN = (investmentDetailId: string) =>
  secureApi.get(
    `${urlConstants.getAdminSubscriberSSN}?investmentDetailId=${investmentDetailId}`,
  );

export const getInvestorStats = () =>
  secureApi.get(`${urlConstants.getInvestorStats}`);

export const sendInvestorsNavReportEmail = (payload: any) =>
  secureApi.post(`${urlConstants.sendInvestorsNavReportEmail}`, payload);
