import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { VentureDebtReducer } from 'redux-modules/ventureDebt';

import { AuthReducer } from '../auth';
import { EnquiryFormReducer } from '../enquiryForm';
import { EnquiryReducer } from '../enquiries';
import { GlobalReducer } from '../global';
import { ProcessReducer } from '../process';
import { PaymentsReducer } from '../payments';
import { VdInstallmentsReducer } from '../vdinstallments';
import { InvestorReducer } from '../investors';
import { DealsReducer } from '../deals';
import { FundingRoundReducer } from '../fundingRound';

// create a root reducer
const rootReducer = (history: History) =>
  combineReducers({
    Auth: AuthReducer,
    EnquiryForm: EnquiryFormReducer,
    Enquiries: EnquiryReducer,
    global: GlobalReducer,
    Process: ProcessReducer,
    VentureDebt: VentureDebtReducer,
    // for connected router
    router: connectRouter(history),
    Payments: PaymentsReducer,
    VdInstallments: VdInstallmentsReducer,
    Investors: InvestorReducer,
    Deals: DealsReducer,
    FundingRound: FundingRoundReducer,
  });

export default rootReducer;
