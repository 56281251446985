import { IRoute } from 'common/types';
import DashboardLayoutV2 from 'layouts/DashboardLayoutV2';

import BenchMark from './index';

export const BenchMarkRoute: IRoute = {
  path: '/benchmark-kpi',
  component: BenchMark,
  layout: DashboardLayoutV2,
  exact: true,
};

export const BenchMarkStartupViewRoute: IRoute = {
  path: '/benchmark-kpi/:id',
  component: BenchMark,
  layout: DashboardLayoutV2,
  exact: true,
};
