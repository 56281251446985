import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import DealList from './DealList';

export const DealListRoute: IRoute = {
  path: '/admin/deals',
  component: DealList,
  layout: DashboardLayout,
  exact: true,
};
