import { IRoute } from 'common/types';
import SimpleLayout from 'layouts/SimpleLayout';

import VcOnboard from './VcOnboard';

export const VcOnboardRoute: IRoute = {
  path: '/admin/vc-onboard/:id',
  component: VcOnboard,
  layout: SimpleLayout,
};
