import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const FundDealSchema = yup.object().shape({
  deal: yup.string().nullable().required(requiredConstant),
  order: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant),
  fundTitle: yup.string().nullable().required(requiredConstant),
  dealSize: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant),
  startDate: yup.string().nullable().required(requiredConstant),
  estimatedCloseDate: yup.string().nullable().required(requiredConstant),
  businessModel: yup.string().nullable().required(requiredConstant),
  geography: yup.string().nullable().required(requiredConstant),
  manager: yup.string().nullable().required(requiredConstant),
  minimumInvestmentAmount: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  tenure: yup.string().nullable().required(requiredConstant),
  managementFees: yup.string().nullable().required(requiredConstant),
  performanceFee: yup.string().nullable().required(requiredConstant),
  // schemeCapacity: yup.string().nullable().required(requiredConstant),
  frequency: yup
    .number()
    .nullable()
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  carry: yup
    .number()
    .nullable()
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
});

export const StartupDealSchema = yup.object().shape({
  deal: yup.string().nullable().required(requiredConstant),
  order: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant),
  companyName: yup.string().nullable().required(requiredConstant),
  dealSize: yup
    .number()
    .nullable()
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  startDate: yup.string().nullable().required(requiredConstant),
  estimatedCloseDate: yup.string().nullable().required(requiredConstant),
  businessModel: yup.string().nullable().required(requiredConstant),
  geography: yup.string().nullable().required(requiredConstant),
  manager: yup.string().nullable().required(requiredConstant),
  minimumInvestmentAmount: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  tenure: yup.string().nullable().required(requiredConstant),
  serviceFee: yup.string().nullable().required(requiredConstant),
  frequency: yup
    .number()
    .nullable()
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  originationFee: yup.string().nullable().required(requiredConstant),
  syndicationFee: yup.string().nullable().required(requiredConstant),
});

export const MarketingSchema = yup.object().shape({
  investmentTeaserLink: yup.string().nullable().required(requiredConstant),
  investmentTeaserLinkDesc: yup.string().nullable().required(requiredConstant),
  informationLink: yup.string().nullable().required(requiredConstant),
  informationLinkDesc: yup.string().nullable().required(requiredConstant),
});

export const LegalRegulatorySchema = yup.object().shape({
  entityLegalName: yup.string().nullable().required(requiredConstant),
  subscriptionDocDesc: yup.string().nullable().required(requiredConstant),
});
