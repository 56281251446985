import { IRoute } from 'common/types';
import SimpleLayout from 'layouts/SimpleLayout';

import VentureDebtForm from './VentureDebt';

export const VDEnquiryFormRoute: IRoute = {
  path: '/startup/vd-enquiry-form/:type',
  component: VentureDebtForm,
  layout: SimpleLayout,
};
