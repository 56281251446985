import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

type currencyType = {
  label?: string;
  placeholder?: string;
  className?: any;
  InputProps?: any;
  InputLabelProps?: any;
  value: any;
  onChange: any;
  name?: string;
  error?: any;
  disabled?: any;
  currencySymbol?: string;
  decimalPlaces?: number;
  minimumValue?: number;
  onBlur?: any;
  selectOnFocus?: any;
  autoFocus?: boolean;
  textAlign?: string;
};
const WrappedPriceInput = ({
  label,
  name,
  placeholder,
  className,
  InputLabelProps,
  InputProps,
  value,
  onChange,
  error,
  disabled,
  currencySymbol,
  decimalPlaces = 0,
  minimumValue = 0,
  onBlur,
  selectOnFocus = false,
  autoFocus,
  textAlign,
}: currencyType) => {
  return (
    <div>
      <CurrencyTextField
        label={label}
        name={name}
        variant="standard"
        value={value}
        onBlur={onBlur}
        textAlign={textAlign}
        currencySymbol={currencySymbol || null}
        InputLabelProps={InputLabelProps}
        InputProps={InputProps}
        minimumValue={minimumValue}
        className={className}
        outputFormat="string"
        decimalCharacter="."
        digitGroupSeparator=","
        autoFocus={autoFocus}
        error={error}
        readonly
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        decimalPlaces={decimalPlaces}
        selectOnFocus={selectOnFocus}
      />
    </div>
  );
};

export default WrappedPriceInput;
