import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

import {
  Button,
  Box,
  WrappedTextInput,
  WrappedSelect,
  Banner,
} from 'components';
import { getPaymentById, logPayment } from 'redux-modules/payments/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { FN } from 'common/types';

import styles from './styles';
import { logPaymentSchema } from './validations';

type Props = {
  handleBack: FN;
  startupId: string | undefined;
  isRoleAdmin: boolean;
  fundType: string;
  paymentId: string;
  vdInstallmentId: string | undefined;
  installmentAmount: string | undefined;
};

const LogPayment = ({
  handleBack,
  isRoleAdmin,
  startupId,
  fundType,
  paymentId,
  vdInstallmentId,
  installmentAmount,
}: Props) => {
  const [successPop, setSuccessPop] = useState<boolean>(false);
  const { handleSubmit, control, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(logPaymentSchema),
  });
  const classes = styles();
  const dispatch = useDispatch();
  const { paymentDetails, successMessage } = useSelector(
    ({ Payments }: RootState) => Payments,
  );

  const { isLoading, errorMessage, data } = paymentDetails;

  useEffect(() => {
    dispatch(getPaymentById({ paymentId }));
  }, [paymentId]);

  const onSubmit = () => {
    const payload = {
      ...getValues(),
      paymentId,
      startupId,
      loanType: fundType,
      isVerifiedByAdmin: isRoleAdmin ? true : false,
      vdInstallmentId: !isEmpty(vdInstallmentId) ? vdInstallmentId : null,
    };
    dispatch(logPayment(payload));
  };

  useEffect(() => {
    if (!isEmpty(paymentDetails.successMessage)) {
      reset(data);
    }
  }, [paymentDetails.successMessage, data]);

  useEffect(() => {
    if (!isEmpty(successMessage)) {
      setSuccessPop(true);
    }
  }, [successMessage]);
  return (
    <div>
      {errorMessage && <Typography>{errorMessage}</Typography>}
      {successPop ? (
        <Banner title={successMessage} subTitle={''} source={'SuccessBanner'} />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} data-testid="logpaymentForm">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Controller
                  name="amount"
                  control={control}
                  defaultValue={installmentAmount || ''}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label="Amount"
                        placeholder="Amount"
                        className={classes.textValInput}
                        InputProps={{
                          inputProps: { min: 1 },
                          classes: { root: classes.inputRoot },
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled={installmentAmount ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{
                    required: 'Amount is required',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Controller
                  name="transactionType"
                  control={control}
                  defaultValue="CREDIT"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedSelect
                        value={value}
                        onChange={onChange}
                        label="Transaction"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        dropdowns={[
                          { text: 'Debit from 8vdx', value: 'DEBIT' },
                          { text: 'Credit to 8vdx', value: 'CREDIT' },
                        ]}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Transaction Type is required' }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Controller
                  name="transactionId"
                  defaultValue=""
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label="Transaction Id"
                        placeholder="Transaction Id"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Transaction Id is required' }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Controller
                  name="processedAt"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        id="date-local"
                        type="date"
                        value={
                          !get(data, 'processedAt')
                            ? value
                            : get(data, 'processedAt').split('T')[0]
                        }
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                        onChange={onChange}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Processed At is required' }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Controller
                  name="remarks"
                  defaultValue=""
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label="Remarks"
                        placeholder="Remarks"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  // rules={{ required: 'Remarks is required' }}
                />
              </Grid>
            </Grid>
            <Box className={classes.backContinueBox}>
              <Button
                type="submit"
                name="Confirm"
                onClick={handleSubmit(onSubmit)}
                className={classes.continueBtn}
                isLoading={isLoading}
              />
              <Button
                onClick={() => handleBack()}
                className={classes.backBtn}
                name="Cancel"
              />
            </Box>
          </form>
        </>
      )}
    </div>
  );
};

export default LogPayment;
