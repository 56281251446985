import React from 'react';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';

import { Box, Loader } from 'components';
import { FN } from 'common/types';
import { getVcPortalCountryObject, formatAmount } from 'common/utils/helpers';

import styles from './styles';

type Props = {
  isLoading: boolean;
  errorMessage: string;
  fundData: Array<FN>;
  fundId: string;
};

const VcFundOverview = ({ isLoading, errorMessage, fundData }: Props) => {
  const classes = styles();

  return (
    <>
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      {isLoading ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : (
        <Box className={classes.tabPanelContent}>
          <Box className={classes.vTwoSection}>
            <Box className={classes.vFirstSection}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Fund Name
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {get(fundData, 'fundTitle')}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Target Fund Size
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {formatAmount(get(fundData, 'targetFundSize'), {
                  currency: get(fundData, 'currency') || 'USD',
                })}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Raised Till Now
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {formatAmount(get(fundData, 'raisedFundAmount'), {
                  currency: get(fundData, 'currency') || 'USD',
                })}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Estimated First Close
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {get(fundData, 'estimatedFirstCloseAt')
                  ? moment(get(fundData, 'estimatedFirstCloseAt')).format(
                      'MMM D, YYYY',
                    )
                  : '-'}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Estimated Final Close
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {get(fundData, 'estimatedFinalCloseAt')
                  ? moment(get(fundData, 'estimatedFinalCloseAt')).format(
                      'MMM D, YYYY',
                    )
                  : '-'}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Fund Strategy
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {get(fundData, 'fundStrategy')}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Country of Operation
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {getVcPortalCountryObject(get(fundData, 'geography'))?.country}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Fund Manager
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {get(fundData, 'fundManager')}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Sponsor Name
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {get(fundData, 'sponsorName')}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Tenure
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {get(fundData, 'tenure')}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Management Fees
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {get(fundData, 'managementFees')}%
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.overViewSubHeadTitle}
              >
                Invested Till Now
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.descTitletext}
              >
                {formatAmount(get(fundData, 'investedAmount'), {
                  currency: get(fundData, 'currency') || 'USD',
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default VcFundOverview;
