import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';
// import SimpleLayout from 'layouts/SimpleLayout';

import EnquiryView from './EnquiryView';

export const EnquiryViewRoute: IRoute = {
  path: '/admin/enquiry-view/:id',
  component: EnquiryView,
  layout: DashboardLayout,
  // layout: SimpleLayout,
  exact: true,
};
