import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  signUpContainer: {
    height: 'calc(100vh - 104px)',
  },
  loaderCenter: {
    textAlign: 'center',
  },
  gridContainer: {
    paddingTop: 30,
    height: '100%',
  },
  leftSideBox: {
    alignSelf: 'center',
  },
  leftSide: {
    padding: '20px 50px 30px 190px',
    '@media (max-width: 1024px)': {
      padding: '20px 30px 20px 30px',
    },
    // [theme.breakpoints.down('sm')]: {
    //   padding: '20px 30px 20px 30px',
    // },
  },
  welcome8vdx: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.warning.contrastText,
  },
  welcomeSub8vdx: {
    color: theme.palette.warning.light,
    fontSize: 16,
    fontWeight: 400,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    top: -30,
  },
  validateForm: {
    paddingTop: 43,
  },
  textValInput: {
    marginBottom: 35,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: theme.palette.primary.dark,
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.dark,
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.dark,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  forgotText: {
    alignSelf: 'center',
    textAlign: 'right',
  },
  forgotPassword: {
    fontSize: 14,
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    margin: 0,
    display: 'inline-block',
  },
  signInBtnBox: {
    paddingTop: 40,
  },
  signupBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    padding: '15px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 175,
    fontSize: 16,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  doYouHaveBox: {
    padding: '25px 0 0',
  },
  doYouHaveBoxText: {
    color: theme.palette.warning.light,
    fontSize: 16,
    fontWeight: 400,
  },
  doYouLink: {
    fontSize: 16,
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  rightSide: {
    alignSelf: 'center',
    // paddingTop: 50,
    textAlign: 'center',
    '@media (max-width: 1224px)': {
      padding: 30,
    },
  },
  signupImage: {
    // maxWidth: '100%',
    // height: 'auto',
    maxWidth: 550,
    '@media (max-width: 1224px)': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
  checkedBox: {
    color: theme.palette.primary.dark,
  },
}));
