/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Box,
} from '@material-ui/core';
import { get } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from 'components';

import styles from './styles';

type ConfirmDialogProps = {
  dialogOpen: boolean;
  dialogHeading: string;
  dialogContent: string;
  isLoading: boolean;
  onConfirm: any;
  onCancel: any;
  onClose: any;
  buttonText?: any;
  showClose?: boolean;
};
const ConfirmDialog = ({
  dialogOpen,
  onClose,
  dialogContent,
  dialogHeading,
  isLoading,
  onConfirm,
  onCancel,
  buttonText,
  showClose = false,
}: ConfirmDialogProps) => {
  const classes = styles();
  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {showClose && (
        <Box className={classes.closeIcon}>
          <CloseIcon onClick={onCancel} />
        </Box>
      )}
      <DialogTitle id="alert-dialog-title">{dialogHeading}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          name={get(buttonText, 'yes') || 'OK'}
          isLoading={isLoading}
          onClick={() => onConfirm()}
        />
        <Button
          name={get(buttonText, 'no') || 'Cancel'}
          onClick={onCancel}
          autoFocus
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
