import {
  ISetPassword,
  ISignInModal,
  ISignUpModal,
  IErrorLog,
  IVerifyUser,
  IUsername,
  IForgotPassword,
} from 'common/types';

export const USER_LOGIN = 'user/USER-LOGIN';
export const USER_LOGIN_COMPLETE = 'user/USER-LOGIN-COMPLETE';
export const USER_LOGIN_LOADING = 'user/USER-LOGIN-LOADING';
export const ADMIN_SET_PASSWORD = 'admin/ADMIN_SET_PASSWORD';
export const ADMIN_SET_PASSWORD_SUCCESS = 'admin/ADMIN_SET_PASSWORD_SUCCESS';
export const ADMIN_SET_PASSWORD_FAILURE = 'admin/ADMIN_SET_PASSWORD_FAILURE';
export const USER_SET_PASSWORD = 'user/SET_PASSWORD';
export const USER_SET_PASSWORD_SUCCESS = 'user/USER_SET_PASSWORD_SUCCESS';
export const SIGN_UP = 'user/SIGN_UP';
export const SIGN_UP_SUCCESS = 'user/SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'user/SIGN_UP_FAILURE';
export const ERROR_LOGIN = 'user/ERROR_LOGIN';
export const VERIFY_USER = 'user/VERIFY_USER';
export const ERROR_VERIFY = 'user/ERROR_VERIFY';
export const VERIFY_SUCCESS = 'user/VERIFY_SUCCESS';
export const ERROR_SIGN_IN = 'user/ERROR_SIGN_IN';
export const FORGOT_PASSWORD = 'user/FORGOT_PASSWORD';
export const ERROR_FORGOT_PASSWORD = 'user/ERROR_FORGOT_PASSWORD';
export const SUCCESS_FORGOT_PASSWORD = 'user/SUCCESS_FORGOT_PASSWORD';
export const USER_SET_PASSWORD_FAILURE = 'user/USER_SET_PASSWORD_FAILURE';
export const ERROR_SIGN_OUT = 'ERROR_SIGN_OUT';
export const YC_BATCH = 'YC_BATCH';
export const YC_BATCH_SUCCESS = 'YC_BATCH_SUCCESS';
export const SIGN_OUT = 'SIGN_OUT';
export const SUCCESS_SIGN_OUT = 'SUCCESS_SIGN_OUT';
export const YC_BATCH_FAILURE = 'YC_BATCH_FAILURE';
export const EMPTY_STATE = 'EMPTY_STATE';
// export const USER_SET_PASSWORD

export const loginComplete = (payload: ISignInModal) => {
  return {
    type: USER_LOGIN_COMPLETE,
    payload,
  };
};

export const handleForgotPassword = (payload: IUsername) => {
  return {
    type: FORGOT_PASSWORD,
    payload,
  };
};

export const loginUser = (payload: ISignInModal) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const handleSetPasswordAction = (payload: ISetPassword) => {
  return {
    type: ADMIN_SET_PASSWORD,
    payload,
  };
};

export const signUp = (payload: ISignUpModal) => ({ type: SIGN_UP, payload });

export const errorSignIn = (payload: IErrorLog) => {
  return {
    type: ERROR_SIGN_IN,
    payload,
  };
};

export const signUpSuccess = () => {
  return {
    type: SIGN_UP_SUCCESS,
  };
};

export const signUpFailure = (payload: IErrorLog) => {
  return {
    type: SIGN_UP_FAILURE,
    payload,
  };
};

export const errorLoginIn = (payload: IErrorLog) => {
  return {
    type: ERROR_LOGIN,
    payload,
  };
};
export const verifyUser = (payload: IVerifyUser) => {
  return {
    type: VERIFY_USER,
    payload,
  };
};

export const ErrorVerify = (payload: string) => {
  return {
    type: ERROR_VERIFY,
    payload,
  };
};

export const handleSetPassword = (payload: IForgotPassword) => {
  return {
    type: USER_SET_PASSWORD,
    payload,
  };
};

export const handleSignOut = () => {
  return {
    type: SIGN_OUT,
  };
};

export const logoutComplete = () => ({ type: SUCCESS_SIGN_OUT });

export const getBatches = () => {
  return {
    type: YC_BATCH,
  };
};

export const emptyState = () => {
  return {
    type: EMPTY_STATE,
  };
};
