import {
  GET_PAYMENTS_LIST_LOADING,
  GET_PAYMENTS_LIST_FAILURE,
  GET_PAYMENTS_LIST_SUCCESS,
  LOG_PAYMENT_LOADING,
  LOG_PAYMENT_SUCCESS,
  LOG_PAYMENT_FAILURE,
  GET_PAYMENTS_BY_ID_LOADING,
  GET_PAYMENTS_BY_ID_SUCCESS,
  GET_PAYMENTS_BY_ID_FAILURE,
  EMPTY_SUCCESS_MESSAGE,
  CLEAR_PAYMENT_DETAILS,
} from './Actions';
import { PaymentsState } from './Types';

export const PaymentsInitialState: PaymentsState = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  paymentsList: [],
  paymentDetails: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    data: {},
  },
};

export const PaymentsReducer = (state = PaymentsInitialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PAYMENTS_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_PAYMENTS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: '',
        errorMessage: '',
        paymentsList: payload,
      };
    case GET_PAYMENTS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        successMessage: '',
        errorMessage: payload,
      };
    case LOG_PAYMENT_LOADING:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case LOG_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: payload,
        errorMessage: '',
      };
    case LOG_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        successMessage: '',
        errorMessage: payload,
      };
    case GET_PAYMENTS_BY_ID_LOADING:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
        paymentDetails: {
          ...state.paymentDetails,
          isLoading: true,
        },
      };
    case GET_PAYMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: '',
        errorMessage: '',
        paymentDetails: {
          ...state.paymentDetails,
          data: payload,
          isLoading: false,
          successMessage: 'successfully Fetched.',
        },
      };
    case GET_PAYMENTS_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        successMessage: '',
        errorMessage: payload,
        paymentDetails: {
          ...state.paymentDetails,
          isLoading: false,
          successMessage: '',
          errorMessage: payload,
        },
      };
    case EMPTY_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: '',
      };
    case CLEAR_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          successMessage: '',
          errorMessage: '',
          data: {},
        },
      };
    default:
      return state;
  }
};
