import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const approveVDLoanSchema = yup.object().shape({
  amountConvertedToVd: yup.number().required(requiredConstant),
  bdLoanRepaidAmount: yup.number().required(requiredConstant),
  vdLoanTenureInMonths: yup.number().required(requiredConstant),
  vdInterestRate: yup.number().required(requiredConstant),
  vdQuarterlyInstallmentAmount: yup.number().required(requiredConstant),
  vdInstallmentsCount: yup.number().required(requiredConstant),
  termsAccepted: yup.boolean().required(requiredConstant),
});
