import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { get } from 'lodash';

import { WrappedTextInput } from 'components';

import styles from './styles';

const SignupDetail = ({ control }: any) => {
  const classes = styles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <Typography className={classes.investNowSubHeader} variant="h3">
          Signup Details
        </Typography>
        <Controller
          name="signupDetails.name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Box className={classes.controllerBox}>
              <WrappedTextInput
                type="text"
                label="Investor's Name"
                placeholder="Investor's Name"
                className={classes.textValInput}
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                value={value}
                onChange={(e: any) =>
                  onChange(get(e, 'target.value').trimStart())
                }
                error={!!error?.message}
              />
              {error?.message && (
                <span className={classes.errorText}>{error?.message}</span>
              )}
            </Box>
          )}
          rules={{ required: "Founder's Name is required" }}
        />
        <Controller
          name="signupDetails.email"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Box className={classes.controllerBox}>
              <WrappedTextInput
                type="text"
                label="Email"
                placeholder="Investor's Email"
                className={classes.textValInput}
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                value={value}
                onChange={(e: any) =>
                  onChange(get(e, 'target.value').trimStart())
                }
                error={!!error?.message}
              />
              {error?.message && (
                <span className={classes.errorText}>{error?.message}</span>
              )}
            </Box>
          )}
          rules={{ required: 'Email is required' }}
        />

        {[
          'linkedinUrl',
          'twitterUrl',
          'crunchbaseUrl',
          'anglelistUrl',
          'otherUrl',
        ].map((item: string) => (
          <Controller
            key={item}
            name={`signupDetails.${item}`}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className={classes.controllerBox}>
                <WrappedTextInput
                  type="text"
                  label={item.charAt(0).toUpperCase() + item.slice(1)}
                  placeholder={`Please enter ${
                    item.charAt(0).toUpperCase() + item.slice(1)
                  }`}
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={(e: any) =>
                    onChange(get(e, 'target.value').trimStart().toLowerCase())
                  }
                  error={!!error?.message}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </Box>
            )}
          />
        ))}
      </Grid>
    </Grid>
  );
};
export default SignupDetail;
