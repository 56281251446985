import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  //////////////////////
  tableContainer: {
    boxShadow: theme.shadows[0],
    height: 390,
    overflow: 'overlay',
    borderRadius: 11,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      padding: '16px 8px',
      textAlign: 'left',
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      padding: '8px',
      textAlign: 'left',
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
  },
  textValInput: {
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 13,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  actionEditDeleteCell: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    padding: 5,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));
