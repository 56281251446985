import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  closeIcon: {
    fontSize: 20,
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    //padding: theme.spacing(0, 1),
    height: '20px',
    margin: '5px 15px 0px 0px',
    //...theme.mixins.toolbar,
    position: 'relative',
    zIndex: 3,
  },
}));
