import React, { useEffect } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { RootState } from 'redux-modules/store/rootState';
import { Box, Button } from 'components';

import styles from './styles';
import StartupInformationForm from '../StartupInformationForm';

type Props = {
  openBenchmarkDashboard: boolean;
  setOpenBenchmarkDashboard: any;
  editProfile: boolean;
};

const BenchMarkStartupInfo = ({
  openBenchmarkDashboard,
  setOpenBenchmarkDashboard,
  editProfile,
}: Props) => {
  const classes = styles();

  const { startUpId, name, role } = useSelector(
    ({ Auth }: RootState) => Auth.user,
  );

  const { startupDetails } = useSelector(
    ({ EnquiryForm }: RootState) => EnquiryForm,
  );

  const [formLoading, setFormLoading] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  // const [formErrors, setFormErrors] = React.useState<any>();

  const handleGetStartedButton = () => {
    const scrollBasic: any = document.getElementById('benchmark-basic-detail');
    scrollBasic?.scrollIntoView();
    // setErrorMessage('');
    // if (
    //   isEmpty(startupFullName) ||
    //   isEmpty(ycBatch) ||
    //   isEmpty(businessModel) ||
    //   !isEmpty(formErrors)
    // ) {
    //   setErrorMessage('Please fill the required field!');
    // } else {
    //   setOpenBenchmarkDashboard(true);
    // }
  };

  useEffect(() => {
    if (!openBenchmarkDashboard && !editProfile) {
      const keysTOCheck = [
        'startupFullName',
        'ycIncorporationCountry',
        // 'stateOfIncorporation',
        'businessOpsLocation',
        'industryType',
        'businessModel',
        'fundingStage',
        'accelerator',
        'startupTaxIdNumber',
      ];
      const emptyFields = Object.keys(startupDetails).filter((key) => {
        if (keysTOCheck.includes(key) && isEmpty(get(startupDetails, key)))
          return true;
        else return false;
      });
      if (isEmpty(emptyFields)) {
        setOpenBenchmarkDashboard(true);
      }
      setFormLoading(false);
    } else {
      setFormLoading(false);
    }
  }, [startupDetails, editProfile]);

  return (
    <Box className={classes.benchMarkContainer}>
      <Box className={classes.benchmarkHeadEmpty}></Box>
      <Box className={classes.benchMarkContent}>
        {formLoading ? (
          <Box className={classes.loader}>
            <CircularProgress color="inherit" size={40} />
          </Box>
        ) : (
          <>
            {editProfile && (
              <Typography
                variant="h5"
                className={classes.backToDashboardText}
                onClick={() => setOpenBenchmarkDashboard(true)}
              >
                <KeyboardBackspaceIcon /> Back to Benchmark Tool
              </Typography>
            )}
            {!editProfile && (
              <Box className={classes.benchmarkTopContainer}>
                <Typography
                  variant="caption"
                  className={classes.pageSmallTitle}
                >
                  BenchMark KPI
                </Typography>
                <Box className={classes.containerBox}>
                  <Typography variant="body1" className={classes.welcomeHeader}>
                    Welcome, {name}
                  </Typography>
                  <Box>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.welcomeText}
                    >
                      <span className={classes.welcomeTextDot}></span>
                      Wish to check how your business growth stacks up against
                      your competitors?
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.welcomeText}
                    >
                      <span className={classes.welcomeTextDot}></span>
                      Need more insights on what valuations to expect based on
                      your current traction?
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.welcomeText}
                    >
                      <span className={classes.welcomeTextDot}></span>
                      Want to apply for 8vdx founder-friendly venture debt?
                    </Typography>
                  </Box>
                  <Button
                    name="Get Started"
                    className={classes.getStartedBtn}
                    disabled={isLoading}
                    onClick={() => handleGetStartedButton()}
                  />
                </Box>
              </Box>
            )}
            <Box className={classes.benchmarkBottomContainer}>
              {/* <Box className={classes.benchmarkBottomInnerContainer}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.formHeadingText}
                >
                  Startup debt application
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.subHeading}
                >
                  Please provide the following info to get started [Let’s
                  organise it in the same format as we Startups debt application
                  form]
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.errorText}
                >
                  {errorMessage}
                </Typography>
              </Box> */}
              <StartupInformationForm
                role={role}
                existingStartup={true}
                selectedStartupId={startUpId}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                // setFormErrors={setFormErrors}
                successAction={() => setOpenBenchmarkDashboard(true)}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default BenchMarkStartupInfo;
