import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  MenuItem,
  // Avatar,
  Toolbar,
  Drawer,
  Divider,
  Badge,
  CircularProgress,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { get, isEmpty } from 'lodash';
import { useLocation, useParams } from 'react-router-dom';

import { emptyEnquiryForm } from 'redux-modules/enquiryForm/Actions';
import { Box, WrappedSelect } from 'components';
import { RootState } from 'redux-modules/store/rootState';
import { isStartupInCurrentYcBatch } from 'services';
import { handleSignOut, getBatches } from 'redux-modules/auth/Actions';
import history from 'common/utils/history';
import { Logo, MenuDash } from 'assets';
import { setDashboardView } from 'redux-modules/global/Actions';
import { ADMIN_VIEW } from 'common/utils/constants';

import styles from './styles';

const adminStartupRoutes = [
  { name: 'Dashboard', link: '/dashboard', key: 'dashboard' },
  { name: 'Actions', link: '/actions', key: 'actions' },
  { name: 'All Enquiries', link: '/admin/enquiry-list', key: 'enquiry-list' },
  { name: 'Portfolio', link: '/portfolio', key: 'portfolio' },
  // { name: 'Payments', link: '/payments', key: 'payments' },
  // { name: 'Tickets', link: '/admin/tickets', key: 'tickets' },
  {
    name: 'Yardstick',
    link: '/admin/benchmark-kpi',
    key: 'benchmark-kpi',
  },
  {
    name: 'Fundraising CRM',
    link: '/admin/fcrms',
    key: 'fcrms',
  },
  { name: 'Settings', link: '/settings', key: 'settings' },
  {
    name: 'Startup Onboard',
    link: '/admin/startup-onboard/NEW',
    key: 'startup-onboard',
  },
  // {
  //   name: 'Clients',
  //   link: '/clients',
  //   key: 'clients',
  // },
];

const adminInvestorRoutes = [
  { name: 'Dashboard', link: '/dashboard', key: 'dashboard' },
  { name: 'Actions', link: '/actions', key: 'actions' },
  { name: 'Investors', link: '/admin/investor-list', key: 'investor-list' },
  { name: 'Deals', link: '/admin/deals', key: 'deals' },
  { name: 'Settings', link: '/settings', key: 'settings' },
  {
    name: 'Investment Setting',
    link: '/admin/investment-settings',
    key: 'investment-settings',
  },
  {
    name: 'Investor Onboard',
    link: '/admin/investor-onboard/NEW',
    key: 'investor-onboard',
  },
  { name: 'Referral Data', link: '/admin/referral-data', key: 'referral-data' },
];

const adminVCRoutes = [
  { name: 'Dashboard', link: '/dashboard', key: 'dashboard' },
  { name: 'Actions', link: '/actions', key: 'actions' },
  { name: 'VC Firms', link: '/admin/vc-firms', key: 'vc-firms' },
  { name: 'Funds', link: '/admin/vc-funds', key: 'vc-funds' },
  { name: 'Settings', link: '/settings', key: 'settings' },
  { name: 'Prospective Deal', link: '/prospective', key: 'prospective' },
  { name: 'Subscription', link: '/subscription', key: 'subscription' },
  // {
  //   name: 'VC Onboard',
  //   link: '/admin/vc-onboard/NEW',
  //   key: 'vc-onboard',
  // },
];

const startUpRoutes = [
  { name: 'Dashboard', link: '/dashboard', key: 'dashboard' },
  { name: 'Yardstick', link: '/benchmark-kpi-v2', key: 'benchmark-kpi-v2' },
  {
    name: 'Fundraising CRM',
    link: '/fund-raising',
    key: 'fund-raising',
  },
  { name: 'Actions', link: '/actions', key: 'actions' },
  { name: 'Payments', link: '/payments', key: 'payments' },
  { name: 'Profile', link: '/startup/profile', key: 'profile' },
  { name: 'Settings', link: '/settings', key: 'settings' },
  { name: 'Contact Us', link: '/startup/contact-us', key: 'contact-us' },
];

const startUpRoutesForNonCurrentYc = [
  { name: 'Dashboard', link: '/dashboard', key: 'dashboard' },
  { name: 'Yardstick', link: '/benchmark-kpi-v2', key: 'benchmark-kpi-v2' },
  {
    name: 'Fundraising CRM',
    link: '/fund-raising',
    key: 'fund-raising',
  },
  { name: 'Actions', link: '/actions', key: 'actions' },
  { name: 'Profile', link: '/startup/profile', key: 'profile' },
  { name: 'Settings', link: '/settings', key: 'settings' },
  { name: 'Contact Us', link: '/startup/contact-us', key: 'contact-us' },
];

const startUpNonYcRoutes = [
  { name: 'Dashboard', link: '/dashboard', key: 'dashboard' },
  { name: 'Yardstick', link: '/benchmark-kpi-v2', key: 'benchmark-kpi-v2' },
  {
    name: 'Fundraising CRM',
    link: '/fund-raising',
    key: 'fund-raising',
  },
  { name: 'Contact Us', link: '/startup/contact-us', key: 'contact-us' },
];

const NavBar = () => {
  const { user, ycBatches } = useSelector(({ Auth }: RootState) => Auth);
  const {
    startupInfo: { ycBatch },
  } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const pathName = useLocation().pathname;
  const params = useParams<Record<string, string>>();

  const actionsListState = useSelector(
    ({ global }: RootState) => global.actionsList,
  );
  const { dashboardStartupView } = useSelector(
    ({ global }: RootState) => global,
  );
  const actionsList = get(actionsListState, 'data', []);
  const dispatch = useDispatch();
  const classes = styles();
  const [active, setActive] = useState('');
  const [count, setCount] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCurrentYc, setIsCurrentYc] = React.useState<any>(undefined);
  const [startupView, setStartupView] = React.useState<string>(
    dashboardStartupView,
  );
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;
  const isRoleAdmin = get(user, 'role') === 'ADMIN';

  useEffect(() => {
    if (ycBatches.length === 0) {
      dispatch(getBatches());
    }
  }, [ycBatches]);

  useEffect(() => {
    if (!isRoleAdmin) {
      isStartupInCurrentYcBatch()
        .then((res) => {
          setIsCurrentYc(get(res, 'inCurrentBatch') || false);
        })
        .catch((error) => {
        console.log(error); // eslint-disable-line
          history.push('/sign-in');
        });
    }
  }, [isRoleAdmin]);

  useEffect(() => {
    const basePathName = Object.values(params).reduce(
      (path, param) => path.replace('/' + param, ''),
      pathName,
    );
    const activeItem = basePathName.split('/')[
      basePathName.split('/').length - 1
    ];
    setActive(activeItem);
  }, [pathName]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1025
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const handleSignOutButton = () => {
    localStorage.removeItem('dashboard');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Intercom('shutdown');
    setIsLoading(true);
    dispatch(handleSignOut());
    dispatch(emptyEnquiryForm());
    dispatch(setDashboardView('STARTUP'));
    setIsLoading(false);
  };

  const Buttons = () => (
    <Box className={classes.leftBottomSection}>
      <Box className={!mobileView && classes.leftLinks}>
        <MenuItem className={classes.logoutBtn} onClick={handleSignOutButton}>
          {isLoading && <CircularProgress color="inherit" size={20} />}
          Logout
        </MenuItem>
      </Box>
    </Box>
  );

  useEffect(() => {
    if (window.localStorage.getItem('dashboard') === ADMIN_VIEW.STARTUP) {
      setStartupView(ADMIN_VIEW.STARTUP);
      dispatch(setDashboardView(ADMIN_VIEW.STARTUP));
    } else if (
      window.localStorage.getItem('dashboard') === ADMIN_VIEW.INVESTOR
    ) {
      setStartupView(ADMIN_VIEW.INVESTOR);
      dispatch(setDashboardView(ADMIN_VIEW.INVESTOR));
    } else if (
      window.localStorage.getItem('dashboard') === ADMIN_VIEW.VC_ADMIN
    ) {
      setStartupView(ADMIN_VIEW.VC_ADMIN);
      dispatch(setDashboardView(ADMIN_VIEW.VC_ADMIN));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(actionsList) && !isEmpty(startupView)) {
      const actionCount =
        startupView === ADMIN_VIEW.STARTUP
          ? actionsList.filter(
              (item: any) =>
                item.status === 'PENDING' && item.actionType === 'STARTUP',
            )
          : startupView === ADMIN_VIEW.INVESTOR
          ? actionsList.filter(
              (item: any) =>
                item.status === 'PENDING' && item.actionType === 'INVESTOR',
            )
          : startupView === ADMIN_VIEW.VC_ADMIN
          ? actionsList.filter(
              (item: any) =>
                item.status === 'PENDING' && item.actionType === 'VC_FIRM',
            )
          : actionsList;
      setCount(actionCount);
    }
  }, [actionsList, startupView]);

  const handleSwitchChange = (event: any) => {
    if (event.target.value === ADMIN_VIEW.STARTUP) {
      window.localStorage.setItem('dashboard', ADMIN_VIEW.STARTUP);
    } else if (event.target.value === ADMIN_VIEW.INVESTOR) {
      window.localStorage.setItem('dashboard', ADMIN_VIEW.INVESTOR);
    } else if (event.target.value === ADMIN_VIEW.VC_ADMIN) {
      window.localStorage.setItem('dashboard', ADMIN_VIEW.VC_ADMIN);
    }
    setStartupView(event.target.value);
    dispatch(setDashboardView(event.target.value));
    history.push('/dashboard');
  };

  const getAdminDropdowns = () => {
    const options = [
      { text: 'VentureInsights', value: ADMIN_VIEW.VC_ADMIN },
      { text: 'Investor', value: ADMIN_VIEW.INVESTOR },
      { text: 'Startup', value: ADMIN_VIEW.STARTUP },
    ];
    return options;
  };

  const adminSwitch = () => {
    return (
      <div className={classes.switchBox}>
        <WrappedSelect
          value={startupView || dashboardStartupView}
          onChange={handleSwitchChange}
          className={classes.wrappedSelectVal}
          InputProps={{ classes: { root: classes.inputRoot } }}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused,
            },
          }}
          dropdowns={getAdminDropdowns()}
        />
      </div>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={classes.toolbarBox}>
        <Drawer
          className={classes.drawer}
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <Box className={classes.drawerContent}>
            <Box className={classes.drawerHeader}>
              <ArrowBackIosIcon
                className={classes.rightIcon}
                onClick={handleDrawerClose}
              />
            </Box>
            <Divider />
            <Box className={classes.linksInfo}>
              {userInfo()}
              {isRoleAdmin && adminSwitch()}
              {isRoleAdmin &&
                startupView === ADMIN_VIEW.STARTUP &&
                adminStartupRoutes.map((i) => {
                  return (
                    <MenuItem
                      className={
                        active === i.key.toLowerCase()
                          ? count && count.length && i.name === 'Actions'
                            ? classes.redColor
                            : classes.activeMenuItem
                          : classes.menuItem
                      }
                      key={i.name}
                      onClick={() => {
                        history.push(i.link);
                      }}
                    >
                      {i.name}
                      <Badge
                        className={classes.brageAccount}
                        overlap="circular"
                        color="secondary"
                        badgeContent={
                          i.name === 'Actions' && count && count.length
                            ? count.length
                            : null
                        }
                      ></Badge>
                    </MenuItem>
                  );
                })}
              {isRoleAdmin &&
                startupView === ADMIN_VIEW.INVESTOR &&
                adminInvestorRoutes.map((i) => {
                  return (
                    <MenuItem
                      className={
                        active === i.key.toLowerCase()
                          ? count && count.length && i.name === 'Actions'
                            ? classes.redColor
                            : classes.activeMenuItem
                          : classes.menuItem
                      }
                      key={i.name}
                      onClick={() => {
                        history.push(i.link);
                      }}
                    >
                      {i.name}
                      <Badge
                        className={classes.brageAccount}
                        overlap="circular"
                        color="secondary"
                        badgeContent={
                          i.name === 'Actions' && count && count.length
                            ? count.length
                            : null
                        }
                      ></Badge>
                    </MenuItem>
                  );
                })}
              {isRoleAdmin &&
                startupView === ADMIN_VIEW.VC_ADMIN &&
                adminVCRoutes.map((i) => {
                  return (
                    <MenuItem
                      className={
                        active === i.key.toLowerCase()
                          ? count && count.length && i.name === 'Actions'
                            ? classes.redColor
                            : classes.activeMenuItem
                          : classes.menuItem
                      }
                      key={i.name}
                      onClick={() => {
                        history.push(i.link);
                      }}
                    >
                      {i.name}
                      <Badge
                        className={classes.brageAccount}
                        overlap="circular"
                        color="secondary"
                        badgeContent={
                          i.name === 'Actions' && count && count.length
                            ? count.length
                            : null
                        }
                      ></Badge>
                    </MenuItem>
                  );
                })}
              {((!isRoleAdmin && user.accelerator === 'YC' && isCurrentYc) ||
                (!isRoleAdmin &&
                  !user.accelerator &&
                  ycBatch &&
                  isCurrentYc)) &&
                startUpRoutes.map((i) => {
                  return (
                    <MenuItem
                      className={
                        active === i.key.toLowerCase()
                          ? count && count.length && i.name === 'Actions'
                            ? classes.redColor
                            : classes.activeMenuItem
                          : classes.menuItem
                      }
                      key={i.name}
                      onClick={() => {
                        if (i.key === 'contact-us') {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          window.Intercom('show');
                        } else {
                          history.push(i.link);
                        }
                      }}
                    >
                      {i.name}
                      <Badge
                        className={classes.brageAccount}
                        overlap="circular"
                        color="secondary"
                        badgeContent={
                          i.name === 'Actions' && count && count.length
                            ? count.length
                            : null
                        }
                      ></Badge>
                    </MenuItem>
                  );
                })}
              {((!isRoleAdmin && user.accelerator === 'YC' && !isCurrentYc) ||
                (!isRoleAdmin &&
                  !user.accelerator &&
                  ycBatch &&
                  !isCurrentYc)) &&
                startUpRoutesForNonCurrentYc.map((i) => {
                  return (
                    <MenuItem
                      className={
                        active === i.key.toLowerCase()
                          ? count && count.length && i.name === 'Actions'
                            ? classes.redColor
                            : classes.activeMenuItem
                          : classes.menuItem
                      }
                      key={i.name}
                      onClick={() => {
                        if (i.key === 'contact-us') {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          window.Intercom('show');
                        } else {
                          history.push(i.link);
                        }
                      }}
                    >
                      {i.name}
                      <Badge
                        className={classes.brageAccount}
                        overlap="circular"
                        color="secondary"
                        badgeContent={
                          i.name === 'Actions' && count && count.length
                            ? count.length
                            : null
                        }
                      ></Badge>
                    </MenuItem>
                  );
                })}
              {!isRoleAdmin &&
                user.accelerator &&
                user.accelerator !== 'YC' &&
                startUpNonYcRoutes.map((i) => {
                  return (
                    <MenuItem
                      className={
                        active === i.key.toLowerCase()
                          ? count && count.length && i.name === 'Actions'
                            ? classes.redColor
                            : classes.activeMenuItem
                          : classes.menuItem
                      }
                      key={i.name}
                      onClick={() => {
                        if (i.key === 'contact-us') {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          window.Intercom('show');
                        } else {
                          history.push(i.link);
                        }
                      }}
                    >
                      {i.name}
                      <Badge
                        className={classes.brageAccount}
                        overlap="circular"
                        color="secondary"
                        badgeContent={
                          i.name === 'Actions' && count && count.length
                            ? count.length
                            : null
                        }
                      ></Badge>
                    </MenuItem>
                  );
                })}
            </Box>
            <Box className={classes.mobileBtns}>{Buttons()}</Box>
          </Box>
        </Drawer>

        <Box>{LogoImg()}</Box>
        <img
          src={MenuDash}
          alt={MenuDash}
          className={classes.menuDash}
          {...{
            onClick: handleDrawerOpen,
          }}
        />
      </Toolbar>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar className={classes.dashLeftContent}>
        {LogoImg()}
        <Box className={classes.linksInfo}>
          {userInfo()}
          {isRoleAdmin && adminSwitch()}
          <Box className={classes.leftLinks}>
            {isRoleAdmin &&
              startupView === ADMIN_VIEW.STARTUP &&
              adminStartupRoutes.map((i) => {
                return (
                  <MenuItem
                    className={
                      active === i.key.toLowerCase()
                        ? count && count.length && i.name === 'Actions'
                          ? classes.redColor
                          : classes.activeMenuItem
                        : classes.menuItem
                    }
                    key={i.name}
                    onClick={() => {
                      history.push(i.link);
                    }}
                  >
                    {i.name}{' '}
                    <Badge
                      className={classes.brageAccount}
                      overlap="circular"
                      color="secondary"
                      badgeContent={
                        i.name === 'Actions' && count && count.length
                          ? count.length
                          : null
                      }
                    ></Badge>
                  </MenuItem>
                );
              })}
            {isRoleAdmin &&
              startupView === ADMIN_VIEW.VC_ADMIN &&
              adminVCRoutes.map((i) => {
                return (
                  <MenuItem
                    className={
                      active === i.key.toLowerCase()
                        ? count && count.length && i.name === 'Actions'
                          ? classes.redColor
                          : classes.activeMenuItem
                        : classes.menuItem
                    }
                    key={i.name}
                    onClick={() => {
                      history.push(i.link);
                    }}
                  >
                    {i.name}{' '}
                    <Badge
                      className={classes.brageAccount}
                      overlap="circular"
                      color="secondary"
                      badgeContent={
                        i.name === 'Actions' && count && count.length
                          ? count.length
                          : null
                      }
                    ></Badge>
                  </MenuItem>
                );
              })}
            {isRoleAdmin &&
              startupView === ADMIN_VIEW.INVESTOR &&
              adminInvestorRoutes.map((i) => {
                return (
                  <MenuItem
                    className={
                      active === i.key.toLowerCase()
                        ? count && count.length && i.name === 'Actions'
                          ? classes.redColor
                          : classes.activeMenuItem
                        : classes.menuItem
                    }
                    key={i.name}
                    onClick={() => {
                      history.push(i.link);
                    }}
                  >
                    {i.name}{' '}
                    <Badge
                      className={classes.brageAccount}
                      overlap="circular"
                      color="secondary"
                      badgeContent={
                        i.name === 'Actions' && count && count.length
                          ? count.length
                          : null
                      }
                    ></Badge>
                  </MenuItem>
                );
              })}
            {((!isRoleAdmin && user.accelerator === 'YC' && isCurrentYc) ||
              (!isRoleAdmin && !user.accelerator && ycBatch && isCurrentYc)) &&
              startUpRoutes.map((i) => {
                return (
                  <MenuItem
                    className={
                      active === i.key.toLowerCase()
                        ? count && count.length && i.name === 'Actions'
                          ? classes.redColor
                          : classes.activeMenuItem
                        : classes.menuItem
                    }
                    key={i.name}
                    onClick={() => {
                      if (i.key === 'contact-us') {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        window.Intercom('show');
                      } else {
                        history.push(i.link);
                      }
                    }}
                  >
                    {i.name}
                    {/* <Typography
                      variant="caption"
                      className={classes.brageAccount}
                    >
                      {i.name === 'Actions' && count && count.length
                        ? count.length
                        : null}
                    </Typography> */}
                    <Badge
                      className={classes.brageAccount}
                      overlap="circular"
                      color="secondary"
                      badgeContent={
                        i.name === 'Actions' && count && count.length
                          ? count.length
                          : null
                      }
                    ></Badge>
                  </MenuItem>
                );
              })}
            {((!isRoleAdmin && user.accelerator === 'YC' && !isCurrentYc) ||
              (!isRoleAdmin && !user.accelerator && ycBatch && !isCurrentYc)) &&
              startUpRoutesForNonCurrentYc.map((i) => {
                return (
                  <MenuItem
                    className={
                      active === i.key.toLowerCase()
                        ? count && count.length && i.name === 'Actions'
                          ? classes.redColor
                          : classes.activeMenuItem
                        : classes.menuItem
                    }
                    key={i.name}
                    onClick={() => {
                      if (i.key === 'contact-us') {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        window.Intercom('show');
                      } else {
                        history.push(i.link);
                      }
                    }}
                  >
                    {i.name}
                    {/* <Typography
                      variant="caption"
                      className={classes.brageAccount}
                    >
                      {i.name === 'Actions' && count && count.length
                        ? count.length
                        : null}
                    </Typography> */}
                    <Badge
                      className={classes.brageAccount}
                      overlap="circular"
                      color="secondary"
                      badgeContent={
                        i.name === 'Actions' && count && count.length
                          ? count.length
                          : null
                      }
                    ></Badge>
                  </MenuItem>
                );
              })}
            {!isRoleAdmin &&
              user.accelerator &&
              user.accelerator !== 'YC' &&
              startUpNonYcRoutes.map((i) => {
                return (
                  <MenuItem
                    className={
                      active === i.key.toLowerCase()
                        ? count && count.length && i.name === 'Actions'
                          ? classes.redColor
                          : classes.activeMenuItem
                        : classes.menuItem
                    }
                    key={i.name}
                    onClick={() => {
                      if (i.key === 'contact-us') {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        window.Intercom('show');
                      } else {
                        history.push(i.link);
                      }
                    }}
                  >
                    {i.name}
                    {/* <Typography
                      variant="caption"
                      className={classes.brageAccount}
                    >
                      {i.name === 'Actions' && count && count.length
                        ? count.length
                        : null}
                    </Typography> */}
                    <Badge
                      className={classes.brageAccount}
                      overlap="circular"
                      color="secondary"
                      badgeContent={
                        i.name === 'Actions' && count && count.length
                          ? count.length
                          : null
                      }
                    ></Badge>
                  </MenuItem>
                );
              })}
          </Box>
        </Box>
        {Buttons()}
      </Toolbar>
    );
  };

  const LogoImg = () => {
    return (
      <Box className={classes.dashLogo}>
        <img src={Logo} alt={Logo} className={classes.logoImg} />
      </Box>
    );
  };

  const userInfo = () => {
    return (
      <Box className={classes.dashAvatarLogo}>
        {/* <Avatar className={classes.iconStyleOne}>s</Avatar> */}
        <Typography
          variant="h4"
          gutterBottom
          className={classes.labelFundUpHead}
        >
          {user.name}
        </Typography>
        <Typography
          variant="caption"
          gutterBottom
          className={classes.labelStartUpText}
        >
          {user.email}
        </Typography>
      </Box>
    );
  };

  return <>{mobileView ? displayMobile() : displayDesktop()}</>;
};
export default NavBar;
