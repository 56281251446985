export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//regex's
const regex = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

const urlRegex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/;

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const PhoneNumberRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

//constants
const requiredConstant = 'This field is required';
const emailValidConstant = 'Please enter valid Email ID';
const urlValidConstant = 'Please enter valid URL';
const phoneValidConstant = 'Please enter valid PhoneNumber';
const rateValidConstant = 'Please enter a valid Rate';
const amountValidConstant = 'Please enter valid Amount';
const futureDateConstant = 'Please select future date';
const pastDateOnly = 'Future dates are not allowed';
const positiveConstant = 'Enter positive value';
const dateValidConstant = 'Please select a date';
const decimalInvalidConstant = 'Decimals are not allowed';
const passwordValidConstant =
  'Password must be 8-16 characters; must have numbers, upper & lowercase letters and special characters.';
const TransactionTypes = {
  Debit: 'DEBIT',
  Cash: 'CASH',
  Cheque: 'CHEQUE',
};

const urlConstants = {
  deals_s3_url: 'https://8vdx-deals.s3.amazonaws.com/index.html',
  terms_s3_url: 'https://8vdx-terms.s3.amazonaws.com/index.html',
  signUp_url: '/users/startup/signup',
  yc_batch: 'ycbatch/getall',
  getEnquiryDetailsById: 'enquires/get-by-startupid/v2/',
  getAllEnquiriesList: 'enquires/admin/get-all',
  getAllCountries: 'others/get-all-countries',
  getAllIndustries: 'others/get-all-industry-types',
  getAllYcLocations: 'others/get-all-locations',
  getAllStates: 'others/get-all-states',
  createEnquiries: 'enquires/create/v2',
  getStartupDetailsForSignUp: 'startups/detail',
  isInCurrentYcBatch: 'startups/is-in-current-yc',
  createOrUpdateStartupInformation: 'startups/information/create',
  updateStartupInformation: 'startups/update-info',
  adminCreateStartupInformation: 'startups/admin/startup-info/create',
  adminGetEnquiryId: 'enquires/get-by-id/v2',
  adminRequiredDocuments: 'documents/admin/request',
  documentsListAdmin: 'documents/admin/view',
  getActionsListStartUp: 'actions/startup/get',
  getActionsListAdmin: 'actions/admin/get',
  getDocuSignDocuments: 'documents/startup/sign-documents',
  startupGetSignedUrl: 'documents/startup/get-signed-url',
  getSignedUrlForYCDoc: 'documents/get-yc-document-signed-url',
  startupListDocuments: 'documents/startup/view',
  approveDocuments: 'documents/admin/approve',
  approveRejectDocuments: 'documents/admin/approve-reject',
  approveLoan: 'loans/admin/approve',
  processLoan: 'loans/admin/process',
  manageLoan: 'loans/startup/manage-loan',
  getStartupDetailsById: 'startups',
  startupDocumentUpload: 'documents/startup/upload',
  adminGetPortfolioList: 'loans/admin/get-portfolio-list',
  docuSignTemplates: 'docusign/template-documents',
  submitDocuSignUrl: 'documents/admin/request-sign-on-documents',
  getFundType: 'loans/admin/get-by-startupid',
  getStartupLoan: 'loans/admin/startup-loan',
  getVdByStartupId: 'venturedebts/get',
  ventureDebtSubmit: 'venturedebts/startup/request-vd-conversion',
  approveVDLoanByAdmin: 'venturedebts/admin/approve-vd-loan',
  approveVdRequest: 'venturedebts/admin/approve-vd-request',
  trackBridgeLoanProcess: 'enquires/get-loan-process-track',
  validateYcStatus: 'enquires/admin/validate-yc-status',
  trackVdProcess: 'venturedebts/get-vdprocess-tracking',
  getLoansDetails: 'loans/startup',
  getDashboardValues: 'dashboard/admin/get-values',
  investorsFollowup: 'fcrm/next-followup-fcrms',
  getPaymentsList: 'payments/admin/get-payments',
  logPayment: 'payments/log-payment',
  getVdInstallments: 'venturedebts/get-vd-installments',
  getPaymentById: 'payments/get-payment-by-id',
  getAdminRepaidAmount: 'payments/admin/get-repaid-amount',
  getStartupRepaidAmount: 'payments/startup/get-repaid-amount',
  getVdLoanDetailsByStartupId: 'venturedebts/admin/get-vd-approve-loan',
  getStartupData: 'enquires/get-by-startupid/v2',
  getFounderDetails: 'startups/founders',
  submitQuery: 'others/startup/create-query',
  checkEmailYcVerified: 'users/startup/check-email',
  inviteAdminUser: 'users/admin/invite/V2',
  inviteStartupUser: 'users/startup/invite',
  updateAdminUser: 'users/admin',
  updateStartupUser: 'users/startup',
  deleteAdminUser: 'users/admin',
  deleteStartupUser: 'users/startup',
  getAllAdminUsers: 'users/admin',
  getStartupUsers: 'users/startup',
  getAllAdminInvestors: 'investors/admin/investors',
  getAdminInvestorById: 'investors/admin/investor/get',
  updateInvestorByAdmin: 'investors/admin/investor/update',
  deleteStartupData: 'dashboard/admin/startup-delete',
  getInvestmentDocuments: '/documents/admin/investment-documents',
  saveStartupOnboardDetails: 'dashboard/onboard/startup/details',
  adminDocusignGetSignedUrl: 'documents/docusign/signed-url',
  adminAddDocusignDocument: 'documents/docusign/add-document',
  kycSignupOnboard: 'investors/admin/investor-onboard',
  createAdminInvestmentDetail: 'investors/admin/investment/create',
  addInvestorDocusignDocument: 'documents/docusign/investor/add-document',
  adminInvestorAddDocument: 'documents/admin/investor/add-document',
  adminInvestorDocumentSignedUrl:
    'documents/admin/investor/get-document-signed-url',
  adminAddInvestmentNavAttr: 'investors/admin/add-nav-attributes',
  adminGetInvestmentNavAttrList: 'investors/admin/investor-nav-attributes',
  adminGetInvestmentCurrentNavAttr: 'investors/admin/investor-nav-attribute',
  approveInvestorDocuments: 'documents/investor/admin/approve',
  adminApproveInvestment: 'investors/admin/approve-investment',
  getInvestorPayments: 'investors/admin/payments',
  startupUpcomingPayment: 'loans/startup/upcoming-payment',
  getAdminKycSSN: 'investors/admin/get-kyc-ssn',
  getAdminSubscriberSSN: 'investors/admin/get-subscriber-ssn',
  createOrUpdateDeal: 'deals/admin/create',
  getAllDeal: 'deals/all-deals',
  getDealById: 'deals/admin/deal',
  adminGetDealDocSignedurl: 'deals/admin/deal-doc-signedurl',
  adminAddDealDoc: 'deals/admin/add-deal-doc',
  getAdminDealUpdates: 'deals/admin/get-deal-updates',
  createUpdateDealUpdate: 'deals/admin/add-deal-update',
  deleteDealUpdate: 'deals/admin/delete-deal-update',
  publishDealUpdate: 'deals/admin/publish-deal-update',
  getInvestorStats: 'investors/get-Investor-stats',
  adminGetDealUpdateDocSignedurl: 'deals/admin/deal-update-doc-signedurl',
  addFundingRound: 'safe/startup/add-fuding-round',
  adminApproveFundingRound: '/safe/admin/approve-funding-round',
  getStartupFundingRoundList: 'safe/startup/funding-rounds',
  getStartupFundingRoundListByAdmin: 'safe/admin/funding-rounds',
  getLoanCloserDocusignUrl: 'loans/loan-closure/docusign-sign-url',
  getYcDocs: 'documents/get-yc-documents',
  getStartupYcDocument: 'documents/startup/get-yc-documents',
  ycVerification: 'enquires/handle-yc-verification',
  getLoanLogs: 'loans/loan-logs',
  loanTerms: 'loans/get-loanTerms',
  getLoanCloserInfo: 'loans/loan-closure',
  handleLoanCloser: 'loans/handle-loan-closure',
  scheduleMeeting: 'loans/startup/meeting-scheduled',
  agreeLoanTerms: 'loans/startup/agree-loan-terms',
  adminAgreeLoanTerms: 'loans/admin/agree-loan-terms',
  getLoanTerms: 'loans/admin/get-loan-terms',
  updateStartUpLoanTerms: 'loans/admin/update-loan-terms',
  getStartupBenchmarkingKpi: 'benchmark-kpi/startup',
  getStartupBenchmarkFundraisingKpi: 'benchmark-kpi/startup/fundraising',
  getStartupBenchmarkKpiGrowth: 'benchmark-kpi/startup-growth',
  createOrUpdateStartupMultipleKpiData: 'benchmark-kpi/startup/multiple/create',
  createOrUpdateStartupMultipleFundraising:
    'benchmark-kpi/startup/fundraising/multiple/create',
  getBenchmarkingKpiByStartupId: 'benchmark-kpi/admin',
  getFundraisingKpiByStartupId: 'benchmark-kpi/admin/fundraising',
  getBenchmarkKpiGrowthBystartupId: 'benchmark-kpi/admin/startup-growth',
  adminCreateOrUpdateStartupKpi: 'benchmark-kpi/admin/multiple/create',
  adminCreateOrUpdateFundraising:
    'benchmark-kpi/admin/fundraising/multiple/create',
  getAdminAllStartupPercentile: 'benchmark-kpi/admin/startups-percentile',
  getAdminAllNonPrimaryStartupsGrowth:
    'benchmark-kpi/admin/non-primary-startups',
  getAdminPercentileGrowth: 'benchmark-kpi/percentile-growth',
  getAdminPreviewPercentileGrowthRates:
    'benchmark-kpi/preview/percentile-growth',
  publishBenchmarkingGrowthRates: 'benchmark-kpi/admin/publish-kpi',
  getBenchmarkingStartups: 'benchmark-kpi/startups',
  deleteStartupBenchmarkingKpi: 'benchmark-kpi/startup/delete',
  deleteStartupBenchmarkFundraisingKpi:
    'benchmark-kpi/startup/fundraising/delete',
  adminDeleteBenchmarkingKpi: 'benchmark-kpi/admin/delete',
  adminDeleteBenchmarkFundraisingKpi: 'benchmark-kpi/admin/fundraising/delete',
  adminGetUploadStartupsSignedurl:
    'benchmark-kpi/upload-startups/get-signed-url',
  adminUploadStartups: 'benchmark-kpi/admin/upload-startups',
  adminDeleteStartupsAllBenchmarkingData:
    'benchmark-kpi/admin/all-benchmark-kpi',
  adminSkipStartupsAllBenchmarkingData: 'benchmark-kpi/skip-startup-publish',
  getStartupsBySearchString: 'startups/search',
  getPublishedDates: 'benchmark-kpi/admin/published-dates',
  getPublishedPercentileGrowth: 'benchmark-kpi/published/percentile-growth',

  sendInvestorsNavReportEmail: 'investors/admin/nav-reports/send-mail',
  adminReferralFilters: 'referral/admin/referral-filters',
  addReferralDetailByAdmin: 'referral/admin/add-referral',
  getAllAdminReferral: 'referral/admin/get-referral',
  getStartupDefaultTerms: 'loans/startup/loan-type',
  getStartupTerms: 'loans/get-loan-terms',
  createStartupTerms: 'loans/create-loan-terms',
  adminCreateStartupTerms: 'loans/admin/create-loan-terms',
  getStartupBridgeLoanStatus: 'loans/startup/bd-loan-status',
  getBenchmarkStartupKpiSignedUrl:
    'benchmark-kpi/startup/upload-kpi/get-signed-url',
  addStartupBenchmarkInfo: 'benchmark-kpi/startup/benchmark-info',
  getBenchmarkStartupProcess: 'benchmark-kpi/startup-process',
  getStartupValuationInsight: 'benchmark-kpi/startup-valuation-insight',
  adminGetStartupValuationInsight:
    'benchmark-kpi/admin/startup-valuation-insight',
  startupUploadBenchmarkKPI: 'benchmark-kpi/startup/upload-kpi',
  getAdminPreviewValuationInsight: 'benchmark-kpi/preview-valuation',
  adminPublishValuationInsightRange: 'benchmark-kpi/publish-valuation',
  completeAction: 'actions/startup/update-action',
  adminExportStartups: 'benchmark-kpi/admin/export-startups',
  getVcUsersDetailsByInvestor: 'vc/users',
  getVcFirmDetailsByInvestor: 'vc/vc-firm/admin/by-investor',
  adminApprovesVcfirm: 'vc/vc-firm/review-vc',
  getAdminVcFirmDocumentsByInvestor: 'vc/vc-documents/admin/by-investor',
  adminApproveRejectVcFirmDocuments: 'vc/vc-documents/admin/approve-reject',
  adminActivateDeactivateVcFirm: 'vc/vc-firm/activate-deactivate',
  getAdminAllVcFirms: 'vc/vc-firm',
  getAdminAllVcFunds: 'vc/funds',
  getAdminVcFund: 'vc/funds/admin',
  getAdminVcFirmFundsByInvestor: 'vc/funds/admin/by-investor',
  reviewVcFundActionByInvestorId: 'vc/funds/review-vc-fund',
  approveVcFund: 'vc/funds/approve',
  getFundRaisingCRM: 'fcrm/startup/create-basic-fcrm',
  getBasicFundRaisingCRM: 'fcrm/startup/basic-fcrm',
  getAllFcrms: 'fcrm/startup/fcrms',
  adminStartupFcrms: 'fcrm/admin/startup-fcrms',
  adminCcrmRound: 'fcrm/admin/round',
  updateFcrm: 'fcrm/startup/update-fcrm',
  createFcrm: 'fcrm/startup/create-fcrm',
  deleteFcrmProperty: '/fcrm/startup/delete-fcrm-property',
  createUpdateFcrmContact: '/fcrm/startup/add-contact',
  deleteFcrmContact: '/fcrm/startup/delete-contact',
  deleteFcrm: '/fcrm/startup/delete-fcrm',
  fcrmUploadSignedUrl: 'fcrm/startup/upload-fcrm/get-signed-url',
  uploadFcrmFile: 'fcrm/startup/upload-fcrm',
  sendFcrmEmail: 'fcrm/startup/send-email',
  getAdminFcrmLists: 'fcrm/admin/fcrms',
  getAdminMasterFcrmLists: 'fcrm/admin/master-fcrms',
  getEmailTemplatesList: 'fcrm/list-template',
  saveEmailTemplate: 'fcrm/save-template',
  deleteEmailTemplate: 'fcrm/delete-template',
  fcrmEmailMedia: 'fcrm/signed-url-email-media',
  checkEmailVerified: 'fcrm/email-verify-identity',
  verifyUserEmail: 'fcrm/verify-identity',
  getVcFirmAllBillingHistoryByInvestorId2: 'pricing/admin/billing-history',
  getAllVcFirmsFundStat: 'vc/vc-firm/firm-fund-stat',
  acceptYardStickPerm: 'referral/startup/accept-yardstick-permission',
  yardstickPermission: 'referral/startup/get-yardstick-permissions',
  acceptYardStickPermInvite: 'referral/startup/accept-permission-invite',
  listSubscriptionPlans: 'pricing/subscription-plans',
  listUnsubscribedUsers: 'pricing/unsubscibed-users',
  createProduct: 'pricing/create-product',
  createUpdateClient: 'client/create',
  getClientsList: 'client/admin/clients',
  getClientAccessKey: 'client/admin/get-access-key',
  viewClientServiceUsage: 'client/service-usage',
  getAllSubscribedInvestorUser: 'pricing/admin/subscribed-user',
  updateInvestorSubscription: 'pricing/update-investor-subscription',
  getAllVcFirmsSubscriptionStat: 'vc/vc-firm/firm-subscription-stat',
  updatePlanForNonSubscribed: 'pricing/unsubscribed/create-subscription',
  getDealRequestSent: 'referral/startup/get-deal-request',
  updateDealStartupBasicProfile: 'vc/deals/startup/update-basic-profile',
  getSignedUrlForVcDealDocumentForStartup:
    'vc/vc-documents/startup-vc-deal/get-signed-url',
  addOrUpdateVcDealDocumentsForStartup: 'vc/vc-documents/startup-vc-deal/add',
  getVcDealForVcInvitedStartup: 'vc/deals/vc-startup',
  checkStartupsEmailReader: 'startups/check-email-reader',
  createOrUpdateVcAdminDeal: 'vc/admin-deals/create',
  getVcAdminDeal: '/vc/admin-deals/by-id',
  getAllVcAdminDeals: 'vc/admin-deals',
  getSignedUrlForVcAdminDocument: 'vc/admin-documents/get-signed-url',
  addOrUpdateVcAdminDealsMultipleDocuments:
    'vc/admin-documents/vc-deals-multi-add',
  deleteVcAdminDocuments: 'vc/admin-documents/delete',
  getVcAdminDocuments: 'vc/admin-documents/get-all',
  deleteVcDeal: '/vc/admin-deals/delete',
  getSignedUrlForVcAdminDealsImport:
    'vc/admin-deals/import-deal/get-signed-url',
  processVcAdminDealsCSVImportFromS3: 'vc/admin-deals/import-deal',
  createUpdateVcYcBatch: 'vc/ycbatch/create',
  getAllVcYcBatchForAdmin: 'vc/ycbatch/admin',
  sendVcAdminDealEmailToVcUsers: 'vc/admin-deals/send-email',
};

const ROLES = {
  ADMIN: 'ADMIN',
  STARTUP_ADMIN: 'STARTUP_ADMIN',
};

const FUND_TYPE = {
  BRIDGE_LOAN: 'BRIDGE_LOAN',
  VENTURE_DEBT: 'VENTURE_DEBT',
  ALL: 'ALL',
};

export const ACTION_NAME = {
  REQUEST_BRIDGE_LOAN_SIGN_DOC: 'REQUEST_BRIDGE_LOAN_SIGN_DOC',
};
export const ACTION_STATUS = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};
const LOAN_STATUS = {
  APPROVED: 'APPROVED',
  PROCESSED: 'PROCESSED',
};

const STATIC_WEBSITE: Record<string, string> = {
  dev: 'http://qa.8vdx.com.s3-website-us-east-1.amazonaws.com/',
  qa: 'http://qa.8vdx.com.s3-website-us-east-1.amazonaws.com/',
  stage: 'https://stage.8vdx.com/',
  prod: 'https://8vdx.com/',
};

const BENCHMARK_CSV_SAMPLE_URL =
  'https://s3.amazonaws.com/dev-8vdx-media/KPISampleFormat.xlsx';

const BENCHMARK_STARTUP_CSV_SAMPLE_URL =
  'https://s3.amazonaws.com/dev-8vdx-media/KPISampleFormat_Startup.xlsx';

const FCRM_SAMPLE_CSV_FILE =
  'https://dev-8vdx-media.s3.amazonaws.com/FCRM_UPLOAD_SAMPLE_File.csv';

const VC_PROSPECTIVE_DEAL_SAMPLE_URL =
  'https://dev-8vdx-media.s3.amazonaws.com/VcProspectiveAdminDealSampleFormat.xlsx';

//font family
const fontFamily = 'mulish';

const ROLES_OPTION = [
  { text: 'ADMIN', value: 'ADMIN' },
  { text: 'ADMIN', value: 'STARTUP_ADMIN' },
];

const DEFAULT_PASSWORD = 'Password@123';

const FUNDING_ENTITY_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  ENTITY: 'ENTITY',
};

const DEAL_TYPE = {
  STARTUP: 'STARTUP',
  FUND: 'FUND',
};

const DEAL_TYPE_MAPPER: any = {
  STARTUP: 'Startup',
  FUND: 'Fund',
};

const KPI_FUNDRAISE_TYPE_MAPPER: any = {
  EQUITY: 'Equity',
  DEBT: 'Debt',
  SAFE_POST_MONEY: 'SAFE (Post Money)',
  SAFE_PRE_MONEY: 'SAFE (Pre Money)',
  // SAFE: 'SAFE',
  CONVERTIBLE: 'Convertible',
};

const PERCENTILE_GROWTH = ['10th', '25th', '50th', '75th', '90th'];

const YARDSTICK_KPI_IN_NUMBER = [
  'Total Customers',
  'DAU',
  'MAU',
  '% Logged in',
  'Runway (mo)',
];

const REFERRAL_STATUS_TYPE: any = {
  INVITED: 'Yet to sign up',
  REGISTERED_WITH_DIFFERENT_REFERRAL: 'Registered with different referral',
  SIGNED_UP: 'Signed up',
  INVESTED: 'Invested in deal',
  DEAL_CLOSED: 'Deal Closed',
};

const ADMIN_VIEW: any = {
  STARTUP: 'STARTUP',
  INVESTOR: 'INVESTOR',
  VC_ADMIN: 'VC_ADMIN',
};

const VC_ADMIN_DOCUMENT_RELATIVE_NAME = {
  DEAL: 'DEAL',
};

export const FCRM_ROUND_MAPPER: any = {
  PRE_SEED: 'Pre-seed',
  SEED: 'Seed',
  SEED_EXTENSION: 'Seed Extension',
  PRE_SERIES_A: 'Pre-Series A',
  SERIES_A: 'Series A',
  SERIES_B: 'Series B',
  SERIES_C: 'Series C',
  SERIES_D: 'Series D',
  SERIES_E: 'Series E',
  SERIES_F: 'Series F',
};
export const FCRM_STAGE_MAPPER: any = {
  LEAD: 'Lead',
  CONTACTED: 'Contacted',
  MEETING: 'Meeting',
  DUE_DILIGENCE: 'Due Diligence',
  TERM_SHEET: 'Term Sheet',
  CLOSED: 'Closed',
  CLOSED_WON: 'Closed-Won',
  CLOSED_PASS: 'Closed-Pass',
  VC_REQUEST: 'Pending Investor Request',
};
export const FCRM_STAGE_COLORS: any = {
  LEAD: '#E4E8FF',
  CONTACTED: '#DEF3FF',
  MEETING: '#DFFFE0',
  DUE_DILIGENCE: '#FFF7DD',
  TERM_SHEET: '#FBEDDD',
  CLOSED: '#FFEAE9',
  CLOSED_WON: '#FFE5DF',
  CLOSED_PASS: '#FFEDED',
};
export const CURRENCY_MAPPER: any = {
  INR: 'Indian Rupee (INR)',
  MUR: 'Mauritian Rupee (MUR)',
  KYD: 'Cayman Islands Dollar (KYD)',
  SGD: 'Singapore Dollar (SGD)',
  USD: 'United States Dollar (USD)',
  CAD: 'Canadian Dollar (CAD)',
};

export const READER_EMAILS: any = {
  qa: 'qa-mail.8vdx.com',
  dev: 'dev-mail.8vdx.com',
  stage: 'stage-mail.8vdx.com',
  prod: 'mail.8vdx.com',
};

export const SUBSCRIBED_USER: any = {
  ANGEL_INVESTOR: 'ANGEL_INVESTOR',
  VC_FIRM: 'VC_FIRM',
  YARDSTICK: 'YARDSTICK',
};

const STARTUP_DEAL_DOCUMENT_NAME = {
  DEAL_PITCH_DECK_DOC: 'DEAL_PITCH_DECK_DOC',
  OTHER: 'OTHER',
  DEAL_INVESTMENT_NOTE: 'DEAL_INVESTMENT_NOTE',
};

const FCRM_TAG = {
  OWN_INVESTOR: 'OWN_INVESTOR',
  VC_FIRM: 'VC_FIRM',
  ANGEL: 'ANGEL',
  ADMIN: 'ADMIN',
};

const VC_DEAL_STATUS_TYPE = {
  ACTIVE: 'ACTIVE',
  PROSPECTIVE: 'PROSPECTIVE',
};

const VC_ADMIN_DOCUMENT_NAME = {
  DEAL_PITCH_DECK_DOC: 'DEAL_PITCH_DECK_DOC',
  OTHER_DOC: 'OTHER_DOC',
  DEAL_INVESTMENT_NOTE: 'DEAL_INVESTMENT_NOTE',
};

const VC_ADMIN_DEAL_STAGE = {
  LEAD: 'LEAD',
  INTERESTED: 'INTERESTED',
  SEEK_INFO: 'SEEK_INFO',
  MEETING_SCHEDULED: 'MEETING_SCHEDULED',
  DUE_DILIGENCE: 'DUE_DILIGENCE',
  MOVE_TO_IC: 'MOVE_TO_IC',
};

const VC_ADMIN_DEAL_SOURCE = {
  IMPORT: 'IMPORT',
  MANUAL: 'MANUAL',
};

const NOTIFY_USER = {
  initEmailSubject: `Explore [#] New Startups on 8vdx: YC W24 Batch Now Live!`,
  initEmail: `<p>Great news! We've added [#-Hyperlink] new startups from the YC W24 cohort. You're invited to be among the first to explore these innovative startups</p>
  <p>Start Discovering: <strong>[Link to platform -Hyperlink]</strong></p>
  </br>
  <p>Warm regards,</p>
  <p>8vdx Team</p>`,
};

export {
  regex,
  urlConstants,
  fontFamily,
  requiredConstant,
  emailValidConstant,
  dateValidConstant,
  decimalInvalidConstant,
  amountValidConstant,
  passwordValidConstant,
  futureDateConstant,
  positiveConstant,
  pastDateOnly,
  urlRegex,
  urlValidConstant,
  phoneValidConstant,
  rateValidConstant,
  phoneRegex,
  PhoneNumberRegex,
  ROLES,
  LOAN_STATUS,
  FUND_TYPE,
  TransactionTypes,
  STATIC_WEBSITE,
  ROLES_OPTION,
  DEFAULT_PASSWORD,
  FUNDING_ENTITY_TYPE,
  DEAL_TYPE,
  DEAL_TYPE_MAPPER,
  KPI_FUNDRAISE_TYPE_MAPPER,
  PERCENTILE_GROWTH,
  YARDSTICK_KPI_IN_NUMBER,
  BENCHMARK_CSV_SAMPLE_URL,
  BENCHMARK_STARTUP_CSV_SAMPLE_URL,
  REFERRAL_STATUS_TYPE,
  ADMIN_VIEW,
  FCRM_SAMPLE_CSV_FILE,
  STARTUP_DEAL_DOCUMENT_NAME,
  FCRM_TAG,
  VC_DEAL_STATUS_TYPE,
  VC_PROSPECTIVE_DEAL_SAMPLE_URL,
  VC_ADMIN_DOCUMENT_NAME,
  VC_ADMIN_DOCUMENT_RELATIVE_NAME,
  VC_ADMIN_DEAL_STAGE,
  VC_ADMIN_DEAL_SOURCE,
  NOTIFY_USER,
};
