import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
  dashContainer: {
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    background: '#F6FAFA',
  },
  dashleft: {
    width: 310,
    flex: '0 auto',
    '@media (max-width: 1120px)': {
      width: 140,
      padding: 30,
    },
  },
  sidebarFixed: {
    padding: 40,
    height: '75%',
    width: '100%',
  },
  dashLogo: {
    display: 'flex',
    width: '100%',
    marginBottom: 70,
  },
  logoImg: {
    width: 100,
    height: 'auto',
  },
  dashRight: {
    width: 'calc(100% - 310px)',
    flexGrow: 1,
    padding: '55px 170px 35px',
    background: theme.palette.common.white,
    position: 'relative',
    '@media (max-width: 1120px)': {
      width: 'calc(100% - 200px)',
      padding: '40px 0px 30px 40px',
    },
  },
  dashHeader: {
    paddingRight: 80,
  },
  closeIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: 15,
    right: 15,
    '&:hover': {
      background: 'transparent',
    },
  },
  skeletonWrapper: {
    width: '80%',
    margin: 'auto',
    marginRight: '40px',
    marginTop: '100px',
  },
}));
