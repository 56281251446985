import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import Clients from './Clients';

export const ClientsAPIRoute: IRoute = {
  path: '/clients',
  component: Clients,
  layout: DashboardLayout,
  isAdmin: false,
  exact: true,
};
