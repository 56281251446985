import React, { useEffect, useState } from 'react';
import { IconButton, Grid, InputBase, Typography } from '@material-ui/core';
import { Skeleton, Alert } from '@material-ui/lab';
import { get, isEmpty } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import Assessment from '@material-ui/icons/Assessment';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  updateFcrm,
  getAllFcrms,
  getBasicFundRaisingCRM,
  getUploadFcrmSignedUrl,
  uploadFcrmFile,
  getAdminStartupFcrms,
  getAdminFcrmRound,
} from 'services';
import { Box, Tabs, Button, Loader, CSVUploadFcrm } from 'components';
import {
  FCRM_ROUND_MAPPER,
  FCRM_SAMPLE_CSV_FILE,
} from 'common/utils/constants';
import AddFCRMRoundDialog from 'views/FundRaising/AddFCRMRoundDialog';
import { RootState } from 'redux-modules/store/rootState';
import { investorTypes } from 'common/utils/optionList';

import styles from './styles';
import FcrmSidePane from './FcrmSidePane';
import EditFcrmRoundTooltip from './FCRMTableComponents/EditFcrmRoundTooltip';
import FCRMChart from './FCRMTableComponents/FCRMChart';
import { FCRMAdditionDetailDialog } from './FCRMDialogs';
import FCRMTable from './FCRMTableComponents/FCRMTable';
import FCRMEmailSidePane from './FCRMEmailSidePane';

const fcrmColumns = [
  'EMPTY',
  'Stage',
  'Status',
  'Invested Amount',
  'Remarks',
  'Asked for Intros',
  'Source',
  'Invested Amount',
  'Remarks',
  'Asked for Intros',
  'Next Followup',
  'Intro Sent At',
  'Primary Contact Name',
  'Name',
  'Email',
  'Fund',
  'Title',
  'Type',
  'YC Alum',
  'Investor DB link',
  'Investor DB user link',
  'LinkedIn Profile',
  'Investor Website',
  'Investor Grades',
  'Has led a Series A round?',
  'Invests in international cos? (self-reported)',
  'Historical # of YC investments in recent batches',
  'Historical # of YC investments linked to Demo Day',
  'Historical conversion % of Demo Day like to investment',
  'Historical average check size',
  'Self-reported expected # of investments in 6 months',
  'Self-reported average seed check',
  'Total # of investing intros requested',
  'User bio',
  'Investor Description',
  'Investor Interests',
];

const FundRaisingCRM = ({ match }: Record<string, string>) => {
  const classes = styles();

  const startupId = get(match, 'params.startupId');

  const [editFcrmContent, setEditFcrmContent] = useState<any>({});

  const [showStages, setShowStages] = useState<Record<string, boolean>>({
    LEAD: true,
    CONTACTED: true,
    MEETING: true,
    DUE_DILIGENCE: true,
    TERM_SHEET: true,
    CLOSED: true,
    VC_REQUEST: true,
  });
  const [openSidePane, setOpenSidePane] = useState<boolean>(false);
  const [openEmailSidePane, setOpenEmailSidePane] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [fieldsStatus, setFieldStatus] = useState<Record<string, boolean>>({});
  const [fieldHaveError, setFieldHaveError] = useState<boolean>(false);
  const [loadingFcrms, setLoadingFcrms] = useState<boolean>(false);
  const [nextPrevList, setNexPrevList] = useState<string[]>([]);
  const [editItemIdMap, setEditItemIdMap] = useState<string>('');
  const [searchStr, setSearchStr] = useState<string>('');
  const [fetchingCrmRound, setFetchingCrmRound] = useState<boolean>(false);
  const [fcrmRounds, setFcrmRounds] = useState<Array<Record<string, any>>>([]);
  const [allFcrms, setAllFcrms] = useState<Array<Record<string, any>>>([]);
  const [selectFieldChangeLoader, setFieldChangeLoader] = useState<string>('');
  const [filteredFcrms, setFilteredFcrms] = useState<
    Array<Record<string, any>>
  >([]);

  const [openRoundDialog, setOpenRoundDialog] = useState<boolean>(false);
  const [editContactId, setEditContactId] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<string>('DESC');
  const [emailReader, setEmailReader] = useState<string>('');
  const [chartOpen, setChartOpen] = useState<boolean>(true);
  const [showedAdditionalDialog, setAdditionalDialogShowed] = useState<boolean>(
    false,
  );
  const [reloadOnSidePaneCLose, setReloadOnSidePaneCLose] = useState<boolean>(
    false,
  );
  const [groupedFcrms, setGroupedFcrms] = useState<Record<string, any>>({});
  const [currency, setCurrency] = useState<string>('USD');
  const [stageTotal, setStageTotal] = useState<Record<string, any>>({});
  const [fromEmailCrms, setFromEmailCrms] = useState<
    Array<Record<string, any>>
  >([]);
  const [emailSendFcrm, setemailSendFcrm] = useState<Record<string, any>>([]);

  const [openCsvUpload, setOpenCsvUpload] = useState<boolean>(false);
  const [linkFieldFocus, setLinkFieldFocus] = useState<boolean>(false);
  const [fcrmInvestorTypes, setFcrmInvestorTypes] = useState<Array<string>>([]);
  const [dilutionPercentage, setDilutionPercentage] = useState<any>(0);
  // eslint-disable-next-line no-unused-vars
  const [recordHasError, setRecordHasError] = useState<boolean>(false);

  const { startupInfo } = useSelector(({ Enquiries }: RootState) => Enquiries);

  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  const isRoleAdmin = role === 'ADMIN';

  const getTabs = () => {
    const tabList = (fcrmRounds || []).map((item) => {
      return {
        name: (
          <Grid container spacing={4} className={classes.tabItem}>
            <Assessment className={classes.tabIcon} />
            {item.roundName === 'EMPTY'
              ? '-'
              : FCRM_ROUND_MAPPER[item.roundName] || item.roundName}
            <EditFcrmRoundTooltip
              onEdit={() => setOpenRoundDialog(true)}
              fcrmRound={get(fcrmRounds, '[0]')}
              currency={currency}
              isRoleAdmin={isRoleAdmin}
            />
            {!chartOpen && (
              <KeyboardArrowDown
                className={classes.tabIcon}
                onClick={() => setChartOpen(true)}
              />
            )}
            {chartOpen && (
              <KeyboardArrowUp
                className={classes.tabIcon}
                onClick={() => setChartOpen(false)}
              />
            )}
          </Grid>
        ),
        key: item.roundName,
      };
    });

    return tabList;
  };

  const fetchFcrmRound = () => {
    setFetchingCrmRound(true);
    const promise = isRoleAdmin
      ? getAdminFcrmRound({ startupId })
      : getBasicFundRaisingCRM();
    promise
      .then((data: any) => {
        const res = get(data, 'data');
        setFcrmRounds(res);
        setCurrency(get(data, 'data[0].currency'));
        setEmailReader(get(data, 'emailReader'));
      })
      .finally(() => setFetchingCrmRound(false));
  };
  useEffect(() => {
    fetchFcrmRound();
  }, []);

  useEffect(() => {
    if (!isEmpty(groupedFcrms)) {
      const result = Object.keys(groupedFcrms).reduce((acc: any, cur: any) => {
        if (!acc[cur]) {
          acc[cur] = {
            totalInvestor: groupedFcrms[cur] && groupedFcrms[cur].length,
            totalExpectedAmount: get(groupedFcrms, `${cur}`).reduce(
              (n: any, { expectedAmount }: any) =>
                n + parseInt((isNaN(expectedAmount) ? 0 : expectedAmount) || 0),
              0,
            ),
            totalInvestedAmount: get(groupedFcrms, `${cur}`).reduce(
              (n: any, { amountInvested, status, stage }: any) => {
                if (status === 'WON' && stage === 'CLOSED') {
                  n =
                    n +
                    parseInt((isNaN(amountInvested) ? 0 : amountInvested) || 0);
                }
                return n;
              },
              0,
            ),
            totalTargetAmount: get(fcrmRounds, '[0].targetAmount'),
            totalShowAmount: get(groupedFcrms, `${cur}`).reduce(
              (n: any, { amountInvested, expectedAmount }: any) =>
                n +
                parseInt(
                  (isNaN(amountInvested) ? 0 : amountInvested) ||
                    (isNaN(expectedAmount) ? 0 : expectedAmount || 0),
                ),
              0,
            ),
          };
        }
        return acc;
      }, {});
      const closed = get(groupedFcrms, 'CLOSED') || [];
      if (!isEmpty(closed)) {
        const wonOrPassCount = get(groupedFcrms, `CLOSED`).reduce(
          (acc: any, cur: any) => {
            if (cur.status === 'WON') {
              acc.hasWon = true;
              acc.won += parseInt(
                cur.amountInvested || cur.expectedAmount || 0,
              );
            }
            if (cur.status === 'PASS') {
              acc.hasPass = true;
              acc.pass += parseInt(
                cur.amountInvested || cur.expectedAmount || 0,
              );
            }
            return acc;
          },
          { won: 0, pass: 0, hasWon: false, hasPass: false },
        );

        if (get(wonOrPassCount, 'hasWon')) {
          result.CLOSED_WON = {
            totalShowAmount: wonOrPassCount.won,
          };
        }
        if (get(wonOrPassCount, 'hasPass')) {
          result.CLOSED_PASS = {
            totalShowAmount: wonOrPassCount.pass,
          };
        }
        const err = closed.some((item: any) => {
          if (
            !item.investmentCrm &&
            item.status === 'WON' &&
            (!item.amountInvested || !item.closingDate)
          ) {
            return true;
          }
          if (
            !item.investmentCrm &&
            item.status === 'WON' &&
            item.valuationChoice !== 'UNCAPPED_MFN' &&
            !item.valuationCap
          ) {
            return true;
          }
          return false;
        });
        setRecordHasError(err);
      }
      setStageTotal({ ...result });
    } else {
      setStageTotal({
        totalInvestor: 0,
        totalExpectedAmount: 0,
        totalInvestedAmount: 0,
        totalTargetAmount: 0,
        totalShowAmount: 0,
      });
    }
  }, [groupedFcrms]);

  useEffect(() => {
    if (!isEmpty(filteredFcrms)) {
      const grouped = filteredFcrms.reduce(
        (acc: any, cur: Record<string, any>) => {
          if (acc[cur.stage]) {
            acc[cur.stage].push(cur);
          } else {
            acc[cur.stage] = [cur];
          }
          return acc;
        },
        {},
      );

      const order = [
        'LEAD',
        'CONTACTED',
        'MEETING',
        'DUE_DILIGENCE',
        'TERM_SHEET',
        'CLOSED',
        'VC_REQUEST',
      ];

      const orderItems = order.reduce((acc: any, cur: any) => {
        if (!acc[cur] && grouped[cur]) {
          acc[cur] = grouped[cur];
        }

        return acc;
      }, {});

      // orderItems.LEAD = orderItems.LEAD.sort(
      //   (a: any, b: any) =>
      //     parseInt(b.expectedAmount) - parseInt(a.expectedAmount),
      // );
      setGroupedFcrms({ ...orderItems });
    } else {
      setGroupedFcrms({});
    }
  }, [filteredFcrms]);

  const appendDilutionRecord = (allcrm: any, rounds: any) => {
    const round = get(rounds, '[0]');

    if (
      round &&
      ![null, undefined].includes(round.ycInvestment) &&
      get(startupInfo, 'accelerator') === 'YC'
    ) {
      const crm = (round.ycInvestment === 'YES'
        ? [125000, 375000]
        : [125000]
      ).map((val) => {
        return {
          investorName: `YC - $${val === 125000 ? '125k' : '375k'}`,
          id: Math.random() * 10,
          expectedAmount: val,
          amountInvested: val,
          stage: 'CLOSED',
          status: 'WON',
          contacts: [],
          investorType: 'YC',
          investmentCrm: true,
        };
      });
      allcrm = [...crm, ...allcrm];
    }
    return allcrm;
  };
  const fetchAllFcrm = () => {
    const promise = !isRoleAdmin
      ? getAllFcrms()
      : getAdminStartupFcrms({
          startupId,
        });
    setLoadingFcrms(true);
    promise
      .then((res: any) => {
        const fcrmInvestorTypes = (res || []).reduce(
          (acc: Array<string>, cur: any) => {
            if (
              get(cur, 'investorType') &&
              !acc.includes(get(cur, 'investorType')) &&
              !investorTypes.find(
                (item: any) => item.value === get(cur, 'investorType'),
              )
            ) {
              acc.push(get(cur, 'investorType'));
            }
            return acc;
          },
          [],
        );
        setFcrmInvestorTypes(fcrmInvestorTypes);
        setFromEmailCrms(
          res.filter(
            (item: any) =>
              get(item, 'fromEmail') && !get(item, 'viewedEmailCrm'),
          ),
        );
        let newAllCrm = (res || []).map((item: Record<string, any>) => {
          const primaryContact = (get(item, 'contacts') || []).find(
            (c: any) => c.primary,
          );
          item.cname = get(primaryContact, 'name');
          return item;
        });
        newAllCrm = appendDilutionRecord(newAllCrm, fcrmRounds);
        getDilutionPercentage(
          (newAllCrm || []).filter(
            (item: any) =>
              item.stage === 'CLOSED' &&
              item.status === 'WON' &&
              item.closingDate &&
              !item.investmentCrm,
          ),
        );
        setFilteredFcrms(
          sortFcrmItems(sortDirection, newAllCrm, 'expectedAmount'),
        );
        setAllFcrms(newAllCrm);
      })
      .finally(() => setLoadingFcrms(false));
  };
  useEffect(() => {
    if (!isEmpty(fcrmRounds)) {
      fetchAllFcrm();
    }
  }, [fcrmRounds]);

  useEffect(() => {
    const itemList: any = [];
    Object.keys(groupedFcrms).map((grp: any) => {
      groupedFcrms[grp].map((item: any, index: number) => {
        itemList.push(`${grp}|${index}`);
      });
    });
    setNexPrevList(itemList);
  }, [groupedFcrms]);

  useEffect(() => {
    if (!openSidePane) setEditItemIdMap('');
  }, [openSidePane]);

  useEffect(() => {
    if (editItemIdMap) {
      const indexes: any = editItemIdMap.split('|');
      setEditFcrmContent(groupedFcrms[indexes[0]][indexes[1]]);
    }
  }, [editItemIdMap]);

  const onBlurField = (fieldRef: string) => {
    setFieldStatus({
      ...fieldsStatus,
      ...{ [fieldRef]: true },
    });
  };
  const onClickTableCell = (fieldRef: string) => {
    if (fieldHaveError) return;
    setFieldStatus({
      ...fieldsStatus,
      ...{ [fieldRef]: false },
    });
  };

  const onSuccessInlineUpdate = (data: any) => {
    const objectKey = Object.keys(data)[0];
    const id = get(objectKey.split('#'), [0]);
    const key = get(objectKey.split('#'), [1]);
    const index = allFcrms.findIndex((item) => item.id === id);
    const indexInFiltered = filteredFcrms.findIndex((item) => item.id === id);
    if (index !== -1) {
      allFcrms[index][key] = data[objectKey];
      filteredFcrms[indexInFiltered][key] = data[objectKey];
      setAllFcrms([...allFcrms]);
      setFilteredFcrms([...filteredFcrms]);
      getDilutionPercentage(
        (allFcrms || []).filter(
          (item: any) =>
            item.stage === 'CLOSED' &&
            item.status === 'WON' &&
            item.closingDate &&
            !item.investmentCrm,
        ),
      );
    }
  };

  const addAdditionalDetails = () => {
    setAdditionalDialogShowed(true);
    setEditFcrmContent(get(fromEmailCrms, '[0]'));
    setOpenSidePane(true);
  };

  const onCloseSidePan = (reload = false) => {
    setOpenSidePane(false);
    setEditMode(false);
    setEditFcrmContent({});
    setEditContactId('');
    setSearchStr('');
    if (reloadOnSidePaneCLose || reload) {
      fetchAllFcrm();
      setReloadOnSidePaneCLose(false);
    }
  };

  const onSelectFieldChange = (e: any, key: string) => {
    const value = e.target.value;
    onSuccessInlineUpdate({
      [key]: value,
    });

    setFieldChangeLoader(key);
    updateFcrm({
      [key]: value,
    })
      .then(() => {
        onSuccessInlineUpdate({
          [key]: value,
        });
      })
      .finally(() => {
        setFieldChangeLoader('');
      });
  };

  const editEmailCrmNextAction = () => {
    if (get(fromEmailCrms, '[0]')) {
      fromEmailCrms.shift();
      setFromEmailCrms(fromEmailCrms);
      setEditFcrmContent(get(fromEmailCrms, '[0]') || {});
      if (isEmpty(fromEmailCrms)) {
        onCloseSidePan();
      }
    } else {
      onCloseSidePan();
    }
  };

  const onNextOrPreviousEdit = (action: string) => {
    const indx = nextPrevList.indexOf(editItemIdMap);
    if (action === 'NEXT') {
      if (indx < nextPrevList.length - 1)
        setEditItemIdMap(nextPrevList[indx + 1]);
    }
    {
      if (indx !== 0) setEditItemIdMap(nextPrevList[indx - 1]);
    }
  };

  const resetSearchFilter = () => {
    setSearchStr('');
    setFilteredFcrms(allFcrms);
  };

  const onSearch = (str: string) => {
    setSearchStr(str);

    if (!str) {
      setFilteredFcrms(allFcrms);
    } else {
      const filteredData = (allFcrms || []).filter((value: any) => {
        let investorType = value.investorType?.toString().toLowerCase();

        if (investorType && investorType.includes('_')) {
          investorType = investorType.replace('_', ' ');
        }

        if (
          value.investorName
            ?.toString()
            .toLowerCase()
            .includes(str?.toString().toLowerCase()) ||
          value.status
            ?.toString()
            .toLowerCase()
            .includes(str?.toString().toLowerCase()) ||
          value.cname
            ?.toString()
            .toLowerCase()
            .includes(str?.toString().toLowerCase()) ||
          investorType?.includes(str?.toString().toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      setFilteredFcrms([...filteredData]);

      // setFilteredSearchData(filteredData);
    }
  };

  const sortFcrmItems = (order = 'ASC', fcrms: any, field: string) => {
    const items = (fcrms || filteredFcrms).sort((a: any, b: any) => {
      if (a.investmentCrm || b.investmentCrm) return 1;
      if ([null, undefined].includes(get(a, field))) {
        return 1;
      }
      if ([null, undefined].includes(get(b, field))) {
        return -1;
      }
      if (order === 'DESC') {
        return get(b, field) - get(a, field);
      } else {
        return get(a, field) - get(b, field);
      }
    });
    if (fcrms) return items;
    setFilteredFcrms([...items]);
  };

  const getSortedCrms = (closedCrms: any, closingDate: string) => {
    const greaterThanClosingDate = closedCrms.filter((item: any) => {
      return moment(item.closingDate).isAfter(moment(closingDate));
    });
    return greaterThanClosingDate.sort((a: any, b: any) => {
      if (!a.valuationCap) {
        return 1;
      }
      if (!b.valuationCap) {
        return -1;
      }
      return parseInt(a.valuationCap) < parseInt(b.valuationCap) ? -1 : 1;
    });
  };
  const getDilutionPercentage = (closedCrms: any) => {
    const nonYcStartup = get(startupInfo, 'accelerator') !== 'YC';
    const takenInvestment = get(fcrmRounds, '[0].ycInvestment') === 'YES';
    const investmentAmount = takenInvestment ? 375000 : 125000;
    const tentativeValuation = parseInt(
      get(fcrmRounds, '[0].tentativeValuation'),
    );
    let initialValuation = 1785714;
    let valuation = 1785714;
    if (takenInvestment) {
      initialValuation = tentativeValuation;
    }
    if (isEmpty(closedCrms) && takenInvestment) {
      valuation = tentativeValuation;
    } else if (takenInvestment && !isEmpty(closedCrms)) {
      // const crms = JSON.parse(JSON.stringify(closedCrms));
      // const sortedCrms = getSortedCrms(crms);
      // console.log(sortedCrms, 'SortedCrm') //eslint-disable-line
      // valuation = get(sortedCrms, '[0].valuationCap') || tentativeValuation;
      // initialValuation = valuation;
    }
    let inititalPercentage = 0;
    if (!takenInvestment) {
      inititalPercentage = nonYcStartup
        ? 0
        : (investmentAmount / initialValuation) * 100;
    } else {
      const mfnClosingDate = get(fcrmRounds, '[0].mfnStartingDate');
      let sortedCrms = [];
      if (mfnClosingDate) {
        const crms = JSON.parse(JSON.stringify(closedCrms));
        sortedCrms = getSortedCrms(crms, mfnClosingDate);
      }

      const val = get(sortedCrms, '[0].valuationCap') || initialValuation;

      inititalPercentage = 7 + (investmentAmount / val) * 100;
    }

      console.log(inititalPercentage, 'inititalPercentage') //eslint-disable-line
    let closedCrmPercentage = 0;
    if (!isEmpty(closedCrms)) {
      closedCrmPercentage = closedCrms.reduce((acc: any, cur: any) => {
        const mfn = get(cur, 'valuationChoice') === 'MFN';
        const uncappedMfn = get(cur, 'valuationChoice') === 'UNCAPPED_MFN';
        const selfValuation = get(cur, 'valuationCap');
        const selfAmountInvested = get(cur, 'amountInvested');

        const crms = JSON.parse(JSON.stringify(closedCrms));
        const sortedValuations = getSortedCrms(crms, cur.closingDate);
        valuation =
          get(sortedValuations, '[0].valuationCap') || tentativeValuation;
        let percentage = 0;
        if (nonYcStartup) {
          // const crms = JSON.parse(JSON.stringify(closedCrms));
          // const val = getSortedCrms(crms);
          percentage =
            (selfAmountInvested /
              (mfn || uncappedMfn
                ? parseInt(get(sortedValuations, '[0].valuationCap') || 0)
                : selfValuation)) *
            100;
        } else {
          percentage = takenInvestment
            ? (selfAmountInvested /
                (mfn || uncappedMfn ? valuation : selfValuation)) *
              100
            : (selfAmountInvested / get(cur, 'valuationCap')) * 100;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        percentage = percentage && parseFloat(percentage.toFixed(2));

        // console.log( //eslint-disable-line
        //   `takenInvestment: ${takenInvestment}`,
        //   `closingDate: ${cur.closingDate}`,
        //   `Percenage: ${percentage.toFixed(2)}`,
        //   `selfAmountInvested: ${selfAmountInvested}`,
        //   `investorName: ${cur.investorName}`,
        //   `mfn: ${mfn}`,
        //   `uncappedMfn: ${uncappedMfn}`,
        //   `valuation: ${mfn || uncappedMfn ? valuation : selfValuation}`,
        //   `sortedValuations:`,
        // );

        console.log(uncappedMfn || mfn ? sortedValuations : []) //eslint-disable-line
        // const percentage = (get(cur, 'amountInvested') / valuation) * 100;

        acc = acc + percentage;
        return acc;
      }, 0);
    }
    const closedPerc =
      closedCrmPercentage && parseFloat(closedCrmPercentage.toFixed(2));
    const initPer =
      inititalPercentage && parseFloat(inititalPercentage.toFixed(2));
    const totalPercentage = initPer + closedPerc;
    setDilutionPercentage(totalPercentage && totalPercentage.toFixed(2));
  };

  const setScheduleMeeting = (val: any) => {
    if (get(fcrmRounds, '[0].meetingLink')) {
      setemailSendFcrm({ data: val, type: 'MEET' });
      setOpenEmailSidePane(true);
    } else {
      setLinkFieldFocus(true);
      setOpenRoundDialog(true);
    }
  };
  return (
    <>
      <Box className={classes.pageHeader}>
        <Box className={classes.titleHeader}>
          <Typography variant="h6" className={classes.headText}>
            Fundraising CRM
            <span className={classes.headTextNote}>
              The data provided by you is secure and confidential.
            </span>
          </Typography>
          {recordHasError && (
            <Alert className={classes.errorAlert} severity="error">
              Please enter Valuation Cap and Closing Date for highlighted
              investor to get correct dilution number.
            </Alert>
          )}
        </Box>
        {!fetchingCrmRound && (
          <Box>
            <Grid container xs={12}>
              <Grid item xs={12} sm={4} md={4}>
                <Box className={classes.actionContentText}>
                  <Tabs
                    tabs={getTabs()}
                    minWidth={160}
                    // startIcon={<AddIcon />}
                    handleChange={undefined}
                    className={classes.enquiryTabs}
                    value={0}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                className={classes.buttonsGrid + ' ' + classes.vButtonGridNew}
              >
                {!isRoleAdmin && (
                  <Button
                    className={classes.addNewInvestor}
                    name="Add new lead"
                    onClick={() => {
                      setEditMode(false);
                      setOpenSidePane(true);
                    }}
                  />
                )}
                {!isRoleAdmin && (
                  <Button
                    className={classes.addNewInvestor}
                    name="Upload New Leads"
                    onClick={() => {
                      setOpenCsvUpload(true);
                    }}
                  />
                )}
                <Box
                  className={classes.searchBox + ' ' + classes.vSearchBoxNew}
                >
                  <InputBase
                    className={classes.inputSear}
                    placeholder="Search"
                    value={searchStr}
                    inputProps={{ 'aria-label': 'Close' }}
                    onChange={(e: any) => onSearch(e.target.value)}
                  />
                  {!searchStr && (
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  )}
                  {searchStr && (
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search"
                    >
                      <Close onClick={resetSearchFilter} />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      {fetchingCrmRound && <Loader />}
      {!fetchingCrmRound && (
        <Box className={classes.fundRaisingContainer}>
          <Box className={classes.fundRaisingContent}>
            <Box
              className={
                classes.fundRaisingTableTabContainer +
                ' ' +
                classes.vFundRaisingTableTabContainerNew
              }
            >
              {loadingFcrms && (
                <Box className={classes.listingBox}>
                  <Skeleton variant="text" height={50} animation="pulse" />
                  <Skeleton variant="text" height={50} animation="pulse" />
                  <Skeleton variant="text" height={50} animation="pulse" />
                </Box>
              )}
              {!loadingFcrms && chartOpen && (
                <FCRMChart
                  stageTotal={stageTotal}
                  isEmptyData={isEmpty(groupedFcrms)}
                  fcrmRound={get(fcrmRounds, '[0]')}
                  startupInfo={startupInfo}
                  dilutionPercentage={
                    isFinite(dilutionPercentage) ? dilutionPercentage : 0
                  }
                  currency={currency}
                  targetAmount={parseInt(
                    get(fcrmRounds, '[0].targetAmount') || 0,
                  )}
                />
              )}
              {!loadingFcrms && (
                <FCRMTable
                  groupedFcrms={groupedFcrms}
                  setShowStages={setShowStages}
                  currency={currency}
                  stageTotal={stageTotal}
                  showStages={showStages}
                  setEditItemIdMap={setEditItemIdMap}
                  setEditMode={setEditMode}
                  setOpenSidePane={setOpenSidePane}
                  setOpenEmailSidePane={setOpenEmailSidePane}
                  onSuccessInlineUpdate={onSuccessInlineUpdate}
                  onClickTableCell={onClickTableCell}
                  onBlurField={onBlurField}
                  setFieldHaveError={setFieldHaveError}
                  onSelectFieldChange={onSelectFieldChange}
                  filteredFcrms={filteredFcrms}
                  setEditContactId={setEditContactId}
                  selectFieldChangeLoader={selectFieldChangeLoader}
                  setemailSendFcrm={setemailSendFcrm}
                  setScheduleMeeting={setScheduleMeeting}
                  fcrmInvestorTypes={fcrmInvestorTypes}
                  sortFcrmItems={sortFcrmItems}
                  setSortDirection={setSortDirection}
                  sortDirection={sortDirection}
                  isRoleAdmin={isRoleAdmin}
                />
              )}
            </Box>
          </Box>

          <FcrmSidePane
            editFcrmContent={editFcrmContent}
            openSidePane={openSidePane}
            editMode={editMode}
            setOpenSidePane={setOpenSidePane}
            fetchAllFcrm={fetchAllFcrm}
            isEmailEdit={!isEmpty(fromEmailCrms)}
            setReloadOnSidePaneCLose={setReloadOnSidePaneCLose}
            emailCrmNextAction={editEmailCrmNextAction}
            onNextOrPreviousEdit={onNextOrPreviousEdit}
            onClose={onCloseSidePan}
            currency={currency}
            editContactId={editContactId}
            setEditContactId={setEditContactId}
            editItemIdMap={editItemIdMap}
            nextPrevList={nextPrevList}
            fcrmInvestorTypes={fcrmInvestorTypes}
            isRoleAdmin={isRoleAdmin}
          />

          <FCRMEmailSidePane
            openEmailSidePane={openEmailSidePane}
            setOpenEmailSidePane={setOpenEmailSidePane}
            emailSendFcrm={emailSendFcrm}
            setemailSendFcrm={setemailSendFcrm}
            meetingLink={get(fcrmRounds, '[0].meetingLink')}
            startupInfo={startupInfo}
          />
        </Box>
      )}
      <FCRMAdditionDetailDialog
        open={
          !isEmpty(fromEmailCrms) && !openSidePane && !showedAdditionalDialog
        }
        onClose={() => setFromEmailCrms([])}
        onOk={addAdditionalDetails}
        onCancel={() => {
          setFromEmailCrms([]);
          setAdditionalDialogShowed(true);
        }}
        fromEmailCrms={fromEmailCrms}
      />
      {openRoundDialog && (
        <AddFCRMRoundDialog
          open={openRoundDialog}
          handleCloseFcrmRound={() => {
            fetchFcrmRound();
            setOpenRoundDialog(false);
          }}
          edit={true}
          editRound={get(fcrmRounds, '[0]')}
          emailReader={emailReader}
          linkFieldFocus={linkFieldFocus}
        />
      )}

      <CSVUploadFcrm
        openCsvUpload={openCsvUpload}
        setOpenCsvUpload={setOpenCsvUpload}
        startupId={''}
        title="Upload Investors"
        showGroupHeader={false}
        getSignedUrl={getUploadFcrmSignedUrl}
        uploadFileUrl={uploadFcrmFile}
        onSuccessProcess={fetchAllFcrm}
        sampleFile={FCRM_SAMPLE_CSV_FILE}
        columnMapping={fcrmColumns}
      />
    </>
  );
};

export default FundRaisingCRM;
