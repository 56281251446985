import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isEmpty, get, filter } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import { FN } from 'common/types';
import { RootState } from 'redux-modules/store/rootState';
import {
  getYcDocsByStartupId,
  getLoansDetailsByStartupId,
  getLoanTermsByStartupId,
} from 'redux-modules/enquiries/Actions';
import { YcDocumentsSchema } from 'common/utils/validations';
import {
  handleYCVerification,
  updateStartUpLoanTerms,
  validateYcStatus,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { Accordion, Box, Button, ConfirmDialog } from 'components';

import ReviewDocuments from '../../../ReviewDocumentsList';
import YCStatus from '../YCStatus';
import styles from '../../styles';
import { AdminLoanTermsUpdateSchema } from '../../validations';
import ReviewLoanForm from './ReviewLoanForm';

type BridgeLoanProps = {
  fundType: string | undefined;
  startupId: string | undefined;
  isDispatchReq: boolean;
  expanded: string | boolean;
  input: boolean;
  documentName: string;
  documentsList: any;
  isDisabled: boolean;
  nonDocumentsList: any;
  isDocuSignSendDisabled: boolean;
  isRoleAdmin: boolean;
  setInput: FN;
  setDisable: FN;
  handleChange: FN;
  handleAddDocuments: FN;
  handleSubmitDocuments: FN;
  handleSetDocumentName: FN;
  handleUpdateRequestedDoc: FN;
  handleSendDocuSignDocs: FN;
  validateYcDocumentsForVD: FN;
  validateDocuments: FN;
  handleDeleteDocument: FN;
  docuSignDocumentsList: any;
};

const VDLoan = ({
  fundType,
  startupId,
  isRoleAdmin,
  expanded,
  handleChange,
  validateDocuments,
  validateYcDocumentsForVD,
}: BridgeLoanProps) => {
  const {
    handleSubmit: ycFormHandleSubmit,
    getValues: ycGetValues,
    control: ycControl,
    setValue: ycSetvalue,
  } = useForm({
    mode: 'onChange',

    resolver: yupResolver(YcDocumentsSchema),
  });

  const classes = styles();
  const dispatch = useDispatch();

  const { handleSubmit, control, getValues, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AdminLoanTermsUpdateSchema),
  });

  const [expandYcStep, setExpandYcStep] = useState<boolean>(false);
  const [isEditYc, setEditYc] = useState<boolean>(false);

  const [updatingLoanTerms, setUpdatingLoanTerms] = useState<boolean>(false);
  const [documentObject, setDocumentObject] = React.useState<any>([]);

  const { Documents, isLoading } = useSelector(
    ({ Process }: RootState) => Process,
  );
  const { documents } = Documents;

  const [loanTermErrorMessage, setLoanTermErrorMessage] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [approvedLoanTerm, setApprovedLoanTerm] = useState<boolean>(false);
  const [ycVerfLoading, setYcVerfLoading] = useState<boolean>(false);
  const [count, setCount] = useState({});
  const [updatingYcStatus, setUpdatingYcStatus] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { id: enquiryId } = useParams();
  const { bridgeLoanProcess, enquiryView } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  console.log(bridgeLoanProcess, "AKLS:DALSDKJLASD-vd") //eslint-disable-line

  const isBridgeLoanLoading: any = useSelector(
    ({ Enquiries }: RootState) => Enquiries.isLoading,
  );
  const {
    loanTerms,
    isLoadingLoanTerms,
    ycDocuments,
    isLoadingYcDocs,
  } = useSelector(({ Enquiries }: RootState) => Enquiries);

  useEffect(() => {
    if (!isEmpty(documents)) {
      const temp = documents.length;
      const tempVerified = (
        documents.filter((e: any) => e.documentStatus === 'VERIFIED') || []
      ).length;
      setCount({ verified: tempVerified, total: temp });
    }
  }, [documents]);

  useEffect(() => {
    setDocumentObject(ycDocuments);
  }, [ycDocuments]);

  useEffect(() => {
    if (isRoleAdmin && startupId) {
      dispatch(getLoanTermsByStartupId(`${startupId}/VENTURE_DEBT`));
    }
  }, [startupId]);

  useEffect(() => {
    if (get(loanTerms, 'termsChangedByAdmin')) {
      setApprovedLoanTerm(true);
    }
  }, [loanTerms]);

  const onApprove = async () => {
    const data: any = { ...getValues() };
    setUpdatingLoanTerms(true);
    const payload: any = data;

    data.id = get(loanTerms, 'id');
    data.startupId = get(loanTerms, 'startupId');

    await updateStartUpLoanTerms(payload)
      .then(() =>
        dispatch(getLoanTermsByStartupId(`${startupId}/VENTURE_DEBT`)),
      )
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setLoanTermErrorMessage(message);
      });
    setUpdatingLoanTerms(false);
    setDialogOpen(false);
    setApprovedLoanTerm(true);
  };

  const allDocumentsApproved: boolean =
    filter(documents, { documentStatus: 'VERIFIED' }).length ===
    documents.length;

  const handleGetName = (value: string) => {
    switch (value) {
      case 'REQUIRED_DOCUMENTS':
        if (isRoleAdmin) {
          return get(bridgeLoanProcess, 'documentRequestRaised')
            ? 'Request raised'
            : 'Yet to raise request';
        } else {
          return get(bridgeLoanProcess, 'documentsSubmitted')
            ? 'Documents Submitted'
            : 'Yet to submit Documents';
        }
      case 'DOCU_SIGN_DOCUMENTS':
        if (isRoleAdmin) {
          return get(bridgeLoanProcess, 'docuSignRequestRaised')
            ? 'Request raised'
            : 'Yet to raise request';
        } else {
          return get(bridgeLoanProcess, 'documentsSigned')
            ? 'Documents Signed'
            : 'Yet to sign Documents';
        }
      case 'REVIEW_DOCUMENTS':
        return get(bridgeLoanProcess, 'documentsVerified') ||
          (documents || []).every(
            (doc: any) => doc.documentStatus === 'VERIFIED',
          )
          ? 'Documents verified'
          : 'Yet to verify documents';
      case 'YC_STATUS':
        return !isEditYc ? 'Edit' : 'Save';
      case 'APPROVE_LOAN':
        return get(bridgeLoanProcess, 'isLoanApproved')
          ? 'Approved'
          : 'Yet to approve loan';
      case 'PROCESS_LOAN':
        return get(bridgeLoanProcess, 'isLoanProcessed')
          ? 'Processed'
          : 'Yet to process loan';
      default:
        break;
    }
  };

  const handleValidateYcStatus = () => {
    setUpdatingYcStatus(true);
    validateYcStatus({
      startupId: get(enquiryView, 'startupDetails.startupId'),
      fundType: 'VENTURE_DEBT',
    })
      .then(() => validateYcDocumentsForVD())
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
      .finally(() => setUpdatingYcStatus(false));
  };
  useEffect(() => {
    if (
      !isEmpty(bridgeLoanProcess) &&
      (bridgeLoanProcess.isLoanApproved || bridgeLoanProcess.isLoanProcessed)
    ) {
      // if (isRoleAdmin) {
      //   dispatch(getFundType(startupId));
      // } else {
      //   dispatch(getLoansDetailsByStartupId(startupId));
      // }
      if (!isRoleAdmin) {
        dispatch(getLoansDetailsByStartupId(startupId));
      }
    }
  }, [bridgeLoanProcess]);

  const handleYcSubmit = async () => {
    setYcVerfLoading(true);

    const { assignedYcLegalPocName, assignedYcPartnerName } = ycGetValues();

    const payload = {
      documents: [...ycDocuments, ...documentObject],
      enquiryId: enquiryId,
      startupId: get(enquiryView, 'startupDetails.startupId'),
      assignedYcLegalPocName,
      assignedYcPartnerName,
      fundType: 'VENTURE_DEBT',
    };

    await handleYCVerification(payload);

    dispatch(
      getYcDocsByStartupId(
        `${get(enquiryView, 'startupDetails.startupId')}##VENTURE_DEBT`,
      ),
    );

    setEditYc(false);
    setYcVerfLoading(false);
  };

  const validateDisable = () => {
    return !(documentObject || []).every(
      (item: any) => item.documentStatus === 'VERIFIED',
    );
  };

  return (
    <>
      <Box>
        {/* <Typography
          className={classes.bridgeLoadText}
          gutterBottom
          variant="h3"
        >
          BRIDGE LOAN
        </Typography> */}

        <form data-testid="adminYcverifyForm" className={classes.formAccord}>
          <Accordion
            subheading={'Verify YC Status'}
            expanded={expandYcStep}
            // handleChange={(e: string) => handleChange(e)}
            onClickSubHeading={() => setExpandYcStep((prev) => !prev)}
            onClickIcon={() => setExpandYcStep((prev) => !prev)}
            handleChange={() => null}
            index={'YC_STATUS'}
            buttons={
              <Box className={classes.summaryAccordRight}>
                {isRoleAdmin && (
                  <Button
                    name={
                      get(bridgeLoanProcess, 'isYcStatusVerified')
                        ? 'Validated'
                        : 'Validate'
                    }
                    className={classes.accordSendBtn}
                    disabled={validateDisable()}
                    onClick={handleValidateYcStatus}
                    isLoading={isBridgeLoanLoading || updatingYcStatus}
                  />
                )}
                {/* ycFormHandleSubmit(() => handleYcSubmit()) */}
                <Button
                  name={handleGetName('YC_STATUS')}
                  isLoading={ycVerfLoading}
                  onClick={
                    isEditYc
                      ? ycFormHandleSubmit(() => handleYcSubmit())
                      : () => {
                          setEditYc(true);
                          setExpandYcStep(true);
                        }
                  }
                  className={classes.editYCBtn}
                />
                <MoreHorizIcon
                  className={classes.moreIcon}
                  onClick={() => setExpandYcStep((prev) => !prev)}
                />
              </Box>
            }
          >
            {!isLoadingYcDocs ? (
              <YCStatus
                isEditYc={isEditYc}
                isVd={true}
                documentObject={documentObject}
                setDocumentObject={setDocumentObject}
                control={ycControl}
                enquiryView={enquiryView}
                fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
                setValue={ycSetvalue}
              />
            ) : (
              <CircularProgress color="inherit" size={40} />
            )}
          </Accordion>
        </form>

        <form data-testid="adminReviewLoanTermsForm">
          <Accordion
            subheading={'Review Loan Terms'}
            expanded={expanded === 'REVIEW_LOAN_TERMS'}
            handleChange={(e: string) => handleChange(e)}
            index={'REVIEW_LOAN_TERMS'}
            buttons={
              <Box className={classes.summaryAccordRight}>
                {isRoleAdmin && (
                  <Button
                    name={approvedLoanTerm ? 'Approved' : 'Approve'}
                    onClick={handleSubmit(() => setDialogOpen(true))}
                    className={classes.accordSendBtn}
                    isLoading={isBridgeLoanLoading || updatingLoanTerms}
                    disabled={
                      isBridgeLoanLoading ||
                      updatingLoanTerms ||
                      get(bridgeLoanProcess, 'documentsSigned')
                    }
                  />
                )}
                <MoreHorizIcon className={classes.moreIcon} />
              </Box>
            }
          >
            <Box className={classes.docuComponent}>
              {!isEmpty(loanTerms) ? (
                <ReviewLoanForm
                  handleSubmit={handleSubmit}
                  control={control}
                  loanTerms={loanTerms}
                  isLoadingLoanTerms={isLoadingLoanTerms}
                  setValue={setValue}
                />
              ) : (
                <Typography
                  className={classes.bridgeLoadText}
                  gutterBottom
                  variant="h3"
                >
                  Loan terms to be discussed
                </Typography>
              )}
              <Typography
                variant="body1"
                gutterBottom
                className={classes.errorText}
              >
                {loanTermErrorMessage}
              </Typography>
            </Box>
          </Accordion>
        </form>

        <Accordion
          subheading={'Review Documents'}
          expanded={expanded === 'REVIEW_DOCUMENTS'}
          handleChange={(e: string) => handleChange(e)}
          index={'REVIEW_DOCUMENTS'}
          buttons={
            <Box className={classes.summaryAccordRight}>
              {!isEmpty(documents) && (
                <Typography className={classes.approvedDocs}>
                  {`Approved ${get(count, 'verified')} /${get(count, 'total')}`}
                </Typography>
              )}

              <Button
                name={handleGetName('REVIEW_DOCUMENTS')}
                className={classes.statusBtn}
              />
              {isRoleAdmin && (
                <Button
                  name="Validate"
                  className={classes.accordSendBtn}
                  onClick={() => validateDocuments()}
                  isLoading={isBridgeLoanLoading}
                  disabled={
                    !allDocumentsApproved ||
                    get(bridgeLoanProcess, 'documentsVerified')
                  }
                />
              )}
              <MoreHorizIcon className={classes.moreIcon} />
            </Box>
          }
        >
          <Box className={classes.docuComponent}>
            {isLoading && isEmpty(documents) ? (
              <Box className={classes.loader}>
                <CircularProgress color="inherit" size={40} />
              </Box>
            ) : !isEmpty(documents) ? (
              <ReviewDocuments
                startupId={startupId}
                fundType={fundType}
                isRoleAdmin={isRoleAdmin}
              />
            ) : (
              <Typography>No documents available for review</Typography>
            )}
          </Box>
        </Accordion>
      </Box>

      <ConfirmDialog
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
        dialogHeading="Approve Loan Terms"
        dialogContent="Are you sure you wish to approve new loan terms changes?"
        isLoading={isLoading}
        onConfirm={() => onApprove()}
        buttonText={{ yes: 'Approve', no: 'No' }}
      />
    </>
  );
};

export default VDLoan;
