import { ConvertVentureDebtType } from './Types';

export const VENTURE_DEBT_SUBMIT = 'user/VENTURE_DEBT_SUBMIT';
export const VENTURE_DEBT_SUBMIT_LOADING = 'user/VENTURE_DEBT_SUBMIT_LOADING';
export const VENTURE_DEBT_SUBMIT_SUCCESS = 'user/VENTURE_DEBT_SUBMIT_SUCCESS';
export const VENTURE_DEBT_SUBMIT_FAILURE = 'user/VENTURE_DEBT_SUBMIT_FAILURE';

export const convertVentureDebt = (payload: ConvertVentureDebtType) => {
  return {
    type: VENTURE_DEBT_SUBMIT,
    payload,
  };
};
