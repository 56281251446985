import { IRoute } from 'common/types';
import AuthLayout from 'layouts/AuthLayout';

import Invite from './Invite';

export const InviteRoute: IRoute = {
  path: '/invite/:username/:password',
  component: Invite,
  layout: AuthLayout,
  exact: true,
};
