import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Button, WrappedTextInput } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { createOrUpdateDeal } from 'services';
import { DealCreationProps } from 'common/types';

import { MarketingSchema } from '../../validations';
import styles from './styles';

const MarketingMaterial = ({
  handleNext,
  handleBack,
  activeStep,
  // dealId,
  deal,
  setDeal,
}: DealCreationProps) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  // const [loadingSignedUrl, setLoadingSignedUrl] = React.useState<boolean>(
  //   false,
  // );

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(MarketingSchema),
  });

  // const uploadOnSelect = async (fileuploadReq: any): Promise<void> => {
  //   setErrorMessage('');
  //   if (!isEmpty(fileuploadReq)) {
  //     setIsLoading(true);
  //     try {
  //       // eslint-disable-next-line no-undef
  //       await fetch(get(fileuploadReq, 'url.uploadUrl'), {
  //         method: 'PUT',
  //         body: fileuploadReq.file,
  //       });
  //       await addInvestorDocusignDocument({
  //         filename: get(fileuploadReq, 'filename'),
  //         dealId,
  //         documentUrl: get(fileuploadReq, 'url.accessUrl'),
  //       });
  //       setIsLoading(false);
  //       handleNext(activeStep + 1);
  //     } catch (err) {
  //       const message: string = errorMessageHandler(err);
  //       setErrorMessage(message);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   } else {
  //     setErrorMessage('Please upload file.');
  //   }
  // };

  const onSubmit = async (data: any): Promise<void> => {
    setErrorMessage('');
    setIsLoading(true);
    const payload = {
      ...data,
      id: get(deal, 'id'),
    };
    try {
      const res = await createOrUpdateDeal(payload);
      setDeal(res);
      handleNext(activeStep + 1);
    } catch (err) {
      const message: string = errorMessageHandler(err);
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (get(deal, 'id')) {
      setValue('investmentTeaserLink', get(deal, 'investmentTeaserLink', ''));
      setValue(
        'investmentTeaserLinkDesc',
        get(deal, 'investmentTeaserLinkDesc', ''),
      );
      setValue('informationLink', get(deal, 'informationLink', ''));
      setValue('informationLinkDesc', get(deal, 'informationLinkDesc', ''));
    }
  }, [deal]);

  return (
    <Box>
      <Typography variant="h3" className={classes.dealCreationHeader}>
        Marketing material
      </Typography>
      {errorMessage && (
        <Typography variant="body1" className={classes.errorMessage}>
          {errorMessage}
        </Typography>
      )}
      <Box>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="marketingMaterialForm"
          // className={classes.validateForm}
        >
          <Typography variant="body1" className={classes.uploadText}>
            Investment Teaser
          </Typography>
          <Box className={classes.descBox}>
            <Typography className={classes.labelText} variant="body1">
              <b>Description</b>
            </Typography>
            <Controller
              name="investmentTeaserLinkDesc"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder=""
                    className={classes.textValInputDesc}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Box>
          <Box className={classes.uploadFileOrLinkBox}>
            {/* <FileUpload
              getSignedUrl={getSignedUrlForDocusignDocument}
              uploadedFiles={[]}
              setLoadingSignedUrl={setLoadingSignedUrl}
              uploadOnSelect={uploadOnSelect}
              requestObject={{
                dealId,
              }}
              content={
                <Button
                  className={classes.uploadBtn}
                  name={'Upload File'}
                  disabled={loadingSignedUrl || isLoading}
                  isLoading={loadingSignedUrl || isLoading}
                />
              }
            /> */}
            {/* <Typography className={classes.orText}>Or</Typography> */}
            <Box className={classes.linkBox}>
              <Typography className={classes.labelText} variant="body1">
                <b>Enter Link</b>
              </Typography>
              <Controller
                name="investmentTeaserLink"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box className={classes.controllerBox}>
                    <WrappedTextInput
                      type="text"
                      placeholder="Url"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(e.target.value.trimStart())
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </Box>
                )}
              />
            </Box>
          </Box>
          <Typography variant="body1" className={classes.uploadText}>
            Information memorandum
          </Typography>
          <Box className={classes.descBox}>
            <Typography className={classes.labelText} variant="body1">
              <b>Description</b>
            </Typography>
            <Controller
              name="informationLinkDesc"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder=""
                    className={classes.textValInputDesc}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Box>
          <Box className={classes.uploadFileOrLinkBox}>
            {/* <FileUpload
              getSignedUrl={getSignedUrlForDocusignDocument}
              uploadedFiles={[]}
              setLoadingSignedUrl={setLoadingSignedUrl}
              uploadOnSelect={uploadOnSelect}
              requestObject={{
                dealId,
              }}
              content={
                <Button
                  className={classes.uploadBtn}
                  name={'Upload File'}
                  disabled={loadingSignedUrl || isLoading}
                  isLoading={loadingSignedUrl || isLoading}
                />
              }
            /> */}
            {/* <Typography className={classes.orText}>Or</Typography> */}
            <Box className={classes.linkBox}>
              <Typography className={classes.labelText} variant="body1">
                <b>Enter Link</b>
              </Typography>
              <Controller
                name="informationLink"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box className={classes.controllerBox}>
                    <WrappedTextInput
                      type="text"
                      placeholder="Url"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(e.target.value.trimStart())
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </Box>
                )}
              />
            </Box>
          </Box>
          <Box>
            <Button
              className={classes.prevButton}
              name="Previous"
              onClick={() => handleBack()}
              disabled={isLoading}
            />
            <Button
              type="submit"
              className={classes.nextButton}
              name="Next"
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};
export default MarketingMaterial;
