import React, { ReactChildren } from 'react';

import { NavBar, Box } from 'components';

import styles from './styles';

type Props = {
  children: ReactChildren;
};

// Use this layout for different background color
const DashboardLayoutV2 = ({ children }: Props) => {
  const classes = styles();
  return (
    <Box className={classes.dashboardLayout}>
      <Box className={classes.dashLayoutLeft}>
        <NavBar />
      </Box>
      <Box className={classes.dashLayoutRightV2}>{children}</Box>
    </Box>
  );
};

export default DashboardLayoutV2;
