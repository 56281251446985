import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { isEmpty } from '@aws-amplify/core';

import { Accordion, Box, Button, Dialog } from 'components';
import { RootState } from 'redux-modules/store/rootState';
import {
  approveVdRequest,
  emptyApproveVdLoanState,
  emptyApproveVdReqState,
} from 'redux-modules/process/Actions';
//import { DocuSignDocType } from 'redux-modules/process/Types';
import { getLoansDetailsByStartupId } from 'redux-modules/enquiries/Actions';
import { FN } from 'common/types';

import VentureDebt from '../../../../views/dashboard/components/VentureDebt';
import { VDApproveLoan } from '..';
// import ReviewDocuments from '../../../ReviewDocumentsList';
import styles from '../../styles';

type VentureDebtProps = {
  fundType: string | undefined;
  startupId: string | undefined;
  isRoleAdmin: boolean;
  isDispatchReq: boolean;
  expanded: string | boolean;
  handleChange: FN;
  handleUpdateRequestedDoc: FN;
  handleSendDocuSignDocs: FN;
  validateDocuments: FN;
};

const VentureDebtProcess = ({
  // fundType,
  startupId,
  isRoleAdmin,
  expanded,
  handleChange,
}: // handleUpdateRequestedDoc,
// handleSendDocuSignDocs,
// validateDocuments,
VentureDebtProps) => {
  const dispatch = useDispatch();
  const classes = styles();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [dialogFormTitle, setDialogFormTitle] = useState<string>('');

  const { vdProcess } = useSelector(({ Enquiries }: RootState) => Enquiries);
  // const isVdReqRaised = get(vdProcess, 'vdConversionRequestRaised', false);

  const { approveVdReq } = useSelector(({ Process }: RootState) => Process);
  // const isDocumentsLoading = useSelector(
  //   ({ Process }: RootState) => Process.isLoading,
  // );

  // const { Documents, docuSignDocumentsTemplate } = useSelector(
  //   ({ Process }: RootState) => Process,
  // );
  // const { documents } = Documents;
  // const { isDocuSignLoading } = docuSignDocumentsTemplate;
  // const { docusignDocuments, requestedDocuments } = docuSignDocumentsTemplate;

  const handleApproveVdRequest = () => {
    if (startupId) {
      dispatch(approveVdRequest(startupId));
    }
  };

  useEffect(() => {
    if (get(approveVdReq, 'successMessage')) {
      // if (isRoleAdmin) {
      //   dispatch(getFundType(startupId));
      // } else {
      //   dispatch(getLoansDetailsByStartupId(startupId));
      // }
      if (!isRoleAdmin) {
        dispatch(getLoansDetailsByStartupId(startupId));
      }
    }
  }, [approveVdReq]);

  const handleGetName = (value: string) => {
    if (vdProcess && !isEmpty(vdProcess)) {
      switch (value) {
        case 'REVIEW_VD_DETAILS':
          if (isRoleAdmin) {
            return vdProcess.vdConversionRequestApproved
              ? 'Request aprroved'
              : 'Yet to approve request';
          } else {
            return vdProcess.vdConversionRequestRaised ? 'Request raised' : '';
          }
        case 'DOCU_SIGN_DOCUMENTS':
          if (isRoleAdmin) {
            return vdProcess.docuSignRequestRaised
              ? 'Request raised'
              : 'Yet to raise request';
          } else {
            return vdProcess.documentsSigned
              ? 'Documents Signed'
              : 'Yet to sign Documents';
          }
        case 'REVIEW_DOCUMENTS':
          return vdProcess.documentsVerified
            ? 'Documents verified'
            : 'Yet to verify documents';
        case 'APPROVE_LOAN':
          return vdProcess.isLoanToVdApproved
            ? 'Approved'
            : 'Yet to approve loan';
        case 'VD_FORM':
          if (isRoleAdmin) {
            return vdProcess.vdConversionRequestApproved
              ? 'View VD Form'
              : 'Review VD Form';
          } else {
            return 'View VD Form';
          }
        default:
          break;
      }
    }
  };

  const handleGetApproveLoanSubHeading = () => {
    if (get(vdProcess, 'documentsVerified')) {
      if (get(vdProcess, 'isLoanToVdApproved')) {
        return 'Loan is approved. Click on Send to get the details.';
      } else {
        return isRoleAdmin
          ? 'Fill the Approve loan form to Approve loan'
          : 'Your loan will be approved soon';
      }
    } else {
      return isRoleAdmin
        ? 'Verify the documents to approve loan'
        : get(vdProcess, 'documentsVerified') &&
          get(vdProcess, 'documentsSigned')
        ? 'Document verification is in progress. Your loan will be approved soon.'
        : 'Submit the required documents to get your loan approved';
    }
  };

  return (
    <>
      <Box>
        {/* <Typography
          className={classes.bridgeLoadText}
          gutterBottom
          variant="h3"
        >
          VENTURE DEBT
        </Typography> */}

        <Accordion
          // title={'Step 1'}
          subheading={'Review VD details'}
          expanded={expanded === 'REVIEW_VD_DETAILS'}
          handleChange={(e: string) => handleChange(e)}
          index={'REVIEW_VD_DETAILS'}
          // style={{ marginTop: 20 }}
          buttons={
            <Box className={classes.summaryAccordRight}>
              <Button
                name={handleGetName('REVIEW_VD_DETAILS')}
                className={classes.statusBtn}
              />
              <Button
                name={handleGetName('VD_FORM')}
                className={classes.formSendBtn}
                onClick={() => {
                  setOpen(true);
                  setDialogFormTitle('Venture Debt');
                }}
              />
              <MoreHorizIcon className={classes.moreIcon} />
            </Box>
          }
        >
          <Box className={classes.accordDetailsContent}></Box>
        </Accordion>
        {/* <Accordion
          title={'Step 2'}
          subheading={'Share Documents to be Signed'}
          expanded={expanded === 'DOCU_SIGN_DOCUMENTS'}
          handleChange={(e: string) => handleChange(e, 'DOCU_SIGN_DOCUMENTS')}
          index={'DOCU_SIGN_DOCUMENTS'}
          buttons={
            <Box className={classes.summaryAccordRight}>
              <Button
                name={handleGetName('DOCU_SIGN_DOCUMENTS')}
                className={classes.statusBtn}
              />
              {isRoleAdmin && (
                <Button
                  name="Send"
                  className={classes.accordSendBtn}
                  onClick={() => handleSendDocuSignDocs()}
                  disabled={
                    get(vdProcess, 'docuSignRequestRaised') ||
                    !get(vdProcess, 'vdConversionRequestApproved')
                  }
                  isLoading={isDocuSignLoading}
                />
              )}
              <MoreHorizIcon className={classes.moreIcon} />
            </Box>
          }
        >
          <Box className={classes.accordDetailsContent}>
            {isRoleAdmin && (
              <Button
                name="Sync"
                variant="contained"
                isLoading={isDocuSignLoading}
              />
            )}
            {isRoleAdmin &&
              docusignDocuments &&
              docusignDocuments.map((document) => {
                const { id } = document;
                return (
                  <DocuSignDocument
                    key={id}
                    document={document}
                    handleUpdateRequestedDoc={handleUpdateRequestedDoc}
                    requestedDocuments={requestedDocuments}
                    isRoleAdmin={isRoleAdmin}
                  />
                );
              })}
            {!isRoleAdmin &&
              requestedDocuments &&
              requestedDocuments.map((document) => {
                const { id } = document;
                return (
                  <DocuSignDocument
                    key={id}
                    document={document}
                    handleUpdateRequestedDoc={handleUpdateRequestedDoc}
                    isRoleAdmin={isRoleAdmin}
                  />
                );
              })}
          </Box>
        </Accordion>
        <Accordion */}
        {/* title={'Step 3'}
          subheading={'Review Documents'}
          expanded={expanded === 'REVIEW_DOCUMENTS'}
          handleChange={(e: string) => handleChange(e)}
          index={'REVIEW_DOCUMENTS'}
          buttons={
            <Box className={classes.summaryAccordRight}>
              <Button
                name={handleGetName('REVIEW_DOCUMENTS')}
                className={classes.statusBtn}
              />
              {isRoleAdmin && (
                <Button
                  name="Validate"
                  onClick={() => validateDocuments()}
                  className={classes.accordSendBtn}
                  disabled={
                    get(vdProcess, 'documentsVerified') ||
                    !get(vdProcess, 'docuSignRequestRaised')
                  }
                />
              )}
              <MoreHorizIcon className={classes.moreIcon} />
            </Box>
          }
        >
          <Grid container>
            {isDocumentsLoading ? (
              <Box className={classes.loader}>
                <CircularProgress color="inherit" size={40} />
              </Box>
            ) : !isEmpty(documents) ? (
              <ReviewDocuments
                startupId={startupId}
                fundType={fundType}
                isRoleAdmin={isRoleAdmin}
              />
            ) : (
              !isRoleAdmin && (
                <Typography>Admin didnt requested any document</Typography>
              )
            )}
          </Grid>
        </Accordion> */}
        <Accordion
          // title={'Step 4'}
          subheading={'Approve Loan'}
          expanded={expanded === 'APPROVE_LOAN'}
          handleChange={(e: string) => handleChange(e)}
          index={'APPROVE_LOAN'}
          buttons={
            <Box className={classes.summaryAccordRight}>
              <Button
                name={handleGetName('APPROVE_LOAN')}
                className={classes.statusBtn}
              />
              {/* <Button
                name="Send"
                onClick={() => {
                  setOpen(true);
                  setDialogFormTitle('Approve Loan');
                }}
                className={classes.accordSendBtn}
                disabled={!get(vdProcess, 'documentsVerified')}
              /> */}
              {isRoleAdmin ? (
                <Button
                  name={get(vdProcess, 'isLoanToVdApproved') ? 'View' : 'Send'}
                  onClick={() => {
                    setOpen(true);
                    setDialogFormTitle('Approve Loan');
                  }}
                  className={classes.accordSendBtn}
                  disabled={!get(vdProcess, 'vdConversionRequestApproved')}
                />
              ) : (
                <Button
                  name={'View'}
                  onClick={() => {
                    setOpen(true);
                    setDialogFormTitle('Approve Loan');
                  }}
                  className={classes.accordSendBtn}
                  disabled={!get(vdProcess, 'vdConversionRequestApproved')}
                />
              )}
              <MoreHorizIcon className={classes.moreIcon} />
            </Box>
          }
        >
          <Typography>{handleGetApproveLoanSubHeading()}</Typography>
        </Accordion>
      </Box>
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        className={classes.approveModel}
        subheading={''}
        title={dialogFormTitle}
        handleClose={() => {
          dispatch(emptyApproveVdLoanState());
          dispatch(emptyApproveVdReqState());
          setOpen(false);
        }}
      >
        {dialogFormTitle === 'Approve Loan' ? (
          <VDApproveLoan
            handleBack={() => setOpen(false)}
            startupId={startupId}
          />
        ) : (
          <VentureDebt
            handleBack={() => setOpen(false)}
            startupId={startupId}
            handleApproveVdRequest={handleApproveVdRequest}
          />
        )}
      </Dialog>
    </>
  );
};

export default VentureDebtProcess;
