import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Typography, Grid, TextField } from '@material-ui/core';
import { isEmpty, get } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import {
  Box,
  Button,
  Dialog,
  WrappedSelect,
  WrappedTextInput,
  WrappedAutocomplete,
} from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { addReferralDetailByAdmin } from 'services';

import styles from './styles';
import { referralSchema } from './validations';

type AddReferralProps = {
  referralDialog: boolean;
  setReferralDialog: any;
  adminFilters: Array<Record<string, any>>;
  selectEditReferral: Record<string, any>;
  setSelectEditReferral: any;
  editMode: boolean;
  setEditMode: any;
  getReferralDataList: any;
};

const AddReferral = ({
  referralDialog,
  setReferralDialog,
  adminFilters,
  selectEditReferral,
  setSelectEditReferral,
  editMode,
  setEditMode,
  getReferralDataList,
}: AddReferralProps) => {
  const classes = styles();

  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(referralSchema),
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedInvs, setSelectedInvs] = React.useState({});

  const handleCloseDialog = () => {
    reset();
    setSelectedInvs({});
    setSelectEditReferral({});
    setErrorMessage('');
    setEditMode(false);
    setReferralDialog(false);
  };

  const getAddReferralPayload = (
    data: Record<string, any>,
    tempReferredBy: Record<string, any>,
  ) => {
    return {
      referrerId: !isEmpty(get(data, 'referredBy.value'))
        ? get(data, 'referredBy.value')
        : get(data, 'referredBy'),
      refereeId: !isEmpty(get(data, 'referrer.value'))
        ? get(data, 'referrer.value')
        : get(data, 'referrer'),
      referrerEmail: get(tempReferredBy, 'email'),
      refereeEmail: get(data, 'email'),
      referralDate: moment(get(data, 'dateReferral')).format('YYYY-MM-DD'),
      status: get(data, 'status'),
      investedDate: moment(get(data, 'investedDate')).format('YYYY-MM-DD'),
      carryRewardAmount: get(data, 'incentiveFee', ''),
      referralDealId: get(data, 'firstSpv'),
      referralPage: get(data, 'referralPage'),
      investedAmount: get(data, 'amountInvested'),
      id: editMode ? get(selectEditReferral, 'id') : undefined,
    };
  };

  const onSubmit = () => {
    setErrorMessage('');
    const data = getValues();
    const tempReferredBy = get(adminFilters, 'investors').find(
      (val: any) => get(val, 'value') === get(data, 'referredBy.value'),
    );
    const payload = getAddReferralPayload(data, tempReferredBy);
    const type = 'INVESTOR';
    setIsLoading(true);
    addReferralDetailByAdmin(payload, type)
      .then(() => {
        handleCloseDialog();
        getReferralDataList();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  const setRefereeData = (e: any) => {
    const tempSelected = get(adminFilters, 'investors').find(
      (val: any) => get(val, 'value') === e.value,
    );
    setValue('email', get(tempSelected, 'email'));
    setValue(
      'status',
      get(tempSelected, 'investment.dealClosed') ? 'DEAL_CLOSED' : 'INVESTED',
    );
    setValue('amountInvested', get(tempSelected, 'investment.amountInvested'));
    setValue(
      'investedDate',
      moment(get(tempSelected, 'investment.investedAt')).format('YYYY-MM-DD'),
    );
    const spvValue = get(adminFilters, 'deals').find(
      (item: any) => item.value === get(tempSelected, 'investment.spv'),
    );
    setValue('firstSpv', get(spvValue, 'value'));
    setSelectedInvs(tempSelected);
  };

  const getIncentiveFieldStatus = () => {
    if (editMode) {
      const investor = (get(adminFilters, 'investors') || []).find(
        (item: Record<string, string>) =>
          item.value === get(selectEditReferral, 'refereeId'),
      );
      return !get(investor, 'investment.dealClosed');
    } else if (!isEmpty(selectedInvs)) {
      return !get(selectedInvs, 'investment.dealClosed');
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (editMode && !isEmpty(selectEditReferral)) {
      setValue(
        'referredBy',
        !isEmpty(get(selectEditReferral, 'referrerName'))
          ? {
              text: get(selectEditReferral, 'referrerName'),
              value: get(selectEditReferral, 'referrerId'),
            }
          : '',
      );
      setValue(
        'referrer',
        !isEmpty(get(selectEditReferral, 'refereeName'))
          ? {
              text: get(selectEditReferral, 'refereeName'),
              value: get(selectEditReferral, 'refereeId'),
            }
          : '',
      );
      setValue('email', get(selectEditReferral, 'referrerEmail'));
      setValue('incentiveFee', get(selectEditReferral, 'carryRewardAmount'));
      // setValue(
      //   'status',
      //   get(selectEditReferral, 'invested') ? 'DEAL_CLOSED' : 'INVESTED',
      // );
      setValue('status', get(selectEditReferral, 'status'));
      setValue('amountInvested', get(selectEditReferral, 'investedAmount'));
      setValue(
        'investedDate',
        moment(get(selectEditReferral, 'investedDate')).format('YYYY-MM-DD'),
      );
      setValue(
        'dateReferral',
        moment(get(selectEditReferral, 'referralDate')).format('YYYY-MM-DD'),
      );
      const spvValue = get(adminFilters, 'deals').find(
        (item: any) => item.value === get(selectEditReferral, 'referralDealId'),
      );
      setValue('firstSpv', get(spvValue, 'value'));
    }
  }, [editMode]);

  return (
    <Dialog
      open={referralDialog}
      handleClose={handleCloseDialog}
      title={editMode ? 'Edit Referral' : 'Add New Referral'}
    >
      <form data-testid="navAttrForm" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography>Referred by*</Typography>
            <Controller
              name="referredBy"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedAutocomplete
                    id="referrer-select"
                    disableClearable
                    value={value}
                    onChange={(_: any, value: any) => {
                      onChange(value);
                    }}
                    placeholder="Referred by"
                    className={classes.autoTextValInput}
                    InputProps={{
                      classes: { root: classes.autoCompleteInputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={!!error?.message}
                    options={
                      (!isEmpty(adminFilters) &&
                        get(adminFilters, 'investors').map((item: any) => ({
                          text: item.text,
                          value: item.value,
                        }))) ||
                      []
                    }
                    disabled={editMode}
                    getOptionLabel={(option: any) => option.text || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.value === value?.value
                    }
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Name of Referee*</Typography>
            <Controller
              name="referrer"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedAutocomplete
                    id="referee-select"
                    value={value}
                    disableClearable
                    onChange={(_: any, value: any) => {
                      setRefereeData(value);
                      onChange(value);
                    }}
                    placeholder="Name of Referee"
                    className={classes.autoTextValInput}
                    InputProps={{
                      classes: { root: classes.autoCompleteInputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={!!error?.message}
                    options={
                      (!isEmpty(adminFilters) &&
                        get(adminFilters, 'investors').map((item: any) => ({
                          text: item.text,
                          value: item.value,
                        }))) ||
                      []
                    }
                    disabled={editMode}
                    getOptionLabel={(option: any) => option.text || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.value === value?.value
                    }
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Email of Referee*</Typography>
            <Controller
              name="email"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    placeholder="Email of Referee"
                    disabled
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Referral Date*</Typography>
            <Controller
              name="dateReferral"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    id="date-local"
                    label=""
                    type="date"
                    value={value}
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      inputProps: {
                        max: moment().format('YYYY-MM-DD'),
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                      shrink: true,
                    }}
                    disabled={editMode}
                    error={error?.message ? true : false}
                    onChange={onChange}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Status*</Typography>
            <Controller
              name="status"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedSelect
                    value={value}
                    onChange={onChange}
                    disabled
                    placeholder="Select Status"
                    className={classes.selectValInput}
                    InputProps={{ name: 'Status', id: 'age-simple' }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={!!error?.message}
                    dropdowns={[
                      { text: 'Deal Closed', value: 'DEAL_CLOSED' },
                      { text: 'Invested', value: 'INVESTED' },
                    ]}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>First SPV*</Typography>
            <Controller
              name="firstSpv"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedSelect
                    value={value}
                    onChange={onChange}
                    placeholder="Select SPV"
                    className={classes.selectValInput}
                    InputProps={{ name: 'SPV', id: 'age-simple' }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    disabled
                    error={!!error?.message}
                    dropdowns={get(adminFilters, 'deals') || []}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Amount Invested*</Typography>
            <Controller
              name="amountInvested"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="number"
                    disabled
                    placeholder="Amount Invested"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Invested Date*</Typography>
            <Controller
              name="investedDate"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    id="date-local"
                    disabled
                    type="date"
                    value={value}
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      // inputProps: {
                      //   max: moment().format('yyyy-mm-dd'),
                      // },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                      shrink: true,
                    }}
                    error={error?.message ? true : false}
                    onChange={onChange}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Invested Date is required' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Incentive Amount</Typography>
            <Controller
              name="incentiveFee"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="number"
                    disabled={getIncentiveFieldStatus()}
                    placeholder="Incentive Amount"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Referral page*</Typography>
            <Controller
              name="referralPage"
              control={control}
              defaultValue="OFFLINE"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedSelect
                    value={value}
                    onChange={onChange}
                    disabled
                    label=""
                    placeholder="Select referral page"
                    className={classes.selectValInput}
                    InputProps={{ name: 'ReferralPage', id: 'age-simple' }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={!!error?.message}
                    dropdowns={[
                      { text: 'Offline', value: 'OFFLINE' },
                      { text: 'Online', value: 'ONLINE' },
                    ]}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
        {errorMessage && (
          <Typography variant="body1" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        )}
        <Box className={classes.backContinueBox}>
          <Button
            name="Submit"
            type="submit"
            className={classes.continueBtn}
            isLoading={isLoading}
            disabled={isLoading || (editMode && getIncentiveFieldStatus())}
          />
          <Button
            className={classes.backBtn}
            onClick={handleCloseDialog}
            name="Cancel"
          />
        </Box>
      </form>
    </Dialog>
  );
};

export default AddReferral;
