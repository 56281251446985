import React, { ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { get, isEmpty, find } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import CloseIcon from '@material-ui/icons/Close';

import { Sidebar, Box, Stepper, WrappedSelect } from 'components';
import { Logo } from 'assets';
import { getAdminInvestorById, getInvestmentDocuments } from 'services';
import history from 'common/utils/history';
import { handleGetAllDeal } from 'redux-modules/deals/Actions';
import { RootState } from 'redux-modules/store/rootState';

import KycDetails from './Steps/KycDetails/InvestorKyc';
import SubscriptionForm from './Steps/SubscriptionForm/SubscriptionForm';
import WireInstruction from './Steps/WireInstruction/WireInstruction';
import TradeConfirmation from './Steps/TradeConfirmation/TradeConfirmation';
import DocusignUpload from './Steps/DocusignUpload/DocusignUpload';
import styles from './styles';

function getSteps() {
  return [
    { name: 'KYC', key: 'KYC_FORM' },
    { name: 'Amounts', key: 'SUBSCRIPTION_FORM' },
    { name: 'E-Signature', key: 'E_SIGN' },
    { name: 'Funds Transfer', key: 'WIRE_INSTRUCTION' },
    { name: 'Confirmation', key: 'TRADE_CONFIRMATION' },
  ];
}

// const typeSteps = ['BASIC_DETAILS_FORM', 'SUPPORTING_DOCUMENTS', 'E_SIGNATURE'];

const InvestorOnboard = ({ match }: any) => {
  const investorId = get(match, 'params.id');
  const classes = styles();
  const dispatch = useDispatch();
  const steps = getSteps();

  const { dealList, latestDeal } = useSelector(({ Deals }: RootState) => Deals);

  const [activeStep, setActiveStep] = React.useState(0);
  const [loadingInvestor, setLoadingInvestor] = React.useState<boolean>(true);
  const [completedSteps, setCompletedSteps] = React.useState(0);
  const [spv, setSPV] = React.useState<string>('');
  const [kyc, setKyc] = React.useState<any>(null);
  const [investor, setInvestor] = React.useState(null);
  const [investmentProcess, setInvestmentProcess] = React.useState(null);
  const [investmentDetail, setInvestmentDetail] = React.useState(null);
  const [documents, setDocuments] = React.useState(null);

  const spvList = dealList
    .filter((deal: any) => !deal.closed)
    .map((deal: any) => {
      return {
        text: get(deal, 'deal'),
        value: get(deal, 'id'),
      };
    });

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  function getStepContent(step: number, props: any) {
    switch (step) {
      case 0:
        return <KycDetails {...props} />;
      case 1:
        return <SubscriptionForm {...props} />;
      case 2:
        return <DocusignUpload {...props} />;
      case 3:
        return <WireInstruction {...props} />;
      case 4:
        return <TradeConfirmation {...props} />;
    }
  }

  const syncInvestor = async (action = null) => {
    if (action === 'kyc-added') {
      setCompletedSteps(1);
    }
    if (investorId !== 'NEW') {
      try {
        setLoadingInvestor(true);
        const res = await getAdminInvestorById(investorId);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setInvestor(res);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { investorKyc, investmentDetail } = res;
        const spvKyc = (investorKyc || []).find(
          (item: any) => item.spv === spv,
        );
        setKyc(spvKyc);
        let spvInvestmentDetail;
        if (spvKyc) {
          spvInvestmentDetail = (investmentDetail || []).find(
            (item: any) => item.kycId === spvKyc.id,
          );
          setInvestmentDetail(spvInvestmentDetail);
        }

        if (spvInvestmentDetail) {
          setInvestmentProcess(get(spvInvestmentDetail, 'investmentProcess'));
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoadingInvestor(false);
      }
    } else {
      setLoadingInvestor(false);
    }
  };

  const handleClose = () => {
    history.push('/admin/investor-list');
  };

  useEffect(() => {
    if (isEmpty(dealList)) dispatch(handleGetAllDeal());
  }, []);

  useEffect(() => {
    if (!isEmpty(latestDeal)) setSPV(get(latestDeal, 'id'));
  }, [latestDeal]);

  useEffect(() => {
    if (!isEmpty(spv)) syncInvestor();
  }, [investorId, spv]);

  useEffect(() => {
    if (investmentProcess) {
      if (get(investmentProcess, 'wireInstructionUploaded')) {
        setActiveStep(4);
        setCompletedSteps(5);
      } else if (get(investmentProcess, 'wireReceiptUploaded')) {
        setActiveStep(4);
        setCompletedSteps(4);
      } else if (get(investmentProcess, 'docuSignDocumentSigned')) {
        setActiveStep(3);
        setCompletedSteps(3);
      } else if (get(investmentProcess, 'subscriptionFormAdded')) {
        setActiveStep(2);
        setCompletedSteps(2);
      } else if (get(investmentProcess, 'kycAdded')) {
        setActiveStep(1);
      }
    }
  }, [investmentProcess]);

  useEffect(() => {
    if (get(investmentDetail, 'id')) {
      getInvestmentDocuments({ id: get(investmentDetail, 'id') }).then(
        (res: any) => {
          setDocuments(res);
        },
      );
    }
  }, [investmentDetail]);

  return (
    <div className={classes.root}>
      <Box className={classes.dashContainer}>
        <Box className={classes.dashleft}>
          <Sidebar className={classes.sidebarFixed}>
            <Box className={classes.dashLogo}>
              <img src={Logo} alt={Logo} className={classes.logoImg} />
            </Box>
            {!loadingInvestor && (
              <Stepper
                activeStep={activeStep}
                completedSteps={completedSteps}
                steps={steps}
                orientation={true}
                handleStepClick={handleStepClick}
                allStepDisable={false}
              />
            )}
          </Sidebar>
        </Box>
        {loadingInvestor && (
          <div className={classes.skeletonWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Skeleton
                  style={{ marginBottom: 10 }}
                  count={10}
                  enableAnimation
                  height={32}
                />
              </Grid>
              <Grid item xs={9}>
                <Skeleton
                  style={{ marginBottom: 10 }}
                  count={10}
                  enableAnimation
                  height={32}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {!loadingInvestor && (
          <Box className={classes.dashRight}>
            <Box className={classes.dashHeader}>
              <Box className={classes.spvSelect}>
                <WrappedSelect
                  // label="YC Batch"
                  variant={'outlined'}
                  value={spv}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    setActiveStep(0);
                    setCompletedSteps(0);
                    setInvestmentDetail(null);
                    setInvestmentProcess(null);
                    setSPV(get(e, 'target.value'));
                  }}
                  placeholder="SPV"
                  className={classes.selectValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  dropdowns={spvList}
                />
              </Box>
              <Typography className={classes.investNowTextBox}>
                SPV YC {get(find(dealList, { id: spv }), 'deal')}
              </Typography>
              <Typography
                variant="caption"
                className={classes.closeIcon}
                onClick={handleClose}
              >
                <CloseIcon />
              </Typography>
            </Box>
            {getStepContent(activeStep, {
              handleNext: (step: number) => setActiveStep(step),
              handleBack: () => setActiveStep(activeStep - 1),
              setActiveStep,
              investor,
              activeStep,
              kyc,
              spv: spv,
              investmentProcess,
              investmentDetail,
              syncInvestor,
              investorId,
              documents,
            })}
          </Box>
        )}
      </Box>
    </div>
  );
};
export default InvestorOnboard;
