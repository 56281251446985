import React, { useState, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { Accordion, Box, Loader, Dialog } from 'components';
import { pdfIcon, documentIcon } from 'assets';

import styles from './styles';

const VcFundMarketingMaterial = ({
  fundData,
  documentLoading,
  vcFundDocuments,
}: any) => {
  const classes = styles();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [document, setDocument] = useState<any>('');

  const handleChange = (panel: string) => (
    // eslint-disable-next-line @typescript-eslint/ban-types
    _event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const iconProps = useMemo(() => {
    return {
      className: classes.image,
    };
  }, []);

  const handleRenderFile = (ext: string, doc: any) => {
    switch (ext) {
      case 'img':
        return (
          <>
            <img
              src={doc.documentUrl}
              alt={doc.filename}
              className={classes.bGDocuImg}
              onClick={() => {
                setOpen(true);
                setUrl(doc.documentUrl);
                setDocument(doc);
              }}
            />

            <Typography className={classes.reviewDocuText}>
              {doc.filename}
            </Typography>
          </>
        );
      case 'doc':
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <img
                src={documentIcon}
                alt={documentIcon}
                className={classes.bGDocuImg}
              />
            </a>

            <Typography className={classes.reviewDocuText}>
              {doc.filename}
            </Typography>
          </>
        );
      case 'pdf':
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <img src={pdfIcon} alt={pdfIcon} className={classes.bGDocuImg} />
            </a>

            <Typography className={classes.reviewDocuText}>
              {doc.filename}
            </Typography>
          </>
        );
      default:
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <AttachFileIcon {...iconProps} />
            </a>
            <Typography className={classes.reviewDocuText}>
              {doc.filename}
            </Typography>
          </>
        );
    }
  };

  const handleLoadFile = (doc: any) => {
    const filename = doc.filename;
    let ext = filename && filename.substr(filename.lastIndexOf('.') + 1);
    if (['png', 'jpg', 'jpeg'].includes(ext)) {
      ext = 'img';
    }
    if (['doc', 'docx'].includes(ext)) {
      ext = 'doc';
    }
    return handleRenderFile(ext, doc);
  };

  return (
    <>
      <Box className={classes.accordContainer}>
        <Accordion
          subheading={'Investment Teaser'}
          expanded={expanded === 'INVESTMENT_TEASER'}
          handleChange={(e: string) => handleChange(e)}
          index={'INVESTMENT_TEASER'}
        >
          <Box>
            <Typography className={classes.teaserText}>
              A one-to-two paged document containing information on an
              acquisition or investment oppurtuity for potential investoro
            </Typography>
            {get(fundData, 'investmentTeaserLink') && (
              <a
                target="_blank"
                href={get(fundData, 'investmentTeaserLink')}
                rel="noreferrer"
              >
                <Typography className={classes.emailText}>
                  {get(fundData, 'investmentTeaserLink')}
                </Typography>
              </a>
            )}
            {/* =========--------- Document List ----------======== */}
            {documentLoading ? (
              <Box className={classes.loader}>
                <Loader />
              </Box>
            ) : (
              <Box className={classes.MarketingDocumentLists}>
                {!isEmpty(vcFundDocuments) &&
                  vcFundDocuments
                    .filter(
                      (item: any) =>
                        item.documentName === 'INVESTMENT_TEASER_DOC',
                    )
                    .map((doc: any) => {
                      return (
                        <Box key={doc.id}>
                          <Box key={doc.id}>{handleLoadFile(doc)}</Box>
                        </Box>
                      );
                    })}
              </Box>
            )}
            {/* =========--------- Document List End ----------======== */}
          </Box>
        </Accordion>
        <Accordion
          subheading={'Information Memorandum'}
          expanded={expanded === 'INFORMATION_MEMORANDUM'}
          handleChange={(e: string) => handleChange(e)}
          index={'INFORMATION_MEMORANDUM'}
        >
          <Box>
            <Typography className={classes.teaserText}>
              An investment memo is a clear and concise articulation of the key
              components of your company and what the rationale is for investing
              in it.
            </Typography>
            {get(fundData, 'informationLink') && (
              <a
                target="_blank"
                href={get(fundData, 'informationLink')}
                rel="noreferrer"
              >
                <Typography className={classes.emailText}>
                  {get(fundData, 'informationLink')}
                </Typography>
              </a>
            )}
            {/* =========--------- Document List ----------======== */}
            {documentLoading ? (
              <Box className={classes.loader}>
                <Loader />
              </Box>
            ) : (
              <Box className={classes.MarketingDocumentLists}>
                {!isEmpty(vcFundDocuments) &&
                  vcFundDocuments
                    .filter(
                      (item: any) => item.documentName === 'INFORMATION_DOC',
                    )
                    .map((doc: any) => {
                      return (
                        <Box key={doc.id}>
                          <Box key={doc.id}>{handleLoadFile(doc)}</Box>
                        </Box>
                      );
                    })}
              </Box>
            )}
            {/* =========--------- Document List End ----------======== */}
          </Box>
        </Accordion>
      </Box>
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        subheading={''}
        title={get(document, 'documentName', '')}
        handleClose={() => {
          setOpen(false);
          setUrl('');
          setDocument('');
        }}
      >
        <img
          src={url}
          alt={get(document, 'filename')}
          className={classes.previewImg}
        />
      </Dialog>
    </>
  );
};

export default VcFundMarketingMaterial;
