import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import { Box } from 'components';

import { AdminAction, StartupAction } from './ActionTemplate';
import styles from './styles';

const QuickStats = () => {
  const classes = styles();
  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  const actionsListState = useSelector(
    ({ global }: RootState) => global.actionsList,
  );
  const actionsList = get(actionsListState, 'data', []);
  const isRoleAdmin = role === 'ADMIN' ? true : false;

  return (
    <>
      <Box className={classes.dashActionSection}>
        {!isEmpty(actionsList) && (
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography gutterBottom className={classes.dashActiontext}>
                Actions
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.gridTwo}>
              <MoreHorizRoundedIcon className={classes.dottedicon} />
            </Grid>
          </Grid>
        )}
        {isRoleAdmin ? <AdminAction /> : <StartupAction />}
        {/* {role !== 'ADMIN' && <StartupAction />} */}
      </Box>
    </>
  );
};

export default QuickStats;
