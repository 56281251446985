/* eslint-disable no-unused-vars */
import * as yup from 'yup';

import { emailRegex } from 'common/utils/constants';

export const emailYup = yup
  .string()
  .email('Invalid email')
  .required('Required')
  .matches(emailRegex, 'Invalid email');

export const getEmailSchema = (field: string) =>
  yup.object().shape({
    [field]: emailYup,
  });

export const getNumberFieldSchema = (field: string) =>
  yup.object().shape({
    // [field]: yup.number().nullable().required('Required'),
  });

export const emptySchema = yup.object().shape({});
