import * as yup from 'yup';
import { isEmpty } from 'lodash';

import {
  requiredConstant,
  emailRegex,
  emailValidConstant,
  urlRegex,
  urlValidConstant,
} from 'common/utils/constants';

export const BenchMarkSchema = yup.object().shape({
  // accelerator: yup.string().nullable(),
  benchmarkStartup: yup.boolean().nullable(),
  startupFullName: yup.string().required(requiredConstant),
  ycIncorporationCountry: yup.object().nullable().required(requiredConstant),
  stateOfIncorporation: yup
    .object()
    .nullable()
    .when(
      ['accelerator', 'benchmarkStartup', 'ycIncorporationCountry.countryCode'],
      {
        is: (
          accelerator: string,
          benchmarkStartup: boolean,
          countryCode: string,
        ) => {
          return (
            isEmpty(accelerator) ||
            accelerator !== 'YC' ||
            benchmarkStartup ||
            countryCode === 'PENDING_INCORPORATION'
          );
        },
        then: yup.object().nullable(),
        otherwise: yup.object().nullable().required(requiredConstant),
      },
    ),
  businessOpsLocation: yup.object().nullable().required(requiredConstant),
  industryType: yup.object().nullable().required(requiredConstant),
  businessModel: yup.string().required(requiredConstant),
  fundingStage: yup.string().required(requiredConstant),
  accelerator: yup.string().nullable().required(requiredConstant),
  ycBatch: yup
    .string()
    .nullable()
    .when('accelerator', {
      is: (value: any) => !isEmpty(value) && value !== 'YC',
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required(requiredConstant),
    }),
  acceleratorName: yup.string().when('accelerator', {
    is: (value: any) => value === 'OTHER',
    then: yup.string().nullable().required(requiredConstant),
    otherwise: yup.string().nullable().notRequired(),
  }),
});

export const BenchMarkSchemaExistingStartup = yup.object().shape({
  // accelerator: yup.string().nullable(),
  benchmarkStartup: yup.boolean().nullable(),
  startupFullName: yup.object().nullable().required(requiredConstant),
  ycIncorporationCountry: yup.object().nullable().required(requiredConstant),
  stateOfIncorporation: yup
    .object()
    .nullable()
    .when(
      ['accelerator', 'benchmarkStartup', 'ycIncorporationCountry.countryCode'],
      {
        is: (
          accelerator: string,
          benchmarkStartup: boolean,
          countryCode: string,
        ) => {
          return (
            isEmpty(accelerator) ||
            accelerator !== 'YC' ||
            benchmarkStartup ||
            countryCode === 'PENDING_INCORPORATION'
          );
        },
        then: yup.object().nullable(),
        otherwise: yup.object().nullable().required(requiredConstant),
      },
    ),
  businessOpsLocation: yup.object().nullable().required(requiredConstant),
  industryType: yup.object().nullable().required(requiredConstant),
  businessModel: yup.string().required(requiredConstant),
  fundingStage: yup.string().required(requiredConstant),
  accelerator: yup.string().nullable().required(requiredConstant),
  ycBatch: yup
    .string()
    .nullable()
    .when('accelerator', {
      is: (value: any) => !isEmpty(value) && value !== 'YC',
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required(requiredConstant),
    }),
  acceleratorName: yup.string().when('accelerator', {
    is: (value: any) => value === 'OTHER',
    then: yup.string().nullable().required(requiredConstant),
    otherwise: yup.string().nullable().notRequired(),
  }),
});

export const BenchMarkSchemaAdditionalDetail = yup.object().shape({
  founderDetails: yup.array().of(
    yup.object().shape({
      founderName: yup.string().required(requiredConstant),
      founderEmail: yup
        .string()
        .email(emailValidConstant)
        .required(requiredConstant)
        .matches(emailRegex, emailValidConstant),
    }),
  ),
  startupTaxIdNumber: yup.string().nullable(),
  startupWebLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => value.length > 0,
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  startupTwitterUrl: yup.string().nullable(),
  startupFacebookUrl: yup.string().nullable(),
  startupLinkedInUrl: yup.string().nullable(),
  productDescription: yup.string().nullable(),
  productProgress: yup.string().nullable(),
  potentialMarketTarget: yup.string().nullable(),
  startupInvestmentInformation: yup.string().nullable(),
  startupLegalInformation: yup.string().nullable(),
});
