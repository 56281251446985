import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  profileDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  profileBox: {
    width: '100%',
    position: 'relative',
    marginTop: 15,
    '& span': {
      bottom: -18,
      lineHeight: '15px',
      minHeight: 27,
    },
  },
  inputHead: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '12px 20px 12px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 12px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px !important',
    },
  },
  labelCountryText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 0,
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInput: {
    marginBottom: 2,
    width: '100%',
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 14,
    display: 'block',
    position: 'absolute',
    bottom: 0,
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  textValInputSelectField: {
    marginBottom: 10,
    marginTop: 5,
    marginRight: 18,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  containerButtonBox: {
    display: 'flex',
    paddingBottom: 10,
  },
  thankyouButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 200,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    marginRight: 18,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    marginRight: 18,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  prevButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  deleteImg: {
    width: '12px',
    height: '14px',
    cursor: 'pointer',
    margin: 5,
  },
  downloadIcon: {
    fill: theme.palette.primary.main,
    cursor: 'pointer',
  },
  sampleFile: {
    width: 14,
    height: 15,
  },
  previewBox: {
    height: 25,
    background: '#d3d3d3',
    borderRadius: 3,
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'space-between',
  },
  nameContainer: {
    display: 'flex',
    margin: 10,
    width: 'calc(100% - 20px)',
    alignItems: 'center',
  },
  uploadFileName: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    padding: '0 7px',
    width: 'calc(100% - 13px)',
  },
  thankyouContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 25,
  },
  thankyouText: {
    fontSize: 40,
    lineHeight: '30px',
    fontWeight: 700,
    color: theme.palette.success.main,
  },
  vcFirmName: {
    color: theme.shadows[9],
  },
}));
