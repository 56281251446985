import { SubmitEnquiry } from 'common/types';

import { ActionsListPayload } from './Types';

export const TEST = 'global/test';
export const GET_ALL_COUNTRIES = 'global/GET_ALL_COUNTRIES';
export const GET_ALL_COUNTRIES_SUCCESS = 'global/GET_ALL_COUNTRIES_SUCCESS';
export const GET_ALL_INDUSTRY_CLASSIFICATION =
  'global/GET_ALL_INDUSTRY_CLASSIFICATION';
export const GET_ALL_INDUSTRY_CLASSIFICATION_SUCCESS =
  'global/GET_ALL_INDUSTRY_CLASSIFICATION_SUCCESS';

export const GET_ALL_YC_LOCATIONS = 'global/GET_ALL_YC_LOCATIONS';
export const GET_ALL_YC_LOCATIONS_SUCCESS =
  'global/GET_ALL_YC_LOCATIONS_SUCCESS';
export const GET_ALL_STATES = 'global/GET_ALL_STATES';
export const GET_ALL_STATES_SUCCESS = 'global/GET_ALL_STATES_SUCCESS';

export const GET_ACTIONS_LIST = 'global/GET_ACTIONS_LIST';
export const GET_ACTIONS_LIST_LOADING = 'global/GET_ACTIONS_LIST_LOADING';
export const GET_ACTIONS_LIST_FAILURE = 'gloabl/GET_ACTIONS_LIST_FAILURE';
export const GET_ACTIONS_LIST_SUCCESS = 'gloabl/GET_ACTIONS_LIST_SUCCESS';
export const GET_VD_FORM_VALUES = 'global/GET_VD_FORM_VALUES';
export const GET_VD_FORM_VALUES_LOADING = 'global/GET_VD_FORM_VALUES_LOADING';
export const GET_VD_FORM_VALUES_SUCCESS = 'global/GET_VD_FORM_VALUES_SUCCESS';
export const GET_VD_FORM_VALUES_FAILURE = 'global/GET_VD_FORM_VALUES_FAILURE';
export const GET_DASHBOARD_VALUES = 'global/GET_DASHBOARD_VALUES';
export const GET_DASHBOARD_VALUES_LOADING =
  'global/GET_DASHBOARD_VALUES_LOADING';
export const GET_DASHBOARD_VALUES_SUCCESS =
  'global/GET_DASHBOARD_VALUES_SUCCESS';
export const GET_DASHBOARD_VALUES_FAILURE =
  'global/GET_DASHBOARD_VALUES_FAILURE';
export const GET_VD_APPROVE_LOAN_VALUES = 'global/GET_VD_APPROVE_LOAN_VALUES';
export const GET_VD_APPROVE_LOAN_VALUES_LOADING =
  'global/GET_VD_APPROVE_LOAN_VALUES_LOADING';
export const GET_VD_APPROVE_LOAN_VALUES_SUCCESS =
  'global/GET_VD_APPROVE_LOAN_VALUES_SUCCESS';
export const GET_VD_APPROVE_LOAN_VALUES_FAILURE =
  'global/GET_VD_APPROVE_LOAN_VALUES_FAILURE';
export const SUBMIT_QUERY = 'global/SUBMIT_QUERY';
export const SUBMIT_QUERY_LOADING = 'global/SUBMIT_QUERY_LOADING';
export const SUBMIT_QUERY_SUCCESS = 'global/SUBMIT_QUERY_SUCCESS';
export const SUBMIT_QUERY_FAILURE = 'global/SUBMIT_QUERY_FAILURE';
export const SET_DASHBOARD_VIEW = 'global/SET_DASHBOARD_VIEW';
export const CLEAR_STATE = 'global/CLEAR_STATE';

export const test = (payload: Array<any>) => ({ type: TEST, payload });

export const getAllCountries = () => {
  return {
    type: GET_ALL_COUNTRIES,
  };
};

export const getAllIndustries = () => {
  return {
    type: GET_ALL_INDUSTRY_CLASSIFICATION,
  };
};

export const getAllYcLocations = () => {
  return {
    type: GET_ALL_YC_LOCATIONS,
  };
};

export const getAllStates = () => {
  return {
    type: GET_ALL_STATES,
  };
};

export const getActionsList = (payload: ActionsListPayload) => {
  return {
    type: GET_ACTIONS_LIST,
    payload,
  };
};

export const getVdFormValues = (payload: string | undefined) => {
  return {
    type: GET_VD_FORM_VALUES,
    payload,
  };
};

export const getDashboardValues = () => {
  return {
    type: GET_DASHBOARD_VALUES,
  };
};

export const getVdApproveLoanValues = (payload: string | undefined) => {
  return {
    type: GET_VD_APPROVE_LOAN_VALUES,
    payload,
  };
};

export const submitQuery = (payload: SubmitEnquiry) => {
  return {
    type: SUBMIT_QUERY,
    payload,
  };
};

export const setDashboardView = (payload: string) => {
  return {
    type: SET_DASHBOARD_VIEW,
    payload,
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};
