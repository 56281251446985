import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import {
  Box,
  Dialog,
  Loader,
  StyledTableCell,
  StyledTableRow,
} from 'components';
import { viewClientServiceUsage } from 'services';

import styles from '../styles';

type ViewServiceUsageProps = {
  open: boolean;
  setOpen: any;
  clientId: string;
};

const ViewServiceUsage = ({
  open,
  setOpen,
  clientId,
}: ViewServiceUsageProps) => {
  const classes = styles();

  const [serviceUsage, setServiceUsage] = useState<
    Array<Record<string, unknown>>
  >([]);
  const [loadingServices, setLoadingServices] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setLoadingServices(true);
      viewClientServiceUsage(`?clientId=${clientId}`)
        .then((res: any) => {
          setServiceUsage(get(res, 'data'));
          setLoadingServices(false);
        })
        .finally(() => setLoadingServices(false));
    }
  }, [open]);

  const handleShowEmptyMessage = () => {
    if (isEmpty(serviceUsage) && !loadingServices) {
      return (
        <StyledTableRow className={classes.showEmptyMsg}>
          <Box className={classes.noItemsImg}>
            <Typography>No items to show</Typography>
          </Box>
        </StyledTableRow>
      );
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={'lg'}
      className={classes.usageDialog}
      title={'Service Usage'}
      subheading={''}
      handleClose={() => {
        setServiceUsage([]);
        setOpen(false);
      }}
    >
      {loadingServices && (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      )}
      <Grid container>
        {!loadingServices && (
          <TableContainer
            style={{ minHeight: 400 }}
            className={classes.tableContainer}
            component={Paper}
          >
            <Table stickyHeader aria-label="customized table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <StyledTableCell>S.No</StyledTableCell>
                  <StyledTableCell>Month</StyledTableCell>
                  <StyledTableCell>Module</StyledTableCell>
                  <StyledTableCell>Usage Type</StyledTableCell>
                  <StyledTableCell>Usage</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceUsage.map((row: Record<string, any>, i: number) => (
                  <StyledTableRow hover key={row.id}>
                    <StyledTableCell scope="row">{1 + i}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {moment(row.lastUpdatedDate).format('MMMM YYYY')}
                    </StyledTableCell>
                    <StyledTableCell>{row.module}</StyledTableCell>
                    <StyledTableCell>{row.usageFunctionality}</StyledTableCell>
                    <StyledTableCell>{row.usage}</StyledTableCell>
                  </StyledTableRow>
                ))}
                {handleShowEmptyMessage()}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Dialog>
  );
};

export default ViewServiceUsage;
