import React, { useEffect } from 'react';
import {
  Typography,
  MenuItem,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

import history from 'common/utils/history';
import { Sidebar, Box, Stepper } from 'components';
import { Logo, Ornament } from 'assets';
import { getBatches, handleSignOut } from 'redux-modules/auth/Actions';
import {
  handleGetStartUpDetails,
  handleGetStartupDetailsById,
  emptyEnquiryForm,
} from 'redux-modules/enquiryForm/Actions';
import { trackBridgeLoanProcess } from 'redux-modules/enquiries/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { VDStepProps } from 'common/types';

import {
  CompanyDetails,
  ApplyVdDebt,
  OtherInformationDetails,
  UploadDocuments,
  DocuSignView,
} from './Steps';
import styles from './styles';

const typeSteps = [
  'BASIC_DETAILS_FORM',
  'SUPPORTING_DOCUMENTS',
  'E_SIGNATURE',
  'APPROVALS',
  'FUND_DISBURSEMENT',
];

function getSteps() {
  return [
    { name: 'Basic Details', key: 'BASIC_DETAILS_FORM' },
    { name: 'Upload Documents', key: 'UPLOAD_DOCUMENTS' },
    { name: 'E-Signature', key: 'E_SIGNATURE' },
    { name: 'Approval', key: 'APPROVALS' },
    { name: 'Disbursement', key: 'FUND_DISBURSEMENT' },
  ];
}

function getStepContent(step: number, details: boolean, props: VDStepProps) {
  switch (step) {
    case 0:
      return details ? (
        <CompanyDetails {...props} />
      ) : (
        <ApplyVdDebt {...props} />
      );

    case 1:
      return <UploadDocuments {...props} />;
    case 2:
      return <DocuSignView {...props} />;
    default:
      return <OtherInformationDetails {...props} />;
  }
}

const VentureDebtForm = ({ match }: any) => {
  const classes = styles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(0);
  const [details, setDetails] = React.useState<boolean>(false);
  const [stepName, setStepName] = React.useState<string>(typeSteps[activeStep]);
  const [enquiryId, setEnquiryId] = React.useState<string>('');
  const [isLogout, setIsLogout] = React.useState<boolean>(false);
  const [allStepDisable, setAppStepDisable] = React.useState<boolean>(false);

  const type = get(match, 'params.type');
  const steps = getSteps();
  const dispatch = useDispatch();

  const search = history.location.search;
  const event = new URLSearchParams(search).get('event');

  const { bridgeLoanProcess, isLoading } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const {
    user: { startUpId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { id } = useSelector(({ EnquiryForm }: RootState) => EnquiryForm);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (!get(bridgeLoanProcess, 'documentsSigned'))
      setCompletedSteps(activeStep + 1);
    setStepName(typeSteps[activeStep + 1]);
  };

  const detailPage = () => {
    setDetails(true);
  };
  const previousPage = () => {
    setDetails(false);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setStepName(typeSteps[activeStep - 1]);
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
    setStepName(typeSteps[index]);
  };

  useEffect(() => {
    dispatch(getBatches());
    dispatch(handleGetStartUpDetails(startUpId));
    dispatch(trackBridgeLoanProcess(startUpId));
    if (type === 'NEW') {
      dispatch(handleGetStartupDetailsById(startUpId));
    }
  }, []);

  useEffect(() => {
    if (event) {
      setCompletedSteps(3);
      if (event === 'signing_complete') {
        setAppStepDisable(true);
      }
    }
    if (!event && bridgeLoanProcess && !isLoading) {
      if (get(bridgeLoanProcess, 'documentsSubmitted')) {
        setActiveStep(1);
        setCompletedSteps(2);
        setStepName(typeSteps[1]);
      } else if (get(bridgeLoanProcess, 'basicInfoAdded')) {
        setActiveStep(1);
        setCompletedSteps(1);
        setStepName(typeSteps[1]);
      }
    }
  }, [bridgeLoanProcess, isLoading]);

  const handleSignOutButton = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Intercom('shutdown');
    setIsLogout(true);
    dispatch(handleSignOut());
    dispatch(emptyEnquiryForm());
    setIsLogout(false);
  };

  const Buttons = () => (
    <Box className={classes.leftBottomSection}>
      <Box className={classes.leftLinks}>
        <MenuItem className={classes.logoutBtn} onClick={handleSignOutButton}>
          {isLogout && <CircularProgress color="inherit" size={20} />}
          Logout
        </MenuItem>
      </Box>
    </Box>
  );

  return (
    <div className={classes.root}>
      <Box className={classes.dashContainer}>
        <Box className={classes.dashleft}>
          <Sidebar className={classes.sidebarFixed}>
            <Box className={classes.dashLogo}>
              <img src={Logo} alt={Logo} className={classes.logoImg} />
              <Typography variant="h3" className={classes.logoText}>
                Application
              </Typography>
            </Box>
            {!isLoading && (
              <Stepper
                activeStep={activeStep}
                completedSteps={completedSteps}
                steps={steps}
                orientation={true}
                handleStepClick={handleStepClick}
                allStepDisable={allStepDisable}
              />
            )}
          </Sidebar>
          <Box>{Buttons()}</Box>
        </Box>
        {!isLoading ? (
          <Box className={classes.dashRight}>
            <Box className={classes.dashHeader}>
              <Typography variant="h3" className={classes.linkdashText}>
                {!isEmpty(bridgeLoanProcess) &&
                get(bridgeLoanProcess, 'documentsSigned') ? (
                  <span
                    onClick={() => history.push('/dashboard')}
                    className={classes.goToDashBoardBtn}
                  >
                    Go to dashboard
                  </span>
                ) : null}
                <span
                  onClick={() => history.push('/benchmark-kpi-v2/EDIT')}
                  className={classes.bridgeTerms}
                >
                  Yardstick
                </span>
                <span
                  className={classes.contactus}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onClick={() => window.Intercom('show')}
                >
                  Contact Us
                </span>
              </Typography>
              <img
                src={Ornament}
                alt={Ornament}
                className={classes.imgOrnament}
              />
            </Box>
            {getStepContent(activeStep, details, {
              handleNext: handleNext,
              handleBack: handleBack,
              stepName,
              id,
              startUpId,
              type,
              detailPage,
              previousPage,
              setEnquiryId,
              enquiryId,
            })}
          </Box>
        ) : (
          <div className={classes.skeletonWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Skeleton
                  style={{ marginBottom: 10 }}
                  count={10}
                  enableAnimation
                  height={32}
                />
              </Grid>
              <Grid item xs={9}>
                <Skeleton
                  style={{ marginBottom: 10 }}
                  count={10}
                  enableAnimation
                  height={32}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Box>
    </div>
  );
};

export default VentureDebtForm;
