import React, { useEffect } from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';

import { Button, WrappedTextInput, Box, WrappedSelect } from 'components';
import { signUp, getBatches } from 'redux-modules/auth/Actions';
import { SignUpImage } from 'assets/index';
import { RootState } from 'redux-modules/store/rootState';
import { ISignUpModal } from 'common/types';
import { acceleratorList } from 'common/utils/optionList';
import { SignUpYCSchema } from 'common/utils/validations';
import history from 'common/utils/history';

import styles from './styles';

const SignUp = () => {
  const param = useLocation();
  const UrlParamFrom: any = new URLSearchParams(param.search).get('from');
  const dispatchAction = useDispatch();
  const classes = styles();

  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SignUpYCSchema),
  });

  const { ycBatches } = useSelector(({ Auth }: RootState) => Auth);

  useEffect(() => {
    dispatchAction(getBatches());
  }, []);

  const { isLoading, message } = useSelector(
    ({ Auth }: RootState) => Auth.signUp,
  );

  const query = new URLSearchParams(window.location.search);
  const referralCode = query.get('investor-referal');
  const startup = query.get('startup');
  const email = query.get('email');
  const type = query.get('type');
  const inviteId = query.get('invite');

  const onSubmit = (data: ISignUpModal) => {
    const obj = {
      name: get(data, 'name'),
      startupName: get(data, 'startupName'),
      ycBatch: get(data, 'accelerator') === 'YC' ? get(data, 'ycBatch') : null,
      password: get(data, 'password'),
      email: get(data, 'email'),
      referralCode,
      startup,
      redirectUrl:
        type === 'deal' && !isEmpty(inviteId)
          ? `/dashboard?type=${type}&invite=${inviteId}`
          : undefined,
      type,
      accelerator:
        get(data, 'accelerator') === 'OTHER'
          ? get(data, 'acceleratorName')
          : get(data, 'accelerator'),
    };
    dispatchAction(signUp(obj));
  };

  return (
    <Box className={classes.signUpContainer}>
      {/* <Box className={classes.loaderCenter}>
        {isLoading && <CircularProgress />}
      </Box> */}
      <Grid container className={classes.gridContainer}>
        <Grid item sm={6} xs={12}>
          <Box className={classes.leftSide}>
            <Typography
              variant="h2"
              gutterBottom
              className={classes.welcome8vdx}
            >
              Welcome to 8vdX!
            </Typography>
            {!['benchmark', 'captable', 'fundraising', 'venturedebt'].includes(
              UrlParamFrom,
            ) && (
              <Typography className={classes.welcomeSub8vdx}>
                {/* Sign up to start your bridge loan application */}
              </Typography>
            )}
            <form
              onSubmit={handleSubmit(onSubmit)}
              data-testid="signUpForm"
              className={classes.validateForm}
            >
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label="Founder's Name"
                      placeholder="Founder's Full Name"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(get(e, 'target.value').trimStart())
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: "Founder's Name is required" }}
              />
              <Controller
                name="email"
                control={control}
                defaultValue={email}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label="Founder's Email"
                      placeholder="Enter your Email ID" //"Enter your email ID provided to YC"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(
                          get(e, 'target.value').trimStart().toLowerCase(),
                        )
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Email is required' }}
              />
              <Controller
                name="startupName"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label="Company Name"
                      placeholder="Company Name"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(get(e, 'target.value').trimStart())
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Company name is required' }}
              />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={
                    watch('accelerator') === 'NONE' ||
                    isEmpty(watch('accelerator'))
                      ? 12
                      : 6
                  }
                >
                  <Controller
                    name="accelerator"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedSelect
                          value={value}
                          onChange={onChange}
                          label="Accelerator"
                          placeholder="Accelerator"
                          className={classes.selectValInput}
                          InputProps={{ name: 'Accelerator', id: 'age-simple' }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          error={!!error?.message}
                          dropdowns={acceleratorList}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Grid>
                {watch('accelerator') === 'YC' && (
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="ycBatch"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <WrappedSelect
                            value={value}
                            onChange={onChange}
                            label="YC Batch"
                            placeholder="YC batch"
                            className={classes.selectValInput}
                            InputProps={{
                              name: 'Yc Batch',
                              id: 'age-simple',
                            }}
                            SelectProps={{
                              MenuProps: {
                                classes: { paper: classes.menuPaper },
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused,
                              },
                            }}
                            error={!!error?.message}
                            dropdowns={ycBatches}
                          />
                          {error?.message && (
                            <span className={classes.errorText}>
                              {error?.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                )}
                {watch('accelerator') === 'OTHER' && (
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="acceleratorName"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <WrappedTextInput
                            type="text"
                            label="Accelerator Name"
                            placeholder="Accelerator Name"
                            className={classes.textValInput}
                            InputProps={{
                              classes: { root: classes.inputRoot },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused,
                              },
                            }}
                            value={value}
                            onChange={(e: any) =>
                              onChange(get(e, 'target.value').trimStart())
                            }
                            error={!!error?.message}
                          />
                          {error?.message && (
                            <span className={classes.errorText}>
                              {error?.message}
                            </span>
                          )}
                        </>
                      )}
                      rules={{ required: 'Accelerator Name is required' }}
                    />
                  </Grid>
                )}
              </Grid>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="password"
                      label="Password"
                      placeholder="Password"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(get(e, 'target.value').trimStart())
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Password is required' }}
              />
              <Box>
                <Box>
                  {message && message.length && (
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.errorAlready}
                    >
                      {message}
                      <Typography
                        variant="caption"
                        className={classes.errorAlready}
                      >
                        &nbsp;Please
                      </Typography>
                      <Typography
                        variant="caption"
                        className={classes.hyperLink}
                        onClick={() => history.push('/sign-in')}
                      >
                        Sign In
                      </Typography>
                    </Typography>
                  )}
                </Box>
                <Button
                  type="submit"
                  name="Sign up"
                  className={classes.signupBtn}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </Box>
              <Box className={classes.doYouHaveBox}>
                <Typography className={classes.doYouHaveBoxText}>
                  Already have an account?
                  <Typography
                    variant="caption"
                    className={classes.doYouLink}
                    onClick={() => history.push('/sign-in')}
                  >
                    &nbsp;SignIn
                  </Typography>
                </Typography>
              </Box>
            </form>
          </Box>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={6} xs={12} className={classes.rightSide}>
            <img
              src={SignUpImage}
              className={classes.signupImage}
              alt="signUp"
            />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};

export default SignUp;
