import React, { useEffect } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { parse } from 'papaparse';
import * as XLSX from 'xlsx';
import { isEmpty, get } from 'lodash';
import { useDispatch } from 'react-redux';

import { successImage } from 'assets';
import {
  Box,
  Button,
  Dialog,
  SuccessPage,
  StyledTableRow,
  StyledTableCell,
} from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';
import { getStartupData } from 'redux-modules/enquiryForm/Actions';

import styles from './styles';

// const ec = (r: any, c: any) => {
//   return XLSX.utils.encode_cell({ r: r, c: c });
// };

// const delete_row = (ws: any, row_index: any) => {
//   const range: any = XLSX.utils.decode_range(ws['!ref']);
//   for (let R = row_index; R < range.e.r; ++R) {
//     for (let C = range.s.c; C <= range.e.c; ++C) {
//       ws[ec(R, C)] = ws[ec(R + 1, C)];
//     }
//   }
//   range.e.r--;
//   ws['!ref'] = XLSX.utils.encode_range(range.s, range.e);
// };

type Props = {
  openCsvUpload: boolean;
  setOpenCsvUpload: FN;
  getStartupBenchmarkKpiAllData?: FN;
  startupId: string;
  title: string;
  showGroupHeader?: boolean;
  getSignedUrl: FN;
  uploadFileUrl: FN;
  sampleFile?: string;
  onSuccessProcess?: FN;
  columnMapping?: any;
};

const CsvUploadFcrm = ({
  openCsvUpload,
  setOpenCsvUpload,
  getStartupBenchmarkKpiAllData,
  startupId,
  title,
  showGroupHeader = true,
  getSignedUrl,
  uploadFileUrl,
  sampleFile,
  onSuccessProcess,
  columnMapping,
}: Props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [preview, setPreview] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [successModal, setSuccessModal] = React.useState<boolean>(false);
  const [fileUploadReq, setFileUploadReq] = React.useState<any>([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [errorData, setErrorData] = React.useState<any>([]);
  const [spanData, setSpanData] = React.useState<any>([]);
  const [selectedColumns, setSelectedColumns] = React.useState<any>([]);
  const [noMapColumns, setNoMapColumns] = React.useState<any>([]);

  useEffect(() => {
    setNoMapColumns(
      selectedColumns.filter((item: any) => !columnMapping.includes(item)),
    );
  }, [selectedColumns]);

  useEffect(() => {
    setNoMapColumns(
      selectedColumns.filter((item: any) => !columnMapping.includes(item)),
    );
  }, [selectedColumns]);
  const handleAddFileData = async (data: any) => {
    setTableData([]);
    setErrorMessage('');
    setErrorData([]);
    setFileUploadReq(data);
    const fileName = data[0].file.name;
    if (data) {
      const fileExtension = fileName.split('.').pop();
      if (['csv', 'text/csv'].includes(fileExtension)) {
        Array.from(data).map(async (file: any) => {
          const text = await file.file.text();
          const columnJsonData: any = parse(text, { skipEmptyLines: 'greedy' });
          if (columnMapping) setSelectedColumns(columnJsonData.data[0]);
          setTableData(columnJsonData.data);
          getSpanData(columnJsonData.data);
        });
        setPreview(true);
      }
      if (['xlsx'].includes(fileExtension)) {
        const arr = await data[0].file.arrayBuffer();
        const workbook = XLSX.read(arr);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const columnJsonData: any = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: '-',
          blankrows: false,
          raw: false,
        });
        if (columnMapping) setSelectedColumns(columnJsonData[0]);
        setTableData(columnJsonData);
        getSpanData(columnJsonData);

        setPreview(true);
      }
    }
  };

  const getSpanData = (columnJsonData: any) => {
    const kpiDetailSpan = columnJsonData[0].findIndex(
      (item: any) => item === 'Growth Rate Name',
    );
    const fundraisingColStartIndex = columnJsonData[0].findIndex(
      (item: any) => item === 'Round Stage',
    );
    const growthSpan = fundraisingColStartIndex - kpiDetailSpan;
    const fundraisingSpan =
      columnJsonData[0].length - growthSpan - kpiDetailSpan;
    setSpanData({ kpiDetailSpan, growthSpan, fundraisingSpan });
  };

  const handleUploadStartups = async () => {
    setErrorMessage('');
    setErrorData([]);

    const emailCoumn = (selectedColumns || []).find(
      (item: string) => item === 'Email',
    );
    if (!emailCoumn) {
      setErrorMessage('Email column is required');
      return;
    }
    const allEmpty = (selectedColumns || []).every(
      (item: string) => item === 'EMPTY',
    );
    if (allEmpty) {
      setErrorMessage('All the columns cannot be empty');
      return;
    }
    if (!isEmpty(selectedColumns) && !isEmpty(columnMapping)) {
      let mappedColumns = true;
      const duplicateContent = selectedColumns.filter(
        (item: any, index: any) =>
          selectedColumns.indexOf(item) != index && item !== '' && item !== '-',
      );
      const emptyColumns = duplicateContent.filter(
        (item: any) => item === 'EMPTY',
      );
      if (
        duplicateContent?.length > 0 &&
        emptyColumns?.length !== duplicateContent?.length
      ) {
        setErrorMessage('Multiple columns have same column mapping');
        return;
      }

      selectedColumns.map((item: any) => {
        if (!columnMapping.includes(item) && item !== '' && item !== '-')
          mappedColumns = false;
      });

      if (!mappedColumns) {
        setErrorMessage('Columns are not mapped correctly');
        return;
      }
    }
    if (!isEmpty(fileUploadReq)) {
      setIsLoading(true);
      try {
        const fileObject = fileUploadReq[0];
        const signedUrlResponse = await getSignedUrl({
          fileName: get(fileObject, 'file.name'),
          startupId: startupId,
        });

        // eslint-disable-next-line no-undef
        await fetch(get(signedUrlResponse, 'uploadUrl'), {
          method: 'PUT',
          body: fileObject.file,
        });

        const oldKeys = tableData[0];
        const fieldMapping = selectedColumns
          .map((item: any, index: any) => {
            return {
              oldKey: oldKeys[index],
              newKey: item,
            };
          })
          .filter((item: any) => item.oldKey !== '' && item.oldKey !== '-');

        await uploadFileUrl({
          url: get(signedUrlResponse, 'accessUrl'),
          startupId: startupId,
          fieldMapping,
        });
        if (onSuccessProcess) onSuccessProcess();
        setSuccessModal(true);
        getStartupBenchmarkKpiAllData();
        dispatch(getStartupData(startupId));
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        const errorResponse = get(err, 'response.data.response.error', []);
        if (!isEmpty(errorResponse)) setErrorData(errorResponse);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload file.');
    }
  };

  const handleCloseDialog = () => {
    setOpenCsvUpload(false);
    successModal && setSuccessModal(false);
    preview && setPreview(false);
    setErrorMessage('');
    setErrorData([]);
    setTableData([]);
    setSelectedColumns([]);
    if (!isEmpty(fileUploadReq)) setFileUploadReq([]);
  };

  return (
    <div>
      <Dialog
        open={openCsvUpload}
        maxWidth={'md'}
        className={classes.uploadStartupModal}
        title={title}
        subheading={'Please choose a file to begin upload'}
        handleClose={handleCloseDialog}
      >
        <Box className={classes.uploadButtonBox}>
          <Grid container className={classes.uploadContainer}>
            <Grid item xs={12}>
              <DropzoneAreaBase
                classes={{ root: classes.dropZone }}
                acceptedFiles={['.csv', 'text/csv', '.xlsx']}
                showAlerts={true}
                showPreviewsInDropzone={false}
                showPreviews={true}
                fileObjects={fileUploadReq}
                maxFileSize={5000000}
                filesLimit={1}
                showFileNamesInPreview={true}
                showFileNames={true}
                inputProps={{ disabled: isLoading }}
                useChipsForPreview
                previewChipProps={{
                  classes: {
                    root: classes.previewChip,
                  },
                  clickable: true,
                  onClick: () => setPreview(true),
                }}
                getFileLimitExceedMessage={() => 'File is too big'}
                onAdd={(fileObjs) => handleAddFileData(fileObjs)}
                getDropRejectMessage={(file) => {
                  if (file.size > 5000000) return 'File is too big';
                  else return 'Invalid file type';
                }}
                onDelete={() => {
                  setErrorMessage('');
                  setErrorData([]);
                  setFileUploadReq([]);
                }}
                dropzoneText="Click here or drag and drop the file here to upload"
              />
            </Grid>
            <Grid item xs={12} className={classes.sampleCsvBox}>
              <Typography variant="h5" className={classes.sampleCsvText}>
                <a
                  href={`${sampleFile}?version=${Math.floor(
                    Math.random() * 10,
                  )}`}
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  download-sample.xlsx
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Dialog
        open={preview}
        maxWidth={'lg'}
        className={classes.previewModal}
        title="Preview Upload File"
        handleClose={() => {
          setPreview(false);
        }}
      >
        {noMapColumns?.length > 0 && (
          <Box className={classes.errorMessageSmall}>
            Please review the column headers underlined in red and assign the
            correct column names.
          </Box>
        )}

        <Box className={classes.previewBox}>
          {!isEmpty(tableData) && (
            <TableContainer className={classes.previewTable}>
              <Table stickyHeader aria-label="sticky table">
                <colgroup>
                  {tableData[0].map((column: any, index: number) => (
                    <col
                      key={`colgroup_${index}`}
                      style={index === 0 ? { width: 50, minWidth: 50 } : {}}
                    />
                  ))}
                </colgroup>
                <TableHead>
                  {showGroupHeader && (
                    <StyledTableRow
                      style={{
                        top: 0,
                      }}
                      className={classes.tableHeaderStickyRow}
                    >
                      <StyledTableCell colSpan={get(spanData, 'kpiDetailSpan')}>
                        KPI Details - Dollar amounts
                      </StyledTableCell>
                      <StyledTableCell colSpan={get(spanData, 'growthSpan')}>
                        KPI Details - Monthly growth rates (CMGR, past 6-12
                        months)
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={get(spanData, 'fundraisingSpan')}
                      >
                        Fundraising and valuation data - enter amounts in
                        Million Dollars
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                  <StyledTableRow
                    style={{
                      top: showGroupHeader ? 54 : 0,
                    }}
                    className={classes.tableHeaderStickyRow}
                  >
                    {tableData[0].map((row: any, index: any) => (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={`col_${index}`}
                        style={
                          index === 0
                            ? {
                                left: 0,
                              }
                            : {}
                        }
                        className={
                          [0].includes(index)
                            ? classes.tableHeaderStickyCell
                            : ''
                        }
                      >
                        {columnMapping?.length > 0 ? (
                          <FormControl
                            error={
                              !columnMapping.includes(selectedColumns[index])
                            }
                          >
                            <Select
                              label="''"
                              onChange={({ target: { value } }) => {
                                const selectedColumnsTemp = Object.assign(
                                  [],
                                  selectedColumns,
                                );
                                selectedColumnsTemp[index] = value;
                                setSelectedColumns(selectedColumnsTemp);
                              }}
                              value={selectedColumns[index]}
                            >
                              {!columnMapping.includes(row) && (
                                <MenuItem value={row} disabled>
                                  {row}
                                </MenuItem>
                              )}
                              {columnMapping?.map((item: any) => {
                                return (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        ) : (
                          { row }
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {tableData.slice(1).map((row: any, index: any) => (
                    <StyledTableRow key={index}>
                      {row.map((item: any, rowIndex: number) => (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          key={`row_${rowIndex}`}
                          align="center"
                          style={
                            rowIndex === 0
                              ? {
                                  left: 0,
                                }
                              : {}
                          }
                          className={
                            [0].includes(rowIndex)
                              ? classes.tableBodyStickyCell
                              : ''
                          }
                        >
                          {get(row, rowIndex)}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Box className={classes.fixedBtns}>
          {errorMessage && (
            <>
              <Typography variant="body1" className={classes.errorMessage}>
                {errorMessage}
              </Typography>
              {!isEmpty(errorData) &&
                errorData.map((error: string, index: number) => (
                  <Typography
                    variant="body1"
                    className={classes.errorMessage}
                    key={index}
                  >
                    {error}
                  </Typography>
                ))}
            </>
          )}
          <Grid xs={4} item style={{ justifyContent: 'center' }}>
            <Button
              name="Upload"
              className={classes.uploadBtn}
              onClick={() => handleUploadStartups()}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Grid>
        </Box>
      </Dialog>
      <Dialog
        open={successModal}
        maxWidth={'sm'}
        title="Saved Successfully"
        buttonText="Continue"
        handleClose={handleCloseDialog}
      >
        <Box>
          <SuccessPage img={successImage} className={classes.SuccessImg}>
            <Typography variant="h4" gutterBottom className={classes.pointText}>
              <Button
                className={classes.savedSuccessfullyButton}
                name="OK"
                onClick={handleCloseDialog}
              />
            </Typography>
          </SuccessPage>
        </Box>
      </Dialog>
    </div>
  );
};

export default CsvUploadFcrm;
