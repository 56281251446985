import React, { useEffect, useState } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, get } from 'lodash';

import { createUpdateClient } from 'services';
import { FN } from 'common/types';
import { errorMessageHandler } from 'common/utils/helpers';

import { Button, Box, WrappedTextInput, Banner } from '../../../components';
import { ClientFormSchema } from './validations';
import styles from './styles';

type Props = {
  handleBack: FN;
  client?: Record<string, any> | null;
  clientId: any;
  getUserList: FN;
  getAccessKey: FN;
};

const AddClient = ({ client, getUserList, getAccessKey, clientId }: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successPop, setSuccessPop] = useState<boolean>(false);
  const [values, setValues] = React.useState(false);

  const { handleSubmit, control, reset, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ClientFormSchema),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      accessKey: '',
    },
  });

  const saveClient = async (payload: any) => {
    try {
      setIsLoading(true);
      await createUpdateClient(payload);
      if (!isEmpty(client)) {
        setSuccessMessage('Client updated Successfully');
      } else {
        setSuccessMessage('Client added Successfully');
      }
      setIsLoading(false);
    } catch (err) {
      const message: string = errorMessageHandler(err) || get(err, 'message');
      setIsLoading(false);
      setErrorMessage(message);
    }
  };

  const onSubmit = () => {
    const payload = {
      ...getValues(),
      id: !isEmpty(client) ? get(client, 'id') : undefined,
    };
    saveClient(payload);
  };

  const handleClickShowPassword = () => {
    setValues(!values);
  };

  useEffect(() => {
    if (!isEmpty(successMessage)) {
      getUserList();
      setSuccessPop(true);
    }
  }, [successMessage]);

  useEffect(() => {
    if (!isEmpty(client)) {
      reset({
        name: get(client, 'name'),
        email: get(client, 'email'),
        phone: get(client, 'phone'),
        accessKey: get(client, 'accessKey'),
      });
    }
  }, [client]);

  useEffect(() => {
    getAccessKey();
  }, [client]);

  return (
    <Box>
      {successPop ? (
        <Banner title={successMessage} subTitle={''} source={'SuccessBanner'} />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="addUserForm"
          className={classes.validateForm}
        >
          <Grid container spacing={3} className={classes.inputFormGroup}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label="Client Name"
                      placeholder="Enter the full Name"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label="Email Id"
                      placeholder="eg. test@gmail.com"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      // disabled={user?.id ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label="Phone"
                      placeholder="90900000000"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            {!isEmpty(client) && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="accessKey"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label="Access Key"
                        className={classes.textValInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                          endAdornment: (
                            <IconButton onClick={handleClickShowPassword}>
                              {values ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                        InputLabelProps={{
                          classes: {},
                        }}
                        value={
                          !values
                            ? '...................................'
                            : clientId.data
                        }
                        onChange={onChange}
                        disabled={true}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Box className={classes.backContinueBox}>
            <Button
              name="Save"
              type="submit"
              className={classes.continueBtn}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Box>
          {errorMessage && (
            <Typography variant="caption" className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
        </form>
      )}
    </Box>
  );
};
export default AddClient;
