import {
  init,
  track,
  identify,
  Identify,
  reset,
} from '@amplitude/analytics-browser';
import { isEmpty } from 'lodash';

const logAmpEvent = (
  eventName: string,
  userProperties: Record<string, any> = {},
  eventProperties: Record<string, any> = {},
  userId?: string,
) => {
  const key: string = process?.env?.REACT_APP_AMPLITUDE_KEY || '';
  const env: string = process?.env?.REACT_APP_ENV || '';
  init(key, userId);
  if (!isEmpty(userProperties)) {
    console.log(userProperties);//eslint-disable-line
    const identifyObj = new Identify();
    (Object.keys(userProperties) || []).forEach((key: string) => {
        console.log(key, userProperties[key]) //eslint-disable-line
      identifyObj.set(key, userProperties[key]);
    });
    identify(identifyObj);
  }
  track(`${env}-${eventName}`, eventProperties);
};

const resetAmp = () => reset();
export { logAmpEvent, resetAmp };
