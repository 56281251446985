import React, { useEffect } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty, find } from 'lodash';
import { useSelector } from 'react-redux';

import { InvestorOnboardStepProps } from 'common/types';
import {
  adminInvestorAddDocument,
  createAdminInvestmentDetail,
  adminInvestorDocumentSignedUrl,
} from 'services';
import { Box, WrappedTextInput, Button, FileUpload } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { RootState } from 'redux-modules/store/rootState';

import styles from './styles';
import {
  WireInstructionSchema,
  WireInstructionSchemaNotRequired,
} from '../../validations';

const WireInstruction = ({
  handleNext,
  handleBack,
  spv,
  investor,
  kyc,
  activeStep,
  syncInvestor,
  investmentProcess,
  investmentDetail,
  documents,
}: InvestorOnboardStepProps) => {
  const classes = styles();

  const { dealList } = useSelector(({ Deals }: RootState) => Deals);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [fileuploadReq, setFileuploadReq] = React.useState<any>('');
  const [loadingSignedUrl, setLoadingSignedUrl] = React.useState<boolean>(
    false,
  );
  const [copiedTexts, setCopiedText] = React.useState<any>({});

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      isEmpty(fileuploadReq) && !get(investmentProcess, 'wireReceiptUploaded')
        ? WireInstructionSchema
        : WireInstructionSchemaNotRequired,
    ),
  });

  const onSubmit = async (data: any): Promise<void> => {
    if (!isEmpty(fileuploadReq)) {
      setIsLoading(true);
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await adminInvestorAddDocument({
          filename: fileuploadReq.filename,
          investmentDetailId: get(investmentDetail, 'id'),
          investorId: get(investor, 'id'),
          documentName: 'WIRE_RECEIPT',
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
        });
        await syncInvestor();
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    }
    const payload = {
      ...data,
      investmentCurrentStep: 'WIRE_INSTRUCTION',
      spv: spv,
      investorId: get(investor, 'id'),
      kycId: get(kyc, 'id'),
    };
    if (data.transactionId) {
      if (get(investmentDetail, 'id')) payload.id = get(investmentDetail, 'id');
      setIsLoading(true);
      try {
        await createAdminInvestmentDetail(payload);
        await syncInvestor();
        handleNext(activeStep + 1);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      !isEmpty(errorMessage) && handleNext(activeStep + 1);
    }
  };

  useEffect(() => {
    if (get(investmentDetail, 'id')) {
      setValue(
        'transactionId',
        get(investmentDetail, 'transactionId')
          ? get(investmentDetail, 'transactionId')
          : '',
      );
    }
  }, [investmentDetail]);

  const uploadedFiles = () => {
    if (isEmpty(documents)) return [];
    if (!isEmpty(documents)) {
      return documents.filter(
        (doc: any) => doc.documentName === 'WIRE_RECEIPT',
      );
    }
  };

  const copyToClipboard = (str: string, id = 0) => {
    // eslint-disable-next-line no-undef
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      // eslint-disable-next-line no-undef
      const cTexts = copiedTexts;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cTexts[id] = true;
      setCopiedText({ ...cTexts });
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cTexts[id] = false;
        setCopiedText({ ...cTexts });
      }, 500);
      // eslint-disable-next-line no-undef
      return navigator.clipboard.writeText(str);
    }

    return Promise.reject('The Clipboard API is not available.');
  };

  return (
    <Box>
      <Typography variant="h3" className={classes.investNowFormHeader}>
        Fund Transfer:
      </Typography>
      <Typography className={classes.wSubText}>
        Please wire the entire investment amount committed to{' '}
        {get(find(dealList, { id: spv }), 'deal')} SPV of 8vdX LLC to the
        following bank accounts.
      </Typography>
      <Box className={classes.wireInstructionSubBox}>
        <Typography className={classes.wireInstructionText}>
          Wire instructions:
        </Typography>
        <Typography className={classes.wHText}>
          8vdx, LLC Domestic Transfer Details
        </Typography>
        <Typography className={classes.wSSText}>
          Use these details to send both domestic wires and ACH transfers to
          8vdx, LLC’s Mercury account.
        </Typography>
        <Typography className={classes.wBenText}>Beneficiary</Typography>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>Beneficiary Name</Typography>
          <Typography variant="caption" className={classes.wBHText}>
            8vdx, LLC
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() => copyToClipboard('8vdx, LLC', 1)}
              className={classes.copyButton}
            >
              {!get(copiedTexts, '1') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '1') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>Type of Account</Typography>
          <Typography variant="caption" className={classes.wBHText}>
            Checking
          </Typography>
        </Box>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>
            Beneficiary Address
          </Typography>
          <Typography variant="caption" className={classes.wBHText}>
            30 Oakwood Avenue, 1st Floor Norwalk, CT 06850
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() =>
                copyToClipboard(
                  '30 Oakwood Avenue, 1st Floor Norwalk, CT 06850',
                  2,
                )
              }
              className={classes.copyButton}
            >
              {!get(copiedTexts, '2') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '2') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Typography className={classes.wBenText}>
          Receiving Bank Details
        </Typography>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>
            ABA Routing Number
          </Typography>
          <Typography variant="caption" className={classes.wBHText}>
            084106768
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() => copyToClipboard('084106768', 3)}
              className={classes.copyButton}
            >
              {!get(copiedTexts, '3') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '3') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>Bank Name</Typography>
          <Typography variant="caption" className={classes.wBHText}>
            Evolve Bank & Trust
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() => copyToClipboard('Evolve Bank & Trust', 4)}
              className={classes.copyButton}
            >
              {!get(copiedTexts, '4') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '4') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>Bank Address</Typography>
          <Typography variant="caption" className={classes.wBHText}>
            6070 Poplar Ave, Suite 200 Memphis, TN 38119
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() =>
                copyToClipboard(
                  '6070 Poplar Ave, Suite 200 Memphis, TN 38119',
                  5,
                )
              }
              className={classes.copyButton}
            >
              {!get(copiedTexts, '5') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '5') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Typography className={classes.borderBottom}></Typography>
        <Typography className={classes.wireDetails}>
          8vdx, LLC International Wire Details
        </Typography>
        <Box className={classes.impNoteBox}>
          <Typography className={classes.noteText1}>
            <b>Important.</b> To successfully send a wire:
          </Typography>
          <Typography className={classes.noteText1}>
            <b style={{ fontSize: 25, lineHeight: '8px' }}>. </b>use Evolve Bank
            & Trust details in the beneficiary (recipient) field
          </Typography>
          <Typography className={classes.noteText1}>
            <b style={{ fontSize: 25, lineHeight: '8px' }}>. </b>use 8vdx, LLC’s
            details in the reference field
          </Typography>
        </Box>
        <Typography className={classes.fieldText}>
          If you are filling out a wire form, please reference the section
          labels with MT103 field numbers in grey
        </Typography>
        <Typography className={classes.recevBankTxt}>
          Receiving bank
          <Typography variant="caption" className={classes.recevSubBTxt}>
            57D Account with institution
          </Typography>
        </Typography>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>SWIFT / BIC Code </Typography>
          <Typography variant="caption" className={classes.wBHText}>
            FRNAUS44XXX
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() => copyToClipboard('FRNAUS44XXX', 6)}
              className={classes.copyButton}
            >
              {!get(copiedTexts, '6') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '6') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>Bank Name</Typography>
          <Typography variant="caption" className={classes.wBHText}>
            First National Bankers Bank
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() => copyToClipboard('First National Bankers Bank', 7)}
              className={classes.copyButton}
            >
              {!get(copiedTexts, '7') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '7') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>
            Beneficiary Address
          </Typography>
          <Typography variant="caption" className={classes.wBHText}>
            7813 Office Park Blvd Baton Rouge, LA, 70809, USA
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() =>
                copyToClipboard(
                  '7813 Office Park Blvd Baton Rouge, LA, 70809, USA',
                  8,
                )
              }
              className={classes.copyButton}
            >
              {!get(copiedTexts, '8') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '8') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Typography className={classes.recevBankTxt}>
          Beneficiary
          <Typography variant="caption" className={classes.recevSubBTxt}>
            59 Beneficiary customer name & address
          </Typography>
        </Typography>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>
            IBAN / Account Number
          </Typography>
          <Typography variant="caption" className={classes.wBHText}>
            084106768
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() => copyToClipboard('084106768', 9)}
              className={classes.copyButton}
            >
              {!get(copiedTexts, '9') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '9') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>Beneficiary Name</Typography>
          <Typography variant="caption" className={classes.wBHText}>
            Evolve Bank & Trust
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() => copyToClipboard('Evolve Bank & Trust', 10)}
              className={classes.copyButton}
            >
              {!get(copiedTexts, '10') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '10') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Box className={classes.beneficiaryBox}>
          <Typography className={classes.wBSText}>
            Beneficiary Address
          </Typography>
          <Typography variant="caption" className={classes.wBHText}>
            6070 Poplar Ave, Suite 200 Memphis, TN 38119, USA
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() =>
                copyToClipboard(
                  '6070 Poplar Ave, Suite 200 Memphis, TN 38119, USA',
                  11,
                )
              }
              className={classes.copyButton}
            >
              {!get(copiedTexts, '11') && (
                <FileCopyOutlinedIcon
                  fontSize="small"
                  className={classes.fileCopyIcon}
                />
              )}
              {get(copiedTexts, '11') && (
                <span className={classes.copied}>copied</span>
              )}
            </IconButton>
          </Typography>
        </Box>
        <Typography className={classes.recevBankTxt}>
          Reference field
          <Typography variant="caption" className={classes.recevSubBTxt}>
            70 Remittance information
          </Typography>
        </Typography>
        <Typography className={classes.accountNumberText}>
          Account 9801497010 for 8vdx, LLC at Evolve Bank & Trust
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="wireInstructionForm"
        // className={classes.validateForm}
      >
        <Box className={classes.TrxnsIdBx}>
          {/* <Button
            name="Upload the Wire Receipt"
            className={classes.receiptBtn}
          /> */}
          <FileUpload
            getSignedUrl={adminInvestorDocumentSignedUrl}
            uploadedFiles={uploadedFiles()}
            setLoadingSignedUrl={setLoadingSignedUrl}
            disabled={get(investmentProcess, 'wireReceiptUploaded')}
            requestObject={{
              documentName: 'WIRE_RECEIPT',
              investorId: get(investmentDetail, 'investorId'),
              investmentDetailId: get(investmentDetail, 'id'),
            }}
            onSuccess={(req: any) => setFileuploadReq(req)}
            content={
              !get(investmentProcess, 'wireReceiptUploaded') && (
                <Button
                  name="Upload the Wire Receipt"
                  className={classes.receiptBtn}
                  isLoading={loadingSignedUrl}
                />
              )
            }
          />
          <Typography className={classes.orText}>Or</Typography>
          <Box className={classes.TrxnBox}>
            <Typography className={classes.labelText} variant="body1">
              <b>Enter Transaction ID</b>
            </Typography>
            <Controller
              name="transactionId"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Transaction Id"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                  {errorMessage && (
                    <span className={classes.errorText}>{errorMessage}</span>
                  )}
                </Box>
              )}
            />
          </Box>
        </Box>
        <Box className={classes.nextPrevButtonBox}>
          <Button
            variant="outlined"
            className={classes.prevButton}
            name="Previous"
            disabled={loadingSignedUrl}
            onClick={() => handleBack()}
          />
          <Button
            type="submit"
            className={classes.nextButton}
            name="Next"
            disabled={loadingSignedUrl}
            isLoading={isLoading}
          />
        </Box>
      </form>
    </Box>
  );
};
export default WireInstruction;
