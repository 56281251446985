import { IRoute } from 'common/types';
import SimpleLayout from 'layouts/SimpleLayout';

import Navigation from './Navigation';

export const NavigationRoute: IRoute = {
  path: '/startup/navigate',
  component: Navigation,
  layout: SimpleLayout,
  isAdmin: false,
  exact: true,
};
