import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  welcomeBoxHead: {
    fontWeight: 400,
    position: 'relative',
    padding: '78px 63px 20px',
    '@media (max-width: 1024px)': {
      padding: '110px 40px 20px',
    },
    '@media (max-width: 767px)': {
      padding: '50px 20px 15px',
    },
  },
  enquiryImage: {
    position: 'absolute',
    top: 40,
    left: 35,
    height: 90,
    width: 90,
    '@media (max-width: 1120px)': {
      width: 60,
      height: 60,
    },
    '@media (max-width: 1024px)': {
      top: 83,
      left: 20,
      objectFit: 'cover',
    },
    '@media (max-width: 767px)': {
      top: 23,
      left: 20,
      objectFit: 'cover',
    },
  },
  welcomeBoxBody: {
    maxWidth: 600,
    margin: 'auto',
    textAlign: 'center',
    paddingTop: 50,
  },
  welcomeHeadtext: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    marginBottom: 0,
    position: 'relative',
    top: -20,
    '@media (max-width: 1024px)': {
      fontSize: 24,
    },
  },
  welcomeHeadSubtext: {
    fontSize: 16,
    color: theme.palette.primary.main,
    lineHeight: 1.4,
  },
  welcomeImgImage: {
    marginBottom: 25,
  },
  welcomeHeadertext: {
    fontSize: 20,
    color: theme.palette.grey[500],
  },
  welcomeHeaderSubtext: {
    fontSize: 15,
    maxWidth: 400,
    margin: 'auto',
    color: theme.palette.grey[50],
  },
}));
