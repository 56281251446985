import { IRoute } from 'common/types';
import SimpleLayout from 'layouts/SimpleLayout';

import EnquiryForm from './EnquiryForm';

export const EnquiryFormRoute: IRoute = {
  path: '/startup/enquiry-form/:type',
  component: EnquiryForm,
  layout: SimpleLayout,
};
