import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  dashContainer: {
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    background: '#F6FAFA',
  },
  dashleft: {
    width: 310,
    flex: '0 auto',
    '@media (max-width: 1120px)': {
      width: 140,
      padding: 30,
    },
  },
  sidebarFixed: {
    padding: 40,
    height: '75%',
    width: '100%',
  },
  dashLogo: {
    display: 'flex',
    width: '100%',
    marginBottom: 70,
  },
  logoImg: {
    width: 100,
    height: 'auto',
  },
  dashRight: {
    width: 'calc(100% - 310px)',
    flexGrow: 1,
    padding: '55px 170px 35px',
    background: theme.palette.common.white,
    position: 'relative',
    '@media (max-width: 1120px)': {
      width: 'calc(100% - 200px)',
      padding: '40px 0px 30px 40px',
    },
  },
  dashHeader: {
    position: 'absolute',
    top: 15,
    right: 30,
    display: 'flex',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  spvSelect: {
    marginRight: 25,
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      background: 'transparent',
    },
  },
  investNowTextBox: {
    background: '#D2E4FF',
    borderRadius: 5,
    padding: 15,
    marginRight: 25,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '0.3px',
    zIndex: 9,
  },
  skeletonWrapper: {
    width: '80%',
    margin: 'auto',
    marginRight: '40px',
    marginTop: '100px',
  },
  // Spv Select ---------- Start
  selectValInput: {
    width: 100,
    margin: 0,
  },
  inputRoot: {},
  labelRoot: {},
  labelFocused: {},
}));
