import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  // KPI Data Table
  kpiDataTable: {},
  tableContainer: {
    borderRadius: '10px 10px 0 0',
    maxHeight: 440,
    '&.MuiTableContainer-root': {
      overflowX: 'scroll',
    },
  },
  tableHeaderStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
    '@media (max-width: 525px)': {
      position: 'unset',
      zIndex: 'auto',
      left: 'auto !important',
      right: 'auto !important',
    },
  },
  tableBodyStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.common.white,
    '@media (max-width: 525px)': {
      // position: 'static',
      position: 'unset',
      zIndex: 'auto',
      left: 'auto !important',
      right: 'auto !important',
    },
  },
  nextPrevHeaderCell: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
    width: 32,
  },
  nextPrevIconButton: {
    padding: 5,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  kpiNameInfoCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButton: {
    padding: 5,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tableHeader: {
    backgroundColor: '#93C9C7 !important',
    '& .MuiTableCell-stickyHeader': {
      '@media (max-width: 525px)': {
        left: 'auto !important',
      },
    },
  },
  actionEditDeleteCell: {
    display: 'flex',
    alignItems: 'center',
  },
  textValInput: {
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000de',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  menuPaper: {
    maxHeight: 150,
  },
  ellipsis: {
    maxWidth: 150, // percentage also works
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  // Delete Dialog
  deleteText: {
    fontSize: 16,
  },
  backSubmitBox: {
    paddingTop: 10,
    paddingRight: 10,
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  submitBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  errorMessage: {
    fontSize: 16,
    color: theme.palette.error.main,
  },
}));
