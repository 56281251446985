import * as yup from 'yup';

import {
  requiredConstant,
  emailRegex,
  emailValidConstant,
  urlRegex,
  urlValidConstant,
} from 'common/utils/constants';
import { phoneNumberYup } from 'common/utils/validations';
import { getParsedPriceInputValue } from 'common/utils/helpers';

export const CompanyDetailsSchema = yup.object().shape({
  founderDetails: yup.array().of(
    yup.object().shape({
      founderName: yup.string().required(requiredConstant),
      founderEmail: yup
        .string()
        .email(emailValidConstant)
        .required(requiredConstant)
        .matches(emailRegex, emailValidConstant),
    }),
  ),
  // founderName: yup.string().required(requiredConstant),
  // founderEmail: yup.string().email().required(requiredConstant),
  startupWebLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => value.length > 0,
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  businessOpsLocation: yup.object().nullable(),
  startupTwitterUrl: yup.string().nullable(),
  startupFacebookUrl: yup.string().nullable(),
  startupLinkedInUrl: yup.string().nullable(),
  productDescription: yup.string(),
  productProgress: yup.string(),
  potentialMarketTarget: yup.string(),
  startupInvestmentInformation: yup.string(),
  startupLegalInformation: yup.string(),
});

export const BusinessDetailsSchema = yup.object().shape({
  productProgress: yup.string().required(requiredConstant),
  potentialMarketTarget: yup.string().required(requiredConstant),
  competitorsInformation: yup.string().required(requiredConstant),
  startupIncorporationInfo: yup.string().required(requiredConstant),
  startupEquityInformation: yup.string().required(requiredConstant),
  startupStructureInformation: yup.string().required(requiredConstant),
});

export const OtherInformationSchema = yup.object().shape({
  startup8vdxSource: yup.string().required(requiredConstant),
  startupReasonForChosing: yup.string().required(requiredConstant),
  startupInvestmentInformation: yup.string().required(requiredConstant),
  startupLegalInformation: yup.string().required(requiredConstant),
});

export const FounderDetailsSchema = yup.object().shape({
  founders: yup.array().of(
    yup.object().shape({
      founderName: yup.string().required(requiredConstant),
      founderEmail: yup
        .string()
        .email()
        .required(requiredConstant)
        .matches(emailRegex, emailValidConstant),
      founderPhoneNumber: phoneNumberYup,
      founderQualifications: yup.string().required(requiredConstant),
      founderLinkedinUrl: yup
        .string()
        .required(requiredConstant)
        .matches(urlRegex, urlValidConstant),
      founderRole: yup.string().required(requiredConstant),
      founderBio: yup.string().required(requiredConstant),
    }),
  ),
});

export const ApplyBridgeDebtSchema = yup.object().shape({
  // startupYcAssignedId: yup.string().required(requiredConstant),
  startupFullName: yup.string().required(requiredConstant),
  assignedYcPartnerName: yup.string().required(requiredConstant).nullable(),
  assignedYcLegalPocName: yup.string().required(requiredConstant).nullable(),
  ycIncorporationCountry: yup.object().nullable().required(requiredConstant),
  stateOfIncorporation: yup
    .object()
    .nullable()
    .when('ycIncorporationCountry.countryCode', {
      is: 'PENDING_INCORPORATION',
      then: yup.object().nullable(),
      otherwise: yup.object().nullable().required(requiredConstant),
    }),
  startupTaxIdNumber: yup.string().required(requiredConstant),
  industryType: yup.object().nullable().required(requiredConstant),
  industryClassification: yup.object().nullable().required(requiredConstant),
  enquiryTermsAccepted: yup.boolean().oneOf([true]).required(),
});

export const QuerySchema = yup.object().shape({
  title: yup.string().required(requiredConstant),
  message: yup.string().required(requiredConstant),
});

export const LoanTermsSchema = yup.object().shape({
  loanType: yup.string().nullable(),
  loanAmount: yup
    .string()
    .required(requiredConstant)
    .when('loanType', {
      is: 'BRIDGE_TO_YC_FUND',
      then: yup
        .string()
        .nullable(true)
        .transform((v, o) => (o === '' ? null : v))
        .test(
          'min',
          'Please enter amount: Min 100K USD to Max 500K USD',
          (value) =>
            value !== undefined &&
            value !== null &&
            getParsedPriceInputValue(value) >= 100000 &&
            getParsedPriceInputValue(value) <= 500000,
        ),
      otherwise: yup
        .string()
        .nullable(true)
        .transform((v, o) => (o === '' ? null : v))
        .test(
          'min',
          'Please enter amount: Min 50K USD to Max 250K USD',
          (value) =>
            value !== undefined &&
            value !== null &&
            getParsedPriceInputValue(value) >= 50000 &&
            getParsedPriceInputValue(value) <= 250000,
        ),
    }),
  loanTenure: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .positive('Value must be greater than or equal to 0')
    .min(0, 'Value must be greater than or equal to 0')
    .required(requiredConstant),
  percentageBonusSafe: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .positive('Value must be greater than or equal to 0')
    .min(0, 'Value must be greater than or equal to 0')
    .required(requiredConstant),
  safeCover: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant)
    .test(
      'positive',
      'Value must be greater than or equal to 0',
      (value) =>
        value !== undefined &&
        value !== null &&
        getParsedPriceInputValue(value) >= 0,
    ),
  cashInterestRate: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .positive('Value must be greater than or equal to 0')
    .min(0, 'Value must be greater than or equal to 0')
    .required(requiredConstant),
  processingFees: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .positive('Value must be greater than or equal to 0')
    .min(0, 'Value must be greater than or equal to 0')
    .required(requiredConstant),
  valuation: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .test(
      'positive',
      'Value must be greater than or equal to 0',
      (value) =>
        value !== undefined &&
        value !== null &&
        getParsedPriceInputValue(value) >= 0,
    ),
});
