import React, { useEffect, useState } from 'react';
import {
  Typography,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
} from '@material-ui/core';
import * as yup from 'yup';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { isEmpty, get, filter } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {
  Accordion,
  Box,
  Button,
  Dialog,
  Loader,
  WrappedTextInput,
} from 'components';
import { documentIcon, pdfIcon } from 'assets';
import {
  approveInvestorDocuments,
  getAdminInvestorById,
  adminApproveInvestment,
  getAdminSubscriberSSN,
} from 'services';
import { requiredConstant } from 'common/utils/constants';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';

type ComponentProps = {
  investor: any;
  spv?: string;
  kycDetail?: any;
  investmentData?: any;
  setInvestmentData?: any;
  documents?: any;
  setDocumentList: any;
};

const InvestorProcess = ({
  investor,
  spv,
  investmentData,
  setInvestmentData,
  documents,
  setDocumentList,
}: ComponentProps) => {
  const classes = styles();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [docValidateLoading, setDocValidateLoading] = useState<boolean>(false);
  const [isOpenDocument, setOpenDocument] = useState<boolean>(false);
  const [selectedDocId, setSelectedDocId] = useState('');
  const [url, setUrl] = useState<string>('');
  const [document, setDocument] = useState<any>('');
  const [investmentProcess, setInvestmentProcess] = useState<any>(
    investmentData?.investmentProcess,
  );
  const [showSSN, setShowSSN] = React.useState<boolean>(false);
  const [subscriberSSN, setSubscriberSSN] = React.useState<string>('');

  const allDocumentsApproved: boolean = !isEmpty(documents)
    ? filter(documents, { documentStatus: 'VERIFIED' }).length ===
      documents.length
    : false;

  documents = (documents || []).filter(
    (doc: any) => doc.documentName !== 'NAV_REPORT',
  );
  const { handleSubmit, control, getValues, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        transactionId: yup.string().nullable().required(requiredConstant),
      }),
    ),
    defaultValues: {
      transactionId: get(investmentData, 'transactionId', ''),
      sendEmail: get(investmentData, 'sendEmail', true),
    },
  });

  const handleChange = (panel: string) => (
    // eslint-disable-next-line @typescript-eslint/ban-types
    _event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickShowSSN = () => {
    if (!showSSN && !subscriberSSN) {
      getAdminSubscriberSSN(get(investmentData, 'id'))
        .then((res: any) => {
          setSubscriberSSN(res);
          setShowSSN(!showSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.log('ERROR WHILE FETCHING SSN: ', message);
        });
    } else {
      setShowSSN(!showSSN);
    }
  };

  const handleMouseDownSSN = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleGetName = (value: string) => {
    switch (value) {
      case 'REVIEW_DOCUMENTS':
        return allDocumentsApproved ||
          get(investmentProcess, 'documentsVerified')
          ? 'Documents verified'
          : 'Yet to verify documents';
      case 'APPROVE_INVESTMENT':
        return get(investmentProcess, 'investmentApproved')
          ? 'Approved'
          : 'Yet to approve investment';
      default:
        break;
    }
  };

  const handleGetApproveInvestmentSubHeading = () => {
    if (get(investmentProcess, 'documentsVerified')) {
      if (get(investmentProcess, 'investmentApproved')) {
        return 'Investment is approved. Click on View to get the details.';
      } else {
        return 'Review the details to Approve investment';
      }
    } else {
      return isEmpty(documents)
        ? 'No documents available to approve'
        : 'Verify the documents to approve investment';
    }
  };

  const handleRenderFile = (ext: string, doc: any) => {
    switch (ext) {
      case 'img':
        return (
          <img
            src={doc.documentUrl}
            alt={doc.filename}
            className={classes.bGDocuImg}
            onClick={() => {
              setOpenDocument(true);
              setUrl(doc.documentUrl);
              setDocument(doc);
            }}
          />
        );
      case 'doc':
        return (
          <a href={doc.documentUrl} target="_blank" rel="noreferrer">
            <img
              src={documentIcon}
              alt={documentIcon}
              className={classes.bGDocuImg}
            />
          </a>
        );
      case 'pdf':
        return (
          <a href={doc.documentUrl} target="_blank" rel="noreferrer">
            <img src={pdfIcon} alt={pdfIcon} className={classes.bGDocuImg} />
          </a>
        );
      default:
        return (
          <a href={doc.documentUrl} target="_blank" rel="noreferrer">
            <img
              src={documentIcon}
              alt={documentIcon}
              className={classes.bGDocuImg}
            />
          </a>
        );
    }
  };

  const handleFileType = (doc: any) => {
    const filename = doc.filename;
    let ext = filename && filename.substr(filename.lastIndexOf('.') + 1);
    if (['png', 'jpg', 'jpeg'].includes(ext)) {
      ext = 'img';
    }
    if (['doc', 'docx'].includes(ext)) {
      ext = 'doc';
    }
    return handleRenderFile(ext, doc);
  };

  const callback = (response: any) => {
    const modifiedDocuments =
      documents &&
      documents.length > 0 &&
      documents.map((document: any) => {
        if (response && response.length > 0) {
          const selectedDoc = response.filter((item: any) => {
            if (item.id === document.id) {
              const updatedDoc = JSON.parse(JSON.stringify(item));
              updatedDoc.documentUrl = document.documentUrl;

              return updatedDoc;
            }
          });
          if (selectedDoc && selectedDoc.length) {
            return selectedDoc[0];
          }

          return document;
        }

        return document;
      });

    return modifiedDocuments;
  };

  const handleApproveDocument = (id: string, isDialogOpen = false) => {
    const payload = {
      documents: [{ id }],
      investorId: get(investor, 'id'),
      investmentDetailId: get(investmentData, 'id'),
    };
    setSelectedDocId(id);
    setIsLoading(true);
    approveInvestorDocuments(payload)
      .then((res: any) => {
        setIsLoading(false);
        setSelectedDocId('');
        isDialogOpen && setOpenDocument(false);
        setDocumentList(callback(res));
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setIsLoading(false);
      });
  };

  const validateDocuments = async () => {
    setDocValidateLoading(true);
    if (!isEmpty(get(investor, 'id'))) {
      try {
        setDocValidateLoading(true);
        const res: any = await getAdminInvestorById(get(investor, 'id'));
        const { investmentDetail } = res;
        const spvInvestmentDetail = investmentDetail?.find(
          (investment: any) => investment.spv === spv,
        );
        if (spvInvestmentDetail) {
          setInvestmentData(spvInvestmentDetail);
          setInvestmentProcess(get(spvInvestmentDetail, 'investmentProcess'));
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setDocValidateLoading(false);
      }
    }
  };

  const approveInvestment = async (payload: any) => {
    if (!isEmpty(get(investor, 'id'))) {
      try {
        setIsLoading(true);
        const res: any = await adminApproveInvestment({
          investmentProcessId: get(investmentProcess, 'id'),
          investorId: get(investor, 'id'),
          ...payload,
        });
        const spvInvestmentDetail = {
          ...investmentData,
          investmentProcess: res,
        };
        setInvestmentData(spvInvestmentDetail);
        setInvestmentProcess(res);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onSubmit = () => {
    const payload = {
      ...getValues(),
    };
    approveInvestment(payload);
  };

  useEffect(() => {
    if (!isEmpty(get(investmentData, 'transactionId'))) {
      setValue('transactionId', get(investmentData, 'transactionId'));
      setValue('sendEmail', get(investmentData, 'sendEmail'));
    }
  }, [investmentData]);

  return (
    <Box className={classes.tabPanelContent}>
      <Accordion
        subheading={'Review Documents'}
        expanded={expanded === 'REVIEW_DOCUMENTS'}
        handleChange={(e: string) => handleChange(e)}
        index={'REVIEW_DOCUMENTS'}
        buttons={
          <Box className={classes.summaryAccordRight}>
            <Button
              name={handleGetName('REVIEW_DOCUMENTS')}
              className={classes.statusBtn}
            />
            <Button
              name="Validate"
              className={classes.accordSendBtn}
              onClick={() => validateDocuments()}
              isLoading={docValidateLoading}
              disabled={
                !allDocumentsApproved ||
                get(investmentProcess, 'documentsVerified')
              }
            />
            <MoreHorizIcon className={classes.moreIcon} />
          </Box>
        }
      >
        <Box className={classes.docuComponent}>
          {isLoading && isEmpty(documents) && (
            <Box className={classes.loader}>
              <CircularProgress color="inherit" size={40} />
            </Box>
          )}
          {isLoading && !isEmpty(documents) && (
            <Box className={classes.loaderSection}>
              <Loader className={classes.loaderBox} />
            </Box>
          )}
          {!isEmpty(documents) ? (
            <>
              <Box className={classes.documentListComponent}>
                {documents.map((doc: any) => (
                  <Box key={doc.id} className={classes.reviewDocuBox}>
                    {handleFileType(doc)}
                    {doc.documentType === 'DOCU_SIGN_DOCUMENTS' ? (
                      <Typography className={classes.reviewDocuText}>
                        {doc.filename}
                      </Typography>
                    ) : (
                      <Typography className={classes.reviewDocuText}>
                        {doc.documentName}
                      </Typography>
                    )}
                    {doc.documentStatus !== 'VERIFIED' ? (
                      <Button
                        name="Approve"
                        className={classes.approveBtn}
                        onClick={() => handleApproveDocument(doc.id)}
                        isLoading={isLoading && selectedDocId === doc.id}
                        disabled={isLoading}
                      />
                    ) : (
                      doc.documentStatus === 'VERIFIED' && (
                        <Button
                          icon={
                            <CheckCircleOutlineRoundedIcon
                              className={classes.circleOutline}
                            />
                          }
                          name={'Approved'}
                          className={classes.approvedbtn}
                        />
                      )
                    )}
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <Typography>Admin didnt requested any document</Typography>
          )}
        </Box>
      </Accordion>
      <Accordion
        subheading={'Approve Investment'}
        expanded={expanded === 'APPROVE_INVESTMENT'}
        handleChange={(e: string) => handleChange(e)}
        index={'APPROVE_INVESTMENT'}
        buttons={
          <Box className={classes.summaryAccordRight}>
            <Button
              name={handleGetName('APPROVE_INVESTMENT')}
              className={classes.statusBtn}
            />
            <Button
              name={
                get(investmentProcess, 'investmentApproved')
                  ? 'View'
                  : 'Approve'
              }
              onClick={() => {
                setOpen(true);
              }}
              className={classes.accordSendBtn}
              disabled={!get(investmentProcess, 'documentsVerified')}
            />
            <MoreHorizIcon className={classes.moreIcon} />
          </Box>
        }
      >
        <Typography>{handleGetApproveInvestmentSubHeading()}</Typography>
      </Accordion>
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        className={classes.approveModel}
        subheading={''}
        title={'Approve Investment'}
        handleClose={() => {
          setOpen(false);
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="approveProcessForm"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} className={classes.inputHead}>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelText}
              >
                Subscriber Name
              </Typography>
              <TextField
                type="text"
                className={classes.textValInput}
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                defaultValue={get(investmentData, 'subscriberName')}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.inputHead}>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelText}
              >
                EIN/SSN of Subscriber
              </Typography>
              <TextField
                type="text"
                className={classes.textValInput}
                InputProps={{
                  classes: { root: classes.inputRoot },
                  endAdornment: (
                    <>
                      {get(investmentData, 'subscriberEinOrSSN') && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle visibility"
                            onClick={handleClickShowSSN}
                            onMouseDown={handleMouseDownSSN}
                            edge="end"
                          >
                            {showSSN ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                value={
                  showSSN
                    ? subscriberSSN
                    : get(investmentData, 'subscriberEinOrSSN')
                }
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.inputHead}>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelText}
              >
                Investment Amount (US $)
              </Typography>
              <TextField
                type="number"
                className={classes.textValInput}
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                defaultValue={get(investmentData, 'investmentAmount')}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.inputHead}>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelText}
              >
                Transaction Id
              </Typography>
              <Controller
                name="transactionId"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      placeholder="Transaction Id"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled={get(investmentProcess, 'investmentApproved')}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
              {/* <TextField
              type="number"
              className={classes.textValInput}
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              defaultValue={get(investmentData, 'transactionId')}
              disabled
            /> */}
            </Grid>
            <Grid item xs={12} md={6} className={classes.inputHead}>
              <Controller
                name="sendEmail"
                control={control}
                defaultValue={true}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box>
                    <Checkbox
                      style={{ paddingLeft: 0 }}
                      checked={value}
                      onChange={(e: any) => {
                        onChange(e.target.checked);
                      }}
                      disabled={get(investmentProcess, 'investmentApproved')}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                    <Typography className={classes.labelSendEmailText}>
                      Send Email
                    </Typography>
                  </Box>
                )}
                rules={{ required: 'This is required' }}
              />
            </Grid>
          </Grid>
          <Box className={classes.backContinueBox}>
            {get(investmentProcess, 'documentsVerified') && (
              <Button
                type="submit"
                name={
                  get(investmentProcess, 'investmentApproved')
                    ? 'Approved'
                    : 'Approve'
                }
                // onClick={() => approveInvestment()}
                className={classes.continueBtn}
                isLoading={isLoading}
                disabled={get(investmentProcess, 'investmentApproved')}
              />
            )}
            <Button
              onClick={() => setOpen(false)}
              className={classes.backBtn}
              name="Cancel"
            />
          </Box>
        </form>
      </Dialog>
      <Dialog
        open={isOpenDocument}
        maxWidth={'md'}
        subheading={''}
        title={get(document, 'documentName', '')}
        handleClose={() => {
          setOpenDocument(false);
        }}
      >
        <img
          src={url}
          alt={get(document, 'filename')}
          className={classes.previewImg}
        />
        {get(document, 'documentStatus') !== 'VERIFIED' ? (
          <Box className={classes.approveImageButton}>
            <Button
              name="Approve"
              className={classes.approveBtnPreview}
              onClick={() => handleApproveDocument(document.id, true)}
              isLoading={isLoading && selectedDocId === document.id}
            />
          </Box>
        ) : (
          get(document, 'documentStatus') === 'VERIFIED' && (
            <Box className={classes.approveImageButton}>
              <Button
                icon={
                  <CheckCircleOutlineRoundedIcon
                    className={classes.circleOutline}
                  />
                }
                name={'Approved'}
                className={classes.approveFont}
              />
            </Box>
          )
        )}
      </Dialog>
    </Box>
  );
};

export default InvestorProcess;
