import React from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from '@material-ui/core';

import { RootState } from 'redux-modules/store/rootState';
import { Loader, Button, StyledTableCell, StyledTableRow } from 'components';
import { FN } from 'common/types';
import { formatAmount } from 'common/utils/helpers';

import styles from './styles';

type PaymentsListProps = {
  isLoading: boolean;
  data: any;
  isRoleAdmin: boolean;
  paymentsType?: string;
  handleDialogOpen: FN;
};

const PaymentsList = ({
  isLoading,
  data,
  isRoleAdmin,
  handleDialogOpen,
}: PaymentsListProps) => {
  const classes = styles();

  const { errorMessage } = useSelector(({ Enquiries }: RootState) => Enquiries);

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table stickyHeader aria-label="customized table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <StyledTableCell style={{ textAlign: 'left' }}>
              Transaction id
            </StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Amount</StyledTableCell>
            <StyledTableCell>Transaction</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Remarks</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        {!errorMessage ? (
          <TableBody>
            {isLoading ? (
              <Grid item xs={6}>
                <Loader />
              </Grid>
            ) : (
              data.map((row: any) => (
                <StyledTableRow hover key={row.id}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: 'left' }}
                  >
                    {row.transactionId}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.processedAt ? row.processedAt.split('T')[0] : ''}
                  </StyledTableCell>
                  <StyledTableCell>{formatAmount(row.amount)}</StyledTableCell>
                  <StyledTableCell>
                    <span
                      style={{
                        color: isRoleAdmin
                          ? row.transactionType === 'DEBIT'
                            ? 'red'
                            : 'green'
                          : row.transactionType === 'DEBIT'
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {row.transactionType === 'DEBIT'
                        ? isRoleAdmin
                          ? 'Debited from 8vdx'
                          : 'Credited'
                        : isRoleAdmin
                        ? 'Credited to 8vdx'
                        : 'Debited'}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span
                      style={{
                        color: row.isVerifiedByAdmin ? 'green' : 'red',
                      }}
                    >
                      {row.isVerifiedByAdmin ? 'Verified' : 'To Be Verified'}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span
                      style={{
                        color: row.remarks ? 'black' : 'grey',
                      }}
                    >
                      {row.remarks ? row.remarks : 'No Remarks'}
                    </span>
                  </StyledTableCell>
                  {isRoleAdmin && !row.isVerifiedByAdmin ? (
                    <StyledTableCell>
                      <Button
                        className={classes.logTableBtn}
                        name="Verify"
                        onClick={() => handleDialogOpen(row.id, '', '')}
                      />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell>
                      <span style={{ color: 'green' }}>{'-'}</span>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))
            )}
          </TableBody>
        ) : (
          <h2>{errorMessage}</h2>
        )}
      </Table>
    </TableContainer>
  );
};

export default PaymentsList;
