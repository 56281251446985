import React, { useState, useEffect } from 'react';
import { Typography, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { orderBy } from 'lodash';

import { Box } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { getAdminAllVcFunds } from 'services';

import styles from './styles';
import { DeletedFundTable, FundsTable } from './components';

const VcFundList = () => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [adminAllVcFundsData, setAdminAllVcFundsData] = useState<any>([]);
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);

  const onSearchChange = (searchString: string) => {
    const filteredData = adminAllVcFundsData.filter((value: any) => {
      if (
        searchString === '' ||
        value.fundTitle
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase()) ||
        value.fundTitle
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase()) ||
        value.fundTitle
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
  };

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');
    // api for testing
    getAdminAllVcFunds()
      .then((res: any) => {
        const filterData = res.data.filter(
          (item: any) => item.fundTitle !== 'ANGEL_INVESTMENT',
        );
        setAdminAllVcFundsData(orderBy(filterData, ['createdAt'], ['desc']));
        setFilteredSearchData(orderBy(filterData, ['createdAt'], ['desc']));
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  return (
    <Box className={classes.vcFundListBox}>
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      <Box className={classes.vcFirmHead}>
        <Typography variant="h6" className={classes.headText}>
          All Funds
        </Typography>
        <Box className={classes.rightBox}>
          <Box className={classes.searchBox}>
            <InputBase
              value={searchStringText}
              className={classes.inputSearch}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              endAdornment={
                !searchStringText.length ? (
                  <IconButton
                    className={classes.searchIcon}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.clearIcon}
                    aria-label="clear"
                    onClick={() => {
                      setSearchStringText('');
                      onSearchChange('');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )
              }
              onChange={(e: any) => {
                const searchString = e.target.value;
                setSearchStringText(searchString);
                onSearchChange(searchString);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.vcFundAdminTableBox}>
        <FundsTable
          isLoading={isLoading}
          errorMessage={errorMessage}
          filteredSearchData={filteredSearchData.filter(
            (item: any) => !item.isDeleted,
          )}
        />
      </Box>
      <DeletedFundTable fundList={adminAllVcFundsData} />
    </Box>
  );
};

export default VcFundList;
