import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { Dialog, Button } from 'components';
import history from 'common/utils/history';

import styles from './styles';

const ThankyouDialog = ({ open, setOpen, name, handleClose }: any) => {
  const classes = styles();
  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      title={' '}
      handleClose={() => {
        handleClose();
        setOpen(false);
      }}
    >
      <Box className={classes.thankyouContainer}>
        <Typography className={classes.thankyouText}>Thank You</Typography>
        <Typography variant="body1">
          You’ve successfully shared your startup’s profile with {name}. You can
          track all such leads through Fundraising CRM.
        </Typography>
        <Box className={classes.containerButtonBox}>
          <Button
            type="submit"
            className={classes.thankyouButton}
            name="Fundraising CRM"
            onClick={() => {
              handleClose();
              history.push('/fund-raising');
              setOpen(false);
            }}
          />
          <Button
            variant="outlined"
            className={classes.thankyouButton}
            name="Go to Dashboard"
            onClick={() => {
              handleClose();
              history.push('/dashboard');
              setOpen(false);
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default ThankyouDialog;
