import { put, takeLatest, call, fork, all } from 'redux-saga/effects';
import { get } from 'lodash';

import { ISagaAction } from 'common/types';
import { getVdInstallments } from 'services';

import { VdInstallmentPayload } from './Types';
import {
  GET_VD_INSTALLMENTS_LOADING,
  GET_VD_INSTALLMENTS_SUCCESS,
  GET_VD_INSTALLMENTS_FAILURE,
  GET_VD_INSTALLMENTS,
} from './Actions';

type ResponseType = {
  response: any | [];
};

function* handleVdInstallmentsList(data: ISagaAction<VdInstallmentPayload>) {
  const { payload } = data;
  try {
    yield put({ type: GET_VD_INSTALLMENTS_LOADING });
    const response: ResponseType = yield call<any>(getVdInstallments, payload);
    yield put({
      type: GET_VD_INSTALLMENTS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.message');
    yield put({ type: GET_VD_INSTALLMENTS_FAILURE, payload: message });
  }
}

function* handleVdInstallmentsListSaga() {
  yield takeLatest(GET_VD_INSTALLMENTS, handleVdInstallmentsList);
}

export default function* ProcessSagas() {
  yield all([fork(handleVdInstallmentsListSaga)]);
}
