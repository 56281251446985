import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  enquiryListhead: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 90,
    '@media (max-width: 1024px)': {
      paddingTop: 110,
      paddingBottom: 15,
    },
    '@media (max-width: 767px)': {
      paddingTop: 40,
    },
  },
  deleteBtn: {
    background: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    height: 28,
    fontSize: 12,
    cursor: 'pointer',
    borderRadius: 4,
    marginLeft: 15,
    alignSelf: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  enquiryListRight: {
    paddingRight: 30,
    flexGrow: 1,
  },
  enquiryListheadContent: {
    display: 'flex',
    width: 'calc(100% - 75px)',
    flex: '0 auto',
  },
  gridItemView: {
    paddingLeft: 13,
    '@media (max-width: 525px)': {
      paddingLeft: 8,
    },
  },
  rightIconStyle: {
    border: '1px solid #1EA59A',
    // padding: '0px 10px 0',
    borderRadius: 6,
    textAlign: 'center',
    width: 52,
  },
  moreHoriIcon: {
    color: theme.palette.primary.main,
    width: 30,
    height: 30,
    position: 'relative',
    top: 3.5,
  },
  iconStyleOne: {
    height: 52,
    width: 52,
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 34,
    left: 41,
    '@media (max-width: 1024px)': {
      top: 85,
      left: 25,
    },
    '@media (max-width: 767px)': {
      top: 20,
      left: 20,
    },
  },
  labelStartUpText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    opacity: 0.75,
    marginBottom: 8,
    paddingRight: 15,
    '@media (max-width: 525px)': {
      fontSize: 10,
      opacity: 1,
      paddingRight: 5,
      fontWeight: 600,
    },
  },

  labelStartUp: {
    fontSize: 15,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelFundUpHead: {
    fontSize: 20,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    paddingTop: 5,
    '@media (max-width: 525px)': {
      fontSize: 15,
      marginBottom: 0,
    },
  },
  labelInfoText: {
    fontSize: 12,
    color: theme.palette.primary.main,
    opacity: 0.75,
    marginBottom: 25,
    paddingTop: 5,
    '@media (max-width: 1024px)': {
      marginBottom: 10,
    },
  },
}));
