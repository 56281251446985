import React, { ReactNode } from 'react';
import { makeStyles, Box } from '@material-ui/core';
// import { MailImage } from 'assets/index';
const useStyles = makeStyles(() => ({
  boxCenterAlign: {
    // height: 'calc(100vh - 104px)',
    // display: 'flex',
    // justifyContent: 'center',
  },
  boxCenterContent: {
    // alignSelf: 'center',
    display: 'block',
    textAlign: 'center',
    paddingTop: 150,
    position: 'relative',
  },
  successImage: {
    width: 120,
    height: 107.7,
    marginBottom: 35,
  },
}));
type Props = {
  children: ReactNode;
  img?: any;
  className?: any;
};

const SignUpSuccess = ({ children, img, className }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.boxCenterAlign}>
      <Box className={classes.boxCenterContent}>
        <img
          src={img}
          className={className ? className : classes.successImage}
          alt={'mail.png'}
        />
        {children}
      </Box>
    </Box>
  );
};

export default SignUpSuccess;
