import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  inputHead: {
    padding: '17px 40px 0px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 0px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px 12px 0 !important',
    },
  },
  listingBox: {
    position: 'relative',
  },
  tableContainer: {
    boxShadow: theme.shadows[0],
    borderRadius: '1em 1em 0 0',
    height: 'calc(100vh - 400px)',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    // '& .MuiTable-root': {
    //   // height: '100%',
    // },
    // '& .MuiTableBody-root': {
    //   height: 'calc(100% - 73px)',
    // },
    // '& .MuiTableCell-root': {
    //   padding: '16px 16px 10px',
    // },
    '@media (max-width: 768px)': {
      height: 'auto',
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
  tableHeaderStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
    left: 0,
  },
  tableBodyStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.common.white,
    left: 0,
  },
  prevHeaderCell: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
    width: 32,
    zIndex: theme.zIndex.appBar + 3,
    // top: '5em',
    // left: '130px',
    // position: 'absolute',
  },
  nextHeaderCell: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
    width: 32,
    zIndex: theme.zIndex.appBar + 3,
    // top: '5em',
    // right: '30px',
    // position: 'absolute',
  },
  nextPrevIconButton: {
    padding: 5,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  textValInput: {
    marginBottom: 20,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  inputClasses: {
    display: 'none',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    position: 'relative',
    top: '-20px',
  },
  backContinueBox: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    fontSize: 16,
    height: 48,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  checkBoxLabel: {
    fontSize: 14,
    letterSpacing: 0.35,
    color: theme.palette.grey[50],
    display: 'inline-block',
    marginBottom: '1.5em',
  },
  safeTabContainer: {
    width: '100%',
  },
  safeButtonStyle: {
    width: '40%',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginLeft: '120px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  safeTabTableGrid: {
    overflowX: 'auto',
    padding: '10px 20px 0px',
    '@media (max-width: 767px)': {
      padding: '10px 0px 0px',
    },
  },
  safeTabTableData: {
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    font: 'normal 13px Arial, sans-serif',
    width: '100%',
    height: 110,
    textAlign: 'center',
    padding: '50px',
  },
  tableDesign: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderCollapse: 'collapse',
    textAlign: 'center',
    '& .MuiTableCell-root': {
      display: 'table-cell',
      padding: '16px',
      fontSize: '9px',
      textAlign: 'center',
      fontFamily: 'mulish',
      fontWeight: 400,
      lineHeight: 1.43,
      verticalAlign: 'inherit',
      border: '1px solid #E5E5E5',
    },
  },
  selectValInput: {
    width: '100%',
    marginTop: 0,
    border: 'none',
    textSize: '8px',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(20px, 20px) scale(1)',
      fontSize: '8px',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFormLabel-root': {
      fontSize: '8px',
    },
    '& .MuiInputBase-input': {
      fontSize: '5px',
    },
  },
  selectValInputList: {
    width: '100%',
    marginTop: 0,
    border: 'none',
    textSize: '8px',
    '& .MuiPopover-paper': {
      width: '28%',
      textAlign: 'center',
      boxShadow: theme.shadows[1],
    },
    // '& .MuiMenuItem-root': {
    //   marginLeft: '120px',
    // },
  },
  saveTableButton: {
    marginTop: '20px',
    paddingBottom: '10px',
    marginLeft: '250px',
  },
  labelValue: {
    '& .MuiInputLabel-animated': {
      fontSize: '11px',
      marginTop: '25px',
    },
  },
  inputValueHolder: {
    marginLeft: '50px',
  },
  labelValueStyle: {
    '& .MuiInputLabel-animated': {
      fontSize: '8px',
    },
  },
  priceRoundText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textAlign: 'center',
  },
  backTag: {
    color: '#fff',
    background: '#14a871',
    padding: '3px',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: '5px',
  },
  dialogTitle: {
    textAlign: 'center',
    fontSize: '20px',
    paddingBottom: '20px',
  },
  inputHeadText: {
    lineHeight: '25px',
  },
  formCheckbox: {
    paddingTop: '20px',
  },
  borderRightCell: {
    borderRight: '1px solid white !important',
  },
  addButtonBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  hideField: {
    height: 0,
    width: 0,
    display: 'none',
  },
  normalField: {
    lineHeight: '25px',
  },
}));
