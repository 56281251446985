import React from 'react';
import { InputBase, IconButton, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { get, orderBy } from 'lodash';

import { Box, WrappedSelect } from 'components';
import { SubscriptionPlanStatus } from 'common/utils/optionList';
import {
  errorMessageHandler,
  getPaymentStatus,
  getPaymentStatusYardstickUser,
} from 'common/utils/helpers';
import { getAllSubscribedInvestorUser } from 'services';
import { PlanType, ClientData } from 'common/types';
import { SUBSCRIBED_USER } from 'common/utils/constants';

import styles from './styles';
import PlanDetailTable from './PlanDetailTable';
import VcInvestorTransactionView from './VcInvestorTransactionView';

type Props = {
  setIsPlanView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPlanData: PlanType;
};

const PlanDetailView = ({ setIsPlanView, selectedPlanData }: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [
    selectedClientData,
    setSelectedClientData,
  ] = React.useState<ClientData>({
    investorName: '',
    planType: 'string',
    id: '',
  });
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);
  const [transactionView, setTransactionView] = React.useState<boolean>(false);
  const [allSubscribedClients, setAllSubscribedClients] = React.useState<any>(
    [],
  );
  const [planStatus, setPlanStatus] = React.useState<string>('ALL');

  const onSearchChange = (searchString: string) => {
    const filteredData = allSubscribedClients.filter((value: any) => {
      if (
        searchString === '' ||
        value.investorName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
  };

  const onHandlePlanStatusChange = (event: any) => {
    setPlanStatus(event.target.value);
    if (event.target.value !== 'ALL') {
      const filteredData = allSubscribedClients.filter((item: any) => {
        if (
          [SUBSCRIBED_USER.YARDSTICK].includes(
            get(item, 'subscriptionPlan.planType'),
          )
        ) {
          const data =
            get(
              getPaymentStatusYardstickUser(
                get(item, 'usedFreeOutput'),
                get(item, 'billingHistory.status'),
                get(item, 'active'),
              ),
              'value',
            ) === event.target.value;
          return data;
        } else {
          const data =
            get(
              getPaymentStatus(
                get(item, 'startingDate'),
                get(item, 'subscriptionPlan.subscriptionFreeTrialDays'),
                get(item, 'active'),
                get(item, 'billingHistory.amountPaidDecimal'),
                get(item, 'billingHistory.status'),
                get(selectedPlanData, 'planType') === SUBSCRIBED_USER.YARDSTICK,
              ),
              'value',
            ) === event.target.value;
          return data;
        }
      });
      setFilteredSearchData(filteredData);
    } else setFilteredSearchData(allSubscribedClients);
  };

  const getAllSubscribedClients = () => {
    setIsLoading(true);
    setErrorMessage('');
    getAllSubscribedInvestorUser(get(selectedPlanData, 'id'))
      .then((res: any) => {
        setAllSubscribedClients(orderBy(res.data, ['createdAt'], ['desc']));
        setFilteredSearchData(orderBy(res.data, ['createdAt'], ['desc']));
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getAllSubscribedClients();
  }, []);

  return (
    <Box className={classes.planDetailContainer}>
      {transactionView ? (
        <VcInvestorTransactionView
          setTransactionView={setTransactionView}
          selectedClientData={selectedClientData}
        />
      ) : (
        <>
          <Box className={classes.topContainer}>
            <Box className={classes.planHeadingContainer}>
              <Typography className={classes.planHeadingText}>
                {get(selectedPlanData, 'planName')}
              </Typography>
              {!get(selectedPlanData, 'isJoiningPlan') && (
                <Typography
                  className={
                    get(selectedPlanData, 'planType') ===
                    SUBSCRIBED_USER.VC_FIRM
                      ? classes.planTypeVcFirmText
                      : get(selectedPlanData, 'planType') ===
                        SUBSCRIBED_USER.YARDSTICK
                      ? classes.planTypeYardstickText
                      : classes.planTypeAngelInvText
                  }
                >
                  {get(selectedPlanData, 'planType') === SUBSCRIBED_USER.VC_FIRM
                    ? 'VentureInsights Plus'
                    : get(selectedPlanData, 'planType') ===
                      SUBSCRIBED_USER.YARDSTICK
                    ? 'Yardstick Only'
                    : 'VentureInsights'}
                </Typography>
              )}
            </Box>
            <Box className={classes.rightBox}>
              <Box className={classes.searchBox}>
                <InputBase
                  value={searchStringText}
                  className={classes.inputSearch}
                  placeholder="Search Firm"
                  inputProps={{ 'aria-label': 'search' }}
                  endAdornment={
                    !searchStringText.length ? (
                      <IconButton
                        className={classes.searchIcon}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        className={classes.clearIcon}
                        aria-label="clear"
                        onClick={() => {
                          setSearchStringText('');
                          onSearchChange('');
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )
                  }
                  onChange={(e: any) => {
                    const searchString = e.target.value;
                    setSearchStringText(searchString);
                    onSearchChange(searchString);
                  }}
                />
              </Box>
              <WrappedSelect
                variant={'outlined'}
                value={planStatus}
                onChange={onHandlePlanStatusChange}
                className={classes.textValInputSelect}
                InputProps={{
                  classes: { root: classes.inputRootSelect },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRootSelect,
                    focused: classes.labelFocusedSelect,
                  },
                }}
                dropdowns={SubscriptionPlanStatus}
                error={false}
              />
              <KeyboardBackspaceIcon
                className={classes.backIcon}
                onClick={() => setIsPlanView(false)}
              />
            </Box>
          </Box>
          <PlanDetailTable
            filteredSearchData={filteredSearchData}
            isLoading={isLoading}
            errorMessage={errorMessage}
            selectedPlanData={selectedPlanData}
            setSelectedClientData={setSelectedClientData}
            setTransactionView={setTransactionView}
          />
        </>
      )}
    </Box>
  );
};

export default PlanDetailView;
