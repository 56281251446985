import React from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { CircularProgress } from '@material-ui/core';

import { Box } from 'components';
import { RootState } from 'redux-modules/store/rootState';
import { ADMIN_VIEW } from 'common/utils/constants';

import UploadDocuments from './UploadDocuments';
import UploadSignDocuments from './UploadSignDocuments';
import NewEnquiry from '../NewEnquiry';
import NewVentureDebtConversion from '../NewVentureDebtConversion';
import InvestorReview from '../InvestorReview';
import LoanCloser from '../LoanCloser';
import VcReview from '../VcReview';
import styles from '../styles';
import SafeDataFill from '../SafeDataFill';

type Props = {
  status: string;
  loaders?: Record<string, unknown>;
  getDocusignUrl?: any;
};

const env = process.env.REACT_APP_ENV || 'dev';

const PendingActions = (props: Props) => {
  const classes = styles();
  const { status } = props;
  const actionsListState = useSelector(
    ({ global }: RootState) => global.actionsList,
  );

  const { dashboardStartupView } = useSelector(
    ({ global }: RootState) => global,
  );
  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  const isRoleAdmin = role === 'ADMIN';
  const actionsList = get(actionsListState, 'data', []);
  const getActions = () => {
    const isActionsListLoading = get(actionsListState, 'isLoading', false);
    if (isActionsListLoading) {
      return (
        <Box className={classes.loader}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      );
    } else if (!isEmpty(actionsList)) {
      const filteredList =
        dashboardStartupView === ADMIN_VIEW.STARTUP
          ? actionsList.filter(
              (item: any) =>
                item.status === status && item.actionType === 'STARTUP',
            )
          : dashboardStartupView === ADMIN_VIEW.INVESTOR
          ? actionsList.filter(
              (item: any) =>
                item.status === status && item.actionType === 'INVESTOR',
            )
          : dashboardStartupView === ADMIN_VIEW.VC_ADMIN
          ? actionsList.filter(
              (item: any) =>
                item.status === status && item.actionType === 'VC_FIRM',
            )
          : '';
      if (!isEmpty(filteredList)) {
        return filteredList.map((item: any) =>
          getActionContext(item.name, item, status),
        );
      }
    }
  };

  const getActionContext = (
    actionType: string,
    actionItem: any,
    status: string,
  ) => {
    switch (true) {
      case actionType === 'UPLOAD_DOCUMENTS':
        return <UploadDocuments data={actionItem} />;
      case actionType === 'REQUEST_BRIDGE_LOAN_SIGN_DOC':
        return <UploadSignDocuments data={actionItem} />;
      case actionType === 'REQUEST_VD_LOAN_SIGN_DOC':
        return <UploadSignDocuments data={actionItem} />;
      case actionType === 'NEW_ENQUIRY':
        return <NewEnquiry key={actionItem.id} data={actionItem} />;
      case actionType === 'NEW_VENTURE_DEBT_CONVERSION':
        return (
          <NewVentureDebtConversion key={actionItem.id} data={actionItem} />
        );
      case actionType === 'INVESTOR_REVIEW':
        return <InvestorReview key={actionItem.id} data={actionItem} />;
      case env !== 'prod' &&
        ['LOAN_CONVERSION', 'LOAN_EXTENSION', 'SAFE_CAPPED'].includes(
          actionType,
        ):
        return (
          <LoanCloser
            key={actionItem.id}
            data={actionItem}
            getDocusignUrl={props.getDocusignUrl}
            loaders={props.loaders}
            isRoleAdmin={isRoleAdmin}
          />
        );

      case env !== 'prod' && actionType.includes('SAFE_TRACK_VALUATION_DATA'):
        return <SafeDataFill data={actionItem} />;
      case actionType === 'VC_FIRM_SIGNUP':
        return (
          <VcReview key={actionItem.id} data={actionItem} status={status} />
        );
      case actionType === 'VC_FIRM_REVIEW':
        return (
          <VcReview key={actionItem.id} data={actionItem} status={status} />
        );
      case actionType === 'VC_FIRM_FUND_REVIEW':
        return (
          <VcReview key={actionItem.id} data={actionItem} status={status} />
        );
      case actionType === 'ANGEL_INVESTOR_REVIEW':
        return (
          <VcReview key={actionItem.id} data={actionItem} status={status} />
        );
      case actionType === 'YARDSTICK_USER_REVIEW':
        return (
          <VcReview key={actionItem.id} data={actionItem} status={status} />
        );
      default:
        return null;
    }
  };

  return <Box className={classes.pendingBox}>{getActions()}</Box>;
};

export default PendingActions;
