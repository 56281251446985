import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  fundRaisingContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
  },
  fundRaisingHead: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '80px 80px 20px 67px',
    '@media (max-width: 1024px)': {
      padding: '100px 80px 20px 20px',
    },
    '@media (max-width: 767px)': {
      padding: '40px 20px 20px 55px',
    },
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 43,
    left: 40,
    '@media (max-width: 1024px)': {
      top: 75,
      left: 5,
      objectFit: 'cover',
    },
    '@media (max-width: 767px)': {
      top: 23,
      left: 20,
      objectFit: 'cover',
    },
  },
  headText: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    fontWeight: 400,
    letterSpacing: 0.3,
    position: 'relative',
    '@media (max-width: 1024px)': {
      top: 'unset',
    },
  },
  leadText: {
    fontSize: 24,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    letterSpacing: 0.3,
    marginTop: 30,
    marginBottom: 10,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    marginTop: 0,
    marginBottom: 5,
  },
  fundRaisingContent: {},
  fundRaisingTableTabContainer: {},
  fundRaisingListData: {
    background: theme.palette.common.white,
    borderRadius: 7.74,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '20px 40px',
    marginLeft: 30,
    marginRight: 30,
    '@media (max-width: 525px)': {
      padding: '15px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    border: '1px solid #1EA59A',
  },
  fundRaisingListTitle: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    marginBottom: 20,
  },
  fundRaisingListContent: {
    fontSize: 17,
    fontWeight: 400,
    '& ::marker': {
      color: theme.palette.primary.main,
      fontSize: 25,
    },
  },
  fundRaisingFeatureBox: {
    paddingTop: 15,
  },
  getStartedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 18,
    width: 256,
    height: 48,
    lineHeight: '20px',
    borderRadius: 7.74,
    textTransform: 'capitalize',
    marginTop: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      width: 130,
      fontSize: 16,
    },
    '& .MuiButton-label': {
      width: '100%',
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      fontSize: 16,
    },
  },
  inputHeadFull: {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'space-between',
    justifyContent: 'flex-start',
    padding: '12px !important',
    position: 'relative',
  },
  companyLabelText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    marginTop: -10,
  },
  textValInput: {
    marginBottom: 10,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 17,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  fundRaisingDialogText: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.3,
    color: theme.palette.common.black,
    lineHeight: '28px',
    fontStyle: 'italic',
  },
  benchamarkingToolText: {
    color: theme.palette.primary.main,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    marginTop: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  funRaisingCrmDialog: {
    height: '100vh',
  },
  meetingText: { fontSize: 12 },
  vInputHeadFull: {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'space-between',
    justifyContent: 'flex-start',
    padding: '0 12px 12px 12px !important',
    position: 'relative',
    marginTop: '-15px',
  },
}));
