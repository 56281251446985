import React from 'react';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Button, Dialog, WrappedTextInput } from 'components';
import { adminActivateDeactivateVcFirm } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';
import { VcFirmDeactivateSchema, VcFirmActivateSchema } from '../validation';

const VcFirmChangeStatus = ({
  updatingStatus,
  setUpdatingStatus,
  vcDetails,
  setVcDetails,
  investorId,
}: any) => {
  const classes = styles();

  const { handleSubmit, control, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      get(vcDetails, 'activated')
        ? VcFirmDeactivateSchema
        : VcFirmActivateSchema,
    ),
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = () => {
    const data = { ...getValues() };
    setIsLoading(true);
    const obj = {
      investorId: investorId,
      vcFirmId: get(vcDetails, 'id'),
      activated: get(vcDetails, 'activated') ? false : true,
      deactivatedReason: get(vcDetails, 'activated')
        ? get(data, 'deactivate')
        : undefined,
    };
    adminActivateDeactivateVcFirm(obj)
      .then((req: any) => {
        setVcDetails(req.data);
        setIsLoading(false);
        setUpdatingStatus(false);
        reset();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      open={updatingStatus}
      maxWidth={'sm'}
      title={
        get(vcDetails, 'activated') ? 'Deactivate Account' : 'Activate Account'
      }
      handleClose={() => {
        setUpdatingStatus(false);
        reset();
      }}
    >
      <Box
        className={
          !get(vcDetails, 'activated') ? classes.investorStatusPopup : ''
        }
      >
        <Typography variant="h5" className={classes.overViewSubHeadTitle}>
          Are you sure you want to{' '}
          {get(vcDetails, 'activated') ? 'Deactivate' : 'Activate'} the Account?
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="status">
          {get(vcDetails, 'activated') && (
            <>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Reason<span className={classes.errorRequiredText}>*</span>
              </Typography>
              <Controller
                name="deactivate"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Enter Reason"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </>
          )}
          {errorMessage && (
            <Typography variant="caption" className={classes.errorText}>
              {errorMessage}
            </Typography>
          )}
          <Box
            className={
              get(vcDetails, 'activated')
                ? classes.backContinueDeactivateBox
                : classes.backContinueActivateBox
            }
          >
            <Button
              type="submit"
              variant="outlined"
              isLoading={isLoading}
              disabled={isLoading}
              className={
                get(vcDetails, 'activated')
                  ? classes.rejectFirmBtn
                  : classes.activateStatusBtn
              }
              name={get(vcDetails, 'activated') ? 'Deactivate this firm' : 'Ok'}
            />
            <Button
              variant="outlined"
              className={
                get(vcDetails, 'activated')
                  ? classes.cancelBtn
                  : classes.backBtn
              }
              name="Cancel"
              onClick={() => {
                setUpdatingStatus(false);
                reset();
              }}
            />
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default VcFirmChangeStatus;
