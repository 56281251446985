import { GlobalState } from './Types';
import {
  GET_ALL_COUNTRIES_SUCCESS,
  GET_ALL_INDUSTRY_CLASSIFICATION_SUCCESS,
  GET_ACTIONS_LIST_FAILURE,
  GET_ACTIONS_LIST_LOADING,
  GET_ACTIONS_LIST_SUCCESS,
  GET_VD_FORM_VALUES_LOADING,
  GET_VD_FORM_VALUES_SUCCESS,
  GET_VD_FORM_VALUES_FAILURE,
  GET_DASHBOARD_VALUES_LOADING,
  GET_DASHBOARD_VALUES_SUCCESS,
  GET_DASHBOARD_VALUES_FAILURE,
  GET_VD_APPROVE_LOAN_VALUES_LOADING,
  GET_VD_APPROVE_LOAN_VALUES_SUCCESS,
  GET_VD_APPROVE_LOAN_VALUES_FAILURE,
  GET_ALL_YC_LOCATIONS_SUCCESS,
  GET_ALL_STATES_SUCCESS,
  SUBMIT_QUERY_LOADING,
  SUBMIT_QUERY_SUCCESS,
  SUBMIT_QUERY_FAILURE,
  CLEAR_STATE,
  SET_DASHBOARD_VIEW,
} from './Actions';

export const GlobalData: GlobalState = {
  industries: [],
  countries: [],
  ycLocations: [],
  states: [],
  actionsList: {
    isLoading: false,
    errorMessage: '',
    data: null,
  },
  vdFormValues: null,
  vdApproveLoanValues: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    data: {},
  },
  dashboardValues: {
    defaultersCount: 0,
    installmentsReceived: 0,
    netAssetValue: 0,
    newEnquiresCount: 0,
    pendingInstallments: 0,
    totalInvestedAmount: 0,
    paymentsReceived: 0,
  },
  dashboardStartupView: 'STARTUP',
  isLoading: false,
  errorMessage: '',
  successMessage: '',
};

const GlobalReducer = (state = GlobalData, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload,
      };
    case GET_ALL_INDUSTRY_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        industries: payload,
      };
    case GET_ACTIONS_LIST_LOADING:
      return {
        ...state,
        actionsList: {
          isLoading: true,
          errorMessage: '',
          data: null,
        },
      };
    case GET_ACTIONS_LIST_SUCCESS:
      return {
        ...state,
        actionsList: {
          isLoading: false,
          data: payload,
          errorMessage: '',
        },
      };
    case GET_ACTIONS_LIST_FAILURE:
      return {
        ...state,
        actionsList: {
          errorMessage: payload,
          isLoading: false,
          data: null,
        },
      };
    case GET_VD_FORM_VALUES_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        successMessage: '',
      };
    case GET_VD_FORM_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        vdFormValues: payload,
      };
    case GET_VD_FORM_VALUES_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case GET_DASHBOARD_VALUES_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        dashboardValues: '',
      };
    case GET_DASHBOARD_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        dashboardValues: payload,
      };
    case GET_DASHBOARD_VALUES_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case GET_VD_APPROVE_LOAN_VALUES_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        successMessage: '',
        vdApproveLoanValues: {
          ...state.vdApproveLoanValues,
          isLoading: true,
        },
      };
    case GET_VD_APPROVE_LOAN_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        vdApproveLoanValues: {
          ...state.vdApproveLoanValues,
          data: payload,
          isLoading: false,
          successMessage: 'successfully Fetched.',
        },
      };
    case GET_VD_APPROVE_LOAN_VALUES_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        vdApproveLoanValues: {
          ...state.vdApproveLoanValues,
          isLoading: false,
          successMessage: '',
          errorMessage: payload,
        },
      };
    case GET_ALL_YC_LOCATIONS_SUCCESS:
      return {
        ...state,
        ycLocations: payload,
      };
    case GET_ALL_STATES_SUCCESS:
      return {
        ...state,
        states: payload,
      };
    case SUBMIT_QUERY_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        successMessage: '',
      };
    case SUBMIT_QUERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        successMessage: payload,
      };
    case SUBMIT_QUERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        successMessage: '',
      };
    case SET_DASHBOARD_VIEW:
      return {
        ...state,
        dashboardStartupView: payload,
      };
    case CLEAR_STATE:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
      };
    default:
      return state;
  }
};

export default GlobalReducer;
