/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import ReactSpeedometer from 'react-d3-speedometer';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Funnel from 'highcharts/modules/funnel';

import { formatAmount } from 'common/utils/helpers';
import { FCRM_STAGE_MAPPER } from 'common/utils/constants';

Funnel(Highcharts);
import styles from '../styles';

const FCRMChart = ({
  stageTotal = {},
  currency,
  targetAmount = 0,
  isEmptyData = true,
  dilutionPercentage,
}: any) => {
  const classes = styles();
  const [totalAmounts, setTotalAmounts] = useState<Record<string, any>>({});
  // eslint-disable-next-line no-unused-vars
  const [showChart, setShowChart] = useState<boolean>(true);
  const [speedometerInterval, setSpeedometerInterval] = useState<Array<number>>(
    [0, 0, parseInt(targetAmount) || 100000],
  );
  const [
    speedometerDilutionInterval,
    setSpeedometerDilutionInterval,
  ] = useState<Array<number>>([
    0,
    dilutionPercentage,
    dilutionPercentage > 50 ? dilutionPercentage : 50,
  ]);
  const [localStageTotal, setLocalStageTotal] = useState<Record<string, any>>(
    {},
  );

  console.log(stageTotal) //eslint-disable-line
  const getFunnelSeries = () => {
    const order: Record<string, number> = {
      CLOSED_PASS: 6,
      CLOSED_WON: 5,
      TERM_SHEET: 4,
      DUE_DILIGENCE: 3,
      MEETING: 2,
      CONTACTED: 1,
      LEAD: 0,
    };
    const sTotal = {
      MEETING: {
        totalShowAmount: 1,
      },
      CLOSED: {
        totalShowAmount: 1,
      },
      TERM_SHEET: {
        totalShowAmount: 1,
      },
      DUE_DILIGENCE: {
        totalShowAmount: 1,
      },
      LEAD: {
        totalShowAmount: 1,
      },
      CONTACTED: {
        totalShowAmount: 1,
      },
    };

    // if
    const values = Object.keys(isEmptyData ? sTotal : stageTotal).reduce(
      (acc: Array<any>, cur: string) => {
        const index = order[cur];

        acc[index] = [
          cur,
          get(isEmptyData ? sTotal : stageTotal, `${cur}.totalShowAmount`),
        ];

        return acc;
      },
      [[], [], [], [], [], []],
    );
    return (values || []).filter((item) => item[0]);
  };

  const prevCountRef = useRef();

  const getSpeedometerValue = (totals: any) => {
    const val =
      get(totals, 'totalAmountInvested') >= parseInt(targetAmount)
        ? parseInt(targetAmount)
        : get(totals, 'totalAmountInvested');

    return isNaN(val) ? 0 : val;
  };

  useEffect(() => {
    const newChange =
      JSON.stringify(localStageTotal) !== JSON.stringify(stageTotal);
    setShowChart(false);
    if (!isEmpty(stageTotal) && newChange) {
      const total = Object.keys(stageTotal).reduce(
        (acc: Record<string, any>, cur: string) => {
          acc.totalExpectedAmount += parseInt(
            get(stageTotal, `${cur}.totalExpectedAmount`) || 0,
          );
          acc.totalAmountInvested += parseInt(
            get(stageTotal, `${cur}.totalInvestedAmount`) || 0,
          );
          if (cur !== 'CLOSED')
            acc.totalShowAmount += parseInt(
              get(stageTotal, `${cur}.totalShowAmount`) || 0,
            );
          acc.totalTargetAmount = parseInt(
            get(stageTotal, `${cur}.totalTargetAmount`) || 0,
          );
          return acc;
        },
        {
          totalExpectedAmount: 0,
          totalAmountInvested: 0,
          totalShowAmount: 0,
          totalTargetAmount: 0,
        },
      );
      const completedPercent =
        (get(total, 'totalAmountInvested') / parseInt(targetAmount)) * 100;
      total.completedPercent = completedPercent && completedPercent.toFixed(2);

      setTotalAmounts(total);
      setLocalStageTotal(stageTotal);
      // @ts-ignore
      prevCountRef.current = total;

      setSpeedometerInterval([
        ...[0, getSpeedometerValue(total), targetAmount],
      ]);
    }
    setTimeout(() => setShowChart(true), 100);
  }, [stageTotal]);

  const getFunnelChartOptions = () => {
    const funnelChartOptions = {
      chart: {
        type: 'funnel',
        spacing: [10, 10, 15, 10],
        height: 250,
      },
      backgroundColor: 'transparent',
      title: {
        text: '',
      },
      funnel: {
        showInLegend: true,
      },
      tooltip: {
        // @ts-ignore
        formatter() {
          return `${
            // @ts-ignore
            FCRM_STAGE_MAPPER[this.key] || this.key
            // @ts-ignore
          }: ${currency}${formatAmount(isEmptyData ? 0 : this.y, {
            style: 'decimal',
          })}`;
        },
        shared: true,
      },
      plotOptions: {
        funnel: {
          colors: [
            '#3646AF',
            '#029DF1',
            '#43A547',
            '#F7C519',
            '#FF8B07',
            '#FE372A',
            '#FF716F',
          ],
        },

        series: {
          dataLabels: {
            enabled: true,
            // @ts-ignore
            formatter() {
              return `${
                // @ts-ignore
                FCRM_STAGE_MAPPER[this.key] || this.key
                // @ts-ignore
              }: ${currency}${formatAmount(isEmptyData ? 0 : this.y, {
                style: 'decimal',
              })}`;
            },
            useHTML: true,
            softConnector: true,
          },
          center: ['30%', '50%'],
          neckWidth: '12%',
          neckHeight: '0%',
          width: '60%',
        },
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        floating: true,
        layout: 'vertical',
        x: 0,
        y: 0,
        margin: 1,
      },
      series: [
        {
          name: '',
          data: getFunnelSeries(),
          showInLegend: false,
        },
      ],
    };

    return funnelChartOptions;
  };

  useEffect(() => {
    if (dilutionPercentage) {
      setSpeedometerDilutionInterval([
        ...[
          0,
          dilutionPercentage,
          dilutionPercentage > 50 ? dilutionPercentage : 50,
        ],
      ]);
    }
  }, [dilutionPercentage]);

  return (
    <>
      <Box className={classes.chartWrap}>
        <Box className={classes.funnelChartWrap}>
          <Box className={classes.funnelChartBox}>
            <Typography variant="h6">Fundraising Funnel</Typography>
            <Typography variant="h4">
              {currency}
              {formatAmount(get(totalAmounts, 'totalShowAmount') || 0, {
                style: 'decimal',
              })}
            </Typography>
            <Typography variant="body1">
              Sum of amount from all stages
            </Typography>
          </Box>

          <div className={isEmptyData ? classes.blurChart : ''}>
            {showChart && (
              <HighchartsReact
                options={getFunnelChartOptions()}
                highcharts={Highcharts}
              />
            )}
          </div>
        </Box>
        <Box style={{ borderRight: '1px solid #0000000a', width: 400 }}>
          <Box className={classes.guageHeadBox}>
            <Box>
              <Typography variant="h6">Target Amount</Typography>
              <Typography
                variant="h5"
                style={{ fontWeight: 500, fontSize: 22 }}
              >
                {currency}
                {formatAmount(parseInt(targetAmount), {
                  style: 'decimal',
                })}{' '}
                ({get(totalAmounts, 'completedPercent') || 0}%)
              </Typography>
            </Box>
          </Box>
          <div className={classes.guageChartBox}>
            {showChart && parseInt(targetAmount) && (
              <ReactSpeedometer
                value={getSpeedometerValue(totalAmounts)}
                maxValue={parseInt(targetAmount)}
                // fluidWidth={true}
                minValue={0}
                currentValueText={`${currency}${formatAmount(
                  get(totalAmounts, 'totalAmountInvested') || 0,
                  { style: 'decimal' },
                )}/${currency}${formatAmount(parseInt(targetAmount), {
                  style: 'decimal',
                })}`}
                ringWidth={20}
                valueTextFontWeight={'600'}
                segments={2}
                customSegmentStops={speedometerInterval}
                textColor="#A8A8A8"
                segmentColors={['#FF8B07', '#FFCFA4']}
                valueTextFontSize={'14'}
                needleColor="#3747AD"
                needleHeightRatio={0.7}
                customSegmentLabels={[
                  {
                    text: '',
                    // @ts-ignore
                    position: 'OUTSIDE',
                    color: '#555',
                    fontSize: '16',
                  },
                  {
                    text: '',
                    // @ts-ignore
                    position: 'OUTSIDE',
                    color: '#555',
                    fontSize: '16',
                  },
                ]}
              />
            )}
          </div>
        </Box>

        <Box style={{ width: 400, paddingLeft: 10 }}>
          <Box className={classes.guageHeadBox}>
            <Box>
              <Typography variant="h6">Dilution Percentage</Typography>
              <Typography className={classes.invText}>
                This includes YC investment
              </Typography>
            </Box>
          </Box>
          <div className={classes.guageChartBox}>
            {showChart && (
              <ReactSpeedometer
                value={dilutionPercentage}
                maxValue={dilutionPercentage > 50 ? dilutionPercentage : 50}
                // fluidWidth={true}
                minValue={0}
                currentValueText={`${dilutionPercentage}%`}
                ringWidth={15}
                valueTextFontWeight={'600'}
                segments={2}
                customSegmentStops={speedometerDilutionInterval}
                textColor="#A8A8A8"
                segmentColors={['#FF8B07', '#c2c2c26b']}
                valueTextFontSize={'18'}
                needleColor="#3747AD"
                needleHeightRatio={0.7}
                segmentValueFormatter={(val: any) => `${val}%`}
              />
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default FCRMChart;
