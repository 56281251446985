import React, { useEffect } from 'react';
import {
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import { Box } from 'components';
import { Ornament } from 'assets';

import styles from './styles';
import StartupInformationForm from '../../BenchMark/StartupInformationForm';

type Props = {
  selectedStartup: any;
  handleAddStartupModalClose: any;
};

const BenchMarkStartupInfo = ({
  selectedStartup,
  handleAddStartupModalClose,
}: Props) => {
  // const dispatch = useDispatch();
  const classes = styles();

  const { role } = useSelector(({ Auth }: RootState) => Auth.user);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [startupOption, setStartupOption] = React.useState('newStartup');
  const [existingStartup, setExistingStartup] = React.useState<boolean>(false);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setStartupOption(value);
    if (value === 'existingStartup') {
      setExistingStartup(true);
    } else if (value === 'newStartup') {
      setExistingStartup(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedStartup)) {
      setStartupOption('existingStartup');
      setExistingStartup(true);
    }
  }, [selectedStartup]);

  return (
    <Box className={classes.benchMarkContainer}>
      <Box className={classes.benchmarkHead}>
        <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
        <Typography variant="h6" className={classes.headText}>
          Yardstick
        </Typography>
      </Box>
      <Box className={classes.benchMarkFormContent}>
        <Box className={classes.benchmarkFormContainer}>
          <>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.formHeadingText}
            >
              Startup debt application
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.subHeading}
            >
              Please provide the following info to get started [Let’s organise
              it in the same format as we Startups debt application form]
            </Typography>
            {/* <Typography
              variant="body1"
              gutterBottom
              className={classes.errorText}
            >
              {errorMessage}
            </Typography> */}
          </>
          {isEmpty(selectedStartup) && (
            <Grid container className={classes.startupOptionContainer}>
              <Grid item>
                <Typography
                  variant="caption"
                  className={classes.startupOptionText}
                >
                  Please choose the startup option? *
                </Typography>
              </Grid>
              <Grid item>
                <FormControl>
                  <RadioGroup
                    row={true}
                    value={startupOption}
                    onChange={handleOptionChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      value="newStartup"
                      control={<Radio />}
                      label="New Startup"
                      className={classes.formControlText}
                    />
                    <FormControlLabel
                      value="existingStartup"
                      control={<Radio />}
                      label="Existing Startups"
                      className={classes.formControlText}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Box>
        <StartupInformationForm
          role={role}
          existingStartup={existingStartup}
          selectedStartupId={selectedStartup}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
        <Typography
          variant="h6"
          className={classes.closeIcon}
          onClick={() => {
            handleAddStartupModalClose();
          }}
        >
          <CloseIcon />
        </Typography>
      </Box>
    </Box>
  );
};

export default BenchMarkStartupInfo;
