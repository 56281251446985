import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  dealCreationHeader: {
    fontSize: 32,
    letterSpacing: 0.3,
    color: theme.palette.grey[500],
    marginBottom: 35,
    fontWeight: 400,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '0px 0 20px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  uploadText: {
    fontSize: 22,
    color: theme.palette.error.contrastText,
    marginTop: 12,
    paddingLeft: 2,
    position: 'relative',
    display: 'inline-block',
    '@media (max-width: 1120px)': {
      fontSize: 24,
    },
  },
  uploadFileOrLinkBox: {
    display: 'flex',
    marginBottom: 30,
    paddingTop: 20,
    '@media (max-width: 767px)': {
      display: 'block',
      marginBottom: 10,
    },
  },
  uploadBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 270,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
  orText: {
    width: 30,
    paddingLeft: 35,
    paddingRight: 35,
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: 600,
    '@media (max-width: 767px)': {
      padding: '20px 35px',
    },
  },
  linkBox: {
    width: 'calc(100% - 270px - 100px)',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  descBox: {
    marginTop: 10,
    width: '70%',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  controllerBox: {
    position: 'relative',
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInput: {
    width: 350,
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  textValInputDesc: {
    width: '100%',
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    // position: 'absolute',
    // bottom: 12,
    display: 'block',
    textAlign: 'left',
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  prevButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    marginRight: 18,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));
