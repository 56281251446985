import React, { useEffect, useState } from 'react';
import { Typography, Checkbox } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { find, get, isEmpty } from 'lodash';

import { IconImg, documentIcon, UploadIcon } from 'assets';
import { RootState } from 'redux-modules/store/rootState';
import {
  Button,
  Loader,
  Box,
  WrappedTextInput,
  WrappedAutocomplete,
  FileUpload,
} from 'components';
import {
  handleSaveForm,
  handleSubmitStartUp,
  getStartupData,
} from 'redux-modules/enquiryForm/Actions';
import { StepProps } from 'common/types';
import {
  incorporationCountryList,
  getAllStatesOfCountry,
  getIncorporationCountryObject,
  getStateObject,
  getIndustryClassificationList,
  getIndustryTypeObject,
  getIndustryClassificationObject,
} from 'common/utils/helpers';
import { industryTypeList } from 'common/utils/optionList';
import { urlConstants } from 'common/utils/constants';
import { getSignedUrlForYCDoc, getStartupYcDocument } from 'services';

import { ApplyBridgeDebtSchema } from '../validations';
import styles from '../styles';

const CompanyDetails = ({
  handleNext,
  // stepName,
  id,
  detailPage,
  enquiryId,
  loadingTerms,
  scheduled,
  loanStatus,
}: StepProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { handleSubmit, control, getValues, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ApplyBridgeDebtSchema),
  });

  const {
    startupDetails,
    successMessage,
    enquiryTermsAccepted,
    currentPage,
  } = useSelector(({ EnquiryForm }: RootState) => EnquiryForm);

  const {
    startupFullName,
    // startupYcAssignedId,
    startupTaxIdNumber,
    assignedYcLegalPocName,
    assignedYcPartnerName,
    ycIncorporationCountry,
    stateOfIncorporation,
    industryType,
    industryClassification,
  } = startupDetails;

  const { startUpId } = useSelector(({ Auth }: RootState) => Auth.user);

  const { errorMessage, isLoading } = useSelector(
    ({ EnquiryForm }: RootState) => EnquiryForm.isStartUp,
  );

  const { bridgeLoanProcess } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const [isLoadingSignedUrl, setIsLoadingSignedUrl] = React.useState<boolean>(
    false,
  );
  const [showYcDocPreview, setShowYcDocPreview] = useState<boolean>(false);
  const [
    signedUrlErrorMessage,
    setSignedUrlErrorMessage,
  ] = React.useState<string>('');
  const [documentObject, setDocumentObject] = React.useState<any>([]);
  const [
    startupUploadedDocument,
    setStartupUploadedDocument,
  ] = React.useState<any>([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(
    ycIncorporationCountry,
  );
  const [selectedIndustry, setSelectedIndustry] = React.useState<any>(
    industryType,
  );

  const getDocument = () => {
    getStartupYcDocument('BRIDGE_LOAN')
      .then((startupYcDocument) => {
        setStartupUploadedDocument(startupYcDocument);
        setShowYcDocPreview(true);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const cancelSelectedDocument = () => {
    setStartupUploadedDocument([]);
  };

  const handlePayloadObj = () => {
    const startupFormDetails = {
      ...getValues(),
      startupId: startUpId,
    };
    const obj = {
      currentPage: 'BASIC_DETAILS_FORM',
      enquiryTermsAccepted: get(startupFormDetails, 'enquiryTermsAccepted'),
      startupDetails: {
        startupFullName: get(startupFormDetails, 'startupFullName'),
        startupId: get(startupFormDetails, 'startupId'),
        // startupYcAssignedId: get(startupFormDetails, 'startupYcAssignedId'),
        startupTaxIdNumber: get(startupFormDetails, 'startupTaxIdNumber'),
        ycIncorporationCountry: get(
          startupFormDetails,
          'ycIncorporationCountry.countryCode',
        ),
        stateOfIncorporation: get(
          startupFormDetails,
          'stateOfIncorporation.isoCode',
        ),
        assignedYcLegalPocName: get(
          startupFormDetails,
          'assignedYcLegalPocName',
        ),
        assignedYcPartnerName: get(startupFormDetails, 'assignedYcPartnerName'),
        industryType: get(startupFormDetails, 'industryType.value'),
        industryClassification: get(
          startupFormDetails,
          'industryClassification.value',
        ),
      },
      ycDocuments: startupUploadedDocument.map((document: any) => {
        return {
          ...document,
          documentUrl: document.accessUrl || document.documentUrl,
        };
      }),
      id: id || enquiryId,
    };

    return obj;
  };

  const saveForm = () => {
    const obj = JSON.parse(JSON.stringify(handlePayloadObj()));
    if (!obj.id) {
      delete obj.id;
    }
    dispatch(handleSaveForm(obj));
  };

  const onFormSubmit = (navigationToAddMore = false) => {
    saveForm();
    const obj = JSON.parse(JSON.stringify(handlePayloadObj()));
    let callback;
    if (loanStatus === 'ONHOLD') {
      callback = () => {
        handleNext();
        getDocument();
      };
    } else {
      callback = () => {
        navigationToAddMore ? detailPage() : handleNext();
        getDocument();
      };
    }
    if (!obj.id) {
      delete obj.id;
    }
    obj.callback = callback;
    dispatch(handleSubmitStartUp(obj));
    setSignedUrlErrorMessage('');
  };

  const onClickAddMore = () => {
    onFormSubmit(true);
  };

  useEffect(() => {
    if (startUpId && !currentPage) {
      dispatch(getStartupData(startUpId));
    }
    getDocument();
  }, []);

  useEffect(() => {
    if (isEmpty(selectedCountryCode) && !isEmpty(ycIncorporationCountry))
      setSelectedCountryCode(ycIncorporationCountry);
  }, [ycIncorporationCountry]);

  useEffect(() => {
    if (isEmpty(selectedIndustry) && !isEmpty(industryType))
      setSelectedIndustry(industryType);
  }, [industryType]);

  useEffect(() => {
    if (successMessage && successMessage.length) {
      setValue('enquiryTermsAccepted', enquiryTermsAccepted || true);
    }
  }, [successMessage]);

  useEffect(() => {
    if (startupFullName) {
      setValue('startupFullName', startupFullName);
      setValue('startupTaxIdNumber', startupTaxIdNumber);
      setValue('assignedYcPartnerName', assignedYcPartnerName);
      setValue('assignedYcLegalPocName', assignedYcLegalPocName);
      setValue(
        'ycIncorporationCountry',
        getIncorporationCountryObject(ycIncorporationCountry),
      );
      setValue(
        'stateOfIncorporation',
        getStateObject(stateOfIncorporation, ycIncorporationCountry),
      );
      setValue('enquiryTermsAccepted', enquiryTermsAccepted);
      setValue('industryType', getIndustryTypeObject(industryType));
      setValue(
        'industryClassification',
        getIndustryClassificationObject(industryType, industryClassification),
      );
    }
  }, [startupDetails]);

  const uploadFile = async (fileuploadReq: any): Promise<void> => {
    if (!isEmpty(fileuploadReq)) {
      try {
        const documentUrl = get(fileuploadReq, 'url.accessUrl');
        const filename = get(fileuploadReq, 'filename');
        setDocumentObject([fileuploadReq]);
        if (isEmpty(startupUploadedDocument)) {
          setStartupUploadedDocument([
            { filename, documentUrl, documentName: 'YC_ACCEPTANCE' },
          ]);
        } else {
          const [firstDoc] = startupUploadedDocument;
          firstDoc.filename = filename;
          firstDoc.documentUrl = documentUrl;
          firstDoc.documentStatus = 'UPLOADED';
          setStartupUploadedDocument([firstDoc]);
        }
        setShowYcDocPreview(false);
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setIsLoadingSignedUrl(false);
      }
    }
  };

  const getDisableYcField = () => {
    if (loanStatus === 'ONHOLD') {
      return false;
    }
    if (get(bridgeLoanProcess, 'documentsSigned') || scheduled) {
      return true;
    }
  };

  return (
    <div>
      {/* {isLoading && <Loader />} */}
      <Typography variant="h2" className={classes.detailsText}>
        Apply for Bridge Debt
      </Typography>
      <br />

      <Typography variant="body1" gutterBottom className={classes.errorMessage}>
        {errorMessage}
      </Typography>
      {loadingTerms && <Loader />}
      {!loadingTerms && (
        <form
          onSubmit={
            get(bridgeLoanProcess, 'documentsSigned') && loanStatus !== 'ONHOLD'
              ? () => handleNext()
              : handleSubmit(() => {
                  if (!isEmpty(startupUploadedDocument)) {
                    onFormSubmit(false);
                  } else if (isEmpty(startupUploadedDocument)) {
                    setSignedUrlErrorMessage('Please upload file');
                  }
                })
          }
          data-testid="companyDetailsForm"
          className={classes.validateForm}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Full legal name of your company*
              </Typography>
              <Controller
                name="startupFullName"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Legal name of the entity receiving YC investment"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled={
                        get(bridgeLoanProcess, 'documentsSigned') || scheduled
                      }
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Company full name is required' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Country of Incorporation*
              </Typography>
              <Controller
                name="ycIncorporationCountry"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedAutocomplete
                      id="field1"
                      disableClearable
                      options={incorporationCountryList}
                      placeholder="Country of Incorporation"
                      // value={value}
                      value={
                        value ||
                        getIncorporationCountryObject(ycIncorporationCountry)
                      }
                      getOptionLabel={(option: any) => option.country || ''}
                      getOptionSelected={(option: any, value: any) =>
                        option.countryCode === value.countryCode
                      }
                      onChange={(_: any, value: any) => {
                        setSelectedCountryCode(value?.countryCode);
                        setValue('stateOfIncorporation', null);
                        onChange(value);
                      }}
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                      disabled={
                        get(bridgeLoanProcess, 'documentsSigned') || scheduled
                      }
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                State of Incorporation of the above legal entity*
              </Typography>
              <Controller
                name="stateOfIncorporation"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedAutocomplete
                      id="field2"
                      placeholder="State of Incorporation"
                      disableClearable
                      options={getAllStatesOfCountry(selectedCountryCode || '')}
                      // value={value}
                      value={
                        value ||
                        (selectedCountryCode !== 'PENDING_INCORPORATION' &&
                        selectedCountryCode == ycIncorporationCountry
                          ? getStateObject(
                              stateOfIncorporation,
                              ycIncorporationCountry,
                            )
                          : null)
                      }
                      getOptionLabel={(option: any) => option.name || ''}
                      getOptionSelected={(option: any, value: any) =>
                        option.isoCode === value.isoCode
                      }
                      onChange={(_: any, value: any) => {
                        onChange(value);
                      }}
                      disabled={
                        !selectedCountryCode ||
                        selectedCountryCode === 'PENDING_INCORPORATION' ||
                        get(bridgeLoanProcess, 'documentsSigned') ||
                        scheduled
                      }
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                    />

                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Tax identification number of the legal entity*
              </Typography>
              <Controller
                name="startupTaxIdNumber"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Company tax identification number"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled={
                        get(bridgeLoanProcess, 'documentsSigned') || scheduled
                      }
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Sector/Industry*
              </Typography>
              <Controller
                name="industryType"
                control={control}
                // defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedAutocomplete
                      id="industry-type-select"
                      placeholder="Select Industry"
                      disableClearable
                      options={industryTypeList}
                      // value={value}
                      value={value || getIndustryTypeObject(industryType)}
                      getOptionLabel={(option: any) => option.text || ''}
                      getOptionSelected={(option: any, value: any) =>
                        option.value === value.value
                      }
                      onChange={(_: any, value: any) => {
                        setSelectedIndustry(value?.value);
                        setValue('industryClassification', null);
                        onChange(value);
                      }}
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      disabled={
                        get(bridgeLoanProcess, 'documentsSigned') || scheduled
                      }
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Sub-industry*
              </Typography>
              <Controller
                name="industryClassification"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedAutocomplete
                      id="industry-classification"
                      placeholder="Sub-industry"
                      disableClearable
                      options={getIndustryClassificationList(selectedIndustry)}
                      // value={value}
                      value={
                        value ||
                        (selectedIndustry == industryType
                          ? getIndustryClassificationObject(
                              industryType,
                              industryClassification,
                            )
                          : null)
                      }
                      getOptionLabel={(option: any) => option.text || ''}
                      getOptionSelected={(option: any, value: any) =>
                        option.value === value.value
                      }
                      onChange={(_: any, value: any) => {
                        onChange(value);
                      }}
                      disabled={
                        get(bridgeLoanProcess, 'documentsSigned') ||
                        !selectedIndustry ||
                        scheduled
                      }
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Assigned YC Group Partner Name*
              </Typography>
              <Controller
                name="assignedYcPartnerName"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Assigned YC Group Partner Name"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled={getDisableYcField()}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Assigned YC Legal POC Name*
              </Typography>
              <Controller
                name="assignedYcLegalPocName"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Assigned YC Legal POC Name"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled={getDisableYcField()}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography
                variant="caption"
                className={classes.ycAcceptanceText}
              >
                YC Acceptance email screenshot*
              </Typography>
              {(isEmpty(startupUploadedDocument) ||
                !isEmpty(
                  startupUploadedDocument &&
                    find(startupUploadedDocument, {
                      documentStatus: 'REJECTED',
                    }),
                )) && (
                <FileUpload
                  getSignedUrl={getSignedUrlForYCDoc}
                  style={{ display: 'block' }}
                  uploadOnSelect={uploadFile}
                  fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
                  disabled={getDisableYcField()}
                  setLoadingSignedUrl={setIsLoadingSignedUrl}
                  requestObject={{
                    startupId: startUpId,
                  }}
                  showSelectedFilePreview={false}
                  content={
                    <Box>
                      <Grid container className={classes.uploadYcContainer}>
                        <Grid item>
                          <img
                            src={UploadIcon}
                            alt={UploadIcon}
                            className={classes.uploadYcIcon}
                          />
                          <Typography
                            variant="caption"
                            className={classes.uploadYcText}
                          >
                            Upload Document
                          </Typography>
                        </Grid>
                      </Grid>

                      {signedUrlErrorMessage &&
                        isEmpty(documentObject) &&
                        !isLoadingSignedUrl && (
                          <Typography
                            variant="caption"
                            className={classes.ycErrorText}
                          >
                            {signedUrlErrorMessage}
                          </Typography>
                        )}
                    </Box>
                  }
                />
              )}

              {isLoadingSignedUrl && <Loader />}
              {!isLoadingSignedUrl &&
                !isEmpty(startupUploadedDocument) &&
                startupUploadedDocument.map((item: any) => (
                  <Box key={item.id} className={classes.upLoadBox}>
                    <Box className={classes.upLoadleft}>
                      <img
                        src={IconImg}
                        alt={IconImg}
                        className={classes.circleImg}
                      />
                      {showYcDocPreview && (
                        <a
                          href={get(item, 'documentUrl')}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={documentIcon}
                            alt={documentIcon}
                            className={classes.previewImgNotUploaded}
                          />
                        </a>
                      )}
                      {!showYcDocPreview && (
                        <img
                          src={documentIcon}
                          alt={documentIcon}
                          className={classes.previewImgNotUploaded}
                        />
                      )}

                      <Typography className={classes.uploadFileName}>
                        {get(item, 'filename')}
                      </Typography>
                    </Box>
                    {!item.id && (
                      <CancelIcon
                        className={classes.crossImg}
                        onClick={() => cancelSelectedDocument()}
                      />
                    )}
                  </Box>
                ))}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="enquiryTermsAccepted"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box className={classes.checkBoxAgree}>
                    <Checkbox
                      style={{ paddingLeft: 0 }}
                      checked={value}
                      defaultChecked={enquiryTermsAccepted}
                      // onChange={onChange}
                      onChange={(e: any) => {
                        onChange(e.target.checked);
                      }}
                      disabled={get(bridgeLoanProcess, 'documentsSigned')}
                    />
                    <Typography
                      variant="caption"
                      className={classes.agreeTerms}
                    >
                      I agree to the{' '}
                      <a
                        href={urlConstants.terms_s3_url}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.termAndCondition}
                      >
                        Terms and Conditions
                      </a>
                    </Typography>
                    {error?.message && (
                      <span className={classes.termsText}>
                        Please read and agree to the Terms & Conditions to
                        submit the application
                      </span>
                    )}
                  </Box>
                )}
                rules={{ required: 'This is required' }}
              />
            </Grid>
          </Grid>
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              disabled={scheduled || isLoadingSignedUrl}
              className={classes.continueBtn}
              name={`Submit & Continue`}
              isLoading={isLoading}
            />
            <Button
              variant="outlined"
              disabled={scheduled || isLoadingSignedUrl}
              className={classes.moreDetails}
              name="Add More Details"
              onClick={
                get(bridgeLoanProcess, 'documentsSigned')
                  ? () => detailPage()
                  : handleSubmit(onClickAddMore)
              }
            />
          </Box>
        </form>
      )}
    </div>
  );
};

export default CompanyDetails;
