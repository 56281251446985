import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputAdornment } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';

import { WrappedPriceInput, WrappedTextInput } from 'components';
import { updateFcrm } from 'services';

import { emptySchema } from '../validations';
import styles from '../styles';

type FcrmEditableCellProps = {
  defaulValue: string;
  disabled?: boolean;
  onBlur?: any;
  focusCondition?: any;
  type?: string;
  name: string;
  validations?: any;
  setFieldHaveError?: any;
  prefix?: string;
  onSuccessInlineUpdate?: any;
  priceInput?: boolean;
  postfix?: any;
  priceError?: boolean;
  recordErr?: boolean;
  // onFocus?: any;
};
const FcrmEditableCell = ({
  defaulValue,
  disabled,
  onBlur,
  type = 'text',
  name,
  validations,
  prefix,
  onSuccessInlineUpdate,
  priceInput,
  postfix,
  priceError,
  recordErr,
}: // setFieldHaveError,
// onFocus,
FcrmEditableCellProps) => {
  const classes = styles();

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    // formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations || emptySchema),
  });

  const onSubmit = (data: any) => {
    if (!isDirty) return;

    const keyValues = get(Object.entries(data), '[0]');

    const key = get(keyValues, '[0]');
    const value = get(keyValues, '[1]');

    if (key.includes('expectedAmount') || key.includes('amountInvested')) {
      data[key] = value && value.replace(/,/g, '');
    }
    onBlur(name);
    updateFcrm(data).then(() => {
      if (onSuccessInlineUpdate) {
        onSuccessInlineUpdate(data);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="navAttrForm">
      {!priceInput && (
        <Controller
          name={name}
          control={control}
          defaultValue={defaulValue}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <WrappedTextInput
                type={type}
                label=""
                onBlur={(e: any) => {
                  onSubmit({ [name]: e.target.value });
                  onBlur(e);
                }}
                disabled={disabled}
                // inputRef={(input: any) =>
                //   focusCondition === true && input?.focus()
                // }
                className={classes.editTextValInput}
                InputProps={{
                  inputProps: {
                    style: {
                      textAlign: type === 'number' ? 'right' : 'center',
                    },
                  },
                  endAdornment: postfix && (
                    <InputAdornment position="end">
                      <span style={{ color: recordErr ? 'red' : '' }}>
                        {postfix}
                      </span>
                    </InputAdornment>
                  ),
                  classes: {
                    root: recordErr
                      ? classes.fcrmErrorInputRoot
                      : classes.fcrmInputRoot,
                  },
                  startAdornment: prefix && value && (
                    <InputAdornment position="start">{prefix}</InputAdornment>
                  ),
                  disableUnderline: true,
                  // startAdornment: (
                  //   <InputAdornment position="start">
                  //     $
                  //   </InputAdornment>
                  // ),
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                value={value}
                onChange={(e: any) => {
                  onChange(e);
                }}
                error={error?.message ? true : false}
              />
              {error?.message && (
                <span className={classes.errorText}>{error?.message}</span>
              )}
            </>
          )}
          rules={{ required: 'Target Amount is required' }}
        />
      )}
      {priceInput && (
        <Controller
          name={name}
          control={control}
          defaultValue={defaulValue}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <WrappedPriceInput
                label=""
                onBlur={(e: any) => {
                  onSubmit({ [name]: e.target.value });
                  onBlur(e);
                }}
                disabled={disabled}
                currencySymbol={'USD'}
                placeholder={''}
                decimalPlaces={0}
                autoFocus={false}
                selectOnFocus={false}
                // inputRef={(input: any) =>
                //   focusCondition === true && input?.focus()
                // }
                className={classes.editTextValInput}
                InputProps={{
                  classes: {
                    root: recordErr
                      ? classes.fcrmErrorInputRoot
                      : classes.fcrmInputRoot,
                  },
                  startAdornment: prefix && value && (
                    <InputAdornment position="start">
                      <span style={{ color: recordErr ? 'red' : '' }}>
                        {prefix}
                      </span>
                    </InputAdornment>
                  ),
                  disableUnderline: true,

                  // startAdornment: (
                  //   <InputAdornment position="start">
                  //     $
                  //   </InputAdornment>
                  // ),
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                value={value === 'DELETED' ? '' : value}
                onChange={(e: any) => {
                  onChange(e);
                }}
                error={error?.message ? true : false}
              />
              {error?.message && (
                <span className={classes.errorText}>{error?.message}</span>
              )}
              {priceError && !value && (
                <span className={classes.errorText}>
                  This field is required
                </span>
              )}
            </>
          )}
          rules={{ required: 'Target Amount is required' }}
        />
      )}
    </form>
  );
};

export default FcrmEditableCell;
