import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;
export default makeStyles((theme) => ({
  appBarRoot: {
    padding: '20px 120px 20px 160px',
    backgroundColor: 'white',
    '@media (max-width: 1024px)': {
      padding: '20px 5px',
    },
    // [theme.breakpoints.down('sm')]: {
    //   padding: '20px 5px',
    // },
  },
  logoText: {
    position: 'relative',
    height: 'auto',
    width: 60,
  },
  logoText8: {
    position: 'absolute',
    height: 40,
    width: 40,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    // color: '#fff',
  },
  linksText8: {
    color: theme.palette.primary.contrastText,
    position: 'relative',
    left: 16,
    top: 6,
    fontSize: 17,
  },
  logoLinksText: {
    position: 'relative',
    color: '#000000',
    fontSize: 17,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    letterSpacing: '0.5px',
  },
  leftLinks: {
    flexGrow: 1,
  },
  rightLinks: {
    display: 'flex',
    alignItems: 'center',
  },
  linksText: {
    padding: '10px 20px',
    color: '#000000',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    letterSpacing: '0.5px',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    textDecoration: 'none',
    '@media (max-width: 1100px)': {
      padding: '10px 15px',
    },
  },
  applyBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    padding: '8px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    fontWeight: 600,
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  startUpBtn: {
    color: theme.palette.primary.dark,
    marginRight: 15,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.dark,
    },
  },
  header: {
    backgroundColor: '#400CCC',
    paddingRight: '79px',
    paddingLeft: '118px',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 600,
    color: '#FFFEFE',
    textAlign: 'left',
  },
  logoImg: {
    height: 40,
  },
  menuButton: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 700,
    size: '18px',
    marginLeft: '38px',
  },
  drawer: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    height: 50,
    width: drawerWidth,
  },
  leftMobileLinks: {},
  mobileBtns: {
    padding: '5px 15px',
  },
  toolbarBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuIcon: {
    color: theme.palette.secondary.contrastText,
    height: 32,
    width: 32,
  },
  rightIcon: {
    color: theme.palette.secondary.contrastText,
    height: 35,
    width: 35,
  },
  drawerContainer: {
    padding: '20px 30px',
  },
}));
