import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { isEmpty, get } from 'lodash';
import moment from 'moment';

import { Box, StyledTableCell, StyledTableRow, Loader } from 'components';
import { TransactionIcon } from 'assets';
import {
  getPaymentStatusYardstickUser,
  getPaymentStatus,
  formatAmount,
} from 'common/utils/helpers';
import { ClientData, PlanType } from 'common/types';
import { StyledTooltip } from 'components/Wrappers/Tooltip';
import { SUBSCRIBED_USER } from 'common/utils/constants';

import styles from './styles';

type Props = {
  filteredSearchData: any;
  selectedPlanData: PlanType;
  isLoading: boolean;
  errorMessage: string;
  setTransactionView: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedClientData: React.Dispatch<React.SetStateAction<ClientData>>;
};

const headers = (array: any[]) => {
  return (
    <>
      {array.map((i: any, n: number) => {
        return (
          <StyledTableCell
            key={n}
            style={{
              textAlign: i.textAlign ? i.textAlign : 'left',
            }}
          >
            {i.name}
          </StyledTableCell>
        );
      })}
    </>
  );
};

const VC_FIRM_TABLE_HEADERS = [
  { name: 'Client Name', textAlign: 'left' },
  { name: 'Service Period', textAlign: 'center' },
  { name: 'Next Billing Date', textAlign: 'center' },
  { name: 'Output Generated', textAlign: 'right' },
  { name: 'Amount to be Paid', textAlign: 'right' },
  { name: 'Status', textAlign: 'left' },
  { name: 'Action', textAlign: 'left' },
];

const YARDSTICK_TABLE_HEADERS = [
  { name: 'Client Name', textAlign: 'left' },
  { name: 'Service Period', textAlign: 'center' },
  { name: 'Next Billing Date', textAlign: 'center' },
  { name: 'Output Generated', textAlign: 'right' },
  { name: 'Amount to be Paid', textAlign: 'right' },
  { name: 'Status', textAlign: 'left' },
  { name: 'Action', textAlign: 'left' },
];

const PlanDetailTable = ({
  filteredSearchData,
  isLoading,
  errorMessage,
  setTransactionView,
  setSelectedClientData,
  selectedPlanData,
}: Props) => {
  const classes = styles();

  if (!isEmpty(filteredSearchData)) {
    const newHistories = filteredSearchData.filter(
      (item: any) => item.subscriptionPlan,
    );
    const freeTrialRecord = newHistories[newHistories.length - 1];
    freeTrialRecord.freeTrial = true;
    const index = filteredSearchData.findIndex(
      (item: any) => item.id === get(freeTrialRecord, 'id'),
    );
    if (
      index !== -1 &&
      get(freeTrialRecord, 'billingHistory.amountPaidDecimal') === 0
    )
      filteredSearchData[index] = freeTrialRecord;
  }

  const getPlanPriceDetail = (planDetail: any) => {
    const prices = get(planDetail, 'prices');
    return prices.find((price: any) => !price.addon);
  };

  // eslint-disable-next-line no-unused-vars
  const getPlanAddOnPriceDetail = (planDetail: any) => {
    const prices = get(planDetail, 'prices');
    return prices.find((price: any) => price.addon);
  };

  const getServicePeriod = (row: any) => {
    if (get(selectedPlanData, 'planType') === SUBSCRIBED_USER.YARDSTICK) {
      if (!get(row, 'usedFreeOutput')) return 'NA';
      else if (!isEmpty(get(row, 'billingHistory'))) {
        return `${moment(
          get(row, 'billingHistory.billingPeriodFrom'),
          'X',
        ).format('MMM DD, YYYY')} -
      ${moment(get(row, 'billingHistory.billingPeriodTo'), 'X').format(
        'MMM DD, YYYY',
      )}`;
      } else {
        return `${moment(get(row, 'startingDate')).format(
          'MMM DD, YYYY',
        )} - ${moment(get(row, 'startingDate'))
          .add(get(row, 'subscriptionPlan.subscriptionFreeTrialDays'), 'days')
          .format('MMM DD, YYYY')}`;
      }
    } else {
      if (!isEmpty(get(row, 'billingHistory'))) {
        return `${moment(
          get(row, 'billingHistory.billingPeriodFrom'),
          'X',
        ).format('MMM DD, YYYY')} -
      ${moment(get(row, 'billingHistory.billingPeriodTo'), 'X').format(
        'MMM DD, YYYY',
      )}`;
      } else {
        return `${moment(get(row, 'startingDate')).format(
          'MMM DD, YYYY',
        )} - ${moment(get(row, 'startingDate'))
          .add(get(row, 'subscriptionPlan.subscriptionFreeTrialDays'), 'days')
          .format('MMM DD, YYYY')}`;
      }
    }
  };

  const getNextBillingDate = (row: any) => {
    if (!isEmpty(get(row, 'billingHistory')) && get(row, 'active')) {
      return `${moment(get(row, 'billingHistory.billingPeriodTo'), 'X')
        .add(1, 'days')
        .format('MMM DD, YYYY')}`;
    } else if (isEmpty(get(row, 'billingHistory')) && get(row, 'active')) {
      return `${moment(get(row, 'startingDate'))
        .add(get(row, 'subscriptionPlan.subscriptionFreeTrialDays'), 'days')
        .format('MMM DD, YYYY')}`;
    } else {
      return 'NA';
    }
  };

  return (
    <Box className={classes.firmAdminTableBox}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              {headers(
                get(selectedPlanData, 'planType') === SUBSCRIBED_USER.YARDSTICK
                  ? YARDSTICK_TABLE_HEADERS
                  : VC_FIRM_TABLE_HEADERS,
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={
                    get(selectedPlanData, 'planType') ===
                    SUBSCRIBED_USER.YARDSTICK
                      ? YARDSTICK_TABLE_HEADERS.length
                      : VC_FIRM_TABLE_HEADERS.length
                  }
                >
                  <Box className={classes.loader}>
                    <Loader />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : isEmpty(filteredSearchData) && !errorMessage ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={
                    get(selectedPlanData, 'planType') ===
                    SUBSCRIBED_USER.YARDSTICK
                      ? YARDSTICK_TABLE_HEADERS.length
                      : VC_FIRM_TABLE_HEADERS.length
                  }
                >
                  <Box className={classes.loader}>
                    <Typography>No data found in table</Typography>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              (filteredSearchData || []).map((row: any, index: number) => (
                <StyledTableRow hover key={index}>
                  <StyledTableCell component="th" scope="row" align="center">
                    {get(row, 'investorName', '-')}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ textAlign: 'center' }}
                  >
                    {getServicePeriod(row)}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ textAlign: 'center' }}
                  >
                    {getNextBillingDate(row)}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ textAlign: 'right' }}
                  >
                    {get(selectedPlanData, 'planType') ===
                    SUBSCRIBED_USER.YARDSTICK ? (
                      <>
                        {get(row, 'freeTrial')
                          ? get(row, 'noOfFreeOutput')
                          : get(row, 'outputs')}
                      </>
                    ) : (
                      get(row, 'outputs') || '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ textAlign: 'right' }}
                  >
                    {get(selectedPlanData, 'planType') ===
                    SUBSCRIBED_USER.YARDSTICK
                      ? formatAmount(
                          get(row, 'paidOuputGeneratedInCurrentMonth') *
                            (get(
                              getPlanPriceDetail(get(row, 'subscriptionPlan')),
                              'amount',
                            ) || 0),
                        )
                      : get(row, 'outputs')
                      ? formatAmount(
                          get(row, 'outputs') *
                            (get(
                              getPlanAddOnPriceDetail(
                                get(row, 'subscriptionPlan'),
                              ),
                              'amount',
                            ) || 0),
                        )
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {get(selectedPlanData, 'planType') ===
                    SUBSCRIBED_USER.YARDSTICK
                      ? get(
                          getPaymentStatusYardstickUser(
                            get(row, 'usedFreeOutput'),
                            get(row, 'billingHistory.status'),
                            get(row, 'active'),
                          ),
                          'text',
                        )
                      : get(
                          getPaymentStatus(
                            get(row, 'startingDate'),
                            get(
                              row,
                              'subscriptionPlan.subscriptionFreeTrialDays',
                            ),
                            get(row, 'active'),
                            get(row, 'billingHistory.amountPaidDecimal'),
                            get(row, 'billingHistory.status'),
                            get(row, 'subscriptionPlan.planType'),
                          ),
                          'text',
                        )}
                  </StyledTableCell>
                  <StyledTableCell className={classes.actionButtonBox}>
                    <span className={classes.viewIcon}>
                      <StyledTooltip
                        title={'Transaction History'}
                        placement="top"
                      >
                        <img
                          src={TransactionIcon}
                          alt="link"
                          className={classes.viewIcon}
                          onClick={() => {
                            setSelectedClientData({
                              investorName: get(row, 'investorName'),
                              planType: get(selectedPlanData, 'planType'),
                              id: get(row, 'investorId'),
                            });
                            setTransactionView(true);
                          }}
                        />
                      </StyledTooltip>
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PlanDetailTable;
