import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { isEmpty, get } from 'lodash';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Box, Loader, StyledTableCell, StyledTableRow } from 'components';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import {
  getAdminAllVcFirms,
  getAllVcFirmsFundStat,
  getAllVcFirmsSubscriptionStat,
} from 'services';
import history from 'common/utils/history';
import { SUBSCRIBED_USER } from 'common/utils/constants';

import styles from './styles';

const cardItem = [
  { name: 'VentureInsights MRR', key: 'ventureInsightsMrr' },
  { name: 'Yardstick Monthly Revenue', key: 'yardstickUserRevenue' },
  { name: 'Total Fund Raised', key: 'totalRaisedFundAmount' },
  { name: 'Number of Funds', key: 'totalVcFunds' },
  { name: 'Number of Unique Investors', key: 'totalVcInvestors' },
  { name: 'Number of Firms', key: 'totalVcFirm' },
];

const headers = (array: string[]) => {
  return (
    <>
      {array.map((i: string, n: number) => {
        return <StyledTableCell key={n}>{i}</StyledTableCell>;
      })}
    </>
  );
};

const TABLE_HEADERS = [
  'Sr.no',
  'Firm Name',
  'Fund Name',
  'Fund Size',
  'Strategy',
  'Investor Count',
];

const VcQuickStats = () => {
  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [vcFirmData, setVcFirmData] = React.useState<any>([]);
  const [investmentStat, setInvestmentStat] = React.useState<any>();
  const [subscriptionStats, setSubscriptionStats] = React.useState<any>({});

  const data = {
    labels: ['VentureInsights Plus', 'VentureInsights', 'Yardstick Only'],
    datasets: [
      {
        data: [
          subscriptionStats?.totalVcFirm,
          subscriptionStats?.totalAngelInvestor,
          subscriptionStats?.totalYardstickUser,
        ],
        backgroundColor: ['#029DF1', '#43A547', '#3646AF'],
        borderColor: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        onClick: (e: any) => {
          if (e.preventDefault) e.preventDefault();
        },
        position: 'bottom' as const,
        align: 'center' as const,
        textAlign: 'center' as const,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      datalabels: {
        color: '#FFFFFF',
        font: {
          size: 18,
        },
      },
    },
  };

  React.useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');
    getAdminAllVcFirms()
      .then((res: any) => {
        setVcFirmData(
          (res.data || []).filter(
            (firm: any) => firm.firmType === SUBSCRIBED_USER.VC_FIRM,
          ),
        );
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');
    Promise.all([getAllVcFirmsFundStat(), getAllVcFirmsSubscriptionStat()])
      .then(([fundStats, subscriptionStats]: any) => {
        setInvestmentStat(fundStats.data);
        setSubscriptionStats(subscriptionStats.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  const modifiedVcFirmData = vcFirmData.reduce((acc: any, cur: any) => {
    const funds = get(cur, 'vcFunds').map((item: any) => {
      item.vcFirmName = cur.name;
      return item;
    });
    acc = [...acc, ...funds];
    return acc;
  }, []);

  return (
    <Box>
      <Box className={classes.summaryContainer}>
        <Box className={classes.summaryLeft}>
          <Box className={classes.leftTopBox}>
            <Typography className={classes.planAnalysisText}>
              Subscription Plan Analysis
            </Typography>
            <Typography className={classes.freeTrialText}>
              Free Trials: {get(subscriptionStats, 'freeTrialUser', 0)}
            </Typography>
          </Box>
          <Box className={classes.pieChartBox}>
            <Pie data={data} options={options} />
          </Box>
        </Box>
        <Box className={classes.dashBox}>
          {cardItem.map(({ name, key }: any) => (
            <Box key={key} className={classes.dashCardBox}>
              <Typography className={classes.dashText}>{name}</Typography>
              <Typography className={classes.dashNumber}>
                {key === 'totalRaisedFundAmount'
                  ? formatAmount(
                      get(investmentStat, 'totalRaisedFundAmoun', 123458680),
                      {
                        notation: 'compact',
                        maximumFractionDigits: 1,
                      },
                    )
                  : key === 'totalVcFirm'
                  ? get(investmentStat, 'totalVcFirm', 0)
                  : key === 'totalVcInvestors'
                  ? get(investmentStat, 'totalVcInvestors', 0)
                  : key === 'totalVcFunds'
                  ? get(investmentStat, 'totalVcFunds', 0)
                  : key === 'ventureInsightsMrr'
                  ? formatAmount(
                      (get(subscriptionStats, 'vcFirmRevenue', 0) +
                        get(subscriptionStats, 'angelInvestorRevenue', 0)) /
                        100,
                    )
                  : key === 'yardstickUserRevenue'
                  ? formatAmount(
                      get(subscriptionStats, 'yardstickUserRevenue', 0) / 100,
                    )
                  : ''}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.dashVCTableBox}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>{headers(TABLE_HEADERS)}</TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={TABLE_HEADERS.length}>
                    <Box className={classes.loader}>
                      <Loader />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : isEmpty(modifiedVcFirmData) && !errorMessage ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={TABLE_HEADERS.length}>
                    <Box className={classes.loader}>
                      <Typography>No data found in table</Typography>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                (modifiedVcFirmData || []).map((row: any, index: number) => (
                  <StyledTableRow hover key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          history.push(
                            `/admin/vc-firms/${get(
                              row,
                              'investorId',
                            )}?value=VIEW`,
                          )
                        }
                      >
                        {get(row, 'vcFirmName')}{' '}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {get(row, 'fundTitle')}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="right">
                      {formatAmount(get(row, 'targetFundSize'), {
                        currency: get(row, 'currency', 'USD') || 'USD',
                      })}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {get(row, 'fundStrategy')}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {get(row, 'vcInvestors').length}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default VcQuickStats;
