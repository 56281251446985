import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Drawer,
  Box,
  Divider,
  List,
  ListItem,
  Link,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';

import history from 'common/utils/history';
import { LogoOrangeIcon } from 'assets';
import { STATIC_WEBSITE } from 'common/utils/constants';

import styles from './styles';

// const HeaderLinks = [
//   {
//     label: 'Home',
//     href: '/home',
//   },
//   {
//     label: 'About 8vdx',
//     href: '/about',
//   },
//   {
//     label: 'Blog',
//     href: '/blog',
//   },
//   {
//     label: 'Contact Us',
//     href: '/contact-us',
//   },
// ];

const Header = () => {
  const classes = styles();
  const [state, setState] = React.useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const pathName = history.location.pathname;

  const env = process.env.REACT_APP_ENV || null;
  const HeaderRightLinks = [
    {
      label: 'Contact Us',
      href: env ? `${STATIC_WEBSITE[env]}#contact` : '/',
      redirectToReact: false,
    },
  ];

  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const Buttons = () => (
    <>
      {pathName !== '/sign-in' && (
        <Button
          className={classes.startUpBtn}
          onClick={() => history.push('/sign-in')}
        >
          {/* Startup Login */}
          Startup Sign In
        </Button>
      )}
      {pathName === '/sign-in' && (
        <Button
          className={classes.applyBtn}
          onClick={() => history.push('/sign-up')}
        >
          Sign Up
        </Button>
      )}
    </>
  );

  const LogoImage = () => {
    return (
      // <Typography variant="h3" className={classes.logoText}>
      //   <Typography variant="button" className={classes.logoText8} />
      //   <Typography variant="body1" className={classes.linksText8}>
      //     8
      //     <Typography variant="caption" className={classes.logoLinksText}>
      //       vdx
      //     </Typography>
      //   </Typography>
      // </Typography>
      <Link href={env ? `${STATIC_WEBSITE[env]}` : '/'} underline="none">
        <Box className={classes.logoText}>
          <img
            src={LogoOrangeIcon}
            alt={LogoOrangeIcon}
            className={classes.logoImg}
          />
        </Box>
      </Link>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar>
        {LogoImage()}
        <Typography className={classes.leftLinks}>
          {/* {HeaderLinks.map((i) => {
            return (
              <Link to={i.href} className={classes.linksText} key={i.href}>
                {i.label}
              </Link>
            );
          })} */}
        </Typography>
        <div className={classes.rightLinks}>
          <Typography>
            {HeaderRightLinks.map((i) => {
              return i.redirectToReact ? (
                <RouterLink
                  to={i.href}
                  className={classes.linksText}
                  key={i.href}
                >
                  {i.label}
                </RouterLink>
              ) : (
                <Link
                  href={i.href}
                  className={classes.linksText}
                  key={i.href}
                  underline="none"
                >
                  {i.label}
                </Link>
              );
              // return (
              //   <Link to={i.href} className={classes.linksText} key={i.href}>
              //     {i.label}
              //   </Link>
              // );
            })}
          </Typography>
          {Buttons()}
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={classes.toolbarBox}>
        <Drawer
          className={classes.drawer}
          {...{
            anchor: 'right',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={classes.drawerHeader}>
            <ChevronRightIcon
              className={classes.rightIcon}
              onClick={handleDrawerClose}
            />
          </div>
          <Divider />
          <List className={classes.leftMobileLinks}>
            {/* {HeaderLinks.map((i) => {
              return (
                <ListItem key={i.href}>
                  <Link to={i.href} className={classes.linksText}>
                    {i.label}
                  </Link>
                </ListItem>
              );
            })} */}
            {HeaderRightLinks.map((i) => {
              return (
                <ListItem key={i.href}>
                  {i.redirectToReact ? (
                    <RouterLink to={i.href} className={classes.linksText}>
                      {i.label}
                    </RouterLink>
                  ) : (
                    <Link
                      href={i.href}
                      className={classes.linksText}
                      key={i.href}
                      underline="none"
                    >
                      {i.label}
                    </Link>
                  )}
                  {/* <Link to={i.href} className={classes.linksText}>
                    {i.label}
                  </Link> */}
                </ListItem>
              );
            })}
          </List>
          <Box className={classes.mobileBtns}>{Buttons()}</Box>
        </Drawer>

        <Box>{LogoImage()}</Box>
        <MenuIcon
          className={classes.menuIcon}
          color="primary"
          {...{
            onClick: handleDrawerOpen,
          }}
        />
      </Toolbar>
    );
  };

  return (
    <AppBar position="static" className={classes.appBarRoot} elevation={0}>
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
};

export default Header;
