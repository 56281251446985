import React from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import moment from 'moment';

import {
  Box,
  WrappedPriceInput,
  WrappedSelect,
  WrappedTextInput,
} from 'components';

type InputProps = {
  control: any;
  classes: any;
  // rules?: any;
  name: string;
  label: any;
  defaultValue?: string | number;
  percentType?: boolean;
  inputProps?: any;
  placeholder?: string;
  register?: any;
  onBlurField?: any;
  type?: string;
  inputRef?: any;
  disabled?: boolean;
  onFieldChange?: any;
};

type SelectProps = {
  control: any;
  classes: any;
  name: string;
  label: any;
  defaultValue?: string | number;
  percentType?: boolean;
  inputProps?: any;
  id?: string;
  placeholder?: string;
  dropdowns: any;
  onChangeField?: any;
  disabled?: boolean;
};

const getDateDefaultValue = (val: any) => {
  if (val && moment(moment(val).format('YYYY-MM-DD')).isValid()) {
    return moment(val).format('YYYY-MM-DD');
  }
  return val;
};
export const DateField = ({
  control,
  classes,
  // rules,
  name,
  label,
  inputProps = {},
  defaultValue,
  onBlurField,
  disabled,
}: InputProps) => {
  return (
    <>
      {typeof label === 'function' ? label() : <Typography>{label}</Typography>}
      <Controller
        name={name}
        control={control}
        defaultValue={getDateDefaultValue(defaultValue)}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <WrappedTextInput
              type="date"
              value={getDateDefaultValue(value)}
              disabled={disabled}
              className={classes.textValInput}
              InputProps={{
                classes: { root: classes.inputRoot },
                ...inputProps,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              onBlur={(e: any) =>
                onBlurField && onBlurField(e.target.value, name)
              }
              error={error?.message ? true : false}
              onChange={onChange}
            />

            {error?.message && (
              <span className={classes.errorText}>{error?.message}</span>
            )}
          </>
        )}
        // rules={{ required: true }}
      />
    </>
  );
};

export const PriceField = ({
  control,
  classes,
  // rules,
  name,
  label,
  defaultValue = '',
  inputProps = {},
  placeholder = '',
  onBlurField,
  disabled,
  onFieldChange,
}: // register,
InputProps) => {
  return (
    <>
      {typeof label === 'function' ? label() : <Typography>{label}</Typography>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <WrappedPriceInput
              // error={error}
              label=""
              disabled={disabled}
              className={classes.textValInput}
              InputProps={{
                classes: { root: classes.inputRoot },
                ...inputProps,
              }}
              // {...register(name, rules || {})}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              value={value === 'DELETED' ? 0 : value}
              onBlur={(e: any) =>
                onBlurField && onBlurField(e.target.value, name)
              }
              onChange={(e: any) => {
                onChange(e);
                if (onFieldChange) onFieldChange(e);
              }}
              placeholder={placeholder}
              error={error?.message ? true : false}
            />
            {error?.message && (
              <span className={classes.errorText}>{error?.message}</span>
            )}
          </>
        )}
        // rules={rules}
      />
    </>
  );
};

export const InputField = ({
  control,
  classes,
  // rules,
  name,
  label,
  defaultValue = '',
  inputProps = {},
  placeholder = '',
  onBlurField,
  type,
  inputRef,
  disabled,
}: // register,
InputProps) => {
  return (
    <>
      {typeof label === 'function' ? label() : <Typography>{label}</Typography>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <WrappedTextInput
              type={type || 'text'}
              label=""
              // error={error}
              className={classes.textValInput}
              inputRef={inputRef}
              disabled={disabled}
              InputProps={{
                classes: { root: classes.inputRoot },
                ...inputProps,
              }}
              // {...register(name, rules || {})}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              onKeyPress={(event: any) => {
                if (
                  (type === 'number' && event?.key === '-') ||
                  event?.key === '+'
                ) {
                  event.preventDefault();
                }
              }}
              value={value}
              onBlur={(e: any) =>
                onBlurField && onBlurField(e.target.value, name)
              }
              onChange={onChange}
              placeholder={placeholder}
              error={error?.message ? true : false}
            />

            {error?.message && (
              <span className={classes.errorText}>{error?.message}</span>
            )}
          </>
        )}
        // rules={rules}
      />
    </>
  );
};

export const SelectInputField = ({
  control,
  classes,
  // rules,
  name,
  label,
  defaultValue = '',
  inputProps = {},
  placeholder = '',
  dropdowns = [],
  onChangeField,
  disabled,
}: SelectProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            {typeof label === 'function' ? (
              label()
            ) : (
              <Typography>{label}</Typography>
            )}
            <WrappedSelect
              value={value}
              onChange={(e: any) => {
                onChange(e);
                if (onChangeField) onChangeField(e.target.value, name);
              }}
              disabled={disabled}
              label={''}
              placeholder={placeholder}
              className={classes.selectValInput}
              InputProps={inputProps}
              SelectProps={{
                MenuProps: {
                  classes: { paper: classes.menuPaper },
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              error={!!error?.message}
              dropdowns={dropdowns}
            />
            {error?.message && (
              <span className={classes.errorText}>{error?.message}</span>
            )}
          </>
        )}
      />
    </>
  );
};

export const CheckboxField = ({
  control,
  classes,
  // rules,
  name,
  label,
}: InputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box>
          <Checkbox
            style={{ paddingLeft: 0 }}
            checked={value}
            onChange={(e: any) => {
              onChange(e.target.checked);
            }}
          />
          <Typography variant="caption" className={classes.checkBoxLabel}>
            {label}
          </Typography>
          {error?.message && (
            <div className={classes.errorText}>{error?.message}</div>
          )}
        </Box>
      )}
      // rules={rules}
    />
  );
};
