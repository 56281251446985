import React, { useState } from 'react';
import { Typography } from '@material-ui/core';

import { Dialog, Box } from 'components';
import { Logo, DealDemoDayImg, DealYCFundingImg } from 'assets';
import history from 'common/utils/history';

import styles from './styles';

const Deal = () => {
  const search = history.location.search;
  const ycBatch: any = new URLSearchParams(search).get('ycbatch');
  const loanType: any = new URLSearchParams(search).get('type');

  const classes = styles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <Box className={classes.dealContainer}>
      <Box className={classes.dealHeader}>
        <Box className={classes.headerContent}>
          <img src={Logo} alt={Logo} className={classes.dealLogo} />
        </Box>
      </Box>
      <Box className={classes.dealBody}>
        <Typography variant="h3" className={classes.dHText}>
          How does the 8vdX deal work?
        </Typography>
        <Typography variant="h4" className={classes.dHSText}>
          What are the terms of the deal?
        </Typography>

        {loanType === 'BRIDGE_TO_DEMO_DAY' && (
          <ul className={classes.ulBox}>
            <li className={classes.liBox}>
              You get up to $150k at a ZERO cash interest rate when you are in
              YC’s accelerator program
            </li>
            <li className={classes.liBox}>
              Issue bonus SAFE worth $30K to 8vdX with same terms as YC’s $375k
              Uncapped, MFN SAFE* (“Bonus SAFE”)
            </li>
            <li className={classes.liBox}>
              After six months, i.e., after the Demo Day raise;
            </li>
            <Typography variant="body1" className={classes.subUlText}>
              a)&nbsp;You repay $150k in cash or
            </Typography>
            <Typography variant="body1" className={classes.subUlText}>
              {ycBatch === 'S22' &&
                'b) If unable to repay, convert $150k into a SAFE with a $10m valuation cap (“Conversion SAFE”)'}
              {ycBatch !== 'S22' && (
                <>
                  b) If unable to repay, convert $150k into a SAFE with a
                  pre-agreed valuation cap (“Conversion SAFE”)
                </>
              )}
            </Typography>
          </ul>
        )}
        {loanType === 'BRIDGE_TO_YC_FUND' && (
          <ul className={classes.ulBox}>
            <li className={classes.liBox}>
              You get up to $500k at a ZERO cash interest rate repayable by Demo
              Day (a bridge to YC money)
            </li>
            <li className={classes.liBox}>
              Issue bonus SAFE worth $35K (i.e. for 3 months) with the same
              terms as YC’s $375k Uncapped, MFN SAFE* (“Bonus SAFE”)
            </li>
            <li className={classes.liBox}>Within three months,</li>
            <Typography variant="body1" className={classes.subUlText}>
              a)&nbsp;You repay the principal (i.e. $500k) in cash or
            </Typography>
            <Typography variant="body1" className={classes.subUlText}>
              b) If you need more time to repay, you’ll issue additional SAFE at
              the rate of $16,667 per month (“Additional Bonus SAFE”)
            </Typography>
          </ul>
        )}
        {/* <Typography variant="body1" className={classes.hereText}>
          A detailed version of the term sheet is available&nbsp;
          <Typography
            variant="caption"
            onClick={() => setModalOpen(true)}
            className={classes.spanHere}
          >
            here.
          </Typography>
        </Typography> */}
        <Box className={classes.dealBox}>
          <img
            src={
              loanType === 'BRIDGE_TO_YC_FUND'
                ? DealYCFundingImg
                : DealDemoDayImg
            }
            alt={
              loanType === 'BRIDGE_TO_YC_FUND'
                ? DealYCFundingImg
                : DealDemoDayImg
            }
            className={classes.dealImg}
          />
        </Box>
        <Typography variant="h4" className={classes.dHSText}>
          What are the steps in availing of the deal?
        </Typography>
        {ycBatch === 'S22' && (
          <Typography variant="body1" className={classes.subUlText}>
            All YC companies in the current batch are pre-approved and can
            complete the debt application through our tech{' '}
            <a
              href="https://portal.8vdx.com/sign-up"
              target="_blank"
              rel="noreferrer"
            >
              platform
            </a>{' '}
            within a few minutes.
          </Typography>
        )}

        {ycBatch !== 'S22' && (
          <Typography variant="body1" className={classes.subUlText}>
            Say you chose to avail the 8vdX deal on January 30th, 2022 and we
            agreed on a valuation cap of $15M for the Conversion SAFE. All YC
            W22 companies are pre-approved and can complete the debt application
            through our tech{' '}
            <a
              href="https://portal.8vdx.com/sign-up"
              target="_blank"
              rel="noreferrer"
            >
              platform
            </a>{' '}
            within a few minutes.
          </Typography>
        )}
        {loanType === 'BRIDGE_TO_DEMO_DAY' && (
          <ul className={classes.ulBox}>
            <li className={classes.liBox}>
              Provide basic KYC details and a Board Consent.
            </li>
            <li className={classes.liBox}>
              Sign debt agreements which consist of a Promissory Note and a
              Bonus SAFE for $30k. We are using Uncapped, MFN SAFE
              template/terms.
            </li>
            <li className={classes.liBox}>
              The Promissory Note will mention the valuation cap of $10mn used
              to issue the Conversion SAFE if you choose to convert $150k on
              maturity.
            </li>
            <li className={classes.liBox}>
              8vdX will review the application and provide the funds within a
              few hours.
            </li>
          </ul>
        )}
        {loanType === 'BRIDGE_TO_YC_FUND' && (
          <ul className={classes.ulBox}>
            <li className={classes.liBox}>
              Provide basic KYC details and a Board Consent. Select the debt
              amount upto $500k (assumed $500k for this explainer)
            </li>
            <li className={classes.liBox}>
              Sign debt agreements which consist of a Promissory Note and a
              Bonus SAFE. We are using Uncapped, MFN SAFE template/terms.
            </li>
            <li className={classes.liBox}>
              8vdX will review the application and provide the funds within a
              few hours.
            </li>
          </ul>
        )}
        <Typography variant="h4" className={classes.dHSText}>
          <br />
          How does the repayment work?
        </Typography>
        {loanType === 'BRIDGE_TO_DEMO_DAY' && (
          <>
            {ycBatch !== 'S22' && (
              <Typography variant="body1" className={classes.subUlText}>
                Let’s assume you receive money from Demo Day investors by June
                2022. Two scenarios could play out based on your amount raised:
              </Typography>
            )}
            {ycBatch === 'S22' && (
              <Typography variant="body1" className={classes.subUlText}>
                Let’s assume you take 8vdX bridge debt on June 1st, 2022 and you
                receive money from Demo Day investors by October 2022. Two
                scenarios could play out based on your amount raised:
              </Typography>
            )}
            <Typography variant="body1" className={classes.hdText}>
              A) raised your target amount - repay in full
            </Typography>
            <ul className={classes.ulBox}>
              {ycBatch === 'S22' && (
                <li className={classes.liBox}>
                  You repay $150k without any cash interest in six months, i.e.,
                  by November 30th, 2022.
                </li>
              )}
              {ycBatch !== 'S22' && (
                <li className={classes.liBox}>
                  You repay $150k without any cash interest in six months, i.e.,
                  by July 30th, 2022.
                </li>
              )}
            </ul>
            <Typography variant="body1" className={classes.bonusText}>
              Bonus SAFE:
            </Typography>
            <ul className={classes.ulBox}>
              <li className={classes.liBox}>
                $30k SAFE converts into equity immediately before the next
                priced round - along with YC’s $375k SAFE.
              </li>
              <Typography variant="body1" className={classes.exText}>
                For example, if your MFN valuation is $30 mn, 8vdX will get only
                ~ 0.1% stake, which further gets diluted during the next priced
                round.
              </Typography>
            </ul>
            <Typography variant="body1" className={classes.hdText}>
              B) raised a small amount - convert to SAFE
            </Typography>
            <ul className={classes.ulBox}>
              <li className={classes.liBox}>
                You do not repay any amount in cash
              </li>
              <li className={classes.liBox}>
                Instead, you’ll issue a SAFE with a{' '}
                {ycBatch === 'S22' ? '$10M' : '$15M'} $
                {ycBatch === 'S22' ? ' cap as agreed before.' : ' cap.'} We’ll
                use YC’s{' '}
                <a
                  href="https://www.ycombinator.com/documents"
                  target="_blank"
                  rel="noreferrer"
                >
                  Valuation Cap, no Discount SAFE template.
                </a>
              </li>
            </ul>
            <Typography variant="body1" className={classes.bonusText}>
              SAFEs:
            </Typography>
            <ul className={classes.ulBox}>
              <li className={classes.liBox}>
                $30k Bonus SAFE and $150k Conversion SAFE convert into equity
                immediately before the next priced round.
              </li>
            </ul>
            <Typography variant="body1" className={classes.batchText}>
              A detailed FAQ on Uncapped, MFN SAFE is available on Bookface; we
              would be happy to get on a call to explain its workings.
            </Typography>
            {ycBatch !== 'S22' && (
              <Typography variant="body1" className={classes.debtFeeText}>
                *Debt processing fees of 0.5%, reduced from the usual 1% for the
                first batch. There are no charges/fees other than those referred
                to in this document.
              </Typography>
            )}
            {ycBatch === 'S22' && (
              <>
                <Typography variant="body1" className={classes.debtFeeText}>
                  *MFN period starts from the date of signing of debt documents.
                </Typography>
                <Typography variant="body1" className={classes.debtFeeText}>
                  **Debt processing fees of 1%. Banks typically charge 1-2% loan
                  processing fees. There are no charges/fees other than those
                  referred to in this document.
                </Typography>
              </>
            )}
          </>
        )}
        {loanType === 'BRIDGE_TO_YC_FUND' && (
          <>
            <Typography variant="body1" className={classes.subUlText}>
              Let’s assume you take 8vdX bridge debt on June 15th, 2022. Two
              scenarios could play out based on when you receive YC money:
            </Typography>
            <Typography variant="body1" className={classes.hdText}>
              A) receive YC money by Demo Day - no additional SAFE
            </Typography>
            <ul className={classes.ulBox}>
              <li className={classes.liBox}>
                You repay $500k without any cash interest within three month
                i.e., by September 15th, 2022.
              </li>
            </ul>
            <Typography variant="body1" className={classes.bonusText}>
              Bonus SAFE:
            </Typography>
            <ul className={classes.ulBox}>
              <li className={classes.liBox}>
                $50k SAFE converts into equity immediately before the next
                priced round - along with YC’s $375k SAFE.
              </li>
              <Typography variant="body1" className={classes.exText}>
                For example, if your MFN valuation is $30mn, 8vdX will get only
                ~ 0.167% stake, further diluted during the next priced round.
              </Typography>
            </ul>
            <Typography variant="body1" className={classes.hdText}>
              B) need extension to repay - issue additional SAFE
            </Typography>
            <ul className={classes.ulBox}>
              <li className={classes.liBox}>
                Let’s say you take additional two months to repay i.e. pay by
                November 15th, 2022.
              </li>
              <li className={classes.liBox}>
                You’ll issue additional bonus SAFE for $33.34k (=2*16,667) for
                the extension period of two months.
              </li>
            </ul>
            <Typography variant="body1" className={classes.bonusText}>
              Bonus SAFEs:
            </Typography>
            <ul className={classes.ulBox}>
              <li className={classes.liBox}>
                $83.34k Bonus SAFE converts into equity immediately before the
                next priced round. Refer to the Bonus SAFE section for more
                details.
              </li>
            </ul>
            <Typography variant="body1" className={classes.batchText}>
              A detailed FAQ on Uncapped, MFN SAFE is available on Bookface; we
              would be happy to get on a call to explain its workings.
            </Typography>
            {ycBatch === 'S22' && (
              <>
                <Typography variant="body1" className={classes.debtFeeText}>
                  *MFN period starts from the date of signing of debt documents.
                </Typography>
                <Typography variant="body1" className={classes.debtFeeText}>
                  **Debt processing fees of 1%. Banks typically charge 1-2% loan
                  processing fees. There are no charges/fees other than those
                  referred to in this document.
                </Typography>
              </>
            )}
          </>
        )}
        <Typography variant="h4" className={classes.dHSText}>
          How is 8vdX founder-friendly?
        </Typography>
        {loanType === 'BRIDGE_TO_DEMO_DAY' && (
          <ul className={classes.ulBox}>
            <li className={classes.liBox}>
              8vdX money is available immediately anytime between your
              acceptance to YC and Demo Day
            </li>
            <li className={classes.liBox}>
              Capital is available without any personal guarantees from
              co-founders, their credit scores, or cash flow sweeps
            </li>
            <li className={classes.liBox}>Cheaper capital: </li>
            <Typography variant="body1" className={classes.subUlText}>
              a) zero cash interest rates,
            </Typography>
            <Typography variant="body1" className={classes.subUlText}>
              b) 5-10 times lower dilution compared to VC funding
            </Typography>
            <li className={classes.liBox}>
              The company will achieve higher KPIs with our loan, meaning Demo
              Day raise at a higher valuation
            </li>
            {ycBatch !== 'S22' && (
              <li className={classes.liBox}>
                8vdX will not take any coercive measures if the company defaults
                on its repayments
              </li>
            )}
          </ul>
        )}
        {loanType === 'BRIDGE_TO_YC_FUND' && (
          <ul className={classes.ulBox}>
            <li className={classes.liBox}>
              8vdX money is available immediately any time between your
              acceptance to YC and Demo Day
            </li>
            <li className={classes.liBox}>
              Capital is available without any personal guarantees from
              co-founders, credit scores, or cash flow sweeps
            </li>
            <li className={classes.liBox}>Cheaper capital: </li>
            <Typography variant="body1" className={classes.subUlText}>
              a) zero cash interest rates,
            </Typography>
            <Typography variant="body1" className={classes.subUlText}>
              b)5-10 times lower dilution compared to VC funding
            </Typography>
            <li className={classes.liBox}>
              Startups are proven to achieve higher KPIs with our loan, allowing
              for a Demo Day raise at a higher valuation
            </li>
          </ul>
        )}
      </Box>
      <Box className={classes.dealsFooter}>
        <Typography variant="body1" className={classes.copyRText}>
          Copyright © 2021 8vdX. All rights reserved.
        </Typography>
      </Box>

      <Dialog
        open={modalOpen}
        maxWidth={'md'}
        title="Non-Binding Term Sheet for Bridge Funding to XYZ"
        buttonText="Continue"
        className={classes.dealsModel}
        handleClose={() => setModalOpen(false)}
      >
        <Box className={classes.termSheetBox}>
          <Typography variant="body1" className={classes.mSubTxt}>
            This Term Sheet shall not be binding, save and except provided
            herein. It is expressly understood that the consummation of the
            Proposed Transaction shall remain subject to further negotiations
            and execution of the Transaction Documents.
          </Typography>
          <Typography variant="h5" className={classes.mHeadTxt}>
            Summary of the Principal terms and conditions
          </Typography>
          <table className={classes.tTable}>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Company Name</th>
              <td className={classes.tTd}>M/s [XYZ]</td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Company Founder/s</th>
              <td className={classes.tTd}>
                <p>1.</p>
                <p>2.</p>
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Potential Investors/Lenders: </th>
              <td className={classes.tTd}>8vdX and its affiliates</td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>
                Brief Description of the Investment
              </th>
              <td className={classes.tTd}>
                Investors will extend the Company a Bridge Capital of up to US$
                150,000 for 6 months without any interest. At the end of 6
                months, Investors will receive bonus shares/SAFE worth 20% of
                the Bridge Capital Amount i.e. US$ 30,000 at Demo Day valuation
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Processing Fee</th>
              <td className={classes.tTd}>1% of Bridge Capital Amount</td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Conversion Option </th>
              <td className={classes.tTd}>
                At the end of six months, the company will have an option to
                convert any unpaid amount of Bridge Capital into a SAFE. The
                terms are stated below.
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Terms of SAFE (Post Conversion)</th>
              <td className={classes.tTd}>
                The terms of the SAFE note used for conversion of unpaid Bridge
                Capital will be same as the terms of first SAFE signed by the
                company after getting in YC. It is assumed that this will be the
                lowest valuation SAFE post admission to YC. If not, lowest
                valuation SAFE will be considered. If company hasn’t not signed
                any new SAFE after getting into YC, terms will be same as the
                SAFE signed by the company with Y Combinator.
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Support for the facility</th>
              <td className={classes.tTd}>
                <ul>
                  <li>
                    The Company shall provide monthly operational updates of
                    their key metrics
                  </li>
                  <li>
                    The Company shall inform 8vdX about any key events such as a
                    Fundraise, Changes in the top management and other major
                    business updates that result in changes in the capital
                    structure.
                  </li>
                </ul>
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Disbursements</th>
              <td className={classes.tTd}>
                The funds will be disbursed within [7] business days of signing
                of definitive agreements between the parties.
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Event of Default </th>
              <td className={classes.tTd}>
                <p>
                  Event of default will occur in case any of the above-mentioned
                  Covenants are not adhered to.
                </p>
                <p>
                  In case there’s any default in above covenants beyond 30 days,
                  the investors will remain on the top of the capital structure
                  until the company is either wound down, sold or has another
                  form of exit.
                </p>
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Governing Law and Jurisdiction</th>
              <td className={classes.tTd}>
                This Term Sheet and their enforcement will be governed by, and
                construed in accordance with, the [federal laws of United States
                of America and the courts in Delaware] shall have exclusive
                jurisdiction.
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Documentation </th>
              <td className={classes.tTd}>
                The Company will endeavor to render the necessary cooperation by
                providing all the requisite documentation and papers to the
                investors, Eight Capital Team, their Counsel and appointed
                parties to complete necessary regulatory requirements
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Validity of Term Sheet </th>
              <td className={classes.tTd}>
                This Term Sheet is valid for a period of [90] days from the date
                of signing of this document.
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>
                Condition Precedent to closing of the transaction
              </th>
              <td className={classes.tTd}>
                <p>
                  The Investor shall invest in the Company subject to the
                  fulfilment of customary conditions precedent to the
                  satisfaction of the Investor, including the following:
                </p>
                <ul>
                  <li>
                    The Company has been accepted to Y Combinator’s Core Program
                    for Winter 2022 batch
                  </li>
                  <li>
                    Satisfactory execution of the Transaction Document including
                    facility
                  </li>
                </ul>
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Confidentiality</th>
              <td className={classes.tTd}>
                Unless otherwise required by applicable law, no Party shall make
                any disclosure with respect to this Term Sheet (including its
                existence or terms) without the prior written consent of the
                other Parties, except to the extent the disclosure is made to
                the professional advisors of any Party appointed for the
                purposes of the Proposed Transaction, on a strict need to know
                basis, provided that such Party shall ensure compliance by such
                persons of the confidentiality obligation under this Term Sheet.
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Good Faith</th>
              <td className={classes.tTd}>
                The Parties shall, in good faith, co-operate with one another
                and perform or cause to be done and performed all such further
                acts and things and shall execute and deliver all such other
                agreements, certificates, instruments and documents as the other
                Parties may reasonably request in order to carry out the
                commercial intent of the Parties and to accomplish the purpose
                of this Term Sheet and the consummation of the Proposed
                Transaction contemplated hereby. Each Party shall act reasonably
                to give full effect to this Term Sheet and the consummation of
                the Proposed Transaction contemplated hereby.
              </td>
            </tr>
            <tr className={classes.tTr}>
              <th className={classes.tTh}>Non-Binding</th>
              <td className={classes.tTd}>
                Except for the provisions in the paragraphs titled
                “Confidentiality”, “Governing Law and Jurisdiction”, “No
                Partnership”, “Expenses” and this paragraph, “NonBinding”, this
                Term Sheet is not binding on the Parties and is intended to
                serve only as a basis for discussions in relation to the
                Proposed Transaction.{' '}
              </td>
            </tr>
          </table>
          <Typography variant="body1" className={classes.forText}>
            For 8vdX
          </Typography>
          <Box className={classes.forBox}>
            <Typography
              variant="body1"
              className={classes.forBext}
            ></Typography>
            <Box className={classes.forDate}>
              <Typography
                variant="body1"
                className={classes.forSText}
              ></Typography>
              <Typography variant="body1" className={classes.forSDate}>
                Date
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1" className={classes.forText}>
            For the Company
          </Typography>
          <Box className={classes.forBox}>
            <Typography
              variant="body1"
              className={classes.forBext}
            ></Typography>
            <Box className={classes.forDate}>
              <Typography
                variant="body1"
                className={classes.forSText}
              ></Typography>
              <Typography variant="body1" className={classes.forSDate}>
                Date
              </Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Deal;
