import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  Button as MuiButton,
} from '@material-ui/core';
import { isEmpty, get } from 'lodash';

import {
  Box,
  StyledTableCell,
  StyledTableRow,
  Loader,
  WrappedSelect,
  SnackBar,
} from 'components';
import { getDealActionList } from 'common/utils/helpers';
import {
  VC_ADMIN_DEAL_STAGE,
  VC_ADMIN_DEAL_SOURCE,
} from 'common/utils/constants';

import styles from './styles';
import { HtmlTooltip } from '../../../components/Wrappers/Tooltip';
import { DeleteDealDialog } from '../DialogBox';

const headers = (array: any[]) => {
  return (
    <>
      {array.map((i: any, n: number) => {
        return (
          <StyledTableCell
            key={n}
            style={{
              textAlign: i.textAlign ? i.textAlign : 'left',
            }}
          >
            {i.name}
          </StyledTableCell>
        );
      })}
    </>
  );
};

const TABLE_HEADERS = [
  { name: 'Sr.no', textAlign: 'left' },
  { name: 'Company Name', textAlign: 'left' },
  {
    name: 'Sector',
    textAlign: 'left',
  },
  { name: 'Emails', textAlign: 'left' },

  { name: 'Status', textAlign: 'left' },
  { name: 'Actions', textAlign: 'left' },
];

const AllDealTable = ({
  setSelectedDeal,
  setModal,
  filteredSearchData,
  isLoading,
  errorMessage,
  getAllVcDeals,
}: any) => {
  const classes = styles();

  const [dealData, setDealData] = React.useState<any>({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState<boolean>(
    false,
  );
  const [openSnackBar, setOpenSnackBar] = React.useState<boolean>(false);

  const handleActionChange = (event: any, rowData: any) => {
    const value = get(event, 'target.value');
    if (value === 'deleteForEveryone') {
      setDealData({ ...rowData });
      setIsOpenDeleteModal(true);
    }
  };

  const handleCloseSnackBar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const actionSelect = (rowData: any) => {
    return (
      <WrappedSelect
        dropdowns={getDealActionList()}
        onClickChildren={(value: string) =>
          handleActionChange(
            {
              target: {
                value,
              },
            },
            rowData,
          )
        }
        value={''}
        onChange={(event: any) => {
          handleActionChange(event, rowData);
        }}
        className={classes.statusSelectFeild}
        InputProps={{ classes: { root: classes.inputRoot } }}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
            focused: classes.labelFocused,
          },
        }}
      />
    );
  };

  const getDealStatus = (rowData: any) => {
    if (
      get(rowData, 'dealStage') === VC_ADMIN_DEAL_STAGE.LEAD &&
      get(rowData, 'dealSource') === VC_ADMIN_DEAL_SOURCE.IMPORT
    ) {
      return 'Lead - 8vdX Deal';
    } else if (
      get(rowData, 'dealStage') === VC_ADMIN_DEAL_STAGE.LEAD &&
      get(rowData, 'dealSource') === VC_ADMIN_DEAL_SOURCE.MANUAL
    ) {
      return 'Lead - 8vdX Deal';
    } else if (get(rowData, 'dealStage') === VC_ADMIN_DEAL_STAGE.INTERESTED)
      return 'Interested';
    else if (get(rowData, 'dealStage') === VC_ADMIN_DEAL_STAGE.SEEK_INFO)
      return 'Info Requested';
    else if (
      get(rowData, 'dealStage') === VC_ADMIN_DEAL_STAGE.MEETING_SCHEDULED
    )
      return 'Meeting Scheduled';
    else if (get(rowData, 'dealStage') === VC_ADMIN_DEAL_STAGE.DUE_DILIGENCE)
      return 'Diligence ongoing';
    else if (get(rowData, 'dealStage') === VC_ADMIN_DEAL_STAGE.MOVE_TO_IC)
      return 'Presented to IC';
    else return 'Lead';
  };

  return (
    <>
      <Box className={classes.firmAdminTableBox}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>{headers(TABLE_HEADERS)}</TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={TABLE_HEADERS.length}>
                    <Box className={classes.loader}>
                      <Loader />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : isEmpty(filteredSearchData) && !errorMessage ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={TABLE_HEADERS.length}>
                    <Box className={classes.loader}>
                      <Typography>No data found in table</Typography>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                (filteredSearchData || []).map((row: any, index: number) => (
                  <StyledTableRow hover key={`${row.id}_${index}`}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      onClick={() => {
                        setSelectedDeal(get(row, 'id'));
                        setModal(true);
                      }}
                    >
                      <p
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          margin: 0,
                        }}
                      >
                        {isEmpty(get(row, 'companyName'))
                          ? '-'
                          : get(row, 'companyName')}
                      </p>
                      {!isEmpty(get(row, 'ycBatch')) && (
                        <span>
                          <Chip
                            className={classes.ycTagText}
                            label={get(row, 'ycBatch')}
                          />
                        </span>
                      )}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      <p
                        style={{
                          margin: 0,
                        }}
                      >
                        {get(row, 'sector') ? get(row, 'sector') : '-'}
                      </p>
                      {!isEmpty(get(row, 'tags')) && (
                        <span>
                          {get(row, 'tags')
                            .split(',')
                            .map((item: any) => (
                              <>
                                <Chip
                                  className={classes.tagText}
                                  label={item}
                                />
                              </>
                            ))}
                        </span>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ textAlign: 'center' }}
                    >
                      <>
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          {get(row, 'email') ? (
                            <>
                              {get(row, 'email')}
                              <Chip
                                className={classes.tagText}
                                label={'Primary'}
                              />
                            </>
                          ) : (
                            '-'
                          )}
                        </p>
                        {!isEmpty(get(row, 'otherEmails')) && (
                          <>
                            <Box className={classes.otherEmailBox}>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    {get(row, 'otherEmails')
                                      .split(',')
                                      .map((item: any) => (
                                        <Typography
                                          key={item}
                                          className={classes.tooltipText}
                                        >
                                          {item}
                                        </Typography>
                                      ))}
                                  </React.Fragment>
                                }
                                placement="bottom-end"
                              >
                                <MuiButton className={classes.otherEmailBtn}>
                                  Other Emails
                                </MuiButton>
                              </HtmlTooltip>
                            </Box>
                          </>
                        )}
                      </>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {getDealStatus(row)}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.actionButtonBox}
                      style={{ paddingLeft: 2 }}
                    >
                      {actionSelect(row)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {isOpenDeleteModal && (
        <DeleteDealDialog
          isOpenDeleteModal={isOpenDeleteModal}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
          dealData={dealData}
          refreshDeals={() => getAllVcDeals()}
          closeDialog={() => {
            setDealData({});
          }}
          title={'Delete Prospective Deal'}
          setOpenSnackBar={setOpenSnackBar}
        />
      )}
      {openSnackBar && (
        <SnackBar
          open={openSnackBar}
          handleClose={handleCloseSnackBar}
          title="Deal deleted successfully"
          type="success"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        />
      )}
    </>
  );
};

export default AllDealTable;
