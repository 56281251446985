import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import { Box, PageHeader } from 'components';
import { getActionsList } from 'redux-modules/global/Actions';

import { Banner } from './components';
import styles from './styles';
import VcQuickStats from './components/QuickStats/VcQuickStats';

const VcDashboard = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const user = useSelector(({ Auth }: RootState) => Auth.user);

  React.useEffect(() => {
    const role = get(user, 'role', '');
    const status = 'PENDING';
    const startUpId = get(user, 'startUpId', '');
    const payload = {
      role,
      status,
      startUpId,
    };
    dispatch(getActionsList(payload));
  }, []);

  return (
    <Box className={classes.welcomeBox}>
      {!get(user, 'name') ? (
        <Banner name={get(user, 'name')} />
      ) : (
        <Box className={classes.dashWelcomeBox}>
          <Box className={classes.dashVcWelcomeLeft}>
            <PageHeader name={get(user, 'name')} />
            <Box className={classes.dashLVCContent}>
              <VcQuickStats />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default VcDashboard;
