import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import SubscriptionList from './SubscriptionList';

export const VcFirmSubscriptionRoute: IRoute = {
  path: '/subscription',
  component: SubscriptionList,
  layout: DashboardLayout,
  exact: true,
};
