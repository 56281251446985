import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  //   DashBoard page Right styling ------- start
  dashWelRightContent: {
    padding: '90px 35px 50px',
    '@media (max-width: 767px)': {
      padding: '60px 5% 30px',
    },
    overflowY: 'scroll',
  },
  dashWelRightContBox: {
    background: theme.palette.success.light,
    borderRadius: 15,
    padding: 20,
    paddingTop: 75,
    position: 'relative',
  },
  illustateImg: {
    position: 'absolute',
    width: 84.15,
    height: 72.43,
    top: -15,
  },
  iIlustraImg: {
    position: 'absolute',
    width: 52.25,
    height: 90,
    top: -33,
    right: 20,
  },
  ticketText: {
    fontSize: 14.5,
    fontWeight: 600,
    letterSpacing: 0.34,
    color: theme.palette.grey[400],
    marginBottom: 8,
  },
  ticketSubText: {
    marginBottom: 25,
    color: theme.palette.grey[50],
    fontSize: 11.5,
    letterSpacing: 0.43,
  },
  showBtn: {
    background: theme.palette.grey[100],
    height: 42,
    width: '100%',
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  /////////
  paymentContBox: {
    position: 'relative',
  },
  longTermText: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.33,
    lineHeight: '30px',
    color: theme.palette.grey[500],
    marginBottom: 14,
  },
  tenureProgress: {
    width: 180,
    height: 180,
    margin: 20,
  },
  monthCountText: {
    fontSize: 20,
    fontWeight: 600,
    color: '#171725',
    lineHeight: '27.16px',
    textAlign: 'center',
  },
  monthText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#696974',
    marginTop: 2,
    lineHeight: '16.05px',
    textAlign: 'center',
  },
  upcomingPaymentText: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.33,
    color: theme.palette.grey[500],
    marginBottom: 14,
    marginTop: 25,
    lineHeight: '30px',
  },
  paymentDataBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 75,
  },
  paymentDataLeft: {},
  dateText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.grey[400],
    marginBottom: 2,
  },
  installmentText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16.05px',
    color: theme.palette.grey[50],
    opacity: 0.7,
  },
  paymentDataRight: {},
  amountText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: 0.46,
    color: theme.palette.grey[400],
  },
  status: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: 0.46,
    marginBottom: 10,
  },
  statusContBox: {
    background: theme.palette.success.light,
    borderRadius: 15,
    padding: 20,
    marginBottom: 50,
  },
  statusText: {
    fontSize: '14.5px',
    fontWeight: 700,
    letterSpacing: 0.46,
    color: theme.palette.grey[400],
  },
}));
