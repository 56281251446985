import React, { useEffect } from 'react';
import {
  Typography,
  MenuItem,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import history from 'common/utils/history';
import { Sidebar, Box, Stepper } from 'components';
import { Logo, Ornament } from 'assets';
import { getBatches, handleSignOut } from 'redux-modules/auth/Actions';
import {
  handleGetStartUpDetails,
  handleGetStartupDetailsById,
  emptyEnquiryForm,
} from 'redux-modules/enquiryForm/Actions';
import { trackBridgeLoanProcess } from 'redux-modules/enquiries/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { StepProps } from 'common/types';
import { getStartupTerms, getStartupLoan } from 'services';

import {
  CompanyDetails,
  OtherInformationDetails,
  ApplyBridgeDebt,
  UploadDocuments,
  DocuSignView,
  LoanTerms,
} from './Steps';
import styles from './styles';

const typeSteps = [
  'BASIC_DETAILS_FORM',
  'LOAN_TERMS',
  'SUPPORTING_DOCUMENTS',
  'E_SIGNATURE',
  'APPROVALS',
  'FUND_DISBURSEMENT',
];

function getSteps() {
  return [
    { name: 'Basic Details', key: 'BASIC_DETAILS_FORM' },
    { name: 'Loan Terms', key: 'LOAN_TERMS' },
    { name: 'Upload Documents', key: 'UPLOAD_DOCUMENTS' },
    { name: 'E-Signature', key: 'E_SIGNATURE' },
    { name: 'Approval', key: 'APPROVALS' },
    { name: 'Disbursement', key: 'FUND_DISBURSEMENT' },
  ];
}

function getStepContent(step: number, details: boolean, props: StepProps) {
  switch (step) {
    case 0:
      return details ? (
        <CompanyDetails {...props} />
      ) : (
        <ApplyBridgeDebt {...props} />
      );
    case 1:
      return <LoanTerms {...props} />;
    case 2:
      return <UploadDocuments {...props} />;
    case 3:
      return <DocuSignView {...props} />;
    default:
      return <OtherInformationDetails {...props} />;
  }
}

const EnquiryForm = ({ match }: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const type = get(match, 'params.type');
  const steps = getSteps();
  const search = history.location.search;
  const event = new URLSearchParams(search).get('event');

  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(0);
  const [details, setDetails] = React.useState<boolean>(false);
  const [stepName, setStepName] = React.useState<string>(typeSteps[activeStep]);
  const [enquiryId, setEnquiryId] = React.useState<string>('');
  const [isLogout, setIsLogout] = React.useState<boolean>(false);
  const [allStepDisable, setAppStepDisable] = React.useState<boolean>(false);
  const [loan, setLoan] = React.useState<any>({});
  const [loadingTerms, setLoadingTerms] = React.useState<boolean>(false);
  const [loanTerms, setLoanTerms] = React.useState<any>({});
  const [scheduled, setScheduled] = React.useState<boolean>(false);

  const { bridgeLoanProcess, isLoading } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );
  const {
    user: { startUpId },
  } = useSelector(({ Auth }: RootState) => Auth);
  const { id } = useSelector(({ EnquiryForm }: RootState) => EnquiryForm);

  useEffect(() => {
    setLoadingTerms(true);
    getStartupTerms('BRIDGE_LOAN')
      .then((res: any) => {
        if (get(res, 'meetingScheduled')) {
          setAppStepDisable(true);
          setScheduled(true);
        }
        setLoanTerms(res);
      })
      .finally(() => setLoadingTerms(false));
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (!get(bridgeLoanProcess, 'documentsSigned'))
      setCompletedSteps(activeStep + 1);
    setStepName(typeSteps[activeStep + 1]);
  };

  const detailPage = () => {
    setDetails(true);
  };
  const previousPage = () => {
    setDetails(false);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setStepName(typeSteps[activeStep - 1]);
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
    setStepName(typeSteps[index]);
  };

  useEffect(() => {
    if (startUpId) {
      getStartupLoan(`${startUpId}/BRIDGE_LOAN`).then((res) => setLoan(res));
    }
  }, [startUpId]);

  useEffect(() => {
    dispatch(getBatches());
    dispatch(handleGetStartUpDetails(startUpId));
    dispatch(trackBridgeLoanProcess(startUpId));
    if (type === 'NEW') {
      dispatch(handleGetStartupDetailsById(startUpId));
    }
  }, []);

  useEffect(() => {
    const acknowledgedByStartup = get(loanTerms, 'acknowledgedByStartup');
    if (!get(bridgeLoanProcess, 'documentsSigned') && scheduled) {
      setActiveStep(1);
      setCompletedSteps(1);
    } else if (
      ![null, undefined].includes(acknowledgedByStartup) &&
      !acknowledgedByStartup
    ) {
      setActiveStep(1);
      setCompletedSteps(1);
    } else {
      if (event) {
        setCompletedSteps(4);
        setActiveStep(3);
        if (event === 'signing_complete') {
          setAppStepDisable(true);
        }
      }
      if (!event && bridgeLoanProcess && !isLoading) {
        if (get(bridgeLoanProcess, 'isLoanProcessed')) {
          setActiveStep(4);
          setCompletedSteps(6);
          setStepName(typeSteps[4]);
        } else if (get(bridgeLoanProcess, 'isLoanApproved')) {
          setActiveStep(4);
          setCompletedSteps(5);
          setStepName(typeSteps[4]);
        } else if (get(bridgeLoanProcess, 'documentsSigned')) {
          setActiveStep(4);
          setCompletedSteps(4);
          setStepName(typeSteps[4]);
        } else if (get(bridgeLoanProcess, 'documentsSubmitted')) {
          setActiveStep(3);
          setCompletedSteps(3);
          setStepName(typeSteps[3]);
        } else if (get(bridgeLoanProcess, 'loanTermAdded')) {
          setActiveStep(2);
          setCompletedSteps(2);
          setStepName(typeSteps[2]);
        } else if (get(bridgeLoanProcess, 'basicInfoAdded')) {
          setActiveStep(1);
          setCompletedSteps(1);
          setStepName(typeSteps[1]);
        }
      }
    }
  }, [bridgeLoanProcess, isLoading, scheduled, loanTerms]);

  const handleSignOutButton = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Intercom('shutdown');
    setIsLogout(true);
    dispatch(handleSignOut());
    dispatch(emptyEnquiryForm());
    setIsLogout(false);
  };

  const Buttons = () => (
    <Box className={classes.leftBottomSection}>
      <Box className={classes.leftLinks}>
        <MenuItem className={classes.logoutBtn} onClick={handleSignOutButton}>
          {isLogout && <CircularProgress color="inherit" size={20} />}
          Logout
        </MenuItem>
      </Box>
    </Box>
  );

  return (
    <div className={classes.root}>
      <Box className={classes.dashContainer}>
        <Box className={classes.dashleft}>
          <Sidebar className={classes.sidebarFixed}>
            <Box className={classes.dashLogo}>
              <img src={Logo} alt={Logo} className={classes.logoImg} />
              <Typography variant="h3" className={classes.logoText}>
                Application
              </Typography>
            </Box>
            {!isLoading && (
              <Stepper
                activeStep={activeStep}
                completedSteps={completedSteps}
                steps={steps}
                orientation={true}
                handleStepClick={handleStepClick}
                allStepDisable={allStepDisable}
              />
            )}
          </Sidebar>
          <Box>{Buttons()}</Box>
        </Box>
        {!isLoading ? (
          <Box className={classes.dashRight}>
            <Box className={classes.dashHeader}>
              <Typography variant="h3" className={classes.linkdashText}>
                {/* {!isEmpty(bridgeLoanProcess) &&
                get(bridgeLoanProcess, 'documentsSigned') ? ( */}
                <span
                  onClick={() => history.push('/dashboard')}
                  className={classes.goToDashBoardBtn}
                >
                  Go to dashboard
                </span>
                {/* ) : null} */}
                {/* <strong className={classes.needHelp}>Need Help?</strong> */}

                <span
                  className={classes.contactus}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onClick={() => window.Intercom('show')}
                >
                  Contact Us
                </span>
              </Typography>
              <img
                src={Ornament}
                alt={Ornament}
                className={classes.imgOrnament}
              />
            </Box>
            {getStepContent(activeStep, details, {
              handleNext: handleNext,
              handleBack: handleBack,
              stepName,
              id,
              startUpId,
              type,
              detailPage,
              previousPage,
              setEnquiryId,
              enquiryId,
              loadingTerms,
              scheduled,
              setScheduled,
              loanStatus: get(loan, 'loanStatus'),
            })}
          </Box>
        ) : (
          <div className={classes.skeletonWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Skeleton
                  style={{ marginBottom: 10 }}
                  count={10}
                  enableAnimation
                  height={32}
                />
              </Grid>
              <Grid item xs={9}>
                <Skeleton
                  style={{ marginBottom: 10 }}
                  count={10}
                  enableAnimation
                  height={32}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Box>
    </div>
  );
};

export default EnquiryForm;
