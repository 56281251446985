import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import { FN, InviteUser } from 'common/types';
import { ROLES_OPTION } from 'common/utils/constants';
import { inviteUserService, updateUserService } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import {
  Button,
  Box,
  WrappedTextInput,
  WrappedSelect,
  Banner,
} from '../../../components';
import styles from './styles';
import { addUserSchema } from './validations';

type Props = {
  handleBack: FN;
  startupId: string | undefined;
  user?: InviteUser | null;
  getUserList: FN;
};
const AddUser = ({ handleBack, user, getUserList }: Props) => {
  const classes = styles();

  const { role } = useSelector(({ Auth }: RootState) => Auth.user);

  const { handleSubmit, control, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(addUserSchema),
    defaultValues: {
      name: '',
      email: '',
      roleId: role,
    },
  });

  const [successPop, setSuccessPop] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const saveUser = async (userId: string | undefined, payload: any) => {
    try {
      setIsLoading(true);
      if (!userId) {
        await inviteUserService(role, payload);
        setSuccessMessage('User Invited Successfully');
        setIsLoading(false);
      } else {
        await updateUserService(role, userId, payload);
        setSuccessMessage('User Updated Successfully');
        setIsLoading(false);
      }
    } catch (err) {
      const message: string = errorMessageHandler(err) || get(err, 'message');
      setIsLoading(false);
      setErrorMessage(message);
    }
  };

  const onSubmit = () => {
    const payload = {
      ...getValues(),
    };
    saveUser(user?.id, payload);
  };

  useEffect(() => {
    if (!isEmpty(successMessage)) {
      getUserList();
      setSuccessPop(true);
    }
  }, [successMessage]);

  useEffect(() => {
    if (!isEmpty(user)) {
      reset({
        name: get(user, 'name'),
        email: get(user, 'email'),
        roleId: get(user, 'roleId'),
      });
    }
  }, [user]);

  return (
    <div>
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      {successPop ? (
        <Banner title={successMessage} subTitle={''} source={'SuccessBanner'} />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} data-testid="addUserForm">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.labelText}
                >
                  Name<span className={classes.errorRequiredText}>*</span>
                </Typography>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Name"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.labelText}
                >
                  Email<span className={classes.errorText}>*</span>
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Email"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled={user?.id ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Controller
                  name="roleId"
                  control={control}
                  // defaultValue={role}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <>
                      <WrappedSelect
                        value={role}
                        onChange={onChange}
                        label=""
                        className={classes.textValInputUserType}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        dropdowns={ROLES_OPTION}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Box className={classes.backContinueBox}>
              <Button
                name="Save"
                type="submit"
                className={classes.continueBtn}
                isLoading={isLoading}
                disabled={isLoading}
              />
              <Button
                onClick={() => handleBack()}
                className={classes.backBtn}
                name="Cancel"
              />
            </Box>
          </form>
        </>
      )}
    </div>
  );
};

export default AddUser;
