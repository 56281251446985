import React, { ChangeEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputBase,
} from '@material-ui/core';

import history from 'common/utils/history';
import { errorMessageHandler } from 'common/utils/helpers';
import { RootState } from 'redux-modules/store/rootState';
import {
  Box,
  WrappedSelect,
  Loader,
  Button,
  StyledTableCell,
  StyledTableRow,
} from 'components';
import { getBatches } from 'redux-modules/auth/Actions';
import { Ornament } from 'assets';
import { getAdminPortfolioList } from 'services';
import { PortFolioParams } from 'common/types';

import styles from './styles';

const limit = 20;

const fundTypes = [
  {
    text: 'ALL',
    value: 'ALL',
  },
  {
    text: 'BRIDGE_LOAN',
    value: 'BRIDGE_LOAN',
  },
  {
    text: 'VENTURE_DEBT',
    value: 'VENTURE_DEBT',
  },
];

const PORTFOLIO_TABLE_HEADERS = [
  'Company Name',
  'Location',
  'YC Batch',
  'Fund Amount',
  'Fund Type',
  'Status',
  'Actions',
];

const PortFolioList = () => {
  const dispatch = useDispatch();
  const [ycBatch, setYcBatch] = React.useState<string>('ALL');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [page, setPage] = React.useState(1);
  const [fundType, setFundType] = React.useState<string>('ALL');
  const { ycBatches } = useSelector(({ Auth }: RootState) => Auth);
  const [portfolios, setPortfolios] = React.useState<any>([]);
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);

  const getAllPortfolios = (obj: any) => {
    setErrorMessage('');
    setIsLoading(true);
    getAdminPortfolioList(obj)
      .then((res) => {
        setPortfolios(get(res, 'data'));
        setFilteredSearchData(get(res, 'data'));
        setIsLoading(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    const obj: PortFolioParams = {
      fundType: 'ALL',
      ycBatch: 'ALL',
      page,
      limit,
    };
    getAllPortfolios(obj);
  }, []);

  const classes = styles();

  const onCompanySearch = (str: string) => {
    const filteredData = portfolios.filter((value: any) => {
      if (
        str === '' ||
        value.startupName
          ?.toString()
          .toLowerCase()
          .includes(str?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
  };

  const Headers = (array: string[]) => {
    return (
      <>
        {array.map((i: string, n: number) => {
          return <StyledTableCell key={n}>{i}</StyledTableCell>;
        })}
      </>
    );
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>, type: string) => {
    const obj: PortFolioParams = {
      fundType: fundType,
      ycBatch: ycBatch,
      page: 1,
      limit,
    };
    if (type === 'type') {
      obj['fundType'] = e.target.value;
      setFundType(e.target.value);
    }

    if (type === 'batch') {
      obj['ycBatch'] = e.target.value;
      setYcBatch(e.target.value);
    }
    setPage(1);
    getAllPortfolios(obj);
  };

  useEffect(() => {
    if (ycBatches.length === 0) {
      dispatch(getBatches());
    }
  }, []);

  const handleLoadMore = (type: number) => {
    const pageNumber = type === 0 ? page - 1 : page + 1;
    const obj: PortFolioParams = {
      fundType: fundType,
      ycBatch: ycBatch,
      page: pageNumber,
      limit,
    };
    setPage(pageNumber);
    getAllPortfolios(obj);
  };

  return (
    <Box className={classes.enquiryListBox}>
      <Box className={classes.enquiryListhead}>
        <Box className={classes.enquiryListLeft}>
          <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
          <Typography
            variant="h3"
            gutterBottom
            className={classes.enquiryHeadText}
          >
            Portfolio
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.enquirySubHeadText}
          >
            {portfolios.length > 1 && portfolios.length} Companies
          </Typography>
        </Box>
        <Box className={classes.enquiryListRight}>
          <FormControl required className={classes.formControl}>
            <Typography
              variant="caption"
              gutterBottom
              className={classes.batchLabel}
            >
              YC Batch
            </Typography>
            <WrappedSelect
              // label="Batch"
              variant={'outlined'}
              value={ycBatch}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleChange(e, 'batch')
              }
              placeholder="Batch"
              className={classes.selectValInput}
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              dropdowns={[
                ...[
                  {
                    text: 'ALL',
                    value: 'ALL',
                  },
                ],
                ...ycBatches,
                ...[
                  {
                    text: 'Non YC',
                    value: 'NON_YC',
                  },
                ],
              ]}
            />
            <Typography
              variant="caption"
              gutterBottom
              className={classes.batchLabel}
            >
              Fund Type
            </Typography>
            <WrappedSelect
              // label="Type"
              variant={'outlined'}
              value={fundType}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleChange(e, 'type')
              }
              placeholder="Type"
              className={classes.textValInput}
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              dropdowns={fundTypes}
            />
          </FormControl>
        </Box>
      </Box>

      <InputBase
        placeholder="Search company name"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e: any) => onCompanySearch(get(e, 'target.value'))}
      />
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>{Headers(PORTFOLIO_TABLE_HEADERS)}</TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <Box className={classes.loaderSection}>
                <Loader className={classes.loaderBox} />
              </Box>
            ) : isEmpty(filteredSearchData) && !errorMessage ? (
              <StyledTableRow>
                <StyledTableCell colSpan={PORTFOLIO_TABLE_HEADERS.length}>
                  <Box className={classes.noDatafoundText}>
                    <Typography>No data found in table</Typography>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              filteredSearchData.map((row: any) => (
                <StyledTableRow hover key={row.startupId}>
                  <StyledTableCell component="th" scope="row">
                    {row.startupName}
                  </StyledTableCell>
                  <StyledTableCell>{row.location}</StyledTableCell>
                  <StyledTableCell>
                    {' '}
                    {get(row, 'accelerator') && get(row, 'ycBatch')
                      ? `YC ${get(row, 'ycBatch')}`
                      : get(row, 'accelerator')}
                  </StyledTableCell>
                  {/* <StyledTableCell>
                    {row.isYcVerified ? 'true' : 'false'}
                  </StyledTableCell> */}
                  <StyledTableCell>
                    {row.finalProcessedLoanAmount
                      ? row.finalProcessedLoanAmount
                      : '150000'}
                  </StyledTableCell>
                  <StyledTableCell>
                    <span
                      style={{
                        backgroundColor:
                          row.fundType === 'BRIDGE_LOAN' ? 'green' : 'blue',
                        color: 'white',
                        padding: '4px',
                        fontSize: '10px',
                        borderRadius: 4,
                      }}
                    >
                      {row.fundType}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.loanStatus ? row.loanStatus : 'PENDING'}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.tdBtn}
                      onClick={() =>
                        history.push(`/admin/enquiry-view/${row.enquiryId}`)
                      }
                    >
                      View
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.Buttons}>
        {page >= 2 && (
          <Button
            name="Back"
            className={classes.backButton}
            onClick={() => handleLoadMore(0)}
          />
        )}
        {portfolios && portfolios.length && (
          <Button
            style={{ marginRight: 15 }}
            variant="contained"
            name="Next"
            onClick={() => handleLoadMore(1)}
          />
        )}
      </Box>
    </Box>
  );
};

export default PortFolioList;
