import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import Payments from './Payments';

export const PaymentsRoute: IRoute = {
  path: `/payments`,
  component: Payments,
  layout: DashboardLayout,
  isAdmin: true,
  exact: true,
};
