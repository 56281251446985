import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  benchMarkContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
  },
  benchmarkHead: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '80px 80px 20px 67px',
    '@media (max-width: 1024px)': {
      padding: '90px 80px 30px 20px',
    },
    '@media (max-width: 767px)': {
      padding: '40px 20px 20px 20px',
    },
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 43,
    left: 40,
    '@media (max-width: 1024px)': {
      top: 75,
      left: 5,
      objectFit: 'cover',
      height: 70,
      width: 80,
    },
    '@media (max-width: 767px)': {
      top: 23,
      left: 20,
      objectFit: 'cover',
    },
  },
  headText: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    fontWeight: 400,
    letterSpacing: 0.3,
    position: 'relative',
    top: -24,
    '@media (max-width: 1024px)': {
      top: 'unset',
      fontSize: 24,
    },
  },
  //////////////////////
  benchMarkFormContent: {
    padding: '0px 25px 25px',
    height: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media (max-width: 1024px)': {
      padding: '20px 20px 25px',
      height: 'calc(100vh - 220px)',
    },
    '@media (max-width: 767px)': {
      padding: '20px 15px 25px',
      height: 'auto',
    },
  },
  benchmarkFormContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px 11px 0 0',
    padding: '20px 40px 5px',
    '@media (max-width: 525px)': {
      padding: '15px 25px 5px',
    },
  },
  formHeadingText: {
    marginBottom: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 30,
    lineHeight: '30px',
    letterSpacing: '0.3px',
    color: theme.palette.grey[500],
    textAlign: 'center',
  },
  subHeading: {
    // marginBottom: 20,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '25px',
    color: theme.palette.grey[50],
    letterSpacing: '0.3px',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  startupOptionContainer: {
    marginLeft: 43,
    flexDirection: 'column',
  },
  startupOptionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
  },
  radioGroup: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
  formControlText: {
    '& .MuiFormControlLabel-root': {
      fontWeight: 400,
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
    },
  },
  closeIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: 20,
    right: 20,
    '&:hover': {
      background: 'transparent',
    },
  },
}));
