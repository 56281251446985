import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tabPanelContent: {
    // height: 'calc(100vh - 250px)',
    // overflow: 'auto',
    padding: '10px 20px 20px',
    '@media (max-width: 1024px)': {
      padding: '10px 0px 20px',
    },
  },
  tableContainer: {
    boxShadow: theme.shadows[0],
    height: 'calc(100% - 185px)',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTable-root': {
      // height: '100%',
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
    '& .MuiTableCell-root': {
      padding: '16px 16px 10px',
    },
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
}));
