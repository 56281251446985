import { PayloadType } from 'common/types';

export const ADMIN_GET_ENQUIRY_BY_ID_EMPTY =
  'admin/ADMIN_GET_ENQUIRY_BY_ID_EMPTY';

export const ADMIN_GET_ENQUIRY_BY_ID = 'admin/ADMIN_GET_ENQUIRY_BY_ID';
export const ADMIN_GET_ENQUIRY_BY_ID_LOADING =
  'admin/ADMIN_GET_ENQUIRY_BY_ID_LOADING';
export const ADMIN_GET_ENQUIRY_BY_ID_SUCCESS =
  'admin/ADMIN_GET_ENQUIRY_BY_ID_SUCCESS';
export const ADMIN_GET_ENQUIRY_BY_ID_FAILURE =
  'admin/ADMIN_GET_ENQUIRY_BY_ID_FAILURE';
export const ADMIN_GET_ENQUIRY_BY_STARTUP_ID =
  'ADMIN_GET_ENQUIRY_BY_STARTUP_ID';
export const ADMIN_GET_ENQUIRY_BY_STARTUP_ID_LOADING =
  'ADMIN_GET_ENQUIRY_BY_STARTUP_ID_LOADING';
export const GET_FUND_TYPE = 'GET_FUND_TYPE';
export const GET_FUND_TYPE_LOADING = 'GET_FUND_TYPE_LOADING';
export const GET_FUND_TYPE_SUCCESS = 'GET_FUND_TYPE_SUCCESS';
export const GET_FUND_TYPE_FAILURE = 'GET_FUND_TYPE_FAILURE';
export const TRACK_BRIDGE_LOAN_PROCESS = 'TRACK_BRIDGE_LOAN_PROCESS';
export const TRACK_BRIDGE_LOAN_PROCESS_LOADING =
  'TRACK_BRIDGE_LOAN_PROCESS_LOADING';
export const TRACK_BRIDGE_LOAN_PROCESS_SUCCESS =
  'TRACK_BRIDGE_LOAN_PROCESS_SUCCESS';
export const TRACK_BRIDGE_LOAN_PROCESS_FAILURE =
  'TRACK_BRIDGE_LOAN_PROCESS_FAILURE';
export const TRACK_VD_PROCESS = 'TRACK_VD_PROCESS';
export const TRACK_VD_PROCESS_LOADING = 'TRACK_VD_PROCESS_LOADING';
export const TRACK_VD_PROCESS_SUCCESS = 'TRACK_VD_PROCESS_SUCCESS';
export const TRACK_VD_PROCESS_FAILURE = 'TRACK_VD_PROCESS_FAILURE';

export const GET_LOANS_DETAILS_BY_STARTUP_ID =
  'GET_LOANS_DETAILS_BY_STARTUP_ID';
export const GET_LOANS_DETAILS_BY_STARTUP_ID_LOADING =
  'GET_LOANS_DETAILS_BY_STARTUP_ID_LOADING';
export const GET_LOANS_DETAILS_BY_STARTUP_ID_SUCCESS =
  'GET_LOANS_DETAILS_BY_STARTUP_ID_SUCCESS';
export const GET_LOANS_DETAILS_BY_STARTUP_ID_FAILURE =
  'GET_LOANS_DETAILS_BY_STARTUP_ID_FAILURE';

export const GET_YC_DOCS_BY_STARTUP_ID = 'GET_YC_DOCS_BY_STARTUP_ID';
export const GET_YC_DOCS_BY_STARTUP_ID_LOADING =
  'GET_YC_DOCS_BY_STARTUP_ID_LOADING';
export const GET_YC_DOCS_BY_STARTUP_ID_SUCCESS =
  'GET_YC_DOCS_BY_STARTUP_ID_SUCCESS';
export const GET_YC_DOCS_BY_STARTUP_ID_FAILURE =
  'GET_YC_DOCS_BY_STARTUP_ID_FAILURE';
export const GET_LOAN_TERMS_BY_STARTUP_ID = 'GET_LOAN_TERMS_BY_STARTUP_ID';
export const GET_LOAN_TERMS_BY_STARTUP_ID_LOADING =
  'GET_LOAN_TERMS_BY_STARTUP_ID_LOADING';
export const GET_LOAN_TERMS_BY_STARTUP_ID_SUCCESS =
  'GET_LOAN_TERMS_BY_STARTUP_ID_SUCCESS';

export const GET_LOAN_TERMS_BY_STARTUP_ID_FAILURE =
  'GET_LOAN_TERMS_BY_STARTUP_ID_FAILURE';

export const STARTUP_INFO = 'STARTUP_INFO';
export const STARTUP_INFO_LOADING = 'STARTUP_INFO_LOADING';
export const STARTUP_INFO_SUCCESS = 'STARTUP_INFO_SUCCESS';
export const STARTUP_INFO_FAILURE = 'STARTUP_INFO_FAILURE';
export const EMPTY_ENQUIRY_STATE = 'EMPTY_ENQUIRY_STATE';

export const GET_LOAN_CLOSER_INFO = 'GET_LOAN_CLOSER_INFO';
export const GET_LOAN_CLOSER_INFO_LOADING = 'GET_LOAN_CLOSER_INFO_LOADING';
export const GET_LOAN_CLOSER_INFO_SUCCESS = 'GET_LOAN_CLOSER_INFO_SUCCESS';
export const GET_LOAN_CLOSER_INFO_FAILURE = 'GET_LOAN_CLOSER_INFO_FAILURE';

export const HANDLE_LOAN_CLOSER = 'HANDLE_LOAN_CLOSER';
export const HANDLE_LOAN_CLOSER_LOADING = 'HANDLE_LOAN_CLOSER_LOADING';
export const HANDLE_LOAN_CLOSER_SUCCESS = 'HANDLE_LOAN_CLOSER_SUCCESS';
export const HANDLE_LOAN_CLOSER_FAILURE = 'HANDLE_LOAN_CLOSER_FAILURE';

export const GET_REPAID_AMOUNT_SUCCESS = 'GET_REPAID_AMOUNT_SUCCESS';
export const GET_REPAID_AMOUNT_FAILURE = 'GET_REPAID_AMOUNT_FAILURE';
export const HANDLE_GET_REPAID_AMOUNT = 'HANDLE_GET_REPAID_AMOUNT';

export const handleGetEnquiriesbyId = (payload: PayloadType) => {
  return {
    type: ADMIN_GET_ENQUIRY_BY_ID,
    payload,
  };
};

export const getEnquiryByStartupId = (payload: PayloadType) => {
  return {
    type: ADMIN_GET_ENQUIRY_BY_STARTUP_ID,
    payload,
  };
};

export const emptyEnquiryDetails = () => {
  return {
    type: ADMIN_GET_ENQUIRY_BY_ID_EMPTY,
  };
};

export const getFundType = (payload: string | undefined) => {
  return {
    type: GET_FUND_TYPE,
    payload,
  };
};

export const trackBridgeLoanProcess = (payload: string | undefined) => {
  return {
    type: TRACK_BRIDGE_LOAN_PROCESS,
    payload,
  };
};

export const trackVdProcess = (payload: string | undefined) => {
  return {
    type: TRACK_VD_PROCESS,
    payload,
  };
};

export const getLoansDetailsByStartupId = (payload: string | undefined) => {
  return {
    type: GET_LOANS_DETAILS_BY_STARTUP_ID,
    payload,
  };
};

export const getYcDocsByStartupId = (payload: string | undefined) => {
  return {
    type: GET_YC_DOCS_BY_STARTUP_ID,
    payload,
  };
};

export const getLoanTermsByStartupId = (payload: string | undefined) => {
  return {
    type: GET_LOAN_TERMS_BY_STARTUP_ID,
    payload,
  };
};

export const getStartupInfo = () => {
  return {
    type: STARTUP_INFO,
  };
};

export const getLoanCloserInfo = (payload: string | undefined) => {
  return {
    type: GET_LOAN_CLOSER_INFO,
    payload,
  };
};

export const handleLoanCloserAction = (payload: string | undefined) => {
  return {
    type: HANDLE_LOAN_CLOSER,
    payload,
  };
};

export const handleGetRepaidAmountAction = (payload: string | undefined) => {
  return {
    type: HANDLE_GET_REPAID_AMOUNT,
    payload,
  };
};

export const emptyEnquiryState = () => {
  return {
    type: EMPTY_ENQUIRY_STATE,
  };
};
