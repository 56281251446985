import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  skeletonWrapper: {
    width: '80%',
    margin: 'auto',
    marginRight: '40px',
    marginTop: '100px',
  },
  dashContainer: {
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    background: '#F6FAFA',
  },
  dashleft: {
    width: 310,
    flex: '0 auto',
    '@media (max-width: 1120px)': {
      width: 140,
      padding: 30,
    },
  },
  dashLogo: {
    display: 'flex',
    width: '100%',
    marginBottom: 70,
  },
  logoImg: {
    width: 100,
    height: 'auto',
  },
  logoText: {
    fontSize: 16,
    fontStyle: 'italic',
    letterSpacing: '0.5px',
    color: theme.palette.grey[100],
    paddingLeft: 15,
    alignSelf: 'center',
    '@media (max-width: 1120px)': {
      fontSize: 13,
      paddingLeft: 10,
    },
  },
  stepperBox: {
    background: 'none',
    '& .MuiStepConnector-vertical': {
      marginLeft: 10,
    },
    '& .MuiStepLabel-label': {
      fontSize: 14,
    },
  },
  message: {
    marginBottom: 15,
    fontStyle: 'italic',
    color: 'orange',
    lineHeight: 1.4,
    paddingRight: 125,
  },
  stepLabelText: {},
  dashRight: {
    width: 'calc(100% - 310px)',
    flexGrow: 1,
    padding: '55px 0px 35px 93px',
    background: theme.palette.common.white,
    position: 'relative',
    '@media (max-width: 1120px)': {
      width: 'calc(100% - 200px)',
      padding: '40px 0px 30px 40px',
    },
  },
  dashHeader: {
    paddingRight: 80,
  },
  detailsText: {
    fontSize: 29,
    color: theme.palette.error.contrastText,
    marginBottom: 50,
    marginTop: 2,
    paddingLeft: 2,
    position: 'relative',
    top: -20,
    display: 'inline-block',
    '@media (max-width: 1120px)': {
      // marginBottom: 20,
      // marginTop: 30,
      fontSize: 24,
    },
  },
  linkdashText: {
    fontSize: 13,
    fontWeight: 600,
    letterSpacing: '0.5px',
    color: theme.palette.primary.main,
    paddingLeft: 15,
    width: '100%',
    textAlign: 'right',
    marginTop: 8,
    position: 'relative',
    zIndex: 1,
    '@media (max-width: 768px)': {
      fontSize: 12,
    },
  },
  needHelp: {
    color: theme.palette.grey[100],
    marginRight: 5,
  },
  imgOrnament: {
    position: 'absolute',
    top: 40,
    left: 24,
    width: 90,
    height: 90,
    '@media (max-width: 1120px)': {
      // top: 25,
      width: 60,
      height: 60,
    },
  },
  founderTitleText: {
    fontSize: 19,
    fontWeight: 700,
    color: theme.palette.warning.contrastText,
    padding: '15px 0',
  },
  removeText: {
    fontSize: 14,
    color: theme.palette.grey[700],
    cursor: 'pointer',
  },
  sidebarFixed: {
    padding: 40,
    height: '75%',
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  pointText: {
    fontSize: 15,
    letterSpacing: '0.35px',
    marginBottom: 20,
  },
  pitText: {
    fontSize: 13,
    position: 'relative',
    top: -15,
    left: 15,
  },
  strongText: {
    // display: 'block',
    marginBottom: 7,
    fontWeight: 700,
  },
  linkHere: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: 600,
    display: 'inline-block',
  },
  continueBtnBox: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 15,
    cursor: 'pointer',
    height: 58,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    marginTop: 20,
  },
  moreDetails: {
    width: 200,
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
    marginLeft: 20,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 6,
  },
  ycErrorText: {
    color: theme.palette.error.main,
    fontSize: 13,
  },
  termsText: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
  },
  inputHeadFull: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px !important',
    position: 'relative',
    // '@media (max-width: 1120px)': {
    //   padding: '12px 20px 12px 12px !important',
    // },
    // '@media (max-width: 960px)': {
    //   padding: '12px !important',
    // },
  },
  inputHead: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '12px 40px 12px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 12px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px !important',
    },
  },
  inputHeadRight: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '12px 12px 12px 40px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 12px 12px 20px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px !important',
    },
  },
  inputHeadPart: {
    padding: '0px 40px 12px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '0px 20px 12px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px !important',
    },
  },
  inputHeadLastPart: {
    padding: '0px 12px 12px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '0px 12px 12px 20px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px !important',
    },
  },
  inputInHead: {
    padding: '0px 40px 0px 0px !important',
    '@media (max-width: 1120px)': {
      padding: '0px 40px 0px 0px !important',
    },
  },
  inputInRightHead: {
    padding: '0px 0px 0px 12px !important',
  },
  companyLabelHeader: {
    fontSize: 20,
    color: theme.palette.grey[50],
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
  },
  companyLabelText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
  },
  companyLtext: {
    fontSize: 22,
    fontWeight: 700,
    color: theme.palette.warning.contrastText,
    letterSpacing: 0.3,
    paddingBottom: 20,
  },
  companySocialText: {
    // paddingLeft: 12,
    fontSize: 15,
    color: theme.palette.grey[50],
    marginTop: 10,
  },
  cmpnyLtext: {
    fontSize: 22,
    fontWeight: 700,
    color: theme.palette.warning.contrastText,
    letterSpacing: 0.3,
    paddingBottom: 25,
    paddingTop: 30,
  },
  validateForm: {
    paddingRight: 125,
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    height: 'calc(100vh - 220px)',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1120px)': {
      height: 'calc(100vh - 173px)',
      paddingRight: 40,
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
    },
  },
  textValInput: {
    marginBottom: 25,
    marginTop: 7,
    width: '100%',
    '@media (max-width: 1120px)': {
      marginBottom: 15,
      marginTop: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000de',
    },
  },
  textFValInput: {
    marginBottom: 15,
    marginTop: 7,
    width: '100%',
    '@media (max-width: 1120px)': {
      marginBottom: 15,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  forgotText: {
    alignSelf: 'center',
    textAlign: 'right',
  },
  agreeTerms: {
    fontSize: 14,
    letterSpacing: 0.35,
    color: theme.palette.grey[50],
  },
  termAndCondition: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  backContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 220,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  docusignContainer: {
    marginLeft: 40,
    marginTop: 10,
  },
  redirectedText: {
    margin: 15,
    marginTop: 15,
  },
  addFondbtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '14px 5px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    letterSpacing: 0.3,
    cursor: 'pointer',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  dashFondbtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '14px 5px',
    margin: 'auto',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 180,
    fontSize: 15,
    letterSpacing: 0.3,
    cursor: 'pointer',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.warning.main,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 120,
    fontSize: 15,
    height: 50,
    cursor: 'pointer',
    marginRight: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
  },
  addFound: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    padding: '8px 16px 55px 5px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    letterSpacing: 0.5,
    cursor: 'pointer',
    border: 0,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      border: 0,
      color: theme.palette.primary.main,
    },
  },
  saveMoreBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 200,
    fontSize: 15,
    height: 58,
    cursor: 'pointer',
    marginRight: 20,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  backMoreBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 15,
    height: 58,
    border: '1px solid #1EA59A',
    width: 140,
    letterSpacing: 0.3,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  checkBoxAgree: {
    paddingTop: 18,
  },
  successImage: {
    display: 'none',
  },
  dashSuccessImg: {
    height: 250,
    paddingTop: 0,
  },
  enquirySuccessBox: {
    paddingRight: 125,
    height: 'calc(100vh - 120px)',
    display: 'flex',
  },
  eBoxContent: {
    width: '100%',
    alignSelf: 'center',
    marginTop: -120,
  },
  successHeaderTxt: {
    fontSize: 20,
    color: '#262A41',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    lineHeight: '30px',
    maxWidth: 650,
    margin: 'auto',
    marginBottom: 20,
  },
  successSubTxt: {
    fontSize: 15,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    marginTop: 15,
  },
  imgOrnamentOne: {
    height: 80,
    width: 90,
    position: 'absolute',
    bottom: 60,
    left: 60,
  },
  infoIcon: {
    color: theme.palette.primary.main,
    position: 'relative',
    top: 5,
    left: 5,
  },
  backArrow: {
    position: 'absolute',
    top: 65,
    // right: 460,
    fontSize: 40,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    left: '40px',
  },

  // Contact Us Model -------------- Start

  contactModel: {
    '& .MuiDialogContent-root': {
      overflowY: 'hidden',
    },
  },

  // Contact Us Model -------------- End

  // Basic Details Styling ----------- Start

  basicDetails: {},
  basicHt: {
    fontSize: 22,
    fontWeight: 700,
    letterSpacing: 0.3,
    color: theme.palette.warning.contrastText,
    paddingTop: 50,
    marginBottom: 15,
    lineHeight: '45px',
  },
  addFoundBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    padding: '12px 8px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    letterSpacing: 0.5,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  saveBox: {
    paddingTop: 30,
    paddingLeft: 13,
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 180,
    height: 50,
    fontSize: 18,
    letterSpacing: 0.3,
    cursor: 'pointer',
    textAlign: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  // Basic Details Styling ------------ End

  // Upload Document --------- Start

  docuUploadBox: {
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    height: 'calc(100vh - 220px)',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1120px)': {
      height: 'calc(100vh - 173px)',
      paddingRight: 40,
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
    },
  },
  docuUploadContent: {
    padding: '0px 30px 25px 0',
    maxWidth: 634,
  },
  headDetails: {},
  headerText: {
    fontSize: 22,
    fontWeight: 700,
    color: theme.palette.warning.contrastText,
    letterSpacing: 0.3,
    lineHeight: '45px',
  },
  subHtext: {
    color: theme.palette.grey[50],
    fontSize: 15,
    letterSpacing: 0.34,
    marginBottom: 30,
  },
  uploadDocuBox: {
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.primary.contrastText,
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: '10px 25px',
    marginBottom: 20,
  },
  uploadDocuContent: {
    display: 'flex',
    alignSelf: 'center',
  },
  bGImg: {
    height: 42,
    width: 42,
    borderRadius: 4,
    border: '1px solid #D0D2DA',
  },
  iconImg: {
    height: 20,
    alignSelf: 'center',
    marginRight: 20,
  },
  fileName: {
    fontSize: 16,
    color: theme.palette.grey[900],
    alignSelf: 'center',
    paddingLeft: 10,
  },
  uploadIcon: {
    height: 16,
    width: 16,
    alignSelf: 'center',
  },
  uploadDocuBtn: {
    paddingTop: 30,
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 240,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    marginRight: 20,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  cancelBtn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.warning.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 130,
    fontSize: 18,
    height: 50,
    border: '1px solid #1EA59A',
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
  },
  templateBoard: {
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.primary.contrastText,
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: '18px 18px',
    marginBottom: 20,
  },
  btLeft: {},
  boardText: {
    paddingRight: 25,
    display: 'flex',
  },
  colorFill: {
    alignSelf: 'baseline',
    height: 20,
    marginRight: 20,
  },
  boardHtext: {
    fontSize: 16,
    color: theme.palette.grey[900],
    lineHeight: '16px',
    marginBottom: 6,
  },
  boardStext: {
    fontSize: 13,
    color: theme.palette.grey[800],
    lineHeight: '18px',
    maxWidth: 380,
    paddingTop: '10px',
  },
  templateText: {
    color: theme.palette.primary.main,
    fontSize: 14,
    lineHeight: '20px',
  },
  ulIcon: {
    alignSelf: 'baseline',
    height: 16,
    width: 16,
  },

  // Upload Document --------- End

  // DocuSign View ---------- Start

  docuSignBox: {},
  docuSignText: {
    fontSize: 22,
    fontWeight: 700,
    letterSpacing: 0.3,
    color: theme.palette.warning.contrastText,
    lineHeight: '45px',
    marginBottom: 10,
  },
  listPoints: {
    fontSize: 17,
    lineHeight: '32.1px',
    letterSpacing: 0.35,
    color: theme.palette.grey[50],
    marginBottom: 10,
  },
  docuSignBtn: {
    display: 'flex',
    padding: '30px 0px 20px',
  },
  redirectTxt: {
    alignSelf: 'center',
    fontSize: 14,
    lineHeight: '19.04px',
    color: theme.palette.grey[300],
  },
  goToDashBoardBtn: {
    color: theme.palette.primary.main,
    // backgroundColor: theme.palette.warning.main,
    // padding: '12px 16px',
    // borderRadius: 10,
    // fontFamily: theme.typography.fontFamily,
    // fontWeight: 800,
    // width: 175,
    marginTop: -3,
    fontSize: 15,
    // height: 50,
    cursor: 'pointer',
    marginRight: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  contactus: {
    cursor: 'pointer',
    fontSize: 15,
  },
  bridgeTerms: {
    fontSize: 15,
    cursor: 'pointer',
    textTransform: 'capitalize',
    padding: 0,
    marginRight: 20,
  },
  errorMessage: {
    color: 'red',
  },
  leftBottomSection: {
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
  },
  leftLinks: {
    flexGrow: 1,
    padding: '0px 24px',
    '@media (max-width: 1220px)': {
      padding: '0px 14px',
    },
  },
  logoutBtn: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.error.contrastText,
    marginTop: 30,
    padding: '6px 16px',
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 15,
    },
  },
  // DocuSign View ---------- Start
  // upload YC
  ycAcceptanceText: {
    fontSize: 15,
    paddingBottom: 10,
    color: theme.palette.grey[50],
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
  },
  uploadYcContainer: {
    border: '1px solid #000000',
    height: 60,
    marginTop: 10,
  },
  primaryText: {
    color: theme.palette.primary.light,
    cursor: 'pointer',
  },
  uploadYcIcon: {
    paddingLeft: 90,
    paddingTop: 20,
    height: 16,
    width: 16,
    cursor: 'pointer',
    '@media (max-width: 960px)': {
      paddingTop: 18,
      paddingLeft: 80,
    },
  },
  uploadYcText: {
    fontSize: 15,
    paddingLeft: 20,
    color: '#247CDE',
    cursor: 'pointer',
    //color: theme.palette.grey[50],
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
      paddingLeft: 30,
    },
  },
  upLoadBox: {
    display: 'flex',
    padding: '10px 18px',
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    maxWidth: 634,
    marginTop: 15,
    minHeight: 45,
  },
  upLoadleft: {
    display: 'flex',
    width: 'calc(100% - 13px)',
  },
  circleImg: {
    height: 20,
    marginRight: 20,
    alignSelf: 'center',
  },
  previewImgNotUploaded: {
    height: 42.5,
    marginRight: 11,
    cursor: 'default',
  },
  uploadFileName: {
    alignSelf: 'center',
    fontSize: 16,
    lineHeight: '18px',
    color: theme.palette.grey[900],
  },
  crossImg: {
    height: 13,
    alignSelf: 'center',
  },
  radioGroup: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
      lineHeight: '18px',
      color: theme.palette.common.black,
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.common.black,
    },
    marginBottom: 5,
  },
  formControlText: {},
  dummyBox: {
    width: 10,
  },
  contactUsText: {
    fontWeight: 1000,
    marginBottom: 3,
  },
}));
