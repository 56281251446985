import { urlConstants } from 'common/utils/constants';
import { secureApi } from 'api/Axios';

export const userListService = (role: string) => {
  let url = '';
  if (role === 'STARTUP_ADMIN') {
    url = `${urlConstants.getStartupUsers}`;
  } else if (role === 'ADMIN') {
    url = `${urlConstants.getAllAdminUsers}`;
  }
  if (url) {
    return secureApi
      .get(url)
      .then((res) => res)
      .catch((err) => Promise.reject(err));
  }
};

export const inviteUserService = (role: string, payload: any) => {
  let url = '';
  if (role === 'STARTUP_ADMIN') {
    url = `${urlConstants.inviteStartupUser}`;
  } else if (role === 'ADMIN') {
    url = `${urlConstants.inviteAdminUser}`;
  }
  if (url) {
    return secureApi
      .post(url, payload)
      .then((res) => res)
      .catch((err) => Promise.reject(err));
  }
};

export const updateUserService = (role: string, id: string, payload: any) => {
  let url = '';
  if (role === 'STARTUP_ADMIN') {
    url = `${urlConstants.updateStartupUser}/${id}`;
  } else if (role === 'ADMIN') {
    url = `${urlConstants.updateAdminUser}/${id}`;
  }
  if (url) {
    return secureApi
      .patch(url, payload)
      .then((res) => res)
      .catch((err) => Promise.reject(err));
  }
};

export const deleteUserService = (role: string, id: string) => {
  let url = '';
  if (role === 'STARTUP_ADMIN') {
    url = `${urlConstants.deleteStartupUser}/${id}`;
  } else if (role === 'ADMIN') {
    url = `${urlConstants.deleteAdminUser}/${id}`;
  }
  if (url) {
    return secureApi
      .delete(url)
      .then((res) => res)
      .catch((err) => Promise.reject(err));
  }
};

export const deleteStartupData = (body: any) => {
  return secureApi
    .post(urlConstants.deleteStartupData, body)
    .then((res) => res)
    .catch((err) => Promise.reject(err));
};
