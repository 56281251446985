import React from 'react';
import { Box } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import { get } from 'lodash';

import { LightStyledTooltip } from 'components/Wrappers/Tooltip';
import { CURRENCY_MAPPER } from 'common/utils/constants';
import { formatAmount } from 'common/utils/helpers';

import styles from '../styles';

const EditFcrmRoundTooltip = ({
  onEdit,
  fcrmRound,
  currency,
  isRoleAdmin,
}: any) => {
  const classes = styles();

  return (
    <LightStyledTooltip
      interactive
      arrow={false}
      placement="bottom-start"
      title={
        <Box className={classes.editRoundToolTip}>
          <Box className={classes.flexBox}>
            <Box className={classes.tooltipBigText}>
              {CURRENCY_MAPPER[get(fcrmRound, 'currency')] ||
                get(fcrmRound, 'currency')}
            </Box>
            {!isRoleAdmin && (
              <Box className={classes.tooltipBtn} onClick={onEdit}>
                <span>Edit</span>
              </Box>
            )}
          </Box>

          <Box className={classes.flexBox}>
            <Box>
              <Box className={classes.tooltipSmallText}>Target Amount</Box>
              <Box className={classes.tooltipBigText}>
                {currency}
                {formatAmount(get(fcrmRound, 'targetAmount'), {
                  currency: get(fcrmRound, 'currency') || 'USD',
                  style: 'decimal',
                })}
              </Box>
            </Box>

            <Box>
              <Box className={classes.tooltipSmallText}>
                Tentative Valuation
              </Box>
              <Box className={classes.tooltipBigText}>
                {currency}
                {formatAmount(get(fcrmRound, 'tentativeValuation'), {
                  currency: get(fcrmRound, 'currency') || 'USD',
                  style: 'decimal',
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      }
      // arrow={false}
      // placement="right"
    >
      <MoreVert
        className={classes.tabIcon}
        // onClick={() => setOpenRoundDialog(true)}
      />
    </LightStyledTooltip>
  );
};

export default EditFcrmRoundTooltip;
