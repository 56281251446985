import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  dashTop: {
    display: 'block',
    margin: '0 -14px',
  },
  topContent: {
    display: 'inline-block',
    width: '33.333%',
    marginBottom: 35,
    '@media (max-width: 1170px)': {
      width: '50%',
    },
    '@media (max-width: 1024px)': {
      width: '33.333%',
    },
    '@media (max-width: 920px)': {
      width: '50%',
    },
    '@media (max-width: 520px)': {
      width: '100%',
    },
  },
  dashValuBox: {
    border: '1px solid #E5E5E5',
    borderRadius: 4,
    padding: '12px 20px',
    margin: '0 14px',
    position: 'relative',
  },
  dashValuInsideBox: {
    position: 'absolute',
    top: 5,
    right: 5,
    textAlign: 'center',
  },
  valueNo: {
    marginBottom: 7,
    fontSize: 20,
    color: theme.palette.warning.contrastText,
  },
  valueText: {
    fontSize: 13,
    color: theme.palette.grey.A400,
    '@media (max-width: 1300px)': {
      fontSize: 12,
    },
  },
  valueInsideNo: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  valueInsideText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.warning.contrastText,
  },
  companiestext: {
    color: theme.palette.grey.A200,
    fontSize: 13,
    paddingLeft: 15,
    '@media (max-width: 1300px)': {
      fontSize: 12,
    },
  },
  // Spv Select ---------- Start
  spvSelect: {
    marginRight: 15,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  selectValInput: {
    width: 100,
    margin: 0,
  },
  inputRoot: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: 12,
    },
  },
  labelRoot: {},
  labelFocused: {},
  dashBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(140px, 1fr))',
    gridGap: 20,
    //paddingBottom: 30,
    // borderBottom: '1px solid #D8D8D8',
    '@media (max-width: 1024px)': {
      gridGap: 25,
    },
    '@media (max-width: 525px)': {
      gridTemplateColumns: 'repeat(1, minmax(200px, 1fr))',
    },
  },
  dashCardBox: {
    background: theme.palette.common.white,
    border: '1px solid #D8D8D8',
    boxShadow: theme.shadows[2],
    borderRadius: 11,
    fontSize: 50,
    // maxWidth: 347,
    // width: '100%',
    padding: 25,
    '@media (max-width: 1024px)': {
      padding: '30px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 20px',
    },
  },
  dashText: {
    color: theme.palette.grey[600],
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0.3,
    marginBottom: 5,
    minHeight: 50,
    '@media (max-width: 525px)': {
      minHeight: 30,
    },
  },
  dashNumber: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '2.4vw',
    lineHeight: '63px',
    textTransform: 'uppercase',
    marginBottom: 0,
    '@media (max-width: 1024px)': {
      fontSize: '2.1vw',
      lineHeight: '25px',
    },
  },
  dashVCTableBox: {
    paddingTop: 30,
  },
  tableContainer: {
    boxShadow: theme.shadows[2],
    maxHeight: 340,
    minHeight: 200,
    overflow: 'overlay',
    borderRadius: 11,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '26px',
      padding: '16px 8px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 16,
      padding: '10px 8px',
      textAlign: 'left',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  summaryContainer: {
    display: 'flex',
    paddingBottom: 30,
    borderBottom: '1px solid #D8D8D8',
  },
  summaryLeft: {
    // flex: 1,
    width: '45%',
    //display: 'flex',
    border: '1px solid #D8D8D8',
    boxShadow: '0px 9px 6px rgba(246, 250, 250, 1)',
    borderRadius: 11,
    marginRight: 0,
    marginBottom: '1em',
    background: '#F6FAFA',
    '@media (min-width: 768px)': {
      marginRight: '1em',
      marginBottom: 0,
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  leftTopBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15,
  },
  planAnalysisText: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '20px',
  },
  freeTrialText: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.primary.main,
    lineHeight: '23px',
  },
  pieChartBox: {
    width: 'auto',
    margin: 15,
  },
}));
