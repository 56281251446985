import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { isEmpty, get, orderBy } from 'lodash';

import { Box, StyledTableCell, StyledTableRow, Loader } from 'components';
import { DeleteIcon, ViewIcon } from 'assets';
import { formatAmount } from 'common/utils/helpers';
import { SUBSCRIBED_USER } from 'common/utils/constants';
import { SubscriptionUsersOptions } from 'common/utils/optionList';
import { PlanType } from 'common/types';

import styles from './styles';

type Props = {
  getSubscriptionPlansList: () => void;
  filteredSearchData: any;
  isLoading: boolean;
  errorMessage: string;
  setIsPlanView: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPlanData: React.Dispatch<React.SetStateAction<PlanType>>;
  setSubscriptionUser: React.Dispatch<React.SetStateAction<string>>;
};

const headers = (array: any[]) => {
  return (
    <>
      {array.map((i: any, n: number) => {
        return (
          <StyledTableCell
            key={n}
            style={{
              textAlign: i.textAlign ? i.textAlign : 'left',
            }}
          >
            {i.name}
          </StyledTableCell>
        );
      })}
    </>
  );
};

const TABLE_HEADERS = [
  { name: 'Plan Name', textAlign: 'left' },
  { name: 'Free Trial Days', textAlign: 'right' },
  { name: 'Monthly Charges', textAlign: 'right' },
  { name: 'No. of Outputs', textAlign: 'right' },
  { name: 'Active Subscription', textAlign: 'right' },
  { name: 'Free Trial Clients', textAlign: 'right' },
  { name: 'Paying Clients', textAlign: 'right' },
  { name: 'Action', textAlign: 'left' },
];

const VcFirmAllPlanTable = ({
  getSubscriptionPlansList,
  filteredSearchData,
  isLoading,
  errorMessage,
  setIsPlanView,
  setSelectedPlanData,
  setSubscriptionUser,
}: Props) => {
  const classes = styles();

  const defaultSortedList = orderBy(
    filteredSearchData,
    ['isDefault'],
    ['desc'],
  );

  const getPlanPriceDetail = (planDetail: any) => {
    const prices = get(planDetail, 'prices');
    return prices.find((price: any) => !price.addon);
  };

  // eslint-disable-next-line no-unused-vars
  const getPlanAddOnPriceDetail = (planDetail: any) => {
    const prices = get(planDetail, 'prices');
    return prices.find((price: any) => price.addon);
  };

  useEffect(() => {
    getSubscriptionPlansList();
    setSubscriptionUser('ALL');
  }, []);

  return (
    <Box className={classes.firmAdminTableBox}>
      <TableContainer
        className={classes.tableAllPlanContainer}
        component={Paper}
      >
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>{headers(TABLE_HEADERS)}</TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={TABLE_HEADERS.length}>
                  <Box className={classes.loader}>
                    <Loader />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : isEmpty(defaultSortedList) && !errorMessage ? (
              <StyledTableRow>
                <StyledTableCell colSpan={TABLE_HEADERS.length}>
                  <Box className={classes.loader}>
                    <Typography>No data found in table</Typography>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              (defaultSortedList || []).map((row: any, index: number) => (
                <StyledTableRow hover key={index}>
                  <StyledTableCell component="th" scope="row" align="center">
                    {get(row, 'name')}
                    {!get(row, 'isJoiningPlan') && (
                      <span
                        className={
                          get(row, 'planType') === SUBSCRIBED_USER.VC_FIRM
                            ? classes.userTypeVcFirmText
                            : get(row, 'planType') ===
                              SUBSCRIBED_USER.ANGEL_INVESTOR
                            ? classes.userTypeAngelInvText
                            : classes.userTypeYardstickText
                        }
                      >
                        {get(
                          SubscriptionUsersOptions.find(
                            (obj: any) => obj.value === get(row, 'planType'),
                          ),
                          'subText',
                        ) || 'Fund'}
                      </span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ textAlign: 'right' }}
                  >
                    {[
                      SUBSCRIBED_USER.VC_FIRM,
                      SUBSCRIBED_USER.ANGEL_INVESTOR,
                    ].includes(get(row, 'planType'))
                      ? get(row, 'subscriptionFreeTrialDays', '-')
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ textAlign: 'right' }}
                  >
                    {formatAmount(get(getPlanPriceDetail(row), 'amount') || 0)}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ textAlign: 'right' }}
                  >
                    {[
                      SUBSCRIBED_USER.VC_FIRM,
                      SUBSCRIBED_USER.ANGEL_INVESTOR,
                    ].includes(get(row, 'planType'))
                      ? 'Unlimited'
                      : get(getPlanPriceDetail(row), 'freeUnit') || '-'}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ textAlign: 'right' }}
                  >
                    {get(row, 'clients.active')}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ textAlign: 'right' }}
                  >
                    {get(row, 'clients.free')}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ textAlign: 'right' }}
                  >
                    {get(row, 'isJoiningPlan')
                      ? '-'
                      : get(row, 'clients.paying')}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.actionButtonBox}
                  >
                    <span className={classes.viewIcon}>
                      <img
                        src={ViewIcon}
                        alt="link"
                        className={classes.viewIcon}
                        onClick={() => {
                          setSelectedPlanData({
                            planType: get(row, 'planType'),
                            planName: get(row, 'name'),
                            id: get(row, 'id'),
                            isJoiningPlan: get(row, 'isJoiningPlan'),
                          });
                          setIsPlanView(true);
                        }}
                      />
                    </span>
                    {!get(row, 'isDefault') && !get(row, 'isJoiningPlan') && (
                      <span className={classes.viewIcon}>
                        <img
                          src={DeleteIcon}
                          alt="link"
                          className={classes.viewIcon}
                        />
                      </span>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VcFirmAllPlanTable;
