import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  founderBox: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 10,
  },
  founderLeft: {},
  founderRight: {
    paddingLeft: 12,
  },
  labelFundUpHeadText: {
    fontSize: 15,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 4,
    paddingTop: 1,
  },
  labelFundUpText: {
    fontSize: 12.5,
    color: theme.palette.grey[50],
    // fontWeight: 600,
    marginBottom: 3,
    opacity: 0.5,
  },
  labelInfoText: {
    fontSize: 12,
    color: theme.palette.primary.main,
    // opacity: 0.75,
    marginBottom: 25,
    paddingTop: 10,
  },
  // labelStartUpText: {
  //   fontSize: 15,
  //   color: theme.palette.grey[50],
  //   opacity: 0.75,
  //   marginBottom: 8,
  //   paddingRight: 15,
  // },
  // labelStartHead: {
  //   fontSize: 22,
  //   color: theme.palette.error.contrastText,
  //   fontWeight: 600,
  //   marginBottom: 25,
  // },
  // labelStartUpHead: {
  //   fontSize: 18,
  //   color: theme.palette.error.contrastText,
  //   fontWeight: 600,
  //   marginBottom: 25,
  // },
  // labelFundHead: {
  //   fontSize: 18,
  //   color: theme.palette.error.contrastText,
  //   fontWeight: 600,
  //   marginBottom: 10,
  // },
  // labelStartUp: {
  //   fontSize: 15,
  //   color: theme.palette.error.contrastText,
  //   fontWeight: 600,
  //   marginBottom: 25,
  // },
  // labelStartUpUrl: {
  //   fontSize: 15,
  //   color: theme.palette.primary.main,
  //   fontWeight: 600,
  //   marginBottom: 25,
  // },
  // labelFundUpHead: {
  //   fontSize: 18,
  //   color: theme.palette.error.contrastText,
  //   fontWeight: 600,
  //   marginBottom: 5,
  //   paddingTop: 5,
  // },

  iconStyle: {
    width: 32,
    height: 32,
    textTransform: 'uppercase',
    fontSize: 20,
    fontWeight: 600,
  },
  menuItemText: {
    display: 'inline-block',
  },
}));
