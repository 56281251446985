import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  activateButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 90,
    fontSize: 10,
    height: 30,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
    },
    marginLeft: 15,
  },
  deActivateButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    fontSize: 10,
    height: 30,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      borderColor: theme.palette.error.main,
    },
    marginLeft: 15,
  },
  onboardBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 90,
    fontSize: 10,
    height: 30,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
    },
    marginLeft: 15,
  },
  enquiryViewContainer: {
    paddingLeft: 60,
    '@media (max-width: 1024px)': {
      paddingLeft: 20,
    },
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    },
  },
  enquiryViewBox: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 20px)',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  enquiryTabs: {
    borderBottom: '0.5px solid #DEDEDE',
    marginTop: 25,
  },
  enquiryViewBoxLeft: {
    width: 'calc(100% - 300px)',
    flex: '0 auto',
    // paddingRight: 30,
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 260px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 20,
    },
    '@media (max-width: 525px)': {
      paddingLeft: 15,
    },
  },
  enquiryListLeft: {
    position: 'relative',
    paddingLeft: 10,
  },
  enquiryListRight: {
    paddingRight: 30,
  },
  gridItemView: {
    paddingLeft: '15px',
  },
  iconStyleOne: {
    height: 52,
    width: 52,
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 34,
    left: 41,
  },
  enquiryViewTabBox: {
    paddingRight: 30,
  },
  enquiryViewBoxRight: {
    flexGrow: 1,
    width: 300,
    background: theme.palette.warning.main,
    borderTopRightRadius: 25,
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      width: 260,
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  tanPanelBox: {
    height: 'calc(100vh - 246px)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 265px)',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  tabPaymentBox: {
    height: 'calc(100vh - 285px)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 300px)',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  contentBoxRight: {
    padding: '60px 25px 30px',
  },
  overViewSubHeadTitle: {
    fontSize: 16,
    color: theme.palette.grey[500],
    fontWeight: 600,
  },
  labelStartUpText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    opacity: 0.75,
    marginBottom: 8,
    paddingRight: 15,
  },
  labelStartUp: {
    fontSize: 15,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelStartUpUrl: {
    fontSize: 15,
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelFundUpHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    paddingTop: 5,
  },
  menuItemText: {
    display: 'inline-block',
  },
  ////////////////////////////////
  enquiryListhead: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 90,
    '@media (max-width: 1024px)': {
      paddingTop: 110,
      paddingBottom: 15,
    },
    '@media (max-width: 767px)': {
      paddingTop: 40,
    },
  },
  enquiryListheadContent: {
    display: 'flex',
    width: 'calc(100% - 75px)',
    flex: '0 auto',
  },
  backContinueBox: {
    paddingTop: 10,
    paddingRight: 10,
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  btnSubmit: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  //////////
  investmentNotFoundTxt: {
    fontSize: 20,
    color: theme.palette.grey[500],
    fontWeight: 600,
    marginTop: 20,
  },
  backArrow: {
    float: 'right',
    fontSize: 40,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  dealsBox: {
    display: 'block',
    margin: '40px -14px 0px',
    height: 'calc(100vh - 240px)',
    overflow: 'hidden auto',
  },
  dealBox: {
    display: 'inline-block',
    width: '33.333%',
    border: '1px solid #E5E5E5',
    borderRadius: 4,
    padding: '12px 20px',
    margin: '0 14px',
    marginBottom: 35,
    cursor: 'pointer',
    '@media (max-width: 1170px)': {
      width: '50%',
    },
    '@media (max-width: 1024px)': {
      width: '33.333%',
    },
    '@media (max-width: 920px)': {
      width: '50%',
    },
    '@media (max-width: 520px)': {
      width: '100%',
    },
  },
  spvText: {
    marginBottom: 7,
    fontSize: 20,
    color: theme.palette.warning.contrastText,
  },
  spvSubText: {
    fontSize: 13,
    color: theme.palette.grey.A400,
    '@media (max-width: 1300px)': {
      fontSize: 12,
    },
  },
}));
