import React from 'react';
import { Typography } from '@material-ui/core';
import { isEmpty, get } from 'lodash';
import { useSelector } from 'react-redux';

import { Box, Button } from 'components';
import history from 'common/utils/history';
import { RootState } from 'redux-modules/store/rootState';

import styles from '../styles';

const UploadDocuments = (data: any) => {
  const classes = styles();
  const actionsData = get(data, 'data', {});
  const documents = get(actionsData, 'documents', []);

  const { loansDetails } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const fundType = get(loansDetails, 'fundType');

  return (
    <>
      <Box className={classes.pendingBox}>
        <Box className={classes.dashactionUploadBox}>
          <Typography
            className={classes.dashUploadText}
            variant="h6"
            gutterBottom
          >
            Upload pending documents.
          </Typography>
          {!isEmpty(documents)
            ? documents.map((document: any, i: number) => (
                <>
                  <Typography
                    className={classes.dashUploadListText}
                    variant="h6"
                    gutterBottom
                    key={i}
                  >
                    {i + 1}. {document.documentName}
                  </Typography>
                </>
              ))
            : null}

          <Button
            className={classes.UploadBtn}
            name="Upload"
            onClick={() =>
              history.push(
                `/startup/profile?value=1&${
                  fundType ? `loanType=${fundType}` : ''
                }&step=REQUIRED_DOCUMENTS&fundType=BRIDGE_LOAN`,
              )
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default UploadDocuments;
