import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  firmAdminTableBox: {
    padding: '0px',
    border: '0.5px solid #DEDEDE',
    borderRadius: 11,
    marginTop: 20,
    height: 'calc(100% - 40px) !important',
    overflow: 'hidden',
    '@media (max-width: 1180px)': {
      padding: '0px',
    },
    '@media (max-width: 1024px)': {
      padding: '20px 0px 20px',
    },
  },
  tableAllPlanContainer: {
    boxShadow: theme.shadows[2],
    // maxHeight: '100%',
    height: '100%',
    minHeight: 200,
    overflow: 'overlay',
    borderRadius: 11,
    borderRight: 0,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTable-stickyHeader': {
      tableLayout: 'fixed',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '26px',
      padding: '16px 8px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
        width: '200px',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 16,
      padding: '10px 8px',
      textAlign: 'left',
      wordBreak: 'break-word',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },
  tableContainer: {
    boxShadow: theme.shadows[2],
    // maxHeight: '100%',
    height: '100%',
    minHeight: 200,
    overflow: 'overlay',
    borderRadius: 11,
    borderRight: 0,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTable-stickyHeader': {
      tableLayout: 'fixed',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '26px',
      padding: '16px 8px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 16,
      padding: '10px 8px',
      textAlign: 'left',
      wordBreak: 'break-word',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  customizePlanBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 30,
    width: 200,
    height: 40,
    marginTop: 5,
    marginRight: 20,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  // TAB
  actionTabPanel: {
    height: 'calc(100vh - 230px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      // height: 'calc(100% - 60px - 80px)',
    },
  },
  // select
  textValInputSelect: {
    marginBottom: 10,
    marginTop: 5,
    marginRight: 18,
    width: '50%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRootSelect: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRootSelect: {},
  labelFocusedSelect: {},
  actionButtonBox: {
    // display: 'flex',
    // justifyContent: 'center',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  viewButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid #1EA59A',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    padding: '4px 7px 4px',
    margin: '5px 0px',
    cursor: 'pointer',
    width: 71,
    height: 30,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.secondary.main,
      background: theme.palette.primary.contrastText,
    },
  },
  inputHead: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '20px 40px 12px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 12px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px !important',
    },
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInputSelectField: {
    marginBottom: 10,
    marginTop: 5,
    marginRight: 18,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 14,
    display: 'block',
    position: 'absolute',
    bottom: 0,
  },
  errorTextCreateNewPlan: {
    color: theme.palette.error.main,
    fontSize: 14,
    display: 'block',
    position: 'absolute',
  },
  textValInput: {
    marginBottom: 10,
    width: '100%',
  },
  prevButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    marginRight: 18,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  actionVcView: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 6,
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    width: 65,
    height: 31,
    border: '1px solid #1EA59A',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
    },
  },
  viewIcon: {
    position: 'relative',
    margin: '0 5px',
    cursor: 'pointer',
    '&:hover::after': {
      display: 'block',
    },
  },
  deleteDocuIcon: {
    position: 'relative',
    margin: '0px 5px',
    cursor: 'pointer',
    '&:hover::after': {
      display: 'block',
    },
  },
  // plan Detail
  planDetailContainer: {
    padding: '0px 40px 30px 40px',
    height: 'calc(100% - 60px)',
    '@media (max-width: 1180px)': {
      padding: '0px 40px 20px 40px',
    },
    '@media (max-width: 1024px)': {
      padding: '80px 20px 20px',
    },
  },
  searchBox: {
    borderRadius: 30,
    border: '1px solid #E5E5E5',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    marginTop: 5,
    width: 300,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  clearIcon: {
    padding: 5,
  },
  inputSearch: {
    width: 'calc(100% - 10px)',
    paddingLeft: 14,
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightBox: {
    display: 'flex',
    alignItems: 'center',
  },
  backIcon: {
    fontSize: 30,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  freeTrialText: {
    color: theme.palette.primary.dark,
    fontSize: 10,
  },
  // overview
  tabPanelContent: {
    padding: '25px 15px 20px',
    '@media (max-width: 1024px)': {
      // padding: '10px 0px 20px',
    },
  },
  vTwoSection: {
    display: 'flex',
  },
  vFirstSection: {
    marginRight: '10%',
  },
  overViewSubHeadTitle: {
    fontSize: 16,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    lineHeight: '30px',
    marginBottom: 5,
    letterSpacing: '0.3px',
  },
  descTitletext: {
    fontSize: 15,
    color: theme.palette.grey[600],
    marginBottom: 20,
    lineHeight: '25px',
    letterSpacing: '0.3px',
  },
  planNameText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  defaultText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    display: 'inline-flex',
  },
  planHeadingText: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '25px',
  },
  planHeadingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  planTypeVcFirmText: {
    height: 18,
    background: theme.shadows[4],
    borderRadius: 3,
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 10,
    padding: 3,
    color: theme.palette.common.white,
  },
  planTypeYardstickText: {
    height: 18,
    background: theme.shadows[5],
    borderRadius: 3,
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 10,
    padding: 3,
    color: theme.palette.common.white,
  },
  planTypeAngelInvText: {
    height: 18,
    background: theme.shadows[6],
    borderRadius: 3,
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 10,
    padding: 3,
    color: theme.palette.common.white,
  },
  userTypeAngelInvText: {
    height: 14,
    background: theme.shadows[6],
    borderRadius: 3,
    fontSize: 8,
    fontWeight: 400,
    marginLeft: 10,
    padding: 3,
    color: theme.palette.common.white,
  },
  userTypeYardstickText: {
    height: 14,
    background: theme.shadows[5],
    borderRadius: 3,
    fontSize: 8,
    fontWeight: 400,
    marginLeft: 10,
    padding: 3,
    color: theme.palette.common.white,
  },
  userTypeVcFirmText: {
    height: 14,
    background: theme.shadows[4],
    borderRadius: 3,
    fontSize: 8,
    fontWeight: 400,
    marginLeft: 10,
    padding: 3,
    color: theme.palette.common.white,
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 11,
  },
  planNameDisableText: {
    textDecoration: 'underline',
    color: theme.palette.text.disabled,
  },
}));
