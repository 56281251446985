import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEmpty, get, find } from 'lodash';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  StyledTableCell,
  StyledTableRow,
} from 'components';
import { FN } from 'common/types';
import {
  deleteDealUpdate,
  getAdminDealUpdates,
  publishDealUpdate,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { RootState } from 'redux-modules/store/rootState';

import styles from './styles';
import AddInvestmentUpdate from './AddInvestmentUpdate';

type ActionMenuProps = {
  invUpdate: any;
  index: number;
  handleActionEdit: FN;
  handleActionDelete: FN;
};

const ActionMenu = (props: ActionMenuProps) => {
  const classes = styles();
  const { invUpdate, index, handleActionEdit, handleActionDelete } = props;
  // eslint-disable-next-line no-undef
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleActionMenuClick = (
    // eslint-disable-next-line no-undef
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="action"
        aria-controls={`simple-action-menu-${index}`}
        aria-haspopup="true"
        size="medium"
        style={{
          padding: 0,
        }}
        onClick={handleActionMenuClick}
      >
        <MoreHorizRoundedIcon className={classes.dottedThemeIcon} />
      </IconButton>
      <Menu
        id={`simple-action-menu-${index}`}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleActionMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleActionEdit(invUpdate);
            handleActionMenuClose();
          }}
        >
          <EditIcon className={classes.actionIcon} /> Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleActionDelete(invUpdate);
            handleActionMenuClose();
          }}
        >
          <DeleteIcon className={classes.actionIcon} /> Delete
        </MenuItem>
      </Menu>
    </>
  );
};

const InvestmentUpdate = () => {
  const classes = styles();

  const { dealList } = useSelector(({ Deals }: RootState) => Deals);

  const [dealUpdateList, setDealUpdateList] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isOpen, setOpen] = useState<boolean>(false);
  const [dealUpdate, setDealUpdate] = useState<any | null>(null);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const handleDialogOpen = (invUpdate: any = {}) => {
    setDealUpdate(invUpdate);
    setOpen(true);
  };

  const handleActionEdit = (invUpdate: any) => {
    handleDialogOpen(invUpdate);
  };

  const handleActionDelete = (invUpdate: any) => {
    handleDialogOpen(invUpdate);
    setDeleteAction(true);
  };

  const handleDialogClose = () => {
    setDealUpdate(null);
    setDeleteAction(false);
    setErrorMessage('');
    setSuccessMessage('');
    setOpen(false);
  };

  const handleDeleteUser = async () => {
    setErrorMessage('');
    try {
      if (!isEmpty(get(dealUpdate, 'id'))) {
        setDeleteLoading(true);
        await deleteDealUpdate({
          id: get(dealUpdate, 'id'),
        });
        setDeleteLoading(false);
        getDealUpdate();
        handleDialogClose();
      }
    } catch (err) {
      const message: string = errorMessageHandler(err) || get(err, 'message');
      setDeleteLoading(false);
      setErrorMessage(message);
    }
  };

  const publishUpdate = async (dealUpdate: any) => {
    await publishDealUpdate({
      updateId: get(dealUpdate, 'id'),
      publish: true,
    });
    getDealUpdate();
  };

  const getDealUpdate = async (dealId?: string) => {
    setErrorMessage('');
    try {
      setIsLoading(true);
      const response: any = await getAdminDealUpdates(dealId);
      setDealUpdateList(response);
      setIsLoading(false);
      setErrorMessage('');
    } catch (err) {
      const message: string = errorMessageHandler(err) || get(err, 'message');
      setIsLoading(false);
      setErrorMessage(message);
    }
  };

  useEffect(() => {
    getDealUpdate();
  }, []);

  return (
    <>
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="caption" className={classes.successText}>
          {successMessage}
        </Typography>
      )}
      {isLoading ? (
        <Box className={classes.loader}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <>
          <Box className={classes.listingBox}>
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
            >
              <Table stickyHeader aria-label="customized table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <StyledTableCell>SPV</StyledTableCell>
                    <StyledTableCell>Title</StyledTableCell>
                    <StyledTableCell>Published On</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealUpdateList.map((row: any, index: number) => (
                    <StyledTableRow hover key={row.id}>
                      <StyledTableCell>
                        {get(find(dealList, { id: row.dealId }), 'deal') ||
                          row.dealId}
                      </StyledTableCell>
                      <StyledTableCell>{row.title}</StyledTableCell>
                      <StyledTableCell>
                        {row.publishedOn ? row.publishedOn.split('T')[0] : '-'}{' '}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.published ? (
                          'Published'
                        ) : (
                          <Button
                            name="Publish"
                            className={classes.logTableBtn}
                            onClick={() => publishUpdate(row)}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <ActionMenu
                          invUpdate={row}
                          index={index}
                          handleActionDelete={handleActionDelete}
                          handleActionEdit={handleActionEdit}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.Buttons}>
            <Button
              variant="contained"
              name="New Update"
              onClick={() => handleDialogOpen(null)}
            />
          </Box>
        </>
      )}
      <Dialog
        open={isOpen}
        maxWidth={deleteAction ? 'md' : 'lg'}
        className={classes.addUpdateModel}
        subheading={''}
        title={
          isEmpty(dealUpdate)
            ? 'New Deal Update'
            : deleteAction
            ? 'Delete Deal Update'
            : 'Update Deal Update'
        }
        handleClose={() => {
          handleDialogClose();
        }}
        dialogAction={deleteAction}
        dialogActionChildren={
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              name="OK"
              onClick={handleDeleteUser}
              className={classes.btnSubmit}
              isLoading={deleteLoading}
              disabled={deleteLoading}
            />
            <Button
              className={classes.backBtn}
              onClick={handleDialogClose}
              name="Cancel"
            />
          </Box>
        }
      >
        {deleteAction ? (
          <>
            {errorMessage && (
              <Typography variant="caption" className={classes.errorText}>
                {errorMessage}
              </Typography>
            )}
            <Typography variant="h5" className={classes.deleteUpdatetext}>
              Are you sure, you want to delete the update?
            </Typography>
          </>
        ) : (
          <AddInvestmentUpdate
            handleBack={() => setOpen(false)}
            dealUpdate={dealUpdate}
            getInvestmentUpdateList={() => getDealUpdate()}
          />
        )}
      </Dialog>
    </>
  );
};

export default InvestmentUpdate;
