import { put, call, all, fork, takeLatest } from 'redux-saga/effects';

import { errorMessageHandler } from 'common/utils/helpers';
import {
  addFundingRound,
  approveFundingRound,
  getStartupFundingRoundList,
  getStartupFundingRoundListByAdmin,
} from 'services';
import { ParamsType, ISagaAction, startupIdPayload } from 'common/types';

import {
  GET_STARTUP_FUNDING_ROUND_LIST,
  GET_STARTUP_FUNDING_ROUND_LIST_SUCCESS,
  GET_STARTUP_FUNDING_ROUND_LIST_LOADING,
  GET_STARTUP_FUNDING_ROUND_LIST_FAILURE,
  ADD_STARTUP_FUNDING_ROUND,
  ADD_STARTUP_FUNDING_ROUND_LOADING,
  ADD_STARTUP_FUNDING_ROUND_FAILURE,
  APPROVE_FUNDING_ROUND_LOADING,
  APPROVE_FUNDING_ROUND_FAILURE,
  APPROVE_FUNDING_ROUND,
} from './Actions';
import { ApproveFundingRoundPayload } from './Types';

type ResponseType = {
  response: any;
};

function* handleGetStartupFundingRoundList(data: ISagaAction<ParamsType>) {
  try {
    const { payload: startupId } = data;

    yield put({ type: GET_STARTUP_FUNDING_ROUND_LIST_LOADING });
    const response: ResponseType = !startupId
      ? yield call<any>(getStartupFundingRoundList)
      : yield call<any>(getStartupFundingRoundListByAdmin, startupId);
    yield put({
      type: GET_STARTUP_FUNDING_ROUND_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_STARTUP_FUNDING_ROUND_LIST_FAILURE,
      payload: message,
    });
  }
}

function* handleAddStartupFundingRound(data: ISagaAction<startupIdPayload>) {
  const { payload } = data;
  const { startupId } = payload;
  try {
    yield put({ type: ADD_STARTUP_FUNDING_ROUND_LOADING });
    yield call<any>(addFundingRound, payload);
    yield put({ type: GET_STARTUP_FUNDING_ROUND_LIST, payload: startupId });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: ADD_STARTUP_FUNDING_ROUND_FAILURE, payload: message });
  }
}

function* handleApproveFundingRound(
  data: ISagaAction<ApproveFundingRoundPayload>,
) {
  const { payload } = data;
  const { startupId } = payload;
  try {
    yield put({ type: APPROVE_FUNDING_ROUND_LOADING });
    yield call<any>(approveFundingRound, payload);
    yield put({ type: GET_STARTUP_FUNDING_ROUND_LIST, payload: startupId });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: APPROVE_FUNDING_ROUND_FAILURE, payload: message });
  }
}

function* getStartupFundingRoundListSaga() {
  yield takeLatest(
    GET_STARTUP_FUNDING_ROUND_LIST,
    handleGetStartupFundingRoundList,
  );
}

function* addStartupFundingRoundSaga() {
  yield takeLatest(ADD_STARTUP_FUNDING_ROUND, handleAddStartupFundingRound);
}

function* handleApproveFundingRoundSaga() {
  yield takeLatest(APPROVE_FUNDING_ROUND, handleApproveFundingRound);
}

export default function* FundingRoundSaga() {
  yield all([
    fork(getStartupFundingRoundListSaga),
    fork(addStartupFundingRoundSaga),
    fork(handleApproveFundingRoundSaga),
  ]);
}
