import React from 'react';
import { Typography, Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { get } from 'lodash';
import moment from 'moment';

import { Box } from 'components';
import { RightArrow } from 'assets';
import history from 'common/utils/history';

import styles from './styles';

const VcReview = ({ data, status }: any) => {
  const classes = styles();
  const { investor, investorId, name, actionRelativeId } = data;

  const handleGetVcByInvestorId = () => {
    if (status === 'COMPLETED') {
      get(data, 'name') === 'VC_FIRM_FUND_REVIEW'
        ? history.push(`/admin/vc-funds/${actionRelativeId}`)
        : history.push(`/admin/vc-firms/${investorId}?value=${name}`);
    } else {
      get(data, 'name') === 'VC_FIRM_FUND_REVIEW'
        ? history.push(
            `/admin/vc-funds/${actionRelativeId}?value=${name}&status=${status}`,
          )
        : history.push(
            `/admin/vc-firms/${investorId}?value=${name}&status=${status}`,
          );
    }
  };

  return (
    <Box className={classes.dashActProfileBox}>
      <Box className={classes.dashActProfileLeft}>
        <Avatar className={classes.avatarIcon}>
          <PersonIcon />
        </Avatar>
        <Box className={classes.dashActProfiledetails}>
          <Typography className={classes.dateText}>
            {moment(data.createdAt).format('DD, MMM YYYY')}
          </Typography>
          <Typography className={classes.profileNameText} variant="h4">
            {get(data, 'name') === 'VC_FIRM_SIGNUP'
              ? get(investor, 'name')
              : get(data, 'name') === 'VC_FIRM_REVIEW'
              ? get(investor, 'name')
              : get(data, 'name') === 'VC_FIRM_FUND_REVIEW'
              ? get(data, 'vcFund.fundTitle')
              : get(data, 'name') === 'ANGEL_INVESTOR_REVIEW'
              ? get(investor, 'name')
              : get(data, 'name') === 'YARDSTICK_USER_REVIEW'
              ? get(investor, 'name')
              : ''}
            <Typography
              className={
                get(data, 'name') === 'ANGEL_INVESTOR_REVIEW'
                  ? classes.profileSubVcAngelText
                  : get(data, 'name') === 'YARDSTICK_USER_REVIEW'
                  ? classes.profileSubVcYardstickText
                  : classes.profileSubVcText
              }
              variant="caption"
            >
              {get(data, 'name') === 'VC_FIRM_SIGNUP'
                ? 'New Signup'
                : get(data, 'name') === 'VC_FIRM_REVIEW'
                ? 'VentureInsights Plus New Signup'
                : get(data, 'name') === 'VC_FIRM_FUND_REVIEW'
                ? 'New Fund'
                : get(data, 'name') === 'ANGEL_INVESTOR_REVIEW'
                ? 'VentureInsights New Signup'
                : get(data, 'name') === 'YARDSTICK_USER_REVIEW'
                ? 'Yardstick New Signup'
                : ''}
            </Typography>
          </Typography>
          <Box className={classes.vcFirmDetailscontainer}>
            <Typography className={classes.profileEmailText} variant="h4">
              {investor.email}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.dashActProfileRight}>
        <Typography
          variant="caption"
          className={classes.openText}
          onClick={handleGetVcByInvestorId}
        >
          Open{' '}
        </Typography>
        <img
          src={RightArrow}
          alt={RightArrow}
          className={classes.pointer}
          onClick={handleGetVcByInvestorId}
        />
      </Box>
    </Box>
  );
};

export default VcReview;
