import { IRoute } from 'common/types';
import SimpleLayout from 'layouts/SimpleLayout';

import StartupOnboard from './StartupOnboard';

export const StartupOnboardRoute: IRoute = {
  path: '/admin/startup-onboard/:id',
  component: StartupOnboard,
  layout: SimpleLayout,
};
