import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import {
  getFundType,
  getLoansDetailsByStartupId,
} from 'redux-modules/enquiries/Actions';
import { getStartupLoan } from 'services';
import { RootState } from 'redux-modules/store/rootState';
import {
  getPaymentsList,
  emptySuccessMessage,
  removePaymentDetails,
} from 'redux-modules/payments/Actions';
import { getVdInstallments } from 'redux-modules/vdinstallments/Actions';
import { Box, Button, Dialog } from 'components';
import { FUND_TYPE } from 'common/utils/constants';
import { Ornament } from 'assets';

import styles from './styles';
import PaymentsList from './PaymentsList';
import InstallmentsList from './InstallmentsList';
import LogPayment from './LogPayment';

type PaymentsProps = {
  startupId: string | undefined;
};

const Payments = ({ startupId }: PaymentsProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const pathName = useLocation().pathname;

  const [fundType, setFundType] = useState<string>('');
  const [isOpen, setOpen] = useState<boolean>(false);
  const [paymentId, setPaymentId] = useState<string>('');
  const [loan, setLoan] = React.useState<any>({});
  const [vdInstallmentId, setVdInstallmentId] = useState<string>('');
  const [installmentAmount, setInstallmentAmount] = useState<string>('');

  const { role, startUpId } = useSelector(({ Auth }: RootState) => Auth.user);
  const isRoleAdmin = role === 'ADMIN';

  const {
    loanTypeObj,
    loansDetails,
    errorMessage,
    loanCloserInfo,
  } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const { isLoading, paymentsList, successMessage } = useSelector(
    ({ Payments }: RootState) => Payments,
  );
  const { vdInstallmentsList } = useSelector(
    ({ VdInstallments }: RootState) => VdInstallments,
  );

  useEffect(() => {
    if (startupId || startUpId) {
      if (isRoleAdmin) {
        dispatch(getFundType(startupId || startUpId));
      } else {
        dispatch(getLoansDetailsByStartupId(startupId || startUpId));
      }
    }
  }, [startupId, startUpId]);

  useEffect(() => {
    if (startUpId || startupId) {
      getStartupLoan(`${startUpId || startupId}/BRIDGE_LOAN`).then((res) =>
        setLoan(get(res, 'loanStatus')),
      );
    }
  }, [startUpId, startupId]);

  useEffect(() => {
    if (loanTypeObj || loansDetails) {
      let loanType = '';
      if (isRoleAdmin) {
        loanType = get(loanTypeObj, 'fundType');
      } else {
        loanType = get(loansDetails, 'fundType');
      }
      setFundType(loanType);
    }
  }, [loanTypeObj, loansDetails]);

  useEffect(() => {
    if (fundType || successMessage) {
      const payload = {
        startupId: startupId || startUpId,
        loanType: FUND_TYPE.BRIDGE_LOAN,
      };
      dispatch(getPaymentsList(payload));
      if (fundType === FUND_TYPE.VENTURE_DEBT) {
        dispatch(getVdInstallments(payload));
      }
      dispatch(emptySuccessMessage());
    }
  }, [fundType, successMessage, startUpId, startupId]);

  const handleDialogOpen = (
    id: string,
    vdInstallmentId: string,
    vdInstalAmount: string,
  ) => {
    setOpen(true);
    setPaymentId(id);
    setVdInstallmentId(vdInstallmentId);
    setInstallmentAmount(vdInstalAmount);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(removePaymentDetails());
  };
  const conditionToShowLogBtn = () => {
    if (
      ['CLOSED', 'CONVERTED', 'EXTENDED'].includes(
        get(loanCloserInfo, 'status'),
      )
    ) {
      return false;
    }
    if (!errorMessage && loan === 'PROCESSED') return true;
  };
  return (
    <>
      {pathName === '/payments' && (
        <Box className={classes.paymentsLeft}>
          <Box className={classes.headerPayment}>
            <img
              src={Ornament}
              alt={Ornament}
              className={classes.imgOrnament}
            />
            <Typography gutterBottom className={classes.headPytext}>
              Payments
            </Typography>
          </Box>
        </Box>
      )}
      {fundType === 'VENTURE_DEBT' ? (
        <Box
          className={
            pathName === '/payments' ? classes.vdPayment : classes.vdTabPayment
          }
        >
          <Box className={classes.paymentsHeader}>
            <Typography className={classes.paymentsTitle}>
              VD Payments
            </Typography>
          </Box>
          <InstallmentsList
            isLoading={isLoading}
            data={vdInstallmentsList}
            isRoleAdmin={isRoleAdmin}
            paymentsType={'VENTURE_DEBT'}
            handleDialogOpen={handleDialogOpen}
          />

          <Box className={classes.paymentsHeader}>
            <Typography className={classes.paymentsTitle}>
              Bridge Loan
            </Typography>
          </Box>
          <PaymentsList
            isLoading={isLoading}
            data={paymentsList}
            isRoleAdmin={isRoleAdmin}
            paymentsType={'BRIDGE_LOAN'}
            handleDialogOpen={handleDialogOpen}
          />
        </Box>
      ) : (
        <Box
          className={
            pathName === '/payments' ? classes.vdPayment : classes.vdTabPayment
          }
        >
          <Box className={classes.paymentsHeader}>
            <Typography className={classes.paymentsTitle}>
              Bridge Loan
            </Typography>
            {conditionToShowLogBtn() && (
              <Button
                className={classes.logBtn}
                name="Log Payment"
                onClick={() => handleDialogOpen('', '', '')}
              />
            )}
          </Box>
          <PaymentsList
            isLoading={isLoading}
            data={paymentsList}
            isRoleAdmin={isRoleAdmin}
            paymentsType={'BRIDGE_LOAN'}
            handleDialogOpen={handleDialogOpen}
          />
        </Box>
      )}
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        className={classes.approveModel}
        subheading={''}
        title={'Log Payment'}
        handleClose={() => {
          handleClose();
        }}
      >
        <LogPayment
          handleBack={() => setOpen(false)}
          isRoleAdmin={isRoleAdmin}
          startupId={startupId || startUpId}
          fundType={fundType}
          paymentId={paymentId}
          vdInstallmentId={vdInstallmentId}
          installmentAmount={installmentAmount}
        />
      </Dialog>
    </>
  );
};

export default Payments;
