import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tabPanelContent: {
    // height: 'calc(100vh - 250px)',
    // overflow: 'auto',
    padding: '10px 20px 20px',
    '@media (max-width: 1024px)': {
      padding: '10px 0px 20px',
    },
  },
  basicHeadTitle: {
    fontSize: 20,
    color: theme.palette.grey[500],
    marginBottom: 20,
    paddingTop: 20,
  },
  descTitletext: {
    fontSize: 15,
    color: theme.palette.grey[600],
    marginBottom: 25,
  },
  overViewSubHeadTitle: {
    fontSize: 15,
    color: theme.palette.grey[500],
    fontWeight: 600,
  },
}));
