import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  // overview̦
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    display: 'block',
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tabPanelContent: {
    padding: '25px 15px 20px',
    '@media (max-width: 1024px)': {
      // padding: '10px 0px 20px',
    },
  },
  overViewSubHeadTitle: {
    fontSize: 16,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    lineHeight: '30px',
    marginBottom: 5,
    letterSpacing: '0.3px',
  },
  descTitletext: {
    fontSize: 15,
    color: theme.palette.grey[600],
    marginBottom: 20,
    lineHeight: '25px',
    letterSpacing: '0.3px',
  },
  // Marketing Material
  accordContainer: {
    padding: '25px 15px',
    '& .MuiIconButton-root': {
      top: 22,
    },
    '@media (max-width: 767px)': {
      padding: '25px 12.5px',
    },
  },
  teaserText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[600],
    marginBottom: 20,
    lineHeight: '24px',
    letterSpacing: '0.3px',
  },
  // legal regulatory
  legalRegContainer: {
    padding: '25px 15px 20px',
  },
  legalNameContainer: {
    // paddingLeft: 20,
    // paddingTop: 0,
  },
  legalNameHeader: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.warning.contrastText,
    marginBottom: 10,
    lineHeight: '24px',
    letterSpacing: '0.3px',
  },
  subscriptioDocTextHeader: {
    fontSize: 15,
    fontWeight: 400,
    color: theme.palette.text.primary,
    marginBottom: 5,
    lineHeight: '24px',
    letterSpacing: '0.3px',
  },

  // ---------------================= Marketing Material -----------=====================

  MarketingDocumentLists: {
    margin: 0,
    display: 'grid',
    gridGap: 20,
    gridTemplateColumns: 'repeat(auto-fill, minmax(106px, 1fr) )',
    padding: '5px 20px 10px 10px',
    maxHeight: 250,
    overflowX: 'hidden',
    overflowY: 'auto',
    '@media (max-width: 767px)': {
      maxHeight: 350,
    },
  },
  DocumentContent: {
    textAlign: 'center',
  },
  BGDocuImg: {
    height: 106,
    width: 106,
    objectFit: 'cover',
  },
  DocuName: {
    fontWeight: 400,
    overflowWrap: 'break-word',
    fontSize: 12,
    lineHeight: '17px',
    opacity: 0.75,
    color: theme.palette.warning.contrastText,
    marginBottom: 0,
  },
  emailText: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '25px',
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    marginBottom: 0,
  },
  reviewDocuBox: {
    textAlign: 'center',
  },
  reviewDocuText: {
    fontSize: 11,
    color: theme.palette.grey[600],
    marginBottom: 10,
    overflowWrap: 'break-word',
  },
  bGDocuImg: {
    height: 100,
    width: 106,
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  image: {
    height: '100px',
    width: 'initial',
    maxWidth: '100%',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    zIndex: 5,
    opacity: 1,
    cursor: 'pointer',
  },
  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  vTwoSection: {
    display: 'flex',
  },
  vFirstSection: {
    marginRight: '10%',
  },
  // payments
  vcFundPricingBox: {
    padding: '0px 10px 30px',
  },
  tableContainer: {
    boxShadow: theme.shadows[2],
    maxHeight: 340,
    minHeight: 200,
    overflow: 'overlay',
    borderRadius: 11,
    marginTop: 20,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '26px',
      padding: '16px 8px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 16,
      padding: '10px 8px',
      textAlign: 'left',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },
  investorStatusPopup: {
    textAlign: 'center',
  },
  backContinueBox: {
    paddingTop: 30,
    paddingRight: 10,
  },
  approveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 143,
    fontSize: 16,
    cursor: 'pointer',
    height: 38,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 125,
    },
  },
  rejectBtn: {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 143,
    fontSize: 16,
    borderColor: theme.palette.grey[700],
    cursor: 'pointer',
    height: 38,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 125,
    },
  },
  cancelBtn: {
    width: 143,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    cursor: 'pointer',
    height: 38,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
    marginLeft: 20,
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 125,
      marginLeft: 15,
    },
  },
  summaryAccordRight: {
    textAlign: 'right',
  },
  moreIcon: {
    alignSelf: 'center',
    color: theme.palette.primary.main,
    width: 30,
    height: 30,
    marginLeft: 12,
    position: 'relative',
    top: 10,
  },
  accordDeletedFundContainer: {
    '& .MuiAccordionSummary-expandIcon': {
      top: 25,
      marginLeft: 10,
    },
    padding: '0px 40px 10px 60px',
    //height: 'calc(100% - 500px)',
    '@media (max-width: 1180px)': {
      padding: '0px 40px 10px 40px',
    },
    '@media (max-width: 1024px)': {
      padding: '0px 20px 20px',
    },
  },
  actionButtonBox: {
    display: 'flex',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  viewButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid #1EA59A',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    padding: '4px 7px 4px',
    margin: '5px 0px',
    cursor: 'pointer',
    width: 71,
    height: 30,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.secondary.main,
      background: theme.palette.primary.contrastText,
    },
  },
  fundTableContainer: {
    boxShadow: theme.shadows[2],
    maxHeight: 325,
    '@media (max-width: 1180px)': {
      maxHeight: 300,
    },
    '@media (max-width: 1024px)': {
      maxHeight: 250,
    },
    //minHeight: 200,
    overflow: 'overlay',
    borderRadius: 11,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      // minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
    '& .MuiTableCell-stickyHeader': {
      left: 'auto',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '26px',
      padding: '16px 8px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 16,
      padding: '10px 8px',
      textAlign: 'left',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },
  AccordionContent: {
    padding: '0px',
    maxHeight: 200,
    width: '100%',
    '@media (max-width: 525px)': {
      padding: '10px 15px 25px',
    },
    '& .MuiTableContainer-root': {
      maxHeight: 200,
    },
  },
}));
