import { makeStyles } from '@material-ui/core';
const drawerWidth = 280;
export default makeStyles((theme) => ({
  dashLeftContent: {
    flexDirection: 'column',
    height: '100%',
    padding: 0,
    alignItems: 'baseline',
  },
  leftLinks: {
    flexGrow: 1,
    padding: '0px 24px',
    '@media (max-width: 1220px)': {
      padding: '0px 14px',
    },
  },
  dashLogo: {
    display: 'flex',
    // width: '100%',
    marginBottom: 70,
    padding: '20px 40px 0',
    '@media (max-width: 1220px)': {
      padding: '20px 30px 0',
    },
    '@media (max-width: 1024px)': {
      marginBottom: 0,
      padding: 0,
    },
    '@media (max-width: 640px)': {
      marginBottom: 0,
    },
  },
  menuDash: {
    marginRight: 20,
    alignSelf: 'center',
  },
  linksInfo: {
    height: 'calc(100% - 110px - 130px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    // scrollbarWidth: 'none',
    width: '100%',
    // '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    //   backgroundColor: '#1EA59A',
    //   width: '0.6em',
    //   visibility: 'hidden',
    // },
    // '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    //   borderRadius: 8,
    //   backgroundColor: '#F6FAFA',
    //   minHeight: 24,
    //   border: '1px solid #1EA59A',
    //   visibility: 'hidden',
    // },
    // '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
    //   backgroundColor: '#2B2B2B',
    // },
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 60px - 80px)',
    },
    '@media (max-width: 640px)': {
      height: 'calc(100% - 60px - 80px)',
    },
  },
  logoImg: {
    width: 100,
    height: 'auto',
  },
  logoText: {
    fontSize: 16,
    fontStyle: 'italic',
    letterSpacing: '0.5px',
    color: theme.palette.secondary.dark,
    paddingLeft: 15,
    alignSelf: 'center',
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.error.contrastText,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 10,
    },
    '@media (max-width: 640px)': {
      marginTop: 10,
    },
  },
  activeMenuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.primary.main,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 2,
    },
  },
  redColor: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.primary.main,
    marginTop: 15,
    // paddingLeft: 0,
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 2,
    },
  },
  brageAccount: {
    left: 40,
    top: 1,
    '& .MuiBadge-colorSecondary': {
      background: theme.palette.secondary.light,
      color: theme.palette.primary.contrastText,
    },
  },
  leftBottomSection: {
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
  },
  logoutBtn: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.error.contrastText,
    marginTop: 30,
    padding: '6px 16px',
    '@media (max-width: 1024px)': {
      padding: '10px 30px',
      marginTop: 15,
    },
    '@media (max-width: 640px)': {
      marginTop: 15,
    },
  },
  dashAvatarLogo: {
    marginBottom: 40,
    padding: '0 40px',
    '@media (max-width: 1220px)': {
      padding: '0px 30px',
    },
    '@media (max-width: 1024px)': {
      padding: '30px 30px 0',
      marginBottom: 35,
    },
    '@media (max-width: 640px)': {
      marginBottom: 25,
    },
  },
  labelFundUpHead: {
    fontSize: 16,
  },
  labelStartUpText: {
    fontSize: 13,
    color: theme.palette.grey[600],
  },
  iconStyleOne: {
    height: 45,
    width: 45,
    fontSize: 25,
    fontWeight: 600,
    marginBottom: 10,
    textTransform: 'capitalize',
  },
  appBarRoot: {
    padding: '20px 120px 20px 160px',
    backgroundColor: 'white',
    '@media (max-width: 1024px)': {
      padding: '20px 5px',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      overflow: 'unset',
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 10px',
    height: 60,
  },
  leftMobileLinks: {},
  mobileBtns: {},
  toolbarBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    '@media (max-width: 1024px)': {
      padding: 0,
      minHeight: 40,
    },
    '@media (max-width: 767px)': {
      padding: '15px 20px',
      minHeight: 40,
    },
  },
  menuIcon: {
    color: theme.palette.secondary.contrastText,
    height: 32,
    width: 32,
  },
  rightIcon: {
    color: theme.palette.secondary.contrastText,
    fontSize: 30,
  },
  drawerContainer: {
    padding: '20px 30px',
  },
  drawerContent: {
    width: drawerWidth,
    height: '100%',
  },
  //Custom Switch Style
  switchBox: {
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
    margin: '25px 30px',
  },
  switchRoot: {
    width: '170px',
    height: '40px',
    padding: '0px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#E5E5E5',
      },
      '& $thumb:after': {
        content: "'Startups'",
      },
    },
    '&$disabled + $track': {
      backgroundColor: '#E5E5E5',
      opacity: 0.5,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#E5E5E5',
      opacity: '0.5 !important',
    },
  },
  thumb: {
    color: theme.palette.primary.main,
    width: '85px',
    height: '40px',
    margin: '-1px',
    borderRadius: 31,
    '&:after': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 11,
      right: 18,
      left: 15,
      content: "'Investors'",
    },
  },
  track: {
    borderRadius: '31px',
    backgroundColor: '#E5E5E5',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 11,
    },
    '&:after': {
      content: "'Investors'",
      left: 15,
    },
    '&:before': {
      content: "'Startups'",
      right: 18,
    },
  },
  checked: {
    color: theme.palette.primary.main,
    transform: 'translateX(85px) !important',
  },
  //Custom Switch Style
  adminSwitch: {
    margin: '25px 30px',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 31,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    width: 176,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    '@media (max-width: 1125px)': {
      width: 280,
    },
  },
  wrappedSelectVal: {
    marginTop: 8,
    marginBottom: 0,
    color: 'white',
    borderRadius: 31,
    width: 156,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    background: theme.palette.primary.light,
    padding: '2px 5px 2px 15px',
    '& .MuiSelect-select': {
      paddingRight: 34,
      color: theme.palette.primary.contrastText,
      fontWeight: 500,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.contrastText,
      paddingLeft: 3,
      marginRight: 12,
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    // margin: 8,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    cursor: 'pointer',
    lineHeight: '18px',
    height: 48,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
}));
