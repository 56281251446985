import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';

import { Dialog, WrappedTextInput, Button } from 'components';
import { createUpdateVcYcBatch } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';
import { CreateYCBatchSchema } from '../validation';

type Props = {
  addYCBatch: boolean;
  setAddYCBatch: React.Dispatch<React.SetStateAction<boolean>>;
  getAllVcDealsAndBatches: () => void;
};

const AddYCBatch = ({
  addYCBatch,
  setAddYCBatch,
  getAllVcDealsAndBatches,
}: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const { handleSubmit, control, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CreateYCBatchSchema),
  });

  const onSubmit = () => {
    setErrorMessage('');
    const data = { ...getValues() };
    setIsLoading(true);
    const obj = {
      ycBatchName: get(data, 'name'),
    };
    createUpdateVcYcBatch(obj)
      .then(() => {
        getAllVcDealsAndBatches();
        setAddYCBatch(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Dialog
        open={addYCBatch}
        maxWidth={'sm'}
        subheading={''}
        title={'8vdX recommended deals'}
        handleClose={() => {
          setAddYCBatch(false);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.inputHead}>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelText}
              >
                Batch Name<span className={classes.errorRequiredText}>*</span>
              </Typography>

              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      placeholder="Enter your Batch name"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(e.target.value.trimStart())
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          {errorMessage && (
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
          <Box className={classes.nextPrevButtonBox}>
            <Button
              type="submit"
              className={classes.nextButton}
              name="Create"
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              variant="outlined"
              className={classes.prevButton}
              name="Cancel"
              disabled={isLoading}
              onClick={() => setAddYCBatch(false)}
            />
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default AddYCBatch;
