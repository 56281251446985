import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paymentsBox: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 20px)',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  paymentsLeft: {
    width: 'calc(100% - 300px)',
    flex: '0 auto',
    // paddingRight: 30,
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 260px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 20,
    },
    '@media (max-width: 525px)': {
      paddingLeft: 15,
    },
  },
  paymentsRight: {
    flexGrow: 1,
    width: 300,
    background: theme.palette.warning.main,
    borderTopRightRadius: 25,
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      width: 260,
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  tableContainer: {
    boxShadow: theme.shadows[0],
    height: 'calc(100% - 185px)',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTable-root': {
      // height: '100%',
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
    '& .MuiTableCell-root': {
      padding: '16px 16px 10px',
    },
  },
  headerPayment: {
    position: 'relative',
    padding: '78px 63px 20px',
    '@media (max-width: 1024px)': {
      padding: '110px 40px 20px',
    },
    '@media (max-width: 767px)': {
      padding: '50px 20px 15px',
    },
  },
  headPytext: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    marginBottom: 0,
    position: 'relative',
    top: -22,
    '@media (max-width: 1024px)': {
      fontSize: 24,
    },
  },
  imgOrnament: {
    position: 'absolute',
    top: 40,
    left: 35,
    height: 90,
    width: 90,
    '@media (max-width: 1120px)': {
      width: 60,
      height: 60,
    },
    '@media (max-width: 1024px)': {
      top: 83,
      left: 20,
      objectFit: 'cover',
    },
    '@media (max-width: 767px)': {
      top: 23,
      left: 20,
      objectFit: 'cover',
    },
  },
  vdPayment: {
    padding: '0 50px 0 63px',
  },
  vdTabPayment: {
    padding: '20px 10px 20px 15px',
  },
  paymentsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid #DEDEDE',
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 18,
  },
  paymentsTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.grey[500],
    alignSelf: 'center',
  },
  logBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 135,
    fontSize: 16,
    height: 40,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  logTableBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 90,
    fontSize: 10,
    height: 30,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    // '@media (max-width: 525px)': {
    //   width: 100,
    //   height: 40,
    // },
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
  tdBtn: {
    border: '1px solid #1EA59A',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    padding: 3,
    cursor: 'pointer',
  },

  titleUnderline: {
    borderBottom: '1px solid grey',
  },

  inputHead: {
    padding: '12px 40px 0px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 0px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px 12px 0 !important',
    },
  },
  textValInput: {
    marginBottom: 35,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  inputClasses: {
    display: 'none',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  backContinueBox: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    fontSize: 16,
    height: 48,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  approveModel: {
    height: '100%',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiDialog-container': {
      alignSelf: 'center',
      height: '90%',
      width: 700,
    },
    '& .MuiDialog-paper': {
      margin: 0,
      padding: '40px 20px 40px 45px',
      position: 'relative',
      '@media (max-width: 960px)': {
        padding: '20px 10px 30px 30px',
        maxWidth: '90%',
      },
    },
    '& .MuiTypography-h3': {
      textAlign: 'center',
      padding: '10px 0 15px',
    },
    '& .MuiTypography-h6': {
      padding: '20px 0 20px',
      textAlign: 'center',
    },
    '& .MuiTypography-caption': {
      top: 0,
      right: 15,
    },
    '& .MuiDialogContent-root': {
      padding: '20px 40px 0 0',
      overflowX: 'hidden',
      '@media (max-width: 960px)': {
        paddingRight: 20,
      },
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#1EA59A',
        width: '0.6em',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: '#F6FAFA',
        minHeight: 24,
        border: '1px solid #1EA59A',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#2B2B2B',
      },
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: '0 -12px 20px',
      '@media (max-width: 960px)': {
        margin: '0 -12px 5px',
      },
    },
  },
}));
