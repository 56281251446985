import {
  createStyles,
  TableCell,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      background: theme.palette.grey[200],
      color: theme.palette.grey[300],
      fontSize: 13,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      textAlign: 'center',
      letterSpacing: '3%',
      border: 'none',
      '&:first-child': {
        borderRadius: '1em 0 0 1em',
      },
      '&:last-child': {
        borderRadius: '0 1em 1em 0',
      },
    },
    body: {
      background: theme.palette.common.white,
      color: theme.palette.grey[400],
      fontSize: 13,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      border: 'none',
      textAlign: 'center',
    },
  }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);
