import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  backContinueBox: {
    paddingTop: 10,
    paddingRight: 10,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  successText: {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  inputHead: {
    padding: '12px 40px 0px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 0px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px 12px 0 !important',
    },
  },
  textValInput: {
    marginBottom: 20,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 17,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  listingBox: {
    margin: '20px 0',
    width: '100%',
    '@media (max-width: 1024px)': {
      width: '100%',
      margin: 0,
    },
  },
  tableContainer: {
    boxShadow: theme.shadows[0],
    height: 'calc(100% - 185px)',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTable-root': {
      // height: '100%',
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
    '& .MuiTableCell-root': {
      padding: '16px 16px 10px',
    },
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
  editTableCell: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editIcon: {
    color: theme.palette.primary.main,
    padding: '4px 10px 0px 3px',
    cursor: 'pointer',
  },
  sendBtn: {
    width: '80px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      // color: theme.palette.primary.main,
    },
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
}));
