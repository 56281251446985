import {
  EnquiryPayload,
  DeleteFounderPayload,
  IStartUpType,
  IFounderType,
  IBusinessType,
  IAdditonalType,
  UploadDocuments,
} from 'common/types';

export const USER_BUSINESS_DETAILS = 'user/USER_BUSINESS_DETAILS';
export const USER_COMPANY_DETAILS = 'user/USER_COMPANY_DETAILS';
export const USER_FOUNDER_DETAILS = 'user/USER_FOUNDER_DETAILS';
export const USER_OTHER_INFORMATION = 'user/USER_OTHER_INFORMATION';
export const USER_SUBMIT = 'user/USER_SUBMIT';
export const USER_DATA = 'user/USER_DATA';
export const USER_SUBMIT_FAILURE = 'user/USER_SUBMIT_FAILURE';
export const USER_SUBMIT_LOADING = 'user/USER_SUBMIT_LOADING';
export const USER_SUBMIT_SUCCESS = 'user/USER_SUBMIT_SUCCESS';
export const USER_SUBMIT_FOUNDER = 'user/USER_SUBMIT_FOUNDER';
export const USER_SUBMIT_FAILURE_FOUNDER = 'user/USER_SUBMIT_FAILURE_FOUNDER';
export const USER_SUBMIT_LOADING_FOUNDER = 'user/USER_SUBMIT_LOADING_FOUNDER';
export const USER_SUBMIT_SUCCESS_FOUNDER = 'user/USER_SUBMIT_SUCCESS_FOUNDER';
export const USER_SUBMIT_BUSINESS = 'user/USER_SUBMIT_BUSINESS';
export const USER_SUBMIT_FAILURE_BUSINESS = 'user/USER_SUBMIT_FAILURE_BUSINESS';
export const USER_SUBMIT_LOADING_BUSINESS = 'user/USER_SUBMIT_LOADING_BUSINESS';
export const USER_SUBMIT_SUCCESS_BUSINESS = 'user/USER_SUBMIT_SUCCESS_BUSINESS';
export const USER_SUBMIT_OTHER_INFO = 'user/USER_SUBMIT_OTHER_INFO';
export const USER_STARTUP_ID = 'user/USER_STARTUP_ID';
export const USER_SUBMIT_FAILURE_OTHER_INFO =
  'user/USER_SUBMIT_FAILURE_OTHER_INFO';
export const USER_SUBMIT_LOADING_OTHER_INFO =
  'user/USER_SUBMIT_LOADING_OTHER_INFO';
export const USER_SUBMIT_SUCCESS_OTHER_INFO =
  'user/USER_SUBMIT_SUCCESS_OTHER_INFO';
export const USER_ADD_FOUNDERS = 'user/USER_ADD_FOUNDERS';
export const USER_DELETE_FOUNDERS = 'user/USER_DELETE_FOUNDERS';
export const USER_UPDATE_FOUNDERS = 'user/USER_UPDATE_FOUNDERS';
export const GET_ENQUIRY_DETAILS_BY_ID = 'user/GET_ENQUIRY_DETAILS_BY_ID';
export const GET_ENQUIRY_DETAILS_BY_ID_LOADING =
  'user/GET_ENQUIRY_DETAILS_BY_ID_LOADING';
export const GET_ENQUIRY_DETAILS_BY_ID_SUCCESS =
  'user/GET_ENQUIRY_DETAILS_BY_ID_SUCCESS';
export const GET_ENQUIRY_DETAILS_BY_ID_FAILURE =
  'user/GET_ENQUIRY_DETAILS_BY_ID_FAILURE';
export const GET_COMPANY_DETAILS = 'user/GET_COMPANY_DETAILS';
export const GET_STARTUP_DETAILS_SUCCESS = 'user/GET_STARTUP_DETAILS_SUCCESS';
export const EMPTY_ENQUIRY_FORM = 'EMPTY_ENQUIRY_FORM';
export const UPLOAD_DOCUMENTS_LOADING = 'UPLOAD_DOCUMENTS_LOADING';
export const UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS';
export const UPLOAD_DOCUMENTS_SUCCESS = 'UPLOAD_DOCUMENTS_SUCCESS';
export const UPLOAD_DOCUMENTS_FAILURE = 'UPLOAD_DOCUMENTS_FAILURE';
export const GET_STARTUP_DATA = 'GET_STARTUP_DATA';
export const GET_STARTUP_DATA_LOADING = 'GET_STARTUP_DATA_LOADING';
export const GET_STARTUP_DATA_SUCCESS = 'GET_STARTUP_DATA_SUCCESS';
export const GET_STARTUP_DATA_FAILURE = 'GET_STARTUP_DATA_FAILURE';
export const GET_FOUNDER_DETAILS = 'GET_FOUNDER_DETAILS';
export const GET_FOUNDER_DETAILS_LOADING = 'GET_FOUNDER_DETAILS_LOADING';
export const GET_FOUNDER_DETAILS_SUCCESS = 'GET_FOUNDER_DETAILS_SUCCESS';
export const GET_FOUNDER_DETAILS_FAILURE = 'GET_FOUNDER_DETAILS_FAILURE';

export const handleBusinessDetails = (payload: EnquiryPayload) => {
  return {
    type: USER_BUSINESS_DETAILS,
    payload,
  };
};

export const handleOtherInformation = (payload: EnquiryPayload) => {
  return {
    type: USER_OTHER_INFORMATION,
    payload,
  };
};

export const handleCompanyInformation = (payload: EnquiryPayload) => {
  return {
    type: USER_COMPANY_DETAILS,
    payload,
  };
};

export const handleFounderInformation = (payload: EnquiryPayload) => {
  return {
    type: USER_FOUNDER_DETAILS,
    payload,
  };
};

export const handleSaveForm = (payload: IStartUpType) => {
  return {
    type: USER_DATA,
    payload,
  };
};

export const handleSubmitStartUp = (payload: IStartUpType) => {
  return {
    type: USER_SUBMIT,
    payload,
  };
};

export const handleSubmitFounders = (payload: IFounderType) => {
  return {
    type: USER_SUBMIT_FOUNDER,
    payload,
  };
};

export const handleSubmitBusiness = (payload: IBusinessType) => {
  return {
    type: USER_SUBMIT_BUSINESS,
    payload,
  };
};

export const handleSubmitAdditionalInfo = (payload: IAdditonalType) => {
  return {
    type: USER_SUBMIT_OTHER_INFO,
    payload,
  };
};

export const handleAddFounders = () => {
  return {
    type: USER_ADD_FOUNDERS,
  };
};

export const handleDeleteFounders = (payload: DeleteFounderPayload) => {
  return {
    type: USER_DELETE_FOUNDERS,
    payload,
  };
};

export const handleGetStartUpDetails = (payload: string | undefined) => {
  return {
    type: GET_ENQUIRY_DETAILS_BY_ID,
    payload,
  };
};

export const handleStartUpId = (payload: string | undefined) => {
  return {
    type: USER_STARTUP_ID,
    payload,
  };
};

export const handleGetStartupDetailsById = (payload: string | undefined) => {
  return {
    type: GET_COMPANY_DETAILS,
    payload,
  };
};

export const emptyEnquiryForm = () => {
  return {
    type: EMPTY_ENQUIRY_FORM,
  };
};

export const getDocuments = (payload: UploadDocuments) => {
  return {
    type: UPLOAD_DOCUMENTS,
    payload,
  };
};

export const getStartupData = (payload: string) => {
  return {
    type: GET_STARTUP_DATA,
    payload,
  };
};

export const getFounderDetails = (payload: string) => {
  return {
    type: GET_FOUNDER_DETAILS,
    payload,
  };
};
