import React, { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

import {
  Box,
  Loader,
  StyledTableCell,
  StyledTableRow,
  Button,
} from 'components';
import history from 'common/utils/history';
import { formatAmount } from 'common/utils/helpers';

import styles from './styles';

const headers = (array: string[]) => {
  return (
    <>
      {array.map((i: string, n: number) => {
        return <StyledTableCell key={n}>{i}</StyledTableCell>;
      })}
    </>
  );
};

const TABLE_HEADERS = [
  'Fund',
  'Fund Size',
  'Fund Raised',
  'Start Date',
  'Close Date',
  'Action',
];

const VcFirmFund = ({ vcDetails, isLoading, errorMessage }: any) => {
  const classes = styles();

  const [fundData, setFundData] = React.useState<any>([]);

  useEffect(() => {
    setFundData(get(vcDetails, 'vcFunds'));
  }, []);

  return (
    <Box className={classes.enquiryFundListBox}>
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      <TableContainer className={classes.firmTableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>{headers(TABLE_HEADERS)}</TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={TABLE_HEADERS.length}>
                  <Box className={classes.loader}>
                    <Loader />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : isEmpty(fundData) && !errorMessage ? (
              <StyledTableRow>
                <StyledTableCell colSpan={TABLE_HEADERS.length}>
                  <Box className={classes.loader}>
                    <Typography>No data found in table</Typography>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              (fundData || []).map((row: any, index: number) => (
                <StyledTableRow hover key={index}>
                  <StyledTableCell component="th" scope="row" align="center">
                    {get(row, 'fundTitle')}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {formatAmount(get(row, 'targetFundSize'), {
                      currency: get(row, 'currency') || 'USD',
                    })}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {formatAmount(get(row, 'raisedFundAmount'), {
                      currency: get(row, 'currency') || 'USD',
                    })}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {get(row, 'estimatedFirstCloseAt')
                      ? moment(get(row, 'estimatedFirstCloseAt')).format(
                          'MMM D, YYYY',
                        )
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {get(row, 'estimatedFinalCloseAt')
                      ? moment(get(row, 'estimatedFinalCloseAt')).format(
                          'MMM D, YYYY',
                        )
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.actionButtonBox}
                    style={{ paddingLeft: 2 }}
                  >
                    <Button
                      name="View"
                      className={classes.viewButton}
                      onClick={() =>
                        history.push(`/admin/vc-funds/${get(row, 'id')}`)
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VcFirmFund;
