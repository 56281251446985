import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { getActionsList } from 'redux-modules/global/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { Box, Loader, AlertDialog } from 'components';
import { getLoanCloserDocusignUrl, completeAction } from 'services';
import history from 'common/utils/history';

import PendingActions from './Pending';
import CompletedActions from './Completed';

type Props = {
  status: string;
};

const ActionsList = (props: Props) => {
  const search = history.location.search;
  const event = new URLSearchParams(search).get('event');
  const aid: any = new URLSearchParams(search).get('aid');
  const initsignin = new URLSearchParams(search).get('initsignin');
  const [loanErrorPopup, setLoanErrorPopup] = useState<boolean>(false);
  const { status } = props;
  const dispatch = useDispatch();
  const user = useSelector(({ Auth }: RootState) => Auth.user);
  // eslint-disable-next-line no-unused-vars
  const [loaders, setLoaders] = useState<Record<string, unknown>>({});
  const [
    processingDocusignAction,
    setProcessingDocusignAction,
  ] = useState<boolean>(!isEmpty(aid) || event === 'signing_complete');

  console.log(event, aid); //eslint-disable-line
  useEffect(() => {
    const role = get(user, 'role', '');
    const startUpId = get(user, 'startUpId', '');
    const payload = {
      role,
      startUpId,
      status,
    };
    dispatch(getActionsList(payload));
  }, []);

  useEffect(() => {
    if (event === 'signing_complete' && aid) {
      handleCompleteAction();
    }
  }, [aid, event]);

  const handleCompleteAction = () => {
    setProcessingDocusignAction(true);
    completeAction({ id: aid })
      .then(() => {
        history.push('/actions');
      })
      .finally(() => setProcessingDocusignAction(false));
  };
  const getDocusignUrl = (actionId: string) => {
    setLoaders({ [actionId]: true });
    getLoanCloserDocusignUrl({ actionId })
      .then((res: any) => {
        if (get(res, 'message')) {
          setLoanErrorPopup(true);
        } else {
          window.open(res, '_blank');
        }
      })
      .finally(() => {
        setLoaders({ [actionId]: false });
      });
  };

  const getActionsContext = () => {
    if (aid && event === 'signing_complete' && processingDocusignAction) {
      return <Loader />;
    }
    if (initsignin && aid) {
      return <Loader />;
    }
    switch (status) {
      case 'PENDING':
        return (
          <PendingActions
            status={status}
            getDocusignUrl={getDocusignUrl}
            loaders={loaders}
          />
        );
      case 'COMPLETED':
        return <CompletedActions status={status} />;
      default:
        break;
    }
  };

  return (
    <Box>
      {getActionsContext()}
      <AlertDialog
        dialogOpen={loanErrorPopup}
        onClose={() => setLoanErrorPopup(false)}
        dialogHeading={'Sign Documents'}
        dialogContent={'Loan already closed'}
      />
    </Box>
  );
};

export default ActionsList;
