import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  enquiryViewContainer: {
    paddingLeft: 60,
    '@media (max-width: 1024px)': {
      paddingLeft: 25,
    },
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    },
  },
  enquiryViewBox: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 20px)',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  enquiryTabs: {
    borderBottom: '0.5px solid #DEDEDE',
  },
  enquiryViewBoxLeft: {
    width: 'calc(100% - 300px)',
    flex: '0 auto',
    '&.rightClosed': {
      width: 'calc(100% - 40px)',
      '@media (max-width: 1024px)': {
        width: 'calc(100% - 40px)',
      },
    },
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 260px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 20,
    },
    '@media (max-width: 525px)': {
      paddingLeft: 15,
    },
    // paddingRight: 30,
  },
  enquiryViewBoxLeftFullWidth: {
    width: '100%',
    flex: '0 auto',
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 260px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 20,
    },
    '@media (max-width: 525px)': {
      paddingLeft: 15,
    },
    // paddingRight: 30,
  },
  enquiryViewHeadBox: {},
  OrnamentImg: {},
  // enquiryListhead: {
  //   display: 'flex',
  //   width: '100%',
  //   justifyContent: 'space-between',
  //   paddingTop: 70,
  // },
  enquiryListLeft: {
    position: 'relative',
    paddingLeft: 10,
  },
  enquiryListRight: {
    paddingRight: 30,
  },
  gridItemView: {
    paddingLeft: '20px !important',
  },
  rightIconStyle: {
    border: '1px solid #1EA59A',
    padding: '2px 6px 0',
    borderRadius: 6,
  },
  moreHoriIcon: {
    color: theme.palette.primary.main,
  },
  iconStyleOne: {
    height: 50,
    width: 50,
  },
  enquiryHeadText: {
    fontSize: 29,
    color: theme.palette.error.contrastText,
    marginBottom: 5,
    position: 'relative',
    top: -20,
  },
  enquirySubHeadText: {
    fontSize: 17,
    marginBottom: 10,
    color: theme.palette.primary.main,
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 20,
    left: 40,
  },
  tanPanelBox: {
    height: 'calc(100vh - 246px)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 265px)',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  safeTracking: {
    marginTop: '30px',
  },
  enquiryViewTabBox: {
    paddingRight: 30,
  },
  enquiryViewBoxRight: {
    flexGrow: 1,
    width: '300px',
    background: theme.palette.warning.main,
    borderTopRightRadius: 25,
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&.rightClosed': {
      width: 40,
      '@media (max-width: 1024px)': {
        width: 40,
      },
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      width: 260,
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  contentBoxRight: {
    position: 'relative',
    padding: '60px 40px 30px',
  },
  rightContentToggleButton: {
    zIndex: theme.zIndex.appBar + 3,
    position: 'absolute',
    left: 5,
    top: '2em',
  },
  nextPrevIconButton: {
    padding: 5,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
    // boxShadow: theme.shadows[1],
    '&:focus': {
      outline: 'none',
    },
  },
  tabPaymentBox: {
    height: 'calc(100vh - 285px)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 300px)',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  tabPanelContent: {
    padding: '10px 20px 20px',
    '@media (max-width: 1024px)': {
      padding: '10px 0px 20px',
    },
  },
  tabPanelText: {
    padding: '30px 20px',
  },
  overViewHeadTitle: {
    fontSize: 20,
    color: theme.palette.grey[500],
    marginBottom: 10,
    paddingTop: 20,
  },
  descTitletext: {
    fontSize: 15,
    color: theme.palette.grey[600],
    marginBottom: 25,
  },
  overViewSubHeadTitle: {
    fontSize: 16,
    color: theme.palette.grey[500],
    fontWeight: 700,
  },
  labelStartUpText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    opacity: 0.75,
    marginBottom: 8,
    paddingRight: 15,
  },
  labelStartHead: {
    fontSize: 22,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelStartUpHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelFundHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 10,
  },
  labelStartUp: {
    fontSize: 15,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelStartUpUrl: {
    fontSize: 15,
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelFundUpHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    paddingTop: 5,
  },
  labelFundUpHeadText: {
    fontSize: 15,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    paddingTop: 5,
  },
  labelFundUpText: {
    fontSize: 12,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    opacity: 0.75,
  },
  labelInfoText: {
    fontSize: 12,
    color: theme.palette.primary.main,
    opacity: 0.75,
    marginBottom: 25,
    paddingTop: 5,
  },
  iconStyle: {
    width: 35,
    height: 35,
  },
  menuItemText: {
    display: 'inline-block',
  },
  founderDetailsBox: {
    marginBottom: 25,
  },
}));
