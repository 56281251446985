import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import VcFirm from './VcFirm';
import VcFirmList from './VcFirmList';

export const VcFirmViewRoute: IRoute = {
  path: '/admin/vc-firms/:id', // investorId
  component: VcFirm,
  layout: DashboardLayout,
  exact: true,
};

export const VcFirmListViewRoute: IRoute = {
  path: '/admin/vc-firms',
  component: VcFirmList,
  layout: DashboardLayout,
  exact: true,
};
