import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import { Box, SuccessPage } from 'components';
import { successImage } from 'assets';
import history from 'common/utils/history';

import styles from '../styles';

const OtherInformationDetails = () => {
  const classes = styles();

  const { bridgeLoanProcess } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const getStatusMessage = () => {
    return get(bridgeLoanProcess, 'isLoanProcessed')
      ? 'Loan processed successfully.'
      : get(bridgeLoanProcess, 'isLoanApproved')
      ? 'Loan approved successfully.'
      : get(bridgeLoanProcess, 'documentsSigned')
      ? 'We have received signed agreements, your debt application will be approved shortly.'
      : 'Application successful.';
  };

  return (
    <Box>
      <Box className={classes.enquirySuccessBox}>
        <Box className={classes.eBoxContent}>
          <SuccessPage img={successImage} className={classes.dashSuccessImg}>
            <Typography
              gutterBottom
              variant="h4"
              className={classes.successHeaderTxt}
            >
              {getStatusMessage()}
            </Typography>
            <Typography
              variant="body1"
              className={classes.dashFondbtn}
              onClick={() => history.push('/dashboard')}
            >
              Go to Dashboard
            </Typography>
          </SuccessPage>
        </Box>
      </Box>
    </Box>
  );
};

export default OtherInformationDetails;
