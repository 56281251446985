import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Typography,
  TextField,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { isEmpty, uniq, get } from 'lodash';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline';

import { Loader } from 'components';
import { getStartupBenchmarkingKpi } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { frequencyList } from 'common/utils/optionList';

import GetStartedBox from '../GetStartedBox';
import BarChart from './BarChart';
import AreaChart from './AreaChart';
import styles from './styles';

const Chart = ({
  startUpId,
  role,
  startupDetails,
  setEmptyBenchmarkData,
  showBenchmarkGetStarted = false,
}: any) => {
  const [chartType, setChartType] = React.useState<string>('BAR');

  const classes = styles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [benchmarkingData, setBenchmarkingData] = useState<any>([]);
  const [kpiList, setKpiList] = useState<any>([]);
  const [kpi, setKpi] = useState<any>('');

  const renderKpiName = (name: string) => {
    return name === 'Monthly Revenue'
      ? `${get(
          frequencyList.find(
            (freq: any) =>
              Number(freq.value) ===
              Number(get(startupDetails, 'benchmarkKPIFrequency')),
          ),
          'text',
        )} Revenue`
      : name;
  };

  const onKpiChange = (e: any) => {
    setKpi(e.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');
    Promise.all([getStartupBenchmarkingKpi(startUpId, role)])
      .then(([benchmarkData]: any) => {
        setBenchmarkingData(benchmarkData);
        setEmptyBenchmarkData && setEmptyBenchmarkData(isEmpty(benchmarkData));
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(benchmarkingData)) {
      const kpiNameList = uniq(benchmarkingData.map((kpi: any) => kpi.kpiName));
      setKpiList(
        kpiNameList.map((kpi: any) => {
          return { kpiName: kpi, value: kpi };
        }),
      );
      setKpi(kpiNameList[0]);
    }
  }, [benchmarkingData]);

  return (
    <>
      {errorMessage && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.errorMessage}
        >
          {errorMessage}
        </Typography>
      )}
      {isLoading && (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      )}
      {!isLoading && !isEmpty(benchmarkingData) && (
        <Box>
          <Box className={classes.benchmarkTitleContainer}>
            <Typography variant="body1" className={classes.benchmarkTitle}>
              Yardstick
            </Typography>
            <Box className={classes.chartItems}>
              <Box className={classes.chartBtns}>
                <Box className={classes.chartBtnsBox}>
                  {chartType === 'BAR' && 'Bar'}
                  <IconButton
                    aria-label="Bar"
                    className={classes.chartBtnsBar}
                    style={{
                      backgroundColor:
                        chartType === 'BAR' ? '#E0987C' : '#DEDEDE',
                    }}
                    onClick={() => setChartType('BAR')}
                  >
                    <BarChartIcon />
                  </IconButton>
                </Box>
                <Box className={classes.chartBtnsBox}>
                  {chartType !== 'BAR' && 'Line'}
                  <IconButton
                    aria-label="Line"
                    className={classes.chartBtnsLine}
                    style={{
                      backgroundColor:
                        chartType !== 'BAR' ? '#E0987C' : '#DEDEDE',
                    }}
                    onClick={() => setChartType('AREA')}
                  >
                    <TimelineIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box className={classes.benchmarkFilter}>
                <Typography
                  variant="body1"
                  className={classes.benchmarkFilterTitle}
                >
                  KPI :
                </Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.filterInput}
                >
                  <TextField
                    select
                    value={kpi}
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    SelectProps={{
                      MenuProps: {
                        classes: { paper: classes.menuPaper },
                      },
                    }}
                    onChange={(e: any) => onKpiChange(e)}
                    variant={'outlined'}
                  >
                    {kpiList &&
                      kpiList.map((i: any, n: number) => (
                        <MenuItem value={i.kpiName} key={n}>
                          {renderKpiName(i.kpiName)}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box className={classes.benchOutputChart}>
            {chartType === 'BAR' ? (
              <BarChart
                startUpId={startUpId}
                role={role}
                startupDetails={startupDetails}
                benchmarkingData={benchmarkingData}
                kpi={kpi}
                kpiLable={renderKpiName(kpi)}
              />
            ) : (
              <AreaChart
                startUpId={startUpId}
                role={role}
                startupDetails={startupDetails}
                benchmarkingData={benchmarkingData}
                kpi={kpi}
                kpiLable={renderKpiName(kpi)}
              />
            )}
          </Box>
        </Box>
      )}
      {!isLoading && isEmpty(benchmarkingData) && showBenchmarkGetStarted && (
        <GetStartedBox dashboardView={true} />
      )}
    </>
  );
};

export default Chart;
