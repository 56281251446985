import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';

import history from 'common/utils/history';
import { handleGetAllInvestors } from 'redux-modules/investors/Actions';
import { RootState } from 'redux-modules/store/rootState';
import {
  Box,
  Loader,
  WrappedSelect,
  StyledTableCell,
  StyledTableRow,
} from 'components';
import { Ornament } from 'assets';
import { handleGetAllDeal } from 'redux-modules/deals/Actions';

import styles from './styles';

const InvestorList = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { isLoading, investorList } = useSelector(
    ({ Investors }: RootState) => Investors,
  );
  const { dealList } = useSelector(({ Deals }: RootState) => Deals);
  const spvList = dealList.map((deal: any) => {
    return {
      text: get(deal, 'deal'),
      value: get(deal, 'id'),
    };
  });
  spvList.push({ text: 'All', value: '' });

  const [spv, setSPV] = React.useState<string>('');

  React.useEffect(() => {
    if (isEmpty(dealList)) dispatch(handleGetAllDeal());
  }, []);

  React.useEffect(() => {
    dispatch(handleGetAllInvestors({}));
  }, [handleGetAllInvestors]);

  const getFilteredInvestorList = (spv?: string) => {
    dispatch(
      handleGetAllInvestors({
        spv: spv,
      }),
    );
  };

  return (
    <Box className={classes.enquiryListBox}>
      <Box className={classes.enquiryListhead}>
        <Box className={classes.enquiryListLeft}>
          <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
          <Typography
            variant="h3"
            gutterBottom
            className={classes.enquiryHeadText}
          >
            All Investors
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.enquirySubHeadText}
          >
            {investorList.length > 1 && investorList.length} Investors
          </Typography>
        </Box>
        <Box className={classes.enquiryListRight}>
          <FormControl required className={classes.formControl}>
            <Typography
              variant="caption"
              gutterBottom
              className={classes.batchLabel}
            >
              Deal
            </Typography>
            <WrappedSelect
              variant={'outlined'}
              value={spv}
              onChange={(event: any) => {
                setSPV(event.target.value);
                getFilteredInvestorList(event.target.value);
              }}
              placeholder="Deal"
              className={classes.selectValInput}
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              dropdowns={spvList}
            />
          </FormControl>
        </Box>
      </Box>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <Box className={classes.loaderSection}>
                <Loader className={classes.loaderBox} />
              </Box>
            ) : (
              investorList.map((row: any) => (
                <StyledTableRow hover key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  {/* <StyledTableCell>
                    {row.isYcVerified ? 'true' : 'false'}
                  </StyledTableCell> */}
                  {/* <StyledTableCell>
                    {row.fundAmount ? row.fundAmount : '12000'}
                  </StyledTableCell> */}
                  <StyledTableCell>
                    {row.reviewed ? 'Active' : 'Unactivated'}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.tdBtn}
                      onClick={() =>
                        history.push(`/admin/investor-view/${row.id}`)
                      }
                    >
                      View
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InvestorList;
