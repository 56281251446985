import * as yup from 'yup';
import { isEmpty } from 'lodash';

import {
  emailRegex,
  emailValidConstant,
  phoneRegex,
  requiredConstant,
  urlRegex,
  urlValidConstant,
} from 'common/utils/constants';

export const IndividualFundingSchema = yup.object().shape({
  signupDetails: yup.object({
    name: yup.string().nullable().required(requiredConstant),
    linkedinUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    crunchbaseUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    anglelistUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    twitterUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    otherUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    email: yup
      .string()
      .nullable()
      .email(emailValidConstant)
      .required(requiredConstant)
      .matches(emailRegex, emailValidConstant),
  }),
  signatoryLegalName: yup.string().nullable().required(requiredConstant),
  countryCode: yup.string().nullable(),
  dob: yup.string().nullable(),
  phone: yup
    .string()
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v))
    .matches(phoneRegex, 'Phone number is not valid'),
  email: yup
    .string()
    .nullable()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  ssn: yup.string().nullable(),
  address1: yup.string().nullable(),
  // address2: yup.string().nullable().required(requiredConstant),
  // state: yup.string().nullable().required(requiredConstant),
  // country: yup.string().nullable().required(requiredConstant),
  zip: yup.string().nullable(),
});

export const EntityFundingSchema = yup.object().shape({
  signupDetails: yup.object({
    name: yup.string().nullable().required(requiredConstant),
    linkedinUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    crunchbaseUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    anglelistUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    twitterUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    otherUrl: yup
      .string()
      .nullable()
      .when({
        is: (value: string) => !isEmpty(value),
        then: yup.string().matches(urlRegex, urlValidConstant),
      }),
    email: yup
      .string()
      .nullable()
      .email(emailValidConstant)
      .required(requiredConstant)
      .matches(emailRegex, emailValidConstant),
  }),
  fundingEntityLegalName: yup.string().nullable(),
  signatoryLegalName: yup.string().nullable().required(requiredConstant),
  countryCode: yup.string().nullable(),
  phone: yup
    .string()
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v))
    .matches(phoneRegex, 'Phone number is not valid'),
  email: yup
    .string()
    .nullable()
    .email(emailValidConstant)
    .required(requiredConstant)
    .matches(emailRegex, emailValidConstant),
  ssn: yup.string().nullable(),
  address1: yup.string().nullable(),
  // address2: yup.string().nullable().required(requiredConstant),
  // state: yup.string().nullable().required(requiredConstant),
  // country: yup.string().nullable().required(requiredConstant),
  zip: yup.string().nullable(),
});

export const SubscriptionSchema = yup.object().shape({
  investmentAmount: yup
    .number()
    .positive()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  investmentDate: yup.string().nullable().required(requiredConstant),
  subscriberName: yup.string().nullable().required(requiredConstant),
  subscriberEinOrSSN: yup.string().nullable().required(requiredConstant),
});

export const WireInstructionSchema = yup.object().shape({
  transactionId: yup
    .string()
    .nullable()
    .required('Please Upload Wire Reciept or Enter the transaction id.'),
});
export const WireInstructionSchemaNotRequired = yup.object().shape({
  transactionId: yup.string().nullable(),
});
