import React from 'react';
import {
  makeStyles,
  Theme,
  TooltipProps,
  Tooltip,
  withStyles,
} from '@material-ui/core';

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
  },
}));

export const BootstrapTooltip = (props: TooltipProps) => {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
};

const useStylesTooltip = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontFamily: theme.typography.fontFamily,
  },
}));

export const StyledTooltip = (props: TooltipProps) => {
  const classes = useStylesTooltip();
  return <Tooltip arrow classes={classes} {...props} />;
};

const useLightStylesTooltip = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: 'transparent',
    fontFamily: theme.typography.fontFamily,
    padding: 0,
    position: 'relative',
    top: 27,
  },
}));

export const LightStyledTooltip = (props: TooltipProps) => {
  const classes = useLightStylesTooltip();
  return <Tooltip arrow classes={classes} {...props} />;
};

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  popper: {
    pointerEvents: 'auto',
  },
}))(Tooltip);
