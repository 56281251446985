import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import Dashboard from './Dashboard';

export const DashboardRoute: IRoute = {
  path: '/dashboard',
  component: Dashboard,
  layout: DashboardLayout,
  isAdmin: true,
  exact: true,
};
