import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import { Button, Box, WrappedTextInput } from 'components';
import { handleForgotPassword } from 'redux-modules/auth/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { ForgotPasswordSchema } from 'common/utils/validations';

import styles from './styles';

type ForgotPasswordType = {
  username: string;
};

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const onSubmit = ({ username }: ForgotPasswordType) => {
    dispatch(handleForgotPassword({ username }));
  };

  const { errorMessage, isLoading } = useSelector(
    ({ Auth }: RootState) => Auth.forgotPassword,
  );
  const classes = styles();

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ForgotPasswordSchema),
  });
  return (
    <>
      <Box className={classes.forgotContainer}>
        {/* {isLoading && <Loader />} */}
        {errorMessage && (
          <Typography
            variant="body1"
            gutterBottom
            className={classes.errorText}
          >
            {errorMessage}
          </Typography>
        )}
        <Typography variant="h2" gutterBottom className={classes.welcome8vdx}>
          Forgot Password
        </Typography>
        <Typography className={classes.welcomeSub8vdx}>
          Enter your email and we’ll send a link on your email to reset your
          password.
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="forgotPasswordForm"
          className={classes.validateForm}
        >
          <Controller
            name="username"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label="Email"
                  placeholder="Email"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
            rules={{ required: 'Email is required' }}
          />
          <Box>
            <Button
              name="Confirm"
              type="submit"
              className={classes.signupBtn}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ForgotPassword;
