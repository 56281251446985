import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  benchMarkContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
  },
  benchmarkHeadEmpty: {
    height: 0,
    '@media (max-width: 1024px)': {
      height: 80,
    },
    '@media (max-width: 767px)': {
      height: 0,
    },
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 43,
    left: 40,
    '@media (max-width: 767px)': {
      top: 23,
      left: 20,
      objectFit: 'cover',
      height: 70,
      width: 80,
    },
  },
  headText: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    fontWeight: 400,
    letterSpacing: 0.3,
    position: 'relative',
    top: -24,
  },
  backToDashboardText: {
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 600,
    letterSpacing: 0.25,
    lineHeight: '21px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    borderRadius: 6,
    width: 236,
    height: 48,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  //////////////////////
  benchMarkContent: {
    padding: '40px 45px',
    height: 'calc(100vh - 100px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 130px)',
      padding: '10px 20px 30px',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 80px)',
      padding: '30px 20px',
    },
    '@media (max-width: 525px)': {
      height: 'auto',
      padding: '25px 15px 15px',
    },
  },
  benchmarkTopContainer: {
    background: theme.palette.common.white,
    borderRadius: 11,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '20px 40px',
    '@media (max-width: 525px)': {
      padding: '15px',
    },
  },
  pageSmallTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '30px',
    letterSpacing: '0.3px',
    color: theme.palette.grey[50],
  },
  containerBox: {
    padding: '30px 0px',
    '@media (max-width: 525px)': {
      padding: '15px 0px',
    },
  },
  welcomeHeader: {
    paddingBottom: 20,
    fontFamily: theme.typography.fontFamily,
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '30px',
    letterSpacing: '0.3px',
    color: theme.palette.common.black,
    '@media (max-width: 525px)': {
      fontSize: 26,
      paddingBottom: 10,
    },
  },
  welcomeTextDot: {
    height: 12,
    width: 12,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    marginRight: 20,
    display: 'inline-block',
  },
  welcomeText: {
    padding: '10px',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '0.24px',
    color: theme.palette.common.black,
    '@media (max-width: 525px)': {
      fontSize: 14,
      padding: '8px',
    },
  },
  getStartedBtn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '15px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 240,
    textTransform: 'capitalize',
    marginTop: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  benchmarkBottomContainer: {
    marginTop: 20,
  },
  benchmarkBottomInnerContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px 11px 0 0',
    padding: '20px 40px 5px',
    '@media (max-width: 525px)': {
      padding: '15px 25px 5px',
    },
  },
  formHeadingText: {
    marginBottom: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 22,
    lineHeight: '30px',
    letterSpacing: '0.3px',
    color: theme.palette.grey[500],
  },
  subHeading: {
    // marginBottom: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '25px',
    color: theme.palette.grey[50],
    letterSpacing: '0.3px',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
}));
