import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { StartupOnboardStepProps } from 'common/types';
import { Box, Button } from 'components';
import { RootState } from 'redux-modules/store/rootState';
import { trackBridgeLoanProcess } from 'redux-modules/enquiries/Actions';
import history from 'common/utils/history';
import { errorMessageHandler } from 'common/utils/helpers';
import FileUpload from 'components/FileUpload';

import {
  getDefaultDocuments,
  adminDocusignGetSignedUrl,
  adminAddDocusignDocument,
} from '../../../services';
import styles from '../styles';

const DocuSignView = ({ startUpId, enquiryId }: StartupOnboardStepProps) => {
  const dispatch = useDispatch();
  const classes = styles();

  const { bridgeLoanProcess } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loadingSignedUrl, setLoadingSignedUrl] = React.useState<boolean>(
    false,
  );
  const [documentSigned, setDocumentSigned] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [documentList, setDocumentList] = React.useState([]);

  useEffect(() => {
    if (bridgeLoanProcess) {
      const isDocumentsSigned = get(bridgeLoanProcess, 'documentsSigned');
      setDocumentSigned(isDocumentsSigned);
    } else {
      if (!isEmpty(startUpId)) dispatch(trackBridgeLoanProcess(startUpId));
    }
  }, [bridgeLoanProcess]);

  const getDocuSignDocuments = () => {
    const payload = {
      startupId: startUpId,
      documentsType: 'DOCU_SIGN_DOCUMENTS',
      loanType: 'BRIDGE_LOAN',
      onboard: true,
    };
    setIsLoading(true);
    getDefaultDocuments(payload)
      .then((res: any) => {
        setDocumentList(res);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };
  useEffect(() => {
    if (!isEmpty(startUpId)) getDocuSignDocuments();
  }, []);

  const onSubmit = async (fileuploadReq: any): Promise<void> => {
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsLoading(true);
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });

        await adminAddDocusignDocument({
          filename: get(fileuploadReq, 'filename'),
          startupId: startUpId,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
        });
        getDocuSignDocuments();
        setDocumentSigned(true);
        setIsLoading(false);
        dispatch(trackBridgeLoanProcess(startUpId));
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload wire instruction.');
    }
  };

  return (
    <Box>
      <>
        <Typography variant="h2" className={classes.detailsText}>
          Onboard a startup
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.errorText}>
          {errorMessage}
        </Typography>
        <Box className={classes.docuSignBox}>
          <Typography variant="h4" className={classes.docuSignText}>
            Upload Docusign signed documents
          </Typography>
          {/* <Typography variant="body1" className={classes.listPoints}>
            1. Promissory Note
          </Typography>
          <Typography variant="body1" className={classes.listPoints}>
            2. Bonus SAFE Agreement
          </Typography> */}
          <FileUpload
            getSignedUrl={adminDocusignGetSignedUrl}
            uploadedFiles={!isEmpty(documentList) ? documentList : []}
            setLoadingSignedUrl={setLoadingSignedUrl}
            uploadOnSelect={onSubmit}
            requestObject={{
              startupId: startUpId,
            }}
            content={
              !documentSigned && (
                <Box className={classes.docuSignBtn}>
                  <Button
                    className={classes.uploadBtn}
                    name={'Upload signed agreements'}
                    disabled={loadingSignedUrl || isLoading}
                    isLoading={loadingSignedUrl || isLoading}
                  />
                </Box>
              )
            }
          />
          <Box className={classes.docuSignBtn}>
            {documentSigned && (
              <>
                <Button
                  className={classes.uploadBtn}
                  name="e-Signatures completed"
                  disabled={true}
                />
                <Button
                  className={classes.uploadBtn}
                  name="Go to Startup profile"
                  onClick={() =>
                    history.push(`/admin/enquiry-view/${enquiryId}`)
                  }
                />
              </>
            )}
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default DocuSignView;
