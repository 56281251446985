import { makeStyles } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
export default makeStyles((theme) => ({
  hidden: {
    width: 0,
    height: 0,
    overflow: 'hidden',
  },
  tabPanelContent: {
    padding: 20,
    '@media (max-width: 1024px)': {
      padding: '20px 0px',
    },
  },
  pointText: {
    fontSize: 15,
    letterSpacing: '0.35px',
    marginBottom: 20,
  },
  infoAlert: {
    background: '#e4f4f9',
    marginBottom: 10,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 0,
  },
  approveAlertBox: {
    width: '100%',
    paddingTop: 15,
  },
  alertBox: {
    width: '100%',
  },
  alertDate: {
    color: theme.palette.grey[50],
    fontSize: 11,
  },
  alertStatusTxt: {
    color: theme.palette.grey[50],
    fontSize: 13,
  },
  approveSubHead: {
    fontSize: 15,
    color: theme.palette.grey[400],
  },
  continueBtnBox: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    fontSize: 15,
    cursor: 'pointer',
    height: 50,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    marginTop: 20,
  },
  strongText: {
    // display: 'block',
    marginBottom: 7,
    fontWeight: 700,
  },
  bridgeLoadText: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.grey[500],
    letterSpacing: 0.3,
    marginBottom: 20,
  },
  approvedDocs: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: 0.3,
    position: 'absolute',
    top: '-12px',
    right: 0,
  },
  formAccord: {
    marginBottom: 20,
  },
  accordBox: {
    marginBottom: 20,
    border: '1px solid #CDE8DD',
    borderRadius: 4,
    padding: '10px 20px 20px',
    '&::before': {
      display: 'none',
      position: 'static',
    },
  },
  summaryAccord: {
    boxShadow: theme.shadows[0],
    position: 'relative',
    flexDirection: 'row-reverse',
    msFlexDirection: 'row-reverse',
    padding: 0,
    '& .MuiAccordionSummary-content': {
      margin: '12px 0',
      paddingLeft: 25,
      justifyContent: 'space-between',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      position: 'absolute',
      left: -10,
      top: 27,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  chRightIcon: {
    padding: 0,
    fontSize: 28,
  },
  stepAccord: {
    fontSize: 13,
    color: theme.palette.grey[600],
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  accordHeadText: {
    fontSize: 16,
    color: theme.palette.grey[500],
    fontWeight: 600,
    alignSelf: 'center',
    marginTop: 10,
    letterSpacing: 0.3,
    // width: 'calc(100% - 125px)',
    // width: '50%',
  },
  summaryAccordRight: {
    // width: '50%',
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  statusBtn: {
    background: theme.palette.grey[800],
    color: theme.palette.primary.contrastText,
    height: 35,
    fontSize: 12,
    cursor: 'auto',
    borderRadius: 5,
    marginRight: 15,
    alignSelf: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.grey[800],
      color: theme.palette.primary.contrastText,
    },
  },
  processLoanstatusBtn: {
    background: theme.palette.grey[800],
    color: theme.palette.primary.contrastText,
    height: 35,
    fontSize: 12,
    cursor: 'auto',
    borderRadius: 5,
    marginRight: 15,
    marginTop: 10,
    alignSelf: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.grey[800],
      color: theme.palette.primary.contrastText,
    },
  },
  accordSendBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    // padding: '12px 16px',
    borderRadius: 5,
    fontWeight: 600,
    width: 150,
    fontSize: 15,
    cursor: 'pointer',
    marginRight: 10,
    height: 36,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  accordViewBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    // padding: '12px 16px',
    borderRadius: 5,
    fontWeight: 600,
    width: 150,
    fontSize: 15,
    marginTop: 8,
    cursor: 'pointer',
    marginRight: 10,
    height: 36,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  editYCBtn: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid #1EA59A',
    borderRadius: 5,
    fontFamily: 'Mulish',
    fontWeight: 600,
    fontSize: 15,
    cursor: 'pointer',
    height: 36,
    minWidth: 76,
    padding: 5,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      // width: 100,
      // height: 40,
    },
  },
  accordCancelBtn: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 5,
    fontWeight: 600,
    width: 150,
    fontSize: 16,
    height: 36,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 30,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  formSendBtn: {
    fontSize: 14,
    letterSpacing: 0.25,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    // width: 84,
    alignSelf: 'center',
    textTransform: 'capitalize',
  },
  moreIcon: {
    alignSelf: 'center',
    color: theme.palette.primary.main,
    width: 30,
    height: 30,
    marginLeft: 12,
    position: 'relative',
    top: 1,
  },
  accordDetails: {
    padding: '0px 0px 30px 25px',
  },
  accordDetailsContent: {
    display: 'block',
    width: 'calc(100% - 40px)',
  },
  buttonBox: {
    display: 'flex',
  },
  documentRequestBox: {
    display: 'flex',
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: '10px 18px',
    marginTop: 15,
    minHeight: 45,
  },
  documentShareBox: {
    display: 'flex',
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: '8px 18px',
    marginTop: 15,
  },
  iconImg: {
    height: 20,
    alignSelf: 'center',
    marginRight: 20,
  },
  uploadIcon: {
    height: 15,
    alignSelf: 'center',
    paddingLeft: 40,
  },
  bGImg: {
    height: 43,
    alignSelf: 'center',
    paddingLeft: 20,
  },
  documenttext: {
    alignSelf: 'center',
    fontSize: 16,
    lineHeight: '18px',
    paddingLeft: 10,
    // paddingTop: 10,
    color: theme.palette.grey[900],
  },
  documentUpload: {
    margin: 'auto 0 auto auto',
    display: 'flex',
  },
  uploadStatus: {
    fontSize: 13,
    color: theme.palette.grey[900],
  },
  uploadingStatus: {
    fontSize: 13,
    color: 'blue',
  },
  uploadedStatus: {
    fontSize: 13,
    color: theme.palette.primary.main,
  },
  errorStatus: {
    fontSize: 13,
    color: 'red',
  },
  shareDocumentText: {
    alignSelf: 'center',
    fontSize: 16,
    paddingLeft: 10,
    color: theme.palette.grey[900],
  },
  uploadDocumenttext: {
    alignSelf: 'center',
    fontSize: 16,
    padding: '10px 0 10px 10px',
    color: theme.palette.grey.A100,
  },
  reviewDocuBox: {
    textAlign: 'center',
    // marginBottom: 15,
  },
  reviewDocuText: {
    fontSize: 11,
    color: theme.palette.grey[600],
    marginBottom: 10,
  },
  bGDocuImg: {
    height: 100,
    width: 106,
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  verifiedBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconApprovImg: {
    height: 11,
    alignSelf: 'center',
  },
  circleOutline: {
    fontSize: 11,
    alignSelf: 'center',
    color: theme.palette.error.main,
  },
  approveFont: {
    fontSize: '11px',
    textTransform: 'capitalize',
  },
  rejectedFont: {
    fontSize: '11px',
    textTransform: 'capitalize',
    color: theme.palette.error.main,
  },
  approvedbtn: {
    fontSize: 10,
    color: theme.palette.primary.main,
    background: 'transparent',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
  },
  // circleOutline: {
  //   fontSize: 10,
  //   color: theme.palette.primary.main,
  //   marginRight: 5,
  // },
  approveBtn: {
    fontSize: 10,
    height: 26,
    background: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid #1EA59A',
    borderRadius: 4.5,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },

  ycerrorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    display: 'block',
  },
  companyLabelText: {
    fontSize: 15,
    color: theme.palette.grey[50],
  },
  docuComponent: {
    width: '100%',
  },
  docuName: {
    fontSize: 15,
    color: theme.palette.grey[50],
    padding: '15px 0 5px',
  },
  inputHead: {
    padding: '12px 40px 0px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 0px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px 12px 0 !important',
    },
  },
  textValInput: {
    marginBottom: 10,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  approveTextValInput: {
    marginBottom: 15,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  wrappedSelectVal: {
    marginTop: 8,
    marginBottom: 0,
    background: theme.palette.primary.light,
    padding: '2px 5px 2px 15px',
    borderRadius: 4,
    '& .MuiSelect-select': {
      paddingRight: 34,
      color: theme.palette.primary.contrastText,
      fontWeight: 500,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.contrastText,
      borderLeft: '1px solid #fff',
      paddingLeft: 3,
    },
  },
  wrappedDisableSelectVal: {
    marginTop: 8,
    marginBottom: 0,
    background: '#8080809c',
    padding: '2px 5px 2px 15px',
    borderRadius: 4,
    '& .MuiSelect-select': {
      paddingRight: 34,
      color: theme.palette.primary.contrastText,
      fontWeight: 500,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.contrastText,
      borderLeft: '1px solid #fff',
      paddingLeft: 3,
    },
  },
  docuInput: {
    marginBottom: 35,
    marginTop: 0,
    width: 280,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 17,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  inputClasses: {
    display: 'none',
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  btnSubmit: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 100,
    fontSize: 15,
    cursor: 'pointer',
    height: 42,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    fontSize: 16,
    height: 48,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  btnCancel: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 100,
    fontSize: 16,
    height: 42,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  backContinueBox: {
    marginTop: 40,
    paddingTop: 10,
    paddingBottom: 10,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  closeIcon: {
    color: red['800'],
    cursor: 'pointer',
  },

  // Approve Load Model styling --------- start

  approveModel: {
    height: '100%',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiDialog-container': {
      alignSelf: 'center',
      height: '90%',
      maxWidth: 800,
    },
    '& .MuiDialog-paper': {
      margin: 0,
      padding: '40px 20px 40px 45px',
      position: 'relative',
      // alignSelf: 'baseline',
      width: '100%',
      maxWidth: '100%',
      '@media (max-width: 960px)': {
        padding: '20px 10px 30px 30px',
        maxWidth: '90%',
      },
    },
    '& .MuiTypography-h3': {
      textAlign: 'center',
      padding: '10px 0 15px',
    },
    '& .MuiTypography-h6': {
      padding: '20px 0 20px',
      textAlign: 'center',
    },
    '& .MuiTypography-caption': {
      top: 0,
      right: 15,
    },
    '& .MuiDialogContent-root': {
      padding: '20px 40px 0 0',
      overflowX: 'hidden',
      '@media (max-width: 960px)': {
        paddingRight: 20,
      },
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#1EA59A',
        width: '0.6em',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: '#F6FAFA',
        minHeight: 24,
        border: '1px solid #1EA59A',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#2B2B2B',
      },
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: '0 -12px 20px',
      '@media (max-width: 960px)': {
        margin: '0 -12px 5px',
      },
    },
  },
  rejectOnHoldModel: {
    maxHeight: '100vh',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiDialog-container': {
      alignSelf: 'center',
      maxHeight: '90vh',
      maxWidth: 800,
      alignItems: 'unset',
      height: 'auto',
      margin: '5vh auto auto auto',
    },
    '& .MuiDialog-paper': {
      maxHeight: '100%',
      margin: 0,
      padding: '40px 20px 40px 45px',
      position: 'relative',
      // alignSelf: 'baseline',
      width: 800,
      maxWidth: '100%',
      // height: '100%',
      '@media (max-width: 960px)': {
        padding: '20px 10px 30px 30px',
        maxWidth: '90%',
        width: 600,
      },
      '@media (max-width: 650px)': {
        width: 380,
      },
      '@media (max-width: 420px)': {
        width: 250,
      },
    },
    '& .MuiTypography-h3': {
      textAlign: 'left',
      padding: '10px 40px 15px 0',
      fontSize: 20,
      color: theme.palette.grey[50],
    },
    '& .MuiTypography-h6': {
      padding: '0px 70px 20px 0',
      textAlign: 'left',
      color: theme.palette.grey[50],
      fontSize: 16,
    },
    '& .MuiTypography-caption': {
      top: 0,
      right: 15,
    },
    '& .MuiDialogContent-root': {
      padding: '5px 40px 0 0',
      overflow: 'hidden',
      '@media (max-width: 960px)': {
        paddingRight: 20,
      },
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#1EA59A',
        width: '0.6em',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: '#F6FAFA',
        minHeight: 24,
        border: '1px solid #1EA59A',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#2B2B2B',
      },
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: '0 -12px 20px',
      '@media (max-width: 960px)': {
        margin: '0 -12px 5px',
      },
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'flex-start',
      padding: '8px 0',
      marginTop: 20,
    },
  },
  dailogContentBox: {
    height: 'calc(100% - 81px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  errorLoanText: {
    color: theme.palette.error.main,
    fontSize: 16,
    marginBottom: 5,
    position: 'relative',
    top: -10,
  },
  onHoldList: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 20px',
    borderRadius: 5,
    border: '1px solid #dedede',
    marginBottom: 15,
  },
  imgIcon: {},
  onHoldTxt: {
    paddingLeft: 10,
    fontSize: 14,
    color: theme.palette.grey[50],
  },
  funTypeTabs: {
    borderBottom: '0.5px solid #DEDEDE',
    marginBottom: 20,
    '& .PrivateTabIndicator-root-330': {
      height: 3,
    },
  },
  fundTabs: {
    height: 'calc(100vh - 370px)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  approveChk: {
    marginBottom: 20,
  },
  // Approve Load Model styling --------- start
  submitDocBtn: {
    marginTop: '10px',
    float: 'right',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  loanApprovedBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 200,
    fontSize: 16,
    height: 48,
    cursor: 'pointer',
    // border: '1px solid #1EA59A',
    // marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 150,
      height: 40,
    },
  },
  approveBackBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 140,
    fontSize: 16,
    height: 48,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 120,
      height: 40,
    },
  },
  colorFill: {
    alignSelf: 'center',
    height: 20,
    marginRight: 20,
  },
  docutext: {
    alignSelf: 'center',
  },
  boardStext: {
    fontSize: 13,
    color: theme.palette.grey[800],
    lineHeight: '18px',
    maxWidth: 380,
    paddingTop: 10,
    paddingLeft: 10,
  },
  templateText: {
    color: theme.palette.primary.main,
    fontSize: 14,
    lineHeight: '20px',
    alignSelf: 'center',
    paddingRight: '35px',
  },
  image: {
    display: 'flex',
  },
  thumbnail: {
    alignSelf: 'center',
  },

  ycStatusBox: {
    width: '100%',
    paddingTop: 20,
  },
  partnerName: {
    fontSize: 14,
    color: theme.palette.grey[50],
    marginBottom: 10,
    width: '100%',
  },
  partnerNameTxt: {
    fontSize: 20,
    color: theme.palette.grey[50],
    paddingLeft: 7,
  },
  ycFieldBox: {
    marginTop: 20,
    width: '100%',
  },
  ycDocumentBox: {
    paddingTop: 20,
    paddingBottom: 30,
  },
  documentTxt: {
    fontSize: 16,
    color: theme.palette.grey[50],
    marginBottom: 7,
  },
  docuList: {
    margin: 0,
    display: 'grid',
    gridGap: 20,
    // gridTemplateColumns: 'repeat(auto-fill, minmax(115px, 1fr) )',
    gridTemplateColumns: 'repeat(auto-fill, 120px)',
    // padding: '5px 20px 10px 0',
  },
  docuImgBox: {
    // marginBottom: 25,
  },
  docuImg: {
    height: 120,
    width: 120,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
  },
  docuImgTxt: {
    textAlign: 'center',
    fontSize: 13,
    color: theme.palette.grey[50],
    marginBottom: 7,
    paddingTop: 5,
    width: 121,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      overflow: 'visible',
      whiteSpace: 'normal',
    },
  },
  approvedBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  rightImg: {
    height: 11,
    alignSelf: 'center',
    marginRight: 5,
  },
  approvedTxt: {
    fontSize: 11,
    color: theme.palette.grey[50],
  },
  uploadYcContainer: {
    border: '1px solid grey',
    height: 60,
    width: '300px',
    // marginTop: 10,
    borderRadius: 5,
    margin: '10px auto auto',
  },
  uploadYcBox: {
    display: 'flex',
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadYcIcon: {
    // paddingLeft: 50,
    // paddingTop: 20,
    height: 16,
    width: 16,
    cursor: 'pointer',
  },
  uploadYcText: {
    fontSize: 16,
    fontWeight: 600,
    // width: '300px',
    paddingLeft: 11,
    color: '#247CDE',
    cursor: 'pointer',
    //color: theme.palette.grey[50],
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
  },
  approveBtnPreview: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid #1EA59A',
    borderRadius: 5,
    fontFamily: 'Mulish',
    fontWeight: 500,
    fontSize: 10,
    cursor: 'pointer',
    height: 25,
    minWidth: 56,
    padding: 5,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      // width: 100,
      // height: 40,
    },
  },
  rejectBtnPreview: {
    backgroundColor: 'transparent',
    color: theme.palette.error.main,
    border: '1px solid #FF0000',
    borderRadius: 5,
    fontFamily: 'Mulish',
    fontWeight: 500,
    fontSize: 10,
    minWidth: 56,
    padding: 5,
    cursor: 'pointer',
    height: 25,
    textTransform: 'capitalize',
    marginLeft: 8,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.error.main,
    },
    // '@media (max-width: 525px)': {
    //   width: 100,
    //   height: 40,
    // },
  },
  loanCloserDialogTitle: {
    fontSize: 20,
    paddingBottom: 10,
  },
  loanCloserBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    padding: '12px 0 12px 16px',
    borderRadius: 5,
    fontWeight: 600,
    width: 140,
    fontSize: 15,
    marginTop: 8,
    cursor: 'pointer',
    height: 36,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  loanCloserBtnIcon: {
    borderLeft: '1px solid #fff',
    padding: '0 8px',
  },
  loanCloserContent: {
    backgroundColor: '#1EA59A1A',
    padding: '0 1em 1em',
    width: '100%',
    fontWeight: 600,
    p: {
      fontSize: '14px',
    },
  },
}));
