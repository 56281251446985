import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import Settings from './Settings';

export const SettingsRoute: IRoute = {
  path: '/settings',
  component: Settings,
  layout: DashboardLayout,
  isAdmin: false,
  exact: true,
};
