import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { RootState } from 'redux-modules/store/rootState';
import history from 'common/utils/history';
import { Sidebar, Box } from 'components';
import { Logo } from 'assets';

import { BenchmarkKpiData, BenchmarkOutput } from './Steps';
import styles from './styles';

const BenchmarkKPI = () => {
  const classes = styles();

  const { name, email, role, startUpId }: any = useSelector(
    ({ Auth }: RootState) => Auth.user,
  );
  const { startupInfo } = useSelector(({ Enquiries }: RootState) => Enquiries);

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [startupDetails, setStartupDetails] = React.useState<any>({});
  const [viewOutput, setViewOutput] = React.useState<boolean>(false);

  useEffect(() => {
    setStartupDetails(startupInfo);
  }, [startupInfo]);

  return (
    <div className={classes.root}>
      <Box className={classes.dashContainer}>
        <Box className={classes.dashleft}>
          <Sidebar className={classes.sideStepperFixed}>
            <Box className={classes.dashLogo}>
              <img src={Logo} alt={Logo} className={classes.logoImg} />
              <Typography variant="h3" className={classes.stepperText}>
                Yardstick
              </Typography>
              <Box className={classes.dashAvatarLogo}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.userLabelHead}
                >
                  {name}
                </Typography>
                <Typography
                  variant="caption"
                  gutterBottom
                  className={classes.userLabelText}
                >
                  {email}
                </Typography>
              </Box>
            </Box>
          </Sidebar>
        </Box>
        <Box className={classes.dashRight}>
          <Box className={classes.dashHeader}>
            <Typography
              variant="caption"
              className={classes.closeIcon}
              onClick={() => history.push('/dashboard')}
            >
              <CloseIcon />
            </Typography>
          </Box>

          {!viewOutput ? (
            <BenchmarkKpiData
              role={role}
              startUpId={startUpId}
              startupDetails={startupDetails}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              setViewOutput={setViewOutput}
            />
          ) : (
            <BenchmarkOutput
              role={role}
              startUpId={startUpId}
              startupDetails={startupDetails}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              setViewOutput={setViewOutput}
            />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default BenchmarkKPI;
