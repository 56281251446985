import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 17,
  },
  errorSelectText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 17,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  //////// Basic detail
  benchBasicForm: {
    paddingRight: 70,
    '@media (max-width: 1120px)': {
      paddingRight: 50,
    },
    '@media (max-width: 1024px)': {
      padding: '30px 30px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 20px 20px',
    },
  },
  startupFormFirstContainer: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    // marginRight: 30,
    '@media (max-width: 1024px)': {
      marginRight: 0,
      boxShadow: 'none',
      padding: '0',
    },
  },
  headingText: {
    marginBottom: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '45px',
    letterSpacing: '0.3px',
    color: theme.palette.common.black,
  },
  gridContainer: {
    '& .MuiGrid-item': {
      marginBottom: -12,
      '&:nth-of-type(odd)': {
        padding: '12px 40px 12px 12px !important',
        '@media (max-width: 1120px)': {
          padding: '12px 20px 12px 12px !important',
        },
        '@media (max-width: 960px)': {
          padding: '12px !important',
        },
      },
      '&:nth-of-type(even)': {
        padding: '12px 12px 12px 40px !important',
        '@media (max-width: 1120px)': {
          padding: '12px 12px 12px 20px !important',
        },
        '@media (max-width: 960px)': {
          padding: '12px !important',
        },
      },
    },
  },
  inputHead: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
  },
  inputHeadFull: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px !important',
    position: 'relative',
  },
  inputHeadRight: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
  },
  companyLabelText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
    '@media (max-width: 600px)': {
      minHeight: 'auto',
      marginBottom: 7,
    },
  },
  textValInput: {
    marginBottom: 25,
    marginTop: 7,
    width: '100%',
    '@media (max-width: 1120px)': {
      marginBottom: 15,
      marginTop: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000de',
    },
  },
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    marginTop: 7,
    '@media (max-width: 1120px)': {
      marginBottom: 15,
      marginTop: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
    top: -7,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  backContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 220,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 165,
    },
  },
  resetBtn: {
    width: 200,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
    marginLeft: 20,
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 125,
      marginLeft: 15,
    },
  },
  menuPaper: {
    maxHeight: 150,
  },
  ///////////// WELCOME/ GET Started
  benchMarkWelcome: {
    height: 'calc(100vh - 180px)',
    padding: '70px 45px 80px',
    background: '#eff3f6',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media(max-width: 1024px)': {
      padding: '80px 20px 30px',
      height: 'calc(100vh - 130px)',
      overflow: 'hidden',
    },
    '@media(max-width: 767px)': {
      padding: '20px 20px 30px',
      height: 'auto',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  //////////////////// KPI DATA
  benchMarkContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    height: 'calc(100vh - 80px)',
    paddingRight: 32,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1120px)': {
      height: 'calc(100vh - 80px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
    },
    '@media (max-width: 1024px)': {
      height: 'auto',
      padding: 30,
    },
    '@media (max-width: 767px)': {
      padding: '25px 20px 35px',
    },
  },
  benchMarkKPIContent: {
    '@media (max-width: 1024px)': {
      padding: '0px 0px 0px',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  startupKPIDataBox: {
    padding: '10px 0px',
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  kpiDataLabelText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.3,
    paddingRight: 25,
    marginBottom: 0,
    '@media (max-width: 992px)': {
      fontSize: 18,
      lineHeight: '20px',
    },
    '@media (max-width: 767px)': {
      marginBottom: 15,
    },
  },
  selectFrequBox: {
    display: 'flex',
    alignItems: 'center',
  },
  textValInputFreq: {
    background: theme.palette.common.white,
    paddingRight: 15,
    margin: 0,
    width: 130,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRootFreq: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  kpiTableDataBox: {
    background: theme.palette.common.white,
    borderRadius: 11,
    marginBottom: 25,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    paddingBottom: '20px',
  },
  addKpiButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // margin: '15px 20px 0',
    borderRadius: 30,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 157,
    fontSize: 16,
    cursor: 'pointer',
    height: 42,
    letterSpacing: 0.3,
    lineHeight: '20.08px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 179,
    height: 42,
    lineHeight: '20px',
    textTransform: 'capitalize',
    marginRight: 15,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      fontSize: 14,
      width: 140,
      marginRight: 0,
    },
    '@media (max-width: 525px)': {
      fontSize: 12,
      width: 130,
      height: 38,
    },
  },
  enterKpiTextContainer: {
    backgroundColor: '#E4F4F9',
    alignItems: 'center',
    padding: '6px 16px',
    height: 49,
    width: 675,
    marginBottom: 20,
    '@media (max-width: 992px)': {
      height: 'auto',
      width: '100%',
    },
  },
  infoIcon: {
    marginRight: 5,
    color: '#0288D1',
  },
  enterKpiDetailsContainer: {
    backgroundColor: '#FFEEEE',
    alignItems: 'center',
    padding: '6px 16px',
    height: 49,
    width: 363,
    marginTop: 20,
    marginBottom: 20,
  },
  infoIconRed: {
    marginRight: 5,
    color: '#FF5151',
  },
  saveBtnContainer: {
    marginTop: 40,
    display: 'flex',
  },
  /////////// Fundraising
  benchFundBox: {
    paddingRight: 5,
    // '@media (max-width: 1024px)': {
    //   padding: '30px 30px 30px',
    // },
    // '@media (max-width: 767px)': {
    //   padding: '30px 20px 20px',
    // },
  },
  fundraisingInterestedContainer: {
    padding: '10px 0px 30px',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      padding: '0px 0px 20px',
    },
  },
  fundraisingInterestedText: {
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    fontSize: 15,
    paddingRight: 25,
  },
  switchBox: {
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
  },
  switchRoot: {
    width: '116px',
    height: '28px',
    padding: '0px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#E5E5E5',
      },
      '& $thumb:after': {
        content: "'Yes'",
      },
    },
    '&$disabled + $track': {
      backgroundColor: '#E5E5E5',
      opacity: 0.5,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#E5E5E5',
      opacity: '0.5 !important',
    },
  },
  thumb: {
    color: theme.palette.primary.main,
    width: '58px',
    height: '28px',
    margin: '-1px',
    borderRadius: 31,
    '&:after': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 5,
      right: 18,
      left: 15,
      content: "'No'",
    },
  },
  track: {
    borderRadius: '31px',
    backgroundColor: '#E5E5E5',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 5,
    },
    '&:after': {
      content: "'No'",
      left: 15,
    },
    '&:before': {
      content: "'Yes'",
      right: 18,
    },
  },
  checked: {
    color: theme.palette.primary.main,
    transform: 'translateX(58px) !important',
  },
  /////////// Filter dialog
  filterLabelBox: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  filterLabelText: {
    color: theme.palette.grey[300],
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    textAlign: 'start',
    paddingRight: 20,
    '@media (max-width: 525px)': {
      paddingRight: 10,
      fontSize: 12.5,
    },
  },
  filterInput: {
    marginTop: 0,
    width: '90%',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  textValInputSelect: {
    marginBottom: 10,
    marginTop: 5,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRootSelect: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRootSelect: {},
  labelFocusedSelect: {},
  startupRankContainer: {
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 23,
  },
  startupRankText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: '23px',
    '@media (max-width: 768px)': {
      lineHeight: '15px',
    },
  },
  startupRankCompairedText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 400,
    lineHeight: '23px',
    '@media (max-width: 768px)': {
      lineHeight: '15px',
    },
  },
  startupRankValue: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '63px',
    '@media (max-width: 768px)': {
      fontSize: 40,
    },
  },
  /// OUTPUT Page
  benchmarkOutputContainer: {
    height: 'calc(100vh - 80px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingRight: 33,
    '@media (max-width: 1024px)': {
      padding: '30px',
      height: 'auto',
    },
    '@media (max-width: 767px)': {
      padding: '30px 20px 20px',
    },
  },
  benchmarkOutputChart: {
    width: '100%',
    margin: 'auto',
  },
  benchmarkContent: {
    display: 'flex',
    '@media (max-width: 600px)': {
      display: 'block',
    },
  },
  benchmarkContentLeft: {
    width: '100%',
    marginBottom: 35,
  },
  benchmarkContentRight: {
    '@media (max-width: 600px)': {
      width: '100%',
      paddingLeft: 0,
      marginBottom: 0,
    },
  },
  benchmarkContentTitle: {
    fontWeight: 600,
    paddingLeft: 0,
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.3,
    marginBottom: 25,
  },
  benchmarkOutputBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    background: '#FFF',
    border: '1px solid #D8D8D8',
    borderRadius: 11,
    padding: '1em 2em',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    // '@media (max-width: 600px)': {
    //   minHeight: 324,
    // },
  },
  benchmarkOutputBoxLeft: {
    textAlign: 'center',
    width: 180,
    marginRight: '3em',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  benchmarkOutputBoxRight: {
    width: 'calc(100% - 200px)',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  benchBetterText: {
    color: theme.palette.grey[50],
    // fontSize: 16,
    lineHeight: '23px',
    marginTop: 18,
    marginBottom: 10,
  },
  benchStartupText: {
    color: theme.palette.grey[50],
    // fontSize: 16,
    lineHeight: '23px',
    marginBottom: 30,
    '@media (max-width: 525px)': {
      marginBottom: 20,
    },
  },

  benchmarkTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 70px 15px 0',
    '@media (max-width: 767px)': {
      padding: '0px 0px 15px 0',
    },
  },

  benchmarkTitle: {
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: '0.3px',
    fontWeight: 600,
    '@media (max-width: 767px)': {
      paddingTop: 0,
    },
    '@media (max-width: 375px)': {
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  benchDoBetterText: {
    color: theme.palette.grey[50],
    // fontSize: 16,
    lineHeight: '23px',
    marginTop: 20,
    marginBottom: 10,
  },
  benchPeersText: {
    color: theme.palette.grey[50],
    // fontSize: 16,
    lineHeight: '23px',
  },
  benchmarkOutputBoxPer: {
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '63px',
    color: theme.palette.primary.main,
    marginBottom: 10,
    '@media (max-width: 525px)': {
      fontSize: 34,
      lineHeight: '41px',
    },
  },
  benchmarkOutputBoxText: {
    color: '#5592ED',
    paddingTop: 10,
    paddingBottom: 25,
    cursor: 'pointer',
    marginBottom: 0,
    '@media (max-width: 525px)': {
      paddingBottom: 20,
    },
  },
  benchmarkNoOutputBox: {
    width: '100%',
    minHeight: 381,
    background: '#FFF',
    border: '1px solid #D8D8D8',
    borderRadius: 11,
    textAlign: 'center',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    '@media (max-width: 600px)': {
      minHeight: 324,
    },
  },
  benchmarkNoOutputText: {
    padding: 20,
    fontSize: 18,
    color: theme.palette.grey[50],
    lineHeight: '23px',
    marginBottom: 10,
  },
  benchmarkOutputBoxDollar: {
    width: '100%',
    background: '#FFF',
    border: '1px solid #D8D8D8',
    borderRadius: 11,
    textAlign: 'center',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      minHeight: 324,
    },
  },
  benchmarkOutputBoxDollarContent: {
    alignSelf: 'center',
    padding: 20,
  },
  benchmarkSubGreenText: {
    fontSize: 18,
    color: theme.palette.grey[50],
    lineHeight: '23px',
    marginBottom: 10,
  },
  fundRaisingUpdateText: {
    fontSize: 18,
    color: theme.palette.primary.main,
    lineHeight: '23px',
    marginBottom: 10,
    cursor: 'pointer',
  },
  benchmarkOutputGreenText: {
    fontWeight: 700,
    fontSize: 48,
    lineHeight: '60px',
    color: theme.palette.primary.main,
    '@media (max-width: 525px)': {
      fontSize: 34,
      lineHeight: '41px',
    },
  },
  benchMillionText: {
    fontSize: 20,
    marginLeft: 8,
  },
  applyVentureDebtBox: {
    marginTop: 40,
  },
  applyVentButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 250,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      display: 'block',
      width: '100%',
      height: 50,
      fontSize: 16,
      marginBottom: 20,
    },
  },
  goToDashButton: {
    width: 200,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    marginLeft: 20,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      display: 'block',
      width: '100%',
      height: 50,
      fontSize: 16,
      marginLeft: 0,
    },
  },
  editProfileBtn: {
    marginLeft: 10,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 50,
    width: 125,
    height: 25,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 13,
    cursor: 'pointer',
    letterSpacing: 0.3,
    lineHeight: '16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  sidePaneWrapper: {
    padding: 25,
    paddingTop: 0,
    overflowY: 'scroll',
    height: 'calc(100vh - 80px)',
    '@media (max-width: 767px)': {
      padding: 15,
    },
  },
  yardstickTypeTabs: {
    borderBottom: '0.5px solid #DEDEDE',
    marginBottom: 20,
    '& .PrivateTabIndicator-root-330': {
      height: 3,
    },
  },
  yardstickTabs: {
    height: 'calc(100vh - 370px)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  permWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  togglebtngrp: {
    height: 20,
  },
  enableToggle: {
    height: 22,
    marginRight: 5,
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  disableToggle: {
    height: 22,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.grey.A700,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.grey.A700,
      color: theme.palette.primary.contrastText,
    },
  },
  timeAgo: {
    fontSize: 10,
  },
  permLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  noRecordsPerm: {
    textAlign: 'center',
  },
}));
