import { get } from 'lodash';

import { EnquiryPayloadAction } from 'common/types';

import { IEnquiryState } from './Types';
import {
  USER_SUBMIT_FAILURE,
  USER_SUBMIT_LOADING,
  USER_SUBMIT_SUCCESS,
  USER_DATA,
  GET_ENQUIRY_DETAILS_BY_ID_SUCCESS,
  USER_SUBMIT_LOADING_FOUNDER,
  USER_SUBMIT_SUCCESS_FOUNDER,
  USER_SUBMIT_FAILURE_FOUNDER,
  USER_SUBMIT_LOADING_BUSINESS,
  USER_SUBMIT_SUCCESS_BUSINESS,
  USER_SUBMIT_FAILURE_BUSINESS,
  USER_SUBMIT_LOADING_OTHER_INFO,
  USER_SUBMIT_SUCCESS_OTHER_INFO,
  USER_SUBMIT_FAILURE_OTHER_INFO,
  GET_ENQUIRY_DETAILS_BY_ID_FAILURE,
  USER_STARTUP_ID,
  GET_STARTUP_DETAILS_SUCCESS,
  EMPTY_ENQUIRY_FORM,
  UPLOAD_DOCUMENTS_LOADING,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENTS_FAILURE,
  GET_STARTUP_DATA_LOADING,
  GET_STARTUP_DATA_SUCCESS,
  GET_STARTUP_DATA_FAILURE,
  GET_FOUNDER_DETAILS_LOADING,
  GET_FOUNDER_DETAILS_SUCCESS,
  GET_FOUNDER_DETAILS_FAILURE,
} from './Actions';

export const EnquiryFormState: IEnquiryState = {
  id: '',
  currentPage: '',
  enquiryRequestLoanAmount: null,
  enquiryTermsAccepted: false || undefined,
  startupDetails: {
    // startupName: '',
    // startupId: '',
    // startupLink: '',
    // startupLogoUrl: '',
    // locationId: '',
    // ycIndustryClassification: '',
    // description: '',
    // startupTwitterUrl: '',
    // startupFacebookUrl: '',
    // startupLinkedInUrl: '',
    // ycBatch: '',
    // startupProductDemoUrl: '',
    // isStartupYcCertified: false,
    startupFullName: '',
    assignedYcLegalPocName: '',
    assignedYcPartnerName: '',
    startupId: '',
    startupYcAssignedId: '',
    startupTaxIdNumber: '',
    startupWebLink: '',
    startupLogoUrl: '',
    startupLegalState: '',
    ycLocation: '',
    ycIncorporationCountry: '',
    stateOfIncorporation: '',
    enquiryTermsAccepted: false,
    startupCountry: '',
    businessOpsLocation: '',
    industryClassification: '',
    industryType: '',
    startupTwitterUrl: '',
    startupFacebookUrl: '',
    startupLinkedInUrl: '',
    ycBatch: '',
    businessModel: '',
    fundingStage: '',
    benchmarkStartup: false,
    accelerator: '',
  },
  productDescription: '',
  productProgress: '',
  potentialMarketTarget: '',
  startupInvestmentInformation: '',
  startupLegalInformation: '',
  defaultFounderDetails: [
    {
      founderName: '',
      founderEmail: '',
      // founderPhoneNumber: '',
      // founderQualifications: '',
      // founderLinkedinUrl: '',
      // founderRole: '',
      // founderBio: '',
    },
  ],
  founderDetails: [
    {
      founderName: '',
      founderEmail: '',
      // founderPhoneNumber: '',
      // founderQualifications: '',
      // founderLinkedinUrl: '',
      // founderRole: '',
      // founderBio: '',
    },
  ],
  additionalInformation: {
    startup8vdxSource: '',
    startupReasonForChosing: '',
    startupInvestmentInformation: '',
    startupLegalInformation: '',
  },
  isStartUp: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
  },
  isFounder: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
  },
  isBusiness: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
  },
  isAdditionalInfo: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
  },
  popupLog: '',
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  uploadDocuments: [],
  getDocsToUploadErr: '',
};

const EnquiryFormReducer = (
  state = EnquiryFormState,
  action: EnquiryPayloadAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case USER_STARTUP_ID:
      return {
        ...state,
        startupDetails: {
          ...state.startupDetails,
          startupId: payload,
        },
      };
    case USER_SUBMIT_LOADING:
      return {
        ...state,
        isStartUp: {
          ...state.isStartUp,
          isLoading: true,
        },
      };
    case GET_STARTUP_DETAILS_SUCCESS:
      return {
        ...state,
        startupDetails: {
          ...state.startupDetails,
          startupId: get(payload, 'id'),
          startupFullName: get(payload, 'fullName', ''),
          startupName: get(payload, 'name'),
          accelerator: get(payload, 'accelerator'),
          ycBatch: get(payload, 'ycBatch'),
          ycIncorporationCountry: get(payload, 'ycIncorporationCountry'),
          stateOfIncorporation: get(payload, 'stateOfIncorporation'),
          businessOpsLocation: get(payload, 'businessOpsLocation'),
          industryClassification: get(payload, 'industryClassification'),
          industryType: get(payload, 'industryType'),
          businessModel: get(payload, 'businessModel'),
          fundingStage: get(payload, 'fundingStage'),
          benchmarkStartup: get(payload, 'benchmarkStartup'),
        },
      };
    case USER_DATA:
      return {
        ...state,
        ...payload,
        // id: payload.id,
        founderDetails: (get(payload, 'founderDetails') || []).length
          ? get(payload, 'founderDetails')
          : [
              {
                founderName: '',
                founderEmail: '',
                founderPhoneNumber: '',
                founderQualifications: '',
                founderLinkedinUrl: '',
                founderRole: '',
                founderBio: '',
              },
            ],
        startupDetails: {
          ...state.startupDetails,
          ...payload.startupDetails,
        },
      };
    case USER_SUBMIT_SUCCESS:
      return {
        ...state,
        id: payload.id,
        isStartUp: {
          isLoading: false,
          successMessage: payload.message,
        },
      };
    case USER_SUBMIT_FAILURE:
      return {
        ...state,
        isStartUp: {
          isLoading: false,
          errorMessage: payload.message,
        },
      };
    case USER_SUBMIT_LOADING_FOUNDER:
      return {
        ...state,
        isFounder: {
          ...state.isFounder,
          isLoading: true,
        },
      };
    case USER_SUBMIT_SUCCESS_FOUNDER:
      return {
        ...state,
        ...get(payload, 'response'),
        founderDetails: [...get(payload, 'response.founderDetails')],
        startupDetails: {
          ...payload.response.startupDetails,
        },
        isFounder: {
          isLoading: false,
          successMessage: payload.message,
        },
      };
    case USER_SUBMIT_FAILURE_FOUNDER:
      return {
        ...state,
        isFounder: {
          isLoading: false,
          errorMessage: payload.message,
        },
      };
    case USER_SUBMIT_LOADING_BUSINESS:
      return {
        ...state,
        isBusiness: {
          ...state.isBusiness,
          isLoading: true,
        },
      };
    case USER_SUBMIT_SUCCESS_BUSINESS:
      return {
        ...state,
        ...get(payload, 'response'),
        isBusiness: {
          isLoading: false,
          successMessage: payload.message,
        },
      };
    case USER_SUBMIT_FAILURE_BUSINESS:
      return {
        ...state,
        isBusiness: {
          isLoading: false,
          errorMessage: payload.message,
        },
      };
    case USER_SUBMIT_LOADING_OTHER_INFO:
      return {
        ...state,
        isAdditionalInfo: {
          ...state.isAdditionalInfo,
          isLoading: true,
        },
      };
    case USER_SUBMIT_SUCCESS_OTHER_INFO:
      return {
        ...state,
        ...get(payload, 'response'),
        isAdditionalInfo: {
          isLoading: false,
          successMessage: payload.message,
        },
        popupLog: '',
        successMessage: '',
      };
    case USER_SUBMIT_FAILURE_OTHER_INFO:
      return {
        ...state,
        isAdditionalInfo: {
          isLoading: false,
          errorMessage: payload.message,
        },
      };
    case GET_ENQUIRY_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        ...payload,
        startupDetails: {
          ...payload.startupDetails,
          startupWebLink: get(payload, 'startupDetails.startupLink'),
          locationId: get(payload, 'startupDetails.location.id'),
          location: get(payload, 'startupDetails.location'),
        },
        isLoading: false,
        successMessage: 'successfully fetched',
      };
    case GET_ENQUIRY_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        startupDetails: {
          ...state.startupDetails,
          startupId: payload.id,
        },
        isLoading: false,
        popupLog: payload.message,
      };
    case EMPTY_ENQUIRY_FORM:
      return {
        ...EnquiryFormState,
      };
    case UPLOAD_DOCUMENTS_LOADING:
      return {
        ...state,
        isLoading: true,
        uploadDocuments: [],
        errorMessage: '',
      };
    case UPLOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        uploadDocuments: payload,
        errorMessage: '',
        getDocsToUploadErr: '',
      };
    case UPLOAD_DOCUMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        getDocsToUploadErr: payload,
      };
    case GET_STARTUP_DATA_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_STARTUP_DATA_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
        errorMessage: null,
        startupDetails: {
          ...state.startupDetails,
          ...payload.startupDetails,
          industryClassification: get(
            payload,
            'startupDetails.industryClassification',
          ),
          industryType: get(payload, 'startupDetails.industryType'),
        },
        founderDetails: [...get(payload, 'founderDetails', [])],
      };
    case GET_STARTUP_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case GET_FOUNDER_DETAILS_LOADING:
      return {
        ...state,
        isFounder: {
          isLoading: true,
          successMessage: '',
          errorMessage: '',
        },
      };
    case GET_FOUNDER_DETAILS_SUCCESS:
      return {
        ...state,
        isFounder: {
          isLoading: false,
          successMessage: '',
          errorMessage: '',
        },
        defaultFounderDetails: payload,
      };
    case GET_FOUNDER_DETAILS_FAILURE:
      return {
        ...state,
        isFounder: {
          isLoading: false,
          successMessage: '',
          errorMessage: payload,
        },
      };
    default:
      return state;
  }
};

export default EnquiryFormReducer;
