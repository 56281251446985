/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { Button } from 'components';
type ConfirmDialogProps = {
  dialogOpen: boolean;
  dialogHeading: string;
  dialogContent: any;
  onClose: any;
};
const ConfirmDialog = ({
  dialogOpen,
  onClose,
  dialogContent,
  dialogHeading,
}: ConfirmDialogProps) => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogHeading}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button name={'OK'} onClick={() => onClose()} />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
