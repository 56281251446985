import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { get } from 'lodash';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { Box } from 'components';
import { FUNDING_ENTITY_TYPE } from 'common/utils/constants';
import { errorMessageHandler } from 'common/utils/helpers';
import { getAdminKycSSN } from 'services';

import styles from './styles';

type ComponentProps = {
  investor: any;
  spv?: string;
  kycDetail?: any;
  investmentData?: any;
};

const InvestorOverview = ({ kycDetail }: ComponentProps) => {
  const classes = styles();

  const [showSSN, setShowSSN] = React.useState<boolean>(false);
  const [kycSSN, setKycSSN] = React.useState<string>('');

  const handleClickShowSSN = () => {
    if (!showSSN && !kycSSN) {
      getAdminKycSSN(get(kycDetail, 'id'))
        .then((res: any) => {
          setKycSSN(res);
          setShowSSN(!showSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.log('ERROR WHILE FETCHING SSN: ', message);
        });
    } else {
      setShowSSN(!showSSN);
    }
  };

  return (
    <Box className={classes.tabPanelContent}>
      <Typography variant="h4" gutterBottom className={classes.basicHeadTitle}>
        Basic Details
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Funding Type
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {get(kycDetail, 'fundingEntityType')}
      </Typography>
      {get(kycDetail, 'fundingEntityType') === FUNDING_ENTITY_TYPE.ENTITY && (
        <>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.overViewSubHeadTitle}
          >
            The legal name of the funding entity
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.descTitletext}
          >
            {get(kycDetail, 'fundingEntityLegalName')}
          </Typography>
        </>
      )}
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        What is the signatory's legal name?
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {get(kycDetail, 'signatoryLegalName')}
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        EIN/Social Security Number (SSN):
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {showSSN ? kycSSN : get(kycDetail, 'ssn')}
        <IconButton
          aria-label="toggle visibility"
          onClick={handleClickShowSSN}
          edge="end"
        >
          {get(kycDetail, 'ssn') &&
            (showSSN ? <Visibility /> : <VisibilityOff />)}
        </IconButton>
      </Typography>
      {get(kycDetail, 'fundingEntityType') ===
        FUNDING_ENTITY_TYPE.INDIVIDUAL && (
        <>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.overViewSubHeadTitle}
          >
            Date of Birth
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.descTitletext}
          >
            {get(kycDetail, 'dob')}
          </Typography>
        </>
      )}
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Email Id
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {get(kycDetail, 'email')}
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Phone
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {get(kycDetail, 'phone')?.split('#').join(' ')}
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Address
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {get(kycDetail, 'address1')}
        {', '}
        {get(kycDetail, 'zip')}
      </Typography>
    </Box>
  );
};

export default InvestorOverview;
