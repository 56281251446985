import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  // Desktop Links
  fileWrapper: {
    border: '1px solid',
    padding: '10px',
    borderColor: '#94949463',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  upLoadBox: {
    display: 'flex',
    padding: '10px 18px',
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    maxWidth: 634,
    marginTop: 15,
    minHeight: 45,
  },
  upLoadleft: {
    display: 'flex',
    width: 'calc(100% - 13px)',
  },
  circleImg: {
    height: 20,
    marginRight: 20,
    alignSelf: 'center',
  },
  bGImg: {
    height: 55,
    marginRight: 11,
    width: 60,
    cursor: 'pointer',
    borderRadius: 6,
    border: '1px solid #1EA59A',
    objectFit: 'cover',
  },
  uploadFileName: {
    alignSelf: 'center',
    fontSize: 16,
    lineHeight: '18px',
    color: theme.palette.grey[900],
  },
  crossImg: {
    height: 13,
    alignSelf: 'center',
  },
  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
  },
  previewImgNotUploaded: {
    height: 42.5,
    marginRight: 11,
    cursor: 'default',
  },
}));
