import React, { useEffect, useState } from 'react';
import { Typography, Checkbox } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { find, get, isEmpty } from 'lodash';

import history from 'common/utils/history';
import { RootState } from 'redux-modules/store/rootState';
import {
  errorMessageHandler,
  incorporationCountryList,
  getAllStatesOfCountry,
  getIncorporationCountryObject,
  getStateObject,
  getIndustryClassificationList,
  getIndustryClassificationObject,
  getIndustryTypeObject,
} from 'common/utils/helpers';
import {
  Button,
  Box,
  WrappedTextInput,
  WrappedAutocomplete,
  WrappedSelect,
  FileUpload,
  Loader,
} from 'components';
import { StartupOnboardStepProps } from 'common/types';
import { urlConstants } from 'common/utils/constants';
import {
  saveStartupOnboardDetails,
  getSignedUrlForYCDoc,
  getYcDocs,
} from 'services';
import { IconImg, documentIcon, UploadIcon } from 'assets';
import { acceleratorList, industryTypeList } from 'common/utils/optionList';

import {
  StartupOnboardSchema,
  StartupOnboardUpdateSchema,
} from '../validations';
import styles from '../styles';
import AdditionalDetails from './AdditionalDetails';

const BasicDetails = ({
  id,
  type,
  stepName,
  startUpId,
  enquiryId,
  setEnquiryId,
  scheduled,
  handleNext,
  loanStatus,
  setHasYcScreenshot,
}: StartupOnboardStepProps) => {
  const classes = styles();

  const { handleSubmit, control, watch, setValue, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      isEmpty(id) || id === 'NEW'
        ? StartupOnboardSchema
        : StartupOnboardUpdateSchema,
    ),
  });

  const { ycBatches } = useSelector(({ Auth }: RootState) => Auth);
  const { bridgeLoanProcess } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const { enquiryView } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const { startupDetails, enquiryTermsAccepted } = enquiryView;

  const {
    startupFullName,
    startupTaxIdNumber,
    ycIncorporationCountry,
    stateOfIncorporation,
    industryType,
    industryClassification,
    assignedYcPartnerName,
    assignedYcLegalPocName,
    accelerator,
  } = startupDetails;

  const [details, setDetails] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');
  const [showYcDocPreview, setShowYcDocPreview] = useState<boolean>(false);
  const [selectedIndustry, setSelectedIndustry] = React.useState<any>(
    industryType,
  );
  const [
    startupUploadedDocument,
    setStartupUploadedDocument,
  ] = React.useState<any>([]);
  const [
    signedUrlErrorMessage,
    setSignedUrlErrorMessage,
  ] = React.useState<string>('');
  const [isLoadingSignedUrl, setIsLoadingSignedUrl] = React.useState<boolean>(
    false,
  );
  // const [documentObject, setDocumentObject] = React.useState<any>([]);

  const detailPage = () => {
    setDetails(true);
  };
  const previousPage = () => {
    setDetails(false);
  };

  const getDocument = () => {
    getYcDocs(`${startUpId}/BRIDGE_LOAN`)
      .then((startupYcDocument) => {
        setStartupUploadedDocument(startupYcDocument);
        setShowYcDocPreview(true);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const handleStartupDetailPayload = (data: any) => {
    const startupFormDetails = {
      ...data,
      startupId: startUpId,
    };
    const obj = {
      currentPage: stepName,
      enquiryTermsAccepted: get(startupFormDetails, 'enquiryTermsAccepted'),
      startupDetails: {
        startupFullName: get(startupFormDetails, 'startupFullName'),
        startupId: get(startupFormDetails, 'startupId'),
        startupTaxIdNumber: get(startupFormDetails, 'startupTaxIdNumber'),
        ycIncorporationCountry: get(
          startupFormDetails,
          'ycIncorporationCountry.countryCode',
        ),
        stateOfIncorporation: get(
          startupFormDetails,
          'stateOfIncorporation.isoCode',
        ),
        assignedYcPartnerName: get(data, 'assignedYcPartnerName'),
        assignedYcLegalPocName: get(data, 'assignedYcLegalPocName'),
        industryType: get(data, 'industryType.value'),
        industryClassification: get(data, 'industryClassification.value'),
      },
      ycDocuments: startupUploadedDocument.map((document: any) => {
        return {
          ...document,
          documentUrl: document.accessUrl || document.documentUrl,
        };
      }),
      id: enquiryId,
    };
    return obj;
  };

  const cancelSelectedDocument = () => {
    setStartupUploadedDocument([]);
  };

  const uploadFile = async (fileuploadReq: any): Promise<void> => {
    if (!isEmpty(fileuploadReq)) {
      try {
        const documentUrl = get(fileuploadReq, 'url.accessUrl');
        const filename = get(fileuploadReq, 'filename');
        // setDocumentObject([fileuploadReq]);
        if (isEmpty(startupUploadedDocument)) {
          setStartupUploadedDocument([
            { filename, documentUrl, documentName: 'YC_ACCEPTANCE' },
          ]);
        } else {
          const [firstDoc] = startupUploadedDocument;
          firstDoc.filename = filename;
          firstDoc.documentUrl = documentUrl;
          firstDoc.documentStatus = 'UPLOADED';
          setStartupUploadedDocument([firstDoc]);
        }
        setShowYcDocPreview(false);
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setIsLoadingSignedUrl(false);
      }
    }
  };

  const getDisableYcField = () => {
    if (loanStatus === 'ONHOLD') {
      return false;
    }
    if (get(bridgeLoanProcess, 'documentsSigned') || scheduled) {
      return true;
    }
  };

  const onSubmit = (navigationToAddMore = false): void => {
    const data: any = { ...getValues() };
    const payload: any = {
      enquiryDetail: handleStartupDetailPayload(data),
    };
    if (isEmpty(startUpId)) {
      payload.signupDetail = {
        name: get(data, 'name'),
        startupName: get(data, 'startupName'),
        ycBatch:
          get(data, 'accelerator') === 'YC' ? get(data, 'ycBatch') : null,
        email: get(data, 'email'),
        accelerator:
          get(data, 'accelerator') === 'OTHER'
            ? get(data, 'acceleratorName')
            : get(data, 'accelerator'),
      };
    }

    if (
      !isEmpty(startUpId) &&
      accelerator === 'YC' &&
      isEmpty(startupUploadedDocument)
    ) {
      setSignedUrlErrorMessage('Please upload file');
      return;
    }
    setIsLoading(true);
    setHasYcScreenshot(!isEmpty(startupUploadedDocument));
    saveStartupOnboardDetails(payload)
      .then((res: any) => {
        setIsLoading(false);
        setEnquiryId(res?.id);
        type === 'NEW'
          ? history.push(`/admin/startup-onboard/${res?.id}`)
          : navigationToAddMore
          ? detailPage()
          : startupUploadedDocument && !isEmpty(startupUploadedDocument)
          ? handleNext()
          : null;
        getDocument();
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  useEffect(() => {
    if (startUpId) {
      getDocument();
    }
  }, []);

  useEffect(() => {
    if (isEmpty(selectedCountryCode) && !isEmpty(ycIncorporationCountry))
      setSelectedCountryCode(ycIncorporationCountry);
  }, [ycIncorporationCountry]);

  useEffect(() => {
    if (isEmpty(selectedIndustry) && !isEmpty(industryType))
      setSelectedIndustry(industryType);
  }, [industryType]);

  useEffect(() => {
    if (!isEmpty(startupFullName)) {
      setValue('startupFullName', startupFullName);
      setValue('startupTaxIdNumber', startupTaxIdNumber);
      setValue(
        'ycIncorporationCountry',
        getIncorporationCountryObject(ycIncorporationCountry),
      );
      setValue(
        'stateOfIncorporation',
        getStateObject(stateOfIncorporation, ycIncorporationCountry),
      );
      setValue('enquiryTermsAccepted', enquiryTermsAccepted);
      setValue('assignedYcPartnerName', assignedYcPartnerName);
      setValue('assignedYcLegalPocName', assignedYcLegalPocName);
      setValue('industryType', { text: industryType, value: industryType });
      setValue('industryClassification', {
        text: industryClassification,
        value: industryClassification,
      });
    }
  }, [startupDetails]);

  return !details ? (
    <div>
      <Typography variant="h2" className={classes.detailsText}>
        Onboard a startup
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.errorText}>
        {errorMessage}
      </Typography>
      <form
        onSubmit={
          get(bridgeLoanProcess, 'documentsSigned') && loanStatus !== 'ONHOLD'
            ? () => handleNext()
            : handleSubmit(() => {
                if (
                  isEmpty(startupUploadedDocument) &&
                  watch('accelerator') === 'YC' &&
                  !isEmpty(startUpId)
                ) {
                  setSignedUrlErrorMessage('Please upload file');
                } else if (
                  !isEmpty(startupUploadedDocument) &&
                  watch('accelerator') === 'YC'
                ) {
                  onSubmit(false);
                } else {
                  onSubmit(false);
                }
              })
        }
        data-testid="startupOnboardForm"
        className={classes.validateForm}
      >
        {isEmpty(startUpId) && (
          <>
            <Box className={classes.headDetails}>
              <Typography variant="h3" className={classes.headerText}>
                SignUp Details
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Founder's Name*
                </Typography>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Founder's Full Name"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={(e: any) =>
                          onChange(get(e, 'target.value').trimStart())
                        }
                        error={!!error?.message}
                        disabled={get(bridgeLoanProcess, 'basicInfoAdded')}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: "Founder's Name is required" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className={classes.inputHeadRight}
              >
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Founder's Email*
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Enter your Email ID" //"Enter your email ID provided to YC"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={(e: any) =>
                          onChange(
                            get(e, 'target.value').trimStart().toLowerCase(),
                          )
                        }
                        error={!!error?.message}
                        disabled={get(bridgeLoanProcess, 'basicInfoAdded')}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Email is required' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Company Name*
                </Typography>
                <Controller
                  name="startupName"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Company Name" // "Company Name as provided on YC Bookface"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={(e: any) =>
                          onChange(get(e, 'target.value').trimStart())
                        }
                        error={!!error?.message}
                        disabled={get(bridgeLoanProcess, 'basicInfoAdded')}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Company name is required' }}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Accelerator*
                  </Typography>
                  <Controller
                    name="accelerator"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedSelect
                          value={value}
                          onChange={onChange}
                          label="Accelerator"
                          placeholder="Accelerator"
                          className={classes.selectValInput}
                          InputProps={{ name: 'Accelerator', id: 'age-simple' }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          error={!!error?.message}
                          dropdowns={acceleratorList}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className={classes.inputHeadRight}
                >
                  {watch('accelerator') === 'YC' && (
                    <>
                      <Typography
                        variant="caption"
                        className={classes.companyLabelText}
                      >
                        YC Batch*
                      </Typography>
                      <Controller
                        name="ycBatch"
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedSelect
                              value={value}
                              onChange={onChange}
                              label="YC Batch"
                              placeholder="YC batch"
                              className={classes.selectValInput}
                              InputProps={{
                                name: 'Yc Batch',
                                id: 'age-simple',
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  classes: { paper: classes.menuPaper },
                                },
                              }}
                              error={!!error?.message}
                              dropdowns={ycBatches}
                              disabled={get(
                                bridgeLoanProcess,
                                'basicInfoAdded',
                              )}
                            />
                            {error?.message && (
                              <span className={classes.errorText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className={classes.inputHeadRight}
                >
                  {watch('accelerator') === 'OTHER' && (
                    <>
                      <Typography
                        variant="caption"
                        className={classes.companyLabelText}
                      >
                        Accelerator Name*
                      </Typography>
                      <Controller
                        name="acceleratorName"
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedTextInput
                              type="text"
                              label=""
                              placeholder="Accelerator Name"
                              className={classes.textValInput}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              value={value}
                              onChange={(e: any) =>
                                onChange(get(e, 'target.value').trimStart())
                              }
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <span className={classes.errorText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                        rules={{ required: 'Accelerator Name is required' }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Box className={classes.headDetails}>
          <Typography variant="h3" className={classes.headerText}>
            Basic Details
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.inputHeadFull}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Full legal name of your company*
            </Typography>
            <Controller
              name="startupFullName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="Legal name of the entity receiving YC investment"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                    disabled={get(bridgeLoanProcess, 'documentsSigned')}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Company full name is required' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Country of Incorporation*
            </Typography>
            <Controller
              name="ycIncorporationCountry"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedAutocomplete
                    id="field1"
                    disableClearable
                    options={incorporationCountryList}
                    // value={value}
                    placeholder="Country of Incorporation"
                    value={
                      value ||
                      getIncorporationCountryObject(ycIncorporationCountry)
                    }
                    getOptionLabel={(option: any) => option.country || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.countryCode === value.countryCode
                    }
                    onChange={(_: any, value: any) => {
                      setSelectedCountryCode(value?.countryCode);
                      setValue('stateOfIncorporation', null);
                      onChange(value);
                    }}
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={error?.message ? true : false}
                    disabled={get(bridgeLoanProcess, 'documentsSigned')}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
            <Typography variant="caption" className={classes.companyLabelText}>
              State of Incorporation of the above legal entity*
            </Typography>
            <Controller
              name="stateOfIncorporation"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedAutocomplete
                    id="field2"
                    placeholder="State of Incorporation"
                    disableClearable
                    options={getAllStatesOfCountry(selectedCountryCode || '')}
                    // value={value}
                    value={
                      value ||
                      (selectedCountryCode !== 'PENDING_INCORPORATION' &&
                      selectedCountryCode == ycIncorporationCountry
                        ? getStateObject(
                            stateOfIncorporation,
                            ycIncorporationCountry,
                          )
                        : null)
                    }
                    getOptionLabel={(option: any) => option.name || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.isoCode === value.isoCode
                    }
                    onChange={(_: any, value: any) => {
                      onChange(value);
                    }}
                    disabled={
                      !selectedCountryCode ||
                      selectedCountryCode === 'PENDING_INCORPORATION' ||
                      get(bridgeLoanProcess, 'documentsSigned')
                    }
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={error?.message ? true : false}
                  />

                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Tax identification number of the legal entity*
            </Typography>
            <Controller
              name="startupTaxIdNumber"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="Company tax identification number"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                    disabled={get(bridgeLoanProcess, 'documentsSigned')}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.inputHeadRight}
          ></Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Sector/Industry*
            </Typography>
            <Controller
              name="industryType"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedAutocomplete
                    id="industry-type-select"
                    placeholder="Select Industry"
                    disableClearable
                    options={industryTypeList}
                    // value={value}
                    value={value || getIndustryTypeObject(industryType)}
                    getOptionLabel={(option: any) => option.text || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.value === value?.value
                    }
                    onChange={(_: any, value: any) => {
                      setSelectedIndustry(value?.value);
                      setValue('industryClassification', null);
                      onChange(value);
                    }}
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    disabled={
                      get(bridgeLoanProcess, 'documentsSigned') || scheduled
                    }
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={error?.message ? true : false}
                    /*disabled={!isEmpty(industryType)}*/
                  />

                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Sub-industry*
            </Typography>
            <Controller
              name="industryClassification"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedAutocomplete
                    id="industry-classification"
                    placeholder="Sub-industry"
                    disableClearable
                    options={getIndustryClassificationList(selectedIndustry)}
                    // value={value}
                    value={
                      value ||
                      (selectedIndustry == industryType
                        ? getIndustryClassificationObject(
                            industryType,
                            industryClassification,
                          )
                        : null)
                    }
                    getOptionLabel={(option: any) => option.text || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.value === value.value
                    }
                    onChange={(_: any, value: any) => {
                      onChange(value);
                    }}
                    disabled={
                      get(bridgeLoanProcess, 'documentsSigned') ||
                      !selectedIndustry ||
                      !isEmpty(industryClassification)
                    }
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          {(watch('accelerator') === 'YC' ||
            get(startupDetails, 'accelerator') === 'YC') && (
            <>
              <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Assigned YC Group Partner Name*
                </Typography>
                <Controller
                  name="assignedYcPartnerName"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Assigned YC Group Partner Name" // "Company Name as provided on YC Bookface"
                        className={classes.textValInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={(e: any) =>
                          onChange(get(e, 'target.value').trimStart())
                        }
                        error={!!error?.message}
                        disabled={get(bridgeLoanProcess, 'basicInfoAdded')}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{
                    required: 'Assigned YC Group Partner Name is required',
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className={classes.inputHeadRight}
              >
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Assigned YC Legal POC Name*
                </Typography>
                <Controller
                  name="assignedYcLegalPocName"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Assigned YC Legal POC Name" // "Company Name as provided on YC Bookface"
                        className={classes.textValInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={(e: any) =>
                          onChange(get(e, 'target.value').trimStart())
                        }
                        error={!!error?.message}
                        disabled={get(bridgeLoanProcess, 'basicInfoAdded')}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{
                    required: 'Assigned YC Legal POC Name is required',
                  }}
                />
              </Grid>
              {!isEmpty(startUpId) && (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className={classes.inputHead}
                  >
                    <Typography
                      variant="caption"
                      className={classes.ycAcceptanceText}
                    >
                      YC Acceptance email screenshot*
                    </Typography>
                    {(isEmpty(startupUploadedDocument) ||
                      !isEmpty(
                        startupUploadedDocument &&
                          find(startupUploadedDocument, {
                            documentStatus: 'REJECTED',
                          }),
                      )) && (
                      <FileUpload
                        getSignedUrl={getSignedUrlForYCDoc}
                        style={{ display: 'block' }}
                        uploadOnSelect={uploadFile}
                        fileExtensions={[
                          'png',
                          'jpg',
                          'jpeg',
                          'doc',
                          'docx',
                          'pdf',
                        ]}
                        /*disabled={get(bridgeLoanProcess, 'documentsSigned')}*/
                        disabled={getDisableYcField()}
                        setLoadingSignedUrl={setIsLoadingSignedUrl}
                        requestObject={{
                          startupId: startUpId,
                        }}
                        /*onSuccess={(req: any) => setDocumentObject([req])}*/
                        showSelectedFilePreview={false}
                        content={
                          <Box>
                            <Grid
                              container
                              className={classes.uploadYcContainer}
                            >
                              <Grid item>
                                <img
                                  src={UploadIcon}
                                  alt={UploadIcon}
                                  className={classes.uploadYcIcon}
                                />
                                <Typography
                                  variant="caption"
                                  className={classes.uploadYcText}
                                >
                                  Upload Document
                                </Typography>
                              </Grid>
                            </Grid>

                            {signedUrlErrorMessage &&
                              isEmpty(startupUploadedDocument) &&
                              !isLoadingSignedUrl && (
                                <Typography
                                  variant="caption"
                                  className={classes.ycErrorText}
                                >
                                  {signedUrlErrorMessage}
                                </Typography>
                              )}
                          </Box>
                        }
                      />
                    )}

                    {isLoadingSignedUrl && <Loader />}
                    {!isLoadingSignedUrl &&
                      !isEmpty(startupUploadedDocument) &&
                      startupUploadedDocument.map((item: any) => (
                        <Box key={item.id} className={classes.upLoadBox}>
                          <Box className={classes.upLoadleft}>
                            <img
                              src={IconImg}
                              alt={IconImg}
                              className={classes.circleImg}
                            />
                            {showYcDocPreview && (
                              <a
                                href={get(item, 'documentUrl')}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={documentIcon}
                                  alt={documentIcon}
                                  className={classes.previewImgNotUploaded}
                                />
                              </a>
                            )}
                            {!showYcDocPreview && (
                              <img
                                src={documentIcon}
                                alt={documentIcon}
                                className={classes.previewImgNotUploaded}
                              />
                            )}
                            <Typography className={classes.uploadFileName}>
                              {get(item, 'filename')}
                            </Typography>
                          </Box>
                          {!item.id && (
                            <CancelIcon
                              className={classes.crossImg}
                              onClick={() => cancelSelectedDocument()}
                            />
                          )}
                        </Box>
                      ))}
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Controller
              name="enquiryTermsAccepted"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.checkBoxAgree}>
                  <Checkbox
                    style={{ paddingLeft: 0 }}
                    checked={value}
                    defaultChecked={enquiryTermsAccepted}
                    onChange={(e: any) => {
                      onChange(e.target.checked);
                    }}
                    disabled={get(bridgeLoanProcess, 'documentsSigned')}
                  />
                  <Typography variant="caption" className={classes.agreeTerms}>
                    I agree to the{' '}
                    <a
                      href={urlConstants.terms_s3_url}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.termAndCondition}
                    >
                      Terms and Conditions
                    </a>
                  </Typography>
                  {error?.message && (
                    <span className={classes.termsText}>
                      Please read and agree to the Terms & Conditions to submit
                      the application
                    </span>
                  )}
                </Box>
              )}
              rules={{ required: 'This is required' }}
            />
          </Grid>
        </Grid>
        <Box className={classes.backContinueBox}>
          <Button
            type="submit"
            className={classes.continueBtn}
            name={`Submit & Continue`}
            isLoading={isLoading}
            disabled={isLoading}
          />
          {!isEmpty(enquiryId) && (
            <Button
              variant="outlined"
              className={classes.moreDetails}
              name="Add More Details"
              isLoading={
                isLoading && !get(bridgeLoanProcess, 'documentsSigned')
              }
              onClick={
                get(bridgeLoanProcess, 'documentsSigned')
                  ? () => detailPage()
                  : handleSubmit(() => onSubmit(true))
              }
            />
          )}
        </Box>
      </form>
    </div>
  ) : (
    <AdditionalDetails
      id={id}
      startUpId={startUpId}
      enquiryId={enquiryId}
      setEnquiryId={setEnquiryId}
      previousPage={previousPage}
    />
  );
};

export default BasicDetails;
