import React from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
} from '@material-ui/core';
import { isEmpty, get } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import { Loader, Button, StyledTableCell, StyledTableRow } from 'components';
import { FN } from 'common/types';
import { formatAmount } from 'common/utils/helpers';

import styles from './styles';

type InstallmentsListProps = {
  isLoading: boolean;
  data: any;
  isRoleAdmin: boolean;
  paymentsType?: string;
  handleDialogOpen: FN;
};

const InstallmentsList = ({
  isLoading,
  data,
  isRoleAdmin,
  handleDialogOpen,
}: InstallmentsListProps) => {
  const classes = styles();

  const { errorMessage } = useSelector(({ Enquiries }: RootState) => Enquiries);

  return (
    <Box>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <StyledTableCell style={{ textAlign: 'left' }}>
                Transaction id
              </StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          {!errorMessage ? (
            <TableBody>
              {isLoading ? (
                <Grid item xs={6} justifyContent="center">
                  <Loader />
                </Grid>
              ) : (
                data.map((row: any) => (
                  <StyledTableRow hover key={row.id}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: 'left' }}
                    >
                      <span
                        style={{
                          color: row.transactionId ? 'green' : 'grey',
                        }}
                      >
                        {row.transactionId ? row.transactionId : 'Enter Id'}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.installmentDueDate
                        ? row.installmentDueDate.split('T')[0]
                        : ''}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatAmount(row.installmentAmount)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <span
                        style={{
                          color:
                            row.vdInstallmentStatus == 'PROCESSED'
                              ? get(row, 'payment.isVerifiedByAdmin')
                                ? 'green'
                                : 'red'
                              : 'grey',
                        }}
                      >
                        {row.vdInstallmentStatus == 'PROCESSED'
                          ? get(row, 'payment.isVerifiedByAdmin')
                            ? 'Verified'
                            : 'To Be Verified'
                          : 'Upcoming'}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span
                        style={{
                          color: row.remarks ? 'black' : 'grey',
                        }}
                      >
                        {row.remarks ? row.remarks : 'No Remarks'}
                      </span>
                    </StyledTableCell>
                    {isEmpty(row.paymentId) ? (
                      <StyledTableCell>
                        <Button
                          className={classes.logTableBtn}
                          name="Log Payment"
                          onClick={() =>
                            handleDialogOpen('', row.id, row.installmentAmount)
                          }
                        />
                      </StyledTableCell>
                    ) : isRoleAdmin &&
                      !get(row, 'payment.isVerifiedByAdmin') ? (
                      <StyledTableCell>
                        <Button
                          className={classes.logTableBtn}
                          name="Verify"
                          onClick={() =>
                            handleDialogOpen(
                              row.paymentId,
                              row.id,
                              row.installmentAmount,
                            )
                          }
                        />
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell>
                        <span style={{ color: 'green' }}>{'-'}</span>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          ) : (
            <h2>{errorMessage}</h2>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InstallmentsList;
