import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { Box, Button, WrappedTextInput, WrappedAutocomplete } from 'components';
import { InvestorOnboardStepProps } from 'common/types';
import { countryCodeList, errorMessageHandler } from 'common/utils/helpers';
import { getAdminKycSSN } from 'services';

import styles from './styles';

const EntityFundingForm = ({
  investor,
  kyc,
  setValue,
  control,
  loadingKyc,
  investmentProcess,
  setErrorMessage,
}: InvestorOnboardStepProps) => {
  const classes = styles();

  const [isEditModeSSN, setIsEditModeSSN] = React.useState<boolean>(true);
  const [showSSN, setShowSSN] = React.useState<boolean>(false);

  const handleClickEditSSN = () => {
    if (!isEmpty(get(kyc, 'id'))) {
      getAdminKycSSN(get(kyc, 'id'))
        .then((res: any) => {
          setValue('ssn', res);
          setIsEditModeSSN(!isEditModeSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  const handleMouseDownSSN = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCloseEditSSN = () => {
    setIsEditModeSSN(!isEditModeSSN);
    setValue('ssn', get(kyc, 'ssn'));
  };

  const handleClickShowSSN = () => {
    if (!showSSN) {
      getAdminKycSSN(get(kyc, 'id'))
        .then((res: any) => {
          setValue('ssn', res);
          setShowSSN(!showSSN);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    } else {
      setValue('ssn', get(kyc, 'ssn'));
      setShowSSN(!showSSN);
    }
  };

  useEffect(() => {
    if (get(kyc, 'id')) {
      const phoneSplit = get(kyc, 'phone')?.split('#') || [];
      setValue(
        'fundingEntityLegalName',
        get(kyc, 'fundingEntityLegalName', ''),
      );

      setValue(
        'countryCode',
        (phoneSplit.length === 2 && phoneSplit[0]) || '+1',
      );
      setValue(
        'phone',
        (phoneSplit.length === 2 ? phoneSplit[1] : phoneSplit[0]) || '',
      );
      setValue('signatoryLegalName', get(kyc, 'signatoryLegalName', ''));
      setValue('email', get(kyc, 'email'));
      setValue('ssn', get(kyc, 'ssn'));
      setValue('address1', get(kyc, 'address1'));
      setValue('zip', get(kyc, 'zip'));
      if (!isEmpty(get(kyc, 'ssn'))) setIsEditModeSSN(false);
    } else {
      setValue('email', get(investor, 'email'));
      setValue('signatoryLegalName', get(investor, 'name'));
      setValue('dob', '1990-01-01');
      setValue('countryCode', '+1');
    }
  }, [kyc]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography className={classes.labelText} variant="body1">
            The legal name of the funding entity
          </Typography>
          <Controller
            name="fundingEntityLegalName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className={classes.controllerBox}>
                <WrappedTextInput
                  type="text"
                  placeholder="Name"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={(e: any) => onChange(e.target.value.trimStart())}
                  error={!!error?.message}
                  disabled={get(investmentProcess, 'docuSignDocumentSigned')}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography className={classes.labelText} variant="body1">
            What is the signatory's legal name?
          </Typography>
          <Controller
            name="signatoryLegalName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className={classes.controllerBox}>
                <WrappedTextInput
                  type="text"
                  placeholder="Name"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={(e: any) => onChange(e.target.value.trimStart())}
                  error={!!error?.message}
                  disabled={get(investmentProcess, 'docuSignDocumentSigned')}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography className={classes.labelText} variant="body1">
            Phone
          </Typography>
          <Box className={classes.selectedOpt}>
            <Box className={classes.selectedOptL}>
              <Controller
                name="countryCode"
                control={control}
                defaultValue="+1"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box className={classes.controllerBox}>
                    <WrappedAutocomplete
                      id="field1"
                      disableClearable
                      options={countryCodeList}
                      value={value}
                      getOptionSelected={(option: any, value: any) =>
                        option === value
                      }
                      onChange={(_: any, value: any) => {
                        onChange(value);
                      }}
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={!!error?.message}
                      disabled={get(
                        investmentProcess,
                        'docuSignDocumentSigned',
                      )}
                      // getOptionLabel={undefined}
                    />
                    {error?.message && (
                      <span className={classes.errorTextCountry}>
                        {error?.message}
                      </span>
                    )}
                  </Box>
                )}
              />
            </Box>
            <Box className={classes.selectedOptR}>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box className={classes.controllerBox}>
                    <WrappedTextInput
                      type="text"
                      placeholder="Phone"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(e.target.value.trimStart())
                      }
                      error={!!error?.message}
                      disabled={get(
                        investmentProcess,
                        'docuSignDocumentSigned',
                      )}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </Box>
                )}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography className={classes.labelText} variant="body1">
            Email ID
          </Typography>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className={classes.controllerBox}>
                <WrappedTextInput
                  type="email"
                  placeholder="Email"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={(e: any) => onChange(e.target.value.trimStart())}
                  error={!!error?.message}
                  disabled={get(investmentProcess, 'docuSignDocumentSigned')}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography className={classes.labelText} variant="body1">
            EIN of Legal entity
          </Typography>
          <Controller
            name="ssn"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className={classes.controllerBox}>
                <WrappedTextInput
                  type="text"
                  placeholder="EIN of Legal entity"
                  className={classes.textValInput}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                    endAdornment: (
                      <>
                        {!isEditModeSSN &&
                          get(kyc, 'ssn') &&
                          !get(investmentProcess, 'docuSignDocumentSigned') && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="edit"
                                onClick={handleClickEditSSN}
                                onMouseDown={handleMouseDownSSN}
                                edge="end"
                              >
                                <EditIcon />
                              </IconButton>
                            </InputAdornment>
                          )}
                        {isEditModeSSN &&
                          get(kyc, 'ssn') &&
                          !get(investmentProcess, 'docuSignDocumentSigned') && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="close"
                                onClick={handleCloseEditSSN}
                                onMouseDown={handleMouseDownSSN}
                                edge="end"
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          )}
                        {get(kyc, 'ssn') && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle visibility"
                              onClick={handleClickShowSSN}
                              onMouseDown={handleMouseDownSSN}
                              edge="end"
                              disabled={isEditModeSSN}
                            >
                              {showSSN ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={(e: any) => onChange(e.target.value.trimStart())}
                  error={!!error?.message}
                  disabled={
                    !isEditModeSSN ||
                    get(investmentProcess, 'docuSignDocumentSigned')
                  }
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography className={classes.labelText} variant="body1">
            Street Address
          </Typography>
          <Controller
            name="address1"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className={classes.controllerBox}>
                <WrappedTextInput
                  type="text"
                  placeholder="Street Address"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={(e: any) => onChange(e.target.value.trimStart())}
                  error={!!error?.message}
                  disabled={get(investmentProcess, 'docuSignDocumentSigned')}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography className={classes.labelText} variant="body1">
            Zip
          </Typography>
          <Controller
            name="zip"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className={classes.controllerBox}>
                <WrappedTextInput
                  type="text"
                  placeholder="Zip"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={(e: any) => onChange(e.target.value.trimStart())}
                  error={!!error?.message}
                  disabled={get(investmentProcess, 'docuSignDocumentSigned')}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </Box>
            )}
          />
        </Grid>
      </Grid>

      <Box className={classes.nextPrevButtonBox}>
        {/* <Button
            variant="outlined"
            className={classes.prevButton}
            name="Previous"
            onClick={() => handleBack()}
          /> */}
        <Button
          type="submit"
          className={classes.nextButton}
          name="Next"
          isLoading={loadingKyc}
          disabled={loadingKyc}
        />
      </Box>
    </Box>
  );
};
export default EntityFundingForm;
