import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import {
  Box,
  Button,
  WrappedAutocomplete,
  WrappedSelect,
  WrappedTextInput,
  Loader,
} from 'components';
import { getBatches } from 'redux-modules/auth/Actions';
import {
  handleGetStartupDetailsById,
  emptyEnquiryForm,
  getFounderDetails,
  getStartupData,
} from 'redux-modules/enquiryForm/Actions';
import {
  createOrUpdateStartupInformation,
  getStartupsBySearchString,
} from 'services';
import {
  errorMessageHandler,
  businessOpsCountryList,
  getIncorpCountryObject,
  getBusinessOpsCountryObject,
  incorporationCountryList,
  getAllStatesOfCountry,
  getIncorporationCountryObject,
  getStateObject,
  getIndustryTypeObject,
} from 'common/utils/helpers';
import {
  stageList,
  businessModalList,
  incorpCountryList,
  industryTypeList,
  acceleratorList,
} from 'common/utils/optionList';
import { FN } from 'common/types';
import history from 'common/utils/history';

import {
  BenchMarkSchema,
  BenchMarkSchemaAdditionalDetail,
  BenchMarkSchemaExistingStartup,
} from './validation';
import styles from './styles';

type Props = {
  role: string;
  existingStartup: boolean;
  selectedStartupId: any;
  isLoading: boolean;
  setIsLoading: FN;
  errorMessage: string;
  setErrorMessage: FN;
  setFormErrors?: any;
  successAction?: FN;
};

const StartupInformationForm = ({
  role,
  existingStartup,
  selectedStartupId,
  isLoading,
  setIsLoading,
  errorMessage,
  setErrorMessage,
  setFormErrors,
  successAction,
}: Props) => {
  const dispatch = useDispatch();
  const classes = styles();

  const [
    autoCompleteFullName,
    setAutoCompleteFullName,
  ] = React.useState<boolean>(false);
  const [addMoreDetailPage, setAddMoreDetailPage] = React.useState<boolean>(
    false,
  );

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    formState,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      !addMoreDetailPage
        ? autoCompleteFullName
          ? BenchMarkSchemaExistingStartup
          : BenchMarkSchema
        : BenchMarkSchemaAdditionalDetail,
    ),
  });

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'founderDetails',
  });
  if (!fields.length) {
    prepend({ founderName: '', founderEmail: '' });
  }

  const {
    id,
    startupDetails,
    defaultFounderDetails,
    potentialMarketTarget,
    productDescription,
    productProgress,
    startupLegalInformation,
    startupInvestmentInformation,
  } = useSelector(({ EnquiryForm }: RootState) => EnquiryForm);
  const { ycBatches } = useSelector(({ Auth }: RootState) => Auth);

  const {
    businessModel,
    businessOpsLocation,
    fundingStage,
    industryType,
    startupFacebookUrl,
    startupName,
    startupFullName,
    startupWebLink,
    startupLinkedInUrl,
    startupTwitterUrl,
    ycBatch,
    accelerator,
    startupTaxIdNumber,
    ycIncorporationCountry,
    stateOfIncorporation,
  } = startupDetails;

  const [showYcCountryList, setShowYcCountryList] = React.useState<boolean>(
    false,
  );
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(
    ycIncorporationCountry,
  );
  const [allStartupsData, setAllStartupsData] = React.useState([]);
  const [startupId, setStartupId] = React.useState<string>('');
  const [isSearchLoading, setIsSearchLoading] = React.useState<boolean>(false);
  const [searchNotFoundText, setSearchNotFoundText] = React.useState<string>(
    '',
  );

  const onSubmit = (navigationToAddMore = false) => {
    const data: any = { ...getValues() };
    setIsLoading(true);
    setErrorMessage('');
    const startupFormDetails = {
      ...data,
      startupId: get(startupDetails, 'startupId') || startupId,
    };
    const obj = {
      id: id,
      isExistingStartup: existingStartup,
      productDescription: get(startupFormDetails, 'productDescription'),
      productProgress: get(startupFormDetails, 'productProgress'),
      potentialMarketTarget: get(startupFormDetails, 'potentialMarketTarget'),
      startupInvestmentInformation: get(
        startupFormDetails,
        'startupInvestmentInformation',
      ),
      startupLegalInformation: get(
        startupFormDetails,
        'startupLegalInformation',
      ),
      startupDetails: {
        startupFullName: autoCompleteFullName
          ? get(startupFormDetails, 'startupFullName.text')
          : get(startupFormDetails, 'startupFullName'),
        startupId: get(startupFormDetails, 'startupId'),
        startupTaxIdNumber: get(startupFormDetails, 'startupTaxIdNumber'),
        ycIncorporationCountry: get(
          startupFormDetails,
          'ycIncorporationCountry.countryCode',
        ),
        stateOfIncorporation: get(
          startupFormDetails,
          'stateOfIncorporation.isoCode',
        ),
        businessOpsLocation: get(
          startupFormDetails,
          'businessOpsLocation.countryCode',
        ),
        industryType: get(startupFormDetails, 'industryType.value'),
        businessModel: get(startupFormDetails, 'businessModel'),
        fundingStage: get(startupFormDetails, 'fundingStage'),
        ycBatch:
          get(startupFormDetails, 'accelerator') === 'YC'
            ? get(startupFormDetails, 'ycBatch')
            : null,
        accelerator:
          get(startupFormDetails, 'accelerator') === 'OTHER'
            ? get(startupFormDetails, 'acceleratorName')
            : get(startupFormDetails, 'accelerator'),
        startupWebLink: get(startupFormDetails, 'startupWebLink'),
        startupTwitterUrl: get(startupFormDetails, 'startupTwitterUrl'),
        startupFacebookUrl: get(startupFormDetails, 'startupFacebookUrl'),
        startupLinkedInUrl: get(startupFormDetails, 'startupLinkedInUrl'),
      },
      founderDetails: addMoreDetailPage
        ? get(startupFormDetails, 'founderDetails')
        : [],
    };
    createOrUpdateStartupInformation(obj, role)
      .then((res: any) => {
        setIsLoading(false);
        dispatch(getStartupData(res.startupDetails.id));
        dispatch(getFounderDetails(res.startupDetails.id));
        addMoreDetailPage && setAddMoreDetailPage(false);
        if (navigationToAddMore) {
          setAddMoreDetailPage(true);
        } else {
          setTimeout(() => {
            role === 'ADMIN'
              ? history.push(
                  `/benchmark-kpi/${
                    get(res, 'startupDetails.id') || startupId
                  }`,
                )
              : successAction();
          }, 500);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const resetValue = () => {
    setValue(
      'benchmarkStartup',
      get(startupDetails, 'benchmarkStartup')
        ? get(startupDetails, 'benchmarkStartup')
        : !existingStartup
        ? true
        : false,
    );
    if (
      !isEmpty(accelerator) &&
      accelerator !== 'YC' &&
      accelerator !== 'NONE'
    ) {
      setValue('accelerator', 'OTHER');
      setValue('acceleratorName', accelerator || '');
    } else if (
      !isEmpty(accelerator) &&
      (accelerator === 'NONE' || accelerator === 'YC')
    ) {
      setValue('accelerator', accelerator);
    } else {
      setValue('accelerator', accelerator || '');
    }
    if (!isEmpty(get(startupDetails, 'startupId'))) {
      setValue(
        'startupFullName',
        !autoCompleteFullName
          ? startupFullName || startupName
          : {
              text: startupFullName || startupName,
              value: get(startupDetails, 'startupId'),
            },
      );
      setValue(
        'ycIncorporationCountry',
        showYcCountryList
          ? getIncorporationCountryObject(ycIncorporationCountry)
          : getIncorpCountryObject(ycIncorporationCountry),
      );
      setValue(
        'stateOfIncorporation',
        getStateObject(stateOfIncorporation, ycIncorporationCountry),
      );
      setValue('startupTaxIdNumber', startupTaxIdNumber);
      setValue(
        'businessOpsLocation',
        getBusinessOpsCountryObject(businessOpsLocation),
      );
      setValue('industryType', getIndustryTypeObject(industryType));
      setValue('businessModel', businessModel || '');
      setValue('ycBatch', ycBatch || '');
      setValue('fundingStage', fundingStage || '');
      setValue('startupWebLink', startupWebLink || '');
      setValue('startupTwitterUrl', startupTwitterUrl || '');
      setValue('startupLinkedInUrl', startupLinkedInUrl || '');
      setValue('startupFacebookUrl', startupFacebookUrl || '');
      setValue('potentialMarketTarget', potentialMarketTarget || '');
      setValue('productDescription', productDescription || '');
      setValue('productProgress', productProgress || '');
      setValue('startupLegalInformation', startupLegalInformation || '');
      setValue(
        'startupInvestmentInformation',
        startupInvestmentInformation || '',
      );
      if (defaultFounderDetails && defaultFounderDetails.length) {
        setValue('founderDetails', defaultFounderDetails);
        defaultFounderDetails.map((item, index) => {
          setValue(`founderDetails[${index}].founderName`, item.founderName);
          setValue(`founderDetails[${index}].founderEmail`, item.founderEmail);
        });
      }
    } else if (!isEmpty(ycBatch)) {
      setValue('ycBatch', ycBatch);
    } else {
      // Reset value to the empty
      setValue('startupFullName', '');
      setValue('ycIncorporationCountry', '');
      setValue('stateOfIncorporation', '');
      setValue('startupTaxIdNumber', '');
      setValue('businessOpsLocation', '');
      setValue('industryType', '');
      setValue('businessModel', '');
      setValue('ycBatch', '');
      setValue('fundingStage', '');
      setValue('startupWebLink', '');
      setValue('startupTwitterUrl', '');
      setValue('startupLinkedInUrl', '');
      setValue('startupFacebookUrl', '');
      setValue('potentialMarketTarget', '');
      setValue('productDescription', '');
      setValue('productProgress', '');
      setValue('startupLegalInformation', '');
      setValue('startupInvestmentInformation', '');
      setValue('founderDetails', []);
    }
    clearErrors();
  };

  const getALLStartupsData = (payload: any) => {
    setSearchNotFoundText('');
    setIsSearchLoading(true);
    getStartupsBySearchString(payload)
      .then((res: any) => {
        const startupData = res.map((startup: any) => {
          return {
            text:
              get(startup, 'startupFullName') || get(startup, 'startupName'),
            value: get(startup, 'startupId'),
          };
        });
        setAllStartupsData(startupData);
        setIsSearchLoading(false);
        if (isEmpty(startupData)) setSearchNotFoundText('Startup not found');
      })
      .catch((err: any) => {
        setIsSearchLoading(false);
        setSearchNotFoundText('Startup not found');
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.log('ERROR GET STARTUPS DATA: ', message);
      });
  };

  useEffect(() => {
    setStartupId('');
    dispatch(emptyEnquiryForm());
    setAllStartupsData([]);
    if (ycBatches.length === 0) {
      dispatch(getBatches());
    }
  }, []);

  useEffect(() => {
    if (existingStartup && get(startupDetails, 'benchmarkStartup')) {
      setShowYcCountryList(false);
    } else if (existingStartup && get(startupDetails, 'accelerator') === 'YC') {
      setShowYcCountryList(true);
    } else {
      setShowYcCountryList(false);
    }
  }, [existingStartup, startupDetails]);

  useEffect(() => {
    setStartupId('');
    setAllStartupsData([]);
    dispatch(emptyEnquiryForm());
    setSearchNotFoundText('');
    addMoreDetailPage && setAddMoreDetailPage(false);
    clearErrors();
    if (role === 'ADMIN' && isEmpty(selectedStartupId) && existingStartup) {
      setAutoCompleteFullName(true);
    } else {
      setAutoCompleteFullName(false);
    }
    if (!isEmpty(selectedStartupId)) {
      setStartupId(selectedStartupId);
    }
  }, [role, selectedStartupId, existingStartup]);

  useEffect(() => {
    if (startupId) {
      dispatch(getStartupData(startupId));
      dispatch(getFounderDetails(startupId));
    }
    if (startupId && isEmpty(ycBatch)) {
      dispatch(handleGetStartupDetailsById(startupId));
    }
  }, [startupId]);

  useEffect(() => {
    if (isEmpty(selectedCountryCode) && !isEmpty(ycIncorporationCountry))
      setSelectedCountryCode(ycIncorporationCountry);
  }, [ycIncorporationCountry]);

  useEffect(() => {
    resetValue();
  }, [startupDetails]);

  useEffect(() => {
    if (defaultFounderDetails && defaultFounderDetails.length) {
      // reset({ founderDetails: defaultFounderDetails });
      setValue('founderDetails', defaultFounderDetails);
      defaultFounderDetails.map((item, index) => {
        setValue(`founderDetails[${index}].founderName`, item.founderName);
        setValue(`founderDetails[${index}].founderEmail`, item.founderEmail);
      });
    }
  }, [defaultFounderDetails]);

  useEffect(() => {
    const { errors } = formState;
    if (setFormErrors) {
      setFormErrors(errors);
    }
  }, [formState]);

  return (
    <>
      <form
        onSubmit={handleSubmit(() => onSubmit(false))}
        data-testid="companyDetailsForm"
        className={classes.validateForm}
      >
        <Box
          className={
            role === 'ADMIN'
              ? addMoreDetailPage
                ? `${classes.startupFormFirstContainer} ${classes.displayNone}`
                : `${classes.startupFormFirstContainer} ${classes.displayBlock}`
              : addMoreDetailPage
              ? `${classes.startupFormFirstContainerStartup} ${classes.displayNone}`
              : `${classes.startupFormFirstContainerStartup} ${classes.displayBlock}`
          }
        >
          <Typography
            variant="body1"
            gutterBottom
            className={classes.errorText}
          >
            {errorMessage}
          </Typography>
          <Typography
            variant="body1"
            className={classes.headingText}
            id="benchmark-basic-detail"
          >
            Basic Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.inputHeadFull}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Full legal name of your company*
              </Typography>
              {autoCompleteFullName ? (
                <Controller
                  name="startupFullName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedAutocomplete
                        id="startupFullName"
                        disableClearable
                        loading={isSearchLoading}
                        loadingText={<Loader />}
                        options={allStartupsData}
                        value={value}
                        getOptionLabel={(option: any) => option.text || ''}
                        getOptionSelected={(option: any, value: any) => {
                          option.text === value?.text;
                        }}
                        onChange={(_: any, value: any) => {
                          dispatch(emptyEnquiryForm());
                          setStartupId(value.value);
                          onChange(value);
                        }}
                        onTextChange={(event: any) => {
                          if (
                            event.target.value &&
                            event.target.value.length >= 3
                          ) {
                            setTimeout(() => {
                              getALLStartupsData(event.target.value);
                            }, 100);
                          }
                        }}
                        className={classes.textValInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                        noOptionsText={searchNotFoundText}
                        // disabled={!isEmpty(startupFullName)}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              ) : (
                <Controller
                  name="startupFullName"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Enter Company Name"
                        className={classes.textValInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled={!isEmpty(startupFullName)}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Company full name is required' }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Country of Incorporation*
              </Typography>
              <Controller
                name="ycIncorporationCountry"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedAutocomplete
                      id="field1"
                      disableClearable
                      // options={incorporationCountryList}
                      options={
                        showYcCountryList
                          ? incorporationCountryList
                          : incorpCountryList
                      }
                      // value={value}
                      value={
                        value ||
                        (showYcCountryList
                          ? getIncorporationCountryObject(
                              ycIncorporationCountry,
                            )
                          : getIncorpCountryObject(ycIncorporationCountry))
                      }
                      getOptionLabel={(option: any) => option.country || ''}
                      getOptionSelected={(option: any, value: any) =>
                        option.countryCode === value?.countryCode
                      }
                      onChange={(_: any, value: any) => {
                        setSelectedCountryCode(value?.countryCode);
                        setValue('stateOfIncorporation', null);
                        onChange(value);
                      }}
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                      disabled={!isEmpty(ycIncorporationCountry)}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            {showYcCountryList && (
              <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  State of Incorporation of the above legal entity*
                </Typography>
                <Controller
                  name="stateOfIncorporation"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedAutocomplete
                        id="field2"
                        placeholder="State of Incorporation"
                        disableClearable
                        options={getAllStatesOfCountry(
                          selectedCountryCode || '',
                        )}
                        // value={value}
                        value={
                          value ||
                          (selectedCountryCode !== 'PENDING_INCORPORATION' &&
                          selectedCountryCode == ycIncorporationCountry
                            ? getStateObject(
                                stateOfIncorporation,
                                ycIncorporationCountry,
                              )
                            : null)
                        }
                        getOptionLabel={(option: any) => option.name || ''}
                        getOptionSelected={(option: any, value: any) =>
                          option.isoCode === value?.isoCode
                        }
                        onChange={(_: any, value: any) => {
                          onChange(value);
                        }}
                        disabled={
                          !selectedCountryCode ||
                          selectedCountryCode === 'PENDING_INCORPORATION' ||
                          !isEmpty(stateOfIncorporation)
                        }
                        className={classes.textValInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Country of your business operation*
              </Typography>
              <Controller
                name="businessOpsLocation"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedAutocomplete
                      id="field-business-op-location"
                      placeholder="Choose Country"
                      disableClearable
                      options={businessOpsCountryList}
                      value={
                        value ||
                        getBusinessOpsCountryObject(businessOpsLocation)
                      }
                      getOptionLabel={(option: any) => option.country || ''}
                      getOptionSelected={(option: any, value: any) =>
                        option.countryCode === value?.countryCode
                      }
                      onChange={(_: any, value: any) => {
                        onChange(value);
                      }}
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                      disabled={!isEmpty(businessOpsLocation)}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Sector/Industry*
              </Typography>
              <Controller
                name="industryType"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedAutocomplete
                      id="industry-select"
                      placeholder="Select Industry"
                      disableClearable
                      options={industryTypeList}
                      // value={value}
                      value={value || getIndustryTypeObject(industryType)}
                      getOptionLabel={(option: any) => option.text || ''}
                      getOptionSelected={(option: any, value: any) =>
                        option.value === value?.value
                      }
                      onChange={(_: any, value: any) => {
                        onChange(value);
                      }}
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                      disabled={!isEmpty(industryType)}
                    />

                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Business Model*
              </Typography>
              <Controller
                name="businessModel"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedSelect
                      value={value}
                      onChange={onChange}
                      placeholder="Select Business Model"
                      className={classes.selectValInput}
                      InputProps={{
                        name: 'Business Model',
                        id: 'business-model',
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                      dropdowns={businessModalList}
                      disabled={!isEmpty(businessModel)}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Funding stage*
              </Typography>
              <Controller
                name="fundingStage"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedSelect
                      value={value}
                      onChange={onChange}
                      placeholder="Select Funding stage"
                      className={classes.selectValInput}
                      InputProps={{
                        name: 'Funding stage',
                        id: 'funding-stage',
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                      dropdowns={stageList}
                      disabled={!isEmpty(fundingStage)}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Accelerator*
              </Typography>
              <Controller
                name="accelerator"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedSelect
                      value={value}
                      onChange={onChange}
                      placeholder="Accelerator"
                      className={classes.selectValInput}
                      InputProps={{ name: 'Accelerator', id: 'age-simple' }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={!!error?.message}
                      dropdowns={acceleratorList}
                      disabled={!isEmpty(accelerator)}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              {watch('accelerator') === 'YC' && (
                <Box>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    YC Batch*
                  </Typography>
                  <Controller
                    name="ycBatch"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedSelect
                          value={value}
                          onChange={onChange}
                          placeholder="YC Batch"
                          className={classes.selectValInput}
                          InputProps={{
                            name: 'Yc Batch',
                            id: 'yc-batch',
                          }}
                          SelectProps={{
                            MenuProps: {
                              classes: { paper: classes.menuPaper },
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          error={error?.message ? true : false}
                          dropdowns={ycBatches}
                          disabled={!isEmpty(startupId)}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Box>
              )}
              {watch('accelerator') === 'OTHER' && (
                <Box>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Accelerator Name*
                  </Typography>
                  <Controller
                    name="acceleratorName"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedTextInput
                          type="text"
                          label=""
                          placeholder="Accelerator Name"
                          className={classes.textValInput}
                          InputProps={{
                            classes: { root: classes.inputRoot },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          value={value}
                          onChange={onChange}
                          disabled={!isEmpty(startupId)}
                          error={!!error?.message}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              className={classes.continueBtn}
              name={`Submit & Continue`}
              isLoading={isLoading}
              disabled={isLoading}
            />
            {!isEmpty(startupId) && (
              <Button
                variant="outlined"
                className={classes.resetBtn}
                name="Add More Details"
                disabled={isLoading}
                onClick={handleSubmit(() => onSubmit(true))}
              />
            )}
          </Box>
        </Box>
        <Box
          className={
            !addMoreDetailPage
              ? `${classes.startupFormSecondContainer} ${classes.displayNone}`
              : `${classes.startupFormSecondContainer} ${classes.displayBlock}`
          }
        >
          <Typography
            variant="body1"
            gutterBottom
            className={classes.errorText}
          >
            {errorMessage}
          </Typography>
          <Typography
            variant="h6"
            className={classes.headingText}
            id="benchmark-additional-detail"
          >
            Additional details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.companyLabelText}>
                Founder Details
              </Typography>
            </Grid>
          </Grid>
          {fields.map((item, index) => (
            <div key={index}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Founder {index + 1}{' '}
                    {fields.length > 1 && index !== 0 && (
                      <Typography
                        variant="caption"
                        className={classes.removeText}
                        onClick={() => remove(index)}
                      >
                        (Remove)
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                  <Controller
                    name={`founderDetails[${index}].founderName` as const}
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedTextInput
                          type="text"
                          label=""
                          placeholder="Name"
                          className={classes.textValInput}
                          InputProps={{
                            classes: { root: classes.inputRoot },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          value={value}
                          onChange={onChange}
                          error={error?.message ? true : false}
                          disabled={
                            !isEmpty(startupId) &&
                            !isEmpty(defaultFounderDetails) &&
                            !index
                          }
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                    // rules={{ required: 'Name is required' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                  <Controller
                    name={`founderDetails[${index}].founderEmail` as const}
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedTextInput
                          type="text"
                          label=""
                          placeholder="Email Address"
                          className={classes.textValInput}
                          InputProps={{
                            classes: { root: classes.inputRoot },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          value={value}
                          onChange={onChange}
                          error={error?.message ? true : false}
                          disabled={
                            !isEmpty(startupId) &&
                            !isEmpty(defaultFounderDetails) &&
                            !index
                          }
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                    // rules={{ required: 'Email is required' }}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
          <Button
            className={classes.addFound}
            variant="outlined"
            name="Add Founder"
            onClick={() =>
              append({
                founderName: '',
                founderEmail: '',
              })
            }
          />

          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Your company website
              </Typography>
              <Controller
                name="startupWebLink"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Enter company website"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Tax identification number of the legal entity
              </Typography>
              <Controller
                name="startupTaxIdNumber"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Tax identification number of the legal entity"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      // disabled={!isEmpty(startupTaxIdNumber)}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Facebook URL
              </Typography>
              <Controller
                name="startupFacebookUrl"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Enter Facebook URL"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Facebook URL is required' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                LinkedIn URL
              </Typography>
              <Controller
                name="startupLinkedInUrl"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Enter LinkedIn URL"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'LinkedIn URL is required' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Twitter URL
              </Typography>
              <Controller
                name="startupTwitterUrl"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Enter Twitter URL"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Twitter URL is required' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Describe your Company Product/Services.
              </Typography>
              <Controller
                name="productDescription"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Describe your Company Product/Services"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Company Product name is required' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Describe the status of the business.
              </Typography>
              <Controller
                name="productProgress"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Describe the status of the business"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Business status is required' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Describe your potential market.
              </Typography>
              <Controller
                name="potentialMarketTarget"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Describe your potential market"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'name is required' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Please provide relevant investor and amount details.
              </Typography>
              <Controller
                name="startupInvestmentInformation"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Provide relevant investor and amount details"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Amount details is required' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Is there anything else we should know about your company(legal
                or otherwise)?
              </Typography>
              <Controller
                name="startupLegalInformation"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      label=""
                      placeholder="Describe about your company"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Company legal is required' }}
              />
            </Grid>
          </Grid>
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              className={classes.continueBtn}
              name={`Save`}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              variant="outlined"
              className={classes.resetBtn}
              name="Back"
              disabled={isLoading}
              onClick={() => {
                resetValue();
                setAddMoreDetailPage(false);
              }}
            />
          </Box>
        </Box>
      </form>
    </>
  );
};

export default StartupInformationForm;
