import { get, isEmpty, compact } from 'lodash';
import {
  getStateByCodeAndCountry,
  getStatesOfCountry,
} from 'country-state-city/dist/lib/state';
import { Country } from 'country-state-city';
import moment, { Moment } from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';

import { User } from 'redux-modules/auth/Types';
import history from 'common/utils/history';

import {
  incorpCountryList,
  businessOpsCountryList,
  incorporationCountryList,
  sectorList,
  industryTypeList,
  industryClassificationList,
  vcPortalCountryList,
  countryOfOperationList,
} from './optionList';

const validateUser = ({ role }: User) => {
  const search = history.location.search;
  const redirectUrl: any = new URLSearchParams(search).get('redirecturl');
  if (redirectUrl) {
    return redirectUrl;
  }
  const isRoleAdmin = role === 'ADMIN';
  return isRoleAdmin ? '/dashboard' : '/dashboard';
};

// const validateUser = ({ role }: helperProps) => {
//   const isRoleAdmin = role === 'ADMIN';
//   return isRoleAdmin ? '/dashboard' : '/startup/navigate';
// };

const constructArray = (payload: any = [], type: string) => {
  if (type !== 'YC_BATCH') {
    const response = payload.map((i: any) => {
      return {
        text:
          i.ycBatchName ||
          i.location ||
          i.industryType ||
          i.country ||
          i.state ||
          i.ycLocation,
        value:
          i.ycBatchName ||
          i.location ||
          i.industryType ||
          i.country ||
          i.state ||
          i.ycLocation,
      };
    });
    return response;
  } else {
    const response = payload.map((i: any) => {
      return {
        text: i.ycBatchName,
        value: i.ycBatchName,
        currentYcBatch: i.currentYcBatch,
      };
    });
    return response;
  }
};

const messageHelper = (message: string, constant: string, include: string) =>
  message.includes(include) ? constant : message;

const errorMessageHandler = (err: any) => {
  // later sentry integration purposes
  return get(err, 'response.data.message') || get(err, 'message');
};

const removeDuplicates = (payload: any, prop: string) => {
  const newArray = [];
  const lookupObject: any = {};

  for (const i in payload) {
    if (payload[i] && payload[i][prop]) {
      lookupObject[payload[i][prop]] = payload[i];
    }
  }

  for (const i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

const formatAmount = (amount: number, options?: any) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    ...options,
  });
  return formatter.format(amount);
};

const getAllCountryList = () => {
  const listOfCountries = Country.getAllCountries();
  return listOfCountries.map((country: any) => {
    return {
      country: get(country, 'name'),
      countryCode: get(country, 'isoCode'),
    };
  });
};

const getAllStatesOfCountry = (countryCode: string) => {
  const stateList =
    countryCode === 'KY'
      ? [
          {
            name: 'NA',
            isoCode: 'NA',
            countryCode: 'KY',
          },
        ]
      : countryCode !== 'PENDING_INCORPORATION'
      ? getStatesOfCountry(countryCode)
      : [];
  return stateList;
};

const getCountryObject = (countryCode: string) => {
  if (!isEmpty(countryCode)) {
    const countryObj = Country.getCountryByCode(countryCode);
    return {
      country: get(countryObj, 'name'),
      countryCode: get(countryObj, 'isoCode'),
    };
  } else return null;
};

const getBusinessOpsCountryObject = (countryCode: string) => {
  if (!isEmpty(countryCode))
    return businessOpsCountryList.find(
      (object) => object.countryCode === countryCode,
    );
  else return null;
};

const getCountryOfOperationsObject = (countryCode: string) => {
  if (!isEmpty(countryCode))
    return countryOfOperationList.find(
      (object) => object.countryCode === countryCode,
    );
  else return null;
};

const getStateObject = (stateCode: string, countryCode: string) => {
  if (!isEmpty(countryCode) && !isEmpty(stateCode) && countryCode === 'KY') {
    return {
      name: 'NA',
      isoCode: 'NA',
      countryCode: 'KY',
    };
  } else if (
    !isEmpty(countryCode) &&
    !isEmpty(stateCode) &&
    countryCode !== 'PENDING_INCORPORATION'
  )
    return getStateByCodeAndCountry(stateCode, countryCode);
  else return null;
};

const getIncorpCountryObject = (countryCode: string) => {
  if (!isEmpty(countryCode))
    return incorpCountryList.find(
      (object) => object.countryCode === countryCode,
    );
  else return null;
};

const getIncorporationCountryObject = (countryCode: string) => {
  if (!isEmpty(countryCode))
    return incorporationCountryList.find(
      (object) => object.countryCode === countryCode,
    );
  else return null;
};

const getSectorObject = (value: string | undefined) => {
  if (value && !isEmpty(value))
    return sectorList.find((object) => object.value === value);
  else return null;
};

const getIndustryTypeObject = (value: string | undefined) => {
  if (value && !isEmpty(value))
    return industryTypeList.find((object) => object.value === value);
  else return null;
};

const getIndustryClassificationList = (industryType: string | undefined) => {
  if (industryType && !isEmpty(industryType))
    return get(
      industryClassificationList.find(
        (object) => object.industryType === industryType,
      ),
      'industrySubCategory',
      [],
    );
  else return [];
};

const getIndustryClassificationObject = (
  industryType: string | undefined,
  value: string | undefined,
) => {
  if (industryType && !isEmpty(industryType) && value && !isEmpty(value)) {
    const classificationList = getIndustryClassificationList(industryType);
    return classificationList.find((object) => object.value === value);
  } else return null;
};

const getLoanCloserStatus = (status: string) => {
  const statuses = [
    { text: 'Open', value: 'OPEN' },
    { text: 'Close', value: 'CLOSED' },
    {
      text: 'Extension',
      value: 'EXTENSION',
      disabled: ['CONVERSION', 'CONVERTED'].includes(status),
    },
    { text: 'Conversion', value: 'CONVERSION' },
  ];
  if (['EXTENDED', 'CONVERTED', 'CLOSED'].includes(status)) {
    return [
      ...statuses,
      ...[
        { text: 'Closed', value: 'CLOSED' },
        { text: 'Extended', value: 'EXTENDED' },
        { text: 'Converted', value: 'CONVERTED' },
      ],
    ];
  }
  return statuses;
};

//Used for sorting in table column
const descendingComparator = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

//Used for sorting in table column
const getComparator = (order: string, orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

//Used for sorting in table column
const stableSort = (array: any, comparator: any) => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
};

const getVcPortalCountryObject = (countryCode: string) => {
  if (!isEmpty(countryCode))
    return vcPortalCountryList.find(
      (object) => object.countryCode === countryCode,
    );
  else return null;
};

const getPaymentStatus = (
  startingDate: any,
  subscriptionFreeTrialDays: number,
  active: boolean,
  amountPaidDecimal: number,
  status: string,
  isYardstickUser: boolean,
  startedPaidSubscription?: boolean,
  isJoiningPlan?: boolean,
): any => {
  if (active) {
    const endDate: Moment = moment(startingDate).add(
      subscriptionFreeTrialDays,
      'days',
    );
    if (!startedPaidSubscription && moment().isSameOrBefore(moment(endDate))) {
      return { text: 'Free Trial', value: 'FREE_TRIAL' };
    } else {
      if (amountPaidDecimal > 0 && status === 'PAID')
        return isYardstickUser
          ? { text: 'Paid Plan', value: 'PAID' }
          : { text: 'Paid', value: 'PAID' };
      else if (isJoiningPlan) return { text: '-', value: 'EMPTY' };
      return { text: 'Payment due', value: 'PAYMENT_DUE' };
    }
  } else {
    return { text: 'Deactivated', value: 'DEACTIVATED' };
  }
};

const getPaymentStatusYardstickUser = (
  usedFreeOutput: number,
  status: string,
  isActive: boolean,
) => {
  if (isActive) {
    if (status !== 'FAILED') {
      if (usedFreeOutput) {
        return { text: 'Paid Plan', value: 'PAID' };
      } else {
        return { text: 'Free Trial', value: 'FREE_TRIAL' };
      }
    } else {
      return { text: 'Payment due', value: 'PAYMENT_DUE' };
    }
  } else {
    return { text: 'Deactivated', value: 'DEACTIVATED' };
  }
};

const containsEncodedURIComponents = (param: any): boolean => {
  if (decodeURI(param) !== decodeURIComponent(param)) return true;
  else return false;
};

const getDealActionList = () => {
  const options: any = [
    {
      text: 'Delete',
      value: 'delete',
      childrens: [
        {
          text: 'Delete for Me',
          value: 'deleteForMe',
        },
        {
          text: 'Delete for Everyone',
          value: 'deleteForEveryone',
        },
      ],
    },
  ];
  return options;
};

const getFilteredDocuments = (documents: any) => {
  if (documents) {
    const filteredDocument = documents.filter((item: any) => {
      if (!Object.prototype.hasOwnProperty.call(item, 'id')) {
        return item;
      }
    });
    return filteredDocument;
  }
};

const checkSubstringExists = (
  listOfStrings: any,
  substring: string | undefined,
) => {
  if (
    compact(listOfStrings).some((i: any) =>
      substring?.toLowerCase().includes(i),
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export {
  incorpCountryList,
  incorporationCountryList,
  businessOpsCountryList,
  sectorList,
  validateUser,
  messageHelper,
  constructArray,
  errorMessageHandler,
  removeDuplicates,
  formatAmount,
  getAllCountryList,
  getAllStatesOfCountry,
  getCountryObject,
  getBusinessOpsCountryObject,
  getStateObject,
  getIncorporationCountryObject,
  getSectorObject,
  getIndustryTypeObject,
  getIndustryClassificationList,
  getIndustryClassificationObject,
  getIncorpCountryObject,
  getLoanCloserStatus,
  stableSort,
  getComparator,
  getVcPortalCountryObject,
  getPaymentStatus,
  getPaymentStatusYardstickUser,
  containsEncodedURIComponents,
  getSymbolFromCurrency,
  getDealActionList,
  getFilteredDocuments,
  checkSubstringExists,
  getCountryOfOperationsObject,
};

export const countryCodeList = [
  '+93',
  '+358',
  '+355',
  '+213',
  '+1684',
  '+376',
  '+244',
  '+1264',
  '+672',
  '+1268',
  '+54',
  '+374',
  '+297',
  '+61',
  '+43',
  '+994',
  '+1242',
  '+973',
  '+880',
  '+1246',
  '+375',
  '+32',
  '+501',
  '+229',
  '+1441',
  '+975',
  '+591',
  '+387',
  '+267',
  '+55',
  '+246',
  '+673',
  '+359',
  '+226',
  '+257',
  '+855',
  '+237',
  '+1',
  '+238',
  '+ 345',
  '+236',
  '+235',
  '+56',
  '+86',
  '+61',
  '+61',
  '+57',
  '+269',
  '+242',
  '+243',
  '+682',
  '+506',
  '+225',
  '+385',
  '+53',
  '+357',
  '+420',
  '+45',
  '+253',
  '+1767',
  '+1849',
  '+593',
  '+20',
  '+503',
  '+240',
  '+291',
  '+372',
  '+251',
  '+500',
  '+298',
  '+679',
  '+358',
  '+33',
  '+594',
  '+689',
  '+241',
  '+220',
  '+995',
  '+49',
  '+233',
  '+350',
  '+30',
  '+299',
  '+1473',
  '+590',
  '+1671',
  '+502',
  '+44',
  '+224',
  '+245',
  '+595',
  '+509',
  '+379',
  '+504',
  '+852',
  '+36',
  '+354',
  '+91',
  '+62',
  '+98',
  '+964',
  '+353',
  '+44',
  '+972',
  '+39',
  '+1876',
  '+81',
  '+44',
  '+962',
  '+77',
  '+254',
  '+686',
  '+850',
  '+82',
  '+965',
  '+996',
  '+856',
  '+371',
  '+961',
  '+266',
  '+231',
  '+218',
  '+423',
  '+370',
  '+352',
  '+853',
  '+389',
  '+261',
  '+265',
  '+60',
  '+960',
  '+223',
  '+356',
  '+692',
  '+596',
  '+222',
  '+230',
  '+262',
  '+52',
  '+691',
  '+373',
  '+377',
  '+976',
  '+382',
  '+1664',
  '+212',
  '+258',
  '+95',
  '+264',
  '+674',
  '+977',
  '+31',
  '+599',
  '+687',
  '+64',
  '+505',
  '+227',
  '+234',
  '+683',
  '+672',
  '+1670',
  '+47',
  '+968',
  '+92',
  '+680',
  '+970',
  '+507',
  '+675',
  '+595',
  '+51',
  '+63',
  '+872',
  '+48',
  '+351',
  '+1939',
  '+974',
  '+40',
  '+7',
  '+250',
  '+262',
  '+590',
  '+290',
  '+1869',
  '+1758',
  '+590',
  '+508',
  '+1784',
  '+685',
  '+378',
  '+239',
  '+966',
  '+221',
  '+381',
  '+248',
  '+232',
  '+65',
  '+421',
  '+386',
  '+677',
  '+252',
  '+27',
  '+211',
  '+500',
  '+34',
  '+94',
  '+249',
  '+597',
  '+47',
  '+268',
  '+46',
  '+41',
  '+963',
  '+886',
  '+992',
  '+255',
  '+66',
  '+670',
  '+228',
  '+690',
  '+676',
  '+1868',
  '+216',
  '+90',
  '+993',
  '+1649',
  '+688',
  '+256',
  '+380',
  '+971',
  '+44',
  '+1',
  '+598',
  '+998',
  '+678',
  '+58',
  '+84',
  '+1284',
  '+1340',
  '+681',
  '+967',
  '+260',
  '+263',
];

export const checkContactUniqeAttributes = (
  data: Array<Record<string, string>>,
): Record<string, number> => {
  const emails = data.map((item) => item.email);
  const phones = data.map((item) => item.phone).filter((item) => item);
  const dupEmails = (emails || []).filter(
    ((s) => (v: any) => s.has(v) || !s.add(v))(new Set()),
  );

  const dupPhones = (phones || []).filter(
    ((s) => (v: any) => s.has(v) || !s.add(v))(new Set()),
  );
  // eslint-disable-next-line no-console
  console.log('dupEmails', dupEmails);
  // eslint-disable-next-line no-console
  console.log('dupPhones', dupPhones);
  return {
    emailIndex: data.findIndex((item) => item.email === get(dupEmails, '[0]')),
    phoneIndex: data.findIndex((item) => item.phone === get(dupPhones, '[0]')),
  };
};
export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
};

export const getDefaultMeetingBody = ({
  link,
  startupName,
  investorName,
}: Record<string, any>) => {
  const money = '${}mn';

  // return `<p>Hi {{InvestorName}}</p>\n<body text should go here , please click on it and schedule a time</p>\n<p><a href=${link} target="_blank">Meeting Link</a>&nbsp;</p>\n<p></p>\n<p>Thanks,</p>\n<p>{{name}}</p>`;
  return `<p><span style="color: rgb(34,34,34);background-color: rgb(255,255,255);font-size: 11pt;font-family: Arial;">Hi ${
    investorName || '{Investor first name}'
  },</span></p>
  <p><span style="color: rgb(34,34,34);background-color: transparent;font-size: 11pt;font-family: Arial;">Thanks very much for your interest in {startup name}!</span></p>
  <p><span style="color: rgb(34,34,34);background-color: transparent;font-size: 11pt;font-family: Arial;">As a reminder, ${
    startupName || '{startup name}'
  } is building…...</span></p>
  <p><span style="color: rgb(34,34,34);background-color: transparent;font-size: 11pt;font-family: Arial;">We’ve raised ${money} out of the target ${money} from {investor names to be typed in by the startup}.</span></p>
  <p><span style="color: rgb(34,34,34);background-color: transparent;font-size: 11pt;font-family: Arial;">We’d love to talk with you more. Would you like to schedule a call this week?  You can pick any time </span><a href=${link} target="_blank"><span style="color: rgb(17,85,204);background-color: transparent;font-size: 11pt;font-family: Arial;"><ins>here</ins></span></a><span style="color: rgb(34,34,34);background-color: transparent;font-size: 11pt;font-family: Arial;"> or let us know what times work for you.</span></p>
  <p><span style="color: rgb(34,34,34);background-color: transparent;font-size: 11pt;font-family: Arial;">We look forward to connecting!</span></p>
  <p>&nbsp;</p>
  <p><span style="color: rgb(34,34,34);background-color: transparent;font-size: 11pt;font-family: Arial;">Regards,</span></p>
  <p><span style="color: rgb(34,34,34);background-color: transparent;font-size: 11pt;font-family: Arial;">${
    startupName || '{Founder Name}'
  }</span><br></p>
  `;
};

export const getParsedPriceInputValue = (value: any) => {
  if (value) {
    return parseInt(value.toString().replace(/,/g, ''));
  }
  return value;
};

export const isStartupView = () => {
  if (!localStorage.getItem('isStartupView')) {
    return null;
  } else if (localStorage.getItem('isStartupView') == 'true') {
    return true;
  }
  return false;
};

export const storeStartupView = (value: any) => {
  localStorage.setItem('isStartupView', value || false);
};
