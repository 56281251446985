import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';

import { Loader, Box } from 'components';
import history from 'common/utils/history';
import { successImage } from 'assets/index';
import { validateUser } from 'common/utils/helpers';
import { USER_LOGIN_COMPLETE } from 'redux-modules/auth/Actions';

import ChangeInviteUserPassword from './ChangeInviteUserPassword';

const useStyles = makeStyles((theme) => ({
  verifyBoxCenter: {
    height: 'calc(100vh - 104px)',
  },
  verifyBoxContent: {
    display: 'block',
    textAlign: 'center',
    padding: '130px 5% 30px',
  },
  successImg: {
    marginBottom: 30,
    height: 250,
  },
  verifySuccessTxt: {
    color: theme.palette.error.contrastText,
    fontSize: 23,
    fontWeight: 600,
  },
  verifySuccessSubTxt: {
    color: theme.palette.error.light,
    fontSize: 18,
    fontWeight: 500,
  },
  verifyErrorTxt: {
    color: theme.palette.error.main,
    fontSize: 18,
    fontWeight: 500,
  },
}));

type Props = {
  match: any;
};

const Invite = ({ match }: Props) => {
  const [isVerifying, setIsVerifying] = useState(true);
  const [error, setError] = useState('');
  const [user, setUser] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsVerifying(true);
    Auth.signIn({
      username: get(match, 'params.username'),
      password: get(match, 'params.password'),
    })
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsVerified(true);
          setIsVerifying(false);
          setUser(res);
          //   history.push(`/set-password/${get(res, 'username')}`);
        } else {
          const userObj = {
            ...res.attributes,
            role: res.attributes['custom:role'],
            startUpId: res.attributes['custom:startup_id'],
          };
          dispatch({
            type: USER_LOGIN_COMPLETE,
            payload: { ...userObj },
          });
          history.push(validateUser(userObj));
          setIsVerifying(false);
        }
      })
      .catch((error) => {
        setError(
          get(
            error,
            'message',
            'Something went wrong, please try again later.',
          ),
        );
        setIsVerifying(false);
      });
  }, []);
  const classes = useStyles();
  return (
    <Box className={classes.verifyBoxCenter}>
      <Box className={classes.verifyBoxContent}>
        {isVerifying && (
          <>
            <Loader />
          </>
        )}
        {error && (
          <>
            <img
              src={successImage}
              alt={successImage}
              className={classes.successImg}
            />
            <Typography
              variant="h2"
              gutterBottom
              className={classes.verifySuccessTxt}
            >
              {error}
            </Typography>
          </>
          // <Typography gutterBottom className={classes.verifyErrorTxt}>
          //   {errorMessage}
          // </Typography>
        )}
        {isVerified && <ChangeInviteUserPassword user={user} />}
      </Box>
    </Box>
  );
};

export default Invite;
