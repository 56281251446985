import { urlConstants } from 'common/utils/constants';
import { ConvertVentureDebtType } from 'redux-modules/ventureDebt/Types';

import { secureApi } from '../api/Axios';

export const convertVentureDebt = (payload: ConvertVentureDebtType) =>
  secureApi.post(urlConstants.ventureDebtSubmit, payload);

export const approveVDRequest = (payload: string) =>
  secureApi.get(`${urlConstants.approveVdRequest}/${payload}`);
