import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { SidePane } from 'react-side-pane';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Close from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { Box, Button } from 'components';
import { createFcrm, updateFcrm, deleteFcrm } from 'services';
import { fcrmStage, fcrmStatus } from 'common/utils/optionList';
import {
  capitalizeFirstLetter,
  checkContactUniqeAttributes,
} from 'common/utils/helpers';

import styles from './styles';
import { InputField, SelectInputField } from './SidePaneComponents/FormFields';
import InvestmentDetails from './SidePaneComponents/InvestmentDetails';
import ContactDetails from './SidePaneComponents/ContactDetails';
import { FCRMDeleteFcrmDialog } from './FCRMDialogs';

const PRICE_FIELDS = ['expectedAmount', 'amountInvested', 'valuationCap'];
type FcrmSidePaneProps = {
  openSidePane: boolean;
  editMode: boolean;
  setOpenSidePane: any;
  editFcrmContent: any;
  fetchAllFcrm: any;
  onNextOrPreviousEdit: any;
  onClose: any;
  isEmailEdit: boolean;
  emailCrmNextAction: any;
  currency: string;
  setReloadOnSidePaneCLose: any;
  editContactId: string;
  setEditContactId: any;
  editItemIdMap: any;
  nextPrevList: any;
  fcrmInvestorTypes: Array<string>;
  isRoleAdmin: boolean;
};

const FcrmSidePane = ({
  openSidePane,
  setOpenSidePane,
  editFcrmContent,
  fetchAllFcrm,
  editMode,
  onNextOrPreviousEdit,
  onClose,
  currency,
  isEmailEdit,
  emailCrmNextAction,
  setReloadOnSidePaneCLose,
  editContactId,
  setEditContactId,
  editItemIdMap,
  nextPrevList,
  fcrmInvestorTypes,
  isRoleAdmin,
}: FcrmSidePaneProps) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeletingFcrm, setIsDeletingFcrm] = useState<boolean>(false);
  const [showAddProperty, setShowAddProperty] = useState<boolean>(false);
  const [nextEmailEditInterval, setNextEmailEdit] = useState<boolean>(true);
  const [deleteFcrmDialog, setDeleteFcrmDialog] = useState<boolean>(false);
  const [allNonPrimaryContact, setAllNonPrimaryContact] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedStage, setSelectedStage] = useState<string>('');
  const [reject, setReject] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    register,
    reset,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (!openSidePane) reset({});
  }, [openSidePane]);

  useEffect(() => {
    if (!isEmpty(editFcrmContent)) {
      setValue('id', editFcrmContent.id);
      setValue('stage', editFcrmContent.stage);
      setValue('status', editFcrmContent.status);
      setValue('investorName', editFcrmContent.investorName);
      setSelectedStatus(editFcrmContent.status);
      setSelectedStage(editFcrmContent.stage);
    }
  }, [editFcrmContent]);

  const saveOnEditMode = (value: any, field: string) => {
    if (get(errors, `${field}.message`)) {
      return;
    }
    if (editMode) {
      if (PRICE_FIELDS.includes(field)) {
        value = value && value.replace(/,/g, '');
      }
      updateFcrm({
        [`${editFcrmContent.id}#${field}`]: value,
      });
      setReloadOnSidePaneCLose(true);
    }
  };

  const clearAllInvestorDetails = (data: any) => {
    updateFcrm(data);
    setReloadOnSidePaneCLose(true);
  };

  const getFormatPriceField = (formContent: any) => {
    return {
      expectedAmount:
        formContent.expectedAmount &&
        formContent.expectedAmount.replace(/,/g, ''),
      amountInvested:
        formContent.amountInvested &&
        formContent.amountInvested.replace(/,/g, ''),
      valuationCap:
        formContent.valuationCap && formContent.valuationCap.replace(/,/g, ''),
    };
  };
  const onSubmit = (data: any) => {
    let hasError = false;
    setAllNonPrimaryContact('');
    const formContent: any = Object.assign({}, data);
    const contactKeys = Object.keys(data).filter((key) => key.includes('name'));
    let contactData = contactKeys.map((key: string, index: number) => {
      const phone = data[`phone_${index}`];
      const countryCode = data[`countryCode_${index}`];
      const item: any = {
        name: data[`name_${index}`],
        phone: phone ? `${countryCode}#${phone}` : '',
        email: data[`email_${index}`],
        primary: data[`primary_${index}`],
      };
      if (data[`id_${index}`]) item['id'] = data[`id_${index}`];
      return item;
    });

    ['expectedAmount', 'amountInvested', 'valuationCap'];

    contactData = contactData.filter((item) => item.email && item.name);
    let hasDupError = false;
    const { emailIndex, phoneIndex } = checkContactUniqeAttributes(contactData);
    if (phoneIndex !== -1) {
      setError(`phone_${phoneIndex}`, {
        type: 'custom',
        message: 'Duplicate phone value',
      });
      hasDupError = true;
    }
    if (emailIndex !== -1) {
      setError(`email_${emailIndex}`, {
        type: 'custom',
        message: 'Duplicate email value',
      });
      hasDupError = true;
    }
    if (hasDupError) {
      return;
    }
    formContent['contacts'] = contactData;

    if (isEmailEdit) {
      formContent.viewedEmailCrm = true;
    }
    formContent['primaryContact'] = 'test';
    formContent['leads'] = 'test';
    formContent['email'] = 'test';
    Object.assign(formContent, { ...getFormatPriceField(formContent) });

    const allNonPrimary = (contactData || []).every((item) => !item.primary);
    if (allNonPrimary) {
      hasError = true;
      setAllNonPrimaryContact('Alteast one contact should be primary');
    }

    if (
      parseInt(get(formContent, 'amountInvested')) >
      parseInt(get(formContent, 'valuationCap'))
    ) {
      setError(`amountInvested`, {
        type: 'custom',
        message: `Investment amount cannot be greater than Valuation cap`,
      });
      hasError = true;
    }
    if (hasError) return;

    setIsLoading(true);
    createFcrm(formContent)
      .then(() => {
        setShowAddProperty(false);
        fetchAllFcrm();
        if (isEmailEdit) {
          setNextEmailEdit(false);
          emailCrmNextAction();
          setTimeout(() => setNextEmailEdit(true), 100);
        } else {
          setOpenSidePane(false);
          onCloseSidePan();
        }

        setReloadOnSidePaneCLose(true);
      })
      .catch((e) => {
        const err = e?.response?.data?.message;

        if (Array.isArray(err) && !isEmpty(err)) {
          const index = (contactData || []).findIndex(
            (item) => item[err[0]] === err[1],
          );
          if (index !== -1) {
            setError(`${err[0]}_${index}`, {
              type: 'custom',
              message: `${capitalizeFirstLetter(err[0])} already exist`,
            });
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  const onCloseSidePan = () => {
    setSelectedStatus('');
    setSelectedStage('');
    setShowAddProperty(false);
    onClose();
  };

  const getActionButtons = () => {
    if (isEmailEdit) {
      return (
        <Box className={classes.investorFormBtns}>
          <>
            <Button
              name="Save &amp; Continue"
              type="submit"
              isLoading={isLoading}
              className={classes.investorPrimaryBtn}
            />
            <Button
              name="Reject"
              type="button"
              onClick={() => {
                setDeleteFcrmDialog(true);
                setReject(true);
              }}
              className={classes.investorSecondaryBtn}
            />
          </>
        </Box>
      );
    }
  };

  useEffect(() => {
    if (selectedStatus === 'WON') {
      // setSelectedStage('CLOSED');
      setValue('stage', 'CLOSED');
    }
  }, [selectedStatus]);

  return (
    <SidePane
      open={openSidePane}
      // autoWidth={true}
      onClose={onCloseSidePan}
      width={54}
      // className={classes.vPaneMobile}
    >
      <Box className={classes.sidePaneWrapper}>
        <Box className={classes.iconsWrapper}>
          {get(editFcrmContent, 'investorName') ? (
            <Grid item xs={6} className={classes.investorNameEdit}>
              <InputField
                label={() => null}
                control={control}
                classes={classes}
                {...register('investorName', {
                  required: 'This field is requierd', // JS only: <p>error message</p> TS only support string
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value: get(editFcrmContent, 'investorName'),
                })}
                placeholder="Investor"
              />
            </Grid>
          ) : (
            'Add New Investor'
          )}
          <Grid item xs={3}>
            {!isRoleAdmin && editMode && !isEmailEdit && (
              <Button
                name="Delete"
                isLoading={isDeletingFcrm}
                className={classes.deleteBtn}
                onClick={() => setDeleteFcrmDialog(true)}
              />
            )}
          </Grid>
          <Box>
            {editMode && !isEmailEdit && (
              <>
                {nextPrevList.indexOf(editItemIdMap) !== 0 && (
                  <KeyboardArrowUp
                    onClick={() => onNextOrPreviousEdit('PREV')}
                    className={classes.iconStyle}
                  />
                )}
                {nextPrevList.indexOf(editItemIdMap) !==
                  nextPrevList.length - 1 && (
                  <KeyboardArrowDown
                    onClick={() => onNextOrPreviousEdit('NEXT')}
                    className={classes.iconStyle}
                  />
                )}
              </>
            )}
            <Close
              onClick={() => onCloseSidePan()}
              className={classes.iconStyle}
            />
          </Box>
        </Box>

        {nextEmailEditInterval && openSidePane && (
          <form onSubmit={handleSubmit(onSubmit)}>
            {editMode && (
              <Grid item xs={6} className={classes.hideField}>
                <InputField
                  control={control}
                  classes={classes}
                  label=""
                  {...register('id', {})}
                />
              </Grid>
            )}
            <Box className={classes.statusForm}>
              <Grid xs={12} container>
                {editMode ? (
                  <Grid item xs={6} className={classes.investorInputBox}>
                    <SelectInputField
                      disabled={isRoleAdmin}
                      label={() => (
                        <>
                          Status <span className={classes.errorText}> *</span>
                        </>
                      )}
                      control={control}
                      classes={classes}
                      {...register('status', {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        value: get(editFcrmContent, 'status'),
                      })}
                      onChangeField={(val: string) => {
                        setSelectedStatus(val);
                      }}
                      dropdowns={fcrmStatus}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <InputField
                      label={() => (
                        <>
                          Investor <span className={classes.errorText}> *</span>
                        </>
                      )}
                      control={control}
                      classes={classes}
                      {...register('investorName', {
                        required: 'This field is requierd', // JS only: <p>error message</p> TS only support string
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        value: get(editFcrmContent, 'investorName') || '',
                      })}
                      placeholder="Investor"
                    />
                  </Grid>
                )}
                <Grid item xs={6} className={classes.investorInputBox}>
                  <SelectInputField
                    disabled={isRoleAdmin || selectedStatus === 'WON'}
                    label={() => (
                      <>
                        Stage <span className={classes.errorText}> *</span>
                      </>
                    )}
                    control={control}
                    classes={classes}
                    onChangeField={setSelectedStage}
                    dropdowns={fcrmStage}
                    {...register('stage', {
                      required: 'This field is requierd', // JS only: <p>error message</p> TS only support string
                      // value: editFcrmContent.stage || '',
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      value: editFcrmContent.stage || '',
                    })}
                  />
                </Grid>
              </Grid>
            </Box>
            <ContactDetails
              control={control}
              openSidePane={openSidePane}
              editMode={editMode}
              register={register}
              editFcrmContent={editFcrmContent}
              setValue={setValue}
              setError={setError}
              getValues={getValues}
              setReloadOnSidePaneCLose={setReloadOnSidePaneCLose}
              editContactId={editContactId}
              setEditContactId={setEditContactId}
              allNonPrimaryContact={allNonPrimaryContact}
              isRoleAdmin={isRoleAdmin}
            />

            <InvestmentDetails
              control={control}
              register={register}
              editMode={editMode}
              openSidePane={openSidePane}
              editFcrmContent={editFcrmContent}
              setValue={setValue}
              setShowAddProperty={setShowAddProperty}
              showAddProperty={showAddProperty}
              saveOnEditMode={saveOnEditMode}
              currency={currency}
              getValues={getValues}
              isEmailEdit={isEmailEdit}
              setReloadOnSidePaneCLose={setReloadOnSidePaneCLose}
              clearAllInvestorDetails={clearAllInvestorDetails}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              fcrmInvestorTypes={fcrmInvestorTypes}
              setError={setError}
              selectedStage={selectedStage}
              clearErrors={clearErrors}
              isLoading={isLoading}
              onClose={onClose}
              isRoleAdmin={isRoleAdmin}
            />

            {getActionButtons()}
          </form>
        )}
        <FCRMDeleteFcrmDialog
          open={deleteFcrmDialog}
          onClose={() => {
            setDeleteFcrmDialog(false);
            setReject(false);
          }}
          isLoading={isDeletingFcrm}
          onOk={() => {
            setIsDeletingFcrm(true);
            deleteFcrm({
              id: get(editFcrmContent, 'id'),
            })
              .then(() => {
                setDeleteFcrmDialog(false);
                setReject(false);
                setReloadOnSidePaneCLose(true);
                if (isEmailEdit) {
                  setNextEmailEdit(false);
                  emailCrmNextAction();
                  setTimeout(() => setNextEmailEdit(true), 100);
                } else {
                  onClose(true);
                }
              })
              .finally(() => setIsDeletingFcrm(false));
          }}
          reject={reject}
        />
      </Box>
    </SidePane>
  );
};

export default FcrmSidePane;
