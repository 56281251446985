import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import ReferralData from './ReferralData';

export const ReferralDataRoute: IRoute = {
  path: '/admin/referral-data',
  component: ReferralData,
  layout: DashboardLayout,
  isAdmin: false,
  exact: true,
};
