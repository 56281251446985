import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Hidden,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import { Button, WrappedTextInput, Box } from 'components';
import { loginUser, emptyState } from 'redux-modules/auth/Actions';
import { ISignInModal } from 'common/types';
import { signInSchema } from 'common/utils/validations';
import { RootState } from 'redux-modules/store/rootState';
import { SignUpImage } from 'assets';
import history from 'common/utils/history';

import styles from './styles';

const SignIn = () => {
  const dispatch = useDispatch();
  const { isLoading, errorMessage } = useSelector(
    ({ Auth }: RootState) => Auth,
  );

  useEffect(() => {
    if (errorMessage) {
      dispatch(emptyState());
    }
  }, []);

  const onSubmit = (data: ISignInModal): void => {
    dispatch(loginUser(data));
  };

  const classes = styles();

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(signInSchema),
  });

  return (
    <Box className={classes.signUpContainer}>
      <Grid container className={classes.gridContainer}>
        <Grid item sm={6} xs={12} className={classes.leftSideBox}>
          <Box className={classes.leftSide}>
            <Box>
              {errorMessage && (
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.errorText}
                >
                  {errorMessage}
                </Typography>
              )}
            </Box>
            <Typography
              variant="h2"
              gutterBottom
              className={classes.welcome8vdx}
            >
              Welcome to 8vdX!
            </Typography>
            <Typography className={classes.welcomeSub8vdx}>
              Please login to your account.
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              data-testid="signInForm"
              className={classes.validateForm}
            >
              <Controller
                name="username"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="search"
                      label="Email"
                      placeholder="Email"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(e.target.value.trimStart())
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Email required' }}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      label="Password"
                      type="password"
                      placeholder="Password"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(e.target.value.trimStart())
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'Password required' }}
              />
              <Grid container>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={
                          <CheckBoxIcon
                            className={classes.checkedBox}
                            fontSize="small"
                          />
                        }
                        name="checkedI"
                      />
                    }
                    label="Remember Me"
                  />
                </Grid>
                <Grid item xs={6} className={classes.forgotText}>
                  <p
                    className={classes.forgotPassword}
                    onClick={() => history.push('/forgot-password')}
                  >
                    Forgot Password?
                  </p>
                </Grid>
              </Grid>
              <Box className={classes.signInBtnBox}>
                <Button
                  type="submit"
                  className={classes.signupBtn}
                  name="Sign In"
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </Box>
              <Box className={classes.doYouHaveBox}>
                <Typography className={classes.doYouHaveBoxText}>
                  Don’t have an account yet?
                  <Typography
                    variant="caption"
                    className={classes.doYouLink}
                    onClick={() => history.push('/sign-up')}
                  >
                    &nbsp;Create Account
                  </Typography>
                </Typography>
              </Box>
            </form>
          </Box>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={6} xs={12} className={classes.rightSide}>
            <img
              src={SignUpImage}
              className={classes.signupImage}
              alt="signUp"
            />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};

export default SignIn;
