import { isEmpty, sortBy } from 'lodash';

import {
  ADMIN_GET_ALL_DEAL,
  ADMIN_GET_ALL_DEAL_SUCCESS,
  ADMIN_GET_ALL_DEAL_FAILURE,
} from './Actions';
import { IAdminDealsState, DealAction } from './Types';
export const DealsState: IAdminDealsState = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  dealList: [],
  latestDeal: {},
};
const DealsReducer = (state = DealsState, action: DealAction) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_GET_ALL_DEAL:
      return {
        ...state,
        isLoading: true,
        successMessage: '',
        errorMessage: '',
      };
    case ADMIN_GET_ALL_DEAL_SUCCESS: {
      const sortedData = sortBy(payload, ['order']).filter(
        (deal: any) => !deal.closed,
      );
      return {
        ...state,
        isLoading: false,
        successMessage: 'successfully fetched',
        dealList: sortBy(payload, ['order']),
        latestDeal: !isEmpty(sortedData) ? sortedData[0] : null,
      };
    }
    case ADMIN_GET_ALL_DEAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        dealList: [],
        errorMessage: payload,
      };
    default:
      return state;
  }
};

export default DealsReducer;
