import * as yup from 'yup';

import {
  regex,
  emailRegex,
  requiredConstant,
  emailValidConstant,
  passwordValidConstant,
  urlRegex,
  urlValidConstant,
  phoneRegex,
} from 'common/utils/constants';

export const emailYup = yup
  .string()
  .nullable()
  .email(emailValidConstant)
  .required(requiredConstant)
  .matches(emailRegex, emailValidConstant);

export const urlYup = yup
  .string()
  .required(requiredConstant)
  .matches(urlRegex, urlValidConstant);

export const passwordYup = yup
  .string()
  .required(requiredConstant)
  .matches(regex, passwordValidConstant)
  .test(
    'len',
    'Password is too long - should be 16 characters maximum.',
    (value) => value !== undefined && value.length <= 16,
  );

export const nameYup = yup.string().required(requiredConstant);
export const phoneNumberYup = yup
  .string()
  .matches(phoneRegex, 'Phone number is not valid');

export const signInSchema = yup.object().shape({
  username: emailYup,
  password: passwordYup,
});

export const ConfirmPasswordSchema = yup.object().shape({
  password: passwordYup,
  newPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const ForgotPasswordSchema = yup.object().shape({
  username: emailYup,
});

export const ResetPasswordSchema = yup.object().shape({
  password: passwordYup,
  newPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const SignUpYCSchema = yup.object().shape({
  name: nameYup,
  startupName: nameYup,
  email: emailYup,
  accelerator: yup.string().required(requiredConstant),
  password: passwordYup,
  acceleratorName: yup.string().when('accelerator', {
    is: (value: any) => value === 'YC' || value === 'NONE',
    then: yup.string().nullable().notRequired(),
    otherwise: yup.string().nullable().required(requiredConstant),
  }),
  ycBatch: yup.string().when('accelerator', {
    is: (value: any) => value === 'YC',
    then: yup.string().nullable().required(requiredConstant),
    otherwise: yup.string().nullable().notRequired(),
  }),
});

export const YcDocumentsSchema = yup.object().shape({
  assignedYcPartnerName: yup.string().required(requiredConstant).nullable(),
  assignedYcLegalPocName: yup.string().required(requiredConstant).nullable(),
});
