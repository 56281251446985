import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';

import { InvestorOnboardStepProps } from 'common/types';
import { Box, Button } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  getSignedUrlForDocusignDocument,
  addInvestorDocusignDocument,
} from 'services';
import FileUpload from 'components/FileUpload';

import styles from './styles';

const DocusignUpload = ({
  investmentProcess,
  investorId,
  investmentDetail,
  syncInvestor,
  handleNext,
  handleBack,
  documents,
}: InvestorOnboardStepProps) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loadingSignedUrl, setLoadingSignedUrl] = React.useState<boolean>(
    false,
  );
  const [documentSigned, setDocumentSigned] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  useEffect(() => {
    if (investmentProcess) {
      const isDocumentsSigned = get(
        investmentProcess,
        'docuSignDocumentSigned',
      );
      setDocumentSigned(isDocumentsSigned);
    }
  }, [investmentProcess]);

  const onSubmit = async (fileuploadReq: any): Promise<void> => {
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsLoading(true);
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        await addInvestorDocusignDocument({
          filename: get(fileuploadReq, 'filename'),
          investorId,
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
          investmentProcessId: get(investmentProcess, 'id'),
          investmentDetailId: get(investmentDetail, 'id'),
        });
        setDocumentSigned(true);
        setIsLoading(false);
        await syncInvestor();
        handleNext(3);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload wire instruction.');
    }
  };

  const uploadedFiles = () => {
    if (isEmpty(documents)) return [];
    if (!isEmpty(documents)) {
      return documents.filter(
        (doc: any) => doc.documentName === 'Docusign_Document',
      );
    }
  };

  return (
    <Box>
      <Typography variant="h2" className={classes.detailsText}>
        e-Signature
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.errorText}>
        {errorMessage}
      </Typography>
      <Box>
        <Typography variant="h4" className={classes.docuSignText}>
          Upload Docusign signed documents
        </Typography>
        {/* <Typography variant="body1" className={classes.listPoints}>
            1. Promissory Note
          </Typography>
          <Typography variant="body1" className={classes.listPoints}>
            2. Bonus SAFE Agreement
          </Typography> */}
        <FileUpload
          getSignedUrl={getSignedUrlForDocusignDocument}
          uploadedFiles={uploadedFiles()}
          setLoadingSignedUrl={setLoadingSignedUrl}
          uploadOnSelect={onSubmit}
          requestObject={{
            investorId,
            investmentDetailId: get(investmentDetail, 'id'),
          }}
          content={
            !documentSigned && (
              <Box className={classes.docuSignBtn}>
                <Button
                  className={classes.uploadBtn}
                  name={'Upload signed agreements'}
                  disabled={loadingSignedUrl || isLoading}
                  isLoading={loadingSignedUrl || isLoading}
                />
              </Box>
            )
          }
        />
        {documentSigned && (
          <Box className={classes.docuSignBtn}>
            <Button
              className={classes.uploadBtn}
              name="e-Signatures completed"
              disabled={true}
            />
          </Box>
        )}
        <Box className={classes.docuSignBtn}>
          <Button
            className={classes.prevButton}
            name="Previous"
            onClick={() => handleBack()}
            disabled={loadingSignedUrl}
          />
          <Button
            className={classes.nextButton}
            name="Next"
            disabled={!get(investmentProcess, 'docuSignDocumentSigned')}
            onClick={() => handleNext(3)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DocusignUpload;
