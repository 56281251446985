import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { isEmpty, orderBy, get } from 'lodash';
import moment from 'moment';

import {
  errorMessageHandler,
  formatAmount,
  getPaymentStatus,
  getPaymentStatusYardstickUser,
} from 'common/utils/helpers';
import {
  Box,
  StyledTableCell,
  StyledTableRow,
  Loader,
  Button,
} from 'components';
import history from 'common/utils/history';
import { getAllSubscribedInvestorUser } from 'services';
import { SubscriptionUsersOptions } from 'common/utils/optionList';
import { SUBSCRIBED_USER } from 'common/utils/constants';

import styles from './styles';
import CreateNewPlan from './CreateNewPlan';

type Props = {
  filteredSearchData: any;
  setAllSubscribedInvestorUser: React.Dispatch<any>;
  setFilteredSearchUserData: React.Dispatch<any>;
  subscriptionPlanAllData: any;
  getSubscriptionPlansList: () => void;
  setSubscriptionUser: React.Dispatch<React.SetStateAction<string>>;
};

const headers = (array: any[]) => {
  return (
    <>
      {array.map((i: any, n: number) => {
        return (
          <StyledTableCell
            key={n}
            style={{
              textAlign: i.textAlign ? i.textAlign : 'left',
            }}
          >
            {i.name}
          </StyledTableCell>
        );
      })}
    </>
  );
};

const TABLE_HEADERS = [
  { name: 'Client Name', textAlign: 'left' },
  { name: 'Type of User', textAlign: 'left' },
  {
    name: 'Start Date',
    textAlign: 'center',
  },
  { name: 'Free Trial Days', textAlign: 'right' },
  { name: 'Monthly Charges', textAlign: 'right' },
  { name: 'No of Outputs', textAlign: 'right' },
  {
    name: 'Plan Name',
    textAlign: 'left',
  },
  { name: 'Payment Status', textAlign: 'left' },
  { name: 'Action', textAlign: 'left' },
];

const VcFirmAllSubscribedUser = ({
  filteredSearchData,
  setAllSubscribedInvestorUser,
  setFilteredSearchUserData,
  subscriptionPlanAllData,
  getSubscriptionPlansList,
  setSubscriptionUser,
}: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentInvestorData, setCurrentInvestorData] = useState<any>({});

  const getAllSubscribedInvestorUsers = () => {
    setIsLoading(true);
    setErrorMessage('');
    getAllSubscribedInvestorUser()
      .then((res: any) => {
        setAllSubscribedInvestorUser(
          orderBy(res.data, ['createdAt'], ['desc']),
        );
        setFilteredSearchUserData(orderBy(res.data, ['createdAt'], ['desc']));
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const handleVcUSerRedirection = (row: any) => {
    if (
      [SUBSCRIBED_USER.YARDSTICK].includes(
        get(row, 'subscriptionPlan.planType'),
      )
    ) {
      history.push(
        `/admin/vc-firms/${get(row, 'investorId')}?value=YARDSTICK_USER_REVIEW`,
      );
    } else if (
      [SUBSCRIBED_USER.ANGEL_INVESTOR].includes(
        get(row, 'subscriptionPlan.planType'),
      )
    ) {
      history.push(
        `/admin/vc-firms/${get(row, 'investorId')}?value=ANGEL_INVESTOR_REVIEW`,
      );
    } else {
      history.push(
        `/admin/vc-firms/${get(row, 'investorId')}?value=VC_FIRM_REVIEW`,
      );
    }
  };

  const getPlanPriceDetail = (planDetail: any) => {
    const prices = get(planDetail, 'prices');
    if (prices) {
      return prices.find((price: any) => !price.addon);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getPlanAddOnPriceDetail = (planDetail: any) => {
    const prices = get(planDetail, 'prices');
    return prices.find((price: any) => price.addon);
  };

  React.useEffect(() => {
    getAllSubscribedInvestorUsers();
    getSubscriptionPlansList();
    setSubscriptionUser('ALL');
  }, []);

  return (
    <>
      <Box className={classes.firmAdminTableBox}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>{headers(TABLE_HEADERS)}</TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={TABLE_HEADERS.length}>
                    <Box className={classes.loader}>
                      <Loader />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : isEmpty(filteredSearchData) && !errorMessage ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={TABLE_HEADERS.length}>
                    <Box className={classes.loader}>
                      <Typography>No data found in table</Typography>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                (filteredSearchData || []).map((row: any, index: number) => (
                  <StyledTableRow hover key={index}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {get(row, 'investorName', '-')}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {get(
                        SubscriptionUsersOptions.find(
                          (obj: any) =>
                            obj.value === get(row, 'subscriptionPlan.planType'),
                        ),
                        'text',
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ textAlign: 'center' }}
                    >
                      {moment(
                        get(row, 'startingDate') || get(row, 'createdAt'),
                      ).format('MMM DD, YYYY')}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ textAlign: 'right' }}
                    >
                      {[
                        SUBSCRIBED_USER.VC_FIRM,
                        SUBSCRIBED_USER.ANGEL_INVESTOR,
                      ].includes(get(row, 'subscriptionPlan.planType'))
                        ? get(row, 'subscriptionPlan.subscriptionFreeTrialDays')
                        : 'NA'}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ textAlign: 'right' }}
                    >
                      {get(row, 'subscriptionPlan.isJoiningPlan')
                        ? '-'
                        : formatAmount(
                            get(
                              getPlanPriceDetail(get(row, 'subscriptionPlan')),
                              'amount',
                              '-',
                            ),
                            {
                              currency: get(row, 'currency') || 'USD',
                            },
                          )}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ textAlign: 'right' }}
                    >
                      {[SUBSCRIBED_USER.YARDSTICK].includes(
                        get(row, 'subscriptionPlan.planType'),
                      )
                        ? get(
                            getPlanPriceDetail(get(row, 'subscriptionPlan')),
                            'freeUnit',
                            '-',
                          )
                        : get(row, 'outputs') || 'NA'}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      <span
                        className={
                          get(row, 'subscriptionPlan.isJoiningPlan')
                            ? classes.planNameDisableText
                            : classes.planNameText
                        }
                        onClick={() => {
                          if (get(row, 'subscriptionPlan.isJoiningPlan')) {
                            return;
                          } else {
                            setIsOpen(true);
                            setCurrentInvestorData(row);
                          }
                        }}
                      >
                        {get(row, 'subscriptionPlan.name')}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {[SUBSCRIBED_USER.YARDSTICK].includes(
                        get(row, 'subscriptionPlan.planType'),
                      )
                        ? get(
                            getPaymentStatusYardstickUser(
                              get(row, 'usedFreeOutput'),
                              get(row, 'billingHistory.status'),
                              get(row, 'active'),
                            ),
                            'text',
                          )
                        : get(
                            getPaymentStatus(
                              get(row, 'startingDate'),
                              get(
                                row,
                                'subscriptionPlan.subscriptionFreeTrialDays',
                              ),
                              get(row, 'active'),
                              get(row, 'billingHistory.amountPaidDecimal'),
                              get(row, 'billingHistory.status'),
                              get(row, 'subscriptionPlan.planType'),
                              get(row, 'startedPaidSubscription'),
                              get(row, 'subscriptionPlan.isJoiningPlan'),
                            ),
                            'text',
                          )}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.actionButtonBox}
                      style={{ paddingLeft: 2 }}
                    >
                      <Button
                        name="View"
                        className={classes.viewButton}
                        onClick={() => handleVcUSerRedirection(row)}
                      >
                        View
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CreateNewPlan
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        getAllSubscribedInvestorUsers={getAllSubscribedInvestorUsers}
        type={'UPDATE'}
        subscriptionPlanAllData={subscriptionPlanAllData}
        currentInvestorData={currentInvestorData}
      />
    </>
  );
};

export default VcFirmAllSubscribedUser;
