import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';

import { getClientsList, getClientAccessKey } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import {
  Box,
  Dialog,
  Button,
  StyledTableCell,
  StyledTableRow,
  Loader,
} from '../../components';
import AddClient from './AddClient';
import styles from './styles';
import ViewServiceUsage from './Dialog/ViewServiceUsage';

const Clients = () => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clientList, setClientList] = useState<Array<Record<string, unknown>>>(
    [],
  );
  const [client, setClient] = useState<Record<string, unknown> | null>(null);
  const [clientId, setClientId] = useState<any>();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [openServiceUsage, setOpenServiceUsage] = useState<boolean>(false);

  const getUserList = async () => {
    setIsLoading(true);
    try {
      const response: any = await getClientsList();
      if (typeof get(response, 'data') !== 'string') {
        setClientList(get(response, 'data', []));
      }
    } catch (err) {
      errorMessageHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getAccessKey = async () => {
    if (!isEmpty(client)) {
      const payload = {
        clientId: get(client, 'id'),
      };
      getClientAccessKey(payload).then((res) => {
        setClientId(res);
      });
    }
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleShowEmptyMessage = () => {
    if (isEmpty(clientList) && !isLoading) {
      return (
        <StyledTableRow className={classes.showEmptyMsg}>
          <Box className={classes.noItemsImg}>
            <Typography>No items to show</Typography>
          </Box>
        </StyledTableRow>
      );
    }
  };

  const handleClose = () => {
    setClient(null);
    setOpen(false);
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      <Box className={classes.clientContainer}>
        <Box className={classes.actionContent}>
          <Box className={classes.actionHead}>
            {/* <Box className={classes.actionHeadContent}>
              <img
                src={Ornament}
                alt={Ornament}
                className={classes.enquiryImage}
              />
            </Box> */}
            <Box className={classes.addClientBtnBox}>
              <Typography variant="h6" className={classes.headText}>
                Clients
              </Typography>
              <Button
                name="Add New Client"
                className={classes.addNewClientBtn}
                onClick={() => handleDialogOpen()}
              />
            </Box>
          </Box>
          {isLoading && (
            <Box className={classes.loader}>
              <Loader />
            </Box>
          )}
          {!isLoading && (
            <Box className={classes.clientContent}>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <Table stickyHeader aria-label="customized table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <StyledTableCell>S.No</StyledTableCell>
                      <StyledTableCell>Client</StyledTableCell>
                      <StyledTableCell>Client Type</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Phone</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientList.map((row: Record<string, any>, i: number) => (
                      <StyledTableRow hover key={row.id}>
                        <StyledTableCell scope="row">{1 + i}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell>{row.clientType}</StyledTableCell>
                        <StyledTableCell>{row.email}</StyledTableCell>
                        <StyledTableCell>{row.phone}</StyledTableCell>
                        <StyledTableCell>
                          <Button
                            name="View"
                            className={classes.viewTextBtn}
                            onClick={() => {
                              handleDialogOpen();
                              setClient(row);
                              getAccessKey();
                            }}
                          />
                          <Button
                            name="View Usage"
                            className={classes.viewTextBtn}
                            onClick={() => {
                              setClientId(get(row, 'id'));
                              setOpenServiceUsage(true);
                            }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                    {handleShowEmptyMessage()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        subheading={''}
        title={isEmpty(client) ? 'Add New Client' : 'Update Client'}
        handleClose={() => {
          handleClose();
        }}
        dialogActionChildren={''}
      >
        <AddClient
          handleBack={() => setOpen(false)}
          client={client}
          clientId={clientId}
          getUserList={() => getUserList()}
          getAccessKey={() => getAccessKey()}
        />
      </Dialog>

      <ViewServiceUsage
        open={openServiceUsage}
        setOpen={setOpenServiceUsage}
        clientId={clientId}
      />
    </>
  );
};
export default Clients;
