import React from 'react';
import { Typography, Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

import { Box } from 'components';
import { RightArrow } from 'assets';
import history from 'common/utils/history';

import styles from './styles';

const NewVentureDebtConversion = ({ data }: any) => {
  const classes = styles();
  const { startup, actionRelativeId } = data;

  const handleGetEnquirybyStartupId = () => {
    history.push(`/admin/enquiry-view/${actionRelativeId}?value=1`);
  };

  return (
    <Box className={classes.dashActProfileBox}>
      <Box className={classes.dashActProfileLeft}>
        <Avatar>
          <PersonIcon />
        </Avatar>
        <Box className={classes.dashActProfiledetails}>
          <Typography className={classes.profileUNameText} variant="h4">
            {startup.name}
            <Typography className={classes.profileSubText} variant="caption">
              New Venture Debt Conversion
            </Typography>
          </Typography>
          <Typography className={classes.profileLocationText} variant="h4">
            {startup.weblink} • {startup.name}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.dashActProfileRight}>
        <Typography
          variant="caption"
          className={classes.openText}
          onClick={handleGetEnquirybyStartupId}
        >
          Open{' '}
        </Typography>
        <img
          src={RightArrow}
          alt={RightArrow}
          className={classes.pointer}
          onClick={handleGetEnquirybyStartupId}
        />
      </Box>
    </Box>
  );
};

export default NewVentureDebtConversion;
