import { get, isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux-modules/store/rootState';
import NotFound from 'views/notFound';

import BenchMarkDashboard from './BenchMarkDashboard';
import BenchMarkStartupInfo from './BenchMarkStartupInfo';
// import styles from './styles';

type ComponentProps = {
  match: any;
};

const BenchMarkKPI = ({ match }: ComponentProps) => {
  // const classes = styles();

  const [editProfile, setEditProfile] = React.useState<boolean>(false);
  const startupId: string = get(match, 'params.id');
  const { role, startUpId } = useSelector(({ Auth }: RootState) => Auth.user);
  const [
    openBenchmarkDashboard,
    setOpenBenchmarkDashboard,
  ] = React.useState<boolean>(false);

  return role === 'ADMIN' && !isEmpty(startupId) ? (
    <BenchMarkDashboard startupId={startupId} role={role} />
  ) : role === 'STARTUP_ADMIN' && openBenchmarkDashboard ? (
    <BenchMarkDashboard
      startupId={startUpId}
      role={role}
      setOpenBenchmarkDashboard={setOpenBenchmarkDashboard}
      setEditProfile={setEditProfile}
    />
  ) : role === 'STARTUP_ADMIN' ? (
    <BenchMarkStartupInfo
      openBenchmarkDashboard={openBenchmarkDashboard}
      setOpenBenchmarkDashboard={setOpenBenchmarkDashboard}
      editProfile={editProfile}
    />
  ) : (
    <NotFound />
  );
};

export default BenchMarkKPI;
