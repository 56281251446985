import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tabPanelContent: {
    padding: 20,
    '@media (max-width: 1024px)': {
      padding: '20px 0px',
    },
  },
  bridgeLoadText: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.grey[500],
    letterSpacing: 0.3,
    marginBottom: 20,
  },
  accordBox: {
    marginBottom: 20,
    border: '1px solid #CDE8DD',
    borderRadius: 4,
    padding: '10px 20px 20px',
    '&::before': {
      display: 'none',
      position: 'static',
    },
  },
  fundAccordBox: {
    backgroundColor: theme.palette.common.white,
    marginBottom: '20px !important',
    border: '1px solid #CDE8DD',
    borderRadius: 4,
    padding: '0',
    '&::before': {
      display: 'none',
      position: 'static',
    },
  },
  summaryAccord: {
    boxShadow: theme.shadows[0],
    position: 'relative',
    flexDirection: 'row-reverse',
    msFlexDirection: 'row-reverse',
    padding: 0,
    '& .MuiAccordionSummary-content': {
      margin: '12px 0',
      paddingLeft: 25,
      justifyContent: 'space-between',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      position: 'absolute',
      left: -10,
      top: 27,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  prospectiveBox: {
    margin: 0,
    padding: 0,
    '&.MuiAccordion-root::before': {
      top: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      boxShadow: '0px 10px 26px 0px #0000000F',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 40,
    },
  },
  accordProspectiveBox: {
    marginTop: 20,
    padding: '0px',
    background: '#E8F6F5',
    boxShadow: theme.shadows[0],
    '&::before': {
      display: 'none',
      position: 'static',
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#E8F6F5',
      borderRadius: '8px 8px 2px 2px',
      '@media (max-width: 525px)': {
        padding: '20px 12px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '&  .MuiAccordionDetails-root': {
      padding: '0px !important',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      margin: '0px',
      paddingLeft: 7,
      '& .MuiTypography-root': {
        '@media (max-width: 525px)': {
          fontSize: 12,
          lineHeight: '17px',
        },
      },
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      boxShadow: 'none',
    },
    '& .MuiIconButton-root': {
      padding: 10,
    },
    '& $accordHeadText': {
      marginTop: 0,
    },
  },
  summaryFundAccord: {
    boxShadow: theme.shadows[0],
    position: 'relative',
    flexDirection: 'row-reverse',
    msFlexDirection: 'row-reverse',
    padding: '12px 15px',
    minHeight: '48px !important',
    '& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
    },
    '& .MuiAccordionSummary-content': {
      margin: '0',
      paddingLeft: 20,
      justifyContent: 'space-between',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      position: 'absolute',
      left: -10,
      top: 25,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  chRightIcon: {
    padding: 0,
    fontSize: 28,
    //marginLeft: 10,
  },
  stepAccord: {
    fontSize: 13,
    color: theme.palette.grey[600],
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  accordHeadText: {
    fontSize: 16,
    color: theme.palette.grey[500],
    fontWeight: 600,
    alignSelf: 'center',
    marginTop: 10,
    letterSpacing: 0.3,
    // width: 'calc(100% - 125px)',
    // width: '50%',
  },
  summaryAccordRight: {
    // width: '50%',
    textAlign: 'right',
  },
  statusBtn: {
    background: theme.palette.grey[800],
    color: theme.palette.primary.contrastText,
    height: 28,
    fontSize: 12,
    cursor: 'pointer',
    borderRadius: 4,
    marginRight: 15,
    alignSelf: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.grey[800],
      color: theme.palette.primary.contrastText,
    },
  },
  accordSendBtn: {
    fontSize: 14,
    letterSpacing: 0.25,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 84,
    alignSelf: 'center',
    textTransform: 'capitalize',
  },
  moreIcon: {
    alignSelf: 'center',
    color: theme.palette.primary.main,
    width: 30,
    height: 30,
    marginLeft: 12,
    position: 'relative',
    top: 10,
  },
  accordDetails: {
    padding: '0px 0px 10px 25px',
    '@media (max-width: 525px)': {
      padding: '0px 0px 10px 0px',
    },
  },
  fundAccordDetails: {
    padding: 0,
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  prospectiveAccordDetails: {
    display: 'block',
    padding: '0px 0px 10px 25px',
    '@media (max-width: 525px)': {
      padding: '0px 0px 10px 0px',
    },
  },
  accordDetailsContent: {
    display: 'block',
    width: 'calc(100% - 40px)',
  },
  documentRequestBox: {
    display: 'flex',
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 18,
    marginTop: 15,
  },
  documentShareBox: {
    display: 'flex',
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: '8px 18px',
    marginTop: 15,
  },
  iconImg: {
    height: 18,
    alignSelf: 'center',
  },
  uploadIcon: {
    height: 15,
    alignSelf: 'center',
    paddingLeft: 40,
  },
  bGImg: {
    height: 43,
    alignSelf: 'center',
    paddingLeft: 20,
  },
  documenttext: {
    alignSelf: 'center',
    fontSize: 16,
    paddingLeft: 40,
    color: theme.palette.grey[900],
  },
  shareDocumentText: {
    alignSelf: 'center',
    fontSize: 16,
    paddingLeft: 10,
    color: theme.palette.grey[900],
  },
  uploadDocumenttext: {
    alignSelf: 'center',
    fontSize: 16,
    padding: '10px 0 10px 10px',
    color: theme.palette.grey.A100,
  },
  reviewDocuBox: {
    textAlign: 'center',
    marginBottom: 25,
  },
  reviewDocuText: {
    fontSize: 11,
    color: theme.palette.grey[600],
    marginBottom: 10,
  },
  bGDocuImg: {
    height: 100,
    width: 106,
    marginTop: 25,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    borderRadius: 5,
  },
  approvedbtn: {
    fontSize: 10,
    color: theme.palette.primary.main,
    background: 'transparent',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
  },
  circleOutline: {
    fontSize: 10,
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  approveBtn: {
    fontSize: 10,
    height: 26,
    background: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid #1EA59A',
    borderRadius: 4.5,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
  },
}));
