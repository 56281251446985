import React, { useState, useMemo, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';

import { Box, Tabs, TabPanel } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  getAdminVcFirmDocumentsByInvestor,
  getVcFirmDetailsByInvestor,
  getVcUsersDetailsByInvestor,
} from 'services';

import styles from './styles';
import {
  VcFirmDocument,
  VcFirmFund,
  VcFirmOverview,
  // VcFirmPayment,
  TransactionHistory,
  VcFirmProcess,
  VcFirmInfo,
} from './components';

type ComponentProps = {
  match: any;
};

const VcFirm = ({ match }: ComponentProps) => {
  const classes = styles();
  const param = useLocation();
  const investorId: string = get(match, 'params.id');
  const UrlParamValue: string | null = new URLSearchParams(param.search).get(
    'value',
  );
  const UrlParamStatus: string | null = new URLSearchParams(param.search).get(
    'status',
  );

  const [value, setValue] = useState<string>('OVERVIEW');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);
  const [vcFirmDocuments, setVcFirmDocuments] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [documentErrorMessage, setDocumentErrorMessage] = useState<string>('');
  const [vcDetails, setVcDetails] = React.useState<any>({});
  const [vcUserDetails, setVcUserDetails] = React.useState<any>({});

  const tabs = useMemo(() => {
    if (UrlParamValue === 'VC_FIRM_REVIEW' || UrlParamValue === 'VIEW') {
      return [
        { name: 'Overview', key: 'OVERVIEW' },
        { name: 'Funds', key: 'FUND' },
        { name: 'Process', key: 'PROCESS' },
        { name: 'Documents', key: 'DOCUMENT' },
        { name: 'Payments', key: 'PAYMENT' },
      ];
    } else if (
      UrlParamValue === 'ANGEL_INVESTOR_REVIEW' ||
      UrlParamValue === 'YARDSTICK_USER_REVIEW'
    ) {
      return [
        { name: 'Overview', key: 'OVERVIEW' },
        { name: 'Payments', key: 'PAYMENT' },
      ];
    } else {
      return [{ name: 'Overview', key: 'OVERVIEW' }];
    }
  }, []);

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  const getVcFirmDocuments = (vcFirmId: string) => {
    if (!isEmpty(vcFirmId)) {
      setDocumentLoading(true);
      setDocumentErrorMessage('');
      getAdminVcFirmDocumentsByInvestor(investorId, {
        type: 'VC_FIRM',
        vcFirmId: vcFirmId,
      })
        .then((res: any) => {
          setDocumentLoading(false);
          setVcFirmDocuments(res.data);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setDocumentErrorMessage(message);
          setDocumentLoading(false);
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');
    getVcFirmDetailsByInvestor(investorId)
      .then((res) => {
        setVcDetails(res.data);
        setIsLoading(false);
        getVcFirmDocuments(res.data.id);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');
    getVcUsersDetailsByInvestor(investorId)
      .then((res: any) => {
        setVcUserDetails(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  return (
    <Box className={classes.vcFirmContainer}>
      <Box className={classes.vcFirmHead}>
        <VcFirmInfo
          investorId={investorId}
          UrlParamValue={UrlParamValue}
          vcUserDetails={vcUserDetails}
          vcDetails={vcDetails}
          setVcDetails={setVcDetails}
          vcFirmDocuments={vcFirmDocuments}
          isLoading={isLoading}
        />
      </Box>
      <Box className={classes.vcFirmContentText}>
        <Tabs
          tabs={tabs}
          value={value}
          handleChange={handleChange}
          className={classes.enquiryTabs}
          keyAsValue={true}
        />
        <TabPanel value={value} index={'OVERVIEW'}>
          <VcFirmOverview
            setValue={setValue}
            vcDetails={vcDetails}
            investorId={investorId}
            setIsLoading={setIsLoading}
            setErrorMessage={setErrorMessage}
            UrlParamValue={UrlParamValue}
            UrlParamStatus={UrlParamStatus}
            vcUserDetails={vcUserDetails}
            isLoading={isLoading}
            errorMessage={errorMessage}
          />
        </TabPanel>
        <TabPanel value={value} index={'FUND'} className={classes.tanPanelBox}>
          <VcFirmFund
            investorId={investorId}
            vcDetails={vcDetails}
            isLoading={isLoading}
            errorMessage={errorMessage}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={'PROCESS'}
          className={classes.tanPanelBox}
        >
          <VcFirmProcess
            setVcDetails={setVcDetails}
            vcDetails={vcDetails}
            investorId={investorId}
            documentLoading={documentLoading}
            vcFirmDocuments={vcFirmDocuments}
            getVcFirmDocuments={(vcFirmId: string) =>
              getVcFirmDocuments(vcFirmId)
            }
            documentErrorMessage={documentErrorMessage}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={'DOCUMENT'}
          className={classes.tanPanelBox}
        >
          <VcFirmDocument
            investorId={investorId}
            documentLoading={documentLoading}
            vcFirmDocuments={vcFirmDocuments}
            documentErrorMessage={documentErrorMessage}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={'PAYMENT'}
          className={classes.tanPanelBox}
        >
          <TransactionHistory
            investorId={investorId}
            firmType={get(vcDetails, 'firmType')}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default VcFirm;
