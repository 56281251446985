import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';
import { emailYup, phoneNumberYup } from 'common/utils/validations';

export const ClientFormSchema = yup.object().shape({
  name: yup.string().required(requiredConstant),
  email: emailYup,
  phone: phoneNumberYup,
  clientId: yup.string().nullable(),
});
