import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  vcFirmContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  vcFirmAdminBox: {
    height: 'calc(100vh - 20px)',
    width: '100%',
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  vcFirmHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '40px 40px 30px 60px',
    '@media (max-width: 1180px)': {
      padding: '30px 40px 20px 40px',
    },
    '@media (max-width: 1024px)': {
      padding: '80px 20px 20px',
    },
    '@media (max-width: 767px)': {
      padding: '20px 15px',
      display: 'block',
    },
  },
  headText: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    fontWeight: 400,
    letterSpacing: 0.3,
    margin: 0,
    '@media (max-width: 767px)': {
      fontSize: 20,
    },
  },
  vcFirmContentText: {
    padding: '0px 40px 0px 60px',
    '@media (max-width: 1180px)': {
      padding: '0px 40px 0px 40px',
    },
    '@media (max-width: 1024px)': {
      padding: '0px 20px 25px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 12.5px 25px',
    },
  },
  enquiryTabs: {
    borderBottom: '0.5px solid #DEDEDE',
  },
  tanPanelBox: {
    height: 'calc(100vh - 210px)',
    overflow: 'hidden auto',
    boxShadow: theme.shadows[2],
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 235px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 225px)',
    },
  },
  // admin
  enquiryListBox: {
    padding: 20,
    height: '100%',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    display: 'block',
    padding: 20,
  },
  searchBox: {
    borderRadius: 30,
    border: '1px solid #E5E5E5',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 257,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  clearIcon: {
    padding: 5,
  },
  inputSearch: {
    width: 'calc(100% - 10px)',
    paddingLeft: 14,
  },
  textValInput: {
    background: theme.palette.common.white,
    margin: 0,
    width: 130,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  menuPaper: {
    maxHeight: 200,
  },
  rightBox: {
    display: 'flex',
    '@media (max-width: 600px)': {
      paddingTop: 16,
    },
  },
  firmAdminTableBox: {
    padding: '0px 40px 20px 60px',
    height: 'calc(100% - 160px)',
    '@media (max-width: 1180px)': {
      padding: '0px 40px 20px 40px',
    },
    '@media (max-width: 1024px)': {
      padding: '0px 20px 20px',
    },
  },
  tableContainer: {
    boxShadow: theme.shadows[2],
    // maxHeight: '100%',
    height: '100%',
    minHeight: 200,
    overflow: 'overlay',
    borderRadius: 11,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '26px',
      padding: '16px 8px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 16,
      padding: '10px 8px',
      textAlign: 'left',
      wordBreak: 'break-word',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },

  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  actionButtonBox: {
    display: 'flex',
    // justifyContent: 'center',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  viewButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 6,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    width: 71,
    height: 31,
    border: '1px solid #1EA59A',
    textTransform: 'capitalize',
    '@media (max-width: 768px)': {
      width: '80%',
    },
  },
  vNoSpacing: {
    padding: '0 !important',
  },
  vHeadBg: {
    background: '#FFF !important',
  },
  vTitleSpacing: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  actionVcView: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 6,
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    width: 65,
    height: 31,
    border: '1px solid #1EA59A',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
    },
  },
  vCollapseTable: {
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 14,
      padding: '10px 8px',
      textAlign: 'center',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
  },
}));
