import React from 'react';
import { Typography } from '@material-ui/core';
import { get, isEmpty, sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import { Box, Button, FileUpload } from 'components';
import history from 'common/utils/history';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  addDealDocument,
  createOrUpdateDeal,
  getSignedUrlForDealDocument,
} from 'services';
import { DealCreationProps } from 'common/types';
import { RootState } from 'redux-modules/store/rootState';

import styles from './styles';

const BankDetails = ({
  handleBack,
  deal,
  dealId,
  setDeal,
}: DealCreationProps) => {
  const classes = styles();

  const { dealList } = useSelector(({ Deals }: RootState) => Deals);

  const [loadingSignedUrl, setLoadingSignedUrl] = React.useState<boolean>(
    false,
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [previousFile, setPreviousFile] = React.useState<any>();

  const uploadOnSelect = async (fileuploadReq: any): Promise<void> => {
    setErrorMessage('');
    const fileExtension = get(fileuploadReq, 'filename', '').split('.').pop();
    const validFileExt = ['pdf'].includes(fileExtension);
    if (!isEmpty(fileuploadReq) && validFileExt) {
      setIsLoading(true);
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        const res = await addDealDocument({
          dealId,
          fileName: get(fileuploadReq, 'filename'),
          documentName: 'BANK_DETAILS_DOC',
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
        });
        setIsLoading(false);
        setDeal(res);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else if (!isEmpty(fileuploadReq) && !validFileExt) {
      setErrorMessage('Please upload pdf file only');
    } else {
      setErrorMessage('Please upload bank detail.');
    }
  };

  const uploadedFiles = () => {
    const allDeal = JSON.parse(JSON.stringify(dealList));
    const sortedData = sortBy(
      allDeal.filter((item: any) => get(item, 'id') !== dealId),
      ['createdAt'],
    );
    const previousDeal: any = !isEmpty(sortedData)
      ? sortedData[sortedData.length - 1]
      : {};
    if (
      isEmpty(get(deal, 'bankdetailLink')) &&
      !isEmpty(get(previousDeal, 'bankdetailLink')) &&
      isEmpty(previousFile)
    ) {
      setPreviousFile({
        filename: get(previousDeal, 'bankdetailFilename'),
        documentUrl: get(previousDeal, 'bankdetailLink'),
        accessUrl: get(previousDeal, 'bankdetailAccessLink'),
      });
      return [
        {
          filename: get(previousDeal, 'bankdetailFilename'),
          documentUrl: get(previousDeal, 'bankdetailLink'),
        },
      ];
    } else {
      return isEmpty(get(deal, 'bankdetailLink'))
        ? []
        : [
            {
              filename: get(deal, 'bankdetailFilename'),
              documentUrl: get(deal, 'bankdetailLink'),
            },
          ];
    }
  };

  const confirmDetail = async () => {
    if (!isEmpty(get(previousFile, 'accessUrl'))) {
      setErrorMessage('');
      setIsLoading(true);
      const payload = {
        ...deal,
        bankdetailFilename: get(previousFile, 'filename'),
        bankdetailLink: get(previousFile, 'accessUrl'),
      };
      try {
        const res = await createOrUpdateDeal(payload);
        setDeal(res);
        history.push(`/admin/deals`);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      history.push(`/admin/deals`);
    }
  };

  return (
    <Box>
      <Typography variant="h3" className={classes.dealCreationHeader}>
        Bank Account details
      </Typography>
      <Typography className={classes.bankDetailText}>
        Upload Bank account details of the Fund.
      </Typography>
      <Box className={classes.uploadFileBox}>
        <FileUpload
          getSignedUrl={getSignedUrlForDealDocument}
          uploadedFiles={
            !isEmpty(previousFile) ? [previousFile] : uploadedFiles()
          }
          setLoadingSignedUrl={setLoadingSignedUrl}
          uploadOnSelect={uploadOnSelect}
          requestObject={{
            documentName: 'BANK_DETAILS_DOC',
            dealId: dealId,
          }}
          content={
            <Button
              name="Upload Bank Account Detail"
              className={classes.uploadBtn}
              disabled={loadingSignedUrl || isLoading}
              isLoading={loadingSignedUrl || isLoading}
            />
          }
        />
      </Box>
      {errorMessage && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.errorMessage}
        >
          {errorMessage}
        </Typography>
      )}
      <Box className={classes.nextPrevButtonBox}>
        <Button
          variant="outlined"
          className={classes.prevButton}
          name="Previous"
          disabled={loadingSignedUrl || isLoading}
          onClick={() => handleBack()}
        />
        <Button
          className={classes.nextButton}
          name={'Confirm'}
          disabled={loadingSignedUrl || isLoading}
          onClick={() => confirmDetail()}
        />
      </Box>
    </Box>
  );
};
export default BankDetails;
