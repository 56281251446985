import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import { isEmpty, get } from 'lodash';
import { useParams } from 'react-router-dom';

// import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
// import DescriptionIcon from '@material-ui/icons/Description';
// import AttachFileIcon from '@material-ui/icons/AttachFile';
import { pdfIcon, documentIcon, IconImg } from 'assets';
import { RootState } from 'redux-modules/store/rootState';
import { Box, Button, Dialog, Loader, ConfirmDialog } from 'components';
import {
  approveDocuments,
  emptySuccessMessage,
} from 'redux-modules/process/Actions';

import styles from './styles';

type ReviewDocumentProps = {
  startupId: string | undefined;
  fundType?: string | undefined;
  isRoleAdmin: boolean;
};

type DocuSignReqDoc = {
  createdAt: string;
  documentName: string;
  documentStatus: string;
  documentType: string;
  documentUrl: string | null;
  docusignDocumentGuid: string;
  docusignDocumentId: string;
  docusignTemplateId: string;
  filename: string;
  loanType: string;
  startupId: string;
  id: string;
  uploadedAt: string;
  verifiedAt: string;
};

const ReviewDocuments = ({
  startupId,
  fundType,
  isRoleAdmin,
}: ReviewDocumentProps) => {
  const classes = styles();
  const [selectedDocId, setSelectedDocId] = useState('');
  const [isOpen, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [document, setDocument] = useState<any>('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState({});

  const { Documents, isLoading, successMessage } = useSelector(
    ({ Process }: RootState) => Process,
  );
  const { documents = [] } = Documents;
  // const { email } = useSelector(({ Auth }: RootState) => Auth.user);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { id: enquiryId } = useParams();

  const dispatch = useDispatch();

  const callback = (response: any) => {
    const modifiedDocuments =
      documents &&
      documents.length > 0 &&
      documents.map((document: DocuSignReqDoc) => {
        if (response && response.length > 0) {
          const selectedDoc = response.filter((item: any) => {
            if (item.id === document.id) {
              const updatedDoc = JSON.parse(JSON.stringify(item));
              updatedDoc.documentUrl = document.documentUrl;

              return updatedDoc;
            }
          });
          if (selectedDoc && selectedDoc.length) {
            return selectedDoc[0];
          }

          return document;
        }

        return document;
      });

    return modifiedDocuments;
  };

  useEffect(() => {
    if (successMessage) {
      setOpen(false);
    }
  }, [successMessage]);

  const handleApproveDocument = (id: string, action: string) => {
    const payload = {
      documentId: id,
      enquiryId,
      action,
      startupId,
      // approvedBy: email,
      loanType: fundType,
      callback,
    };
    console.log(payload) //eslint-disable-line
    setSelectedDocId(id);
    dispatch(approveDocuments(payload));
    setDialogOpen(false);
  };

  const handleRenderFile = (ext: string, doc: any) => {
    switch (ext) {
      case 'img':
        return (
          <img
            src={doc.documentUrl}
            alt={doc.filename}
            className={classes.bGDocuImg}
            onClick={() => {
              setOpen(true);
              setUrl(doc.documentUrl);
              setDocument(doc);
              dispatch(emptySuccessMessage());
            }}
          />
        );
      case 'doc':
        return (
          <a href={doc.documentUrl} target="_blank" rel="noreferrer">
            <img
              src={documentIcon}
              alt={documentIcon}
              className={classes.bGDocuImg}
            />
          </a>
        );
      case 'pdf':
        return (
          <a href={doc.documentUrl} target="_blank" rel="noreferrer">
            <img src={pdfIcon} alt={pdfIcon} className={classes.bGDocuImg} />
          </a>
        );
      default:
        return (
          <a href={doc.documentUrl} target="_blank" rel="noreferrer">
            <img
              src={documentIcon}
              alt={documentIcon}
              className={classes.bGDocuImg}
            />
          </a>
        );
    }
  };

  const handleFileType = (doc: any) => {
    const filename = doc.filename;
    let ext = filename && filename.substr(filename.lastIndexOf('.') + 1);
    if (['png', 'jpg', 'jpeg'].includes(ext)) {
      ext = 'img';
    }
    if (['doc', 'docx'].includes(ext)) {
      ext = 'doc';
    }
    return handleRenderFile(ext, doc);
  };

  return (
    <Box style={{ width: '100%' }}>
      {/* <Grid container spacing={3} className={classes.noDocText}> */}
      {!isRoleAdmin && isLoading && (
        <Box className={classes.loaderSectionView}>
          <Loader />
        </Box>
      )}
      <Box className={classes.noDocText}>
        {isLoading && isRoleAdmin && (
          <Box className={classes.loaderSection}>
            <Loader className={classes.loaderBox} />
          </Box>
        )}
        {!isLoading &&
          !isEmpty(documents) &&
          documents.map((doc: any) => (
            // <Grid item xs={6} md={3} key={doc.id}>
            <Box key={doc.id} className={classes.reviewDocuBox}>
              {handleFileType(doc)}
              {doc.documentType === 'DOCU_SIGN_DOCUMENTS' ? (
                <Typography className={classes.reviewDocuText}>
                  {doc.filename}
                </Typography>
              ) : (
                <Typography className={classes.reviewDocuText}>
                  {doc.documentName}
                </Typography>
              )}
              {doc.documentStatus !== 'VERIFIED' &&
                doc.documentStatus !== 'REJECTED' &&
                isRoleAdmin && (
                  <Box className={classes.approvedBtnBox}>
                    <Button
                      name="Approve"
                      className={classes.approveBtn}
                      onClick={() => {
                        setDialogOpen(true);
                        setDialogAction({ id: doc.id, action: 'VERIFIED' });
                      }}
                      isLoading={isLoading && selectedDocId === doc.id}
                      // disabled={isLoading}
                    />
                    <Button
                      name="Reject"
                      className={classes.rejectBtn}
                      onClick={() => {
                        setDialogOpen(true);
                        setDialogAction({ id: doc.id, action: 'REJECTED' });
                      }}
                      isLoading={isLoading && selectedDocId === document.id}
                    />
                  </Box>
                )}

              {doc.documentStatus === 'VERIFIED' && (
                <Box className={classes.approvedBox}>
                  <img src={IconImg} className={classes.approvIcon} />
                  <Button
                    icon={
                      <CheckCircleOutlineRoundedIcon
                        className={classes.circleOutline}
                      />
                    }
                    name={'Approved'}
                    onClick={() => {
                      setDialogOpen(true);
                      setDialogAction({ id: doc.id, action: 'REJECTED' });
                    }}
                    className={classes.approvedbtn}
                  />
                </Box>
              )}

              {doc.documentStatus === 'REJECTED' && (
                <Box className={classes.rejectedBox}>
                  <CancelIcon className={classes.cancelIcon} />
                  <Button
                    onClick={() => {
                      setDialogOpen(true);
                      setDialogAction({ id: doc.id, action: 'VERIFIED' });
                    }}
                    icon={
                      <CheckCircleOutlineRoundedIcon
                        className={classes.circleOutline}
                      />
                    }
                    name={'Rejected'}
                    className={classes.rejectedTxtBtn}
                  />
                </Box>
              )}
            </Box>
          ))}
        {/* </Grid> */}
      </Box>
      {!isLoading && isEmpty(documents) && (
        <Typography>Admin didnt requested any document</Typography>
      )}
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        subheading={''}
        title={get(document, 'documentName', '')}
        handleClose={() => {
          setOpen(false);
        }}
      >
        <img
          src={url}
          alt={get(document, 'filename')}
          className={classes.previewImg}
        />
        {get(document, 'documentStatus') !== 'VERIFIED' &&
          get(document, 'documentStatus') !== 'REJECTED' &&
          isRoleAdmin && (
            <Box className={classes.approveImageButton}>
              <Box className={classes.approvedBtnBox}>
                <Button
                  name="Approve"
                  className={classes.approveBtnPreview}
                  onClick={() => {
                    setDialogOpen(true);
                    setDialogAction({ id: document.id, action: 'REJECTED' });
                  }}
                  isLoading={isLoading && selectedDocId === document.id}
                />
                <Button
                  name="Reject"
                  className={classes.rejectBtnPreview}
                  onClick={() => {
                    setDialogOpen(true);
                    setDialogAction({ id: document.id, action: 'REJECTED' });
                  }}
                  isLoading={isLoading && selectedDocId === document.id}
                />
              </Box>
            </Box>
          )}
        {get(document, 'documentStatus') === 'VERIFIED' && (
          <Box className={classes.approveImageButton}>
            <Button
              icon={
                <CheckCircleOutlineRoundedIcon
                  className={classes.circleOutline}
                />
              }
              onClick={() => {
                setDialogOpen(true);
                setDialogAction({ id: document.id, action: 'REJECTED' });
              }}
              name={'Approved'}
              className={classes.approveFont}
            />
          </Box>
        )}

        {get(document, 'documentStatus') === 'REJECTED' && (
          <Box className={classes.approveImageButton}>
            <Button
              icon={
                <CheckCircleOutlineRoundedIcon
                  className={classes.circleOutline}
                />
              }
              onClick={() => {
                setDialogOpen(true);
                setDialogAction({ id: document.id, action: 'VERIFIED' });
              }}
              name={'Rejected'}
              className={classes.rejectedFont}
            />
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        dialogOpen={dialogOpen && !isEmpty(get(dialogAction, 'id'))}
        onClose={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
        dialogHeading={`${
          get(dialogAction, 'action') === 'VERIFIED' ? 'Approve' : 'Reject'
        } document`}
        dialogContent={`Are you sure you want to ${
          get(dialogAction, 'action') === 'VERIFIED' ? 'approve' : 'reject'
        }  this document`}
        isLoading={isLoading}
        onConfirm={() =>
          handleApproveDocument(
            get(dialogAction, 'id'),
            get(dialogAction, 'action'),
          )
        }
      />
    </Box>
  );
};

export default ReviewDocuments;
