import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  subscriptionForm: {
    padding: '50px 170px 35px',
    '@media (max-width: 1125px)': {
      padding: '50px 5% 35px',
    },
    '@media (max-width: 1024px)': {
      padding: '0px 5% 35px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5% 15px',
    },
  },
  investNowFormHeader: {
    fontSize: 32,
    letterSpacing: 0.3,
    color: theme.palette.grey[500],
    marginBottom: 35,
    fontWeight: 400,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '0px 0 20px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 12,
    display: 'block',
    textAlign: 'left',
  },
  validateForm: {
    paddingTop: 10,
    '& .MuiGrid-item': {
      padding: '5px 15.5px 0',
    },
  },
  controllerBox: {
    position: 'relative',
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInput: {
    marginBottom: 30,
    width: '50%',
    '@media (max-width: 1024px)': {
      width: '70%',
    },
    '@media (max-width: 525px)': {
      width: '100%',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  prevButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    marginRight: 18,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));
