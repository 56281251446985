import React, { useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';

import history from 'common/utils/history';
import { Sidebar, Box, Stepper } from 'components';
import { Logo, Ornament } from 'assets';
import { getBatches } from 'redux-modules/auth/Actions';
import {
  emptyEnquiryDetails,
  emptyEnquiryState,
  handleGetEnquiriesbyId,
  trackBridgeLoanProcess,
} from 'redux-modules/enquiries/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { PayloadType, StartupOnboardStepProps } from 'common/types';
import { getAdminLoanTerms } from 'services';
import { emptyEnquiryForm } from 'redux-modules/enquiryForm/Actions';

import {
  BasicDetails,
  LoanTerms,
  OtherInformationDetails,
  UploadDocuments,
  DocuSignView,
} from './Steps';
import styles from './styles';

const typeSteps = [
  'BASIC_DETAILS_FORM',
  'LOAN_TERMS',
  'SUPPORTING_DOCUMENTS',
  'E_SIGNATURE',
];

function getSteps() {
  return [
    { name: 'Basic Details', key: 'BASIC_DETAILS_FORM' },
    { name: 'Loan Terms', key: 'LOAN_TERMS' },
    { name: 'Upload Documents', key: 'UPLOAD_DOCUMENTS' },
    { name: 'E-Signature', key: 'E_SIGNATURE' },
  ];
}

function getStepContent(step: number, props: StartupOnboardStepProps) {
  switch (step) {
    case 0:
      return <BasicDetails {...props} />;
    case 1:
      return <LoanTerms {...props} />;
    case 2:
      return <UploadDocuments {...props} />;
    case 3:
      return <DocuSignView {...props} />;
    default:
      return <OtherInformationDetails />;
  }
}

const StartupOnboard = ({ match }: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const id = get(match, 'params.id');
  const search = history.location.search;
  const event = new URLSearchParams(search).get('event');
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(0);
  const [stepName, setStepName] = React.useState<string>(typeSteps[activeStep]);
  const [allStepDisable, setAppStepDisable] = React.useState<boolean>(false);
  const [enquiryId, setEnquiryId] = React.useState<string>('');
  const [loadingTerms, setLoadingTerms] = React.useState<boolean>(false);
  const [loanTerms, setLoanTerms] = React.useState<any>({});
  const [scheduled, setScheduled] = React.useState<boolean>(false);
  const [hasYcScreenshot, setHasYcScreenshot] = React.useState<boolean>(false);

  const { enquiryLog, enquiryView } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );
  const {
    startupDetails: { startupId },
  } = enquiryView;

  const { bridgeLoanProcess, isLoading } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (!get(bridgeLoanProcess, 'documentsSigned'))
      setCompletedSteps(activeStep + 1);
    setStepName(typeSteps[activeStep + 1]);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setStepName(typeSteps[activeStep - 1]);
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
    setStepName(typeSteps[index]);
  };

  const handleClose = () => {
    dispatch(emptyEnquiryDetails());
    dispatch(emptyEnquiryForm());
    dispatch(emptyEnquiryState());
    setCompletedSteps(0);
    setEnquiryId('');
    history.push('/admin/enquiry-list');
  };

  useEffect(() => {
    if (!isEmpty(startupId)) {
      setLoadingTerms(true);
      getAdminLoanTerms(`${startupId}/BRIDGE_LOAN`)
        .then((res: any) => {
          if (get(res, 'meetingScheduled')) {
            setAppStepDisable(true);
            setScheduled(true);
          }
          setLoanTerms(res);
        })
        .finally(() => setLoadingTerms(false));
    }
  }, [startupId]);

  useEffect(() => {
    setCompletedSteps(0);
    setEnquiryId('');
    dispatch(emptyEnquiryDetails());
    dispatch(emptyEnquiryForm());
    dispatch(emptyEnquiryState());
    dispatch(getBatches());
  }, []);

  useEffect(() => {
    if (id !== 'NEW') {
      setEnquiryId(id);
    }
  }, [id]);

  useEffect(() => {
    if (!isEmpty(enquiryId)) {
      const obj: PayloadType = {
        id: enquiryId,
      };
      dispatch(handleGetEnquiriesbyId(obj));
    }
  }, [enquiryId]);

  useEffect(() => {
    if (!isEmpty(startupId) && id !== 'NEW')
      dispatch(trackBridgeLoanProcess(startupId));
  }, [startupId]);

  useEffect(() => {
    if (event) {
      setCompletedSteps(3);
      if (event === 'signing_complete') {
        setAppStepDisable(true);
      }
    }
    if (!event && bridgeLoanProcess && !isLoading && hasYcScreenshot) {
      if (get(bridgeLoanProcess, 'documentsSigned')) {
        setActiveStep(3);
        setCompletedSteps(4);
        setStepName(typeSteps[3]);
      } else if (get(bridgeLoanProcess, 'documentsSubmitted')) {
        setActiveStep(3);
        setCompletedSteps(3);
        setStepName(typeSteps[3]);
      } else if (get(bridgeLoanProcess, 'loanTermAdded')) {
        setActiveStep(2);
        setCompletedSteps(2);
        setStepName(typeSteps[2]);
      } else if (get(bridgeLoanProcess, 'basicInfoAdded')) {
        setActiveStep(1);
        setCompletedSteps(1);
        setStepName(typeSteps[1]);
      }
    } else {
      setActiveStep(0);
      setCompletedSteps(0);
      setStepName(typeSteps[0]);
    }
  }, [bridgeLoanProcess, scheduled, isLoading, loanTerms]);

  return (
    <div className={classes.root}>
      <Box className={classes.dashContainer}>
        <Box className={classes.dashleft}>
          <Sidebar className={classes.sidebarFixed}>
            <Box className={classes.dashLogo}>
              <img src={Logo} alt={Logo} className={classes.logoImg} />
              <Typography variant="h3" className={classes.logoText}>
                Application
              </Typography>
            </Box>
            {!isLoading && (
              <Stepper
                activeStep={activeStep}
                completedSteps={completedSteps}
                steps={steps}
                orientation={true}
                handleStepClick={handleStepClick}
                allStepDisable={allStepDisable}
              />
            )}
          </Sidebar>
          {/* <Box>{Buttons()}</Box> */}
        </Box>
        {!isLoading && isEmpty(get(enquiryLog, 'errorMessage')) ? (
          <Box className={classes.dashRight}>
            <Box className={classes.dashHeader}>
              <Typography
                variant="caption"
                className={classes.closeIcon}
                onClick={handleClose}
              >
                <CloseIcon />
              </Typography>
              <img
                src={Ornament}
                alt={Ornament}
                className={classes.imgOrnament}
              />
            </Box>
            {getStepContent(activeStep, {
              handleNext: handleNext,
              handleBack: handleBack,
              stepName,
              id,
              startUpId: startupId,
              type: id === 'NEW' ? 'NEW' : 'EDIT',
              enquiryId,
              setEnquiryId,
              loadingTerms,
              scheduled,
              setScheduled,
              setHasYcScreenshot,
            })}
          </Box>
        ) : (
          <div className={classes.skeletonWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Skeleton
                  style={{ marginBottom: 10 }}
                  count={10}
                  enableAnimation
                  height={32}
                />
              </Grid>
              <Grid item xs={9}>
                <Skeleton
                  style={{ marginBottom: 10 }}
                  count={10}
                  enableAnimation
                  height={32}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Box>
    </div>
  );
};

export default StartupOnboard;
