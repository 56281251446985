import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  benchmarkTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 15px 15px 0',
    '@media (max-width: 767px)': {
      padding: '0px 0px 15px 0',
    },
  },
  benchmarkFilter: {
    display: 'flex',
    alignItems: 'center',
    width: 250,
    '@media (max-width: 767px)': {
      paddingRight: 0,
      width: 'auto',
    },
  },
  chartItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  benchmarkTitle: {
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: '0.3px',
    fontWeight: 600,
    '@media (max-width: 767px)': {
      paddingTop: 0,
    },
    '@media (max-width: 375px)': {
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  benchmarkContent: {
    paddingTop: 35,
    paddingLeft: 10,
  },
  benchmarkFilterTitle: {
    textAlign: 'end',
    width: 80,
    paddingRight: 15,
    '@media (max-width: 525px)': {
      paddingLeft: 10,
      paddingRight: 5,
      width: 50,
    },
  },
  filterInput: {
    marginTop: 0,
    width: 190,
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  textValInput: {
    marginBottom: 0,
    marginTop: 0,
    width: '100%',
    '@media (max-width: 1120px)': {
      marginBottom: 0,
      marginTop: 0,
    },
  },
  inputRoot: {
    width: '100%',
    height: 41,
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  menuPaper: {
    maxHeight: 150,
  },
  benchOutputChart: {
    maxWidth: '1080px',
    margin: '0 auto',
    // paddingLeft: 20,
    // '@media (max-width: 767px)': {
    //   margin: '0 auto',
    // },
  },
  chartBtns: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'flex-end',
    // maxWidth: '1040px',
    // margin: '0em auto 1em',
  },
  chartBtnsBox: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    textAlign: 'center',
  },
  chartBtnsBar: {
    borderRadius: 0,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    outline: 'none',
    border: 'none',
    // padding: '0.5em 0.6em 0.4em 1em',
    padding: '0.2em 0.4em',
    cursor: 'pointer',
  },
  chartBtnsLine: {
    borderRadius: 0,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    outline: 'none',
    border: 'none',
    // padding: '0.5em 1em 0.4em 0.6em',
    padding: '0.2em 0.4em',
    cursor: 'pointer',
  },
  growthText: {
    marginLeft: 30,
  },
  growthCircle: {
    width: 14,
    height: 14,
    backgroundColor: '#1EA59A',
    display: 'inline-block',
    borderRadius: 100,
  },
}));
