import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  confirmPwdBox: {
    // height: 'calc(100vh - 104px)',
    maxWidth: 370,
    margin: 'auto',
    padding: '100px 20px 20px',
    textAlign: 'center',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'relative',
    top: -25,
    display: 'block',
    textAlign: 'left',
  },
  errorMsgText: {
    color: 'red',
    fontSize: 18,
    marginBottom: 25,
  },
  successImg: {
    marginBottom: 30,
    height: 250,
  },
  welcome8vdx: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.warning.contrastText,
  },
  welcomeSub8vdx: {
    color: theme.palette.warning.light,
    fontSize: 16,
    fontWeight: 400,
    padding: '0 15px',
  },
  validateForm: {
    paddingTop: 43,
  },
  textValInput: {
    marginBottom: 30,
    marginTop: 0,
    width: '100%',
  },
  inputRoot: {
    fontSize: 16,
    width: '100%',
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  signupBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '15px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    width: 240,
    textTransform: 'capitalize',
    marginTop: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));
