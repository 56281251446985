import {
  ADMIN_GET_ALL_INVESTORS,
  ADMIN_GET_ALL_INVESTORS_SUCCESS,
  ADMIN_GET_ALL_INVESTORS_FAILURE,
} from './Actions';
import { IAdminInvestorState, InvestorAction } from './Types';
export const InvestorState: IAdminInvestorState = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  investorList: [],
  investorView: {
    name: '',
    linkedinUrl: '',
    otherUrl: '',
    twitterUrl: '',
    crunchbaseUrl: '',
  },
  investorLog: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
};
const InvestorReducer = (state = InvestorState, action: InvestorAction) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_GET_ALL_INVESTORS:
      return {
        ...state,
        isLoading: true,
      };
    case ADMIN_GET_ALL_INVESTORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        investorList: payload,
      };
    case ADMIN_GET_ALL_INVESTORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        investorList: [],
        errorMessage: payload,
      };
    default:
      return state;
  }
};

export default InvestorReducer;
