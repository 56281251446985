import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },

  pointText: {
    fontSize: 15,
    letterSpacing: '0.35px',
    marginBottom: 20,
  },

  strongText: {
    // display: 'block',
    marginBottom: 7,
    fontWeight: 700,
  },
  linkHere: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: 600,
    display: 'inline-block',
  },

  continueBtnBox: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 15,
    cursor: 'pointer',
    height: 58,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    marginTop: 20,
    marginBottom: 10,
  },

  contactUsText: {
    fontWeight: 1000,
    marginBottom: 3,
  },
  dummyBox: {
    width: 10,
  },
  closeIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: 4,
    right: 3,
    '&:hover': {
      background: 'transparent',
    },
  },
  // DocuSign View ---------- Start
}));
