import React from 'react';
import { Divider } from '@material-ui/core';

import { Box } from 'components';
import ActionsList from 'components/ActionsList';

import styles from '../styles';

const AdminAction = () => {
  const classes = styles();
  return (
    <>
      <Divider className={classes.dividerLine} />
      <Box className={classes.dashActionProfile}>
        <ActionsList status="PENDING" />
      </Box>
    </>
  );
};

export default AdminAction;
