import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tabPanelContent: {
    padding: '25px 15px 20px',
    '@media (max-width: 1024px)': {
      // padding: '10px 0px 20px',
    },
  },
  overViewSubHeadTitle: {
    fontSize: 16,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    lineHeight: '30px',
    marginBottom: 5,
    letterSpacing: '0.3px',
  },
  overViewHeadTitle: {
    fontSize: 20,
    color: theme.palette.grey[500],
    marginBottom: 20,
    paddingTop: 20,
    lineHeight: '30px',
    letterSpacing: '0.3px',
  },
  descTitletext: {
    fontSize: 15,
    color: theme.palette.grey[600],
    marginBottom: 20,
    lineHeight: '25px',
    letterSpacing: '0.3px',
  },
  // funds
  enquiryListBox: {
    padding: 20,
    height: '100%',
  },
  enquiryFundListBox: {
    padding: '20px 14px',
    height: '100%',
    '@media (max-width: 1024px)': {
      padding: '20px 14px',
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    display: 'block',
  },
  firmTableContainer: {
    boxShadow: theme.shadows[0],
    maxHeight: 540,
    minHeight: 300,
    overflow: 'overlay',
    borderRadius: 11,
    paddingTop: 10,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '26px',
      padding: '16px 8px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 16,
      padding: '8px',
      textAlign: 'left',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },
  tableContainer: {
    boxShadow: theme.shadows[2],
    maxHeight: 340,
    minHeight: 200,
    overflow: 'overlay',
    borderRadius: 11,
    marginTop: 20,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '26px',
      padding: '16px 8px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 16,
      padding: '10px 8px',
      textAlign: 'left',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },
  // tableHead: {
  //   backgroundColor: '#1EA59A !important',
  // },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  summaryAccordRight: {
    // width: '50%',
    textAlign: 'right',
  },
  didntRequestText: {
    padding: 20,
    textAlign: 'center',
    fontSize: 16,
  },
  statusBtn: {
    background: theme.palette.grey[800],
    color: theme.palette.primary.contrastText,
    height: 28,
    fontSize: 12,
    cursor: 'auto',
    borderRadius: 4,
    marginRight: 15,
    alignSelf: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.grey[800],
      color: theme.palette.primary.contrastText,
    },
  },
  moreIcon: {
    alignSelf: 'center',
    color: theme.palette.primary.main,
    width: 30,
    height: 30,
    marginLeft: 12,
    position: 'relative',
    top: 10,
  },
  verifiedBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 28,
    fontSize: 12,
    cursor: 'auto',
    borderRadius: 4,
    marginRight: 15,
    alignSelf: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.grey[800],
      color: theme.palette.primary.contrastText,
    },
  },
  loaderSection: {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    background: '#ffffff7d',
    height: '70%',
    marginTop: 40,
  },
  loaderBox: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '35%',
  },
  bGDocuImg: {
    height: 100,
    width: 106,
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  documentListComponent: {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-around',
    padding: '5px 20px 10px 0',
  },
  reviewDocuBox: {
    textAlign: 'center',
  },
  reviewDocuText: {
    fontSize: 11,
    color: theme.palette.grey[600],
    marginBottom: 10,
    overflowWrap: 'break-word',
  },
  approveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 129,
    fontSize: 16,
    cursor: 'pointer',
    height: 38,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      height: 42,
      width: 110,
    },
  },
  approvedBtn: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 129,
    fontSize: 16,
    cursor: 'pointer',
    height: 38,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      height: 42,
      width: 110,
    },
  },
  circleOutline: {
    fontSize: 10,
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  docuComponent: {
    width: '100%',
  },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  viewButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid #1EA59A',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    background: theme.palette.primary.contrastText,
    cursor: 'pointer',
    width: 71,
    height: 30,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.secondary.main,
      background: theme.palette.primary.contrastText,
    },
  },
  // documents
  image: {
    height: '100px',
    width: 'initial',
    maxWidth: '100%',
    // color: palette.text.primary,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    // borderRadius: shape.borderRadius,
    zIndex: 5,
    opacity: 1,
    cursor: 'pointer',
  },
  docuContainer: {
    display: 'grid',
    gridGap: 20,
    padding: '10px 20px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(106px, 1fr) )',
    '@media (max-width: 525px)': {
      padding: '10px',
      textAlign: 'center',
    },
  },
  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  // InvestorInfo
  enquiryListhead: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    // paddingTop: 90,
    // '@media (max-width: 1024px)': {
    //   paddingTop: 110,
    //   paddingBottom: 15,
    // },
    // '@media (max-width: 767px)': {
    //   paddingTop: 40,
    // },
  },
  profileIcon: {
    marginRight: 10,
    marginTop: 2,
  },
  enquiryListheadContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // width: 'calc(100% - 75px)',
    flex: '0 auto',
  },
  gridItemView: {
    // paddingLeft: '15px',
  },
  avatarInfoRight: {
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
      fontSize: 25,
    },
  },
  labelFundUpHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    paddingTop: 5,
    display: 'flex',
    alignItems: 'center',
  },
  activateButton: {
    backgroundColor: '#3EB961',
    color: theme.palette.common.white,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 90,
    fontSize: 10,
    height: 30,
    cursor: 'pointer',
    border: '1px solid #3EB961',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#3EB961',
      color: theme.palette.common.white,
      borderColor: '#3EB961',
    },
    marginLeft: 15,
  },
  deActivateButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    fontSize: 10,
    height: 30,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      borderColor: theme.palette.error.main,
    },
    marginLeft: 15,
  },
  backContinueBox: {
    paddingTop: 30,
    paddingRight: 10,
  },
  approvecontainerBox: {
    marginTop: 20,
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  btnSubmit: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  activateStatusBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  deactivateStausBtn: {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 120,
    fontSize: 16,
    borderColor: theme.palette.grey[700],
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 165,
    },
  },
  // Deactivate vc firm dialog
  rejectText: {
    marginBottom: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '25px',
    letterSpacing: '0.3px',
    color: theme.palette.grey[50],
  },
  rejectFirmBtn: {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 250,
    fontSize: 16,
    borderColor: theme.palette.grey[700],
    cursor: 'pointer',
    height: 38,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 165,
    },
  },
  backContinueDeactivateBox: {
    paddingTop: 20,
  },
  cancelBtn: {
    width: 143,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    cursor: 'pointer',
    height: 38,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
    marginLeft: 20,
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 125,
      marginLeft: 15,
    },
  },
  textValInput: {
    marginBottom: 25,
    marginTop: 7,
    width: '100%',
    '@media (max-width: 1120px)': {
      marginBottom: 15,
      marginTop: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000de',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
    top: -7,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  companyLabelText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
    '@media (max-width: 600px)': {
      minHeight: 'auto',
      marginBottom: 7,
    },
  },
  investorStatusPopup: {
    textAlign: 'center',
  },
  firmVCProcessBox: {
    padding: '30px 25px',
    '& .MuiAccordionSummary-expandIcon': {
      top: 24,
    },
    '@media (max-width: 525px)': {
      padding: '20px 10px',
    },
  },
  firmVCDocumentBox: {},
  firmVCPaymentBox: {
    padding: '0px 10px 30px',
  },
  //
  tanPanelBoxOverview: {
    height: 'calc(100vh - 50%)',
    overflow: 'hidden auto',
    boxShadow: theme.shadows[2],
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 50%)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 50%)',
    },
  },
  approveVcFirmBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 129,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      height: 42,
      width: 110,
    },
  },
  approveVcFirmProfileBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 199,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      height: 42,
      width: 110,
    },
  },
  rejectBtn: {
    width: 129,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    cursor: 'pointer',
    height: 38,
    borderColor: theme.palette.grey[700],
    backgroundColor: '#FFF1F1',
    color: theme.palette.grey[700],
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.grey[700],
      backgroundColor: '#FFF1F1',
      color: theme.palette.grey[700],
    },
    marginLeft: 20,
    '@media (max-width: 767px)': {
      fontSize: 13,
      height: 42,
      width: 110,
      marginLeft: 15,
    },
  },
  buttonContainer: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
    '@media (max-width: 600px)': {
      paddingTop: 15,
      paddingBottom: 30,
    },
  },
  errorRejectText: {
    color: theme.palette.error.main,
    fontSize: 13,
    bottom: 17,
  },
  rejectDocBtn: {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 221,
    fontSize: 16,
    borderColor: theme.palette.grey[700],
    cursor: 'pointer',
    height: 38,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 165,
    },
  },
  backContinueActivateBox: {
    paddingTop: 20,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  tanPanelVcReviewBox: {
    height: 'calc(100vh - 300px)',
    overflow: 'hidden auto',
    boxShadow: theme.shadows[2],
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 300px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 250px)',
    },
  },
  tanPanelBox: {
    height: 'calc(100vh - 210px)',
    overflow: 'hidden auto',
    boxShadow: theme.shadows[2],
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 235px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100vh - 225px)',
    },
  },
  warningText: {
    color: theme.palette.grey[700],
    fontSize: 16,
    fontWeight: 600,
  },
  warningContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
  warnBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 88,
    fontSize: 16,
    cursor: 'pointer',
    height: 38,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 767px)': {
      fontSize: 13,
      height: 42,
      width: 110,
    },
  },
  warnCancelBtn: {
    width: 88,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    cursor: 'pointer',
    height: 38,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
    marginLeft: 20,
    '@media (max-width: 525px)': {
      fontSize: 14,
      height: 50,
      width: 125,
      marginLeft: 15,
    },
  },
  rejectReasonText: {
    fontSize: 12,
    fontWeight: 400,
  },
  vSocialLink: {
    color: theme.palette.primary.main,
  },
  vTwoBlocks: {
    display: 'flex',
  },
  vFirstBlock: {
    marginRight: '10%',
  },
  textValInputSelect: {
    marginBottom: 10,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputHead: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '12px 40px 12px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 12px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px !important',
    },
  },
  profileSubVcAngelText: {
    fontSize: 10,
    background: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    padding: '8px 10px',
    borderRadius: 8,
    marginLeft: 15,
  },
  profileSubVcYardstickText: {
    fontSize: 10,
    background: theme.shadows[5],
    color: theme.palette.primary.contrastText,
    padding: '6px 12px',
    borderRadius: 8,
    marginLeft: 15,
  },
  profileSubVentureInsightText: {
    fontSize: 10,
    background: theme.shadows[4],
    color: theme.palette.primary.contrastText,
    padding: '6px 12px',
    borderRadius: 8,
    marginLeft: 15,
  },
  emailText: {
    margin: 0,
    color: theme.palette.info.light,
    fontSize: 14,
    fontWeight: 400,
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 11,
  },
}));
