import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  InputAdornment,
  Link,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Button,
  Dialog,
  WrappedPriceInput,
  WrappedSelect,
  WrappedTextInput,
} from 'components';
import { checkStartupsEmailReader, createBasicFcrm } from 'services';
import history from 'common/utils/history';
import { currencyListV2, fcrmRoundNameList } from 'common/utils/optionList';
// import { READER_EMAILS } from 'common/utils/constants';
import { RootState } from 'redux-modules/store/rootState';
import { getStartupInfo } from 'redux-modules/enquiries/Actions';
import { emailRegex } from 'common/utils/constants';
import { errorMessageHandler } from 'common/utils/helpers';

import { fundRaisingCRM } from './validations';
import styles from './styles';

type AddFCRMRoundDialogProps = {
  open: boolean;
  handleCloseFcrmRound: any;
  edit?: boolean;
  editRound?: Record<string, any>;
  emailReader?: string;
  linkFieldFocus?: boolean;
  basicFcrm?: any;
};
const AddFCRMRoundDialog = ({
  open,
  handleCloseFcrmRound,
  edit,
  editRound,
  emailReader,
  linkFieldFocus,
  basicFcrm,
}: AddFCRMRoundDialogProps) => {
  const dispatch = useDispatch();
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasTakenInvestment, setHasTakenInvestment] = useState<boolean>(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  const [editEmailReader, setIsEditEmailReader] = useState<boolean>(false);
  const emailReaderText = `${emailReader?.split('@')[0]}`;
  const emailReaderDomain = `${emailReader?.split('@')[1]}`;

  const { control, handleSubmit, getValues, setError } = useForm({
    mode: 'onChange',
    resolver: yupResolver(fundRaisingCRM),
  });

  const { startupInfo } = useSelector(({ Enquiries }: RootState) => Enquiries);

  useEffect(() => {
    if (!isEmpty(editRound) && editRound?.ycInvestment === 'YES') {
      setHasTakenInvestment(true);
    }
  }, [editRound]);

  const onSubmit = async () => {
    const isError = validateData();
    if (isError) return;
    if (editEmailReader) {
      const isEmailReaderError = validateEmailReader();
      if (isEmailReaderError) return;
    }
    const data: any = { ...getValues() };
    const newEmailReader = `${data.emailReaderText || ''}@${
      emailReaderDomain || ''
    }`;
    if (editEmailReader && newEmailReader !== data.emailReader) {
      try {
        await checkStartupsEmailReader(newEmailReader);
      } catch (err: any) {
        const message: string = errorMessageHandler(err);
        setError(`emailReaderText`, {
          type: 'custom',
          message: message,
        });
        return;
      }
    }
    setIsLoading(true);

    const { targetAmount, tentativeValuation } = data;
    const obj: Record<string, any> = {
      roundName: data.roundName,
      ycInvestment: data.ycInvestment,
      currency: data.currency,
      mfnStartingDate: data.mfnStartingDate,
      targetAmount: targetAmount && targetAmount.replace(/,/g, ''),
      tentativeValuation:
        tentativeValuation && tentativeValuation.replace(/,/g, ''),
      emailReader:
        !editEmailReader || newEmailReader === data.emailReader
          ? data.emailReader
          : newEmailReader,
      meetingLink: data.meetingLink,
    };
    if (edit) {
      obj.id = get(editRound, 'id');
    }
    if (get(basicFcrm, '[0].roundName') === 'EMPTY') {
      obj.id = get(basicFcrm, '[0].id');
    }
    createBasicFcrm(obj)
      .then(() => {
        setIsEditEmailReader(false);
        handleCloseFcrmRound();
        dispatch(getStartupInfo());
        if (!edit) {
          history.push('/fund-raising-crm');
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.log('ERROR WHILE SAVING FCRM BASIC ROUND DATA: ', message);
      })
      .finally(() => setIsLoading(false));
  };

  const validateData = () => {
    let targetAmount = getValues('targetAmount');
    let tentativeValuation = getValues('tentativeValuation');
    targetAmount = targetAmount && parseInt(targetAmount.replace(/,/g, ''));
    tentativeValuation =
      tentativeValuation && parseInt(tentativeValuation.replace(/,/g, ''));
    if (targetAmount > tentativeValuation) {
      setError(`tentativeValuation`, {
        type: 'custom',
        message: 'Tentative valuation must be greater than target amount',
      });
      return true;
    }
  };

  const validateEmailReader = () => {
    const emailReaderText = getValues('emailReaderText');
    const newEmailReader = `${emailReaderText || ''}@${
      emailReaderDomain || ''
    }`;
    if (!emailRegex.test(newEmailReader)) {
      setError(`emailReaderText`, {
        type: 'custom',
        message: 'Please enter valid Email ID',
      });
      return true;
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      title="Fundraising CRM"
      className={classes.funRaisingCrmDialog}
      handleClose={handleCloseFcrmRound}
    >
      <form onSubmit={handleSubmit(onSubmit)} data-testid="fundRaisingData">
        <Grid
          item
          xs={12}
          className={classes.inputHeadFull}
          style={{ height: 0, width: 0 }}
        >
          <Controller
            name="accelerator"
            control={control}
            defaultValue={get(startupInfo, 'accelerator')}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  className={classes.textValInput}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} className={classes.inputHeadFull}>
          <Typography variant="caption" className={classes.companyLabelText}>
            Round Name <span className={classes.errorText}>*</span>
          </Typography>
          <Controller
            name="roundName"
            control={control}
            defaultValue={
              (get(editRound, 'roundName') !== 'EMPTY' &&
                get(editRound, 'roundName')) ||
              'PRE_SEED'
            }
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedSelect
                  value={value}
                  onChange={onChange}
                  label=""
                  className={classes.textValInput}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  dropdowns={fcrmRoundNameList}
                  error={error?.message ? true : false}
                />

                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        {get(startupInfo, 'accelerator') === 'YC' && (
          <>
            <Grid item xs={12} className={classes.inputHeadFull}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Have you taken YC's $375k investment?{' '}
                {get(startupInfo, 'accelerator') === 'YC' && (
                  <span className={classes.errorText}>*</span>
                )}
              </Typography>
              <Controller
                name="ycInvestment"
                control={control}
                defaultValue={get(editRound, 'ycInvestment')}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedSelect
                      value={value}
                      label=""
                      onChange={(val: any) => {
                        setHasTakenInvestment(val.target.value === 'YES');
                        onChange(val);
                      }}
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      dropdowns={[
                        { text: 'Yes', value: 'YES' },
                        { text: 'No', value: 'NO' },
                      ]}
                      error={error?.message ? true : false}
                    />

                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputHeadFull}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                MFN Starting Date
                {hasTakenInvestment && (
                  <span className={classes.errorText}>*</span>
                )}
              </Typography>
              <Controller
                name="mfnStartingDate"
                control={control}
                defaultValue={moment(get(editRound, 'mfnStartingDate')).format(
                  'YYYY-MM-DD',
                )}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      value={value}
                      onChange={onChange}
                      type="date"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                    />

                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} className={classes.inputHeadFull}>
          <Typography variant="caption" className={classes.companyLabelText}>
            Currency <span className={classes.errorText}>*</span>
          </Typography>
          <Controller
            name="currency"
            defaultValue={get(editRound, 'currency')}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedSelect
                  value={value}
                  onChange={(e: any) => {
                    setSelectedCurrency(e.target.value);
                    onChange(e);
                  }}
                  label=""
                  className={classes.textValInput}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                    startAdornment: (
                      <InputAdornment position="start">
                        {edit ? get(editRound, 'currency') : selectedCurrency}
                      </InputAdornment>
                    ),
                  }}
                  disabled={edit}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  dropdowns={currencyListV2}
                  error={error?.message ? true : false}
                />

                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid container>
          <Grid item xs={6} className={classes.inputHeadFull}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Target Amount <span className={classes.errorText}>*</span>
            </Typography>
            <Controller
              name="targetAmount"
              control={control}
              defaultValue={get(editRound, 'targetAmount')}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedPriceInput
                    label=""
                    className={classes.textValInput}
                    onBlur={validateData}
                    textAlign="left"
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <InputAdornment position="start">
                          {edit ? get(editRound, 'currency') : selectedCurrency}
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                    disabled={undefined}
                    placeholder={''}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Target Amount is required' }}
            />
          </Grid>
          <Grid item xs={6} className={classes.inputHeadFull}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Tentative Valuation <span className={classes.errorText}>*</span>
            </Typography>
            <Controller
              name="tentativeValuation"
              control={control}
              defaultValue={get(editRound, 'tentativeValuation')}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedPriceInput
                    label=""
                    onBlur={validateData}
                    textAlign="left"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <InputAdornment position="start">
                          {edit ? get(editRound, 'currency') : selectedCurrency}
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                    disabled={undefined}
                    placeholder={''}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Target Amount is required' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.vInputHeadFull}>
          <Typography className={classes.fundRaisingDialogText}>
            8vdX can suggest a valuation range if you use the{' '}
            <Link onClick={() => history.push('/benchmark-kpi-v2')}>
              <span className={classes.benchamarkingToolText}>
                Yardstick tool.
              </span>{' '}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.inputHeadFull}>
          <Typography variant="caption" className={classes.companyLabelText}>
            Calendly or Other Calendar Link
          </Typography>
          <Controller
            name="meetingLink"
            control={control}
            defaultValue={get(editRound, 'meetingLink')}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  inputRef={(input: any) => linkFieldFocus && input?.focus()}
                  placeholder="eg. https://calendly.com/meet"
                  className={classes.textValInput}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
                <span className={classes.meetingText}>
                  This will allow you to invite investors to schedule meetings.
                </span>
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} className={classes.inputHeadFull}>
          <Typography variant="caption" className={classes.companyLabelText}>
            Email Reader
          </Typography>
          <Controller
            name="emailReader"
            control={control}
            defaultValue={emailReader}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  className={classes.textValInput}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                    endAdornment: (
                      <>
                        {!editEmailReader && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                setIsEditEmailReader(!editEmailReader)
                              }
                              onMouseDown={(
                                event: React.MouseEvent<HTMLButtonElement>,
                              ) => {
                                event.preventDefault();
                              }}
                              edge="end"
                            >
                              <EditIcon />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                  disabled={true}
                  style={
                    editEmailReader
                      ? {
                          visibility: 'hidden',
                          height: 0,
                          marginBottom: 0,
                        }
                      : {}
                  }
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
          <Controller
            name="emailReaderText"
            control={control}
            defaultValue={emailReaderText}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  className={classes.textValInput}
                  onBlur={validateEmailReader}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                    endAdornment: (
                      <InputAdornment position="end">
                        @{emailReaderDomain}
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                  disabled={false}
                  style={
                    !editEmailReader
                      ? {
                          visibility: 'hidden',
                          height: 0,
                          marginBottom: 0,
                        }
                      : {}
                  }
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
          <span className={classes.meetingText}>
            You can personalize this email ID for easier recall. Forward or copy
            new investor emails to this ID to capture those leads in the CRM
            automatically.
          </span>
        </Grid>
        <Grid item xs={12}>
          <Button
            name="Save"
            type="submit"
            className={classes.continueBtn}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </Grid>
      </form>
    </Dialog>
  );
};

export default AddFCRMRoundDialog;
