import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { get, isEmpty } from 'lodash';

import {
  Box,
  WrappedTextInput,
  Loader,
  StyledTableCell,
  StyledTableRow,
} from 'components';
import { StyledTooltip } from 'components/Wrappers/Tooltip';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import {
  adminPublishValuationInsightRange,
  getAdminPreviewValuationInsight,
} from 'services';

import styles from './styles';

const ValuationInsightPreview = () => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [valuationInsightData, setValuationInsightData] = useState<any>([]);
  const [editRowId, setEditRowId] = useState<number | null>();

  const publishValuationInsight = (rowData: any, index: number) => {
    setErrorMessage('');
    if (
      !isEmpty(get(rowData, 'valuationMin')) &&
      !isEmpty(get(rowData, 'valuationMax')) &&
      Number(get(rowData, 'valuationMin')) >=
        Number(get(rowData, 'valuationMax'))
    ) {
      setErrorMessage('Vmin should be less than the Vmax');
    } else {
      adminPublishValuationInsightRange([rowData])
        .then((res: any) => {
          if (!isEmpty(res)) {
            const newData: any[] = valuationInsightData.map(
              (rowData: any, rowIndex: number) => {
                if (rowIndex === index) {
                  return { ...rowData, ...res[0] };
                } else {
                  return rowData;
                }
              },
            );
            setValuationInsightData(newData);
          }
          setEditRowId(null);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  const getPreviewValuation = () => {
    setIsLoading(true);
    getAdminPreviewValuationInsight()
      .then((res: any) => {
        setValuationInsightData(res);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const onChangeRowData = (e: any, index: number) => {
    const newData: any[] = valuationInsightData.map(
      (rowData: any, rowIndex: number) => {
        if (rowIndex === index) {
          return { ...rowData, [e.target.name]: e.target.value };
        } else {
          return rowData;
        }
      },
    );
    setValuationInsightData(newData);
  };

  useEffect(() => {
    getPreviewValuation();
  }, []);
  return (
    <>
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Industry</StyledTableCell>
              <StyledTableCell>Geography</StyledTableCell>
              <StyledTableCell>Stage</StyledTableCell>
              <StyledTableCell># of Startups</StyledTableCell>
              <StyledTableCell>Constant</StyledTableCell>
              <StyledTableCell>Coefficient</StyledTableCell>
              <StyledTableCell>KPI Coefficient</StyledTableCell>
              <StyledTableCell>CMGR Coefficient</StyledTableCell>
              <StyledTableCell>R^2</StyledTableCell>
              <StyledTableCell>Vmin</StyledTableCell>
              <StyledTableCell>Vmax</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={9}>
                  <Box className={classes.loader}>
                    <Loader />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              valuationInsightData.map((rowData: any, rowIndex: number) => (
                <StyledTableRow key={rowIndex}>
                  <StyledTableCell>
                    {get(rowData, 'industryType')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {/* {get(
                      getBusinessOpsCountryObject(
                        get(rowData, 'businessOpsLocation'),
                      ),
                      'country',
                    )} */}
                    {get(rowData, 'businessOpsLocation')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {get(rowData, 'fundingStage')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {get(rowData, 'noOfStartup')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {get(rowData, 'constant')
                      ? parseFloat(
                          (get(rowData, 'constant') / 1000000).toString(),
                        ).toFixed(3)
                      : 'NA'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {get(rowData, 'coefficient')
                      ? parseFloat(
                          get(rowData, 'coefficient').toString(),
                        ).toFixed(3)
                      : 'NA'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {rowIndex === editRowId ? (
                      <WrappedTextInput
                        name="kpiCoefficient"
                        type="kpiCoefficient"
                        value={get(rowData, 'kpiCoefficient', null)}
                        className={classes.textValInput}
                        InputProps={{
                          inputProps: { step: 0 },
                          classes: { root: classes.inputRoot },
                          startAdornment: null,
                        }}
                        onChange={(e: any) => onChangeRowData(e, rowIndex)}
                        error={false}
                      />
                    ) : get(rowData, 'kpiCoefficient') ? (
                      parseFloat(get(rowData, 'kpiCoefficient')).toFixed(3)
                    ) : (
                      'NA'
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {rowIndex === editRowId ? (
                      <WrappedTextInput
                        name="cmgrCoefficient"
                        type="number"
                        value={parseFloat(get(rowData, 'cmgrCoefficient', 0))}
                        className={classes.textValInput}
                        InputProps={{
                          inputProps: { step: 0 },
                          classes: { root: classes.inputRoot },
                          startAdornment: null,
                        }}
                        onChange={(e: any) => onChangeRowData(e, rowIndex)}
                        error={false}
                      />
                    ) : get(rowData, 'cmgrCoefficient') ? (
                      parseFloat(get(rowData, 'cmgrCoefficient')).toFixed(3)
                    ) : (
                      'NA'
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {get(rowData, 'r2')
                      ? parseFloat(get(rowData, 'r2')).toFixed(3)
                      : 'NA'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {rowIndex === editRowId ? (
                      <WrappedTextInput
                        name="valuationMin"
                        type="number"
                        value={parseFloat(
                          get(rowData, 'valuationMin', 0),
                        ).toFixed(0)}
                        className={classes.textValInput}
                        InputProps={{
                          inputProps: { step: 0, min: 0 },
                          classes: { root: classes.inputRoot },
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onChange={(e: any) => onChangeRowData(e, rowIndex)}
                        error={false}
                      />
                    ) : (
                      get(rowData, 'valuationMin') &&
                      formatAmount(get(rowData, 'valuationMin'), {
                        maximumFractionDigits: 0,
                      })
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {rowIndex === editRowId ? (
                      <WrappedTextInput
                        name="valuationMax"
                        type="number"
                        value={parseFloat(
                          get(rowData, 'valuationMax', 0),
                        ).toFixed(0)}
                        className={classes.textValInput}
                        InputProps={{
                          inputProps: { step: 0, min: 0 },
                          classes: { root: classes.inputRoot },
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onChange={(e: any) => onChangeRowData(e, rowIndex)}
                        error={false}
                      />
                    ) : (
                      get(rowData, 'valuationMax') &&
                      formatAmount(get(rowData, 'valuationMax'), {
                        maximumFractionDigits: 0,
                      })
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box className={classes.actionEditDeleteCell}>
                      {rowIndex === editRowId ? (
                        <>
                          <StyledTooltip title="Save">
                            <IconButton
                              aria-label="save"
                              className={classes.iconButton}
                              disableFocusRipple
                              disableRipple
                              onClick={() =>
                                publishValuationInsight(rowData, rowIndex)
                              }
                            >
                              <SaveOutlinedIcon />
                            </IconButton>
                          </StyledTooltip>
                          <StyledTooltip title="Cancel">
                            <IconButton
                              aria-label="cancel"
                              className={classes.iconButton}
                              disableFocusRipple
                              disableRipple
                              onClick={() => setEditRowId(null)}
                            >
                              <CancelOutlinedIcon />
                            </IconButton>
                          </StyledTooltip>
                        </>
                      ) : (
                        <StyledTooltip title="Edit">
                          <IconButton
                            aria-label="edit"
                            className={classes.iconButton}
                            disableFocusRipple
                            disableRipple
                            onClick={() => setEditRowId(rowIndex)}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </StyledTooltip>
                      )}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ValuationInsightPreview;
