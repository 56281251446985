import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import GetAppIcon from '@material-ui/icons/GetApp';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  WrappedAutocomplete,
  Dialog,
  WrappedTextInput,
  WrappedSelect,
  WrappedPriceInput,
  FileUpload,
  Loader,
} from 'components';
import { RootState } from 'redux-modules/store/rootState';
import { Delete, FileIcon } from 'assets';
import {
  updateDealStartupBasicProfile,
  addOrUpdateVcDealDocumentsForStartup,
  getSignedUrlForVcDealDocumentForStartup,
  getVcDealForVcInvitedStartup,
} from 'services';
import {
  businessOpsCountryList,
  errorMessageHandler,
  getSymbolFromCurrency,
  getBusinessOpsCountryObject,
} from 'common/utils/helpers';
import {
  industryTypeList,
  fundingRoundStageList,
} from 'common/utils/optionList';
import {
  STARTUP_DEAL_DOCUMENT_NAME,
  VC_DEAL_STATUS_TYPE,
} from 'common/utils/constants';

import {
  updateActiveDealStartupProfileDetail,
  updateProspectiveDealStartupProfileDetail,
} from './validation';
import styles from './styles';
import ThankyouDialog from './ThankyouDialog';

const ShareDealProfile = ({ open, request, closeDialog, vcDealId }: any) => {
  const classes = styles();
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      get(request, 'dealStatus') === VC_DEAL_STATUS_TYPE.PROSPECTIVE
        ? updateProspectiveDealStartupProfileDetail
        : updateActiveDealStartupProfileDetail,
    ),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingVcDeal, setIsLoadingVcDeal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [document, setDocument] = React.useState<any>({});
  const [isOpenThankyouDialog, setIsOpenThankyouDialog] = useState<boolean>(
    false,
  );
  const [vcDealData, setVcDealData] = React.useState<any>({});

  const { startupInfo } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const { user } = useSelector(({ Auth }: RootState) => Auth);

  const uploadDocumentFile = async () => {
    if (!isEmpty(document)) {
      try {
        const url = await getSignedUrlForVcDealDocumentForStartup({
          filename: document.filename,
          investorId: request.investorId,
          vcDealId: request.referenceModuleId,
          documentName: document.documentName,
        });
        // eslint-disable-next-line no-undef
        await fetch(get(url, 'data.uploadUrl'), {
          method: 'PUT',
          body: document.file,
        });
        await addOrUpdateVcDealDocumentsForStartup({
          filename: document.filename,
          investorId: request.investorId,
          vcDealId: request.referenceModuleId,
          documentName: document.documentName,
          documentUrl: get(url, 'data.accessUrl'),
          id: get(request, 'document.id') || null,
        });
        setIsLoading(false);
        setIsOpenThankyouDialog(true);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload file.');
    }
  };

  const onSubmit = () => {
    setErrorMessage('');
    if (isEmpty(document) && isEmpty(watch('companyPitchdeckLink'))) {
      setError('companyPitchdeckLink', {
        type: 'custom',
        message: 'Please Upload Pitch Deck or Enter the link.',
      });
    } else {
      setIsLoading(true);
      const data = { ...getValues() };
      const obj = {
        ...data,
        geography: get(data, 'geography.countryCode'),
        inviteId: request.id,
      };
      updateDealStartupBasicProfile(obj)
        .then(() => {
          if (!isEmpty(document) && isEmpty(get(document, 'id'))) {
            uploadDocumentFile();
          } else {
            setIsLoading(false);
            setIsOpenThankyouDialog(true);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    }
  };

  const handleClose = () => {
    setErrorMessage('');
    closeDialog();
  };

  const getVcDealDataForStartup = () => {
    setIsLoadingVcDeal(true);
    getVcDealForVcInvitedStartup(vcDealId)
      .then((res: any) => {
        setVcDealData(res.data);
        setDocument(get(res, 'data.document'));
        setIsLoadingVcDeal(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoadingVcDeal(false);
      });
  };

  useEffect(() => {
    if (!isEmpty(vcDealId)) {
      getVcDealDataForStartup();
    }
  }, [vcDealId]);

  useEffect(() => {
    if (!isEmpty(vcDealData)) {
      setValue('companyName', get(vcDealData, 'companyName'));
      setValue('proposedValuation', get(vcDealData, 'proposedValuation'));
      setValue('currentValuation', get(vcDealData, 'currentValuation'));
      setValue('companyUrl', get(vcDealData, 'companyUrl'));
      setValue('sector', get(vcDealData, 'sector'));
      setValue('lastFundingRound', get(vcDealData, 'lastFundingRound'));
      setValue(
        'geography',
        getBusinessOpsCountryObject(get(vcDealData, 'geography')),
      );
      setValue('companyPitchdeckLink', get(vcDealData, 'companyPitchdeckLink'));
    }
  }, [vcDealData]);

  useEffect(() => {
    if (isEmpty(vcDealId) && !isEmpty(request)) {
      setDocument(get(request, 'document'));
    }
  }, [request]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth={'sm'}
        title={'Profile Detail'}
        handleClose={() => handleClose()}
      >
        {isLoadingVcDeal ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} data-testid="convert-deal">
            <Box className={classes.profileDetailContainer}>
              {isEmpty(vcDealData) ? (
                <Typography variant="h6">
                  Please complete the following to share your's startup profile
                  with{' '}
                  <span className={classes.vcFirmName}>
                    {get(request, 'investor.name')}
                  </span>
                </Typography>
              ) : (
                <Typography variant="h6">
                  Following are the details you shared with{' '}
                  <span className={classes.vcFirmName}>
                    {get(vcDealData, 'vcFirmName')}
                  </span>
                </Typography>
              )}
              <Box className={classes.profileBox}>
                <Grid item xs={12} className={classes.inputHead}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.labelText}
                  >
                    Company Name
                    <span className={classes.errorRequiredText}>*</span>
                  </Typography>
                  <Controller
                    name="companyName"
                    control={control}
                    defaultValue={get(user, 'companyName') || ''}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedTextInput
                          type="text"
                          placeholder="Company Name"
                          className={classes.textValInput}
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          value={value}
                          onChange={(e: any) =>
                            onChange(e.target.value.trimStart())
                          }
                          error={!!error?.message}
                          disabled={!isEmpty(vcDealData)}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Grid>
                {get(request, 'dealStatus') ===
                  VC_DEAL_STATUS_TYPE.PROSPECTIVE ||
                get(vcDealData, 'dealStatus') ===
                  VC_DEAL_STATUS_TYPE.PROSPECTIVE ? (
                  <Grid item xs={12} className={classes.inputHead}>
                    <Typography className={classes.labelText} variant="body1">
                      Proposed Valuation
                      <span className={classes.errorRequiredText}>*</span>
                    </Typography>
                    <Controller
                      name="proposedValuation"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <WrappedPriceInput
                            label=""
                            className={classes.textValInput}
                            currencySymbol={
                              getSymbolFromCurrency(
                                get(request, 'vcFirmCurrency'),
                              ) ||
                              getSymbolFromCurrency(
                                get(vcDealData, 'vcFirmCurrency'),
                              )
                            }
                            placeholder={'Enter Proposed Valuation'}
                            textAlign="left"
                            decimalPlaces={2}
                            InputProps={{
                              classes: { root: classes.inputRoot },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused,
                              },
                            }}
                            value={value}
                            onChange={(_: any, val: any) => onChange(val)}
                            error={error?.message ? true : false}
                            disabled={!isEmpty(vcDealData)}
                          />
                          {error?.message && (
                            <span className={classes.errorText}>
                              {error?.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} className={classes.inputHead}>
                    <Typography className={classes.labelText} variant="body1">
                      Current valuation
                      <span className={classes.errorRequiredText}>*</span>
                    </Typography>
                    <Controller
                      name="currentValuation"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <WrappedPriceInput
                            label=""
                            className={classes.textValInput}
                            currencySymbol={
                              getSymbolFromCurrency(
                                get(request, 'vcFirmCurrency'),
                              ) ||
                              getSymbolFromCurrency(
                                get(vcDealData, 'vcFirmCurrency'),
                              )
                            }
                            placeholder={'Enter Current Valuation'}
                            textAlign="left"
                            decimalPlaces={2}
                            InputProps={{
                              classes: { root: classes.inputRoot },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused,
                              },
                            }}
                            value={value}
                            onChange={(_: any, val: any) => onChange(val)}
                            error={error?.message ? true : false}
                            disabled={!isEmpty(vcDealData)}
                          />
                          {error?.message && (
                            <span className={classes.errorText}>
                              {error?.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} className={classes.inputHead}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.labelText}
                  >
                    Company URL
                  </Typography>
                  <Controller
                    name="companyUrl"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedTextInput
                          type="text"
                          placeholder="Company Url"
                          className={classes.textValInput}
                          InputProps={{ classes: { root: classes.inputRoot } }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          value={value}
                          onChange={(e: any) =>
                            onChange(e.target.value.trimStart())
                          }
                          error={!!error?.message}
                          disabled={!isEmpty(vcDealData)}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} className={classes.inputHead}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.labelText}
                  >
                    Fundraise Round
                    <span className={classes.errorRequiredText}>*</span>
                  </Typography>
                  <Controller
                    name="lastFundingRound"
                    control={control}
                    defaultValue={get(startupInfo, 'fundingStage') || ''}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedSelect
                          value={value}
                          onChange={onChange}
                          placeholder={'Select Fundraise Round'}
                          label=""
                          className={classes.textValInputSelectField}
                          InputProps={{
                            classes: { root: classes.inputRoot },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          dropdowns={fundingRoundStageList}
                          error={!!error?.message}
                          disabled={
                            !isEmpty(get(startupInfo, 'fundingStage')) ||
                            !isEmpty(vcDealData)
                          }
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} className={classes.inputHead}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.labelText}
                  >
                    Sector<span className={classes.errorRequiredText}>*</span>
                  </Typography>
                  <Controller
                    name="sector"
                    control={control}
                    defaultValue={get(startupInfo, 'industryType') || ''}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedSelect
                          value={value}
                          onChange={onChange}
                          placeholder={'Select Sector'}
                          label=""
                          className={classes.textValInputSelectField}
                          InputProps={{
                            classes: { root: classes.inputRoot },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          dropdowns={industryTypeList}
                          error={!!error?.message}
                          disabled={!isEmpty(vcDealData)}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} className={classes.inputHead}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.labelCountryText}
                  >
                    Country of Incorporation
                    <span className={classes.errorRequiredText}>*</span>
                  </Typography>
                  <Controller
                    name="geography"
                    control={control}
                    defaultValue={
                      getBusinessOpsCountryObject(
                        get(startupInfo, 'businessOpsLocation'),
                      ) || ''
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedAutocomplete
                          id="geography"
                          disableClearable
                          placeholder="Select Country of Business Operations"
                          options={businessOpsCountryList}
                          value={value}
                          getOptionLabel={(option: any) => option.country || ''}
                          getOptionSelected={(option: any, value: any) =>
                            option.countryCode === value?.countryCode
                          }
                          onChange={(_: any, value: any) => {
                            onChange(value);
                          }}
                          className={classes.textValInput}
                          InputProps={{
                            classes: { root: classes.inputRoot },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          error={error?.message ? true : false}
                          disabled={!isEmpty(vcDealData)}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} className={classes.inputHead}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.labelText}
                  >
                    Pitch Deck
                    <span className={classes.errorRequiredText}>*</span>
                  </Typography>
                  <Controller
                    name="companyPitchdeckLink"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedTextInput
                          type="text"
                          placeholder="Enter Link/Attach File"
                          className={classes.textValInput}
                          disabled={!isEmpty(document) || !isEmpty(vcDealData)}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          value={value}
                          onChange={(e: any) =>
                            onChange(e.target.value.trimStart())
                          }
                          error={!!error?.message}
                          InputProps={{
                            classes: { root: classes.inputRoot },
                            endAdornment: (
                              <FileUpload
                                style={{ display: 'block' }}
                                fileExtensions={['pdf', 'pptx', 'ppt']}
                                disabled={
                                  !isEmpty(watch('companyPitchdeckLink')) ||
                                  !isEmpty(vcDealData)
                                }
                                requestObject={{
                                  investorId: request.investorId,
                                  vcDealId: request.referenceModuleId,
                                  documentName:
                                    STARTUP_DEAL_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                                }}
                                onSuccess={(req: any) => {
                                  setDocument(req);
                                  clearErrors('companyPitchdeckLink');
                                }}
                                uploadOnSelect={false}
                                callGetSignedUrl={false}
                                showSelectedFilePreview={false}
                                content={
                                  <AttachmentIcon
                                    style={{ cursor: 'pointer' }}
                                  />
                                }
                              />
                            ),
                          }}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                  {!isEmpty(document) && (
                    <Box className={classes.previewBox}>
                      <Box className={classes.nameContainer}>
                        <img
                          src={FileIcon}
                          alt={FileIcon}
                          className={classes.sampleFile}
                        />
                        <Typography className={classes.uploadFileName}>
                          {get(document, 'filename')}
                        </Typography>
                      </Box>
                      {get(document, 'documentStatus') !== 'UPLOADED' ? (
                        <Box>
                          <img
                            src={Delete}
                            alt={Delete}
                            className={classes.deleteImg}
                            onClick={() => {
                              setDocument({});
                            }}
                          />
                        </Box>
                      ) : (
                        <a
                          href={get(document, 'documentUrl')}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          <GetAppIcon className={classes.downloadIcon} />
                        </a>
                      )}
                    </Box>
                  )}
                </Grid>
              </Box>
            </Box>
            {errorMessage && (
              <Typography variant="caption" className={classes.errorText}>
                {errorMessage}
              </Typography>
            )}
            <Box className={classes.nextPrevButtonBox}>
              {isEmpty(vcDealId) && (
                <Button
                  type="submit"
                  className={classes.nextButton}
                  name="save"
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              )}
              <Button
                variant="outlined"
                className={classes.prevButton}
                name="Cancel"
                disabled={isLoading}
                onClick={() => handleClose()}
              />
            </Box>
          </form>
        )}
      </Dialog>
      <ThankyouDialog
        open={isOpenThankyouDialog}
        setOpen={setIsOpenThankyouDialog}
        name={get(request, 'investor.name')}
        handleClose={handleClose}
      />
    </>
  );
};

export default ShareDealProfile;
