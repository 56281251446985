import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  //   Empty dashboard styling ------- Start
  welcomeBox: {
    fontSize: 20,
    color: '#262A41',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
  },
  welcomeBoxHead: {
    fontWeight: 400,
    position: 'relative',
    padding: '78px 60px 0px',
    '@media (max-width: 1120px)': {
      padding: '20px 30px',
    },
  },
  enquiryImage: {
    position: 'absolute',
    top: 40,
    left: 30,
    height: 80,
    width: 90,
    '@media (max-width: 1120px)': {
      top: 15,
      left: 15,
    },
  },
  welcomeBoxBody: {
    maxWidth: 600,
    margin: 'auto',
    textAlign: 'center',
    paddingTop: 50,
  },
  welcomeHeadtext: {
    fontSize: 28,
    color: theme.palette.warning.contrastText,
    marginBottom: 0,
  },
  welcomeHeadSubtext: {
    fontSize: 16,
    color: theme.palette.primary.main,
    lineHeight: 1.4,
  },
  welcomeImgImage: {
    marginBottom: 25,
  },
  welcomeHeadertext: {
    fontSize: 20,
    color: theme.palette.grey[500],
  },
  welcomeHeaderSubtext: {
    fontSize: 15,
    maxWidth: 400,
    margin: 'auto',
    color: theme.palette.grey[50],
  },
  //   Empty dashboard styling ------- End

  dashWelcomeBox: {
    display: 'flex',
    height: '100vh',
  },
  dashWelcomeLeft: {
    width: 'calc(100% - 300px)',
    flex: '0 auto',
  },
  dashLeftContent: {
    padding: 60,
    paddingTop: 0,
    height: 'calc(100vh - 210px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '& .MuiGrid-item': {
      padding: 14,
      '@media (max-width: 1120px)': {
        padding: 8,
      },
    },
    '@media (max-width: 1120px)': {
      padding: 30,
      paddingTop: 10,
      height: 'calc(100vh - 112px)',
    },
    '@media (max-width: 767px)': {
      height: '100%',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
  },
  dashWelcomeRight: {
    width: 300,
    background: theme.palette.warning.main,
    flexGrow: 1,
    borderTopRightRadius: 25,
  },

  //   DashBoard page Right styling ------- start

  dashWelRightContent: {
    padding: '90px 35px 50px',
    '@media (max-width: 767px)': {
      padding: '60px 5% 30px',
    },
  },
  dashWelRightContBox: {
    background: theme.palette.success.light,
    borderRadius: 15,
    padding: 20,
    paddingTop: 75,
    position: 'relative',
  },
  illustateImg: {
    position: 'absolute',
    width: 84.15,
    height: 72.43,
    top: -15,
  },
  iIlustraImg: {
    position: 'absolute',
    width: 52.25,
    height: 90,
    top: -33,
    right: 20,
  },
  ticketText: {
    fontSize: 14.5,
    fontWeight: 600,
    letterSpacing: 0.34,
    color: theme.palette.grey[400],
    marginBottom: 8,
  },
  ticketSubText: {
    marginBottom: 25,
    color: theme.palette.grey[50],
    fontSize: 11.5,
    letterSpacing: 0.43,
  },
  showBtn: {
    background: theme.palette.grey[100],
    height: 42,
    width: '100%',
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  //   DashBoard page Right styling ------- End

  //   DashBoard Value Box dashboard styling ------- start

  dashValuBox: {
    border: '1px solid #E5E5E5',
    borderRadius: 4,
    padding: '12px 20px',
    marginBottom: 15,
    '@media (max-width: 1120px)': {
      marginBottom: 0,
    },
  },
  valueNo: {
    marginBottom: 7,
    fontSize: 20,
    color: theme.palette.warning.contrastText,
  },
  valueText: {
    fontSize: 13,
    color: theme.palette.grey.A400,
  },
  companiestext: {
    color: theme.palette.grey.A200,
    fontSize: 13,
    paddingLeft: 15,
  },
  //   DashBoard Value Box dashboard styling ------- End

  //   Action section styling -------- start

  dashActionSection: {},
  dashActiontext: {
    color: theme.palette.grey[500],
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
  },
  gridTwo: {
    textAlign: 'right',
    padding: '0 14px',
  },
  dottedicon: {
    color: theme.palette.grey.A700,
    fontSize: 35,
  },
  dividerLine: {
    background: '#DEDEDE',
    height: 2,
    margin: '0 6px',
  },

  //   Action section styling ---------------- start

  //   Dashboard Action Profile ---------------- start

  dashActionProfile: {
    paddingTop: 32,
    paddingBottom: 30,
  },
  dashActProfileBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 28,
  },
  dashActProfileLeft: {
    display: 'flex',
  },
  dashActProfiledetails: {
    paddingLeft: 14,
  },
  profileUNameText: {
    fontSize: 16,
    color: theme.palette.grey[400],
    fontWeight: 600,
    marginBottom: 5,
  },
  profileSubText: {
    fontSize: 10,
    background: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 5px',
    borderRadius: 4,
    marginLeft: 15,
  },
  profileLocationText: {
    fontSize: 13,
    color: theme.palette.grey[600],
  },
  dashActProfileRight: {
    alignSelf: 'center',
    paddingLeft: 15,
    width: 65,
    textAlign: 'right',
  },
  openText: {
    color: theme.palette.primary.main,
    fontSize: 13,
    position: 'relative',
    top: -2,
    marginRight: 3,
    cursor: 'pointer',
  },

  //   Dashboard Action Profile ---------------- End

  //  StartUP Dashboard Loan Process and Action Section ---------------- Start

  dashLoanProcess: {},
  dashLoadText: {
    fontSize: 19,
    color: theme.palette.warning.contrastText,
    letterSpacing: 0.3,
    marginTop: 15,
    marginBottom: 20,
    '@media (max-width: 767px)': {
      fontSize: 16,
    },
  },
  // dashLoadSubText: {
  //   fontSize: 15,
  //   letterSpacing: 0.35,
  //   color: theme.palette.grey[600],
  //   marginBottom: 20,
  //   maxWidth: 610,
  // },
  dashBridgeLoadBox: {
    border: '1px solid #CDE8DD',
    borderRadius: 4,
    padding: '20px 20px 15px',
    marginBottom: 25,
  },
  dashBridgeSubText: {
    background: theme.palette.error.dark,
    padding: '1px 6px',
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    borderRadius: 2,
    fontSize: 13,
    marginBottom: 15,
  },
  dashBridgeContent: {
    display: 'flex',
  },
  dashBridgeLeft: {},
  dashBridgeHeadText: {
    fontSize: 25,
    letterSpacing: 0.3,
    lineHeight: '25px',
    color: theme.palette.warning.contrastText,
    marginBottom: 2,
  },
  dashBridgeHeadSubText: {
    fontSize: 12,
    color: theme.palette.grey[50],
    opacity: 0.5,
  },
  dashBridgeCenter: {},
  stepperBox: {
    padding: '0 15px',
    '& .MuiStepConnector-alternativeLabel': {
      top: 10,
      left: 'calc(-50% + 10px)',
      right: 'calc(50% + 10px)',
    },
  },
  dashBridgeRight: {},
  actionSelectInput: {
    width: 100,
    height: 40,
    '& .MuiSelect-selectMenu': {
      color: theme.palette.primary.main,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
      top: 'calc(50% - 10.5px)',
    },
    '& .MuiOutlinedInput-root': {
      height: 37,
    },
    '& .MuiInputLabel-formControl': {
      top: -8,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 2px) scale(0.75)',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #1EA59A',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      height: 40,
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'rgb(0 0 0 0)',
      },
    },
  },
  dashactionSignNowBox: {
    marginBottom: 20,
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 18,
  },
  dashSignNowText: {
    color: theme.palette.grey[900],
    fontSize: 14,
    marginBottom: 12,
  },
  signNowBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    width: 90,
    height: 30,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  dashactionUploadBox: {
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 18,
    marginBottom: 55,
  },
  dashUploadText: {
    color: theme.palette.grey[900],
    fontSize: 14,
    marginBottom: 12,
  },
  dashUploadListText: {
    fontSize: 12,
    color: theme.palette.grey[600],
    letterSpacing: 0.35,
    marginBottom: 5,
  },
  UploadBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    width: 90,
    height: 30,
    marginTop: 10,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },

  //  StartUP Dashboard Loan Process and Action Section ---------------- End
}));
