import { VdInstallmentPayload } from './Types';
export const GET_VD_INSTALLMENTS = 'admin/GET_VD_INSTALLMENTS';
export const GET_VD_INSTALLMENTS_LOADING = 'admin/GET_VD_INSTALLMENTS_LOADING';
export const GET_VD_INSTALLMENTS_SUCCESS = 'admin/GET_VD_INSTALLMENTS_SUCCESS';
export const GET_VD_INSTALLMENTS_FAILURE = 'admin/GET_VD_INSTALLMENTS_FAILURE';
export const EMPTY_SUCCESS_MESSAGE = 'EMPTY_SUCCESS_MESSAGE';

export const getVdInstallments = (payload: VdInstallmentPayload) => {
  return {
    type: GET_VD_INSTALLMENTS,
    payload,
  };
};

export const emptySuccessMessage = () => {
  return {
    type: EMPTY_SUCCESS_MESSAGE,
  };
};
