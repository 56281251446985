import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  clientContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
    '@media (max-width: 767px)': {
      height: '100%',
    },
  },
  actionContent: {
    height: '100%',
  },
  actionHead: {},
  actionHeadContent: {
    padding: '80px 80px 10px 67px',
    '@media (max-width: 767px)': {
      padding: '30px 40px',
    },
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 43,
    left: 40,
    '@media (max-width: 1024px)': {
      height: 60,
      width: 70,
      top: 75,
    },
    '@media (max-width: 767px)': {
      top: 24,
      left: 20,
    },
  },
  headText: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    fontWeight: 400,
    letterSpacing: 0.3,
    '@media (max-width: 1024px)': {
      top: 0,
      fontSize: 24,
    },
  },
  addClientBtnBox: {
    display: 'flex',
    padding: '0 40px',
    textAlign: 'right',
    marginTop: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      padding: '0 20px',
    },
  },
  addNewClientBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 170,
      height: 45,
    },
  },
  clientContent: {
    padding: '20px 40px',
    height: 'calc(100% - 120px)',
    overflow: 'hidden auto',
    '@media (max-width: 1024px)': {
      height: 'calc(100% - 216px)',
    },
    '@media (max-width: 767px)': {
      padding: '20px',
      height: 'calc(100% - 186px)',
    },
  },
  validateForm: {
    padding: '20px',
    background: '#FFFFFF',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    borderRadius: 11,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    left: 0,
    bottom: 15,
  },
  inputFormGroup: {
    width: '100%',
    padding: 0,
    margin: 0,
    '& .MuiGrid-item': {
      padding: 0,
      position: 'relative',
      width: '65%',
    },
  },
  textValInput: {
    marginBottom: 35,
    marginTop: 0,
    width: '65%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '@media (max-width: 525px)': {
      width: '100%',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 17,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  backContinueBox: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 45,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  // ------------------ Table Styling -----------------

  tableContainer: {
    boxShadow: theme.shadows[2],
    overflow: 'overlay',
    height: '100%',
    borderRadius: '0 0 11px 11px',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTable-root': {
      // height: '100%',
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
    '& .MuiTableCell-root': {
      padding: '16px',
    },
    '& .MuiTableRow-hover': {
      '&:nth-child(even)': {
        '& .MuiTableCell-body': {
          background: theme.palette.common.white,
        },
      },
      '&:nth-child(odd)': {
        '& .MuiTableCell-body': {
          background: theme.shadows[3],
        },
      },
    },
    '& .MuiTableCell-head': {
      background: theme.palette.success.dark,
      color: theme.palette.warning.contrastText,
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '15px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0px',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.warning.contrastText,
      fontSize: 15,
      fontWeight: 600,
      lineHeight: '15px',
      textAlign: 'left',
    },
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
  showEmptyMsg: {
    position: 'relative',
  },
  noItemsImg: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    padding: '25px 0',
  },
  viewTextBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '3',
    marginRight: 3,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    fontSize: 12,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50%',
  },
  usageDialog: {
    minHeight: 500,
  },
}));
