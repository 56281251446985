import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  enquiryViewContainer: {
    paddingLeft: 60,
  },
  enquiryViewBox: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 20px)',
  },
  enquiryTabs: {
    borderBottom: '0.5px solid #DEDEDE',
  },
  enquiryViewBoxLeft: {
    width: 'calc(100% - 300px)',
  },
  enquiryViewHeadBox: {},
  OrnamentImg: {},
  enquiryListhead: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 70,
  },
  enquiryListLeft: {
    position: 'relative',
    paddingLeft: 10,
  },
  enquiryListRight: {
    paddingRight: 30,
  },
  gridItemView: {
    paddingLeft: '20px !important',
  },
  rightIconStyle: {
    border: '1px solid #1EA59A',
    padding: '2px 6px 0',
    borderRadius: 6,
  },
  moreHoriIcon: {
    color: theme.palette.primary.main,
  },
  iconStyleOne: {
    height: 50,
    width: 50,
  },
  enquiryHeadText: {
    fontSize: 29,
    color: theme.palette.error.contrastText,
    marginBottom: 5,
    position: 'relative',
    top: -24,
  },
  enquirySubHeadText: {
    fontSize: 17,
    marginBottom: 10,
    color: theme.palette.primary.main,
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 20,
    left: 40,
  },
  enquiryViewTabBox: {
    paddingRight: 30,
  },
  enquiryViewBoxRight: {
    width: '400px',
    padding: '60px 25px 30px',
    background: theme.palette.warning.main,
    borderTopRightRadius: 25,
    // height: '100%',
  },
  tabPanelContent: {
    // height: 'calc(100vh - 250px)',
    // overflow: 'auto',
    padding: '10px 20px 20px',
    '@media (max-width: 1024px)': {
      padding: '10px 0px 20px',
    },
  },
  tabPanelText: {
    padding: '30px 20px',
  },
  overViewHeadTitle: {
    fontSize: 20,
    color: theme.palette.grey[500],
    marginBottom: 20,
    paddingTop: 20,
  },
  basicHeadTitle: {
    fontSize: 20,
    color: theme.palette.grey[500],
    marginBottom: 20,
    paddingTop: 20,
  },
  businessHeadTitle: {
    fontSize: 20,
    color: theme.palette.grey[500],
    marginBottom: 20,
    paddingTop: 50,
  },
  descTitletext: {
    fontSize: 15,
    color: theme.palette.grey[600],
    marginBottom: 25,
  },
  overViewSubHeadTitle: {
    fontSize: 15,
    color: theme.palette.grey[500],
    fontWeight: 600,
  },
  labelStartUpText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    opacity: 0.75,
    marginBottom: 8,
    paddingRight: 15,
  },
  labelStartHead: {
    fontSize: 22,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelStartUpHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelFundHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 10,
  },
  labelStartUp: {
    fontSize: 15,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelStartUpUrl: {
    fontSize: 15,
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelFundUpHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    paddingTop: 5,
  },
  labelFundUpHeadText: {
    fontSize: 15,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    paddingTop: 5,
  },
  labelFundUpText: {
    fontSize: 12,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    opacity: 0.75,
  },
  labelInfoText: {
    fontSize: 12,
    color: theme.palette.primary.main,
    opacity: 0.75,
    marginBottom: 25,
    paddingTop: 5,
  },
  iconStyle: {
    width: 35,
    height: 35,
  },
  menuItemText: {
    display: 'inline-block',
  },
}));
