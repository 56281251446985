import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';

import { Box, Loader, StyledTableCell, StyledTableRow } from 'components';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { getInvestorPayments } from 'services';

import styles from './styles';

type ComponentProps = {
  investor: any;
  spv?: string;
  kycDetail?: any;
  investmentData?: any;
};

const InvestorPayments = ({ investor, spv }: ComponentProps) => {
  const classes = styles();

  const [paymentsList, setPaymentsList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setIsLoading(true);
    getInvestorPayments({ spv: spv, investorId: get(investor, 'id') })
      .then((res: any) => {
        setPaymentsList(res);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  return (
    <Box className={classes.tabPanelContent}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <StyledTableCell style={{ textAlign: 'left' }}>
                Date
              </StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Transaction Id</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
            </TableRow>
          </TableHead>
          {!errorMessage ? (
            <TableBody>
              {isLoading ? (
                <Grid item xs={6}>
                  <Loader />
                </Grid>
              ) : (
                paymentsList.map((row: any) => (
                  <StyledTableRow hover key={row.id}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: 'left' }}
                    >
                      {' '}
                      {moment(
                        get(row, 'investmentDetail.investmentDate'),
                      ).format('DD-MM-YYYY')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatAmount(get(row, 'amount', 0))}
                    </StyledTableCell>
                    <StyledTableCell>
                      {get(row, 'transactionId')}
                    </StyledTableCell>
                    {!row.approvedByAdmin ? (
                      <StyledTableCell>
                        <span>{'Confirmation pending'}</span>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell>
                        <span style={{ color: 'green' }}>{'Confirmed'}</span>
                      </StyledTableCell>
                    )}
                    <StyledTableCell>
                      <span
                        style={{
                          color: row.remarks ? 'black' : 'grey',
                        }}
                      >
                        {get(row, 'remarks') || 'No Remarks'}
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          ) : (
            <h2>{errorMessage}</h2>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InvestorPayments;
