import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  firmAdminTableBox: {
    padding: '0px',
    //border: '0.5px solid #DEDEDE',
    borderRadius: 11,
    marginTop: 20,
    height: 'calc(100% - 180px) !important',
    overflow: 'hidden',
    '@media (max-width: 1180px)': {
      padding: '0px',
    },
    '@media (max-width: 1024px)': {
      padding: '20px 0px 20px',
    },
  },
  tableContainer: {
    boxShadow: theme.shadows[2],
    maxHeight: '100%',
    height: '100%',
    minHeight: 200,
    overflow: 'overlay',
    borderRadius: 11,
    borderRight: 0,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTable-stickyHeader': {
      tableLayout: 'fixed',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
      padding: '16px 8px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
        width: 40,
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 14,
      padding: '10px 8px',
      textAlign: 'left',
      wordBreak: 'break-word',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  actionButtonBox: {
    // display: 'flex',
    // justifyContent: 'center',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  viewButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid #1EA59A',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    padding: '4px 7px 4px',
    margin: '5px 0px',
    cursor: 'pointer',
    width: 71,
    height: 30,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.secondary.main,
      background: theme.palette.primary.contrastText,
    },
  },
  // side pane
  sidePaneContainer: {
    padding: '5px 20px 10px 20px',
  },
  sidePaneHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightHeadSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  companyNameEditBox: {
    display: 'flex',
    alignItems: 'baseline',
  },
  companyNameText: {
    fontSize: 24,
    fontWeight: 300,
  },
  saveIcon: {
    color: theme.palette.primary.contrastText,
    marginLeft: 3,
    marginTop: 3,
    fontSize: 28,
  },
  saveBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    width: 35,
    height: 35,
    boxShadow: theme.shadows[5],
    cursor: 'pointer',
  },
  editIcon: {
    height: 17,
    width: 18,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  errorRequiredText: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
  leftHeadSubContainer: {
    display: 'flex',
    marginBottom: 18,
  },
  textValInput: {
    marginBottom: 10,
    width: '100%',
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 14,
    display: 'block',
    // position: 'absolute',
    // bottom: 20,
  },
  errorPricesFieldText: {
    color: theme.palette.error.main,
    fontSize: 14,
    display: 'block',
    position: 'absolute',
    bottom: 0,
  },
  errorDateFieldText: {
    color: theme.palette.error.main,
    fontSize: 14,
    display: 'block',
    position: 'absolute',
    bottom: 10,
  },
  errorCompanyNameText: {
    color: theme.palette.error.main,
    fontSize: 14,
    position: 'absolute',
    bottom: -6,
    left: 0,
  },
  textFieldBox: {
    position: 'relative',
    width: '100%',
  },
  rightBox: {
    display: 'flex',
  },
  ycBatchBox: {
    marginLeft: 10,
  },
  accordMainBox: {
    marginTop: 20,
  },
  inputFundBox: {
    padding: '15px 0px 10px 0px',
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
      position: 'relative',
      '&:nth-of-type(odd)': {
        marginRight: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginRight: 0,
          maxWidth: '100%',
        },
      },
      '&:nth-of-type(even)': {
        marginLeft: 15,
        maxWidth: 'calc(50% - 15px)',
        '@media (max-width: 600px)': {
          marginLeft: 0,
          maxWidth: '100%',
        },
      },
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
    },
    '& .MuiTypography-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInputSelectField: {
    marginBottom: 10,
    marginTop: 5,
    marginRight: 18,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  autoTextValInput: {
    marginBottom: 20,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  autoCompleteInputRoot: {
    width: '100%',
    fontSize: 14,
    height: 32,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  emailChipStyle: {
    marginBottom: 5,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.text.secondary,
      borderRadius: 10,
      backgroundColor: '#DEDEDE',
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  editToolTip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  tagSmallDesc: {
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  tagStyle: {
    marginBottom: 5,
    width: '100%',
    '& .MuiChip-root': {
      color: theme.palette.common.black,
      borderRadius: 2,
      backgroundColor: '#E8F6F5',
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  countryTagStyle: {
    '& .MuiChip-root': {
      color: theme.palette.common.black,
      borderRadius: 2,
      backgroundColor: '#E8F6F5',
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  textfieldBox: {
    paddingRight: 20,
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
  },
  downloadIcon: {
    fill: theme.palette.primary.main,
    cursor: 'pointer',
  },
  previewBox: {
    height: 25,
    background: '#E6E6E6',
    borderRadius: 3,
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'space-between',
  },
  sampleFile: {
    width: 14,
    height: 15,
  },
  deleteImg: {
    width: '12px',
    height: '14px',
    cursor: 'pointer',
    margin: 5,
  },
  nameContainer: {
    display: 'flex',
    margin: 10,
    width: 'calc(100% - 20px)',
    alignItems: 'center',
  },
  uploadFileName: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.text.primary,
    letterSpacing: '0.3px',
    padding: '0 7px',
    width: 'calc(100% - 13px)',
  },
  addOtherDocBox: {
    border: '1px dashed #93C9C7',
    background: '#E8F6F5',
    cursor: 'pointer',
    height: 55,
    width: 60,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 30,
    color: 'grey',
    fontWeight: 200,
  },
  aianalystBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 500,
    width: 200,
    height: 40,
    borderRadius: 7,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
    },
  },
  left10: {
    marginLeft: 10,
  },
  blueText: {
    color: '#4e5db9',
    fontSize: 13,
    marginBottom: 12,
  },
  tagText: {
    color: theme.palette.common.black,
    borderRadius: 2,
    backgroundColor: '#E8F6F5',
    marginRight: 5,
    height: 'auto',
    fontSize: '10px',
    '& .MuiChip-label': {
      paddingLeft: 3,
      paddingRight: 3,
    },
  },
  otherEmailBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  tooltipText: {
    fontSize: 12,
    fontWeight: 400,
    paddingBottom: 4,
    borderBottom: '0.5px solid' + theme.palette.text.disabled,
    '&:last-child': {
      border: 'none',
    },
  },
  otherEmailBtn: {
    border: '1px solid' + theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 9,
    fontWeight: 600,
    width: 65,
    height: 15,
    borderRadius: 2,
    padding: 1,
    marginRight: 5,
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  ycTagText: {
    color: theme.palette.common.black,
    borderRadius: 2,
    backgroundColor: theme.palette.success.dark,
    marginRight: 5,
    height: 'auto',
    fontSize: '10px',
    '& .MuiChip-label': {
      paddingLeft: 3,
      paddingRight: 3,
    },
  },
  statusSelectFeild: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    minWidth: 100,
    width: 160,
    marginTop: 0,
    '@media (max-width: 525px)': {
      minWidth: 70,
      maxWidth: 100,
    },
  },
  errorMessageSidePen: {
    color: theme.palette.error.main,
    fontSize: 13,
    display: 'block',
    textAlign: 'left',
    marginBottom: 10,
  },
  removeText: {
    height: '16px',
    cursor: 'pointer',
  },
  addFounderBtn: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  disableClick: {
    pointerEvents: 'none',
    color: theme.palette.primary.main,
  },
  attachmentIcon: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
  },
  currentYCBatchText: {
    width: 85,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 5,
    marginLeft: 10,
  },
}));
