import { put, takeLatest, call, fork, all } from 'redux-saga/effects';
import { get } from 'lodash';

import { ISagaAction } from 'common/types';
import { getPaymentsList, logPayments, getPaymentById } from 'services';

import { PaymentsPayload, LogPaymentType, PaymentByIdPayload } from './Types';
import {
  GET_PAYMENTS_LIST,
  GET_PAYMENTS_LIST_LOADING,
  GET_PAYMENTS_LIST_FAILURE,
  GET_PAYMENTS_LIST_SUCCESS,
  LOG_PAYMENT,
  LOG_PAYMENT_LOADING,
  LOG_PAYMENT_SUCCESS,
  LOG_PAYMENT_FAILURE,
  GET_PAYMENTS_BY_ID,
  GET_PAYMENTS_BY_ID_LOADING,
  GET_PAYMENTS_BY_ID_SUCCESS,
  GET_PAYMENTS_BY_ID_FAILURE,
} from './Actions';

type ResponseType = {
  response: any | [];
};

function* handleGetPaymentsList(data: ISagaAction<PaymentsPayload>) {
  const { payload } = data;
  try {
    yield put({ type: GET_PAYMENTS_LIST_LOADING });
    const response: ResponseType = yield call<any>(getPaymentsList, payload);
    yield put({
      type: GET_PAYMENTS_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.message');
    yield put({ type: GET_PAYMENTS_LIST_FAILURE, payload: message });
  }
}

function* handleLogPayment(data: ISagaAction<LogPaymentType>) {
  const { payload } = data;
  try {
    yield put({ type: LOG_PAYMENT_LOADING });
    yield call<any>(logPayments, payload);
    yield put({
      type: LOG_PAYMENT_SUCCESS,
      payload: 'Payment logged successfully',
    });
  } catch (err) {
    const message: string = get(err, 'response.message');
    yield put({ type: LOG_PAYMENT_FAILURE, payload: message });
  }
}

function* handleGetPaymentById(data: ISagaAction<PaymentByIdPayload>) {
  const { payload } = data;
  try {
    yield put({ type: GET_PAYMENTS_BY_ID_LOADING });
    const response: ResponseType = yield call<any>(getPaymentById, payload);
    yield put({
      type: GET_PAYMENTS_BY_ID_SUCCESS,
      payload: response,
    });
  } catch (err) {
    const message: string = get(err, 'response.message');
    yield put({ type: GET_PAYMENTS_BY_ID_FAILURE, payload: message });
  }
}

function* handleGetPaymentsListAdminSaga() {
  yield takeLatest(GET_PAYMENTS_LIST, handleGetPaymentsList);
}

function* handleLogPaymentSaga() {
  yield takeLatest(LOG_PAYMENT, handleLogPayment);
}

function* handleGetPaymentByIdSaga() {
  yield takeLatest(GET_PAYMENTS_BY_ID, handleGetPaymentById);
}

export default function* ProcessSagas() {
  yield all([
    fork(handleGetPaymentsListAdminSaga),
    fork(handleLogPaymentSaga),
    fork(handleGetPaymentByIdSaga),
  ]);
}
