import React from 'react';
import {
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Table,
  TextField,
  MenuItem,
  Checkbox,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { find, get, isEmpty, uniqBy } from 'lodash';
import moment from 'moment';

import {
  Box,
  Dialog,
  WrappedTextInput,
  Button,
  WrappedPriceInput,
} from 'components';
import { BootstrapTooltip, StyledTooltip } from 'components/Wrappers/Tooltip';
import {
  frequencyList,
  fundRaisingKpiType,
  roundStageList,
} from 'common/utils/optionList';
import {
  KPI_FUNDRAISE_TYPE_MAPPER,
  YARDSTICK_KPI_IN_NUMBER,
} from 'common/utils/constants';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';

import styles from './styles';

type KPITableProps = {
  tableClassName: string;
  columnKeyName: string;
  columnValueKeyName: string;
  originalKpiRows: any[];
  kpiRows: any[];
  setKpiRows: any;
  kpiColumns: any[];
  kpiList: any[];
  startupId: any;
  role: string;
  onUpdateCall: any;
  deleteBenchmarkKpi: any;
  onDeleteCall: any;
  setNoKpiAdded?: any;
  selectedFrequency?: number;
};

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: 12,
  },
  head: {
    border: '0 !important',
    backgroundColor: '#93C9C7 !important',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.3,
    color: theme.palette.common.black,
    '&:first-child': {
      borderRadius: '10px 0 0 0',
    },
    '&:last-child': {
      borderRadius: '0 10px 0 0',
    },
    wordWrap: 'break-word',
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 0.3,
    color: theme.palette.common.black,
    wordWrap: 'break-word',
    '&:first-child': {
      fontWeight: 700,
    },
  },
}))(TableCell);

const displayKPICellValue = (
  value: any,
  columnKeyName: string,
  kpiKey?: string,
) => {
  if (columnKeyName === 'kpiName' && !isEmpty(value)) {
    if (!YARDSTICK_KPI_IN_NUMBER.includes(kpiKey || ''))
      return formatAmount(value, {
        maximumFractionDigits: 0,
      });
    else return parseFloat(value).toFixed(0);
  } else if (columnKeyName === 'cmgrKpiName' && !isEmpty(value)) {
    return `${parseFloat(value).toFixed(1)}%`;
  } else if (
    columnKeyName === 'fundRaiseKpiName' &&
    (!isEmpty(value) || typeof value === 'boolean')
  ) {
    return kpiKey === 'closingMonth'
      ? moment(value, 'MM_YYYY').format('MMM-YYYY')
      : kpiKey === 'amountRaised'
      ? formatAmount(value, {
          maximumFractionDigits: 2,
          notation: 'compact',
        })
      : kpiKey === 'valuation'
      ? value > 0
        ? formatAmount(value, {
            maximumFractionDigits: 2,
            notation: 'compact',
          })
        : '-'
      : kpiKey === 'discount'
      ? `${parseFloat(value).toFixed(1)}%`
      : kpiKey === 'type'
      ? KPI_FUNDRAISE_TYPE_MAPPER[value]
      : kpiKey === 'mfnClause' || kpiKey === 'uncapped'
      ? value === true
        ? 'Yes'
        : value === false
        ? 'No'
        : '-'
      : value;
  }
  return value;
};

const KPITable = ({
  tableClassName,
  columnKeyName,
  columnValueKeyName,
  originalKpiRows,
  kpiRows,
  setKpiRows,
  kpiColumns,
  kpiList,
  startupId,
  role,
  onUpdateCall,
  deleteBenchmarkKpi,
  onDeleteCall,
  setNoKpiAdded,
  selectedFrequency,
}: KPITableProps) => {
  const classes = styles();

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = React.useState<string>(
    '',
  );
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [deletePayload, setDeletePayload] = React.useState<any>();
  const primaryKPI = originalKpiRows.find((kpi: any) => kpi.isPrimary);

  const closingMonthList = Array.from({
    length: moment().diff(moment('01-2017', 'MM-YYYY'), 'month') + 1,
  })
    .map((_, index) => {
      return {
        value: `${moment(moment('01-2017', 'MM-YYYY'))
          .add(index, 'month')
          .format('M_YYYY')}`,
        text: moment(moment('01-2017', 'MM-YYYY'))
          .add(index, 'month')
          .format('MMM-YYYY'),
      };
    })
    .reverse();

  const renderKpiName = (name: string, key: string) => {
    return name === 'Monthly Revenue' && key === 'kpiName'
      ? `${get(
          frequencyList.find(
            (freq: any) => Number(freq.value) === Number(selectedFrequency),
          ),
          'text',
        )} Revenue`
      : name;
  };

  const renderKpiNameInfo = (name: string, key: string) => {
    const kpiData = find(
      kpiList,
      key === 'kpiName' ? { kpiName: name } : { cmgrKpiName: name },
    );
    return (
      <Box className={classes.kpiNameInfoCell}>
        <BootstrapTooltip
          title={
            get(kpiData, key === 'kpiName' ? 'kpiDef' : 'cmgrKpiDef') ||
            get(kpiData, 'kpiDef', '')
          }
          placement="top"
        >
          <IconButton
            aria-label="info"
            className={classes.iconButton}
            disableFocusRipple
            disableRipple
          >
            <InfoOutlinedIcon />
          </IconButton>
        </BootstrapTooltip>
      </Box>
    );
  };

  const handleEditRow = (index: number, isEdit: boolean) => {
    const newData: any[] = kpiRows.map((rowData: any, rowIndex: number) =>
      rowIndex === index ? { ...rowData, isEdit } : rowData,
    );
    setKpiRows(newData);
  };

  const handleCancelEditRow = (
    index: number,
    isEdit: boolean,
    isNewRow: boolean,
  ) => {
    setNoKpiAdded(false);
    setErrorMessage('');
    const originalBenchmarkKpiRows = [...originalKpiRows];
    let newData: any[] = kpiRows.map((rowData: any, rowIndex: number) =>
      rowIndex === index && !isNewRow
        ? { ...originalBenchmarkKpiRows[rowIndex], isEdit }
        : rowData,
    );
    if (isNewRow) {
      newData = newData.filter(
        (rowData: any, rowIndex: number) => rowIndex !== index,
      );
    }
    setKpiRows(newData);
  };

  const onChangeRowData = (e: any, index: number) => {
    const newData: any[] = kpiRows.map((rowData: any, rowIndex: number) => {
      if (rowIndex === index && e.target.name !== 'kpiName') {
        const kpiDataArray = get(rowData, 'kpiValues', []);
        const foundObj = find(kpiDataArray, { key: e.target.name });
        let newkpiDataArray = [...kpiDataArray];
        if (!isEmpty(kpiDataArray) && !isEmpty(foundObj)) {
          newkpiDataArray = kpiDataArray.map((kpiObj: any) =>
            kpiObj.key === e.target.name
              ? { ...kpiObj, kpiValue: e.target.value }
              : kpiObj,
          );
        } else {
          const monthYearSplit = e.target.name.split('_');
          const kpiData = find(kpiList, { kpiName: get(rowData, 'kpiName') });
          const newObj = {
            kpiName: get(rowData, 'kpiName'),
            month: monthYearSplit[0],
            year: monthYearSplit[1],
            key: e.target.name,
            kpiValue: e.target.value,
            cmgrKpiName: get(kpiData, 'cmgrKpiName'),
            isPrimary:
              !primaryKPI && get(kpiData, 'isPrimary')
                ? get(kpiData, 'isPrimary')
                : false,
          };
          newkpiDataArray.push(newObj);
        }
        return { ...rowData, kpiValues: newkpiDataArray };
      } else if (rowIndex === index && e.target.name == 'kpiName') {
        const kpiData = find(kpiList, { kpiName: e.target.value });
        const kpiDataArray = get(rowData, 'kpiValues', []);
        const newkpiDataArray = kpiDataArray.map((kpiObj: any) => {
          return {
            ...kpiObj,
            [e.target.name]: e.target.value,
            cmgrKpiName: get(kpiData, 'cmgrKpiName'),
            isPrimary:
              !primaryKPI && get(kpiData, 'isPrimary')
                ? get(kpiData, 'isPrimary')
                : false,
          };
        });
        return {
          ...rowData,
          [e.target.name]: e.target.value,
          kpiValues: newkpiDataArray,
          isPrimary:
            !primaryKPI && get(kpiData, 'isPrimary')
              ? get(kpiData, 'isPrimary')
              : false,
        };
      } else {
        return rowData;
      }
    });
    setKpiRows(newData);
  };

  const onChangeCmgrRowData = (e: any, index: number) => {
    const newData: any[] = kpiRows.map((rowData: any, rowIndex: number) => {
      if (rowIndex === index && e.target.name !== 'kpiName') {
        const kpiDataArray = get(rowData, 'kpiValues', []);
        const foundObj = find(kpiDataArray, { key: e.target.name });
        let newkpiDataArray = [...kpiDataArray];
        if (!isEmpty(kpiDataArray) && !isEmpty(foundObj)) {
          newkpiDataArray = kpiDataArray.map((kpiObj: any) =>
            kpiObj.key === e.target.name
              ? { ...kpiObj, cmgrKpiValue: e.target.value }
              : kpiObj,
          );
        } else {
          const monthYearSplit = e.target.name.split('_');
          const kpiData = find(kpiList, {
            cmgrKpiName: get(rowData, 'kpiName'),
          });
          const newObj = {
            kpiName: get(kpiData, 'kpiName'),
            month: Number(monthYearSplit[0]),
            year: Number(monthYearSplit[1]),
            key: e.target.name,
            cmgrKpiValue: e.target.value,
            cmgrKpiName: get(rowData, 'kpiName'),
            isPrimary:
              !primaryKPI && get(kpiData, 'isPrimary')
                ? get(kpiData, 'isPrimary')
                : false,
          };
          newkpiDataArray.push(newObj);
        }
        return { ...rowData, kpiValues: newkpiDataArray };
      } else if (rowIndex === index && e.target.name == 'kpiName') {
        return { ...rowData, [e.target.name]: e.target.value };
      } else {
        return rowData;
      }
    });
    setKpiRows(newData);
  };

  const onChangeFundraiseRowData = (
    e: any,
    index: number,
    inputType: string,
  ) => {
    const newData: any[] = kpiRows.map((rowData: any, rowIndex: number) => {
      if (rowIndex === index) {
        if (e.target.name === 'closingMonth') {
          const monthYearSplit = e.target.value.split('_');
          return {
            ...rowData,
            [e.target.name]: e.target.value,
            month: Number(monthYearSplit[0]),
            year: Number(monthYearSplit[1]),
          };
        } else {
          return {
            ...rowData,
            [e.target.name]:
              inputType === 'boolean' ? e.target.checked : e.target.value,
          };
        }
      } else {
        return rowData;
      }
    });
    setKpiRows(newData);
  };

  // After api call change originalRow array and row array
  const handleSaveRowData = (index: number) => {
    setErrorMessage('');
    setNoKpiAdded(false);
    const newKpiRows = kpiRows.filter((row: any) => row.isEdit);
    if (
      columnKeyName === 'kpiName' &&
      uniqBy(kpiRows, 'kpiName').length !== kpiRows.length
    ) {
      setErrorMessage(
        'Please add different KPI name. Duplicate KPI will not allowed.',
      );
    } else if (
      (columnKeyName === 'kpiName' &&
        isEmpty(get(newKpiRows.slice(-1)[0], 'kpiValues'))) ||
      (columnKeyName === 'fundRaiseKpiName' &&
        get(newKpiRows.slice(-1)[0], 'roundStage') === '')
    ) {
      setNoKpiAdded(true);
    } else {
      setNoKpiAdded(false);
      if (columnKeyName !== 'fundRaiseKpiName') {
        const kpiData = get(
          kpiRows.find((rowData: any, rowIndex: number) => {
            if (rowIndex === index) return rowData;
          }),
          'kpiValues',
          [],
        );
        const payload = kpiData
          .filter((obj: any) => obj.id || obj.kpiValue)
          .map((data: any) => {
            return {
              ...data,
              startupId,
              kpiValue: parseInt(data.kpiValue?.replace(/,/g, '') || 0),
              updateCmgrValue: columnKeyName === 'cmgrKpiName',
            };
          });
        onUpdateCall(payload);
      } else {
        const kpiData = [
          kpiRows.find((rowData: any, rowIndex: number) => {
            if (rowIndex === index) return rowData;
          }),
        ];
        const payload = kpiData.map((data: any) => {
          return {
            ...data,
            amountRaised: data?.amountRaised?.replace(/,/g, ''),
            valuation: data?.valuation?.replace(/,/g, ''),
            startupId,
          };
        });
        onUpdateCall(payload);
      }
    }
  };

  // After api call change originalRow array and row array
  const handleDeleteRowData = (index: number) => {
    const originalBenchmarkKpiRows = [...originalKpiRows];
    const rowData: any[] = originalBenchmarkKpiRows.find(
      (rowData: any, rowIndex: number) => {
        if (rowIndex === index) return rowData;
      },
    );
    setDeletePayload({
      kpiName:
        columnKeyName === 'fundRaiseKpiName'
          ? get(rowData, 'roundStage')
          : columnKeyName === 'cmgrKpiName'
          ? get(rowData, 'kpiValues[0].kpiName')
          : get(rowData, 'kpiName'),
      kpiIds:
        columnKeyName !== 'fundRaiseKpiName'
          ? get(rowData, 'kpiValues', []).map((kpi: any) => kpi.id)
          : [get(rowData, 'id')],
      isPrimary:
        columnKeyName !== 'fundRaiseKpiName'
          ? get(rowData, 'isPrimary')
          : false,
    });
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setDeleteErrorMessage('');
    setDeletePayload({});
  };

  const handleDeleteBenchmarkKpi = async () => {
    if (!isEmpty(deletePayload)) {
      setDeleteErrorMessage('');
      try {
        const payload = {
          ...deletePayload,
          startupId,
        };
        setDeleteLoading(true);
        await deleteBenchmarkKpi(payload, role);
        setDeleteLoading(false);
        onDeleteCall();
        handleDeleteModalClose();
      } catch (err) {
        const message: string = errorMessageHandler(err) || get(err, 'message');
        setDeleteLoading(false);
        setDeleteErrorMessage(message);
      }
    } else {
      setDeleteErrorMessage(
        'Something went wrong. You can not delete this row.',
      );
    }
  };

  const executeScrollRight = (className: string) => {
    document.getElementsByClassName(className)[0].scrollLeft += 150;
  };
  const executeScrollLeft = (className: string) => {
    document.getElementsByClassName(className)[0].scrollLeft -= 150;
  };

  return (
    <>
      <Box className={classes.kpiDataTable}>
        {errorMessage && (
          <Typography variant="caption" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        )}
        <TableContainer
          className={`${classes.tableContainer} ${tableClassName}`}
        >
          <Table stickyHeader aria-label="sticky table">
            <colgroup>
              {kpiColumns.map((column: any, index: number) => (
                <col
                  key={`colgroup_${index}`}
                  style={{ width: column.width, minWidth: column.width }}
                />
              ))}
            </colgroup>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {kpiColumns.map((column: any, index: number) => (
                  <React.Fragment key={`col_${index}`}>
                    {![
                      'info',
                      'next',
                      'prev',
                      'kpiName',
                      'roundStage',
                      '#',
                    ].includes(column.name) && (
                      <StyledTableCell align={column.align}>
                        {column.title}
                      </StyledTableCell>
                    )}
                    {column.name === 'kpiName' && (
                      <StyledTableCell
                        align={column.align}
                        style={{
                          left: 0,
                        }}
                        className={classes.tableHeaderStickyCell}
                      >
                        {column.title}
                      </StyledTableCell>
                    )}
                    {column.name === 'roundStage' && (
                      <StyledTableCell
                        align={column.align}
                        style={{
                          left: 0,
                        }}
                        className={classes.tableHeaderStickyCell}
                      >
                        {column.title}
                      </StyledTableCell>
                    )}
                    {column.name === 'info' && (
                      <StyledTableCell
                        align={column.align}
                        style={{
                          left: 150,
                        }}
                        className={classes.tableHeaderStickyCell}
                      >
                        {column.title}
                      </StyledTableCell>
                    )}
                    {column.name === 'prev' && (
                      <StyledTableCell
                        align={column.align}
                        style={{
                          left: 205,
                        }}
                        className={classes.tableHeaderStickyCell}
                      >
                        <Box className={classes.nextPrevHeaderCell}>
                          <IconButton
                            aria-label="prev"
                            className={classes.nextPrevIconButton}
                            disableFocusRipple
                            disableRipple
                            onClick={() => executeScrollLeft(tableClassName)}
                          >
                            <ChevronLeftOutlinedIcon />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    )}
                    {column.name === 'next' && (
                      <StyledTableCell
                        align={column.align}
                        style={{
                          right: 100,
                        }}
                        className={classes.tableHeaderStickyCell}
                      >
                        <Box className={classes.nextPrevHeaderCell}>
                          <IconButton
                            aria-label="next"
                            className={classes.nextPrevIconButton}
                            disableFocusRipple
                            disableRipple
                            onClick={() => executeScrollRight(tableClassName)}
                          >
                            <ChevronRightOutlinedIcon />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    )}
                    {column.name === '#' && (
                      <StyledTableCell
                        align={column.align}
                        style={{
                          right: 0,
                        }}
                        className={classes.tableHeaderStickyCell}
                      >
                        {column.title}
                      </StyledTableCell>
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {kpiRows.map((rowData: any, rowIndex: number) => (
                <TableRow key={`${tableClassName}_${rowIndex}`}>
                  {kpiColumns.map((column: any, colIndex: number) => (
                    <React.Fragment key={`row_${colIndex}`}>
                      {![
                        'info',
                        'next',
                        'prev',
                        'kpiName',
                        'roundStage',
                        '#',
                      ].includes(column.name) && (
                        <>
                          {columnKeyName === 'kpiName' && (
                            <StyledTableCell align={column.align}>
                              {rowData.isEdit ? (
                                <WrappedPriceInput
                                  name={column.name}
                                  className={classes.textValInput}
                                  InputProps={
                                    !YARDSTICK_KPI_IN_NUMBER.includes(
                                      get(rowData, 'kpiName'),
                                    )
                                      ? {
                                          inputProps: { step: 100, min: 0 },
                                          classes: { root: classes.inputRoot },
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }
                                      : {
                                          inputProps: { step: 100, min: 0 },
                                          classes: { root: classes.inputRoot },
                                        }
                                  }
                                  value={get(
                                    find(rowData.kpiValues, {
                                      key: column.name,
                                    }),
                                    columnValueKeyName,
                                    0,
                                  )}
                                  onChange={(e: any) =>
                                    onChangeRowData(e, rowIndex)
                                  }
                                  error={false}
                                />
                              ) : (
                                displayKPICellValue(
                                  get(
                                    find(rowData.kpiValues, {
                                      key: column.name,
                                    }),
                                    columnValueKeyName,
                                  ),
                                  columnKeyName,
                                  get(rowData, 'kpiName'),
                                )
                              )}
                            </StyledTableCell>
                          )}
                          {columnKeyName === 'cmgrKpiName' && (
                            <StyledTableCell align={column.align}>
                              {rowData.isEdit &&
                              !isEmpty(
                                get(
                                  find(rowData.kpiValues, {
                                    key: column.name,
                                  }),
                                  'kpiValue',
                                ),
                              ) ? (
                                <WrappedTextInput
                                  type="number"
                                  name={column.name}
                                  className={classes.textValInput}
                                  InputProps={{
                                    inputProps: { step: 10, min: 0 },
                                    classes: { root: classes.inputRoot },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={get(
                                    find(rowData.kpiValues, {
                                      key: column.name,
                                    }),
                                    columnValueKeyName,
                                    0,
                                  )}
                                  onChange={(e: any) =>
                                    onChangeCmgrRowData(e, rowIndex)
                                  }
                                  error={false}
                                />
                              ) : (
                                displayKPICellValue(
                                  get(
                                    find(rowData.kpiValues, {
                                      key: column.name,
                                    }),
                                    columnValueKeyName,
                                  ),
                                  columnKeyName,
                                )
                              )}
                            </StyledTableCell>
                          )}
                          {columnKeyName === 'fundRaiseKpiName' && (
                            <StyledTableCell align={column.align}>
                              {rowData.isEdit ? (
                                <>
                                  {column.inputType === 'month' && (
                                    <TextField
                                      select
                                      name={column.name}
                                      className={classes.textValInput}
                                      InputProps={{
                                        classes: { root: classes.inputRoot },
                                      }}
                                      value={get(rowData, column.name, '')}
                                      onChange={(e: any) => {
                                        onChangeFundraiseRowData(
                                          e,
                                          rowIndex,
                                          column.inputType,
                                        );
                                      }}
                                      variant={'standard'}
                                      SelectProps={{
                                        MenuProps: {
                                          classes: { paper: classes.menuPaper },
                                        },
                                      }}
                                    >
                                      {closingMonthList &&
                                        closingMonthList.map((i: any) => (
                                          <MenuItem
                                            value={i.value}
                                            key={i.value}
                                          >
                                            {i.text}
                                          </MenuItem>
                                        ))}
                                    </TextField>
                                  )}
                                  {column.inputType === 'number' &&
                                    (column.name === 'amountRaised' ||
                                      column.name === 'valuation') && (
                                      <WrappedPriceInput
                                        // type="number"
                                        name={column.name}
                                        // id={column.name}
                                        className={classes.textValInput}
                                        InputProps={{
                                          inputProps: {
                                            step: 1000,
                                            min: 0,
                                          },
                                          classes: {
                                            root: classes.inputRoot,
                                          },
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                        value={get(rowData, column.name, 0)}
                                        onChange={(e: any) =>
                                          onChangeFundraiseRowData(
                                            e,
                                            rowIndex,
                                            column.inputType,
                                          )
                                        }
                                        error={false}
                                      />
                                    )}
                                  {column.inputType === 'number' &&
                                    column.name !== 'amountRaised' &&
                                    column.name !== 'valuation' && (
                                      <WrappedTextInput
                                        type="number"
                                        name={column.name}
                                        // id={column.name}
                                        className={classes.textValInput}
                                        InputProps={{
                                          inputProps: { step: 10, min: 0 },
                                          classes: {
                                            root: classes.inputRoot,
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                        value={get(rowData, column.name, 0)}
                                        onChange={(e: any) =>
                                          onChangeFundraiseRowData(
                                            e,
                                            rowIndex,
                                            column.inputType,
                                          )
                                        }
                                        error={false}
                                      />
                                    )}
                                  {column.inputType === 'boolean' && (
                                    <Checkbox
                                      name={column.name}
                                      checked={get(rowData, column.name)}
                                      onChange={(e: any) => {
                                        onChangeFundraiseRowData(
                                          e,
                                          rowIndex,
                                          column.inputType,
                                        );
                                      }}
                                      style={{
                                        padding: 0,
                                      }}
                                    />
                                  )}
                                  {column.inputType === 'list' && (
                                    <TextField
                                      select
                                      name={column.name}
                                      className={classes.textValInput}
                                      InputProps={{
                                        classes: { root: classes.inputRoot },
                                      }}
                                      SelectProps={{
                                        MenuProps: {
                                          classes: { paper: classes.menuPaper },
                                        },
                                      }}
                                      value={get(rowData, column.name, '')}
                                      onChange={(e: any) => {
                                        onChangeFundraiseRowData(
                                          e,
                                          rowIndex,
                                          column.inputType,
                                        );
                                      }}
                                      variant={'standard'}
                                    >
                                      {fundRaisingKpiType &&
                                        fundRaisingKpiType.map((i: any) => (
                                          <MenuItem
                                            value={i.value}
                                            key={i.value}
                                          >
                                            {i.text}
                                          </MenuItem>
                                        ))}
                                    </TextField>
                                  )}
                                </>
                              ) : (
                                displayKPICellValue(
                                  get(rowData, column.name),
                                  columnKeyName,
                                  column.name,
                                )
                              )}
                            </StyledTableCell>
                          )}
                        </>
                      )}
                      {column.name === 'kpiName' && (
                        <StyledTableCell
                          align={column.align}
                          style={{
                            left: 0,
                          }}
                          className={classes.tableBodyStickyCell}
                        >
                          {rowData.isEdit && columnKeyName === 'kpiName' ? (
                            <>
                              <TextField
                                select
                                name={column.name}
                                className={classes.textValInput}
                                InputProps={{
                                  classes: { root: classes.inputRoot },
                                }}
                                SelectProps={{
                                  MenuProps: {
                                    classes: { paper: classes.menuPaper },
                                  },
                                }}
                                value={get(rowData, column.name)}
                                onChange={(e: any) =>
                                  onChangeRowData(e, rowIndex)
                                }
                                variant={'standard'}
                                disabled={
                                  !rowData.isNewRow &&
                                  find(rowData.kpiValues, {
                                    isPrimary: true,
                                  })
                                    ? true
                                    : false
                                }
                              >
                                {kpiList &&
                                  kpiList
                                    .filter((item: any) => {
                                      const primaryOption = !rowData.isNewRow
                                        ? rowData.isPrimary
                                        : rowIndex === 0
                                        ? true
                                        : false;
                                      return isEmpty(primaryKPI)
                                        ? item.isPrimary === primaryOption
                                        : item.isPrimary === primaryOption ||
                                            item.kpiName !== primaryKPI.kpiName;
                                    })
                                    .map((i: any, n: number) => (
                                      <MenuItem value={i.kpiName} key={n}>
                                        {renderKpiName(
                                          i.kpiName,
                                          columnKeyName,
                                        )}
                                      </MenuItem>
                                    ))}
                              </TextField>
                            </>
                          ) : (
                            renderKpiName(
                              get(rowData, column.name),
                              columnKeyName,
                            )
                          )}
                        </StyledTableCell>
                      )}
                      {column.name === 'roundStage' && (
                        <StyledTableCell
                          align={column.align}
                          style={{
                            left: 0,
                          }}
                          className={classes.tableBodyStickyCell}
                        >
                          {rowData.isEdit ? (
                            <TextField
                              select
                              name={column.name}
                              className={classes.textValInput}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  classes: { paper: classes.menuPaper },
                                },
                              }}
                              value={get(rowData, column.name, '')}
                              onChange={(e: any) => {
                                onChangeFundraiseRowData(
                                  e,
                                  rowIndex,
                                  column.inputType,
                                );
                              }}
                              variant={'standard'}
                            >
                              {roundStageList &&
                                roundStageList.map((i: any) => (
                                  <MenuItem value={i.value} key={i.value}>
                                    {i.text}
                                  </MenuItem>
                                ))}
                            </TextField>
                          ) : (
                            get(rowData, column.name)
                          )}
                        </StyledTableCell>
                      )}
                      {column.name === 'info' && (
                        <StyledTableCell
                          align={column.align}
                          style={{
                            left: 150,
                          }}
                          className={classes.tableBodyStickyCell}
                        >
                          {renderKpiNameInfo(
                            get(rowData, 'kpiName'),
                            columnKeyName,
                          )}
                        </StyledTableCell>
                      )}
                      {column.name === 'prev' && (
                        <StyledTableCell
                          align={column.align}
                          style={{
                            left: 205,
                          }}
                          className={classes.tableBodyStickyCell}
                        >
                          {get(rowData, column.name)}
                        </StyledTableCell>
                      )}
                      {column.name === 'next' && (
                        <StyledTableCell
                          align={column.align}
                          style={{
                            right: 100,
                          }}
                          className={classes.tableBodyStickyCell}
                        >
                          {get(rowData, column.name)}
                        </StyledTableCell>
                      )}
                      {column.name === '#' && (
                        <StyledTableCell
                          align={column.align}
                          style={{
                            right: 0,
                          }}
                          className={classes.tableBodyStickyCell}
                        >
                          <Box className={classes.actionEditDeleteCell}>
                            {rowData.isEdit ? (
                              <>
                                <StyledTooltip title="Save">
                                  <IconButton
                                    aria-label="save"
                                    className={classes.iconButton}
                                    disableFocusRipple
                                    disableRipple
                                    onClick={() => handleSaveRowData(rowIndex)}
                                  >
                                    <SaveOutlinedIcon />
                                  </IconButton>
                                </StyledTooltip>
                                <StyledTooltip title="Cancel">
                                  <IconButton
                                    aria-label="cancel"
                                    className={classes.iconButton}
                                    disableFocusRipple
                                    disableRipple
                                    onClick={() =>
                                      handleCancelEditRow(
                                        rowIndex,
                                        !rowData.isEdit,
                                        rowData.isNewRow,
                                      )
                                    }
                                  >
                                    <CancelOutlinedIcon />
                                  </IconButton>
                                </StyledTooltip>
                              </>
                            ) : (
                              <>
                                <StyledTooltip title="Edit">
                                  <IconButton
                                    aria-label="edit"
                                    className={classes.iconButton}
                                    disableFocusRipple
                                    disableRipple
                                    onClick={() =>
                                      handleEditRow(rowIndex, !rowData.isEdit)
                                    }
                                  >
                                    <EditOutlinedIcon />
                                  </IconButton>
                                </StyledTooltip>
                                <StyledTooltip title="Delete">
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.iconButton}
                                    disableFocusRipple
                                    disableRipple
                                    onClick={() =>
                                      handleDeleteRowData(rowIndex)
                                    }
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </StyledTooltip>
                              </>
                            )}
                          </Box>
                        </StyledTableCell>
                      )}
                    </React.Fragment>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog
        open={deleteModal}
        maxWidth={'sm'}
        subheading={''}
        title={
          columnKeyName === 'fundRaiseKpiName'
            ? 'Delete Fundraise Data'
            : 'Delete Benchmark KPI'
        }
        handleClose={() => {
          handleDeleteModalClose();
        }}
        dialogAction={true}
        dialogActionChildren={
          <Box className={classes.backSubmitBox}>
            <Button
              type="submit"
              name="OK"
              onClick={handleDeleteBenchmarkKpi}
              className={classes.submitBtn}
              isLoading={deleteLoading}
              disabled={deleteLoading}
            />
            <Button
              className={classes.backBtn}
              onClick={handleDeleteModalClose}
              disabled={deleteLoading}
              name="Cancel"
            />
          </Box>
        }
      >
        <>
          {deleteErrorMessage && (
            <Typography variant="caption" className={classes.errorMessage}>
              {deleteErrorMessage}
            </Typography>
          )}
          <Typography variant="h5" className={classes.deleteText}>
            {columnKeyName === 'fundRaiseKpiName'
              ? 'Are you sure, you want to delete this funding data?'
              : 'Are you sure, you want to delete the benchmark KPI data?'}
          </Typography>
        </>
      </Dialog>
    </>
  );
};

export default KPITable;
