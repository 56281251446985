import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  wireInstructionBox: {
    padding: '50px 124px 35px 100px',
    '@media (max-width: 1125px)': {
      padding: '50px 5% 35px',
    },
    '@media (max-width: 1024px)': {
      padding: '0px 5% 35px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5% 15px',
    },
  },
  investNowFormHeader: {
    fontSize: 32,
    letterSpacing: 0.3,
    color: theme.palette.warning.contrastText,
    marginBottom: 30,
    fontWeight: 400,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '0px 0 20px',
    },
  },
  wireInstructionSubBox: {
    borderRadius: 5,
    padding: 15,
    boxShadow: theme.shadows[1],
    border: 'solid 1px #D0D2DA',
    background: '#F8F8F8',
  },
  wireInstructionText: {
    fontSize: 22,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    letterSpacing: 0.3,
    marginBottom: 15,
    '@media (max-width: 525px)': {
      fontSize: 18,
    },
  },
  wSubText: {
    fontSize: 16,
    color: theme.palette.grey[50],
    letterSpacing: 0.3,
    marginBottom: 15,
  },
  wHText: {
    fontSize: 18,
    color: theme.palette.grey[100],
    letterSpacing: 0.3,
    fontWeight: 500,
    marginBottom: 15,
  },
  wSSText: {
    fontSize: 14,
    color: theme.palette.grey[50],
    letterSpacing: 0.3,
    marginBottom: 35,
    '@media (max-width: 525px)': {
      marginBottom: 25,
    },
  },
  wBenText: {
    fontSize: 18,
    color: theme.palette.grey[100],
    letterSpacing: 0.3,
    fontWeight: 500,
    marginBottom: 15,
  },
  beneficiaryBox: {
    display: 'flex',
    marginBottom: 20,
  },
  wBSText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    letterSpacing: 0.3,
    marginBottom: 0,
    width: 170,
  },
  wBHText: {
    fontSize: 14,
    color: theme.palette.grey[100],
    letterSpacing: 0.3,
    marginBottom: 0,
    paddingLeft: 60,
    width: 265,
  },
  borderBottom: {
    borderBottom: '1px solid #E5E5E5',
    padding: '30px 0 0px',
    marginBottom: 15,
    '@media (max-width: 525px)': {
      marginBottom: 5,
      padding: '15px 0 0px',
    },
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  wireDetails: {
    fontSize: 18,
    color: theme.palette.grey[100],
    letterSpacing: 0.3,
    fontWeight: 500,
    marginBottom: 18,
    paddingTop: 15,
  },
  impNoteBox: {
    background: '#D2E4FF',
    borderRadius: 5,
    padding: 20,
    marginBottom: 15,
  },
  noteText1: {
    fontSize: 14,
    color: theme.palette.grey[100],
    letterSpacing: 0.3,
    marginBottom: 5,
  },
  fieldText: {
    marginBottom: 30,
    color: theme.palette.grey[100],
    fontSize: 14,
  },
  recevBankTxt: {
    fontSize: 18,
    color: theme.palette.grey[100],
    letterSpacing: 0.3,
    fontWeight: 500,
    marginBottom: 18,
  },
  recevSubBTxt: {
    color: '#70706F',
    paddingLeft: 20,
    fontSize: 14,
    letterSpacing: 0.32,
  },
  accountNumberText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    letterSpacing: 0.3,
    marginBottom: 15,
  },
  TrxnsIdBx: {
    display: 'flex',
    marginBottom: 30,
    paddingTop: 20,
    '@media (max-width: 767px)': {
      display: 'block',
      marginBottom: 10,
    },
  },
  receiptBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 270,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
  orText: {
    width: 30,
    paddingLeft: 35,
    paddingRight: 35,
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: 600,
    '@media (max-width: 767px)': {
      padding: '20px 35px',
    },
  },
  TrxnBox: {
    width: 'calc(100% - 270px - 100px)',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  controllerBox: {
    position: 'relative',
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInput: {
    width: 350,
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    // position: 'absolute',
    // bottom: 12,
    display: 'block',
    textAlign: 'left',
  },
  prevButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    marginRight: 18,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  copyButton: {
    cursor: 'pointer',
    marginLeft: 8,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  fileCopyIcon: {
    fontSize: 14,
  },
  copied: {
    color: 'green',
    height: 0,
    marginBottom: 15,
  },
}));
