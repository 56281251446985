import React from 'react';

import { Box } from 'components';
import ActionsList from 'components/ActionsList';

const StartupAction = () => {
  return (
    <Box>
      <ActionsList status="PENDING" />
    </Box>
  );
};

export default StartupAction;
