import React from 'react';
import { Typography, Avatar } from '@material-ui/core';

import { Box } from 'components';

import styles from './styles';

type ComponentProps = {
  details: any;
  key: number;
};

const Founder = ({ details }: ComponentProps) => {
  const classes = styles();
  const {
    founderName,
    founderEmail,
    // founderQualifications,
    // founderPhoneNumber,
    // founderRole,
  } = details;
  return (
    <Box className={classes.founderBox}>
      <Box className={classes.founderLeft}>
        <Avatar className={classes.iconStyle}>
          {founderName && founderName.charAt(0)}
        </Avatar>
      </Box>
      <Box className={classes.founderRight}>
        <Typography
          variant="h4"
          gutterBottom
          className={classes.labelFundUpHeadText}
        >
          {founderName}
        </Typography>
        {/* <Typography
          variant="body1"
          gutterBottom
          className={classes.labelFundUpText}
        >
          {founderRole}
        </Typography> */}
        <Typography
          variant="body1"
          gutterBottom
          className={classes.labelFundUpText}
        >
          {founderEmail}
        </Typography>
        {/* <Typography
          variant="body1"
          gutterBottom
          className={classes.labelFundUpText}
        >
          {founderQualifications}
        </Typography> */}
        {/* <Typography
          variant="body1"
          gutterBottom
          className={classes.labelInfoText}
        >
          Contact Info {founderPhoneNumber}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default Founder;
