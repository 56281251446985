import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { isEmpty, get } from 'lodash';
import moment from 'moment';

import {
  Box,
  // WrappedSelect,
  StyledTableCell,
  StyledTableRow,
  Loader,
  Button,
} from 'components';
import { formatAmount } from 'common/utils/helpers';
import history from 'common/utils/history';

import styles from './styles';

const headers = (array: any[]) => {
  return (
    <>
      {array.map((i: any, n: number) => {
        return (
          <StyledTableCell
            key={n}
            style={{
              textAlign: i.textAlign ? i.textAlign : 'left',
            }}
          >
            {i.name}
          </StyledTableCell>
        );
      })}
    </>
  );
};

const TABLE_HEADERS = [
  { name: 'Firm Name', textAlign: 'left' },
  { name: 'Fund', textAlign: 'left' },
  { name: 'Fund Size', textAlign: 'right' },
  { name: '% Funded', textAlign: 'right' },
  { name: 'Fund Raised', textAlign: 'right' },
  { name: 'Start Date', textAlign: 'center' },
  { name: 'Close Date', textAlign: 'center' },
  { name: 'Status', textAlign: 'left' },
  { name: 'Action', textAlign: 'left' },
];

const FundsTable = ({ isLoading, filteredSearchData, errorMessage }: any) => {
  const classes = styles();
  return (
    <TableContainer className={classes.fundTableContainer} component={Paper}>
      <Table stickyHeader aria-label="customized table">
        <TableHead>
          <TableRow>{headers(TABLE_HEADERS)}</TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <StyledTableRow>
              <StyledTableCell colSpan={TABLE_HEADERS.length}>
                <Box className={classes.loader}>
                  <Loader />
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ) : isEmpty(filteredSearchData) && !errorMessage ? (
            <StyledTableRow>
              <StyledTableCell colSpan={TABLE_HEADERS.length}>
                <Box className={classes.loader}>
                  <Typography>No data found in table</Typography>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            (filteredSearchData || []).map((row: any, index: number) => (
              <StyledTableRow hover key={index}>
                <StyledTableCell component="th" scope="row" align="center">
                  {get(row, 'vcFirm.name')}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {get(row, 'fundTitle')}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ textAlign: 'right' }}
                >
                  {formatAmount(get(row, 'targetFundSize'), {
                    currency: get(row, 'currency') || 'USD',
                  })}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ textAlign: 'right' }}
                >
                  {`${(
                    (get(row, 'raisedFundAmount') /
                      get(row, 'targetFundSize')) *
                    100
                  ).toFixed(2)}%`}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ textAlign: 'right' }}
                >
                  {formatAmount(get(row, 'raisedFundAmount'), {
                    currency: get(row, 'currency') || 'USD',
                  })}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ textAlign: 'center' }}
                >
                  {get(row, 'estimatedFirstCloseAt')
                    ? moment(get(row, 'estimatedFirstCloseAt')).format(
                        'DD/MM/YYYY',
                      )
                    : '-'}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{ textAlign: 'center' }}
                >
                  {get(row, 'estimatedFinalCloseAt')
                    ? moment(get(row, 'estimatedFinalCloseAt')).format(
                        'DD/MM/YYYY',
                      )
                    : '-'}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {get(row, 'closed')
                    ? 'Final Closed'
                    : get(row, 'firstClosed')
                    ? 'First Closed'
                    : get(row, 'launched') && get(row, 'active')
                    ? 'Launched'
                    : 'In Progress'}
                </StyledTableCell>
                <StyledTableCell
                  className={classes.actionButtonBox}
                  style={{ paddingLeft: 2 }}
                >
                  <Button
                    name="View"
                    className={classes.viewButton}
                    onClick={() =>
                      history.push(`/admin/vc-funds/${get(row, 'id')}`)
                    }
                  >
                    View
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FundsTable;
