import React, { useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { isEmpty, get } from 'lodash';
import moment from 'moment';

import { Box, Loader, StyledTableCell, StyledTableRow } from 'components';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { getVcFirmAllBillingHistoryByInvestorId2 } from 'services';
import { SUBSCRIBED_USER } from 'common/utils/constants';

import styles from './styles';

const headers = (array: any[]) => {
  return (
    <>
      {array.map((i: any, n: number) => {
        return (
          <StyledTableCell
            key={n}
            style={{
              textAlign: i.textAlign ? i.textAlign : 'left',
            }}
          >
            {i.name}
          </StyledTableCell>
        );
      })}
    </>
  );
};

const VC_FIRM_TABLE_HEADERS = [
  { name: 'Transaction Id', textAlign: 'left' },
  { name: 'Plan Name', textAlign: 'left' },
  { name: 'Payment Date', textAlign: 'center' },
  { name: 'AI Anaylst Usage', textAlign: 'right' }, // AI
  { name: 'Amount Paid', textAlign: 'right' },
  { name: 'Service-Period', textAlign: 'center' },
  { name: 'Payment Method', textAlign: 'left' },
  { name: 'Status', textAlign: 'left' },
];

const YARDSTICK_TABLE_HEADERS = [
  { name: 'Transaction Id', textAlign: 'left' },
  { name: 'Plan Name', textAlign: 'left' },
  { name: 'Payment Date', textAlign: 'center' },
  { name: 'Output Generated', textAlign: 'right' },
  { name: 'Amount Paid', textAlign: 'right' },
  { name: 'Service-Period', textAlign: 'center' },
  { name: 'Payment Method', textAlign: 'left' },
  { name: 'Status', textAlign: 'left' },
];

const TransactionHistory = ({ investorId, firmType }: any) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [billingHistory, setBillingHistory] = React.useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');
    getVcFirmAllBillingHistoryByInvestorId2(investorId)
      .then((res: any) => {
        if (!isEmpty(res.data)) {
          const newHistories = res.data.filter(
            (item: any) => item.subscriptionPlan,
          );
          const freeTrialRecord = newHistories[newHistories.length - 1];
          freeTrialRecord.freeTrial = true;
          const index = res.data.findIndex(
            (item: any) => item.id === get(freeTrialRecord, 'id'),
          );
          if (index !== -1 && get(freeTrialRecord, 'amountPaidDecimal') === 0)
            res.data[index] = freeTrialRecord;
        }
        const filtered = (res.data || []).filter((item: any) => {
          if (
            item.status === 'PENDING' ||
            item.amountPaidDecimal === 0 ||
            (item.billingReason === 'subscription_create_with_amount' &&
              item.status === 'FAILED')
          )
            return false;
          else if (item.amountPaidDecimal !== 0) return true;

          return true;
        });
        setBillingHistory(filtered);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  return (
    <Box className={classes.firmVCPaymentBox}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              {headers(
                firmType === SUBSCRIBED_USER.YARDSTICK
                  ? YARDSTICK_TABLE_HEADERS
                  : VC_FIRM_TABLE_HEADERS,
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={
                    firmType === SUBSCRIBED_USER.YARDSTICK
                      ? YARDSTICK_TABLE_HEADERS.length
                      : VC_FIRM_TABLE_HEADERS.length
                  }
                >
                  <Box className={classes.loader}>
                    <Loader />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : isEmpty(billingHistory) && !errorMessage ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={
                    get(billingHistory, '[0].subscriptionPlan.planType') ===
                    'YARDSTICK'
                      ? YARDSTICK_TABLE_HEADERS.length
                      : VC_FIRM_TABLE_HEADERS.length
                  }
                >
                  <Box className={classes.loader}>
                    <Typography>No data found in table</Typography>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              (billingHistory || []).map((row: any) => (
                <StyledTableRow hover key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {get(row, 'paymentGateWayInvoiceId')}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: 'left' }}
                  >
                    {get(row, 'subscriptionPlan.name')}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: 'center' }}
                  >
                    {get(row, 'paidAt')
                      ? moment(get(row, 'paidAt'), 'X').format('MMM DD, YYYY')
                      : '-'}
                  </StyledTableCell>
                  {get(billingHistory, '[0].subscriptionPlan.planType') ===
                  'YARDSTICK' ? (
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ textAlign: 'right' }}
                    >
                      {get(row, 'freeTrial') ? '-' : get(row, 'outputs')}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ textAlign: 'right' }}
                    >
                      {get(row, 'outputs') || '-'}
                    </StyledTableCell>
                  )}
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: 'right' }}
                  >
                    {formatAmount(
                      parseInt(get(row, 'amountDueDecimal')) / 100,
                      {
                        currency: get(row, 'currency') || 'USD',
                      },
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: 'center' }}
                  >
                    {get(row, 'freeTrial') &&
                    get(billingHistory, '[0].subscriptionPlan.planType') ===
                      'YARDSTICK'
                      ? 'NA'
                      : `${moment(get(row, 'billingPeriodFrom'), 'X').format(
                          'MMM DD, YYYY',
                        )} -
                        ${moment(get(row, 'billingPeriodTo'), 'X').format(
                          'MMM DD, YYYY',
                        )}`}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: 'left' }}
                  >
                    {get(row, 'freeTrial') &&
                    get(billingHistory, '[0].subscriptionPlan.planType') ===
                      'YARDSTICK'
                      ? '-'
                      : get(row, 'paymentMethodName')}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {get(row, 'freeTrial')
                      ? 'Free Trial'
                      : get(row, 'status') === 'PAID'
                      ? 'Payment Received'
                      : 'Failed'}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TransactionHistory;
