import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const VcFirmDeactivateSchema = yup.object().shape({
  deactivate: yup.string().required(requiredConstant),
});

export const VcFirmActivateSchema = yup.object().shape({
  deactivate: yup.string().nullable(),
});

export const vcFirmDocReject = yup.object().shape({
  reject: yup.string().required(requiredConstant),
});
