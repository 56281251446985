import { IRoute } from 'common/types';
import AuthLayout from 'layouts/AuthLayout';

import Verify from './verify';

export const VerifyRoutes: IRoute = {
  path: '/verify/:username/:code',
  component: Verify,
  layout: AuthLayout,
  isAdmin: false,
  exact: true,
};
