import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  enquiryViewContainer: {
    paddingLeft: 60,
    '@media (max-width: 1024px)': {
      paddingLeft: 20,
    },
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    },
  },
  enquiryViewBox: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 20px)',
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  safeTracking: {
    marginTop: '30px',
  },
  enquiryTabs: {
    borderBottom: '0.5px solid #DEDEDE',
  },
  enquiryViewBoxLeft: {
    width: 'calc(100% - 300px)',
    flex: '0 auto',
    // paddingRight: 30,
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 260px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 20,
    },
    '@media (max-width: 525px)': {
      paddingLeft: 15,
    },
  },
  enquiryViewBoxLeftFullWidth: {
    width: '100%',
    flex: '0 auto',
    // paddingRight: 30,
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 260px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 20,
    },
    '@media (max-width: 525px)': {
      paddingLeft: 15,
    },
  },

  enquiryViewHeadBox: {},
  OrnamentImg: {},
  // enquiryListhead: {
  //   display: 'flex',
  //   width: '100%',
  //   justifyContent: 'space-between',
  //   paddingTop: 70,
  // },
  enquiryListLeft: {
    position: 'relative',
    paddingLeft: 10,
  },
  enquiryListRight: {
    paddingRight: 30,
  },
  gridItemView: {
    paddingLeft: '20px !important',
  },
  rightIconStyle: {
    border: '1px solid #1EA59A',
    padding: '2px 6px 0',
    borderRadius: 6,
  },
  moreHoriIcon: {
    color: theme.palette.primary.main,
  },
  iconStyleOne: {
    height: 50,
    width: 50,
  },
  enquiryHeadText: {
    fontSize: 29,
    color: theme.palette.error.contrastText,
    marginBottom: 5,
    position: 'relative',
    top: -24,
  },
  enquirySubHeadText: {
    fontSize: 17,
    marginBottom: 10,
    color: theme.palette.primary.main,
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 20,
    left: 40,
  },
  enquiryViewTabBox: {
    paddingRight: 30,
  },
  enquiryViewBoxRight: {
    flexGrow: 1,
    width: 300,
    background: theme.palette.warning.main,
    borderTopRightRadius: 25,
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      width: 260,
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  tanPanelBox: {
    height: 'calc(100vh - 246px)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 265px)',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  tabPaymentBox: {
    height: 'calc(100vh - 285px)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '2px',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2b2b2b',
    },
    '@media (max-width: 1024px)': {
      height: 'calc(100vh - 300px)',
    },
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  contentBoxRight: {
    padding: '60px 25px 30px',
  },
  tabPanelContent: {
    padding: '10px 20px 20px',
    '@media (max-width: 1024px)': {
      padding: '10px 0px 20px',
    },
  },
  tabPanelText: {
    padding: '30px 20px',
  },
  overViewHeadTitle: {
    fontSize: 20,
    color: theme.palette.grey[500],
    marginBottom: 10,
    paddingTop: 20,
  },
  descTitletext: {
    fontSize: 15,
    color: theme.palette.grey[600],
    marginBottom: 25,
  },
  overViewSubHeadTitle: {
    fontSize: 16,
    color: theme.palette.grey[500],
    fontWeight: 600,
  },
  labelStartUpText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    opacity: 0.75,
    marginBottom: 8,
    paddingRight: 15,
  },
  labelStartHead: {
    fontSize: 22,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelStartUpHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelFundHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 10,
  },
  labelStartUp: {
    fontSize: 15,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelStartUpUrl: {
    fontSize: 15,
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginBottom: 25,
  },
  labelFundUpHead: {
    fontSize: 18,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    paddingTop: 5,
  },
  labelFundUpHeadText: {
    fontSize: 15,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    paddingTop: 5,
  },
  labelFundUpText: {
    fontSize: 12,
    color: theme.palette.error.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    opacity: 0.75,
  },
  labelInfoText: {
    fontSize: 12,
    color: theme.palette.primary.main,
    opacity: 0.75,
    marginBottom: 25,
    paddingTop: 5,
  },
  iconStyle: {
    width: 35,
    height: 35,
  },
  menuItemText: {
    display: 'inline-block',
  },
  textValInput: {
    marginBottom: 25,
    width: '100%',
    '@media (max-width: 1120px)': {
      marginBottom: 15,
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  image: {
    height: '100px',
    width: 'initial',
    maxWidth: '100%',
    // color: palette.text.primary,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    // borderRadius: shape.borderRadius,
    zIndex: 5,
    opacity: 1,
    cursor: 'pointer',
  },
  noDocText: {
    margin: '30px',
  },
  bGDocuImg: {
    height: 100,
    width: 104,
    marginTop: 25,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    borderRadius: 5,
  },
  // Document Styling -------- Start

  docuContainer: {
    display: 'grid',
    gridGap: 30,
    padding: '10px 20px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(106px, 1fr) )',
  },
  reviewDocuText: {
    fontSize: 11,
    color: theme.palette.grey[600],
    marginBottom: 10,
  },
  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  founderDetailsBox: {
    marginBottom: 25,
  },
  // Document Styling --------- End
}));
