import { IRoute } from 'common/types';
import SimpleLayout from 'layouts/SimpleLayout';

import InvestorOnboard from './InvestorOnboard';

export const InvestorOnboardRoute: IRoute = {
  path: '/admin/investor-onboard/:id',
  component: InvestorOnboard,
  layout: SimpleLayout,
};
