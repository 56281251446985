import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { isEmpty, get } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { submitQuery, clearState } from 'redux-modules/global/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { Box, WrappedTextInput, Button } from 'components';
import { Ornament } from 'assets';

import { QuerySchema } from './validations';
import styles from './styles';

const ContactUs = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const [isSubmit, setSubmit] = useState<boolean>(false);
  const pathName = useLocation().pathname;

  const { isLoading, errorMessage, successMessage } = useSelector(
    ({ global }: RootState) => global,
  );

  const { startUpId } = useSelector(({ Auth }: RootState) => Auth.user);

  const { handleSubmit, control, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(QuerySchema),
  });

  useEffect(() => {
    if (successMessage) {
      reset({
        title: '',
        message: '',
      });
    } else if (isSubmit && !successMessage) {
      const payload = {
        startupId: startUpId,
        title: getValues().title,
        message: getValues().message,
        queryType: 'ENQUIRY_FORM',
      };
      dispatch(submitQuery(payload));
      setSubmit(false);
    }
  }, [successMessage, isSubmit]);

  const onSubmit = () => {
    dispatch(clearState());
    setSubmit(true);
  };

  return (
    <div>
      <Box className={classes.paymentsLeft}>
        {pathName === '/startup/contact-us' && (
          <Box className={classes.headerPayment}>
            <img
              src={Ornament}
              alt={Ornament}
              className={classes.imgOrnament}
            />
            <Typography gutterBottom className={classes.headPytext}>
              Contact Us
            </Typography>
          </Box>
        )}
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="companyDetailsForm">
        <Grid
          container
          spacing={3}
          className={
            pathName === '/startup/contact-us'
              ? classes.paymentsHeader
              : classes.paymtsPopHeader
          }
        >
          <Typography
            variant="body1"
            gutterBottom
            className={classes.pointText}
          >
            For general enquiry, please reach out to us on{' '}
            <span className={classes.link}>zeronote@8vdx.com</span> or fill the
            form below
          </Typography>
          <Grid item xs={12} md={12} className={classes.inputContainer}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.labelText}
            >
              Enter Subject
            </Typography>
            <Controller
              name="title"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    // label="Enter Subject"
                    placeholder=""
                    className={
                      pathName === '/startup/contact-us'
                        ? classes.textValInput
                        : classes.textPopInput
                    }
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.inputContainer}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.labelText}
            >
              Description
            </Typography>
            <Controller
              name="message"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    multiline={true}
                    // label="Description"
                    placeholder="Start writing here"
                    className={
                      pathName === '/startup/contact-us'
                        ? classes.textValInput
                        : classes.textPopInput
                    }
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          {errorMessage && (
            <Typography variant="body1" className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
          {successMessage && (
            <Typography variant="body1" className={classes.successMessage}>
              {successMessage}
            </Typography>
          )}
          <Button
            type="submit"
            className={classes.continueBtnBox}
            name="Submit"
            isLoading={isLoading}
          />
        </Grid>
      </form>
    </div>
  );
};

export default ContactUs;
