import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import FundRaising from './FundRaising';

export const FundRaisingRoute: IRoute = {
  path: '/fund-raising',
  component: FundRaising,
  layout: DashboardLayout,
  isAdmin: false,
  exact: true,
};
