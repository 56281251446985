import React, { ChangeEvent, useState, useEffect } from 'react';
import { Box, FormControl, Grid, Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import history from 'common/utils/history';
import { RootState } from 'redux-modules/store/rootState';
import { WrappedSelect, Dialog, Button } from 'components';
import {
  getStartupBenchmarkKpiGrowth,
  getStartupValuationInsight,
  getBenchmarkingStartups,
} from 'services';
import {
  businessOpsCountryList,
  stageList,
  industryTypeList,
} from 'common/utils/optionList';
import { formatAmount, errorMessageHandler } from 'common/utils/helpers';

import Chart from '../Chart';
import styles from './styles';
import FundRaisingData from './FundRaisingData';
import BenchmarkBasicDetails from './BenchmarkBasicDetails';

const BenchmarkOutput = ({
  startUpId,
  role,
  setErrorMessage,
  startupDetails,
  errorMessage,
  setViewOutput,
}: any) => {
  const classes = styles();

  const { ycBatches, currentYcBatch } = useSelector(
    ({ Auth }: RootState) => Auth,
  );

  const [open, setOpen] = React.useState(false);
  const [basicDetailsOpen, setBasicDetailsOpen] = React.useState<boolean>(
    false,
  );
  const [fundRaisingOpen, setFundRaisingOpen] = React.useState<boolean>(false);
  const [valuationLoading, setValuationLoading] = React.useState<boolean>(
    false,
  );
  const [filterLoading, setFilterLoading] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [industryList, setIndustryList] = React.useState<any>([]);
  const [ycBatchesList, setYcBatchesList] = React.useState<any>([]);
  const [businessOpsList, setBusinessOpsList] = React.useState<any>([]);
  const [fundingStageList, setFundingStageList] = React.useState<any>([]);
  const [benchmarkKpiGrowth, setBenchmarkKpiGrowth] = React.useState<any>({});
  const [
    filterBenchmarkKpiGrowth,
    setFilterBenchmarkKpiGrowth,
  ] = React.useState<any>({});
  const [valuationInsight, setValuationInsight] = useState<any>({});
  const [
    benchmarkingStartupsList,
    setBenchmarkingStartupsList,
  ] = React.useState<any>([]);
  const [emptyBenchmarkData, setEmptyBenchmarkData] = useState<boolean>(false);

  const {
    industryType,
    ycBatch,
    businessOpsLocation,
    fundingStage,
  } = startupDetails;

  const [filterObj, setFilterObj] = useState<any>({
    industryType: 'ALL',
    fundingStage: 'ALL',
    ycBatch: 'ALL',
    businessOpsLocation: 'ALL',
  });

  // const handleClickOpen = () => {
  //   setFilterObj({
  //     industryType: 'ALL',
  //     fundingStage: 'ALL',
  //     ycBatch: 'ALL',
  //     businessOpsLocation: 'ALL',
  //   });
  //   handleFilterChange('', '', true);
  //   setOpen(true);
  // };

  const handleClose = () => {
    getValuationData();
    setOpen(false);
  };

  const createFilterOptionsList = () => {
    if (ycBatches) {
      const updatedYcBatches = ycBatches.filter(
        (batch: any) => batch.value === ycBatch,
      );
      setYcBatchesList(updatedYcBatches);
    }

    if (businessOpsCountryList) {
      const newCountryList = businessOpsCountryList.map((country: any) => {
        return {
          text: country.country,
          value: country.countryCode,
        };
      });
      const updatedCountryList = newCountryList.filter(
        (country: any) => country.value === businessOpsLocation,
      );
      setBusinessOpsList(updatedCountryList);
    }

    if (stageList) {
      const updatedStageList = stageList.filter(
        (stage: any) => stage.value === fundingStage,
      );
      setFundingStageList(updatedStageList);
    }

    if (industryTypeList) {
      const updatedList = industryTypeList.filter(
        (country: any) => country.value === industryType,
      );
      setIndustryList(updatedList);
    }
  };

  const handleFilterChange = (
    e: any,
    type: string,
    filterObject: any,
    calculateAll = false,
  ) => {
    const obj = { ...filterObject };
    // eslint-disable-next-line no-console
    console.log(filterObject);
    if (!calculateAll) {
      if (type === 'industryType') obj['industryType'] = e.target.value;
      else if (type === 'fundingStage') obj['fundingStage'] = e.target.value;
      else if (type === 'ycBatch') obj['ycBatch'] = e.target.value;
      else if (type === 'businessOpsLocation')
        obj['businessOpsLocation'] = e.target.value;
    }
    setFilterObj(obj);
    const newFilterObj = Object.fromEntries(
      Object.entries(obj).filter((entry: any) => entry[1] !== 'ALL'),
    );

    const filteredStartup = !isEmpty(newFilterObj)
      ? benchmarkingStartupsList.filter((startup: any) => {
          let trueCount = 0;
          Object.entries(newFilterObj).map(([key, value]: any) => {
            if (startup[key] === value) trueCount++;
          });
          return trueCount === Object.keys(newFilterObj).length;
        })
      : benchmarkingStartupsList;
    getStartupBenchmarkGrowthData(
      filteredStartup.map((startup: any) => startup.startupId),
      true,
    );
  };

  const getBenchmarkingStartupsData = () => {
    getBenchmarkingStartups()
      .then((res: any) => {
        setBenchmarkingStartupsList(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.log('ERROR WHILE GETTING BENCHMARK STARTUPS: ', message);
      });
  };

  const getStartupBenchmarkGrowthData = (
    startupIdList: Array<string>,
    filterCall = false,
  ) => {
    filterCall ? setFilterLoading(true) : setIsLoading(true);
    setErrorMessage('');
    getStartupBenchmarkKpiGrowth(
      startUpId,
      {
        startupIdList: startupIdList,
      },
      role,
    )
      .then((res: any) => {
        // filterCall
        //   ? setFilterBenchmarkKpiGrowth(res)
        //   : setBenchmarkKpiGrowth(res);
        setFilterBenchmarkKpiGrowth(res);
        setBenchmarkKpiGrowth(res);
        setFilterLoading(false);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setFilterLoading(false);
        setIsLoading(false);
      });
  };

  const getValuationData = () => {
    setValuationLoading(true);
    setErrorMessage('');
    getStartupValuationInsight()
      .then((res) => {
        setValuationInsight(res);
        setValuationLoading(false);
      })
      .catch((err: any) => {
        setValuationLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const checkBasicPopup = () => {
    if (
      industryList.length === 0 ||
      businessOpsList.length === 0 ||
      fundingStageList.length === 0
    ) {
      setBasicDetailsOpen(true);
    }
  };

  const handleBasicPopupClose = () => {
    setBasicDetailsOpen(false);
    fundRaisingOpen && setOpen(true);
    handleFilterChange(
      '',
      '',
      {
        industryType: industryType,
        fundingStage: fundingStage,
        ycBatch: 'ALL',
        businessOpsLocation: businessOpsLocation,
      },
      true,
    );
  };

  useEffect(() => {
    if (!isEmpty(startupDetails)) {
      createFilterOptionsList();
      if (
        !isEmpty(industryType) ||
        !isEmpty(businessOpsLocation) ||
        !isEmpty(fundingStage)
      ) {
        handleFilterChange(
          '',
          '',
          {
            industryType: industryType,
            fundingStage: fundingStage,
            ycBatch: 'ALL',
            businessOpsLocation: businessOpsLocation,
          },
          true,
        );
      }
    }
  }, [startupDetails]);

  useEffect(() => {
    if (startUpId) {
      getBenchmarkingStartupsData();
      getValuationData();
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     !isEmpty(industryType) ||
  //     !isEmpty(businessOpsLocation) ||
  //     !isEmpty(fundingStage)
  //   ) {
  //     setFilterObj({
  //       industryType: industryType,
  //       fundingStage: fundingStage,
  //       ycBatch: 'ALL',
  //       businessOpsLocation: businessOpsLocation,
  //     });
  //   }
  // }, [industryType, businessOpsLocation, fundingStage]);

  return (
    <Box className={classes.benchmarkOutputContainer}>
      <Box className={classes.benchmarkOutputChart}>
        <Chart
          role={role}
          startUpId={startUpId}
          startupDetails={startupDetails}
          setEmptyBenchmarkData={setEmptyBenchmarkData}
          showBenchmarkGetStarted={false}
        />
      </Box>
      <Box>
        <Box className={classes.benchmarkContent}>
          <Box className={classes.benchmarkContentLeft}>
            <Typography className={classes.benchmarkContentTitle}>
              KPI Benchmarking{' '}
              <Button
                name="Update KPIs"
                className={classes.editProfileBtn}
                startIcon={<EditOutlinedIcon fontSize="small" />}
                onClick={() => {
                  setViewOutput(false);
                }}
              />
            </Typography>
            {!emptyBenchmarkData ? (
              <Box className={classes.benchmarkOutputBox}>
                <Box className={classes.benchmarkOutputBoxLeft}>
                  <Typography
                    variant="body1"
                    className={classes.benchBetterText}
                  >
                    You are doing better than
                  </Typography>
                  <Typography className={classes.benchmarkOutputBoxPer}>
                    {isLoading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <>
                        {parseFloat(
                          get(benchmarkKpiGrowth, 'startupAllRank', 0),
                        ).toFixed(1)}
                        %
                      </>
                    )}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.benchStartupText}
                  >
                    of startups on 8vdX
                  </Typography>
                </Box>
                <Box className={classes.benchmarkOutputBoxRight}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={9} md={8} container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={3}
                          sm={5}
                          className={classes.filterLabelBox}
                        >
                          <Typography className={classes.filterLabelText}>
                            Industry:{' '}
                          </Typography>
                        </Grid>

                        <Grid item xs={9} sm={7}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.filterInput}
                          >
                            <WrappedSelect
                              variant={'outlined'}
                              value={get(filterObj, 'industryType')}
                              onChange={(e: ChangeEvent<any>) =>
                                handleFilterChange(e, 'industryType', filterObj)
                              }
                              onClick={checkBasicPopup}
                              className={classes.textValInputSelect}
                              InputProps={{
                                classes: { root: classes.inputRootSelect },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRootSelect,
                                  focused: classes.labelFocusedSelect,
                                },
                              }}
                              dropdowns={[
                                ...industryList,
                                { text: 'ALL', value: 'ALL' },
                              ]}
                              error={false}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={3}
                          sm={5}
                          className={classes.filterLabelBox}
                        >
                          <Typography className={classes.filterLabelText}>
                            Stage:{' '}
                          </Typography>
                        </Grid>
                        <Grid item xs={9} sm={7}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.filterInput}
                          >
                            <WrappedSelect
                              variant={'outlined'}
                              value={get(filterObj, 'fundingStage')}
                              onChange={(e: ChangeEvent<any>) =>
                                handleFilterChange(e, 'fundingStage', filterObj)
                              }
                              onClick={checkBasicPopup}
                              className={classes.textValInputSelect}
                              InputProps={{
                                classes: { root: classes.inputRootSelect },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRootSelect,

                                  focused: classes.labelFocusedSelect,
                                },
                              }}
                              dropdowns={[
                                ...fundingStageList,
                                { text: 'ALL', value: 'ALL' },
                              ]}
                              error={false}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      {get(startupDetails, 'accelerator') === 'YC' && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={3}
                            sm={5}
                            className={classes.filterLabelBox}
                          >
                            <Typography className={classes.filterLabelText}>
                              YC Batch:
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={7}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              className={classes.filterInput}
                            >
                              <WrappedSelect
                                variant={'outlined'}
                                value={get(filterObj, 'ycBatch')}
                                onChange={(e: ChangeEvent<any>) =>
                                  handleFilterChange(e, 'ycBatch', filterObj)
                                }
                                onClick={checkBasicPopup}
                                placeholder="Loan Tenuare"
                                className={classes.textValInputSelect}
                                InputProps={{
                                  classes: { root: classes.inputRootSelect },
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRootSelect,

                                    focused: classes.labelFocusedSelect,
                                  },
                                }}
                                dropdowns={[
                                  ...ycBatchesList,
                                  { text: 'ALL', value: 'ALL' },
                                ]}
                                error={false}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={3}
                          sm={5}
                          className={classes.filterLabelBox}
                        >
                          <Typography className={classes.filterLabelText}>
                            Geography:
                          </Typography>
                        </Grid>
                        <Grid item xs={9} sm={7}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.filterInput}
                          >
                            <WrappedSelect
                              variant={'outlined'}
                              value={get(filterObj, 'businessOpsLocation')}
                              onChange={(e: ChangeEvent<any>) =>
                                handleFilterChange(
                                  e,
                                  'businessOpsLocation',
                                  filterObj,
                                )
                              }
                              onClick={checkBasicPopup}
                              className={classes.textValInputSelect}
                              InputProps={{
                                classes: { root: classes.inputRootSelect },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRootSelect,

                                  focused: classes.labelFocusedSelect,
                                },
                              }}
                              dropdowns={[
                                ...businessOpsList,
                                {
                                  text: 'ALL',
                                  value: 'ALL',
                                },
                              ]}
                              error={false}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={3}
                      md={4}
                      className={classes.startupRankContainer}
                      style={
                        isEmpty(industryType) ||
                        isEmpty(businessOpsLocation) ||
                        isEmpty(fundingStage)
                          ? { filter: 'blur(5.5px)' }
                          : {}
                      }
                    >
                      <Typography className={classes.startupRankText}>
                        You are doing better than
                      </Typography>
                      <Typography
                        className={classes.startupRankValue}
                        variant="h4"
                      >
                        {filterLoading ? (
                          <Skeleton variant="text" />
                        ) : (
                          <>
                            {parseFloat(
                              get(filterBenchmarkKpiGrowth, 'startupRank', 0),
                            ).toFixed(1)}
                            %
                          </>
                        )}
                      </Typography>
                      <Typography className={classes.startupRankText}>
                        startups when compared within peers
                      </Typography>
                      <Typography className={classes.startupRankCompairedText}>
                        (Compared against:{' '}
                        {get(benchmarkKpiGrowth, 'noOfStartup') > 0
                          ? get(benchmarkKpiGrowth, 'noOfStartup') - 1
                          : get(benchmarkKpiGrowth, 'noOfStartup')}{' '}
                        startups)
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Box className={classes.benchmarkNoOutputBox}>
                <Typography
                  variant="body1"
                  className={classes.benchmarkNoOutputText}
                >
                  Enter the company's latest KPIs to view its aggregate growth
                  rate.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.benchmarkContentRight}>
          <Typography className={classes.benchmarkContentTitle}>
            Estimated Valuation Range
          </Typography>
          <Box className={classes.benchmarkOutputBoxDollar}>
            <Box className={classes.benchmarkOutputBoxDollarContent}>
              {valuationLoading ? (
                <Skeleton variant="text" />
              ) : (
                <Box
                  style={
                    get(valuationInsight, 'fundRaisingDataAdded')
                      ? {}
                      : { paddingBottom: 10, filter: 'blur(5.5px)' }
                  }
                >
                  {!(
                    get(valuationInsight, 'rangeL', 0) > 0 &&
                    get(valuationInsight, 'rangeU', 0) > 0
                  ) ? (
                    <Typography className={classes.benchmarkSubGreenText}>
                      {/* A report on valuation insights will be sent to your
                      registered email shortly.{' '} */}
                      We don't have enough data points for this category at the
                      moment.
                    </Typography>
                  ) : (
                    <>
                      <Typography className={classes.benchmarkSubGreenText}>
                        Based on your KPIs and Traction <br /> your expected
                        current valuation is
                      </Typography>
                      <Typography className={classes.benchmarkOutputGreenText}>
                        {formatAmount(get(valuationInsight, 'rangeL', 0), {
                          maximumFractionDigits: 2,
                          notation: 'compact',
                        })}{' '}
                        -{' '}
                        {formatAmount(get(valuationInsight, 'rangeU', 0), {
                          maximumFractionDigits: 2,
                          notation: 'compact',
                        })}
                      </Typography>
                    </>
                  )}
                </Box>
              )}
              <Typography className={classes.benchmarkSubGreenText}>
                <span
                  onClick={() => {
                    if (
                      isEmpty(industryType) ||
                      isEmpty(businessOpsLocation) ||
                      isEmpty(fundingStage)
                    ) {
                      setBasicDetailsOpen(true);
                      setFundRaisingOpen(true);
                    } else {
                      setOpen(true);
                    }
                  }}
                  className={classes.fundRaisingUpdateText}
                >
                  Please update fundraising history{' '}
                </span>
                to view valuation insights based on your current traction.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.applyVentureDebtBox}>
        {!isEmpty(currentYcBatch) &&
        get(startupDetails, 'ycBatch') === get(currentYcBatch, 'value') ? (
          <Button
            className={classes.applyVentButton}
            name={`Apply for bridge Debt`}
            onClick={() => history.push('/startup/enquiry-form/NEW')}
          />
        ) : (
          <Button
            className={classes.applyVentButton}
            name={`Apply for venture Debt`}
            onClick={() => history.push('/startup/vd-enquiry-form/NEW')}
          />
        )}
        <Button
          variant="outlined"
          className={classes.goToDashButton}
          name="Go To Dashboard"
          onClick={() => history.push('/dashboard')}
        />
      </Box>
      <Dialog
        maxWidth="lg"
        open={open}
        handleClose={() => handleClose()}
        title="Fundraising History"
      >
        <FundRaisingData
          role={role}
          startUpId={startUpId}
          startupDetails={startupDetails}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleClose={handleClose}
        />
      </Dialog>
      <Dialog
        maxWidth={'md'}
        open={basicDetailsOpen}
        handleClose={handleBasicPopupClose}
        title="About Your Company"
      >
        <BenchmarkBasicDetails
          startUpId={startUpId}
          handleClose={handleBasicPopupClose}
        />
      </Dialog>
    </Box>
  );
};

export default BenchmarkOutput;
