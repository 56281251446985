import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tableContainer: {
    boxShadow: theme.shadows[0],
    height: 'calc(100% - 185px)',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTable-root': {
      // height: '100%',
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
    '& .MuiTableCell-root': {
      padding: '16px 16px 10px',
    },
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
  listingBox: {
    margin: '20px 0',
    width: '70%',
    '@media (max-width: 1024px)': {
      width: '100%',
      margin: 0,
    },
  },
  userCountText: {
    color: theme.palette.grey[500],
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
  },
  gridTwo: {
    textAlign: 'right',
    padding: '0 14px',
  },
  dottedIcon: {
    color: theme.palette.grey.A700,
    fontSize: 35,
  },
  dottedThemeIcon: {
    color: theme.palette.primary.main,
    // fontSize: 35,
  },
  actionIcon: {
    color: theme.palette.grey.A700,
    marginRight: 5,
  },
  dividerLine: {
    background: '#DEDEDE',
    height: 2,
    // margin: '0 6px',
  },
  Buttons: {
    // float: 'right',
    padding: '10px 0 0',
    marginBottom: 30,
  },
}));
