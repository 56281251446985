export const ADMIN_GET_ALL_DEAL = 'admin/ADMIN_GET_ALL_DEAL';
export const ADMIN_GET_ALL_DEAL_SUCCESS = 'admin/ADMIN_GET_ALL_DEAL_SUCCESS';
export const ADMIN_GET_ALL_DEAL_FAILURE = 'admin/ADMIN_GET_ALL_DEAL_FAILURE';
export const ADMIN_GET_ALL_DEAL_LOADING = 'admin/ADMIN_GET_ALL_DEAL_LOADING';

export const handleGetAllDeal = () => {
  return {
    type: ADMIN_GET_ALL_DEAL,
  };
};
