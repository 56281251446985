import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import PortFolio from './PortFolio';

export const PortFolioRoute: IRoute = {
  path: '/portfolio',
  component: PortFolio,
  layout: DashboardLayout,
  isAdmin: false,
  exact: true,
};
