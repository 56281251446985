import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Grid, Checkbox, Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';

import { getVdFormValues } from 'redux-modules/global/Actions';
import { trackVdProcess } from 'redux-modules/enquiries/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { Button, Box, WrappedTextInput, Loader, Banner } from 'components';
import { convertVentureDebt } from 'redux-modules/ventureDebt/Actions';
import { FN } from 'common/types';

import styles from './styles';
import { ventureDebtSchema } from './validations';

type Props = {
  handleBack: FN;
  startupId?: string | undefined;
  fundType?: string;
  handleApproveVdRequest?: FN;
};
const VentureDebtForm = ({
  handleBack,
  startupId,
  handleApproveVdRequest,
}: Props) => {
  const dispatch = useDispatch();
  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  const vdFormValues = useSelector(
    ({ global }: RootState) => global.vdFormValues,
  );
  const { vdProcess } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const isVdConversionReqRaised = get(
    vdProcess,
    'vdConversionRequestRaised',
    false,
  );
  const vdConversionRequestApproved = get(
    vdProcess,
    'vdConversionRequestApproved',
    false,
  );

  const isRoleAdmin = role === 'ADMIN';

  const [amountConversionToVdType, setValue] = useState<string>('');
  const [successPop, setSuccessPop] = useState<boolean>(false);

  const { isLoading, successMessage, errorMessage } = useSelector(
    ({ VentureDebt }: RootState) => VentureDebt,
  );

  const { approveVdReq } = useSelector(({ Process }: RootState) => Process);

  const { startUpId } = useSelector(({ Auth }: RootState) => Auth.user);

  const { handleSubmit, control, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ventureDebtSchema),
  });

  useEffect(() => {
    dispatch(getVdFormValues(startupId));
  }, []);

  const classes = styles();
  const onSubmit = () => {
    const payload: any = {
      ...getValues(),
      startupId: startUpId,
      amountConversionToVdType,
    };
    dispatch(convertVentureDebt(payload));
  };
  const onChange = (e: string) => {
    setValue(e);
  };

  useEffect(() => {
    if (!isEmpty(successMessage) || approveVdReq.successMessage) {
      setSuccessPop(true);
      dispatch(trackVdProcess(startupId));
    }
  }, [successMessage, approveVdReq.successMessage]);

  return (
    <div>
      {successPop ? (
        <Banner
          title={successMessage || approveVdReq.successMessage}
          subTitle={''}
          source={'SuccessBanner'}
        />
      ) : (
        <>
          {isLoading && <Loader />}
          {/* {!isEmpty(successMessage) && (
            <Typography variant="caption" className={classes.companyLabelText}>
              {successMessage}
            </Typography>
          )} */}
          {!isEmpty(errorMessage) && (
            <Typography variant="caption" className={classes.errorText}>
              {errorMessage}
            </Typography>
          )}
          <form onSubmit={handleSubmit(onSubmit)} data-testid="ventureDebtForm">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Convert the entire due amount to Venture debt or make part
                  payment and convert remaining amount?
                </Typography>
                <Grid item xs={12} md={12}>
                  <Checkbox
                    value={
                      (vdFormValues && vdFormValues.amountConversionToVd) ||
                      amountConversionToVdType
                    }
                    name="ENTIRE_OUTSTANDING_AMOUNT"
                    onChange={(e: any) => onChange(e.target.name)}
                    checked={
                      amountConversionToVdType ===
                        'ENTIRE_OUTSTANDING_AMOUNT' ||
                      get(vdFormValues, 'amountConversionToVd', '') ===
                        'ENTIRE_OUTSTANDING_AMOUNT'
                    }
                    disabled={isRoleAdmin || isVdConversionReqRaised}
                  />
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Convert entire o/s amount
                  </Typography>
                  <Checkbox
                    name="PARTIAL_AMOUNT"
                    onChange={(e: any) => onChange(e.target.name)}
                    checked={
                      amountConversionToVdType === 'PARTIAL_AMOUNT' ||
                      get(vdFormValues, 'amountConversionToVd', '') ===
                        'PARTIAL_AMOUNT'
                    }
                    disabled={isRoleAdmin || isVdConversionReqRaised}
                  />
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Make part payment
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  2. Amount paid/expected to be paid by due date?
                </Typography>
                <Controller
                  name="expectedOrPaidAmount"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Expected Or Paid Amount"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={
                          get(vdFormValues, 'expectedOrPaidAmount', '') || value
                        }
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled={isRoleAdmin || isVdConversionReqRaised}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  3. What is the amount raised/expected to be raised from Demo
                  day?
                </Typography>
                <Controller
                  name="expectedOrRaisedAmount"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        value={
                          get(vdFormValues, 'expectedOrRaisedAmount', '') ||
                          value
                        }
                        onChange={onChange}
                        label=""
                        placeholder="Enter Amount"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                        disabled={isRoleAdmin || isVdConversionReqRaised}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  4. what is the post-money valuation after the demo day raise?
                </Typography>
                <Controller
                  name="postMoneyValuation"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        value={
                          get(vdFormValues, 'postMoneyValuation', '') || value
                        }
                        onChange={onChange}
                        label=""
                        placeholder="Enter Amount"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                        disabled={isRoleAdmin || isVdConversionReqRaised}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  5. What is the expected utilisation of the money raised from
                  demo day?
                </Typography>
                <Controller
                  name="expectedFundUtilization"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        value={
                          get(vdFormValues, 'expectedFundUtilization', '') ||
                          value
                        }
                        onChange={onChange}
                        label=""
                        placeholder="Type Something"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                        disabled={isRoleAdmin || isVdConversionReqRaised}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
            </Grid>
            <Box className={classes.backContinueBox}>
              {isRoleAdmin ? (
                <Button
                  name="Approve"
                  onClick={() =>
                    handleApproveVdRequest && handleApproveVdRequest()
                  }
                  className={classes.continueBtn}
                  isLoading={approveVdReq.isLoading}
                  disabled={vdConversionRequestApproved}
                />
              ) : (
                !isVdConversionReqRaised && (
                  <Button
                    name="Convert to Venture Debt"
                    type="submit"
                    onClick={() => onSubmit()}
                    className={classes.continueBtn}
                  />
                )
              )}
              <Button
                onClick={() => handleBack()}
                className={classes.backBtn}
                name="Cancel"
              />
            </Box>
          </form>
        </>
      )}
    </div>
  );
};

export default VentureDebtForm;
