import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Box } from 'components';
const useStyles = makeStyles(() => ({
  dialogPaper: {
    borderRadius: 20,
    padding: '20px 25px 35px',
    boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    margin: 15,
    '& .MuiDialogContent-root': {
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#1EA59A',
        width: '0.6em',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: '#F6FAFA',
        minHeight: 24,
        border: '1px solid #1EA59A',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#2B2B2B',
      },
    },
  },
  headerDailog: {
    position: 'relative',
  },
  titleText: {
    textAlign: 'center',
    fontSize: 20,
    padding: '15px 15px 30px',
  },
  subheadingText: {
    textAlign: 'center',
    fontSize: 14,
    padding: '15px 15px 30px',
  },
  closeIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: 12,
    right: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
}));

type DialogType = {
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  open: boolean;
  handleClose?: any;
  title?: string;
  children: ReactNode;
  buttonText?: string;
  subheading?: string;
  className?: any;
  dialogAction?: boolean;
  dialogActionChildren?: ReactNode;
};

export default function DialogComponent({
  maxWidth,
  open,
  handleClose,
  title,
  subheading,
  children,
  className,
  dialogAction,
  dialogActionChildren,
}: DialogType) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        className={className}
        open={open}
        onClose={handleClose}
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        {title && (
          <Box className={classes.headerDailog}>
            <Typography variant="h3" className={classes.titleText}>
              {title}
            </Typography>
            {subheading && (
              <Typography variant="h6" className={classes.subheadingText}>
                {subheading}
              </Typography>
            )}
            <Typography
              variant="caption"
              className={classes.closeIcon}
              onClick={handleClose}
            >
              <CloseIcon />
            </Typography>
          </Box>
        )}

        <DialogContent>{children}</DialogContent>
        {dialogAction && <DialogActions>{dialogActionChildren}</DialogActions>}
      </Dialog>
    </React.Fragment>
  );
}
