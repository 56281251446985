import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { errorMessageHandler } from 'common/utils/helpers';
import { getAllDeal } from 'services';
import { ParamsType, ISagaAction } from 'common/types';

import {
  ADMIN_GET_ALL_DEAL,
  ADMIN_GET_ALL_DEAL_SUCCESS,
  ADMIN_GET_ALL_DEAL_FAILURE,
} from './Actions';

type ResponseType = {
  response: any;
};

const getAllDeals = async () =>
  getAllDeal()
    .then((res) => res)
    .catch((err) => Promise.reject(err));

function* handleGetAllDeals(data: ISagaAction<ParamsType>) {
  const { payload } = data;
  try {
    const response: ResponseType = yield call<any>(getAllDeals, payload);
    yield put({ type: ADMIN_GET_ALL_DEAL_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: ADMIN_GET_ALL_DEAL_FAILURE, payload: message });
  }
}

function* getAllDealList() {
  yield takeEvery(ADMIN_GET_ALL_DEAL, handleGetAllDeals);
}

export default function* DealsSaga() {
  yield all([fork(getAllDealList)]);
}
