import { urlConstants } from 'common/utils/constants';
import {
  AddFundingRoundPayload,
  ApproveFundingRoundPayload,
} from 'redux-modules/fundingRound/Types';

import { secureApi } from '../api/Axios';

export const getStartupFundingRoundList = () =>
  secureApi.get(`${urlConstants.getStartupFundingRoundList}`);

export const getStartupFundingRoundListByAdmin = (startupId: any) =>
  secureApi.get(
    `${urlConstants.getStartupFundingRoundListByAdmin}/${startupId}`,
  );

export const addFundingRound = (payload: AddFundingRoundPayload) =>
  secureApi.post(`${urlConstants.addFundingRound}`, payload);

export const approveFundingRound = (payload: ApproveFundingRoundPayload) =>
  secureApi.post(`${urlConstants.adminApproveFundingRound}`, payload);
