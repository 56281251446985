import React from 'react';
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEmpty, get } from 'lodash';
import { useSelector } from 'react-redux';

import { Box, Button, StyledTableCell, StyledTableRow } from 'components';
import { FN, InviteUser } from 'common/types';
import { ROLES_OPTION } from 'common/utils/constants';
import { RootState } from 'redux-modules/store/rootState';

import styles from './styles';

type UserActionMenuProps = {
  user: InviteUser;
  index: number;
  handleActionEdit: FN;
  handleActionDelete: FN;
  sub?: string;
};

const UserActionMenu = (props: UserActionMenuProps) => {
  const classes = styles();
  const { user, index, handleActionEdit, handleActionDelete, sub } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleActionMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="action"
        aria-controls={`simple-action-menu-${index}`}
        aria-haspopup="true"
        size="medium"
        style={{
          padding: 0,
        }}
        onClick={handleActionMenuClick}
      >
        <MoreHorizRoundedIcon className={classes.dottedThemeIcon} />
      </IconButton>
      <Menu
        id={`simple-action-menu-${index}`}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleActionMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleActionEdit(user);
            handleActionMenuClose();
          }}
        >
          <EditIcon className={classes.actionIcon} /> Edit
        </MenuItem>
        {get(user, 'id') !== sub && (
          <MenuItem
            onClick={() => {
              handleActionDelete(user);
              handleActionMenuClose();
            }}
          >
            <DeleteIcon className={classes.actionIcon} /> Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

type Props = {
  userList: Array<InviteUser>;
  isLoading: boolean;
  errorMessage: string;
  handleDialogOpen: FN;
  setDeleteAction: FN;
};

const UserList = (props: Props) => {
  const {
    isLoading,
    userList,
    errorMessage,
    handleDialogOpen,
    setDeleteAction,
  } = props;

  const classes = styles();

  const { sub } = useSelector(({ Auth }: RootState) => Auth.user);

  const handleActionEdit = (user: any) => {
    handleDialogOpen(user);
  };

  const handleActionDelete = (user: any) => {
    handleDialogOpen(user);
    setDeleteAction(true);
  };

  return (
    <>
      {isLoading ? (
        <Box className={classes.loader}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        !isEmpty(userList) && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography gutterBottom className={classes.userCountText}>
                  {userList.length} Users
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.gridTwo}>
                <MoreHorizRoundedIcon className={classes.dottedIcon} />
              </Grid>
            </Grid>
            <Divider className={classes.dividerLine} />
            <Box className={classes.listingBox}>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <Table stickyHeader aria-label="customized table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <StyledTableCell
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        User
                      </StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Role</StyledTableCell>
                      <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {!errorMessage ? (
                    <TableBody>
                      {userList.map((row: InviteUser, index: number) => (
                        <StyledTableRow hover key={row.id}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{ textAlign: 'left' }}
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              textAlign: 'left',
                            }}
                          >
                            {row.email}
                          </StyledTableCell>
                          <StyledTableCell>
                            {
                              ROLES_OPTION.find((e) => e.value === row.roleId)
                                ?.text
                            }
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.isUser ? (
                              <UserActionMenu
                                user={row}
                                index={index}
                                handleActionDelete={handleActionDelete}
                                handleActionEdit={handleActionEdit}
                                sub={sub}
                              />
                            ) : (
                              ''
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <h2>{errorMessage}</h2>
                  )}
                </Table>
              </TableContainer>
              <Box className={classes.Buttons}>
                <Button
                  variant="contained"
                  name="New User"
                  onClick={() => handleDialogOpen()}
                />
              </Box>
            </Box>
          </>
        )
      )}
    </>
  );
};

export default UserList;
