import React, { useEffect } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';
import moment from 'moment';

import { Box, Button, WrappedTextInput } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { createOrUpdateDeal } from 'services';
import history from 'common/utils/history';

import styles from './styles';
import { StartupDealSchema } from '../../validations';

type Props = {
  handleNext?: any;
  activeStep: number;
  dealType: string;
  deal: any;
  setDeal?: any;
};

const StartupDealForm = ({
  dealType,
  deal,
  activeStep,
  handleNext,
  setDeal,
}: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(StartupDealSchema),
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const payload = {
      ...data,
      dealType,
      schemeCapacity: get(data, 'dealSize'),
    };
    try {
      if (get(deal, 'id')) payload.id = get(deal, 'id');
      const res = await createOrUpdateDeal(payload);
      setDeal(res);
      if (deal) {
        handleNext(activeStep + 1);
      } else {
        history.push(`/admin/new-deal/${get(res, 'id')}`);
      }
    } catch (err) {
      const message: string = errorMessageHandler(err);
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (get(deal, 'id')) {
      setValue('deal', get(deal, 'deal', ''));
      setValue('companyName', get(deal, 'companyName', ''));
      setValue('order', get(deal, 'order', 0));
      setValue('dealSize', get(deal, 'dealSize', 0));
      setValue(
        'startDate',
        moment(get(deal, 'startDate')).format('YYYY-MM-DD'),
      );
      setValue(
        'estimatedCloseDate',
        moment(get(deal, 'estimatedCloseDate')).format('YYYY-MM-DD'),
      );
      setValue('businessModel', get(deal, 'businessModel', ''));
      setValue('geography', get(deal, 'geography', ''));
      setValue('manager', get(deal, 'manager', ''));
      setValue(
        'minimumInvestmentAmount',
        get(deal, 'minimumInvestmentAmount', 0),
      );
      setValue('tenure', get(deal, 'tenure', ''));
      setValue('serviceFee', get(deal, 'serviceFee', ''));
      setValue('frequency', get(deal, 'frequency', 0));
      setValue('originationFee', get(deal, 'originationFee', ''));
      setValue('syndicationFee', get(deal, 'syndicationFee', ''));
    }
  }, [deal]);

  return (
    <Box>
      {errorMessage && (
        <Typography variant="body1" className={classes.errorMessage}>
          {errorMessage}
        </Typography>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="startupDealForm"
        className={classes.validateForm}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Deal
            </Typography>
            <Controller
              name="deal"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Deal"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Company Name
            </Typography>
            <Controller
              name="companyName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Company Name"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Deal Order
            </Typography>
            <Controller
              name="order"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="number"
                    placeholder="Order"
                    className={classes.textValInput}
                    InputProps={{
                      inputProps: { min: 0 },
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Deal Size ($)
            </Typography>
            <Controller
              name="dealSize"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="number"
                    placeholder="Deal Size"
                    className={classes.textValInput}
                    InputProps={{
                      inputProps: { min: 0 },
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Start Date
            </Typography>
            <Controller
              name="startDate"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <TextField
                    id="date-local"
                    type="date"
                    placeholder="Start Date"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Estimated Close Date
            </Typography>
            <Controller
              name="estimatedCloseDate"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <TextField
                    id="date-local"
                    type="date"
                    placeholder="Estimated Close Date"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Business Model
            </Typography>
            <Controller
              name="businessModel"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Business Model"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Geography
            </Typography>
            <Controller
              name="geography"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Geography"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Manager
            </Typography>
            <Controller
              name="manager"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Manager"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Minimum Investment Amount (US $)
            </Typography>
            <Controller
              name="minimumInvestmentAmount"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="number"
                    placeholder="Minimum Investment Amount"
                    className={classes.textValInput}
                    InputProps={{
                      inputProps: { step: 1000, min: 0 },
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Tenure
            </Typography>
            <Controller
              name="tenure"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Tenure"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Servicing Fees (%)
            </Typography>
            <Controller
              name="serviceFee"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Servicing Fees"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Frequency
            </Typography>
            <Controller
              name="frequency"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="number"
                    placeholder="Frequency"
                    className={classes.textValInput}
                    InputProps={{
                      inputProps: { min: 0 },
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Origination Fees (%)
            </Typography>
            <Controller
              name="originationFee"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Origination Fees"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.labelText} variant="body1">
              Syndication Fees (%)
            </Typography>
            <Controller
              name="syndicationFee"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Syndication Fees"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Grid>
        </Grid>
        <Box className={classes.nextPrevButtonBox}>
          <Button
            type="submit"
            className={classes.nextButton}
            name="Next"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </Box>
      </form>
    </Box>
  );
};
export default StartupDealForm;
