import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { Box, Button, WrappedSelect, WrappedTextInput } from 'components';

import styles from './styles';

type Props = {
  spvList: any[];
  control: any;
  handleSubmit: any;
  onSubmit: any;
  isLoading: boolean;
  editMode: boolean;
  handleEditClose: any;
};

const AddInvestmentNavAttr = ({
  spvList,
  control,
  handleSubmit,
  onSubmit,
  isLoading,
  editMode,
  handleEditClose,
}: Props) => {
  const classes = styles();

  return (
    <form
      onSubmit={handleSubmit(() => onSubmit(editMode))}
      data-testid="navAttrForm"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} className={classes.inputHead}>
          <Controller
            name="date"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <TextField
                  id="date-local"
                  label="As of date"
                  type="date"
                  value={editMode && value ? value.split('T')[0] : value}
                  className={classes.textValInput}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                    // inputProps: {
                    //   max: moment().format('yyyy-mm-dd'),
                    // },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                    shrink: true,
                  }}
                  error={error?.message ? true : false}
                  onChange={onChange}
                  disabled={editMode}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputHead}>
          <Controller
            name="spv"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedSelect
                  value={value}
                  onChange={onChange}
                  label="SPV"
                  placeholder="SPV"
                  className={classes.selectValInput}
                  InputProps={{ name: 'SPV', id: 'age-simple' }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  error={!!error?.message}
                  dropdowns={spvList}
                  disabled={editMode}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputHead}>
          <Controller
            name="realisedGain"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="number"
                  label="Realised Gain"
                  placeholder="Realised Gain"
                  className={classes.textValInput}
                  InputProps={{
                    inputProps: { step: 0, min: 0 },
                    classes: { root: classes.inputRoot },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputHead}>
          <Controller
            name="unRealisedGain"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="number"
                  label="Unrealised Gain"
                  placeholder="Unrealised Gain"
                  className={classes.textValInput}
                  InputProps={{
                    inputProps: { step: 0, min: 0 },
                    classes: { root: classes.inputRoot },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputHead}>
          <Controller
            name="expenses"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="number"
                  label="Expenses"
                  placeholder="Expenses"
                  className={classes.textValInput}
                  InputProps={{
                    inputProps: { step: 0, min: 0 },
                    classes: { root: classes.inputRoot },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputHead}>
          <Controller
            name="managementFee"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="number"
                  label="Management Fee"
                  placeholder="Management Fee"
                  className={classes.textValInput}
                  InputProps={{
                    inputProps: { step: 0, min: 0 },
                    classes: { root: classes.inputRoot },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.inputHead}>
          <Controller
            name="distribution"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="number"
                  label="Distribution"
                  placeholder="Distribution"
                  className={classes.textValInput}
                  InputProps={{
                    inputProps: { step: 0, min: 0 },
                    classes: { root: classes.inputRoot },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
      </Grid>
      <Box className={classes.backContinueBox}>
        <Button
          name="Save"
          type="submit"
          className={classes.continueBtn}
          isLoading={isLoading}
          disabled={isLoading}
        />
        {editMode && (
          <Button
            className={classes.backBtn}
            onClick={handleEditClose}
            disabled={isLoading}
            name="Cancel"
          />
        )}
      </Box>
    </form>
  );
};

export default AddInvestmentNavAttr;
