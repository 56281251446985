import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import ProspectiveDeal from './ProspectiveDeal';

export const ProspectiveDealRoute: IRoute = {
  path: '/prospective',
  component: ProspectiveDeal,
  layout: DashboardLayout,
  exact: true,
};
