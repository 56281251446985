import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import VcFundList from './VcFundList';
import VcFunds from './VcFunds';

export const VcFundsViewRoute: IRoute = {
  path: '/admin/vc-funds/:id', // Fund id
  component: VcFunds,
  layout: DashboardLayout,
  exact: true,
};

export const VcFundsListViewRoute: IRoute = {
  path: '/admin/vc-funds',
  component: VcFundList,
  layout: DashboardLayout,
  exact: true,
};
