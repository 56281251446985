import React, { useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  withStyles,
  createStyles,
  Theme,
  Paper,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Box, Button, ConfirmDialog } from 'components';
import {
  handleApproveFundingRound,
  handleGetStartupFundingRoundList,
} from 'redux-modules/fundingRound/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { formatAmount } from 'common/utils/helpers';
import { FN } from 'common/types';

import styles from './styles';

const StyledGroupTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontFamily: theme.typography.fontFamily,
      textAlign: 'center',
      color: '#000',
      // border: '0 !important',
      // borderBottom: '0 !important',
      backgroundColor: '#93C9C7 !important',
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: 0.3,
      border: '1px solid #dedede',
    },
  }),
)(TableCell);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontFamily: theme.typography.fontFamily,
      textAlign: 'center',
      color: '#000',
      // border: '0 !important',
      // border: '1px solid',
      border: '1px solid #dedede',
      backgroundColor: '#93C9C7 !important',
      fontWeight: 400,
      fontSize: 15,
      letterSpacing: 0.3,
      minWidth: 120,
    },
    body: {
      background: theme.palette.common.white,
      color: '#000',
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      border: '1px solid #dedede',
      textAlign: 'center',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const columns = {
  fundingRound: [
    {
      field: 'quarter',
      name: 'Quarter',
    },
    {
      field: 'roundClosingDate',
      name: 'Round Closing Date',
      type: 'DATE',
    },
    {
      field: 'capitalRaised',
      name: 'Capital Raised',
      type: 'AMOUNT',
    },
    {
      field: 'postMoneyValuation',
      name: 'Post Money Valuation',
      type: 'AMOUNT',
      isNA: (fundingRoundType: string) =>
        fundingRoundType === 'SAFE_UNCAPPED' ? 'NA' : null,
    },
    {
      field: 'discountRate',
      name: 'Discount Rate',
    },
    {
      field: 'totalOutstandingShares',
      name: 'Total # Outstanding Shares',
    },
  ],
  bonusSafe: [
    {
      field: 'bonusSafeAmount',
      name: 'Ammount Issued $',
      type: 'AMOUNT',
    },
    {
      field: 'sharesAgainstBonusSafe',
      name: '# Shares alloted to 8vdX',
    },
  ],
  bonusSafeAdmin: [
    {
      field: 'bonusSafeAmount',
      name: 'Ammount Issued $',
      type: 'AMOUNT',
    },
    {
      field: 'sharesAgainstBonusSafe',
      name: '# Shares alloted to 8vdX',
    },
    {
      field: 'bonusSafeEquity',
      name: '% Equity',
      isNA: (fundingRoundType: string) =>
        fundingRoundType === 'SAFE_UNCAPPED' ? 'NA' : null,
    },
    {
      field: 'bonusSafeMFNValuation',
      name: 'MFN Valuation',
      type: 'AMOUNT',
      isNA: (fundingRoundType: string) =>
        fundingRoundType === 'SAFE_UNCAPPED' ? 'NA' : null,
    },
    {
      field: 'bonusMTMValue',
      name: 'MTM Value of 8vdX',
      type: 'AMOUNT',
      isNA: (fundingRoundType: string) =>
        fundingRoundType === 'SAFE_UNCAPPED' ? 'NA' : null,
    },
  ],
  convertedSafe: [
    {
      field: 'convertedSafeAmount',
      name: 'Converted SAFE',
      type: 'AMOUNT',
    },
    {
      field: 'sharesAgainstConvertedSafe',
      name: 'Shares alloted to 8vdX',
    },
  ],
  convertedSafeAdmin: [
    {
      field: 'convertedSafeAmount',
      name: 'Converted SAFE',
      type: 'AMOUNT',
    },
    {
      field: 'sharesAgainstConvertedSafe',
      name: 'Shares alloted to 8vdX',
    },
    {
      field: 'convertedSafeEquity',
      name: '% Equity',
    },
    {
      field: 'conversionValuation',
      name: 'Conversion Valuation',
      type: 'AMOUNT',
    },
    {
      field: 'convertedMTMValue',
      name: 'MTM Value of 8vdX',
      type: 'AMOUNT',
    },
  ],
};

type Props = {
  setEditFundingRoundData: FN;
};

type ApproveFundingRoundParam = {
  id: string;
  startupId: string;
};

const defaultDialogContent = {
  id: '',
  startupId: '',
};

const SafeTrackingTable = ({ setEditFundingRoundData }: Props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const tableRef = useRef<HTMLDivElement>(null);
  // TODO: check if we get rid of approvingId
  // eslint-disable-next-line no-unused-vars
  const [approvingId, setApprovingId] = useState('');
  const [dialogContent, setDialogContent] = useState<ApproveFundingRoundParam>(
    defaultDialogContent,
  );
  const {
    isLoading,
    fundingRoundList,
    errorMessage,
    isApproveFundingRoundLoading,
  } = useSelector(({ FundingRound }: RootState) => FundingRound);

  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  const startupId = get(
    useSelector(({ Enquiries }: RootState) => Enquiries),
    'enquiryView.startupDetails.startupId',
  );
  const { bridgeLoanProcess, loanCloserInfo } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const [showBonusColumns, setShowBonusColumns] = useState<boolean>(false);
  const [showConvertedColumns, setShowConvertedColumns] = useState<boolean>(
    false,
  );

  const isRoleAdmin = role === 'ADMIN';

  if (isRoleAdmin) {
    columns.bonusSafe = columns.bonusSafeAdmin;
    columns.convertedSafe = columns.convertedSafeAdmin;
  }

  useEffect(() => {
    dispatch(handleGetStartupFundingRoundList(isRoleAdmin ? startupId : ''));
  }, []);

  useEffect(() => {
    if (!isApproveFundingRoundLoading && dialogContent.id) {
      setDialogContent(defaultDialogContent);
    }
  }, [isApproveFundingRoundLoading]);

  useEffect(() => {
    let bonusColumnsFlag = false;
    // let convertedColumnsFlag = false;
    fundingRoundList.forEach((data: any) => {
      if (parseInt(data.bonusSafeAmount) > 0) bonusColumnsFlag = true;
      // if (parseInt(data.convertedSafeAmount) > 0) convertedColumnsFlag = true;
    });
    setShowBonusColumns(bonusColumnsFlag);
    // setShowConvertedColumns(convertedColumnsFlag);
  }, [fundingRoundList]);

  useEffect(() => {
    if (loanCloserInfo)
      setShowConvertedColumns(
        loanCloserInfo.status === 'CONVERTED' ||
          loanCloserInfo.status === 'CONVERSION',
      );
  }, [loanCloserInfo]);

  const getRightColBorder = (field: string) => {
    if (isRoleAdmin && field === 'bonusMTMValue') {
      return true;
    } else {
      return !isRoleAdmin && field === 'sharesAgainstBonusSafe';
    }
  };

  const formatValue = (type: string, val: any) => {
    switch (type) {
      case 'DATE':
        return moment(val).format('MM/DD/YYYY');
      case 'AMOUNT':
        val;
        if (val && val.includes('.')) {
          val = parseInt(val);
        }
        return formatAmount(val);
      default:
        return val;
    }
  };

  const getRoundType = (fundingType: string) => {
    if (fundingType === 'EMPTY') return '';
    return fundingType && fundingType.replace('_', ' ');
  };

  const approveFundingRound = ({ id, startupId }: ApproveFundingRoundParam) => {
    setApprovingId(id);
    dispatch(
      handleApproveFundingRound({
        id,
        startupId,
        approved: true,
      }),
    );
  };

  return (
    <Box className={classes.listingBox}>
      <TableContainer
        ref={tableRef}
        className={classes.tableContainer}
        component={Paper}
      >
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <StyledGroupTableCell
                style={{
                  left: 0,
                }}
                className={classes.tableHeaderStickyCell}
              ></StyledGroupTableCell>
              <StyledGroupTableCell
                style={{
                  left: '140px',
                  width: 30,
                  minWidth: 30,
                }}
                className={classes.tableHeaderStickyCell}
              ></StyledGroupTableCell>
              <StyledGroupTableCell
                colSpan={columns.fundingRound.length}
                className={classes.borderRightCell}
              >
                Funding Round Detail
              </StyledGroupTableCell>
              {showBonusColumns && (
                <StyledGroupTableCell
                  colSpan={columns.bonusSafe.length}
                  className={classes.borderRightCell}
                >
                  Bonus SAFE
                </StyledGroupTableCell>
              )}
              {showConvertedColumns && (
                <StyledGroupTableCell
                  colSpan={columns.convertedSafe.length}
                  className={classes.borderRightCell}
                >
                  Converted SAFE
                </StyledGroupTableCell>
              )}
              {!isRoleAdmin && <StyledGroupTableCell></StyledGroupTableCell>}
              <StyledGroupTableCell
                style={{
                  right: isRoleAdmin ? '150px' : 0,
                  width: 30,
                  minWidth: 30,
                }}
                className={classes.tableHeaderStickyCell}
              ></StyledGroupTableCell>
              {isRoleAdmin && (
                <StyledGroupTableCell
                  style={{
                    right: 0,
                  }}
                  className={classes.tableHeaderStickyCell}
                ></StyledGroupTableCell>
              )}
            </TableRow>
            <TableRow>
              <StyledTableCell
                className={classes.tableHeaderStickyCell}
                style={{
                  left: 0,
                }}
              >
                Round Type
              </StyledTableCell>
              <StyledTableCell
                className={classes.tableHeaderStickyCell}
                style={{
                  left: '140px',
                  width: 30,
                  minWidth: 30,
                }}
              >
                <Box className={classes.prevHeaderCell}>
                  <IconButton
                    aria-label="prev"
                    className={classes.nextPrevIconButton}
                    disableFocusRipple
                    disableRipple
                    onClick={() => {
                      if (tableRef && tableRef.current) {
                        tableRef.current.scrollLeft -= 120;
                      }
                    }}
                  >
                    <ChevronLeftOutlinedIcon />
                  </IconButton>
                </Box>
              </StyledTableCell>
              {columns.fundingRound.map((col: any) => (
                <StyledTableCell
                  key={col.field}
                  className={
                    col.field === 'totalOutstandingShares'
                      ? classes.borderRightCell
                      : ''
                  }
                >
                  {col.name}
                </StyledTableCell>
              ))}

              {showBonusColumns &&
                columns.bonusSafe.map((col: any) => (
                  <StyledTableCell
                    key={col.field}
                    className={
                      getRightColBorder(col.field)
                        ? classes.borderRightCell
                        : ''
                    }
                  >
                    {col.name}
                  </StyledTableCell>
                ))}

              {showConvertedColumns &&
                columns.convertedSafe.map((col: any) => (
                  <StyledTableCell key={col.field}>{col.name}</StyledTableCell>
                ))}

              {!isRoleAdmin && <StyledTableCell>Status</StyledTableCell>}
              <StyledTableCell
                className={classes.tableHeaderStickyCell}
                style={{
                  right: isRoleAdmin ? '150px' : 0,
                  width: 30,
                  minWidth: 30,
                }}
              >
                <Box className={classes.nextHeaderCell}>
                  <IconButton
                    aria-label="next"
                    className={classes.nextPrevIconButton}
                    disableFocusRipple
                    disableRipple
                    onClick={() => {
                      if (tableRef && tableRef.current) {
                        tableRef.current.scrollLeft += 120;
                      }
                    }}
                  >
                    <ChevronRightOutlinedIcon />
                  </IconButton>
                </Box>
              </StyledTableCell>
              {isRoleAdmin && (
                <StyledTableCell
                  className={classes.tableHeaderStickyCell}
                  style={{
                    right: 0,
                  }}
                >
                  Action
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={4}>
                  <Box className={classes.loader}>
                    <CircularProgress color="inherit" size={40} />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : errorMessage ? (
              <StyledTableRow>
                <StyledTableCell colSpan={4}>
                  <h2>{errorMessage}</h2>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              fundingRoundList.map((row: any) => (
                <StyledTableRow hover key={row.id}>
                  <StyledTableCell
                    style={{
                      left: 0,
                    }}
                    className={classes.tableBodyStickyCell}
                  >
                    {getRoundType(row.fundingRoundType)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      left: '140px',
                      width: 30,
                      minWidth: 30,
                    }}
                    className={classes.tableBodyStickyCell}
                  ></StyledTableCell>
                  {columns.fundingRound.map((col: any) => (
                    <StyledTableCell key={col.field}>
                      {get(row, 'fundingRoundType') === 'EMPTY'
                        ? ''
                        : (col.isNA &&
                            col.isNA(get(row, 'fundingRoundType'))) ||
                          formatValue(col.type, row[col['field']])}
                    </StyledTableCell>
                  ))}

                  {showBonusColumns &&
                    columns.bonusSafe.map((col: any) => (
                      <StyledTableCell key={col.field}>
                        {(col.isNA && col.isNA(get(row, 'fundingRoundType'))) ||
                          formatValue(col.type, row[col['field']])}
                      </StyledTableCell>
                    ))}

                  {showConvertedColumns &&
                    columns.convertedSafe.map((col: any) => (
                      <StyledTableCell key={col.field}>
                        {formatValue(col.type, row[col['field']])}
                      </StyledTableCell>
                    ))}

                  {!isRoleAdmin && (
                    <StyledTableCell>
                      {row.approved
                        ? 'Approved'
                        : (['EXTENDED', 'CONVERTED'].includes(
                            get(loanCloserInfo, 'status'),
                          ) &&
                            get(row, 'fundingRoundType') === 'EMPTY') ||
                          get(row, 'fundingRoundType') === 'EMPTY'
                        ? ''
                        : 'Approval Pending'}
                    </StyledTableCell>
                  )}

                  <StyledTableCell
                    style={{
                      right: isRoleAdmin ? '150px' : 0,
                      width: 30,
                      minWidth: 30,
                    }}
                    className={classes.tableBodyStickyCell}
                  ></StyledTableCell>
                  {isRoleAdmin && (
                    <StyledTableCell
                      style={{
                        right: 0,
                      }}
                      className={classes.tableBodyStickyCell}
                    >
                      {get(row, 'fundingRoundType') !== 'EMPTY' && (
                        <>
                          <Button
                            name="Edit"
                            disabled={row.approved}
                            onClick={() => setEditFundingRoundData(row)}
                          />
                          <Button
                            name="Approve"
                            disabled={row.approved}
                            onClick={() => setDialogContent(row)}
                          />
                        </>
                      )}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {get(bridgeLoanProcess, 'isLoanProcessed') &&
        !isLoading &&
        !errorMessage &&
        isEmpty(fundingRoundList) && (
          <Typography align="center" variant="h5">
            No Records Found
          </Typography>
        )}
      <ConfirmDialog
        dialogOpen={!isEmpty(dialogContent.id)}
        onClose={() => setDialogContent(defaultDialogContent)}
        onCancel={() => setDialogContent(defaultDialogContent)}
        dialogHeading=""
        dialogContent={`Are you sure you want to Approve`}
        isLoading={isApproveFundingRoundLoading}
        onConfirm={() => approveFundingRound(dialogContent)}
      />
    </Box>
  );
};

export default SafeTrackingTable;
