import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { isEmpty, get } from 'lodash';

import {} from 'common/utils/helpers';
import {
  Box,
  StyledTableCell,
  StyledTableRow,
  Loader,
  Button,
} from 'components';

import styles from './styles';
import CreateNewPlan from './CreateNewPlan';

type Props = {
  unsubscribedUsersAllData: Array<Record<string, any>>;
  getUnsubscibedUsersList: any;
  isLoading: boolean;
  subscriptionPlanAllData: any;
};

const headers = (array: any[]) => {
  return (
    <>
      {array.map((i: any, n: number) => {
        return (
          <StyledTableCell
            key={n}
            style={{
              textAlign: i.textAlign ? i.textAlign : 'left',
            }}
          >
            {i.name}
          </StyledTableCell>
        );
      })}
    </>
  );
};

const TABLE_HEADERS = [
  { name: 'Client Name', textAlign: 'left' },
  { name: 'Email', textAlign: 'left' },
  { name: 'Action', textAlign: 'center' },
];

const VcUnsubsribedUser = ({
  unsubscribedUsersAllData,
  isLoading,
  subscriptionPlanAllData,
  getUnsubscibedUsersList,
}: Props) => {
  const classes = styles();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentInvestorData, setCurrentInvestorData] = useState<any>({});

  useEffect(() => {
    getUnsubscibedUsersList();
  }, []);

  return (
    <>
      <Box className={classes.firmAdminTableBox}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>{headers(TABLE_HEADERS)}</TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={TABLE_HEADERS.length}>
                    <Box className={classes.loader}>
                      <Loader />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : isEmpty(unsubscribedUsersAllData) ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={TABLE_HEADERS.length}>
                    <Box className={classes.loader}>
                      <Typography>No data found in table</Typography>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                (unsubscribedUsersAllData || []).map(
                  (row: any, index: number) => (
                    <StyledTableRow hover key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {get(row, 'name', '-')}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {get(row, 'email')}
                      </StyledTableCell>

                      <StyledTableCell
                        className={classes.actionButtonBox}
                        style={{ paddingLeft: 2, textAlign: 'center' }}
                      >
                        <Button
                          name="Assign"
                          className={classes.viewButton}
                          onClick={() => {
                            setIsOpen(true);
                            setCurrentInvestorData(row);
                          }}
                        >
                          Assign
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ),
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CreateNewPlan
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        getUnsubscibedUsersList={getUnsubscibedUsersList}
        type={'ASSIGN'}
        subscriptionPlanAllData={subscriptionPlanAllData}
        currentInvestorData={currentInvestorData}
      />
    </>
  );
};

export default VcUnsubsribedUser;
