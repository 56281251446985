/* eslint-disable no-unused-vars */
import { Typography, Snackbar } from '@material-ui/core';
// eslint-disable-next-line import/order
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/order
import Alert from '@material-ui/lab/Alert';

//import { Button } from 'components';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { VDStepProps } from 'common/types';
import { Box, Button } from 'components';
import { RootState } from 'redux-modules/store/rootState';
import { trackBridgeLoanProcess } from 'redux-modules/enquiries/Actions';
import history from 'common/utils/history';

import {
  getDocuSignUrl,
  trackBridgeLoanProcess as trackBridgeLoanProcessService,
} from '../../../services';
import styles from '../styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DocuSignView = ({ handleNext, stepName, id }: VDStepProps) => {
  const { bridgeLoanProcess } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );
  const [loading, setLoading] = React.useState(false);
  const [signingInprogress, setSigningInprogress] = useState(false);
  const [signingChangesWaiting, setSigningChangesWaiting] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const [documentSigned, setDocumentSigned] = React.useState(false);
  const dispatch = useDispatch();

  const { startUpId } = useSelector(({ Auth }: RootState) => Auth.user);

  useEffect(() => {
    if (bridgeLoanProcess) {
      const isDocumentsSigned = get(bridgeLoanProcess, 'documentsSigned');
      setDocumentSigned(isDocumentsSigned);
    } else {
      dispatch(trackBridgeLoanProcess(startUpId));
    }
  }, [bridgeLoanProcess]);

  useEffect(() => {
    handleDocusignPost();
  }, []);
  //setSuccessPage(true);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  let interval;
  let count = 0;
  const handleDocusignPost = () => {
    const search = get(history, 'location.search');
    const event = new URLSearchParams(search).get('event');
    if (event) {
      setSigningChangesWaiting(true);
      window.localStorage.setItem('closeTab', 'yes'); //eslint-disable-line
      if (event === 'signing_complete') {
        interval = setInterval(async () => {
          if (count < 6) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const res = await trackBridgeLoanProcessService(startUpId);
            console.log(res); //eslint-disable-line
            if (get(res, 'documentsSigned')) {
              // history.push('/dashboard');
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              clearInterval(interval); // thanks @Luca D'Amico
              history.push('/dashboard');
            }
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            clearInterval(interval); // thanks @Luca D'Amico
            history.push('/dashboard');
          }

          count += 1;
        }, 5000);
      } else {
        setOpenNotification(true);
        setSigningChangesWaiting(false);
        setNotificationMessage(`Event from Docusign: ${event}, Please retry`);
      }
    }
  };

  const handleSignButton = async () => {
    let docuUrl;
    setLoading(true);
    setSigningInprogress(true);
    let new_tab;

    try {
      if (!documentSigned) {
        new_tab = window.open();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new_tab.document.write(
          "<div style='text-align: center;font-size: 24px;margin-top: 40px;font-weight: 600;'>Loading...<div>",
        );
        docuUrl = await getDocuSignUrl();

        window.addEventListener('storage', () => {
          // When local storage changes, dump the list to
          // the console.
          // eslint-disable-next-line
      if(window.localStorage.getItem('closeTab') === 'yes'){
            localStorage.removeItem('closeTab');
            window.close();
          }
        });
        if (docuUrl) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          new_tab.location.href = docuUrl;
        }
        setSigningInprogress(true);
        setSigningChangesWaiting(true);
      }
    } catch (err) {
      if (new_tab) {
        new_tab.close();
      }
      console.log(err); //eslint-disable-line
      setNotificationMessage('Error in fetching url');
      setOpenNotification(true);
      setSigningInprogress(false);
      setSigningChangesWaiting(false);
    } finally {
      setLoading(false);
    }
  };
  const classes = styles();
  return (
    <Box>
      <>
        <Typography variant="h2" className={classes.detailsText}>
          Apply for Venture Debt
        </Typography>
        <Box className={classes.docuSignBox}>
          <Typography variant="h4" className={classes.docuSignText}>
            Please sign the below debt documents
          </Typography>
          {/* <Typography variant="body1" className={classes.listPoints}>
            1. AOA, MOA, Incorporation certificate,
          </Typography>
          <Typography variant="body1" className={classes.listPoints}>
            2. Other KYC documents - PAN, Aadhar Card, etc
          </Typography> */}
          <Typography variant="body1" className={classes.listPoints}>
            1. Promissory Note
          </Typography>
          <Typography variant="body1" className={classes.listPoints}>
            2. Bonus SAFE Agreement
          </Typography>
          <Box className={classes.docuSignBtn}>
            {documentSigned ? (
              <Button
                className={classes.uploadBtn}
                name="e-Signatures completed"
                disabled={true}
              />
            ) : (
              <>
                <Button
                  className={classes.uploadBtn}
                  name={`${
                    signingInprogress || signingChangesWaiting
                      ? 'Signing in progress'
                      : 'Sign Documents'
                  } `}
                  disabled={
                    loading || signingInprogress || signingChangesWaiting
                  }
                  isLoading={
                    loading || signingInprogress || signingChangesWaiting
                  }
                  onClick={() => {
                    handleSignButton();
                  }}
                />
                <Typography variant="body1" className={classes.redirectTxt}>
                  You will be redirected to DocuSign.
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </>
      <Snackbar
        open={openNotification}
        autoHideDuration={5000}
        onClose={() => setOpenNotification(false)}
        message="Note archived"
        // action={action}
      >
        <Alert severity="error">{notificationMessage}</Alert>
      </Snackbar>
    </Box>
  );
};

export default DocuSignView;
