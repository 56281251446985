import { IRoute } from 'common/types';
import AuthLayout from 'layouts/AuthLayout';

import ConfirmPassword from './ConfirmPassword';

export const ConfirmPasswordRoute: IRoute = {
  path: '/set-password/:username/:code',
  component: ConfirmPassword,
  layout: AuthLayout,
  exact: true,
};
