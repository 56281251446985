import React, { ReactNode } from 'react';
import LoadingOverlay, { LoadingOverLayProps } from 'react-loading-overlay-ts';

import styles from './styles';

interface props extends LoadingOverLayProps {
  active: boolean;
  fadeSpeed?: number | undefined;
  onClick?: any;
  className?: string | undefined;
  spinner?: boolean | ReactNode;
  text?: ReactNode;
  children: ReactNode;
  innerRef?: any;
}

const WrappedLoadingOverlay = ({
  children,
  active,
  spinner,
  className,
  text,
  innerRef,
  onClick,
  fadeSpeed,
  ...props
}: props) => {
  const classes = styles();
  return (
    <LoadingOverlay
      active={active}
      spinner={spinner}
      className={className ? className : classes.loadingOverlay}
      text={text}
      innerRef={innerRef}
      onClick={onClick}
      fadeSpeed={fadeSpeed}
      {...props}
    >
      {children}
    </LoadingOverlay>
  );
};

export default WrappedLoadingOverlay;
