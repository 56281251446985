import * as yup from 'yup';
import { isEmpty } from 'lodash';

import {
  requiredConstant,
  urlRegex,
  urlValidConstant,
} from 'common/utils/constants';
import { getParsedPriceInputValue } from 'common/utils/helpers';

export const updateActiveDealStartupProfileDetail = yup.object().shape({
  companyName: yup.string().required(requiredConstant),
  currentValuation: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant)
    .test(
      'positive',
      'Value must be greater than 0',
      (value) =>
        value !== undefined &&
        value !== null &&
        getParsedPriceInputValue(value) > 0,
    ),
  companyUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  sector: yup.string().nullable().required(requiredConstant),
  lastFundingRound: yup.string().nullable().required(requiredConstant),
  geography: yup.object().nullable().required(requiredConstant),
  companyPitchdeckLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
});

export const updateProspectiveDealStartupProfileDetail = yup.object().shape({
  companyName: yup.string().required(requiredConstant),
  proposedValuation: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant)
    .test(
      'positive',
      'Value must be greater than 0',
      (value) =>
        value !== undefined &&
        value !== null &&
        getParsedPriceInputValue(value) > 0,
    ),
  lastFundingRound: yup.string().nullable().required(requiredConstant),
  companyUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  sector: yup.string().nullable().required(requiredConstant),
  geography: yup.object().nullable().required(requiredConstant),
  companyPitchdeckLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
});
