import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { Box, DropZone } from 'components';
import { IconImg, ColorFill } from 'assets';
import { RootState } from 'redux-modules/store/rootState';
import { IDoc } from 'redux-modules/process/Types';
import { FN } from 'common/types';

import styles from '../../styles';

type props = {
  docu: IDoc;
  value: number;
  startupId: string | undefined;
  loading: boolean;
  error: string;
  success: string;
  documentObj: any;
  index: number;
  setSuccess: FN;
  setError: FN;
  handleDeleteDocument?: FN;
  handleGetSignedURL: FN;
  handleCancelDocument?: FN;
};

const ReviewDocument = ({
  docu,
  value,
  loading,
  error,
  success,
  documentObj,
  index,
  // setSuccess,
  setError,
  // handleDeleteDocument,
  handleGetSignedURL,
  handleCancelDocument,
}: props) => {
  const classes = styles();
  const { role } = useSelector(({ Auth }: RootState) => Auth.user);

  const data = JSON.parse(JSON.stringify(docu));

  const { id, documentStatus } = data;
  const isRoleAdmin = role === 'ADMIN';

  const handleGetSrcUrl = () => {
    // if (get(bridgeLoanProcess, 'documentsSubmitted')) {
    return get(data, 'documentUrl');
    // } else {
    //   return get(data, 'srcUrl');
    // }
  };

  return (
    <Box key={value} className={classes.documentRequestBox}>
      {documentStatus !== 'REQUESTED' ? (
        <img src={IconImg} alt={IconImg} className={classes.iconImg} />
      ) : (
        <img src={ColorFill} alt={ColorFill} className={classes.colorFill} />
      )}
      <Box className={classes.thumbnail}>
        <Box className={classes.image}>
          {handleGetSrcUrl() && (role === 'STARTUP_ADMIN' || role === 'ADMIN') && (
            <DropZone
              handleFile={(file: any) => handleGetSignedURL(file, data, value)}
              imageUploading={documentObj.id === id ? loading : false}
              key={value}
              fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
              handleDeleteDocument={() => {
                setError('');
                handleCancelDocument(data);
              }}
              data={data}
              isRoleAdmin={isRoleAdmin}
            />
          )}
          <Box className={classes.docutext}>
            <Typography className={classes.documenttext}>
              {data.documentName}
              {!data.isOptional ? '*' : ''}
            </Typography>
            {data.documentDescription ? (
              <Typography variant="body1" className={classes.boardStext}>
                {data.documentDescription}
              </Typography>
            ) : null}
          </Box>
        </Box>
      </Box>
      {/* {data.sampleDocDownloadLink ? (
        <Typography variant="body1" className={classes.templateText}>
          <a href={data.sampleDocDownloadLink} target="_blank" rel="noreferrer">
            Template
          </a>
        </Typography>
      ) : null} */}
      <Box className={classes.documentUpload}>
        {data.sampleDocDownloadLink ? (
          <Typography variant="body1" className={classes.templateText}>
            <a
              href={`${data.sampleDocDownloadLink}?version=4`}
              target="_blank"
              rel="noreferrer"
            >
              Template
            </a>
          </Typography>
        ) : null}
        {(role === 'STARTUP_ADMIN' || role === 'ADMIN') &&
          documentStatus === 'REQUESTED' &&
          documentObj.id !== id &&
          !get(data, 'srcUrl') && (
            <DropZone
              handleFile={(file: any) => handleGetSignedURL(file, data, value)}
              imageUploading={documentObj.id === id ? loading : false}
              key={value}
              fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
              handleDeleteDocument={() => {
                setError('');
                handleCancelDocument(data);
              }}
              data={data}
            />
          )}
        {documentObj.id === id && loading && (
          <Typography className={classes.uploadingStatus}>Uploading</Typography>
        )}
        {(!value || value <= index || value >= index) &&
          documentStatus === 'UPLOADED' && (
            <Typography className={classes.uploadedStatus}>
              {success}
            </Typography>
          )}
        {value <= index && documentStatus === 'REQUESTED' && error && (
          <Typography className={classes.errorStatus}>{error}</Typography>
        )}
      </Box>
    </Box>
  );
};
export default memo(ReviewDocument);
