import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  pointText: {
    fontSize: 16,
    letterSpacing: '0.35px',
    marginBottom: 30,
    lineHeight: '24px',
    color: theme.palette.grey.A400,
    maxWidth: 520,
  },
  labelText: {
    display: 'block',
    fontSize: 15,
    width: '100%',
    color: theme.palette.grey[50],
    marginBottom: 0,
    letterSpacing: '0.35px',
  },
  textValInput: {
    marginBottom: 25,
    marginTop: 7,
    maxWidth: 370,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputContainer: {
    position: 'relative',
    padding: '12px 0 !important',
  },
  textPopInput: {
    marginBottom: 25,
    marginTop: 7,
    maxWidth: 370,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    lineHeight: '22px',
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
    '& .MuiInputBase-inputMultiline': {
      maxHeight: 200,
      overflowY: 'auto !important',
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#1EA59A',
        width: '2px',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: '#F6FAFA',
        minHeight: 24,
        border: '1px solid #1EA59A',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#2b2b2b',
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 15,
    left: 0,
    width: '100%',
  },
  continueBtnBox: {
    width: 170,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    cursor: 'pointer',
    height: 58,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    marginTop: 25,
  },
  errorMessage: {
    color: 'red',
    position: 'absolute',
    bottom: 90,
  },
  detailsText: {
    fontSize: 29,
    color: theme.palette.error.contrastText,
    marginBottom: 50,
    marginTop: -8,
    paddingLeft: 2,
    position: 'relative',
    top: -20,
    '@media (max-width: 1120px)': {
      marginBottom: 20,
      marginTop: 30,
    },
  },
  paymentsLeft: {
    width: 'calc(100% - 300px)',
    flex: '0 auto',
    // paddingRight: 30,
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 260px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 20,
    },
    '@media (max-width: 525px)': {
      paddingLeft: 15,
    },
  },
  headerPayment: {
    position: 'relative',
    padding: '58px 63px 20px',
    '@media (max-width: 1024px)': {
      padding: '98px 40px 20px',
    },
    '@media (max-width: 767px)': {
      padding: '50px 20px 15px',
    },
  },
  headPytext: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    marginBottom: 0,
    '@media (max-width: 1024px)': {
      fontSize: 24,
    },
  },
  imgOrnament: {
    position: 'absolute',
    top: 40,
    left: 35,
    height: 90,
    width: 90,
    '@media (max-width: 1120px)': {
      width: 60,
      height: 60,
    },
    '@media (max-width: 1024px)': {
      top: 83,
      left: 20,
      objectFit: 'cover',
    },
    '@media (max-width: 767px)': {
      top: 23,
      left: 20,
      objectFit: 'cover',
    },
  },
  paymentsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 18,
    position: 'relative',
    padding: '0 50px 0 77px',
  },
  paymtsPopHeader: {
    padding: '0 27px 17px',
    position: 'relative',
  },
  successMessage: {
    color: theme.palette.primary.main,
    fontSize: 16,
    display: 'block',
    margin: '0',
    position: 'absolute',
    bottom: 90,
    width: '100%',
  },
  link: {
    color: theme.palette.primary.main,
  },
}));
