import React, { useEffect, useState } from 'react';
import { SidePane } from 'react-side-pane';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';

import history from 'common/utils/history';
import { Box, Loader, TabPanel, Tabs, Button } from 'components';
import { getYardstickPermissions, acceptYardStickPermInvite } from 'services';

import styles from './styles';

type YardstickRequestsProps = {
  openSidePane: boolean;
  onCloseSidePan: any;
};
const YardstickRequests = ({
  openSidePane,
  onCloseSidePan,
}: YardstickRequestsProps) => {
  const classes = styles();

  const [value, setValue] = useState<number>(0);
  const [permissions, setPermissions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const YardstickTabs = [
    { name: 'All Requests', value: 'ALL_REQ' },
    { name: 'Activated Investors', value: 'ACT_INV' },
    { name: 'Deactivated Investors', value: 'DACT_INV' },
  ];

  const handleOnClickPermission = (permission: any, grant: boolean) => {
    const req = {
      permissionId: get(permission, 'id'),
      grant,
      investorId: get(permission, 'investorId'),
    };
    setIsLoading(true);
    acceptYardStickPermInvite(req)
      .then(() => fetchYardstickPermissions())
      .catch(() => setIsLoading(false));
  };

  const handleRenderTabs = (tabVal: string) => {
    let filteredPermissions = permissions;

    if (tabVal === 'ALL_REQ') {
      filteredPermissions = permissions.filter(
        (item: any) => !item.actionTaken,
      );
    }
    if (tabVal === 'ACT_INV') {
      filteredPermissions = permissions.filter((item: any) => item.active);
    }
    if (tabVal === 'DACT_INV') {
      filteredPermissions = permissions.filter(
        (item: any) => !item.active && item.actionTaken,
      );
    }
    if (isEmpty(filteredPermissions)) {
      return <div className={classes.noRecordsPerm}>No Records</div>;
    } else {
      return (filteredPermissions || []).map((perm: any) => (
        <Box key={perm.id} className={classes.permWrapper}>
          <Box>
            {get(perm, 'investor.name')}{' '}
            <span className={classes.timeAgo}>
              ({moment(get(perm, 'createdAt')).fromNow()})
            </span>
          </Box>
          <Box>
            {' '}
            <Box>
              {(!get(perm, 'actionTaken') || !get(perm, 'active')) && (
                <Button
                  value="center"
                  aria-label="centered"
                  className={classes.enableToggle}
                  disabled={isLoading}
                  onClick={() => handleOnClickPermission(perm, true)}
                  name="Enable"
                />
              )}
              {(!get(perm, 'actionTaken') || get(perm, 'active')) && (
                <Button
                  value="left"
                  aria-label="left aligned"
                  className={classes.disableToggle}
                  onClick={() => handleOnClickPermission(perm, false)}
                  disabled={isLoading}
                  name="Disable"
                />
              )}
            </Box>
          </Box>
        </Box>
      ));
    }
  };

  const fetchYardstickPermissions = () => {
    setIsLoading(true);
    getYardstickPermissions()
      .then((res) => {
        setPermissions(res);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    fetchYardstickPermissions();
  }, []);

  const handlePaneClose = () => {
    const query = new URLSearchParams(window.location.search);
    const access = query.get('access');
    const investor = query.get('investor');
    if (access || investor) {
      history.push('/benchmark-kpi-v2/EDIT');
    }
    onCloseSidePan();
  };
  return (
    <>
      <SidePane
        open={openSidePane}
        // autoWidth={true}
        onClose={handlePaneClose}
        backdropClassName="yardstick-sidepane"
        width={45}
        // className={classes.vPaneMobile}
      >
        <>
          <div style={{ textAlign: 'right', padding: 10 }}>
            <CloseIcon onClick={handlePaneClose} />
          </div>
          <Box className={classes.sidePaneWrapper}>
            {isLoading && <Loader className={classes.permLoader} />}
            <Tabs
              tabs={YardstickTabs}
              value={value}
              handleChange={(e: any, val: number) => setValue(val)}
              className={classes.yardstickTypeTabs}
            />

            {YardstickTabs.map((tab: any, index: number) => (
              <TabPanel
                value={value}
                index={index}
                key={tab.value}
                className={classes.yardstickTabs}
              >
                {handleRenderTabs(tab.value)}
              </TabPanel>
            ))}
          </Box>
        </>
      </SidePane>
    </>
  );
};

export default YardstickRequests;
