import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  settingsContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
  },
  settingsContent: {},
  settingsHead: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '80px 80px 30px 67px',
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 43,
    left: 40,
  },
  headText: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    fontWeight: 400,
    letterSpacing: 0.3,
    position: 'relative',
    top: -24,
  },
  settingsContentText: {
    paddingLeft: 60,
    paddingRight: 80,
    height: 'calc(100vh - 210px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  enquiryTabs: {
    borderBottom: '0.5px solid #DEDEDE',
  },
  settingsBodyPaddingTop: {
    paddingTop: '20px',
  },
  settingsTabPanel: {
    height: 'calc(100vh - 230px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      // height: 'calc(100% - 60px - 80px)',
    },
  },
}));
