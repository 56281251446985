import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FacebookIcon from '@material-ui/icons/Facebook';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import {
  Button,
  Box,
  Dialog,
  Loader,
  SuccessPage,
  WrappedTextInput,
  WrappedAutocomplete,
} from 'components';
import {
  getStartupData,
  handleSubmitFounders,
  getFounderDetails,
} from 'redux-modules/enquiryForm/Actions';
import { VDStepProps } from 'common/types';
import {
  businessOpsCountryList,
  getBusinessOpsCountryObject,
} from 'common/utils/helpers';
import { successImage } from 'assets';

import { CompanyDetailsSchema } from '../validation';
import styles from '../styles';

const CompanyDetails = ({
  //stepName,
  id,
  previousPage,
  setEnquiryId,
}: VDStepProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { handleSubmit, control, reset, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CompanyDetailsSchema),
  });

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'founderDetails',
  });
  if (!fields.length) {
    prepend({ founderName: '', founderEmail: '' });
  }
  const {
    startupDetails,
    // successMessage,
    // founderDetails,
    defaultFounderDetails,
    potentialMarketTarget,
    productDescription,
    productProgress,
    startupLegalInformation,
    startupInvestmentInformation,
  } = useSelector(({ EnquiryForm }: RootState) => EnquiryForm);

  const {
    // industryType,
    // industryClassification,
    businessOpsLocation,
    startupFacebookUrl,
    startupLinkedInUrl,
    startupTwitterUrl,
    startupWebLink,
  } = startupDetails;

  // const { industries } = useSelector(({ global }: RootState) => global);

  const { startUpId } = useSelector(({ Auth }: RootState) => Auth.user);

  const { errorMessage, isLoading } = useSelector(
    ({ EnquiryForm }: RootState) => EnquiryForm.isStartUp,
  );

  const [modal, setModal] = React.useState<boolean>(false);

  const onSubmit = (formValues: any) => {
    const callback = (id: string) => {
      setEnquiryId(id);
      //previousPage();
    };
    const {
      // founderEmail,
      // founderName,
      founderDetails,
      // industryType,
      // industryClassification,
      potentialMarketTarget,
      productDescription,
      productProgress,
      businessOpsLocation,
      startupFacebookUrl,
      startupLinkedInUrl,
      startupTwitterUrl,
      startupWebLink,
      startupLegalInformation,
      startupInvestmentInformation,
    } = formValues;

    const {
      startupLegalState,
      enquiryTermsAccepted,
      startupId,
    } = startupDetails;

    const obj = {
      currentPage: 'ADDITIONAL_INFORMATION_FORM',
      productDescription: productDescription,
      productProgress: productProgress,
      potentialMarketTarget: potentialMarketTarget,
      startupInvestmentInformation: startupInvestmentInformation,
      startupLegalInformation: startupLegalInformation,
      startupDetails: {
        startupId: startupId,
        startupWebLink,
        startupLegalState: startupLegalState,
        enquiryTermsAccepted: enquiryTermsAccepted,
        businessOpsLocation: businessOpsLocation?.countryCode,
        // industryType: industryType?.value,
        // industryClassification: industryClassification?.value,
        startupTwitterUrl,
        startupFacebookUrl,
        startupLinkedInUrl,
      },
      founderDetails,
      id,
      callback,
    };
    dispatch(handleSubmitFounders(obj));
    setModal(true);
  };

  useEffect(() => {
    if (startUpId) {
      dispatch(getStartupData(startUpId));
      dispatch(getFounderDetails(startUpId));
    }
  }, []);

  useEffect(() => {
    reset({
      // founderDetails: defaultFounderDetails,
      startupFacebookUrl: startupFacebookUrl ? startupFacebookUrl : '',
      startupLinkedInUrl: startupLinkedInUrl ? startupLinkedInUrl : '',
      startupTwitterUrl: startupTwitterUrl ? startupTwitterUrl : '',
      startupWebLink: startupWebLink ? startupWebLink : '',
      potentialMarketTarget: potentialMarketTarget ? potentialMarketTarget : '',
      productDescription: productDescription ? productDescription : '',
      productProgress: productProgress ? productProgress : '',
      startupLegalInformation: startupLegalInformation
        ? startupLegalInformation
        : '',
      startupInvestmentInformation: startupInvestmentInformation
        ? startupInvestmentInformation
        : '',
    });
    setValue(
      'businessOpsLocation',
      getBusinessOpsCountryObject(businessOpsLocation),
    );
    if (defaultFounderDetails && defaultFounderDetails.length) {
      setValue('founderDetails', defaultFounderDetails);
      defaultFounderDetails.map((item, index) => {
        setValue(`founderDetails[${index}].founderName`, item.founderName);
        setValue(`founderDetails[${index}].founderEmail`, item.founderEmail);
      });
    }
  }, [startupDetails]);

  useEffect(() => {
    if (defaultFounderDetails && defaultFounderDetails.length) {
      // reset({ founderDetails: defaultFounderDetails });
      setValue('founderDetails', defaultFounderDetails);
      defaultFounderDetails.map((item, index) => {
        setValue(`founderDetails[${index}].founderName`, item.founderName);
        setValue(`founderDetails[${index}].founderEmail`, item.founderEmail);
      });
    }
  }, [defaultFounderDetails]);

  return (
    <div>
      {isLoading && <Loader />}
      <KeyboardBackspaceIcon
        className={classes.backArrow}
        onClick={() => previousPage()}
      />
      <Typography variant="h2" className={classes.detailsText}>
        Apply for Venture Debt
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.errorText}>
        {errorMessage}
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="companyDetailsForm"
        className={classes.validateForm}
      >
        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Founder 1
            </Typography>
            <Controller
              name="founderName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="Name"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Name is required' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
            <Controller
              name="founderEmail"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="Name"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Company website is required' }}
            />
          </Grid>
          
        </Grid> */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.companyLtext}>
              Founder Details
            </Typography>
          </Grid>
        </Grid>
        {fields.map((item, index) => (
          <div key={index}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Founder {index + 1}{' '}
                  {fields.length > 1 && index !== 0 && (
                    <Typography
                      variant="caption"
                      className={classes.removeText}
                      onClick={() => remove(index)}
                    >
                      (Remove)
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                <Controller
                  name={`founderDetails[${index}].founderName` as const}
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Name"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled={!index}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  // rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className={classes.inputHeadRight}
              >
                <Controller
                  name={`founderDetails[${index}].founderEmail` as const}
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Email Address"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled={!index}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  // rules={{ required: 'Email is required' }}
                />
              </Grid>
            </Grid>
          </div>
        ))}
        <Button
          className={classes.addFound}
          variant="outlined"
          name="Add Founder"
          onClick={() =>
            append({
              founderName: '',
              founderEmail: '',
            })
          }
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.companyLtext}>
              Company Introduction
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Your company website
            </Typography>
            <Controller
              name="startupWebLink"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="Link"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              // rules={{ required: 'Company website is required' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Country of your business operation
            </Typography>
            <Controller
              name="businessOpsLocation"
              control={control}
              // defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedAutocomplete
                    id="field-business-op-location"
                    placeholder="Choose Country"
                    disableClearable
                    options={businessOpsCountryList}
                    value={
                      value || getBusinessOpsCountryObject(businessOpsLocation)
                    }
                    getOptionLabel={(option: any) => option.country || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.countryCode === value.countryCode
                    }
                    onChange={(_: any, value: any) => {
                      onChange(value);
                    }}
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={error?.message ? true : false}
                    disabled={!isEmpty(businessOpsLocation)}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              // rules={{ required: 'Country is required' }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography variant="caption" className={classes.companySocialText}>
              Social Media URL’s
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputHeadPart}>
            <Controller
              name="startupFacebookUrl"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="Enter Facebook"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <InputAdornment position="start">
                          <FacebookIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Facebook URL is required' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputHeadPart}>
            <Controller
              name="startupTwitterUrl"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="Enter Twitter"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <InputAdornment position="start">
                          <TwitterIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Twitter URL is required' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            className={classes.inputHeadLastPart}
          >
            <Controller
              name="startupLinkedInUrl"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="LinkedIn URL"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedInIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'LinkedIn URL is required' }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.cmpnyLtext}>
              Business details
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Typography variant="caption" className={classes.companyLabelText}>
            Describe your Company Product/Services.
          </Typography>
          <Controller
            name="productDescription"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Type Something"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid>
          <Typography variant="caption" className={classes.companyLabelText}>
            Describe the status of the business.
          </Typography>
          <Controller
            name="productProgress"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Type Something"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid>
          <Typography variant="caption" className={classes.companyLabelText}>
            Describe your potential market.
          </Typography>
          <Controller
            name="potentialMarketTarget"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Type Something"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid>
          <Typography variant="caption" className={classes.companyLabelText}>
            Has the company received any investment to date(apart from Y
            Combinator commitment)? If so please provide relevant investor and
            amount details.
          </Typography>
          <Controller
            name="startupInvestmentInformation"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Type Something"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid>
          <Typography variant="caption" className={classes.companyLabelText}>
            Is there anything else we should know about your company(legal or
            otherwise).
          </Typography>
          <Controller
            name="startupLegalInformation"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Type Something"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid>
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              className={classes.saveMoreBtn}
              name="Save"
              //onClick={() => setModal(true)}
            />
            <Button
              //type="submit"
              className={classes.backMoreBtn}
              name="Back"
              onClick={() => previousPage()}
            />
          </Box>
        </Grid>
      </form>
      <Dialog
        open={modal}
        maxWidth={'sm'}
        title="Saved Successfully"
        buttonText="Continue"
        handleClose={() => setModal(false)}
      >
        <Box>
          <SuccessPage img={successImage} className={classes.dashSuccessImg}>
            <Typography variant="h4" gutterBottom className={classes.pointText}>
              <Button
                className={classes.saveMoreBtn}
                name="OK"
                onClick={() => previousPage()}
              />
            </Typography>
          </SuccessPage>
        </Box>
      </Dialog>
    </div>
  );
};

export default CompanyDetails;
