import React, { Fragment } from 'react';
import {
  Typography,
  Grid,
  Chip,
  Switch,
  TextField,
  Checkbox,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { get, isEmpty, isArray, differenceBy } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

import {
  SidePane,
  Box,
  WrappedTextInput,
  Accordion,
  WrappedSelect,
  WrappedAutocomplete,
  MultiValueTextField,
  WrappedPriceInput,
  FileUpload,
  Loader,
  WrappedLoadingOverlay,
} from 'components';
import {
  industryTypeList,
  businessOpsCountryList,
  fundingRoundStageList,
  vcDealsTagsList,
  countryOfOperationList,
} from 'common/utils/optionList';
import {
  VC_ADMIN_DOCUMENT_NAME,
  VC_ADMIN_DOCUMENT_RELATIVE_NAME,
} from 'common/utils/constants';
import {
  AIArt,
  PdfOutlinedIcon,
  DownloadOutlinedIcon,
  Delete,
  OutlinedCancelIcon,
} from 'assets';
import {
  createOrUpdateVcAdminDeal,
  getVcAdminDeal,
  getSignedUrlForVcAdminDocument,
  addOrUpdateVcAdminDealsMultipleDocuments,
  getVcAdminDocuments,
} from 'services';
import {
  errorMessageHandler,
  getBusinessOpsCountryObject,
  getFilteredDocuments,
  getCountryOfOperationsObject,
} from 'common/utils/helpers';

import { HtmlTooltip } from '../../../components/Wrappers/Tooltip';
import styles from './styles';
import { AddProspectiveDealSchema } from '../validation';
import {
  DeleteDealDocument,
  AddOtherDocument,
  SaveChangesAlert,
} from '../DialogBox';

const ProspectiveDealSidePane = ({
  open,
  closeDialog,
  selectedDeal,
  getAllVcDeals,
  currentYCBatch,
}: any) => {
  const classes = styles();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AddProspectiveDealSchema),
  });

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'vcAdminDealFounders',
  });
  if (!fields.length) {
    prepend({ name: '', linkedinUrl: '' });
  }

  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string[]>(['Overview']);
  const [emails, setEmails] = React.useState<any>({});
  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
  const [isAddDocument, setIsAddDocument] = React.useState<boolean>(false);
  const [isDeleteDocument, setIsDeleteDocument] = React.useState<boolean>(
    false,
  );
  const [selectedDocument, setSelectedDocument] = React.useState<any>({});
  const [isDealLoading, setIsDealLoading] = React.useState<boolean>(false);
  const [isDocLoading, setIsDocLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<any>({
    propective: false,
    upload: false,
  });
  const [selectedDealData, setSelectedDealData] = React.useState<any>([]);
  const [saveChangesModal, setSaveChangesModal] = React.useState<boolean>(
    false,
  );
  const [
    fileReqInvestmentReportDoc,
    setFileReqInvestmentReportDoc,
  ] = React.useState<any>({});

  const uploadFile = async (id: string) => {
    setErrorMessage('');
    if (!Object.prototype.hasOwnProperty.call(selectedFiles, 'id')) {
      if (!isEmpty(selectedFiles)) {
        setIsLoading((prevState: any) => ({
          ...prevState,
          upload: true,
        }));
        try {
          const objArray: any = [];
          await Promise.all(
            getFilteredDocuments(selectedFiles).map(async (item: any) => {
              const url = await getSignedUrlForVcAdminDocument({
                filename: item.filename,
                vcDealId: id,
                documentName: item.documentName,
                type: VC_ADMIN_DOCUMENT_RELATIVE_NAME.DEAL,
              });
              // eslint-disable-next-line no-undef
              await fetch(get(url, 'data.uploadUrl'), {
                method: 'PUT',
                body: item.file,
              });
              objArray.push({
                documentName: item.documentName,
                vcDealId: id,
                filename: item.filename,
                type: VC_ADMIN_DOCUMENT_RELATIVE_NAME.DEAL,
                documentUrl: get(url, 'data.accessUrl'),
                id: get(item, 'id', null),
              });
            }),
          );
          if (!isEmpty(objArray)) {
            await addOrUpdateVcAdminDealsMultipleDocuments(objArray);
          }
          setIsLoading((prevState: any) => ({
            ...prevState,
            upload: false,
          }));
          getAllVcDeals();
          handleClose();
        } catch (err) {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        } finally {
          setIsLoading((prevState: any) => ({
            ...prevState,
            upload: false,
          }));
        }
      } else {
        setErrorMessage('Please upload file');
      }
    }
  };

  const uploadInvestmentReportDocx = async (dealRes: any, id: string) => {
    if (!isEmpty(fileReqInvestmentReportDoc)) {
      const url = await getSignedUrlForVcAdminDocument({
        filename: fileReqInvestmentReportDoc.filename,
        vcDealId: id,
        documentName: fileReqInvestmentReportDoc.documentName,
        type: VC_ADMIN_DOCUMENT_RELATIVE_NAME.DEAL,
      });
      // eslint-disable-next-line no-undef
      await fetch(get(url, 'data.uploadUrl'), {
        method: 'PUT',
        body: fileReqInvestmentReportDoc.file,
      });
      await createOrUpdateVcAdminDeal({
        ...dealRes,
        aiAnalyst: {
          ...(dealRes.aiAnalyst || {}),
          INVESTMENT_NOTE_DOCX: get(url, 'data.accessUrl'),
        },
      });
    }
  };

  const onSubmit = () => {
    setErrorMessage('');
    const data: any = { ...getValues() };
    if (!isEmpty(get(data, 'email')) && isEmpty(emails)) {
      setErrorMessage('Please Add one Primary Email');
      return;
    }
    const foundersDetails: any[] = [];
    get(data, 'vcAdminDealFounders').map((item: any) => {
      if (!isEmpty(get(item, 'name'))) {
        return foundersDetails.push({
          id: get(item, 'id') ? get(item, 'id') : undefined,
          name: get(item, 'name'),
          linkedinUrl: get(item, 'linkedinUrl'),
        });
      }
    });
    const deletedFounder = differenceBy(
      get(selectedDealData, 'vcAdminDealFounders'),
      foundersDetails,
      'id',
    );
    if (!isEmpty(deletedFounder)) {
      deletedFounder.forEach((item: any) => {
        return foundersDetails.push({
          id: get(item, 'id') ? get(item, 'id') : undefined,
          name: get(item, 'name'),
          linkedinUrl: get(item, 'linkedinUrl'),
          isDeleted: true,
        });
      });
    }
    const obj = {
      ...data,
      id: !isEmpty(selectedDealData) ? selectedDeal : undefined,
      dealStatus: 'PROSPECTIVE',
      dealStage: 'LEAD',
      companyBlurp: get(data, 'companyBlurp')
        ? get(data, 'companyBlurp')
        : null,
      geography: get(data, 'geography.countryCode'),
      countryOfOperation: get(data, 'countryOfOperation')
        ? get(data, 'countryOfOperation')
            .map((i: any) => i.countryCode)
            .toString()
        : undefined,
      email: !isEmpty(get(data, 'email'))
        ? get(data, 'email', []).find((item: any) => emails[item])
        : '',
      otherEmails: get(data, 'email')
        ? get(data, 'email', [])
            .filter((item: any) => !emails[item] || item === undefined)
            .toString()
        : null,
      tags: get(data, 'tags') ? get(data, 'tags').toString() : undefined,
      other: get(data, 'other') ? get(data, 'other') : null,
      remark: get(data, 'remark') ? get(data, 'remark') : null,
      proposedAmount: get(data, 'proposedAmount')
        ? get(data, 'proposedAmount')
        : null,
      companyPitchdeckLink: get(data, 'companyPitchdeckLink')
        ? get(data, 'companyPitchdeckLink')
        : null,
      vcAdminDealFounders: !isEmpty(foundersDetails)
        ? foundersDetails
        : undefined,
      ycBatch: !isEmpty(get(data, 'ycBatch'))
        ? get(data, 'ycBatch')
        : currentYCBatch,
    };
    setErrorMessage('');
    setIsLoading((prevState: any) => ({
      ...prevState,
      prospective: true,
    }));

    createOrUpdateVcAdminDeal(obj)
      .then((res: any) => {
        uploadInvestmentReportDocx(res.data, res.data.id);
        if (!isEmpty(selectedFiles)) {
          uploadFile(res.data.id);
        } else {
          getAllVcDeals();
          handleClose();
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() =>
        setIsLoading((prevState: any) => ({
          ...prevState,
          prospective: false,
        })),
      );
  };

  const handleClose = () => {
    closeDialog();
    setSelectedFiles([]);
    setFileReqInvestmentReportDoc({});
    setSelectedDealData({});
    resetFieldValues();
    setExpanded([]);
  };

  const resetFieldValues = () => {
    reset({
      companyName: '',
      dateOfEmail: null,
      email: null,
      companyUrl: '',
      sector: '',
      geography: '',
      lastFundingRound: '',
      proposedAmount: null,
      proposedValuation: null,
      estimatedCloseDate: null,
      dealEstimatedCloseDate: null,
      companyPitchdeckLink: null,
      otherEmails: null,
      remark: null,
      tags: null,
    });
    setErrorMessage('');
    setEmails({});
  };

  const handleChange = (panel: string) => () => {
    if (expanded.includes(panel)) {
      const newArray = expanded.filter((i) => i !== panel);
      setExpanded(newArray);
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<any>, option: any) => {
    const dataEmails: any = {};
    Object.keys(emails).forEach((key) => {
      dataEmails[key] = false;
    });
    dataEmails[option] = true;
    setEmails(dataEmails);
  };

  const getUploadedDocuments = async () => {
    if (!isEmpty(selectedDeal)) {
      setIsDocLoading(true);
      setErrorMessage('');
      const obj = {
        type: VC_ADMIN_DOCUMENT_RELATIVE_NAME.DEAL,
        vcDealId: selectedDeal,
      };
      await getVcAdminDocuments(obj)
        .then((res: any) => {
          if (!isEmpty(res.data) && isArray(res.data)) {
            const data: any = [];
            res.data.map((item: any) => {
              if (
                item.documentName ===
                  VC_ADMIN_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC ||
                VC_ADMIN_DOCUMENT_NAME.OTHER_DOC
              ) {
                data.push(item);
              }
            });
            setSelectedFiles(data);
          }
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsDocLoading(false);
        });
    }
  };

  const isFieldDataChanged = (): boolean => {
    if (isEmpty(selectedDeal)) {
      const data = { ...getValues() };
      const isEmptyField = Object.keys(data).every((x: any) =>
        x === 'vcAdminDealFounders'
          ? get(data, 'vcAdminDealFounders').every(
              (i: any) => !i.linkedinUrl && !i.name,
            )
          : isEmpty(get(data, x)),
      );
      return isEmptyField ? false : true;
    } else if (
      isDirty ||
      selectedFiles.some((item: any) => isEmpty(get(item, 'documentStatus')))
    )
      return true;
    else return false;
  };

  const getPrimaryEmail = () => {
    const dataEmails: any = {};
    const allEmails = [
      get(selectedDealData, 'email'),
      get(selectedDealData, 'otherEmails'),
    ].filter((x) => x);
    Object.keys(allEmails).forEach((key) => {
      dataEmails[key] = false;
    });
    dataEmails[get(selectedDealData, 'email')] = true;
    setEmails(dataEmails);
  };

  React.useEffect(() => {
    if (!isEmpty(selectedDeal)) {
      setIsDealLoading(true);
      getVcAdminDeal(selectedDeal)
        .then((res: any) => {
          setSelectedDealData(res.data);
          setIsDealLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsDealLoading(false);
        });
      getUploadedDocuments();
    }
  }, [selectedDeal]);

  React.useEffect(() => {
    if (!isEmpty(selectedDealData)) {
      setValue('companyName', get(selectedDealData, 'companyName'));
      setValue(
        'dateOfEmail',
        !isEmpty(get(selectedDealData, 'dateOfEmail'))
          ? moment(get(selectedDealData, 'dateOfEmail')).format('YYYY-MM-DD')
          : get(selectedDealData, 'dateOfEmail'),
      );
      if (!isEmpty(get(selectedDealData, 'otherEmails'))) {
        setValue(
          'email',
          [
            get(selectedDealData, 'email'),
            ...get(selectedDealData, 'otherEmails').split(','),
          ].filter((x) => x),
        );
      } else if (!isEmpty(get(selectedDealData, 'email'))) {
        setValue('email', [get(selectedDealData, 'email')]);
      }
      setValue('companyUrl', get(selectedDealData, 'companyUrl'));
      setValue('sector', get(selectedDealData, 'sector'));
      setValue(
        'geography',
        getBusinessOpsCountryObject(get(selectedDealData, 'geography')),
      );
      setValue(
        'countryOfOperation',
        get(selectedDealData, 'countryOfOperation')
          ? get(selectedDealData, 'countryOfOperation')
              ?.split(',')
              .map((i: any) => getCountryOfOperationsObject(i))
          : [],
      );
      setValue('lastFundingRound', get(selectedDealData, 'lastFundingRound'));
      setValue(
        'proposedAmount',
        get(selectedDealData, 'proposedAmount') !== '0.00'
          ? get(selectedDealData, 'proposedAmount')
          : null,
      );
      setValue('proposedValuation', get(selectedDealData, 'proposedValuation'));
      setValue('ycBatch', get(selectedDealData, 'ycBatch'));
      setValue(
        'dealEstimatedCloseDate',
        get(selectedDealData, 'estimatedCloseDate'),
      );
      setValue(
        'estimatedCloseDate',
        !isEmpty(get(selectedDealData, 'estimatedCloseDate'))
          ? moment(get(selectedDealData, 'estimatedCloseDate')).format(
              'YYYY-MM-DD',
            )
          : get(selectedDealData, 'estimatedCloseDate'),
      );
      setValue('other', get(selectedDealData, 'other'));
      setValue(
        'companyPitchdeckLink',
        get(selectedDealData, 'companyPitchdeckLink'),
      );
      setValue(
        'tags',
        get(selectedDealData, 'tags')
          ? get(selectedDealData, 'tags').split(',')
          : [],
      );
      setValue('remark', get(selectedDealData, 'remark'));
      setValue('companyOneLiner', get(selectedDealData, 'companyOneLiner'));
      setValue('companyBlurp', get(selectedDealData, 'companyBlurp'));
      setIsEditMode(get(selectedDealData, 'companyName') ? true : false);
      getPrimaryEmail();
    }
  }, [selectedDealData]);

  const adminDealFoundersDetails = get(selectedDealData, 'vcAdminDealFounders');
  React.useEffect(() => {
    if (adminDealFoundersDetails && adminDealFoundersDetails.length) {
      setValue('vcAdminDealFounders', adminDealFoundersDetails);
      adminDealFoundersDetails.map((item: any, index: any) => {
        setValue(`vcAdminDealFounders[${index}].name`, get(item, 'name'));
        setValue(
          `vcAdminDealFounders[${index}].linkedinUrl`,
          get(item, 'linkedinUrl'),
        );
      });
    }
  }, [adminDealFoundersDetails]);

  return (
    <>
      <SidePane
        anchor={'right'}
        open={open}
        onSidePaneClose={() => {
          if (isFieldDataChanged()) {
            setSaveChangesModal(true);
          } else handleClose();
        }}
      >
        <WrappedLoadingOverlay
          active={
            isDealLoading ||
            isDocLoading ||
            get(isLoading, 'prospective') ||
            get(isLoading, 'upload')
          }
          spinner={<Loader />}
          fadeSpeed={100}
          styles={{
            overlay: (base) => ({
              ...base,
              opacity: 0.5,
            }),
            wrapper: {},
          }}
        >
          <Box className={classes.sidePaneContainer}>
            {errorMessage && (
              <Typography className={classes.errorMessageSidePen}>
                {errorMessage}
              </Typography>
            )}
            <form noValidate>
              <Box className={classes.sidePaneHeaderContainer}>
                <Box className={classes.rightBox}>
                  <Box className={classes.rightHeadSubContainer}>
                    <Box className={classes.companyNameEditBox}>
                      {!isEditMode && (
                        <>
                          <Typography className={classes.companyNameText}>
                            {watch('companyName') || 'Company Name'}
                            <span className={classes.errorRequiredText}>*</span>
                          </Typography>
                          <EditOutlinedIcon
                            className={classes.editIcon}
                            onClick={() => {
                              setIsEditMode(isEditMode ? false : true);
                            }}
                          />
                        </>
                      )}
                    </Box>
                    <Box className={classes.companyNameEditBox}>
                      <Controller
                        name={'companyName'}
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <Box className={classes.textFieldBox}>
                              <WrappedTextInput
                                type="text"
                                placeholder={'Enter Company Name'}
                                className={classes.textValInput}
                                InputProps={
                                  !isEditMode
                                    ? {
                                        style: {
                                          visibility: 'hidden',
                                          height: 0,
                                        },
                                        classes: { root: classes.inputRoot },
                                      }
                                    : { classes: { root: classes.inputRoot } }
                                }
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused,
                                  },
                                }}
                                value={value}
                                onChange={(e: any) =>
                                  onChange(e.target.value.trimStart())
                                }
                                onBlur={() => {
                                  setIsEditMode(isEditMode ? false : true);
                                }}
                                error={!!error?.message}
                              />
                              {error?.message && (
                                <span className={classes.errorCompanyNameText}>
                                  {error?.message}
                                </span>
                              )}
                            </Box>
                          </>
                        )}
                      />
                      {isEditMode && (
                        <EditOutlinedIcon
                          className={classes.editIcon}
                          onClick={() => {
                            setIsEditMode(isEditMode ? false : true);
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.ycBatchBox}>
                    {/* <Controller
                      name={'ycBatch'}
                      control={control}
                      defaultValue={currentYCBatch}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <WrappedTextInput
                            type="text"
                            placeholder={'Enter YC Batch'}
                            className={classes.textValInput}
                            InputProps={{
                              classes: { root: classes.inputRoot },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused,
                              },
                            }}
                            value={value}
                            onChange={(e: any) =>
                              onChange(e.target.value.trimStart())
                            }
                            error={!!error?.message}
                          />
                          {error?.message && (
                            <span className={classes.errorText}>
                              {error?.message}
                            </span>
                          )}
                        </>
                      )}
                    /> */}
                    <Typography className={classes.currentYCBatchText}>
                      {currentYCBatch}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.leftHeadSubContainer}>
                  <Box
                    className={classes.saveBox}
                    onClick={handleSubmit(onSubmit)}
                  >
                    <SaveOutlinedIcon className={classes.saveIcon} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Controller
                  name={'companyOneLiner'}
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        placeholder={'Write here company description'}
                        className={classes.textValInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                          endAdornment: `${
                            watch('companyOneLiner')?.length
                              ? watch('companyOneLiner')?.length
                              : '0'
                          }/50`,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={(e: any) =>
                          onChange(e.target.value.trimStart())
                        }
                        error={!!error?.message}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Box>
              <Box className={classes.accordMainBox}>
                <Accordion
                  subheading="Overview"
                  expanded={expanded.includes('Overview')}
                  handleChange={(e: string) => handleChange(e)}
                  index="Overview"
                >
                  <Grid container spacing={3} className={classes.inputFundBox}>
                    {fields.map((item, index) => (
                      <Fragment key={`${item.id}`}>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body1"
                            gutterBottom
                            className={classes.labelText}
                          >
                            Founder {index + 1}
                          </Typography>
                          <Controller
                            name={`vcAdminDealFounders[${index}].name`}
                            control={control}
                            defaultValue={get(item, 'name')}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <>
                                <WrappedTextInput
                                  type="text"
                                  label=""
                                  placeholder={`Enter Founder ${
                                    index + 1
                                  } name`}
                                  className={classes.textValInput}
                                  InputProps={{
                                    classes: { root: classes.inputRoot },
                                    endAdornment: (
                                      <>
                                        {fields.length > 1 &&
                                          index !== 0 &&
                                          index < 5 && (
                                            <img
                                              src={OutlinedCancelIcon}
                                              alt={OutlinedCancelIcon}
                                              onClick={() => {
                                                remove(index);
                                              }}
                                              className={classes.removeText}
                                            />
                                          )}
                                        {index < 4 && (
                                          <AddBoxOutlinedIcon
                                            className={
                                              fields.length < 5
                                                ? classes.addFounderBtn
                                                : classes.disableClick
                                            }
                                            onClick={() =>
                                              append({
                                                name: '',
                                                linkedinUrl: '',
                                              })
                                            }
                                          />
                                        )}
                                      </>
                                    ),
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused,
                                    },
                                  }}
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message ? true : false}
                                />
                                {error?.message && (
                                  <span className={classes.errorText}>
                                    {error?.message}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body1"
                            gutterBottom
                            className={classes.labelText}
                          >
                            Founder{index !== 0 ? ` ${index + 1}` : `'s`}{' '}
                            Linkedin Url
                          </Typography>
                          <Controller
                            name={`vcAdminDealFounders[${index}].linkedinUrl`}
                            control={control}
                            defaultValue={get(item, 'linkedinUrl')}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <>
                                <WrappedTextInput
                                  type="text"
                                  label=""
                                  placeholder={`Enter Founder${
                                    index !== 0 ? ` ${index + 1}` : `'s`
                                  } Linkedin Url`}
                                  className={classes.textValInput}
                                  InputProps={{
                                    classes: { root: classes.inputRoot },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused,
                                    },
                                  }}
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message ? true : false}
                                />
                                {error?.message && (
                                  <span
                                    className={classes.errorPricesFieldText}
                                  >
                                    {error?.message}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </Grid>
                      </Fragment>
                    ))}

                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Email ID
                      </Typography>
                      <MultiValueTextField
                        className={classes.emailChipStyle}
                        placeholder="Input Email ID & hit Enter"
                        control={control}
                        name="email"
                        onBlur={() => {
                          if (watch('email').length === 1) {
                            const dataEmails: any = {};
                            dataEmails[watch('email').toString()] = true;
                            setEmails(dataEmails);
                          }
                        }}
                        freeSolo={true}
                        renderTags={(value: string[], getTagProps: any) => {
                          return (
                            value
                              // .filter((item: any) => emailRegex.test(item))
                              .map((option: string, index: number) => {
                                return (
                                  <>
                                    <HtmlTooltip
                                      className={classes.editToolTip}
                                      title={
                                        !emails[option]
                                          ? 'Set Primary Email'
                                          : ''
                                      }
                                      placement="top"
                                    >
                                      <Chip
                                        {...getTagProps({ index })}
                                        key={index}
                                        icon={
                                          isEmpty(get(errors, 'email')) ? (
                                            <Switch
                                              onChange={(e: any) =>
                                                handleSwitchChange(e, option)
                                              }
                                              checked={
                                                emails[option]
                                                  ? emails[option]
                                                  : false
                                              }
                                              size="small"
                                              value={option}
                                            />
                                          ) : undefined
                                        }
                                        deleteIcon={<CloseIcon />}
                                        size="small"
                                        label={option}
                                      />
                                    </HtmlTooltip>
                                  </>
                                );
                              })
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Company Url
                      </Typography>
                      <Controller
                        name="companyUrl"
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedTextInput
                              type="text"
                              placeholder="Enter Company Url"
                              className={classes.textValInput}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              value={value}
                              onChange={(e: any) =>
                                onChange(e.target.value.trimStart())
                              }
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <span className={classes.errorText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Country of Operation
                      </Typography>
                      <Controller
                        name="countryOfOperation"
                        control={control}
                        defaultValue={[]}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <Autocomplete
                              multiple
                              className={classes.countryTagStyle}
                              id="countryOfOperation"
                              options={countryOfOperationList}
                              disableCloseOnSelect
                              onChange={(_, selectedOptions: any) => {
                                return onChange(selectedOptions);
                              }}
                              value={
                                value ||
                                get(selectedDealData, 'countryOfOperation')
                                  ?.split(',')
                                  .map((i: any) =>
                                    getCountryOfOperationsObject(i),
                                  )
                              }
                              getOptionLabel={(option) => option.country}
                              renderOption={(option, { selected }) => {
                                return (
                                  <li>
                                    <Checkbox
                                      checked={
                                        selected ||
                                        !isEmpty(
                                          value.find(
                                            (i: any) =>
                                              i.countryCode ===
                                              option.countryCode,
                                          ),
                                        )
                                      }
                                    />
                                    {option.country}
                                  </li>
                                );
                              }}
                              renderTags={(value: any[], getTagProps: any) => {
                                return value.map(
                                  (option: any, index: number) => (
                                    <Chip
                                      key={`country_${index}`}
                                      size="small"
                                      label={option.country}
                                      deleteIcon={<CloseIcon />}
                                      {...getTagProps({ index })}
                                    />
                                  ),
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  placeholder="Select country of Operation"
                                  className={classes.textValInput}
                                  InputProps={{
                                    ...params.InputProps,
                                    classes: { root: classes.inputRoot },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      focused: classes.labelFocused,
                                    },
                                  }}
                                />
                              )}
                            />
                            {error?.message && (
                              <span className={classes.errorText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Sector
                      </Typography>
                      <Controller
                        name="sector"
                        control={control}
                        defaultValue={''}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedSelect
                              value={value}
                              onChange={onChange}
                              label=""
                              placeholder="Select Sector"
                              className={classes.textValInputSelectField}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              dropdowns={industryTypeList}
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <span className={classes.errorText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Tags{' '}
                        <span className={classes.tagSmallDesc}>
                          (Add a maximum of 3 tags.)
                        </span>
                      </Typography>
                      <MultiValueTextField
                        control={control}
                        name="tags"
                        className={classes.tagStyle}
                        placeholder="Input Tags & hit Enter"
                        limitTags={3}
                        options={vcDealsTagsList}
                        freeSolo={false}
                        renderTags={(value: string[], getTagProps: any) => {
                          return value.map((option: string, index: number) => (
                            <Chip
                              key={index}
                              size="small"
                              label={option}
                              deleteIcon={<CloseIcon />}
                              {...getTagProps({ index })}
                            />
                          ));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Other
                      </Typography>
                      <Controller
                        name="other"
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedTextInput
                              type="text"
                              placeholder="Other"
                              className={classes.textValInput}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              value={value}
                              onChange={(e: any) =>
                                onChange(e.target.value.trimStart())
                              }
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <span className={classes.errorText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Accordion>
                <Accordion
                  subheading="Investment Details"
                  expanded={expanded.includes('Investment Details')}
                  handleChange={(e: string) => handleChange(e)}
                  index="Investment Details"
                >
                  <Grid container spacing={3} className={classes.inputFundBox}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Fundraise Round
                      </Typography>
                      <Controller
                        name="lastFundingRound"
                        control={control}
                        defaultValue={''}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedSelect
                              value={value}
                              onChange={onChange}
                              label=""
                              placeholder="Select Fundraise Round"
                              className={classes.textValInputSelectField}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              dropdowns={fundingRoundStageList}
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <span className={classes.errorText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Country of Incorporation
                      </Typography>
                      <Controller
                        name="geography"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedAutocomplete
                              id="geography-select"
                              disableClearable
                              value={
                                value ||
                                getBusinessOpsCountryObject(
                                  get(selectedDealData, 'geography'),
                                )
                              }
                              onChange={(_: any, value: any) => {
                                onChange(value);
                              }}
                              placeholder="Select Incorporation Country"
                              className={classes.autoTextValInput}
                              InputProps={{
                                classes: {
                                  root: classes.autoCompleteInputRoot,
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              error={!!error?.message}
                              options={businessOpsCountryList}
                              getOptionLabel={(option: any) =>
                                option.country || ''
                              }
                              getOptionSelected={(option: any, value: any) =>
                                option.countryCode === value?.countryCode
                              }
                            />
                            {error?.message && (
                              <span className={classes.errorText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Amount Proposed
                      </Typography>
                      <Controller
                        name="proposedAmount"
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedPriceInput
                              label=""
                              className={classes.textValInput}
                              currencySymbol={'$'}
                              placeholder="Enter Amount Proposed"
                              textAlign="left"
                              decimalPlaces={2}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              value={value}
                              //onChange={onChange}
                              onChange={(_: any, val: any) => onChange(val)}
                              error={error?.message ? true : false}
                            />
                            {error?.message && (
                              <span className={classes.errorPricesFieldText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Close Date
                      </Typography>
                      <Controller
                        name="estimatedCloseDate"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <TextField
                              id="date-local"
                              label=""
                              type="date"
                              value={value}
                              className={classes.textValInput}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                                // inputProps: {
                                //   max: moment().format('YYYY-MM-DD'),
                                // },
                              }}
                              placeholder="Enter Close date"
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                                shrink: true,
                              }}
                              error={error?.message ? true : false}
                              onChange={onChange}
                            />
                            {error?.message && (
                              <span className={classes.errorDateFieldText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Proposed Valuation
                      </Typography>
                      <Controller
                        name="proposedValuation"
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedPriceInput
                              label=""
                              className={classes.textValInput}
                              currencySymbol={'$'}
                              placeholder={'Enter Amount Valuation'}
                              textAlign="left"
                              decimalPlaces={2}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              value={value}
                              //onChange={onChange}
                              onChange={(_: any, val: any) => onChange(val)}
                              error={error?.message ? true : false}
                            />
                            {error?.message && (
                              <span className={classes.errorPricesFieldText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Accordion>
                <Accordion
                  subheading="Documents"
                  expanded={expanded.includes('Documents')}
                  handleChange={(e: string) => handleChange(e)}
                  index="Documents"
                >
                  <Grid container spacing={3} className={classes.inputFundBox}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Pitch Deck
                      </Typography>
                      <Controller
                        name="companyPitchdeckLink"
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedTextInput
                              type="text"
                              placeholder="Enter Link/Attach Pdf"
                              className={classes.textValInput}
                              disabled={
                                !isEmpty(
                                  selectedFiles.find(
                                    (item: any) =>
                                      get(item, 'documentName') ===
                                      VC_ADMIN_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                                  ),
                                )
                              }
                              InputProps={{
                                classes: { root: classes.inputRoot },
                                endAdornment: (
                                  <FileUpload
                                    fileExtensions={['pdf', 'pptx', 'ppt']}
                                    requestObject={{
                                      documentName:
                                        VC_ADMIN_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                                    }}
                                    fileSize={20971520} // bytes in decimal
                                    showSelectedFilePreview={false}
                                    uploadOnSelect={false}
                                    callGetSignedUrl={false}
                                    onSuccess={(req: any) =>
                                      setSelectedFiles([...selectedFiles, req])
                                    }
                                    disabled={
                                      !isEmpty(watch('companyPitchdeckLink')) ||
                                      !isEmpty(
                                        selectedFiles.find(
                                          (item: any) =>
                                            item.documentName ===
                                            VC_ADMIN_DOCUMENT_NAME.DEAL_PITCH_DECK_DOC,
                                        ),
                                      )
                                    }
                                    content={
                                      <AttachmentIcon
                                        style={{ cursor: 'pointer' }}
                                      />
                                    }
                                  />
                                ),
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              value={value}
                              onChange={(e: any) =>
                                onChange(e.target.value.trimStart())
                              }
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <span className={classes.errorText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                      {!isEmpty(selectedFiles)
                        ? selectedFiles
                            .filter(
                              (item: any) =>
                                get(item, 'documentName') !==
                                VC_ADMIN_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                            )
                            .map((item: any, index: number) => (
                              <Box className={classes.previewBox} key={index}>
                                <Box className={classes.nameContainer}>
                                  <img
                                    src={PdfOutlinedIcon}
                                    alt={PdfOutlinedIcon}
                                    className={classes.sampleFile}
                                  />
                                  <Typography
                                    className={classes.uploadFileName}
                                  >
                                    {get(item, 'filename')}
                                  </Typography>
                                </Box>
                                {get(item, 'documentStatus') !== 'UPLOADED' ? (
                                  <Box>
                                    <img
                                      src={Delete}
                                      alt={Delete}
                                      className={classes.deleteImg}
                                      onClick={() => {
                                        const filteredData = selectedFiles.filter(
                                          (doc: any) =>
                                            get(doc, 'file.uid') !==
                                            get(item, 'file.uid'),
                                        );
                                        setSelectedFiles(filteredData);
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <>
                                    <Box>
                                      <img
                                        src={Delete}
                                        alt={Delete}
                                        className={classes.deleteImg}
                                        onClick={() => {
                                          setIsDeleteDocument(true);
                                          setSelectedDocument(item);
                                        }}
                                      />
                                    </Box>
                                    <a
                                      href={get(item, 'documentUrl')}
                                      download
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={DownloadOutlinedIcon}
                                        alt={DownloadOutlinedIcon}
                                        style={{
                                          marginTop: 4,
                                          marginRight: 5,
                                        }}
                                      />
                                    </a>
                                  </>
                                )}
                              </Box>
                            ))
                        : ''}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        className={classes.addOtherDocBox}
                        onClick={() => {
                          setIsAddDocument(true);
                        }}
                      >
                        <Box>+</Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Accordion>
                <Accordion
                  subheading="AI Analyst"
                  backgroundStyle={{
                    backgroundImage: `url(${AIArt})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                  expanded={expanded.includes('AiAnalyst')}
                  handleChange={(e: string) => handleChange(e)}
                  index="AiAnalyst"
                >
                  <Box>
                    <Grid
                      container
                      spacing={1}
                      className={classes.textfieldBox}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          className={classes.labelText}
                        >
                          Company Blurb
                        </Typography>
                        <Controller
                          name="companyBlurp"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <>
                              <WrappedTextInput
                                type="text"
                                multiline={true}
                                placeholder="Write here..."
                                className={classes.textValInput}
                                InputProps={{
                                  classes: { root: classes.inputRoot },
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused,
                                  },
                                }}
                                value={value}
                                onChange={(e: any) =>
                                  onChange(e.target.value.trimStart())
                                }
                                error={!!error?.message}
                              />
                              {error?.message && (
                                <span className={classes.errorText}>
                                  {error?.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      className={classes.inputFundBox}
                    >
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          className={classes.labelText}
                        >
                          Investment Note
                        </Typography>
                        <Controller
                          name="investmentNote"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <>
                              <WrappedTextInput
                                type="text"
                                placeholder="Attach Pdf"
                                className={classes.textValInput}
                                disabled={true}
                                InputProps={{
                                  classes: { root: classes.inputRoot },
                                  endAdornment: (
                                    <FileUpload
                                      fileExtensions={['pdf']}
                                      requestObject={{
                                        documentName:
                                          VC_ADMIN_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                                      }}
                                      fileSize={20971520} // bytes in decimal
                                      showSelectedFilePreview={false}
                                      uploadOnSelect={false}
                                      callGetSignedUrl={false}
                                      onSuccess={(req: any) =>
                                        setSelectedFiles([
                                          ...selectedFiles,
                                          req,
                                        ])
                                      }
                                      disabled={
                                        !isEmpty(
                                          selectedFiles.find(
                                            (item: any) =>
                                              item.documentName ===
                                              VC_ADMIN_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                                          ),
                                        )
                                      }
                                      content={
                                        <AttachmentIcon
                                          className={classes.attachmentIcon}
                                        />
                                      }
                                    />
                                  ),
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused,
                                  },
                                }}
                                value={value}
                                onChange={(e: any) =>
                                  onChange(e.target.value.trimStart())
                                }
                                error={!!error?.message}
                              />
                              {error?.message && (
                                <span className={classes.errorText}>
                                  {error?.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                        {!isEmpty(selectedFiles)
                          ? selectedFiles
                              .filter(
                                (item: any) =>
                                  get(item, 'documentName') ===
                                  VC_ADMIN_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                              )
                              .map((item: any, index: number) => (
                                <Box className={classes.previewBox} key={index}>
                                  <Box className={classes.nameContainer}>
                                    <img
                                      src={PdfOutlinedIcon}
                                      alt={PdfOutlinedIcon}
                                      className={classes.sampleFile}
                                    />
                                    <Typography
                                      className={classes.uploadFileName}
                                    >
                                      {get(item, 'filename')}
                                    </Typography>
                                  </Box>
                                  {get(item, 'documentStatus') !==
                                  'UPLOADED' ? (
                                    <Box>
                                      <img
                                        src={Delete}
                                        alt={Delete}
                                        className={classes.deleteImg}
                                        onClick={() => {
                                          const filteredData = selectedFiles.filter(
                                            (doc: any) =>
                                              get(doc, 'file.uid') !==
                                              get(item, 'file.uid'),
                                          );
                                          setSelectedFiles(filteredData);
                                        }}
                                      />
                                    </Box>
                                  ) : (
                                    <>
                                      <Box>
                                        <img
                                          src={Delete}
                                          alt={Delete}
                                          className={classes.deleteImg}
                                          onClick={() => {
                                            setIsDeleteDocument(true);
                                            setSelectedDocument(item);
                                          }}
                                        />
                                      </Box>
                                      <a
                                        href={get(item, 'documentUrl')}
                                        download
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <img
                                          src={DownloadOutlinedIcon}
                                          alt={DownloadOutlinedIcon}
                                          style={{
                                            marginTop: 4,
                                            marginRight: 5,
                                          }}
                                        />
                                      </a>
                                    </>
                                  )}
                                </Box>
                              ))
                          : ''}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          className={classes.labelText}
                        >
                          Investment Note (Docx)
                        </Typography>
                        <Controller
                          name="investmentNoteDocx"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <>
                              <WrappedTextInput
                                type="text"
                                placeholder="Attach Docx"
                                className={classes.textValInput}
                                disabled={true}
                                InputProps={{
                                  classes: { root: classes.inputRoot },
                                  endAdornment: (
                                    <FileUpload
                                      fileExtensions={['docx', 'doc']}
                                      requestObject={{
                                        documentName:
                                          VC_ADMIN_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                                      }}
                                      fileSize={20971520} // bytes in decimal
                                      showSelectedFilePreview={false}
                                      uploadOnSelect={false}
                                      callGetSignedUrl={false}
                                      onSuccess={(req: any) => {
                                        setFileReqInvestmentReportDoc(req);
                                      }}
                                      // disabled={
                                      //  !isEmpty(
                                      //    selectedFiles.find(
                                      //      (item: any) =>
                                      //        item.documentName ===
                                      //        VC_ADMIN_DOCUMENT_NAME.DEAL_INVESTMENT_NOTE,
                                      //    ),
                                      //  )
                                      // }
                                      content={
                                        <AttachmentIcon
                                          className={classes.attachmentIcon}
                                        />
                                      }
                                    />
                                  ),
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused,
                                  },
                                }}
                                value={value}
                                onChange={(e: any) =>
                                  onChange(e.target.value.trimStart())
                                }
                                error={!!error?.message}
                              />
                              {error?.message && (
                                <span className={classes.errorText}>
                                  {error?.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                        {!isEmpty(fileReqInvestmentReportDoc) ? (
                          <Box className={classes.previewBox}>
                            <Box className={classes.nameContainer}>
                              <img
                                src={PdfOutlinedIcon}
                                alt={PdfOutlinedIcon}
                                className={classes.sampleFile}
                              />
                              <Typography className={classes.uploadFileName}>
                                {get(fileReqInvestmentReportDoc, 'filename')}
                              </Typography>
                            </Box>
                            <Box>
                              <img
                                src={Delete}
                                alt={Delete}
                                className={classes.deleteImg}
                                onClick={() => {
                                  setFileReqInvestmentReportDoc({});
                                }}
                              />
                            </Box>
                          </Box>
                        ) : !isEmpty(
                            get(
                              selectedDealData,
                              'aiAnalyst.INVESTMENT_NOTE_DOCX',
                            ),
                          ) ? (
                          <Box className={classes.previewBox}>
                            <Box className={classes.nameContainer}>
                              <img
                                src={PdfOutlinedIcon}
                                alt={PdfOutlinedIcon}
                                className={classes.sampleFile}
                              />
                              <Typography className={classes.uploadFileName}>
                                {`${get(selectedDealData, 'companyName')}-
                                INVESTMENT_NOTE.doc`}
                              </Typography>
                            </Box>
                            <a
                              href={get(
                                selectedDealData,
                                'aiAnalyst.INVESTMENT_NOTE_DOCX',
                              )}
                              download
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={DownloadOutlinedIcon}
                                alt={DownloadOutlinedIcon}
                                style={{
                                  marginTop: 4,
                                  marginRight: 5,
                                }}
                              />
                            </a>
                          </Box>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Accordion>
                <Accordion
                  subheading="Remark"
                  expanded={expanded.includes('Remark')}
                  handleChange={(e: string) => handleChange(e)}
                  index="Remark"
                >
                  <Grid container spacing={1} className={classes.textfieldBox}>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.labelText}
                      >
                        Remark
                      </Typography>
                      <Controller
                        name="remark"
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <WrappedTextInput
                              type="text"
                              multiline={true}
                              minRows={2}
                              variant={'outlined'}
                              placeholder="Write here..."
                              className={classes.textValInput}
                              InputProps={{
                                classes: { root: classes.inputRoot },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              value={value}
                              onChange={(e: any) =>
                                onChange(e.target.value.trimStart())
                              }
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <span className={classes.errorText}>
                                {error?.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Accordion>
              </Box>
            </form>
          </Box>
        </WrappedLoadingOverlay>
      </SidePane>
      {isDeleteDocument && (
        <DeleteDealDocument
          isDeleteDocument={isDeleteDocument}
          setIsDeleteDocument={setIsDeleteDocument}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
          selectedDeal={selectedDeal}
          documents={selectedFiles}
          setDocuments={setSelectedFiles}
        />
      )}
      {isAddDocument && (
        <AddOtherDocument
          isOpen={isAddDocument}
          setIsOpen={setIsAddDocument}
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
        />
      )}
      {saveChangesModal && (
        <SaveChangesAlert
          saveChangesModal={saveChangesModal}
          setSaveChangesModal={setSaveChangesModal}
          onClickIgnore={handleClose}
          onClickSave={handleSubmit(onSubmit)}
        />
      )}
    </>
  );
};

export default ProspectiveDealSidePane;
