import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
// import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelIcon from '@material-ui/icons/Cancel';
// import { DropzoneArea } from 'material-ui-dropzone';
// import { AttachFile } from '@material-ui/icons';
// import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { get, isEmpty } from 'lodash';

import { UploadIcon, pdfIcon, documentIcon } from 'assets';
import { Loader, Dialog, AlertDialog } from 'components';
import { FN } from 'common/types';
import { RootState } from 'redux-modules/store/rootState';

import styles from './styles';
type ComponentProps = {
  handleFile: FN;
  imageUploading: boolean;
  handleDeleteDocument: FN;
  isRoleAdmin?: boolean;
  data?: any;
  documentId?: string;
  fileExtensions?: any;
};

const ImageUpload = ({
  handleFile,
  imageUploading,
  handleDeleteDocument,
  data,
  fileExtensions = [],
}: ComponentProps) => {
  const classes = styles();
  const [src, setSrc] = useState<string | undefined>('');
  const [invalidFormatDialog, setInvalidFormatDialog] = useState<boolean>(
    false,
  );
  const [file, setFile] = useState<any>('');
  const [isOpen, setOpen] = useState<boolean>(false);

  const uploadInputRef = useRef<HTMLInputElement>(null);

  const { bridgeLoanProcess } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  useEffect(() => {
    let isMount = true;
    if (isMount && data) {
      setSrc(data.srcUrl || data.documentUrl || '');
    }
    return () => {
      isMount = false;
    };
  }, []);

  const handleFilesChange = (event: any) => {
    const fileExt = event.target.files[0].name.split('.').pop();
    if (!isEmpty(fileExtensions)) {
      if (!fileExtensions.includes(fileExt.toLowerCase())) {
        setInvalidFormatDialog(true);
        return;
      }
    }
    const file = event.target.files || null;
    if (file) {
      setFile(file[0]);
      if (file[0] === undefined) return;
      setSrc(URL.createObjectURL(file[0]));
      handleFile(file[0]);
    }
  };

  const handlePreviewIcon = (fileType: any) => {
    if (fileType) {
      switch (fileType) {
        case 'img':
          return (
            <img
              src={get(data, 'documentUrl')}
              alt={get(data, 'filename')}
              className={classes.uploadImg}
              onClick={() => {
                setOpen(true);
              }}
            />
          );
        case 'doc':
          return (
            <a href={get(data, 'documentUrl')} target="_blank" rel="noreferrer">
              <img
                src={documentIcon}
                alt={documentIcon}
                className={classes.image}
              />
            </a>
          );
        case 'pdf':
          return (
            <a href={get(data, 'documentUrl')} target="_blank" rel="noreferrer">
              <img src={pdfIcon} alt={pdfIcon} className={classes.image} />
            </a>
          );
        default:
          return (
            <a href={get(data, 'documentUrl')} target="_blank" rel="noreferrer">
              <img
                src={documentIcon}
                alt={documentIcon}
                className={classes.image}
              />
            </a>
          );
      }
    }

    return (
      <a href={get(data, 'documentUrl')} target="_blank" rel="noreferrer">
        <img src={documentIcon} alt={documentIcon} className={classes.image} />
      </a>
    );
  };
  const showRejectedDoc = () => {
    return (
      <Box className={classes.uploadAvatar}>
        <Box>
          {handleGetFileFormat(get(data, 'filename')) === 'img' ||
          (file && file.type && file.type.includes('image')) ||
          (get(data, 'fileType') && get(data, 'fileType').includes('image')) ? (
            <img
              src={src}
              alt="avatar"
              className={classes.uploadImg}
              onClick={() => {
                setOpen(true);
              }}
            />
          ) : (
            <>{handlePreviewIcon(handleGetFileFormat(get(data, 'filename')))}</>
          )}

          <CancelIcon
            className={classes.offIcon}
            onClick={() => {
              setSrc('');
              setFile('');
              handleDeleteDocument();
            }}
          />
        </Box>
        <Typography className={classes.fileNameText} variant="body1">
          {get(data, 'filename')}
        </Typography>
      </Box>
    );
  };

  const showNormalDocs = () => {
    if (src && data && data.documentStatus != 'REQUESTED') {
      return (
        <Box className={classes.uploadAvatar}>
          <Box>
            {handleGetFileFormat(get(data, 'filename')) === 'img' ||
            (file && file.type && file.type.includes('image')) ||
            (get(data, 'fileType') &&
              get(data, 'fileType').includes('image')) ? (
              <img
                src={src}
                alt="avatar"
                className={classes.uploadImg}
                onClick={() => {
                  setOpen(true);
                }}
              />
            ) : (
              <>
                {handlePreviewIcon(handleGetFileFormat(get(data, 'filename')))}
              </>
            )}
            {!get(bridgeLoanProcess, 'documentsSigned') && (
              <CancelIcon
                className={classes.offIcon}
                onClick={() => {
                  setSrc('');
                  setFile('');
                  handleDeleteDocument();
                }}
              />
            )}
          </Box>
          <Typography className={classes.fileNameText} variant="body1">
            {get(data, 'filename')}
          </Typography>
        </Box>
      );
    } else {
      return (
        <>
          <img
            src={UploadIcon}
            alt={UploadIcon}
            className={classes.uploadIcon}
            onClick={() => {
              if (uploadInputRef && uploadInputRef.current) {
                uploadInputRef.current && uploadInputRef.current.click();
              }
            }}
          />
          <input
            // accept="image/*"
            // className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            // multiple
            type="file"
            ref={uploadInputRef}
            onChange={(e: any) => handleFilesChange(e)}
          />
        </>
      );
    }
  };

  const handleGetFileFormat = (filename: string) => {
    let fileExtension = '';
    if (filename) {
      fileExtension = filename.substr(filename.length - 3);
      if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
        fileExtension = 'img';
      } else if (['doc', 'docx'].includes(fileExtension)) {
        fileExtension = 'doc';
      } else if (['pdf'].includes(fileExtension)) {
        fileExtension = 'pdf';
      }
    }

    return fileExtension;
  };

  return (
    <>
      {imageUploading && !src ? <Loader /> : null}

      {get(data, 'documentStatus') === 'REJECTED'
        ? showRejectedDoc()
        : showNormalDocs()}

      <Dialog
        open={isOpen}
        maxWidth={'md'}
        subheading={''}
        title={get(data, 'filename', '')}
        handleClose={() => {
          setOpen(false);
        }}
      >
        <img
          src={src}
          alt={get(data, 'filename')}
          className={classes.previewImg}
        />
      </Dialog>
      <AlertDialog
        dialogOpen={invalidFormatDialog}
        onClose={() => setInvalidFormatDialog(false)}
        dialogHeading={'Invalid file extension'}
        dialogContent={
          <>
            <div>Allowed file format are:</div>
            <div>{fileExtensions.join(', ')}</div>
          </>
        }
      />
    </>
  );
};

export default ImageUpload;
