import { urlConstants } from 'common/utils/constants';
import {
  PaymentsPayload,
  LogPaymentType,
  VdInstallmentPayload,
  PaymentByIdPayload,
} from 'redux-modules/payments/Types';

import { secureApi } from '../api/Axios';

export const getPaymentsList = (payload: PaymentsPayload) =>
  secureApi.get(
    `${urlConstants.getPaymentsList}/${payload.startupId}?loanType=${payload.loanType}`,
  );

export const getVdInstallments = (payload: VdInstallmentPayload) =>
  secureApi.get(`${urlConstants.getVdInstallments}/${payload.startupId}`);

export const logPayments = (payload: LogPaymentType) =>
  secureApi.post(`${urlConstants.logPayment}`, payload);

export const getPaymentById = (payload: PaymentByIdPayload) =>
  secureApi.get(`${urlConstants.getPaymentById}/${payload.paymentId}`);

export const startupUpcomingPayment = () =>
  secureApi.post(`${urlConstants.startupUpcomingPayment}`);
