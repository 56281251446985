import React, { memo, useEffect, useState } from 'react';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import Upload from 'rc-upload';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import CancelIcon from '@material-ui/icons/Cancel';

import { documentIcon, UploadIcon, IconImg } from 'assets';
// eslint-disable-next-line no-unused-vars
import {
  Box,
  Button,
  WrappedTextInput,
  ConfirmDialog,
  AlertDialog,
} from 'components';
import { getSignedUrlForYCDoc } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { approveYCDocuments } from 'redux-modules/process/Actions';
import { getYcDocsByStartupId } from 'redux-modules/enquiries/Actions';
import { RootState } from 'redux-modules/store/rootState';

import styles from '../../styles';

type YcStatusProps = {
  isEditYc: boolean;
  documentObject: any;
  setDocumentObject?: any;
  control: any;
  enquiryView: any;
  fileExtensions?: any;
  setValue: any;
  isVd: boolean;
};
// eslint-disable-next-line no-unused-vars
const YCStatus = ({
  documentObject,
  setDocumentObject,
  isEditYc,
  control,
  enquiryView,
  fileExtensions = [],
  setValue,
  isVd,
}: YcStatusProps) => {
  const { startupDetails } = enquiryView;
  const dispatch = useDispatch();
  const classes = styles();
  const [isLoadingSignedUrl, setIsLoadingSignedUrl] = React.useState<boolean>(
    false,
  );
  const [selectedDocId, setSelectedDocId] = useState('');
  const { isLoadingYc } = useSelector(({ Process }: RootState) => Process);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState({});
  const [invalidFormatDialog, setInvalidFormatDialog] = useState<boolean>(
    false,
  );
  // const { documents } = YCDocuments;

  const { isLoadingYcDocs } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const [
    signedUrlErrorMessage,
    setSignedUrlErrorMessage,
  ] = React.useState<string>('');

  const beforeUpload = async (file: any): Promise<void> => {
    const fileExt = file.name.split('.').pop();
    if (!isEmpty(fileExtensions)) {
      if (!fileExtensions.includes(fileExt.toLowerCase())) {
        setInvalidFormatDialog(true);
        return;
      }
    }

    setSignedUrlErrorMessage('');
    if (!isEmpty(file)) {
      setIsLoadingSignedUrl(true);
      try {
        const url = await getSignedUrlForYCDoc({
          fileName: file.name,
          documentName: 'YC_ACCEPTANCE',
          startupId: get(startupDetails, 'startupId'),
        });
        // eslint-disable-next-line no-undef
        await fetch(get(url, 'uploadUrl'), {
          method: 'PUT',
          body: file,
        });
        const dataObj = {
          documentName: 'YC_ACCEPTANCE',
          documentUrl: get(url, 'accessUrl'),
          filename: get(file, 'name'),
          startupId: get(startupDetails, 'startupId'),
        };
        setDocumentObject([dataObj]);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setSignedUrlErrorMessage(message);
      } finally {
        setIsLoadingSignedUrl(false);
      }
    } else {
      setSignedUrlErrorMessage('Please upload file.');
    }

    return Promise.reject();
  };

  const callback = () =>
    dispatch(
      getYcDocsByStartupId(
        `${get(startupDetails, 'startupId')}##${
          isVd ? 'VENTURE_DEBT' : 'BRIDGE_LOAN'
        }`,
      ),
    );
  const handleApproveDocument = async (id: string, action: string) => {
    const payload: any = {
      documentId: id,
      enquiryId: get(enquiryView, 'id'),
      action,
      startupId: get(startupDetails, 'startupId'),
      loanType: get(enquiryView, 'fundType'),
      callback,
    };
    setSelectedDocId(id);
    await dispatch(approveYCDocuments(payload));
    setDialogOpen(false);
  };

  useEffect(() => {
    setValue(
      'assignedYcPartnerName',
      get(startupDetails, 'assignedYcPartnerName'),
    );
    setValue(
      'assignedYcLegalPocName',
      get(startupDetails, 'assignedYcLegalPocName'),
    );
  }, [startupDetails]);
  // eslint-disable-next-line no-console
  console.log(startupDetails);
  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={2} className={classes.ycFieldBox}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography className={classes.partnerName}>
            Assigned YC partner name
          </Typography>
          <Controller
            name="assignedYcPartnerName"
            control={control}
            defaultValue={get(startupDetails, 'assignedYcPartnerName')}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Assigned YC Group Partner Name"
                  className={classes.partnerName}
                  InputProps={{
                    // inputProps: { step: 1000, min: 50000, max: 150000 },
                    classes: { root: classes.inputRoot },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                  disabled={!isEditYc}
                />
                {error?.message && (
                  <span className={classes.ycerrorText}>{error?.message}</span>
                )}{' '}
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography className={classes.partnerName}>
            Assigned YC Legal POC name
          </Typography>
          <Controller
            name="assignedYcLegalPocName"
            defaultValue={get(startupDetails, 'assignedYcLegalPocName')}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Assigned YC Legal POC Name"
                  className={classes.partnerName}
                  InputProps={{
                    // inputProps: { step: 1000, min: 50000, max: 150000 },
                    classes: { root: classes.inputRoot },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                  disabled={!isEditYc}
                />
                {error?.message && (
                  <span className={classes.ycerrorText}>{error?.message}</span>
                )}{' '}
              </>
            )}
          />
        </Grid>
      </Grid>

      <Box className={classes.ycDocumentBox}>
        {/* <Typography className={classes.documentTxt}>Document</Typography> */}
        <Box className={classes.docuList}>
          {(documentObject || []).map(
            ({ filename, url, id, documentStatus, documentUrl }: any) => (
              <Box key={id}>
                {/* <Grid item xs={12} sm={8} md={8}> */}
                <Box className={classes.docuImgBox}>
                  <a target="_blank" href={documentUrl} rel="noreferrer">
                    <img
                      src={documentIcon || url}
                      className={classes.docuImg}
                    />
                  </a>
                  <Typography className={classes.docuImgTxt}>
                    {filename}
                  </Typography>
                  {id &&
                    documentStatus !== 'VERIFIED' &&
                    documentStatus !== 'REJECTED' && (
                      <Box className={classes.approvedBox}>
                        <Button
                          name="Approve"
                          className={classes.approveBtnPreview}
                          onClick={() => {
                            setDialogOpen(true);
                            setDialogAction({ id, action: 'VERIFIED' });
                          }}
                          isLoading={
                            (isLoadingYc && selectedDocId === id) ||
                            isLoadingYcDocs
                          }
                        />
                        <Button
                          className={classes.rejectBtnPreview}
                          name="Reject"
                          onClick={() => {
                            setDialogOpen(true);
                            setDialogAction({ id, action: 'REJECTED' });
                          }}
                          isLoading={
                            (isLoadingYc && selectedDocId === id) ||
                            isLoadingYcDocs
                          }
                        />
                      </Box>
                    )}
                  {documentStatus === 'VERIFIED' && (
                    <Box className={classes.verifiedBox}>
                      <img src={IconImg} className={classes.iconApprovImg} />
                      <Button
                        // icon={
                        //   <CheckCircleOutlineRoundedIcon
                        //     className={classes.circleOutline}
                        //   />
                        // }
                        onClick={() => {
                          setDialogOpen(true);
                          setDialogAction({ id, action: 'REJECTED' });
                        }}
                        name={'Approved'}
                        className={classes.approveFont}
                        isLoading={
                          (isLoadingYc && selectedDocId === id) ||
                          isLoadingYcDocs
                        }
                      />
                    </Box>
                  )}
                  {documentStatus === 'REJECTED' && (
                    <Box className={classes.verifiedBox}>
                      <CancelIcon className={classes.circleOutline} />
                      <Button
                        onClick={() => {
                          setDialogOpen(true);
                          setDialogAction({ id, action: 'VERIFIED' });
                        }}
                        name={'Rejected'}
                        className={classes.rejectedFont}
                        isLoading={
                          (isLoadingYc && selectedDocId === id) ||
                          isLoadingYcDocs
                        }
                      />
                    </Box>
                  )}
                </Box>
                {/* </Grid> */}
              </Box>
            ),
          )}
        </Box>
      </Box>

      {!isLoadingSignedUrl && isEditYc && (
        <Box className={classes.uploadYcContainer}>
          <Upload
            beforeUpload={beforeUpload}
            style={{ display: 'block' }}
            disabled={!isEditYc}
          >
            <Box className={classes.uploadYcBox}>
              <img
                src={UploadIcon}
                alt={UploadIcon}
                className={classes.uploadYcIcon}
              />
              <Typography variant="caption" className={classes.uploadYcText}>
                Upload Document
              </Typography>
            </Box>

            {signedUrlErrorMessage &&
              isEmpty(documentObject) &&
              !isLoadingSignedUrl && (
                <Typography variant="caption">
                  {signedUrlErrorMessage}
                </Typography>
              )}
          </Upload>
        </Box>
      )}

      {isLoadingSignedUrl && <CircularProgress />}
      <ConfirmDialog
        dialogOpen={dialogOpen && !isEmpty(get(dialogAction, 'id'))}
        onClose={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
        dialogHeading={`${
          get(dialogAction, 'action') === 'VERIFIED' ? 'Approve' : 'Reject'
        } document`}
        dialogContent={`Are you sure you want to ${
          get(dialogAction, 'action') === 'VERIFIED' ? 'approve' : 'reject'
        }  this document`}
        isLoading={isLoadingYc || isLoadingYcDocs}
        onConfirm={() =>
          handleApproveDocument(
            get(dialogAction, 'id'),
            get(dialogAction, 'action'),
          )
        }
      />

      <AlertDialog
        dialogOpen={invalidFormatDialog}
        onClose={() => setInvalidFormatDialog(false)}
        dialogHeading={'Invalid file extension'}
        dialogContent={
          <>
            <div>Allowed file format are:</div>
            <div>{fileExtensions.join(', ')}</div>
          </>
        }
      />
    </div>
  );
};
export default memo(YCStatus);
