import React, { ChangeEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputBase,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { get, isEmpty } from 'lodash';

import history from 'common/utils/history';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { RootState } from 'redux-modules/store/rootState';
import {
  Box,
  WrappedSelect,
  Loader,
  StyledTableCell,
  StyledTableRow,
} from 'components';
import { getEnquiries } from 'services';
import { getBatches } from 'redux-modules/auth/Actions';
import { Ornament } from 'assets';
import { ParamsType } from 'common/types';

import styles from './styles';

const fundTypes = [
  {
    text: 'ALL',
    value: 'ALL',
  },
  {
    text: 'BRIDGE LOAN',
    value: 'BRIDGE_LOAN',
  },
  {
    text: 'VENTURE DEBT',
    value: 'VENTURE_DEBT',
  },
];

const enquiryStatusList = [
  {
    text: 'ALL',
    value: 'ALL',
  },
  {
    text: 'PENDING',
    value: 'PENDING',
  },
  {
    text: 'APPROVED',
    value: 'APPROVED',
  },
  {
    text: 'BASIC DETAILS',
    value: 'BASIC_DETAILS',
  },
  {
    text: 'DOCUMENTS UPLOADED',
    value: 'DOCUMENTS_UPLOADED',
  },
];

const EnquiryList = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [ycBatch, setYcBatch] = React.useState<string>('ALL');
  const [fundType, setFundType] = React.useState<string>('ALL');
  const [enquiryStatus, setEnquiryStatus] = React.useState<string>('ALL');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  // eslint-disable-next-line no-unused-vars
  const [ycBatchesList, setYcBatchesList] = React.useState([]);
  const { ycBatches } = useSelector(({ Auth }: RootState) => Auth);
  // eslint-disable-next-line no-unused-vars
  const [enquiries, setEnquiries] = React.useState<any>([]);
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);
  const [totalPages, setTotalPages] = React.useState<number>(3);
  const [totalRows, setTotalRows] = React.useState<number>(0);

  const [currentPage, setCurrentPage] = React.useState<number>(0);

  const getAllEnquiries = (obj: any) => {
    setErrorMessage('');
    setIsLoading(true);
    getEnquiries(obj)
      .then((res) => {
        setEnquiries(get(res, 'data'));
        setFilteredSearchData(get(res, 'data'));
        setTotalPages(get(res, 'totalPages'));
        setTotalRows(get(res, 'totalRows'));
        setIsLoading(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    const obj: ParamsType = {
      fundtype: 'ALL',
      ycbatch: 'ALL',
      enquirytype: 'ALL',
      itemsPerPage: 10,
      page: currentPage,
    };
    getAllEnquiries(obj);
  }, []);

  const classes = styles();

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    type: string,
    page?: number,
  ) => {
    const obj: ParamsType = {
      fundtype: fundType,
      ycbatch: ycBatch,
      enquirytype: enquiryStatus,
      itemsPerPage: 10,
      page: currentPage,
    };
    if (type === 'type') {
      obj['fundtype'] = e.target.value;
      obj.page = 0;
      setFundType(e.target.value);
      setCurrentPage(0);
    }

    if (type === 'batch') {
      obj['ycbatch'] = e.target.value;
      obj.page = 0;
      setYcBatch(e.target.value);
      setCurrentPage(0);
    }

    if (type === 'status') {
      obj['enquirytype'] = e.target.value;
      setEnquiryStatus(e.target.value);
      obj.page = 0;
      setCurrentPage(0);
    }
    if (type === 'pagination')
      obj.page = page === undefined ? currentPage : page - 1;

    getAllEnquiries(obj);
  };

  useEffect(() => {
    if (ycBatches.length === 0) {
      dispatch(getBatches());
    }
  }, []);

  useEffect(() => {
    if (ycBatches) {
      const updatedYcBatches = JSON.parse(JSON.stringify(ycBatches));
      const batchItem = {
        text: 'ALL',
        value: 'ALL',
      };
      updatedYcBatches.push(batchItem);
      setYcBatchesList(updatedYcBatches);
      updatedYcBatches.push({
        text: 'Non YC',
        value: 'NON_YC',
      });
    }
  }, [ycBatches]);

  const onCompanySearch = (str: string) => {
    const filteredData = enquiries.filter((value: any) => {
      if (
        str === '' ||
        value.startupName
          ?.toString()
          .toLowerCase()
          .includes(str?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
  };

  const onChangePagination = (e: any, page: number) => {
    setCurrentPage(page - 1);
    handleChange(e, 'pagination', page);
  };

  return (
    <Box className={classes.enquiryListBox}>
      <Box className={classes.enquiryListhead}>
        <Box className={classes.enquiryListLeft}>
          <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
          <Typography
            variant="h3"
            gutterBottom
            className={classes.enquiryHeadText}
          >
            All Enquiries
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.enquirySubHeadText}
          >
            {totalRows} Companies
          </Typography>
        </Box>
        <Box className={classes.enquiryListRight}>
          <FormControl required className={classes.formControl}>
            <Typography
              variant="caption"
              gutterBottom
              className={classes.batchLabel}
            >
              Accelerator
            </Typography>
            <WrappedSelect
              // label="YC Batch"
              variant={'outlined'}
              value={ycBatch}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleChange(e, 'batch')
              }
              placeholder="YC Batch"
              className={classes.selectValInput}
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              SelectProps={{
                MenuProps: {
                  classes: { paper: classes.menuPaper },
                },
              }}
              dropdowns={ycBatchesList}
            />
            <Typography
              variant="caption"
              gutterBottom
              className={classes.batchLabel}
            >
              Fund Type
            </Typography>
            <WrappedSelect
              // label="Fund Type"
              variant={'outlined'}
              value={fundType}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleChange(e, 'type')
              }
              placeholder="Fund Type"
              className={classes.selectValInput}
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              dropdowns={fundTypes}
            />
            <Typography
              variant="caption"
              gutterBottom
              className={classes.batchLabel}
            >
              Enquiry Status
            </Typography>
            <WrappedSelect
              variant={'outlined'}
              value={enquiryStatus}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleChange(e, 'status')
              }
              placeholder="Enquiry Status"
              className={classes.textValInput}
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              dropdowns={enquiryStatusList}
            />
          </FormControl>
        </Box>
      </Box>

      <InputBase
        placeholder="Search company name"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e: any) => onCompanySearch(get(e, 'target.value'))}
      />
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <StyledTableCell>Company Name</StyledTableCell>

              <StyledTableCell style={{ width: 50 }}>Location</StyledTableCell>
              <StyledTableCell>Accelerator</StyledTableCell>
              {/* <StyledTableCell>YC Verification</StyledTableCell> */}
              {/* <StyledTableCell>Fund Amount</StyledTableCell> */}
              <StyledTableCell style={{ width: 100 }}>
                Fund Type
              </StyledTableCell>
              <StyledTableCell>MTM Value</StyledTableCell>
              <StyledTableCell style={{ width: 140 }}>
                Latest Post Money Valuation
              </StyledTableCell>
              <StyledTableCell>Loan Status</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <Box className={classes.loaderSection}>
                <Loader className={classes.loaderBox} />
              </Box>
            ) : isEmpty(filteredSearchData) && !errorMessage ? (
              <StyledTableRow>
                <StyledTableCell colSpan={6}>
                  <Box className={classes.noDatafoundText}>
                    <Typography>No data found in table</Typography>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              filteredSearchData.map((row: any) => (
                <StyledTableRow hover key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.startupName}
                  </StyledTableCell>
                  <StyledTableCell>{row.location}</StyledTableCell>
                  <StyledTableCell>
                    {get(row, 'accelerator') && get(row, 'ycBatch')
                      ? `YC ${get(row, 'ycBatch')}`
                      : get(row, 'accelerator')}
                  </StyledTableCell>
                  {/* <StyledTableCell>
                    {row.isYcVerified ? 'true' : 'false'}
                  </StyledTableCell> */}
                  {/* <StyledTableCell>
                    {row.fundAmount ? row.fundAmount : '12000'}
                  </StyledTableCell> */}
                  <StyledTableCell>
                    {' '}
                    <span
                      style={{
                        backgroundColor:
                          row.fundType === 'BRIDGE_LOAN' ? 'green' : 'blue',
                        color: 'white',
                        padding: '4px',
                        fontSize: '10px',
                        borderRadius: 4,
                      }}
                    >
                      {get(row, 'fundType') &&
                        get(row, 'fundType').replace('_', ' ')}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>
                    {get(row, 'mtmValue') &&
                      get(row, 'mtmValue') !== '0.00' &&
                      formatAmount(get(row, 'mtmValue'))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {get(row, 'postMoneyValuation') &&
                      get(row, 'postMoneyValuation') !== '0.00' &&
                      formatAmount(get(row, 'postMoneyValuation'))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {['EXTENDED', 'CONVERTED', 'CLOSED'].includes(
                      get(row, 'status'),
                    ) && get(row, 'status')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.enquiryStatus ? row.enquiryStatus : 'PENDING'}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.tdBtn}
                      onClick={() =>
                        history.push(`/admin/enquiry-view/${row.id}`)
                      }
                    >
                      View
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.paginationWrap}>
        <Pagination
          onChange={onChangePagination}
          count={totalPages}
          page={currentPage + 1}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default EnquiryList;
