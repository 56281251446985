import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  dealContainer: {},
  dealHeader: {
    boxShadow: theme.shadows[1],
    background: theme.palette.common.white,
    width: '100%',
    height: 70,
    position: 'sticky',
    top: 0,
  },
  headerContent: {
    maxWidth: 1440,
    margin: 'auto',
    padding: '12.5px 11%',
  },
  dealLogo: {
    height: 40,
  },
  dealBody: {
    maxWidth: 1440,
    margin: 'auto',
    padding: '50px 11%',
    '@media (max-width: 525px)': {
      padding: '30px 5%',
    },
  },
  dHText: {
    fontSize: 35,
    textAlign: 'center',
    fontWeight: 800,
    marginBottom: 50,
    color: theme.palette.warning.contrastText,
    '@media (max-width: 525px)': {
      fontSize: 25,
      marginBottom: 30,
    },
  },
  dHSText: {
    fontSize: 20,
    letterSpacing: 0.35,
    fontWeight: 700,
    marginBottom: 20,
    color: theme.palette.warning.contrastText,
  },
  ulBox: {
    listStyle: 'disc',
  },
  liBox: {
    color: theme.palette.grey[50],
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 15,
  },
  subUlText: {
    color: theme.palette.grey[50],
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 15,
  },
  hereText: {
    color: theme.palette.grey[50],
    fontSize: 18,
    fontWeight: 500,
    cursor: 'pointer',
  },
  spanHere: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: 18,
    fontWeight: 500,
  },
  dealBox: {
    padding: '30px 0',
    margin: '0 -15px',
  },
  dealImg: {
    maxWidth: '100%',
    height: 'auto',
  },
  hdText: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 600,
  },
  bonusText: {
    color: theme.palette.warning.contrastText,
    fontSize: 18,
    fontWeight: 700,
  },
  exText: {
    color: theme.palette.grey[50],
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 15,
    // paddingLeft: 20,
    maxWidth: 600,
  },
  batchText: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.warning.contrastText,
    marginTop: 15,
    marginBottom: 5,
  },
  debtFeeText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.grey[50],
    marginTop: 0,
    marginBottom: 25,
  },
  dealsFooter: {
    background: theme.palette.warning.main,
  },
  copyRText: {
    fontSize: 16,
    color: theme.palette.grey[50],
    padding: '15px 5%',
    textAlign: 'center',
  },

  dealsModel: {
    height: '100%',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiDialog-container': {
      alignSelf: 'center',
      height: '90%',
      width: 800,
    },
    '& .MuiDialog-paper': {
      margin: 0,
      padding: '40px 20px 40px 45px',
      position: 'relative',
      '@media (max-width: 960px)': {
        padding: '20px 10px 30px 30px',
        maxWidth: '90%',
      },
    },

    '& .MuiDialogContent-root': {
      padding: '20px 40px 0 0',
      overflowX: 'hidden',
      '@media (max-width: 960px)': {
        paddingRight: 20,
      },
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#1EA59A',
        width: '0.6em',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: '#F6FAFA',
        minHeight: 24,
        border: '1px solid #1EA59A',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#2B2B2B',
      },
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: '0 -12px 20px',
      '@media (max-width: 960px)': {
        margin: '0 -12px 5px',
      },
    },
  },
  termSheetBox: {},
  mSubTxt: {
    fontSize: 18,
    // fontWeight: 500,
    color: theme.palette.warning.contrastText,
    marginBottom: 20,
  },
  mHeadTxt: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.warning.contrastText,
    marginBottom: 5,
  },
  tTable: {
    border: '1px solid #121212',
    borderCollapse: 'collapse',
  },
  tTr: {
    border: '1px solid #121212',
    borderCollapse: 'collapse',
  },
  tTh: {
    border: '1px solid #121212',
    borderCollapse: 'collapse',
    color: theme.palette.warning.contrastText,
    fontSize: 16,
    fontWeight: 700,
    padding: 10,
    letterSpacing: 0.35,
    fontFamily: theme.typography.fontFamily,
  },
  tTd: {
    border: '1px solid #121212',
    borderCollapse: 'collapse',
    color: theme.palette.grey[50],
    fontFamily: theme.typography.fontFamily,
    padding: 10,
    letterSpacing: 0.35,
    fontSize: 16,
  },
  forText: {
    fontSize: 17,
    color: theme.palette.warning.contrastText,
    marginTop: 30,
    marginBottom: 50,
    letterSpacing: 0.5,
  },
  forBox: {
    display: 'flex',
  },
  forBext: {
    borderBottom: '1px solid #121212',
    width: 200,
    alignSelf: 'baseline',
    marginRight: 70,
    marginBottom: 5,
  },
  forSText: {
    borderBottom: '1px solid #121212',
    width: 200,
    alignSelf: 'baseline',
    marginBottom: 5,
  },
  forDate: {},
  forSDate: {
    fontSize: 16,
  },
}));
