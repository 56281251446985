import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

import { Box, Tabs, TabPanel } from 'components';
import {
  SubmitDocuments,
  getDocumentlist,
  getStartUpDocumentlist,
  getDocuSignDocAdmin,
  handleSubmitDocs,
  emptyDocuSignState,
  emptySuccessMessage,
} from 'redux-modules/process/Actions';
import {
  trackBridgeLoanProcess,
  trackVdProcess,
  getFundType,
  getLoansDetailsByStartupId,
  getYcDocsByStartupId,
} from 'redux-modules/enquiries/Actions';
import {
  ListDocType,
  DocuSignDocType,
  SubmitDocuSignDocsType,
} from 'redux-modules/process/Types';
import { RootState } from 'redux-modules/store/rootState';
import { removeDuplicates } from 'common/utils/helpers';
import history from 'common/utils/history';

import BridgeLoanProcess from './Components/FundType/BridgeLoan';
import VentureDebtProcess from './Components/FundType/VentureDebt';
import styles from './styles';

type ComponentProps = {
  startupId: string | undefined;
  // loanType: string | undefined;
};

type DocTyeProps = {
  documentsType: string | undefined;
};

const CompanyProcess = ({ startupId }: ComponentProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [documentsType, setDocumentsType] = useState<string | undefined>('');
  const [expanded, setExpanded] = useState<string | false>(false);
  const [input, setInput] = useState(false);
  const [documentName, handleSetDocumentName] = useState<string>('');
  const [value, setValue] = useState<number>(0);
  const [fundType, setFundType] = useState<string>('');
  const [fundTypeTabs, setFundTypeTabs] = useState([
    { name: 'Bridge Loan', value: 'BRIDGE_LOAN', key: 'BRIDGE_LOAN' },
  ]);
  const [documentsList, setDocumentsList] = useState<any>([]);
  const [isDisabled, setDisable] = useState<boolean>(true);
  const [isDocuSignSendDisabled, setDocuSignSend] = useState<boolean>(true);
  const [docuSignDocumentsList, setDocuSignDocumentsList] = useState<any>([]);

  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  const isRoleAdmin = role === 'ADMIN';

  const { Documents, docuSignDocumentsTemplate, submitDocuments } = useSelector(
    ({ Process }: RootState) => Process,
  );
  const enquiryErrorMessage = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );
  const { vdProcess, enquiryView } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );
  const { documents } = Documents;
  const {
    docusignDocuments,
    requestedDocuments,
    docuSignSuccessMessage,
  } = docuSignDocumentsTemplate;
  const [nonDocumentsList, setNonDocumentsList] = useState<any>(documents);

  useEffect(() => {
    const loanType = get(vdProcess, 'vdConversionRequestRaised', false)
      ? 'VENTURE_DEBT'
      : 'BRIDGE_LOAN';
    setFundType(loanType);
  }, [vdProcess]);

  useEffect(() => {
    if (
      (fundType === 'VENTURE_DEBT' && fundTypeTabs.length === 1) ||
      (fundTypeTabs.length === 1 && !enquiryErrorMessage)
    ) {
      const fundTypeTabsRef = JSON.parse(JSON.stringify(fundTypeTabs));
      const ventureDebtTab = {
        name: 'Venture Debt',
        value: 'VENTURE_DEBT',
        key: 'VENTURE_DEBT',
      };
      fundTypeTabsRef.push(ventureDebtTab);
      fundTypeTabsRef.reverse();
      setFundTypeTabs(fundTypeTabsRef);
    }
  }, [fundType]);

  const handleChange = (panel: string, docType?: string) => (
    // eslint-disable-next-line @typescript-eslint/ban-types
    _event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
    setDocumentsType(docType);
  };
  const handleSetFundTypeOnTabChange = (value: number) => {
    let loanType = '';
    if (value) {
      loanType = 'BRIDGE_LOAN';
    } else if (fundTypeTabs && fundTypeTabs.length > 1 && !value) {
      loanType = 'VENTURE_DEBT';
    } else {
      loanType = 'BRIDGE_LOAN';
    }
    setFundType(loanType);
    return loanType;
  };

  const getAdminListDocuments = () => {
    const loanType = handleSetFundTypeOnTabChange(value);
    let obj: ListDocType = {
      startupId: startupId,
      loanType,
    };
    const documentTypeObj: DocTyeProps = {
      documentsType,
    };
    if (documentsType) {
      obj = { ...obj, ...documentTypeObj };
    }
    dispatch(getDocumentlist(obj));
  };

  const handleAddDocuments = () => {
    const obj: any = {
      documentName,
      action: '',
    };
    // dispatch(addDocuments(obj));
    setDocumentsList(removeDuplicates([...documentsList, obj], 'documentName'));
    setNonDocumentsList(
      removeDuplicates([...documents, ...documentsList, obj], 'documentName'),
    );
    setInput(false);
    handleSetDocumentName('');
  };

  const handleDeleteDocument = (documentName: string) => {
    const data = nonDocumentsList.filter(
      (i: any) => i.documentName !== documentName,
    );
    setNonDocumentsList(data);
  };

  const handleSubmitDocuments = () => {
    const updatedDocuments = removeDuplicates(nonDocumentsList, 'documentName');
    const obj = {
      ...Documents,
      documents: updatedDocuments,
    };
    dispatch(SubmitDocuments(obj));
    setDocumentsList([]);
  };

  useEffect(() => {
    if (startupId) {
      if (isRoleAdmin) {
        dispatch(getFundType(startupId));
      } else {
        dispatch(getLoansDetailsByStartupId(startupId));
      }
      dispatch(
        getYcDocsByStartupId(`${startupId}##${get(enquiryView, 'fundType')}`),
      );
    }
  }, [startupId]);

  useEffect(() => {
    if (!isEmpty(documents) || !isEmpty(nonDocumentsList)) {
      setNonDocumentsList(documents);
    }
  }, [documents]);

  useEffect(() => {
    setDocuSignDocumentsList(requestedDocuments);
  }, [requestedDocuments]);

  const isDispatchReq =
    expanded !== 'APPROVE_LOAN' &&
    expanded !== 'PROCESS_LOAN' &&
    expanded !== 'REVIEW_VD_DETAILS'
      ? true
      : false;

  useEffect(() => {
    if (expanded && isDispatchReq) {
      const loanType = handleSetFundTypeOnTabChange(value);
      if (expanded === 'DOCU_SIGN_DOCUMENTS') {
        dispatch(getDocuSignDocAdmin({ startupId, loanType }));
      } else if (
        (isRoleAdmin &&
          expanded === 'REQUIRED_DOCUMENTS' &&
          !isEmpty(documentsList)) ||
        expanded === 'REVIEW_DOCUMENTS'
      ) {
        getAdminListDocuments();
      } else if (!isRoleAdmin && expanded === 'REQUIRED_DOCUMENTS') {
        const payload = {
          startupId,
          documentsType: 'NON_DOCU_SIGN_DOCUMENTS',
          loanType,
        };
        dispatch(getStartUpDocumentlist(payload));
      }
    }
  }, [expanded, value]);

  const handleUpdateRequestedDoc = (doc: DocuSignDocType) => {
    const documentObj = {
      createdAt: doc.createdAt,
      documentName: 'Docusign_Document',
      documentStatus: 'REQUESTED',
      documentType: 'DOCU_SIGN_DOCUMENTS',
      documentUrl: null,
      docusignDocumentGuid: doc.documentIdGuid,
      docusignDocumentId: doc.documentId,
      docusignTemplateId: doc.templateId,
      filename: doc.name,
      loanType: fundType,
      startupId,
    };
    let response;
    if (isEmpty(docuSignDocumentsList)) {
      response = [documentObj];
    } else {
      const updatedRequestedDoc = docuSignDocumentsList.some(
        (o: any) => o.docusignDocumentGuid === doc.documentIdGuid,
      );
      response = updatedRequestedDoc
        ? [...docuSignDocumentsList].filter(
            (i) => i.docusignDocumentGuid !== doc.documentIdGuid,
          )
        : [...docuSignDocumentsList, documentObj];
    }
    // dispatch(handleDocuSign(response));
    setDocuSignDocumentsList(response);
  };

  const handleSendDocuSignDocs = () => {
    const obj: SubmitDocuSignDocsType = {
      templateId: get(docusignDocuments, '[0].templateId'),
      documentIds: docuSignDocumentsList.map((i: any) =>
        i.documentIdGuid ? i.documentIdGuid : i.docusignDocumentGuid,
      ),
      startupId: startupId,
      loanType: fundType,
    };
    dispatch(handleSubmitDocs(obj));
  };

  const handleTabChange = (event: any, newValue: number) => {
    setValue(newValue);
    // handleSetFundTypeOnTabChange(newValue);
    setExpanded(false);
  };

  const validateDocuments = () => {
    dispatch(emptySuccessMessage());
    if (fundType === 'BRIDGE_LOAN') {
      dispatch(trackBridgeLoanProcess(startupId));
    } else {
      dispatch(trackVdProcess(startupId));
    }
  };

  useEffect(() => {
    if (submitDocuments.successMessage || docuSignSuccessMessage) {
      validateDocuments();
      if (submitDocuments.successMessage) {
        setDisable(true);
      }
      if (docuSignSuccessMessage) {
        setDocuSignSend(true);
      }
      dispatch(emptyDocuSignState());
      setNonDocumentsList(documents);
    }
  }, [submitDocuments.successMessage, docuSignSuccessMessage]);

  useEffect(() => {
    if (history.location.search) {
      const search = history.location.search;
      const loanType = new URLSearchParams(search).get('loanType');
      const fundTypeParam: any = new URLSearchParams(search).get('fundType');
      if (loanType === 'VENTURE_DEBT') {
        setValue(Number(1));
        setFundType(fundTypeParam);
      } else {
        setValue(Number(0));
      }
      const step: any = new URLSearchParams(search).get('step');
      if (step) {
        setExpanded(step);
      }
    }
  }, [fundType]);

  useEffect(() => {
    if (isRoleAdmin && expanded === 'REQUIRED_DOCUMENTS') {
      if (nonDocumentsList.length === documents.length) {
        setDisable(true);
      }
      if (
        !isEmpty(nonDocumentsList) &&
        nonDocumentsList.length !== documents.length
      ) {
        setDisable(false);
      }
    }
  }, [expanded, nonDocumentsList]);

  useEffect(() => {
    if (isRoleAdmin && expanded === 'DOCU_SIGN_DOCUMENTS') {
      if (docuSignDocumentsList.length === requestedDocuments.length) {
        setDocuSignSend(true);
      }
      if (
        !isEmpty(docuSignDocumentsList) &&
        docuSignDocumentsList.length !== requestedDocuments.length
      ) {
        setDocuSignSend(false);
      }
    }
  }, [expanded, docuSignDocumentsList]);

  const handleRenderTabs = (tabVal: string) => {
    switch (tabVal) {
      case 'VENTURE_DEBT':
        return (
          <VentureDebtProcess
            fundType={'VENTURE_DEBT'}
            startupId={startupId}
            isRoleAdmin={isRoleAdmin}
            isDispatchReq={isDispatchReq}
            expanded={expanded}
            handleChange={handleChange}
            handleUpdateRequestedDoc={handleUpdateRequestedDoc}
            handleSendDocuSignDocs={handleSendDocuSignDocs}
            validateDocuments={validateDocuments}
          />
        );
      case 'BRIDGE_LOAN':
        return (
          <BridgeLoanProcess
            fundType={'BRIDGE_LOAN'}
            startupId={startupId}
            isRoleAdmin={isRoleAdmin}
            isDispatchReq={isDispatchReq}
            expanded={expanded}
            input={input}
            documentName={documentName}
            setInput={setInput}
            handleChange={handleChange}
            handleAddDocuments={handleAddDocuments}
            handleSubmitDocuments={handleSubmitDocuments}
            handleUpdateRequestedDoc={handleUpdateRequestedDoc}
            handleSendDocuSignDocs={handleSendDocuSignDocs}
            handleSetDocumentName={handleSetDocumentName}
            validateDocuments={validateDocuments}
            documentsList={documentsList}
            isDisabled={isDisabled}
            setDisable={setDisable}
            nonDocumentsList={nonDocumentsList}
            handleDeleteDocument={handleDeleteDocument}
            isDocuSignSendDisabled={isDocuSignSendDisabled}
            docuSignDocumentsList={docuSignDocumentsList}
          />
        );
      default:
        return;
    }
  };

  return (
    <Box className={classes.tabPanelContent}>
      <Box>
        <Tabs
          tabs={fundTypeTabs}
          value={value}
          handleChange={handleTabChange}
          className={classes.funTypeTabs}
        />
        {fundTypeTabs.map((tab: any, index: number) => (
          <TabPanel
            value={value}
            index={index}
            key={tab.value}
            className={classes.fundTabs}
          >
            {handleRenderTabs(tab.value)}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default CompanyProcess;
