import { urlConstants } from 'common/utils/constants';

import { secureApi } from '../api/Axios';

export const createBasicFcrm = (payload: any) =>
  secureApi.post(`${urlConstants.getFundRaisingCRM}`, payload);

export const getBasicFundRaisingCRM = () =>
  secureApi.get(`${urlConstants.getBasicFundRaisingCRM}`);

export const getAllFcrms = () => secureApi.get(`${urlConstants.getAllFcrms}`);
export const getAdminStartupFcrms = (payload: any) =>
  secureApi.post(`${urlConstants.adminStartupFcrms}`, payload);

export const getAdminFcrmRound = (payload: any) =>
  secureApi.post(`${urlConstants.adminCcrmRound}`, payload);

export const updateFcrm = (payload: any) =>
  secureApi.post(urlConstants.updateFcrm, payload);

export const createFcrm = (payload: any) =>
  secureApi.post(urlConstants.createFcrm, payload);

export const deleteFcrmProperty = (payload: any) =>
  secureApi.post(urlConstants.deleteFcrmProperty, payload);

export const createUpdateFcrmContact = (payload: any) =>
  secureApi.post(urlConstants.createUpdateFcrmContact, payload);

export const deleteFcrmContact = (payload: any) =>
  secureApi.post(urlConstants.deleteFcrmContact, payload);

export const deleteFcrm = (payload: any) =>
  secureApi.post(urlConstants.deleteFcrm, payload);

export const getUploadFcrmSignedUrl = (payload: any) =>
  secureApi.post(urlConstants.fcrmUploadSignedUrl, payload);

export const uploadFcrmFile = (payload: any) =>
  secureApi.post(urlConstants.uploadFcrmFile, payload);

export const sendFcrmEmail = (payload: any) =>
  secureApi.post(urlConstants.sendFcrmEmail, payload);

export const getAdminFcrmLists = (payload: any) =>
  secureApi.post(urlConstants.getAdminFcrmLists, payload);

export const getAdminMasterFcrmLists = (payload: any) =>
  secureApi.post(urlConstants.getAdminMasterFcrmLists, payload);

export const getEmailTemplatesList = () =>
  secureApi.post(urlConstants.getEmailTemplatesList);

export const saveEmailTemplate = (payload: any) =>
  secureApi.post(urlConstants.saveEmailTemplate, payload);

export const deleteEmailTemplate = (payload: any) =>
  secureApi.post(urlConstants.deleteEmailTemplate, payload);

export const fcrmEmailMediaSignedUrl = (payload: any) =>
  secureApi.post(urlConstants.fcrmEmailMedia, payload);

export const checkEmailVerified = () =>
  secureApi.get(`${urlConstants.checkEmailVerified}`);

export const verifyUserEmail = () =>
  secureApi.post(urlConstants.verifyUserEmail);
