import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import Actions from './Actions';

export const ActionsRoute: IRoute = {
  path: '/actions',
  component: Actions,
  layout: DashboardLayout,
  isAdmin: false,
  exact: true,
};
