import React, { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { SuccessPage } from 'components';
import { MailImage } from 'assets/index';
const useStyles = makeStyles((theme) => ({
  successHeaderTxt: {
    fontSize: 20,
    color: '#262A41',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
  },
  successSubTxt: {
    fontSize: 15,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    marginTop: 15,
  },
}));

const SignUpSuccess: FC = () => {
  const classes = useStyles();

  return (
    <SuccessPage img={MailImage}>
      <Typography
        gutterBottom
        variant="h4"
        className={classes.successHeaderTxt}
      >
        Your Signup is Successful!
      </Typography>
      <Typography variant="body1" className={classes.successSubTxt}>
        Please check your mail and click on the verification link.
      </Typography>
    </SuccessPage>
  );
};

export default SignUpSuccess;
