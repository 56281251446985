import React, { useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { get, isEmpty } from 'lodash';

import { Box, Dialog, Loader } from 'components';
import { pdfIcon, documentIcon } from 'assets';

import styles from './styles';

type Props = {
  investorId: string;
  documentLoading: boolean;
  vcFirmDocuments: any;
  documentErrorMessage: string;
};

const VcFirmDocument = ({
  documentLoading,
  vcFirmDocuments,
  documentErrorMessage,
}: Props) => {
  const classes = styles();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [document, setDocument] = useState<any>('');

  const iconProps = useMemo(() => {
    return {
      className: classes.image,
    };
  }, []);

  const handleRenderFile = (ext: string, doc: any) => {
    switch (ext) {
      case 'img':
        return (
          <>
            <img
              src={doc.documentUrl}
              alt={doc.filename}
              className={classes.bGDocuImg}
              onClick={() => {
                setOpen(true);
                setUrl(doc.documentUrl);
                setDocument(doc);
              }}
            />
            {doc.documentType === 'DOCU_SIGN_DOCUMENTS' ? (
              <Typography className={classes.reviewDocuText}>
                {doc.filename}
              </Typography>
            ) : (
              <Typography className={classes.reviewDocuText}>
                {doc.documentName}
              </Typography>
            )}
          </>
        );
      case 'doc':
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <img
                src={documentIcon}
                alt={documentIcon}
                className={classes.bGDocuImg}
              />
            </a>
            {doc.documentType === 'DOCU_SIGN_DOCUMENTS' ? (
              <Typography className={classes.reviewDocuText}>
                {doc.filename}
              </Typography>
            ) : (
              <Typography className={classes.reviewDocuText}>
                {doc.documentName}
              </Typography>
            )}
          </>
        );
      case 'pdf':
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <img src={pdfIcon} alt={pdfIcon} className={classes.bGDocuImg} />
            </a>
            {doc.documentType === 'DOCU_SIGN_DOCUMENTS' || !doc.documentType ? (
              <Typography className={classes.reviewDocuText}>
                {doc.filename}
              </Typography>
            ) : (
              <Typography className={classes.reviewDocuText}>
                {doc.documentName}
              </Typography>
            )}
          </>
        );
      default:
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <AttachFileIcon {...iconProps} />
            </a>
            {doc.documentType === 'DOCU_SIGN_DOCUMENTS' ? (
              <Typography className={classes.reviewDocuText}>
                {doc.filename}
              </Typography>
            ) : (
              <Typography className={classes.reviewDocuText}>
                {doc.documentName}
              </Typography>
            )}
          </>
        );
    }
  };

  const handleLoadFile = (doc: any) => {
    const filename = doc.filename;
    let ext = filename && filename.substr(filename.lastIndexOf('.') + 1);
    if (['png', 'jpg', 'jpeg'].includes(ext)) {
      ext = 'img';
    }
    if (['doc', 'docx'].includes(ext)) {
      ext = 'doc';
    }
    return handleRenderFile(ext, doc);
  };

  const LoadDocuments = () => {
    return (
      <Box className={classes.firmVCDocumentBox}>
        {documentErrorMessage && (
          <Typography variant="caption" className={classes.errorText}>
            {documentErrorMessage}
          </Typography>
        )}
        {isEmpty(vcFirmDocuments) && !documentLoading && (
          <Typography className={classes.didntRequestText}>
            No Document Found
          </Typography>
        )}
        {documentLoading && (
          <Box className={classes.loader}>
            <Loader />
          </Box>
        )}
        <Box className={classes.docuContainer}>
          {vcFirmDocuments &&
            vcFirmDocuments.map((doc: any) => {
              return (
                <Box key={doc.id}>
                  <Box key={doc.id}>{handleLoadFile(doc)}</Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {LoadDocuments()}
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        subheading={''}
        title={get(document, 'documentName', '')}
        handleClose={() => {
          setOpen(false);
          setUrl('');
          setDocument('');
        }}
      >
        <img
          src={url}
          alt={get(document, 'filename')}
          className={classes.previewImg}
        />
      </Dialog>
    </>
  );
};

export default VcFirmDocument;
