import React, { useState } from 'react';
import Upload from 'rc-upload';
import { get, isEmpty } from 'lodash';
import { Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import { Box, Dialog, AlertDialog } from 'components';
import { IconImg, documentIcon, pdfIcon } from 'assets';

import styles from './styles';

const FileUpload = ({
  content,
  getSignedUrl,
  requestObject,
  onSuccess,
  disabled,
  uploadedFiles,
  setLoadingSignedUrl,
  uploadOnSelect,
  callGetSignedUrl = true,
  showSelectedFilePreview = true,
  fileExtensions = [],
}: any) => {
  const classes = styles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState<string | undefined>(
    '',
  );
  const [invalidFormatDialog, setInvalidFormatDialog] = useState<boolean>(
    false,
  );
  const [isOpen, setOpen] = useState<boolean>(false);
  const [src, setSrc] = useState<string | undefined>('');
  const [imageData, setImageData] = useState<any>(null);

  const props = {
    multiple: false,

    async beforeUpload(file: any) {
      try {
        const fileExt = file.name.split('.').pop();
        if (!isEmpty(fileExtensions)) {
          if (!fileExtensions.includes(fileExt.toLowerCase())) {
            setInvalidFormatDialog(true);
            return;
          }
        }

        if (!callGetSignedUrl) {
          onSuccess({
            filename: file.name,
            file,
            documentName: get(requestObject, 'documentName'),
          });
          if (showSelectedFilePreview) {
            setSelectedFile(file);
            setSelectedFileType(handleGetFileFormat(file.name));
          }
          return;
        }

        setLoadingSignedUrl(true);
        const url = await getSignedUrl({
          fileName: file.name,
          ...requestObject,
        });
        setSelectedFile(file);
        setSelectedFileType(handleGetFileFormat(file.name));
        if (uploadOnSelect) {
          await uploadOnSelect({ url, filename: file.name, file });
          setSelectedFile(null);
          setSelectedFileType('');
        } else {
          onSuccess({ url, filename: file.name, file });
          if (!showSelectedFilePreview) {
            setSelectedFile(null);
            setSelectedFileType('');
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoadingSignedUrl(false);
      }
      return Promise.reject();
    },
  };
  const handleGetFileFormat = (filename: string) => {
    let fileExtension = '';
    if (filename) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fileExtension = filename.split('.').pop();
      if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
        fileExtension = 'img';
      } else if (['doc', 'docx'].includes(fileExtension)) {
        fileExtension = 'doc';
      } else if (['pdf'].includes(fileExtension)) {
        fileExtension = 'pdf';
      }
    }

    return fileExtension;
  };

  const handlePreviewIcon = (fileType: any, data: any) => {
    if (fileType) {
      switch (fileType) {
        case 'img':
          return (
            <img
              src={get(data, 'documentUrl')}
              alt={get(data, 'filename')}
              className={classes.bGImg}
              onClick={() => {
                setSrc(data.srcUrl || data.documentUrl || '');
                setImageData(data);
                setOpen(true);
              }}
            />
          );
        case 'doc':
          return (
            <a href={get(data, 'documentUrl')} target="_blank" rel="noreferrer">
              <img
                src={documentIcon}
                alt={documentIcon}
                className={classes.bGImg}
              />
            </a>
          );
        case 'pdf':
          return (
            <a href={get(data, 'documentUrl')} target="_blank" rel="noreferrer">
              <img src={pdfIcon} alt={pdfIcon} className={classes.bGImg} />
            </a>
          );
        default:
          return (
            <a href={get(data, 'documentUrl')} target="_blank" rel="noreferrer">
              <img
                src={documentIcon}
                alt={documentIcon}
                className={classes.bGImg}
              />
            </a>
          );
      }
    }
  };

  return (
    <>
      <div>
        <Upload disabled={disabled} style={{ display: 'block' }} {...props}>
          {content}
        </Upload>
        {!isEmpty(uploadedFiles) &&
          uploadedFiles.map((item: any) => (
            <Box key={item.id} className={classes.upLoadBox}>
              <Box className={classes.upLoadleft}>
                <img
                  src={IconImg}
                  alt={IconImg}
                  className={classes.circleImg}
                />
                {handlePreviewIcon(
                  handleGetFileFormat(get(item, 'filename')),
                  item,
                )}
                <Typography className={classes.uploadFileName}>
                  {get(item, 'filename')}
                </Typography>
              </Box>
            </Box>
          ))}
        {selectedFile && (
          <Box className={classes.upLoadBox}>
            <Box className={classes.upLoadleft}>
              <img src={IconImg} alt={IconImg} className={classes.circleImg} />
              {(selectedFileType === 'img' || isEmpty(selectedFileType)) && (
                <img
                  src={documentIcon}
                  alt={documentIcon}
                  className={classes.previewImgNotUploaded}
                />
              )}
              {selectedFileType === 'doc' && (
                <img
                  src={documentIcon}
                  alt={documentIcon}
                  className={classes.previewImgNotUploaded}
                />
              )}
              {selectedFileType === 'pdf' && (
                <img
                  src={pdfIcon}
                  alt={pdfIcon}
                  className={classes.previewImgNotUploaded}
                />
              )}
              <Typography className={classes.uploadFileName}>
                {get(selectedFile, 'name')}
              </Typography>
            </Box>
            <CancelIcon
              className={classes.crossImg}
              onClick={() => {
                setSelectedFile(null);
                setSelectedFileType('');
                onSuccess(null);
              }}
            />
            {/* <img
              src={CrossIcon}
              alt={CrossIcon}
              className={classes.crossImg}
              onClick={() => {
                setSelectedFile(null);
                setSelectedFileType('');
                onSuccess(null);
              }}
            /> */}
          </Box>
        )}
      </div>
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        subheading={''}
        title={get(imageData, 'filename', '')}
        handleClose={() => {
          setOpen(false);
          setSrc('');
          setImageData(null);
        }}
      >
        <img
          src={src}
          alt={get(imageData, 'filename')}
          className={classes.previewImg}
        />
      </Dialog>

      <AlertDialog
        dialogOpen={invalidFormatDialog}
        onClose={() => setInvalidFormatDialog(false)}
        dialogHeading={'Invalid file extension'}
        dialogContent={
          <>
            <div>Allowed file format are:</div>
            <div>{fileExtensions.join(', ')}</div>
          </>
        }
      />
    </>
  );
};

export default FileUpload;
