import React, { useState, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { Box, Loader, Dialog } from 'components';
import { pdfIcon, documentIcon } from 'assets';

import styles from './styles';

const VcFundLegalRegulatory = ({
  fundData,
  documentLoading,
  vcFundDocuments,
}: any) => {
  const classes = styles();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [document, setDocument] = useState<any>('');

  const iconProps = useMemo(() => {
    return {
      className: classes.image,
    };
  }, []);

  const handleRenderFile = (ext: string, doc: any) => {
    switch (ext) {
      case 'img':
        return (
          <>
            <img
              src={doc.documentUrl}
              alt={doc.filename}
              className={classes.bGDocuImg}
              onClick={() => {
                setOpen(true);
                setUrl(doc.documentUrl);
                setDocument(doc);
              }}
            />

            <Typography className={classes.reviewDocuText}>
              {doc.filename}
            </Typography>
          </>
        );
      case 'doc':
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <img
                src={documentIcon}
                alt={documentIcon}
                className={classes.bGDocuImg}
              />
            </a>

            <Typography className={classes.reviewDocuText}>
              {doc.filename}
            </Typography>
          </>
        );
      case 'pdf':
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <img src={pdfIcon} alt={pdfIcon} className={classes.bGDocuImg} />
            </a>

            <Typography className={classes.reviewDocuText}>
              {doc.filename}
            </Typography>
          </>
        );
      default:
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <AttachFileIcon {...iconProps} />
            </a>
            <Typography className={classes.reviewDocuText}>
              {doc.filename}
            </Typography>
          </>
        );
    }
  };

  const handleLoadFile = (doc: any) => {
    const filename = doc.filename;
    let ext = filename && filename.substr(filename.lastIndexOf('.') + 1);
    if (['png', 'jpg', 'jpeg'].includes(ext)) {
      ext = 'img';
    }
    if (['doc', 'docx'].includes(ext)) {
      ext = 'doc';
    }
    return handleRenderFile(ext, doc);
  };

  return (
    <>
      <Box className={classes.legalRegContainer}>
        <Box className={classes.legalNameContainer}>
          <Typography className={classes.legalNameHeader}>
            Entity Legal Name : {get(fundData, 'entityLegalName')}
          </Typography>
          <Typography className={classes.subscriptioDocTextHeader}>
            A formal agreement that defines the terms of investment
          </Typography>
          {/* =========--------- Document List ----------======== */}
          {documentLoading ? (
            <Box className={classes.loader}>
              <Loader />
            </Box>
          ) : (
            <Box className={classes.MarketingDocumentLists}>
              {!isEmpty(vcFundDocuments) &&
                vcFundDocuments
                  .filter(
                    (item: any) => item.documentName === 'SUBSCRIPTION_DOC',
                  )
                  .map((doc: any) => {
                    return <Box key={doc.id}>{handleLoadFile(doc)}</Box>;
                  })}
            </Box>
          )}
          {/* =========--------- Document List End ----------======== */}
        </Box>
      </Box>
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        subheading={''}
        title={get(document, 'documentName', '')}
        handleClose={() => {
          setOpen(false);
          setUrl('');
          setDocument('');
        }}
      >
        <img
          src={url}
          alt={get(document, 'filename')}
          className={classes.previewImg}
        />
      </Dialog>
    </>
  );
};

export default VcFundLegalRegulatory;
