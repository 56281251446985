import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { isEmpty, get, find, orderBy } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { EditOutlined } from '@material-ui/icons';

import {
  Box,
  Button,
  Dialog,
  ConfirmDialog,
  StyledTableCell,
  StyledTableRow,
} from 'components';
import {
  adminAddInvestmentNavAttr,
  adminGetInvestmentNavAttrList,
  sendInvestorsNavReportEmail,
} from 'services';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { RootState } from 'redux-modules/store/rootState';

import styles from './styles';
import { navAttributeSchema } from './validations';
import AddInvestmentNavAttr from './AddInvestmentNavAttr';

const InvestmentNavAttr = () => {
  const classes = styles();

  const { handleSubmit, control, getValues, setValue, clearErrors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(navAttributeSchema),
    defaultValues: {
      spv: '',
      date: moment().format(),
      realisedGain: 0,
      unRealisedGain: 0,
      expenses: 0,
      managementFee: 0,
      distribution: 0,
    },
  });

  const { dealList } = useSelector(({ Deals }: RootState) => Deals);

  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGetLoading, setIsGetLoading] = useState<boolean>(false);
  const [isMailLoading, setIsMailLoading] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [navAttrList, setNavAttrList] = useState<any>([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedNavUpdate, setSelectedNavUpdate] = useState(null);

  const setFormValue = (rowData: any) => {
    if (!isEmpty(rowData)) {
      setValue('date', get(rowData, 'date'));
      setValue('spv', get(rowData, 'spv'));
      setValue('realisedGain', get(rowData, 'realisedGain'));
      setValue('expenses', get(rowData, 'expenses'));
      setValue('unRealisedGain', get(rowData, 'unRealisedGain'));
      setValue('managementFee', get(rowData, 'managementFee'));
      setValue('distribution', get(rowData, 'distribution'));
    } else {
      setValue('date', moment().format());
      setValue('spv', '');
      setValue('realisedGain', 0);
      setValue('expenses', 0);
      setValue('unRealisedGain', 0);
      setValue('managementFee', 0);
      setValue('distribution', 0);
    }
    clearErrors();
  };

  const handleEditOpen = (rowData: any) => {
    setEditDialogOpen(true);
    setFormValue(rowData);
    setSelectedNavUpdate(get(rowData, 'id'));
  };

  const handleEditClose = () => {
    dialogOpen && setDialogOpen(false);
    editDialogOpen && setEditDialogOpen(false);
    setFormValue({});
    setSelectedNavUpdate(null);
  };

  const spvList = dealList.map((deal: any) => {
    return {
      text: get(deal, 'deal'),
      value: get(deal, 'id'),
    };
  });

  const onSubmit = (isEditMode = false) => {
    setErrorMessage('');
    if (!isEditMode) {
      const payload: any = {
        ...getValues(),
        id: selectedNavUpdate,
      };
      setIsLoading(true);
      adminAddInvestmentNavAttr(payload)
        .then((res: any) => {
          // eslint-disable-next-line no-console
          console.log('res---', res);
          setIsLoading(false);
          setSuccessMessage('Saved successfully');
          getNavAttrList();
          handleEditClose();
          setTimeout(() => setSuccessMessage(''), 3500);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
          setIsLoading(false);
        });
    } else {
      setDialogOpen(true);
    }
  };

  const getNavAttrList = () => {
    setIsGetLoading(true);
    setErrorMessage('');
    adminGetInvestmentNavAttrList()
      .then((res: any) => {
        setNavAttrList(orderBy(res, ['createdAt'], ['desc']));
        setIsGetLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsGetLoading(false);
      });
  };

  const sendAllInvestorsNavReportEmail = (payload: any, rowId: string) => {
    setIsMailLoading(rowId);
    setErrorMessage('');
    sendInvestorsNavReportEmail(payload)
      .then(() => {
        setTimeout(() => {
          getNavAttrList();
          setIsMailLoading('');
        }, 3000);
        setSuccessMessage('Mail to investors sent successfully');
        setTimeout(() => setSuccessMessage(''), 3500);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsMailLoading('');
        setTimeout(() => setErrorMessage(''), 10000);
      });
  };

  useEffect(() => {
    getNavAttrList();
  }, []);

  return (
    <Box>
      <div>
        {errorMessage && (
          <Typography variant="caption" className={classes.errorText}>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="caption" className={classes.successText}>
            {successMessage}
          </Typography>
        )}
        <AddInvestmentNavAttr
          spvList={spvList}
          control={control}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isLoading={isLoading}
          editMode={false}
          handleEditClose={handleEditClose}
        />
      </div>
      {isGetLoading ? (
        <Box className={classes.loader}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        !isEmpty(navAttrList) && (
          <>
            <Box className={classes.listingBox}>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <Table stickyHeader aria-label="customized table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <StyledTableCell
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        Date
                      </StyledTableCell>
                      <StyledTableCell>SPV</StyledTableCell>
                      <StyledTableCell>Realised Gain</StyledTableCell>
                      <StyledTableCell>Unrealised Gain</StyledTableCell>
                      <StyledTableCell>Expenses</StyledTableCell>
                      <StyledTableCell>Management Fee</StyledTableCell>
                      <StyledTableCell>Distribution</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {navAttrList.map((row: any) => (
                      <StyledTableRow hover key={row.id}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ textAlign: 'left' }}
                        >
                          {moment(row.date).format('DD, MMM YYYY')}
                        </StyledTableCell>
                        <StyledTableCell>
                          {get(find(spvList, { value: row.spv }), 'text') ||
                            row.spv}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatAmount(row.realisedGain)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatAmount(row.unRealisedGain)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatAmount(row.expenses)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatAmount(row.managementFee)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatAmount(row.distribution)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box className={classes.editTableCell}>
                            <EditOutlined
                              className={classes.editIcon}
                              onClick={() => {
                                handleEditOpen(row);
                              }}
                            />
                            <Box>
                              <Button
                                className={classes.sendBtn}
                                name="Send Email"
                                size="small"
                                disabled={!isEmpty(isMailLoading)}
                                isLoading={isMailLoading === row.id}
                                onClick={() => {
                                  sendAllInvestorsNavReportEmail(
                                    {
                                      spv: get(row, 'spv'),
                                      date: moment(get(row, 'date')).format(
                                        'YYYY-MM-DD',
                                      ),
                                    },
                                    row.id,
                                  );
                                }}
                              />
                              {!isEmpty(get(row, 'sentEmailAt')) && (
                                <Typography
                                  variant="body2"
                                  style={{ fontSize: '0.50rem' }}
                                >
                                  Last Sent -{' '}
                                  {moment(row.sentEmailAt).format('DD-MM-YYYY')}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )
      )}
      <Dialog
        open={editDialogOpen}
        handleClose={handleEditClose}
        title={'Investment'}
      >
        <AddInvestmentNavAttr
          spvList={spvList}
          control={control}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isLoading={isLoading}
          editMode={true}
          handleEditClose={handleEditClose}
        />
      </Dialog>
      <ConfirmDialog
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
        dialogHeading="Investment"
        dialogContent="Are you sure you want to save the following changes?"
        isLoading={isLoading}
        onConfirm={() => onSubmit(false)}
      />
    </Box>
  );
};

export default InvestmentNavAttr;
