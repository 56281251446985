import React from 'react';

import { Box } from 'components';
import { FN } from 'common/types';

import Extension from './LoanCloserForms/ExtensionForm';
import Conversion from './LoanCloserForms/ConversionForm';
import Closed from './LoanCloserForms/ClosedForm';

type Props = {
  handleClose: FN;
  type: string;
  startupId: string | undefined;
};

const LoanCloser = ({ handleClose, type, startupId }: Props) => {
  return (
    <Box>
      {type.toUpperCase() === 'EXTENSION' && (
        <Extension handleClose={handleClose} startupId={startupId} />
      )}
      {type.toUpperCase() === 'CONVERSION' && (
        <Conversion handleClose={handleClose} startupId={startupId} />
      )}
      {type.toUpperCase() === 'CLOSED' && (
        <Closed handleClose={handleClose} startupId={startupId} />
      )}
    </Box>
  );
};

export default LoanCloser;
