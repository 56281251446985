import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import ContactUs from './ContactUs';

export const ContactUsRoute: IRoute = {
  path: '/startup/contact-us',
  component: ContactUs,
  layout: DashboardLayout,
  exact: true,
};
