import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { Button, Box, WrappedTextInput, Banner } from 'components';
import { RootState } from 'redux-modules/store/rootState';
import { VDApproveType } from 'redux-modules/process/Types';
import { trackVdProcess } from 'redux-modules/enquiries/Actions';
import { getVdApproveLoanValues } from 'redux-modules/global/Actions';
import { FN } from 'common/types';
import { handleApproveVDLoan } from 'redux-modules/process/Actions';

import styles from '../../styles';
import { approveVDLoanSchema } from './validations';

type Props = {
  handleBack: FN;
  startupId: string | undefined;
};
const ApproveLoan = ({ handleBack, startupId }: Props) => {
  const { handleSubmit, control, reset, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(approveVDLoanSchema),
  });
  const classes = styles();
  const dispatch = useDispatch();
  const [successPop, setSuccessPop] = useState<boolean>(false);
  const { approveVdLoan } = useSelector(({ Process }: RootState) => Process);
  const vdApproveLoanValues = useSelector(
    ({ global }: RootState) => global.vdApproveLoanValues,
  );
  const { data } = vdApproveLoanValues;

  const { isLoading, successMessage, errorMessage } = approveVdLoan;
  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  const isRoleAdmin = role === 'ADMIN';
  const { vdProcess } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const isLoanToVdApproved = get(vdProcess, 'isLoanToVdApproved', false);
  const onSubmit = (data: VDApproveType) => {
    dispatch(
      handleApproveVDLoan({
        startupId,
        ...data,
      }),
    );
  };

  useEffect(() => {
    dispatch(getVdApproveLoanValues(startupId));
  }, []);

  useEffect(() => {
    if (!isEmpty(successMessage)) {
      dispatch(trackVdProcess(startupId));
      setSuccessPop(true);
    }
  }, [successMessage]);

  useEffect(() => {
    reset({
      ...getValues(),
      termsAccepted: true,
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(vdApproveLoanValues.successMessage)) {
      reset(data);
    }
  }, [vdApproveLoanValues.successMessage]);

  // const getInputFieldValues = (inputField: string) => {
  //   if (!isEmpty(loansDetails)) {
  //     return loansDetails[inputField];
  //   }
  // };

  // const handleDisableFields = () => {
  //   if (!isRoleAdmin || isLoanToVdApproved) {
  //     return true;
  //   }

  //   return false;
  // };

  return (
    <div>
      {/* {successMessage && <Typography>{successMessage}</Typography>} */}
      {errorMessage && <Typography>{errorMessage}</Typography>}
      {successPop ? (
        <Banner title={successMessage} subTitle={''} source={'SuccessBanner'} />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} data-testid="ApproveLoanForm">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Controller
                  name="amountConvertedToVd"
                  defaultValue=""
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label="Amount Converted to VD"
                        placeholder="Requested Amount"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Controller
                  name="bdLoanRepaidAmount"
                  defaultValue=""
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label="Bridge Loan repaid Amount"
                        placeholder="Processing Amount"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Controller
                  name="vdLoanTenureInMonths"
                  control={control}
                  defaultValue="18"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label="Loan Tenure"
                        placeholder="Processing Amount"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Controller
                  name="vdInterestRate"
                  control={control}
                  defaultValue="14"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label="Interest Rate"
                        placeholder="Processing Amount"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Controller
                  name="vdInstallmentsCount"
                  control={control}
                  defaultValue="6"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label="No. of Installments"
                        placeholder="Processing Amount"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Controller
                  name="vdQuarterlyInstallmentAmount"
                  defaultValue=""
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label="Quaterly Installment Amount"
                        placeholder="Processing Amount"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
            </Grid>
            {/* <Grid>
              <Controller
                name="vdTermsAccepted"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Checkbox
                      checked={getInputFieldValues('vdTermsAccepted') || value}
                      onChange={onChange}
                      disabled={handleDisableFields()}
                    />
                    <span>Terms and conditions</span>
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
                rules={{ required: 'This is required' }}
              />
            </Grid> */}
            <Box className={classes.backContinueBox}>
              <Button
                name="Approve"
                type="submit"
                isLoading={isLoading}
                className={classes.continueBtn}
                onClick={() => onSubmit(data)}
                disabled={!isRoleAdmin || isLoanToVdApproved}
              />
              <Button
                onClick={() => handleBack()}
                className={classes.backBtn}
                name="Back"
              />
            </Box>
          </form>
        </>
      )}
    </div>
  );
};

export default ApproveLoan;
