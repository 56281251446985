export const ADMIN_GET_ALL_INVESTORS = 'admin/ADMIN_GET_ALL_INVESTORS';
export const ADMIN_GET_ALL_INVESTORS_SUCCESS =
  'admin/ADMIN_GET_ALL_INVESTORS_SUCCESS';
export const ADMIN_GET_ALL_INVESTORS_FAILURE =
  'admin/ADMIN_GET_ALL_INVESTORS_FAILURE';
export const ADMIN_GET_ALL_INVESTORS_LOADING =
  'admin/ADMIN_GET_ALL_INVESTORS_LOADING';

export const handleGetAllInvestors = (payload: any) => {
  return {
    type: ADMIN_GET_ALL_INVESTORS,
    payload,
  };
};
