import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  FormControl,
  Grid,
  IconButton,
  InputBase,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import { isEmpty, get, find, groupBy, orderBy } from 'lodash';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';

import {
  Box,
  WrappedSelect,
  Button,
  Dialog,
  Tabs,
  TabPanel,
  WrappedAutocomplete,
} from 'components';
import { RootState } from 'redux-modules/store/rootState';
import { Ornament } from 'assets';
import {
  businessOpsCountryList,
  stageList,
  industryTypeList,
} from 'common/utils/optionList';
import {
  getAdminAllNonPrimaryStartupsGrowth,
  getAdminAllStartupPercentile,
  getAdminPercentileGrowth,
  getAdminPreviewPercentileGrowthRates,
  getPublishedPercentileGrowth,
  publishBenchmarkingGrowthRates,
  adminExportStartups,
  getClientsList,
  getPublishedDates,
} from 'services';
import { getBatches } from 'redux-modules/auth/Actions';
import { errorMessageHandler } from 'common/utils/helpers';
import { PERCENTILE_GROWTH } from 'common/utils/constants';

import styles from './styles';
import BenchMarkStartupInfo from './BenchMarkStartupInfo';
import CsvUpload from './CsvUpload';
import StartupsGrowthRateList from './StartupsGrowthRateList';
import ValuationInsightPreview from './ValuationInsightPreview';

const BenchMarkDashboardAdmin = () => {
  const classes = styles();
  const dispatch = useDispatch();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

  const { ycBatches } = useSelector(({ Auth }: RootState) => Auth);

  const [tabValue, setTabValue] = useState<number>(0);
  const tabs = useMemo(() => {
    const tabsList = [
      { name: 'Primary', key: 'PRIMARY' },
      { name: 'Non Primary', key: 'NON PRIMARY' },
    ];

    return tabsList;
  }, []);

  const [filterObj, setFilterObj] = useState<any>({
    industryType: 'ALL',
    fundingStage: 'ALL',
    ycBatch: 'ALL',
    businessOpsLocation: 'ALL',
  });
  const [ycBatchesList, setYcBatchesList] = React.useState<any>([]);
  const [businessOpsList, setBusinessOpsList] = React.useState<any>([]);
  const [industryList, setIndustryList] = React.useState<any>([]);
  const [fundingStageList, setFundingStageList] = React.useState<any>([]);
  const [openAddStartupModal, setOpenAddStartupModal] = React.useState<boolean>(
    false,
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [openCsvUpload, setOpenCsvUpload] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [pageNonPrimary, setPageNonPrimary] = React.useState(1);
  const [filterLoading, setFilterLoading] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [exportLoading, setExportLoading] = React.useState<boolean>(false);
  const [loadingPublish, setLoadingPublish] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [previewErrorMessage, setPreviewErrorMessage] = React.useState<string>(
    '',
  );
  const [selectedStartup, setSelectedStartup] = React.useState<string>('');
  const [percentileGrowth, setPercentileGrowth] = React.useState<any>([]);
  const [startupGrowthRateList, setStartupGrowthRateList] = React.useState<any>(
    [],
  );
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);
  const [previewPercentileGrowth, setPreviewPercentileGrowth] = React.useState(
    [],
  );
  // const [
  //   publishedPercentileData,
  //   setPublishedPercentileData,
  // ] = React.useState<any>([]);
  const [publishedDateList, setPublishedDateList] = React.useState<any[]>([]);
  const [publishedDate, setPublishedDate] = React.useState<string>('');
  const [previewValuation, setPreviewValuation] = React.useState(false);
  const [
    nonPrimaryGrowthRateList,
    setNonPrimaryGrowthRateList,
  ] = React.useState<any>([]);
  const [
    filteredNonPrimaryGrowth,
    setFilteredNonPrimaryGrowth,
  ] = React.useState<any>([]);
  const [chartGrowthData, setChartGrowthData] = React.useState<any>({
    labels: [],
    datasets: [
      {
        label: 'Growth',
        backgroundColor: '#1EA59A',
        borderColor: '1px solid #FFFFFF',
        borderWidth: 0,
        color: '#ffffff',
        data: [],
      },
    ],
  });
  const [clientsList, setClientsList] = React.useState<any>([]);
  const [selectedClient, setSelectedClient] = React.useState<any>({});

  const rowsPerPage = 10;
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Proportion of startups',
          font: {
            weight: '400',
            size: 14,
          },
          color: '#000000',
        },
        min: 0,
        max: 100,
        ticks: {
          stepSize: 20,
        },
      },
      x: {
        title: {
          display: true,
          text: 'Growth Rates',
          font: {
            weight: '400',
            size: 14,
          },
          color: '#000000',
        },
        grid: {
          offset: true,
        },
      },
    },
    barThickness: 37,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        text: 'Growth',
        align: 'end' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
        title: {
          font: {
            weight: '400',
            size: 12,
          },
        },
      },
    },
  };

  const getAdminBenchmarkKpiAllData = () => {
    setIsLoading(true);
    setFilterLoading(true);
    setErrorMessage('');
    setPublishedDate('');
    setFilterObj({
      industryType: 'ALL',
      fundingStage: 'ALL',
      ycBatch: 'ALL',
      businessOpsLocation: 'ALL',
    });
    Promise.all([getAdminPercentileGrowth({}), getAdminAllStartupPercentile()])
      .then(([percentileGrowthData, startupPercentileData]: any) => {
        startupPercentileData = startupPercentileData.map((startup: any) => {
          startup.latestKpiGrowthRate = Number(startup.latestKpiGrowthRate);
          return startup;
        });
        startupPercentileData = orderBy(
          startupPercentileData,
          ['published'],
          ['asc'],
        );
        setPercentileGrowth(percentileGrowthData);
        setStartupGrowthRateList(startupPercentileData);
        setFilteredSearchData(startupPercentileData);
        setPage(1);
        setFilterLoading(false);
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
        setFilterLoading(false);
      });
  };

  const getNonPrimaryBenchmarkData = () => {
    getAdminAllNonPrimaryStartupsGrowth()
      .then((res: any) => {
        setNonPrimaryGrowthRateList(res);
        setFilteredNonPrimaryGrowth(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.log('ERROR: NON PRIMARY BENCHMARK DATA: ', message);
      });
  };

  const getPublishedDatesData = () => {
    getPublishedDates()
      .then((res: any) => {
        setPublishedDateList(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.log('ERROR: PUBLISHED DATES DATA: ', message);
      });
  };

  // const getPublishedBenchmarkData = (publishDate: any) => {
  //   getPublishedPercentileGrowth({
  //     publishDate,
  //   })
  //     .then((res: any) => {
  //       setPublishedPercentileData(res);
  //       return res;
  //     })
  //     .catch((err: any) => {
  //       const message: string = errorMessageHandler(err);
  //       // eslint-disable-next-line no-console
  //       console.log('ERROR: PUBLISHED PERCENTILE DATA: ', message);
  //       return [];
  //     });
  // };

  const getClientsListData = () => {
    getClientsList()
      .then((res: any) => {
        setClientsList(res?.data);
      })
      .catch(() => {
        setClientsList([]);
      });
  };

  const openPreviewModel = () => {
    setOpen(true);
    growthRateData();
    setPreviewErrorMessage('');
    getAdminPreviewPercentileGrowthRates()
      .then((res: any) => {
        setPreviewPercentileGrowth(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setPreviewErrorMessage(message);
        // eslint-disable-next-line no-console
        console.log('ERROR PREVIEW PERCENTILE: ', message);
      });
  };

  const publishStartupGrowthRates = () => {
    setLoadingPublish(true);
    setErrorMessage('');
    publishBenchmarkingGrowthRates({})
      .then(() => {
        setLoadingPublish(false);
        getAdminBenchmarkKpiAllData();
        // getPublishedBenchmarkData();
        getPublishedDatesData();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setLoadingPublish(false);
      });
  };

  const exportBenchmarkStartupData = () => {
    setExportLoading(true);
    setErrorMessage('');
    adminExportStartups()
      .then((res: any) => {
        const link = document.createElement('a');
        link.href = res.url;
        link.setAttribute('download', 'true');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setExportLoading(false);
      })
      .catch((err: any) => {
        setExportLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const filterPublishedPercentileGrowth = (startupIdList: Array<string>) => {
    setFilterLoading(true);
    setErrorMessage('');
    getAdminPercentileGrowth({
      startupIdList: startupIdList,
    })
      .then((res: any) => {
        setPercentileGrowth(res);
        setFilterLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setFilterLoading(false);
      });
  };

  const createFilterOptionsList = () => {
    if (ycBatches) {
      const groupedBatchKeys = Object.keys(
        groupBy(startupGrowthRateList, 'ycBatch'),
      );
      const updatedYcBatches = ycBatches.filter((batch: any) =>
        groupedBatchKeys.includes(batch.value),
      );
      setYcBatchesList(updatedYcBatches);
    }
    if (businessOpsCountryList) {
      const newCountryList = businessOpsCountryList.map((country: any) => {
        return {
          text: country.country,
          value: country.countryCode,
        };
      });
      const groupedCountryKeys = Object.keys(
        groupBy(startupGrowthRateList, 'businessOpsLocation'),
      );
      const updatedCountryList = newCountryList.filter((country: any) =>
        groupedCountryKeys.includes(country.value),
      );
      setBusinessOpsList(updatedCountryList);
    }
    if (stageList) {
      const groupedStageKeys = Object.keys(
        groupBy(startupGrowthRateList, 'fundingStage'),
      );
      const updatedStageList = stageList.filter((stage: any) =>
        groupedStageKeys.includes(stage.value),
      );
      setFundingStageList(updatedStageList);
    }
    if (industryTypeList) {
      const groupedKeys = Object.keys(
        groupBy(startupGrowthRateList, 'industryType'),
      );
      const updatedList = industryTypeList.filter((country: any) =>
        groupedKeys.includes(country.value),
      );
      setIndustryList(updatedList);
    }
  };

  const handleFilterChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    type: string,
  ) => {
    const obj = { ...filterObj };
    if (type === 'industryType') obj['industryType'] = e.target.value;
    else if (type === 'fundingStage') obj['fundingStage'] = e.target.value;
    else if (type === 'ycBatch') obj['ycBatch'] = e.target.value;
    else if (type === 'businessOpsLocation')
      obj['businessOpsLocation'] = e.target.value;
    setFilterObj(obj);
    const newFilterObj = Object.fromEntries(
      Object.entries(obj).filter((entry: any) => entry[1] !== 'ALL'),
    );
    const filteredStartup = !isEmpty(newFilterObj)
      ? startupGrowthRateList.filter((startup: any) => {
          let trueCount = 0;
          Object.entries(newFilterObj).map(([key, value]: any) => {
            if (key === 'ycBatch' && value === 'NONE') {
              if (
                startup['accelerator'] === value &&
                (isEmpty(startup[key]) || startup[key] === 'NA')
              )
                trueCount++;
            } else if (key === 'ycBatch' && value === 'OTHER') {
              if (
                startup['accelerator'] !== 'NONE' &&
                startup['accelerator'] !== 'YC' &&
                (isEmpty(startup[key]) || startup[key] === 'NA')
              )
                trueCount++;
            } else if (startup[key] === value) trueCount++;
          });
          return trueCount === Object.keys(newFilterObj).length;
        })
      : [];
    filterPublishedPercentileGrowth(
      filteredStartup.map((startup: any) => startup.startupId),
    );
  };

  const resetFilter = () => {
    setFilterObj({
      industryType: 'ALL',
      fundingStage: 'ALL',
      ycBatch: 'ALL',
      businessOpsLocation: 'ALL',
    });
    filterPublishedPercentileGrowth([]);
  };

  const onSearchChange = (searchString: any) => {
    const filteredData = startupGrowthRateList.filter((value: any) => {
      if (
        searchString === '' ||
        value.startupFullName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
    setPage(1);
  };

  const onNonPrimarySearchChange = (searchString: any) => {
    const filteredData = nonPrimaryGrowthRateList.filter((value: any) => {
      if (
        searchString === '' ||
        value.startupFullName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredNonPrimaryGrowth(filteredData);
    setPageNonPrimary(1);
  };

  const handleChangePage = (event: any, newPage: number) => {
    tabValue === 0 ? setPage(newPage) : setPageNonPrimary(newPage);
  };

  const handleTabChange = (event: any, newValue: number) => {
    setTabValue(newValue);
    newValue === 0 ? onSearchChange('') : onNonPrimarySearchChange('');
  };

  const handlePublishedDateChange = (event: any) => {
    const selectedDate = event.target.value as string;
    setPublishedDate(selectedDate);
    if (!isEmpty(selectedDate)) {
      // const filteredPublishedStartup = publishedPercentileData.filter(
      //   (startup: any) => {
      //     return moment(startup.publishDate).isSame(selectedDate);
      //   },
      // );
      // setFilteredSearchData(filteredPublishedStartup);
      setIsLoading(true);
      getPublishedPercentileGrowth({
        publishDate: selectedDate,
      })
        .then((res: any) => {
          setFilteredSearchData(res);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.log('ERROR: PUBLISHED PERCENTILE DATA: ', message);
          setFilteredSearchData([]);
        })
        .finally(() => setIsLoading(false));
    } else setFilteredSearchData(startupGrowthRateList);
    setPage(1);
  };

  const handleClientChange = (data: any) => {
    setSelectedClient(data);
    if (!isEmpty(data)) {
      const filteredStartup = startupGrowthRateList.filter((startup: any) => {
        return startup?.source?.id === data.value;
      });
      setFilteredSearchData(filteredStartup);
    } else setFilteredSearchData(startupGrowthRateList);
    setPage(1);
  };

  const splitArrayToInterval = (left: any, right: any, parts: any) => {
    const result = [],
      delta = (right - left) / (parts - 1);
    while (left < right) {
      result.push(parseFloat(left).toFixed(1));
      left += delta;
    }
    result.push(parseFloat(right).toFixed(1));
    return result;
  };

  const growthRateData = () => {
    const percentageRange = startupGrowthRateList.map((a: any) =>
      parseFloat(a.latestKpiGrowthRate),
    );
    let range = splitArrayToInterval(0, Math.max(...percentageRange), 25);
    range = range.slice(1);
    const formattedData: any = range.reduce((accumulator, value) => {
      return { ...accumulator, [value]: 0 };
    }, {});
    startupGrowthRateList.map((item: any) => {
      const percentile: any = parseFloat(item.latestKpiGrowthRate);
      const growthRateRange = range.reduce(
        (r, n) => (n >= percentile && r < percentile ? n : r),
        '-1',
      );
      formattedData[growthRateRange] =
        (formattedData[growthRateRange] || 0) + 1;
    });
    const chartPercentileGrowthRange = Object.values(formattedData);
    const chartData = { ...chartGrowthData };
    chartData.labels = range.map((x: any) => `${x}%`);
    chartData.datasets[0].data = chartPercentileGrowthRange;
    setChartGrowthData(chartData);
  };

  const handleAddStartupModalClose = () => {
    setSelectedStartup('');
    setOpenAddStartupModal(false);
  };

  useEffect(() => {
    if (ycBatches.length === 0) {
      dispatch(getBatches());
    }
    getAdminBenchmarkKpiAllData();
    getPublishedDatesData();
    getNonPrimaryBenchmarkData();
    // getPublishedBenchmarkData();
    getClientsListData();
  }, []);

  useEffect(() => {
    if (!isEmpty(startupGrowthRateList)) {
      createFilterOptionsList();
    }
  }, [startupGrowthRateList]);

  return (
    <>
      {!openAddStartupModal ? (
        <>
          <Box className={classes.benchMarkContainer}>
            <Box className={classes.benchmarkHead}>
              <img
                src={Ornament}
                alt={Ornament}
                className={classes.enquiryImage}
              />
              <Typography variant="h6" className={classes.headText}>
                Yardstick
              </Typography>
            </Box>
            {errorMessage && (
              <Typography
                variant="body1"
                gutterBottom
                className={classes.errorText}
              >
                {errorMessage}
              </Typography>
            )}
            <Box className={classes.benchMarkKPIContent}>
              <Box className={classes.percentileGrowthBox}>
                <Box className={classes.resetButtonBox}>
                  <Button
                    className={classes.resetButton}
                    name="Reset"
                    onClick={resetFilter}
                  />
                </Box>
                <Grid container className={classes.percentileGrowthContainer}>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item sm={3} container direction="column">
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        className={classes.filterLabelBox}
                      >
                        <Typography className={classes.filterLabelText}>
                          Industry:{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={11} sm={8} md={10}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.filterInput}
                        >
                          <WrappedSelect
                            variant={'outlined'}
                            value={get(filterObj, 'industryType')}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                              handleFilterChange(e, 'industryType')
                            }
                            className={classes.textValInputSelect}
                            InputProps={{
                              classes: { root: classes.inputRootSelect },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRootSelect,
                                focused: classes.labelFocusedSelect,
                              },
                            }}
                            dropdowns={[
                              ...industryList,
                              { text: 'ALL', value: 'ALL' },
                            ]}
                            error={false}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item sm={3} container direction="column">
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        className={classes.filterLabelBox}
                      >
                        <Typography className={classes.filterLabelText}>
                          Stage:
                        </Typography>
                      </Grid>
                      <Grid item xs={11} sm={8} md={10}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.filterInput}
                        >
                          <WrappedSelect
                            variant={'outlined'}
                            value={get(filterObj, 'fundingStage')}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                              handleFilterChange(e, 'fundingStage')
                            }
                            className={classes.textValInputSelect}
                            InputProps={{
                              classes: { root: classes.inputRootSelect },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRootSelect,
                                focused: classes.labelFocusedSelect,
                              },
                            }}
                            dropdowns={[
                              ...fundingStageList,
                              { text: 'ALL', value: 'ALL' },
                            ]}
                            error={false}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item sm={3} container direction="column">
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        className={classes.filterLabelBox}
                      >
                        <Typography className={classes.filterLabelText}>
                          YC Batch:
                        </Typography>
                      </Grid>
                      <Grid item xs={11} sm={8} md={10}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.filterInput}
                        >
                          <WrappedSelect
                            variant={'outlined'}
                            value={get(filterObj, 'ycBatch')}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                              handleFilterChange(e, 'ycBatch')
                            }
                            className={classes.textValInputSelect}
                            InputProps={{
                              classes: { root: classes.inputRootSelect },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRootSelect,
                                focused: classes.labelFocusedSelect,
                              },
                            }}
                            dropdowns={[
                              ...ycBatchesList,
                              { text: 'ALL', value: 'ALL' },
                              { text: 'Accelerator(NONE)', value: 'NONE' },
                              { text: 'Accelerator(OTHER)', value: 'OTHER' },
                            ]}
                            error={false}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item sm={3} container direction="column">
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        className={classes.filterLabelBox}
                      >
                        <Typography className={classes.filterLabelText}>
                          Geography:
                        </Typography>
                      </Grid>
                      <Grid item xs={11} sm={8} md={10}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.filterInput}
                        >
                          <WrappedSelect
                            variant={'outlined'}
                            value={get(filterObj, 'businessOpsLocation')}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                              handleFilterChange(e, 'businessOpsLocation')
                            }
                            className={classes.textValInputSelect}
                            InputProps={{
                              classes: { root: classes.inputRootSelect },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.labelRootSelect,
                                focused: classes.labelFocusedSelect,
                              },
                            }}
                            dropdowns={[
                              ...businessOpsList,
                              {
                                text: 'ALL',
                                value: 'ALL',
                              },
                            ]}
                            error={false}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    // item
                    // xs={12}
                    // container
                    // alignItems="center"
                    // justifyContent="center"
                    className={classes.percentileTableBox}
                  >
                    <table className={classes.percentileGrowthZuiTable}>
                      <thead>
                        <tr>
                          <th>Percentile</th>
                          {PERCENTILE_GROWTH.map((title: string) => (
                            <th key={title}>{title}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Growth</td>
                          {PERCENTILE_GROWTH.map((title: string) => (
                            <td key={title}>
                              {filterLoading ? (
                                <Skeleton variant="text" />
                              ) : (
                                <>
                                  {get(
                                    find(percentileGrowth, { key: title }),
                                    'value',
                                    0,
                                  )}
                                  %
                                </>
                              )}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.growthRateLabelBox}>
                <Box className={classes.addStartupBox}>
                  <Box className={classes.addStartupLeftBox}>
                    <Typography className={classes.kpiDataText}>
                      Benchmarking - Growth Rates
                    </Typography>
                  </Box>
                  <Box className={classes.addStartupRightBox}>
                    <Button
                      className={classes.uploadButton}
                      isLoading={exportLoading}
                      disabled={exportLoading}
                      onClick={() => exportBenchmarkStartupData()}
                      name="Export"
                    />
                    <Button
                      className={classes.uploadButton}
                      onClick={() => setOpenCsvUpload(true)}
                      name="Upload Startup"
                    />
                    <CsvUpload
                      openCsvUpload={openCsvUpload}
                      setOpenCsvUpload={setOpenCsvUpload}
                      getAdminBenchmarkKpiAllData={getAdminBenchmarkKpiAllData}
                    />
                    <Button
                      className={classes.addStartupButton}
                      onClick={() => {
                        setOpenAddStartupModal(true);
                      }}
                      name="Add Startup"
                    />
                  </Box>
                </Box>
              </Box>
              <Tabs
                tabs={tabs}
                value={tabValue}
                handleChange={handleTabChange}
                className={classes.tabs}
              />
              <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
                <Box className={classes.searchFilterBox}>
                  <Box className={classes.searchBox}>
                    <InputBase
                      name="search"
                      className={classes.inputSear}
                      placeholder="Search"
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={(e: any) => onSearchChange(e.target.value)}
                      disabled={!isEmpty(publishedDate)}
                    />
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search"
                      disabled={!isEmpty(publishedDate)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Box>
                  <WrappedSelect
                    variant={'outlined'}
                    value={publishedDate}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      handlePublishedDateChange(e)
                    }
                    className={classes.textValInputPublish}
                    InputProps={{
                      classes: { root: classes.inputRootPublish },
                    }}
                    InputLabelProps={{}}
                    SelectProps={{
                      MenuProps: {
                        classes: { paper: classes.menuPaper },
                      },
                    }}
                    dropdowns={[
                      { text: 'LATEST', value: '' },
                      ...(publishedDateList || []).map((date: any) => {
                        return {
                          text: moment(date).format('DD MMM YYYY'),
                          value: date,
                        };
                      }),
                    ]}
                    error={false}
                  />
                  <WrappedAutocomplete
                    variant={'outlined'}
                    id={'client-list'}
                    value={selectedClient}
                    placeholder="Client"
                    options={clientsList?.map((item: any) => ({
                      text: item.name,
                      value: item.id,
                    }))}
                    getOptionLabel={(option: any) => option.text || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.value === value
                    }
                    onChange={(_: any, data: any) => {
                      handleClientChange(data);
                    }}
                    className={classes.textValInputClient}
                    InputProps={{
                      classes: { root: classes.inputRootClient },
                    }}
                    InputLabelProps={{}}
                    noOptionsText={'No Results'}
                  />
                </Box>
                <Box className={classes.growthRateTableBox}>
                  <StartupsGrowthRateList
                    isLoading={isLoading}
                    startupsGrowthRateData={filteredSearchData}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    emptyRows={
                      rowsPerPage -
                      Math.min(
                        rowsPerPage,
                        filteredSearchData.length - (page - 1) * rowsPerPage,
                      )
                    }
                    selectedStartup={selectedStartup}
                    setSelectedStartup={setSelectedStartup}
                    setOpenAddStartupModal={setOpenAddStartupModal}
                    getAdminBenchmarkKpiAllData={getAdminBenchmarkKpiAllData}
                    showActionColumn={isEmpty(publishedDate)}
                    primaryStartups={true}
                    showNewTag={true}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
                <Box className={classes.searchFilterBox}>
                  <Box className={classes.searchBox}>
                    <InputBase
                      name="search2"
                      className={classes.inputSear}
                      placeholder="Search"
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={(e: any) =>
                        onNonPrimarySearchChange(e.target.value)
                      }
                    />
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className={classes.growthRateTableBox}>
                  <StartupsGrowthRateList
                    isLoading={isLoading}
                    startupsGrowthRateData={filteredNonPrimaryGrowth}
                    page={pageNonPrimary}
                    rowsPerPage={rowsPerPage}
                    emptyRows={
                      rowsPerPage -
                      Math.min(
                        rowsPerPage,
                        filteredNonPrimaryGrowth.length -
                          (pageNonPrimary - 1) * rowsPerPage,
                      )
                    }
                    selectedStartup={selectedStartup}
                    setSelectedStartup={setSelectedStartup}
                    setOpenAddStartupModal={setOpenAddStartupModal}
                    getAdminBenchmarkKpiAllData={getNonPrimaryBenchmarkData}
                    showActionColumn={true}
                    primaryStartups={false}
                    showNewTag={false}
                  />
                </Box>
              </TabPanel>
              <Box className={classes.growthRatePaginationBox}>
                <Grid container>
                  <Grid item xs={12} sm={5}>
                    {isEmpty(publishedDate) && (
                      <>
                        <Button
                          className={classes.previewButton}
                          onClick={() => {
                            openPreviewModel();
                          }}
                          disabled={loadingPublish}
                          name="Preview"
                        />
                        <Button
                          className={classes.publishButton}
                          onClick={publishStartupGrowthRates}
                          isLoading={loadingPublish}
                          disabled={loadingPublish}
                          name="Publish"
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={7} style={{ marginTop: 25 }}>
                    <Pagination
                      className={classes.pagination}
                      page={tabValue === 0 ? page : pageNonPrimary}
                      onChange={handleChangePage}
                      count={Math.ceil(
                        tabValue === 0
                          ? filteredSearchData.length / rowsPerPage
                          : filteredNonPrimaryGrowth.length / rowsPerPage,
                      )}
                      variant="outlined"
                      shape="rounded"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <BenchMarkStartupInfo
          handleAddStartupModalClose={handleAddStartupModalClose}
          selectedStartup={selectedStartup}
        />
      )}
      <Dialog
        open={open}
        maxWidth={'lg'}
        className={classes.previewPercentileModel}
        title={!previewValuation ? 'Benchmark Preview' : 'Valuation Insight'}
        subheading={''}
        handleClose={() => {
          setOpen(false);
          setPreviewErrorMessage('');
        }}
      >
        {!previewValuation ? (
          <Grid container>
            <Grid item xs={12} className={classes.barContainer}>
              {previewErrorMessage && (
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.errorText}
                >
                  {previewErrorMessage}
                </Typography>
              )}
              <Bar
                data={chartGrowthData}
                options={chartOptions}
                className={classes.barChart}
              />
            </Grid>
            <Grid item xs={12}>
              <table className={classes.percentileGrowthZuiTableV2}>
                <thead>
                  <tr>
                    <th>Percentile</th>
                    {previewPercentileGrowth.map(
                      (percentile: any, key: any) => (
                        <th key={key}>{percentile.key}</th>
                      ),
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Growth</td>
                    {previewPercentileGrowth.map((growth: any, key: any) => (
                      <td key={key}>{growth.value}%</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <ValuationInsightPreview />
            </Grid>
          </Grid>
        )}
        {!previewValuation ? (
          <Button
            className={classes.nextButton}
            onClick={() => setPreviewValuation(true)}
            name="NEXT"
          />
        ) : (
          <Button
            className={classes.nextButton}
            name="BACK"
            onClick={() => setPreviewValuation(false)}
          />
        )}
      </Dialog>
    </>
  );
};

export default BenchMarkDashboardAdmin;
