import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  //   Empty dashboard styling ------- Start
  welcomeBox: {
    fontSize: 20,
    color: '#262A41',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
  },
  welcomeBoxHead: {
    fontWeight: 400,
    position: 'relative',
    padding: '78px 60px 0px',
    '@media (max-width: 1120px)': {
      padding: '20px 30px',
    },
  },
  enquiryImage: {
    position: 'absolute',
    top: 40,
    left: 35,
    height: 80,
    width: 90,
    '@media (max-width: 1120px)': {
      top: 15,
      left: 15,
    },
  },
  welcomeBoxBody: {
    maxWidth: 600,
    margin: 'auto',
    textAlign: 'center',
    paddingTop: 50,
  },
  welcomeHeadtext: {
    fontSize: 28,
    color: theme.palette.warning.contrastText,
    marginBottom: 0,
    position: 'relative',
    top: -20,
  },
  welcomeHeadSubtext: {
    fontSize: 16,
    color: theme.palette.primary.main,
    lineHeight: 1.4,
  },
  welcomeImgImage: {
    marginBottom: 25,
  },
  welcomeHeadertext: {
    fontSize: 20,
    color: theme.palette.grey[500],
  },
  welcomeHeaderSubtext: {
    fontSize: 15,
    maxWidth: 400,
    margin: 'auto',
    color: theme.palette.grey[50],
  },
  //   Empty dashboard styling ------- End

  dashWelcomeBox: {
    display: 'flex',
    height: '100vh',
    '@media (max-width: 767px)': {
      display: 'block',
      height: 'auto',
    },
  },
  dashWelcomeLeft: {
    width: 'calc(100% - 300px)',
    flex: '0 auto',
    '@media (max-width: 1300px)': {
      width: 'calc(100% - 260px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  dashVcWelcomeLeft: {
    width: '100%',
  },
  dashLeftContent: {
    padding: 60,
    paddingTop: 0,
    height: 'calc(100vh - 210px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '& .MuiGrid-item': {
      padding: 14,
      '@media (max-width: 1120px)': {
        padding: 8,
      },
    },
    '@media (max-width: 1024px)': {
      padding: 30,
      paddingTop: 0,
      height: 'calc(100vh - 225px)',
    },
    '@media (max-width: 767px)': {
      padding: '0 5% 10px',
      height: '100%',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
  },
  dashLVCContent: {
    padding: '30px 50px',
    height: 'calc(100vh - 222px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '@media (max-width: 1024px)': {
      padding: '30px',
    },
    '@media (max-width: 767px)': {
      padding: '5px 5% 20px',
      height: 'calc(100vh - 197px)',
    },
  },
  dashWelcomeRight: {
    width: 300,
    background: theme.palette.warning.main,
    flexGrow: 1,
    borderTopRightRadius: 25,
    '@media (max-width: 1300px)': {
      width: 260,
    },
    '@media (max-width: 767px)': {
      width: '100%',
      borderTopRightRadius: 0,
    },
  },

  //  StartUP Dashboard Loan Process and Action Section ---------------- End
}));
