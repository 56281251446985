import { get } from 'lodash';

import { VentureDebtState } from './Types';
import {
  VENTURE_DEBT_SUBMIT_LOADING,
  VENTURE_DEBT_SUBMIT_FAILURE,
  VENTURE_DEBT_SUBMIT_SUCCESS,
} from './Actions';

export const VentureDebtInitialState: VentureDebtState = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  ventureDebtStatus: {},
};

export const VentureDebtReducer = (
  state = VentureDebtInitialState,
  action: any,
) => {
  const { type, payload } = action;
  switch (type) {
    case VENTURE_DEBT_SUBMIT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case VENTURE_DEBT_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: payload,
      };
    case VENTURE_DEBT_SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        successMessage: '',
        errorMessage: get(payload, 'message'),
      };
    default:
      return state;
  }
};
