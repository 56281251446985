import React from 'react';
import { Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { get } from 'lodash';

import { Box } from 'components';
import { ClientData } from 'common/types';
import { SUBSCRIBED_USER } from 'common/utils/constants';

import styles from './styles';
import { TransactionHistory } from '../../VcFirms/components';

type Props = {
  setTransactionView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedClientData: ClientData;
};

const TransactionInvestorView = ({
  setTransactionView,
  selectedClientData,
}: Props) => {
  const classes = styles();

  return (
    <>
      <Box className={classes.topContainer}>
        <Box className={classes.planHeadingContainer}>
          <Typography className={classes.planHeadingText}>
            {`Transaction History / ${get(selectedClientData, 'investorName')}`}
          </Typography>
          <Typography
            className={
              get(selectedClientData, 'planType') === SUBSCRIBED_USER.VC_FIRM
                ? classes.planTypeVcFirmText
                : get(selectedClientData, 'planType') ===
                  SUBSCRIBED_USER.YARDSTICK
                ? classes.planTypeYardstickText
                : classes.planTypeAngelInvText
            }
          >
            {get(selectedClientData, 'planType') === SUBSCRIBED_USER.VC_FIRM
              ? 'VentureInsights Plus'
              : get(selectedClientData, 'planType') ===
                SUBSCRIBED_USER.YARDSTICK
              ? 'Yardstick Only'
              : 'VentureInsights'}
          </Typography>
        </Box>
        <Box className={classes.rightBox}>
          <KeyboardBackspaceIcon
            className={classes.backIcon}
            onClick={() => setTransactionView(false)}
          />
        </Box>
      </Box>
      <TransactionHistory
        investorId={get(selectedClientData, 'id')}
        firmType={get(selectedClientData, 'planType')}
      />
    </>
  );
};

export default TransactionInvestorView;
