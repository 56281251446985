import {
  ADMIN_GET_ENQUIRY_BY_ID,
  ADMIN_GET_ENQUIRY_BY_ID_EMPTY,
  ADMIN_GET_ENQUIRY_BY_ID_LOADING,
  ADMIN_GET_ENQUIRY_BY_ID_SUCCESS,
  ADMIN_GET_ENQUIRY_BY_ID_FAILURE,
  GET_FUND_TYPE_LOADING,
  GET_FUND_TYPE_SUCCESS,
  GET_FUND_TYPE_FAILURE,
  TRACK_BRIDGE_LOAN_PROCESS_LOADING,
  TRACK_BRIDGE_LOAN_PROCESS_SUCCESS,
  TRACK_BRIDGE_LOAN_PROCESS_FAILURE,
  TRACK_VD_PROCESS_LOADING,
  TRACK_VD_PROCESS_SUCCESS,
  TRACK_VD_PROCESS_FAILURE,
  GET_LOANS_DETAILS_BY_STARTUP_ID_LOADING,
  GET_LOANS_DETAILS_BY_STARTUP_ID_SUCCESS,
  GET_LOANS_DETAILS_BY_STARTUP_ID_FAILURE,
  GET_YC_DOCS_BY_STARTUP_ID_LOADING,
  GET_YC_DOCS_BY_STARTUP_ID_SUCCESS,
  GET_YC_DOCS_BY_STARTUP_ID_FAILURE,
  GET_LOAN_TERMS_BY_STARTUP_ID_LOADING,
  GET_LOAN_TERMS_BY_STARTUP_ID_SUCCESS,
  GET_LOAN_TERMS_BY_STARTUP_ID_FAILURE,
  STARTUP_INFO,
  STARTUP_INFO_FAILURE,
  STARTUP_INFO_SUCCESS,
  GET_LOAN_CLOSER_INFO_LOADING,
  GET_LOAN_CLOSER_INFO_SUCCESS,
  GET_LOAN_CLOSER_INFO_FAILURE,
  HANDLE_LOAN_CLOSER_SUCCESS,
  HANDLE_LOAN_CLOSER_LOADING,
  GET_REPAID_AMOUNT_SUCCESS,
  GET_REPAID_AMOUNT_FAILURE,
  EMPTY_ENQUIRY_STATE,
} from './Actions';
import { IAdminEnquiryState, EnquiryAction } from './Types';
export const EnquiryState: IAdminEnquiryState = {
  isLoading: false,
  isLoadingYcDocs: false,
  successMessage: '',
  errorMessage: '',
  startupInfo: {},
  enquiryView: {
    startupDetails: {
      startupFullName: '',
      startupId: '',
      assignedYcLegalPocName: '',
      assignedYcPartnerName: '',
      startupYcAssignedId: '',
      startupTaxIdNumber: '',
      startupWebLink: '',
      startupLogoUrl: '',
      startupLegalState: '',
      ycLocation: '',
      ycIncorporationCountry: '',
      stateOfIncorporation: '',
      enquiryTermsAccepted: true,
      startupCountry: '',
      businessOpsLocation: '',
      industryClassification: '',
      industryType: '',
      startupTwitterUrl: '',
      startupFacebookUrl: '',
      startupLinkedInUrl: '',
      ycBatch: '',
      //startupProductDemoUrl: '',
      //isStartupYcCertified: false,
    },
    founderDetails: [
      {
        founderName: '',
        founderEmail: '',
        // founderPhoneNumber: '',
        // founderQualifications: '',
        // founderLinkedinUrl: '',
        // founderBio: '',
        // founderRole: '',
      },
    ],
    defaultFounderDetails: [
      {
        founderName: '',
        founderEmail: '',
        // founderPhoneNumber: '',
        // founderQualifications: '',
        // founderLinkedinUrl: '',
        // founderBio: '',
        // founderRole: '',
      },
    ],
    productDescription: '',
    productProgress: '',
    potentialMarketTarget: '',
    startupInvestmentInformation: '',
    startupLegalInformation: '',
    additionalInformation: {
      startup8vdxSource: '',
      startupReasonForChosing: '',
      startupInvestmentInformation: '',
      startupLegalInformation: '',
    },
    id: '',
  },
  enquiryLog: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
  loanTypeObj: null,
  bridgeLoanProcess: null,
  vdProcess: null,
  loansDetails: null,
  ycDocuments: [],
  loanTerms: null,
  isLoadingLoanTerms: false,
  loanCloserInfo: null,
  isLoadingLoanCloserInfo: false,
  repaidInfo: null,
  repaidInfoSuccessMessage: '',
  repaidInfoErrorMessage: '',
};
const EnquiryReducer = (state = EnquiryState, action: EnquiryAction) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_GET_ENQUIRY_BY_ID_EMPTY:
      return {
        ...state,
        enquiryView: EnquiryState.enquiryView,
        isLoading: false,
      };
    case ADMIN_GET_ENQUIRY_BY_ID:
      return {
        ...state,
        enquiryLog: {
          ...state.enquiryLog,
          isLoading: true,
        },
      };
    case ADMIN_GET_ENQUIRY_BY_ID_LOADING:
      return {
        ...EnquiryState,
        isLoading: payload,
      };
    case ADMIN_GET_ENQUIRY_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        enquiryLog: {
          ...state.enquiryLog,
          isLoading: false,
          successMessage: 'successfully fetched',
        },
        enquiryView: payload,
      };
    case ADMIN_GET_ENQUIRY_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        enquiryLog: {
          ...state.enquiryLog,
          isLoading: false,
          errorMessage: payload,
        },
      };

    case STARTUP_INFO:
      return {
        ...state,
        STARTUP_INFO: {
          ...state,
          isLoading: true,
        },
      };

    case STARTUP_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        startupInfo: payload,
      };
    case STARTUP_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        startupInfo: {},
      };

    case GET_FUND_TYPE_LOADING:
      return {
        ...state,
        isLoading: true,
        loanTypeObj: null,
        errorMessage: '',
      };
    case GET_FUND_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loanTypeObj: payload,
        errorMessage: '',
      };
    case GET_FUND_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        loanTypeObj: null,
        errorMessage: payload,
      };
    case TRACK_BRIDGE_LOAN_PROCESS_LOADING:
      return {
        ...state,
        isLoading: true,
        bridgeLoanProcess: null,
        successMessage: '',
        errorMessage: '',
      };
    case TRACK_BRIDGE_LOAN_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bridgeLoanProcess: payload,
        successMessage: '',
        errorMessage: '',
      };
    case TRACK_BRIDGE_LOAN_PROCESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        bridgeLoanProcess: null,
        successMessage: '',
        errorMessage: payload,
      };
    case TRACK_VD_PROCESS_LOADING:
      return {
        ...state,
        isLoading: true,
        vdProcess: null,
        successMessage: '',
        errorMessage: '',
      };
    case TRACK_VD_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vdProcess: payload,
        successMessage: '',
        errorMessage: '',
      };
    case TRACK_VD_PROCESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vdProcess: null,
        successMessage: '',
        errorMessage: payload,
      };
    case GET_LOANS_DETAILS_BY_STARTUP_ID_LOADING:
      return {
        ...state,
        isLoading: true,
        loansDetails: null,
        successMessage: '',
        errorMessage: '',
      };
    case GET_LOANS_DETAILS_BY_STARTUP_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loansDetails: payload,
        successMessage: '',
        errorMessage: '',
      };
    case GET_LOANS_DETAILS_BY_STARTUP_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        loansDetails: null,
        successMessage: '',
        errorMessage: payload,
      };
    case GET_YC_DOCS_BY_STARTUP_ID_LOADING:
      return {
        ...state,
        isLoadingYcDocs: true,
        ycDocuments: [],
        successMessage: '',
        errorMessage: '',
      };
    case GET_YC_DOCS_BY_STARTUP_ID_SUCCESS:
      return {
        ...state,
        isLoadingYcDocs: false,
        ycDocuments: payload,
        successMessage: '',
        errorMessage: '',
      };
    case GET_YC_DOCS_BY_STARTUP_ID_FAILURE:
      return {
        ...state,
        isLoadingYcDocs: false,
        ycDocuments: [],
        successMessage: '',
        errorMessage: payload,
      };
    case GET_LOAN_TERMS_BY_STARTUP_ID_LOADING:
      return {
        ...state,
        isLoadingLoanTerms: true,
        loanTerms: null,
        successMessage: '',
        errorMessage: '',
      };
    case GET_LOAN_TERMS_BY_STARTUP_ID_SUCCESS:
      return {
        ...state,
        isLoadingLoanTerms: false,
        loanTerms: payload,
        successMessage: '',
        errorMessage: '',
      };
    case GET_LOAN_TERMS_BY_STARTUP_ID_FAILURE:
      return {
        ...state,
        isLoadingLoanTerms: false,
        loanTerms: null,
        successMessage: '',
        errorMessage: payload,
      };
    case GET_LOAN_CLOSER_INFO_LOADING:
      return {
        ...state,
        isLoadingLoanCloserInfo: true,
        loanCloserInfo: null,
        successMessage: '',
        errorMessage: '',
      };
    case GET_LOAN_CLOSER_INFO_SUCCESS:
    case HANDLE_LOAN_CLOSER_SUCCESS:
      return {
        ...state,
        isLoadingLoanCloserInfo: false,
        loanCloserInfo: payload,
        successMessage: '',
        errorMessage: '',
      };
    case GET_LOAN_CLOSER_INFO_FAILURE:
      return {
        ...state,
        isLoadingLoanCloserInfo: false,
        loanCloserInfo: null,
        successMessage: '',
        errorMessage: payload,
      };
    case HANDLE_LOAN_CLOSER_LOADING:
      return {
        ...state,
        isLoadingLoanCloserInfo: true,
        successMessage: '',
        errorMessage: '',
      };
    case GET_REPAID_AMOUNT_SUCCESS:
      return {
        ...state,
        repaidInfo: payload,
        repaidInfoSuccessMessage: '',
        repaidInfoErrorMessage: '',
      };
    case GET_REPAID_AMOUNT_FAILURE:
      return {
        ...state,
        repaidInfo: null,
        repaidInfoSuccessMessage: '',
        repaidInfoErrorMessage: payload,
      };
    case EMPTY_ENQUIRY_STATE:
      return EnquiryState;
    default:
      return state;
  }
};

export default EnquiryReducer;
