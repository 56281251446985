import React from 'react';
import { Typography } from '@material-ui/core';

import { Box } from 'components';
import { Ornament } from 'assets';

import styles from './styles';

type Props = {
  name: string;
};
const PageHeader = ({ name }: Props) => {
  const classes = styles();
  return (
    <Box className={classes.welcomeBoxHead}>
      <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
      <Typography gutterBottom className={classes.welcomeHeadtext}>
        Welcome, {name}
      </Typography>
      {/* <Typography gutterBottom className={classes.welcomeHeadSubtext}>
        Good Evening!
      </Typography> */}
    </Box>
  );
};

export default PageHeader;
