import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const approveLoanSchema = yup.object().shape({
  requestedAmount: yup.string().required(requiredConstant),
  processingAmount: yup.string().required(requiredConstant),
  loanTenureInMonths: yup.string().required(requiredConstant),
  interestRate: yup.string().required(requiredConstant),
  warrantsRate: yup.string().required(requiredConstant),
  termsAccepted: yup.string().required(requiredConstant),
});
