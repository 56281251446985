import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tabPanelContent: {
    padding: 20,
    '@media (max-width: 1024px)': {
      padding: '20px 0px',
    },
  },
  summaryAccordRight: {
    // width: '50%',
    textAlign: 'right',
  },
  statusBtn: {
    background: theme.palette.grey[800],
    color: theme.palette.primary.contrastText,
    height: 28,
    fontSize: 12,
    cursor: 'auto',
    borderRadius: 4,
    marginRight: 15,
    alignSelf: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.grey[800],
      color: theme.palette.primary.contrastText,
    },
  },
  accordSendBtn: {
    fontSize: 14,
    letterSpacing: 0.25,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 84,
    alignSelf: 'center',
    textTransform: 'capitalize',
  },
  moreIcon: {
    alignSelf: 'center',
    color: theme.palette.primary.main,
    width: 30,
    height: 30,
    marginLeft: 12,
    position: 'relative',
    top: 10,
  },
  reviewDocuBox: {
    textAlign: 'center',
    // marginBottom: 15,
  },
  reviewDocuText: {
    fontSize: 11,
    color: theme.palette.grey[600],
    marginBottom: 10,
  },
  bGDocuImg: {
    height: 100,
    width: 106,
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  approvedbtn: {
    fontSize: 10,
    color: theme.palette.primary.main,
    background: 'transparent',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
  },
  circleOutline: {
    fontSize: 10,
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  approveBtn: {
    fontSize: 10,
    height: 26,
    background: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid #1EA59A',
    borderRadius: 4.5,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
  },
  docuComponent: {
    width: '100%',
  },
  documentListComponent: {
    margin: 0,
    display: 'grid',
    gridGap: 20,
    gridTemplateColumns: 'repeat(auto-fill, minmax(106px, 1fr) )',
    padding: '5px 20px 10px 0',
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  // Approve Load Model styling --------- start
  approveModel: {
    height: '100%',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    '& .MuiDialog-container': {
      alignSelf: 'center',
      height: '90%',
      width: 700,
    },
    '& .MuiDialog-paper': {
      margin: 0,
      padding: '40px 20px 40px 45px',
      position: 'relative',
      '@media (max-width: 960px)': {
        padding: '20px 10px 30px 30px',
        maxWidth: '90%',
      },
    },
    '& .MuiTypography-h3': {
      textAlign: 'center',
      padding: '10px 0 15px',
    },
    '& .MuiTypography-h6': {
      padding: '20px 0 20px',
      textAlign: 'center',
    },
    '& .MuiTypography-caption': {
      top: 0,
      right: 15,
    },
    '& .MuiDialogContent-root': {
      padding: '20px 40px 0 0',
      overflowX: 'hidden',
      '@media (max-width: 960px)': {
        paddingRight: 20,
      },
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#1EA59A',
        width: '0.6em',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: '#F6FAFA',
        minHeight: 24,
        border: '1px solid #1EA59A',
        visibility: 'hidden',
      },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#2B2B2B',
      },
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: '0 -12px 20px',
      '@media (max-width: 960px)': {
        margin: '0 -12px 5px',
      },
    },
  },
  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  approveFont: {
    fontSize: '16px',
  },
  approveBtnPreview: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  approveImageButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  loaderBox: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '35%',
  },
  loaderSection: {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    background: '#ffffff7d',
    height: '70%',
    // opacity: 0.5,
    marginTop: 40,
  },
  inputHead: {
    padding: '12px 40px 0px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 0px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px 12px 0 !important',
    },
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInput: {
    marginBottom: 30,
    width: '100%',
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  backContinueBox: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    fontSize: 16,
    height: 48,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  checkedBox: {
    color: theme.palette.primary.main,
  },
  labelSendEmailText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 5,
    display: 'inline-flex',
  },
}));
