import { PaymentsPayload, PaymentByIdPayload } from './Types';
export const GET_PAYMENTS_LIST = 'admin/GET_PAYMENTS_LIST';
export const GET_PAYMENTS_LIST_LOADING = 'admin/GET_PAYMENTS_LIST_LOADING';
export const GET_PAYMENTS_LIST_SUCCESS = 'admin/GET_PAYMENTS_LIST_SUCCESS';
export const GET_PAYMENTS_LIST_FAILURE = 'admin/GET_PAYMENTS_LIST_FAILURE';
export const LOG_PAYMENT = 'LOG_PAYMENT';
export const LOG_PAYMENT_LOADING = 'LOG_PAYMENT_LOADING';
export const LOG_PAYMENT_SUCCESS = 'LOG_PAYMENT_SUCCESS';
export const LOG_PAYMENT_FAILURE = 'LOG_PAYMENT_FAILURE';
export const GET_PAYMENTS_BY_ID = 'GET_PAYMENTS_BY_ID';
export const GET_PAYMENTS_BY_ID_LOADING = 'GET_PAYMENTS_BY_ID_LOADING';
export const GET_PAYMENTS_BY_ID_SUCCESS = 'GET_PAYMENTS_BY_ID_SUCCESS';
export const GET_PAYMENTS_BY_ID_FAILURE = 'GET_PAYMENTS_BY_ID_FAILURE';
export const EMPTY_SUCCESS_MESSAGE = 'EMPTY_SUCCESS_MESSAGE';
export const CLEAR_PAYMENT_DETAILS = 'admin/CLEAR_PAYMENT_DETAILS';

export const getPaymentsList = (payload: PaymentsPayload) => {
  return {
    type: GET_PAYMENTS_LIST,
    payload,
  };
};

export const getPaymentById = (payload: PaymentByIdPayload) => {
  return {
    type: GET_PAYMENTS_BY_ID,
    payload,
  };
};

export const removePaymentDetails = () => {
  return {
    type: CLEAR_PAYMENT_DETAILS,
  };
};

export const logPayment = (payload: any) => {
  return {
    type: LOG_PAYMENT,
    payload,
  };
};

export const emptySuccessMessage = () => {
  return {
    type: EMPTY_SUCCESS_MESSAGE,
  };
};
