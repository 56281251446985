import React, { useEffect } from 'react';
import { Typography, Switch, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddIcon from '@material-ui/icons/Add';
import { get, isEmpty, orderBy } from 'lodash';

import { Box, Button, SnackBar } from 'components';
import {
  createOrUpdateStartupMultipleFundraising,
  addStartupBenchmarkInfo,
  deleteStartupBenchmarkFundraisingKpi,
  getStartupBenchmarkFundraisingKpi,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { getStartupInfo } from 'redux-modules/enquiries/Actions';

import styles from './styles';
import KPITable from '../KPITable';

const fundRaiseTableCols = [
  {
    title: 'Stage',
    name: 'roundStage',
    inputType: 'text',
    width: 120,
    align: 'left',
  },
  {
    title: 'Closing Month',
    name: 'closingMonth',
    inputType: 'month',
    width: 140,
    align: 'center',
  },
  {
    title: 'Amount Raised',
    name: 'amountRaised',
    inputType: 'number',
    width: 140,
    align: 'center',
  },
  {
    title: 'Type',
    name: 'type',
    inputType: 'list',
    width: 120,
    align: 'center',
  },
  {
    title: 'Uncapped?',
    name: 'uncapped',
    inputType: 'boolean',
    width: 100,
    align: 'center',
  },
  {
    title: 'Valuation',
    name: 'valuation',
    inputType: 'number',
    width: 140,
    align: 'center',
  },
  {
    title: 'Discount (%)',
    name: 'discount',
    inputType: 'number',
    width: 100,
    align: 'center',
  },
  {
    title: 'MFN Clause?',
    name: 'mfnClause',
    inputType: 'boolean',
    width: 100,
    align: 'center',
  },
];

const FundRaisingData = ({
  startUpId,
  startupDetails,
  role,
  handleClose,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [fundRaisingKpiRows, setFundraisingKpiRows] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [openSnackBar, setOpenSnackBar] = React.useState<boolean>(false);
  const [isInterested, setIsInterested] = React.useState<boolean>(
    get(startupDetails, 'raisedFundingRound') === true ||
      get(startupDetails, 'raisedFundingRound') === false
      ? get(startupDetails, 'raisedFundingRound')
      : true,
  );
  const [noFundraiseAdded, setNoFundraiseAdded] = React.useState<boolean>(
    false,
  );
  const [
    benchmarkFundraisingKpiList,
    setBenchmarkFundraisingKpiList,
  ] = React.useState<any>([]);

  const handleCloseSnackBar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const fundRaisingColumns = [
    ...fundRaiseTableCols,
    { name: '#', title: 'Actions', width: 100, align: 'left' },
  ];

  const getStartupBenchmarkKpiAllData = () => {
    setIsLoading(true);
    setErrorMessage('');
    Promise.all([getStartupBenchmarkFundraisingKpi(startUpId, role)])
      .then(([fundraisingData]: any) => {
        setBenchmarkFundraisingKpiList(
          orderBy(
            fundraisingData,
            [(obj: any) => Number(obj.month), (obj: any) => Number(obj.year)],
            ['desc', 'desc'],
          ),
        );
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const createRowArrayForFundraiseKPITable = (mainArrayList: any) => {
    return mainArrayList.map((kpiObj: any) => {
      return {
        ...kpiObj,
        closingMonth: `${kpiObj.month}_${kpiObj.year}`,
        isEdit: false,
      };
    });
  };

  const handleSaveMultipleRow = () => {
    setNoFundraiseAdded(false);
    const kpiRows = fundRaisingKpiRows.filter((row: any) => row.isEdit);
    if (get(kpiRows.slice(-1)[0], 'roundStage') === '') {
      setNoFundraiseAdded(true);
    } else {
      const payload = kpiRows.map((data: any) => {
        return {
          ...data,
          amountRaised: data?.amountRaised?.replace(/,/g, ''),
          valuation: data?.valuation?.replace(/,/g, ''),
          startupId: startUpId,
        };
      });
      onUpdateFundRaiseKpiDataCall(payload);
    }
  };

  const onUpdateFundRaiseKpiDataCall = (payload: any) => {
    setErrorMessage('');
    if (!isEmpty(payload)) {
      const invalidObj = payload.filter((obj: any) => {
        return (
          isEmpty(obj.roundStage) ||
          isEmpty(obj.closingMonth) ||
          isEmpty(obj.type)
        );
      });
      if (!isEmpty(invalidObj)) {
        setErrorMessage('Round stage, closing month and type is required');
      } else {
        createOrUpdateStartupMultipleFundraising(payload, role)
          .then(() => {
            getStartupBenchmarkKpiAllData();
          })
          .catch((err: any) => {
            const message: string = errorMessageHandler(err);
            setErrorMessage(message);
          });
      }
    }
  };

  const handleAddFundraisingKpiRow = () => {
    const newData: any[] = [...fundRaisingKpiRows];
    newData.push({
      roundStage: '',
      isEdit: true,
      isNewRow: true,
    });
    setFundraisingKpiRows(newData);
  };

  const handleSwitchChange = (event: any) => {
    setIsInterested(event.target.checked);
    if (!event.target.checked) {
      setOpenSnackBar(true);
    }
    const obj = {
      raisedFundingRound: event.target.checked,
    };
    addStartupBenchmarkInfo(obj)
      .then((res) => {
        dispatch(getStartupInfo());
        // eslint-disable-next-line no-console
        console.log('BENCHMARK_INFO', res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const checkIsEditModeOpen = () => {
    let isEditMode = false;
    if (fundRaisingKpiRows.find((row: any) => row.isEdit)) isEditMode = true;
    return isEditMode;
  };

  useEffect(() => {
    setFundraisingKpiRows(
      createRowArrayForFundraiseKPITable(benchmarkFundraisingKpiList),
    );
  }, [benchmarkFundraisingKpiList]);

  useEffect(() => {
    getStartupBenchmarkKpiAllData();
  }, []);

  return (
    <Box className={classes.benchFundBox}>
      {errorMessage && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.errorMessage}
        >
          {errorMessage}
        </Typography>
      )}
      {/* <Box className={classes.startupKPIDataBox}>
        <Typography className={classes.kpiDataLabelText}>
          Fundraising History
        </Typography>
      </Box> */}
      {noFundraiseAdded && (
        <Grid container className={classes.enterKpiDetailsContainer}>
          <Grid item style={{ display: 'inherit' }}>
            <InfoOutlinedIcon className={classes.infoIconRed} />
            <Typography>Please enter Fundraise Details</Typography>
          </Grid>
        </Grid>
      )}
      <Box className={classes.fundraisingInterestedContainer}>
        <Typography className={classes.fundraisingInterestedText}>
          Have you ever raised any round ?
        </Typography>
        <Box className={classes.switchBox}>
          <Switch
            classes={{
              root: classes.switchRoot,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            checked={isInterested}
            onChange={handleSwitchChange}
            name="viewType"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={!isEmpty(benchmarkFundraisingKpiList)}
          />
        </Box>
      </Box>
      {isInterested && (
        <>
          <Grid container className={classes.enterKpiTextContainer}>
            <Grid item style={{ display: 'inherit' }}>
              <InfoOutlinedIcon className={classes.infoIcon} />
              <Typography>
                Note: Enter your fundraising data to arrive at a suitable
                valuation.
              </Typography>
            </Grid>
          </Grid>
          <Box className={classes.kpiTableDataBox}>
            <KPITable
              tableClassName="fundraising-kpi-table-container"
              columnKeyName="fundRaiseKpiName"
              columnValueKeyName=""
              originalKpiRows={createRowArrayForFundraiseKPITable(
                benchmarkFundraisingKpiList,
              )}
              kpiRows={fundRaisingKpiRows}
              setKpiRows={setFundraisingKpiRows}
              kpiColumns={fundRaisingColumns}
              startupId={startUpId}
              role={role}
              onUpdateCall={onUpdateFundRaiseKpiDataCall}
              deleteBenchmarkKpi={deleteStartupBenchmarkFundraisingKpi}
              onDeleteCall={getStartupBenchmarkKpiAllData}
              setNoKpiAdded={setNoFundraiseAdded}
              selectedFrequency={get(startupDetails, 'benchmarkKPIFrequency')}
            />
            {checkIsEditModeOpen() && (
              <Button
                className={classes.addKpiButton}
                name="Save"
                onClick={() => handleSaveMultipleRow()}
              />
            )}
            <Button
              className={classes.addKpiButton}
              name="Add Round"
              startIcon={<AddIcon />}
              onClick={handleAddFundraisingKpiRow}
            />
          </Box>
        </>
      )}
      <Box className={classes.saveBtnContainer}>
        <Button
          type="submit"
          className={classes.continueBtn}
          name={`Save`}
          isLoading={isLoading}
          disabled={isLoading || checkIsEditModeOpen()}
          onClick={handleClose}
        />
        <Button
          variant="outlined"
          className={classes.resetBtn}
          name="Back"
          disabled={isLoading}
          onClick={handleClose}
        />
      </Box>
      <SnackBar
        open={openSnackBar}
        handleClose={handleCloseSnackBar}
        title="No insights on the valuation of your company."
        type="info"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </Box>
  );
};

export default FundRaisingData;
