import React from 'react';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { Box, Button } from 'components';
import history from 'common/utils/history';
import { RootState } from 'redux-modules/store/rootState';

import styles from '../styles';

const UploadSignDocuments = () => {
  const classes = styles();
  const { loansDetails } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const fundType = get(loansDetails, 'fundType');

  return (
    <Box className={classes.completedBox}>
      <Box className={classes.signedBox}>
        <CheckCircleIcon className={classes.circleIcon} />
        <Box className={classes.signedRight}>
          <Typography variant="h5" className={classes.signedText}>
            Documents signed
          </Typography>
          {/* <Typography variant="body1" className={classes.signedSub}>
            14th July 2021
          </Typography> */}
          <Button
            name="View"
            className={classes.viewBtn}
            onClick={() =>
              history.push(
                `/startup/profile?value=1&${
                  fundType ? `loanType=${fundType}&` : ''
                }step=DOCU_SIGN_DOCUMENTS&fundType=BRIDGE_LOAN`,
              )
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UploadSignDocuments;
