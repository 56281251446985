import React from 'react';
import { Typography, Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { get } from 'lodash';

import { Box } from 'components';
import { RightArrow } from 'assets';
import history from 'common/utils/history';

import styles from './styles';

const InvestorReview = (data: any) => {
  const classes = styles();

  return (
    <Box className={classes.dashActProfileBox}>
      <Box className={classes.dashActProfileLeft}>
        <Avatar>
          <PersonIcon />
        </Avatar>
        <Box className={classes.dashActProfiledetails}>
          <Typography className={classes.profileUNameText} variant="h4">
            {get(data, 'data.investor.name')}
            <Typography
              className={classes.profileSubTextOrange}
              variant="caption"
            >
              New Investor
            </Typography>
          </Typography>
          <Typography className={classes.profileLocationText} variant="h4">
            {get(data, 'data.investor.email')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.dashActProfileRight}>
        <Typography
          variant="caption"
          className={classes.openText}
          onClick={() =>
            history.push(
              `/admin/investor-view/${get(data, 'data.investor.id')}`,
            )
          }
        >
          Open{' '}
        </Typography>
        <img
          src={RightArrow}
          alt={RightArrow}
          className={classes.pointer}
          onClick={() =>
            history.push(
              `/admin/investor-view/${get(data, 'data.investor.id')}`,
            )
          }
        />
      </Box>
    </Box>
  );
};

export default InvestorReview;
