import React from 'react';
import { CircularProgress } from '@material-ui/core';

type Props = {
  className?: any;
  size?: number;
};

const Loader = ({ className, size }: Props) => {
  return <CircularProgress className={className} size={size} />;
};

export default Loader;
