import { AddFundingRoundPayload, ApproveFundingRoundPayload } from './Types';

export const GET_STARTUP_FUNDING_ROUND_LIST_SUCCESS =
  'GET_STARTUP_FUNDING_ROUND_LIST_SUCCESS';
export const GET_STARTUP_FUNDING_ROUND_LIST_LOADING =
  'GET_STARTUP_FUNDING_ROUND_LIST_LOADING';
export const GET_STARTUP_FUNDING_ROUND_LIST_FAILURE =
  'GET_STARTUP_FUNDING_ROUND_LIST_FAILURE';
export const GET_STARTUP_FUNDING_ROUND_LIST = 'GET_STARTUP_FUNDING_ROUND_LIST';
export const ADD_STARTUP_FUNDING_ROUND_SUCCESS =
  'ADD_STARTUP_FUNDING_ROUND_SUCCESS';
export const ADD_STARTUP_FUNDING_ROUND_LOADING =
  'ADD_STARTUP_FUNDING_ROUND_LOADING';
export const ADD_STARTUP_FUNDING_ROUND_FAILURE =
  'ADD_STARTUP_FUNDING_ROUND_FAILURE';
export const ADD_STARTUP_FUNDING_ROUND = 'ADD_STARTUP_FUNDING_ROUND';
export const APPROVE_FUNDING_ROUND = 'APPROVE_FUNDING_ROUND';
export const APPROVE_FUNDING_ROUND_LOADING = 'APPROVE_FUNDING_ROUND_LOADING';
export const APPROVE_FUNDING_ROUND_FAILURE = 'APPROVE_FUNDING_ROUND_FAILURE';

export const handleGetStartupFundingRoundList = (startupId: any) => ({
  type: GET_STARTUP_FUNDING_ROUND_LIST,
  payload: startupId,
});

export const handleAddStartupFundingRound = (
  payload: AddFundingRoundPayload,
) => ({
  type: ADD_STARTUP_FUNDING_ROUND,
  payload,
});

export const handleApproveFundingRound = (
  payload: ApproveFundingRoundPayload,
) => ({
  type: APPROVE_FUNDING_ROUND,
  payload,
});
