import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { Box } from 'components';
import { WelcomeImg } from 'assets';

const useStyles = makeStyles((theme) => ({
  welcomeHeadertext: {
    fontSize: 20,
    color: theme.palette.grey[500],
  },
  welcomeBoxBody: {
    maxWidth: 600,
    margin: 'auto',
    textAlign: 'center',
    paddingTop: 50,
  },
  welcomeImgImage: {
    marginBottom: 25,
  },
}));

const NoItemsImg = () => {
  const classes = useStyles();

  return (
    <Box className={classes.welcomeBoxBody}>
      <img
        src={WelcomeImg}
        alt={WelcomeImg}
        className={classes.welcomeImgImage}
      />
      <Typography
        variant="h4"
        gutterBottom
        className={classes.welcomeHeadertext}
      >
        No items to show
      </Typography>
    </Box>
  );
};

export default NoItemsImg;
