import React, { useEffect } from 'react';
import { Switch, Typography } from '@material-ui/core';
import { get } from 'lodash';

import { Box } from 'components';
import { DealCreationProps } from 'common/types';
import { DEAL_TYPE } from 'common/utils/constants';

import styles from './styles';
import FundDealForm from './FundDealForm';
import StartupDealForm from './StartupDealForm';

const DealInformation = ({
  handleNext,
  activeStep,
  deal,
  setDeal,
}: DealCreationProps) => {
  const classes = styles();

  const [fundDeal, setFundDeal] = React.useState<boolean>(true);
  const [dealType, setDealType] = React.useState<string>(DEAL_TYPE.FUND);

  const handleDealTypeChange = (event: any) => {
    setFundDeal(!event.target.checked);
  };

  useEffect(() => {
    if (get(deal, 'id')) {
      if (get(deal, 'dealType') === DEAL_TYPE.FUND) setFundDeal(true);
      else setFundDeal(false);
    }
  }, [deal]);

  useEffect(() => {
    if (fundDeal) setDealType(DEAL_TYPE.FUND);
    else setDealType(DEAL_TYPE.STARTUP);
  }, [fundDeal]);

  return (
    <Box>
      <Typography variant="h3" className={classes.dealCreationHeader}>
        Deal Information
      </Typography>
      <Box className={classes.switchBox}>
        <Switch
          classes={{
            root: classes.switchRoot,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          checked={!fundDeal}
          onChange={handleDealTypeChange}
          name="dealType"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </Box>
      {dealType === DEAL_TYPE.FUND ? (
        <FundDealForm
          dealType={dealType}
          deal={deal}
          setDeal={setDeal}
          activeStep={activeStep}
          handleNext={handleNext}
        />
      ) : (
        <StartupDealForm
          dealType={dealType}
          deal={deal}
          setDeal={setDeal}
          activeStep={activeStep}
          handleNext={handleNext}
        />
      )}
    </Box>
  );
};
export default DealInformation;
