import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  dashContainer: {
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    background: '#F6FAFA',
    '@media (max-width: 1024px)': {
      display: 'block',
      position: 'relative',
      height: '100vh',
      overflow: 'hidden',
    },
  },
  dashleft: {
    width: 265,
    flex: '0 auto',
    '@media (max-width: 1120px)': {
      width: 170,
      minWidth: 170,
      padding: 15,
    },
    '@media (max-width: 1024px)': {
      width: 'auto',
    },
    '@media (max-width: 767px)': {
      width: 'calc(100% - 40px)',
      padding: '15px 20px 15px',
    },
  },
  sideStepperFixed: {
    padding: 40,
    height: '75%',
    width: '100%',
    '@media (max-width: 1120px)': {
      padding: 0,
    },
  },
  dashLogo: {
    // display: 'flex',
    width: '100%',
    marginBottom: 31,
    '@media (max-width: 1024px)': {
      marginBottom: 20,
    },
    '@media (max-width: 767px)': {
      marginBottom: 10,
    },
  },
  dashAvatarLogo: {
    paddingLeft: 8,
  },
  userLabelHead: {
    fontSize: 16,
  },
  userLabelText: {
    fontSize: 13,
    color: theme.palette.grey[600],
  },
  stepperText: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.grey[500],
    marginBottom: 54,
    paddingLeft: 8,
    '@media (max-width: 1024px)': {
      marginBottom: 15,
    },
    '@media (max-width: 767px)': {
      marginBottom: 10,
      fontSize: 13,
    },
  },
  logoImg: {
    width: 100,
    height: 'auto',
    marginBottom: 54,
    '@media (max-width: 1024px)': {
      marginBottom: 15,
      width: 90,
    },
    '@media (max-width: 767px)': {
      marginBottom: 10,
      width: 75,
    },
  },
  needHelpBox: {
    paddingTop: 70,
    '@media (max-width: 1024px)': {
      paddingTop: 15,
    },
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  needHelpText: {
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: '19px',
    fontWeight: 600,
    color: theme.palette.grey[100],
    '@media (max-width: 1120px)': {
      fontSize: 12,
    },
  },
  needSubText: {
    color: theme.palette.primary.main,
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: '19px',
    cursor: 'pointer',
    paddingLeft: 5,
    '@media (max-width: 1120px)': {
      fontSize: 12,
    },
  },
  dashRight: {
    width: 'calc(100% - 300px)',
    flexGrow: 1,
    padding: '45px 0px 35px 25px',
    //padding: '55px 0px 35px 93px',
    background: theme.palette.common.white,
    position: 'relative',
    '@media (max-width: 1120px)': {
      width: 'calc(100% - 200px)',
      padding: '40px 0px 30px 25px',
      overflow: 'hidden',
    },
    '@media (max-width: 1024px)': {
      width: 'auto',
      padding: '0',
      position: 'static',
      height: 'calc(100% - 203.5px)',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    '@media (max-width: 800px)': {
      height: 'calc(100% - 222.2px)',
    },
    '@media (max-width: 767px)': {
      height: 'calc(100% - 135.5px)',
    },
    '@media (max-width: 683px)': {
      height: 'calc(100% - 151px)',
    },
    '@media (max-width: 452px)': {
      height: 'calc(100% - 167px)',
    },
  },
  dashHeader: {
    position: 'absolute',
    top: 10,
    right: 15,
    display: 'flex',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media (max-width: 1024px)': {
      padding: 0,
      right: 25,
      top: 25,
    },
    '@media (max-width: 767px)': {
      top: 20,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '&:hover': {
      background: 'transparent',
    },
  },
  skeletonWrapper: {
    width: '80%',
    margin: 'auto',
    marginRight: '40px',
    marginTop: '100px',
  },
}));
