import {
  takeEvery,
  put,
  call,
  all,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import { get } from 'lodash';
import { Auth } from 'aws-amplify';
import moment from 'moment';

import history from 'common/utils/history';
// import { secureApi } from 'api/Axios';
import { getYcBatches, signUp } from 'services';
import {
  validateUser,
  constructArray,
  errorMessageHandler,
  containsEncodedURIComponents,
} from 'common/utils/helpers';
import {
  ISagaAction,
  ISetPassword,
  ISignInModal,
  ISignUpModal,
  IVerifyUser,
  IUsername,
  IForgotPassword,
} from 'common/types';
import { logAmpEvent, resetAmp } from 'common/utils/amplitude';

import {
  USER_LOGIN_LOADING,
  USER_LOGIN,
  USER_LOGIN_COMPLETE,
  ADMIN_SET_PASSWORD,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  VERIFY_USER,
  VERIFY_SUCCESS,
  ERROR_VERIFY,
  ERROR_LOGIN,
  FORGOT_PASSWORD,
  SUCCESS_FORGOT_PASSWORD,
  ERROR_FORGOT_PASSWORD,
  USER_SET_PASSWORD_FAILURE,
  USER_SET_PASSWORD,
  USER_SET_PASSWORD_SUCCESS,
  ADMIN_SET_PASSWORD_SUCCESS,
  ADMIN_SET_PASSWORD_FAILURE,
  ERROR_SIGN_OUT,
  SIGN_OUT,
  YC_BATCH,
  YC_BATCH_SUCCESS,
  YC_BATCH_FAILURE,
  logoutComplete,
} from './Actions';
type typesObj = {
  user: any;
  attributes: any;
  route?: string;
  challengeName: string;
};

type responseType = {
  response: any;
};
const signUpAPI = async (payload: ISignUpModal) =>
  signUp(payload)
    .then((res) => res)
    .catch((err) => Promise.reject(err));
const getBatchYc = async () =>
  getYcBatches()
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const verifyUser = async (payload: IVerifyUser) =>
  Auth.confirmSignUp(payload.username, payload.code)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const signOut = async () =>
  Auth.signOut()
    .then((res) => res)
    .catch((err) => Promise.reject(err));

const forgotPassword = async (payload: IUsername) =>
  Auth.forgotPassword(payload.username)
    .then((data) => data)
    .catch((err) => Promise.reject(err));

const signIn = async (payload: ISignInModal) => {
  const { username, password } = payload;
  return Auth.signIn(username, password)
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const confirmPassword = async (payload: IForgotPassword) => {
  const { username, password, code } = payload;
  return Auth.forgotPasswordSubmit(username, code, password)
    .then((data) => data)
    .catch((err) => Promise.reject(err));
};

const newPasswordRequired = async (payload: ISetPassword) => {
  const { newPassword, username, password } = payload;
  return Auth.signIn(username, password)
    .then((res) =>
      Auth.completeNewPassword(res, newPassword).catch((err) =>
        Promise.reject(err),
      ),
    )
    .catch((err) => Promise.reject(err));
};

function* loginUser(data: ISagaAction<ISignInModal>) {
  const { payload } = data;
  try {
    yield put({ type: USER_LOGIN_LOADING });
    const response: typesObj = yield call<any>(signIn, payload);

    const { challengeName } = response;
    if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put({ type: ERROR_LOGIN });

      history.push(`/reset-password/${payload.username}`);
    } else {
      const { email, email_verified, name, sub: cognitoSubId } =
        get(response, 'attributes') || {};
      const role = get(response, 'attributes.custom:role');
      if (role !== 'ADMIN') {
        logAmpEvent(
          'Login',
          {
            email,
            email_verified,
            name,
            sub: cognitoSubId,
            role: get(response, 'attributes.custom:role'),
            invited: get(response, 'attributes.custom:invited'),
            companyName: get(response, 'attributes.custom:company_name'),
            accelerator: get(response, 'attributes.custom:accelerator'),
          },
          { createdAt: moment().format('MMMM d, YYYY h:mma') },
          email,
        );
      }
      const userObj = {
        ...response.attributes,
        role: response.attributes['custom:role'],
        startUpId: response.attributes['custom:startup_id'],
        accelerator: response.attributes['custom:accelerator'],
        companyName: response.attributes['custom:company_name'],
      };
      yield put({
        type: USER_LOGIN_COMPLETE,
        payload: { ...userObj },
      });
      history.push(validateUser(userObj));
    }
  } catch (err) {
    const message: string = get(err, 'message');
    yield put({ type: ERROR_LOGIN, payload: message });
  }
}

function* userSignOut() {
  try {
    resetAmp();
    yield call(signOut);

    localStorage.clear();
    yield put(logoutComplete());

    history.push('/sign-in');
  } catch (err) {
    const message: string = get(err, 'message');
    yield put({ type: ERROR_SIGN_OUT, payload: message });
  }
}

function* handleForgotPassword(data: ISagaAction<IUsername>) {
  const { payload } = data;
  try {
    yield call<any>(forgotPassword, payload);

    yield put({ type: SUCCESS_FORGOT_PASSWORD, payload: payload.username });
    logAmpEvent(
      'Init forgot password',
      { email: payload.username },
      { createdAt: moment().format('MMMM d, YYYY h:mma') },
      payload.username,
    );
    history.push('/success');
  } catch (err) {
    const type = get(err, '__type');
    const message: string =
      type === 'UserNotFoundException'
        ? 'User does not exist'
        : get(err, 'message');
    yield put({ type: ERROR_FORGOT_PASSWORD, payload: message });
  }
}

function* setPassword(data: ISagaAction<ISetPassword>) {
  const { payload } = data;
  try {
    yield call<any>(newPasswordRequired, payload);
    yield put({
      type: ADMIN_SET_PASSWORD_SUCCESS,
      payload: 'Successfully Changed',
    });
    setTimeout(() => {
      history.push('/admin/dashboard');
    }, 3500);
  } catch (err) {
    const message: string = get(err, 'message');
    yield put({ type: ADMIN_SET_PASSWORD_FAILURE, payload: message });
  }
}

function* handleSignUp(data: ISagaAction<ISignUpModal>) {
  const { payload } = data;
  try {
    const res: typesObj = yield call<any>(signUpAPI, payload);
    const { email, id: cognitoSubId, name: companyName, phone } =
      get(res, 'data') || {};
    const role = get(res, 'data.role.roleId');
    const { accelerator, ycBatch } = get(res, 'data.startup') || {};

    logAmpEvent(
      'Signup',
      { email, cognitoSubId, companyName, phone, role, accelerator, ycBatch },
      { createdAt: moment().format('MMMM d, YYYY h:mma') },
      email,
    );

    yield put({ type: SIGN_UP_SUCCESS, payload: payload.email });
    history.push('/sign-up-success');
  } catch (err) {
    const message: string = errorMessageHandler(err) || get(err, 'message');
    yield put({ type: SIGN_UP_FAILURE, payload: message });
  }
}

function* handleVerifyUser(data: ISagaAction<IVerifyUser>) {
  const { payload } = data;
  try {
    const search = history.location.search;
    const redirectUrl: any = new URLSearchParams(search).get('redirecturl');
    yield call<any>(verifyUser, payload);
    yield put({ type: VERIFY_SUCCESS, payload: 'Successfully Verified' });

    logAmpEvent(
      'Verify User',
      {},
      { createdAt: moment().format('MMMM d, YYYY h:mma') },
    );

    setTimeout(() => {
      if (redirectUrl) {
        const toUrl = (param: any) => {
          return `/sign-in${
            containsEncodedURIComponents(param)
              ? param
              : encodeURIComponent(param)
          }`;
        };
        history.push(toUrl(window.location.search));
      } else {
        history.push('/sign-in');
      }
    }, 3500);
  } catch (err) {
    const message: string = get(err, 'message');
    yield put({ type: ERROR_VERIFY, payload: message });
  }
}

function* handleSubmitPassword(data: ISagaAction<IForgotPassword>) {
  const { payload } = data;
  try {
    yield call<any>(confirmPassword, payload);
    yield put({
      type: USER_SET_PASSWORD_SUCCESS,
      payload: 'Successfully Changed',
    });
    logAmpEvent('complete forgot password', {
      createdAt: moment().format('MMMM d, YYYY h:mma'),
    });
    setTimeout(() => history.push('/sign-in'), 3500);
  } catch (err) {
    const message: string = get(err, 'message');
    yield put({ type: USER_SET_PASSWORD_FAILURE, payload: message });
  }
}

function* handlegetBatches() {
  try {
    const response: responseType = yield call<any>(getBatchYc);
    const array = constructArray(response, 'YC_BATCH');
    array.sort();
    array.sort(
      (a: any, b: any) =>
        parseFloat(a.value.substring(1)) - parseFloat(b.value.substring(1)),
    );
    array.reverse();
    yield put({
      type: YC_BATCH_SUCCESS,
      payload: array,
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: YC_BATCH_FAILURE, payload: message });
  }
}

function* loginSaga() {
  yield takeEvery(USER_LOGIN, loginUser);
}

function* forgotPasswordSaga() {
  yield takeEvery(FORGOT_PASSWORD, handleForgotPassword);
}

function* setPasswordSaga() {
  yield takeEvery(ADMIN_SET_PASSWORD, setPassword);
}

function* setSignUpSaga() {
  yield takeEvery(SIGN_UP, handleSignUp);
}

function* setUserPassword() {
  yield takeEvery(USER_SET_PASSWORD, handleSubmitPassword);
}

function* verifyUserSaga() {
  yield takeEvery(VERIFY_USER, handleVerifyUser);
}

function* signOutSaga() {
  yield takeLatest(SIGN_OUT, userSignOut);
}

function* getYCBatchSaga() {
  yield takeLatest(YC_BATCH, handlegetBatches);
}

export default function* authSagas() {
  yield all([
    fork(loginSaga),
    fork(setPasswordSaga),
    fork(setSignUpSaga),
    fork(verifyUserSaga),
    fork(forgotPasswordSaga),
    fork(setUserPassword),
    fork(signOutSaga),
    fork(getYCBatchSaga),
  ]);
}
