import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  dealCreationHeader: {
    fontSize: 32,
    letterSpacing: 0.3,
    color: theme.palette.warning.contrastText,
    marginBottom: 30,
    fontWeight: 400,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '0px 0 20px',
    },
  },
  bankDetailText: {
    fontSize: 15,
    color: theme.palette.grey[50],
    letterSpacing: 0.3,
    marginBottom: 21,
  },
  uploadFileBox: {
    display: 'flex',
  },
  uploadBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 320,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    marginBottom: 30,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    '@media (max-width: 1125px)': {
      width: 280,
    },
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  prevButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    marginRight: 18,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
}));
