import React from 'react';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';

import { Box, Loader, Button } from 'components';
import history from 'common/utils/history';
import {
  getVcPortalCountryObject,
  getBusinessOpsCountryObject,
  errorMessageHandler,
} from 'common/utils/helpers';
import { adminApprovesVcFirmByInvestorId } from 'services';
import { FN } from 'common/types';
import { entityList } from 'common/utils/optionList';

import styles from './styles';

type Props = {
  investorId: string;
  errorMessage: string;
  vcDetails: Array<FN>;
  vcUserDetails: Array<FN>;
  isLoading: boolean;
  UrlParamValue: FN;
  UrlParamStatus: FN;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};

const VcFirmOverview = ({
  vcDetails,
  investorId,
  vcUserDetails,
  errorMessage,
  isLoading,
  UrlParamValue,
  setErrorMessage,
  setIsLoading,
  setValue,
  UrlParamStatus,
}: Props) => {
  const classes = styles();

  const onSubmit = () => {
    setIsLoading(true);
    setErrorMessage('');
    adminApprovesVcFirmByInvestorId(investorId, UrlParamValue)
      .then(() => {
        setIsLoading(false);
        UrlParamValue === 'VC_FIRM_SIGNUP' ||
        UrlParamValue === 'ANGEL_INVESTOR_REVIEW' ||
        UrlParamValue === 'YARDSTICK_USER_REVIEW'
          ? history.push('/actions')
          : setValue('PROCESS');
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  return (
    <>
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      {isLoading ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : (
        <Box
          className={
            UrlParamValue === 'VC_FIRM_SIGNUP' ||
            UrlParamValue === 'ANGEL_INVESTOR_REVIEW' ||
            UrlParamValue === 'YARDSTICK_USER_REVIEW'
              ? classes.tanPanelBoxOverview
              : UrlParamValue === 'VC_FIRM_REVIEW'
              ? classes.tanPanelVcReviewBox
              : classes.tanPanelBox
          }
        >
          <Box className={classes.tabPanelContent}>
            <Box className={classes.vTwoBlocks}>
              <Box className={classes.vFirstBlock}>
                {UrlParamValue === 'VC_FIRM_SIGNUP' ||
                UrlParamValue === 'ANGEL_INVESTOR_REVIEW' ||
                UrlParamValue === 'YARDSTICK_USER_REVIEW' ? (
                  <>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.overViewSubHeadTitle}
                    >
                      First Name
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.descTitletext}
                    >
                      {get(vcUserDetails, 'firstName')}
                    </Typography>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.overViewSubHeadTitle}
                    >
                      Last Name
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.descTitletext}
                    >
                      {get(vcUserDetails, 'lastName')}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.overViewSubHeadTitle}
                    >
                      Registered Firm Name
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.descTitletext}
                    >
                      {get(vcDetails, 'registeredFirmName')}
                    </Typography>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.overViewSubHeadTitle}
                    >
                      Name of the Firm
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.descTitletext}
                    >
                      {get(vcDetails, 'name')}
                    </Typography>
                  </>
                )}
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.overViewSubHeadTitle}
                >
                  Email Id
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.descTitletext}
                >
                  {get(vcUserDetails, 'email')}
                </Typography>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.overViewSubHeadTitle}
                >
                  Country of Origin
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.descTitletext}
                >
                  {
                    getVcPortalCountryObject(
                      get(vcUserDetails, 'countryOfOrigin') ||
                        get(vcDetails, 'countryOfOrigin'),
                    )?.country
                  }
                </Typography>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.overViewSubHeadTitle}
                >
                  Social Link
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.descTitletext}
                >
                  <a
                    target="_blank"
                    href={get(vcDetails, 'angellistUrl')}
                    rel="noreferrer"
                    className={classes.vSocialLink}
                  >
                    {get(vcDetails, 'angellistUrl')}
                  </a>
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.descTitletext}
                >
                  <a
                    target="_blank"
                    href={get(vcDetails, 'twitterUrl')}
                    rel="noreferrer"
                    className={classes.vSocialLink}
                  >
                    {get(vcDetails, 'twitterUrl')}
                  </a>
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.descTitletext}
                >
                  <a
                    target="_blank"
                    href={get(vcDetails, 'linkedinUrl')}
                    rel="noreferrer"
                    className={classes.vSocialLink}
                  >
                    {get(vcDetails, 'linkedinUrl')}
                  </a>
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.descTitletext}
                >
                  <a
                    target="_blank"
                    href={get(vcDetails, 'crunchbaseUrl')}
                    rel="noreferrer"
                    className={classes.vSocialLink}
                  >
                    {get(vcDetails, 'crunchbaseUrl')}
                  </a>
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.descTitletext}
                >
                  <a
                    target="_blank"
                    href={get(vcDetails, 'otherUrl')}
                    rel="noreferrer"
                    className={classes.vSocialLink}
                  >
                    {get(vcDetails, 'otherUrl')}
                  </a>
                </Typography>
              </Box>
              <Box>
                {UrlParamValue === 'VC_FIRM_REVIEW' ||
                UrlParamValue === 'VIEW' ? (
                  <>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.overViewSubHeadTitle}
                    >
                      First Name & Last Name
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.descTitletext}
                    >
                      {get(vcDetails, 'firstName')} {get(vcDetails, 'lastName')}
                    </Typography>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.overViewSubHeadTitle}
                    >
                      Type of Entity
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.descTitletext}
                    >
                      {get(
                        entityList.find(
                          (en: any) =>
                            en.value === get(vcDetails, 'entityType'),
                        ),
                        'text',
                      )}
                    </Typography>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.overViewSubHeadTitle}
                    >
                      Country of Business Operation
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.descTitletext}
                    >
                      {
                        getBusinessOpsCountryObject(
                          get(vcDetails, 'businessOpsCountry'),
                        )?.country
                      }
                    </Typography>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.overViewSubHeadTitle}
                    >
                      Fund Registered With
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.descTitletext}
                    >
                      {get(vcDetails, 'fundRegisteredWith')}
                    </Typography>
                  </>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {((UrlParamValue === 'VC_FIRM_SIGNUP' ||
        UrlParamValue === 'ANGEL_INVESTOR_REVIEW' ||
        UrlParamValue === 'YARDSTICK_USER_REVIEW') &&
        UrlParamStatus === 'PENDING') ||
      (UrlParamValue === 'VC_FIRM_REVIEW' && UrlParamStatus === 'PENDING') ? (
        <Box className={classes.backContinueBox}>
          <Button
            type="button"
            className={
              UrlParamValue === 'VC_FIRM_REVIEW'
                ? classes.approveVcFirmProfileBtn
                : classes.approveVcFirmBtn
            }
            name={
              UrlParamValue === 'VC_FIRM_REVIEW'
                ? 'Confirm & Review'
                : 'Confirm'
            }
            onClick={onSubmit}
          />
        </Box>
      ) : (
        ''
      )}
    </>
  );
};

export default VcFirmOverview;
