import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  reviewDocuBox: {
    textAlign: 'center',
    // marginBottom: 15,
  },
  reviewDocuText: {
    fontSize: 11,
    color: theme.palette.grey[600],
    marginBottom: 10,
  },
  bGDocuImg: {
    height: 100,
    width: 104,
    border: '1px solid #1EA59A',
    borderRadius: 5,
    marginTop: 25,
    cursor: 'pointer',
  },
  approvedBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  approvedBtnBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  rejectedBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  approvedbtn: {
    fontSize: 10,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    background: 'transparent',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
  },
  rejectedTxtBtn: {
    fontSize: 10,
    color: theme.palette.error.main,
    minWidth: 56,
    cursor: 'pointer',
    background: 'transparent',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.error.main,
      background: 'transparent',
    },
  },
  cancelIcon: {
    fontSize: 12,
    color: theme.palette.error.main,
    alignSelf: 'center',
  },
  circleOutline: {
    fontSize: 10,
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  approvIcon: {
    height: 11,
    alignSelf: 'center',
  },
  approveBtnPreview: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  rejectBtnPreview: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.error.main,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  approveImageButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  approvedImageButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  approveFont: {
    fontSize: '16px',
  },
  rejectedFont: {
    fontSize: '16px',
  },
  approveBtn: {
    fontSize: 10,
    height: 25,
    minWidth: 56,
    background: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid #1EA59A',
    borderRadius: 4.5,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
  },
  rejectBtn: {
    fontSize: 10,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    cursor: 'auto',
    height: 25,
    minWidth: 56,
    border: '1px solid',
    background: 'transparent',
    textTransform: 'capitalize',
    marginLeft: 8,
    '&:hover': {
      color: theme.palette.error.main,
      background: 'transparent',
    },
  },
  image: {
    height: '100px',
    width: 104,
    // maxWidth: '100%',
    // // transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    // boxSizing: 'border-box',
    // // boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    // zIndex: 5,
    // opacity: 1,
    cursor: 'pointer',
    marginTop: 25,
  },
  noDocText: {
    margin: 0,
    display: 'grid',
    gridGap: 20,
    gridTemplateColumns: 'repeat(auto-fill, minmax(106px, 1fr) )',
    padding: '5px 20px 10px 0',
  },
  loaderSection: {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    background: '#ffffff7d',
    height: '70%',
    // opacity: 0.5,
    marginTop: 40,
  },
  loaderBox: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '35%',
  },
  loaderSectionView: {
    marginTop: 40,
    textAlign: 'center',
  },
}));
