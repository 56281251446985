import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { Box } from 'components';

import useStyles from './styles';

const MultiValueTextField = ({
  control,
  name,
  defaultValue,
  placeholder,
  renderTags,
  limitTags,
  disabled,
  className,
  onInputChange,
  freeSolo,
  ...props
}: any) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Box className={classes.textFieldBox}>
            <Autocomplete
              limitTags={limitTags}
              multiple={true}
              id="tags-filled"
              options={[]}
              clearOnBlur={true}
              value={value || []}
              freeSolo={
                freeSolo ? (value.length > limitTags ? false : true) : freeSolo
              }
              onChange={(_, value: any) => {
                const fieldValue = value
                  .map((x: string) => x.trim())
                  .filter((x: string) => x);
                if (
                  (limitTags && fieldValue.length <= limitTags) ||
                  !limitTags
                ) {
                  onChange(fieldValue);
                }
                if (onInputChange) onChange(onInputChange(fieldValue));
              }}
              renderTags={(value: string[], getTagProps) =>
                renderTags(value, getTagProps)
              }
              renderInput={(params: any) => {
                // params.inputProps.onKeyDown = handleKeyDown;
                return (
                  <TextField
                    {...params}
                    className={className ? className : classes.textValInput}
                    onBlur={(event: any) => {
                      const eventValue = event.target.value?.trim();
                      if (!isEmpty(eventValue) && freeSolo) {
                        const fieldValue = [...value, eventValue];
                        if (
                          (limitTags && fieldValue.length <= limitTags) ||
                          !limitTags
                        ) {
                          onChange(fieldValue);
                        }
                      }
                      return true;
                    }}
                    InputProps={{
                      ...params.InputProps,
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    variant="standard"
                    error={!!error?.message}
                    placeholder={placeholder}
                    disabled={disabled}
                  />
                );
              }}
              {...props}
            />
            {error?.message && (
              <span className={classes.errorText}>{error?.message}</span>
            )}
          </Box>
        );
      }}
    />
  );
};

export default MultiValueTextField;
