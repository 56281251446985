import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  textFieldBox: {
    // position: 'relative',
    width: '100%',
  },
  textValInput: {
    marginBottom: 5,
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root .Mui-focused': {
      color: 'green',
    },
    '& .MuiChip-root': {
      color: theme.palette.common.black,
      borderRadius: 2,
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
      height: 20,
      width: 20,
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        color: theme.palette.common.black,
      },
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    color: '#404852',
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.main,
      },
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'red',
      paddingBottom: 10,
    },
  },
  labelFocused: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#404852',
      paddingBottom: 10,
    },
    '& .MuiInputLabel-root .Mui-focused': {
      marginTop: 10,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    // position: 'absolute',
    // bottom: 4,
    // left: 0,
  },
}));
