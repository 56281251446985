import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  detailsText: {
    fontSize: 29,
    color: theme.palette.error.contrastText,
    marginBottom: 50,
    marginTop: 12,
    paddingLeft: 2,
    position: 'relative',
    // top: -20,
    display: 'inline-block',
    '@media (max-width: 1120px)': {
      // marginBottom: 20,
      // marginTop: 30,
      fontSize: 24,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 6,
  },
  docuSignBox: {
    padding: '50px 168px 35px 188px',
    '@media (max-width: 1125px)': {
      padding: '50px 5% 35px',
    },
    '@media (max-width: 1024px)': {
      padding: '0px 5% 35px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5% 15px',
    },
  },
  docuSignText: {
    fontSize: 22,
    fontWeight: 700,
    letterSpacing: 0.3,
    color: theme.palette.warning.contrastText,
    lineHeight: '45px',
    marginBottom: 10,
  },
  docuSignBtn: {
    display: 'flex',
    padding: '30px 0px 20px',
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 280,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    marginRight: 20,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  prevButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    marginRight: 18,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));
