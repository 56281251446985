import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { get, isEmpty } from 'lodash';

// import styles from './styles';

const BarChart = ({ startupDetails, benchmarkingData, kpi, kpiLable }: any) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    BarElement,
  );

  // const classes = styles();
  const [chartLabel, setChartLabel] = useState<any>([]);
  const [chartGrowthData, setChartGrowthData] = useState<any>({});
  const chartRef = React.useRef<any>();

  const chartData = {
    labels: chartLabel.map((item: any) => item.title),
    datasets: [
      {
        label: 'Growth',
        barPercentage: 0.5,
        barThickness: 10,
        maxBarThickness: 12,
        minBarLength: 5,
        backgroundColor: '#1EA59A',
        data: chartGrowthData.kpiValue,
      },
    ],
  };

  const tileTooltip = (context: any) => {
    return `CMGR: ${get(chartGrowthData, 'cmgrValue')[context[0].dataIndex]} %`;
  };

  const options = {
    tension: 0.4,
    responsive: true,
    layout: {
      padding: {
        right: 30,
      },
    },
    plugins: {
      legend: {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick: () => {},
        display: true,
        text: 'Growth',
        align: 'end' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
        title: {
          font: {
            weight: '400',
            size: 12,
          },
        },
      },
      tooltip: {
        titleMarginBottom: 0,
        backgroundColor: '#FFFFFF',
        displayColors: true,
        titleColor: '#1EA59A',
        titleFont: {
          size: 13,
          weight: '400',
        },
        borderColor: '#1EA59A',
        borderWidth: 1,
        callbacks: {
          title: tileTooltip,
          label: function () {
            return '';
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        title: {
          display: true,
          text: kpiLable,
          font: {
            weight: '400',
            size: 14,
          },
          color: '#000000',
        },
      },
      x: {
        min: 0,
        max: 8,
        stacked: true,
        title: {
          display: true,
          font: {
            weight: '400',
            size: 14,
          },
          color: '#000000',
        },
        grid: {
          offset: true,
        },
      },
    },
  };

  const moveChart = {
    id: 'moveChart',
    afterEvent(chart: any, args: any) {
      const {
        canvas,
        chartArea: { left, right, top, height },
      } = chart;

      canvas.addEventListener('mousemove', () => {
        const x = args.event.x;
        const y = args.event.y;
        if (
          x >= left - 15 &&
          x <= left + 15 &&
          y >= height / 2 + top - 15 &&
          y <= height / 2 + top + 15
        ) {
          canvas.style.cursor = 'pointer';
        } else if (
          x >= right - 15 &&
          x <= right + 15 &&
          y >= height / 2 + top - 15 &&
          y <= height / 2 + top + 15
        ) {
          canvas.style.cursor = 'pointer';
        } else {
          canvas.style.cursor = 'default';
        }
      });
    },
    afterDraw(chart: any) {
      const {
        ctx,
        chartArea: { left, right, top, height },
      } = chart;
      class CircleChevron {
        draw(ctx: any, x1: any, pixel: any) {
          const angle = Math.PI / 180;
          ctx.beginPath();
          ctx.lineWidth = 3;
          ctx.strokeStyle = 'rgba(102,102,102,0.5)';
          ctx.fillStyle = 'white';
          ctx.arc(x1, height / 2 + top, 10, angle * 0, angle * 360, false);
          ctx.stroke();
          ctx.fill();
          ctx.closePath();

          ctx.beginPath();
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#1EA59A';
          ctx.moveTo(x1 + pixel, height / 2 + top - 5);
          ctx.lineTo(x1 - pixel, height / 2 + top);
          ctx.lineTo(x1 + pixel, height / 2 + top + 5);
          ctx.stroke();
          ctx.closePath();
        }
      }
      const drawCircleLeft = new CircleChevron();
      drawCircleLeft.draw(ctx, left, 3);
      const drawCircleRight = new CircleChevron();
      drawCircleRight.draw(ctx, right, -3);
    },
  };

  const moveScroll = () => {
    const {
      canvas,
      chartArea: { left, right, top, height },
    } = chartRef.current;
    canvas.addEventListener('click', (e: any) => {
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const dataLength = chartRef.current.config.data.labels.length;
      if (
        x >= left - 15 &&
        x <= left + 15 &&
        y >= height / 2 + top - 15 &&
        y <= height / 2 + top + 15
      ) {
        chartRef.current.config.options.scales.x.min -= 9;
        chartRef.current.config.options.scales.x.max -= 9;
        if (chartRef.current.config.options.scales.x.max <= 8) {
          chartRef.current.config.options.scales.x.min = 0;
          chartRef.current.config.options.scales.x.max = 8;
        }
      }
      if (
        x >= right - 15 &&
        x <= right + 15 &&
        y >= height / 2 + top - 15 &&
        y <= height / 2 + top + 15
      ) {
        chartRef.current.config.options.scales.x.min += 9;
        chartRef.current.config.options.scales.x.max += 9;
        if (chartRef.current.config.options.scales.x.max >= dataLength) {
          chartRef.current.config.options.scales.x.min = dataLength - 9;
          chartRef.current.config.options.scales.x.max = dataLength;
        }
      }
      chartRef.current.update();
    });
  };

  const scrollChart = (event: any) => {
    const dataLength = chartRef.current.data.labels.length;
    if (event.deltaY > 0) {
      chartRef.current.config.options.scales.x.min -= 9;
      chartRef.current.config.options.scales.x.max -= 9;
      if (chartRef.current.config.options.scales.x.min <= 8) {
        chartRef.current.config.options.scales.x.min = 0;
        chartRef.current.config.options.scales.x.max = 8;
      }
    }
    if (event.deltaY < 0) {
      chartRef.current.config.options.scales.x.min += 9;
      chartRef.current.config.options.scales.x.max += 9;
      if (chartRef.current.config.options.scales.x.max >= dataLength) {
        chartRef.current.config.options.scales.x.min = dataLength - 9;
        chartRef.current.config.options.scales.x.max = dataLength;
      }
    }
    chartRef.current.update();
  };

  const getAllMonths = (start: any, end: any) => {
    const data: any = [];
    Array.from({
      length: end.diff(start, 'months') + 1,
    }).forEach((_, index) => {
      if (index % startupDetails.benchmarkKPIFrequency === 0) {
        const newDate = moment(start).add(
          index + startupDetails.benchmarkKPIFrequency,
          'months',
        );
        data.push({
          name: `${moment(newDate).format('M_YYYY')}`,
          title: moment(newDate).format('MMM-YYYY'),
        });
      }
    });
    return data.reverse();
  };

  const growthRateData = (selectedKpi: string) => {
    const label = [
      ...getAllMonths(moment('12-2020', 'MM-YYYY'), moment()),
    ].reverse();
    const filterData = benchmarkingData.filter(
      (item: any) => item.kpiName === selectedKpi,
    );
    const newChartLabelData: any = [];
    let newChartData = label.map((monthYear: any) => {
      const [month, year] = monthYear.name.split('_');
      const monthYearData = filterData.find(
        (kpi: any) => +month === +kpi.month && +year === +kpi.year,
      );
      if (get(monthYearData, 'kpiValue') > 0) {
        newChartLabelData.push(monthYear);
      }
      return monthYearData;
    });
    setChartLabel(newChartLabelData);
    newChartData = newChartData.filter((element: any) => element !== undefined);
    setChartGrowthData({
      ...chartGrowthData,
      kpiValue: newChartData.map((item: any) => get(item, 'kpiValue')),
      cmgrValue: newChartData.map((item: any) => get(item, 'cmgrKpiValue')),
    });
  };

  useEffect(() => {
    if (!isEmpty(kpi)) growthRateData(kpi);
  }, [kpi]);

  useEffect(() => {
    if (!isEmpty(chartRef.current)) {
      const { canvas, ctx } = chartRef.current;
      ctx.onclick = moveScroll();
      canvas?.addEventListener('wheel', (e: any) => {
        scrollChart(e);
      });
    }
  }, [chartRef.current]);

  return (
    <>
      <Bar
        options={options}
        data={chartData}
        plugins={[moveChart]}
        id="benchmark-chart"
        ref={chartRef}
      />
    </>
  );
};

export default BarChart;
