import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';
import { getParsedPriceInputValue } from 'common/utils/helpers';

export const CreateNewPlanSchema = yup.object().shape({
  name: yup.string().required(requiredConstant),
  trialPeriod: yup
    .string()
    //.positive('Trial Period must be positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant)
    .when('planType', {
      is: 'YARDSTICK',
      then: yup
        .string()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .test(
          'max',
          'Maximum 5 Free output is allowed.',
          (value) =>
            value !== undefined &&
            value !== null &&
            getParsedPriceInputValue(value) <= 5,
        )
        .test(
          'min',
          'Minimum 1 Free output is allowed.',
          (value) =>
            value !== undefined &&
            value !== null &&
            getParsedPriceInputValue(value) > 0,
        ),
      otherwise: yup
        .string()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .test(
          'positive',
          'Trial Period must be greater than 0',
          (value) =>
            value !== undefined &&
            value !== null &&
            getParsedPriceInputValue(value) > 0,
        ),
    }),

  amount: yup
    .string()
    //.positive('Amount must be positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant)
    .test(
      'positive',
      'Amount must be greater than 0',
      (value) =>
        value !== undefined &&
        value !== null &&
        getParsedPriceInputValue(value) > 0,
    ),
  planType: yup.string().required(requiredConstant),
});

export const VcCreateNewPlanSchema = yup.object().shape({
  name: yup.string().required(requiredConstant),
  trialPeriod: yup
    .string()
    //.positive('Trial Period must be positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant)
    .test(
      'positive',
      'Value must be greater than 0',
      (value) =>
        value !== undefined &&
        value !== null &&
        getParsedPriceInputValue(value) > 0,
    ),
  amount: yup
    .string()
    //.positive('Amount must be positive number')
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant)
    .test(
      'positive',
      'Value must be greater than 0',
      (value) =>
        value !== undefined &&
        value !== null &&
        getParsedPriceInputValue(value) > 0,
    ),
  planType: yup.string().required(requiredConstant),
});
