import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';
import SimpleLayout from 'layouts/SimpleLayout';

import { BenchmarkWelcome } from './Steps';
import BenchMark from './index';

export const BenchMarkRouteV2: IRoute = {
  path: '/benchmark-kpi-v2',
  component: BenchmarkWelcome,
  layout: DashboardLayout,
  exact: true,
};

export const BenchMarkStartupRoute: IRoute = {
  path: '/benchmark-kpi-v2/:type',
  component: BenchMark,
  layout: SimpleLayout,
};
