import React from 'react';
import { FormControl, Grid, TextField } from '@material-ui/core';
import { get, omit, isEmpty, find } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

import { WrappedSelect, Button } from 'components';

import styles from './styles';

type FilterRefferalDataProps = {
  adminFilters: Array<Record<string, any>>;
  queryFilter: Record<string, any>;
  setQueryFilter: any;
};

const FilterRefferalData = ({
  adminFilters,
  queryFilter = {},
  setQueryFilter,
}: FilterRefferalDataProps) => {
  const classes = styles();

  const [
    rerenderAutocomplete,
    setRerenderAutocomplete,
  ] = React.useState<number>(1);

  const [
    rerenderAutocompleteName,
    setRerenderAutocompleteName,
  ] = React.useState<number>(1);

  const resetFilters = () => {
    setQueryFilter({});
    setRerenderAutocomplete(0);
    setRerenderAutocompleteName(0);
  };

  return (
    <>
      <Grid item xs={3} md={3}>
        <FormControl fullWidth className={classes.filterInput}>
          <Autocomplete
            size={'small'}
            key={rerenderAutocomplete}
            id="referred-by"
            autoComplete
            disableClearable
            value={
              !isEmpty(get(queryFilter, 'referrerId'))
                ? find(get(adminFilters, 'investors'), {
                    value: get(queryFilter, 'referrerId'),
                  })
                : null
            }
            options={
              (!isEmpty(adminFilters) &&
                get(adminFilters, 'investors').map((item: any) => ({
                  text: item.text,
                  value: item.value,
                }))) ||
              []
            }
            getOptionLabel={(option: any) => option.text || ''}
            getOptionSelected={(option: any, value: any) => {
              return option.value === value?.value;
            }}
            onChange={(_: any, value: any) => {
              setRerenderAutocomplete(1);
              if (value !== null) {
                setQueryFilter({ ...queryFilter, referrerId: value.value });
              } else {
                setQueryFilter(omit(queryFilter, ['referrerId']));
              }
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant={'outlined'}
                placeholder="Referred by"
                label="Referred by"
                className={classes.textValInput}
                InputProps={{
                  ...params.InputProps,
                  classes: { root: classes.inputRootFilter },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} md={3}>
        <FormControl fullWidth className={classes.filterInput}>
          <Autocomplete
            size={'small'}
            key={rerenderAutocompleteName}
            id="referee-name"
            autoComplete
            disableClearable
            options={
              (!isEmpty(adminFilters) &&
                get(adminFilters, 'investors').map((item: any) => ({
                  text: item.text,
                  value: item.value,
                }))) ||
              []
            }
            value={
              !isEmpty(get(queryFilter, 'refereeId'))
                ? find(get(adminFilters, 'investors'), {
                    value: get(queryFilter, 'refereeId'),
                  })
                : null
            }
            getOptionLabel={(option: any) => option.text || ''}
            getOptionSelected={(option: any, value: any) => {
              return option.value === value?.value;
            }}
            onChange={(_: any, value: any) => {
              setRerenderAutocompleteName(1);
              if (value !== null) {
                setQueryFilter({ ...queryFilter, refereeId: value.value });
              } else {
                setQueryFilter(omit(queryFilter, ['refereeId']));
              }
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant={'outlined'}
                label="Name of referee"
                placeholder="Name of referee"
                className={classes.textValInput}
                InputProps={{
                  ...params.InputProps,
                  classes: { root: classes.inputRootFilter },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} md={3}>
        <FormControl fullWidth className={classes.filterInput}>
          <TextField
            variant={'outlined'}
            id="referal_date"
            label="Referral date"
            placeholder="Referral date"
            type="date"
            value={get(queryFilter, 'referralDate')}
            className={classes.textValInput}
            InputProps={{
              classes: { root: classes.inputRootFilter },
              inputProps: {
                max: moment().format('YYYY-MM-DD'),
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused,
              },
              shrink: true,
            }}
            size="small"
            onChange={(e: any) => {
              const value = e.target.value;
              if (value !== null) {
                setQueryFilter({
                  ...queryFilter,
                  referralDate: e.target.value,
                });
              } else {
                setQueryFilter(omit(queryFilter, ['referralDate']));
              }
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} md={2}>
        <FormControl fullWidth className={classes.filterInput}>
          <WrappedSelect
            variant={'outlined'}
            label="Status"
            placeholder="Status"
            value={get(queryFilter, 'status')}
            onChange={(e: any) => {
              setQueryFilter({ ...queryFilter, status: e.target.value });
            }}
            className={classes.selectValInput}
            InputProps={{
              classes: { root: classes.inputRootFilter },
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused,
              },
            }}
            dropdowns={[
              { text: 'Yet to Sign up', value: 'INVITED' },
              { text: 'Signed up', value: 'SIGNED_UP' },
              { text: 'Invested in deal', value: 'INVESTED' },
              { text: 'Deal Closed', value: 'DEAL_CLOSED' },
            ]}
            error={false}
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} md={3}>
        <FormControl fullWidth className={classes.filterInput}>
          <WrappedSelect
            variant={'outlined'}
            label="First SPV"
            placeholder="First SPV"
            value={get(queryFilter, 'referralDealId')}
            onChange={(e: any) => {
              setQueryFilter({
                ...queryFilter,
                referralDealId: e.target.value,
              });
            }}
            className={classes.selectValInput}
            InputProps={{
              classes: { root: classes.inputRootFilter },
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused,
              },
            }}
            dropdowns={get(adminFilters, 'deals') || []}
            error={false}
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} md={3}>
        <FormControl fullWidth className={classes.filterInput}>
          <TextField
            variant={'outlined'}
            id="date_invested"
            label="Date Invested"
            placeholder="Date Invested"
            type="date"
            value={get(queryFilter, 'investedDate')}
            className={classes.textValInput}
            InputProps={{
              classes: { root: classes.inputRootFilter },
              inputProps: {
                max: moment().format('YYYY-MM-DD'),
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused,
              },
              shrink: true,
            }}
            size="small"
            onChange={(e: any) => {
              const value = e.target.value;
              if (value !== null) {
                setQueryFilter({
                  ...queryFilter,
                  investedDate: e.target.value,
                });
              } else {
                setQueryFilter(omit(queryFilter, ['investedDate']));
              }
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} md={2}>
        <FormControl fullWidth className={classes.filterInput}>
          <WrappedSelect
            variant={'outlined'}
            label="Referral"
            placeholder="Referral"
            value={get(queryFilter, 'referralPage')}
            onChange={(e: any) => {
              setQueryFilter({
                ...queryFilter,
                referralPage: e.target.value,
              });
            }}
            className={classes.selectValInput}
            InputProps={{
              classes: { root: classes.inputRootFilter },
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused,
              },
            }}
            dropdowns={[
              { text: 'Offline', value: 'Offline' },
              { text: 'Deal', value: 'Deal' },
              { text: 'Refer and Earn', value: 'Refer and Earn' },
            ]}
            error={false}
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={5} md={3}>
        <Grid item>
          <Button
            name="Reset"
            variant="contained"
            className={classes.referralBtn}
            onClick={resetFilters}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FilterRefferalData;
