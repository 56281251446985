import React, { useEffect, useState, ChangeEvent } from 'react';
import moment from 'moment';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  createStyles,
  Theme,
  TableBody,
  Grid,
  Typography,
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import Pagination from '@material-ui/lab/Pagination';

import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { getAdminFcrmLists, getAdminMasterFcrmLists } from 'services';
import { AdminFcrmListsParams } from 'common/types';
import { WrappedSelect, Loader } from 'components';
import { fcrmOptions } from 'common/utils/optionList';
import history from 'common/utils/history';

import styles from './styles';

const columns = [
  {
    field: 'startup.name',
    name: 'Startup',
    sticky: true,
    align: 'center',
  },
  {
    field: 'roundName',
    name: 'Round Name',
    align: 'center',
    sort: true,
  },
  {
    field: 'targetAmount',
    name: 'Target Amount',
    type: 'AMOUNT',
    align: 'center',
  },
  {
    field: 'tentativeValuation',
    name: 'Tentative Valuation',
    type: 'AMOUNT',
    align: 'center',
  },
  {
    field: 'totalExpectedAmount',
    name: 'Total Expected Amount',
    type: 'AMOUNT',
    sticky: true,
    align: 'center',
  },
  {
    field: 'totalInvestedAmount',
    name: 'Total Invested Amount',
    align: 'center',
  },
  {
    field: 'totalInvestors',
    name: 'Total Investors',
    type: 'DROPDOWN',
    align: 'center',
  },
  {
    field: 'uploaded',
    name: 'Excel Upload',
    align: 'center',
    sort: true,
  },
  {
    field: 'completedPercent',
    name: 'Completed Percent',
    align: 'center',
    sort: true,
  },
  {
    field: 'updatedAt',
    name: 'Last Updated',
    align: 'center',
  },
  {
    field: 'actions',
    name: 'Actions',
    align: 'center',
  },
];

const columnsmaster = [
  {
    field: 'startup.name',
    name: 'Startup',
    sticky: true,
    align: 'center',
  },
  {
    field: 'fund',
    name: 'Fund',
    align: 'center',
    sort: true,
  },
  {
    field: 'histAverageCheckSize',
    name: 'Total Expected Amount',
    type: 'AMOUNT',
    sticky: true,
    align: 'center',
  },
  {
    field: 'name',
    name: 'Name',
    align: 'center',
  },
  {
    field: 'title',
    name: 'Title',
    type: 'DROPDOWN',
    align: 'center',
  },
  {
    field: 'email',
    name: 'Email',
    align: 'center',
    sort: true,
  },
  {
    field: 'type',
    name: 'Type',
    align: 'center',
    sort: true,
  },
  {
    field: 'ycAlum',
    name: 'YC Alumni',
    align: 'center',
    sort: true,
  },
];

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontFamily: theme.typography.fontFamily,
      textAlign: 'left',
      color: '#000',
      border: '1px solid #dedede',
      backgroundColor: '#93C9C7 !important',
      fontWeight: 400,
      fontSize: 15,
      letterSpacing: 0.3,
      minWidth: 100,
    },
    body: {
      // background: theme.palette.common.white,
      background: 'white',
      color: '#000',
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      border: '1px solid #dedede',
      textAlign: 'center',
      // cursor: 'pointer',
      padding: 10,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const AdminFCRMList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [adminFcrmLists, setAdminFcrmLists] = React.useState<any>([]);
  const [adminMasterFcrmLists, setAdminMasterFcrmLists] = React.useState<any>(
    [],
  );

  const [totalPages, setTotalPages] = React.useState<number>(3);
  const [totalRows, setTotalRows] = React.useState<number>(0);

  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [selectFcrm, setSelectFcrm] = React.useState<string>('STARTUPS');

  const getAllAdminFcrmLists = (obj: any) => {
    setErrorMessage('');
    setIsLoading(true);
    getAdminFcrmLists(obj)
      .then((res) => {
        setAdminFcrmLists(get(res, 'fcrms') || []);
        setTotalPages(Math.ceil(get(res, 'totalPages')));
        setTotalRows(get(res, 'totalRows'));
        setIsLoading(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const getAllAdminMasterFcrmLists = (obj: any) => {
    setErrorMessage('');
    setIsLoading(true);
    getAdminMasterFcrmLists(obj)
      .then((res) => {
        setAdminMasterFcrmLists(get(res, 'fcrms') || []);
        setTotalPages(Math.ceil(get(res, 'totalPages')));
        setTotalRows(get(res, 'totalRows'));
        setIsLoading(false);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const obj: AdminFcrmListsParams = {
      page: 0,
      limit: 20,
    };
    if (selectFcrm === 'STARTUPS') {
      getAllAdminFcrmLists(obj);
    }
    if (selectFcrm === 'MASTER') {
      getAllAdminMasterFcrmLists(obj);
    }
  }, [selectFcrm]);

  const onChangePagination = (e: any, page: number) => {
    setCurrentPage(page - 1);
    if (selectFcrm === 'STARTUPS') {
      getAllAdminFcrmLists({ page: page - 1, limit: 20 });
    }
    if (selectFcrm === 'MASTER') {
      getAllAdminMasterFcrmLists({ page: page - 1, limit: 20 });
    }
  };

  const classes = styles();
  return (
    <Box className={classes.vFcrmListsSection}>
      {/*<Box className={classes.vAllFcrmHead}>
        <img src={Ornament} alt={Ornament} className={classes.vEnquiryImage} />
        <Typography variant="h6" className={classes.headText}>
          All FCRMs
        </Typography>
      </Box>*/}
      <Grid className={classes.vTopHeadSection}>
        <Box>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.vAllFcrmCount}
          >
            {totalRows}{' '}
            {selectFcrm === 'STARTUPS' ? 'CRM Users' : 'Master Fcrms'}
          </Typography>
        </Box>
        <Box>
          <WrappedSelect
            variant={'outlined'}
            value={selectFcrm}
            label="Select FCRM"
            onChange={(e: ChangeEvent<any>) => setSelectFcrm(e.target.value)}
            className={classes.vSelectFcrm}
            InputProps
            InputLabelProps
            SelectProps
            dropdowns={fcrmOptions}
            disabled={false}
            error={false}
            defaultValue="STARTUPS"
          />
        </Box>
      </Grid>
      {selectFcrm === 'STARTUPS' && (
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns.map((col: any) => (
                  <StyledTableCell
                    key={col.field}
                    style={{
                      textAlign: col.align,
                      cursor: 'pointer',
                    }}
                  >
                    <Box className={classes.vTableHeader}>{col.name}</Box>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <Box>
                  <Loader />
                </Box>
              ) : isEmpty(adminFcrmLists) && !errorMessage ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={11}>
                    <Box>
                      <Typography>No data found in table</Typography>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                (adminFcrmLists || []).map((row: any) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>{get(row, 'startup.name')}</Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>
                        {get(row, 'roundName') &&
                          get(row, 'roundName').replace(/_/gi, ' ')}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>{formatAmount(get(row, 'targetAmount'))}</Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>
                        {formatAmount(get(row, 'tentativeValuation'))}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>
                        {formatAmount(get(row, 'totalExpectedAmount'))}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>
                        {formatAmount(get(row, 'totalInvestedAmount'))}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid className={classes.vTextCenter}>
                        {get(row, 'totalInvestors')}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid className={classes.vTextCenter}>
                        {get(row, 'uploaded').toString() === 'true'
                          ? 'Yes'
                          : 'No'}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid className={classes.vTextCenter}>
                        {get(row, 'completedPercent')}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid className={classes.vTextCenter}>
                        {moment(get(row, 'updatedAt')).format('lll')}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ textAlign: 'center', cursor: 'pointer' }}
                    >
                      <Grid className={classes.vDetailBtn}>
                        <Typography
                          variant="body1"
                          className={classes.viewButton}
                          onClick={() =>
                            history.push(`/admin/fcrms/${row.startupId}`)
                          }
                        >
                          View
                        </Typography>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {selectFcrm === 'MASTER' && (
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                {columnsmaster.map((col: any) => (
                  <StyledTableCell
                    key={col.field}
                    style={{
                      textAlign: col.align,
                      cursor: 'pointer',
                    }}
                  >
                    <Box className={classes.vTableHeader}>{col.name}</Box>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <Box>
                  <Loader />
                </Box>
              ) : isEmpty(adminMasterFcrmLists) && !errorMessage ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>
                    <Box>
                      <Typography>No data found in table</Typography>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                (adminMasterFcrmLists || []).map((row: any) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>{get(row, 'startup.name')}</Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>{get(row, 'fund')}</Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>
                        {parseInt(get(row, 'histAverageCheckSize'))
                          ? formatAmount(get(row, 'histAverageCheckSize'))
                          : ' '}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>{get(row, 'name')}</Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>{get(row, 'title')}</Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid>{get(row, 'email')}</Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid className={classes.vTextCenter}>
                        {get(row, 'type')}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid className={classes.vTextCenter}>
                        {get(row, 'ycAlum') === 'TRUE' ? 'Yes' : 'No'}
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box className={classes.vPaginationSection}>
        <Pagination
          onChange={onChangePagination} //eslint-disable-line
          count={totalPages}
          page={currentPage + 1}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default AdminFCRMList;
