import {
  IDoc,
  DocumentType,
  PayloadType,
  ListDocType,
  SubmitDocuSignDocsType,
  DocuSignPayload,
  VDApproveType,
  ApproveRejectDocumentsType,
} from './Types';

export const TEST = 'global/test';
export const ADD_REQUEST_DOCUMENTS = 'admin/GET_ALL_COUNTRIES';
export const DELETE_REQUEST_DOUCUMENTS = 'admin/DELETE_REQUEST_DOUCUMENTS';
export const ADD_YCBATCH_STARTUPID = 'admin/ADD_YCBATCH_STARTUPID';
export const SUBMIT_DOCUMENTS = 'admin/SUBMIT_DOCUMENTS';
export const SUBMIT_DOCUMENTS_LOADING = 'admin/SUBMIT_DOCUMENTS_LOADING';
export const SUBMIT_DOCUMENTS_SUCCESS = 'admin/SUBMIT_DOCUMENTS_SUCCESS';
export const SUBMIT_DOCUMENTS_FAILURE = 'admin/SUBMIT_DOCUMENTS_FAILURE';
export const UPDATE_DOCUMENTS = 'user/UPDATE_DOCUMENTS';
export const GET_LIST_DOUCMENTS = 'admin/GET_LIST_DOUCMENTS';
export const GET_LIST_DOUCMENTS_LOADING = 'admin/GET_LIST_DOUCMENTS_LOADING';
export const GET_LIST_DOUCMENTS_FAILURE = 'admin/GET_LIST_DOUCMENTS_FAILURE';
export const GET_LIST_DOUCMENTS_SUCCESS = 'admin/GET_LIST_DOUCMENTS_SUCCESS';
export const GET_LIST_DOUCMENTS_STARTUP = 'startup/GET_LIST_DOUCMENTS_STARTUP';
export const APPROVE_DOCUMENTS = 'admin/APPROVE_DOCUMENTS';
export const APPROVE_DOCUMENTS_LOADING = 'admin/APPROVE_DOCUMENTS_LOADING';
export const APPROVE_DOCUMENTS_SUCCESS = 'admin/APPROVE_DOCUMENTS_SUCCESS';
export const APPROVE_DOCUMENTS_FAILURE = 'admin/APPROVE_DOCUMENTS_FAILURE';
export const APPROVE_LOAN = 'admin/APPROVE_LOAN';
export const MANAGE_LOAN = 'admin/MANAGE_LOAN';
export const MANAGE_LOAN_LOADING = 'admin/MANAGE_LOAN_LOADING';
export const MANAGE_LOAN_SUCCESS = 'admin/MANAGE_LOANSUCCESS';
export const MANAGE_LOAN_FAILURE = 'admin/MANAGE_LOAN_FAILURE';
export const MANAGE_LOAN_HOLD_REJECT = 'admin/MANAGE_LOAN_HOLD_REJECT';

export const APPROVE_LOAN_LOADING = 'admin/APPROVE_LOAN_LOADING';
export const APPROVE_LOAN_SUCCESS = 'admin/APPROVE_LOAN_SUCCESS';
export const APPROVE_LOAN_FAILURE = 'admin/APPROVE_LOAN_FAILURE';
export const PROCESS_LOAN = 'admin/PROCESS_LOAN';
export const PROCESS_LOAN_LOADING = 'admin/PROCESS_LOAN_LOADING';
export const PROCESS_LOAN_SUCCESS = 'admin/PROCESS_LOAN_SUCCESS';
export const PROCESS_LOAN_FAILURE = 'admin/PROCESS_LOAN_FAILURE';
export const GET_DOCU_SIGN_DOCUMENTS_ADMIN =
  'admin/GET_DOCU_SIGN_DOCUMENTS_ADMIN';
export const GET_DOCU_SIGN_DOCUMENTS_ADMIN_LOADING =
  'admin/GET_DOCU_SIGN_DOCUMENTS_ADMIN_LOADING';
export const GET_DOCU_SIGN_DOCUMENTS_ADMIN_SUCCESS =
  'admin/GET_DOCU_SIGN_DOCUMENTS_ADMIN_SUCCESS';
export const GET_DOCU_SIGN_DOCUMENTS_ADMIN_FAILURE =
  'admin/GET_DOCU_SIGN_DOCUMENTS_ADMIN_FAILURE';
export const ADD_REQUEST_DOCU_SIGN_ADMIN = 'admin/ADD_REQUEST_DOCU_SIGN_ADMIN';

export const SUBMIT_DOCU_SIGN_DOCS = 'admin/SUBMIT_DOCU_SIGN_DOCS';
export const SUBMIT_DOCU_SIGN_DOCS_LOADING =
  'admin/SUBMIT_DOCU_SIGN_DOCS_LOADING';
export const SUBMIT_DOCU_SIGN_DOCS_SUCCESS =
  'admin/SUBMIT_DOCU_SIGN_DOCS_SUCCESS';
export const SUBMIT_DOCU_SIGN_DOCS_FAILURE =
  'admin/SUBMIT_DOCU_SIGN_DOCS_FAILURE';
export const VD_APPROVE_LOAN = 'admin/VD_APPROVE_LOAN';
export const VD_APPROVE_LOAN_LOADING = 'admin/VD_APPROVE_LOAN_LOADING';
export const VD_APPROVE_LOAN_SUCCESS = 'admin/VD_APPROVE_LOAN_SUCCESS';
export const VD_APPROVE_LOAN_FAILURE = 'admin/VD_APPROVE_LOAN_FAILURE';
export const APPROVE_VD_REQUEST_ADMIN = 'admin/APPROVE_VD_REQUEST_ADMIN';
export const APPROVE_VD_REQUEST_ADMIN_LOADING =
  'admin/APPROVE_VD_REQUEST_ADMIN_LOADING';
export const APPROVE_VD_REQUEST_ADMIN_SUCCESS =
  'admin/APPROVE_VD_REQUEST_ADMIN_SUCCESS';
export const APPROVE_VD_REQUEST_ADMIN_FAILURE =
  'admin/APPROVE_VD_REQUEST_ADMIN_FAILURE';
export const EMPTY_VD_REQUEST_STATE = 'EMPTY_VD_REQUEST_STATE';
export const EMPTY_APPROVE_VD_LOAN_STATE = 'EMPTY_APPROVE_VD_LOAN_STATE';
export const EMPTY_SUCCESS_MESSAGE = 'EMPTY_SUCCESS_MESSAGE';
export const EMPTY_DOCU_SIGN_STATE = 'EMPTY_DOCU_SIGN_STATE';
export const APPROVE_YC_DOCUMENTS = 'admin/APPROVE_YC_DOCUMENTS';
export const APPROVE_YC_DOCUMENTS_LOADING =
  'admin/APPROVE_YC_DOCUMENTS_LOADING';
export const APPROVE_YC_DOCUMENTS_SUCCESS =
  'admin/APPROVE_YC_DOCUMENTS_SUCCESS';
export const APPROVE_YC_DOCUMENTS_FAILURE =
  'admin/APPROVE_YC_DOCUMENTS_FAILURE';

export const test = (payload: Array<any>) => ({ type: TEST, payload });

export const addDocuments = (payload: IDoc) => {
  return {
    type: ADD_REQUEST_DOCUMENTS,
    payload,
  };
};

export const AddYCBatchStartupId = (payload: PayloadType) => {
  return {
    type: ADD_YCBATCH_STARTUPID,
    payload,
  };
};

export const SubmitDocuments = (payload: DocumentType) => {
  return {
    type: SUBMIT_DOCUMENTS,
    payload,
  };
};

export const deleteDocuments = (payload: string) => {
  return {
    type: DELETE_REQUEST_DOUCUMENTS,
    payload,
  };
};

export const handleUpdateDoucuments = (payload: IDoc) => {
  return {
    type: UPDATE_DOCUMENTS,
    payload,
  };
};

export const getDocumentlist = (payload: ListDocType) => {
  return {
    type: GET_LIST_DOUCMENTS,
    payload,
  };
};

export const getStartUpDocumentlist = (payload: ListDocType) => {
  return {
    type: GET_LIST_DOUCMENTS_STARTUP,
    payload,
  };
};

export const approveDocuments = (payload: ApproveRejectDocumentsType) => {
  return {
    type: APPROVE_DOCUMENTS,
    payload,
  };
};

export const approveYCDocuments = (payload: ApproveRejectDocumentsType) => {
  return {
    type: APPROVE_YC_DOCUMENTS,
    payload,
  };
};

export const getDocuSignDocAdmin = (payload: DocuSignPayload) => {
  return {
    type: GET_DOCU_SIGN_DOCUMENTS_ADMIN,
    payload,
  };
};

export const approveLoan = (payload: any) => {
  return {
    type: APPROVE_LOAN,
    payload,
  };
};

export const handleManageLoan = (payload: any) => {
  return {
    type: MANAGE_LOAN,
    payload,
  };
};

export const handleApproveVDLoan = (payload: VDApproveType) => {
  return {
    type: VD_APPROVE_LOAN,
    payload,
  };
};
export const handleDocuSign = (payload: any) => {
  return {
    type: ADD_REQUEST_DOCU_SIGN_ADMIN,
    payload,
  };
};

export const processLoan = (payload: any) => {
  return {
    type: PROCESS_LOAN,
    payload,
  };
};

export const handleSubmitDocs = (payload: SubmitDocuSignDocsType) => {
  return {
    type: SUBMIT_DOCU_SIGN_DOCS,
    payload,
  };
};

export const approveVdRequest = (payload: string) => {
  return {
    type: APPROVE_VD_REQUEST_ADMIN,
    payload,
  };
};

export const emptyApproveVdReqState = () => {
  return {
    type: EMPTY_VD_REQUEST_STATE,
  };
};

export const emptyApproveVdLoanState = () => {
  return {
    type: EMPTY_APPROVE_VD_LOAN_STATE,
  };
};

export const emptySuccessMessage = () => {
  return {
    type: EMPTY_SUCCESS_MESSAGE,
  };
};

export const emptyDocuSignState = () => {
  return {
    type: EMPTY_DOCU_SIGN_STATE,
  };
};
