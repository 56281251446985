import { isEmpty } from 'lodash';

import { urlConstants } from 'common/utils/constants';

import { secureApi } from '../api/Axios';

export const getAdminReferralFilters = () =>
  secureApi.get(`${urlConstants.adminReferralFilters}`);

export const addReferralDetailByAdmin = (payload: any, type: any) =>
  secureApi.post(`${urlConstants.addReferralDetailByAdmin}/${type}`, payload);

export const getAllAdminReferral = (type: any, params?: any) =>
  !isEmpty(params)
    ? secureApi.get(`${urlConstants.getAllAdminReferral}/${type}`, {
        params: params,
      })
    : secureApi.get(`${urlConstants.getAllAdminReferral}/${type}`);
