import { IRoute } from 'common/types';
import AuthLayout from 'layouts/AuthLayout';

import SignIn from './SignIn';

export const SignInRoute: IRoute = {
  path: '/sign-in',
  component: SignIn,
  layout: AuthLayout,
  exact: true,
};
