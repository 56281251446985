import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    padding: '0px 45px',
  },
  //////////////////////
  tableContainer: {
    boxShadow: theme.shadows[0],
    height: 540,
    overflow: 'overlay',
    borderRadius: 11,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTableCell-head': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.success.dark,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '26px',
      padding: '16px 8px',
      textAlign: 'left',
      '&:first-child': {
        borderRadius: '10px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.black,
      background: 'inherit',
      fontSize: 16,
      padding: '8px',
      textAlign: 'left',
      '@media (max-width: 768px)': {
        fontWeight: 500,
      },
    },
    '& .MuiTableRow-root': {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
    '& .MuiTableBody-root': {
      height: 'calc(100% - 73px)',
    },
  },
  actionButtonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  updatedTime: {
    fontSize: 12,
    textAlign: 'start',
  },
  viewButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid #1EA59A',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    padding: '4px 0px 4px',
    margin: '5px 0px',
    cursor: 'pointer',
    width: '40%',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      width: '80%',
    },
  },
  actionButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid #1EA59A',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    marginLeft: 3,
    padding: 4,
    paddingLeft: 10,
    margin: '5px 0px',
    cursor: 'pointer',
    width: '40%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
      width: '62%',
    },
  },
  actionItem: {
    backgroundColor: theme.palette.common.white,
  },
  actionIcon: {
    color: theme.palette.common.black,
    marginRight: 5,
  },
  // Delete Dialog
  deleteText: {
    fontSize: 16,
  },
  backSubmitBox: {
    paddingTop: 10,
    paddingRight: 10,
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  submitBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 120,
    height: 48,
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  nonPublishTag: {
    borderRadius: 3,
    padding: '2px 5px',
    fontSize: 12,
    background: '#0A6DF7',
    marginLeft: 10,
    color: '#FFFFFF',
  },
}));
