import { IRoute } from 'common/types';
import AuthLayout from 'layouts/AuthLayout';

import SignUp from './SignUp';

export const SignUpRoute: IRoute = {
  path: '/sign-up',
  component: SignUp,
  layout: AuthLayout,
  exact: true,
};
