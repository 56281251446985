import { urlConstants } from 'common/utils/constants';

import { secureApi } from '../api/Axios';

export const createOrUpdateStartupMultipleKpiData = (
  payload: any,
  role: string,
) =>
  role === 'ADMIN'
    ? secureApi.post(`${urlConstants.adminCreateOrUpdateStartupKpi}`, payload)
    : secureApi.post(
        `${urlConstants.createOrUpdateStartupMultipleKpiData}`,
        payload,
      );

export const createOrUpdateStartupMultipleFundraising = (
  payload: any,
  role: string,
) =>
  role === 'ADMIN'
    ? secureApi.post(`${urlConstants.adminCreateOrUpdateFundraising}`, payload)
    : secureApi.post(
        `${urlConstants.createOrUpdateStartupMultipleFundraising}`,
        payload,
      );

export const getStartupBenchmarkingKpi = (startupId: any, role: string) =>
  role === 'ADMIN'
    ? secureApi.get(
        `${urlConstants.getBenchmarkingKpiByStartupId}/${startupId}`,
      )
    : secureApi.get(`${urlConstants.getStartupBenchmarkingKpi}/${startupId}`);

export const getStartupBenchmarkFundraisingKpi = (
  startupId: any,
  role: string,
) =>
  role === 'ADMIN'
    ? secureApi.get(`${urlConstants.getFundraisingKpiByStartupId}/${startupId}`)
    : secureApi.get(
        `${urlConstants.getStartupBenchmarkFundraisingKpi}/${startupId}`,
      );

export const getStartupBenchmarkKpiGrowth = (
  startupId: any,
  payload: any,
  role: string,
) =>
  role === 'ADMIN'
    ? secureApi.post(
        `${urlConstants.getBenchmarkKpiGrowthBystartupId}/${startupId}`,
        payload,
      )
    : secureApi.post(
        `${urlConstants.getStartupBenchmarkKpiGrowth}/${startupId}`,
        payload,
      );

export const deleteStartupBenchmarkKpi = (payload: any, role: string) =>
  role === 'ADMIN'
    ? secureApi.post(`${urlConstants.adminDeleteBenchmarkingKpi}`, payload)
    : secureApi.post(`${urlConstants.deleteStartupBenchmarkingKpi}`, payload);

export const deleteStartupBenchmarkFundraisingKpi = (
  payload: any,
  role: string,
) =>
  role === 'ADMIN'
    ? secureApi.post(
        `${urlConstants.adminDeleteBenchmarkFundraisingKpi}`,
        payload,
      )
    : secureApi.post(
        `${urlConstants.deleteStartupBenchmarkFundraisingKpi}`,
        payload,
      );

export const getAdminAllStartupPercentile = () =>
  secureApi.get(`${urlConstants.getAdminAllStartupPercentile}`);

export const getAdminAllNonPrimaryStartupsGrowth = () =>
  secureApi.get(`${urlConstants.getAdminAllNonPrimaryStartupsGrowth}`);

export const getAdminPreviewPercentileGrowthRates = () =>
  secureApi.get(`${urlConstants.getAdminPreviewPercentileGrowthRates}`);

export const getAdminPercentileGrowth = (payload: any) =>
  secureApi.post(`${urlConstants.getAdminPercentileGrowth}`, payload);

export const publishBenchmarkingGrowthRates = (payload: any) =>
  secureApi.post(`${urlConstants.publishBenchmarkingGrowthRates}`, payload);

export const getBenchmarkingStartups = () =>
  secureApi.get(`${urlConstants.getBenchmarkingStartups}`);

export const deleteStartupsAllBenchmarkingData = (startupId: any) =>
  secureApi.delete(
    `${urlConstants.adminDeleteStartupsAllBenchmarkingData}/${startupId}`,
  );

export const skipStartupsAllBenchmarkingData = (startupId: any) =>
  secureApi.post(
    `${urlConstants.adminSkipStartupsAllBenchmarkingData}/${startupId}`,
  );

export const getStartupsBySearchString = (payload: any) =>
  secureApi.get(`${urlConstants.getStartupsBySearchString}?text=${payload}`);

export const getPublishedDates = () =>
  secureApi.get(`${urlConstants.getPublishedDates}`);

export const getPublishedPercentileGrowth = (payload: any) =>
  secureApi.post(`${urlConstants.getPublishedPercentileGrowth}`, payload);

export const getSignedUrlForUploadStartups = (payload: any) =>
  secureApi.post(`${urlConstants.adminGetUploadStartupsSignedurl}`, payload);

export const adminUploadStartups = (payload: any) =>
  secureApi.post(`${urlConstants.adminUploadStartups}`, payload);

export const getBenchmarkStartupKpiSignedUrl = (payload: any) =>
  secureApi.post(`${urlConstants.getBenchmarkStartupKpiSignedUrl}`, payload);

export const addStartupBenchmarkInfo = (payload: any) =>
  secureApi.post(`${urlConstants.addStartupBenchmarkInfo}`, payload);

export const getBenchmarkStartupProcess = () =>
  secureApi.get(`${urlConstants.getBenchmarkStartupProcess}`);

export const getStartupValuationInsight = () =>
  secureApi.get(`${urlConstants.getStartupValuationInsight}`);

export const adminGetStartupValuationInsight = (startupId: any) =>
  secureApi.get(`${urlConstants.adminGetStartupValuationInsight}/${startupId}`);

export const startupUploadBenchmarkKPI = (payload: any) =>
  secureApi.post(`${urlConstants.startupUploadBenchmarkKPI}`, payload);

export const getAdminPreviewValuationInsight = () =>
  secureApi.get(`${urlConstants.getAdminPreviewValuationInsight}`);

export const adminPublishValuationInsightRange = (payload: any) =>
  secureApi.post(`${urlConstants.adminPublishValuationInsightRange}`, payload);

export const adminExportStartups = () =>
  secureApi.get(`${urlConstants.adminExportStartups}`);

// API to accep perm
export const acceptYardStickPerm = (req: any) =>
  secureApi.post(`${urlConstants.acceptYardStickPerm}`, req);

export const acceptYardStickPermInvite = (req: any) =>
  secureApi.post(`${urlConstants.acceptYardStickPermInvite}`, req);

export const getYardstickPermissions = () =>
  secureApi.get(`${urlConstants.yardstickPermission}`);
