import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FacebookIcon from '@material-ui/icons/Facebook';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { isEmpty } from 'lodash';

import {
  Button,
  Box,
  Dialog,
  SuccessPage,
  WrappedTextInput,
  WrappedAutocomplete,
} from 'components';
import { getFounderDetails } from 'redux-modules/enquiryForm/Actions';
import {
  errorMessageHandler,
  businessOpsCountryList,
  getBusinessOpsCountryObject,
  getIndustryClassificationList,
  getIndustryClassificationObject,
  getIndustryTypeObject,
} from 'common/utils/helpers';
import { successImage } from 'assets';
import { RootState } from 'redux-modules/store/rootState';
import { saveStartupOnboardDetails } from 'services';
import { industryTypeList } from 'common/utils/optionList';

import { CompanyDetailsSchema } from '../validations';
import styles from '../styles';

const AdditionalDetails = ({
  startUpId,
  enquiryId,
  setEnquiryId,
  previousPage,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    setError,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CompanyDetailsSchema),
  });

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'founderDetails',
  });
  if (!fields.length) {
    prepend({ founderName: '', founderEmail: '' });
  }

  const { enquiryView } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const {
    startupDetails,
    founderDetails,
    defaultFounderDetails,
    potentialMarketTarget,
    productDescription,
    productProgress,
    startupLegalInformation,
    startupInvestmentInformation,
  } = enquiryView;

  const {
    industryType,
    industryClassification,
    businessOpsLocation,
    startupFacebookUrl,
    startupLinkedInUrl,
    startupTwitterUrl,
    startupWebLink,
  } = startupDetails;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [modal, setModal] = React.useState<boolean>(false);
  const [selectedIndustry, setSelectedIndustry] = React.useState<any>(
    industryType,
  );

  const onSubmit = (formValues: any) => {
    const {
      founderDetails,
      industryType,
      industryClassification,
      potentialMarketTarget,
      productDescription,
      productProgress,
      businessOpsLocation,
      startupFacebookUrl,
      startupLinkedInUrl,
      startupTwitterUrl,
      startupWebLink,
      startupLegalInformation,
      startupInvestmentInformation,
    } = formValues;

    const {
      startupLegalState,
      enquiryTermsAccepted,
      startupId,
    } = startupDetails;

    const obj = {
      additionalDetail: true,
      productDescription: productDescription,
      productProgress: productProgress,
      potentialMarketTarget: potentialMarketTarget,
      startupInvestmentInformation: startupInvestmentInformation,
      startupLegalInformation: startupLegalInformation,
      startupDetails: {
        startupId: startupId,
        startupWebLink,
        startupLegalState: startupLegalState,
        enquiryTermsAccepted: enquiryTermsAccepted,
        businessOpsLocation: businessOpsLocation?.countryCode,
        industryType: industryType?.value,
        industryClassification: industryClassification?.value,
        startupTwitterUrl,
        startupFacebookUrl,
        startupLinkedInUrl,
      },
      founderDetails,
      id: enquiryId,
    };
    const payload: any = {
      enquiryDetail: { ...obj },
    };
    saveStartupOnboardDetails(payload)
      .then((res: any) => {
        setIsLoading(false);
        setEnquiryId(res?.id);
        setModal(true);
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  useEffect(() => {
    if (!isEmpty(startUpId)) {
      dispatch(getFounderDetails(startUpId));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(industryType) && isEmpty(selectedIndustry))
      setSelectedIndustry(industryType);
  }, [industryType]);

  useEffect(() => {
    if (!isEmpty(startupDetails)) {
      reset({
        founderDetails: founderDetails,
        startupFacebookUrl: startupFacebookUrl ? startupFacebookUrl : '',
        startupLinkedInUrl: startupLinkedInUrl ? startupLinkedInUrl : '',
        startupTwitterUrl: startupTwitterUrl ? startupTwitterUrl : '',
        startupWebLink: startupWebLink ? startupWebLink : '',
        potentialMarketTarget: potentialMarketTarget
          ? potentialMarketTarget
          : '',
        productDescription: productDescription ? productDescription : '',
        productProgress: productProgress ? productProgress : '',
        startupLegalInformation: startupLegalInformation
          ? startupLegalInformation
          : '',
        startupInvestmentInformation: startupInvestmentInformation
          ? startupInvestmentInformation
          : '',
      });
      setValue(
        'businessOpsLocation',
        getBusinessOpsCountryObject(businessOpsLocation),
      );
      setValue('industryType', getIndustryTypeObject(industryType));
      setValue(
        'industryClassification',
        getIndustryClassificationObject(industryType, industryClassification),
      );
    }
  }, [startupDetails]);

  useEffect(() => {
    if (defaultFounderDetails) {
      reset({
        founderDetails: defaultFounderDetails,
      });
    }
  }, [defaultFounderDetails]);

  const checkFounderEmail = (index: any) => {
    const founderDetailsList = getValues('founderDetails');
    const currentEmail = getValues(`founderDetails.${index}.founderEmail`);
    founderDetailsList.map((data: any, i: any) => {
      if (
        getValues(`founderDetails.${i}.founderEmail`) === currentEmail &&
        index !== i
      ) {
        setError(`founderDetails[${index}].founderEmail`, {
          type: 'custom',
          message: 'Duplicate email value',
        });
      }
    });
  };

  return (
    <div>
      <KeyboardBackspaceIcon
        className={classes.backArrow}
        onClick={() => previousPage()}
      />
      <Typography variant="h2" className={classes.detailsText}>
        Onboard a startup
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.errorText}>
        {errorMessage}
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="companyDetailsForm"
        className={classes.validateForm}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.companyLtext}>
              Founder Details
            </Typography>
          </Grid>
        </Grid>
        {fields.map((item, index) => (
          <div key={index}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Founder {index + 1}{' '}
                  {fields.length > 1 && index !== 0 && (
                    <Typography
                      variant="caption"
                      className={classes.removeText}
                      onClick={() => remove(index)}
                    >
                      (Remove)
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
                <Controller
                  name={`founderDetails[${index}].founderName` as const}
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Name"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                        disabled={!index}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className={classes.inputHeadRight}
              >
                <Controller
                  name={`founderDetails[${index}].founderEmail` as const}
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Email Address"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={() => checkFounderEmail(index)}
                        error={error?.message ? true : false}
                        disabled={!index}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  // rules={{ required: 'Email is required' }}
                />
              </Grid>
            </Grid>
          </div>
        ))}
        <Button
          className={classes.addFound}
          variant="outlined"
          name="Add Founder"
          onClick={() =>
            append({
              founderName: '',
              founderEmail: '',
            })
          }
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.companyLtext}>
              Company Introduction
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Your company website
            </Typography>
            <Controller
              name="startupWebLink"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="Link"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Country of your business operation
            </Typography>
            <Controller
              name="businessOpsLocation"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedAutocomplete
                    id="field-business-op-location"
                    placeholder="Choose Country"
                    disableClearable
                    options={businessOpsCountryList}
                    value={
                      value || getBusinessOpsCountryObject(businessOpsLocation)
                    }
                    getOptionLabel={(option: any) => option.country || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.countryCode === value.countryCode
                    }
                    onChange={(_: any, value: any) => {
                      onChange(value);
                    }}
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography variant="caption" className={classes.companySocialText}>
              Social Media URL’s
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputHeadPart}>
            <Controller
              name="startupFacebookUrl"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="Enter Facebook"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <InputAdornment position="start">
                          <FacebookIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Facebook URL is required' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputHeadPart}>
            <Controller
              name="startupTwitterUrl"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="Enter Twitter"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <InputAdornment position="start">
                          <TwitterIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Twitter URL is required' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            className={classes.inputHeadLastPart}
          >
            <Controller
              name="startupLinkedInUrl"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="text"
                    label=""
                    placeholder="LinkedIn URL"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedInIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'LinkedIn URL is required' }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className={classes.inputHead}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Sector/Industry*
            </Typography>
            <Controller
              name="industryType"
              control={control}
              // defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedAutocomplete
                    id="industry-type-select"
                    placeholder="Select Industry"
                    disableClearable
                    options={industryTypeList}
                    // value={value}
                    value={value || getIndustryTypeObject(industryType)}
                    getOptionLabel={(option: any) => option.text || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.value === value.value
                    }
                    onChange={(_: any, value: any) => {
                      setSelectedIndustry(value?.value);
                      setValue('industryClassification', null);
                      onChange(value);
                    }}
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.inputHeadRight}>
            <Typography variant="caption" className={classes.companyLabelText}>
              Sub-industry*
            </Typography>
            <Controller
              name="industryClassification"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedAutocomplete
                    id="industry-classification"
                    placeholder="Sub-industry"
                    disableClearable
                    options={getIndustryClassificationList(selectedIndustry)}
                    // value={value}
                    value={
                      value ||
                      (selectedIndustry == industryType
                        ? getIndustryClassificationObject(
                            industryType,
                            industryClassification,
                          )
                        : null)
                    }
                    getOptionLabel={(option: any) => option.text || ''}
                    getOptionSelected={(option: any, value: any) =>
                      option.value === value.value
                    }
                    onChange={(_: any, value: any) => {
                      onChange(value);
                    }}
                    disabled={!selectedIndustry}
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.cmpnyLtext}>
              Business details
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Typography variant="caption" className={classes.companyLabelText}>
            Describe your Company Product/Services.
          </Typography>
          <Controller
            name="productDescription"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Type Something"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid>
          <Typography variant="caption" className={classes.companyLabelText}>
            Describe the status of the business.
          </Typography>
          <Controller
            name="productProgress"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Type Something"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid>
          <Typography variant="caption" className={classes.companyLabelText}>
            Describe your potential market.
          </Typography>
          <Controller
            name="potentialMarketTarget"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Type Something"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid>
          <Typography variant="caption" className={classes.companyLabelText}>
            Has the company received any investment to date (apart from Y
            Combinator commitment)? If so please provide relevant investor and
            amount details.
          </Typography>
          <Controller
            name="startupInvestmentInformation"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Type Something"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid>
          <Typography variant="caption" className={classes.companyLabelText}>
            Is there anything else we should know about your company (legal or
            otherwise)?
          </Typography>
          <Controller
            name="startupLegalInformation"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Type Something"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorText}>{error?.message}</span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid>
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              className={classes.saveMoreBtn}
              name="Save"
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              className={classes.backMoreBtn}
              name="Back"
              onClick={() => previousPage()}
            />
          </Box>
        </Grid>
      </form>
      <Dialog
        open={modal}
        maxWidth={'sm'}
        title="Saved Successfully"
        buttonText="Continue"
        handleClose={() => setModal(false)}
      >
        <Box>
          <SuccessPage img={successImage} className={classes.dashSuccessImg}>
            <Typography variant="h4" gutterBottom className={classes.pointText}>
              <Button
                className={classes.saveMoreBtn}
                name="OK"
                onClick={() => previousPage()}
              />
            </Typography>
          </SuccessPage>
        </Box>
      </Dialog>
    </div>
  );
};

export default AdditionalDetails;
