import * as yup from 'yup';
import moment from 'moment';

import { requiredConstant } from 'common/utils/constants';

const currentDate = moment();

export const referralSchema: any = yup.object().shape({
  referredBy: yup.object().nullable().required(requiredConstant),
  referrer: yup.object().nullable().required(requiredConstant),
  email: yup.string().nullable().required(requiredConstant),
  investedDate: yup.string().nullable().required(requiredConstant),
  status: yup.string().nullable().required(requiredConstant),
  firstSpv: yup.string().nullable().required(requiredConstant),
  amountInvested: yup.string().nullable().required(requiredConstant),
  incentiveFee: yup.string(),
  referralPage: yup.string().nullable().required(requiredConstant),
  dateReferral: yup
    .date()
    .nullable()
    .max(currentDate, 'Can not select future date')
    .transform((current, original) => (original === '' ? null : current))
    .required(requiredConstant),
});
