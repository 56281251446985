import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import { get } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import { containsEncodedURIComponents } from 'common/utils/helpers';

import { IRoute } from '../common/types';
import { getStartupDetailsForSignUp } from '../services';

type RouteWrapper = IRoute;

const AuthenticatedRouteWrapper = ({
  layout: Layout,
  component: Component,
  ...props
}: RouteWrapper) => {
  const loggedIn = useSelector(({ Auth }: RootState) => Auth.loggedIn);
  const [intercomInitialized, setIntercomInitialized] = useState(false);
  const user = useSelector(({ Auth }: RootState) => Auth.user);

  const initializeInterCom = () => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getStartupDetailsForSignUp().then((data) => {
        const startupName = get(data, 'name');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.Intercom('shutdown');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          name: get(user, 'name'),
          userType: 'Startup',
          user_id: `${startupName}##${get(user, 'name')}##${get(
            user,
            'email',
          )}`,
          user_hash: CryptoJS.HmacSHA256(
            `${startupName}##${get(user, 'name')}##${get(user, 'email')}`,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            process.env.REACT_APP_INTERCOM_HASH,
          ).toString(CryptoJS.enc.Hex), // HMAC using SHA-256
        });
      });
      setIntercomInitialized(true);
    } catch (err) {
      setIntercomInitialized(false);
    }
  };

  const toUrl = (param: any) => {
    return `/sign-in?redirecturl=${
      containsEncodedURIComponents(param)
        ? window.location.pathname + window.location.search
        : encodeURIComponent(window.location.pathname + window.location.search)
    }`;
  };

  useEffect(() => {
    if (loggedIn && get(user, 'startUpId') && !intercomInitialized) {
      initializeInterCom();
    } else if (loggedIn && !get(user, 'startUpId')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Intercom('shutdown');
    }
  }, [loggedIn, user]);

  const RouteWrapper = () => {
    return (
      <Route
        {...props}
        render={(matchProps) => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    );
  };

  return loggedIn ? (
    <RouteWrapper />
  ) : (
    <Redirect to={toUrl(window.location.search)} />
  );
};

export default AuthenticatedRouteWrapper;
