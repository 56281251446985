import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { Box } from 'components';
import {
  // BusinessDetailType,
  //AdditionalInformationType,
  FounderDetailsType,
  StartUpDetailType,
} from 'common/types';
import {
  getIncorporationCountryObject,
  getStateObject,
} from 'common/utils/helpers';

import styles from './styles';

type ComponentProps = {
  // businessDetails: BusinessDetailType;
  //additionalInformation: AdditionalInformationType;
  // description?: string | undefined;
  // businessModel?: string | undefined;
  startupDetails: StartUpDetailType;
  founderDetails: FounderDetailsType[];
  productDescription?: string | undefined;
  productProgress?: string | undefined;
  potentialMarketTarget?: string | undefined;
  startupInvestmentInformation?: string | undefined;
  startupLegalInformation?: string | undefined;
  // startupYcAssignedId?: string | undefined;
  // startupTaxIdNumber?: string | undefined;
};

const CompanyOverview = ({
  startupDetails,
  founderDetails,
  productProgress,
  productDescription,
  potentialMarketTarget,
  startupInvestmentInformation,
  startupLegalInformation,
}: ComponentProps) => {
  const classes = styles();
  // const { startupYcAssignedId, startupTaxIdNumber } = startupDetails;

  return (
    <Box className={classes.tabPanelContent}>
      <Typography variant="h4" gutterBottom className={classes.basicHeadTitle}>
        Basic Details
      </Typography>
      {/* <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Company ID assigned by YC?
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {startupYcAssignedId}
      </Typography> */}
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Country of Incorporation
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {
          getIncorporationCountryObject(startupDetails.ycIncorporationCountry)
            ?.country
        }
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        State of Incorporation of the above legal entity
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {
          getStateObject(
            startupDetails.stateOfIncorporation,
            startupDetails.ycIncorporationCountry,
          )?.name
        }
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Company tax identification number for the legal entity
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {startupDetails?.startupTaxIdNumber}
      </Typography>
      {/* <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {productDescription}
      </Typography> */}
      {/* <Typography
          variant="body1"
          gutterBottom
          className={classes.descTitletext}
        >
          We earn revenue by providing credit to small and medium
          enterprises. Our primary source of revenue is through interest
          charged on overdue payments and providing various value-added
          services.
        </Typography> */}
      {!isEmpty(founderDetails) ? (
        <Typography
          variant="h4"
          gutterBottom
          className={classes.overViewHeadTitle}
        >
          About Founders
        </Typography>
      ) : (
        ''
      )}
      {!isEmpty(founderDetails) && (
        <Grid container spacing={1}>
          {founderDetails.map((i, index) => {
            return (
              <Grid item key={index} md={6}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.overViewSubHeadTitle}
                >
                  {i.founderName}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.descTitletext}
                >
                  {i.founderEmail}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      )}
      {productDescription && (
        <>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.businessHeadTitle}
          >
            Business Details
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.overViewSubHeadTitle}
          >
            Progress of your Product/Services
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.descTitletext}
          >
            {productDescription}
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.overViewSubHeadTitle}
          >
            Status of your business
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.descTitletext}
          >
            {productProgress}
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.overViewSubHeadTitle}
          >
            Potential Target Market
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.descTitletext}
          >
            {potentialMarketTarget}
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.overViewSubHeadTitle}
          >
            Has the Company received any investment to date (apart from Y
            Combinator commitment)? If so please provide relevant details of the
            investor
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.descTitletext}
          >
            {startupInvestmentInformation}
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.overViewSubHeadTitle}
          >
            Is there anything else we should know about your company (Legal or
            otherwise)?
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.descTitletext}
          >
            {startupLegalInformation}
          </Typography>
        </>
      )}
      {/* <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        What is your edge?
      </Typography> */}
      {/* <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {competitorsInformation}
      </Typography> */}
      {/* <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Is the Company incorporated?
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {startupIncorporationInfo}
      </Typography> */}
      {/* <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        If you have not formed the company yet, describe the planned equity
        ownership breakdown among the founders, employees and any other proposed
        stockholders. If there are multiple founders, be sure to give the
        proposed equity ownership of each founder.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {startupEquityInformation}
      </Typography> */}
      {/* <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Please provide any other relevant information about the structure or
        formation of the company.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {startupStructureInformation}
      </Typography> */}
      {/* <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewHeadTitle}
      >
        Other Information
      </Typography> */}
      {/* <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        How did you hear about 8VDX?
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {startup8vdxSource}
      </Typography> */}
      {/* <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        What made you to apply to 8VDX?
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {startupReasonForChosing}
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Has the Company received any investment to date (apart from Y Combinator
        commitment)? If so please provide relevant details of the investor
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {startupInvestmentInformation}
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.overViewSubHeadTitle}
      >
        Is there anything else we should know about your company (Legal or
        otherwise)?
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className={classes.descTitletext}
      >
        {startupLegalInformation}
      </Typography> */}
    </Box>
  );
};

export default CompanyOverview;
