import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, get } from 'react-hook-form';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { Button, Box, WrappedTextInput, Banner } from 'components';
import { trackBridgeLoanProcess } from 'redux-modules/enquiries/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { approveLoan } from 'redux-modules/process/Actions';
import { FN } from 'common/types';

import styles from '../../styles';
import { approveLoanSchema } from './validations';

type Props = {
  handleBack: FN;
  startupId: string | undefined;
  isRoleAdmin: boolean;
  loanTerms: any;
};
const ApproveLoan = ({
  handleBack,
  startupId,
  isRoleAdmin,
  loanTerms,
}: Props) => {
  const [successPop, setSuccessPop] = useState<boolean>(false);
  const { handleSubmit, control, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(approveLoanSchema),
  });
  const classes = styles();
  const dispatch = useDispatch();
  const { successMessage, isLoading, errorMessage } = useSelector(
    ({ Process }: RootState) => Process,
  );
  const { bridgeLoanProcess, loansDetails, enquiryView } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const { enquiryRequestLoanAmount } = enquiryView;

  const getFieldValue = (inputField: string) => {
    if (!isEmpty(loansDetails)) {
      return loansDetails[inputField];
    }
  };

  const onSubmit = () => {
    const defaultValues = getValues();
    const values = {
      interestRate:
        get(loanTerms, 'cashInterestRate') ||
        get(defaultValues, 'interestRate'),
      loanTenureInMonths:
        get(loanTerms, 'loanTenure') ||
        get(defaultValues, 'loanTenureInMonths'),
      processingLoanAmount:
        get(loanTerms, 'loanAmount') ||
        get(defaultValues, 'processingLoanAmount'),
      requestedLoanAmount:
        get(loanTerms, 'loanAmount') ||
        get(defaultValues, 'requestedLoanAmount'),
      warrantsRate: get(defaultValues, 'warrantsRate') || 15,
    };
    const payload = {
      startupId,
      ...values,
      termsAccepted: true,
    };
    dispatch(approveLoan(payload));
  };

  useEffect(() => {
    if (!isEmpty(successMessage)) {
      dispatch(trackBridgeLoanProcess(startupId));
      setSuccessPop(true);
    }
  }, [successMessage]);

  const handleDisableField = () => {
    if (!isRoleAdmin) {
      return true;
    } else if (!isEmpty(bridgeLoanProcess)) {
      return bridgeLoanProcess.isLoanApproved;
    }
  };

  const safeCoverAmount = () => {
    if (get(loanTerms, 'safeCover')) return get(loanTerms, 'safeCover');
    return (
      (getValues()['processingLoanAmount'] *
        (getValues()['percentageBonusSafe'] || 20)) /
      100
    );
  };

  return (
    <div>
      {errorMessage && <Typography>{errorMessage}</Typography>}
      {successPop ? (
        <Banner title={successMessage} subTitle={''} source={'SuccessBanner'} />
      ) : (
        <>
          {!isEmpty(successMessage) && (
            <Typography variant="caption" className={classes.companyLabelText}>
              {successMessage}
            </Typography>
          )}
          {!isEmpty(errorMessage) && (
            <Typography variant="caption" className={classes.errorText}>
              {errorMessage}
            </Typography>
          )}
          <form onSubmit={handleSubmit(onSubmit)} data-testid="ApproveLoanForm">
            <Grid container spacing={2}>
              {/* <Grid item xs={12} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Requested Amount
                </Typography>
                <Controller
                  name="requestedLoanAmount"
                  control={control}
                  defaultValue={enquiryRequestLoanAmount}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label=""
                        placeholder="Requested Amount"
                        disabled
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={
                          get(loanTerms, 'loanAmount') ||
                          getFieldValue('requestedLoanAmount') ||
                          value
                        }
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid> */}
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Processing Amount
                </Typography>
                <Controller
                  name="processingLoanAmount"
                  control={control}
                  defaultValue={enquiryRequestLoanAmount}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label=""
                        placeholder="Processing Amount"
                        disabled
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={
                          get(loanTerms, 'loanAmount') ||
                          getFieldValue('processingLoanAmount') ||
                          value
                        }
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Loan Tenure (Months)
                </Typography>
                <Controller
                  name="loanTenureInMonths"
                  control={control}
                  defaultValue="6"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        value={
                          get(loanTerms, 'loanTenure') ||
                          getFieldValue('loanTenureInMonths') ||
                          value
                        }
                        onChange={onChange}
                        label=""
                        disabled
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              {/* <Grid item xs={12} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Interest Rate
                </Typography>
                <Controller
                  name="interestRate"
                  control={control}
                  defaultValue="12"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        value={
                          (get(loanTerms, 'cashInterestRate') &&
                            parseInt(get(loanTerms, 'cashInterestRate'))) ||
                          getFieldValue('interestRate') ||
                          value
                        }
                        onChange={onChange}
                        label=""
                        disabled
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Warrant Rate
                </Typography>
                <Controller
                  name="warrantsRate"
                  control={control}
                  defaultValue="15"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedSelect
                        value={getFieldValue('warrantsRate') || value}
                        onChange={onChange}
                        label=""
                        disabled
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        dropdowns={[{ text: '15%', value: '15' }]}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid> */}
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Percentage Bonus SAFE
                </Typography>
                <Controller
                  name="percentageBonusSafe"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        label=""
                        placeholder=""
                        disabled
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={
                          (get(loanTerms, 'percentageBonusSafe') &&
                            parseInt(get(loanTerms, 'percentageBonusSafe'))) ||
                          getFieldValue('percentageBonusSafe') ||
                          value ||
                          20
                        }
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Cash Interest Rate
                </Typography>
                <Controller
                  name="cashInterestRate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="number"
                        value={
                          (get(loanTerms, 'cashInterestRate') &&
                            parseInt(get(loanTerms, 'cashInterestRate'))) ||
                          getFieldValue('cashInterestRate') ||
                          value ||
                          0
                        }
                        onChange={onChange}
                        label=""
                        disabled
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputHead}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  SAFE Cover
                </Typography>
                <Controller
                  name="SafeCover"
                  control={control}
                  defaultValue="$"
                  render={({ fieldState: { error } }) => (
                    <>
                      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                      {/* @ts-ignore */}
                      <WrappedTextInput
                        type="number"
                        label=""
                        disabled
                        placeholder=""
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={safeCoverAmount()}
                        error={error?.message ? true : false}
                      />
                    </>
                  )}
                  rules={{ required: 'Name is required' }}
                />
              </Grid>
            </Grid>
            {/* <Box className={classes.approveChk}>
              <Checkbox />
              <Typography variant="caption">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi, quasi.
              </Typography>
            </Box> */}
            <Box className={classes.backContinueBox}>
              {!get(bridgeLoanProcess, 'isLoanApproved') ? (
                <Button
                  name="Approve"
                  type="submit"
                  onClick={() => onSubmit()}
                  className={classes.continueBtn}
                  isLoading={isLoading}
                  disabled={!isRoleAdmin || handleDisableField()}
                />
              ) : (
                <Button name={'Approved'} className={classes.loanApprovedBtn} />
              )}
              <Button
                onClick={() => handleBack()}
                className={classes.approveBackBtn}
                name="Cancel"
              />
            </Box>
          </form>
        </>
      )}
    </div>
  );
};

export default ApproveLoan;
