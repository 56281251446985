import { get } from 'lodash';

import { ProcessState, IDoc, DocumentType } from './Types';
import {
  ADD_REQUEST_DOCUMENTS,
  DELETE_REQUEST_DOUCUMENTS,
  ADD_YCBATCH_STARTUPID,
  GET_LIST_DOUCMENTS_LOADING,
  GET_LIST_DOUCMENTS_SUCCESS,
  GET_LIST_DOUCMENTS_FAILURE,
  APPROVE_DOCUMENTS_LOADING,
  APPROVE_DOCUMENTS_SUCCESS,
  APPROVE_DOCUMENTS_FAILURE,
  APPROVE_YC_DOCUMENTS_LOADING,
  APPROVE_YC_DOCUMENTS_SUCCESS,
  APPROVE_YC_DOCUMENTS_FAILURE,
  APPROVE_LOAN_LOADING,
  APPROVE_LOAN_SUCCESS,
  APPROVE_LOAN_FAILURE,
  MANAGE_LOAN_LOADING,
  MANAGE_LOAN_SUCCESS,
  MANAGE_LOAN_FAILURE,
  PROCESS_LOAN_LOADING,
  PROCESS_LOAN_SUCCESS,
  PROCESS_LOAN_FAILURE,
  UPDATE_DOCUMENTS,
  GET_DOCU_SIGN_DOCUMENTS_ADMIN_LOADING,
  GET_DOCU_SIGN_DOCUMENTS_ADMIN_SUCCESS,
  GET_DOCU_SIGN_DOCUMENTS_ADMIN_FAILURE,
  ADD_REQUEST_DOCU_SIGN_ADMIN,
  VD_APPROVE_LOAN_SUCCESS,
  VD_APPROVE_LOAN_LOADING,
  VD_APPROVE_LOAN_FAILURE,
  APPROVE_VD_REQUEST_ADMIN_LOADING,
  APPROVE_VD_REQUEST_ADMIN_SUCCESS,
  APPROVE_VD_REQUEST_ADMIN_FAILURE,
  EMPTY_VD_REQUEST_STATE,
  EMPTY_APPROVE_VD_LOAN_STATE,
  EMPTY_SUCCESS_MESSAGE,
  SUBMIT_DOCUMENTS_FAILURE,
  SUBMIT_DOCUMENTS_SUCCESS,
  SUBMIT_DOCUMENTS_LOADING,
  SUBMIT_DOCU_SIGN_DOCS_SUCCESS,
  SUBMIT_DOCU_SIGN_DOCS_FAILURE,
  SUBMIT_DOCU_SIGN_DOCS_LOADING,
  EMPTY_DOCU_SIGN_STATE,
  MANAGE_LOAN_HOLD_REJECT,
} from './Actions';

const handleDeleteDocuments = (state: DocumentType, payload: string) => {
  const { documents } = state;
  return documents.filter((i: any) => i.documentName !== payload);
};

const handleUpdateDoucuments = (state: DocumentType, payload: IDoc) => {
  const { documents } = state;
  return documents.map((i: any) => {
    if (i.id === payload.id) {
      return {
        ...i,
        documentUrl: payload.accessUrl,
      };
    } else {
      return i;
    }
  });
};

export const ProcessInitialState: ProcessState = {
  isLoading: false,
  isLoadingYc: false,
  successMessage: '',
  errorMessage: '',
  Documents: {
    startupId: '',
    documents: [],
    documentsType: 'NON_DOCU_SIGN_DOCUMENTS',
    ycBatch: '',
  },
  docuSignDocumentsTemplate: {
    docusignDocuments: [],
    requestedDocuments: [],
    isDocuSignLoading: false,
    docuSignErrorMessage: '',
    docuSignSuccessMessage: '',
  },
  approveVdLoan: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    holdRejectSuccessMessage: '',
  },
  approveVdReq: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    holdRejectSuccessMessage: '',
  },
  submitDocuments: {
    isLoading: false,
    successMessage: '',
    errorMessage: '',
    holdRejectSuccessMessage: '',
  },
};

const handleAddDocuments = (state: DocumentType, payload: any) => {
  const { documents } = state;
  return [...documents, payload];
};

export const ProcessReducer = (state = ProcessInitialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_REQUEST_DOCUMENTS:
      return {
        ...state,
        Documents: {
          ...state.Documents,
          documents: handleAddDocuments(state.Documents, payload),
        },
      };
    case DELETE_REQUEST_DOUCUMENTS:
      return {
        ...state,
        Documents: {
          ...state.Documents,
          documents: handleDeleteDocuments(state.Documents, payload),
        },
      };
    case ADD_YCBATCH_STARTUPID:
      return {
        ...state,
        Documents: {
          ...state.Documents,
          ...payload,
        },
      };
    case GET_LIST_DOUCMENTS_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        Documents: {
          ...state.Documents,
          documents: [],
        },
        successMessage: '',
      };
    case GET_LIST_DOUCMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        Documents: {
          ...state.Documents,
          documents: payload,
        },
      };
    case GET_LIST_DOUCMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case APPROVE_DOCUMENTS_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        successMessage: '',
      };
    case APPROVE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        Documents: {
          ...state.Documents,
          documents: payload,
        },
        successMessage: 'approved successfully',
      };
    case APPROVE_DOCUMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case APPROVE_YC_DOCUMENTS_LOADING:
      return {
        ...state,
        isLoadingYc: true,
        errorMessage: '',
        successMessage: '',
      };
    case APPROVE_YC_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoadingYc: false,
        successMessage: 'approved successfully',
      };
    case APPROVE_YC_DOCUMENTS_FAILURE:
      return {
        ...state,
        isLoadingYc: false,
        errorMessage: payload,
      };
    case APPROVE_LOAN_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        successMessage: '',
      };
    case APPROVE_LOAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        successMessage: payload,
      };
    case APPROVE_LOAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        successMessage: '',
      };

    case MANAGE_LOAN_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        successMessage: '',
      };

    case MANAGE_LOAN_HOLD_REJECT:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        holdRejectSuccessMessage: payload,
      };
    case MANAGE_LOAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        successMessage: payload,
      };
    case MANAGE_LOAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        successMessage: '',
      };

    case PROCESS_LOAN_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        successMessage: '',
      };
    case PROCESS_LOAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        successMessage: payload,
      };
    case PROCESS_LOAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        successMessage: '',
      };
    case UPDATE_DOCUMENTS:
      return {
        ...state,
        Documents: {
          ...state.Documents,
          documents: handleUpdateDoucuments(state.Documents, payload),
        },
      };
    case GET_DOCU_SIGN_DOCUMENTS_ADMIN_LOADING:
      return {
        ...state,
        docuSignDocumentsTemplate: {
          ...state.docuSignDocumentsTemplate,
          isDocuSignLoading: true,
          docuSignErrorMessage: '',
          docuSignSuccessMessage: '',
        },
      };
    case GET_DOCU_SIGN_DOCUMENTS_ADMIN_SUCCESS:
      return {
        ...state,
        docuSignDocumentsTemplate: {
          ...state.docuSignDocumentsTemplate,
          ...get(action, 'payload'),
          isDocuSignLoading: false,
          docuSignErrorMessage: '',
          docuSignSuccessMessage: 'fetched successfully',
        },
      };
    case GET_DOCU_SIGN_DOCUMENTS_ADMIN_FAILURE:
      return {
        ...state,
        docuSignDocumentsTemplate: {
          ...state.docuSignDocumentsTemplate,
          isDocuSignLoading: false,
          docuSignErrorMessage: get(action, 'payload.message'),
          docuSignSuccessMessage: '',
        },
      };
    case ADD_REQUEST_DOCU_SIGN_ADMIN:
      return {
        ...state,
        docuSignDocumentsTemplate: {
          ...state.docuSignDocumentsTemplate,
          requestedDocuments: [...action.payload],
        },
      };
    case VD_APPROVE_LOAN_LOADING:
      return {
        ...state,
        approveVdLoan: {
          ...state.approveVdLoan,
          isLoading: true,
        },
      };
    case VD_APPROVE_LOAN_SUCCESS:
      return {
        ...state,
        approveVdLoan: {
          ...state.approveVdLoan,
          isLoading: false,
          successMessage: payload,
        },
      };
    case VD_APPROVE_LOAN_FAILURE:
      return {
        ...state,
        approveVdLoan: {
          ...state.approveVdLoan,
          isLoading: false,
          successMessage: '',
          errorMessage: payload,
        },
      };
    case APPROVE_VD_REQUEST_ADMIN_LOADING:
      return {
        ...state,
        approveVdReq: {
          isLoading: true,
          successMessage: '',
          errorMessage: '',
        },
      };
    case APPROVE_VD_REQUEST_ADMIN_SUCCESS:
      return {
        ...state,
        approveVdReq: {
          isLoading: false,
          successMessage: payload,
          errorMessage: '',
        },
      };
    case APPROVE_VD_REQUEST_ADMIN_FAILURE:
      return {
        ...state,
        approveVdReq: {
          isLoading: false,
          successMessage: '',
          errorMessage: payload,
        },
      };
    case EMPTY_VD_REQUEST_STATE:
      return {
        ...state,
        approveVdReq: {
          isLoading: false,
          successMessage: '',
          errorMessage: '',
        },
      };
    case EMPTY_APPROVE_VD_LOAN_STATE:
      return {
        ...state,
        successMessage: '',
        approveVdLoan: {
          isLoading: false,
          successMessage: '',
          errorMessage: '',
        },
      };
    case EMPTY_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: '',
      };
    case SUBMIT_DOCUMENTS_LOADING:
      return {
        ...state,
        submitDocuments: {
          isLoading: true,
          successMessage: '',
          errorMessage: '',
        },
      };
    case SUBMIT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        submitDocuments: {
          isLoading: false,
          successMessage: 'successfully submitted',
          errorMessage: '',
        },
        Documents: {
          ...state.Documents,
          documents: get(payload, 'documents') || [],
        },
      };
    case SUBMIT_DOCUMENTS_FAILURE:
      return {
        ...state,
        submitDocuments: {
          isLoading: false,
          successMessage: '',
          errorMessage: payload,
        },
      };
    case SUBMIT_DOCU_SIGN_DOCS_LOADING:
      return {
        ...state,
        docuSignDocumentsTemplate: {
          ...state.docuSignDocumentsTemplate,
          isDocuSignLoading: true,
          docuSignErrorMessage: '',
          docuSignSuccessMessage: '',
        },
      };
    case SUBMIT_DOCU_SIGN_DOCS_SUCCESS:
      return {
        ...state,
        docuSignDocumentsTemplate: {
          ...state.docuSignDocumentsTemplate,
          isDocuSignLoading: false,
          docuSignErrorMessage: '',
          docuSignSuccessMessage: 'submitted successfully',
        },
      };
    case SUBMIT_DOCU_SIGN_DOCS_FAILURE:
      return {
        ...state,
        docuSignDocumentsTemplate: {
          ...state.docuSignDocumentsTemplate,
          isDocuSignLoading: false,
          docuSignErrorMessage: payload,
          docuSignSuccessMessage: '',
        },
      };
    case EMPTY_DOCU_SIGN_STATE:
      return {
        ...state,
        successMessage: '',
        docuSignDocumentsTemplate: {
          ...state.docuSignDocumentsTemplate,
          isDocuSignLoading: false,
          docuSignErrorMessage: '',
          docuSignSuccessMessage: '',
        },
        submitDocuments: {
          isLoading: false,
          successMessage: '',
          errorMessage: '',
        },
      };
    default:
      return state;
  }
};
