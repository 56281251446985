import { urlConstants } from 'common/utils/constants';

import { secureApi } from '../api/Axios';

export const listSubscriptionPlans = () =>
  secureApi.get(`${urlConstants.listSubscriptionPlans}`);

export const createProduct = (payload: any) =>
  secureApi.post(`${urlConstants.createProduct}`, payload);

export const getUnsubscibedUsers = () =>
  secureApi.get(`${urlConstants.listUnsubscribedUsers}`);

export const getAllSubscribedInvestorUser = (planId?: string | undefined) =>
  planId
    ? secureApi.get(
        `${urlConstants.getAllSubscribedInvestorUser}?subscriptionPlanId=${planId}`,
      )
    : secureApi.get(`${urlConstants.getAllSubscribedInvestorUser}`);

export const updateInvestorSubscription = (payload: any) =>
  secureApi.post(`${urlConstants.updateInvestorSubscription}`, payload);

export const updatePlanForNonSubscribed = (payload: any) =>
  secureApi.post(`${urlConstants.updatePlanForNonSubscribed}`, payload);
