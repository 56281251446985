// import { PayloadType } from 'redux-modules/process/Types';

import { urlConstants } from 'common/utils/constants';
import {
  SubmitDocuSignDocsType,
  DocuSignPayload,
  VDApproveType,
  YcVerification,
} from 'redux-modules/process/Types';

import { secureApi } from '../api/Axios';

type SignURL = {
  startupId: string | undefined;
  documentId: string | undefined;
  documentName: string;
  fileName: string;
};

export const getSignedUrl = (payload: SignURL) =>
  secureApi.post(urlConstants.startupGetSignedUrl, payload);

export const getSignedUrlForYCDoc = (payload: any) =>
  secureApi.post(urlConstants.getSignedUrlForYCDoc, payload);

export const getStartupListDocApi = (payload: any) => {
  return secureApi.post(`${urlConstants.startupListDocuments}`, payload);
};

export const approveDocuments = (payload: any) =>
  secureApi.post(`${urlConstants.approveDocuments}`, payload);

export const approveRejectDocuments = (payload: any) =>
  secureApi.post(`${urlConstants.approveRejectDocuments}`, payload);

export const approveLoan = (payload: any) =>
  secureApi.post(`${urlConstants.approveLoan}`, payload);

export const manageLoan = (payload: any) =>
  secureApi.post(`${urlConstants.manageLoan}`, payload);

export const processLoan = (payload: any) =>
  secureApi.post(`${urlConstants.processLoan}`, payload);

export const uploadDocApi = (data: any) => {
  return secureApi.post(urlConstants.startupDocumentUpload, data);
};

export const getDocuSignTemplates = (data: DocuSignPayload) => {
  return secureApi.get(`${urlConstants.docuSignTemplates}/${data.startupId}`, {
    params: { loanType: data.loanType },
  });
};

export const submitDocuSignDocs = (payload: SubmitDocuSignDocsType) => {
  return secureApi.post(urlConstants.submitDocuSignUrl, payload);
};

export const approveVDLoanApi = (payload: VDApproveType) => {
  return secureApi.post(
    `${urlConstants.approveVDLoanByAdmin}/${payload.startupId}`,
    payload,
  );
};

export const adminDocusignGetSignedUrl = (payload: SignURL) =>
  secureApi.post(urlConstants.adminDocusignGetSignedUrl, payload);

export const getStartupYcDocument = (loanType: string) =>
  secureApi.get(`${urlConstants.getStartupYcDocument}/${loanType}`);

export const adminAddDocusignDocument = (payload: any) =>
  secureApi.post(urlConstants.adminAddDocusignDocument, payload);

export const handleYCVerification = (payload: YcVerification) => {
  return secureApi.post(`${urlConstants.ycVerification}`, payload);
};
export const getLoanLogs = (eid: any) =>
  secureApi.get(`${urlConstants.getLoanLogs}/${eid}`);

export const getStartupDefaultTerms = (fundType: string) =>
  secureApi.get(`${urlConstants.getStartupDefaultTerms}/${fundType}`);

export const getStartupTerms = (fundType: string) =>
  secureApi.get(`${urlConstants.getStartupTerms}/${fundType}`);

export const createStartupTerms = (fundType: string) =>
  secureApi.get(`${urlConstants.createStartupTerms}/${fundType}`);

export const adminCreateStartupTerms = (fundType: string) =>
  secureApi.get(`${urlConstants.adminCreateStartupTerms}/${fundType}`);

export const getStartupBridgeLoanStatus = () =>
  secureApi.post(urlConstants.getStartupBridgeLoanStatus);
