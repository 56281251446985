import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  enquiryListBox: {
    padding: '0px 60px',
    height: '100%',
  },
  enquiryListhead: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 85,
    paddingBottom: 10,
  },
  enquiryListLeft: {
    position: 'relative',
    paddingLeft: 10,
  },
  enquiryListRight: {
    paddingRight: 25,
  },
  enquiryHeadText: {
    fontSize: 29,
    color: theme.palette.error.contrastText,
    marginBottom: 5,
    position: 'relative',
    top: -20,
  },
  enquirySubHeadText: {
    fontSize: 17,
    marginBottom: 10,
    color: theme.palette.primary.main,
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: -44,
    left: -18,
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    '& .MuiOutlinedInput-input': {
      padding: '14px',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  formSelectInput: {
    display: 'flex',
  },
  batchLabel: {
    alignSelf: 'center',
    position: 'relative',
    top: 4,
    fontSize: 15,
    marginBottom: 0,
    marginRight: 10,
  },
  selectValInput: {
    width: 90,
    marginRight: 25,
  },
  textValInput: {
    width: 140,
    marginLeft: 0,
  },
  selectvalInput: {
    width: 100,
    height: 35,
    padding: 10,
    border: '1px solid #1EA59A',
    borderRadius: 4,
  },
  inputRoot: {},
  labelRoot: {},
  labelFocused: {},
  enquiryBatchText: {
    fontSize: 14,
    alignSelf: 'center',
    fontWeight: 600,
    margin: '0 10px 0',
    color: theme.palette.grey[300],
  },
  tableContainer: {
    boxShadow: theme.shadows[0],
    height: 'calc(100% - 163px - 100px)',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiTable-root': {
      // height: '100%',
    },
    '& .MuiTableCell-root': {
      padding: '16px 16px 10px',
    },
    '& .MuiTableCell-head': {
      padding: 15,
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: '3%',
    },
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
  tdBtn: {
    border: '1px solid #1EA59A',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    padding: 3,
    cursor: 'pointer',
  },
  backButton: {
    marginRight: '8px',
  },
  Buttons: {
    float: 'right',
    padding: '10px 0 0',
    marginBottom: 30,
  },
  loaderSection: {
    display: 'table-row',
    position: 'relative',
  },
  loaderBox: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 25,
  },
  searchField: {
    display: 'flex',
    alignContent: 'center',
  },
  searchInput: {
    marginLeft: 5,
    marginTop: -4,
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    width: 200,
    border: '1px solid #d6d6d6',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 15,
    marginBottom: 10,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    display: 'block',
  },
  noDatafoundText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));
