import React, { useState } from 'react';
import {
  Typography,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import { orderBy, isEmpty, filter, get, size } from 'lodash';
import AddIcon from '@material-ui/icons/Add';

import { Box, Button, Tabs, TabPanel } from 'components';
import { AIDealIcon } from 'assets';
import { VC_PROSPECTIVE_DEAL_SAMPLE_URL } from 'common/utils/constants';
import { vcProspectiveDealColumnMapping } from 'common/utils/optionList';
import { getAllVcAdminDeals, getAllVcYcBatchForAdmin } from 'services';
import {
  errorMessageHandler,
  checkSubstringExists,
  getBusinessOpsCountryObject,
} from 'common/utils/helpers';

import styles from './styles';
import {
  AllDealTable,
  ProspectiveDealSidePane,
  CsvUploadDeal,
} from './Components';
import { AddYCBatch, NotifyUser } from './DialogBox';

type tabItem = {
  name: string | React.ReactNode;
  key: string;
  isTabButton?: boolean;
};

const ProspectiveDeal = () => {
  const classes = styles();

  const [searchStringText, setSearchStringText] = useState<string>('');
  const [filteredSearchData, setFilteredSearchData] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [modal, setModal] = React.useState<boolean>(false);
  const [openCsvUpload, setOpenCsvUpload] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [allVcDeals, setAllVcDeals] = React.useState<any>([]);
  const [selectedDeal, setSelectedDeal] = React.useState<any>('');
  const [addYCBatch, setAddYCBatch] = React.useState<boolean>(false);
  const [tabItems, setTabItems] = React.useState<tabItem[]>([]);
  const [selectedYcBatch, setSelectedYcBatch] = useState<string>('');
  const [openNotifyUserModal, setOpenNotifyUserModal] = React.useState<boolean>(
    false,
  );

  const handleChange = (event: any, newValue: string) => {
    if (newValue === '#') {
      setAddYCBatch(true);
      return;
    }
    setSelectedYcBatch(newValue);
  };

  const onSearchChange = (searchString: string) => {
    const substrings: string[] = searchString
      .split(' ')
      .map((item) => item?.toString().toLowerCase())
      .filter((item: any) => item.length > 1);

    const filteredData = filter(allVcDeals, (column: any) => {
      if (
        searchString === '' ||
        checkSubstringExists(substrings, column.sector) ||
        checkSubstringExists(substrings, column.companyName) ||
        checkSubstringExists(substrings, column.lastFundingRound) ||
        checkSubstringExists(
          substrings,
          get(getBusinessOpsCountryObject(column.geography), 'country'),
        )
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (!isEmpty(filteredData)) {
      const filterDealByCurrentYCBatch = filteredData.filter(
        (i: any) => i.ycBatch === selectedYcBatch,
      );
      setFilteredSearchData(filterDealByCurrentYCBatch);
    } else {
      setFilteredSearchData(filteredData);
    }
  };

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getAllVcDealsAndBatches = (isSaveDeal = false) => {
    setIsLoading(true);
    Promise.all([getAllVcAdminDeals(), getAllVcYcBatchForAdmin()])
      .then(([allDeals, allYCBatches]: any) => {
        setAllVcDeals(orderBy(allDeals.data, ['createdAt'], ['desc']));
        if (isSaveDeal) {
          const filterDealByCurrentYCBatch = get(allDeals, 'data', []).filter(
            (i: any) => i.ycBatch === selectedYcBatch,
          );
          setFilteredSearchData(
            orderBy(filterDealByCurrentYCBatch, ['createdAt'], ['desc']),
          );
          getTabItems(
            orderBy(allYCBatches.data, ['createdAt'], ['asc']),
            false,
          );
        } else {
          setFilteredSearchData(
            orderBy(allDeals.data, ['createdAt'], ['desc']),
          );
          getTabItems(orderBy(allYCBatches.data, ['createdAt'], ['asc']), true);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        const message = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const getTabItems = (allVcYCBatch: any[], isSaveDeal = true) => {
    const tabList: any[] = [];
    (allVcYCBatch || []).map((i: any) => {
      tabList.push({
        name: get(i, 'ycBatchName'),
        key: get(i, 'ycBatchName'),
      });
    });
    setTabItems(tabList);
    if (isSaveDeal) {
      setSelectedYcBatch(
        !isEmpty(allVcYCBatch)
          ? get(allVcYCBatch, `[${size(allVcYCBatch) - 1}].ycBatchName`)
          : '',
      );
    }
  };

  React.useEffect(() => {
    getAllVcDealsAndBatches();
  }, []);

  React.useEffect(() => {
    if (!isEmpty(allVcDeals)) {
      const filterDealByCurrentYCBatch = allVcDeals.filter(
        (i: any) => i.ycBatch === selectedYcBatch,
      );
      setFilteredSearchData(filterDealByCurrentYCBatch);
    }
  }, [selectedYcBatch]);

  return (
    <>
      <Box className={classes.mainComponentContainer}>
        <Box className={classes.mainComponentContent}>
          <Box className={classes.notifyUserBox}>
            <Typography className={classes.mainHeaderText}>
              <Box className={classes.aiPoweredBox}>
                <img src={AIDealIcon} alt={AIDealIcon} />
                <Typography className={classes.poweredText}>Powered</Typography>
              </Box>
              Deal Pipeline Management
            </Typography>
            <Button
              className={classes.notifyUserBtn}
              name="Email Users"
              onClick={() => {
                setOpenNotifyUserModal(true);
              }}
            />
          </Box>
          {errorMessage && (
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
          <Box className={classes.rightBox}>
            <Box className={classes.searchBox}>
              <InputBase
                value={searchStringText}
                className={classes.inputSearch}
                placeholder={
                  isEmpty(filteredSearchData)
                    ? 'No Data Available'
                    : 'Search by company name, sector, stage or country of incorporation or any combination of these'
                }
                inputProps={{ 'aria-label': 'search' }}
                endAdornment={
                  !searchStringText.length ? (
                    <IconButton
                      className={classes.searchIcon}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      className={classes.clearIcon}
                      aria-label="clear"
                      onClick={() => {
                        setSearchStringText('');
                        onSearchChange('');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )
                }
                onChange={(e: any) => {
                  const searchString = e.target.value;
                  setSearchStringText(searchString);
                  onSearchChange(searchString);
                }}
              />
            </Box>
          </Box>
          <Box className={classes.tabContainer}>
            <Box className={classes.tabBox}>
              <Tabs
                tabs={tabItems}
                value={selectedYcBatch}
                handleChange={handleChange}
                className={''}
                keyAsValue={true}
                variant={'scrollable'}
                scrollButtons={'auto'}
              />
            </Box>
            <Box
              className={classes.addBtnBox}
              onClick={() => setAddYCBatch(true)}
            >
              <AddIcon className={classes.addIcon} />
            </Box>
            <Box className={classes.rightBtnBox}>
              <Button
                className={classes.activeDealsBtn}
                name="Add Prospective Deal"
                endIcon={<KeyboardArrowDownIcon />}
                onClick={(event) => {
                  handleMenuClick(event);
                }}
              />
            </Box>
          </Box>
          {tabItems.map((item: tabItem, index: number) => {
            return (
              <TabPanel
                value={selectedYcBatch}
                index={selectedYcBatch}
                key={index}
                className={classes.actionTabPanel}
              >
                <AllDealTable
                  setSelectedDeal={setSelectedDeal}
                  setModal={setModal}
                  isLoading={isLoading}
                  filteredSearchData={filteredSearchData}
                  getAllVcDeals={getAllVcDealsAndBatches}
                />
              </TabPanel>
            );
          })}
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.prospectiveMenu}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleMenuClose();
            setOpenCsvUpload(true);
          }}
        >
          Import Excel
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleMenuClose();
            setModal(true);
          }}
        >
          Add Manually
        </MenuItem>
      </Menu>
      {modal && (
        <ProspectiveDealSidePane
          isLoading={isLoading}
          errorMessage={errorMessage}
          open={modal}
          closeDialog={() => {
            setSelectedDeal('');
            setModal(false);
          }}
          filteredSearchData={filteredSearchData}
          allVcDeals={allVcDeals}
          selectedDeal={selectedDeal}
          getAllVcDeals={() => getAllVcDealsAndBatches(true)}
          currentYCBatch={selectedYcBatch}
        />
      )}
      {openCsvUpload && (
        <CsvUploadDeal
          openCsvUpload={openCsvUpload}
          setOpenCsvUpload={setOpenCsvUpload}
          columnMapping={vcProspectiveDealColumnMapping}
          sampleFile={VC_PROSPECTIVE_DEAL_SAMPLE_URL}
          dealStatus={'PROSPECTIVE'}
          refreshDeals={getAllVcDealsAndBatches}
        />
      )}
      {addYCBatch && (
        <AddYCBatch
          addYCBatch={addYCBatch}
          setAddYCBatch={setAddYCBatch}
          getAllVcDealsAndBatches={getAllVcDealsAndBatches}
        />
      )}
      {openNotifyUserModal && (
        <NotifyUser
          isOpen={openNotifyUserModal}
          setIsOpen={setOpenNotifyUserModal}
        />
      )}
    </>
  );
};

export default ProspectiveDeal;
