import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { SidePane } from 'react-side-pane';
import {
  Grid,
  Typography,
  Input,
  Select,
  MenuItem,
  ListItemText,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { isEmpty, get } from 'lodash';
import { Alert } from '@material-ui/lab';

import { Box, Button, Dialog, RichTextEditor } from 'components';
import {
  sendFcrmEmail,
  fcrmEmailMediaSignedUrl,
  getEmailTemplatesList,
  saveEmailTemplate,
  deleteEmailTemplate,
  checkEmailVerified,
  verifyUserEmail,
} from 'services';
import { emailRegex } from 'common/utils/constants';
import { getDefaultMeetingBody } from 'common/utils/helpers';
import { RootState } from 'redux-modules/store/rootState';
import { Delete } from 'assets';

import styles from './styles';
import { InputField } from './SidePaneComponents/FormFields';

// const defaultHtmlTemplate =
//   '<div><h2>Hi,</h2><p>This is default html template of email template 1</p></div>';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type FCRMEmailSidePaneProps = {
  openEmailSidePane: boolean;
  setOpenEmailSidePane: any;
  emailSendFcrm: Record<string, any>;
  setemailSendFcrm: any;
  meetingLink?: string;
  startupInfo?: Record<string, any>;
};
const FCRMEmailSidePane = ({
  openEmailSidePane,
  setOpenEmailSidePane,
  emailSendFcrm,
  setemailSendFcrm,
  meetingLink,
  startupInfo,
}: FCRMEmailSidePaneProps) => {
  const classes = styles();
  const { email } = useSelector(({ Auth }: RootState) => Auth.user);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTestLoading, setIsTestLoading] = useState<boolean>(false);
  //const [selectedEmailTemplate, setSelectedEmailTemplate] = useState([]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<string>(
    '',
  );
  const [selectedTemplateObj, setSelectedTemplateObj] = useState<any>({});
  const [templateList, setTemplateList] = useState<any>([]);
  const [isDeletingTemplate, setIsDeletingTemplate] = useState<boolean>(false);
  const [showEditor, setShowEditor] = useState<boolean>(true);
  const [deleteTemplateId, setDeleteTemplateId] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [saveTemplteErr, setSaveTemplteErr] = useState<string>('');

  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [verifyEmailStatus, setEmailVerifyStatus] = useState<string>('');

  const [showSaveTemplateDialog, setShowSaveTemplateDialog] = useState<boolean>(
    false,
  );
  const [saveTemplateLoading, setSaveTemplateLoading] = useState<boolean>(
    false,
  );

  const {
    handleSubmit,
    register,
    control,
    setError,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const verifyEmail = async () => {
    setEmailVerifyStatus('VERIFYING');
    const verifyRes: any = await verifyUserEmail();
    verifyRes
      ? setEmailVerifyStatus('SUCCESS')
      : setEmailVerifyStatus('FAILED');
  };

  const onSubmit = () => {
    const data: any = getValues();
    if (!data.body || !data.toEmail || !data.subject) {
      if (!data.body)
        setError(`body`, {
          type: 'custom',
          message: 'Field is required',
        });
      if (!data.toEmail)
        setError(`toEmail`, {
          type: 'custom',
          message: 'Field is required',
        });
      if (!data.subject)
        setError(`subject`, {
          type: 'custom',
          message: 'Field is required',
        });
      return;
    }
    setIsLoading(true);
    sendFcrmEmail(data)
      .then(() => {
        setIsLoading(false);
        setSuccessMessage('Email sent successfully.');
        setTimeout(onCloseSidePan, 2000);
      })
      .catch((err) => {
        setError(`body`, {
          type: 'custom',
          message: err.message,
        });
      });
  };

  const sendTestMail = () => {
    const data = getValues();
    if (!data.body) {
      setError(`body`, {
        type: 'custom',
        message: 'Field is required',
      });
      return;
    }
    setIsTestLoading(true);
    data.toEmail = email;
    sendFcrmEmail(data)
      .then(() => setSuccessMessage('Test email sent successfully'))
      .finally(() => setIsTestLoading(false));
  };

  const onCloseSidePan = () => {
    setOpenEmailSidePane('');
    setemailSendFcrm({});
    // reset({});

    setValue('toEmail', '');
    setValue('subject', '');
    setValue('body', '');
    setValue('name', '');
    setSelectedEmailTemplate('');
    setSuccessMessage('');
    setSaveTemplteErr('');
    setEmailVerifyStatus('');
  };

  useEffect(() => {
    getEmailTemplates();
  }, []);

  useEffect(() => {
    if (openEmailSidePane)
      checkEmailVerified()
        .then((res: any) => setIsEmailVerified(res))
        .catch(() => setIsEmailVerified(false));
  }, [openEmailSidePane]);

  useEffect(() => {
    const contacts = get(emailSendFcrm, 'data.contacts') || [];

    if (!isEmpty(contacts)) {
      const primary = contacts.find((item: any) => item.primary);

      if (primary) {
        setValue('toEmail', get(primary, 'email'));
      }
    }
    if (get(emailSendFcrm, 'type') === 'MEET') {
      setValue(
        'body',
        getDefaultMeetingBody({
          link: meetingLink,
          investorName: get(emailSendFcrm, 'data.investorName'),
          startupName: get(startupInfo, 'name'),
        }),
      );
      setValue(
        'subject',
        `Connecting ${get(emailSendFcrm, 'data.investorName')} <> ${get(
          startupInfo,
          'name',
        )}`,
      );
    }
  }, [emailSendFcrm]);

  useEffect(() => {
    const selectedTemplate = templateList.find(
      (item: any) => item.id === selectedEmailTemplate,
    );

    if (selectedTemplate) {
      setShowEditor(false);
      setValue('body', '');

      setSelectedTemplateObj(selectedTemplate);
      setValue('name', get(selectedTemplate, 'name'));

      setTimeout(() => {
        setShowEditor(true);
        setValue('body', get(selectedTemplate, 'template'));
      }, 200);
    }
  }, [selectedEmailTemplate]);

  const getEmailTemplates = async () => {
    try {
      const res: any = await getEmailTemplatesList();
      setTemplateList(res.filter((item: any) => item.name !== ''));
    } catch (err) {
      setTemplateList([]);
    }
  };

  const onSaveEmailTemplate = async () => {
    setSaveTemplteErr('');
    const payload: any = {};
    const name: string = getValues('name');
    const body: string = getValues('body');
    const templatesWithSameName = templateList.filter(
      (item: any) => item.name === name,
    );

    if (!body) {
      setError(`body`, {
        type: 'custom',
        message: 'Field is required',
      });
    }
    if (
      name !== selectedTemplateObj?.name &&
      templatesWithSameName.length > 0
    ) {
      setSaveTemplteErr(`Template with name ${name} already exist`);
      return;
    }
    payload.template = body;

    if (selectedTemplateObj?.name === name) {
      payload.id = selectedTemplateObj?.id;
    } else {
      payload.name = name;
    }

    setSaveTemplateLoading(true);
    try {
      const res: any = await saveEmailTemplate(payload);
      await getEmailTemplates();
      setShowSaveTemplateDialog(false);
      setValue('name', '');
      setSelectedEmailTemplate(res.id);
    } catch (err) {
      setError(`name`, {
        type: 'custom',
        message: 'Error',
      });
    } finally {
      setSaveTemplateLoading(false);
    }
  };

  // const editMailTemplate = (id: any) => {
  //   const selectedTemplate = templateList.filter((item: any) => item.id === id);
  //   if (selectedTemplate && selectedTemplate[0]) {
  //     setSelectedTemplateObj(selectedTemplate[0]);
  //   }
  //   setValue('body', (selectedTemplate[0]?.template || ''))
  //   setValue('name', (selectedTemplate[0]?.name || ''))
  // };

  const onDeleteMailTemplate = async () => {
    setIsDeletingTemplate(true);
    try {
      await deleteEmailTemplate({ id: deleteTemplateId });
      await getEmailTemplates();
      setDeleteTemplateId('');
      setSelectedEmailTemplate('');
      setSelectedTemplateObj({});
      setValue('body', '');
      setValue('name', '');
    } catch (err) {
      // console.log('Delete failed');
    }
    setIsDeletingTemplate(false);
  };

  return (
    <SidePane
      open={openEmailSidePane}
      // autoWidth={true}
      onClose={onCloseSidePan}
      width={50}
      // className={classes.vPaneMobile}
    >
      <Box className={classes.sidePaneWrapper}>
        <Box className={classes.iconsWrapper}>
          <Typography variant="h5" className={classes.vInvestorName}>
            {get(emailSendFcrm, 'type') === 'MEET'
              ? 'Schedule Meeting'
              : 'Send Email'}
          </Typography>
          <Box className={classes.vLeftHeadSide}>
            <FormControl className={classes.formControl}>
              {get(emailSendFcrm, 'type') !== 'MEET' && (
                <>
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Select Email Template
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    //multiple
                    value={selectedEmailTemplate}
                    placeholder="Select Email Template"
                    className={classes.vSelectValInput}
                    onChange={(e: any) =>
                      setSelectedEmailTemplate(e.target.value)
                    }
                    input={<Input />}
                    //renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {templateList.map((template: any) => (
                      <MenuItem key={template.id} value={template.id}>
                        <Box className={classes.vInputValues}>
                          <ListItemText primary={template.name} />
                          <Box>
                            {/* <a onClick={(e: any) => {
                          e.stopPropagation();
                          editMailTemplate(template.id);
                        }}>
                          <img
                            src={Edit}
                            alt={Edit}
                            className={classes.vEmailTempBtn}
                          />
                        </a> */}
                            <a
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setDeleteTemplateId(template.id);
                              }}
                            >
                              <img
                                src={Delete}
                                alt={Delete}
                                className={classes.vEmailTempBtn}
                              />
                            </a>
                          </Box>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
            <Close
              onClick={() => onCloseSidePan()}
              className={classes.iconStyle}
            />
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Grid item xs={12} className={classes.statusForm}>
              {openEmailSidePane && (
                <InputField
                  control={control}
                  classes={classes}
                  label="Email"
                  placeholder="Enter Email"
                  {...register('toEmail', {
                    required: 'This field required',
                    pattern: {
                      value: emailRegex,
                      message: 'Enter a valid email id',
                    },
                  })}
                />
              )}
            </Grid>

            <Grid item xs={12} className={classes.statusForm}>
              <InputField
                control={control}
                classes={classes}
                disabled={get(emailSendFcrm, 'type') === 'MEET'}
                label="Subject"
                placeholder="Enter Subject"
                {...register('subject', { required: 'This field required' })}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.statusForm}>
              <Typography className={classes.vLabelText} variant="body1">
                Content
              </Typography>
              {/* name="body" */}
              {showEditor && (
                <Controller
                  control={control}
                  {...register('body', { required: 'This field required' })}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <RichTextEditor
                        value={value}
                        getSignedUrl={fcrmEmailMediaSignedUrl}
                        purpose="edit"
                        setDefaultEditorStateToNull={
                          get(emailSendFcrm, 'type') === 'SEND_EMAIL'
                        }
                        requestObject={{
                          fcrmId: get(emailSendFcrm, 'data.id'),
                        }}
                        onChange={onChange}
                      />
                      {!isEmpty(getValues('body')) &&
                        get(emailSendFcrm, 'type') !== 'MEET' && (
                          <Box className={classes.vSaveTemplateBlock}>
                            <Button
                              name={
                                watch('name') &&
                                watch('name') === selectedTemplateObj?.name
                                  ? 'Update Template'
                                  : 'Save as Template'
                              }
                              type="button"
                              onClick={() => setShowSaveTemplateDialog(true)}
                              className={classes.saveTemplateSecondaryBtn}
                            />
                          </Box>
                        )}
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                      {successMessage && (
                        <span className={classes.successText}>
                          {successMessage}
                        </span>
                      )}
                    </>
                  )}
                />
              )}
            </Grid>
            {!isEmailVerified && (
              <Grid item container xs={12} className={classes.statusForm}>
                <Alert severity="info">
                  If you want to send the Email from your Email id "{email}"
                  then please verify the Email address.{' '}
                  <a href="javascript:void(0)" onClick={verifyEmail}>
                    Click here
                    {verifyEmailStatus === 'VERIFYING' && (
                      <CircularProgress size={'.8rem'} />
                    )}
                  </a>{' '}
                  to send the verification email
                </Alert>
              </Grid>
            )}
            <Grid item container xs={12} className={classes.statusForm}>
              <Grid item xs={12}>
                <Button
                  name="Send"
                  type="button"
                  onClick={onSubmit}
                  className={classes.investorPrimaryBtn}
                  isLoading={isLoading}
                />
                <Button
                  name="Send Test Email"
                  type="button"
                  onClick={sendTestMail}
                  className={classes.investorPrimaryBtn}
                  isLoading={isTestLoading}
                />
                <Button
                  name="Cancel"
                  type="button"
                  className={classes.investorSecondaryBtn}
                  onClick={() => setOpenEmailSidePane('')}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
        <Dialog
          open={showSaveTemplateDialog}
          maxWidth={'sm'}
          subheading={''}
          title={'Save as'}
          handleClose={() => setShowSaveTemplateDialog(false)}
          dialogAction={true}
          dialogActionChildren={
            <Box>
              <Button
                name={
                  watch('name') === selectedTemplateObj?.name
                    ? 'Update'
                    : 'Save as new'
                }
                onClick={onSaveEmailTemplate}
                className={classes.continueBtn}
                isLoading={saveTemplateLoading}
                disabled={saveTemplateLoading}
              />
              <Button
                className={classes.backBtn}
                onClick={() => setShowSaveTemplateDialog(false)}
                disabled={saveTemplateLoading}
                name="Cancel"
              />
            </Box>
          }
        >
          <Box>
            <InputField
              control={control}
              classes={classes}
              label="Name of the template"
              placeholder="Enter the template name"
              {...register('name', { required: 'This field required' })}
            />
          </Box>
          {saveTemplteErr && (
            <Box className={classes.errorText}>{saveTemplteErr}</Box>
          )}
        </Dialog>

        {/* DELETE TEMPLATE DIALOG */}
        <Dialog
          open={!isEmpty(deleteTemplateId)}
          maxWidth={'sm'}
          subheading={''}
          title={' '}
          handleClose={() => setDeleteTemplateId('')}
          dialogAction={true}
          dialogActionChildren={
            <Box>
              <Button
                name="Yes"
                onClick={onDeleteMailTemplate}
                className={classes.continueBtn}
                isLoading={isDeletingTemplate}
                disabled={isDeletingTemplate}
              />
              <Button
                className={classes.backBtn}
                onClick={() => setDeleteTemplateId('')}
                disabled={isDeletingTemplate}
                name="Cancel"
              />
            </Box>
          }
        >
          <Box>Are you sure you want to delete this template?</Box>
        </Dialog>

        {/* AS FOR EMAIL VERIFICATION DIALOG */}
        <Dialog
          open={['SUCCESS', 'FAILED'].includes(verifyEmailStatus)}
          maxWidth={'sm'}
          title={'Confirmation'}
          handleClose={() => setEmailVerifyStatus('')}
          dialogAction={true}
          dialogActionChildren={
            <Box>
              <Button
                name="Ok"
                onClick={() => setEmailVerifyStatus('')}
                className={classes.continueBtn}
              />
            </Box>
          }
        >
          {['SUCCESS'].includes(verifyEmailStatus) && (
            <>
              <Box>
                Email sent successfully, please check your inbox to verify the
                Email address
              </Box>
            </>
          )}
          {['FAILED'].includes(verifyEmailStatus) && (
            <Box>Failed to send verification email. Please try again.</Box>
          )}
        </Dialog>
      </Box>
    </SidePane>
  );
};

export default FCRMEmailSidePane;
