import React, { useEffect, useRef } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepButton,
  makeStyles,
} from '@material-ui/core';

import { FN } from 'common/types';

const useStyles = makeStyles((theme) => ({
  stepperBox: {
    background: 'none',
    padding: '0 7px',
    '& .MuiStepIcon-root': {
      height: 18,
      width: 18,
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 16,
      '@media (max-width: 1120px)': {
        paddingRight: 10,
      },
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      '@media (max-width: 1024px)': {
        marginTop: 10,
      },
      '@media (max-width: 767px)': {
        marginTop: 5,
      },
    },
    '& .MuiStepConnector-vertical': {
      marginLeft: 10,
      padding: '5px 0',
      '@media (max-width: 1120px)': {
        padding: '1px 0',
      },
    },
    '& .MuiStepLabel-label': {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.35,
      color: theme.palette.warning.contrastText,
      '@media (max-width: 1120px)': {
        fontSize: 13,
        textAlign: 'left',
      },
      '@media (max-width: 1024px)': {
        textAlign: 'center',
      },
      '@media (max-width: 767px)': {
        fontSize: 11,
        lineheight: 14,
      },
    },
    '& .MuiStepConnector-lineVertical': {
      borderLeftStyle: 'dashed',
      minHeight: 35,
    },
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiStepConnector-alternativeLabel': {
      '@media (max-width: 1024px)': {
        top: 9,
        left: 'calc(-50% + 4px)',
        right: 'calc(50% + 14px)',
      },
    },
    '& .MuiStep-horizontal': {
      '@media (max-width: 767px)': {
        paddingRight: 5,
        paddingLeft: 5,
      },
    },
    '& .MuiStepButton-root': {
      '@media (max-width: 1024px)': {
        margin: -12,
        padding: 12,
      },
    },
    '@media (max-width: 1120px)': {
      padding: '0px',
    },
  },
  stepLabelText: {},
}));

type ComponentProps = {
  activeStep: number;
  steps: { name: string; key: string }[];
  orientation: boolean;
  className?: string;
  alternativeLabel?: boolean;
  handleStepClick: FN;
  completedSteps: number;
  allStepDisable?: boolean;
};
const wrappedStepper = ({
  activeStep,
  steps,
  orientation,
  className,
  alternativeLabel,
  handleStepClick,
  completedSteps,
  allStepDisable,
}: ComponentProps) => {
  const classes = useStyles();

  const componentMounted = useRef(true);
  const [state, setState] = React.useState({
    mobileView: false,
  });
  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      if (componentMounted.current) {
        return window.innerWidth <= 1024
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      }
      return;
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
      componentMounted.current = false;
    };
  }, []);

  return mobileView ? (
    <Stepper
      activeStep={activeStep}
      orientation={'horizontal'}
      className={className ? className : classes.stepperBox}
      alternativeLabel
      nonLinear={true}
    >
      {steps.map((label: { name: string; key: string }, index) => (
        <Step
          key={label.key}
          completed={completedSteps > index ? true : false}
          disabled={
            allStepDisable ? true : completedSteps < index ? true : false
          }
        >
          <StepButton
            onClick={() => {
              handleStepClick(index);
            }}
          >
            <StepLabel className={classes.stepLabelText}>
              {label.name}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  ) : (
    <Stepper
      activeStep={activeStep}
      orientation={orientation === true ? 'vertical' : 'horizontal'}
      className={className ? className : classes.stepperBox}
      alternativeLabel={alternativeLabel}
      nonLinear={true}
    >
      {steps.map((label: { name: string; key: string }, index) => (
        <Step
          key={label.key}
          completed={completedSteps > index ? true : false}
          disabled={
            allStepDisable ? true : completedSteps < index ? true : false
          }
        >
          <StepButton
            onClick={() => {
              handleStepClick(index);
            }}
          >
            <StepLabel className={classes.stepLabelText}>
              {label.name}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default wrappedStepper;
