import React from 'react';
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

type Props = {
  tabs: any;
  handleChange: any;
  value: number | string;
  className: any;
  minWidth?: number;
  keyAsValue?: boolean;
  variant?: 'fullWidth' | 'scrollable' | 'standard' | undefined;
  scrollButtons?: 'auto' | 'on' | 'off' | 'desktop' | undefined;
};

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FullWidthTabs({
  tabs,
  handleChange,
  value,
  className,
  minWidth,
  keyAsValue = false,
  variant,
  scrollButtons,
}: Props) {
  const classes = useStyles();
  const StyledTabRow = withStyles(() =>
    createStyles({
      root: {
        '&&': {
          minWidth: minWidth || 100,
          fontSize: 14,
          textTransform: 'capitalize',
        },
        '&.MuiTab-textColorPrimary.Mui-selected': {
          background: '#1EA59A1A',
        },
        '&.PrivateTabIndicator-root': {
          height: 3,
        },
      },
    }),
  )(Tab);

  const StyledTabRowBtn = withStyles(() =>
    createStyles({
      root: {
        '&&': {
          minWidth: 50,
          textTransform: 'capitalize',
          backgroundColor: '#E8F6F5',
          marginLeft: 10,
        },
        '&.MuiTab-textColorPrimary.Mui-selected': {
          background: '#1EA59A1A',
        },
        '&.PrivateTabIndicator-root': {
          height: 3,
          background: 'red',
        },
      },
    }),
  )(Tab);

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="full width tabs example"
        variant={variant}
        scrollButtons={scrollButtons}
        className={className}
      >
        {tabs.map((i: any, n: number) => {
          if (keyAsValue) {
            if (i.isTabButton) {
              return (
                <StyledTabRowBtn
                  key={i.key}
                  label={i.name}
                  value={i.key}
                  {...a11yProps(n - 1)}
                />
              );
            } else {
              return (
                <StyledTabRow
                  key={n}
                  label={i.name}
                  value={i.key}
                  {...a11yProps(n - 1)}
                />
              );
            }
          }
          return <StyledTabRow key={n} label={i.name} {...a11yProps(n - 1)} />;
        })}
      </Tabs>
    </div>
  );
}
