import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const logPaymentSchema = yup.object().shape({
  processedAt: yup.string().required(requiredConstant),
  transactionType: yup.string().required(requiredConstant),
  transactionId: yup.string().required(requiredConstant),
  amount: yup
    .number()
    .positive('Value must be greater than or equal to 0')
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v))
    .min(1, 'Value must be greater than 0')
    .required(requiredConstant),
  remarks: yup.string().trim().required(requiredConstant),
  startupId: yup.string() || undefined,
  loanType: yup.string(),
});
