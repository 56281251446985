import { get } from 'lodash';

import { urlConstants } from 'common/utils/constants';
import { secureApi } from 'api/Axios';

import { ActionsListPayload } from '../redux-modules/global/Types';

export const actionsListService = (payload: ActionsListPayload) => {
  const { role, startUpId, status } = payload;
  let url = '';

  const slug = get(window.location.pathname.split('/').slice(1), '[0]');
  const portal = window.localStorage.getItem('dashboard');
  if (role === 'STARTUP_ADMIN') {
    url = `${urlConstants.getActionsListStartUp}/${startUpId}?status=${status}&page=${slug}`;
  } else if (role === 'ADMIN') {
    url = `${
      urlConstants.getActionsListAdmin
    }?status=${status}&page=${slug}&portal=${portal || 'STARTUP'}`;
  }
  if (url) {
    return secureApi
      .get(url)
      .then((res) => res)
      .catch((err) => Promise.reject(err));
  }
};
