import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

// eslint-disable-next-line import/order
import FundRaisingCRM from './FundRaisingCRM';

export const FundRaisingCRMRoute: IRoute = {
  path: '/fund-raising-crm',
  component: FundRaisingCRM,
  layout: DashboardLayout,
  isAdmin: false,
  exact: true,
};

import FundRaisingCRMLists from './AdminFCRMList';

export const AdminFundRaisingCRMListsRoute: IRoute = {
  path: '/admin/fcrms',
  component: FundRaisingCRMLists,
  layout: DashboardLayout,
  exact: true,
};

export const AdminFundRaisingCRMDetailRoute: IRoute = {
  path: '/admin/fcrms/:startupId',
  component: FundRaisingCRM,
  layout: DashboardLayout,
  exact: true,
};
