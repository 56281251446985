import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  actionContainer: {
    height: 'calc(100vh - 20px)',
    width: '100%',
  },
  loaderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionContent: {},
  actionHead: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '80px 80px 30px 67px',
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 43,
    left: 40,
  },
  headText: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    fontWeight: 400,
    letterSpacing: 0.3,
    position: 'relative',
    top: -24,
  },
  enquiryTabs: {
    borderBottom: '0.5px solid #DEDEDE',
  },
  actionsBodyPaddingTop: {
    paddingTop: '30px',
  },
  actionContentText: {
    paddingLeft: 60,
    paddingRight: 80,
  },
  actionTabPanel: {
    height: 'calc(100vh - 230px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '@media (max-width: 1024px)': {
      // height: 'calc(100% - 60px - 80px)',
    },
  },
  // Empty Pending Styling -------- Start
  pendingEmpty: {
    textAlign: 'center',
    paddingTop: 100,
  },
  actionEmpty: {
    height: 240,
    marginBottom: 30,
  },
  emptyHead: {
    fontSize: 20,
    letterSpacing: 0.3,
    color: theme.palette.grey[500],
    marginBottom: 7,
    fontWeight: 400,
  },
  emptySub: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.grey[600],
  },
  // Empty Pending Styling -------- End
  pendingBox: {
    paddingTop: 38,
    maxWidth: 750,
    // paddingRight: 20,
  },
  // Action Pending item -------- Start

  dashactionSignNowBox: {
    marginBottom: 20,
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 18,
  },
  dashSignNowText: {
    color: theme.palette.grey[900],
    fontSize: 14,
    marginBottom: 12,
  },
  signNowBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    width: 90,
    height: 30,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  dashactionUploadBox: {
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 18,
    marginBottom: 20,
  },
  dashUploadText: {
    color: theme.palette.grey[900],
    fontSize: 14,
    marginBottom: 12,
  },
  dashUploadListText: {
    fontSize: 12,
    color: theme.palette.grey[600],
    letterSpacing: 0.35,
    marginBottom: 5,
  },
  UploadBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    width: 90,
    height: 30,
    marginTop: 10,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  // Action Pending item -------- End
  // Action Completed item -------- Start
  completedBox: {
    paddingTop: 38,
    maxWidth: 750,
    paddingRight: 20,
  },
  SignedBox: {
    display: 'flex',
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 20,
    marginBottom: 25,
  },
  circleIcon: {
    fill: theme.palette.primary.main,
    fontSize: 30,
  },
  SignedRight: {
    paddingLeft: 20,
  },
  signedText: {
    fontSize: 14,
    color: theme.palette.grey[900],
    marginBottom: 5,
  },
  signedSub: {
    fontSize: 13,
    color: theme.palette.info.light,
  },
  viewBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    width: 85,
    height: 30,
    marginTop: 10,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  // Action Completed item -------- End
  // Admin Action Styling -------- Start
  adminActionempty: {
    textAlign: 'center',
    paddingTop: 100,
  },
  adminActionimg: {
    height: 270,
    marginBottom: 25,
  },
  textHead: {
    fontSize: 20,
    letterSpacing: 0.3,
    color: theme.palette.grey[500],
    marginBottom: 7,
    fontWeight: 400,
  },
  textSub: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.grey[600],
  },
  // Admin Action Styling -------- End
}));
