import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';

import { Button } from 'components';
import history from 'common/utils/history';
import { benchmarkWelcomeImg, cac, cmgr } from 'assets';
import { RootState } from 'redux-modules/store/rootState';

import styles from './styles';

const GetStartedBox = ({ dashboardView = false }: any) => {
  const classes = styles();

  const { name } = useSelector(({ Auth }: RootState) => Auth.user);

  return dashboardView ? (
    <Box className={classes.benchMarkWelcomeContainerV2}>
      <Grid container>
        <Typography className={classes.benchmarkKpiText}>Yardstick</Typography>
      </Grid>
      <Grid
        container
        sm={12}
        md={12}
        spacing={1}
        className={classes.banchmarkBoxContainerV2}
      >
        <Grid item sm={12} md={8}>
          <Box className={classes.welcomeTextContent}>
            <ul className={classes.benchmarkListContent}>
              <li>
                Get a loan from 8vdX to extend your runway and substantially
                increase the company’s bargaining power during the next
                fundraise.
              </li>
              <br />
              <li>Wish to check how you stack up against your competitors?</li>
              <br />
              <li>
                Need more insights on what valuations to expect based on your
                traction?
              </li>
            </ul>
          </Box>
          <Button
            className={classes.getStartedButton}
            name="Get Started"
            onClick={() => history.push('/benchmark-kpi-v2/NEW')}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          className={classes.benchMarkWelcomeImageContent}
        >
          <Box className={classes.imageOverlapBoxesContainer}>
            <Box className={classes.imageOverlapBoxes}>
              <img src={cmgr} alt={cmgr} className={classes.overImg} />
              <Typography variant="body2" className={classes.growthIconText}>
                CMGR
                <Typography variant="caption" className={classes.precenText}>
                  25%
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.imageOverlapBoxes}>
              <img src={cac} alt={cac} className={classes.overImg} />
              <Typography variant="body2" className={classes.growthIconText}>
                Paid CAC
                <Typography variant="caption" className={classes.precenText}>
                  20%
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.imageOverlapBoxes}>
              <img src={cmgr} alt={cmgr} className={classes.overImg} />
              <Typography variant="body2" className={classes.growthIconText}>
                MRR Growth
                <Typography variant="caption" className={classes.precenText}>
                  35.7%
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box className={classes.benchMarkWelcomeContainer}>
      <Grid container xs={12}>
        <Typography className={classes.benchmarkKpiText}>Yardstick</Typography>
      </Grid>
      <Grid
        container
        sm={12}
        md={12}
        spacing={1}
        className={classes.banchmarkBoxContainer}
      >
        <Grid item sm={12} md={5}>
          <Typography className={classes.benchMarkWelcomeText}>
            Welcome, {name}.
          </Typography>
          <Box className={classes.welcomeTextContent}>
            <ul className={classes.benchmarkListContent}>
              <li>
                Get a loan from 8vdX to extend your runway and substantially
                increase the company’s bargaining power during the next
                fundraise.
              </li>
              <br />
              <li>Wish to check how you stack up against your competitors?</li>
              <br />
              <li>
                Need more insights on what valuations to expect based on your
                traction?
              </li>
            </ul>
          </Box>
          <Button
            className={classes.getStartedButton}
            name="Get Started"
            onClick={() => history.push('/benchmark-kpi-v2/NEW')}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={7}
          className={classes.benchMarkWelcomeImageContent}
        >
          <Box className={classes.imageOverlapBoxesContainer}>
            <Box className={classes.imageOverlapBoxes}>
              <img src={cmgr} alt={cmgr} className={classes.overImg} />
              <Typography variant="body2" className={classes.growthIconText}>
                CMGR
                <Typography variant="caption" className={classes.precenText}>
                  25%
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.imageOverlapBoxes}>
              <img src={cac} alt={cac} className={classes.overImg} />
              <Typography variant="body2" className={classes.growthIconText}>
                Paid CAC
                <Typography variant="caption" className={classes.precenText}>
                  20%
                </Typography>
              </Typography>
            </Box>
            <Box className={classes.imageOverlapBoxes}>
              <img src={cmgr} alt={cmgr} className={classes.overImg} />
              <Typography variant="body2" className={classes.growthIconText}>
                MRR Growth
                <Typography variant="caption" className={classes.precenText}>
                  35.7%
                </Typography>
              </Typography>
            </Box>
          </Box>
          <img
            src={benchmarkWelcomeImg}
            className={classes.benchMarkWelcomeImage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default GetStartedBox;
