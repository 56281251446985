import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  enquiryViewContainer: {
    paddingLeft: 60,
    '@media (max-width: 1024px)': {
      paddingLeft: 20,
    },
  },
  enquiryViewBox: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 20px)',
  },
  enquiryViewBoxLeft: {
    width: 'calc(100% - 300px)',
    flex: '0 auto',
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 260px)',
    },
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: 20,
    },
    '@media (max-width: 525px)': {
      paddingLeft: 15,
    },
  },
  enquiryListhead: {
    position: 'relative',
    padding: '78px 63px 20px',
    '@media (max-width: 1024px)': {
      padding: '110px 40px 20px',
    },
    '@media (max-width: 767px)': {
      padding: '50px 20px 15px',
    },
  },
  imgOrnament: {
    position: 'absolute',
    top: 40,
    left: 35,
    height: 90,
    width: 90,
    '@media (max-width: 1120px)': {
      width: 60,
      height: 60,
    },
    '@media (max-width: 1024px)': {
      top: 83,
      left: 20,
      objectFit: 'cover',
    },
    '@media (max-width: 767px)': {
      top: 23,
      left: 20,
      objectFit: 'cover',
    },
  },
  dashSuccessImg: {
    height: 250,
    paddingTop: 0,
    paddingBottom: 10,
  },
  enquirySuccessBox: {
    // paddingRight: 125,
    height: 'calc(100vh - 120px)',
    display: 'flex',
  },
  eBoxContent: {
    width: '100%',
    alignSelf: 'center',
    marginTop: -130,
  },
  successHeaderTxt: {
    fontSize: 20,
    color: '#262A41',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    lineHeight: '30px',
    maxWidth: 650,
    margin: 'auto',
    marginBottom: 20,
  },
  successSubTxt: {
    fontSize: 15,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    marginTop: 15,
  },
  notFound: {
    fontSize: 29,
    color: theme.palette.error.contrastText,
    position: 'relative',
    top: -19.5,
  },
}));
