import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import { Box, Tabs, TabPanel, UserList, Dialog, Button } from 'components';
import { Ornament } from 'assets';
import NoItemsImg from 'components/NoItemsImg';
import { deleteUserService, userListService } from 'services';
import { InviteUser } from 'common/types';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';
import AddUser from './AddUser/AddUser';

const Settings = () => {
  const classes = styles();
  const { role, startUpId } = useSelector(({ Auth }: RootState) => Auth.user);

  const [value, setValue] = useState<number>(0);
  const [userList, setUserList] = useState<Array<InviteUser>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isOpen, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState<InviteUser | null>(null);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const tabs = useMemo(() => {
    const tabsList = [
      { name: 'Access', key: 'ACCESS' },
      // { name: 'Others', key: 'OTHERS' },
    ];

    return tabsList;
  }, []);

  const getUserList = async (role: string) => {
    try {
      setIsLoading(true);
      const response: any = await userListService(role);
      if (typeof get(response, 'data') !== 'string') {
        setUserList(get(response, 'data', []));
      }
      setIsLoading(false);
    } catch (err) {
      const message: string = errorMessageHandler(err) || get(err, 'message');
      setIsLoading(false);
      setErrorMessage(message);
    }
  };

  useEffect(() => {
    if (role && value == 0) {
      getUserList(role);
    }
  }, [role, value]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleShowEmptyMessage = () => {
    if (isEmpty(userList) && !isLoading) {
      return <NoItemsImg />;
    }
  };

  const handleDialogOpen = (user: InviteUser) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setUser(null);
    setDeleteAction(false);
    setErrorMessage('');
    setOpen(false);
  };

  const handleDeleteUser = async () => {
    try {
      if (user?.id) {
        setDeleteLoading(true);
        await deleteUserService(role, user?.id);
        setDeleteLoading(false);
        getUserList(role);
        handleClose();
      }
    } catch (err) {
      const message: string = errorMessageHandler(err) || get(err, 'message');
      setDeleteLoading(false);
      setErrorMessage(message);
    }
  };

  return (
    <>
      <Box className={classes.settingsContainer}>
        <Box className={classes.settingsContent}>
          <Box className={classes.settingsHead}>
            <img
              src={Ornament}
              alt={Ornament}
              className={classes.enquiryImage}
            />
            <Typography variant="h6" className={classes.headText}>
              Settings
            </Typography>
          </Box>
          <Box className={classes.settingsContentText}>
            <Tabs
              tabs={tabs}
              value={value}
              handleChange={handleChange}
              className={classes.enquiryTabs}
            />
            <TabPanel
              value={value}
              index={0}
              className={classes.settingsTabPanel}
            >
              <Box className={classes.settingsBodyPaddingTop} />
              <UserList
                userList={userList}
                isLoading={isLoading}
                errorMessage={!deleteAction ? errorMessage : ''}
                handleDialogOpen={handleDialogOpen}
                setDeleteAction={setDeleteAction}
              />
              {handleShowEmptyMessage()}
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              className={classes.settingsTabPanel}
            >
              <Box className={classes.settingsBodyPaddingTop} />
              <div>Coming Soon</div>
            </TabPanel>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        className={classes.addUserModel}
        subheading={''}
        title={
          isEmpty(user)
            ? 'New User'
            : deleteAction
            ? 'Delete User'
            : 'Update User'
        }
        handleClose={() => {
          handleClose();
        }}
        dialogAction={deleteAction}
        dialogActionChildren={
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              name="OK"
              onClick={handleDeleteUser}
              className={classes.btnSubmit}
              isLoading={deleteLoading}
              disabled={deleteLoading}
            />
            <Button
              className={classes.backBtn}
              onClick={handleClose}
              name="Cancel"
            />
          </Box>
        }
      >
        {deleteAction ? (
          <>
            {errorMessage && (
              <Typography variant="caption" className={classes.errorText}>
                {errorMessage}
              </Typography>
            )}
            <Typography variant="h5" className={classes.deleteUsertext}>
              Are you sure, you want to delete the user?
            </Typography>
          </>
        ) : (
          <AddUser
            handleBack={() => setOpen(false)}
            startupId={startUpId}
            user={user}
            getUserList={() => getUserList(role)}
          />
        )}
      </Dialog>
    </>
  );
};
export default Settings;
