import React from 'react';
import { isEmpty, get } from 'lodash';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Select,
  FormControl,
  MenuItem,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Box, Dialog, Button } from 'components';

import styles from './styles';

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: 12,
  },
  head: {
    backgroundColor: '#F8F8F8',
    color: theme.palette.common.black,
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    textAlign: 'center',
    letterSpacing: '3%',
    border: 'none',
  },
  body: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    border: 'none',
    textAlign: 'center',
  },
}))(TableCell);

const PreviewCsv = ({
  preview,
  setPreview,
  tableData,
  selectedColumns,
  setSelectedColumns,
  isLoading,
  errorMessage,
  errorData,
  noMapColumns,
  columnMapping,
  handleUpload,
  importUniqueProcess, // If true and errorData is not empty highlight that data in preview
  duplicateRecord,
}: any) => {
  const classes = styles();
  const columnValueMapping = (columnMapping || []).map((x: any) => x.value);
  return (
    <Dialog
      open={preview}
      maxWidth={'lg'}
      title="Map column headers in red with correct data from your spreadsheet"
      handleClose={() => {
        setPreview(false);
      }}
    >
      <Box className={classes.previewUploadBox}>
        {!isEmpty(tableData) && (
          <Box className={classes.tableDescription}>
            <Box className={classes.tableDescriptionInnerBox}>
              <Typography variant="body1" className={classes.columnHeadersText}>
                Column Headers
              </Typography>
              <Typography variant="body1" className={classes.dataText}>
                Your Data
              </Typography>
            </Box>
            <TableContainer className={classes.previewTable}>
              <Table stickyHeader aria-label="sticky table">
                <colgroup>
                  {tableData[0].map((column: any, index: number) => (
                    <col
                      key={`colgroup_${index}`}
                      style={index === 0 ? { width: 80, minWidth: 80 } : {}}
                    />
                  ))}
                </colgroup>
                <TableHead>
                  <TableRow
                    style={{
                      top: 0,
                    }}
                    className={classes.tableHeaderStickyRow}
                  >
                    {tableData[0].map((row: any, index: any) => (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={`col_${index}`}
                        style={
                          index === 0
                            ? {
                                left: 0,
                                backgroundColor: !columnValueMapping.includes(
                                  selectedColumns[index],
                                )
                                  ? '#FFEAEA'
                                  : '#DEFFDF',
                              }
                            : index === 1
                            ? {
                                left: 90,
                                backgroundColor: !columnValueMapping.includes(
                                  selectedColumns[index],
                                )
                                  ? '#FFEAEA'
                                  : '#DEFFDF',
                              }
                            : {
                                backgroundColor: !columnValueMapping.includes(
                                  selectedColumns[index],
                                )
                                  ? '#FFEAEA'
                                  : '#DEFFDF',
                              }
                        }
                        className={
                          [0, 1].includes(index)
                            ? classes.tableHeaderStickyCell
                            : ''
                        }
                      >
                        {columnMapping?.length > 0 ? (
                          <FormControl
                            error={
                              !columnValueMapping.includes(
                                selectedColumns[index],
                              )
                            }
                            style={{
                              minWidth: 90,
                            }}
                          >
                            <Select
                              onChange={({ target: { value } }) => {
                                const selectedColumnsTemp = Object.assign(
                                  [],
                                  selectedColumns,
                                );
                                selectedColumnsTemp[index] = value;
                                setSelectedColumns(selectedColumnsTemp);
                              }}
                              value={selectedColumns[index]}
                              className={classes.selectField}
                            >
                              {!columnMapping.includes(row) && (
                                <MenuItem value={''} disabled>
                                  {row}
                                </MenuItem>
                              )}
                              {columnMapping?.map((item: any) => {
                                return (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        ) : (
                          'row'
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>

                  <TableRow
                    style={{
                      top: 50,
                    }}
                    className={classes.tableHeaderStickyRow}
                  >
                    {tableData[0].map((row: any, index: any) => (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={`col_${index}`}
                        style={
                          index === 0
                            ? {
                                left: 0,
                              }
                            : index === 1
                            ? {
                                left: 90,
                              }
                            : {}
                        }
                        className={
                          [0, 1].includes(index)
                            ? classes.tableHeaderStickyCell
                            : ''
                        }
                      >
                        {row}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.slice(1).map((row: any, index: any) => (
                    <TableRow key={index}>
                      {row.map((item: any, rowIndex: number) => (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          key={`row_${rowIndex}`}
                          align="center"
                          style={
                            rowIndex === 0
                              ? {
                                  left: 0,
                                  backgroundColor:
                                    importUniqueProcess &&
                                    duplicateRecord.includes(get(row, rowIndex))
                                      ? '#FFEAEA'
                                      : undefined,
                                }
                              : rowIndex === 1
                              ? {
                                  left: 90,
                                  backgroundColor:
                                    importUniqueProcess &&
                                    duplicateRecord.includes(get(row, rowIndex))
                                      ? '#FFEAEA'
                                      : undefined,
                                }
                              : {
                                  backgroundColor:
                                    importUniqueProcess &&
                                    duplicateRecord.includes(get(row, rowIndex))
                                      ? '#FFEAEA'
                                      : undefined,
                                }
                          }
                          className={
                            [0, 1].includes(rowIndex)
                              ? classes.tableBodyStickyCell
                              : ''
                          }
                        >
                          {get(row, rowIndex)}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {noMapColumns?.length > 0 && (
          <Box className={classes.errorMessageSmall}>
            Please review the column headers underlined in red and assign the
            correct column names.
          </Box>
        )}
        {errorMessage && (
          <>
            <Typography variant="body1" className={classes.errorMessage}>
              {errorMessage}
            </Typography>
            {!isEmpty(errorData) &&
              Array.isArray(errorData) &&
              errorData.map((error: string, index: number) => (
                <Typography
                  variant="body1"
                  className={classes.errorMessage}
                  key={index}
                >
                  {error}
                </Typography>
              ))}
          </>
        )}
        <Grid item style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            name="Upload"
            className={classes.uploadBtn}
            onClick={() => handleUpload()}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </Grid>
      </Box>
    </Dialog>
  );
};

export default PreviewCsv;
