import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, DialogActions } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isEmpty, get, filter, uniqBy, orderBy } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  validateYcStatus,
  handleYCVerification,
  getLoanLogs,
  updateStartUpLoanTerms,
  getStartupLoan,
  manageLoan as handleManageLoan,
} from 'services';
import { FN } from 'common/types';
import {
  Accordion,
  Box,
  Button,
  Dialog,
  WrappedSelect,
  WrappedTextInput,
  ConfirmDialog,
  AlertDialog,
} from 'components';
import { IconImg } from 'assets';
import { RootState } from 'redux-modules/store/rootState';
import { emptySuccessMessage } from 'redux-modules/process/Actions';
import {
  getYcDocsByStartupId,
  getLoansDetailsByStartupId,
  getLoanTermsByStartupId,
  // getLoanCloserInfo,
  handleGetRepaidAmountAction,
} from 'redux-modules/enquiries/Actions';
import { YcDocumentsSchema } from 'common/utils/validations';
import {
  errorMessageHandler,
  getLoanCloserStatus,
  getParsedPriceInputValue,
} from 'common/utils/helpers';

import LoanCloser from './LoanCloser';
import ReviewDocuments from '../../../ReviewDocumentsList';
import YCStatus from '../YCStatus';
import { ApproveLoan, ProcessLoan } from '..';
import styles from '../../styles';
import { AdminLoanTermsUpdateSchema } from '../../validations';
import ReviewLoanForm from './ReviewLoanForm';

type BridgeLoanProps = {
  fundType: string | undefined;
  startupId: string | undefined;
  isRoleAdmin: boolean;
  isDispatchReq: boolean;
  expanded: string | boolean;
  input: boolean;
  documentName: string;
  documentsList: any;
  isDisabled: boolean;
  nonDocumentsList: any;
  isDocuSignSendDisabled: boolean;
  setInput: FN;
  setDisable: FN;
  handleChange: FN;
  handleAddDocuments: FN;
  handleSubmitDocuments: FN;
  handleSetDocumentName: FN;
  handleUpdateRequestedDoc: FN;
  handleSendDocuSignDocs: FN;
  validateDocuments: FN;
  handleDeleteDocument: FN;
  docuSignDocumentsList: any;
};

const BridgeLoanProcess = ({
  fundType,
  startupId,
  isRoleAdmin,
  expanded,
  handleChange,
  validateDocuments,
}: BridgeLoanProps) => {
  const classes = styles();
  const dispatch = useDispatch();

  const {
    handleSubmit: ycFormHandleSubmit,
    getValues: ycGetValues,
    control: ycControl,
    setValue: ycSetvalue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(YcDocumentsSchema),
  });

  const [isOpen, setOpen] = useState<boolean>(false);
  const [expandYcStep, setExpandYcStep] = useState<boolean>(false);
  const [isEditYc, setEditYc] = useState<boolean>(false);

  const [dialogFormTitle, setDialogFormTitle] = useState<string>('');
  const [updatingLoanTerms, setUpdatingLoanTerms] = useState<boolean>(false);
  const [documentObject, setDocumentObject] = React.useState<any>([]);
  const [approveLoanNotify, setApproveLoanNotify] = useState<boolean>(false);
  const { Documents, isLoading } = useSelector(
    ({ Process }: RootState) => Process,
  );
  const { documents } = Documents;
  const [approveDialog, setApproveDialog] = useState<boolean>(false);
  const [manageLoan, setManageLoan] = useState<boolean>(false);
  const [managelLoanState, setManageLoanState] = useState<string>('');
  const [rejectedDocsLogs, setRejectedDocsLogs] = useState<Array<any>>([]);
  const [loanLogs, setLoanLogs] = useState<Array<any>>([]);
  const [manageLoanReason, setManageLoanReason] = useState<string>('');
  const [managingLoan, setManagingLoan] = useState<boolean>(false);
  const { holdRejectSuccessMessage, errorMessage } = useSelector(
    ({ Process }: RootState) => Process,
  );
  const [holdRejectError, setHoldRejectError] = useState<boolean>(false);
  const [loanTermErrorMessage, setLoanTermErrorMessage] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loanCloserDialogOpen, setloanCloserDialogOpen] = useState<boolean>(
    false,
  );
  const [loan, setLoan] = useState<any>({});

  const [approvedLoanTerm, setApprovedLoanTerm] = useState<boolean>(false);
  const [ycVerfLoading, setYcVerfLoading] = useState<boolean>(false);
  const [count, setCount] = useState({});
  const [updatingYcStatus, setUpdatingYcStatus] = useState<boolean>(false);
  const [
    manageLoanConfirmDialog,
    setManageLoanConfirmDialog,
  ] = useState<boolean>(false);
  const [loanClosureLogs, setLoanClosureLogs] = useState<any>([]);
  const [showMfn, setShowMfn] = useState<boolean>(false);
  const env = process.env.REACT_APP_ENV || 'dev';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { id: enquiryId } = useParams();
  const {
    bridgeLoanProcess,
    enquiryView,
    loanCloserInfo,
    isLoadingLoanCloserInfo,
  } = useSelector(({ Enquiries }: RootState) => Enquiries);

  const isBridgeLoanLoading: any = useSelector(
    ({ Enquiries }: RootState) => Enquiries.isLoading,
  );

  const {
    loanTerms,
    isLoadingLoanTerms,
    ycDocuments,
    isLoadingYcDocs,
  } = useSelector(({ Enquiries }: RootState) => Enquiries);

  const schema = AdminLoanTermsUpdateSchema;
  if (!showMfn && get(loanTerms, 'loanType') !== 'BRIDGE_TO_DEMO_DAY') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete schema.fields.valuation;
  }

  const { handleSubmit, control, getValues, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isEmpty(documents)) {
      const temp = documents.length;
      const tempVerified = (
        documents.filter((e: any) => e.documentStatus === 'VERIFIED') || []
      ).length;
      setCount({ verified: tempVerified, total: temp });
    }
  }, [documents]);

  useEffect(() => {
    setDocumentObject(ycDocuments);
  }, [ycDocuments]);

  useEffect(() => {
    if (isRoleAdmin && startupId) {
      dispatch(getLoanTermsByStartupId(`${startupId}/BRIDGE_LOAN`));
      // dispatch(getLoanCloserInfo(startupId));
      dispatch(
        handleGetRepaidAmountAction(
          isRoleAdmin
            ? `startupId=${startupId}&loanType=BRIDGE_LOAN`
            : `loanType=BRIDGE_LOAN`,
        ),
      );
    }
  }, [startupId]);

  useEffect(() => {
    if (
      get(loanTerms, 'termsChangedByAdmin') ||
      get(loanTerms, 'approvedByAdmin') ||
      get(bridgeLoanProcess, 'documentsSigned')
    ) {
      setApprovedLoanTerm(true);
    }
  }, [loanTerms, bridgeLoanProcess]);

  useEffect(() => {
    if (!isLoadingLoanCloserInfo) {
      fetchLoanLogs();
      dispatch(
        handleGetRepaidAmountAction(
          isRoleAdmin
            ? `startupId=${startupId}&loanType=BRIDGE_LOAN`
            : `loanType=BRIDGE_LOAN`,
        ),
      );
    }
  }, [isLoadingLoanCloserInfo]);

  const handleFetchLoan = () => {
    getStartupLoan(`${startupId}/BRIDGE_LOAN`).then((res) => setLoan(res));
  };
  useEffect(() => {
    if (startupId) {
      handleFetchLoan();
    }
  }, [startupId]);

  const onApprove = async () => {
    const data: any = { ...getValues() };
    setUpdatingLoanTerms(true);
    const payload: any = data;
    data.id = get(loanTerms, 'id');
    data.startupId = get(loanTerms, 'startupId');
    data.loanType = get(loanTerms, 'loanType');
    data.loanAmount = getParsedPriceInputValue(data.loanAmount);
    data.safeCover = getParsedPriceInputValue(data.safeCover);
    data.valuation = getParsedPriceInputValue(data.valuation);
    await updateStartUpLoanTerms(payload)
      .then(() => dispatch(getLoanTermsByStartupId(`${startupId}/BRIDGE_LOAN`)))
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setLoanTermErrorMessage(message);
      });
    setUpdatingLoanTerms(false);
    setDialogOpen(false);
    setApprovedLoanTerm(true);
  };

  const onManageLoan = async () => {
    if (!manageLoanReason) {
      setHoldRejectError(true);
      return;
    }
    const payload = {
      action: managelLoanState,
      reason: manageLoanReason,
      enquiryId,
      startupId: get(enquiryView, 'startupDetails.startupId'),
    };
    try {
      setManagingLoan(true);
      await handleManageLoan(payload);
      await fetchLoanLogs();
      setManageLoan(false);
      setManageLoanReason('');
      handleFetchLoan();
    } catch (err) {
      console.log(err); //eslint-disable-line
    } finally {
      setManagingLoan(false);
      setManageLoanConfirmDialog(false);
    }
  };

  const fetchLoanLogs = () => {
    getLoanLogs(enquiryId).then((res: any) => {
      const docLogs = (
        uniqBy(orderBy(res, ['updatedAt'], ['desc']), 'itemName') || []
      ).filter(
        (item: any) =>
          ['DOCUMENT', 'YC_DOCUMENT'].includes(item.actionItem) &&
          item.status === 'REJECTED',
      );
      const loanClosureLogs = (res || []).filter(
        (item: any) => item.actionItem === 'LOAN_CLOSURE',
      );
      setRejectedDocsLogs(uniqBy(docLogs, 'itemName'));
      setLoanClosureLogs(loanClosureLogs);
      setLoanLogs(
        (res || []).filter((item: any) => item.actionItem === 'LOAN'),
      );
    });
  };
  useEffect(() => {
    fetchLoanLogs();
  }, [enquiryId]);

  useEffect(() => {
    if (!isEmpty(holdRejectSuccessMessage)) {
      setManageLoan(false);
      fetchLoanLogs();
    }
  }, [holdRejectSuccessMessage]);

  const allDocumentsApproved: boolean =
    filter(documents, { documentStatus: 'VERIFIED' }).length ===
    documents.length;

  const allDocVerified = () => {
    return (documents || []).every(
      (doc: any) => doc.documentStatus === 'VERIFIED',
    );
  };
  const handleGetName = (value: string) => {
    switch (value) {
      case 'REQUIRED_DOCUMENTS':
        if (isRoleAdmin) {
          return get(bridgeLoanProcess, 'documentRequestRaised')
            ? 'Request raised'
            : 'Yet to raise request';
        } else {
          return get(bridgeLoanProcess, 'documentsSubmitted')
            ? 'Documents Submitted'
            : 'Yet to submit Documents';
        }
      case 'DOCU_SIGN_DOCUMENTS':
        if (isRoleAdmin) {
          return get(bridgeLoanProcess, 'docuSignRequestRaised')
            ? 'Request raised'
            : 'Yet to raise request';
        } else {
          return get(bridgeLoanProcess, 'documentsSigned')
            ? 'Documents Signed'
            : 'Yet to sign Documents';
        }
      case 'REVIEW_DOCUMENTS':
        // eslint-disable-next-line no-case-declarations
        const isAllDocVerified = allDocVerified();
        if (!isAllDocVerified) {
          return 'Yet to verify documents';
        } else if (
          isAllDocVerified ||
          get(bridgeLoanProcess, 'documentsVerified')
        ) {
          return 'Documents verified';
        } else {
          return 'Yet to verify documents';
        }
      case 'YC_STATUS':
        return !isEditYc ? 'Edit' : 'Save';
      case 'APPROVE_LOAN':
        return get(bridgeLoanProcess, 'isLoanApproved')
          ? 'Approved'
          : 'Yet to approve loan';
      case 'PROCESS_LOAN':
        return get(bridgeLoanProcess, 'isLoanProcessed')
          ? 'Processed'
          : 'Yet to process loan';
      default:
        break;
    }
  };
  const neitherRejectedNorApproved = () => {
    const allDocs = [...ycDocuments, ...documents];
    return (allDocs || []).every(
      (doc: any) => !['REJECTED', 'VERIFIED'].includes(doc.documentStatus),
    );
  };

  const handleGetApproveLoanSubHeading = () => {
    if (get(bridgeLoanProcess, 'isLoanApproved')) {
      return 'Loan is approved. Click on View to get the details.';
    }

    if (get(bridgeLoanProcess, 'documentsVerified')) {
      if (get(bridgeLoanProcess, 'isLoanApproved')) {
        return 'Loan is approved. Click on View to get the details.';
      } else {
        return isRoleAdmin
          ? 'Fill the Approve loan form to Approve loan'
          : 'Your loan will be approved soon';
      }
    } else {
      return isRoleAdmin
        ? isEmpty(documents)
          ? 'No documents available to approve'
          : 'Verify the documents to approve loan'
        : get(bridgeLoanProcess, '  ') &&
          get(bridgeLoanProcess, 'documentsSigned')
        ? 'Document verification is in progress. Your loan will be approved soon.'
        : 'Submit the required documents to get your loan approved';
    }
  };

  const handleGetProcessLoanSubHeading = () => {
    if (get(bridgeLoanProcess, 'isLoanApproved')) {
      if (get(bridgeLoanProcess, 'isLoanProcessed')) {
        return 'Loan is processed. Click on View to get the details.';
      } else {
        return isRoleAdmin
          ? 'Fill the Process loan form to process loan'
          : 'Your loan will be processed soon';
      }
    } else {
      return isRoleAdmin
        ? 'Loan has to be approved prior to processing it'
        : get(bridgeLoanProcess, 'documentsSubmitted') &&
          get(bridgeLoanProcess, 'documentsSigned')
        ? 'Document verification is in progress. Your loan will be approved and processed soon.'
        : 'Submit the required documents to get your loan approved and processed';
    }
  };

  useEffect(() => {
    if (
      !isEmpty(bridgeLoanProcess) &&
      (bridgeLoanProcess.isLoanApproved || bridgeLoanProcess.isLoanProcessed)
    ) {
      // if (isRoleAdmin) {
      //   dispatch(getFundType(startupId));
      // } else {
      //   dispatch(getLoansDetailsByStartupId(startupId));
      // }
      if (!isRoleAdmin) {
        dispatch(getLoansDetailsByStartupId(startupId));
      }
    }
  }, [bridgeLoanProcess]);

  const handleYcSubmit = async () => {
    setYcVerfLoading(true);

    const { assignedYcLegalPocName, assignedYcPartnerName } = ycGetValues();

    const docObj = documentObject[0];
    if (!isEmpty(ycDocuments) && !isEmpty(documentObject)) {
      docObj.id = get(ycDocuments, '[0].id');
    }
    const payload = {
      documents: [docObj],
      enquiryId: enquiryId,
      startupId: get(enquiryView, 'startupDetails.startupId'),
      assignedYcLegalPocName,
      assignedYcPartnerName,
      fundType: 'BRIDGE_LOAN',
    };

    await handleYCVerification(payload);

    dispatch(
      getYcDocsByStartupId(
        `${get(enquiryView, 'startupDetails.startupId')}##BRIDGE_LOAN`,
      ),
    );

    setEditYc(false);
    setYcVerfLoading(false);
  };
  const handleApproveLoan = (e: any) => {
    dispatch(emptySuccessMessage());
    const val = e.target.value;
    if (val === 'MANAGE') return;
    if (val === 'APPROVED') {
      if (!allDocVerified() || !get(bridgeLoanProcess, 'documentsSigned')) {
        setApproveLoanNotify(true);
      } else {
        setApproveDialog(true);
      }
    } else {
      setManageLoan(true);
    }
    setManageLoanState(val);
    if (val === 'ONHOLD') {
      fetchLoanLogs();
    }
  };

  const handleValidateYcStatus = () => {
    setUpdatingYcStatus(true);
    validateYcStatus({
      startupId: get(enquiryView, 'startupDetails.startupId'),
      fundType: 'BRIDGE_LOAN',
    })
      .then(() => validateDocuments())
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
      .finally(() => setUpdatingYcStatus(false));
  };
  // const validateDisable = () => {
  //   return !(documentObject || []).every(
  //     (item: any) => item.documentStatus === 'VERIFIED',
  //   );
  // };

  const onChangeReason = (val: string) => {
    setManageLoanReason(val);
    if (val) {
      setHoldRejectError(false);
    } else {
      setHoldRejectError(true);
    }
  };

  const getLoanDropdowns = () => {
    const options = [
      { text: 'Manage Loan', value: 'MANAGE' },
      {
        text: get(bridgeLoanProcess, 'isLoanApproved') ? 'Approved' : 'Approve',
        value: 'APPROVED',
      },
      {
        text: get(loan, 'loanStatus') === 'REJECTED' ? 'Rejected' : 'Reject',
        value: 'REJECTED',
      },
      { text: 'OnHold', value: 'ONHOLD' },
    ];
    if (['REJECTED', 'ONHOLD'].includes(get(loan, 'loanStatus'))) {
      options.splice(0, 1);
      return options;
    }
    return options;
  };

  const [loanCloserType, setLoanCloserType] = useState<string>('');

  const handleLoanCloserOpen = (e: any) => {
    const val = e.target.value;
    setLoanCloserType(val);
    setloanCloserDialogOpen(true);
  };

  const handleLoanCloserClose = () => {
    setloanCloserDialogOpen(false);
    setLoanCloserType('');
  };

  return (
    <>
      <Box>
        {/* <Typography
          className={classes.bridgeLoadText}
          gutterBottom
          variant="h3"
        >
          BRIDGE LOAN
        </Typography> */}

        <form data-testid="adminYcverifyForm" className={classes.formAccord}>
          <Accordion
            subheading={'Verify YC Status'}
            expanded={expandYcStep}
            // handleChange={(e: string) => handleChange(e)}
            onClickSubHeading={() => setExpandYcStep((prev) => !prev)}
            onClickIcon={() => setExpandYcStep((prev) => !prev)}
            handleChange={() => null}
            index={'YC_STATUS'}
            buttons={
              <Box className={classes.summaryAccordRight}>
                {isRoleAdmin && (
                  <Button
                    name={
                      get(bridgeLoanProcess, 'isYcStatusVerified')
                        ? 'Validated'
                        : 'Validate'
                    }
                    className={classes.accordSendBtn}
                    disabled={get(bridgeLoanProcess, 'isYcStatusVerified')}
                    onClick={handleValidateYcStatus}
                    isLoading={isBridgeLoanLoading || updatingYcStatus}
                  />
                )}
                {/* ycFormHandleSubmit(() => handleYcSubmit()) */}
                <Button
                  name={handleGetName('YC_STATUS')}
                  isLoading={ycVerfLoading}
                  onClick={
                    isEditYc
                      ? ycFormHandleSubmit(() => handleYcSubmit())
                      : () => {
                          setEditYc(true);
                          setExpandYcStep(true);
                        }
                  }
                  className={classes.editYCBtn}
                />
                <MoreHorizIcon
                  className={classes.moreIcon}
                  onClick={() => setExpandYcStep((prev) => !prev)}
                />
              </Box>
            }
          >
            {!isLoadingYcDocs ? (
              <YCStatus
                isEditYc={isEditYc}
                isVd={false}
                documentObject={documentObject}
                setDocumentObject={setDocumentObject}
                control={ycControl}
                enquiryView={enquiryView}
                fileExtensions={['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf']}
                setValue={ycSetvalue}
              />
            ) : (
              <CircularProgress color="inherit" size={40} />
            )}
          </Accordion>
        </form>

        <form
          data-testid="adminReviewLoanTermsForm"
          className={classes.formAccord}
        >
          <Accordion
            subheading={`Review Loan Terms - ${
              get(loanTerms, 'loanType') === 'BRIDGE_TO_DEMO_DAY'
                ? 'Bridge to Demo Day'
                : get(loanTerms, 'loanType') === 'BRIDGE_TO_YC_FUND'
                ? 'Bridge to YC'
                : ''
            }`}
            expanded={expanded === 'REVIEW_LOAN_TERMS'}
            handleChange={(e: string) => handleChange(e)}
            index={'REVIEW_LOAN_TERMS'}
            buttons={
              <Box className={classes.summaryAccordRight}>
                {isRoleAdmin && (
                  <Button
                    name={approvedLoanTerm ? 'Approved' : 'Approve'}
                    onClick={handleSubmit(() => setDialogOpen(true))}
                    className={classes.accordSendBtn}
                    isLoading={isBridgeLoanLoading || updatingLoanTerms}
                    disabled={
                      isEmpty(loanTerms) ||
                      isBridgeLoanLoading ||
                      updatingLoanTerms ||
                      get(bridgeLoanProcess, 'documentsSigned')
                    }
                  />
                )}
                <MoreHorizIcon className={classes.moreIcon} />
              </Box>
            }
          >
            <Box className={classes.docuComponent}>
              {!isEmpty(loanTerms) ? (
                <ReviewLoanForm
                  handleSubmit={handleSubmit}
                  control={control}
                  loanTerms={loanTerms}
                  isLoadingLoanTerms={isLoadingLoanTerms}
                  setValue={setValue}
                  setShowMfn={setShowMfn}
                  showMfn={showMfn}
                  ycBatch={get(enquiryView, 'startupDetails.ycBatch')}
                  disabled={get(bridgeLoanProcess, 'documentsSigned')}
                />
              ) : (
                <Typography
                  className={classes.bridgeLoadText}
                  gutterBottom
                  variant="h3"
                >
                  No Loan Terms Found
                </Typography>
              )}
              <Typography
                variant="body1"
                gutterBottom
                className={classes.errorText}
              >
                {loanTermErrorMessage}
              </Typography>
            </Box>
          </Accordion>
        </form>

        <Accordion
          subheading={'Review Documents'}
          expanded={expanded === 'REVIEW_DOCUMENTS'}
          handleChange={(e: string) => handleChange(e)}
          index={'REVIEW_DOCUMENTS'}
          style={{ paddingTop: 20 }}
          buttons={
            <Box className={classes.summaryAccordRight}>
              {!isEmpty(documents) && (
                <Typography className={classes.approvedDocs}>
                  {`Approved ${get(count, 'verified')} /${get(count, 'total')}`}
                </Typography>
              )}

              {!isEmpty(documents) && (
                <Button
                  name={handleGetName('REVIEW_DOCUMENTS')}
                  className={classes.statusBtn}
                />
              )}
              {isRoleAdmin && (
                <Button
                  name={
                    get(bridgeLoanProcess, 'documentsVerified')
                      ? 'Validated'
                      : 'Validate'
                  }
                  className={classes.accordSendBtn}
                  onClick={() => validateDocuments()}
                  isLoading={isBridgeLoanLoading}
                  disabled={
                    !allDocumentsApproved ||
                    get(bridgeLoanProcess, 'documentsVerified')
                  }
                />
              )}
              <MoreHorizIcon className={classes.moreIcon} />
            </Box>
          }
        >
          <Box className={classes.docuComponent}>
            {isLoading && isEmpty(documents) ? (
              <Box className={classes.loader}>
                <CircularProgress color="inherit" size={40} />
              </Box>
            ) : !isEmpty(documents) ? (
              <ReviewDocuments
                startupId={startupId}
                fundType={fundType}
                isRoleAdmin={isRoleAdmin}
              />
            ) : (
              <Typography>No documents available for review</Typography>
            )}
          </Box>
        </Accordion>

        <Accordion
          subheading={'Approve Loan'}
          expanded={expanded === 'APPROVE_LOAN'}
          handleChange={(e: string) => handleChange(e)}
          index={'APPROVE_LOAN'}
          buttons={
            <Box className={classes.summaryAccordRight}>
              {isRoleAdmin && get(bridgeLoanProcess, 'isLoanApproved') && (
                <Button
                  name="View"
                  onClick={() => {
                    setApproveDialog(true);
                  }}
                  className={classes.accordViewBtn}
                />
              )}
              {/* <Button
                name={handleGetName('APPROVE_LOAN')}
                className={classes.statusBtn}
              />
              {isRoleAdmin ? (
                <Button
                  name={
                    get(bridgeLoanProcess, 'isLoanApproved') ? 'View' : 'Send'
                  }
                  onClick={() => {
                    setOpen(true);
                    setDialogFormTitle('Approve Loan');
                  }}
                  className={classes.accordSendBtn}
                  disabled={!get(bridgeLoanProcess, 'documentsVerified')}
                />
              ) : (
                <Button
                  name={'View'}
                  onClick={() => {
                    setOpen(true);
                    setDialogFormTitle('Approve Loan');
                  }}
                  className={classes.accordSendBtn}
                  disabled={
                    !isEmpty(bridgeLoanProcess) &&
                    !bridgeLoanProcess.documentsVerified
                  }
                />
              )} */}
              <WrappedSelect
                value={
                  get(bridgeLoanProcess, 'isLoanApproved')
                    ? 'APPROVED'
                    : ['REJECTED', 'ONHOLD'].includes(get(loan, 'loanStatus'))
                    ? get(loan, 'loanStatus')
                    : 'MANAGE'
                }
                onChange={handleApproveLoan}
                disabled={
                  !get(bridgeLoanProcess, 'documentsSigned') ||
                  get(bridgeLoanProcess, 'isLoanApproved') ||
                  isEmpty(documents) ||
                  neitherRejectedNorApproved()
                }
                className={
                  isEmpty(documents) || neitherRejectedNorApproved()
                    ? classes.wrappedDisableSelectVal
                    : classes.wrappedSelectVal
                }
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                dropdowns={getLoanDropdowns()}
              />
              {/* <Button
                name={'Approve'}
                className={classes.accordSendBtn}
                variant="contained"
                onClick={() => {
                  setApproveDialog(true);
                }}
              /> */}
            </Box>
          }
        >
          <Box className={classes.approveAlertBox}>
            <Box className={classes.alertBox}>
              {(loanLogs || []).map((log) => (
                <Alert key={log.id} icon={false} className={classes.infoAlert}>
                  <Typography className={classes.alertDate}>
                    {moment(log.createdAt).format('DD, MMM YYYY')}
                  </Typography>
                  <Typography className={classes.alertStatusTxt}>
                    Loan is {log.status} {log.reason ? `, ${log.reason}` : ''}
                  </Typography>
                </Alert>
              ))}
            </Box>
            <Box>
              <Typography className={classes.approveSubHead}>
                {handleGetApproveLoanSubHeading()}
              </Typography>
            </Box>
          </Box>
        </Accordion>

        <Accordion
          subheading={'Process Loan'}
          expanded={expanded === 'PROCESS_LOAN'}
          handleChange={(e: string) => handleChange(e)}
          index={'PROCESS_LOAN'}
          buttons={
            <Box className={classes.summaryAccordRight}>
              <Button
                name={handleGetName('PROCESS_LOAN')}
                className={classes.processLoanstatusBtn}
              />
              {isRoleAdmin ? (
                <Button
                  name={
                    get(bridgeLoanProcess, 'isLoanProcessed') ? 'View' : 'Send'
                  }
                  onClick={() => {
                    setOpen(true);
                    setDialogFormTitle('Process Loan');
                  }}
                  className={classes.accordViewBtn}
                  disabled={!get(bridgeLoanProcess, 'isLoanApproved')}
                />
              ) : (
                <Button
                  name={'View'}
                  onClick={() => {
                    setOpen(true);
                    setDialogFormTitle('Process Loan');
                  }}
                  className={classes.accordSendBtn}
                  disabled={
                    !isEmpty(bridgeLoanProcess) &&
                    !bridgeLoanProcess.isLoanApproved
                  }
                />
              )}
              <MoreHorizIcon className={classes.moreIcon} />
            </Box>
          }
        >
          <Typography>{handleGetProcessLoanSubHeading()}</Typography>
        </Accordion>
        {env !== 'prod' && (
          <Accordion
            subheading={'Loan Closer'}
            disabled={!get(bridgeLoanProcess, 'isLoanProcessed')}
            expanded={expanded === 'LOAN_CLOSER'}
            handleChange={(e: string) => handleChange(e)}
            index={'LOAN_CLOSER'}
            buttons={
              <Box className={classes.summaryAccordRight}>
                <WrappedSelect
                  value={get(loanCloserInfo, 'status') || 'OPEN'}
                  onChange={handleLoanCloserOpen}
                  disabled={['CONVERTED', 'EXTENDED', 'CLOSED'].includes(
                    get(loanCloserInfo, 'status') ||
                      !get(bridgeLoanProcess, 'isLoanProcessed'),
                  )}
                  className={classes.wrappedSelectVal}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  dropdowns={getLoanCloserStatus(get(loanCloserInfo, 'status'))}
                />
              </Box>
            }
          >
            <Box className={classes.approveAlertBox}>
              <Box className={classes.alertBox}>
                {!isEmpty(loanClosureLogs) &&
                  loanClosureLogs.map(({ id, comment }: any) => (
                    <Alert key={id} icon={false} className={classes.infoAlert}>
                      <Typography className={classes.alertStatusTxt}>
                        {comment}
                      </Typography>
                    </Alert>
                  ))}
              </Box>
            </Box>
          </Accordion>
        )}
      </Box>

      <Dialog
        open={isOpen}
        maxWidth={'md'}
        className={classes.approveModel}
        subheading={''}
        title={dialogFormTitle}
        handleClose={() => {
          dispatch(emptySuccessMessage());
          setOpen(false);
        }}
      >
        {dialogFormTitle === 'Approve Loan' ? (
          <ApproveLoan
            handleBack={() => {
              setOpen(false);
            }}
            loanTerms={loanTerms || {}}
            startupId={startupId}
            isRoleAdmin={isRoleAdmin}
          />
        ) : (
          <ProcessLoan
            handleBack={() => setOpen(false)}
            startupId={startupId}
            isRoleAdmin={isRoleAdmin}
          />
        )}
      </Dialog>

      <Dialog
        open={approveDialog}
        className={classes.approveModel}
        subheading={''}
        title={'Approve Loan'}
        handleClose={() => {
          dispatch(emptySuccessMessage());
          setApproveDialog(false);
        }}
      >
        <ApproveLoan
          loanTerms={loanTerms || {}}
          handleBack={() => {
            setApproveDialog(false);
          }}
          startupId={startupId}
          isRoleAdmin={isRoleAdmin}
        />
      </Dialog>

      <Dialog
        open={manageLoan}
        className={classes.rejectOnHoldModel}
        title={managelLoanState === 'ONHOLD' ? 'On Hold Loan' : 'Reject Loan'}
        handleClose={() => {
          setManageLoanReason('');
          setManageLoan(false);
          setHoldRejectError(false);
        }}
      >
        <Box className={classes.dailogContentBox}>
          {errorMessage && <Typography>{errorMessage}</Typography>}{' '}
          <WrappedTextInput
            type="text"
            value={manageLoanReason}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            maxRows={6}
            minRows={2}
            multiline
            placeholder="Enter Reason"
            className={classes.approveTextValInput}
            InputProps={{ classes: { root: classes.inputRoot } }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused,
              },
            }}
            onChange={(e: any) => onChangeReason(e.target.value)}
            error={holdRejectError ? true : false}
          />
          {holdRejectError && (
            <Typography className={classes.errorLoanText}>
              Please enter reason
            </Typography>
          )}
          {managelLoanState === 'ONHOLD' &&
            (rejectedDocsLogs || []).map((item) => (
              <Box key={item.id} className={classes.onHoldList}>
                <img src={IconImg} className={classes.imgIcon} />
                <Typography className={classes.onHoldTxt}>
                  {get(item, 'comment')}{' '}
                  {get(item, 'itemName') && <b>({get(item, 'itemName')})</b>}
                </Typography>
              </Box>
            ))}
        </Box>
        <DialogActions>
          <Button
            name={managelLoanState === 'ONHOLD' ? 'On Hold' : 'Reject'}
            className={classes.accordSendBtn}
            onClick={() => setManageLoanConfirmDialog(true)}
            isLoading={managingLoan}
            disabled={managingLoan}
          />

          <Button
            name="Cancel"
            className={classes.accordCancelBtn}
            onClick={() => setManageLoan(false)}
            isLoading={managingLoan}
            disabled={managingLoan}
          />
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
        dialogHeading="Approve Loan Terms"
        dialogContent="Are you sure you wish to approve new loan terms changes?"
        isLoading={isLoading}
        onConfirm={() => onApprove()}
        buttonText={{ yes: 'Approve', no: 'No' }}
      />

      <ConfirmDialog
        dialogOpen={manageLoanConfirmDialog}
        onClose={() => setManageLoanConfirmDialog(false)}
        onCancel={() => setManageLoanConfirmDialog(false)}
        dialogHeading={`${
          managelLoanState === 'ONHOLD' ? 'On Hold' : 'Reject'
        }  Loan`}
        dialogContent={`Are you sure you want to ${
          managelLoanState === 'ONHOLD'
            ? ' put the loan On Hold'
            : ' Reject the loan'
        } ?`}
        isLoading={managingLoan}
        onConfirm={() => onManageLoan()}
        buttonText={{ yes: 'Yes', no: 'No' }}
      />

      <AlertDialog
        dialogOpen={approveLoanNotify}
        onClose={() => setApproveLoanNotify(false)}
        dialogHeading={'Approve Loan'}
        dialogContent={'Document not signed yet or documents not approved'}
      />

      <Dialog
        open={loanCloserDialogOpen && loanCloserType !== 'OPEN'}
        handleClose={handleLoanCloserClose}
      >
        <LoanCloser
          handleClose={handleLoanCloserClose}
          type={loanCloserType}
          startupId={startupId}
        />{' '}
      </Dialog>
    </>
  );
};

export default BridgeLoanProcess;
