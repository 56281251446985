import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import EnquiryList from './EnquiryList';

export const EnquiryListRoute: IRoute = {
  path: '/admin/enquiry-list',
  component: EnquiryList,
  layout: DashboardLayout,
  exact: true,
};
