import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dashactionSignNowBox: {
    marginBottom: 20,
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 18,
  },
  dashSignNowText: {
    color: theme.palette.grey[900],
    fontSize: 14,
    marginBottom: 12,
  },
  signNowBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    width: 90,
    height: 30,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  dashactionUploadBox: {
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 18,
    marginBottom: 20,
  },
  dashUploadText: {
    color: theme.palette.grey[900],
    fontSize: 14,
    marginBottom: 12,
  },
  dashUploadListText: {
    fontSize: 12,
    color: theme.palette.grey[600],
    letterSpacing: 0.35,
    marginBottom: 5,
  },
  UploadBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    width: 90,
    height: 30,
    marginTop: 10,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  dashActProfileBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  dashActProfileLeft: {
    display: 'flex',
  },
  dashActLoanProfileLeft: {
    display: 'flex',
    alignItems: 'center',
  },

  dashActProfileRight: {
    alignSelf: 'center',
    paddingLeft: 15,
    width: 65,
    textAlign: 'right',
  },
  loanCloserAction: {
    alignSelf: 'center',
    paddingLeft: 15,
    width: 150,
    textAlign: 'right',
  },
  dashActProfiledetails: {
    paddingLeft: 14,
  },
  profileUNameText: {
    fontSize: 16,
    color: theme.palette.grey[400],
    fontWeight: 500,
    marginBottom: 5,
  },
  profileNameText: {
    fontSize: 18,
    color: theme.palette.grey[100],
    fontWeight: 500,
    marginBottom: 2,
  },
  profileSubText: {
    fontSize: 10,
    background: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 5px',
    borderRadius: 4,
    marginLeft: 15,
  },
  profileSubVcText: {
    fontSize: 10,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 5px',
    borderRadius: 4,
    marginLeft: 15,
  },
  profileSubVcAngelText: {
    fontSize: 10,
    background: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 5px',
    borderRadius: 4,
    marginLeft: 15,
  },
  profileSubVcYardstickText: {
    fontSize: 10,
    background: theme.shadows[5],
    color: theme.palette.primary.contrastText,
    padding: '2px 5px',
    borderRadius: 4,
    marginLeft: 15,
  },
  profileSubTextOrange: {
    fontSize: 10,
    background: '#f26529',
    color: theme.palette.primary.contrastText,
    padding: '2px 5px',
    borderRadius: 4,
    marginLeft: 15,
  },
  profileLocationText: {
    fontSize: 13,
    color: theme.palette.grey[600],
  },
  profileEmailText: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.info.light,
  },
  openText: {
    color: theme.palette.primary.main,
    fontSize: 13,
    position: 'relative',
    top: -2,
    marginRight: 3,
    cursor: 'pointer',
  },
  pointer: {
    cursor: 'pointer',
  },
  pendingBox: {
    // paddingTop: 38,
    maxWidth: 750,
    // paddingRight: 20,
  },
  completedBox: {
    // paddingTop: 38,
    maxWidth: 750,
    paddingRight: 20,
  },
  signedBox: {
    display: 'flex',
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    padding: 20,
    marginBottom: 25,
  },
  circleIcon: {
    fill: theme.palette.primary.main,
    fontSize: 30,
  },
  signedRight: {
    paddingLeft: 20,
  },
  signedText: {
    fontSize: 14,
    color: theme.palette.grey[900],
    marginBottom: 5,
  },
  signedSub: {
    fontSize: 13,
    color: theme.palette.info.light,
  },
  viewBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 4,
    width: 85,
    height: 30,
    marginTop: 10,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  vcFirmDetailscontainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dateText: {
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.grey[50],
  },
  avatarIcon: {
    backgroundColor: '#D9D9D9',
    marginTop: 5,
  },
}));
