import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { get } from 'lodash';

import history from 'common/utils/history';
import { RootState } from 'redux-modules/store/rootState';
import {
  Box,
  Loader,
  Button,
  StyledTableCell,
  StyledTableRow,
} from 'components';
import { Ornament } from 'assets';
import { handleGetAllDeal } from 'redux-modules/deals/Actions';
import { formatAmount } from 'common/utils/helpers';
import { DEAL_TYPE_MAPPER, STATIC_WEBSITE } from 'common/utils/constants';
import { createOrUpdateDeal } from 'services';

import styles from './styles';

const DealList = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const env = process.env.REACT_APP_ENV || 'dev';

  const { isLoading, dealList } = useSelector(({ Deals }: RootState) => Deals);

  const closeDeal = async (dealId: string, closedStatus: boolean) => {
    await createOrUpdateDeal({
      id: dealId,
      closed: closedStatus,
    });
    dispatch(handleGetAllDeal());
  };

  React.useEffect(() => {
    dispatch(handleGetAllDeal());
  }, [handleGetAllDeal]);

  return (
    <Box className={classes.enquiryListBox}>
      <Box className={classes.enquiryListhead}>
        <Box className={classes.enquiryListLeft}>
          <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
          <Typography
            variant="h3"
            gutterBottom
            className={classes.enquiryHeadText}
          >
            All Deals
          </Typography>
          {dealList.length > 1 && (
            <Typography
              variant="body1"
              gutterBottom
              className={classes.enquirySubHeadText}
            >
              {dealList.length} Deals
            </Typography>
          )}
        </Box>
        <Box className={classes.enquiryListRight}>
          <Button
            name="Public Deal"
            className={classes.publicDealButton}
            onClick={() =>
              window.open(`${STATIC_WEBSITE[env]}public-deals/`, '_blank')
            }
          />
          <Button
            name="New Deal"
            className={classes.addButton}
            onClick={() => history.push(`/admin/new-deal/NEW`)}
          />
        </Box>
      </Box>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <StyledTableCell>S.No.</StyledTableCell>
              <StyledTableCell>Deal</StyledTableCell>
              <StyledTableCell>Deal Type</StyledTableCell>
              <StyledTableCell>Deal Size</StyledTableCell>
              <StyledTableCell>% Funded</StyledTableCell>
              <StyledTableCell>Start Date</StyledTableCell>
              <StyledTableCell>Close Date</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <Box className={classes.loaderSection}>
                <Loader className={classes.loaderBox} />
              </Box>
            ) : (
              dealList.map((row: any) => (
                <StyledTableRow hover key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.order}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.deal}
                  </StyledTableCell>
                  <StyledTableCell>
                    {DEAL_TYPE_MAPPER[`${get(row, 'dealType')}`]}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatAmount(row.dealSize)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row?.completedPercentage || 0} %
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(row.startDate).format('DD/MM/YYYY')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(row.estimatedCloseDate).format('DD/MM/YYYY')}
                  </StyledTableCell>
                  {row.closed ? (
                    // <StyledTableCell>Closed</StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.tdBtn}
                        onClick={() => closeDeal(row.id, false)}
                      >
                        Reopen
                      </Typography>
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.tdBtn}
                        onClick={() => closeDeal(row.id, true)}
                      >
                        Close Deal
                      </Typography>
                    </StyledTableCell>
                  )}

                  <StyledTableCell>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.tdBtn}
                      onClick={() => history.push(`/admin/new-deal/${row.id}`)}
                    >
                      View
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DealList;
