import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const processLoanSchema = yup.object().shape({
  processedDate: yup.string().required(requiredConstant),
  transactionType: yup.string().required(requiredConstant),
  transactionId: yup.string().nullable().required(requiredConstant),
  finalProcessedLoanAmount: yup.string().required(requiredConstant),
});
