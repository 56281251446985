import React, { useEffect, useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { Box, Tabs, TabPanel } from 'components';
import { Ornament } from 'assets';
import InvestmentNavAttr from 'components/InvestmentNavAttr';
import InvestmentUpdate from 'components/InvestmentUpdate';
import { handleGetAllDeal } from 'redux-modules/deals/Actions';
import { RootState } from 'redux-modules/store/rootState';

import styles from './styles';

const InvestmentSettings = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { dealList } = useSelector(({ Deals }: RootState) => Deals);

  const [value, setValue] = useState<number>(0);

  const tabs = useMemo(() => {
    const tabsList = [
      { name: 'Nav Attributes', key: 'NAV_ATTRIBUTES' },
      { name: 'Investment Updates', key: 'INVESTMENT_UPDATES' },
    ];

    return tabsList;
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (isEmpty(dealList)) dispatch(handleGetAllDeal());
  }, []);

  return (
    <Box className={classes.settingsContainer}>
      <Box className={classes.settingsContent}>
        <Box className={classes.settingsHead}>
          <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
          <Typography variant="h6" className={classes.headText}>
            Investment Settings
          </Typography>
        </Box>
        <Box className={classes.settingsContentText}>
          <Tabs
            tabs={tabs}
            value={value}
            handleChange={handleChange}
            className={classes.enquiryTabs}
          />
          <TabPanel
            value={value}
            index={0}
            className={classes.settingsTabPanel}
          >
            <Box className={classes.settingsBodyPaddingTop} />
            <InvestmentNavAttr />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className={classes.settingsTabPanel}
          >
            <Box className={classes.settingsBodyPaddingTop} />
            <InvestmentUpdate />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default InvestmentSettings;
