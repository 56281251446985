import React, { ReactElement, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import history from 'common/utils/history';
import { loginComplete } from 'redux-modules/auth/Actions';
import { getStartupInfo } from 'redux-modules/enquiries/Actions';

// TODO find the rooth cause of rerendering
let initializedData = false;

type Props = {
  children: ReactElement;
};

const AuthWrapper = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const pathName = useLocation().pathname;
  const search = useLocation().search;

  useEffect(() => {
    if (!initializedData) {
      initializedData = true;
      Auth.currentAuthenticatedUser()
        .then((user) => {
          const userObj = {
            ...user.attributes,
            role: user.attributes['custom:role'],
            startUpId: user.attributes['custom:startup_id'],
            accelerator: user.attributes['custom:accelerator'],
            companyName: user.attributes['custom:company_name'],
          };
          dispatch(loginComplete(userObj));
          dispatch(getStartupInfo());
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          history.push({
            pathname: pathName,
            search: search,
          });
        });
    }
  }, []);
  return isLoading ? <div className="loader" /> : children;
};

export default AuthWrapper;
