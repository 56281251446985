import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { unemojify, emojify } from 'node-emoji';
import { get } from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { FN } from 'common/types';

import styles from './styles';

//https://reactrocket.com/post/draft-js-persisting-content/
//https://jpuri.github.io/react-draft-wysiwyg/#/docs

type Props = {
  value: any;
  purpose: string;
  onChange: FN;
  getSignedUrl?: any;
  requestObject?: any;
  setDefaultEditorStateToNull?: boolean;
};

const RichTextEditor = ({
  value,
  purpose,
  onChange,
  getSignedUrl,
  requestObject,
  setDefaultEditorStateToNull,
}: Props) => {
  const classes = styles();

  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty(),
  );
  const [updated, setUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (!updated && purpose === 'edit') {
      const defaultValue = value ? value : '';
      const contentBlock = htmlToDraft(emojify(defaultValue));
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
          contentBlock.entityMap,
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [value, purpose]);

  useEffect(() => {
    if (setDefaultEditorStateToNull) {
      setEditorState(EditorState.createEmpty());
    }
  }, [setDefaultEditorStateToNull]);

  const onEditorStateChange = (editorState: any) => {
    setUpdated(true);
    setEditorState(editorState);
    return onChange(
      unemojify(draftToHtml(convertToRaw(editorState.getCurrentContent()))),
    );
  };

  const uploadImageCallBack = async (file: any) => {
    try {
      const url = await getSignedUrl({
        fileName: file.name,
        ...requestObject,
      });
      // eslint-disable-next-line no-undef
      await fetch(get(url, 'uploadUrl'), {
        method: 'PUT',
        body: file,
      });
      return Promise.resolve({ data: { link: get(url, 'accessUrl') } });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('ERROR WHILE GET SIGNED URL FOR IMAGE UPLOAD', err);
    }
    return Promise.reject();
  };

  return (
    <Editor
      editorState={editorState}
      editorClassName={classes.editorClassName}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        image: {
          uploadEnabled: true,
          // uploadCallback: uploadImageCallBack,
          alt: { present: true, mandatory: true },
          defaultSize: {
            height: '200',
            width: '200',
          },
          previewImage: true,
        },
      }}
      uploadCallback={uploadImageCallBack}
    />
  );
};

export default RichTextEditor;
