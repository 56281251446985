import React, { useState } from 'react';
import { Grid, Typography, InputAdornment, Checkbox } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty, compact } from 'lodash';

import {
  Box,
  Button,
  Dialog,
  WrappedTextInput,
  WrappedSelect,
  WrappedPriceInput,
} from 'components';
import {
  SubscriptionUsersOptions,
  // SubscriptionFreeTrialPeriod,
} from 'common/utils/optionList';
import {
  createProduct,
  updateInvestorSubscription,
  updatePlanForNonSubscribed,
} from 'services';
import { errorMessageHandler } from 'common/utils/helpers';

import styles from './styles';
import { CreateNewPlanSchema } from './validation';

type Props = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  getSubscriptionPlansList?: any;
  type: 'CREATE' | 'UPDATE' | 'ASSIGN';
  subscriptionPlanAllData?: any;
  currentInvestorData?: any;
  getAllSubscribedInvestorUsers?: any;
  getUnsubscibedUsersList?: any;
};

const CreateNewPlan = ({
  setIsOpen,
  isOpen,
  getSubscriptionPlansList,
  type,
  subscriptionPlanAllData,
  currentInvestorData,
  getAllSubscribedInvestorUsers,
  getUnsubscibedUsersList,
}: Props) => {
  const classes = styles();

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CreateNewPlanSchema),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredPlanList, setFilteredPlanList] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [planList, setPlanList] = React.useState<any>([]);

  const onSubmit = () => {
    const data = { ...getValues() };
    console.log(data); //eslint-disable-line
    setIsLoading(true);
    const obj = {
      price: {
        unitAmount: get(data, 'amount'),
      },
      trialPeriod: get(data, 'trialPeriod'),
      planType: get(data, 'planType'),
      name: get(data, 'name'),
      description: `${get(data, 'planType')} description`,
      isDefault: get(data, 'isDefault'),
      addon: get(data, 'addon'),
      freeUnit: get(data, 'freeUnit'),
    };
    createProduct(obj)
      .then(() => {
        setIsOpen(false);
        setIsLoading(false);
        getSubscriptionPlansList();
        reset();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const onUpdate = () => {
    const data = { ...getValues() };
    setIsLoading(true);
    const obj = {
      investorId: get(currentInvestorData, 'investorId'),
      planType: get(currentInvestorData, 'subscriptionPlan.planType'),
      subscriptionPlanId: get(data, 'name'),
      subscriptionId: get(currentInvestorData, 'id'),
    };
    updateInvestorSubscription(obj)
      .then(() => {
        setIsOpen(false);
        handleClose();
        setIsLoading(false);
        getAllSubscribedInvestorUsers();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const onAssign = () => {
    const data = { ...getValues() };
    setIsLoading(true);
    const obj = {
      investorId: get(currentInvestorData, 'investorId'),
      firmName: get(currentInvestorData, 'name'),
      planType: get(data, 'planType'),
      subscriptionPlanId: get(data, 'name'),
      // backdate_start_date: 1690533481,
      ycPLan: true,
    };
    updatePlanForNonSubscribed(obj)
      .then(() => {
        setIsOpen(false);
        handleClose();
        setIsLoading(false);
        getUnsubscibedUsersList();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    reset();
    setIsOpen(false);
    setErrorMessage('');
    setFilteredPlanList([]);
  };

  React.useEffect(() => {
    if (type !== 'CREATE') {
      const allPlanList = compact(
        subscriptionPlanAllData.map((item: any) => {
          if (!isEmpty(get(item, 'planType'))) {
            const properties = {
              text: get(item, 'name'),
              value: get(item, 'id'),
              planType: get(item, 'planType'),
              subscriptionFreeTrialDays: get(item, 'subscriptionFreeTrialDays'),
              amount: get(
                (get(item, 'prices') || []).find((price: any) => !price.addon),
                'amount',
              ),
              disabled:
                get(item, 'planType') === 'YARDSTICK' &&
                get(item, 'subscriptionFreeTrialDays') <
                  get(
                    currentInvestorData,
                    'subscriptionPlan.subscriptionFreeTrialDays',
                  ),
            };
            return properties;
          }
        }),
      );
      if (type === 'UPDATE') {
        const filteredData = allPlanList.filter(
          (item: any) =>
            item.planType ===
            get(currentInvestorData, 'subscriptionPlan.planType'),
        );
        setFilteredPlanList(filteredData);
      } else if (type === 'ASSIGN') {
        const filterData = allPlanList.filter(
          (item: any) => item.planType === 'VC_FIRM',
        );
        setPlanList(filterData);
        setFilteredPlanList(allPlanList);
      }
    }
  }, [currentInvestorData]);

  React.useEffect(() => {
    if (type === 'UPDATE') {
      if (!isEmpty(filteredPlanList) && isEmpty(watch('name'))) {
        const currentPlanSelectedData = filteredPlanList.find(
          (item: any) =>
            item.value === get(currentInvestorData, 'subscriptionPlan.id'),
        );
        setValue('planType', get(currentPlanSelectedData, 'planType'));
        setValue(
          'trialPeriod',
          get(currentPlanSelectedData, 'subscriptionFreeTrialDays'),
        );
        setValue('amount', get(currentPlanSelectedData, 'amount'));
        setValue('name', get(currentPlanSelectedData, 'value'));
      } else if (!isEmpty(filteredPlanList) && !isEmpty(watch('name'))) {
        const currentPlanSelectedData = filteredPlanList.find(
          (item: any) => item.value === watch('name'),
        );
        setValue('planType', get(currentPlanSelectedData, 'planType'));
        setValue(
          'trialPeriod',
          get(currentPlanSelectedData, 'subscriptionFreeTrialDays'),
        );
        setValue('amount', get(currentPlanSelectedData, 'amount'));
        setValue('name', get(currentPlanSelectedData, 'value'));
      }
    } else if (type === 'ASSIGN') {
      if (!isEmpty(planList) && !isEmpty(watch('name'))) {
        const currentPlanSelectedData = planList.find(
          (item: any) => item.value === watch('name'),
        );
        setValue(
          'trialPeriod',
          get(currentPlanSelectedData, 'subscriptionFreeTrialDays'),
        );
        setValue('amount', get(currentPlanSelectedData, 'amount'));
      } else if (!isEmpty(planList) && isEmpty(watch('name'))) {
        setValue('name', get(planList, '[0].value', ''));
      }
    }
  }, [filteredPlanList, watch('name')]);

  React.useEffect(() => {
    if (!isEmpty(watch('trialPeriod'))) clearErrors('trialPeriod');
    if (watch('planType') === 'YARDSTICK') {
      setValue('trialPeriod', '');
    }
    if (type === 'ASSIGN' && !isEmpty(filteredPlanList)) {
      const filterData = filteredPlanList.filter(
        (item: any) => item.planType === watch('planType'),
      );
      setValue('name', get(filterData, '[0].value', ''));
      setPlanList(filterData);
    }
  }, [watch('planType')]);

  return (
    <Dialog
      open={isOpen}
      maxWidth={'sm'}
      title={
        type === 'UPDATE'
          ? `${get(currentInvestorData, 'subscriptionPlan.name')}`
          : type === 'ASSIGN'
          ? 'Assign New Plan'
          : 'Create New Plan'
      }
      handleClose={() => handleClose()}
    >
      <form
        onSubmit={handleSubmit(
          type === 'CREATE'
            ? onSubmit
            : type === 'UPDATE'
            ? onUpdate
            : onAssign,
        )}
        data-testid="create-new-plan"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className={classes.inputHead}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.labelText}
            >
              Type of User<span className={classes.errorRequiredText}>*</span>
            </Typography>
            <Controller
              name="planType"
              control={control}
              defaultValue={
                get(currentInvestorData, 'subscriptionPlan.planType') ||
                'VC_FIRM'
              }
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedSelect
                    value={value}
                    onChange={onChange}
                    label=""
                    className={classes.textValInputSelectField}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    dropdowns={[
                      SubscriptionUsersOptions[0],
                      SubscriptionUsersOptions[1],
                    ]}
                    error={!!error?.message}
                    disabled={type === 'UPDATE'}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.inputHead}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.labelText}
            >
              Plan Title<span className={classes.errorRequiredText}>*</span>
            </Typography>
            {type === 'CREATE' ? (
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="text"
                      placeholder="Plan Name"
                      className={classes.textValInput}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(e.target.value.trimStart())
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            ) : (
              <Controller
                name="name"
                control={control}
                defaultValue={get(currentInvestorData, 'subscriptionPlan.id')}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedSelect
                      value={value}
                      onChange={onChange}
                      label=""
                      className={classes.textValInputSelectField}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      dropdowns={
                        type === 'ASSIGN' ? planList : filteredPlanList
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} className={classes.inputHead}>
            <Box>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelText}
              >
                Free Trial Period
                <span className={classes.errorRequiredText}>*</span>
              </Typography>
              {watch('planType') === 'YARDSTICK' ? (
                <Controller
                  name="trialPeriod"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedPriceInput
                        label=""
                        className={classes.textValInput}
                        textAlign="left"
                        placeholder={
                          watch('planType') !== 'YARDSTICK'
                            ? 'Enter Free Trial'
                            : 'Enter Free Output'
                        }
                        InputProps={{
                          classes: { root: classes.inputRoot },
                          endAdornment: (
                            <InputAdornment position="end">
                              {watch('planType') !== 'YARDSTICK'
                                ? 'Days'
                                : 'Outputs'}
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        disabled={type === 'UPDATE' || type === 'ASSIGN'}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              ) : (
                <Controller
                  name="trialPeriod"
                  control={control}
                  defaultValue={'30'}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedPriceInput
                        label=""
                        className={classes.textValInput}
                        textAlign="left"
                        placeholder={'Enter Free Trial'}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                          endAdornment: (
                            <InputAdornment position="end">Days</InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        disabled={type === 'UPDATE' || type === 'ASSIGN'}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={classes.inputHead}>
            <Typography className={classes.labelText} variant="body1">
              {watch('planType') !== 'YARDSTICK'
                ? `Amount/Month`
                : `Amount/Output`}
              <span className={classes.errorRequiredText}>*</span>
            </Typography>
            <Controller
              name="amount"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedPriceInput
                    label=""
                    className={classes.textValInput}
                    currencySymbol={'$'}
                    placeholder={'Enter Amount'}
                    textAlign="left"
                    decimalPlaces={2}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(_: any, val: any) => onChange(val)}
                    disabled={type === 'UPDATE' || type === 'ASSIGN'}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} className={classes.inputHead}>
            <Typography className={classes.labelText} variant="body1">
              {watch('planType') !== 'YARDSTICK'
                ? `Amount/Usage`
                : `Amount/Output`}
              <span className={classes.errorRequiredText}>*</span>
            </Typography>
            <Controller
              name="addon"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedPriceInput
                    label=""
                    className={classes.textValInput}
                    currencySymbol={'$'}
                    placeholder={'Enter Addon Amount'}
                    textAlign="left"
                    decimalPlaces={2}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(_: any, val: any) => onChange(val)}
                    disabled={type === 'UPDATE' || type === 'ASSIGN'}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} className={classes.inputHead}>
            <Typography className={classes.labelText} variant="body1">
              Free Unit
              <span className={classes.errorRequiredText}>*</span>
            </Typography>
            <Controller
              name="freeUnit"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    label=""
                    className={classes.textValInput}
                    placeholder={'Enter Free Unit'}
                    type="number"
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(_: any) => onChange(_.target.value)}
                    disabled={type === 'UPDATE' || type === 'ASSIGN'}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
            />
          </Grid>

          {type === 'CREATE' && (
            <Grid item xs={12} md={6} className={classes.inputHead}>
              <Controller
                name="isDefault"
                control={control}
                defaultValue={false}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box>
                    <Checkbox
                      style={{ paddingLeft: 0 }}
                      checked={value}
                      onChange={(e: any) => {
                        onChange(e.target.checked);
                      }}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                    <Typography className={classes.defaultText}>
                      Set as Default
                    </Typography>
                  </Box>
                )}
                rules={{ required: 'This is required' }}
              />
            </Grid>
          )}
        </Grid>
        {errorMessage && (
          <Typography
            variant="caption"
            className={classes.errorTextCreateNewPlan}
          >
            {errorMessage}
          </Typography>
        )}
        <Box className={classes.nextPrevButtonBox}>
          <Button
            type="submit"
            className={classes.nextButton}
            name="save"
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Button
            variant="outlined"
            className={classes.prevButton}
            name="Cancel"
            disabled={isLoading}
            onClick={() => handleClose()}
          />
        </Box>
      </form>
    </Dialog>
  );
};

export default CreateNewPlan;
