import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  Typography,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';
import { find, get, groupBy, isEmpty, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';

import { Box, WrappedSelect, Button } from 'components';
import { Ornament } from 'assets';
import { RootState } from 'redux-modules/store/rootState';
import {
  businessOpsCountryList,
  industryTypeList,
  KPIsListV2,
  stageList,
} from 'common/utils/optionList';
import { getBatches } from 'redux-modules/auth/Actions';
import {
  createOrUpdateStartupMultipleFundraising,
  createOrUpdateStartupMultipleKpiData,
  deleteStartupBenchmarkFundraisingKpi,
  deleteStartupBenchmarkKpi,
  getBenchmarkingStartups,
  getCompanyDetailsByID,
  getStartupBenchmarkFundraisingKpi,
  getStartupBenchmarkingKpi,
  getStartupBenchmarkKpiGrowth,
  adminGetStartupValuationInsight,
} from 'services';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { PERCENTILE_GROWTH } from 'common/utils/constants';
import history from 'common/utils/history';
import { StyledTooltip } from 'components/Wrappers/Tooltip';

import styles from './styles';
import KPITable from '../KPITable';

const fundRaiseTableCols = [
  {
    title: 'Stage',
    name: 'roundStage',
    inputType: 'text',
    width: 150,
    align: 'left',
  },
  {
    title: 'Closing Month',
    name: 'closingMonth',
    inputType: 'month',
    width: 140,
    align: 'center',
  },
  {
    title: 'Amount Raised',
    name: 'amountRaised',
    inputType: 'number',
    width: 150,
    align: 'center',
  },
  {
    title: 'Type',
    name: 'type',
    inputType: 'list',
    width: 150,
    align: 'center',
  },
  {
    title: 'Uncapped?',
    name: 'uncapped',
    inputType: 'boolean',
    width: 140,
    align: 'center',
  },
  {
    title: 'Valuation',
    name: 'valuation',
    inputType: 'number',
    width: 150,
    align: 'center',
  },
  {
    title: 'Discount (%)',
    name: 'discount',
    inputType: 'number',
    width: 140,
    align: 'center',
  },
  {
    title: 'MFN Clause?',
    name: 'mfnClause',
    inputType: 'boolean',
    width: 140,
    align: 'center',
  },
];

const getAllMonths = (start: any, end: any) => {
  return Array.from({ length: end.diff(start, 'month') + 1 })
    .map((_, index) => {
      return {
        name: `${moment(start).add(index, 'month').format('M_YYYY')}`,
        title: moment(start).add(index, 'month').format('MMM-YYYY'),
        width: 150,
        align: 'center',
      };
    })
    .reverse();
};

const BenchMarkDashboard = ({
  startupId,
  role,
  setOpenBenchmarkDashboard,
  setEditProfile,
}: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { ycBatches, currentYcBatch } = useSelector(
    ({ Auth }: RootState) => Auth,
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [filterLoading, setFilterLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [filterObj, setFilterObj] = useState<any>({
    industryType: 'ALL',
    fundingStage: 'ALL',
    ycBatch: 'ALL',
    businessOpsLocation: 'ALL',
  });
  const [ycBatchesList, setYcBatchesList] = React.useState<any>([]);
  const [businessOpsList, setBusinessOpsList] = React.useState<any>([]);
  const [industryList, setIndustryList] = React.useState<any>([]);
  const [fundingStageList, setFundingStageList] = React.useState<any>([]);
  const [kpiList, setKpiList] = React.useState<any>([]);
  const [startupDetails, setStartupDetails] = React.useState<any>({});
  const [
    benchmarkingStartupsList,
    setBenchmarkingStartupsList,
  ] = React.useState<any>([]);
  const [benchmarkKpiGrowth, setBenchmarkKpiGrowth] = React.useState<any>({});
  const [benchmarkKpiList, setBenchmarkKpiList] = React.useState<any>([]);
  const [
    benchmarkFundraisingKpiList,
    setBenchmarkFundraisingKpiList,
  ] = React.useState<any>([]);
  const [benchmarkKpiRows, setBenchmarkKpiRows] = React.useState<any>([]);
  const [benchmarkCmgrKpiRows, setBenchmarCmgrkKpiRows] = React.useState<any>(
    [],
  );
  const [noKpiAdded, setNoKpiAdded] = React.useState<boolean>(false);
  const [fundRaisingKpiRows, setFundraisingKpiRows] = React.useState<any>([]);
  const [noFundraiseAdded, setNoFundraiseAdded] = React.useState<boolean>(
    false,
  );
  const [progressGrowth, setProgressGrowth] = React.useState<any>([]);
  const [valuationLoading, setValuationLoading] = React.useState<boolean>(
    false,
  );
  const [valuationInsight, setValuationInsight] = useState<any>({});

  const benchmarkColumns = [
    { name: 'kpiName', title: 'KPI', width: 150, align: 'left' },
    { name: 'info', title: '', width: 55, align: 'center' },
    { name: 'prev', title: '', width: 55, align: 'right' },
    { name: 'next', title: '', width: 55, align: 'left' },
    { name: '#', title: 'Actions', width: 100, align: 'left' },
  ];
  benchmarkColumns.splice(
    3,
    0,
    ...getAllMonths(moment('01-2021', 'MM-YYYY'), moment()),
  );

  const fundRaisingColumns = [
    ...fundRaiseTableCols,
    { name: '#', title: 'Actions', width: 100, align: 'left' },
  ];

  const {
    fullName,
    // businessModel,
    industryType,
    ycBatch,
    businessOpsLocation,
    fundingStage,
  } = startupDetails;

  const getStartupBenchmarkKpiAllData = () => {
    setIsLoading(true);
    setErrorMessage('');
    Promise.all([
      getStartupBenchmarkingKpi(startupId, role),
      getStartupBenchmarkFundraisingKpi(startupId, role),
    ])
      .then(([benchmarkData, fundraisingData]: any) => {
        setBenchmarkKpiList(orderBy(benchmarkData, ['isPrimary'], ['desc']));
        setBenchmarkFundraisingKpiList(
          orderBy(
            fundraisingData,
            [(obj: any) => Number(obj.month), (obj: any) => Number(obj.year)],
            ['desc', 'desc'],
          ),
        );
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const getStartupBenchmarkGrowthData = (startupIdList: Array<string>) => {
    setFilterLoading(true);
    setErrorMessage('');
    getStartupBenchmarkKpiGrowth(
      startupId,
      {
        startupIdList: startupIdList,
      },
      role,
    )
      .then((res: any) => {
        setBenchmarkKpiGrowth(res);
        setFilterLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setFilterLoading(false);
      });
  };

  const getValuationData = () => {
    setValuationLoading(true);
    setErrorMessage('');
    adminGetStartupValuationInsight(startupId)
      .then((res: any) => {
        setValuationInsight(res);
        setValuationLoading(false);
      })
      .catch((err: any) => {
        setValuationLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const getStartupData = () => {
    setErrorMessage('');
    getCompanyDetailsByID(startupId)
      .then((res: any) => {
        setStartupDetails(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  const getBenchmarkingStartupsData = () => {
    getBenchmarkingStartups()
      .then((res: any) => {
        setBenchmarkingStartupsList(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.log('ERROR WHILE GETTING BENCHMARK STARTUPS: ', message);
      });
  };

  const createFilterOptionsList = () => {
    if (ycBatches) {
      const updatedYcBatches = ycBatches.filter(
        (batch: any) => batch.value === ycBatch,
      );
      setYcBatchesList(updatedYcBatches);
    }
    if (businessOpsCountryList) {
      const newCountryList = businessOpsCountryList.map((country: any) => {
        return {
          text: country.country,
          value: country.countryCode,
        };
      });
      const updatedCountryList = newCountryList.filter(
        (country: any) => country.value === businessOpsLocation,
      );
      setBusinessOpsList(updatedCountryList);
    }
    if (stageList) {
      const updatedStageList = stageList.filter(
        (stage: any) => stage.value === fundingStage,
      );
      setFundingStageList(updatedStageList);
    }
    if (industryTypeList) {
      const updatedList = industryTypeList.filter(
        (country: any) => country.value === industryType,
      );
      setIndustryList(updatedList);
    }
  };

  const handleFilterChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    type: string,
  ) => {
    const obj = { ...filterObj };
    if (type === 'industryType') obj['industryType'] = e.target.value;
    else if (type === 'fundingStage') obj['fundingStage'] = e.target.value;
    else if (type === 'ycBatch') obj['ycBatch'] = e.target.value;
    else if (type === 'businessOpsLocation')
      obj['businessOpsLocation'] = e.target.value;
    setFilterObj(obj);
    const newFilterObj = Object.fromEntries(
      Object.entries(obj).filter((entry: any) => entry[1] !== 'ALL'),
    );
    const filteredStartup = !isEmpty(newFilterObj)
      ? benchmarkingStartupsList.filter((startup: any) => {
          let trueCount = 0;
          Object.entries(newFilterObj).map(([key, value]: any) => {
            if (startup[key] === value) trueCount++;
          });
          return trueCount === Object.keys(newFilterObj).length;
        })
      : [];
    getStartupBenchmarkGrowthData(
      filteredStartup.map((startup: any) => startup.startupId),
    );
  };

  const createRowArrayForKPITableFormat = (
    mainArrayList: any,
    keyToGroupBy: string,
  ) => {
    const groupedObject = groupBy(mainArrayList, keyToGroupBy);
    const formattedArray = Object.entries(groupedObject).map(
      ([key, value]: any) => {
        return {
          kpiName: key,
          kpiValues: value.map((kpiObj: any) => {
            return {
              ...kpiObj,
              key: `${kpiObj.month}_${kpiObj.year}`,
            };
          }),
          isEdit: false,
          isPrimary: get(value, '[0].isPrimary'),
        };
      },
    );
    return formattedArray;
  };

  const createRowArrayForFundraiseKPITable = (mainArrayList: any) => {
    return mainArrayList.map((kpiObj: any) => {
      return {
        ...kpiObj,
        closingMonth: `${kpiObj.month}_${kpiObj.year}`,
        isEdit: false,
      };
    });
  };

  const onUpdateKpiDataCall = (payload: any) => {
    setErrorMessage('');
    payload = orderBy(
      payload,
      [(obj: any) => Number(obj.year), (obj: any) => Number(obj.month)],
      ['asc', 'asc'],
    );
    setFilterObj({
      industryType: 'ALL',
      fundingStage: 'ALL',
      ycBatch: 'ALL',
      businessOpsLocation: 'ALL',
    });
    if (!isEmpty(payload)) {
      createOrUpdateStartupMultipleKpiData(payload, role)
        .then(() => {
          getStartupBenchmarkKpiAllData();
          getStartupBenchmarkGrowthData([]);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          setErrorMessage(message);
        });
    }
  };

  const onUpdateFundRaiseKpiDataCall = (payload: any) => {
    setErrorMessage('');
    if (!isEmpty(payload)) {
      const invalidObj = payload.filter((obj: any) => {
        return (
          isEmpty(obj.roundStage) ||
          isEmpty(obj.closingMonth) ||
          isEmpty(obj.type)
        );
      });
      if (!isEmpty(invalidObj)) {
        setErrorMessage('Round stage, closing month and type is required');
      } else {
        createOrUpdateStartupMultipleFundraising(payload, role)
          .then(() => {
            getStartupBenchmarkKpiAllData();
            getValuationData();
          })
          .catch((err: any) => {
            const message: string = errorMessageHandler(err);
            setErrorMessage(message);
          });
      }
    }
  };

  const handleAddBenchmarkKpiRow = () => {
    let newData: any[] = [...benchmarkKpiRows];
    const primaryKPIAdded = find(benchmarkKpiRows, { isPrimary: true });
    if (!isEmpty(primaryKPIAdded)) {
      newData.push({
        kpiName: '',
        kpiValues: [],
        isEdit: true,
        isNewRow: true,
      });
    } else {
      newData = [
        {
          kpiName: '',
          kpiValues: [],
          isEdit: true,
          isNewRow: true,
        },
        ...benchmarkKpiRows,
      ];
    }
    setBenchmarkKpiRows(newData);
  };

  const handleAddFundraisingKpiRow = () => {
    const newData: any[] = [...fundRaisingKpiRows];
    newData.push({
      roundStage: '',
      isEdit: true,
      isNewRow: true,
    });
    setFundraisingKpiRows(newData);
  };

  useEffect(() => {
    if (ycBatches.length === 0) {
      dispatch(getBatches());
    }
    if (startupId) {
      getStartupData();
      getBenchmarkingStartupsData();
      getStartupBenchmarkGrowthData([]);
      getStartupBenchmarkKpiAllData();
      getValuationData();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(startupDetails)) {
      createFilterOptionsList();
    }
    // if (businessModel && isEmpty(kpiList)) {
    //   setKpiList(
    //     get(find(KPIsList, { businessModal: businessModel }), 'kpiList'),
    //   );
    // }
    setKpiList(KPIsListV2);
  }, [startupDetails]);

  useEffect(() => {
    setBenchmarkKpiRows(
      createRowArrayForKPITableFormat(benchmarkKpiList, 'kpiName'),
    );
    setBenchmarCmgrkKpiRows(
      createRowArrayForKPITableFormat(benchmarkKpiList, 'cmgrKpiName'),
    );
    setFundraisingKpiRows(
      createRowArrayForFundraiseKPITable(benchmarkFundraisingKpiList),
    );
  }, [benchmarkKpiList, benchmarkFundraisingKpiList]);

  useEffect(() => {
    if (!isEmpty(benchmarkKpiGrowth)) {
      const index = get(
        benchmarkKpiGrowth,
        'publishedPercentileGrowth',
      ).findIndex(
        (item: any) =>
          parseInt(item.value) >= get(benchmarkKpiGrowth, 'mrrMonthlyGrowth'),
      );
      if (index === 0) {
        const lower = get(benchmarkKpiGrowth, 'publishedPercentileGrowth')[
          index
        ].value;
        const fraction = lower / 20;
        const percentage =
          get(benchmarkKpiGrowth, 'mrrMonthlyGrowth') / fraction;
        setProgressGrowth(percentage);
      } else if (index === -1) {
        const percentage = 5 * 20;
        setProgressGrowth(percentage);
      } else {
        const lower = get(benchmarkKpiGrowth, 'publishedPercentileGrowth')[
          index - 1
        ].value;
        const upper = get(benchmarkKpiGrowth, 'publishedPercentileGrowth')[
          index
        ].value;
        const fraction = (upper - lower) / 20;
        const result = get(benchmarkKpiGrowth, 'mrrMonthlyGrowth') - lower;
        const percentage = index * 20 + result / fraction;
        setProgressGrowth(percentage);
      }
    }
  }, [benchmarkKpiGrowth]);

  return (
    <Box className={classes.benchMarkContainer}>
      <Box className={classes.benchmarkHead}>
        <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography variant="h6" className={classes.headText}>
              {role === 'ADMIN' ? `Yardstick - ${fullName || ''}` : 'Yardstick'}
              {role !== 'ADMIN' && (
                <>
                  <Button
                    name="Edit Profile"
                    className={classes.editProfileBtn}
                    startIcon={<EditOutlinedIcon fontSize="small" />}
                    onClick={() => {
                      setOpenBenchmarkDashboard(false);
                      setEditProfile(true);
                    }}
                  />
                </>
              )}
            </Typography>
          </Grid>
          <Grid item>
            {role !== 'ADMIN' &&
              !isEmpty(currentYcBatch) &&
              ycBatch !== get(currentYcBatch, 'value') && (
                <StyledTooltip
                  title={
                    isEmpty(benchmarkKpiList)
                      ? 'Enter latest KPIs to apply for venture debt'
                      : ''
                  }
                  placement="bottom"
                >
                  <div>
                    <Button
                      className={classes.ventureDebtLoanBtn}
                      name="Apply for Venture Debt"
                      onClick={() =>
                        history.push('startup/vd-enquiry-form/NEW')
                      }
                      disabled={isEmpty(benchmarkKpiList)}
                    />
                  </div>
                </StyledTooltip>
              )}
            {role === 'ADMIN' && (
              <Typography
                variant="h5"
                className={classes.backToMainText}
                onClick={() => history.push('/admin/benchmark-kpi')}
              >
                <KeyboardBackspaceIcon fontSize="large" /> Back to Main Page
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      {errorMessage && (
        <Typography variant="body1" gutterBottom className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      {!isEmpty(startupDetails) && (
        <Box className={classes.benchMarkKPIContent}>
          {isEmpty(benchmarkKpiList) && !isLoading && (
            <Typography className={classes.textOnBlur}>
              Enter latest KPIs to see where you stack up against your
              competitors.
            </Typography>
          )}
          <Box
            className={isEmpty(benchmarkKpiList) ? classes.blurContainer : ''}
          >
            <Box className={classes.percentileGrowthBox}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  className={classes.topContainerCgmr}
                >
                  <Typography variant="body1" className={classes.cgmrText}>
                    Current CMGR
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.cgmrPercentage}
                  >
                    {parseFloat(
                      get(benchmarkKpiGrowth, 'mrrMonthlyGrowth', 0),
                    ).toFixed(1)}
                    %
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                  lg={9}
                  className={classes.benchMarkTableOne}
                >
                  <table className={classes.percentileGrowthZuiTable}>
                    <thead>
                      <tr>
                        <th style={{ width: 100 }}>Percentile</th>
                        {PERCENTILE_GROWTH.map((title: string) => (
                          <th key={title}>{title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(
                        get(
                          benchmarkKpiGrowth,
                          'publishedPercentileGrowth',
                          [],
                        ),
                      ) && (
                        <tr style={{ position: 'relative' }}>
                          <td style={{ padding: 0, border: 0 }}></td>
                          <td colSpan={5} style={{ padding: 0, border: 0 }}>
                            <div className={classes.progressPercentageView}>
                              <LinearProgress
                                variant="determinate"
                                value={progressGrowth || 0}
                                className={classes.progressRank}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td style={{ width: 100 }}>Growth</td>
                        {PERCENTILE_GROWTH.map((title: string) => (
                          <td key={title}>
                            {parseFloat(
                              get(
                                find(
                                  get(
                                    benchmarkKpiGrowth,
                                    'publishedPercentileGrowth',
                                    [],
                                  ),
                                  { key: title },
                                ),
                                'value',
                                0,
                              ),
                            ).toFixed(1)}
                            %
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.startupGrowthRankBox}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={9} md={8} container>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={5} className={classes.filterLabelBox}>
                      <Typography className={classes.filterLabelText}>
                        Industry:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={9} sm={7}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.filterInput}
                      >
                        <WrappedSelect
                          variant={'outlined'}
                          value={get(filterObj, 'industryType')}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            handleFilterChange(e, 'industryType')
                          }
                          className={classes.textValInputSelect}
                          InputProps={{
                            classes: { root: classes.inputRootSelect },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRootSelect,
                              focused: classes.labelFocusedSelect,
                            },
                          }}
                          dropdowns={[
                            ...industryList,
                            { text: 'ALL', value: 'ALL' },
                          ]}
                          error={false}
                          disabled={isEmpty(benchmarkKpiList)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={5} className={classes.filterLabelBox}>
                      <Typography className={classes.filterLabelText}>
                        Stage:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} sm={7}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.filterInput}
                      >
                        <WrappedSelect
                          variant={'outlined'}
                          value={get(filterObj, 'fundingStage')}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            handleFilterChange(e, 'fundingStage')
                          }
                          className={classes.textValInputSelect}
                          InputProps={{
                            classes: { root: classes.inputRootSelect },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRootSelect,
                              focused: classes.labelFocusedSelect,
                            },
                          }}
                          dropdowns={[
                            ...fundingStageList,
                            { text: 'ALL', value: 'ALL' },
                          ]}
                          error={false}
                          disabled={isEmpty(benchmarkKpiList)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={5} className={classes.filterLabelBox}>
                      <Typography className={classes.filterLabelText}>
                        YC Batch:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} sm={7}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.filterInput}
                      >
                        <WrappedSelect
                          variant={'outlined'}
                          value={get(filterObj, 'ycBatch')}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            handleFilterChange(e, 'ycBatch')
                          }
                          placeholder="Loan Tenuare"
                          className={classes.textValInputSelect}
                          InputProps={{
                            classes: { root: classes.inputRootSelect },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRootSelect,
                              focused: classes.labelFocusedSelect,
                            },
                          }}
                          dropdowns={[
                            ...ycBatchesList,
                            { text: 'ALL', value: 'ALL' },
                          ]}
                          error={false}
                          disabled={isEmpty(benchmarkKpiList)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={5} className={classes.filterLabelBox}>
                      <Typography className={classes.filterLabelText}>
                        Geography:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} sm={7}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.filterInput}
                      >
                        <WrappedSelect
                          variant={'outlined'}
                          value={get(filterObj, 'businessOpsLocation')}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            handleFilterChange(e, 'businessOpsLocation')
                          }
                          className={classes.textValInputSelect}
                          InputProps={{
                            classes: { root: classes.inputRootSelect },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRootSelect,
                              focused: classes.labelFocusedSelect,
                            },
                          }}
                          dropdowns={[
                            ...businessOpsList,
                            {
                              text: 'ALL',
                              value: 'ALL',
                            },
                          ]}
                          error={false}
                          disabled={isEmpty(benchmarkKpiList)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={3}
                  md={4}
                  className={classes.startupRankContainer}
                >
                  <Typography className={classes.startupRankText}>
                    You are doing better than
                  </Typography>
                  <Typography className={classes.startupRankValue} variant="h4">
                    {filterLoading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <>
                        {parseFloat(
                          get(benchmarkKpiGrowth, 'startupRank', 0),
                        ).toFixed(1)}
                        %
                      </>
                    )}
                  </Typography>
                  <Typography className={classes.startupRankText}>
                    startups when compared within peers
                  </Typography>
                  <Typography className={classes.startupRankCompairedText}>
                    (Compared against:{' '}
                    {get(benchmarkKpiGrowth, 'noOfStartup') > 0
                      ? get(benchmarkKpiGrowth, 'noOfStartup') - 1
                      : get(benchmarkKpiGrowth, 'noOfStartup')}{' '}
                    startups)
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={classes.startupKPIDataBox}>
            <Typography className={classes.kpiDataLabelText}>
              KPI Data
            </Typography>
          </Box>
          {noKpiAdded && (
            <Grid container className={classes.enterKpiDetailsContainer}>
              <Grid item style={{ display: 'inherit' }}>
                <InfoOutlinedIcon className={classes.infoIconRed} />
                <Typography>Please enter KPI Details</Typography>
              </Grid>
            </Grid>
          )}
          {isLoading ? (
            <Box className={classes.loader}>
              <CircularProgress color="inherit" size={40} />
            </Box>
          ) : (
            <Box className={classes.kpiTableDataBox}>
              <KPITable
                tableClassName="benchmark-kpi-table-container"
                columnKeyName="kpiName"
                columnValueKeyName="kpiValue"
                originalKpiRows={createRowArrayForKPITableFormat(
                  benchmarkKpiList,
                  'kpiName',
                )}
                kpiRows={benchmarkKpiRows}
                setKpiRows={setBenchmarkKpiRows}
                kpiColumns={benchmarkColumns}
                kpiList={kpiList}
                startupId={startupId}
                role={role}
                onUpdateCall={onUpdateKpiDataCall}
                deleteBenchmarkKpi={deleteStartupBenchmarkKpi}
                onDeleteCall={() => {
                  getStartupBenchmarkKpiAllData();
                  getStartupBenchmarkGrowthData([]);
                }}
                setNoKpiAdded={setNoKpiAdded}
                selectedFrequency={get(startupDetails, 'benchmarkKPIFrequency')}
              />
              <Button
                className={classes.addKpiButton}
                name="Add KPI"
                startIcon={<AddIcon />}
                onClick={handleAddBenchmarkKpiRow}
                disabled={
                  benchmarkKpiRows.length === kpiList.length ||
                  (isEmpty(
                    benchmarkKpiList.find((kpi: any) => kpi.isPrimary),
                  ) &&
                    !isEmpty(benchmarkKpiRows.find((row: any) => row.isNewRow)))
                }
              />
            </Box>
          )}
          <Box className={classes.startupKPIDataBox}>
            <Typography className={classes.kpiDataLabelText}>
              Cumulative Monthly Growth Rates (CMGR)
            </Typography>
          </Box>
          <Box className={classes.kpiTableDataBox}>
            <KPITable
              tableClassName="benchmark-cmgr-kpi-table-container"
              columnKeyName="cmgrKpiName"
              columnValueKeyName="cmgrKpiValue"
              originalKpiRows={createRowArrayForKPITableFormat(
                benchmarkKpiList,
                'cmgrKpiName',
              )}
              kpiRows={benchmarkCmgrKpiRows}
              setKpiRows={setBenchmarCmgrkKpiRows}
              kpiColumns={benchmarkColumns}
              kpiList={kpiList}
              startupId={startupId}
              role={role}
              onUpdateCall={onUpdateKpiDataCall}
              deleteBenchmarkKpi={deleteStartupBenchmarkKpi}
              onDeleteCall={getStartupBenchmarkKpiAllData}
              setNoKpiAdded={setNoKpiAdded}
              selectedFrequency={get(startupDetails, 'benchmarkKPIFrequency')}
            />
          </Box>
          <Box className={classes.startupKPIDataBox}>
            <Typography className={classes.kpiDataLabelText}>
              Roundwise fundraising data
            </Typography>
          </Box>
          {noFundraiseAdded && (
            <Grid container className={classes.enterKpiDetailsContainer}>
              <Grid item style={{ display: 'inherit' }}>
                <InfoOutlinedIcon className={classes.infoIconRed} />
                <Typography>Please enter Fundraise Details</Typography>
              </Grid>
            </Grid>
          )}
          <Box className={classes.kpiTableDataBox}>
            <KPITable
              tableClassName="fundraising-kpi-table-container"
              columnKeyName="fundRaiseKpiName"
              columnValueKeyName=""
              originalKpiRows={createRowArrayForFundraiseKPITable(
                benchmarkFundraisingKpiList,
              )}
              kpiRows={fundRaisingKpiRows}
              setKpiRows={setFundraisingKpiRows}
              kpiColumns={fundRaisingColumns}
              kpiList={kpiList}
              startupId={startupId}
              role={role}
              onUpdateCall={onUpdateFundRaiseKpiDataCall}
              deleteBenchmarkKpi={deleteStartupBenchmarkFundraisingKpi}
              onDeleteCall={() => {
                getStartupBenchmarkKpiAllData();
                getValuationData();
              }}
              setNoKpiAdded={setNoFundraiseAdded}
              selectedFrequency={get(startupDetails, 'benchmarkKPIFrequency')}
            />
            <Button
              className={classes.addKpiButton}
              name="Add Round"
              startIcon={<AddIcon />}
              onClick={handleAddFundraisingKpiRow}
            />
          </Box>
          <Box className={classes.benchmarkContentRight}>
            <Typography className={classes.benchmarkContentTitle}>
              Estimated Valuation Range
            </Typography>
            <Box className={classes.benchmarkOutputBoxDollar}>
              <Box className={classes.benchmarkOutputBoxDollarContent}>
                {valuationLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  <Box
                    style={
                      get(valuationInsight, 'fundRaisingDataAdded')
                        ? {}
                        : { paddingBottom: 10, filter: 'blur(5.5px)' }
                    }
                  >
                    {!(
                      get(valuationInsight, 'rangeL', 0) > 0 &&
                      get(valuationInsight, 'rangeU', 0) > 0
                    ) ? (
                      <Typography className={classes.benchmarkSubGreenText}>
                        {/* A report on valuation insights will be sent to your
                        registered email shortly.{' '} */}
                        We don't have enough data points for this category at
                        the moment.
                      </Typography>
                    ) : (
                      <>
                        <Typography className={classes.benchmarkSubGreenText}>
                          Based on your KPIs and Traction <br /> your expected
                          current valuation is
                        </Typography>
                        <Typography
                          className={classes.benchmarkOutputGreenText}
                        >
                          {formatAmount(get(valuationInsight, 'rangeL', 0), {
                            maximumFractionDigits: 2,
                            notation: 'compact',
                          })}{' '}
                          -{' '}
                          {formatAmount(get(valuationInsight, 'rangeU', 0), {
                            maximumFractionDigits: 2,
                            notation: 'compact',
                          })}
                        </Typography>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BenchMarkDashboard;
