import React from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import { handleSetPasswordAction } from 'redux-modules/auth/Actions';
import { Button, Box, WrappedTextInput } from 'components';
import { RootState } from 'redux-modules/store/rootState';
import { successImage } from 'assets/index';
import { ResetPasswordSchema } from 'common/utils/validations';

import styles from './styles';

type ComponentProps = {
  match: any;
};

type ResetPasswordType = {
  password: string;
  newPassword: string;
};

const ResetPassword = ({ match }: ComponentProps) => {
  const { isLoading, errorMessage, successMessage } = useSelector(
    ({ Auth }: RootState) => Auth.confirmPassword,
  );
  const classes = styles();
  const dispatch = useDispatch();

  const onSubmit = ({ password, newPassword }: ResetPasswordType) => {
    const username: string = get(match, 'params.id');
    dispatch(
      handleSetPasswordAction({
        username,
        password,
        newPassword,
      }),
    );
  };

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema),
  });
  return (
    <>
      <Box className={classes.resetPwdBox}>
        <Box className={classes.resetPwdBoxContent}>
          {/* <Box className={classes.loaderCenter}>{isLoading && <Loader />}</Box> */}
          <Box>
            {errorMessage && (
              <Typography
                variant="body1"
                gutterBottom
                className={classes.errorText}
              >
                {errorMessage}
              </Typography>
            )}
          </Box>
          {successMessage ? (
            <Box>
              <img
                src={successImage}
                alt={successImage}
                className={classes.successImg}
              />
              <Typography
                variant="h2"
                gutterBottom
                className={classes.welcome8vdx}
              >
                {successMessage}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.welcomeSub8vdx}
              >
                Page will automatically redirect to Login.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="h2"
                gutterBottom
                className={classes.welcome8vdx}
              >
                Reset Password
              </Typography>
              <Typography className={classes.welcomeSub8vdx}>
                Create new password
              </Typography>
              <form
                onSubmit={handleSubmit(onSubmit)}
                data-testid="resetPasswordForm"
                className={classes.validateForm}
              >
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="password"
                        label="Password"
                        placeholder="Password"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'Password is required' }}
                />
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="password"
                        label="New Password"
                        placeholder="New Password"
                        className={classes.textValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                  rules={{ required: 'New Password is required' }}
                />
                <Box>
                  <Button
                    type="submit"
                    name="submit"
                    className={classes.signupBtn}
                    isLoading={isLoading}
                    disabled={isLoading}
                  />
                </Box>
              </form>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
