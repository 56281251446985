import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';

import { Button, Box, WrappedTextInput } from 'components';
import { FN } from 'common/types';
import { handleLoanCloserAction } from 'redux-modules/enquiries/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { formatAmount } from 'common/utils/helpers';

import { loanClosureClosedSchema } from './validations';
import styles from '../../../styles';

type Props = {
  handleClose: FN;
  startupId: string | undefined;
};

const Closed = ({ handleClose, startupId }: Props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(loanClosureClosedSchema),
  });
  const {
    isLoadingLoanCloserInfo,
    errorMessage,
    repaidInfo,
    loanCloserInfo,
  } = useSelector(({ Enquiries }: RootState) => Enquiries);

  useEffect(() => {
    if (!isLoadingLoanCloserInfo && isLoading && !errorMessage) {
      handleClose();
    } else {
      setIsLoading(isLoadingLoanCloserInfo);
    }
  }, [isLoadingLoanCloserInfo]);

  const onSubmit = (data: any) => dispatch(handleLoanCloserAction(data));

  const defaultLoanAmount = () => {
    if (get(repaidInfo, 'loanAmount')) {
      console.log(parseInt(get(repaidInfo, 'loanAmount')) - 1); //eslint-disable-line
      return parseInt(get(repaidInfo, 'loanAmount')) - 1;
    }
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="addPricedRound">
        {/* HIDDEN FIELDS ----------------- */}
        <div className={classes.hidden}>
          <Controller
            name="startupId"
            control={control}
            defaultValue={startupId}
            render={({ field: { onChange, value } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  value={value}
                  onChange={onChange}
                  className={''}
                  error={false}
                />
              </>
            )}
          />
          <Controller
            name="status"
            control={control}
            defaultValue={'CLOSED'}
            render={({ field: { onChange, value } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  value={value}
                  onChange={onChange}
                  className={''}
                  error={false}
                />
              </>
            )}
          />
          <Controller
            name="loanAmount"
            control={control}
            defaultValue={defaultLoanAmount()}
            render={({ field: { onChange, value } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  value={value}
                  onChange={onChange}
                  className={''}
                  error={false}
                />
              </>
            )}
          />
        </div>
        {/* HIDDEN FIELDS ----------------- */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Loan Closer - Closed
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {repaidInfo &&
              repaidInfo.totalPaymentReceived &&
              `Principal amount paid till date is ${formatAmount(
                repaidInfo.totalPaymentReceived,
              )} `}
            {loanCloserInfo &&
              loanCloserInfo.newDeadline &&
              `and Deadline for repayment is ${moment(
                loanCloserInfo.newDeadline,
              ).format('DD MMM YYYY')}`}
          </Grid>
          {errorMessage && (
            <Typography variant="body1" className={classes.errorText}>
              {errorMessage}
            </Typography>
          )}
          <Grid item xs={12} className={classes.inputHead}>
            <Typography>Principal Amount Paid Back</Typography>
            <Controller
              name="principalAmountPaid"
              control={control}
              defaultValue={get(repaidInfo, 'totalPaymentReceived')}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="number"
                    label=""
                    placeholder="0"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{
                required: 'Amount is required',
              }}
            />
          </Grid>
        </Grid>
        <Box className={classes.backContinueBox}>
          <Button
            type="submit"
            name="Save"
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
            className={classes.continueBtn}
          />
          <Button
            className={classes.backBtn}
            name="Cancel"
            onClick={handleClose}
          />
        </Box>
      </form>
    </Box>
  );
};

export default Closed;
