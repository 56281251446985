import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';

export const navAttributeSchema = yup.object().shape({
  spv: yup.string().nullable().required(requiredConstant),
  date: yup.string().nullable().required(requiredConstant),
  realisedGain: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  unRealisedGain: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  expenses: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  managementFee: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
  distribution: yup
    .number()
    .nullable(true)
    .required(requiredConstant)
    .transform((v, o) => (o === '' ? null : v)),
});
