import React, { ReactNode } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useLocation } from 'react-router-dom';

import styles from './styles';

type ComponentProps = {
  title?: string;
  subheading: string;
  children: ReactNode;
  handleChange: any;
  expanded: boolean;
  index: string;
  buttons?: ReactNode;
  style?: any;
  onClickIcon?: any;
  onClickSubHeading?: any;
  disabled?: boolean;
  className?: any;
  backgroundStyle?: any;
};

const SimpleAccordion = ({
  title,
  subheading,
  children,
  expanded,
  index,
  buttons,
  style,
  handleChange,
  onClickIcon,
  onClickSubHeading,
  disabled,
  className,
  backgroundStyle,
}: ComponentProps) => {
  const classes = styles();
  const pathName = useLocation().pathname;

  return (
    <>
      <Accordion
        expanded={expanded}
        style={style}
        disabled={disabled}
        onChange={handleChange(index)}
        className={
          className
            ? className
            : pathName === '/admin/vc-funds'
            ? classes.fundAccordBox
            : pathName === '/prospective'
            ? classes.prospectiveBox
            : classes.accordBox
        }
      >
        <AccordionSummary
          style={backgroundStyle ? backgroundStyle : undefined}
          expandIcon={
            <ChevronRightIcon
              className={classes.chRightIcon}
              onClick={onClickIcon || (() => null)}
            />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={
            pathName === '/admin/vc-funds'
              ? classes.summaryFundAccord
              : pathName === '/prospective'
              ? classes.accordProspectiveBox
              : classes.summaryAccord
          }
        >
          <Typography variant="caption" className={classes.stepAccord}>
            {title}
          </Typography>
          <Typography
            className={classes.accordHeadText}
            onClick={onClickSubHeading || (() => null)}
          >
            {subheading}
          </Typography>
          {buttons}
        </AccordionSummary>
        <AccordionDetails
          className={
            pathName === '/admin/vc-funds'
              ? classes.fundAccordDetails
              : pathName === '/prospective'
              ? classes.prospectiveAccordDetails
              : classes.accordDetails
          }
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SimpleAccordion;
