import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  tradeConfirm: {
    padding: '50px 165px 35px 188px',
    '@media (max-width: 1125px)': {
      padding: '50px 5% 35px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5% 15px',
    },
  },
  investNowFormHeader: {
    fontSize: 32,
    letterSpacing: 0.3,
    color: theme.palette.warning.contrastText,
    marginBottom: 30,
    fontWeight: 400,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '0px 0 20px',
    },
  },
  tCSTxt: {
    fontSize: 15,
    color: theme.palette.grey[50],
    letterSpacing: 0.3,
    marginBottom: 21,
  },
  tCBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 320,
    fontSize: 16,
    cursor: 'pointer',
    height: 48,
    marginBottom: 30,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    '@media (max-width: 1125px)': {
      width: 280,
    },
  },
  upLoadBox: {
    display: 'flex',
    padding: '8px 20px',
    border: '1px solid #D0D2DA',
    borderRadius: 4,
    maxWidth: 560,
    marginBottom: 25,
  },
  upLoadleft: {
    display: 'flex',
    width: 'calc(100% - 13px)',
  },
  circleImg: {
    height: 15,
    marginRight: 20,
    alignSelf: 'center',
  },
  bGImg: {
    height: 42.5,
    marginRight: 11,
  },
  uploadFileName: {
    alignSelf: 'center',
    fontSize: 16,
    color: theme.palette.info.light,
  },
  crossImg: {
    height: 13,
    alignSelf: 'center',
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  prevButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.main,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    marginRight: 18,
    textTransform: 'capitalize',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  errorMessage: {
    fontSize: 15,
    letterSpacing: 0.35,
    color: theme.palette.error.main,
  },
}));
