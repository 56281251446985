/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import postscribe from 'postscribe';

import './App.css';
import history from 'common/utils/history';
import AuthWrapper from 'components/AuthWrapper/AuthWrapper';

import useGaTracker from './useGaTracker';
import Routes from './Routes';
import { fontFamily } from './common/utils/constants';

if (process.env.REACT_APP_ENV === 'prod')
  postscribe(
    '#hubspot',
    ' <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/24440991.js"></script>',
  );

const theme = createTheme({
  palette: {
    primary: {
      main: '#1EA59A',
      contrastText: '#fff',
      // @ts-ignore
      primaryOrange: '#f26529',
      dark: '#EA7445',
    },
    secondary: {
      main: '#1EA59A',
      contrastText: '#1EA59A',
      light: '#DC3434',
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    error: {
      main: '#FF0000',
      contrastText: '#121212',
      light: '#00000099',
      dark: '#E0987C',
    },
    warning: {
      main: '#F6FAFA',
      contrastText: '#121212',
      dark: '#000',
      light: '#00000099',
    },
    success: {
      main: '#75C24B',
      light: '#1EA59A1A',
      dark: '#93C9C7',
    },
    info: {
      main: '#FFD174',
      dark: '#DCDCDC',
      light: '#979797',
    },
    grey: {
      50: '#404852',
      100: '#000',
      200: '#F3F6F9',
      300: '#464E5F',
      400: '#273240',
      500: '#262A41',
      600: '#404852',
      700: '#FF5151',
      800: '#8F99A8',
      900: '#16192C',
      A100: '#0351FF',
      A200: '#EC4B46',
      A400: '#818E9A',
      A700: '#D8D8D8',
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: fontFamily,
  },
  shadows: [
    'none',
    '0px 18px 32px rgba(208, 210, 218, 0.15)',
    '0px 9px 6px rgba(0, 0, 0, 0.06)',
    '#F8F8F8',
    '#029DF1',
    '#3646AF',
    '#43A547',
    '#F7C519',
    '#FF8B07',
    '#FF8010',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
});
const App: FC = () => {
  useGaTracker();
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <AuthWrapper>
            <Routes />
          </AuthWrapper>
        </ConnectedRouter>
      </MuiThemeProvider>
    </div>
  );
};
export default App;
