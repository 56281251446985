import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableCell,
  CircularProgress,
  Menu,
  MenuItem,
  TableSortLabel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Link from '@material-ui/icons/Link';
import { isEmpty, get } from 'lodash';
import moment from 'moment';

import {
  Box,
  Button,
  Dialog,
  StyledTableRow,
  StyledTableCell,
} from 'components';
import history from 'common/utils/history';
import {
  errorMessageHandler,
  formatAmount,
  getComparator,
  stableSort,
} from 'common/utils/helpers';
import { FN } from 'common/types';
import {
  deleteStartupsAllBenchmarkingData,
  skipStartupsAllBenchmarkingData,
} from 'services';

import styles from './styles';

type AdminActionMenuProps = {
  startupId: string;
  index: number;
  handleActionEdit: FN;
  handleActionDelete: FN;
  handleActionSkip: FN;
  skipBenchmarkPublish?: boolean;
};

const AdminActionMenu = (props: AdminActionMenuProps) => {
  const classes = styles();
  const {
    startupId,
    index,
    handleActionEdit,
    handleActionDelete,
    handleActionSkip,
    skipBenchmarkPublish,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleActionMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography
        variant="body1"
        className={classes.actionButton}
        aria-label="action"
        aria-controls={`simple-action-menu-${index}`}
        aria-haspopup="true"
        onClick={handleActionMenuClick}
      >
        Action
        <ExpandMoreIcon />
      </Typography>
      <Menu
        id={`simple-action-menu-${index}`}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleActionMenuClose}
      >
        <MenuItem
          className={classes.actionItem}
          onClick={() => {
            handleActionEdit(startupId);
            handleActionMenuClose();
          }}
        >
          <EditIcon className={classes.actionIcon} /> Edit
        </MenuItem>
        <MenuItem
          className={classes.actionItem}
          onClick={() => {
            handleActionDelete(startupId);
            handleActionMenuClose();
          }}
        >
          <DeleteIcon className={classes.actionIcon} /> Delete
        </MenuItem>
        <MenuItem
          className={classes.actionItem}
          onClick={() => {
            handleActionSkip(startupId, skipBenchmarkPublish);
            handleActionMenuClose();
          }}
        >
          {!skipBenchmarkPublish ? (
            <>
              <LinkOffIcon className={classes.actionIcon} /> Skip
            </>
          ) : (
            <>
              <Link className={classes.actionIcon} /> Include
            </>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

type Props = {
  isLoading: boolean;
  startupsGrowthRateData: any[];
  page: number;
  rowsPerPage: number;
  emptyRows: number;
  selectedStartup: string;
  setSelectedStartup: FN;
  setOpenAddStartupModal: FN;
  getAdminBenchmarkKpiAllData: FN;
  showActionColumn: boolean;
  primaryStartups: boolean;
  showNewTag: boolean;
};

const StartupsGrowthRateList = ({
  isLoading,
  startupsGrowthRateData,
  page,
  rowsPerPage,
  emptyRows,
  selectedStartup,
  setSelectedStartup,
  setOpenAddStartupModal,
  getAdminBenchmarkKpiAllData,
  showActionColumn = true,
  primaryStartups = true,
  showNewTag = true,
}: Props) => {
  const classes = styles();

  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [skipModal, setSkipModal] = React.useState<string>('');
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
  const [skipLoading, setSkipLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [order, setOrder] = React.useState<any>('desc');
  const [orderBy, setOrderBy] = React.useState<any>('latestUpdateAt');

  const handleActionEdit = (startupId: string) => {
    setSelectedStartup(startupId);
    setOpenAddStartupModal(true);
  };

  const handleActionDelete = (startupId: string) => {
    setSelectedStartup(startupId);
    setDeleteModal(true);
  };

  const handleActionSkip = (
    startupId: string,
    skipBenchmarkPublish: boolean,
  ) => {
    setSelectedStartup(startupId);
    setSkipModal(!skipBenchmarkPublish ? 'SKIP' : 'INCLUDE');
  };

  const handleDeleteModalClose = () => {
    setSelectedStartup('');
    setErrorMessage('');
    setDeleteModal(false);
  };

  const handleSkipModalClose = () => {
    setSelectedStartup('');
    setErrorMessage('');
    setSkipModal('');
  };

  const handleDeleteStartupBenchmark = async () => {
    try {
      if (!isEmpty(selectedStartup)) {
        setDeleteLoading(true);
        await deleteStartupsAllBenchmarkingData(selectedStartup);
        setDeleteLoading(false);
        getAdminBenchmarkKpiAllData();
        handleDeleteModalClose();
      } else {
        setErrorMessage(
          'Something went wrong. You can not delete this startup.',
        );
      }
    } catch (err) {
      const message: string = errorMessageHandler(err) || get(err, 'message');
      setDeleteLoading(false);
      setErrorMessage(message);
    }
  };

  const handleSkipStartupBenchmark = async () => {
    try {
      if (!isEmpty(selectedStartup)) {
        setSkipLoading(true);
        await skipStartupsAllBenchmarkingData(selectedStartup);
        setSkipLoading(false);
        getAdminBenchmarkKpiAllData();
        handleSkipModalClose();
      } else {
        setErrorMessage('Something went wrong. You can not skip this startup.');
      }
    } catch (err) {
      const message: string = errorMessageHandler(err) || get(err, 'message');
      setSkipLoading(false);
      setErrorMessage(message);
    }
  };

  const createSortHandler = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Startup</StyledTableCell>
              <StyledTableCell>Industry</StyledTableCell>
              <StyledTableCell>Source</StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === 'latestKpiGrowthRate'}
                  direction={orderBy === 'latestKpiGrowthRate' ? order : 'asc'}
                  onClick={() => createSortHandler('latestKpiGrowthRate')}
                >
                  Latest KPI Growth Rates
                </TableSortLabel>
              </StyledTableCell>
              {primaryStartups ? (
                <StyledTableCell>Yardstick&trade; Score</StyledTableCell>
              ) : (
                <StyledTableCell>Latest Valuation</StyledTableCell>
              )}
              <StyledTableCell>Skip</StyledTableCell>
              <StyledTableCell>
                {' '}
                <TableSortLabel
                  active={orderBy === 'latestUpdateAt'}
                  direction={orderBy === 'latestUpdateAt' ? order : 'asc'}
                  onClick={() => createSortHandler('latestUpdateAt')}
                >
                  Updated At
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={classes.loader}>
                    <CircularProgress color="inherit" size={40} />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {isEmpty(startupsGrowthRateData) ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box className={classes.loader}>
                        <Typography>No data found in table</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {startupsGrowthRateData &&
                      stableSort(
                        startupsGrowthRateData,
                        getComparator(order, orderBy),
                      )
                        .slice(
                          (page - 1) * rowsPerPage,
                          (page - 1) * rowsPerPage + rowsPerPage,
                        )
                        .map((row: any, index: any) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{ color: '#1EA59A' }}
                            >
                              {showNewTag && !row.published ? (
                                <>
                                  {row.startupFullName}
                                  <span className={classes.nonPublishTag}>
                                    New
                                  </span>
                                </>
                              ) : (
                                row.startupFullName
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {!isEmpty(get(row, 'industryType'))
                                ? get(row, 'industryType')
                                : '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {get(row, 'source.name') === 'SELF'
                                ? 'Startup'
                                : get(row, 'source.name') === '8VDX'
                                ? '8vdx admin'
                                : get(row, 'source.name') || '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {parseFloat(
                                get(row, 'latestKpiGrowthRate', 0),
                              ).toFixed(2)}
                              %
                            </StyledTableCell>
                            {primaryStartups ? (
                              <StyledTableCell>
                                {get(row, 'percentile', 0)}%
                              </StyledTableCell>
                            ) : (
                              <StyledTableCell>
                                {get(row, 'latestValuation', 0) > 0
                                  ? formatAmount(
                                      get(row, 'latestValuation', 0),
                                      {
                                        maximumFractionDigits: 2,
                                        notation: 'compact',
                                      },
                                    )
                                  : '-'}
                              </StyledTableCell>
                            )}
                            <StyledTableCell>
                              {get(row, 'skipBenchmarkPublish') ? 'Yes' : 'No'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {moment(get(row, 'latestUpdateAt')).format(
                                'MMM D, YYYY',
                              )}
                              <Typography
                                variant="body1"
                                className={classes.updatedTime}
                              >
                                {moment(get(row, 'latestUpdateAt')).format(
                                  'hh:mm a',
                                )}
                              </Typography>
                            </StyledTableCell>
                            {showActionColumn ? (
                              <StyledTableCell
                                className={classes.actionButtonBox}
                                style={{ paddingLeft: 2 }}
                              >
                                <Typography
                                  variant="body1"
                                  className={classes.viewButton}
                                  onClick={() =>
                                    history.push(
                                      `/benchmark-kpi/${get(row, 'startupId')}`,
                                    )
                                  }
                                >
                                  View
                                </Typography>
                                <AdminActionMenu
                                  startupId={row.startupId}
                                  index={index}
                                  handleActionDelete={handleActionDelete}
                                  handleActionEdit={handleActionEdit}
                                  handleActionSkip={handleActionSkip}
                                  skipBenchmarkPublish={get(
                                    row,
                                    'skipBenchmarkPublish',
                                  )}
                                />
                              </StyledTableCell>
                            ) : (
                              <StyledTableCell
                                className={classes.actionButtonBox}
                              >
                                -
                              </StyledTableCell>
                            )}
                          </StyledTableRow>
                        ))}
                  </>
                )}
                {page > 1 && emptyRows > 0 && (
                  <TableRow style={{ height: 50 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={deleteModal}
        maxWidth={'sm'}
        subheading={''}
        title="Delete Benchmark KPI"
        handleClose={() => {
          handleDeleteModalClose();
        }}
        dialogAction={true}
        dialogActionChildren={
          <Box className={classes.backSubmitBox}>
            <Button
              type="submit"
              name="OK"
              onClick={handleDeleteStartupBenchmark}
              className={classes.submitBtn}
              isLoading={deleteLoading}
              disabled={deleteLoading}
            />
            <Button
              className={classes.backBtn}
              onClick={handleDeleteModalClose}
              disabled={deleteLoading}
              name="Cancel"
            />
          </Box>
        }
      >
        <>
          {errorMessage && (
            <Typography variant="caption" className={classes.errorText}>
              {errorMessage}
            </Typography>
          )}
          <Typography variant="h5" className={classes.deleteText}>
            Are you sure, you want to delete the all benchmark KPI data for
            startup?
          </Typography>
        </>
      </Dialog>
      <Dialog
        open={!!skipModal}
        maxWidth={'sm'}
        subheading={''}
        title={
          skipModal === 'SKIP' ? 'Skip Benchmark KPI' : 'Include Benchmark KPI'
        }
        handleClose={() => {
          handleSkipModalClose();
        }}
        dialogAction={true}
        dialogActionChildren={
          <Box className={classes.backSubmitBox}>
            <Button
              type="submit"
              name="OK"
              onClick={handleSkipStartupBenchmark}
              className={classes.submitBtn}
              isLoading={skipLoading}
              disabled={skipLoading}
            />
            <Button
              className={classes.backBtn}
              onClick={handleSkipModalClose}
              disabled={skipLoading}
              name="Cancel"
            />
          </Box>
        }
      >
        <>
          {errorMessage && (
            <Typography variant="caption" className={classes.errorText}>
              {errorMessage}
            </Typography>
          )}
          <Typography variant="h5" className={classes.deleteText}>
            Are you sure, you want to{' '}
            {skipModal === 'SKIP' ? 'skip' : 'include'} all benchmark KPI data
            for startup?
          </Typography>
        </>
      </Dialog>
    </>
  );
};

export default StartupsGrowthRateList;
