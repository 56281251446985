import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import NotFound from './NotFound';

export const NotFoundRoute: IRoute = {
  path: '/not-found',
  component: NotFound,
  layout: DashboardLayout,
  isAdmin: false,
};
