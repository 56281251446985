import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  dashboardLayout: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    background: theme.palette.warning.main,
    overflow: 'hidden',
    '@media (max-width: 1024px)': {
      display: 'block',
      position: 'relative',
    },
    // '@media (max-width: 767px)': {
    //   overflow: 'inherit',
    //   height: '100%',
    // },
  },
  dashLayoutLeft: {
    flex: '0 0 auto',
    width: 280,
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '@media (max-width: 1220px)': {
      width: 230,
    },
    '@media (max-width: 1024px)': {
      position: 'absolute',
      top: 40,
      left: 40,
      zIndex: 100,
    },
    '@media (max-width: 767px)': {
      position: 'sticky',
      top: 0,
      height: 70,
      margin: 0,
      border: 0,
      width: '100%',
      padding: 0,
      background: theme.palette.primary.contrastText,
      boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 28%)',
    },
  },
  dashLayoutRight: {
    width: 'calc(100% - 280px)',
    flexGrow: 1,
    background: theme.palette.common.white,
    position: 'relative',
    marginTop: 20,
    marginRight: 20,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    border: '1px solid #fff',
    '@media (max-width: 1220px)': {
      width: 'calc(100% - 230px)',
    },
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 40px)',
      margin: 20,
      marginBottom: 0,
    },
    '@media (max-width: 767px)': {
      width: '100%',
      margin: 0,
      border: 0,
      borderRadius: 0,
      height: 'calc(100% - 70px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
    },
  },
  // simpleLayoutRight: {
  //   width: 'calc(100% - 280px)',
  //   padding: '0px 0px 0px 60px',
  //   background: theme.palette.common.white,
  //   position: 'relative',
  //   marginTop: 20,
  //   marginRight: 20,
  //   border: '1px solid #fff',
  //   borderTopLeftRadius: 25,
  //   borderTopRightRadius: 25,
  // },
  ////// Dashboard Layout V2 //////
  dashLayoutRightV2: {
    width: 'calc(100% - 280px)',
    flexGrow: 1,
    background: '#EFF3F6',
    position: 'relative',
    marginTop: 20,
    marginRight: 20,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    // border: '1px solid #fff',
    '@media (max-width: 1220px)': {
      width: 'calc(100% - 230px)',
    },
    '@media (max-width: 1024px)': {
      width: 'calc(100% - 40px)',
      margin: 20,
      marginBottom: 0,
    },
    '@media (max-width: 767px)': {
      width: '100%',
      margin: 0,
      border: 0,
      borderRadius: 0,
      height: 'calc(100% - 70px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
    },
  },
}));
