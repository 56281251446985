import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { Accordion, Box } from 'components';

import styles from './styles';
import FundsTable from './FundsTable';

const DeletedFundTable = ({ fundList, isLoading, errorMessage }: any) => {
  const classes = styles();

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<any>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };
  const filteredList = fundList.filter((item: any) => item.isDeleted);

  return (
    <div className={classes.accordDeletedFundContainer}>
      <Accordion
        subheading={'Deleted Funds'}
        expanded={expanded === 'DELETED_FUNDS'}
        handleChange={(e: string) => handleChange(e)}
        index={'DELETED_FUNDS'}
        buttons={
          <Box className={classes.summaryAccordRight}>
            <MoreHorizIcon className={classes.moreIcon} />
          </Box>
        }
      >
        <Box className={classes.AccordionContent}>
          <FundsTable
            isLoading={isLoading}
            errorMessage={errorMessage}
            filteredSearchData={filteredList}
          />
        </Box>
      </Accordion>
    </div>
  );
};

export default DeletedFundTable;
