import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, get } from 'lodash';

import { getSignedUrl, uploadDocApi } from 'services';
import { IDoc } from 'redux-modules/process/Types';
import { RootState } from 'redux-modules/store/rootState';
import { Button, Box, Loader, SuccessPage } from 'components';
import { getDocuments } from 'redux-modules/enquiryForm/Actions';
import { StartupOnboardStepProps } from 'common/types';
import { successImage } from 'assets';

import ReviewDocument from '../../../components/CompanyProcess/Components/ReviewDocument';
import styles from '../styles';

const UploadDocuments = ({
  handleNext,
  startUpId,
}: StartupOnboardStepProps) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [documentObj, setDocumentObj] = useState<any>('');
  const [index, setIndex] = useState<number>(0);
  const [uploadDocumentsList, setUploadDocumentsList] = useState<any>([]);
  const [isSubmitDocLoading, setSubmitDocLoading] = useState<boolean>(false);

  const {
    uploadDocuments,
    getDocsToUploadErr,
    errorMessage,
    isLoading,
  } = useSelector(({ EnquiryForm }: RootState) => EnquiryForm);

  const getDocumentsList = () => {
    const payload = {
      startupId: startUpId,
      documentsType: 'NON_DOCU_SIGN_DOCUMENTS',
      loanType: 'BRIDGE_LOAN',
      onboard: true,
    };
    dispatch(getDocuments(payload));
  };

  useEffect(() => {
    if (!isEmpty(startUpId)) getDocumentsList();
  }, []);

  useEffect(() => {
    if (!isEmpty(uploadDocuments)) {
      setUploadDocumentsList(uploadDocuments);
    }
  }, [uploadDocuments, getDocsToUploadErr]);

  const handleGetSignedURL = async (file: any, data: IDoc, value: number) => {
    const { documentName, id } = data;
    setDocumentObj(data);
    setIndex(value);
    if (file.type === 'application/x-msdownload') {
      setError('File format not supported');
      return;
    } else {
      setLoading(true);
      const obj = {
        startupId: startUpId,
        documentId: id,
        documentName: documentName,
        fileName: get(file, 'name'),
      };
      try {
        const { uploadUrl, accessUrl }: any = await getSignedUrl(obj);
        // eslint-disable-next-line no-undef
        await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
        });

        const dataObj = {
          documents: [
            {
              ...data,
              id: get(data, 'id'),
              documentUrl: accessUrl,
              filename: get(file, 'name'),
            },
          ],
          startupId: startUpId,
        };
        await uploadDocApi(dataObj);
        setLoading(false);
        setSuccess('Uploaded');
        setError('');

        getDocumentsList();
      } catch (err) {
        setLoading(false);
        setError(
          get(err, 'err.response.data.message')
            ? get(err, 'err.response.data.message')
            : 'something went wrong',
        );
        setSuccess('');
      }
    }
  };

  const handleSubmitUploadDocuments = async () => {
    try {
      const checkDocsUploaded = uploadDocumentsList.every((doc: any) => {
        return doc.isOptional || doc.documentStatus != 'REQUESTED';
      });
      if (checkDocsUploaded) {
        handleNext();
      }
    } catch (err) {
      setSubmitDocLoading(false);
    }
  };

  const handleCancelDocument = (data: IDoc) => {
    setDocumentObj('');
    const documents = uploadDocumentsList.map((doc: IDoc) => {
      if (doc.id === data.id) {
        const item = JSON.parse(JSON.stringify(doc));
        item.documentStatus = 'REQUESTED';
        item.documentUrl = '';
        return item;
      }
      return doc;
    });
    setUploadDocumentsList(documents);
  };

  const handleDisableSubmit = () => {
    let isDisable = true;
    if (!isEmpty(uploadDocumentsList)) {
      uploadDocumentsList.forEach((item: any) => {
        isDisable = true;
        if (item.isOptional || item.documentStatus !== 'REQUESTED') {
          isDisable = false;
        }
      });
      return isDisable;
    }
  };

  return (
    <Box>
      <Typography variant="h2" className={classes.detailsText}>
        Onboard a startup
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.errorText}>
        {errorMessage}
      </Typography>
      {isLoading && isEmpty(uploadDocumentsList) && <Loader />}
      {getDocsToUploadErr ? (
        <Box className={classes.enquirySuccessBox}>
          <Box className={classes.eBoxContent}>
            <SuccessPage img={successImage} className={classes.dashSuccessImg}>
              <Typography
                gutterBottom
                variant="h4"
                className={classes.successHeaderTxt}
              >
                {getDocsToUploadErr === 'NOT_SERVING_FOR_YC_BATCH'
                  ? 'Thank you for submitting the request. We are unable to verify that you are part of an eligible YC batch. We will reach out to you shortly to process your application.'
                  : getDocsToUploadErr === 'EMAIL_NOT_IN_CURRENT_YC_BATCH'
                  ? 'Thank you for submitting the request. We are unable to verify your email as part of an eligible YC batch. We will reach out to you shortly to process your application.'
                  : 'Thank you for submitting the request. We will reach out to you shortly to process your application.'}
              </Typography>
            </SuccessPage>
          </Box>
        </Box>
      ) : (
        !isEmpty(uploadDocumentsList) && (
          <div>
            <Box className={classes.docuUploadBox}>
              <Box className={classes.headDetails}>
                <Typography variant="h3" className={classes.headerText}>
                  Upload the following documents
                </Typography>
              </Box>
              <Box className={classes.docuUploadContent}>
                {!isEmpty(uploadDocumentsList) &&
                  uploadDocumentsList.length &&
                  uploadDocumentsList.map((doc: IDoc, i: number) => {
                    return (
                      <ReviewDocument
                        key={i}
                        docu={doc}
                        value={i}
                        startupId={startUpId}
                        loading={loading}
                        success={success}
                        error={error}
                        documentObj={documentObj}
                        setSuccess={setSuccess}
                        setError={setError}
                        handleGetSignedURL={handleGetSignedURL}
                        handleCancelDocument={handleCancelDocument}
                        index={index}
                      />
                    );
                  })}
              </Box>
              <Box className={classes.uploadDocuBtn}>
                <Button
                  className={classes.uploadBtn}
                  onClick={handleSubmitUploadDocuments}
                  name="Continue"
                  disabled={handleDisableSubmit()}
                  isLoading={isSubmitDocLoading}
                />
              </Box>
            </Box>
          </div>
        )
      )}
    </Box>
  );
};

export default UploadDocuments;
