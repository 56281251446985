import { IRoute } from 'common/types';
import SimpleLayout from 'layouts/SimpleLayout';

import DealCreation from './DealCreation';

export const DealCreationRoute: IRoute = {
  path: '/admin/new-deal/:id',
  component: DealCreation,
  layout: SimpleLayout,
};
