import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import { RootState } from 'redux-modules/store/rootState';
import {
  Box,
  Button,
  WrappedAutocomplete,
  WrappedSelect,
  WrappedTextInput,
} from 'components';
import { updateStartupInformation } from 'services';
import { getBatches } from 'redux-modules/auth/Actions';
import {
  businessOpsCountryList,
  getIndustryTypeObject,
  getBusinessOpsCountryObject,
  errorMessageHandler,
} from 'common/utils/helpers';
import {
  stageList,
  industryTypeList,
  acceleratorList,
} from 'common/utils/optionList';
import { getStartupInfo } from 'redux-modules/enquiries/Actions';

import styles from './styles';
import { BasicDetailsSchema } from './validations';

type Props = {
  startUpId: any;
  handleClose?: any;
};

const BenchmarkBasicDetails = ({ startUpId, handleClose }: Props) => {
  const dispatch = useDispatch();
  const classes = styles();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(BasicDetailsSchema),
  });

  const { startupInfo } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const { ycBatches } = useSelector(({ Auth }: RootState) => Auth);

  const {
    businessModel,
    businessOpsLocation,
    fundingStage,
    industryType,
    fullName: startupFullName,
    ycBatch,
    accelerator,
  } = startupInfo;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = async () => {
    setIsLoading(true);
    const data: any = { ...getValues() };
    const startupFormDetails = {
      ...data,
      startupId: get(startupInfo, 'id') || startUpId,
      startupFullName: get(startupInfo, 'fullName') || startupFullName,
    };
    const obj = {
      startupFullName: get(startupFormDetails, 'startupFullName'),
      startupId: get(startupFormDetails, 'startupId'),
      businessOpsLocation: get(
        startupFormDetails,
        'businessOpsLocation.countryCode',
      ),
      industryType: get(startupFormDetails, 'industryType.value'),
      fundingStage: get(startupFormDetails, 'fundingStage'),
      ycBatch:
        get(startupFormDetails, 'accelerator') === 'YC'
          ? get(startupFormDetails, 'ycBatch')
          : null,
      accelerator:
        get(startupFormDetails, 'accelerator') === 'OTHER'
          ? get(startupFormDetails, 'acceleratorName')
          : get(startupFormDetails, 'accelerator'),
    };
    await updateStartupInformation(obj)
      .then(() => {
        setIsLoading(false);
        dispatch(getStartupInfo());
        handleClose();
      })
      .catch((err: any) => {
        setIsLoading(false);
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      });
  };

  useEffect(() => {
    if (ycBatches.length === 0) {
      dispatch(getBatches());
    }
  }, []);

  const resetValue = () => {
    setValue('benchmarkStartup', get(startupInfo, 'benchmarkStartup'));
    if (accelerator !== 'YC' && accelerator !== 'NONE') {
      setValue('accelerator', 'OTHER');
      setValue('acceleratorName', accelerator || '');
    }
    if (accelerator === 'NONE' || accelerator === 'YC') {
      setValue('accelerator', accelerator);
    }
    if (!isEmpty(get(startupInfo, 'id'))) {
      setValue('startupFullName', startupFullName);
      setValue(
        'businessOpsLocation',
        getBusinessOpsCountryObject(businessOpsLocation),
      );
      setValue('industryType', getIndustryTypeObject(industryType));
      setValue('businessModel', businessModel || '');
      setValue('fundingStage', fundingStage || '');
      setValue('ycBatch', ycBatch || '');
    } else if (!isEmpty(ycBatch)) {
      setValue('ycBatch', ycBatch);
    }
    clearErrors();
  };

  useEffect(() => {
    resetValue();
  }, [startupInfo]);

  return (
    <Box className={classes.benchBasicForm}>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="StartupBasicForm">
        <Box className={classes.startupFormFirstContainer}>
          {errorMessage && (
            <Typography
              variant="body1"
              gutterBottom
              className={classes.errorMessage}
            >
              {errorMessage}
            </Typography>
          )}
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Country of Business Operations*
              </Typography>
              <Controller
                name="businessOpsLocation"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedAutocomplete
                      id="field-business-op-location"
                      placeholder="Choose Country"
                      disableClearable
                      options={businessOpsCountryList}
                      value={
                        value ||
                        getBusinessOpsCountryObject(businessOpsLocation)
                      }
                      getOptionLabel={(option: any) => option.country || ''}
                      getOptionSelected={(option: any, value: any) =>
                        option.countryCode === value?.countryCode
                      }
                      onChange={(_: any, value: any) => {
                        onChange(value);
                      }}
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                      disabled={!isEmpty(businessOpsLocation)}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Sector/Industry*
              </Typography>
              <Controller
                name="industryType"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedAutocomplete
                      id="industry-select"
                      placeholder="Select Industry"
                      disableClearable
                      options={industryTypeList}
                      value={value || getIndustryTypeObject(industryType)}
                      getOptionLabel={(option: any) => option.text || ''}
                      getOptionSelected={(option: any, value: any) =>
                        option.value === value?.value
                      }
                      onChange={(_: any, value: any) => {
                        onChange(value);
                      }}
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                      disabled={!isEmpty(industryType)}
                    />

                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Funding stage*
              </Typography>
              <Controller
                name="fundingStage"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedSelect
                      value={value}
                      onChange={onChange}
                      placeholder="Select Funding stage"
                      className={classes.selectValInput}
                      InputProps={{
                        name: 'Funding stage',
                        id: 'funding-stage',
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={error?.message ? true : false}
                      dropdowns={stageList}
                      disabled={!isEmpty(fundingStage)}
                    />
                    {error?.message && (
                      <span className={classes.errorSelectText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.inputHead}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Accelerator*
              </Typography>
              <Controller
                name="accelerator"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedSelect
                      value={value}
                      onChange={onChange}
                      placeholder="Accelerator"
                      className={classes.selectValInput}
                      InputProps={{ name: 'Accelerator', id: 'age-simple' }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={!!error?.message}
                      dropdowns={acceleratorList}
                      disabled={true}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {watch('accelerator') === 'YC' && (
                <Box>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    YC Batch*
                  </Typography>
                  <Controller
                    name="ycBatch"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedSelect
                          value={value}
                          onChange={onChange}
                          placeholder="YC batch"
                          className={classes.selectValInput}
                          InputProps={{ name: 'Yc Batch', id: 'age-simple' }}
                          SelectProps={{
                            MenuProps: {
                              classes: { paper: classes.menuPaper },
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          error={!!error?.message}
                          dropdowns={ycBatches}
                          disabled={true}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Box>
              )}
              {watch('accelerator') === 'OTHER' && (
                <Box>
                  <Typography
                    variant="caption"
                    className={classes.companyLabelText}
                  >
                    Accelerator Name*
                  </Typography>
                  <Controller
                    name="acceleratorName"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <WrappedTextInput
                          type="text"
                          placeholder="Accelerator Name"
                          className={classes.textValInput}
                          InputProps={{
                            classes: { root: classes.inputRoot },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.labelRoot,
                              focused: classes.labelFocused,
                            },
                          }}
                          value={value}
                          onChange={(e: any) =>
                            onChange(get(e, 'target.value').trimStart())
                          }
                          error={!!error?.message}
                          disabled={true}
                        />
                        {error?.message && (
                          <span className={classes.errorText}>
                            {error?.message}
                          </span>
                        )}
                      </>
                    )}
                    rules={{ required: 'Accelerator Name is required' }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.backContinueBox}>
          <Button
            type="submit"
            className={classes.continueBtn}
            name={`Save`}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Button
            variant="outlined"
            className={classes.resetBtn}
            name="Back"
            disabled={isLoading}
            onClick={handleClose}
          />
        </Box>
      </form>
    </Box>
  );
};

export default BenchmarkBasicDetails;
