import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  vcFirmAdminBox: {
    height: 'calc(100vh - 20px)',
    width: '100%',
    '@media (max-width: 767px)': {
      height: 'auto',
    },
  },
  vcFirmHead: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    padding: '20px 40px 30px 50px',
    '@media (max-width: 1180px)': {
      padding: '20px 40px 20px 40px',
    },
    '@media (max-width: 1024px)': {
      padding: '80px 20px 20px',
    },
    // '@media (max-width: 767px)': {
    //   padding: '20px 15px',
    //   display: 'block',
    // },
  },
  enquiryTabs: {
    //borderBottom: '0.5px solid #DEDEDE',
  },
  rightBox: {
    display: 'flex',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
    display: 'block',
    padding: 20,
  },
  searchBox: {
    borderRadius: 30,
    border: '1px solid #E5E5E5',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    marginTop: 5,
    width: 200,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchIcon: {
    padding: 5,
    pointerEvents: 'none',
  },
  clearIcon: {
    padding: 5,
  },
  inputSearch: {
    width: 'calc(100% - 10px)',
    paddingLeft: 14,
  },
  menuPaper: {
    maxHeight: 200,
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  customizePlanBtn: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.25,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: 30,
    width: 150,
    height: 40,
    marginTop: 5,
    marginRight: 20,
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  // TAB
  actionTabPanel: {
    height: 'calc(100vh - 100px)',
    overflowX: 'hidden',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    '& .MuiBox-root': {
      height: '100%',
    },
    '@media (max-width: 1024px)': {
      // height: 'calc(100% - 60px - 80px)',
    },
  },
  // select
  textValInputSelect: {
    marginBottom: 10,
    marginTop: 5,
    marginRight: 18,
    // width: '50%',
    width: '150px',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRootSelect: {
    width: '100%',
    fontSize: 15,
    height: 42,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRootSelect: {},
  labelFocusedSelect: {},
}));
