import * as yup from 'yup';
import { isEmpty } from 'lodash';
// import moment from 'moment';

import { getParsedPriceInputValue } from 'common/utils/helpers';
import {
  requiredConstant,
  urlRegex,
  urlValidConstant,
  emailValidConstant,
  emailRegex,
} from 'common/utils/constants';

const emailSchema = yup
  .string()
  .email(emailValidConstant)
  .matches(emailRegex, emailValidConstant);

export const AddProspectiveDealSchema = yup.object().shape({
  vcAdminDealFounders: yup.array().of(
    yup.object().shape({
      name: yup.string().nullable(),
      linkedinUrl: yup
        .string()
        .nullable()
        .when({
          is: (value: string) => !isEmpty(value),
          then: yup.string().matches(urlRegex, urlValidConstant),
        }),
    }),
  ),
  companyName: yup.string().nullable().required(requiredConstant),
  dateOfEmail: yup
    .date()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .typeError('Invalid date format. (dd/mm/yyyy)')
    .test('format', 'Invalid date format. (dd/mm/yyyy)', (value: any) =>
      value !== undefined && value !== null
        ? value.getFullYear() <= 9999
        : true,
    ),
  email: yup
    .array()
    .nullable()
    .of(yup.string())
    .test({
      name: 'emails',
      test: function (value) {
        const { createError } = this;
        const firstInvalidEmail = (value || [])
          .map((email: any) => email?.trim())
          .filter((v) => !isEmpty(v))
          .find((v) => !emailSchema.isValidSync(v));
        return !firstInvalidEmail
          ? true
          : createError({
              message: `The email address '${firstInvalidEmail}' is invalid.`,
            });
      },
    }),
  companyUrl: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  sector: yup.string().nullable(),
  geography: yup.object().nullable(),
  countryOfOperation: yup.object().nullable(),
  lastFundingRound: yup.string().nullable(),
  proposedAmount: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup
        .string()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .test(
          'positive',
          'Amount Proposed must be greater than 0',
          (value) =>
            value !== undefined &&
            value !== null &&
            getParsedPriceInputValue(value) > 0,
        ),
    }),
  estimatedCloseDate: yup
    .date()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .typeError('Invalid date format. (dd/mm/yyyy)')
    .test('format', 'Invalid date format. (dd/mm/yyyy)', (value: any) =>
      value !== undefined && value !== null
        ? value.getFullYear() <= 9999
        : true,
    ),
  // .when('dealEstimatedCloseDate', (value: any, field: any) =>
  //   isEmpty(value)
  //     ? field.min(
  //         moment().format('YYYY-MM-DD'),
  //         `Date must be ${moment().format('DD/MM/YYYY')} or later.`,
  //       )
  //     : field.min(
  //         moment(value).format('YYYY-MM-DD'),
  //         `Date must be ${moment(value).format('DD/MM/YYYY')} or later.`,
  //       ),
  // ),
  proposedValuation: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup
        .string()
        .nullable()
        .transform((v, o) => (o === '' ? null : v))
        .test(
          'positive',
          'Proposed valuation must be greater than 0',
          (value) =>
            value !== undefined &&
            value !== null &&
            getParsedPriceInputValue(value) > 0,
        ),
    }),
  companyPitchdeckLink: yup
    .string()
    .nullable()
    .when({
      is: (value: string) => !isEmpty(value),
      then: yup.string().matches(urlRegex, urlValidConstant),
    }),
  other: yup.string().nullable(),
  tags: yup.array().nullable().of(yup.string()),
  remark: yup.string().nullable(),
  companyOneLiner: yup.string().nullable(),
  companyBlurp: yup.string().nullable(),
});

export const CreateYCBatchSchema = yup.object().shape({
  name: yup.string().required(requiredConstant),
});
