import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import InvestorView from './InvestorView';

export const InvestorViewRoute: IRoute = {
  path: '/admin/investor-view/:id',
  component: InvestorView,
  layout: DashboardLayout,
  exact: true,
};
