import React, { useEffect, useState } from 'react';
import { Typography, MenuItem } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { find, get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAdminInvestorById,
  getInvestmentDocuments,
  updateInvestorByAdmin,
} from 'services';
import { Box, Tabs, TabPanel, Loader, Button, Dialog } from 'components';
import {
  Ornament,
  Twitter,
  LinkedIn,
  OtherLink,
  Crunchbase,
  Angellist,
} from 'assets';
import { handleGetAllDeal } from 'redux-modules/deals/Actions';
import history from 'common/utils/history';
import InvestorOverview from 'components/InvestorOverview';
import InvestorProcess from 'components/InvestorProcess';
import InvestorPayments from 'components/InvestorPayments';
import InvestorDocuments from 'components/InvestorDocuments';
import { RootState } from 'redux-modules/store/rootState';
import { formatAmount } from 'common/utils/helpers';

import styles from './styles';

const InvestorView = ({ match }: any) => {
  const classes = styles();
  const dispatch = useDispatch();

  const getTabValue = () => {
    if (history.location.search) {
      const search = history.location.search;
      const tabValue: any = new URLSearchParams(search).get('value') || 0;
      return Number(tabValue);
    }

    return Number(0);
  };

  const investorId: string = get(match, 'params.id');
  const [value, setValue] = useState<number>(getTabValue());
  const [selectedSPV, setSelectedSPV] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deals, setDeals] = useState<any>([]);
  const [investorDetail, setInvestorDetail] = useState<any>({});
  const [kycDetail, setKycDetail] = useState<any>({});
  const [investmentData, setInvestmentData] = useState<any>({});
  const [documentList, setDocumentList] = useState<any>([]);

  const { dealList } = useSelector(({ Deals }: RootState) => Deals);
  const { investorKyc, investmentDetail } = investorDetail;

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const onClickSPV = (spv: string) => {
    setSelectedSPV(spv);
  };

  const companyInfo = (investor: any) => {
    return (
      <Box className={classes.enquiryListhead}>
        <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
        <Box className={classes.enquiryListheadContent}>
          <Box>
            <AccountCircleIcon className={classes.iconStyleOne} />
          </Box>
          <Box className={classes.gridItemView}>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.labelFundUpHead}
            >
              {get(investor, 'name')}
              <Button
                isLoading={updatingStatus}
                disabled={updatingStatus}
                className={
                  !get(investorDetail, 'reviewed')
                    ? classes.activateButton
                    : classes.deActivateButton
                }
                name={
                  get(investorDetail, 'reviewed') ? 'DE-ACTIVATE' : 'ACTIVATE'
                }
                onClick={() => setDialogOpen(true)}
              />
              {get(investorDetail, 'reviewed') && (
                <Button
                  className={classes.onboardBtn}
                  name="Onboard"
                  disabled={updatingStatus}
                  onClick={() =>
                    history.push(`/admin/investor-onboard/${investorId}`)
                  }
                />
              )}
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              className={classes.labelStartUpText}
            >
              {get(investor, 'email')}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    dispatch(handleGetAllDeal());
  }, [handleGetAllDeal]);

  useEffect(() => {
    setIsLoading(true);
    getAdminInvestorById(investorId)
      .then((res) => setInvestorDetail(res))
      .finally(() => setIsLoading(false));
  }, [investorId]);

  useEffect(() => {
    if (!isEmpty(investmentDetail) && !isEmpty(dealList)) {
      const newDeals = investmentDetail.map((investment: any) => {
        const deal = find(dealList, { id: get(investment, 'spv') });
        if (deal) {
          return {
            ...deal,
            investmentAmount: get(investment, 'investmentAmount'),
          };
        }
      });
      setDeals(newDeals);
    }
  }, [investmentDetail, dealList]);

  useEffect(() => {
    if (!isEmpty(selectedSPV)) {
      const kycData = investorKyc?.find((kyc: any) => kyc.spv === selectedSPV);
      const investment = investmentDetail?.find(
        (investment: any) =>
          investment.spv === selectedSPV &&
          investment.kycId === get(kycData, 'id'),
      );
      setKycDetail(kycData);
      setInvestmentData(investment);
    }
  }, [selectedSPV]);

  useEffect(() => {
    if (!isEmpty(get(investmentData, 'id')) && isEmpty(documentList)) {
      setIsLoading(true);
      getInvestmentDocuments({
        id: get(investmentData, 'id'),
      })
        .then((res) => setDocumentList(res))
        .finally(() => setIsLoading(false));
    }
  }, [investmentData]);

  const changeInvestorStatus = () => {
    const req: any = { ...investorDetail };

    req.reviewed = !req.reviewed;
    req.notifyInvestor = true;
    setUpdatingStatus(true);
    updateInvestorByAdmin(req)
      .then(() => {
        setInvestorDetail({ ...req });
        setDialogOpen(false);
      })
      .finally(() => setUpdatingStatus(false));
  };

  return (
    <>
      <Box className={classes.enquiryViewContainer}>
        {isLoading && <Loader />}
        {!isLoading && (
          <Box className={classes.enquiryViewBox}>
            <Box className={classes.enquiryViewBoxLeft}>
              {companyInfo(investorDetail)}
              {isEmpty(selectedSPV) ? (
                <>
                  {isEmpty(deals) && (
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.investmentNotFoundTxt}
                    >
                      Investor has not invested in any of deal.
                    </Typography>
                  )}
                  <Box className={classes.dealsBox}>
                    {deals.map((deal: any) => (
                      <Box
                        key={get(deal, 'id')}
                        className={classes.dealBox}
                        onClick={() => onClickSPV(get(deal, 'id'))}
                      >
                        <Typography className={classes.spvText} variant="h4">
                          YC {get(deal, 'deal')}
                        </Typography>
                        <Typography
                          className={classes.spvSubText}
                          variant="body1"
                        >
                          {formatAmount(get(deal, 'investmentAmount'))}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </>
              ) : (
                <Box className={classes.enquiryViewTabBox}>
                  <KeyboardBackspaceIcon
                    className={classes.backArrow}
                    onClick={() => {
                      setSelectedSPV('');
                      setDocumentList([]);
                      setValue(0);
                    }}
                  />
                  <Tabs
                    tabs={[
                      { name: 'Overview' },
                      { name: 'Process' },
                      { name: 'Documents' },
                      { name: 'Payments' },
                    ]}
                    value={value}
                    handleChange={handleChange}
                    className={classes.enquiryTabs}
                  />
                  <TabPanel
                    value={value}
                    index={0}
                    className={classes.tanPanelBox}
                  >
                    <InvestorOverview
                      investor={investorDetail}
                      spv={selectedSPV}
                      kycDetail={kycDetail}
                      investmentData={investmentData}
                    />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={1}
                    className={classes.tanPanelBox}
                  >
                    <InvestorProcess
                      investor={investorDetail}
                      spv={selectedSPV}
                      kycDetail={kycDetail}
                      investmentData={investmentData}
                      setInvestmentData={setInvestmentData}
                      documents={documentList}
                      setDocumentList={setDocumentList}
                    />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={2}
                    className={classes.tanPanelBox}
                  >
                    <InvestorDocuments documentList={documentList} />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={3}
                    className={classes.tabPaymentBox}
                  >
                    <InvestorPayments
                      investor={investorDetail}
                      spv={selectedSPV}
                      kycDetail={kycDetail}
                    />
                  </TabPanel>
                </Box>
              )}
            </Box>
            <Box className={classes.enquiryViewBoxRight}>
              <Box className={classes.contentBoxRight}>
                {/* <Typography
                variant="body1"
                gutterBottom
                className={classes.labelStartUpText}
              >
                YC Batch
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.labelStartUp}
              >
                {ycBatch}
              </Typography> */}
                {/* <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.labelStartUpText}
                >
                  Company Website
                </Typography>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.labelStartUpUrl}
                >
                  {'--'}
                </Typography> */}
                {(get(investorDetail, 'linkedinUrl') ||
                  get(investorDetail, 'crunchbaseUrl') ||
                  get(investorDetail, 'twitterUrl') ||
                  get(investorDetail, 'anglelistUrl') ||
                  get(investorDetail, 'otherUrl')) && (
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.labelStartUpText}
                  >
                    Social Media URL’s
                  </Typography>
                )}
                {get(investorDetail, 'linkedinUrl') && (
                  <MenuItem
                    className={classes.menuItemText}
                    onClick={() =>
                      window.open(get(investorDetail, 'linkedinUrl'), '_blank')
                    }
                  >
                    <img src={LinkedIn} alt={LinkedIn} />
                  </MenuItem>
                )}
                {get(investorDetail, 'twitterUrl') && (
                  <MenuItem
                    className={classes.menuItemText}
                    onClick={() =>
                      window.open(get(investorDetail, 'twitterUrl'), '_blank')
                    }
                  >
                    <img src={Twitter} alt={Twitter} />
                  </MenuItem>
                )}
                {get(investorDetail, 'crunchbaseUrl') && (
                  <MenuItem
                    className={classes.menuItemText}
                    onClick={() =>
                      window.open(
                        get(investorDetail, 'crunchbaseUrl'),
                        '_blank',
                      )
                    }
                  >
                    <img src={Crunchbase} alt={Crunchbase} />
                  </MenuItem>
                )}
                {get(investorDetail, 'anglelistUrl') && (
                  <MenuItem
                    className={classes.menuItemText}
                    onClick={() =>
                      window.open(get(investorDetail, 'anglelistUrl'), '_blank')
                    }
                  >
                    <img src={Angellist} alt={Angellist} />
                  </MenuItem>
                )}
                {get(investorDetail, 'otherUrl') && (
                  <MenuItem
                    className={classes.menuItemText}
                    onClick={() =>
                      window.open(get(investorDetail, 'otherUrl'), '_blank')
                    }
                  >
                    <img src={OtherLink} alt={OtherLink} />
                  </MenuItem>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Dialog
        open={dialogOpen}
        maxWidth={'sm'}
        // className={classes.addUserModel}
        title="Update Investor status"
        handleClose={() => setDialogOpen(false)}
        dialogAction={true}
        dialogActionChildren={
          <Box className={classes.backContinueBox}>
            <Button
              type="submit"
              name="OK"
              isLoading={updatingStatus}
              onClick={() => changeInvestorStatus()}
              className={classes.btnSubmit}
              disabled={updatingStatus}
            />
            <Button
              className={classes.backBtn}
              onClick={() => setDialogOpen(false)}
              name="Cancel"
            />
          </Box>
        }
      >
        <Typography variant="h5" className={classes.overViewSubHeadTitle}>
          Are you sure you want to{' '}
          {get(investorDetail, 'reviewed') ? 'Unactivate' : 'Activate'} the
          investor?
        </Typography>
      </Dialog>
    </>
  );
};

export default InvestorView;
