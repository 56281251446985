export const businessOpsCountryList = [
  { country: 'United States of America', countryCode: 'US' },
  { country: 'India', countryCode: 'IN' },
  { country: 'Africa', countryCode: 'AFRICA' },
  { country: 'Algeria', countryCode: 'DZ' },
  { country: 'Argentina', countryCode: 'AR' },
  { country: 'Asia', countryCode: 'ASIA' },
  { country: 'Australia', countryCode: 'AU' },
  { country: 'Austria', countryCode: 'AT' },
  { country: 'Belarus', countryCode: 'BY' },
  { country: 'Belgium', countryCode: 'BE' },
  { country: 'Brazil', countryCode: 'BR' },
  { country: 'Canada', countryCode: 'CA' },
  { country: 'Cayman Islands', countryCode: 'KY' },
  { country: 'Chile', countryCode: 'CL' },
  { country: 'China', countryCode: 'CN' },
  { country: 'Colombia', countryCode: 'CO' },
  { country: "Cote D'Ivoire (Ivory Coast)", countryCode: 'CI' },
  { country: 'Croatia (Hrvatska)', countryCode: 'HR' },
  { country: 'Cyprus', countryCode: 'CY' },
  { country: 'Denmark', countryCode: 'DK' },
  { country: 'Ecuador', countryCode: 'EC' },
  { country: 'Egypt', countryCode: 'EG' },
  { country: 'Estonia', countryCode: 'EE' },
  { country: 'Ethiopia', countryCode: 'ET' },
  { country: 'Europe', countryCode: 'EUROPE' },
  { country: 'Finland', countryCode: 'FI' },
  { country: 'France', countryCode: 'FR' },
  { country: 'Georgia', countryCode: 'GE' },
  { country: 'Germany', countryCode: 'DE' },
  { country: 'Ghana', countryCode: 'GH' },
  { country: 'Hong Kong S.A.R.', countryCode: 'HK' },
  { country: 'Indonesia', countryCode: 'ID' },
  { country: 'Ireland', countryCode: 'IE' },
  { country: 'Israel', countryCode: 'IL' },
  { country: 'Italy', countryCode: 'IT' },
  { country: 'Kenya', countryCode: 'KE' },
  { country: 'Latam', countryCode: 'LATAM' },
  { country: 'Lithuania', countryCode: 'LT' },
  { country: 'Malaysia', countryCode: 'MY' },
  { country: 'Malta', countryCode: 'MT' },
  { country: 'Mexico', countryCode: 'MX' },
  { country: 'Middle east', countryCode: 'MIDDLE_EAST' },
  { country: 'Morocco', countryCode: 'MA' },
  { country: 'Netherlands', countryCode: 'NL' },
  { country: 'Nigeria', countryCode: 'NG' },
  { country: 'North America', countryCode: 'NORTH_AMERICA' },
  { country: 'Norway', countryCode: 'NO' },
  { country: 'Pakistan', countryCode: 'PK' },
  { country: 'Panama', countryCode: 'PA' },
  { country: 'Peru', countryCode: 'PE' },
  { country: 'Philippines', countryCode: 'PH' },
  { country: 'Poland', countryCode: 'PL' },
  { country: 'Romania', countryCode: 'RO' },
  { country: 'Singapore', countryCode: 'SG' },
  { country: 'Slovenia', countryCode: 'SI' },
  { country: 'South Africa', countryCode: 'ZA' },
  { country: 'South Korea', countryCode: 'KR' },
  { country: 'Southeast Asia', countryCode: 'SOUTHE_ASIA' },
  { country: 'Spain', countryCode: 'ES' },
  { country: 'Sweden', countryCode: 'SE' },
  { country: 'Switzerland', countryCode: 'CH' },
  { country: 'Tanzania', countryCode: 'TZ' },
  { country: 'United Arab Emirates', countryCode: 'AE' },
  { country: 'United Kingdom', countryCode: 'GB' },
  { country: 'Ukraine', countryCode: 'UA' },
  { country: 'Venezuela', countryCode: 'VE' },
  { country: 'Vietnam', countryCode: 'VN' },
  { country: 'Zambia', countryCode: 'ZM' },
];

export const incorpCountryList = [
  { country: 'NA', countryCode: 'NOT_AVAILABLE' },
  { country: 'United States of America', countryCode: 'US' },
  { country: 'India', countryCode: 'IN' },
  { country: 'Algeria', countryCode: 'DZ' },
  { country: 'Argentina', countryCode: 'AR' },
  { country: 'Australia', countryCode: 'AU' },
  { country: 'Austria', countryCode: 'AT' },
  { country: 'Belarus', countryCode: 'BY' },
  { country: 'Belgium', countryCode: 'BE' },
  { country: 'Brazil', countryCode: 'BR' },
  { country: 'Canada', countryCode: 'CA' },
  { country: 'Cayman Islands', countryCode: 'KY' },
  { country: 'Chile', countryCode: 'CL' },
  { country: 'China', countryCode: 'CN' },
  { country: 'Colombia', countryCode: 'CO' },
  { country: "Cote D'Ivoire (Ivory Coast)", countryCode: 'CI' },
  { country: 'Croatia (Hrvatska)', countryCode: 'HR' },
  { country: 'Cyprus', countryCode: 'CY' },
  { country: 'Denmark', countryCode: 'DK' },
  { country: 'Ecuador', countryCode: 'EC' },
  { country: 'Egypt', countryCode: 'EG' },
  { country: 'Estonia', countryCode: 'EE' },
  { country: 'Ethiopia', countryCode: 'ET' },
  { country: 'Finland', countryCode: 'FI' },
  { country: 'France', countryCode: 'FR' },
  { country: 'Georgia', countryCode: 'GE' },
  { country: 'Germany', countryCode: 'DE' },
  { country: 'Ghana', countryCode: 'GH' },
  { country: 'Hong Kong S.A.R.', countryCode: 'HK' },
  { country: 'Indonesia', countryCode: 'ID' },
  { country: 'Ireland', countryCode: 'IE' },
  { country: 'Israel', countryCode: 'IL' },
  { country: 'Italy', countryCode: 'IT' },
  { country: 'Kenya', countryCode: 'KE' },
  { country: 'Lithuania', countryCode: 'LT' },
  { country: 'Malaysia', countryCode: 'MY' },
  { country: 'Malta', countryCode: 'MT' },
  { country: 'Mexico', countryCode: 'MX' },
  { country: 'Morocco', countryCode: 'MA' },
  { country: 'Netherlands', countryCode: 'NL' },
  { country: 'Nigeria', countryCode: 'NG' },
  { country: 'Norway', countryCode: 'NO' },
  { country: 'Pakistan', countryCode: 'PK' },
  { country: 'Panama', countryCode: 'PA' },
  { country: 'Peru', countryCode: 'PE' },
  { country: 'Philippines', countryCode: 'PH' },
  { country: 'Poland', countryCode: 'PL' },
  { country: 'Romania', countryCode: 'RO' },
  { country: 'Singapore', countryCode: 'SG' },
  { country: 'Slovenia', countryCode: 'SI' },
  { country: 'South Africa', countryCode: 'ZA' },
  { country: 'South Korea', countryCode: 'KR' },
  { country: 'Spain', countryCode: 'ES' },
  { country: 'Sweden', countryCode: 'SE' },
  { country: 'Switzerland', countryCode: 'CH' },
  { country: 'Tanzania', countryCode: 'TZ' },
  { country: 'United Arab Emirates', countryCode: 'AE' },
  { country: 'United Kingdom', countryCode: 'GB' },
  { country: 'Ukraine', countryCode: 'UA' },
  { country: 'Venezuela', countryCode: 'VE' },
  { country: 'Vietnam', countryCode: 'VN' },
  { country: 'Zambia', countryCode: 'ZM' },
];

export const acceleratorList = [
  { text: 'YC', value: 'YC' },
  { text: 'Other', value: 'OTHER' },
  { text: 'None', value: 'NONE' },
];

export const frequencyList = [
  { text: 'Monthly', value: 1 },
  { text: 'Quarterly', value: 3 },
  { text: 'Semi-annually', value: 6 },
  { text: 'Annually', value: 12 },
];

export const incorporationCountryList = [
  { country: 'United States of America', countryCode: 'US' },
  { country: 'Canada', countryCode: 'CA' },
  { country: 'Singapore', countryCode: 'SG' },
  { country: 'Cayman Islands', countryCode: 'KY' },
  { country: 'Pending Incorporation', countryCode: 'PENDING_INCORPORATION' },
];

// For: STARTUP Funding Stage
export const stageList = [
  { text: 'Pre-seed', value: 'Pre-seed' },
  { text: 'Seed', value: 'Seed' },
  { text: 'Seed Extension', value: 'Seed Extension' },
  { text: 'Pre-Series A', value: 'Pre-Series A' },
  { text: 'Series A', value: 'Series A' },
  { text: 'Series B', value: 'Series B' },
  { text: 'Series C', value: 'Series C' },
  { text: 'Series D', value: 'Series D' },
  { text: 'Series E', value: 'Series E' },
  { text: 'Series F', value: 'Series F' },
];

//For: DEALS
export const fundingRoundStageList = [
  { text: 'Pre-seed', value: 'Pre-seed' },
  { text: 'Seed', value: 'Seed' },
  { text: 'Seed Extension', value: 'Seed Extension' },
  { text: 'Pre-Series A', value: 'Pre-Series A' },
  { text: 'Series A', value: 'Series A' },
  { text: 'Series B', value: 'Series B' },
  { text: 'Series C', value: 'Series C' },
  { text: 'Series D', value: 'Series D' },
  { text: 'Series E', value: 'Series E' },
  { text: 'Series F', value: 'Series F' },
];

export const businessModalList = [
  { text: 'B2B SaaS', value: 'B2B SaaS' },
  { text: 'Enterprise', value: 'Enterprise' },
  { text: 'Usage-based', value: 'Usage-based' },
  { text: 'Subscription (Consumer)', value: 'Subscription (Consumer)' },
  { text: 'Transactional', value: 'Transactional' },
  {
    text: 'Marketplace',
    value: 'Marketplace',
  },
  { text: 'e-Commerce', value: 'e-Commerce' },
  { text: 'Advertising', value: 'Advertising' },
  {
    text: 'Hardware/Healthcare/Deep Tech',
    value: 'Hardware/Healthcare/Deep Tech',
  },
];

export const industryTypeList = [
  { text: 'B2B Software and Services', value: 'B2B Software and Services' },
  { text: 'Consumer', value: 'Consumer' },
  { text: 'Education', value: 'Education' },
  { text: 'Financial Technology', value: 'Financial Technology' },
  { text: 'Healthcare', value: 'Healthcare' },
  { text: 'Industrials', value: 'Industrials' },
  {
    text: 'Real Estate and Construction',
    value: 'Real Estate and Construction',
  },
  { text: 'Government', value: 'Government' },
];

export const industryClassificationList = [
  {
    industryType: 'B2B Software and Services',
    industrySubCategory: [
      {
        text: 'Analytics',
        value: 'Analytics',
      },
      {
        text: 'Education',
        value: 'Education',
      },
      {
        text: 'Engineering, Product and Design',
        value: 'Engineering, Product and Design',
      },
      {
        text: 'Finance and Accounting',
        value: 'Finance and Accounting',
      },
      {
        text: 'Human Resources',
        value: 'Human Resources',
      },
      {
        text: 'Infrastructure',
        value: 'Infrastructure',
      },
      {
        text: 'Legal',
        value: 'Legal',
      },
      {
        text: 'Marketing',
        value: 'Marketing',
      },
      {
        text: 'Office Management',
        value: 'Office Management',
      },
      {
        text: 'Operations',
        value: 'Operations',
      },
      {
        text: 'Productivity',
        value: 'Productivity',
      },
      {
        text: 'Recruiting and Talent',
        value: 'Recruiting and Talent',
      },
      {
        text: 'Retail',
        value: 'Retail',
      },
      {
        text: 'Sales',
        value: 'Sales',
      },
      {
        text: 'Security',
        value: 'Security',
      },
      {
        text: 'Supply Chain and Logistics',
        value: 'Supply Chain and Logistics',
      },
    ],
  },
  {
    industryType: 'Consumer',
    industrySubCategory: [
      {
        text: 'Apparel and Cosmetics',
        value: 'Apparel and Cosmetics',
      },
      {
        text: 'Consumer Electronics',
        value: 'Consumer Electronics',
      },
      { text: 'Content', value: 'Content' },
      {
        text: 'Food and Beverage',
        value: 'Food and Beverage',
      },
      { text: 'Gaming', value: 'Gaming' },
      {
        text: 'Home and Personal',
        value: 'Home and Personal',
      },
      {
        text: 'Job and Career Services',
        value: 'Job and Career Services',
      },
      { text: 'Social', value: 'Social' },
      {
        text: 'Transportation Services',
        value: 'Transportation Services',
      },
      {
        text: 'Travel, Leisure and Tourism',
        value: 'Travel, Leisure and Tourism',
      },
      {
        text: 'Virtual and Augmented Reality',
        value: 'Virtual and Augmented Reality',
      },
    ],
  },
  {
    industryType: 'Education',
    industrySubCategory: [
      {
        text: 'NA',
        value: 'NA',
      },
    ],
  },
  {
    industryType: 'Financial Technology',
    industrySubCategory: [
      {
        text: 'Asset Management',
        value: 'Asset Management',
      },
      {
        text: 'Banking and Exchange',
        value: 'Banking and Exchange',
      },
      {
        text: 'Consumer Finance',
        value: 'Consumer Finance',
      },
      {
        text: 'Credit and Lending',
        value: 'Credit and Lending',
      },
      {
        text: 'Insurance',
        value: 'Insurance',
      },
      {
        text: 'Payments',
        value: 'Payments',
      },
    ],
  },
  {
    industryType: 'Healthcare',
    industrySubCategory: [
      {
        text: 'Consumer Health and Wellness',
        value: 'Consumer Health and Wellness',
      },
      {
        text: 'Diagnostics',
        value: 'Diagnostics',
      },
      {
        text: 'Drug Discovery and Delivery',
        value: 'Drug Discovery and Delivery',
      },
      {
        text: 'Healthcare IT',
        value: 'Healthcare IT',
      },
      {
        text: 'Healthcare Services',
        value: 'Healthcare Services',
      },
      {
        text: 'Industrial Bio',
        value: 'Industrial Bio',
      },
      {
        text: 'Medical Devices',
        value: 'Medical Devices',
      },
      {
        text: 'Therapeutics',
        value: 'Therapeutics',
      },
    ],
  },
  {
    industryType: 'Industrials',
    industrySubCategory: [
      {
        text: 'Agriculture',
        value: 'Agriculture',
      },
      {
        text: 'Automotive',
        value: 'Automotive',
      },
      {
        text: 'Aviation and Space',
        value: 'Aviation and Space',
      },
      {
        text: 'Climate',
        value: 'Climate',
      },
      {
        text: 'Drones',
        value: 'Drones',
      },
      {
        text: 'Energy',
        value: 'Energy',
      },
      {
        text: 'Manufacturing and Robotics',
        value: 'Manufacturing and Robotics',
      },
    ],
  },
  {
    industryType: 'Real Estate and Construction',
    industrySubCategory: [
      {
        text: 'Construction',
        value: 'Construction',
      },
      {
        text: 'Housing and Real Estate',
        value: 'Housing and Real Estate',
      },
    ],
  },
  {
    industryType: 'Government',
    industrySubCategory: [
      {
        text: 'NA',
        value: 'NA',
      },
    ],
  },
];

export const sectorList = [
  {
    category: 'B2B Software and Services',
    title: 'Analytics',
    value: 'B2B Software and Services###Analytics',
  },
  {
    category: 'B2B Software and Services',
    title: 'Education',
    value: 'B2B Software and Services###Education',
  },
  {
    category: 'B2B Software and Services',
    title: 'Engineering, Product and Design',
    value: 'B2B Software and Services###Engineering, Product and Design',
  },
  {
    category: 'B2B Software and Services',
    title: 'Finance and Accounting',
    value: 'B2B Software and Services###Finance and Accounting',
  },
  {
    category: 'B2B Software and Services',
    title: 'Human Resources',
    value: 'B2B Software and Services###Human Resources',
  },
  {
    category: 'B2B Software and Services',
    title: 'Infrastructure',
    value: 'B2B Software and Services###Infrastructure',
  },
  {
    category: 'B2B Software and Services',
    title: 'Legal',
    value: 'B2B Software and Services###Legal',
  },
  {
    category: 'B2B Software and Services',
    title: 'Marketing',
    value: 'B2B Software and Services###Marketing',
  },
  {
    category: 'B2B Software and Services',
    title: 'Office Management',
    value: 'B2B Software and Services###Office Management',
  },
  {
    category: 'B2B Software and Services',
    title: 'Operations',
    value: 'B2B Software and Services###Operations',
  },
  {
    category: 'B2B Software and Services',
    title: 'Productivity',
    value: 'B2B Software and Services###Productivity',
  },
  {
    category: 'B2B Software and Services',
    title: 'Recruiting and Talent',
    value: 'B2B Software and Services###Recruiting and Talent',
  },
  {
    category: 'B2B Software and Services',
    title: 'Retail',
    value: 'B2B Software and Services###Retail',
  },
  {
    category: 'B2B Software and Services',
    title: 'Sales',
    value: 'B2B Software and Services###Sales',
  },
  {
    category: 'B2B Software and Services',
    title: 'Security',
    value: 'B2B Software and Services###Security',
  },
  {
    category: 'B2B Software and Services',
    title: 'Supply Chain and Logistics',
    value: 'B2B Software and Services###Supply Chain and Logistics',
  },
  {
    category: 'Consumer',
    title: 'Apparel and Cosmetics',
    value: 'Consumer###Apparel and Cosmetics',
  },
  {
    category: 'Consumer',
    title: 'Consumer Electronics',
    value: 'Consumer###Consumer Electronics',
  },
  { category: 'Consumer', title: 'Content', value: 'Consumer###Content' },
  {
    category: 'Consumer',
    title: 'Food and Beverage',
    value: 'Consumer###Food and Beverage',
  },
  { category: 'Consumer', title: 'Gaming', value: 'Consumer###Gaming' },
  {
    category: 'Consumer',
    title: 'Home and Personal',
    value: 'Consumer###Home and Personal',
  },
  {
    category: 'Consumer',
    title: 'Job and Career Services',
    value: 'Consumer###Job and Career Services',
  },
  { category: 'Consumer', title: 'Social', value: 'Consumer###Social' },
  {
    category: 'Consumer',
    title: 'Transportation Services',
    value: 'Consumer###Transportation Services',
  },
  {
    category: 'Consumer',
    title: 'Travel, Leisure and Tourism',
    value: 'Consumer###Travel, Leisure and Tourism',
  },
  {
    category: 'Consumer',
    title: 'Virtual and Augmented Reality',
    value: 'Consumer###Virtual and Augmented Reality',
  },
  {
    category: 'Healthcare',
    title: 'Consumer Health and Wellness',
    value: 'Healthcare###Consumer Health and Wellness',
  },
  {
    category: 'Healthcare',
    title: 'Diagnostics',
    value: 'Healthcare###Diagnostics',
  },
  {
    category: 'Healthcare',
    title: 'Drug Discovery and Delivery',
    value: 'Healthcare###Drug Discovery and Delivery',
  },
  {
    category: 'Healthcare',
    title: 'Healthcare IT',
    value: 'Healthcare###Healthcare IT',
  },
  {
    category: 'Healthcare',
    title: 'Healthcare Services',
    value: 'Healthcare###Healthcare Services',
  },
  {
    category: 'Healthcare',
    title: 'Industrial Bio',
    value: 'Healthcare###Industrial Bio',
  },
  {
    category: 'Healthcare',
    title: 'Medical Devices',
    value: 'Healthcare###Medical Devices',
  },
  {
    category: 'Healthcare',
    title: 'Therapeutics',
    value: 'Healthcare###Therapeutics',
  },
  {
    category: 'Financial Technology',
    title: 'Asset Management',
    value: 'Financial Technology###Asset Management',
  },
  {
    category: 'Financial Technology',
    title: 'Banking and Exchange',
    value: 'Financial Technology###Banking and Exchange',
  },
  {
    category: 'Financial Technology',
    title: 'Consumer Finance',
    value: 'Financial Technology###Consumer Finance',
  },
  {
    category: 'Financial Technology',
    title: 'Credit and Lending',
    value: 'Financial Technology###Credit and Lending',
  },
  {
    category: 'Financial Technology',
    title: 'Insurance',
    value: 'Financial Technology###Insurance',
  },
  {
    category: 'Financial Technology',
    title: 'Payments',
    value: 'Financial Technology###Payments',
  },
  {
    category: 'Real Estate and Construction',
    title: 'Construction',
    value: 'Real Estate and Construction###Construction',
  },
  {
    category: 'Real Estate and Construction',
    title: 'Housing and Real Estate',
    value: 'Real Estate and Construction###Housing and Real Estate',
  },
  {
    category: 'Industrials',
    title: 'Agriculture',
    value: 'Industrials###Agriculture',
  },
  {
    category: 'Industrials',
    title: 'Automotive',
    value: 'Industrials###Automotive',
  },
  {
    category: 'Industrials',
    title: 'Aviation and Space',
    value: 'Industrials###Aviation and Space',
  },
  { category: 'Industrials', title: 'Climate', value: 'Industrials###Climate' },
  { category: 'Industrials', title: 'Drones', value: 'Industrials###Drones' },
  { category: 'Industrials', title: 'Energy', value: 'Industrials###Energy' },
  {
    category: 'Industrials',
    title: 'Manufacturing and Robotics',
    value: 'Industrials###Manufacturing and Robotics',
  },
];

export const loanTenureOptions = [
  { text: '6 Months', value: '6' },
  { text: '9 Months', value: '9' },
  { text: '12 Months', value: '12' },
];

export const cashIntrestRateOptions = [
  { text: '12 %', value: '12.00' },
  { text: '15 %', value: '15.00' },
];

export const KPIsList = [
  {
    businessModal: 'B2B SaaS',
    kpiList: [
      {
        kpiName: 'MRR',
        kpiDef:
          'Revenue recognized for recurring services rendered in a given month (does not include one time, or non recurring, revenue such as fees and professional services revenue)',
        cmgrKpiName: 'MRR Monthly Growth',
        cmgrKpiDef:
          'Implied compounded monthly MRR growth rate between two disparate months [CMGR = (last month MRR/first month MRR)^(1/# of months)-1]',
        isPrimary: true,
      },
      {
        kpiName: 'ARR',
        kpiDef:
          'Measure of revenue components that are recurring in nature on an annual basis (ARR = MRR*12)',
        cmgrKpiName: 'ARR',
        cmgrKpiDef:
          'Measure of revenue components that are recurring in nature on an annual basis (ARR = MRR*12)',
      },
      {
        kpiName: 'Paid CAC',
        kpiDef:
          'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
        cmgrKpiName: 'Paid CAC',
        cmgrKpiDef:
          'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
      },
    ],
  },
  {
    businessModal: 'Enterprise',
    kpiList: [
      {
        kpiName: 'Monthly Revenue',
        kpiDef: 'Total revenue in a given month',
        cmgrKpiName: 'Monthly Revenue',
        cmgrKpiDef: 'Total revenue in a given month',
        isPrimary: true,
      },
      {
        kpiName: 'Booked Revenue',
        kpiDef:
          'Sum of all values of customer contracts (Letters of intent & verbal agreements are not bookings)',
        cmgrKpiName: 'Booked Revenue CMGR',
        cmgrKpiDef:
          'Implied compounded monthly revenue growth rate between two disparate months [CMGR = (last month revenue/first month revenue)^(1/# of months)-1]',
      },
      {
        kpiName: 'Total Customers',
        kpiDef: 'Total number of unique contracted customers today',
        cmgrKpiName: 'Total Customers',
        cmgrKpiDef: 'Total number of unique contracted customers today',
      },
    ],
  },
  {
    businessModal: 'Usage-based',
    kpiList: [
      {
        kpiName: 'Monthly Revenue',
        kpiDef: 'Total revenue in a given month',
        cmgrKpiName: 'Revenue CMGR',
        cmgrKpiDef:
          'Implied compounded monthly revenue growth rate between two disparate months [CMGR = (last month revenue/first month revenue)^(1/# of months)-1]',
        isPrimary: true,
      },
      {
        kpiName: 'Gross Margin',
        kpiDef:
          'Gross profit in a given month / total revenue in the same month (gross profit = total revenue less cost of sales and goods sold)',
        cmgrKpiName: 'Gross Margin',
        cmgrKpiDef:
          'Gross profit in a given month / total revenue in the same month (gross profit = total revenue less cost of sales and goods sold)',
      },
    ],
  },
  {
    businessModal: 'Subscription (Consumer)',
    kpiList: [
      {
        kpiName: 'Monthly Revenue',
        kpiDef: 'Total revenue in a given month',
        cmgrKpiName: 'Revenue CMGR',
        cmgrKpiDef:
          'Implied compounded monthly revenue growth rate between two disparate months [CMGR = (last month revenue/first month revenue)^(1/# of months)-1]',
        isPrimary: true,
      },
      {
        kpiName: 'Gross Margin',
        kpiDef:
          'Gross profit in a given month / total revenue in the same month (gross profit = total revene less cost of sales and goods sold)',
        cmgrKpiName: 'Gross Margin',
        cmgrKpiDef:
          'Gross profit in a given month / total revenue in the same month (gross profit = total revene less cost of sales and goods sold)',
      },
    ],
  },
  {
    businessModal: 'Transactional',
    kpiList: [
      {
        kpiName: 'Monthly Revenue',
        kpiDef: 'Total revenue in a given month',
        cmgrKpiName: 'Monthly Revenue',
        cmgrKpiDef: 'Total revenue in a given month',
        isPrimary: true,
      },
      // {
      //   kpiName: 'Net Revenue',
      //   kpiDef:
      //     'The portion of GTV that the company recognizes as revenue for service rendered',
      //   cmgrKpiName: 'Net Revenue CMGR',
      //   cmgrKpiDef:
      //     'Implied compounded monthly Net revenue growth rate between two disparate months [CMGR = (last month net revenue/first month net revenue)^(1/# of months)-1]',
      //   isPrimary: true,
      // },
      {
        kpiName: 'GTV',
        kpiDef:
          'Total sales or payment dollar volume transacted in a given period',
        cmgrKpiName: 'GTV',
        cmgrKpiDef:
          'Total sales or payment dollar volume transacted in a given period',
      },
      {
        kpiName: 'Paid CAC',
        kpiDef:
          'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
        cmgrKpiName: 'Paid CAC',
        cmgrKpiDef:
          'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
      },
    ],
  },
  {
    businessModal: 'Marketplace',
    kpiList: [
      {
        kpiName: 'Monthly Revenue',
        kpiDef: 'Total revenue in a given month',
        cmgrKpiName: 'Monthly Revenue',
        cmgrKpiDef: 'Total revenue in a given month',
        isPrimary: true,
      },
      // {
      //   kpiName: 'Net Revenue',
      //   kpiDef:
      //     'The portion of GMV that the company recognizes as revenue for service rendered',
      //   cmgrKpiName: 'Net Revenue CMGR',
      //   cmgrKpiDef:
      //     'Implied compounded monthly Net revenue growth rate between two disparate months [CMGR = (last month net revenue/first month net revenue)^(1/# of months)-1]',
      //   isPrimary: true,
      // },
      {
        kpiName: 'GMV',
        kpiDef:
          'Total sales dollar volume of merchandise transacted in a given period',
        cmgrKpiName: 'GMV',
        cmgrKpiDef:
          'Total sales dollar volume of merchandise transacted in a given period',
      },
      {
        kpiName: 'Paid CAC',
        kpiDef:
          'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
        cmgrKpiName: 'Paid CAC',
        cmgrKpiDef:
          'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
      },
    ],
  },
  {
    businessModal: 'e-Commerce',
    kpiList: [
      {
        kpiName: 'Monthly Revenue',
        kpiDef: 'Total revenue in a given month',
        cmgrKpiName: 'Revenue CMGR',
        cmgrKpiDef:
          'Implied compounded monthly revenue growth rate between two disparate months [CMGR = (last month revenue/first month revenue)^(1/# of months)-1]',
        isPrimary: true,
      },
      {
        kpiName: 'Gross Margin',
        kpiDef:
          'Gross profit in a given month / total revenue in the same month (gross profit = total revene less cost of sales and goods sold)',
        cmgrKpiName: 'Gross Margin',
        cmgrKpiDef:
          'Gross profit in a given month / total revenue in the same month (gross profit = total revene less cost of sales and goods sold)',
      },
      {
        kpiName: 'Paid CAC',
        kpiDef:
          'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
        cmgrKpiName: 'Paid CAC',
        cmgrKpiDef:
          'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
      },
    ],
  },
  {
    businessModal: 'Advertising',
    kpiList: [
      {
        kpiName: 'Monthly Revenue',
        kpiDef: 'Total revenue in a given month',
        cmgrKpiName: 'Monthly Revenue',
        cmgrKpiDef: 'Total revenue in a given month',
        isPrimary: true,
      },
      // {
      //   kpiName: 'DAU',
      //   kpiDef:
      //     'Total no of unique users active in 24hr day, averaged over a given period of time',
      //   cmgrKpiName: 'Avg. DAU Growth',
      //   cmgrKpiDef:
      //     'Total no of unique users active in 24hr day, averaged over a given period of time',
      //   isPrimary: true,
      // },
      {
        kpiName: 'MAU',
        kpiDef: 'Total no of unique users active at least once in last 28 days',
        cmgrKpiName: 'MAU',
        cmgrKpiDef:
          'Total no of unique users active at least once in last 28 days',
      },
      {
        kpiName: '% Logged in',
        kpiDef:
          'Total monthly active users with a registered account ("logged in") divided by the total unique visitors (inclusive of both logged in and logged out) during the same 28 days window.',
        cmgrKpiName: '% Logged in',
        cmgrKpiDef:
          'Total monthly active users with a registered account ("logged in") divided by the total unique visitors (inclusive of both logged in and logged out) during the same 28 days window.',
      },
    ],
  },
  {
    businessModal: 'Hardware/Healthcare/Deep Tech',
    kpiList: [
      {
        kpiName: 'Monthly Revenue',
        kpiDef: 'Total revenue in a given month',
        cmgrKpiName: 'Revenue CMGR',
        cmgrKpiDef:
          'Implied compounded monthly revenue growth rate between two disparate months [CMGR = (last month revenue/first month revenue)^(1/# of months)-1]',
        isPrimary: true,
      },
      {
        kpiName: 'Gross Margin',
        kpiDef:
          'Gross profit in a given month / total revenue in the same month (gross profit = total revene less cost of sales and goods sold)',
        cmgrKpiName: 'Gross Margin',
        cmgrKpiDef:
          'Gross profit in a given month / total revenue in the same month (gross profit = total revene less cost of sales and goods sold)',
      },
      {
        kpiName: 'Paid CAC',
        kpiDef:
          'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
        cmgrKpiName: 'Paid CAC',
        cmgrKpiDef:
          'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
      },
    ],
  },
];

export const KPIsListV2 = [
  {
    kpiName: 'MRR',
    kpiDef:
      'Revenue recognized for recurring services rendered in a given month (does not include one time, or non recurring, revenue such as fees and professional services revenue)',
    cmgrKpiName: 'MRR Monthly Growth',
    cmgrKpiDef:
      'Implied compounded monthly MRR growth rate between two disparate months [CMGR = (last month MRR/first month MRR)^(1/# of months)-1]',
    isPrimary: true,
  },
  {
    kpiName: 'ARR',
    kpiDef:
      'Measure of revenue components that are recurring in nature on an annual basis (ARR = MRR*12)',
    cmgrKpiName: 'ARR',
    cmgrKpiDef:
      'Measure of revenue components that are recurring in nature on an annual basis (ARR = MRR*12)',
    isPrimary: true,
  },
  {
    kpiName: 'Monthly Revenue',
    kpiDef: 'Total revenue in a given month',
    cmgrKpiName: 'Revenue CMGR',
    cmgrKpiDef:
      'Implied compounded monthly revenue growth rate between two disparate months [CMGR = (last month revenue/first month revenue)^(1/# of months)-1]',
    isPrimary: true,
  },
  {
    kpiName: 'Paid CAC',
    kpiDef:
      'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
    cmgrKpiName: 'Paid CAC',
    cmgrKpiDef:
      'Cost per customer acquired through paid marketing channels (total sales and marketing spend in a given month / total customer acquired via paid channels, including via sales in a given month)',
    isPrimary: false,
  },
  {
    kpiName: 'Booked Revenue',
    kpiDef:
      'Sum of all values of customer contracts (Letters of intent & verbal agreements are not bookings)',
    cmgrKpiName: 'Booked Revenue CMGR',
    cmgrKpiDef:
      'Implied compounded monthly revenue growth rate between two disparate months [CMGR = (last month revenue/first month revenue)^(1/# of months)-1]',
    isPrimary: false,
  },
  {
    kpiName: 'Total Customers',
    kpiDef: 'Total number of unique contracted customers today',
    cmgrKpiName: 'Total Customers',
    cmgrKpiDef: 'Total number of unique contracted customers today',
    isPrimary: false,
  },
  {
    kpiName: 'Gross Margin',
    kpiDef:
      'Gross profit in a given month / total revenue in the same month (gross profit = total revenue less cost of sales and goods sold)',
    cmgrKpiName: 'Gross Margin',
    cmgrKpiDef:
      'Gross profit in a given month / total revenue in the same month (gross profit = total revenue less cost of sales and goods sold)',
    isPrimary: false,
  },
  // {
  //   kpiName: 'Net Revenue',
  //   kpiDef:
  //     'The portion of GTV that the company recognizes as revenue for service rendered',
  //   cmgrKpiName: 'Net Revenue CMGR',
  //   cmgrKpiDef:
  //     'Implied compounded monthly Net revenue growth rate between two disparate months [CMGR = (last month net revenue/first month net revenue)^(1/# of months)-1]',
  //   isPrimary: true,
  // },
  {
    kpiName: 'GTV',
    kpiDef: 'Total sales or payment dollar volume transacted in a given period',
    cmgrKpiName: 'GTV',
    cmgrKpiDef:
      'Total sales or payment dollar volume transacted in a given period',
    isPrimary: false,
  },
  {
    kpiName: 'GMV',
    kpiDef:
      'Total sales dollar volume of merchandise transacted in a given period',
    cmgrKpiName: 'GMV',
    cmgrKpiDef:
      'Total sales dollar volume of merchandise transacted in a given period',
    isPrimary: false,
  },
  // {
  //   kpiName: 'DAU',
  //   kpiDef:
  //     'Total no of unique users active in 24hr day, averaged over a given period of time',
  //   cmgrKpiName: 'Avg. DAU Growth',
  //   cmgrKpiDef:
  //     'Total no of unique users active in 24hr day, averaged over a given period of time',
  //   isPrimary: true,
  // },
  {
    kpiName: 'MAU',
    kpiDef: 'Total no of unique users active at least once in last 28 days',
    cmgrKpiName: 'MAU',
    cmgrKpiDef: 'Total no of unique users active at least once in last 28 days',
    isPrimary: false,
  },
  {
    kpiName: '% Logged in',
    kpiDef:
      'Total monthly active users with a registered account ("logged in") divided by the total unique visitors (inclusive of both logged in and logged out) during the same 28 days window.',
    cmgrKpiName: '% Logged in',
    cmgrKpiDef:
      'Total monthly active users with a registered account ("logged in") divided by the total unique visitors (inclusive of both logged in and logged out) during the same 28 days window.',
    isPrimary: false,
  },
  {
    kpiName: 'Annualised GMV',
    kpiDef: 'Please use Annualized Gross Merchandise Value',
    cmgrKpiName: 'Annualised GMV',
    cmgrKpiDef: 'Please use Annualized Gross Merchandise Value',
    isPrimary: false,
  },
  {
    kpiName: 'Annualised GTV',
    kpiDef: 'Please use Annualized Gross Transaction Value',
    cmgrKpiName: 'Annualised GTV',
    cmgrKpiDef: 'Please use Annualized Gross Transaction Value',
    isPrimary: false,
  },
  {
    kpiName: 'Monthly Burn',
    kpiDef: 'The amount of money the company spends each month',
    cmgrKpiName: 'Monthly Burn',
    cmgrKpiDef: 'The amount of money the company spends each month',
    isPrimary: false,
  },
  {
    kpiName: 'Cash in Bank',
    kpiDef: 'Cash left in bank',
    cmgrKpiName: 'Cash in Bank',
    cmgrKpiDef: 'Cash left in bank',
    isPrimary: false,
  },
  {
    kpiName: 'Runway (mo)',
    kpiDef: 'Number of months a business has before it runs out of cash',
    cmgrKpiName: 'Runway (mo)',
    cmgrKpiDef: 'Number of months a business has before it runs out of cash',
    isPrimary: false,
  },
];

export const fundRaisingKpiType = [
  { text: 'Equity', value: 'EQUITY' },
  { text: 'Debt', value: 'DEBT' },
  { text: 'SAFE (Post Money)', value: 'SAFE_POST_MONEY' },
  { text: 'SAFE (Pre Money)', value: 'SAFE_PRE_MONEY' },
  // { text: 'SAFE', value: 'SAFE' },
  { text: 'Convertible', value: 'CONVERTIBLE' },
];

// For: YARDSTICK Fundraise Round
export const roundStageList = [
  { text: 'Pre-seed', value: 'Pre-seed' },
  { text: 'Seed', value: 'Seed' },
  { text: 'Seed Extension', value: 'Seed Extension' },
  { text: 'Pre-Series A', value: 'Pre-Series A' },
  { text: 'Series A', value: 'Series A' },
  { text: 'Series B', value: 'Series B' },
  { text: 'Series C', value: 'Series C' },
  { text: 'Series D', value: 'Series D' },
  { text: 'Series E', value: 'Series E' },
  { text: 'Series F', value: 'Series F' },
];

export const socialMediaOption: Array<any> = [
  { text: 'AngelList', value: 'angellistUrl' },
  { text: 'Crunchbase', value: 'crunchbaseUrl' },
  { text: 'Linkedin', value: 'linkedinUrl' },
  { text: 'Twitter', value: 'twitterUrl' },
  { text: 'Other', value: 'otherUrl' },
];

export const entityList: Array<any> = [
  { text: 'VC Fund', value: 'VC_FUND' },
  { text: 'Investment Advisor', value: 'INVESTMENT_ADVISOR' },
  { text: 'Hedge Fund', value: 'HEDGE_FUND' },
  { text: 'Pe Fund', value: 'PE_FUND' },
  { text: 'Bank', value: 'BANK' },
  { text: 'Insurance Company', value: 'INSURANCE_COMPANY' },
  { text: 'Angel Investor', value: 'ANGEL_INVESTOR' },
  { text: 'Family Office', value: 'FAMILY_OFFICE' },
  { text: 'AIF', value: 'AIF' },
  { text: 'FVCI', value: 'FVCI' },
  { text: 'NBFC', value: 'NBFC' },
  { text: 'Other', value: 'OTHER' },
];

export const fundRegisteredWithList: Array<any> = [
  { text: 'SEBI', value: 'SEBI' },
  { text: 'RBI', value: 'RBI' },
  { text: 'NABARD', value: 'NABARD' },
  { text: 'IRDAI', value: 'IRDAI' },
  { text: 'SIDBI', value: 'SIDBI' },
  { text: 'MAS', value: 'MAS' },
  { text: 'FSC', value: 'FSC' },
  { text: 'ROC', value: 'ROC' },
  { text: 'CIMA', value: 'CIMA' },
  { text: 'SEC', value: 'SEC' },
];

export const vcPortalCountryList: Array<any> = [
  { country: 'India', countryCode: 'IN' },
  { country: 'Mauritius', countryCode: 'MU' },
  { country: 'Cayman Islands', countryCode: 'KY' },
  { country: 'Singapore', countryCode: 'SG' },
  { country: 'United States of America', countryCode: 'US' },
];

// For: FCRM Round Name
export const fcrmRoundNameList = [
  { text: 'Pre-seed', value: 'PRE_SEED' },
  { text: 'Seed', value: 'SEED' },
  { text: 'Seed Extension', value: 'SEED_EXTENSION' },
  { text: 'Pre-Series A', value: 'PRE_SERIES_A' },
  { text: 'Series A', value: 'SERIES_A' },
  { text: 'Series B', value: 'SERIES_B' },
  { text: 'Series C', value: 'SERIES_C' },
  { text: 'Series D', value: 'SERIES_D' },
  { text: 'Series E', value: 'SERIES_E' },
  { text: 'Series F', value: 'SERIES_F' },
];

export const currencyList = [
  { text: 'United States Dollar (USD)', value: 'USD' },
  { text: 'Indian Rupee (INR)', value: 'INR' },
  { text: 'Singapore Dollar (SGD)', value: 'SGD' },
  { text: 'Canadian Dollar (CAD)', value: 'CAD' },
  { text: 'Cayman Islands Dollar (KYD)', value: 'KYD' },
  { text: 'Mauritian Rupee (MUR)', value: 'MUR' },
];

export const currencyListV2 = [
  { text: 'United States Dollar (USD)', value: 'USD' },
  { text: 'Indian Rupee (INR)', value: 'INR' },
  { text: 'The Euro (EUR)', value: 'EUR' },
  { text: 'Canadian Dollar (CAD)', value: 'CAD' },
  { text: 'Singapore Dollar (SGD)', value: 'SGD' },
  { text: 'Nigerian Naira (NGN)', value: 'NGN' },
  { text: 'Emirati Dirham (AED)', value: 'AED' },
  { text: 'Australian Dollar (AUD)', value: 'AUD' },
  { text: 'Brazilian Real (BRL)', value: 'BRL' },
  { text: 'Mexican Peso (MXN)', value: 'MXN' },
  { text: 'British Pound Sterling (GBP)', value: 'GBP' },

  // { text: 'Cayman Islands Dollar (KYD)', value: 'KYD' },
  // { text: 'Mauritian Rupee (MUR)', value: 'MUR' },
];

export const fcrmStage = [
  { text: 'Lead', value: 'LEAD' },
  { text: 'Contacted', value: 'CONTACTED' },
  { text: 'Meeting', value: 'MEETING' },
  { text: 'Due Diligence', value: 'DUE_DILIGENCE' },
  { text: 'Term Sheet', value: 'TERM_SHEET' },
  { text: 'Closed', value: 'CLOSED' },
];

export const fcrmStatus = [
  { text: 'New', value: 'NEW' },
  { text: 'Interested', value: 'INTERESTED' },
  { text: 'Pass', value: 'PASS' },
  { text: 'Won', value: 'WON' },
];

export const countryCodeList = [
  {
    text: '+1',
    value: '+1',
  },
  {
    text: '+7',
    value: '+7',
  },
  {
    text: '+20',
    value: '+20',
  },
  {
    text: '+27',
    value: '+27',
  },
  {
    text: '+30',
    value: '+30',
  },
  {
    text: '+31',
    value: '+31',
  },
  {
    text: '+32',
    value: '+32',
  },
  {
    text: '+33',
    value: '+33',
  },
  {
    text: '+36',
    value: '+36',
  },
  {
    text: '+39',
    value: '+39',
  },
  {
    text: '+40',
    value: '+40',
  },
  {
    text: '+41',
    value: '+41',
  },
  {
    text: '+43',
    value: '+43',
  },
  {
    text: '+44',
    value: '+44',
  },
  {
    text: '+45',
    value: '+45',
  },
  {
    text: '+46',
    value: '+46',
  },
  {
    text: '+47',
    value: '+47',
  },
  {
    text: '+49',
    value: '+49',
  },
  {
    text: '+51',
    value: '+51',
  },
  {
    text: '+53',
    value: '+53',
  },
  {
    text: '+54',
    value: '+54',
  },
  {
    text: '+55',
    value: '+55',
  },
  {
    text: '+56',
    value: '+56',
  },
  {
    text: '+57',
    value: '+57',
  },
  {
    text: '+58',
    value: '+58',
  },
  {
    text: '+60',
    value: '+60',
  },
  {
    text: '+61',
    value: '+61',
  },
  {
    text: '+62',
    value: '+62',
  },
  {
    text: '+63',
    value: '+63',
  },
  {
    text: '+64',
    value: '+64',
  },
  {
    text: '+65',
    value: '+65',
  },
  {
    text: '+66',
    value: '+66',
  },
  {
    text: '+77',
    value: '+77',
  },
  {
    text: '+81',
    value: '+81',
  },
  {
    text: '+82',
    value: '+82',
  },
  {
    text: '+84',
    value: '+84',
  },
  {
    text: '+86',
    value: '+86',
  },
  {
    text: '+90',
    value: '+90',
  },
  {
    text: '+91',
    value: '+91',
  },
  {
    text: '+92',
    value: '+92',
  },
  {
    text: '+93',
    value: '+93',
  },
  {
    text: '+94',
    value: '+94',
  },
  {
    text: '+95',
    value: '+95',
  },
  {
    text: '+98',
    value: '+98',
  },
  {
    text: '+211',
    value: '+211',
  },
  {
    text: '+212',
    value: '+212',
  },
  {
    text: '+213',
    value: '+213',
  },
  {
    text: '+216',
    value: '+216',
  },
  {
    text: '+218',
    value: '+218',
  },
  {
    text: '+220',
    value: '+220',
  },
  {
    text: '+221',
    value: '+221',
  },
  {
    text: '+222',
    value: '+222',
  },
  {
    text: '+223',
    value: '+223',
  },
  {
    text: '+224',
    value: '+224',
  },
  {
    text: '+225',
    value: '+225',
  },
  {
    text: '+226',
    value: '+226',
  },
  {
    text: '+227',
    value: '+227',
  },
  {
    text: '+228',
    value: '+228',
  },
  {
    text: '+229',
    value: '+229',
  },
  {
    text: '+230',
    value: '+230',
  },
  {
    text: '+231',
    value: '+231',
  },
  {
    text: '+232',
    value: '+232',
  },
  {
    text: '+233',
    value: '+233',
  },
  {
    text: '+234',
    value: '+234',
  },
  {
    text: '+235',
    value: '+235',
  },
  {
    text: '+236',
    value: '+236',
  },
  {
    text: '+237',
    value: '+237',
  },
  {
    text: '+238',
    value: '+238',
  },
  {
    text: '+239',
    value: '+239',
  },
  {
    text: '+240',
    value: '+240',
  },
  {
    text: '+241',
    value: '+241',
  },
  {
    text: '+242',
    value: '+242',
  },
  {
    text: '+243',
    value: '+243',
  },
  {
    text: '+244',
    value: '+244',
  },
  {
    text: '+245',
    value: '+245',
  },
  {
    text: '+246',
    value: '+246',
  },
  {
    text: '+248',
    value: '+248',
  },
  {
    text: '+249',
    value: '+249',
  },
  {
    text: '+250',
    value: '+250',
  },
  {
    text: '+251',
    value: '+251',
  },
  {
    text: '+252',
    value: '+252',
  },
  {
    text: '+253',
    value: '+253',
  },
  {
    text: '+254',
    value: '+254',
  },
  {
    text: '+255',
    value: '+255',
  },
  {
    text: '+256',
    value: '+256',
  },
  {
    text: '+257',
    value: '+257',
  },
  {
    text: '+258',
    value: '+258',
  },
  {
    text: '+260',
    value: '+260',
  },
  {
    text: '+261',
    value: '+261',
  },
  {
    text: '+262',
    value: '+262',
  },
  {
    text: '+263',
    value: '+263',
  },
  {
    text: '+264',
    value: '+264',
  },
  {
    text: '+265',
    value: '+265',
  },
  {
    text: '+266',
    value: '+266',
  },
  {
    text: '+267',
    value: '+267',
  },
  {
    text: '+268',
    value: '+268',
  },
  {
    text: '+269',
    value: '+269',
  },
  {
    text: '+290',
    value: '+290',
  },
  {
    text: '+291',
    value: '+291',
  },
  {
    text: '+297',
    value: '+297',
  },
  {
    text: '+298',
    value: '+298',
  },
  {
    text: '+299',
    value: '+299',
  },
  {
    text: '+345',
    value: '+345',
  },
  {
    text: '+350',
    value: '+350',
  },
  {
    text: '+351',
    value: '+351',
  },
  {
    text: '+352',
    value: '+352',
  },
  {
    text: '+353',
    value: '+353',
  },
  {
    text: '+354',
    value: '+354',
  },
  {
    text: '+355',
    value: '+355',
  },
  {
    text: '+356',
    value: '+356',
  },
  {
    text: '+357',
    value: '+357',
  },
  {
    text: '+358',
    value: '+358',
  },
  {
    text: '+359',
    value: '+359',
  },
  {
    text: '+370',
    value: '+370',
  },
  {
    text: '+371',
    value: '+371',
  },
  {
    text: '+372',
    value: '+372',
  },
  {
    text: '+373',
    value: '+373',
  },
  {
    text: '+374',
    value: '+374',
  },
  {
    text: '+375',
    value: '+375',
  },
  {
    text: '+377',
    value: '+377',
  },
  {
    text: '+378',
    value: '+378',
  },
  {
    text: '+379',
    value: '+379',
  },
  {
    text: '+380',
    value: '+380',
  },
  {
    text: '+381',
    value: '+381',
  },
  {
    text: '+382',
    value: '+382',
  },
  {
    text: '+385',
    value: '+385',
  },
  {
    text: '+386',
    value: '+386',
  },
  {
    text: '+387',
    value: '+387',
  },
  {
    text: '+389',
    value: '+389',
  },
  {
    text: '+420',
    value: '+420',
  },
  {
    text: '+421',
    value: '+421',
  },
  {
    text: '+423',
    value: '+423',
  },
  {
    text: '+500',
    value: '+500',
  },
  {
    text: '+501',
    value: '+501',
  },
  {
    text: '+502',
    value: '+502',
  },
  {
    text: '+503',
    value: '+503',
  },
  {
    text: '+504',
    value: '+504',
  },
  {
    text: '+505',
    value: '+505',
  },
  {
    text: '+506',
    value: '+506',
  },
  {
    text: '+507',
    value: '+507',
  },
  {
    text: '+508',
    value: '+508',
  },
  {
    text: '+509',
    value: '+509',
  },
  {
    text: '+590',
    value: '+590',
  },
  {
    text: '+591',
    value: '+591',
  },
  {
    text: '+593',
    value: '+593',
  },
  {
    text: '+594',
    value: '+594',
  },
  {
    text: '+595',
    value: '+595',
  },
  {
    text: '+596',
    value: '+596',
  },
  {
    text: '+597',
    value: '+597',
  },
  {
    text: '+598',
    value: '+598',
  },
  {
    text: '+599',
    value: '+599',
  },
  {
    text: '+670',
    value: '+670',
  },
  {
    text: '+672',
    value: '+672',
  },
  {
    text: '+673',
    value: '+673',
  },
  {
    text: '+674',
    value: '+674',
  },
  {
    text: '+675',
    value: '+675',
  },
  {
    text: '+676',
    value: '+676',
  },
  {
    text: '+677',
    value: '+677',
  },
  {
    text: '+678',
    value: '+678',
  },
  {
    text: '+679',
    value: '+679',
  },
  {
    text: '+680',
    value: '+680',
  },
  {
    text: '+681',
    value: '+681',
  },
  {
    text: '+682',
    value: '+682',
  },
  {
    text: '+683',
    value: '+683',
  },
  {
    text: '+685',
    value: '+685',
  },
  {
    text: '+686',
    value: '+686',
  },
  {
    text: '+687',
    value: '+687',
  },
  {
    text: '+688',
    value: '+688',
  },
  {
    text: '+689',
    value: '+689',
  },
  {
    text: '+690',
    value: '+690',
  },
  {
    text: '+691',
    value: '+691',
  },
  {
    text: '+692',
    value: '+692',
  },
  {
    text: '+850',
    value: '+850',
  },
  {
    text: '+852',
    value: '+852',
  },
  {
    text: '+853',
    value: '+853',
  },
  {
    text: '+855',
    value: '+855',
  },
  {
    text: '+856',
    value: '+856',
  },
  {
    text: '+872',
    value: '+872',
  },
  {
    text: '+880',
    value: '+880',
  },
  {
    text: '+886',
    value: '+886',
  },
  {
    text: '+960',
    value: '+960',
  },
  {
    text: '+961',
    value: '+961',
  },
  {
    text: '+962',
    value: '+962',
  },
  {
    text: '+963',
    value: '+963',
  },
  {
    text: '+964',
    value: '+964',
  },
  {
    text: '+965',
    value: '+965',
  },
  {
    text: '+966',
    value: '+966',
  },
  {
    text: '+967',
    value: '+967',
  },
  {
    text: '+968',
    value: '+968',
  },
  {
    text: '+970',
    value: '+970',
  },
  {
    text: '+971',
    value: '+971',
  },
  {
    text: '+972',
    value: '+972',
  },
  {
    text: '+973',
    value: '+973',
  },
  {
    text: '+974',
    value: '+974',
  },
  {
    text: '+975',
    value: '+975',
  },
  {
    text: '+976',
    value: '+976',
  },
  {
    text: '+977',
    value: '+977',
  },
  {
    text: '+992',
    value: '+992',
  },
  {
    text: '+993',
    value: '+993',
  },
  {
    text: '+994',
    value: '+994',
  },
  {
    text: '+995',
    value: '+995',
  },
  {
    text: '+996',
    value: '+996',
  },
  {
    text: '+998',
    value: '+998',
  },
  {
    text: '+1242',
    value: '+1242',
  },
  {
    text: '+1246',
    value: '+1246',
  },
  {
    text: '+1264',
    value: '+1264',
  },
  {
    text: '+1268',
    value: '+1268',
  },
  {
    text: '+1284',
    value: '+1284',
  },
  {
    text: '+1340',
    value: '+1340',
  },
  {
    text: '+1441',
    value: '+1441',
  },
  {
    text: '+1473',
    value: '+1473',
  },
  {
    text: '+1649',
    value: '+1649',
  },
  {
    text: '+1664',
    value: '+1664',
  },
  {
    text: '+1670',
    value: '+1670',
  },
  {
    text: '+1671',
    value: '+1671',
  },
  {
    text: '+1684',
    value: '+1684',
  },
  {
    text: '+1758',
    value: '+1758',
  },
  {
    text: '+1767',
    value: '+1767',
  },
  {
    text: '+1784',
    value: '+1784',
  },
  {
    text: '+1849',
    value: '+1849',
  },
  {
    text: '+1868',
    value: '+1868',
  },
  {
    text: '+1869',
    value: '+1869',
  },
  {
    text: '+1876',
    value: '+1876',
  },
  {
    text: '+1939',
    value: '+1939',
  },
];

export const sourceTypes = [
  { text: 'Inbound', value: 'INBOUND' },
  { text: 'YC Demo Day', value: 'YC_DEMO_DAY' },
  { text: 'Email Intro', value: 'EMAIL_INTRO' },
];

export const investorTypes = [
  { text: 'VC', value: 'VC' },
  { text: 'Angel', value: 'ANGEL' },
  { text: 'YC Alum', value: 'YC_ALUM' },
  { text: 'Syndicate', value: 'SYNDICATE' },
];

export const valuationChoices = [
  { text: 'Uncapped', value: 'UNCAPPED' },
  { text: 'Uncapped MFN', value: 'UNCAPPED_MFN' },
  { text: 'MFN', value: 'MFN' },
  { text: 'None', value: 'NONE' },
];

export const fcrmOptions = [
  { text: 'Startups', value: 'STARTUPS' },
  { text: 'Master Data', value: 'MASTER' },
];

export const SubscriptionUsersOptions = [
  { text: 'VentureInsights Plus', value: 'VC_FIRM', subText: 'VIP' },
  { text: 'VentureInsights', value: 'ANGEL_INVESTOR', subText: 'VI' },
  { text: 'Yardstick Only', value: 'YARDSTICK', subText: 'YO' },
];

export const SubscriptionPlanStatus = [
  { text: 'All ', value: 'ALL' },
  { text: 'Free Trial', value: 'FREE_TRIAL' },
  { text: 'Paid', value: 'PAID' },
  { text: 'Payment due', value: 'PAYMENT_DUE' },
  { text: 'Deactivated', value: 'DEACTIVATED' },
];

export const SubscriptionFreeTrialPeriod = [
  { text: '15', value: '15' },
  { text: '30', value: '30' },
  { text: '60', value: '60' },
  { text: '90', value: '90' },
];

export const countryOfOperationList = [
  { country: 'United States of America', countryCode: 'US' },
  { country: 'India', countryCode: 'IN' },
  { country: 'United Kingdom', countryCode: 'GB' },
  { country: 'Canada', countryCode: 'CA' },
  { country: 'Australia', countryCode: 'AU' },
  { country: 'Indonesia', countryCode: 'ID' },
  { country: 'Germany', countryCode: 'DE' },
  { country: 'France', countryCode: 'FR' },
  { country: 'Spain', countryCode: 'ES' },
  { country: 'Brazil', countryCode: 'BR' },
  { country: 'Singapore', countryCode: 'SG' },
  { country: 'Netherlands', countryCode: 'NL' },
  { country: 'United Arab Emirates', countryCode: 'AE' },
  { country: 'Israel', countryCode: 'IL' },
  { country: 'Italy', countryCode: 'IT' },
  { country: 'Switzerland', countryCode: 'CH' },
  { country: 'Nigeria', countryCode: 'NG' },
  { country: 'Sweden', countryCode: 'SE' },
  { country: 'Peru', countryCode: 'PE' },
  { country: 'Russia', countryCode: 'RU' },
  { country: 'China', countryCode: 'CN' },
  { country: 'Egypt', countryCode: 'EG' },
  { country: 'Japan', countryCode: 'JP' },
  { country: 'Belgium', countryCode: 'BE' },
  { country: 'Turkey', countryCode: 'TR' },
  { country: 'Mexico', countryCode: 'MX' },
  { country: 'Colombia', countryCode: 'CO' },
  { country: 'Poland', countryCode: 'PL' },
  { country: 'South Africa', countryCode: 'ZA' },
  { country: 'Finland', countryCode: 'FI' },
  { country: 'Ireland', countryCode: 'IE' },
  { country: 'Argentina', countryCode: 'AR' },
  { country: 'Denmark', countryCode: 'DK' },
  { country: 'Sri Lanka', countryCode: 'LK' },
  { country: 'Hong Kong S.A.R.', countryCode: 'HK' },
  { country: 'Portugal', countryCode: 'PT' },
  { country: 'Chile', countryCode: 'CL' },
  { country: 'Ukraine', countryCode: 'UA' },
  { country: 'Pakistan', countryCode: 'PK' },
  { country: 'South Korea', countryCode: 'KR' },
  { country: 'Philippines', countryCode: 'PH' },
  { country: 'Kenya', countryCode: 'KE' },
  { country: 'Malaysia', countryCode: 'MY' },
  { country: 'Austria', countryCode: 'AT' },
  { country: 'Hungary', countryCode: 'HU' },
  { country: 'Norway', countryCode: 'NO' },
  { country: 'Estonia', countryCode: 'EE' },
  { country: 'Bangladesh', countryCode: 'BD' },
  { country: 'New Zealand', countryCode: 'NZ' },
  { country: 'Romania', countryCode: 'RO' },
  { country: 'Iran', countryCode: 'IR' },
  { country: 'Azerbaijan', countryCode: 'AZ' },
  { country: 'Vietnam', countryCode: 'VN' },
  { country: 'Greece', countryCode: 'GR' },
  { country: 'Lithuania', countryCode: 'LT' },
  { country: 'Thailand', countryCode: 'TH' },
  { country: 'Bulgaria', countryCode: 'BG' },
  { country: 'Nepal', countryCode: 'NP' },
  { country: 'Czech Republic', countryCode: 'CZ' },
  { country: 'Ghana', countryCode: 'GH' },
  { country: 'Taiwan', countryCode: 'TW' },
  { country: 'Saudi Arabia', countryCode: 'SA' },
  { country: 'Croatia (Hrvatska)', countryCode: 'HR' },
  { country: 'Morocco', countryCode: 'MA' },
  { country: 'Algeria', countryCode: 'DZ' },
  { country: 'Cameroon', countryCode: 'CM' },
  { country: 'Latvia', countryCode: 'LV' },
  { country: 'Uganda', countryCode: 'UG' },
  { country: 'Jordan', countryCode: 'JO' },
  { country: 'Venezuela', countryCode: 'VE' },
  { country: 'Slovenia', countryCode: 'SI' },
  { country: 'Cyprus', countryCode: 'CY' },
  { country: 'Uruguay', countryCode: 'UY' },
  { country: 'Luxembourg', countryCode: 'LU' },
  { country: 'Slovakia', countryCode: 'SK' },
  { country: 'Ecuador', countryCode: 'EC' },
  { country: 'Belarus', countryCode: 'BY' },
  { country: 'Albania', countryCode: 'AL' },
  { country: 'Ethiopia', countryCode: 'ET' },
  { country: 'Angola', countryCode: 'AO' },
  { country: 'Tunisia', countryCode: 'TN' },
  { country: 'Tanzania', countryCode: 'TZ' },
  { country: 'Malta', countryCode: 'MT' },
  { country: 'Myanmar (Burma)', countryCode: 'MM' },
  { country: 'Lebanon', countryCode: 'LB' },
  { country: 'Kuwait', countryCode: 'KW' },
  { country: 'Botswana', countryCode: 'BW' },
  { country: 'Bolivia', countryCode: 'BO' },
  { country: 'Panama', countryCode: 'PA' },
  { country: 'Mauritius', countryCode: 'MU' },
  { country: 'American Samoa', countryCode: 'AS' },
  { country: 'Qatar', countryCode: 'QA' },
  { country: 'Paraguay', countryCode: 'PY' },
  { country: 'Aland Islands', countryCode: 'AX' },
  { country: 'Congo - Kinshasa', countryCode: 'CD' },
  { country: 'Iceland', countryCode: 'IS' },
  { country: 'Bahrain', countryCode: 'BH' },
  { country: 'Costa Rica', countryCode: 'CR' },
  { country: 'Georgia', countryCode: 'GE' },
  { country: 'Senegal', countryCode: 'SN' },
  { country: 'Cayman Islands', countryCode: 'KY' },
  { country: 'Mozambique', countryCode: 'MZ' },
  { country: 'Bermuda', countryCode: 'BM' },
  { country: 'Armenia', countryCode: 'AM' },
  { country: "Cote D'Ivoire (Ivory Coast)", countryCode: 'CI' },
  { country: 'Puerto Rico', countryCode: 'PR' },
  { country: 'Guatemala', countryCode: 'GT' },
  { country: 'Cambodia', countryCode: 'KH' },
  { country: 'Afghanistan', countryCode: 'AF' },
  { country: 'Dominican Republic', countryCode: 'DO' },
  { country: 'Kazakhstan', countryCode: 'KZ' },
  { country: 'Belize', countryCode: 'BZ' },
  { country: 'Zambia', countryCode: 'ZM' },
  { country: 'Rwanda', countryCode: 'RW' },
  { country: 'Macedonia', countryCode: 'MK' },
  { country: 'Oman', countryCode: 'OM' },
  { country: 'Iraq', countryCode: 'IQ' },
  { country: 'Bosnia & Herzegovina', countryCode: 'BA' },
  { country: 'British Indian Ocean Territory', countryCode: 'IO' },
  { country: 'British Virgin Islands', countryCode: 'VG' },
  { country: 'Cuba', countryCode: 'CU' },
  { country: 'Seychelles', countryCode: 'SC' },
  { country: 'Andorra', countryCode: 'AD' },
  { country: 'Brunei', countryCode: 'BN' },
  { country: 'Moldova', countryCode: 'MD' },
  { country: 'U.S. Virgin Islands', countryCode: 'VI' },
  { country: 'Jamaica', countryCode: 'JM' },
  { country: 'Yemen', countryCode: 'YE' },
  { country: 'Isle of Man', countryCode: 'IM' },
  { country: 'Gibraltar', countryCode: 'GI' },
  { country: 'Zimbabwe', countryCode: 'ZW' },
  { country: 'Benin', countryCode: 'BJ' },
  { country: 'U.S. Outlying Islands', countryCode: 'UM' },
  { country: 'Liechtenstein', countryCode: 'LI' },
  { country: 'Honduras', countryCode: 'HN' },
  { country: 'Trinidad & Tobago', countryCode: 'TT' },
  { country: 'Congo - Brazzaville', countryCode: 'CG' },
  { country: 'Palestinian Territories', countryCode: 'PS' },
  { country: 'Kosovo', countryCode: 'XK' },
  { country: 'Nicaragua', countryCode: 'NI' },
  { country: 'Sudan', countryCode: 'SD' },
  { country: 'Swaziland', countryCode: 'SZ' },
  { country: 'Madagascar', countryCode: 'MG' },
  { country: 'Kyrgyzstan', countryCode: 'KG' },
  { country: 'Maldives', countryCode: 'MV' },
  { country: 'Barbados', countryCode: 'BB' },
  { country: 'Guernsey', countryCode: 'GG' },
  { country: 'Bhutan', countryCode: 'BT' },
  { country: 'French Polynesia', countryCode: 'PF' },
  { country: 'Haiti', countryCode: 'HT' },
  { country: 'Malawi', countryCode: 'MW' },
  { country: 'Gabon', countryCode: 'GA' },
  { country: 'Bahamas', countryCode: 'BS' },
  { country: 'St. Lucia', countryCode: 'LC' },
  { country: 'Somalia', countryCode: 'SO' },
  { country: 'Mali', countryCode: 'ML' },
  { country: 'Sierra Leone', countryCode: 'SL' },
  { country: 'Papua New Guinea', countryCode: 'PG' },
  { country: 'Marshall Islands', countryCode: 'MH' },
  { country: 'Serbia', countryCode: 'RS' },
  { country: 'Montenegro', countryCode: 'ME' },
  { country: 'Togo', countryCode: 'TG' },
  { country: 'Burkina Faso', countryCode: 'BF' },
  { country: 'Fiji', countryCode: 'FJ' },
  { country: 'Suriname', countryCode: 'SR' },
  { country: 'El Salvador', countryCode: 'SV' },
  { country: 'Jersey', countryCode: 'JE' },
  { country: 'Samoa', countryCode: 'WS' },
  { country: 'South Sudan', countryCode: 'SS' },
  { country: 'St. Kitts & Nevis', countryCode: 'KN' },
  { country: 'Monaco', countryCode: 'MC' },
  { country: 'Guinea', countryCode: 'GN' },
  { country: 'Cape Verde', countryCode: 'CV' },
  { country: 'Lesotho', countryCode: 'LS' },
  { country: 'Mongolia', countryCode: 'MN' },
  { country: 'Sao Tome and Principe', countryCode: 'ST' },
  { country: 'Libya', countryCode: 'LY' },
  { country: 'Laos', countryCode: 'LA' },
  { country: 'Antigua & Barbuda', countryCode: 'AG' },
  { country: 'St. Vincent & Grenadines', countryCode: 'VC' },
  { country: 'North Korea', countryCode: 'KP' },
  { country: 'Uzbekistan', countryCode: 'UZ' },
  // { country: 'Netherlands Antilles', countryCode: '' },
  // { country: 'Tristan da Cunha', countryCode: '' },
  { country: 'Norfolk Island', countryCode: 'NF' },
  // { country: 'Canary Islands', countryCode: '' },
  // { country: 'Ceuta & Melilla', countryCode: '' },
  { country: 'Niger', countryCode: 'NE' },
  { country: 'Anguilla', countryCode: 'AI' },
  { country: 'Mauritania', countryCode: 'MR' },
  { country: 'Western Sahara', countryCode: 'EH' },
  { country: 'Timor-Leste', countryCode: 'TL' },
  { country: 'Comoros', countryCode: 'KM' },
  { country: 'Christmas Island', countryCode: 'CX' },
  { country: 'Greenland', countryCode: 'GL' },
  { country: 'Solomon Islands', countryCode: 'SB' },
  { country: 'Turks & Caicos Islands', countryCode: 'TC' },
  { country: 'Burundi', countryCode: 'BI' },
  { country: 'Guinea-Bissau', countryCode: 'GW' },
  { country: 'Tonga', countryCode: 'TO' },
  { country: 'Vanuatu', countryCode: 'VU' },
  { country: 'Antarctica', countryCode: 'AQ' },
  { country: 'San Marino', countryCode: 'SM' },
  { country: 'Chad', countryCode: 'TD' },
  { country: 'Tokelau', countryCode: 'TK' },
  { country: 'Faroe Islands', countryCode: 'FO' },
  { country: 'Aruba', countryCode: 'AW' },
  { country: 'Namibia', countryCode: 'NA' },
  { country: 'Gambia', countryCode: 'GM' },
  { country: 'French Guiana', countryCode: 'GF' },
  { country: 'Liberia', countryCode: 'LR' },
  { country: 'French Southern Territories', countryCode: 'TF' },
  { country: 'Dominica', countryCode: 'DM' },
  { country: 'Remote', countryCode: 'REMOTE' },
];

export const vcDealsTagsList = [
  'Crypto / Web3',
  'SaaS',
  'B2B',
  'Fintech',
  'Developer Tools',
  'Marketplace',
  'Artificial Intelligence',
  'E-commerce',
  'Machine Learning',
  'Education',
  'Analytics',
  'Climate',
  'Consumer',
  'AI',
  'Healthcare',
  'API',
  'Payments',
  'Open Source',
  'Productivity',
  'Health Tech',
  'Generative AI',
  'Biotech',
  'Consumer Health Services',
  'Logistics',
  'Hardware',
  'Data Engineering',
  'Enterprise',
  'Proptech',
  'Gaming',
  'Digital Health',
  'Security',
  'Social',
  'Community',
  'Medical Devices',
  'Sales',
  'Hard Tech',
  'Video',
  'Real Estate',
  'Insurance',
  'Robotics',
  'Delivery',
  'Recruiting',
  'HR Tech',
  'Computer Vision',
  'Supply Chain',
  'Neobank',
  'Design Tools',
  'eLearning',
  'Construction',
  'Food Tech',
  'Mental Health Tech',
  'Collaboration',
  'Investing',
  'Retail',
  'IoT',
  'Entertainment',
  'Messaging',
  'Travel',
  'Marketing',
  'Subscriptions',
  'India',
  'Finance',
  'Telemedicine',
  'Diagnostics',
  'Media',
  'Creator Economy',
  'Deep Learning',
  'DevSecOps',
  'LegalTech',
  'Transportation',
  'No-code',
  'Therapeutics',
  'Grocery',
  'NLP',
  'ClimateTech',
  'Compliance',
  'Genomics',
  'GovTech',
  'Nonprofit',
  'Banking as a Service',
  'AI-powered Drug Discovery',
  'Drug discovery',
  'Electric Vehicles',
  'Manufacturing',
  'Advertising',
  'Agriculture',
  'Automation',
  'DevOps',
  'Energy',
  'AI-Enhanced Learning',
  'AIOps',
  'FinOps',
  'Enterprise Software',
  'Health & Wellness',
  'AI Assistant',
  'Documents',
  'Infrastructure',
  'Cloud Computing',
  'Cybersecurity',
  'DeFi',
  'Drones',
  'Food & Beverage',
  'Latin America',
  'Synthetic Biology',
  'Telehealth',
  'Augmented Reality',
  'Remote Work',
  'Robotic Process Automation',
  'Email',
  'Fashion',
  'Human Resources',
  'Identity',
  'Music',
  'Social Network',
  'Housing',
  'Sales Enablement',
  'Social Media',
  'Workflow Automation',
  'Customer Success',
  'Privacy',
  'Satellites',
  'Space Exploration',
  'Sports Tech',
  'Sustainability',
  'Telecommunications',
  'Oncology',
  'Solar Power',
  'Virtual Reality',
  'CRM',
  'Health Insurance',
  'Neurotechnology',
  'Renewable Energy',
  'Retail Tech',
  'Big Data',
  'Carbon Capture and Removal',
  'Design',
  'Fitness',
  'SMB',
  'Airplanes',
  'Ghost Kitchens',
  'Kubernetes',
  'NFT',
  'Warehouse Management Tech',
  'Autonomous Trucking',
  'Customer Service',
  'Energy Storage',
  'Food Service Robots & Machines',
  'Payroll',
  'Regtech',
  'Aerospace',
  'Automotive',
  'Consumer Finance',
  'Crowdfunding',
  'Customer Support',
  'Gene Therapy',
  'Nanotechnology',
  'Operations',
  'Sustainable Fashion',
  'Team Collaboration',
  'Beauty',
  'Data Visualization',
  'Databases',
  'Fertility Tech',
  'Kids',
  'Legal',
  'Lending',
  'Metaverse',
  'Personalization',
  'Scheduling',
  'Sleep Tech',
  "Women's Health",
  'Assistive Tech',
  'Autonomous Delivery',
  'Calendar',
  'Mobility',
  'Primary Care',
  'Remote',
  'Cannabis',
  'Civic Tech',
  'Conversational AI',
  'Femtech',
  'Furniture',
  'Healthcare IT',
  'Monitoring',
  'Podcasts',
  'Restaurant Tech',
  'Air Taxis',
  'Auto Commerce',
  'Biometrics',
  'Cellular Agriculture',
  'Chatbot',
  'Commercial Space Launch',
  'Data Science',
  'Dating',
  'Digital Freight Brokerage',
  'Edtech',
  'Geographic Information System',
  'Live',
  'Procurement',
  'APIs',
  'COVID-19',
  'Cashierless Checkout',
  'Cloud Gaming',
  'Cloud Workload Protection',
  'Microfluidics',
  'Next-gen Network Security',
  'Smart Clothing',
  'Smart Home Assistants',
  'Web Development',
  'Advanced Materials',
  'Anti-Aging',
  'Biotechnology',
  'Cell Therapy',
  'Consumer Products',
  'Cryptocurrency',
  'DAO',
  'Diversity & Inclusion',
  'Emerging Markets',
  'Food',
  'GraphQL',
  'Hydrogen Energy',
  'Income Share Agreements',
  'Market Research',
  'Medical Robotics',
  'Nanomedicine',
  'Nanosensors',
  'Recommendation System',
  'Trust & Safety',
  'Vertical Farming',
  'Airlines',
  'Alternative Battery Tech',
  'Apparel',
  'Billing',
  'Bioplastic',
  'Careers',
  'Chatbots',
  'Crowdsourcing',
  'Cultivated Meat',
  'Cultured Meat',
  'Drug Delivery',
  'Electronics',
  'Fraud Detection',
  'Indoor Mapping',
  'Industrial',
  'Industrial Workplace Safety',
  'ML',
  'Microinsurance',
  'Note-taking',
  'Notifications',
  'Pediatrics',
  'Quantum Computing',
  'Reinforcement Learning',
  'SEO',
  'SMS',
  'Search',
  'Security Orchestration, Automation and Response (SOAR)',
  'Self-Driving Vehicles',
  'Speech Recognition',
  'Talent Acquisition',
  'Time Series',
  'Trading',
  'Unmanned Vehicle',
  'eSports',
  'AR',
  'Art Trading Platforms',
  'Blockchain',
  'Booking',
  'CRISPR',
  'Chat',
  'China',
  'Coding Bootcamps',
  'Conversational Banking',
  'Culture',
  'Customization',
  'Cyber Insurance',
  'Data Labeling',
  'Dental',
  'Election Tech',
  'Feedback',
  'Fraud Prevention',
  'Gardening',
  'Genetic Engineering',
  'Home Automation',
  'Home Services',
  'Immigration',
  'International',
  'Investments',
  'Location-based',
  'Maritime',
  'Mental Health',
  'Mining',
  'Navigation',
  'Networks',
  'Plant-based Meat',
  'Psychedelics',
  'Radar',
  'Referrals',
  'Remittances',
  'Reviews',
  'Ridesharing',
  'Rocketry',
  'Small Modular Reactors',
  'Stocks',
  'Sustainable Agriculture',
  'Sustainable Tourism',
  'Ticketing',
  'VR Health',
  'Alternative Fuels',
  'Batteryless IoT Sensors',
  'Call Center',
  'Clean Meat',
  'Computational Storage',
  'Cryogenics',
  'Cryptography',
  'Deepfake Detection',
  'Edge Computing Semiconductors',
  'Fundraising',
  'Fusion Energy',
  'IoT Security',
  'Lab-on-a-chip',
  'Robotic Surgery',
  'Skincare',
  'Smart Locks',
  'Weather',
  'web3',
];

export const vcProspectiveDealColumnMapping = [
  { text: 'Sr No', value: 'Sr No' },
  { text: 'Company Name', value: 'Company Name' },
  { text: 'YC Batch', value: 'YC Batch' },
  { text: 'Description', value: 'Description' },
  { text: 'Email', value: 'Email' },
  { text: 'Company URL', value: 'Company URL' },
  { text: 'Country of Operation', value: 'Country of Operation' },
  { text: 'Country of Incorporation', value: 'Country of Incorporation' },
  { text: 'Sector', value: 'Sector' },
  { text: 'Sector Tags', value: 'Sector Tags' },
  { text: 'Fundraise Round', value: 'Fundraise Round' },
  { text: 'Amount Proposed', value: 'Amount Proposed' },
  { text: 'Proposed Valuation', value: 'Proposed Valuation' },
  {
    text: 'Close Date (MM/DD/YYYY)',
    value: 'Close Date (MM/DD/YYYY)',
  },
  { text: 'Founder Name', value: 'Founder Name' },
  { text: "Founder's Linkedin URL", value: "Founder's Linkedin URL" },
  { text: 'Others', value: 'Other' },
  { text: 'Skip', value: 'EMPTY' },
];

export const vcProspectiveDealRequiredFields = ['Company Name'];
