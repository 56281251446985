import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { get, isEmpty, find } from 'lodash';

import { getDashboardValues } from 'redux-modules/global/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { getInvestorStats } from 'services';
import { handleGetAllDeal } from 'redux-modules/deals/Actions';
import { WrappedSelect } from 'components';
import { ADMIN_VIEW } from 'common/utils/constants';

import styles from './styles';

const QuickStats = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const { dealList } = useSelector(({ Deals }: RootState) => Deals);
  const { dashboardValues, dashboardStartupView } = useSelector(
    ({ global }: RootState) => global,
  );
  const {
    defaultersCount,
    netAssetValue,
    newEnquiresCount,
    paymentsReceived,
    totalInvestedAmount,
  } = dashboardValues;

  const spvList = dealList.map((deal: any) => {
    return {
      text: get(deal, 'deal'),
      value: get(deal, 'id'),
    };
  });
  spvList.push({
    text: 'All',
    value: 'ALL',
  });

  const [
    investorDashboardValues,
    setInvestorDashboardValues,
  ] = React.useState<any>({});
  const [spv, setSPV] = React.useState<string>('ALL');
  const [spvNav, setSpvNav] = React.useState<any>();

  const getInvestorDashboardValues = () => {
    getInvestorStats()
      .then((res: any) => {
        setInvestorDashboardValues(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.log('ERROR FETCHING INVESTOR STAT: ', message);
      });
  };

  useEffect(() => {
    dashboardStartupView === ADMIN_VIEW.STARTUP &&
      dispatch(getDashboardValues());
    dashboardStartupView === ADMIN_VIEW.INVESTOR &&
      getInvestorDashboardValues();
  }, []);

  useEffect(() => {
    if (isEmpty(dealList) && dashboardStartupView === ADMIN_VIEW.INVESTOR)
      dispatch(handleGetAllDeal());
  }, []);

  return (
    <>
      {dashboardStartupView === ADMIN_VIEW.STARTUP && (
        <Box className={classes.dashTop}>
          <Box className={classes.topContent}>
            <Box className={classes.dashValuBox}>
              <Typography className={classes.valueNo} variant="h4">
                {dashboardValues ? (
                  formatAmount(netAssetValue)
                ) : (
                  <Skeleton variant="text" />
                )}
              </Typography>
              <Typography className={classes.valueText} variant="body1">
                Net Asset Value
              </Typography>
            </Box>
          </Box>
          <Box className={classes.topContent}>
            <Box className={classes.dashValuBox}>
              <Typography className={classes.valueNo} variant="h4">
                {dashboardValues ? (
                  formatAmount(totalInvestedAmount)
                ) : (
                  <Skeleton variant="text" />
                )}
              </Typography>
              <Typography className={classes.valueText} variant="body1">
                Total Invested
              </Typography>
            </Box>
          </Box>
          <Box className={classes.topContent}>
            <Box className={classes.dashValuBox}>
              <Typography className={classes.valueNo} variant="h4">
                {dashboardValues ? (
                  formatAmount(paymentsReceived)
                ) : (
                  <Skeleton variant="text" />
                )}
              </Typography>
              <Typography className={classes.valueText} variant="body1">
                Payment Received
              </Typography>
            </Box>
          </Box>
          <Box className={classes.topContent}>
            <Box className={classes.dashValuBox}>
              <Typography className={classes.valueNo} variant="h4">
                {newEnquiresCount}
              </Typography>
              <Typography className={classes.valueText} variant="body1">
                New Enquiries
              </Typography>
            </Box>
          </Box>
          <Box className={classes.topContent}>
            <Box className={classes.dashValuBox}>
              <Typography className={classes.valueNo} variant="h4">
                {defaultersCount}
              </Typography>
              <Typography className={classes.valueText} variant="body1">
                Defaulters
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {dashboardStartupView === ADMIN_VIEW.INVESTOR && (
        <>
          <Box className={classes.spvSelect}>
            <WrappedSelect
              variant={'outlined'}
              value={spv}
              onChange={(e: any) => {
                setSPV(get(e, 'target.value'));
                if (get(e, 'target.value') !== 'ALL') {
                  const spvsNav = get(investorDashboardValues, 'spvsNav', []);
                  const filteredSpv = spvsNav.find(
                    (deal: any) => deal.spv === get(e, 'target.value'),
                  );
                  setSpvNav(filteredSpv);
                }
              }}
              placeholder="SPV"
              className={classes.selectValInput}
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
              dropdowns={spvList}
            />
          </Box>
          <Box className={classes.dashTop}>
            <Box className={classes.topContent}>
              <Box className={classes.dashValuBox}>
                <Typography className={classes.valueNo} variant="h4">
                  {!isEmpty(investorDashboardValues) ? (
                    spv === 'ALL' ? (
                      formatAmount(
                        get(investorDashboardValues, 'totalInvestments', 0),
                        {
                          maximumFractionDigits: 0,
                        },
                      )
                    ) : (
                      formatAmount(get(spvNav, 'totalInvestment', 0), {
                        maximumFractionDigits: 0,
                      })
                    )
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </Typography>
                <Typography className={classes.valueText} variant="body1">
                  Total Investments
                </Typography>
              </Box>
            </Box>
            <Box className={classes.topContent}>
              <Box className={classes.dashValuBox}>
                <Typography className={classes.valueNo} variant="h4">
                  {!isEmpty(investorDashboardValues) ? (
                    spv === 'ALL' ? (
                      formatAmount(get(investorDashboardValues, 'nav', 0), {
                        maximumFractionDigits: 0,
                      })
                    ) : (
                      formatAmount(
                        get(spvNav, 'totalNav', 0) ||
                          get(spvNav, 'totalInvestment', 0),
                        {
                          maximumFractionDigits: 0,
                        },
                      )
                    )
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </Typography>
                <Typography className={classes.valueText} variant="body1">
                  Net Asset Value
                </Typography>
              </Box>
            </Box>
            <Box className={classes.topContent}>
              <Box className={classes.dashValuBox}>
                <Typography className={classes.valueNo} variant="h4">
                  {!isEmpty(investorDashboardValues) ? (
                    spv === 'ALL' ? (
                      formatAmount(
                        get(investorDashboardValues, 'totalValue', 0),
                        {
                          maximumFractionDigits: 0,
                        },
                      )
                    ) : (
                      formatAmount(
                        get(spvNav, 'totalValue', 0) ||
                          get(spvNav, 'totalInvestment', 0),
                        {
                          maximumFractionDigits: 0,
                        },
                      )
                    )
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </Typography>
                <Typography className={classes.valueText} variant="body1">
                  Total Value
                </Typography>
              </Box>
            </Box>
            <Box className={classes.topContent}>
              <Box className={classes.dashValuBox}>
                <Typography className={classes.valueNo} variant="h4">
                  {!isEmpty(investorDashboardValues) ? (
                    spv === 'ALL' ? (
                      <>
                        {parseFloat(
                          get(investorDashboardValues, 'multiple') || 0,
                        ).toFixed(1)}
                        X
                      </>
                    ) : (
                      <>
                        {parseFloat(get(spvNav, 'multiple') || 0).toFixed(1)} X
                      </>
                    )
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </Typography>
                <Typography className={classes.valueText} variant="body1">
                  Multiple
                </Typography>
              </Box>
            </Box>
            <Box className={classes.topContent}>
              <Box className={classes.dashValuBox}>
                <Typography className={classes.valueNo} variant="h4">
                  {!isEmpty(investorDashboardValues) ? (
                    spv === 'ALL' ? (
                      formatAmount(
                        get(investorDashboardValues, 'totalVolume', 0),
                        {
                          maximumFractionDigits: 0,
                        },
                      )
                    ) : (
                      formatAmount(
                        get(spvNav, 'spvVolume', 0) ||
                          get(find(dealList, { id: spv }), 'dealSize'),
                        {
                          maximumFractionDigits: 0,
                        },
                      )
                    )
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </Typography>
                <Typography className={classes.valueText} variant="body1">
                  Total Volume
                </Typography>
              </Box>
            </Box>
            <Box className={classes.topContent}>
              <Box className={classes.dashValuBox}>
                <Typography className={classes.valueNo} variant="h4">
                  {get(investorDashboardValues, 'registeredInvestor', 0)}
                </Typography>
                <Typography className={classes.valueText} variant="body1">
                  Registered Investor
                </Typography>
                <Box className={classes.dashValuInsideBox}>
                  <Typography className={classes.valueInsideNo} variant="h1">
                    {get(investorDashboardValues, 'activeInvestor', 0)}
                  </Typography>
                  <Typography
                    className={classes.valueInsideText}
                    variant="body1"
                  >
                    Active
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default QuickStats;
