import React, { ReactNode } from 'react';
import { DrawerProps, Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Box } from 'components';

import styles from './styles';

interface ComponentProps extends DrawerProps {
  children: ReactNode;
  anchor: 'bottom' | 'left' | 'right' | 'top';
  open: boolean;
  onSidePaneClose: (() => void) | any;
}

const SidePane = ({
  anchor,
  children,
  open,
  onSidePaneClose,
}: ComponentProps) => {
  const classes = styles();

  return (
    <React.Fragment>
      <Drawer
        variant="temporary"
        anchor={anchor}
        open={open}
        onClose={() => {
          onSidePaneClose();
        }}
        PaperProps={{
          style: { width: '50%' },
        }}
      >
        <Box className={classes.headerBox}>
          <CloseIcon
            onClick={() => {
              onSidePaneClose();
            }}
            className={classes.closeIcon}
          />
        </Box>
        {children}
      </Drawer>
    </React.Fragment>
  );
};

export default SidePane;
