import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux-modules/store/rootState';
import { Box, PageHeader } from 'components';
import { ADMIN_VIEW } from 'common/utils/constants';

import {
  QuickStats,
  Actions,
  Banner,
  StartupOverview,
  DashboardSider,
} from './components';
import styles from './styles';
import VcDashboard from './VcDashboard';
// import FcrmProcess from './components/FcrmProcess';
import DashboardAlerts from './components/Alerts/Alerts';

const Dashboard = () => {
  const classes = styles();
  const { role, name } = useSelector(({ Auth }: RootState) => Auth.user);
  const { dashboardStartupView } = useSelector(
    ({ global }: RootState) => global,
  );

  const isRoleAdmin = role === 'ADMIN';

  return (
    <>
      {dashboardStartupView === ADMIN_VIEW.VC_ADMIN && isRoleAdmin ? (
        <VcDashboard />
      ) : (
        <Box className={classes.welcomeBox}>
          {!name ? (
            <Banner name={name} />
          ) : (
            <Box className={classes.dashWelcomeBox}>
              <Box className={classes.dashWelcomeLeft}>
                <PageHeader name={name} />
                <Box className={classes.dashLeftContent}>
                  {!isRoleAdmin && <DashboardAlerts />}
                  {isRoleAdmin ? <QuickStats /> : <StartupOverview />}
                  {/* <FcrmProcess /> */}
                  <Actions />
                </Box>
              </Box>
              {dashboardStartupView !== ADMIN_VIEW.VC_ADMIN && (
                <Box className={classes.dashWelcomeRight}>
                  <DashboardSider isRoleAdmin={isRoleAdmin} />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
export default Dashboard;
