import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';
import { emailYup } from 'common/utils/validations';

export const addUserSchema = yup.object().shape({
  name: yup.string().required(requiredConstant),
  email: emailYup,
  roleId: yup.string().required(requiredConstant),
});
