import React from 'react';
import { Warning } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';

import { Dialog, Box, Button } from 'components';

import styles from './styles';

type DeletePropertyDialogProps = {
  open: boolean;
  onClose: any;
  onOk: any;
  onCancel: any;
  isLoadding: boolean;
  deleteProperty: string;
};

type FCRMAdditionDetailDialogProps = {
  open: boolean;
  onClose: any;
  onOk: any;
  onCancel: any;
  fromEmailCrms: Array<Record<string, any>>;
};
type FCRMDeleteContactDialogProps = {
  open: boolean;
  onClose: any;
  onOk: any;
  onCancel: any;
  isLoading: boolean;
};

type FCRMDeleteFcrmDialogProps = {
  open: boolean;
  onClose: any;
  onOk: any;
  isLoading: boolean;
  reject?: boolean;
};

type FCRMClearAllDialogProps = {
  open: boolean;
  onClose: any;
  onOk: any;
  onCancel: any;
  isLoading: boolean;
};

const DeletePropertyDialog = ({
  open,
  onClose,
  onOk,
  onCancel,
  isLoadding,
  deleteProperty,
}: DeletePropertyDialogProps) => {
  const classes = styles();

  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      subheading={''}
      title="  "
      handleClose={onClose}
      dialogAction={true}
      dialogActionChildren={
        <Box className={classes.centerBtnContainer}>
          <Button
            name="Yes"
            className={classes.investorPrimaryBtn}
            onClick={onOk}
            isLoading={isLoadding}
          />
          <Button
            className={classes.investorSecondaryBtn}
            onClick={onCancel}
            name="Cancel"
          />
        </Box>
      }
    >
      <Box className={classes.warningText}>
        <Warning />
        <Typography variant="body1">
          If you delete {deleteProperty} property will work across all Investor,
          so be conscious, as you may lose the data.
        </Typography>
      </Box>
    </Dialog>
  );
};

const FCRMAdditionDetailDialog = ({
  open,
  onClose,
  onOk,
  onCancel,
  fromEmailCrms,
}: FCRMAdditionDetailDialogProps) => {
  const classes = styles();
  let emailInitiated = '';
  if (fromEmailCrms[0] && get(fromEmailCrms[0], 'contacts[0].email')) {
    emailInitiated = get(fromEmailCrms[0], 'contacts[0].email');
  }

  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      subheading={''}
      title="Add additional details"
      handleClose={onClose}
      dialogAction={true}
      dialogActionChildren={
        <Box className={classes.centerBtnContainer}>
          <Button
            name="Add additional details"
            className={classes.investorPrimaryBtn + ' ' + classes.vMB10}
            onClick={onOk}
          />
          <Button
            className={classes.investorSecondaryBtn}
            onClick={onCancel}
            name="Cancel"
          />
        </Box>
      }
    >
      <Box>
        <Typography variant="body1">
          Please add additional details for the Investor added through{' '}
          {emailInitiated || 'email'}
        </Typography>
        <p></p>
        {fromEmailCrms.map((item: any, i: number) => (
          <Typography variant="h6" key={item.id}>
            {i + 1}. {item.investorName}
          </Typography>
        ))}
      </Box>
    </Dialog>
  );
};

const FCRMDeleteContactDialog = ({
  open,
  onOk,
  onCancel,
  onClose,
  isLoading,
}: FCRMDeleteContactDialogProps) => {
  const classes = styles();
  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      subheading={''}
      title="  "
      handleClose={onClose}
      dialogAction={true}
      dialogActionChildren={
        <Box className={classes.centerBtnContainer}>
          <Button
            name="Yes"
            className={classes.investorPrimaryBtn}
            onClick={onOk}
            isLoading={isLoading}
          />
          <Button
            className={classes.investorSecondaryBtn}
            onClick={onCancel}
            name="Cancel"
          />
        </Box>
      }
    >
      <Box className={classes.warningText}>
        <Warning />
        <Typography variant="body1">Are you sure to delete contact?</Typography>
      </Box>
    </Dialog>
  );
};

const FCRMDeleteFcrmDialog = ({
  open,
  onOk,
  onClose,
  isLoading,
  reject,
}: FCRMDeleteFcrmDialogProps) => {
  const classes = styles();
  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      subheading={''}
      title="  "
      handleClose={onClose}
      dialogAction={true}
      dialogActionChildren={
        <Box className={classes.centerBtnContainer}>
          <Button
            name="Yes"
            className={classes.investorPrimaryBtn}
            onClick={onOk}
            isLoading={isLoading}
          />
          <Button
            className={classes.investorSecondaryBtn}
            onClick={onClose}
            name="Cancel"
          />
        </Box>
      }
    >
      <Box className={classes.warningText}>
        <Warning />
        <Typography variant="body1">
          Are you sure you want to {reject ? 'reject' : 'delete'} this Investor?
        </Typography>
      </Box>
    </Dialog>
  );
};

const FCRMClearAllDialog = ({
  open,
  onOk,
  onCancel,
  onClose,
  isLoading,
}: FCRMClearAllDialogProps) => {
  const classes = styles();
  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      subheading={''}
      title="Clear all"
      handleClose={onClose}
      dialogAction={true}
      dialogActionChildren={
        <Box className={classes.centerBtnContainer}>
          <Button
            name="Yes"
            className={classes.investorPrimaryBtn}
            onClick={onOk}
            isLoading={isLoading}
          />
          <Button
            className={classes.investorSecondaryBtn}
            onClick={onCancel}
            name="Cancel"
          />
        </Box>
      }
    >
      <Box>
        <Typography variant="body1">
          Are you sure to clear all properties
        </Typography>
      </Box>
    </Dialog>
  );
};

export {
  DeletePropertyDialog,
  FCRMAdditionDetailDialog,
  FCRMDeleteContactDialog,
  FCRMDeleteFcrmDialog,
  FCRMClearAllDialog,
};
