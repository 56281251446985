import { put, call, all, fork, takeLatest } from 'redux-saga/effects';

import { errorMessageHandler } from 'common/utils/helpers';
import {
  userEnquiryById,
  enquirySubmitApi,
  getCompanyDetailsByID,
  getDefaultDocuments,
  getStartupData,
  getFounderDetails,
} from 'services';
import {
  ISagaAction,
  IEnquiryModal,
  IStartUpType,
  StartUpDetailType,
  AdditionalInformationType,
  FounderDetailsType,
  BusinessDetailType,
  IBusinessType,
  IFounderType,
  IAdditonalType,
  UploadDocuments,
  PayloadType,
} from 'common/types';

import {
  USER_SUBMIT_LOADING,
  USER_SUBMIT_FAILURE,
  USER_SUBMIT,
  GET_ENQUIRY_DETAILS_BY_ID_LOADING,
  GET_ENQUIRY_DETAILS_BY_ID,
  GET_ENQUIRY_DETAILS_BY_ID_SUCCESS,
  GET_ENQUIRY_DETAILS_BY_ID_FAILURE,
  USER_SUBMIT_BUSINESS,
  USER_SUBMIT_FOUNDER,
  USER_SUBMIT_LOADING_FOUNDER,
  USER_SUBMIT_FAILURE_FOUNDER,
  USER_SUBMIT_LOADING_BUSINESS,
  USER_SUBMIT_FAILURE_BUSINESS,
  USER_SUBMIT_FAILURE_OTHER_INFO,
  USER_SUBMIT_LOADING_OTHER_INFO,
  USER_SUBMIT_OTHER_INFO,
  USER_SUBMIT_SUCCESS,
  USER_SUBMIT_SUCCESS_FOUNDER,
  USER_SUBMIT_SUCCESS_BUSINESS,
  USER_SUBMIT_SUCCESS_OTHER_INFO,
  GET_COMPANY_DETAILS,
  GET_STARTUP_DETAILS_SUCCESS,
  UPLOAD_DOCUMENTS,
  UPLOAD_DOCUMENTS_LOADING,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENTS_FAILURE,
  GET_STARTUP_DATA,
  GET_STARTUP_DATA_LOADING,
  GET_STARTUP_DATA_SUCCESS,
  GET_STARTUP_DATA_FAILURE,
  GET_FOUNDER_DETAILS,
  GET_FOUNDER_DETAILS_LOADING,
  GET_FOUNDER_DETAILS_SUCCESS,
  GET_FOUNDER_DETAILS_FAILURE,
} from './Actions';

type responseType = {
  id: string;
};

const getEnquiryById = async (payload: string) => {
  return userEnquiryById(payload)
    .then((res) => res)
    .catch((err) => Promise.reject(err));
};

const getStartupDetailsById = async (payload: string) => {
  return getCompanyDetailsByID(payload)
    .then((res) => res)
    .catch((err) => Promise.reject(err));
};

const enquirySubmit = async (
  payload: IStartUpType | IBusinessType | IFounderType | IAdditonalType,
) =>
  enquirySubmitApi(payload)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

function* handleStartUpEnquirySubmit(data: ISagaAction<StartUpDetailType>) {
  const { payload } = data;
  try {
    yield put({ type: USER_SUBMIT_LOADING });
    const response: responseType = yield call<any>(enquirySubmit, payload);
    yield put({
      type: USER_SUBMIT_SUCCESS,
      payload: { id: response.id, message: 'created successfully', response },
    });
    payload.callback();
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: USER_SUBMIT_FAILURE, payload: { message } });
  }
}

function* handleFounderEnquirySubmit(data: ISagaAction<FounderDetailsType>) {
  const { payload } = data;
  try {
    yield put({ type: USER_SUBMIT_LOADING_FOUNDER });
    const response: responseType = yield call<any>(enquirySubmit, payload);
    payload.callback(response.id);
    yield put({
      type: USER_SUBMIT_SUCCESS_FOUNDER,
      payload: { message: 'created successfully', response },
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: USER_SUBMIT_FAILURE_FOUNDER, payload: { message } });
  }
}
function* handleBusinessEnquirySubmit(data: ISagaAction<BusinessDetailType>) {
  const { payload } = data;
  try {
    yield put({ type: USER_SUBMIT_LOADING_BUSINESS });
    const response: responseType = yield call<any>(enquirySubmit, payload);
    yield put({
      type: USER_SUBMIT_SUCCESS_BUSINESS,
      payload: { message: 'created successfully', response },
    });
    payload.callback();
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: USER_SUBMIT_FAILURE_BUSINESS, payload: { message } });
  }
}
function* handleAdditionalInfoEnquirySubmit(
  data: ISagaAction<AdditionalInformationType>,
) {
  const { payload } = data;
  try {
    yield put({ type: USER_SUBMIT_LOADING_OTHER_INFO });
    const response: responseType = yield call<any>(enquirySubmit, payload);
    yield put({
      type: USER_SUBMIT_SUCCESS_OTHER_INFO,
      payload: { message: 'created successfully', response },
    });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: USER_SUBMIT_FAILURE_OTHER_INFO, payload: { message } });
  }
}

function* getStartUpDetails(data: ISagaAction<IEnquiryModal>) {
  const { payload } = data;
  try {
    yield put({ type: GET_ENQUIRY_DETAILS_BY_ID_LOADING });
    const response: responseType = yield call<any>(getEnquiryById, payload);
    yield put({ type: GET_ENQUIRY_DETAILS_BY_ID_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: GET_ENQUIRY_DETAILS_BY_ID_FAILURE,
      payload: { message, id: payload },
    });
  }
}

function* getStartupById(data: ISagaAction<string>) {
  const { payload } = data;
  try {
    const response: responseType = yield call<any>(
      getStartupDetailsById,
      payload,
    );
    yield put({ type: GET_STARTUP_DETAILS_SUCCESS, payload: response });
  } catch (err) {
    // const message: string = errorMessageHandler(err);
    //  console.log(message);
  }
}

function* uploadDocuments(data: ISagaAction<UploadDocuments>) {
  const { payload } = data;
  try {
    yield put({ type: UPLOAD_DOCUMENTS_LOADING });
    const response: responseType = yield call<any>(
      getDefaultDocuments,
      payload,
    );
    yield put({ type: UPLOAD_DOCUMENTS_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({
      type: UPLOAD_DOCUMENTS_FAILURE,
      payload: message,
    });
  }
}

function* handleGetStartupData(data: ISagaAction<PayloadType>) {
  const { payload } = data;
  try {
    yield put({ type: GET_STARTUP_DATA_LOADING });
    const response: responseType = yield call<any>(getStartupData, payload);
    yield put({ type: GET_STARTUP_DATA_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: GET_STARTUP_DATA_FAILURE, payload: { message } });
  }
}

function* handleGetFounderDetails(data: ISagaAction<PayloadType>) {
  const { payload } = data;
  try {
    yield put({ type: GET_FOUNDER_DETAILS_LOADING });
    const response: responseType = yield call<any>(getFounderDetails, payload);
    yield put({ type: GET_FOUNDER_DETAILS_SUCCESS, payload: response });
  } catch (err) {
    const message: string = errorMessageHandler(err);
    yield put({ type: GET_FOUNDER_DETAILS_FAILURE, payload: { message } });
  }
}

function* submitStartUpSaga() {
  yield takeLatest(USER_SUBMIT, handleStartUpEnquirySubmit);
}
function* submitFounderSaga() {
  yield takeLatest(USER_SUBMIT_FOUNDER, handleFounderEnquirySubmit);
}
function* submitBusinessSaga() {
  yield takeLatest(USER_SUBMIT_BUSINESS, handleBusinessEnquirySubmit);
}
function* submitAdditionalSaga() {
  yield takeLatest(USER_SUBMIT_OTHER_INFO, handleAdditionalInfoEnquirySubmit);
}

function* getEnquiryByStartUpId() {
  yield takeLatest(GET_ENQUIRY_DETAILS_BY_ID, getStartUpDetails);
}

function* getStartupName() {
  yield takeLatest(GET_COMPANY_DETAILS, getStartupById);
}

function* uploadDocumentsSaga() {
  yield takeLatest(UPLOAD_DOCUMENTS, uploadDocuments);
}

function* getStartupDataSaga() {
  yield takeLatest(GET_STARTUP_DATA, handleGetStartupData);
}

function* getFounderDetailsSaga() {
  yield takeLatest(GET_FOUNDER_DETAILS, handleGetFounderDetails);
}

export default function* EnquiryFormSaga() {
  yield all([
    fork(submitStartUpSaga),
    fork(getEnquiryByStartUpId),
    fork(submitAdditionalSaga),
    fork(submitBusinessSaga),
    fork(submitFounderSaga),
    fork(getStartupName),
    fork(uploadDocumentsSaga),
    fork(getStartupDataSaga),
    fork(getFounderDetailsSaga),
  ]);
}
