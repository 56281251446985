import React from 'react';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import { amplify } from './config/amplify';
import reportWebVitals from './reportWebVitals';
import { configStore } from './redux-modules/store';
const { store } = configStore();

Amplify.configure(amplify);

// console.log(amplify, 'amplify');

Amplify.configure({
  Analytics: {
    // OPTIONAL - disable Analytics if true
    disabled: false,
    // OPTIONAL - Allow recording session events. Default is true.
    autoSessionRecord: true,

    AWSPinpoint: {
      // OPTIONAL -  Amazon Pinpoint App Client ID
      // eslint-disable-next-line no-undef
      appId: process.env.REACT_APP_PINPOINT_APP_ID,
      // OPTIONAL -  Amazon service region
      // eslint-disable-next-line no-undef
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
