import React, { useState } from 'react';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';

import { Button, Dialog, Box } from 'components';
import { deleteVcDeal } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { FN } from 'common/types';

import styles from './styles';

type Props = {
  isOpenDeleteModal: boolean;
  setIsOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  dealData: Record<string, FN>;
  refreshDeals: FN;
  closeDialog: FN;
  title: string;
  setOpenSnackBar?: any;
};

const DeleteDealDialog = ({
  isOpenDeleteModal,
  setIsOpenDeleteModal,
  dealData,
  refreshDeals,
  closeDialog,
  title,
  setOpenSnackBar,
}: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    deleteVcDeal(get(dealData, 'id'))
      .then(() => {
        refreshDeals();
        closeDialog();
        setIsOpenDeleteModal(false);
        setOpenSnackBar(true);
      })
      .catch((err) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Dialog
        open={isOpenDeleteModal}
        maxWidth={'sm'}
        subheading={''}
        title={title}
        handleClose={() => {
          closeDialog();
          setErrorMessage('');
          setIsOpenDeleteModal(false);
        }}
      >
        <Box className={classes.dialogContainer}>
          <Box>
            <Typography className={classes.deleteDealText}>
              Are you sure you want to Delete this deal?
            </Typography>
          </Box>
          {errorMessage && (
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
          <Box className={classes.convertBtnSection}>
            <Button
              className={classes.deleteBtn}
              name="Delete"
              onClick={onSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              variant="outlined"
              name="Cancel"
              className={classes.warnCancelBtn}
              disabled={isLoading}
              onClick={() => {
                closeDialog();
                setErrorMessage('');
                setIsOpenDeleteModal(false);
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DeleteDealDialog;
