import React from 'react';
import { Typography } from '@material-ui/core';

import { ActionEmpty, AdminAction, SuccessBanner } from 'assets';
import { Box } from 'components';

import styles from './styles';

const image: any = {
  AdminAction: AdminAction,
  ActionEmpty: ActionEmpty,
  SuccessBanner: SuccessBanner,
};

type Props = {
  title: string;
  subTitle: string;
  source: string;
};

const BannerImage = (type: string) => {
  const classes = styles();
  return (
    <img
      src={image[type]}
      alt={image[type]}
      className={classes.adminActionimg}
    />
  );
};

const Banner = ({ title, subTitle, source }: Props) => {
  const classes = styles();
  return (
    <Box className={classes.adminActionempty}>
      {BannerImage(source)}
      <Typography variant="h6" className={classes.textHead}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.textSub}>
        {subTitle}
      </Typography>
    </Box>
  );
};

export default Banner;
