import React, { ReactChildren } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'redux-modules/store/rootState';
import { validateUser } from 'common/utils/helpers';

import { Header } from '../components';
type Props = {
  children: ReactChildren;
};

const AuthLayout = ({ children }: Props) => {
  const { user, loggedIn } = useSelector(({ Auth }: RootState) => Auth);
  const { pathname } = useLocation();

  const getRoute = () => {
    if (loggedIn && pathname === '/sign-in') {
      return <Redirect to={validateUser(user)} />;
    } else if (pathname === '/') {
      return <Redirect to={'/sign-in'} />;
    }
    return (
      <>
        <Header />
        {children}
      </>
    );
  };

  return getRoute();
};

export default AuthLayout;
