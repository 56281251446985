import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Button, FileUpload, WrappedTextInput } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import {
  addDealDocument,
  createOrUpdateDeal,
  getSignedUrlForDealDocument,
} from 'services';
import { DealCreationProps } from 'common/types';

import { LegalRegulatorySchema } from '../../validations';
import styles from './styles';

const LegalRegulatory = ({
  handleNext,
  handleBack,
  activeStep,
  deal,
  dealId,
  setDeal,
}: DealCreationProps) => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [loadingSignedUrl, setLoadingSignedUrl] = React.useState<boolean>(
    false,
  );

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(LegalRegulatorySchema),
  });

  const uploadOnSelect = async (fileuploadReq: any): Promise<void> => {
    setErrorMessage('');
    if (!isEmpty(fileuploadReq)) {
      setIsLoading(true);
      try {
        // eslint-disable-next-line no-undef
        await fetch(get(fileuploadReq, 'url.uploadUrl'), {
          method: 'PUT',
          body: fileuploadReq.file,
        });
        const res = await addDealDocument({
          dealId,
          fileName: get(fileuploadReq, 'filename'),
          documentName: 'SUBSCRIPTION_DOC',
          documentUrl: get(fileuploadReq, 'url.accessUrl'),
        });
        setIsLoading(false);
        setDeal(res);
      } catch (err) {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Please upload file.');
    }
  };

  const onSubmit = async (data: any): Promise<void> => {
    setErrorMessage('');
    setIsLoading(true);
    const payload = {
      ...data,
      id: get(deal, 'id'),
    };
    try {
      const res = await createOrUpdateDeal(payload);
      setDeal(res);
      handleNext(activeStep + 1);
    } catch (err) {
      const message: string = errorMessageHandler(err);
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (get(deal, 'id')) {
      setValue('entityLegalName', get(deal, 'entityLegalName', ''));
      setValue('subscriptionDocDesc', get(deal, 'subscriptionDocDesc', ''));
    }
  }, [deal]);

  return (
    <Box>
      <Typography variant="h3" className={classes.dealCreationHeader}>
        Legal & Regulatory
      </Typography>
      {errorMessage && (
        <Typography variant="body1" className={classes.errorMessage}>
          {errorMessage}
        </Typography>
      )}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="legalRegForm">
          <Typography variant="body1" className={classes.dealCreationSubHeader}>
            Fund Legal Entity Details:
          </Typography>
          <Box className={classes.entityNameBox}>
            <Typography className={classes.labelText} variant="body1">
              Entity Legal Name
            </Typography>
            <Controller
              name="entityLegalName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className={classes.controllerBox}>
                  <WrappedTextInput
                    type="text"
                    placeholder="Entity legal name"
                    className={classes.textValInput}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value.trimStart())}
                    error={!!error?.message}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </Box>
              )}
            />
          </Box>
          <Box className={classes.uploadFileBox}>
            <Typography variant="body1" className={classes.uploadText}>
              Subscription Doc
            </Typography>
            <Box className={classes.descBox}>
              <Typography className={classes.labelText} variant="body1">
                <b>Description</b>
              </Typography>
              <Controller
                name="subscriptionDocDesc"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box className={classes.controllerBox}>
                    <WrappedTextInput
                      type="text"
                      placeholder=""
                      className={classes.textValInputDesc}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) =>
                        onChange(e.target.value.trimStart())
                      }
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </Box>
                )}
              />
            </Box>
            <FileUpload
              getSignedUrl={getSignedUrlForDealDocument}
              uploadedFiles={
                isEmpty(get(deal, 'subscriptionDocLink'))
                  ? []
                  : [
                      {
                        filename: get(deal, 'subscriptionDocFilename'),
                        documentUrl: get(deal, 'subscriptionDocLink'),
                      },
                    ]
              }
              setLoadingSignedUrl={setLoadingSignedUrl}
              uploadOnSelect={uploadOnSelect}
              requestObject={{
                documentName: 'SUBSCRIPTION_DOC',
                dealId,
              }}
              content={
                <Button
                  className={classes.uploadBtn}
                  name={'Upload subscription doc'}
                  disabled={loadingSignedUrl || isLoading}
                  isLoading={loadingSignedUrl}
                />
              }
            />
          </Box>
          <Box className={classes.uploadFileBox}>
            {/* <Typography variant="body1" className={classes.uploadAgreementText}>
              Operating Agreements
            </Typography>
            <FileUpload
              getSignedUrl={getSignedUrlForDocusignDocument}
              uploadedFiles={[]}
              setLoadingSignedUrl={setLoadingSignedUrl}
              // uploadOnSelect={uploadOnSelect}
              requestObject={{
                dealId,
              }}
              content={
                <Button
                  className={classes.uploadBtn}
                  name={'Upload agreements'}
                  disabled={loadingSignedUrl || isLoading}
                  isLoading={loadingSignedUrl || isLoading}
                />
              }
            /> */}
          </Box>
          <Box>
            <Button
              className={classes.prevButton}
              name="Previous"
              onClick={() => handleBack()}
              disabled={loadingSignedUrl || isLoading}
            />
            <Button
              type="submit"
              className={classes.nextButton}
              name="Next"
              isLoading={isLoading}
              disabled={loadingSignedUrl || isLoading}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};
export default LegalRegulatory;
