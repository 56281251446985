import React, { useState, useEffect } from 'react';
import { get, isEmpty, orderBy } from 'lodash';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  InputBase,
  Grid,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment';
import { Add } from '@material-ui/icons';

import {
  Box,
  Button,
  Loader,
  StyledTableCell,
  StyledTableRow,
} from 'components';
import { Ornament } from 'assets';
import { getAdminReferralFilters, getAllAdminReferral } from 'services';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';
import { REFERRAL_STATUS_TYPE } from 'common/utils/constants';

import FilterRefferalData from './FilterRefferalData';
import AddReferralType from './AddReferral';
import styles from './styles';

const headers = (array: string[]) => {
  return (
    <>
      {array.map((i: string, n: number) => {
        return <StyledTableCell key={n}>{i}</StyledTableCell>;
      })}
    </>
  );
};

const REFERRAL_TABLE_HEADERS = [
  'S.No.',
  'Referred by',
  'Name of referee',
  'Email of referee',
  'Referral Date',
  'Status',
  'First SPV',
  'Amount Invested',
  'Date Invested',
  'Incentive Amount',
  'Referral Page',
  'Action',
];

const ReferralData = () => {
  const classes = styles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [adminAllReferrals, setAdminAllReferrals] = useState<any>([]);
  const [adminFilters, setAdminFilters] = useState<any>({});
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [queryFilter, setQueryFilter] = useState<any>({});
  const [referralDialog, setReferralDialog] = useState(false);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [selectEditReferral, setSelectEditReferral] = useState({});

  const handleEditReferral = (data: any) => {
    setReferralDialog(true);
    setEditMode(true);
    setSelectEditReferral(data);
  };

  const onSearchChange = (searchString: string) => {
    const filteredData = adminAllReferrals.filter((value: any) => {
      if (
        searchString === '' ||
        value.referrerName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase()) ||
        value.refereeName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase()) ||
        value.refereeEmail
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
  };

  const getReferralDataList = (queryFilter?: any) => {
    setErrorMessage('');
    setIsLoading(true);
    const type = 'INVESTOR';
    getAllAdminReferral(type, queryFilter)
      .then((res) => {
        isEmpty(queryFilter) &&
          setAdminAllReferrals(orderBy(res, ['createdAt'], ['desc']));
        setFilteredSearchData(orderBy(res, ['createdAt'], ['desc']));
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAdminReferralFilters()
      .then((res: any) => {
        setAdminFilters(res);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.log('ERROR WHILE FETCHING FILTER DETAILS: ', message);
      });
  }, []);

  useEffect(() => {
    getReferralDataList(queryFilter);
  }, [queryFilter]);

  return (
    <Box className={classes.enquiryListBox}>
      <Box className={classes.enquiryListhead}>
        <Box className={classes.enquiryListLeft}>
          <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
          <Typography
            variant="h3"
            gutterBottom
            className={classes.enquiryHeadText}
          >
            Referral Data
          </Typography>
        </Box>
        <Box className={classes.enquiryListRight}>
          <Button
            name="Add new Referral"
            startIcon={<Add />}
            className={classes.referralBtn}
            onClick={() => setReferralDialog(true)}
          />
        </Box>
      </Box>
      <Box className={classes.filterBox}>
        <Box className={classes.searchBox}>
          <InputBase
            value={searchStringText}
            className={classes.inputSearch}
            placeholder="Search name, email"
            inputProps={{ 'aria-label': 'search' }}
            endAdornment={
              !searchStringText.length ? (
                <IconButton className={classes.searchIcon} aria-label="search">
                  <SearchIcon />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.clearIcon}
                  aria-label="clear"
                  onClick={() => {
                    setSearchStringText('');
                    onSearchChange('');
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )
            }
            onChange={(e: any) => {
              const searchString = e.target.value;
              setSearchStringText(searchString);
              onSearchChange(searchString);
            }}
          />
        </Box>
        <Box className={classes.enquiryListRight}>
          <Button
            name="Filter"
            className={classes.referralBtn}
            startIcon={<FilterListIcon />}
            onClick={() => setShowFilter(!showFilter)}
          />
        </Box>
      </Box>
      {showFilter && (
        <Grid container className={classes.filterGrid}>
          <FilterRefferalData
            adminFilters={adminFilters}
            setQueryFilter={setQueryFilter}
            queryFilter={queryFilter}
          />
        </Grid>
      )}
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>{headers(REFERRAL_TABLE_HEADERS)}</TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={REFERRAL_TABLE_HEADERS.length}>
                  <Box className={classes.loader}>
                    <Loader />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : isEmpty(filteredSearchData) && !errorMessage ? (
              <StyledTableRow>
                <StyledTableCell colSpan={REFERRAL_TABLE_HEADERS.length}>
                  <Box className={classes.loader}>
                    <Typography>No data found in table</Typography>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              (filteredSearchData || []).map((row: any, index: number) => (
                <StyledTableRow hover key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.referrerName}</StyledTableCell>
                  <StyledTableCell>{row.refereeName}</StyledTableCell>
                  <StyledTableCell>{row.refereeEmail}</StyledTableCell>
                  <StyledTableCell>
                    {moment(get(row, 'referralDate')).format('DD/MM/YYYY')}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: '#EA7445',
                      fontSize: 13,
                      fontWeight: 700,
                    }}
                  >
                    {REFERRAL_STATUS_TYPE[get(row, 'status')]}
                  </StyledTableCell>
                  <StyledTableCell>
                    {get(row, 'investedDealName') || '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {isEmpty(get(row, 'investedAmount'))
                      ? '-'
                      : formatAmount(get(row, 'investedAmount'))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {isEmpty(get(row, 'investedDate'))
                      ? '-'
                      : moment(get(row, 'investedDate')).format('DD/MM/YYYY')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {get(row, 'carryRewardAmount') > 0
                      ? formatAmount(get(row, 'carryRewardAmount'))
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell>{row.referralPage}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      name="Edit"
                      className={classes.tdBtn}
                      onClick={() => handleEditReferral(row)}
                      disabled={row.inviterEntity !== 'ADMIN' ? true : false}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddReferralType
        referralDialog={referralDialog}
        setReferralDialog={setReferralDialog}
        adminFilters={adminFilters}
        selectEditReferral={selectEditReferral}
        setSelectEditReferral={setSelectEditReferral}
        editMode={editMode}
        setEditMode={setEditMode}
        getReferralDataList={getReferralDataList}
      />
    </Box>
  );
};

export default ReferralData;
