import React from 'react';
import { TextField, MenuItem, BaseTextFieldProps } from '@material-ui/core';
import { isEmpty, get } from 'lodash';

import { ArrayType, FN } from 'common/types';

import { HtmlTooltip } from '../Tooltip';
import styles from './styles';

interface Props extends BaseTextFieldProps {
  onChange: FN;
  className: string;
  label?: string;
  placeholder?: string;
  dropdowns: ArrayType[];
  InputLabelProps: any;
  InputProps: any;
  SelectProps?: any;
  value: string;
  variant?: 'standard' | 'filled' | 'outlined';
  error?: boolean;
  menuClassName?: string;
  disabled?: boolean;
  onClickChildren?: any;
}

const SelectInput = ({
  onChange,
  className,
  label,
  value = '',
  dropdowns,
  InputProps,
  InputLabelProps,
  SelectProps,
  variant,
  error,
  menuClassName,
  disabled,
  placeholder,
  onClickChildren,
  ...props
}: Props) => {
  const classes = styles();
  return (
    <TextField
      select
      label={label}
      name="ReactHookFormSelect"
      className={className}
      value={value}
      onChange={onChange}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      margin="normal"
      variant={variant ? variant : 'standard'}
      error={error}
      disabled={disabled}
      SelectProps={{
        displayEmpty: true,
        ...SelectProps,
        renderValue: (selected: any) => {
          if (
            typeof selected === 'undefined' ||
            [null, undefined, '', {}, []].includes(selected)
          ) {
            return (
              <em className={classes.placeHolder}>
                {isEmpty(label) ? placeholder : ''}
              </em>
            );
          }
          return (
            dropdowns.find((item) => item.value === selected)?.text || selected
          );
        },
      }}
      {...props}
    >
      {dropdowns &&
        dropdowns.map((i: ArrayType, n: number) =>
          !isEmpty(get(i, 'childrens')) ? (
            <MenuItem
              value={i.value}
              key={n}
              disabled={i.disabled}
              className={menuClassName}
            >
              <HtmlTooltip
                //arrow={true}
                placement="left-end"
                open={true}
                title={
                  <div>
                    {get(i, 'childrens').map((item: any) => (
                      <MenuItem
                        disabled={item.disabled}
                        onClick={() =>
                          onClickChildren ? onClickChildren(item.value) : null
                        }
                        key={item.value}
                        value={i.value}
                        className={menuClassName}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </div>
                }
              >
                <span>{i.text}</span>
              </HtmlTooltip>
            </MenuItem>
          ) : (
            <MenuItem
              value={i.value}
              disabled={i.disabled}
              key={n}
              className={menuClassName}
            >
              <span style={{ color: error && value === i.value ? 'red' : '' }}>
                {i.text}
              </span>
            </MenuItem>
          ),
        )}
    </TextField>
  );
};
export default SelectInput;
