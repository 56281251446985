import { IRoute } from 'common/types';
import AuthLayout from 'layouts/AuthLayout';

import SuccessForgotPassword from './successForgotPassword';

export const SuccessForgotPasswordRoute: IRoute = {
  path: '/success',
  component: SuccessForgotPassword,
  layout: AuthLayout,
  exact: true,
};
