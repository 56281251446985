import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';

import { RootState } from 'redux-modules/store/rootState';
import { SuccessPage } from 'components';
import { MailImage } from 'assets/index';

const useStyles = makeStyles((theme) => ({
  successHeaderTxt: {
    fontSize: 20,
    color: '#262A41',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
  },
  successSubTxt: {
    fontSize: 15,
    color: '#404852',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    marginTop: 15,
  },
}));

const ForgotPasswordSuccess = () => {
  const { successMessage } = useSelector(
    ({ Auth }: RootState) => Auth.forgotPassword,
  );

  const classes = useStyles();

  return (
    <SuccessPage img={MailImage}>
      <Typography
        gutterBottom
        variant="h4"
        className={classes.successHeaderTxt}
      >
        We have sent a password reset link to
      </Typography>
      <Typography
        gutterBottom
        variant="h4"
        className={classes.successHeaderTxt}
      >
        {successMessage}
      </Typography>
      <Typography variant="body1" className={classes.successSubTxt}>
        Click on the ‘Reset Password’ link sent to your email ID and create a
        new password
      </Typography>
    </SuccessPage>
  );
};

export default ForgotPasswordSuccess;
