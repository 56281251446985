import React, { useEffect } from 'react';
import { Typography, InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Controller } from 'react-hook-form';
import { get, isEmpty } from 'lodash';

import { WrappedPriceInput, WrappedTextInput } from 'components';
import { getParsedPriceInputValue } from 'common/utils/helpers';

import styles from '../../styles';

const ReviewLoanForm = ({
  control,
  loanTerms,
  setValue,
  disabled,
  setShowMfn,
  showMfn,
  ycBatch = '',
}: any) => {
  const classes = styles();

  const [safeCover, setSafeCover] = React.useState<any>({
    value: get(loanTerms, 'safeCover'),
    loanAmount: get(loanTerms, 'loanAmount'),
    percentageBonusSafe: get(loanTerms, 'percentageBonusSafe'),
  });

  useEffect(() => {
    if (!isEmpty(get(loanTerms, 'loanType')))
      setValue('loanType', get(loanTerms, 'loanType'));
  }, [loanTerms]);

  useEffect(() => {
    const disableMfnBactch = process.env.REACT_APP_MFN_DISABLE_BATCHES;
    if (disableMfnBactch) {
      const disableMfnBactchList = disableMfnBactch.split(',');
      setShowMfn(!disableMfnBactchList.includes(ycBatch));
    }
  }, [ycBatch]);

  return (
    <div>
      {!isEmpty(loanTerms) && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Loan Amount
              </Typography>
              <Controller
                name="loanAmount"
                control={control}
                rules={{ required: true }}
                defaultValue={get(loanTerms, 'loanAmount')}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedPriceInput
                      currencySymbol="$"
                      label=""
                      placeholder="Please enter loan amount"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) => {
                        const val = getParsedPriceInputValue(
                          get(e, 'target.value'),
                        );
                        setSafeCover({
                          ...safeCover,
                          loanAmount: val,
                        });
                        const tempSafeCover =
                          (val * get(safeCover, 'percentageBonusSafe')) / 100;
                        setValue('safeCover', tempSafeCover, {
                          shouldValidate: true,
                        });
                        onChange(e);
                      }}
                      error={error?.message ? true : false}
                      disabled={disabled}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Loan Tenure (Months)
              </Typography>
              <Controller
                name="loanTenure"
                control={control}
                defaultValue={get(loanTerms, 'loanTenure')}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="number"
                      placeholder="Please enter tenure"
                      className={classes.textValInput}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled={disabled}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Percentage Bonus SAFE
              </Typography>
              <Controller
                name="percentageBonusSafe"
                defaultValue={get(loanTerms, 'percentageBonusSafe')}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="number"
                      label=""
                      placeholder="Please enter percentage"
                      className={classes.textValInput}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: { root: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={(e: any) => {
                        setSafeCover({
                          ...safeCover,
                          percentageBonusSafe: get(e, 'target.value'),
                        });
                        const tempSafeCover =
                          (get(e, 'target.value') *
                            get(safeCover, 'loanAmount')) /
                          100;
                        setValue('safeCover', tempSafeCover, {
                          shouldValidate: true,
                        });
                        onChange(e);
                      }}
                      error={error?.message ? true : false}
                      disabled={disabled}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                SAFE Cover
              </Typography>
              <Controller
                name="safeCover"
                control={control}
                defaultValue={get(loanTerms, 'safeCover')}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedPriceInput
                      label=""
                      currencySymbol="$"
                      placeholder="SAFE Cover"
                      className={classes.textValInput}
                      InputProps={{
                        classes: { root: classes.inputRoot },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled={true}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Cash Interest Rate
              </Typography>
              <Controller
                name="cashInterestRate"
                control={control}
                defaultValue={get(loanTerms, 'cashInterestRate') || 15}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="number"
                      placeholder="Please enter rate"
                      className={classes.textValInput}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: { root: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                      error={error?.message ? true : false}
                      disabled={disabled}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="caption"
                className={classes.companyLabelText}
              >
                Processing Fees
              </Typography>
              <Controller
                name="processingFees"
                control={control}
                defaultValue={get(loanTerms, 'processingFees')}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <WrappedTextInput
                      type="number"
                      placeholder="Please enter processing fees"
                      value={value}
                      onChange={onChange}
                      className={classes.textValInput}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: { root: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                          focused: classes.labelFocused,
                        },
                      }}
                      error={!!error?.message}
                      disabled={disabled}
                    />
                    {error?.message && (
                      <span className={classes.errorText}>
                        {error?.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            {showMfn && get(loanTerms, 'loanType') === 'BRIDGE_TO_DEMO_DAY' && (
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Valuation for converting into SAFE (with MFN)
                </Typography>
                <Controller
                  name="valuation"
                  control={control}
                  defaultValue={get(loanTerms, 'valuation')}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedPriceInput
                        value={value}
                        currencySymbol="$"
                        onChange={onChange}
                        label=""
                        placeholder="Please enter amount"
                        className={classes.textValInput}
                        InputProps={{
                          classes: { root: classes.inputRoot },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        error={!!error?.message}
                        disabled={disabled}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default ReviewLoanForm;
