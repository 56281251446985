import React, { useEffect, useState } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { Box } from 'components';
import { IIllustrationImg, IIlustraImg } from 'assets';
import { startupUpcomingPayment, getStartupBridgeLoanStatus } from 'services';
import { errorMessageHandler, formatAmount } from 'common/utils/helpers';

import styles from './styles';

const DashboardSider = ({ isRoleAdmin }: any) => {
  const classes = styles();
  const [paymentData, setPaymentData] = useState<any>({});
  const [loanData, setLoanData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (!isRoleAdmin) {
      setIsLoading(true);
      startupUpcomingPayment()
        .then((resPayment: any) => {
          setPaymentData(resPayment);
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.log('ERROR WHILE GETTING UPCOMING PAYMENTS: ', message);
          // setErrorMessage(message);
          setIsLoading(false);
        });
      getStartupBridgeLoanStatus()
        .then((resLoan: any) => {
          setLoanData(resLoan);
          setIsLoading(false);
        })
        .catch((err: any) => {
          const message: string = errorMessageHandler(err);
          // eslint-disable-next-line no-console
          console.log('ERROR WHILE GETTING LOAN STATUS: ', message);
          // setErrorMessage(message);
          setIsLoading(false);
        });
    }
  }, [isRoleAdmin]);

  const getLoanStatus = () => {
    const loanCloserStatus = get(loanData, 'loanClosure.status');
    if (['CLOSED', 'CONVERTED', 'EXTENDED'].includes(loanCloserStatus)) {
      return loanCloserStatus;
    }
    return get(loanData, 'loanStatus');
  };
  return (
    <>
      <Box className={classes.dashWelRightContent}>
        {!isRoleAdmin && !isLoading && (
          <>
            {!isEmpty(paymentData) && (
              <Box className={classes.paymentContBox}>
                <Typography gutterBottom className={classes.longTermText}>
                  Loan Term
                </Typography>
                <Box className={classes.tenureProgress}>
                  <CircularProgressbarWithChildren
                    value={
                      get(paymentData, 'totalTenure') -
                      get(paymentData, 'noOfPaidInstallment')
                    }
                    maxValue={get(paymentData, 'totalTenure')}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      pathColor: `#E0987C`,
                      trailColor: '#CDE8DD',
                    })}
                  >
                    <Typography
                      className={classes.monthCountText}
                      variant="body1"
                    >
                      {get(paymentData, 'totalTenure') -
                        get(paymentData, 'noOfPaidInstallment')}
                      /{get(paymentData, 'totalTenure')}
                    </Typography>
                    <Typography className={classes.monthText} variant="body1">
                      Months Remaining
                    </Typography>
                  </CircularProgressbarWithChildren>
                </Box>
                <Typography
                  gutterBottom
                  className={classes.upcomingPaymentText}
                >
                  Upcoming Payments
                </Typography>
                <Box className={classes.paymentDataBox}>
                  <Box className={classes.paymentDataLeft}>
                    <Typography className={classes.dateText} variant="body1">
                      {moment(get(paymentData, 'lastInstallmentDate')).format(
                        'DD MMMM YYYY',
                      )}
                    </Typography>
                    {/* <Typography className={classes.installmentText} variant="body1">
                      Installment {get(paymentData, 'noOfPaidInstallment')}
                    </Typography> */}
                  </Box>
                  <Box className={classes.paymentDataRight}>
                    <Typography className={classes.amountText} variant="body1">
                      {formatAmount(get(paymentData, 'installment'))}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {!isEmpty(loanData) && get(loanData, 'loanStatus') && (
              <>
                <Typography variant="h5" className={classes.status}>
                  Status
                </Typography>
                <Box className={classes.statusContBox}>
                  <Typography className={classes.statusText} variant="h5">
                    Loan {getLoanStatus()}
                  </Typography>
                  {get(loanData, 'reason') && (
                    <Typography className={classes.ticketText} variant="h5">
                      <span className={classes.statusText}>Reason - </span>
                      {get(loanData, 'reason')}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </>
        )}

        <Box className={classes.dashWelRightContBox}>
          <img
            src={IIllustrationImg}
            alt={IIllustrationImg}
            className={classes.illustateImg}
          />
          <img
            src={IIlustraImg}
            alt={IIlustraImg}
            className={classes.iIlustraImg}
          />
          <Typography className={classes.ticketText} variant="h5">
            No Tickets Pending
          </Typography>
          <Typography className={classes.ticketSubText} variant="body1">
            {/* eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim. */}
          </Typography>
          {/* <Button className={classes.showBtn} name="Show" /> */}
        </Box>
      </Box>
    </>
  );
};

export default DashboardSider;
