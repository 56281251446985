import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  fundRaisingContainer: {
    height: 'calc(100vh - 80px)',
    // width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    overflow: 'scroll',
    // marginBottom: 120,
  },
  fundRaisingHead: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 10,
    '@media (max-width: 1024px)': {
      padding: '0 80px 20px 20px',
    },
    '@media (max-width: 767px)': {
      padding: '40px 20px 20px 55px',
    },
  },
  // vFundRaisingHeaderFixed: {
  //   // position: 'fixed',
  //   zIndex: theme.zIndex.appBar - 101,
  //   // paddingLeft: 20,
  //   backgroundColor: theme.palette.common.white,
  //   width: 'calc(100% - 341px)',
  //   '@media (max-width: 767px)': {
  //     position: 'relative',
  //     width: '100%',
  //   },
  //   '@media (max-width: 1024px)': {
  //     position: 'relative',
  //     width: '100%',
  //   },
  //   '@media (min-width: 1025px && max-width: 1220px)': {
  //     width: 'calc(100% - 265px)',
  //   },
  // },
  vFundRaisingHeadNew: {
    '@media (max-width: 767px)': {
      padding: '0 20px 20px 0',
    },
  },
  enquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 43,
    left: 40,
    '@media (max-width: 1024px)': {
      top: 75,
      left: 5,
      objectFit: 'cover',
    },
    '@media (max-width: 767px)': {
      top: 23,
      left: 20,
      objectFit: 'cover',
    },
  },
  headText: {
    fontSize: 29,
    color: theme.palette.warning.contrastText,
    fontWeight: 400,
    marginTop: 10,
    letterSpacing: 0.3,
    marginBottom: 15,
    position: 'relative',
    width: '60%',
    '@media (max-width: 1024px)': {
      top: 'unset',
      width: '65%',
    },
  },
  headTextNote: {
    background: '#E4F4F9',
    padding: 5,
    borderRadius: 2,
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: 0.3,
    marginLeft: 10,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
    // padding: '0px 45px',
  },
  successText: {
    color: theme.palette.success.main,
    fontSize: 12,
    // padding: '0px 45px',
  },
  fundRaisingContent: {},
  fundRaisingTableTabContainer: {},
  vFundRaisingTableTabContainerNew: {
    // paddingTop: '30px',
    '@media (max-width: 767px)': {
      paddingTop: '0px',
    },
    '@media (max-width: 1024px)': {
      paddingTop: '0px',
    },
  },
  actionContentText: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  enquiryTabs: {
    borderBottom: '0.5px solid #DEDEDE',
  },

  actionsBodyPaddingTop: {
    paddingTop: '30px',
  },
  buttonsGrid: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 32,
  },
  vButtonGridNew: {
    paddingRight: 0,
    justifyContent: 'flex-end',
    paddingLeft: 55,
    '@media (max-width: 1023px)': {
      paddingRight: 0,
      paddingLeft: 0,
      marginTop: 15,
    },
  },
  searchBox: {
    border: '1px solid #E5E5E5',
    width: 264,
    height: 38,
    borderRadius: 31,
    marginRight: 15,
    backgroundColor: theme.palette.common.white,
    marginLeft: 73,
  },
  vSearchBoxNew: {
    marginLeft: 0,
    '@media (max-width: 767px)': {
      marginLeft: 0,
      width: 'auto',
    },
  },
  inputSear: {
    width: 'calc(100% - 42px)',
    paddingLeft: 14,
  },
  iconButton: {
    padding: 10,
  },
  loadingIcon: {
    height: 5,
    width: 5,
  },
  saveIconBox: {
    paddingTop: 5,
    paddingLeft: 5,
  },
  iconSaveButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  addNewInvestor: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '15px 16px',
    borderRadius: 50,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    width: 220,
    height: 38,
    marginRight: 4,
    lineHeight: '20px',
    // marginLeft: 88,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '@media (max-width: 1024px)': {
      fontSize: 15,
      width: 160,
    },
    '@media (max-width: 767px)': {
      fontSize: 12,
      lineHeight: '12px',
    },
  },
  inputHeadFull: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px !important',
    position: 'relative',
  },
  companyLabelText: {
    fontSize: 15,
    color: theme.palette.grey[50],
  },
  textValInput: {
    // marginBottom: 20,
    fontSize: 14,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },

  editTextValInput: {
    marginBottom: 20,
    fontSize: 14,
    marginTop: 0,
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectValInput: {
    // marginBottom: 25,
    width: '100%',
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectInlineInput: {
    // marginBottom: 25,
    width: '100%',
    marginBottom: 0,
    marginTop: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 14,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  fcrmInputRoot: {
    width: '100%',
    fontSize: 14,
    marginBottom: -20,
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black', // (default alpha is 0.38)
    },
    // '&:hover': {
    //   '&:not(.Mui-disabled)::before': {
    //     backgroundColor: '#F8F8F8',
    //     color: 'black',
    //   },
    // },
  },
  fcrmErrorInputRoot: {
    width: '100%',
    fontSize: 14,
    marginBottom: -20,
    color: 'red',
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'red', // (default alpha is 0.38)
    },
    // '&:hover': {
    //   '&:not(.Mui-disabled)::before': {
    //     backgroundColor: '#F8F8F8',
    //     color: 'black',
    //   },
    // },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 800,
    width: 170,
    fontSize: 15,
    cursor: 'pointer',
    height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  backBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 120,
    fontSize: 16,
    height: 48,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginLeft: 20,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '@media (max-width: 525px)': {
      width: 100,
      height: 40,
    },
  },
  backContinueBox: {
    marginTop: 10,
  },
  centerBtnContainer: {
    textAlign: 'center',
  },
  // tableContainer: {
  //   boxShadow: theme.shadows[0],
  //   height: 'calc(100% - 185px)',
  //   '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
  //     backgroundColor: '#1EA59A',
  //     width: '0.6em',
  //     visibility: 'hidden',
  //   },
  //   '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
  //     borderRadius: 8,
  //     backgroundColor: '#F6FAFA',
  //     minHeight: 24,
  //     border: '1px solid #1EA59A',
  //     visibility: 'hidden',
  //   },
  //   '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
  //     backgroundColor: '#2B2B2B',
  //   },
  //   '& .MuiTable-root': {
  //     // height: '100%',
  //   },
  //   '& .MuiTableBody-root': {
  //     height: 'calc(100% - 73px)',
  //   },
  //   '& .MuiTableCell-root': {
  //     padding: '16px 16px 10px',
  //   },
  // },
  tableHead: {
    background: theme.palette.grey[200],
  },
  tableContentRow: {},
  ///// Table qa

  inputHead: {
    padding: '17px 40px 0px 12px !important',
    '@media (max-width: 1120px)': {
      padding: '12px 20px 0px 12px !important',
    },
    '@media (max-width: 960px)': {
      padding: '12px 12px 0 !important',
    },
  },
  tableStyle: {
    minWidth: 900,
  },
  listingBox: {
    marginBottom: 80,
    // overflowY: 'auto',
    // marginTop: 50,
    // position: 'relative',
    // overflowY: 'auto',
    // overflowX: 'scroll',
    // '@media (max-width: 1024px)': {
    //   padding: '0px 20px 0px',
    // },
    // '@media (max-width: 767px)': {
    //   height: 'auto',
    //   padding: '0px 20px 25px',
    // },
    //overflow: 'hidden',
    //width: '100%',
    //marginTop: 20,
    // paddingRight: 50,
    // paddingLeft: 50,
  },
  tableContainerDrag: {
    boxShadow: theme.shadows[0],
    overflowX: 'initial',
    position: 'sticky',
    zIndex: theme.zIndex.appBar - 100,
    borderRadius: '1em 1em 0 0',
    // height: 'calc(100vh - 400px)',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#1EA59A',
      width: '0.6em',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#F6FAFA',
      minHeight: 24,
      border: '1px solid #1EA59A',
      visibility: 'hidden',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#2B2B2B',
    },
    // '& .MuiTable-root': {
    //   // height: '100%',
    // },
    // '& .MuiTableBody-root': {
    //   height: 'calc(100% - 73px)',
    // },
    // '& .MuiTableCell-root': {
    //   padding: '16px 16px 10px',
    // },
    '@media (max-width: 768px)': {
      height: 'auto',
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tableHeaderStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
    left: 0,
  },
  tableBodyStickyCell: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.common.white,
    left: 0,
  },
  prevHeaderCell: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
    width: 32,
    zIndex: theme.zIndex.appBar + 3,
    // top: '5em',
    // left: '130px',
    // position: 'absolute',
  },

  nextHeaderCell: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
    width: 32,
    zIndex: theme.zIndex.appBar + 3,
    // top: '5em',
    // right: '30px',
    // position: 'absolute',
  },
  nextPrevIconButton: {
    padding: 5,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  inputClasses: {
    display: 'none',
  },
  safeTabContainer: {
    width: '100%',
  },
  safeButtonStyle: {
    width: '40%',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginLeft: '120px',
    marginTop: '40px',
    marginBottom: '20px',
  },
  safeTabTableGrid: {
    overflowX: 'auto',
    padding: '10px 20px 0px',
    '@media (max-width: 767px)': {
      padding: '10px 0px 0px',
    },
  },
  safeTabTableData: {
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    font: 'normal 13px Arial, sans-serif',
    width: '100%',
    height: 110,
    textAlign: 'center',
    padding: '50px',
  },
  tableDesign: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderCollapse: 'collapse',
    textAlign: 'center',
    '& .MuiTableCell-root': {
      display: 'table-cell',
      padding: '16px',
      fontSize: '9px',
      textAlign: 'center',
      fontFamily: 'mulish',
      fontWeight: 400,
      lineHeight: 1.43,
      verticalAlign: 'inherit',
      border: '1px solid #E5E5E5',
    },
  },
  selectValInputList: {
    width: '100%',
    marginTop: 0,
    border: 'none',
    textSize: '8px',
    '& .MuiPopover-paper': {
      width: '28%',
      textAlign: 'center',
      boxShadow: theme.shadows[1],
    },
    // '& .MuiMenuItem-root': {
    //   marginLeft: '120px',
    // },
  },
  saveTableButton: {
    marginTop: '20px',
    paddingBottom: '10px',
    marginLeft: '250px',
  },
  labelValue: {
    '& .MuiInputLabel-animated': {
      fontSize: '11px',
      marginTop: '25px',
    },
  },
  inputValueHolder: {
    marginLeft: '50px',
  },
  labelValueStyle: {
    '& .MuiInputLabel-animated': {
      fontSize: '8px',
    },
  },
  priceRoundText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textAlign: 'center',
  },
  backTag: {
    color: '#fff',
    background: '#14a871',
    padding: '3px',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: '5px',
  },
  dialogTitle: {
    textAlign: 'center',
    fontSize: '20px',
    paddingBottom: '20px',
  },
  inputHeadText: {
    lineHeight: '25px',
  },
  formCheckbox: {
    paddingTop: '20px',
  },
  addButtonBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  hideField: {
    height: 0,
    width: 0,
    display: 'none',
  },
  normalField: {
    lineHeight: '25px',
  },
  TableCell: {
    '.MuiTableCell-head': {
      padding: '-10px',
    },
  },
  summaryAccordRight: {
    // width: '50%',
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  accordSendBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    // padding: '12px 16px',
    borderRadius: 5,
    fontWeight: 600,
    width: 150,
    fontSize: 15,
    cursor: 'pointer',
    marginRight: 10,
    height: 36,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  borderRightCell: {},
  plusAccord: {
    display: 'flex',
    flexDirection: 'row',
  },
  accordText: {
    paddingLeft: 5,
    fontSize: 16,
    fontWeight: 700,
  },
  accordPlusSign: {
    color: '#979797',
  },

  iconStyle: {
    color: '#757575',
    fontSize: 25,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginRight: 8,
    borderRadius: 7,
  },

  vPaneMobile: {
    transform: 'translateX(100%) translateX(-50vw) !important',
    width: '50% !important',
    '@media (max-width: 767px)': {
      transform: 'translateX(100%) translateX(-100vw) !important',
      width: '100% !important',
    },
    '@media (min-width: 768px && max-width: 1024px)': {
      transform: 'translateX(100%) translateX(-75vw) !important',
      width: '75% !important',
    },
  },

  sidePaneWrapper: {
    padding: 40,
    overflowY: 'scroll',
    height: 'calc(100vh - 80px)',
    '@media (max-width: 767px)': {
      padding: 15,
    },
  },
  iconsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  investorNameEdit: {
    '& input': {
      fontSize: '1.5rem',
    },
  },

  statusForm: {
    marginTop: '2em',
  },

  investmentDetails: {
    marginTop: '1em',
  },

  investorSubTitle: {
    margin: '1em 0',
  },

  contactDetailsForm: {
    boxShadow: '0px 10px 26px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    padding: '1em',
  },

  investorInputBox: {
    marginBottom: 20,
    '&:nth-child(even)': {
      paddingLeft: '.5em',
    },
    '&:nth-child(odd)': {
      paddingRight: '.5em',
    },
  },

  investorFormBtns: {
    textAlign: 'left',
    marginTop: 10,
  },
  vInvestorFormBtnsNew: {
    display: 'flex',
  },
  selectFieldDeleteIcon: {
    marginTop: -120,
    marginLeft: 50,
  },
  contactInputBox: {
    paddingRight: '.5em',
  },

  primaryBox: {
    textAlign: 'center',
    lineHeight: 0,
    fontSize: '12px',
    marginLeft: 10,
    marginRight: 0,
  },

  //Custom Switch Style
  switchBox: {
    marginTop: 25,
    marginBottom: 25,
  },
  switchRoot: {
    width: '20px',
    height: '10px',
    padding: '0px',
    overflow: 'visible',
  },
  switchBase: {
    padding: '1px',
    '&$checked': {
      '& $thumb': {
        background: theme.palette.primary.main,
        margin: '2px -1px',
      },
    },
  },
  thumb: {
    color: 'grey',
    width: '8px',
    height: '8px',
    margin: '2px',
    borderRadius: 100,
  },
  track: {
    background: 'none !important',
    border: '2px solid #2d2d2d',
    borderRadius: '100px',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 12,
    },
  },
  checked: {
    color: theme.palette.primary.main,
    transform: 'translateX(10px) !important',
  },

  investorPrimaryBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '5px 20px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    fontSize: 13,
    marginRight: 5,
    cursor: 'pointer',
    // height: 48,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    // '@media (max-width: 525px)': {
    //   width: 100,
    //   height: 40,
    // },
  },
  investorSecondaryBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    padding: '5px 20px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 75,
    fontSize: 13,
    // height: 48,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  vMB10: {
    '@media (max-width: 403px)': {
      marginBottom: 10,
    },
  },
  tinyText: {
    fontSize: 10,
  },
  warningText: {
    color: '#EF4836',
    textAlign: 'center',
  },
  tabItem: {
    justifyContent: 'center',
  },
  tabIcon: {
    marginTop: 3,
    marginRight: 2,
    marginLeft: 2,
  },
  editRoundToolTip: {
    borderRadius: 10,
    height: 100,
    width: 297,
    background: 'white',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: 15,
    color: 'black',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltipBigText: {
    fontSize: 18,
  },
  tooltipRegularText: {
    fontSize: 18,
  },
  tooltipSmallText: {
    fontSize: 14,
    marginTop: 40,
    marginBottom: 15,
  },
  tooltipBtn: {
    background: theme.palette.primary.main,
    padding: 10,
    color: 'white',
    marginTop: -15,
    marginRight: -15,
    borderBottomLeftRadius: 10,
    borderTopRightRadius: 10,
    fontSize: 16,
    width: 50,
    textAlign: 'center',
    cursor: 'pointer',
  },
  stageSummary: {
    fontWeight: 800,
    fontSize: 15,
    color: '#4a4646',
  },

  deleteBtn: {
    background: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    height: 28,
    fontSize: 12,
    cursor: 'pointer',
    borderRadius: 12,
    marginLeft: 15,
    alignSelf: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  vInvestorName: {
    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  selectFieldBox: {
    display: 'flex',
    alignItems: 'center',
  },
  blurChart: {
    pointerEvents: 'none',
    opacity: 0.8,
    filter: 'blur(1px)',
  },
  chartWrap: {
    marginBottom: 20,
    // paddingBottom: 20,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    borderRadius: 11,
    display: 'flex',
    overflowX: 'scroll',
    overflowY: 'hidden',
    paddingRight: '20px',
    width: '100%',
    '@media (max-width: 700px)': {
      display: 'block',
    },
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#FFFCF9',
      width: '0.5em',
      height: '.5em',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#d3d3d3',
      minHeight: 24,
      // border: '1px solid #1EA59A',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      // backgroundColor: '#2B2B2B',
    },
  },
  funnelChartWrap: {
    borderRight: '1px solid #0000000a',
    height: 380,
    width: 540,
  },
  funnelChartBox: {
    paddingLeft: 50,
    marginTop: 35,
    marginBottom: 10,
  },
  guageChartBox: {
    margin: 'auto',
    // width: '82%',
    height: 320,
    maxWidth: 400,
    position: 'relative',
    marginTop: 45,
  },
  guageHeadBox: {
    paddingLeft: 20,
    marginTop: 35,
    marginBottom: -10,
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 20,
  },
  primaryContactErr: {
    fontSize: 12,
    marginTop: 5,
    color: theme.palette.error.main,
  },
  vLabelText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    marginBottom: 8,
  },
  vCountryMobileSection: {
    display: 'flex',
  },
  vCountryCode: {
    width: 'min-content',
  },
  vMobileLeftSpacing: {
    marginLeft: '20px',
  },

  pageHeader: {
    paddingLeft: 19,
    '@media (max-width: 1024px)': {
      padding: '50px 15px 15px',
    },
  },
  propertyBtnGrid: {
    '@media (min-width: 1280px)': {
      justifyContent: 'flex-end',
    },
    '@media (max-width: 1280px)': {
      justifyContent: 'flex-start',
    },
  },

  vFcrmListsSection: {
    padding: '30px',
    '@media (max-width: 1024px)': {
      marginTop: '50px',
      padding: '30px 15px',
    },
    '@media (max-width: 767px)': {
      marginTop: '0px',
    },
  },
  vAllFcrmHead: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 80px 20px 45px',
    '@media (max-width: 1024px)': {
      padding: '0 80px 20px 20px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 20px 20px 55px',
    },
  },
  vEnquiryImage: {
    height: 80,
    width: 90,
    position: 'absolute',
    top: 43,
    left: 30,
    '@media (max-width: 1024px)': {
      top: 75,
      left: 5,
      objectFit: 'cover',
    },
    '@media (max-width: 767px)': {
      top: 23,
      left: 20,
      objectFit: 'cover',
    },
  },
  vAllFcrmCount: {
    margin: '20px 0 20px 0',
    color: '#1EA59A',
    fontSize: '18px',
  },
  tableContainer: {
    borderRadius: '10px 10px 0 0',
    maxHeight: '62vh',
    backgroundColor: theme.palette.common.white,
  },
  vTypicalBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  vDetailBtn: {
    display: 'flex',
    justifyContent: 'center',
  },
  viewButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 14,
    border: '1px solid #1EA59A',
    borderRadius: 6,
    color: theme.palette.secondary.main,
    padding: '4px 0px 4px',
    margin: '5px 0px',
    cursor: 'pointer',
    width: '40%',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      width: '80%',
    },
  },
  vTableHeader: {
    display: 'block',
    alignItems: 'center',
  },
  vTextCenter: {
    textAlign: 'center',
  },
  vPaginationSection: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  vTopHeadSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  vSelectFcrm: {
    width: '130px',
  },
  vLeftHeadSide: {
    display: 'flex',
    alignItems: 'center',
  },
  vSelectValInput: {
    // marginBottom: 25,
    width: '180px',
    marginTop: 0,
    marginRight: 15,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  vInputValues: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  vEmailTempBtn: {
    marginRight: 5,
  },
  vSaveTemplateBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveTemplateSecondaryBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    padding: '3px 10px',
    borderRadius: 10,
    fontFamily: 'Mulish',
    fontWeight: 600,
    width: 'auto',
    fontSize: 13,
    // height: 48,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    marginTop: 15,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    transform: 'translate(0, 24px) scale(1)',
  },
  titleHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  errorAlert: {
    marginLeft: 20,
    alignItems: 'center',
    height: 30,
    width: '40%',
    '@media (max-width: 1024px)': {
      width: '35%',
      marginLeft: 0,
    },
  },
  invText: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 5,
  },
  ownInvestorText: {
    background: theme.shadows[7],
    borderRadius: 3,
    fontSize: 8,
    fontWeight: 700,
    padding: 3,
    color: theme.palette.common.white,
  },
  adminText: {
    background: theme.shadows[8],
    borderRadius: 3,
    fontSize: 8,
    fontWeight: 700,
    padding: 3,
    color: theme.palette.common.white,
  },
  vcRequestText: {
    background: theme.shadows[4],
    borderRadius: 3,
    fontSize: 8,
    fontWeight: 700,
    padding: 3,
    color: theme.palette.common.white,
  },
  angelInvText: {
    background: theme.shadows[6],
    borderRadius: 3,
    fontSize: 8,
    fontWeight: 700,
    padding: 3,
    color: theme.palette.common.white,
  },
}));
