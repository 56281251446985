import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { isEmpty, get } from 'lodash';
import { useSelector } from 'react-redux';

import history from 'common/utils/history';
import { Box, Button, Loader } from 'components';
import { Ornament } from 'assets';
import { getBasicFundRaisingCRM } from 'services';
import { RootState } from 'redux-modules/store/rootState';

import AddFCRMRoundDialog from './AddFCRMRoundDialog';
import styles from './styles';

const FundRaising = () => {
  const classes = styles();

  const [open, setOpen] = useState<boolean>(false);
  const { startupInfo } = useSelector(({ Enquiries }: RootState) => Enquiries);

  const [fetchingFcrm, setFetchingFcrm] = useState<boolean>(false);
  const [basicFcrmExist, setBasicFcrmExist] = useState<boolean>(false);
  const [emailReader, setEmailReader] = useState<string>('');
  const [basicFcrm, setBasicFcrm] = useState<any>([]);

  const openPreviewModel = () => {
    setOpen(true);
  };

  useEffect(() => {
    setFetchingFcrm(true);
    getBasicFundRaisingCRM()
      .then((res) => {
        if (
          !isEmpty(get(res, 'data')) &&
          get(res, 'data[0].roundName') !== 'EMPTY'
        ) {
          setBasicFcrmExist(true);
          history.push('/fund-raising-crm');
        } else {
          setBasicFcrm(get(res, 'data'));
          setEmailReader(get(res, 'emailReader'));
          setBasicFcrmExist(false);
        }
      })
      .finally(() => setFetchingFcrm(false));
  }, []);
  return (
    <>
      {fetchingFcrm && <Loader />}
      {!basicFcrmExist && !fetchingFcrm && (
        <Box className={classes.fundRaisingContainer}>
          <Box className={classes.fundRaisingContent}>
            <Box className={classes.fundRaisingHead}>
              <img
                src={Ornament}
                alt={Ornament}
                className={classes.enquiryImage}
              />
              <Typography variant="h6" className={classes.headText}>
                Welcome, {get(startupInfo, 'name')}
              </Typography>
            </Box>
            <Box className={classes.fundRaisingListData}>
              {isEmpty(basicFcrm) ? (
                <>
                  <Box>
                    <Typography className={classes.fundRaisingListTitle}>
                      Fund Raising CRM
                    </Typography>
                    <ul className={classes.fundRaisingListContent}>
                      <li>Set up & track your fundraising process.</li>
                      <br />
                      <li>
                        Add new leads automate through email & track potential
                        investors.
                      </li>
                      <br />
                      <li>
                        Capture Investor's interests and add to your fundraising
                        funnel.
                      </li>
                      <br />
                      <li>
                        Keep a track of your % dilution with our proprietary
                        Dilution Meter.
                      </li>
                    </ul>
                  </Box>
                  <Button
                    className={classes.getStartedButton}
                    name={`Get Started`}
                    onClick={() => {
                      openPreviewModel();
                    }}
                  />
                </>
              ) : (
                <>
                  <Box>
                    <Typography className={classes.fundRaisingListTitle}>
                      Fund Raising CRM
                    </Typography>
                    <ul className={classes.fundRaisingListContent}>
                      <li>Keep a track of your fundraising process.</li>
                      <br />
                      <li>
                        Add new leads automate through email & track potential
                        investors.
                      </li>
                      <br />
                      <li>
                        Capture Investor's interests and add to your fundraising
                        funnel.
                      </li>
                      <br />
                      <li>
                        Keep a track of your % dilution with our proprietary
                        Dilution Meter.
                      </li>
                    </ul>
                    <Typography variant="h6" className={classes.leadText}>
                      All your leads are already added to your fundraising
                      funnel
                    </Typography>
                  </Box>
                  <Button
                    className={classes.getStartedButton}
                    name={`Get Started`}
                    onClick={() => {
                      openPreviewModel();
                    }}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {open && (
        <AddFCRMRoundDialog
          open={open}
          handleCloseFcrmRound={() => setOpen(false)}
          emailReader={emailReader}
          basicFcrm={basicFcrm}
        />
      )}
    </>
  );
};

export default FundRaising;
