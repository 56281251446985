import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  errorText: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  validateForm: {
    // marginTop: 20,
    // margin: '0px 5%',
  },
  startupFormFirstContainer: {
    background: theme.palette.common.white,
    borderRadius: '0 0 11px 11px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '20px 40px',
    '@media (max-width: 525px)': {
      padding: '15px 25px',
    },
  },
  startupFormFirstContainerStartup: {
    background: theme.palette.common.white,
    borderRadius: '11px',
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '20px 40px',
    '@media (max-width: 525px)': {
      padding: '15px 25px',
    },
  },
  startupFormSecondContainer: {
    marginTop: 20,
    background: theme.palette.common.white,
    borderRadius: 11,
    boxShadow: '0px 9px 6px rgba(0, 0, 0, 0.06)',
    padding: '20px 40px',
    '@media (max-width: 525px)': {
      padding: '15px 25px',
    },
  },
  headingText: {
    marginBottom: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '45px',
    letterSpacing: '0.3px',
    color: theme.palette.common.black,
  },
  gridContainer: {
    '& .MuiGrid-item': {
      marginBottom: -12,
      '&:nth-of-type(odd)': {
        padding: '12px 40px 12px 12px !important',
        '@media (max-width: 1120px)': {
          padding: '12px 20px 12px 12px !important',
        },
        '@media (max-width: 960px)': {
          padding: '12px !important',
        },
      },
      '&:nth-of-type(even)': {
        padding: '12px 12px 12px 40px !important',
        '@media (max-width: 1120px)': {
          padding: '12px 12px 12px 20px !important',
        },
        '@media (max-width: 960px)': {
          padding: '12px !important',
        },
      },
    },
  },
  inputHead: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    // padding: '12px 40px 12px 12px !important',
    // '@media (max-width: 1120px)': {
    //   padding: '12px 20px 12px 12px !important',
    // },
    // '@media (max-width: 960px)': {
    //   padding: '12px !important',
    // },
  },
  inputHeadFull: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px !important',
    position: 'relative',
  },
  inputHeadRight: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    // padding: '12px 12px 12px 40px !important',
    // '@media (max-width: 1120px)': {
    //   padding: '12px 12px 12px 20px !important',
    // },
    // '@media (max-width: 960px)': {
    //   padding: '12px !important',
    // },
  },
  companyLabelText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    '@media (max-width: 960px)': {
      fontSize: 13.5,
      lineHeight: '16px',
      minHeight: 35,
      display: 'block',
    },
  },
  textValInput: {
    marginBottom: 25,
    marginTop: 7,
    width: '100%',
    '@media (max-width: 1120px)': {
      marginBottom: 15,
      marginTop: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000de',
    },
  },
  selectValInput: {
    marginBottom: 25,
    width: '100%',
    marginTop: 7,
    '@media (max-width: 1120px)': {
      marginBottom: 15,
      marginTop: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputRoot: {
    width: '100%',
    fontSize: 15,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    // fontFamily: 'Mulish',
    // fontWeight: 400,
    // fontSize: 15,
    // letterSpacing: 0.35,
    // top: -7,
  },
  labelFocused: {
    // fontFamily: theme.typography.fontFamily,
    // fontWeight: 400,
    // fontSize: 15,
    // transform: 'translate(0, 1.5px) scale(1)',
  },
  removeText: {
    fontSize: 14,
    color: theme.palette.grey[700],
    cursor: 'pointer',
  },
  addFound: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    padding: '4px 0px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    fontSize: 16,
    letterSpacing: 0.5,
    cursor: 'pointer',
    border: 0,
    textTransform: 'capitalize',
    marginBottom: 10,
    '&:hover': {
      backgroundColor: 'transparent',
      border: 0,
      color: theme.palette.primary.main,
    },
  },
  backContinueBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  continueBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: 220,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  resetBtn: {
    width: 200,
    // padding: '12px 16px',
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 18,
    cursor: 'pointer',
    height: 58,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.primary.main,
    },
    marginLeft: 20,
  },
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  menuPaper: {
    maxHeight: 150,
  },
}));
