import React from 'react';
import { Typography } from '@material-ui/core';
import { isEmpty, get } from 'lodash';

import { Box, Button } from 'components';
import { ACTION_NAME, ACTION_STATUS } from 'common/utils/constants';

import styles from '../styles';

const UploadSignDocuments = ({ data }: any) => {
  const classes = styles();
  const documents = get(data, 'documents', []);

  return (
    <Box className={classes.dashactionSignNowBox}>
      <Typography className={classes.dashSignNowText} variant="h6" gutterBottom>
        Documents pending to be signed
      </Typography>
      {!isEmpty(documents)
        ? documents.map((document: any, i: number) => (
            <>
              <Typography
                className={classes.dashUploadListText}
                variant="h6"
                gutterBottom
                key={i}
              >
                {i + 1}. {document.filename}
              </Typography>
            </>
          ))
        : null}
      {get(data, 'docusignSignUrl') &&
        (get(data, 'name') === ACTION_NAME.REQUEST_BRIDGE_LOAN_SIGN_DOC ||
          get(data, 'name') === 'REQUEST_VD_LOAN_SIGN_DOC') &&
        get(data, 'status') === ACTION_STATUS.PENDING && (
          <Button
            className={classes.signNowBtn}
            name="Sign now"
            onClick={() => {
              window.open(get(data, 'docusignSignUrl'), '_blank');
            }}
          />
        )}
    </Box>
  );
};

export default UploadSignDocuments;
