import { all, spawn, call } from 'redux-saga/effects';

import { AuthSagas } from 'redux-modules/auth';
import { EnquirySaga } from 'redux-modules/enquiries';
import { EnquiryFormSaga } from 'redux-modules/enquiryForm';
import { GlobalSagas } from 'redux-modules/global';
import { ProcessSagas } from 'redux-modules/process';
import { VentureSagas } from 'redux-modules/ventureDebt';
import { PaymentsSaga } from 'redux-modules/payments';
import { VdInstallmentsSaga } from 'redux-modules/vdinstallments';
import { InvestorSaga } from 'redux-modules/investors';
import { DealsSaga } from 'redux-modules/deals';
import { FundingRoundSaga } from 'redux-modules/fundingRound';

export default function* RootSaga() {
  const sagas = [
    AuthSagas,
    EnquirySaga,
    EnquiryFormSaga,
    GlobalSagas,
    ProcessSagas,
    VentureSagas,
    PaymentsSaga,
    VdInstallmentsSaga,
    InvestorSaga,
    DealsSaga,
    FundingRoundSaga,
  ];
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          }
        }
      }),
    ),
  );
}
