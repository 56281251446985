import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { Box, Button, Dialog, Loader } from 'components';
import { FN } from 'common/types';
import { ProfileIcon, WarningIcon } from 'assets';
import history from 'common/utils/history';

import styles from './styles';
import VcFirmChangeStatus from './VcFirmChangeStatus';

type Props = {
  investorId: string;
  vcDetails: any;
  setVcDetails: FN;
  UrlParamValue: FN;
  vcUserDetails: FN;
  vcFirmDocuments: FN;
  isLoading: boolean;
};

const VcFirmInfo = ({
  investorId,
  vcDetails,
  setVcDetails,
  UrlParamValue,
  vcUserDetails,
  vcFirmDocuments,
  isLoading,
}: Props) => {
  const classes = styles();

  const [isApproveDoc, setIsApproveDoc] = useState<boolean>(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  const isDocumentUploaded = (): boolean => {
    if (!isEmpty(vcFirmDocuments)) {
      const doc = vcFirmDocuments.filter(
        (item: any) => item.documentStatus !== 'VERIFIED',
      );
      if (isEmpty(doc)) return true;
      else return false;
    } else return false;
  };

  return (
    <>
      {isLoading ? (
        <Box className={classes.loader}>
          <Loader />
        </Box>
      ) : (
        <Box className={classes.enquiryListhead}>
          <Box className={classes.enquiryListheadContent}>
            <Box className={classes.gridItemView}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.labelFundUpHead}
              >
                <img
                  src={ProfileIcon}
                  alt={ProfileIcon}
                  className={classes.profileIcon}
                />
                <span>
                  {UrlParamValue === 'VC_FIRM_SIGNUP'
                    ? get(vcUserDetails, 'name')
                    : get(vcDetails, 'name')}
                  <p className={classes.emailText}>
                    {get(vcUserDetails, 'email')}
                  </p>
                </span>
                {(UrlParamValue === 'VC_FIRM_REVIEW' ||
                  UrlParamValue === 'ANGEL_INVESTOR_REVIEW' ||
                  UrlParamValue === 'YARDSTICK_USER_REVIEW') && (
                  <Button
                    isLoading={isLoading}
                    className={
                      get(vcDetails, 'activated')
                        ? classes.activateButton
                        : classes.deActivateButton
                    }
                    name={
                      get(vcDetails, 'activated') ? 'ACTIVATED' : 'DE-ACTIVATED'
                    }
                    onClick={() =>
                      get(vcDetails, 'activated')
                        ? setUpdatingStatus(true)
                        : !get(vcDetails, 'activated') &&
                          (isDocumentUploaded() || isEmpty(vcFirmDocuments))
                        ? setUpdatingStatus(true)
                        : setIsApproveDoc(true)
                    }
                    disabled={isLoading}
                  />
                )}
                {(UrlParamValue === 'ANGEL_INVESTOR_REVIEW' ||
                  UrlParamValue === 'YARDSTICK_USER_REVIEW' ||
                  UrlParamValue === 'VC_FIRM_REVIEW') && (
                  <Typography
                    className={
                      UrlParamValue === 'ANGEL_INVESTOR_REVIEW'
                        ? classes.profileSubVcAngelText
                        : UrlParamValue === 'YARDSTICK_USER_REVIEW'
                        ? classes.profileSubVcYardstickText
                        : UrlParamValue === 'VC_FIRM_REVIEW'
                        ? classes.profileSubVentureInsightText
                        : classes.profileSubVentureInsightText
                    }
                    variant="caption"
                  >
                    {UrlParamValue === 'ANGEL_INVESTOR_REVIEW'
                      ? 'VentureInsights'
                      : UrlParamValue === 'YARDSTICK_USER_REVIEW'
                      ? 'Yardstick Only'
                      : UrlParamValue === 'VC_FIRM_REVIEW'
                      ? 'VentureInsights Plus'
                      : ''}
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
          <Box
            className={classes.avatarInfoRight}
            onClick={() => history.goBack()}
          >
            <KeyboardBackspaceIcon />
          </Box>
        </Box>
      )}
      <Dialog
        open={isApproveDoc}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => setIsApproveDoc(false)}
      >
        <Box className={classes.warningContainer}>
          <img src={WarningIcon} alt={WarningIcon} />
          <Typography variant="body1" className={classes.warningText}>
            Please Review and Approve the document before activating the firm
          </Typography>
          <Box>
            <Button
              type="button"
              name="OK"
              className={classes.warnBtn}
              onClick={() => setIsApproveDoc(false)}
            />
            <Button
              variant="outlined"
              className={classes.warnCancelBtn}
              name="Cancel"
              onClick={() => setIsApproveDoc(false)}
            />
          </Box>
        </Box>
      </Dialog>
      <VcFirmChangeStatus
        updatingStatus={updatingStatus}
        setUpdatingStatus={setUpdatingStatus}
        vcDetails={vcDetails}
        setVcDetails={setVcDetails}
        investorId={investorId}
      />
    </>
  );
};

export default VcFirmInfo;
