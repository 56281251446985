import { IRoute } from 'common/types';
import DashboardLayout from 'layouts/DashboardLayout';

import InvestmentSettings from './investmentSettings';

export const InvestmentSettingsRoute: IRoute = {
  path: '/admin/investment-settings',
  component: InvestmentSettings,
  layout: DashboardLayout,
  exact: true,
};
