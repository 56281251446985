import React, { useState } from 'react';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';

import { Button, Dialog, Box } from 'components';
import { deleteVcAdminDocuments } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { VC_ADMIN_DOCUMENT_RELATIVE_NAME } from 'common/utils/constants';

import styles from './styles';

type Props = {
  isDeleteDocument: boolean;
  setIsDeleteDocument: React.Dispatch<React.SetStateAction<any>>;
  selectedDocument: any;
  setSelectedDocument: any;
  selectedDeal: any;
  documents?: any;
  setDocuments?: any;
};

const DeleteDealDocument = ({
  isDeleteDocument,
  setIsDeleteDocument,
  selectedDocument,
  setSelectedDocument,
  selectedDeal,
  documents,
  setDocuments,
}: Props) => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const onDeleteDocument = () => {
    setIsLoading(true);
    setErrorMessage('');
    const obj = {
      id: selectedDocument.id,
      vcDealId: selectedDeal,
      documentName: selectedDocument.documentName,
      type: VC_ADMIN_DOCUMENT_RELATIVE_NAME.DEAL,
    };
    deleteVcAdminDocuments(obj)
      .then(() => {
        const filteredDeletedDocuments = documents.filter(
          (item: any) => get(item, 'id') !== selectedDocument.id,
        );
        setDocuments(filteredDeletedDocuments);
        handleClose();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    setErrorMessage('');
    setIsDeleteDocument(false);
    setSelectedDocument({});
  };

  return (
    <>
      <Dialog
        open={isDeleteDocument}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => {
          handleClose();
        }}
      >
        <Box className={classes.dialogContainer}>
          <Box>
            <Typography className={classes.deleteDealText}>
              Are you sure you want to Delete this Document?
            </Typography>
          </Box>
          {errorMessage && (
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
          <Box className={classes.convertBtnSection}>
            <Button
              type="button"
              className={classes.deleteBtn}
              name="yes"
              onClick={onDeleteDocument}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              variant="outlined"
              name="Cancel"
              className={classes.warnCancelBtn}
              disabled={isLoading}
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DeleteDealDocument;
