import React, { useState, useMemo } from 'react';
import { Typography, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { orderBy, get } from 'lodash';

import { Box, Button, Tabs, TabPanel, WrappedSelect } from 'components';
import {
  SubscriptionUsersOptions,
  SubscriptionPlanStatus,
} from 'common/utils/optionList';
import { listSubscriptionPlans, getUnsubscibedUsers } from 'services';
import {
  errorMessageHandler,
  getPaymentStatus,
  getPaymentStatusYardstickUser,
} from 'common/utils/helpers';
import { PlanType } from 'common/types';
import { SUBSCRIBED_USER } from 'common/utils/constants';

import styles from './styles';
import {
  VcAllPlanTable,
  CreateNewPlan,
  PlanDetailView,
  VcAllSubscribedUser,
} from './Components';
import VcUnsubsribedUser from './Components/VcUnsubscribedUser';

type tabItem = {
  name: string;
  key: string;
};

const SubscriptionList = () => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [subscriptionPlanAllData, setSubscriptionPlanAllData] = useState<any>(
    [],
  );
  const [unsubscribedUsersAllData, setUnsubscribedUsersAllData] = useState<any>(
    [],
  );
  const [
    allSubscribedInvestorUser,
    setAllSubscribedInvestorUser,
  ] = useState<any>([]);
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [
    filteredSearchPlanData,
    setFilteredSearchPlanData,
  ] = React.useState<any>([]);
  const [
    filteredSearchUserData,
    setFilteredSearchUserData,
  ] = React.useState<any>([]);
  const [value, setValue] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPlanView, setIsPlanView] = useState<boolean>(false);
  const [selectedPlanData, setSelectedPlanData] = useState<PlanType>({
    planName: '',
    planType: '',
    id: '',
    isJoiningPlan: false,
  });
  const [
    subscriptionUserPlan,
    setSubscriptionUserPlan,
  ] = React.useState<string>('ALL');
  const [subscriptionUser, setSubscriptionUser] = React.useState<string>('ALL');
  const [planStatus, setPlanStatus] = React.useState<string>('ALL');

  const tabs = useMemo(() => {
    const tabsList = [
      { name: 'All Subscribe Users', key: 'ALL_SUBSCRIBED_USER' },
      { name: 'All Plans', key: 'ALL_PLAN' },
      { name: 'Unassigned Users', key: 'UNASSIGNED' },
    ];

    return tabsList;
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const getSubscriptionPlansList = () => {
    setIsLoading(true);
    setErrorMessage('');
    listSubscriptionPlans()
      .then((res: any) => {
        setSubscriptionPlanAllData(orderBy(res.data, ['createdAt'], ['desc']));
        setFilteredSearchPlanData(orderBy(res.data, ['createdAt'], ['desc']));
        setIsLoading(false);
        setSubscriptionUserPlan('ALL');
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const getUnsubscibedUsersList = () => {
    setIsLoading(true);
    setErrorMessage('');
    getUnsubscibedUsers()
      .then((res: any) => {
        setUnsubscribedUsersAllData(res.data);

        setIsLoading(false);
        setSubscriptionUserPlan('UNASSIGNED');
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const onSearchChange = (searchString: string, type: string) => {
    if (type === 'PLAN') {
      setPlanStatus('ALL');
      setSubscriptionUserPlan('ALL');
      const filteredData = subscriptionPlanAllData.filter((value: any) => {
        if (
          searchString === '' ||
          value.name
            ?.toString()
            .toLowerCase()
            .includes(searchString?.toString().toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
      setFilteredSearchPlanData(filteredData);
    } else {
      setSubscriptionUser('ALL');
      const filteredData = allSubscribedInvestorUser.filter((value: any) => {
        if (
          searchString === '' ||
          value.subscriptionPlan.name
            ?.toString()
            .toLowerCase()
            .includes(searchString?.toString().toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
      setFilteredSearchUserData(filteredData);
    }
  };

  const filterSubUser = (type: any, array: any) => {
    if (type === 'PLAN') {
      if (subscriptionUserPlan !== 'ALL') {
        return array.filter(
          (item: any) => item.planType === subscriptionUserPlan,
        );
      } else return array;
    } else if (type === 'USER') {
      if (subscriptionUser !== 'ALL') {
        return array.filter(
          (item: any) => item.subscriptionPlan.planType === subscriptionUser,
        );
      } else return array;
    }
  };

  const filterPlanStatus = (array: any) => {
    if (planStatus !== 'ALL') {
      return array.filter((item: any) => {
        if (
          [SUBSCRIBED_USER.YARDSTICK].includes(
            get(item, 'subscriptionPlan.planType'),
          )
        ) {
          const data =
            get(
              getPaymentStatusYardstickUser(
                get(item, 'usedFreeOutput'),
                get(item, 'billingHistory.status'),
                get(item, 'active'),
              ),
              'value',
            ) === planStatus;
          return data;
        } else {
          const data =
            get(
              getPaymentStatus(
                get(item, 'startingDate'),
                get(item, 'subscriptionPlan.subscriptionFreeTrialDays'),
                get(item, 'active'),
                get(item, 'billingHistory.amountPaidDecimal'),
                get(item, 'billingHistory.status'),
                get(item, 'subscriptionPlan.planType'),
              ),
              'value',
            ) === planStatus;
          return data;
        }
      });
    } else return array;
  };
  const onHandleSubUserTypeChange = (event: any, type: string) => {
    if (type === 'PLAN') {
      setSubscriptionUserPlan(event.target.value);
    } else {
      setSubscriptionUser(event.target.value);
    }
  };

  const onHandlePlanStatusChange = (event: any) => {
    setPlanStatus(event.target.value);
  };

  const handleRenderTabs = (tabVal: string) => {
    switch (tabVal) {
      case 'ALL_SUBSCRIBED_USER':
        return (
          <VcAllSubscribedUser
            filteredSearchData={filteredSearchUserData}
            setSubscriptionUser={setSubscriptionUser}
            setFilteredSearchUserData={setFilteredSearchUserData}
            subscriptionPlanAllData={subscriptionPlanAllData}
            getSubscriptionPlansList={getSubscriptionPlansList}
            setAllSubscribedInvestorUser={setAllSubscribedInvestorUser}
          />
        );
      case 'ALL_PLAN':
        return (
          <VcAllPlanTable
            getSubscriptionPlansList={getSubscriptionPlansList}
            filteredSearchData={filteredSearchPlanData}
            isLoading={isLoading}
            errorMessage={errorMessage}
            setSelectedPlanData={setSelectedPlanData}
            setIsPlanView={setIsPlanView}
            setSubscriptionUser={setSubscriptionUser}
          />
        );
      case 'UNASSIGNED':
        return (
          <VcUnsubsribedUser
            unsubscribedUsersAllData={unsubscribedUsersAllData}
            getUnsubscibedUsersList={getUnsubscibedUsersList}
            isLoading={isLoading}
            subscriptionPlanAllData={subscriptionPlanAllData}
          />
        );
      default:
        return;
    }
  };

  React.useEffect(() => {
    let result =
      value === 0 ? allSubscribedInvestorUser : subscriptionPlanAllData;
    result = filterSubUser(value === 0 ? 'USER' : 'PLAN', result);
    result = value === 0 ? filterPlanStatus(result) : result;
    setFilteredSearchPlanData(result);
    setFilteredSearchUserData(result);
  }, [planStatus, subscriptionUserPlan, subscriptionUser]);

  return (
    <>
      <Box className={classes.vcFirmAdminBox}>
        {errorMessage && (
          <Typography variant="caption" className={classes.errorText}>
            {errorMessage}
          </Typography>
        )}
        {isPlanView ? (
          <PlanDetailView
            setIsPlanView={setIsPlanView}
            selectedPlanData={selectedPlanData}
          />
        ) : (
          <>
            <Box className={classes.vcFirmHead}>
              <Box className={classes.tabContainer}>
                <Tabs
                  tabs={tabs}
                  value={value}
                  handleChange={handleChange}
                  className={classes.enquiryTabs}
                />
                {value !== 2 && (
                  <Box className={classes.rightBox}>
                    <Box className={classes.searchBox}>
                      <InputBase
                        value={searchStringText}
                        className={classes.inputSearch}
                        placeholder={'Search plan'}
                        inputProps={{ 'aria-label': 'search' }}
                        endAdornment={
                          !searchStringText.length ? (
                            <IconButton
                              className={classes.searchIcon}
                              aria-label="search"
                            >
                              <SearchIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              className={classes.clearIcon}
                              aria-label="clear"
                              onClick={() => {
                                setSearchStringText('');
                                onSearchChange(
                                  '',
                                  value === 0 ? 'USER' : 'PLAN',
                                );
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )
                        }
                        onChange={(e: any) => {
                          const searchString = e.target.value;
                          setSearchStringText(searchString);
                          onSearchChange(
                            searchString,
                            value === 0 ? 'USER' : 'PLAN',
                          );
                        }}
                      />
                    </Box>
                    {value === 1 ? (
                      <>
                        <Button
                          name="Create New Plan"
                          className={classes.customizePlanBtn}
                          onClick={() => setIsOpen(true)}
                        />
                      </>
                    ) : (
                      <WrappedSelect
                        variant={'outlined'}
                        value={planStatus}
                        onChange={onHandlePlanStatusChange}
                        className={classes.textValInputSelect}
                        InputProps={{
                          classes: { root: classes.inputRootSelect },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRootSelect,
                            focused: classes.labelFocusedSelect,
                          },
                        }}
                        dropdowns={SubscriptionPlanStatus}
                        error={false}
                      />
                    )}
                    <WrappedSelect
                      variant={'outlined'}
                      value={
                        value === 0 ? subscriptionUser : subscriptionUserPlan
                      }
                      placeholder="All"
                      onChange={(e: any) =>
                        onHandleSubUserTypeChange(
                          e,
                          value === 0 ? 'USER' : 'PLAN',
                        )
                      }
                      className={classes.textValInputSelect}
                      InputProps={{
                        classes: { root: classes.inputRootSelect },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRootSelect,
                          focused: classes.labelFocusedSelect,
                        },
                      }}
                      dropdowns={[
                        { text: 'All', value: 'ALL' },
                        ...SubscriptionUsersOptions,
                      ]}
                      error={false}
                    />
                  </Box>
                )}
              </Box>
              {tabs.map((item: tabItem, index) => {
                return (
                  <TabPanel
                    value={value}
                    index={index}
                    key={item.key}
                    className={classes.actionTabPanel}
                  >
                    {handleRenderTabs(item.key)}
                  </TabPanel>
                );
              })}
            </Box>
          </>
        )}
      </Box>
      <CreateNewPlan
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        getSubscriptionPlansList={getSubscriptionPlansList}
        type={'CREATE'}
      />
    </>
  );
};

export default SubscriptionList;
