import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField, Typography, InputAdornment } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import { Button, Box, WrappedTextInput } from 'components';
import { FN } from 'common/types';
import { handleLoanCloserAction } from 'redux-modules/enquiries/Actions';
import { RootState } from 'redux-modules/store/rootState';
import { formatAmount } from 'common/utils/helpers';

import { loanClosureExtensionSchema } from './validations';
import styles from '../../../styles';

type Props = {
  handleClose: FN;
  startupId: string | undefined;
};

const Extension = ({ handleClose, startupId }: Props) => {
  const classes = styles();
  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(loanClosureExtensionSchema),
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    isLoadingLoanCloserInfo,
    errorMessage,
    repaidInfo,
    loanCloserInfo,
  } = useSelector(({ Enquiries }: RootState) => Enquiries);
  useEffect(() => {
    if (!isLoadingLoanCloserInfo && isLoading && !errorMessage) {
      handleClose();
    } else {
      setIsLoading(isLoadingLoanCloserInfo);
    }
  }, [isLoadingLoanCloserInfo]);

  const onSubmit = (data: any) => dispatch(handleLoanCloserAction(data));
  return (
    <Box>
      <Typography className={classes.loanCloserDialogTitle}>
        Loan Closer - Extension
      </Typography>
      {errorMessage && (
        <Typography variant="body1" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      <form onSubmit={handleSubmit(onSubmit)} data-testid="addPricedRound">
        {/* HIDDEN FIELDS ----------------- */}
        <div className={classes.hidden}>
          <Controller
            name="startupId"
            control={control}
            defaultValue={startupId}
            render={({ field: { onChange, value } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  value={value}
                  onChange={onChange}
                  className={''}
                  error={false}
                />
              </>
            )}
          />
          <Controller
            name="status"
            control={control}
            defaultValue={'EXTENSION'}
            render={({ field: { onChange, value } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  value={value}
                  onChange={onChange}
                  className={''}
                  error={false}
                />
              </>
            )}
          />
        </div>
        {/* HIDDEN FIELDS ----------------- */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {repaidInfo &&
              repaidInfo.totalPaymentReceived &&
              `Principal amount paid till date is ${formatAmount(
                repaidInfo.totalPaymentReceived,
              )} `}
            {loanCloserInfo &&
              loanCloserInfo.newDeadline &&
              `and Deadline for repayment is ${moment(
                loanCloserInfo.newDeadline,
              ).format('DD MMM YYYY')}`}
          </Grid>
          <Grid item xs={12} md={6} className={classes.inputHead}>
            <Typography>New deadline</Typography>
            <Controller
              name="newDeadLine"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    id="date-local"
                    type="date"
                    value={value}
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                    }}
                    inputProps={{
                      min: moment().format('YYYY-MM-DD'),
                      max: moment().add(11, 'M').format('YYYY-MM-DD'),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                      },
                    }}
                    error={error?.message ? true : false}
                    onChange={onChange}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{ required: 'Round closing date is required' }}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.inputHead}>
            <Typography>Additonal Bonus SAFE</Typography>
            <Controller
              name="additionalBonusSafe"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <WrappedTextInput
                    type="number"
                    label=""
                    placeholder="0"
                    className={classes.textValInput}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={value}
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  {error?.message && (
                    <span className={classes.errorText}>{error?.message}</span>
                  )}
                </>
              )}
              rules={{
                required: 'Amount is required',
              }}
            />
          </Grid>
        </Grid>
        <Box className={classes.backContinueBox}>
          <Button
            type="submit"
            isLoading={isLoading}
            name="Save"
            onClick={handleSubmit(onSubmit)}
            className={classes.continueBtn}
          />
          <Button
            className={classes.backBtn}
            name="Cancel"
            onClick={handleClose}
          />
        </Box>
      </form>
    </Box>
  );
};

export default Extension;
