import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import Alert from '@material-ui/lab/Alert';

import { RootState } from 'redux-modules/store/rootState';
import { Box, Tabs, TabPanel, Banner, Loader } from 'components';
import { Ornament } from 'assets';
import ActionsList from 'components/ActionsList';
import NoItemsImg from 'components/NoItemsImg';
import { emptyEnquiryDetails } from 'redux-modules/enquiries/Actions';
import history from 'common/utils/history';
import { getLoanCloserDocusignUrl } from 'services';

import styles from './styles';

type tabItem = {
  name: string;
  key: string;
};

const Actions = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { role } = useSelector(({ Auth }: RootState) => Auth.user);

  const [errorInInitSign, setErrorInInitSign] = useState<string>('');

  const actionsListState = useSelector(
    ({ global }: RootState) => global.actionsList,
  );
  const { enquiryView } = useSelector(({ Enquiries }: RootState) => Enquiries);
  const actionsList = get(actionsListState, 'data', []);
  const isActionsListLoading = get(actionsListState, 'isLoading', false);

  const [value, setValue] = useState<number>(0);
  const tabs = useMemo(() => {
    const tabsList = [
      { name: 'Pending', key: 'PENDING' },
      { name: 'Completed', key: 'COMPLETED' },
    ];

    return tabsList;
  }, []);

  const search = history.location.search;
  const aid: any = new URLSearchParams(search).get('aid');
  const initsignin = new URLSearchParams(search).get('initsignin');

  useEffect(() => {
    if (enquiryView.id) {
      dispatch(emptyEnquiryDetails());
    }
  }, [enquiryView]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleShowEmptyMessage = () => {
    if (isEmpty(actionsList) && !isActionsListLoading) {
      if (role === 'ADMIN') {
        return (
          <Banner
            title={'Yayy!! No pending action items'}
            subTitle={''}
            source={'AdminAction'}
          />
        );
      } else if (role === 'STARTUP_ADMIN') {
        return (
          <Banner
            title={'Yayy!! No pending action items'}
            subTitle={''}
            source={'ActionEmpty'}
          />
        );
      } else {
        return <NoItemsImg />;
      }
    }
  };

  useEffect(() => {
    if (aid && initsignin) {
      getDocusignUrl(aid);
    }
  }, []);

  const getDocusignUrl = (actionId: string) => {
    getLoanCloserDocusignUrl({ actionId })
      .then((res: any) => {
        if (get(res, 'message')) {
          setErrorInInitSign(get(res, 'message'));
          // history.push('/actions');
        } else {
          window.open(res, '_self');
        }
      })
      .catch(() => {
        history.push('/actions');
      });
  };

  if ((aid && initsignin) || errorInInitSign) {
    return (
      <Box className={[classes.actionContainer, classes.loaderBox]}>
        {!errorInInitSign && <Loader />}
        {!errorInInitSign && <Box ml={6}>Loading signing page...</Box>}
        {errorInInitSign && (
          <Box>
            <Alert style={{ width: '100%' }} severity="error">
              {errorInInitSign}
            </Alert>
          </Box>
        )}
      </Box>
    );
  }
  return (
    <Box className={classes.actionContainer}>
      <Box className={classes.actionContent}>
        <Box className={classes.actionHead}>
          <img src={Ornament} alt={Ornament} className={classes.enquiryImage} />
          <Typography variant="h6" className={classes.headText}>
            Actions
          </Typography>
        </Box>
        <Box className={classes.actionContentText}>
          <Tabs
            tabs={tabs}
            value={value}
            handleChange={handleChange}
            className={classes.enquiryTabs}
          />
          {tabs.map((item: tabItem, index) => {
            return (
              <TabPanel
                value={value}
                index={index}
                key={item.key}
                className={classes.actionTabPanel}
              >
                <Box className={classes.actionsBodyPaddingTop} />
                <ActionsList status={item.key} />
                {handleShowEmptyMessage()}
              </TabPanel>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default Actions;
