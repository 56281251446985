import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  SignInRoute,
  SignUpRoute,
  SignUpSuccessRoute,
  VerifyRoutes,
  InviteRoute,
  ResetPasswordRoute,
  ForgotPasswordRoute,
  ConfirmPasswordRoute,
  SuccessForgotPasswordRoute,
} from 'views/auth';
import { InvestorListRoute } from 'views/investor/InvestorList/Routes';
import { InvestorViewRoute } from 'views/investor/InvestorView/Routes';
import { VcFirmViewRoute, VcFirmListViewRoute } from 'views/VcFirms/Routes';
import { VcFundsViewRoute, VcFundsListViewRoute } from 'views/VcFunds/Routes';
import { RootState } from 'redux-modules/store/rootState';
import { NotFoundRoute } from 'views/notFound/Routes';
import { StartupOnboardRoute } from 'views/StartupOnboard/Routes';
import { InvestorOnboardRoute } from 'views/InvestorOnboard/Routes';
import { VcOnboardRoute } from 'views/VcOnboard/Routes';
import { InvestmentSettingsRoute } from 'views/investor/investmentSettings/Routes';
import { DealCreationRoute } from 'views/DealCreation/Routes';
import { DealListRoute } from 'views/DealList/Routes';
import { BenchMarkStartupViewRoute } from 'views/BenchMark/Routes';
import { VcFirmSubscriptionRoute } from 'views/Subscription/Routes';
import { ProspectiveDealRoute } from 'views/ProspectiveDeal/Routes';
// import {
//   BenchMarkRouteV2,
//   BenchMarkStartupRoute,
// } from 'views/BenchmarkV2/Routes';
import {
  BenchMarkRouteV2,
  BenchMarkStartupRoute,
} from 'views/BenchmarkLite/Routes';
import { ReferralDataRoute } from 'views/ReferralData/Routes';

import { IRoute } from './common/types';
import PublicRouteWrapper from './layouts/PublicRouteWrapper';
import { DashboardRoute } from './views/dashboard/Routes';
import { DealRoute } from './views/deal/Routes';
import { NavigationRoute } from './views/navigation/Routes';
import { VDEnquiryFormRoute } from './views/VentureDebt/Routes';
import { EnquiryFormRoute } from './views/enquiryForm/Routes';
import { ProfileRoute } from './views/profile/Routes';
import { EnquiryListRoute } from './views/enquiriesList/Routes';
import { EnquiryViewRoute } from './views/enquiryView/Routes';
import { ActionsRoute } from './views/Actions/Routes';
import { PortFolioRoute } from './views/portFolio/Routes';
import { PaymentsRoute } from './views/Payments/Routes';
import { ContactUsRoute } from './views/ContactUs/Routes';
import { SettingsRoute } from './views/Settings/Routes';
import { BenchMarkAdminRoute } from './views/BenchMarkAdmin/Routes';
import AuthenticatedRouteWrapper from './layouts/AuthenticatedRouteWrapper';
import {
  FundRaisingCRMRoute,
  AdminFundRaisingCRMListsRoute,
  AdminFundRaisingCRMDetailRoute,
} from './views/FundRaisingCRM/Routes';
import { FundRaisingRoute } from './views/FundRaising/Routes';
import { ClientsAPIRoute } from './views/Clients/Routes';

const Routes = () => {
  const PublicRoutes = [
    SignInRoute,
    SignUpRoute,
    ResetPasswordRoute,
    SignUpSuccessRoute,
    VerifyRoutes,
    ForgotPasswordRoute,
    ConfirmPasswordRoute,
    SuccessForgotPasswordRoute,
    PortFolioRoute,
    InviteRoute,
  ];

  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  const AdminRoutes = [
    DashboardRoute,
    EnquiryListRoute,
    EnquiryViewRoute,
    VcFirmViewRoute,
    VcFirmListViewRoute,
    VcFundsListViewRoute,
    VcFundsViewRoute,
    NotFoundRoute,
    ActionsRoute,
    PaymentsRoute,
    SettingsRoute,
    InvestorListRoute,
    InvestorViewRoute,
    StartupOnboardRoute,
    InvestorOnboardRoute,
    VcOnboardRoute,
    InvestmentSettingsRoute,
    ReferralDataRoute,
    DealCreationRoute,
    DealListRoute,
    BenchMarkAdminRoute,
    BenchMarkStartupViewRoute,
    AdminFundRaisingCRMDetailRoute,
    FundRaisingRoute,
    AdminFundRaisingCRMListsRoute,
    VcFirmSubscriptionRoute,
    ClientsAPIRoute,
    ProspectiveDealRoute,
  ];
  const StartupUserRoutes = [
    NavigationRoute,
    DashboardRoute,
    EnquiryFormRoute,
    VDEnquiryFormRoute,
    ProfileRoute,
    NotFoundRoute,
    ActionsRoute,
    PaymentsRoute,
    ContactUsRoute,
    DealRoute,
    SettingsRoute,
    // BenchMarkRoute,
    BenchMarkRouteV2,
    BenchMarkStartupRoute,
    FundRaisingCRMRoute,
    FundRaisingRoute,
  ];
  const isRoleAdmin = role === 'ADMIN';
  return (
    <Switch>
      {PublicRoutes.map((route: IRoute, index: number) => (
        <PublicRouteWrapper key={`public-route-wrapper-${index}`} {...route} />
      ))}
      {isRoleAdmin &&
        AdminRoutes.map((route: IRoute, index: number) => (
          <AuthenticatedRouteWrapper
            key={`public-route-wrapper-${index}`}
            {...route}
          />
        ))}
      {!isRoleAdmin &&
        StartupUserRoutes.map((route: IRoute, index: number) => (
          <AuthenticatedRouteWrapper
            key={`public-route-wrapper-${index}`}
            {...route}
          />
        ))}
      {!isRoleAdmin && <Redirect exact path="/" to="/startup/navigate" />}
      {isRoleAdmin && <Redirect exact path="/" to="/dashboard" />}
      <Redirect exact to="/not-found" />
    </Switch>
  );
};

export default Routes;
