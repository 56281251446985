import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

const useGaTracker = () => {
  const location = window.location;
  const [initialized, setInitialized] = useState(false);
  const trackingId = process.env.REACT_APP_GOOGLE_TRACKING_ID
    ? process.env.REACT_APP_GOOGLE_TRACKING_ID
    : '';
  useEffect(() => {
    if (trackingId && process.env.REACT_APP_ENV === 'qa') {
      const tagManagerArgs = {
        gtmId: trackingId,
      };
      TagManager.initialize(tagManagerArgs);
    } else if (trackingId && process.env.REACT_APP_ENV === 'prod') {
      ReactGA.initialize(trackingId);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'qa' && initialized) {
      const dataLayer = {
        page: location.pathname + location.search,
        environment: process.env.REACT_APP_ENV,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      TagManager.dataLayer(dataLayer);
    } else if (process.env.REACT_APP_ENV === 'prod' && initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default useGaTracker;
