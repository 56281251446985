import React, { useState, useEffect } from 'react';
import { Typography, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { orderBy } from 'lodash';

import { Box } from 'components';
import { errorMessageHandler } from 'common/utils/helpers';
import { getAdminAllVcFirms } from 'services';
import { SUBSCRIBED_USER } from 'common/utils/constants';

import styles from './styles';
import { VcFirmTable } from './components';

const VcFirmList = () => {
  const classes = styles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [adminAllVcFirmData, setAdminAllVcFirmData] = useState<any>([]);
  const [searchStringText, setSearchStringText] = React.useState<string>('');
  const [filteredSearchData, setFilteredSearchData] = React.useState<any>([]);

  const onSearchChange = (searchString: string) => {
    const filteredData = adminAllVcFirmData.filter((value: any) => {
      if (
        searchString === '' ||
        value.name
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase()) ||
        value.firstName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase()) ||
        value.lastName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase()) ||
        value.registeredFirmName
          ?.toString()
          .toLowerCase()
          .includes(searchString?.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredSearchData(filteredData);
  };

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');
    getAdminAllVcFirms()
      .then((res: any) => {
        const firmRes = (res.data || []).filter(
          (firm: any) => firm.firmType === SUBSCRIBED_USER.VC_FIRM,
        );
        setAdminAllVcFirmData(orderBy(firmRes, ['createdAt'], ['desc']));
        setFilteredSearchData(orderBy(firmRes, ['createdAt'], ['desc']));
        setIsLoading(false);
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsLoading(false);
      });
  }, []);

  return (
    <Box className={classes.vcFirmAdminBox}>
      {errorMessage && (
        <Typography variant="caption" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      <Box className={classes.vcFirmHead}>
        <Typography variant="h6" className={classes.headText}>
          VC Firms
        </Typography>
        <Box className={classes.rightBox}>
          <Box className={classes.searchBox}>
            <InputBase
              value={searchStringText}
              className={classes.inputSearch}
              placeholder="Search Firm"
              inputProps={{ 'aria-label': 'search' }}
              endAdornment={
                !searchStringText.length ? (
                  <IconButton
                    className={classes.searchIcon}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.clearIcon}
                    aria-label="clear"
                    onClick={() => {
                      setSearchStringText('');
                      onSearchChange('');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )
              }
              onChange={(e: any) => {
                const searchString = e.target.value;
                setSearchStringText(searchString);
                onSearchChange(searchString);
              }}
            />
          </Box>
        </Box>
      </Box>
      <VcFirmTable
        filteredSearchData={filteredSearchData}
        errorMessage={errorMessage}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default VcFirmList;
