import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';

import { RootState } from 'redux-modules/store/rootState';
import { Box, Button, WrappedTextInput, SuccessPage } from 'components';
import { AdditionalInformationType, StepProps } from 'common/types';
import { handleSubmitAdditionalInfo } from 'redux-modules/enquiryForm/Actions';
import { successImage } from 'assets';
import history from 'common/utils/history';

import { OtherInformationSchema } from '../validations';
import styles from '../styles';

const OtherInformationDetails = ({
  handleBack,
  stepName,
  id,
}: //type,
StepProps) => {
  const classes = styles();
  const { additionalInformation, successMessage } = useSelector(
    ({ EnquiryForm }: RootState) => EnquiryForm,
  );
  const AdditionalInfoLog = useSelector(
    ({ EnquiryForm }: RootState) => EnquiryForm.isAdditionalInfo,
  );
  const { bridgeLoanProcess, loansDetails } = useSelector(
    ({ Enquiries }: RootState) => Enquiries,
  );

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(OtherInformationSchema),
  });

  const dispatch = useDispatch();

  const onSubmit = (additionalInformation: AdditionalInformationType) => {
    const obj = {
      id,
      currentPage: stepName,
      additionalInformation,
    };
    dispatch(handleSubmitAdditionalInfo(obj));
  };

  const getStatusMessage = () => {
    return get(loansDetails, 'loanStatus') === 'REJECTED'
      ? 'We have reviewed your documents and would not be able to process the bridge loan as of now. You can continue to use our available products and re-apply for the loan again in the future.'
      : get(bridgeLoanProcess, 'isLoanProcessed')
      ? 'Loan processed successfully.'
      : get(bridgeLoanProcess, 'isLoanApproved')
      ? 'Loan approved successfully.'
      : get(bridgeLoanProcess, 'documentsSigned')
      ? 'We have received signed agreements, your debt application will be approved shortly.'
      : 'Application successful.';
  };
  React.useEffect(() => {
    if (successMessage && successMessage.length) {
      reset(additionalInformation);
    }
  }, [successMessage]);
  return (
    <Box>
      {!AdditionalInfoLog.successMessage ? (
        <Box className={classes.enquirySuccessBox}>
          <Box className={classes.eBoxContent}>
            <SuccessPage img={successImage} className={classes.dashSuccessImg}>
              {/* <img
              src={Ornament}
              alt={Ornament}
              className={classes.imgOrnamentOne}
            /> */}
              <Typography
                gutterBottom
                variant="h4"
                className={classes.successHeaderTxt}
              >
                {getStatusMessage()}
              </Typography>
              <Typography
                variant="body1"
                className={classes.dashFondbtn}
                onClick={() => history.push('/dashboard')}
              >
                Go to Dashboard
              </Typography>
            </SuccessPage>
          </Box>
        </Box>
      ) : (
        <>
          <Typography variant="h2" className={classes.detailsText}>
            Other Information Details
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.errorText}
          >
            {AdditionalInfoLog.errorMessage}
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="companyDetailsForm"
            className={classes.validateForm}
          >
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  How did you hear about 8VDX?
                </Typography>
                <Controller
                  name="startup8vdxSource"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Type Something"
                        className={classes.textFValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  What made you to apply to 8VDX?
                </Typography>
                <Controller
                  name="startupReasonForChosing"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Type Something"
                        className={classes.textFValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Has the Company received any investment to date (apart from Y
                  Combinator commitment)? If so please provide relevant details
                  of the investor
                </Typography>
                <Controller
                  name="startupInvestmentInformation"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Type Something"
                        className={classes.textFValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  className={classes.companyLabelText}
                >
                  Is there anything else we should know about your company
                  (Legal or otherwise)?
                </Typography>
                <Controller
                  name="startupLegalInformation"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <WrappedTextInput
                        type="text"
                        label=""
                        placeholder="Type Something"
                        className={classes.textFValInput}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                            focused: classes.labelFocused,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={error?.message ? true : false}
                      />
                      {error?.message && (
                        <span className={classes.errorText}>
                          {error?.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Box className={classes.backContinueBox}>
              <Button
                onClick={handleBack}
                startIcon={<ChevronLeftIcon />}
                className={classes.backBtn}
                name="Back"
              />
              <Button
                type="submit"
                className={classes.continueBtn}
                name="Submit Form"
              />
            </Box>
          </form>
        </>
      )}
    </Box>
  );
};

export default OtherInformationDetails;
