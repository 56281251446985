import * as yup from 'yup';

import { requiredConstant } from 'common/utils/constants';
import { getParsedPriceInputValue } from 'common/utils/helpers';

export const AdminLoanTermsUpdateSchema = yup.object().shape({
  loanType: yup.string().nullable(),
  loanTenure: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .positive('Value must be greater than 0')
    .min(1, 'Value must be greater than 0')
    .required(requiredConstant),
  cashInterestRate: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .positive('Value must be greater than or equal to 0')
    .min(0, 'Value must be greater than or equal to 0')
    .required(requiredConstant),
  percentageBonusSafe: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .positive('Value must be greater than or equal to 0')
    .min(0, 'Value must be greater than or equal to 0')
    .required(requiredConstant),
  loanAmount: yup
    .string()
    .required(requiredConstant)
    .when('loanType', {
      is: 'BRIDGE_TO_YC_FUND',
      then: yup
        .string()
        .nullable(true)
        .transform((v, o) => (o === '' ? null : v))
        .test(
          'min',
          'Please enter amount: Min 100K USD to Max 500K USD',
          (value) =>
            value !== undefined &&
            value !== null &&
            getParsedPriceInputValue(value) >= 100000 &&
            getParsedPriceInputValue(value) <= 500000,
        ),
      otherwise: yup
        .string()
        .nullable(true)
        .transform((v, o) => (o === '' ? null : v))
        .test(
          'min',
          'Please enter amount: Min 50K USD to Max 250K USD',
          (value) =>
            value !== undefined &&
            value !== null &&
            getParsedPriceInputValue(value) >= 50000 &&
            getParsedPriceInputValue(value) <= 250000,
        ),
    }),
  safeCover: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(requiredConstant)
    .test(
      'positive',
      'Value must be greater than or equal to 0',
      (value) =>
        value !== undefined &&
        value !== null &&
        getParsedPriceInputValue(value) >= 0,
    ),
  processingFees: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .positive('Value must be greater than or equal to 0')
    .min(0, 'Value must be greater than or equal to 0')
    .required(requiredConstant),
  valuation: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .test(
      'positive',
      'Value must be greater than or equal to 0',
      (value) =>
        value !== undefined &&
        value !== null &&
        getParsedPriceInputValue(value) >= 0,
    ),
});
