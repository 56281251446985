import { IRoute } from 'common/types';
import SimpleLayout from 'layouts/SimpleLayout';
import Deal from 'components/Deal';

export const DealRoute: IRoute = {
  path: '/startup/deal-explanation',
  component: Deal,
  layout: SimpleLayout,
  isAdmin: false,
  exact: true,
};
