import {
  ISignUpModal,
  PayloadType,
  EnquiryParmasType,
  EnquirySubmitPayloadType,
  UploadDocuments,
  SubmitEnquiry,
  IBenchmarktype,
  StartUpDetailType,
} from 'common/types';
import { DocumentType, ListDocType } from 'redux-modules/process/Types';
import { urlConstants } from 'common/utils/constants';

import { authApi, secureApi } from '../api/Axios';

export const getEnquiries = (params: EnquiryParmasType) =>
  secureApi.get(urlConstants.getAllEnquiriesList, { params });

export const signUp = (payload: ISignUpModal) =>
  authApi.post(urlConstants.signUp_url, payload);

export const getYcBatches = () => authApi.get(urlConstants.yc_batch);

export const adminEnquiryById = (payload: PayloadType) =>
  secureApi.get(`${urlConstants.adminGetEnquiryId}/${payload.id}`);

export const userEnquiryById = (payload: string) =>
  secureApi.get(`${urlConstants.getEnquiryDetailsById}${payload}`);

export const getAllCountries = () =>
  secureApi.get(urlConstants.getAllCountries);

export const getStartupDetailsForSignUp = () =>
  secureApi.post(urlConstants.getStartupDetailsForSignUp);

export const getAllIndustriesApi = () =>
  secureApi.get(urlConstants.getAllIndustries);

export const getAllYcLocations = () =>
  secureApi.get(urlConstants.getAllYcLocations);

export const getAllStates = () => secureApi.get(urlConstants.getAllStates);

export const enquirySubmitApi = (payload: EnquirySubmitPayloadType) =>
  secureApi.post(urlConstants.createEnquiries, payload);

export const createOrUpdateStartupInformation = (
  payload: IBenchmarktype,
  role: string,
) =>
  role === 'ADMIN'
    ? secureApi.post(urlConstants.adminCreateStartupInformation, payload)
    : secureApi.post(urlConstants.createOrUpdateStartupInformation, payload);

export const updateStartupInformation = (payload: StartUpDetailType) =>
  secureApi.post(urlConstants.updateStartupInformation, payload);

export const submitDocumentsByAdmin = (payload: DocumentType) =>
  secureApi.post(urlConstants.adminRequiredDocuments, payload);

export const getListDoucmentsApi = (payload: ListDocType) =>
  secureApi.post(urlConstants.documentsListAdmin, payload);

export const getCompanyDetailsByID = (payload: string) =>
  secureApi.get(`${urlConstants.getStartupDetailsById}/${payload}`);

export const getFundType = (payload: string) => {
  return secureApi.get(`${urlConstants.getFundType}/${payload}`);
};

export const trackBridgeLoanProcess = (payload: string) => {
  return secureApi.get(`${urlConstants.trackBridgeLoanProcess}/${payload}`);
};

export const trackVdProcess = (payload: string) => {
  return secureApi.get(`${urlConstants.trackVdProcess}/${payload}`);
};

export const getLoansDetails = (payload: string) => {
  return secureApi.get(`${urlConstants.getLoansDetails}/${payload}`);
};

export const getYcDocs = (payload: string) => {
  return secureApi.get(`${urlConstants.getYcDocs}/${payload}`);
};

export const getDefaultDocuments = (payload: UploadDocuments) => {
  return secureApi.post(urlConstants.startupListDocuments, payload);
};

export const getStartupData = (payload: PayloadType) => {
  return secureApi.get(`${urlConstants.getStartupData}/${payload}`);
};

export const getFounderDetails = (payload: PayloadType) => {
  return secureApi.get(`${urlConstants.getFounderDetails}/${payload}`);
};

export const submitQuery = (payload: SubmitEnquiry) => {
  return secureApi.post(urlConstants.submitQuery, payload);
};

export const saveStartupOnboardDetails = (payload: any) => {
  return secureApi.post(urlConstants.saveStartupOnboardDetails, payload);
};

export const getLoanCloserDocusignUrl = (payload: any) => {
  return secureApi.post(urlConstants.getLoanCloserDocusignUrl, payload);
};
export const scheduleMeeting = (loanTerms: any) =>
  secureApi.post(urlConstants.scheduleMeeting, loanTerms);

export const agreeLoanTerms = (payload?: any) =>
  secureApi.post(urlConstants.agreeLoanTerms, payload);

export const adminAgreeLoanTerms = (payload?: any) =>
  secureApi.post(urlConstants.adminAgreeLoanTerms, payload);

export const getAdminLoanTerms = (payload: string) => {
  return secureApi.get(`${urlConstants.getLoanTerms}/${payload}`);
};

export const updateStartUpLoanTerms = (payload: any) => {
  return secureApi.post(urlConstants.updateStartUpLoanTerms, payload);
};

export const isStartupInCurrentYcBatch = () => {
  return secureApi.post(urlConstants.isInCurrentYcBatch);
};

export const validateYcStatus = (payload: any) => {
  return secureApi.post(urlConstants.validateYcStatus, payload);
};

export const getStartupLoan = (payload: string) => {
  return secureApi.get(`${urlConstants.getStartupLoan}/${payload}`);
};

export const getLoanCloserInfo = (payload: string) => {
  return secureApi.get(`${urlConstants.getLoanCloserInfo}/${payload}`);
};

export const handleLoanCloserService = (payload: any) => {
  return secureApi.post(urlConstants.handleLoanCloser, payload);
};

export const getAdminRepaidAmount = (payload: string) => {
  return secureApi.get(`${urlConstants.getAdminRepaidAmount}?${payload}`);
};

export const getStartupRepaidAmount = (payload: string) => {
  return secureApi.get(`${urlConstants.getStartupRepaidAmount}?${payload}`);
};

export const completeAction = (payload: any) => {
  return secureApi.post(`${urlConstants.completeAction}`, payload);
};
