import React, { useState } from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  withStyles,
  createStyles,
  Theme,
  Grid,
  TableBody,
  Box,
  Tooltip,
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import {
  openIcon,
  actionMail,
  scheduleMeetings,
  ProfileOutlinedGreenIcon,
  ProfileOutlinedRedIcon,
} from 'assets';
import {
  FCRM_STAGE_MAPPER,
  FCRM_STAGE_COLORS,
  FCRM_TAG,
} from 'common/utils/constants';
import { formatAmount, errorMessageHandler } from 'common/utils/helpers';
import { WrappedSelect, Loader } from 'components';
import { LightStyledTooltip } from 'components/Wrappers/Tooltip';
import { fcrmStatus, investorTypes } from 'common/utils/optionList';
import { ShareDealProfile } from 'views/dashboard/components/DialogBox';
import { getDealRequestSent } from 'services';

import { getNumberFieldSchema } from '../validations';
import FcrmEditableCell from './FcrmEditableCell';
import styles from '../styles';

const columns = [
  {
    field: 'investorName',
    name: 'Investor',
    sticky: true,
    align: 'left',
  },
  {
    field: 'satus',
    name: 'Status',
    type: 'DROPDOWN',
    align: 'center',
  },
  // {
  //   field: 'stage',
  //   name: 'Stage',
  //   align: 'center',
  // },
  {
    field: 'expectedAmount',
    name: 'Typical Check Size',
    type: 'AMOUNT',
    align: 'right',
    sort: true,
  },
  {
    field: 'amountInvested',
    name: 'Invested Amount',
    align: 'right',
    sort: true,
  },
  {
    field: 'probability',
    name: 'Conversion Rate',
    align: 'right',
    sort: true,
  },
  {
    field: 'investorType',
    name: 'Investor Type',
    align: 'center',
  },
  {
    field: 'contacts',
    name: 'Primary Contact',
    type: 'AMOUNT',
    align: 'center',
  },
  {
    field: 'actions',
    name: 'Actions',
    align: 'center',
  },
];

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontFamily: theme.typography.fontFamily,
      textAlign: 'left',
      color: '#000',
      border: '1px solid #dedede',
      backgroundColor: '#93C9C7 !important',
      fontWeight: 400,
      fontSize: 15,
      letterSpacing: 0.3,
      minWidth: 100,
    },
    body: {
      // background: theme.palette.common.white,
      background: 'white',
      color: '#000',
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      border: '1px solid #dedede',
      textAlign: 'center',
      cursor: 'pointer',
      padding: 10,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

type FCRMTableProps = {
  groupedFcrms: any;
  setShowStages: any;
  currency: string;
  stageTotal: Record<string, any>;
  showStages: Record<string, any>;
  setEditItemIdMap: any;
  setEditMode: any;
  setOpenSidePane: any;
  setOpenEmailSidePane: any;
  onSuccessInlineUpdate: any;
  onClickTableCell: any;
  onBlurField: any;
  setFieldHaveError: any;
  onSelectFieldChange: any;
  filteredFcrms: any;
  setEditContactId: any;
  selectFieldChangeLoader: string;
  setemailSendFcrm: any;
  setScheduleMeeting: any;
  fcrmInvestorTypes: Array<string>;
  sortFcrmItems: any;
  setSortDirection: any;
  sortDirection: string;
  isRoleAdmin: boolean;
};
const FCRMTable = ({
  groupedFcrms,
  setShowStages,
  currency,
  stageTotal,
  showStages,
  setEditItemIdMap,
  setEditMode,
  setOpenSidePane,
  setOpenEmailSidePane,
  onSuccessInlineUpdate,
  onClickTableCell,
  onBlurField,
  setFieldHaveError,
  onSelectFieldChange,
  filteredFcrms,
  setEditContactId,
  selectFieldChangeLoader,
  setemailSendFcrm,
  setScheduleMeeting,
  fcrmInvestorTypes,
  sortFcrmItems,
  sortDirection,
  setSortDirection,
  isRoleAdmin,
}: FCRMTableProps) => {
  const classes = styles();

  const [activeTooltipId, setActiveTooltipId] = useState<string>('');
  const [amountInvestedError, setAmountInvestedError] = useState<
    Record<string, boolean>
  >({});
  // eslint-disable-next-line no-unused-vars
  const [sortedColumn, setSortedColumn] = useState<string>('expectedAmount');
  const [open, setOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<any>({});
  const [vcDealId, setVcDealId] = useState<string>('');

  const onMouseEnterRow = (id: string) => {
    setActiveTooltipId(id);
  };
  const onMouseLeaveRow = () => {
    setActiveTooltipId('');
  };

  const getPrimaryContactName = (row: Array<any>) => {
    return get(row, 'contacts').find(
      (item: Record<string, any>) => item.primary,
    );
  };

  const recordError = (item: any) => {
    if (
      !item.investmentCrm &&
      item.status === 'WON' &&
      (!item.amountInvested || !item.closingDate)
    ) {
      return true;
    }
    if (
      !item.investmentCrm &&
      item.status === 'WON' &&
      item.valuationChoice !== 'UNCAPPED_MFN' &&
      !item.valuationCap
    ) {
      return true;
    }
    return false;
  };

  const getAllDealRequestSentToStartup = (id: any) => {
    getDealRequestSent({ referenceId: id })
      .then((res: any) => {
        if (!isEmpty(res)) {
          setRequest(res);
          setOpen(true);
        }
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        // eslint-disable-next-line no-console
        console.log('DEAL_REQUEST_ERROR_MESSAGE', message);
      });
  };

  return (
    <>
      <Box className={classes.listingBox}>
        <TableContainer ref={undefined} className={classes.tableContainerDrag}>
          <Table
            stickyHeader
            aria-label="customized table"
            className={classes.tableStyle}
          >
            <TableHead className={classes.tableHead}>
              <TableRow hover>
                {(!isRoleAdmin
                  ? columns
                  : columns.filter((item) => item.field !== 'actions')
                ).map((col: any) => (
                  <StyledTableCell
                    key={col.field}
                    onClick={() => {
                      if (!col.sort) return;
                      setSortDirection(
                        sortDirection === 'DESC' ? 'ASC' : 'DESC',
                      );
                      setSortedColumn(col.field);
                      sortFcrmItems(
                        sortDirection === 'DESC' ? 'ASC' : 'DESC',
                        null,
                        col.field,
                      );
                    }}
                    style={{
                      textAlign: col.align,
                      cursor: 'pointer',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span>{col.name}</span>
                      {sortedColumn === col.field &&
                        sortDirection === 'DESC' && <ArrowDownwardIcon />}
                      {sortedColumn === col.field &&
                        sortDirection === 'ASC' && <ArrowUpwardIcon />}
                    </Box>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(groupedFcrms).map((item: any) => (
                <>
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={13}
                      style={{
                        textAlign: 'left',
                        cursor: 'pointer',
                        background: FCRM_STAGE_COLORS[item],
                      }}
                      onClick={() =>
                        setShowStages({
                          ...showStages,
                          ...{
                            [item]: !get(showStages, item),
                          },
                        })
                      }
                    >
                      <Grid className={classes.plusAccord}>
                        <Grid>
                          {!get(showStages, item) && (
                            <AddIcon className={classes.accordPlusSign} />
                          )}
                          {get(showStages, item) && (
                            <RemoveIcon className={classes.accordPlusSign} />
                          )}
                        </Grid>
                        <Grid className={classes.accordText}>
                          {' '}
                          {FCRM_STAGE_MAPPER[item] || item}{' '}
                          <span className={classes.stageSummary}>
                            {`${currency} ${formatAmount(
                              get(stageTotal, `${item}.totalShowAmount`),
                              { style: 'decimal' },
                            )} (${get(stageTotal, `${item}.totalInvestor`)})`}
                          </span>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>

                  {showStages[item] &&
                    groupedFcrms[item].map((val: any, i: number) => (
                      <StyledTableRow
                        key={`${val.id}-${i}`}
                        onMouseOver={() => onMouseEnterRow(val.id)}
                        onMouseLeave={() => onMouseLeaveRow()}
                      >
                        <StyledTableCell
                          style={{
                            textAlign: 'left',
                            paddingLeft: 10,
                            color: recordError(val) ? 'red' : '',
                          }}
                          onClick={() => {
                            if (!val.investmentCrm) {
                              setEditItemIdMap(`${item}|${i}`);
                              // setEditFcrmContent(val);
                              setEditMode(true);
                              setOpenSidePane(true);
                            }
                          }}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <span style={{ fontSize: 12, color: 'grey' }}>
                              {i + 1}.
                            </span>
                            {!val.investmentCrm && (
                              <>
                                <LightStyledTooltip
                                  style={{ marginLeft: 10 }}
                                  open={activeTooltipId === val.id}
                                  interactive
                                  title={
                                    <img
                                      src={openIcon}
                                      style={{
                                        marginTop: 8,
                                        marginLeft: -50,
                                      }}
                                      alt={openIcon}
                                      width="80"
                                      onClick={() => {
                                        // setEditFcrmContent(val);
                                        setEditItemIdMap(`${item}|${i}`);
                                        setEditMode(true);
                                        setOpenSidePane(true);
                                      }}
                                    />
                                  }
                                  arrow={false}
                                  placement="top-end"
                                >
                                  <span>{val.investorName}</span>
                                </LightStyledTooltip>
                                &nbsp;
                                <span
                                  className={
                                    val.tag === FCRM_TAG.OWN_INVESTOR
                                      ? classes.ownInvestorText
                                      : val.tag === FCRM_TAG.ADMIN
                                      ? classes.adminText
                                      : val.tag === FCRM_TAG.VC_FIRM
                                      ? classes.vcRequestText
                                      : val.tag === FCRM_TAG.ANGEL
                                      ? classes.angelInvText
                                      : ''
                                  }
                                >
                                  {val.tag === FCRM_TAG.OWN_INVESTOR
                                    ? 'Own Leads'
                                    : val.tag === FCRM_TAG.ADMIN
                                    ? '8vdX'
                                    : val.tag === FCRM_TAG.VC_FIRM
                                    ? 'VC'
                                    : val.tag === FCRM_TAG.ANGEL
                                    ? 'Angel'
                                    : ''}
                                </span>
                              </>
                            )}
                            {val.investmentCrm && (
                              <span style={{ marginLeft: 10, color: 'grey' }}>
                                {val.investorName}
                              </span>
                            )}
                          </div>
                        </StyledTableCell>

                        <StyledTableCell

                        // onClick={() =>
                        //   onClickTableCell(
                        //     `${val.id}#status`,
                        //   )
                        // }
                        >
                          <Box className={classes.selectFieldBox}>
                            <WrappedSelect
                              defaultValue={val.status}
                              error={recordError(val)}
                              disabled={isRoleAdmin || val.investmentCrm}
                              onChange={(e: any) => {
                                if (e.target.value === 'WON') {
                                  setAmountInvestedError({
                                    ...amountInvestedError,
                                    ...{
                                      [`${val.id}#amountInvested`]: true,
                                    },
                                  });
                                } else {
                                  setAmountInvestedError({
                                    ...amountInvestedError,
                                    ...{
                                      [`${val.id}#amountInvested`]: false,
                                    },
                                  });
                                }

                                onSelectFieldChange(e, `${val.id}#status`);
                              }}
                              placeholder="Status"
                              className={classes.selectInlineInput}
                              InputProps={{
                                name: 'SPV',
                                id: 'age-simple',
                                disableUnderline: true,
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              dropdowns={fcrmStatus}
                              value={val.status}
                            />
                            {selectFieldChangeLoader === `${val.id}#status` && (
                              <Loader size={20} />
                            )}
                          </Box>
                          {/* <FcrmEditableCell
                        defaulValue={val.status}
                        validations={getNumberFieldSchema(
                          `${val.id}#status`,
                        )}
                        setFieldHaveError={setFieldHaveError}
                        name={`${val.id}#status`}
                        focusCondition={
                          !getFieldStatus(`${val.id}#status`)
                        }
                        disabled={getFieldStatus(
                          `${val.id}#status`,
                        )}
                        onBlur={() =>
                          onBlurField(`${val.id}#status`)
                        }
                      /> */}
                        </StyledTableCell>

                        {/* <StyledTableCell>
                        <FcrmEditableCell
                          defaulValue={val.stage}
                          setFieldHaveError={setFieldHaveError}
                          name={`${val.id}#stage`}
                        />
                      </StyledTableCell> */}

                        <StyledTableCell
                          onClick={() =>
                            onClickTableCell(`${val.id}#expectedAmount`)
                          }
                        >
                          <FcrmEditableCell
                            defaulValue={val.expectedAmount}
                            priceInput={true}
                            prefix={currency}
                            recordErr={recordError(val)}
                            disabled={isRoleAdmin || val.investmentCrm}
                            onSuccessInlineUpdate={onSuccessInlineUpdate}
                            setFieldHaveError={setFieldHaveError}
                            type="number"
                            validations={getNumberFieldSchema(
                              `${val.id}#expectedAmount`,
                            )}
                            name={`${val.id}#expectedAmount`}
                            // focusCondition={
                            //   !getFieldStatus(
                            //     `${val.id}#expectedAmount`,
                            //   )
                            // }
                            // disabled={getFieldStatus(
                            //   `${val.id}#expectedAmount`,
                            // )}
                            onBlur={() =>
                              onBlurField(`${val.id}#expectedAmount`)
                            }
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          onClick={() =>
                            onClickTableCell(`${val.id}#amountInvested`)
                          }
                        >
                          <FcrmEditableCell
                            priceInput={true}
                            defaulValue={val.amountInvested}
                            prefix={currency}
                            recordErr={recordError(val)}
                            disabled={isRoleAdmin || val.investmentCrm}
                            onSuccessInlineUpdate={onSuccessInlineUpdate}
                            validations={getNumberFieldSchema(
                              `${val.id}#expectedAmount`,
                            )}
                            priceError={get(
                              amountInvestedError,
                              `${val.id}#amountInvested`,
                            )}
                            setFieldHaveError={setFieldHaveError}
                            name={`${val.id}#amountInvested`}
                            type="number"
                            // focusCondition={
                            //   !getFieldStatus(
                            //     `${val.id}#amountInvested`,
                            //   )
                            // }
                            // disabled={getFieldStatus(
                            //   `${val.id}#amountInvested`,
                            // )}
                            onBlur={() =>
                              onBlurField(`${val.id}#amountInvested`)
                            }
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          onClick={() =>
                            onClickTableCell(`${val.id}#probability`)
                          }
                        >
                          <FcrmEditableCell
                            defaulValue={val.probability}
                            postfix="%"
                            recordErr={recordError(val)}
                            disabled={isRoleAdmin || val.investmentCrm}
                            onSuccessInlineUpdate={onSuccessInlineUpdate}
                            validations={getNumberFieldSchema(
                              `${val.id}#probability`,
                            )}
                            setFieldHaveError={setFieldHaveError}
                            name={`${val.id}#probability`}
                            type="number"
                            // focusCondition={
                            //   !getFieldStatus(
                            //     `${val.id}#amountInvested`,
                            //   )
                            // }
                            // disabled={getFieldStatus(
                            //   `${val.id}#amountInvested`,
                            // )}
                            onBlur={() => onBlurField(`${val.id}#probability`)}
                          />
                        </StyledTableCell>

                        <StyledTableCell

                        // onClick={() =>
                        //   onClickTableCell(
                        //     `${val.id}#status`,
                        //   )
                        // }
                        >
                          <Box className={classes.selectFieldBox}>
                            <WrappedSelect
                              defaultValue={val.investorType}
                              error={recordError(val)}
                              onChange={(e: any) =>
                                onSelectFieldChange(e, `${val.id}#investorType`)
                              }
                              disabled={isRoleAdmin || val.investmentCrm}
                              placeholder="SPV"
                              className={classes.selectInlineInput}
                              InputProps={{
                                name: 'SPV',
                                id: 'age-simple',
                                disableUnderline: true,
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  focused: classes.labelFocused,
                                },
                              }}
                              dropdowns={[
                                ...investorTypes,
                                ...(fcrmInvestorTypes || []).map((item) => {
                                  return {
                                    text: item,
                                    value: item,
                                  };
                                }),
                              ]}
                              value={val.investorType}
                            />
                            {selectFieldChangeLoader ===
                              `${val.id}#investorType` && <Loader size={20} />}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() => {
                            // onClickTableCell(
                            //   `${get(val, 'contacts[0].id')}#contact#name`,
                            // )
                            if (!val.investmentCrm) {
                              setEditContactId(
                                get(getPrimaryContactName(val), 'id'),
                              );
                              setEditItemIdMap(`${item}|${i}`);
                              setEditMode(true);
                              setOpenSidePane(true);
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <FcrmEditableCell
                            defaulValue={val.cname}
                            setFieldHaveError={setFieldHaveError}
                            recordErr={recordError(val)}
                            name={`${get(
                              getPrimaryContactName(val),
                              'id',
                            )}#contact#name`}
                            disabled={true}
                            // focusCondition={
                            //   !getFieldStatus(
                            //     `${get(
                            //       val,
                            //       'contacts[0].id',
                            //     )}#contact#name`,
                            //   )
                            // }
                            // disabled={getFieldStatus(
                            //   `${get(
                            //     val,
                            //     'contacts[0].id',
                            //   )}#contact#name`,
                            // )}
                            // onBlur={() =>
                            //   onBlurField(
                            //     `${get(val, 'contacts[0].id')}#contact#name`,
                            //   )
                            // }
                          />
                        </StyledTableCell>
                        {!isRoleAdmin && (
                          <StyledTableCell>
                            <Tooltip title="Send Email">
                              <>
                                {!val.investmentCrm && (
                                  <img
                                    src={actionMail}
                                    style={{
                                      marginTop: 8,
                                      marginRight: 3,
                                    }}
                                    alt={actionMail}
                                    width="20"
                                    onClick={() => {
                                      setemailSendFcrm({
                                        data: val,
                                        type: 'SEND_EMAIL',
                                      });
                                      setOpenEmailSidePane(true);
                                    }}
                                  />
                                )}
                              </>
                            </Tooltip>
                            <Tooltip title="Schedule Meetings">
                              <>
                                {!val.investmentCrm && (
                                  <img
                                    src={scheduleMeetings}
                                    style={{
                                      marginTop: 8,
                                      marginLeft: 3,
                                    }}
                                    onClick={() => {
                                      setScheduleMeeting(val);
                                    }}
                                    alt={scheduleMeetings}
                                    width="20"
                                  />
                                )}
                              </>
                            </Tooltip>
                            {(item === 'CLOSED' || item === 'CONTACTED') &&
                              val.referenceSource === 'VC_DEAL' && (
                                <Tooltip title="See Profile">
                                  <>
                                    {!val.investmentCrm && (
                                      <img
                                        src={ProfileOutlinedGreenIcon}
                                        style={{
                                          marginTop: 8,
                                          marginLeft: 5,
                                        }}
                                        onClick={() => {
                                          if (
                                            val.referenceSource === 'VC_DEAL'
                                          ) {
                                            setVcDealId(val.referenceId);
                                            setOpen(true);
                                          }
                                        }}
                                        alt={ProfileOutlinedGreenIcon}
                                        width="20"
                                      />
                                    )}
                                  </>
                                </Tooltip>
                              )}
                            {item === 'VC_REQUEST' &&
                              val.referenceSource === 'VC_DEAL' && (
                                <Tooltip title="Update Profile">
                                  <>
                                    {!val.investmentCrm && (
                                      <img
                                        src={ProfileOutlinedRedIcon}
                                        style={{
                                          marginTop: 8,
                                          marginLeft: 5,
                                        }}
                                        onClick={() => {
                                          getAllDealRequestSentToStartup(
                                            val.referenceId̦,
                                          );
                                        }}
                                        alt={ProfileOutlinedRedIcon}
                                        width="20"
                                      />
                                    )}
                                  </>
                                </Tooltip>
                              )}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))}
                </>
              ))}

              {isEmpty(filteredFcrms) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={9}>No Record Found</StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {open && (
        <ShareDealProfile
          open={open}
          request={request}
          vcDealId={vcDealId}
          closeDialog={() => {
            setRequest({});
            setOpen(false);
            setVcDealId('');
          }}
        />
      )}
    </>
  );
};

export default FCRMTable;
