import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { isEmpty, get } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Accordion,
  Box,
  Button,
  Dialog,
  Loader,
  WrappedTextInput,
} from 'components';
import { adminApproveRejectVcFirmDocuments } from 'services';
import { errorMessageHandler } from 'common/utils/helpers';
import { documentIcon, pdfIcon, WarningIcon } from 'assets';

import styles from './styles';
import { vcFirmDocReject } from '../validation';
import VcFirmChangeStatus from './VcFirmChangeStatus';

type Props = {
  investorId: string;
  documentLoading: boolean;
  vcFirmDocuments: Array<any>;
  documentErrorMessage: string;
  vcDetails: any;
  setVcDetails: React.Dispatch<React.SetStateAction<any>>;
  getVcFirmDocuments: any;
};

type UploadedDocsType = {
  registration: any;
  logo: any;
};

const VcFirmProcess = ({
  investorId,
  documentLoading,
  vcFirmDocuments,
  vcDetails,
  getVcFirmDocuments,
  setVcDetails,
  documentErrorMessage,
}: Props) => {
  const classes = styles();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [expanded, setExpanded] = useState<string | false>(false);
  const [url, setUrl] = useState<string>('');
  const [document, setDocument] = useState<any>('');
  const [isOpenDocument, setOpenDocument] = useState<boolean>(false);
  const [isReject, setIsReject] = useState<boolean>(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [currentDoc, setCurrentDoc] = useState<any>({});
  const [isApproveDoc, setIsApproveDoc] = useState<boolean>(false);
  const [isApproveLoading, setIsApproveLoading] = useState<UploadedDocsType>({
    registration: false,
    logo: false,
  });
  const [
    isRejectDocLoading,
    setIsRejectDocLoading,
  ] = useState<UploadedDocsType>({
    registration: false,
    logo: false,
  });

  const { handleSubmit, control, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(vcFirmDocReject),
  });

  const handleRenderFile = (ext: string, doc: any) => {
    switch (ext) {
      case 'img':
        return (
          <img
            src={doc.documentUrl}
            alt={doc.filename}
            className={classes.bGDocuImg}
            onClick={() => {
              setOpenDocument(true);
              setUrl(doc.documentUrl);
              setDocument(doc);
            }}
          />
        );
      case 'doc':
        return (
          <a href={doc.documentUrl} target="_blank" rel="noreferrer">
            <img
              src={documentIcon}
              alt={documentIcon}
              className={classes.bGDocuImg}
            />
          </a>
        );
      case 'pdf':
        return (
          <a href={doc.documentUrl} target="_blank" rel="noreferrer">
            <img src={pdfIcon} alt={pdfIcon} className={classes.bGDocuImg} />
          </a>
        );
      default:
        return (
          <a href={doc.documentUrl} target="_blank" rel="noreferrer">
            <img
              src={documentIcon}
              alt={documentIcon}
              className={classes.bGDocuImg}
            />
          </a>
        );
    }
  };

  const onDocumentApprove = (doc: any) => {
    let key = '';
    if (doc.documentName === 'LOGO') {
      key = 'logo';
    } else key = 'registration';
    setErrorMessage('');
    setIsApproveLoading((prevState: any) => ({
      ...prevState,
      [key]: true,
    }));
    const obj = {
      investorId: investorId,
      vcFirmId: get(doc, 'vcFirmId'),
      documentId: get(doc, 'id'),
      documentStatus: 'VERIFIED',
    };
    adminApproveRejectVcFirmDocuments(obj)
      .then(() => {
        setIsApproveLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
        setCurrentDoc({});
        getVcFirmDocuments(get(doc, 'vcFirmId'));
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        setErrorMessage(message);
        setIsApproveLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      });
  };

  const onDocumentReject = () => {
    let key = '';
    if (currentDoc.documentName === 'LOGO') {
      key = 'logo';
    } else key = 'registration';
    setErrorMessage('');
    const data = { ...getValues() };
    setIsRejectDocLoading((prevState: any) => ({
      ...prevState,
      [key]: true,
    }));
    const obj = {
      investorId: investorId,
      vcFirmId: get(currentDoc, 'vcFirmId'),
      documentId: get(currentDoc, 'id'),
      documentStatus: 'REJECTED',
      rejectedReason: get(data, 'reject'),
    };
    adminApproveRejectVcFirmDocuments(obj)
      .then(() => {
        setIsRejectDocLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
        getVcFirmDocuments(get(currentDoc, 'vcFirmId'));
        setIsReject(false);
        reset();
      })
      .catch((err: any) => {
        const message: string = errorMessageHandler(err);
        reset();
        setErrorMessage(message);
        setIsRejectDocLoading((prevState: any) => ({
          ...prevState,
          [key]: false,
        }));
      });
  };

  const handleFileType = (doc: any) => {
    const filename = doc.filename;
    let ext = filename && filename.substr(filename.lastIndexOf('.') + 1);
    if (['png', 'jpg', 'jpeg'].includes(ext)) {
      ext = 'img';
    }
    if (['doc', 'docx'].includes(ext)) {
      ext = 'doc';
    }
    return handleRenderFile(ext, doc);
  };

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<any>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isDocumentUploaded = (): boolean => {
    if (!isEmpty(vcFirmDocuments)) {
      const doc = vcFirmDocuments.filter(
        (item: any) => item.documentStatus !== 'VERIFIED',
      );
      if (isEmpty(doc)) return true;
      else return false;
    } else return false;
  };

  return (
    <>
      <Box className={classes.firmVCProcessBox}>
        {errorMessage && (
          <Typography variant="caption" className={classes.errorText}>
            {errorMessage}
          </Typography>
        )}
        {documentErrorMessage && (
          <Typography variant="caption" className={classes.errorText}>
            {documentErrorMessage}
          </Typography>
        )}
        <Accordion
          subheading={'Review Documents'}
          expanded={expanded === 'REVIEW_DOCUMENTS'}
          handleChange={(e: string) => handleChange(e)}
          index={'REVIEW_DOCUMENTS'}
          buttons={
            <Box className={classes.summaryAccordRight}>
              <Button
                name={
                  isDocumentUploaded()
                    ? 'Document Verified'
                    : 'Yet to verify documents'
                }
                className={classes.verifiedBtn}
                disabled={isEmpty(vcFirmDocuments)}
              />
              <MoreHorizIcon className={classes.moreIcon} />
            </Box>
          }
        >
          <Box className={classes.docuComponent}>
            {documentLoading && !isEmpty(vcFirmDocuments) && (
              <Box className={classes.loaderSection}>
                <Loader className={classes.loaderBox} />
              </Box>
            )}
            {!isEmpty(vcFirmDocuments) ? (
              <>
                <Box className={classes.documentListComponent}>
                  {vcFirmDocuments.map((doc: any) => (
                    <Box key={doc.id} className={classes.reviewDocuBox}>
                      {handleFileType(doc)}
                      <Typography className={classes.reviewDocuText}>
                        {doc.documentName === 'LOGO'
                          ? 'Logo'
                          : 'Registration Certificate'}
                      </Typography>
                      <Box className={classes.buttonContainer}>
                        <Button
                          name={
                            doc.documentStatus === 'VERIFIED'
                              ? 'Approved'
                              : 'Approve'
                          }
                          className={
                            doc.documentStatus === 'VERIFIED'
                              ? classes.approvedBtn
                              : classes.approveBtn
                          }
                          onClick={() => onDocumentApprove(doc)}
                          isLoading={
                            doc.documentName === 'LOGO'
                              ? get(isApproveLoading, 'logo')
                              : get(isApproveLoading, 'registration')
                          }
                          disabled={
                            doc.documentName === 'LOGO'
                              ? get(isApproveLoading, 'logo')
                              : get(isApproveLoading, 'registration')
                          }
                        />
                        <Button
                          variant="outlined"
                          className={classes.rejectBtn}
                          name={
                            doc.documentStatus === 'REJECTED'
                              ? 'Rejected'
                              : 'Reject'
                          }
                          onClick={() => {
                            setCurrentDoc(doc);
                            setIsReject(true);
                          }}
                        />
                      </Box>
                      {doc.documentName === 'LOGO' &&
                        doc.documentStatus === 'REJECTED' && (
                          <Typography className={classes.rejectReasonText}>
                            Logo is rejected because{' '}
                            {get(doc, 'rejectedReason')}{' '}
                          </Typography>
                        )}
                      {doc.documentName === 'REGISTRATION_CERTIFICATE' &&
                        doc.documentStatus === 'REJECTED' && (
                          <Typography className={classes.rejectReasonText}>
                            Registration Certificate is rejected because{' '}
                            {get(doc, 'rejectedReason')}{' '}
                          </Typography>
                        )}
                    </Box>
                  ))}
                </Box>
              </>
            ) : (
              <Typography>No Document Found</Typography>
            )}
          </Box>
        </Accordion>
        <Box className={classes.backContinueBox}>
          <Button
            type="button"
            className={
              get(vcDetails, 'activated')
                ? classes.approvedBtn
                : classes.approveVcFirmBtn
            }
            name={get(vcDetails, 'activated') ? 'Activated' : 'Activate'}
            onClick={() =>
              get(vcDetails, 'activated')
                ? setUpdatingStatus(true)
                : !get(vcDetails, 'activated') &&
                  (isDocumentUploaded() || isEmpty(vcFirmDocuments))
                ? setUpdatingStatus(true)
                : setIsApproveDoc(true)
            }
            // disabled={!get(vcDetails, 'acceptedAggrement')}
          />
        </Box>
      </Box>
      <Dialog
        open={isOpenDocument}
        maxWidth={'md'}
        subheading={''}
        title={get(document, 'documentName', '')}
        handleClose={() => {
          setOpenDocument(false);
          setUrl('');
          setDocument('');
        }}
      >
        <img
          src={url}
          alt={get(document, 'filename')}
          className={classes.previewImg}
        />
      </Dialog>
      <Dialog
        open={isApproveDoc}
        maxWidth={'sm'}
        subheading={''}
        title={''}
        handleClose={() => setIsApproveDoc(false)}
      >
        <Box className={classes.warningContainer}>
          <img src={WarningIcon} alt={WarningIcon} />
          <Typography variant="body1" className={classes.warningText}>
            Please Review and Approve the document before activating the firm
          </Typography>
          <Box>
            <Button
              type="button"
              name="OK"
              className={classes.warnBtn}
              onClick={() => setIsApproveDoc(false)}
            />
            <Button
              variant="outlined"
              className={classes.warnCancelBtn}
              name="Cancel"
              onClick={() => setIsApproveDoc(false)}
            />
          </Box>
        </Box>
      </Dialog>
      <Dialog
        open={isReject}
        maxWidth={'md'}
        subheading={''}
        title="Reject Document"
        handleClose={() => {
          setIsReject(false);
        }}
      >
        <form onSubmit={handleSubmit(onDocumentReject)} data-testid="reject">
          <Typography variant="caption" className={classes.companyLabelText}>
            Reason<span className={classes.errorRequiredText}>*</span>
          </Typography>
          <Controller
            name="reject"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <WrappedTextInput
                  type="text"
                  label=""
                  placeholder="Enter Reason"
                  className={classes.textValInput}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                  error={error?.message ? true : false}
                />
                {error?.message && (
                  <span className={classes.errorRejectText}>
                    {error?.message}
                  </span>
                )}
              </>
            )}
            rules={{ required: 'Email is required' }}
          />
          <Box>
            <Button
              type="submit"
              variant="outlined"
              className={classes.rejectDocBtn}
              name="Reject this document"
              isLoading={
                currentDoc.documentName === 'LOGO'
                  ? get(isRejectDocLoading, 'logo')
                  : get(isRejectDocLoading, 'registration')
              }
              disabled={
                currentDoc.documentName === 'LOGO'
                  ? get(isRejectDocLoading, 'logo')
                  : get(isRejectDocLoading, 'registration')
              }
            />
            <Button
              variant="outlined"
              className={classes.cancelBtn}
              name="Cancel"
              onClick={() => setIsReject(false)}
            />
          </Box>
        </form>
      </Dialog>

      <VcFirmChangeStatus
        updatingStatus={updatingStatus}
        setUpdatingStatus={setUpdatingStatus}
        vcDetails={vcDetails}
        setVcDetails={setVcDetails}
        investorId={investorId}
      />
    </>
  );
};

export default VcFirmProcess;
