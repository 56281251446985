import { urlConstants } from 'common/utils/constants';
import { secureApi } from 'api/Axios';

export const getClientsList = () =>
  secureApi.get(`${urlConstants.getClientsList}`);

export const createUpdateClient = (payload: any) =>
  secureApi.post(urlConstants.createUpdateClient, payload);

export const getClientAccessKey = (payload: any) =>
  secureApi.post(`${urlConstants.getClientAccessKey}`, payload);

export const viewClientServiceUsage = (payload: any) =>
  secureApi.get(`${urlConstants.viewClientServiceUsage}${payload}`);
