import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { get } from 'lodash';

import { Loader } from 'components';
import history from 'common/utils/history';
import { isStartupInCurrentYcBatch } from 'services';
import { RootState } from 'redux-modules/store/rootState';
import { handleGetStartUpDetails } from 'redux-modules/enquiryForm/Actions';

const Navigation = () => {
  const dispatch = useDispatch();

  const [isCurrentYc, setIsCurrentYc] = React.useState<any>(undefined);
  const [accelerator, setAccelerator] = React.useState<any>(undefined);
  const [basicInfoAdded, setBasicInfoAdded] = React.useState<any>(undefined);
  const { isLoading, successMessage, popupLog, currentPage } = useSelector(
    ({ EnquiryForm }: RootState) => EnquiryForm,
  );

  useEffect(() => {
    isStartupInCurrentYcBatch()
      .then((res) => {
        setIsCurrentYc(get(res, 'inCurrentBatch') || false);
        setBasicInfoAdded(get(res, 'basicInfoAdded') || false);
        setAccelerator(get(res, 'startup.accelerator') || null);
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        history.push('/sign-in');
      });
  }, []);

  // TODO: Needs to refactor this conditions
  useEffect(() => {
    if (
      isCurrentYc !== undefined &&
      accelerator !== undefined &&
      basicInfoAdded !== undefined
    ) {
      if (
        successMessage &&
        successMessage.length &&
        (accelerator === 'YC' || !accelerator) &&
        isCurrentYc
      ) {
        currentPage !== 'ADDITIONAL_INFORMATION_FORM'
          ? history.push('/startup/enquiry-form/EDIT')
          : history.push('/dashboard');
      } else if (
        (successMessage && successMessage.length && accelerator !== 'YC') ||
        (successMessage &&
          successMessage.length &&
          accelerator === 'YC' &&
          !isCurrentYc)
      ) {
        basicInfoAdded
          ? history.push('/startup/vd-enquiry-form/EDIT')
          : history.push('/benchmark-kpi-v2');
      } else if (
        popupLog &&
        popupLog.length &&
        (accelerator === 'YC' || !accelerator) &&
        isCurrentYc
      ) {
        history.push('/startup/enquiry-form/NEW');
      } else if (
        popupLog &&
        popupLog.length &&
        (accelerator === 'YC' || !accelerator) &&
        !isCurrentYc
      ) {
        history.push('/benchmark-kpi-v2');
      } else if (popupLog && popupLog.length && accelerator !== 'YC') {
        history.push('/benchmark-kpi-v2');
      }
    }
  }, [successMessage, popupLog, accelerator, isCurrentYc]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const Id = user.attributes['custom:startup_id'];
        dispatch(handleGetStartUpDetails(Id));
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        history.push('/sign-in');
      });
  }, []);

  return isLoading || isCurrentYc === undefined ? <Loader /> : null;
};

export default Navigation;
