import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  kycDetails: {
    padding: '50px 120px 35px',
    '@media (max-width: 1125px)': {
      padding: '50px 5% 35px',
    },
    '@media (max-width: 1024px)': {
      padding: '0px 5% 35px',
    },
    '@media (max-width: 767px)': {
      padding: '0px 5% 15px',
    },
  },
  dealCreationHeader: {
    fontSize: 32,
    letterSpacing: 0.3,
    color: theme.palette.warning.contrastText,
    marginBottom: 35,
    fontWeight: 400,
    '@media (max-width: 525px)': {
      fontSize: 20,
      margin: '0px 0 20px',
    },
  },
  dealCreationSubHeader: {
    fontSize: 26,
    letterSpacing: 0.3,
    color: theme.palette.warning.contrastText,
    marginBottom: 0,
    fontWeight: 600,
    '@media (max-width: 525px)': {
      fontSize: 14,
    },
  },
  dealTypeText: {
    fontSize: 18,
    letterSpacing: 0.3,
    color: theme.palette.warning.contrastText,
    marginBottom: 25,
    marginTop: 45,
    fontWeight: 700,
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
    '@media (max-width: 525px)': {
      fontSize: 17,
      margin: '20px 0',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: 'left',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 15,
    display: 'block',
    textAlign: 'left',
    '@media (max-width: 525px)': {
      bottom: 12,
    },
  },
  validateForm: {
    paddingTop: 10,
    '& .MuiGrid-item': {
      padding: '5px 15.5px 0',
    },
  },
  controllerBox: {
    position: 'relative',
  },
  labelText: {
    fontSize: 15,
    color: theme.palette.warning.contrastText,
    fontWeight: 600,
    marginBottom: 8,
  },
  textValInput: {
    marginBottom: 30,
    width: '100%',
  },
  inputRoot: {
    width: '100%',
    fontSize: 16,
    '&:hover': {
      '&:not(.Mui-disabled)::before': {
        borderBottomColor: theme.palette.primary.light,
      },
    },
  },
  labelRoot: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.35,
  },
  labelFocused: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    fontSize: 15,
    transform: 'translate(0, 1.5px) scale(1)',
  },
  nextPrevButtonBox: {
    paddingTop: 30,
    display: 'flex',
    paddingBottom: 10,
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    width: 152,
    fontSize: 16,
    cursor: 'pointer',
    height: 57,
    letterSpacing: 0.3,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  selectedOpt: {
    display: 'flex',
  },
  selectedOptL: {
    width: 115,
    marginTop: -16,
  },
  selectedOptR: {
    width: 'calc(100% - 115px)',
    paddingLeft: 15,
  },
  errorTextCountry: {
    width: '125px',
    color: theme.palette.error.main,
    fontSize: 13,
    position: 'absolute',
    bottom: 15,
    display: 'block',
    textAlign: 'left',
    '@media (max-width: 525px)': {
      bottom: 12,
    },
  },
  //Custom Switch Style
  switchBox: {
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
    marginTop: 25,
    marginBottom: 25,
  },
  switchRoot: {
    width: '200px',
    height: '42px',
    padding: '0px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#E5E5E5',
      },
      '& $thumb:after': {
        content: "'Startup'",
      },
    },
    '&$disabled + $track': {
      backgroundColor: '#E5E5E5',
      opacity: 0.5,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#E5E5E5',
      opacity: '0.5 !important',
    },
  },
  thumb: {
    color: theme.palette.primary.main,
    width: '100px',
    height: '42px',
    margin: '-1px',
    borderRadius: 31,
    '&:after': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 12,
      right: 35,
      left: 20,
      content: "'Fund'",
    },
  },
  track: {
    borderRadius: '31px',
    backgroundColor: '#E5E5E5',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'black',
      fontSize: 14,
      fontWeight: 700,
      position: 'absolute',
      top: 12,
    },
    '&:after': {
      content: "'Fund'",
      left: 30,
    },
    '&:before': {
      content: "'Startup'",
      right: 35,
    },
  },
  checked: {
    color: theme.palette.primary.main,
    transform: 'translateX(100px) !important',
  },
  //Custom Switch Style
}));
