import { IRoute } from 'common/types';
import AuthLayout from 'layouts/AuthLayout';

import ForgotPassword from './ForgotPassword';

export const ForgotPasswordRoute: IRoute = {
  path: '/forgot-password',
  component: ForgotPassword,
  layout: AuthLayout,
  exact: true,
};
