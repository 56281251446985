import React, { useEffect, useState, useMemo, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Typography, MenuItem, CircularProgress } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { PayloadType } from 'common/types';
import { RootState } from 'redux-modules/store/rootState';
import { getStartupListDocApi } from 'services';
import {
  Box,
  Tabs,
  TabPanel,
  CompanyInfo,
  CompanyOverview,
  CompanyProcess,
  CompanyProcessV2,
  Founder,
  Dialog,
} from 'components';
import {
  handleGetEnquiriesbyId,
  trackBridgeLoanProcess,
  trackVdProcess,
  emptyEnquiryDetails,
  getLoanCloserInfo,
} from 'redux-modules/enquiries/Actions';
import {
  FaceBook,
  Twitter,
  LinkedIn,
  Ornament,
  // UploadIcon,
  pdfIcon,
  documentIcon,
} from 'assets';
import { getDocumentlist } from 'redux-modules/process/Actions';
import history from 'common/utils/history';
import {
  getIncorporationCountryObject,
  getStateObject,
} from 'common/utils/helpers';
import SafeTracking from 'views/SafeTracking';

import Payments from '../Payments';
import styles from './styles';
type ComponentProps = {
  match: any;
};
const env = process.env.REACT_APP_ENV || 'dev';
const EnquiryView = ({ match }: ComponentProps) => {
  const enquiryId: string = get(match, 'params.id');
  const dispatch = useDispatch();

  const getTabValue = () => {
    if (history.location.search) {
      const search = history.location.search;
      const tabValue: any = new URLSearchParams(search).get('value') || 0;
      return Number(tabValue);
    }

    return Number(0);
  };
  const [value, setValue] = useState<number>(getTabValue());
  //const [fundType, setFundType] = useState<string | undefined>('BRIDGE_LOAN');

  const { enquiryView } = useSelector(({ Enquiries }: RootState) => Enquiries);
  //const { successMessage } = enquiryLog;

  const {
    startupDetails,
    founderDetails,
    productDescription,
    productProgress,
    potentialMarketTarget,
    startupInvestmentInformation,
    startupLegalInformation,
  } = enquiryView;

  const {
    startupName,
    startupWebLink,
    stateOfIncorporation,
    ycIncorporationCountry,
    startupId,
    industryType,
    startupTwitterUrl,
    startupFacebookUrl,
    startupLinkedInUrl,
    ycBatch,
    //description,
    //businessModel,
  } = startupDetails;

  const { role } = useSelector(({ Auth }: RootState) => Auth.user);
  const eqs = useSelector(({ Enquiries }: RootState) => Enquiries);
  const isRoleAdmin = role === 'ADMIN';

  const handleChange = (event: any, newValue: number) => {
    if (newValue === 2) {
      getAllDocs();
    }
    setValue(newValue);
  };

  const [isOpen, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [document, setDocument] = useState<any>('');
  const [allDocs, setAllDocs] = useState<any>([]);
  const [fetchingAllDocs, setFetchingAllDocs] = useState<boolean>(false);

  // useEffect(() => {
  //   dispatch(emptyEnquiryDetails());
  // }, []);

  useEffect(() => {
    if (enquiryId) {
      const obj: PayloadType = {
        id: enquiryId,
      };

      dispatch(handleGetEnquiriesbyId(obj));
    }
  }, [enquiryId]);

  // useEffect(() => {
  //   if (id) {
  //     setStartUpId(startupId);
  //   }
  // }, [id]);

  const getTabs = () => {
    if (get(enquiryView, 'fundType') === 'BRIDGE_LOAN') {
      const options = [
        { name: 'Overview' },
        { name: 'Process' },
        { name: 'Documents' },

        { name: 'Payments' },
      ];
      if (env !== 'prod') {
        return [
          { name: 'Overview' },
          { name: 'Process' },
          { name: 'Documents' },
          { name: 'SAFEs' },
          { name: 'Payments' },
        ];
      } else {
        return options;
      }
    }
    return [{ name: 'Overview' }, { name: 'Process' }, { name: 'Documents' }];
  };

  // On Unmount
  useLayoutEffect(
    () => () => {
      dispatch(emptyEnquiryDetails());
    },
    [],
  );

  useEffect(() => {
    if (!isEmpty(startupId)) {
      dispatch(trackBridgeLoanProcess(startupId));
      dispatch(getLoanCloserInfo(startupId));
      dispatch(trackVdProcess(startupId)); // TODO: Need to fix as per fundtype
      handleGetDocumentList();
    }
  }, [startupId]);

  const getAllDocs = () => {
    setFetchingAllDocs(true);
    getStartupListDocApi({
      startupId: startupId,
      loanType: get(eqs, 'enquiryView.fundType'),
      allDocs: true,
    })
      .then((res) => setAllDocs(res))
      .finally(() => setFetchingAllDocs(false));
  };

  // useEffect(() => {
  //   if (successMessage && successMessage.length) {
  //     dispatch(AddYCBatchStartupId({ startupId: startupId, ycBatch: ycBatch }));
  //   }
  // }, [successMessage]);

  // useEffect(() => {
  //   if (loanTypeObj) {
  //     const loanType = get(loanTypeObj, 'vdConversionRequestRaised', false)
  //       ? 'VENTURE_DEBT'
  //       : 'BRIDGE_LOAN';
  //     setFundType(loanType);
  //   }
  // }, [loanTypeObj]);

  const handleGetDocumentList = React.useCallback(() => {
    dispatch(
      getDocumentlist({
        startupId: startupId,
      }),
    );
  }, [startupId]);

  const classes = styles();

  const iconProps = useMemo(() => {
    return {
      className: classes.image,
    };
  }, []);

  const handleRenderFile = (ext: string, doc: any) => {
    switch (ext) {
      case 'img':
        return (
          <>
            <img
              src={doc.documentUrl}
              alt={doc.filename}
              className={classes.bGDocuImg}
              onClick={() => {
                setOpen(true);
                setUrl(doc.documentUrl);
                setDocument(doc);
              }}
            />
            {doc.documentType === 'DOCU_SIGN_DOCUMENTS' ? (
              <Typography className={classes.reviewDocuText}>
                {doc.filename}
              </Typography>
            ) : (
              <Typography className={classes.reviewDocuText}>
                {doc.documentName}
              </Typography>
            )}
          </>
        );
      case 'doc':
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <img
                src={documentIcon}
                alt={documentIcon}
                className={classes.bGDocuImg}
              />
            </a>
            {doc.documentType === 'DOCU_SIGN_DOCUMENTS' ? (
              <Typography className={classes.reviewDocuText}>
                {doc.filename}
              </Typography>
            ) : (
              <Typography className={classes.reviewDocuText}>
                {doc.documentName}
              </Typography>
            )}
          </>
        );
      case 'pdf':
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <img src={pdfIcon} alt={pdfIcon} className={classes.bGDocuImg} />
            </a>
            {doc.documentType === 'DOCU_SIGN_DOCUMENTS' ? (
              <Typography className={classes.reviewDocuText}>
                {doc.filename}
              </Typography>
            ) : (
              <Typography className={classes.reviewDocuText}>
                {doc.documentName}
              </Typography>
            )}
          </>
        );
      default:
        return (
          <>
            <a href={doc.documentUrl} target="_blank" rel="noreferrer">
              <AttachFileIcon {...iconProps} />
            </a>
            {doc.documentType === 'DOCU_SIGN_DOCUMENTS' ? (
              <Typography className={classes.reviewDocuText}>
                {doc.filename}
              </Typography>
            ) : (
              <Typography className={classes.reviewDocuText}>
                {doc.documentName}
              </Typography>
            )}
          </>
        );
    }
  };

  const handleLoadFile = (doc: any) => {
    const filename = doc.filename;
    let ext = filename && filename.substr(filename.lastIndexOf('.') + 1);
    if (['png', 'jpg', 'jpeg'].includes(ext)) {
      ext = 'img';
    }
    if (['doc', 'docx'].includes(ext)) {
      ext = 'doc';
    }
    return handleRenderFile(ext, doc);
  };

  const LoadDocuments = () => {
    return (
      <>
        {isEmpty(allDocs) && (
          <Typography>Admin didnt requested any document</Typography>
        )}
        <Box className={classes.docuContainer}>
          {!isEmpty(allDocs) &&
            allDocs.map((doc: any) => {
              return (
                <Box key={doc.id}>
                  <Box key={doc.id}>{handleLoadFile(doc)}</Box>
                </Box>
              );
            })}
        </Box>
      </>
    );
  };

  return (
    <>
      <Box className={classes.enquiryViewContainer}>
        <Box className={classes.enquiryViewBox}>
          <Box
            className={[
              value !== 3
                ? classes.enquiryViewBoxLeft
                : classes.enquiryViewBoxLeftFullWidth,
            ]}
          >
            <CompanyInfo
              name={startupName}
              startupId={startupId}
              isRoleAdmin={isRoleAdmin}
              link={startupWebLink}
              location={`${
                getStateObject(stateOfIncorporation, ycIncorporationCountry)
                  ?.name || ''
              }${stateOfIncorporation ? ', ' : ''}${
                getIncorporationCountryObject(ycIncorporationCountry)
                  ?.country || ''
              }${ycIncorporationCountry ? '.' : ''}`}
              Ornament={Ornament}
              type={industryType ? industryType : ''}
            />
            <Box className={classes.enquiryViewTabBox}>
              <Tabs
                tabs={getTabs()}
                value={value}
                handleChange={handleChange}
                className={classes.enquiryTabs}
              />
              <TabPanel value={value} index={0} className={classes.tanPanelBox}>
                <CompanyOverview
                  //additionalInformation={additionalInformation}
                  startupDetails={startupDetails}
                  productDescription={productDescription}
                  productProgress={productProgress}
                  potentialMarketTarget={potentialMarketTarget}
                  startupInvestmentInformation={startupInvestmentInformation}
                  startupLegalInformation={startupLegalInformation}
                  // businessDetails={businessDetails}
                  // description={description}
                  // businessModel={businessModel}
                  founderDetails={founderDetails}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                {get(eqs, 'enquiryView.fundType') === 'BRIDGE_LOAN' && (
                  <CompanyProcess startupId={startupId} />
                )}
                {get(eqs, 'enquiryView.fundType') === 'VENTURE_DEBT' && (
                  <CompanyProcessV2 startupId={startupId} />
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {fetchingAllDocs && <CircularProgress />}
                {!fetchingAllDocs && LoadDocuments()}
              </TabPanel>
              {env !== 'prod' && (
                <TabPanel
                  value={value}
                  index={3}
                  className={classes.safeTracking}
                >
                  <SafeTracking isRoleAdmin={isRoleAdmin} />
                </TabPanel>
              )}
              <TabPanel
                value={value}
                index={env !== 'prod' ? 4 : 3}
                className={classes.tabPaymentBox}
              >
                <Payments startupId={startupId} />
              </TabPanel>
            </Box>
          </Box>
          {value !== 3 && (
            <Box className={classes.enquiryViewBoxRight}>
              <Box className={classes.contentBoxRight}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.labelStartUpText}
                >
                  YC Batch
                </Typography>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.labelStartUp}
                >
                  {ycBatch}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.labelStartUpText}
                >
                  Company Website
                </Typography>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.labelStartUpUrl}
                >
                  {startupWebLink || '--'}
                </Typography>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.labelFundHead}
                >
                  Founders
                </Typography>
                {founderDetails && (
                  <div className={classes.founderDetailsBox}>
                    {founderDetails.map((i, n) => {
                      return <Founder details={i} key={n} />;
                    })}
                  </div>
                )}
                {(startupFacebookUrl ||
                  startupTwitterUrl ||
                  startupLinkedInUrl) && (
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.labelStartUpText}
                  >
                    Social Media URL’s
                  </Typography>
                )}
                {startupFacebookUrl && (
                  <MenuItem
                    className={classes.menuItemText}
                    onClick={() => window.open(startupFacebookUrl, '_blank')}
                  >
                    <img src={FaceBook} alt={FaceBook} />
                  </MenuItem>
                )}
                {startupTwitterUrl && (
                  <MenuItem
                    className={classes.menuItemText}
                    onClick={() => window.open(startupTwitterUrl, '_blank')}
                  >
                    <img src={Twitter} alt={Twitter} />
                  </MenuItem>
                )}
                {startupLinkedInUrl && (
                  <MenuItem
                    className={classes.menuItemText}
                    onClick={() => window.open(startupLinkedInUrl, '_blank')}
                  >
                    <img src={LinkedIn} alt={LinkedIn} />
                  </MenuItem>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        open={isOpen}
        maxWidth={'md'}
        subheading={''}
        title={get(document, 'documentName', '')}
        handleClose={() => {
          setOpen(false);
        }}
      >
        <img
          src={url}
          alt={get(document, 'filename')}
          className={classes.previewImg}
        />
      </Dialog>
    </>
  );
};

export default EnquiryView;
