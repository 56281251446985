import { put, takeLatest, call, fork, all } from 'redux-saga/effects';
import { get } from 'lodash';

import { convertVentureDebt } from 'services';
import { ISagaAction } from 'common/types';

import { ConvertVentureDebtType } from './Types';
import {
  VENTURE_DEBT_SUBMIT,
  VENTURE_DEBT_SUBMIT_LOADING,
  VENTURE_DEBT_SUBMIT_SUCCESS,
  VENTURE_DEBT_SUBMIT_FAILURE,
} from './Actions';

const ventureDebtSubmit = async (data: ConvertVentureDebtType) =>
  convertVentureDebt(data)
    .then((res) => res)
    .catch((err) => Promise.reject(err));

function* handleVentureDebtSubmit(data: ISagaAction<ConvertVentureDebtType>) {
  const { payload } = data;
  try {
    yield put({ type: VENTURE_DEBT_SUBMIT_LOADING });
    yield call<any>(ventureDebtSubmit, payload);
    yield put({
      type: VENTURE_DEBT_SUBMIT_SUCCESS,
      payload: 'Venture debt loan request approved successfully',
    });
  } catch (err) {
    const message: string = get(err, 'response.data.message');
    yield put({ type: VENTURE_DEBT_SUBMIT_FAILURE, payload: message });
  }
}

function* ventureDebtSubmitSaga() {
  yield takeLatest(VENTURE_DEBT_SUBMIT, handleVentureDebtSubmit);
}

export default function* ventureDebtSagas() {
  yield all([fork(ventureDebtSubmitSaga)]);
}
