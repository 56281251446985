import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  menuItemText: {
    display: 'inline-block',
  },
  image: {
    height: '100px',
    width: 'initial',
    maxWidth: '100%',
    // color: palette.text.primary,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    // borderRadius: shape.borderRadius,
    zIndex: 5,
    opacity: 1,
    cursor: 'pointer',
  },

  // Document Styling -------- Start
  bGDocuImg: {
    height: 100,
    width: 104,
    marginTop: 25,
    cursor: 'pointer',
    border: '1px solid #1EA59A',
    borderRadius: 5,
  },
  docuContainer: {
    display: 'grid',
    gridGap: 30,
    padding: '10px 20px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(106px, 1fr) )',
  },
  reviewDocuText: {
    fontSize: 11,
    color: theme.palette.grey[600],
    marginBottom: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  previewImg: {
    height: '100%',
    width: '100%',
    marginBottom: 15,
    marginTop: 20,
    cursor: 'pointer',
  },
  // Document Styling --------- End
}));
