import React, { ReactChildren } from 'react';

import { NavBar, Box } from 'components';

import styles from './styles';

type Props = {
  children: ReactChildren;
};

// Use this layout for white background color (COMMON)
const DashboardLayout = ({ children }: Props) => {
  const classes = styles();
  return (
    <Box className={classes.dashboardLayout}>
      <Box className={classes.dashLayoutLeft}>
        <NavBar />
      </Box>
      <Box className={classes.dashLayoutRight}>{children}</Box>
    </Box>
  );
};

export default DashboardLayout;
